import PropTypes from 'prop-types';

const DefaultTosDescription = () => (
  <span data-testid="default-tos-span">
    I understand that Riot is partnering with Znipe.tv to bring me this service, and I accept and
    agree to Znipe.tv’s{' '}
    <a href="https://beta.znipe.tv/terms-of-use" rel="noopener noreferrer" target="_blank">
      Terms of Use{' '}
    </a>
  </span>
);

const TermsOfServiceComponent = ({ productName = 'Pro View' }) => {
  if (productName === 'Pro View') {
    return <DefaultTosDescription />;
  }

  // here we can add different component depending on product
  return <DefaultTosDescription />;
};

TermsOfServiceComponent.propTypes = {
  productName: PropTypes.string,
};

export default TermsOfServiceComponent;
