import setCookie from 'znipe-utils/web/setCookie';
import routes from 'tv-routes/config/setupRoutes/routes';
import { matchPath } from 'react-router-dom';

export const COOKIE_MAX_AGE_SECONDS = 300;

const startUserSessionHeartBeat = userSession => {
  let intervalTimer;
  setCookie('userSession', userSession, COOKIE_MAX_AGE_SECONDS, '/');
  const route = routes.find(currentRoute => matchPath(currentRoute.path, window.location.pathname));
  if (!route?.dontStartUserSessionHeartbeat) {
    intervalTimer = setInterval(
      () => setCookie('userSession', userSession, COOKIE_MAX_AGE_SECONDS, '/'),
      (COOKIE_MAX_AGE_SECONDS - 10) * 1000,
    );
  }
  return intervalTimer;
};

export default startUserSessionHeartBeat;
