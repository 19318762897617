import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const defaultTeams = { teamA: '', teamB: '' };

const makeGetVideoTeams = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => {
    const { teamA = '', teamB = '' } = video ?? defaultTeams;
    return [teamA, teamB];
  });
};

export const useGetVideoTeams = props => useMemoizedGqlSelector(makeGetVideoTeams, props);

export default makeGetVideoTeams;
