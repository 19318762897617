import colors from 'znipe-styles/colors';

export default {
  proview: {
    bufferColor: 'rgba(238, 238, 238, 0.50)',
    hoverColor: 'rgba(238, 238, 238, 0.60)',
    trackColor: 'rgba(255, 255, 255, 0.2)',
    tooltipText: colors.white,
    tooltipBackground: colors.riotBackgroundSecondary,
  },
  default: {
    bufferColor: 'rgba(238, 238, 238, 0.50)',
    hoverColor: 'rgba(238, 238, 238, 0.60)',
    trackColor: 'rgba(255, 255, 255, 0.2)',
    tooltipText: colors.white,
    tooltipBackground: colors.riotBackgroundSecondary,
  },
  esportal: {
    bufferColor: 'rgba(238, 238, 238, 0.50)',
    hoverColor: 'rgba(238, 238, 238, 0.60)',
    trackColor: 'rgba(255, 255, 255, 0.2)',
    tooltipText: colors.white,
    tooltipBackground: colors.riotBackgroundSecondary,
  },
};
