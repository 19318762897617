import { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';
import 'firebase/auth';
import SuccessRoute from './SuccessRoute';
import RegisterRoute from './RegisterRoute';

const RegisterForm = ({ continuePath }) => {
  const [state, setState] = useState('');
  const [userEmail, setUserEmail] = useState('');

  return (
    <Routes>
      <Route
        path="/"
        element={<RegisterRoute setState={setState} state={state} setUserEmail={setUserEmail} />}
      />
      <Route
        path="success"
        element={
          <SuccessRoute
            userEmail={userEmail}
            continuePath={continuePath}
            state={state}
            setState={setState}
          />
        }
      />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

RegisterForm.propTypes = {
  continuePath: PropTypes.string,
};

export default RegisterForm;
