import { useEffect, useRef, useCallback } from 'react';
import shaka from 'shaka-player';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';
import { useFeatureFlag } from 'znipe-link/link';

const createFakeLocation = href => {
  const url = document.createElement('a');
  url.href = href;
  return url;
};

const useNetworkFilter = player => {
  const longToken = useRef(null);
  const headerXToken = useRef(null);
  const origin = useRef(null);
  const usePragma = useRef(false);

  const fastlyTokenRequestType = useFeatureFlag('fastly-token-request-type');

  const responseFilter = useCallback(
    (type, response) => {
      if (type === shaka.net.NetworkingEngine.RequestType.MANIFEST) {
        const { headers } = response;
        const { hdntl, 'x-token': xToken } = headers;
        if (hdntl) longToken.current = hdntl;
        else if (xToken) {
          if (fastlyTokenRequestType === 'qs') {
            longToken.current = xToken;
          } else {
            headerXToken.current = xToken;
          }
        }
      }
    },
    [fastlyTokenRequestType],
  );

  const requestFilter = useCallback((type, request) => {
    const src = request.uris[0];

    if (type === shaka.net.NetworkingEngine.RequestType.MANIFEST) {
      const location = createFakeLocation(src);
      const query = queryStringToObject(location.search);
      const { use_pragma: pragmaQuery, origin: originQuery } = query;
      usePragma.current = !!pragmaQuery;
      origin.current = originQuery;
    }

    if (
      type === shaka.net.NetworkingEngine.RequestType.SEGMENT ||
      type === shaka.net.NetworkingEngine.RequestType.MANIFEST
    ) {
      if (usePragma.current) {
        request.headers.Pragma =
          'akamai-x-cache-on, akamai-x-cache-remote-on, akamai-x-check-cacheable, akamai-x-get-cache-key, akamai-x-get-true-cache-key, akamai-x-get-extracted-values, akamai-x-get-ssl-client-session-id, akamai-x-serial-no, akamai-x-get-request-id';
      }

      if (headerXToken.current) {
        request.headers['x-token'] = headerXToken.current;
      }
    }

    // Add origin and long token to segments
    if (
      type === shaka.net.NetworkingEngine.RequestType.SEGMENT ||
      (type === shaka.net.NetworkingEngine.RequestType.MANIFEST &&
        request.uris[0].includes('.m3u8'))
    ) {
      const queriesToAdd = [
        { key: 'hdntl', value: longToken.current },
        { key: 'origin', value: origin.current },
      ].filter(queryObj => Boolean(queryObj.value));

      queriesToAdd.forEach(({ key, value }, index) => {
        request.uris[0] += index === 0 && request.uris[0].indexOf('?') === -1 ? '?' : '&';
        request.uris[0] += `${key}=${value}`;
      });
    }
  }, []);

  useEffect(() => {
    if (!player) return () => {};
    const networkEngine = player.getNetworkingEngine();

    networkEngine.registerResponseFilter(responseFilter);
    networkEngine.registerRequestFilter(requestFilter);

    return () => {
      networkEngine.unregisterResponseFilter(responseFilter);
      networkEngine.unregisterRequestFilter(requestFilter);
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [player, responseFilter, requestFilter]);
};

export default useNetworkFilter;
