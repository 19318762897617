import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlaylists from './makeGetPlaylistsV2';
import makeGetPlaylistId from './makeGetPlaylistId';

const emptyObject = {};

const makeGetSelectedPlaylist = () => {
  const getPlaylists = makeGetPlaylists();
  const getPlaylistId = makeGetPlaylistId();
  return createSelector(
    [getPlaylists, getPlaylistId],
    (playlists, playlistId) => playlists[playlistId] ?? emptyObject,
  );
};

export const useGetSelectedPlaylist = props =>
  useMemoizedGqlSelector(makeGetSelectedPlaylist, props);

export default makeGetSelectedPlaylist;
