import { useCallback } from 'react';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';

const useIsValidEmoji = () => {
  const { allEmojis } = useChatContext() ?? {};
  return useCallback(
    (emoji: string) => {
      const foundValidEmoji = !!allEmojis?.find(
        ({ shortcode }) => `:${shortcode.toLowerCase()}:` === emoji.toLowerCase(),
      );

      return foundValidEmoji;
    },
    [allEmojis],
  );
};

export default useIsValidEmoji;
