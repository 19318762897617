import { useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import PingPlayer from 'tv-modules/Ping/PingPlayer/PingPlayer';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import { useGetStreamId } from 'tv-selectors/streams/makeGetStreamId';
import { useGetStreamSrcOffsetId } from 'tv-selectors/streams/makeGetStreamSrcOffsetId';
import { useGetGameMatchId } from 'tv-selectors/games/makeGetGameMatchId';
import useOffset from 'tv-hooks/useVideoOffset';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import { sizesShape } from 'tv-modules/DeadPlayerHandling/constants';
import { Container, Wrapper, TextBox, Text, KillBorder, Centering } from './KillCam.styles';

const KILL_CAM_BUFFER = 5;
const KILL_CAM_LENGTH = 7;

const KillCam = ({
  gameId,
  actorId,
  victimId,
  show = false,
  gameOffset = 0,
  onClipEnd,
  size = 'xsmall',
}) => {
  const matchId = useGetGameMatchId({ gameId });
  const streamId = useGetStreamId({ playerId: actorId ?? victimId, gameId });
  const offsetId = useGetStreamSrcOffsetId({ streamId });
  const offset = useOffset(matchId, gameId, offsetId);
  const statsOffset = useIntensiveSelector(state => state.gats?.[gameId]?.offset ?? 0);
  const src = useStreamSrcWithToken(streamId);

  const spring = useSpring({
    y: show ? 0 : -100,
  });

  const startTime = gameOffset + statsOffset - offset - KILL_CAM_BUFFER;
  const endTime = startTime + KILL_CAM_LENGTH;

  if (!show) return null;

  return (
    <Container>
      <Wrapper>
        <PingPlayer
          hideOverlay
          src={src}
          videoId={streamId}
          startTime={startTime}
          endTime={endTime}
          onClose={onClipEnd}
        />
        <Centering>
          <TextBox $size={size} style={spring}>
            <Text $size={size}>KillCam</Text>
          </TextBox>
        </Centering>
        <KillBorder />
      </Wrapper>
    </Container>
  );
};

KillCam.propTypes = {
  gameId: PropTypes.string.isRequired,
  actorId: PropTypes.string,
  victimId: PropTypes.string,
  gameOffset: PropTypes.number,
  show: PropTypes.bool,
  onClipEnd: PropTypes.func.isRequired,
  size: sizesShape,
};

export default KillCam;
