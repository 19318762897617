import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import useTheme from 'tv-hooks/useTheme';
import { Container, ArrowButton } from './StageSelector.styles';

const StageSelector = ({ stages, activeIndex = 0, onClickLeft, onClickRight }) => {
  const theme = useTheme();
  const arrowColor = theme.primaryTextColor;
  const handleClickLeft = useCallback(
    () => onClickLeft(activeIndex - 1),
    [activeIndex, onClickLeft],
  );
  const handleClickRight = useCallback(
    () => onClickRight(activeIndex + 1),
    [activeIndex, onClickRight],
  );

  return (
    <Container data-testid="stage-selector">
      <ArrowButton
        onClick={handleClickLeft}
        hide={activeIndex === 0}
        data-testid="left-arrow"
        aria-label="left-arrow"
      >
        <Icon type="chevronLeft" size={24} fillColor={arrowColor} />
      </ArrowButton>
      <Typography type="title-m">{stages[activeIndex]}</Typography>
      <ArrowButton
        onClick={handleClickRight}
        hide={activeIndex === stages.length - 1}
        data-testid="right-arrow"
        aria-label="right-arrow"
      >
        <Icon type="chevronRight" size={24} fillColor={arrowColor} />
      </ArrowButton>
    </Container>
  );
};

StageSelector.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
};

export default StageSelector;
