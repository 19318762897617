export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';

export const defaultOptions = {
  ssr: true,
  cacheTime: 30,
  heartbeat: null,
  persisted: true,
};
