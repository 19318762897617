import { useEffect } from 'react';
import screenfull from 'screenfull';

const useListenToFullscreenEvent = (ref, callback) => {
  useEffect(() => {
    const playerContainer = ref.current;
    if (!playerContainer) return () => {};
    const handleFullscreenChange = () => {
      const isPlayingInFullscreen = screenfull.isFullscreen;
      callback(isPlayingInFullscreen);
    };
    playerContainer.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      playerContainer.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [ref, callback]);
};

export default useListenToFullscreenEvent;
