import { schema } from 'normalizr';
import spell from './spell.normalizr';

const keys = ['id', 'name', 'image', 'passive', 'spells'];

const character = new schema.Entity(
  'characters',
  {
    spells: [spell],
    passive: spell,
  },
  {
    processStrategy: values =>
      keys.reduce((acc, key) => {
        const val = values[key];
        if (val) acc[key] = val;
        return acc;
      }, {}),
  },
);

export default character;
