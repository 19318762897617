import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import themes from './Header.themes';

import {
  Background,
  LogoWrapper,
  Wrapper,
  HeaderContainer,
  Title,
  StyledLink,
} from './Header.styles';

const Header = ({ title = '', backUrl = '', closePath }) => {
  const { packageSlug } = useParams();
  const navigate = useNavigate();
  const isTabletOrGreater = useGetIsTabletOrGreater();

  const onLogoClick = useCallback(() => {
    navigate(packageSlug ? `/${packageSlug}/home` : '/home');
  }, [packageSlug, navigate]);

  return (
    <Background>
      {isTabletOrGreater && (
        <LogoWrapper onClick={onLogoClick}>
          <Icon type="proViewPlainLogo" />
        </LogoWrapper>
      )}
      <Wrapper data-testid="settings-header">
        <HeaderContainer>
          {backUrl && (
            <StyledLink to={backUrl} data-testid="settings-backIcon">
              <Icon type="chevronLeft" size={24} inline />
            </StyledLink>
          )}
          {title && <Title data-testid="settings-title">{title}</Title>}
          <StyledLink to={closePath} data-testid="settings-closeIcon">
            <Icon type="close" size={24} inline />
          </StyledLink>
        </HeaderContainer>
      </Wrapper>
    </Background>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  backUrl: PropTypes.string,
  closePath: PropTypes.string.isRequired,
};

export default withTheme(memo(Header), themes, 'settingsPageHeader');
