import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

export const updateOutcomePrediction = (gameId, userId, prediction) => {
  firebase
    .database()
    .ref('predictions')
    .child(userId)
    .child(gameId)
    .child(prediction.id)
    .child('outcome')
    .update(prediction.outcome);
};

const setPrediction = (gameId, userId, prediction) => {
  firebase
    .database()
    .ref('predictions')
    .child(userId)
    .child(gameId)
    .child(prediction.id)
    .update(prediction);
};

export const predict = (prediction, predictedValue, userId, gameId) => {
  const predictionPayload = {
    ...prediction,
    predictedValue,
    outcome: 'pending',
  };

  setPrediction(gameId, userId, predictionPayload);
};
