import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'znipe-styles/animations';
import prefers from 'znipe-styles/prefers';
import {
  FADE_IN_DURATION,
  FADE_IN_CONTAINER_DURATION,
  FADE_OUT_CONTAINER_DURATION,
} from './OverlaySettings.timers';

export const menuItemHeight = 55;
export const paddingFromScreen = 10;

export const buttonBasicStyles = ({ theme }) => {
  const buttonStyle = theme.overlaySettings.button;
  return css`
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: ${buttonStyle.fontWeight};
    font-size: ${buttonStyle.fontSize};
    line-height: ${buttonStyle.lineHeight};
  `;
};

const menuContainerMaxHeight = `calc(100vh - ${paddingFromScreen * 2}px)`;

const fadeInAnimation = css`
  @media ${prefers.motion} {
    animation: ${fadeIn()} ${FADE_IN_DURATION}ms forwards;
    animation-delay: 0ms;
    animation-timing-function: ease-out;
  }
`;

export const Container = styled.div`
  width: 280px;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  cursor: pointer;
  ${({ visible }) =>
    visible
      ? css`
          animation: ${fadeIn()} ${FADE_IN_CONTAINER_DURATION}ms forwards;
        `
      : css`
          animation: ${fadeOut()} ${FADE_OUT_CONTAINER_DURATION}ms forwards;
        `}
  animation-delay: 0ms;
  animation-timing-function: ease-out;
`;

export const Menu = styled.div`
  height: ${({ height }) => `${height}px`};
  max-height: ${menuContainerMaxHeight};
  color: ${({ theme }) => theme.overlaySettings.textColorMenu};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.overlaySettings.bgColor};
  overflow: hidden;
  @media ${prefers.motion} {
    transition: height 0.1s ease-out;
  }
  ${({ isSubMenuOpen }) =>
    !isSubMenuOpen &&
    css`
      padding: ${`${paddingFromScreen}px 0`};
    `}
`;

export const MenuItem = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: ${`${menuItemHeight}px`};
  padding-left: 16px;
  padding-right: 10px;
  color: ${({ theme }) => theme.overlaySettings.textColorMenu};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.overlaySettings.bgColorHover};
  }
  ${buttonBasicStyles}
  ${fadeInAnimation}
`;

export const Label = styled.div`
  text-align: left;
  text-transform: uppercase;
`;

export const SelectedOption = styled.div`
  display: flex;
  min-width: 85px;
  align-items: center;
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const TextWrapper = styled.div`
  width: 76%;
  color: ${({ theme }) => theme.overlaySettings.textColorSelected};
`;

export const IconWrapper = styled.div`
  width: 24%;
  color: ${({ theme }) => theme.overlaySettings.textColorMenu};
`;
