import uniqueArray from 'znipe-utils/array/uniqueArray';
import { ACTION_TYPES, BANNED, MUTED, REMOVED, REPORTED } from 'znipe-chat/src/constants';

export const EMOJI_TAG_REGEX = /(:[\S^:]+?:)/gm;

export const generateTextToSpan = (text: string, serverMessageId: string) => {
  const { length } = text.split(' ');
  return text
    .split(' ')
    .map((t, index) => {
      if (t) {
        return (
          <span key={`${t}-${JSON.stringify(index)}-${serverMessageId}}`}>
            {t}
            {index < length - 1 ? '\u00A0' : ''}
          </span>
        );
      }
      return null;
    })
    .filter(Boolean);
};

export const stripHtmlFromMessage = (msg: string) =>
  msg.replace(/(<[^>]+>)/gm, '').replace(/&nbsp;/gm, ' ');

export const parseEmoji = (message: string) => {
  const htmlStrippedMessage = stripHtmlFromMessage(message.replace(/\s\s+/g, ' '));
  const emojisFound = htmlStrippedMessage.match(EMOJI_TAG_REGEX);

  if (!emojisFound) {
    return [];
  }
  return uniqueArray(emojisFound).map(emoji => emoji.split(':').join(''));
};

export const getActionHandledMessage = (
  action?: (typeof ACTION_TYPES)[number],
  deleted?: boolean,
  isModerator?: boolean,
) => {
  if (action === MUTED) return 'User muted';
  if (action === BANNED) return 'User banned';
  if (action === REMOVED || deleted) return 'This message was removed.';
  if (action === REPORTED && !isModerator) return 'You have reported this message.';
  return '';
};
