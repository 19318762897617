import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import GroupsTable, { lolColumns, csgoColumns } from 'tv-modules/Standings/GroupsTable/GroupsTable';
import { device } from 'znipe-styles/breakpoints';
import PropTypes from 'prop-types';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import useGqlSelector from 'tv-hooks/useGqlSelector';

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 16px;
  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

const emptyArray = [];

const GroupStage = ({ tournamentStageId = '', gameTitle = 'lol', selectedTeams = [] }) => {
  const tournamentSchedules = useGqlSelector(
    state => state.tournamentStages[tournamentStageId]?.schedules ?? emptyArray,
  );

  const columns = useMemo(() => (gameTitle === 'csgo' ? csgoColumns : lolColumns), [gameTitle]);
  const generateGroupTable = useCallback(
    ({ scheduleName, scheduleResults }) => (
      <GroupsTable
        columns={columns}
        key={scheduleName}
        results={scheduleResults}
        groupName={scheduleName}
        selectedTeams={selectedTeams}
      />
    ),
    [columns, selectedTeams],
  );

  if (!tournamentSchedules?.length) {
    return <NoResults type="standings" />;
  }

  return <Container>{tournamentSchedules.map(generateGroupTable)}</Container>;
};

GroupStage.propTypes = {
  tournamentStageId: PropTypes.string,
  gameTitle: PropTypes.string,
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default GroupStage;
