import produce from 'immer';
import { SET_PAGE } from 'tv-constants/redux';

export const initialState = {};

const pageReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_PAGE: {
      const { pageItems, options } = payload;
      const { pageType, reset = false, mergeData = false } = options;
      const partnerKey = Object.keys(pageItems)?.[0] || '';

      if (!partnerKey) return state;
      if (!state[pageType] || reset) state[pageType] = {};
      Object.entries(pageItems[partnerKey]).forEach(([key, value]) => {
        if (mergeData && key !== 'cursors') {
          state[pageType][key] = state[pageType][key] ? [...state[pageType][key], ...value] : value;
        } else {
          state[pageType][key] = value;
        }
      });
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default pageReducer;
