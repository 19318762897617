import { useCallback, useMemo, useEffect, useState } from 'react';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  ErrorText,
  Form,
  LinkTypography,
  LoadingButton,
  Options,
  Separator,
} from 'tv-modules/Authentication/Shared/Shared';
import Input from 'znipe-elements/data-entry/Input/Input';
import PasswordInput from 'tv-modules/Authentication/PasswordInput/PasswordInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  defaultError,
  ERROR,
  firebaseErrors,
  LOADING,
  SUCCESS,
} from 'tv-modules/Authentication/constants';
import GoogleAuthButton from 'tv-modules/Authentication/GoogleAuthButton/GoogleAuthButton';
import setPersistentState from 'tv-modules/Authentication/utils/setPersistentState';
import firebase from 'firebase/app';
import { setUserName } from 'tv-utils/firebaseAuthHelper';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import logger from 'znipe-logger';

const RegisterRoute = ({ state, setState, setUserEmail }) => {
  const [error, setError] = useState({});
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      setFocus('email');
    } catch (_e) {
      logger.error('Failed to focus');
    }
  }, [setFocus]);

  const signUp = useCallback(
    data => {
      if (state === LOADING) return;
      setState(LOADING);

      const { email, password, username } = data;
      setPersistentState(true)
        .then(() => firebase.auth().createUserWithEmailAndPassword(email, password))
        .then(({ user }) => {
          setUserEmail(email);
          return Promise.all([
            user.updateProfile({ displayName: username }),
            setUserName(user, username),
            user.sendEmailVerification(),
          ]);
        })
        .then(() => setState(SUCCESS))
        .then(() => navigate('success'))
        .catch(({ code }) => {
          setState(ERROR);
          setError(firebaseErrors[code] ?? {});
        });
    },
    [navigate, setState, setUserEmail, state],
  );
  const login = useMemo(() => pathname.replace('/register', ''), [pathname]);
  return (
    <>
      <Typography type="heading-m">Sign up</Typography>
      <Form onSubmit={handleSubmit(signUp)}>
        <Input
          {...register('email', { required: true })}
          data-testid="email-input"
          errorMessage={error.message}
          error={error.type === 'email' || !!errors.email}
          isInline
          inputType="email"
          label="Email"
        />
        <Input
          {...register('username', { required: true })}
          data-testid="username-input"
          errorMessage={error.message}
          error={error.type === 'username' || !!errors.username}
          isInline
          inputType="text"
          label="Username"
        />
        <PasswordInput
          {...register('password', { required: true, minLength: 6 })}
          data-testid="password-input"
          errorMessage={error.message}
          error={error.type === 'password' || !!errors.password}
          showRestrictionsInfo
        />
        <LinkTypography dataTestId="tos">
          By signing up you are agreeing to Znipe&apos;s
          <Link to="/privacy" rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </Link>
          and
          <Link to="/terms-of-use" rel="noopener noreferrer" target="_blank">
            Terms and Conditions
          </Link>
        </LinkTypography>
        {state === ERROR && !error.type && <ErrorText type="paragraph-s">{defaultError}</ErrorText>}
        <Options>
          <LoadingButton type="submit" loading={state === LOADING}>
            Sign Up
          </LoadingButton>
          <Separator />
          <GoogleAuthButton onStateChange={setState} state={state} persistent />
          <LinkTypography $center>
            Already have an account? <Link to={login}>Log in</Link>
          </LinkTypography>
        </Options>
      </Form>
    </>
  );
};

RegisterRoute.propTypes = {
  state: PropTypes.oneOf([SUCCESS, LOADING, ERROR, '']).isRequired,
  setState: PropTypes.func.isRequired,
  setUserEmail: PropTypes.func.isRequired,
};

export default RegisterRoute;
