import produce from 'immer';
import { SET_GLOBAL_STREAMS } from 'tv-constants/redux';

export const initialState = {};

const matchPreviewReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_STREAMS: {
      Object.entries(payload)
        .filter(([, item]) => item.type === 'event' && item.gameNumber === 1)
        .forEach(([, item]) => {
          state[item.matchId] = item.streamId;
        });
      return state;
    }

    default:
      return state;
  }
}, initialState);

export default matchPreviewReducer;
