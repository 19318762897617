import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import CoverOverlay from 'tv-modules/Onboarding/Overlay/Overlay';

const MOBILE_HEADER_MARGIN = '16px';
const TABLET_HEADER_MARGIN = '36px';
const DESKTOP_HEADER_MARGIN = '24px';
const DESKTOP_LARGE_HEADER_MARGIN = '36px';

export const HeaderWrapper = styled.div`
  margin-bottom: 8px;
  height: ${({ $authenticated }) => ($authenticated ? '112px' : '40px')};
  @media ${device.desktop} {
    > div {
      position: fixed;
      z-index: 3;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const Container = styled.div`
  height: 100%;
  ${Scrollbar};
  overflow-y: ${({ $hideScrollBar }) => ($hideScrollBar ? 'hidden' : 'auto')};
  overflow-x: hidden;
  ${CoverOverlay} {
    margin-top: -${MOBILE_HEADER_MARGIN};
    min-height: calc(100% + ${MOBILE_HEADER_MARGIN});
    @media ${device.tablet} {
      margin-top: -${TABLET_HEADER_MARGIN};
      min-height: calc(100% + ${TABLET_HEADER_MARGIN});
    }
    @media ${device.desktop} {
      margin-top: -${DESKTOP_HEADER_MARGIN};
      min-height: calc(100% + ${DESKTOP_HEADER_MARGIN});
    }
    @media ${device.desktopLarge} {
      margin-top: -${DESKTOP_LARGE_HEADER_MARGIN};
      min-height: calc(100% + ${DESKTOP_LARGE_HEADER_MARGIN});
    }
  }
`;

export const NoAuthPredictionWrapper = styled.div`
  padding: 20px 16px 16px 16px;
`;
