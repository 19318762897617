import { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import Typography from 'znipe-elements/general/Typography/Typography';
import RepeatingTextBackground from 'tv-elements/data-display/RepeatingTextBackground/RepeatingTextBackground';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import useColorThief from 'znipe-hooks/useColorThief';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import CardLoading from 'tv-elements/feedback/CardLoading/CardLoading';
import { LOGO } from 'tv-elements/feedback/CardLoading/CardLoading.constants';
import { XSMALL, SMALL, MEDIUM, animationDuration } from './constants';
import {
  Container,
  ContentWrapper,
  TextWrapper,
  ImageWrapper,
  BackgroundLayer,
  RepeatingTextLayer,
} from './LogoCard.styles';

const AnimatedBackground = animated(BackgroundLayer);

const LogoCard = ({ text, imageSrc, size = SMALL, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const backgroundGradient = useColorThief(imageSrc);
  const { hasLoaded } = useOnImageLoad(imageSrc);

  const textTranslateX = -(text?.length ?? 0) * (isHovered ? 8 : 20);

  const animationBackground = useSpring({
    height: isHovered ? '100%' : '0%',
    config: { tension: animationDuration, friction: 60 },
    immediate: prefersReducedMotion,
  });

  const animationRepeatingText = useSpring({
    transform: `skew(-20deg) translateX(${textTranslateX}px)`,
    config: { tension: animationDuration, friction: 60 },
    immediate: prefersReducedMotion,
  });

  const elementSize = useMemo(() => {
    if (size === MEDIUM) {
      return { text: 'heading-l', logo: 128 };
    }
    if (size === SMALL) {
      return { text: 'heading-m', logo: 96 };
    }
    return { text: 'heading-s', logo: 72 };
  }, [size]);

  const handleClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  const showFullComponent = useLoadingDelay(hasLoaded);

  if (!showFullComponent) {
    return <CardLoading size={size} type={LOGO} />;
  }

  return (
    <Container
      size={size}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="logo-card-container"
    >
      <ContentWrapper size={size}>
        <TextWrapper size={size} data-testid="text-wrapper">
          <Typography type={elementSize.text}>{text}</Typography>
        </TextWrapper>
        <ImageWrapper data-testid="image-wrapper">
          <img src={imageSrc} alt={text} width={elementSize.logo} height={elementSize.logo} />
        </ImageWrapper>
      </ContentWrapper>
      {isHovered && (
        <>
          <RepeatingTextLayer>
            <RepeatingTextBackground
              text={text}
              textSize={size}
              animation={animationRepeatingText}
            />
          </RepeatingTextLayer>
          <AnimatedBackground style={animationBackground} gradient={backgroundGradient} />
        </>
      )}
    </Container>
  );
};

LogoCard.propTypes = {
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM]),
  onClick: PropTypes.func,
};

export default memo(LogoCard);
