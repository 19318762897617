import PauseMarker from '../PauseMarker/PauseMarker';

const createPauseMarker = (timeStart, timeEnd, offset, id) => (
  <PauseMarker
    key={`pauseMarker-${id}`}
    timeStart={timeStart}
    timeEnd={timeEnd}
    offset={offset}
    id={id}
  />
);

export default createPauseMarker;
