import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import useDeadPlayerInfo from 'tv-modules/DeadPlayerHandling/hooks/useDeadPlayerInfo';
import DeadPlayerInfo from 'tv-modules/DeadPlayerHandling/DeadPlayerInfo/DeadPlayerInfo';
import KillCam from 'tv-modules/DeadPlayerHandling/KillCam/KillCam';
import StatsInfo from 'tv-modules/DeadPlayerHandling/StatsInfo/StatsInfo';
import { sizesShape } from 'tv-modules/DeadPlayerHandling/constants';
import PropTypes from 'prop-types';

const INFO_STATE = 'INFO';
const KILL_CAM_STATE = 'KILL_CAM';
const STATS_STATE = 'STATS';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({ $show }) => ($show ? 'rgba(0, 0, 0, 0.8)' : 'transparent')};
`;

const DeadPlayerHandling = ({ gameId, playerId, size = 'xsmall' }) => {
  const stateDelay = useRef(null);
  const deadPlayerInfo = useDeadPlayerInfo(gameId, playerId);
  const [storedInfo, setStoredInfo] = useState(deadPlayerInfo);

  const { victimId, actorId, weapon, gameOffset } = storedInfo ?? {};

  const [state, setState] = useState(null);

  const onStateChange = useCallback(() => {
    clearTimeout(stateDelay.current);
    if (!deadPlayerInfo) {
      setState(null);
      setStoredInfo(undefined);
    } else if (state === KILL_CAM_STATE) {
      setState(STATS_STATE);
    } else if (state === INFO_STATE) {
      stateDelay.current = setTimeout(() => setState(KILL_CAM_STATE), 1500);
    }
  }, [deadPlayerInfo, state]);

  useEffect(() => {
    const reset = () => {
      setState(null);
      setStoredInfo(undefined);
    };
    if (state !== KILL_CAM_STATE && !deadPlayerInfo) reset();

    if (!deadPlayerInfo) {
      clearTimeout(stateDelay.current);
    }

    return () => {
      clearTimeout(stateDelay.current);
    };
  }, [deadPlayerInfo, state]);

  useEffect(() => {
    if (!state) setStoredInfo(undefined);
  }, [state]);

  useEffect(() => {
    if (!deadPlayerInfo) return;
    setStoredInfo(deadPlayerInfo);
    setState(INFO_STATE);
  }, [deadPlayerInfo]);

  useEffect(() => () => clearTimeout(stateDelay.current), []);

  return (
    <Container $show={!!storedInfo}>
      <DeadPlayerInfo
        gameId={gameId}
        victimId={victimId}
        actorId={actorId}
        weapon={weapon}
        show={state === INFO_STATE}
        onAnimationEnd={onStateChange}
        size={size}
      />
      <StatsInfo
        gameId={gameId}
        victimId={victimId}
        actorId={actorId}
        weapon={weapon}
        show={state === STATS_STATE}
        size={size}
      />
      <KillCam
        gameId={gameId}
        victimId={victimId}
        actorId={actorId}
        gameOffset={gameOffset}
        show={state === KILL_CAM_STATE}
        onClipEnd={onStateChange}
        size={size}
      />
    </Container>
  );
};

DeadPlayerHandling.propTypes = {
  size: sizesShape,
  gameId: PropTypes.string.isRequired,
  playerId: PropTypes.string,
};

export default DeadPlayerHandling;
