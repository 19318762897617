import { schema } from 'normalizr';
import {
  TOTAL_SELF_MITIGATED_DAMAGE,
  TOTAL_DAMAGE_TO_CHAMPIONS,
  MINIONS_KILLED,
  NEUTRAL_MINIONS_KILLED,
  TOTAL_DAMAGE,
  VISION_SCORE,
  WARD_KILLED,
  WARD_PLACED,
} from 'tv-modules/Stats/LOL/constants';
import { removeUndefined } from 'tv-schema/rootSchema.normalizr';
import stat from './stat.normalizr';
import { createPlayerObject } from './player.normalizr';
import { createTeamObject } from './team.normalizr';
import monsterKill from './monsterKill.normalizr';
import kill from './kill.normalizr';
import structureDestroyed from './structureDestroyed.normalizr';

const teamKeys = [
  TOTAL_DAMAGE_TO_CHAMPIONS,
  'assists',
  'baronKills',
  'deaths',
  'dragonKills',
  'gold',
  'inhibKills',
  'kills',
  'towerKills',
];

const createTeamStatsObject = (gameId, gameTime, teams) =>
  teams?.reduce((acc, currentTeam) => {
    const id = `${gameId}:${gameTime}:${currentTeam.id}`;
    return {
      ...acc,
      [id]: {
        id,
        ...teamKeys
          .filter(key => key in currentTeam)
          .reduce((teamData, key) => ({ ...teamData, [key]: currentTeam[key] }), {}),
        gameTime,
        team: createTeamObject(currentTeam),
      },
    };
  }, {});

const playerKeys = [
  MINIONS_KILLED,
  NEUTRAL_MINIONS_KILLED,
  TOTAL_DAMAGE,
  TOTAL_DAMAGE_TO_CHAMPIONS,
  TOTAL_SELF_MITIGATED_DAMAGE,
  VISION_SCORE,
  WARD_KILLED,
  WARD_PLACED,
  'abilityPower',
  'armor',
  'armorPenetration',
  'armorPenetrationPercent',
  'assists',
  'attackDamage',
  'attackSpeed',
  'autoAttackRange',
  'ccReduction',
  'character',
  'cooldownReduction',
  'criticalChance',
  'deaths',
  'gold',
  'healthMax',
  'healthRegen',
  'items',
  'kills',
  'level',
  'lifeSteal',
  'magicPenetration',
  'magicPenetrationPercent',
  'magicResist',
  'primaryAbilityResourceMax',
  'primaryAbilityResourceRegen',
  'role',
  'spell1',
  'spell1Cooldown',
  'spell2',
  'spell2Cooldown',
  'spellVamp',
];

const createPlayerStatsObject = (gameId, gameTime, players) =>
  players?.reduce((acc, currentPlayer) => {
    const id = `${gameId}:${gameTime}:${currentPlayer.id}`;
    return {
      ...acc,
      [id]: {
        id,
        ...playerKeys
          .filter(key => key in currentPlayer)
          .reduce((playerData, key) => ({ ...playerData, [key]: currentPlayer[key] }), {}),
        gameTime,
        player: createPlayerObject(currentPlayer),
      },
    };
  }, {});

const timeSeriesEntry = new schema.Entity(
  'timeSeriesEntries',
  {
    teamStats: [stat],
    playerStats: [stat],
    monsterKills: [monsterKill],
    structuresDestroyed: [structureDestroyed],
    kills: [kill],
  },
  {
    processStrategy: values => {
      const { gameId, id, gameTime, monsterKills, kills, structuresDestroyed } = values;
      const newValues = {
        id,
        gameTime,
        teamStats: createTeamStatsObject(gameId, gameTime, values.teams),
        playerStats: createPlayerStatsObject(gameId, gameTime, values.players),
        monsterKills,
        structuresDestroyed,
        kills,
      };

      return removeUndefined(newValues);
    },
  },
);

export default timeSeriesEntry;
