import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ProductionStatusLabel from 'znipe-elements/data-display/ProductionStatusLabel/ProductionStatusLabel';
import { useMatchTeamsLogos } from 'tv-selectors/match/makeGetMatchTeamsLogos';
import { useGetMatchTeamsShortNames } from 'tv-selectors/match/makeGetMatchTeamsShortNames';
import { useGetMatchBestOf } from 'tv-selectors/match/makeGetMatchBestOf';
// import { useGetTournament } from 'tv-selectors/tournaments/makeGetTournament';
import TeamStandings from 'tv-modules/TeamStandings/TeamStandings';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import Link from 'znipe-elements/general/Link/Link';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import useMatchLink from 'tv-hooks/useMatchLink';
import {
  Container,
  StatusLabelWrapper,
  TeamsInfoWrapper,
  TeamSection,
  LinksWrapper,
  DescriptionWrapper,
  TeamImageWrapper,
  TeamImage,
} from './FeaturedMatchDetails.styles';

const FeaturedMatchDetails = ({ matchId, size = 'small', statusLabel = '' }) => {
  const matchLink = useMatchLink(matchId);
  const matchLinkFromStart = useMatchLink(matchId, true);
  const [team1LogoSrc = '', team2LogoSrc = ''] = useMatchTeamsLogos({ matchId });
  const [teamOneName, teamTwoName] = useGetMatchTeamsShortNames({ matchId });
  const matchBestOf = useGetMatchBestOf({ matchId });
  // const tournamentData = useGetTournament({ matchId });
  // const tournamentStage = tournamentData.tournamentStage || 'Tour. Stage';
  const selectedStatusType = useMemo(() => {
    if (statusLabel.indexOf('live') !== -1) return 'live';
    return statusLabel;
  }, [statusLabel]);

  const elementSizes = useMemo(() => {
    if (size === 'small') {
      return {
        statusLabelSize: 'small',
        teamStandingSize: 'large',
        descriptionTypographySize: 'm',
        buttonSize: 'small',
        linkTextTypographySize: 'xs',
      };
    }
    return {
      statusLabelSize: 'large',
      teamStandingSize: 'xlarge',
      descriptionTypographySize: 'm',
      buttonSize: 'medium',
      linkTextTypographySize: 'm',
    };
  }, [size]);

  const {
    statusLabelSize,
    teamStandingSize,
    descriptionTypographySize,
    buttonSize,
    linkTextTypographySize,
  } = elementSizes;
  const showTeamsBackgrounds = size === 'medium';
  return (
    <Container
      data-testid="featured-match-details"
      size={size}
      showTeamsBackgrounds={showTeamsBackgrounds}
    >
      {statusLabel && (
        <StatusLabelWrapper>
          <ProductionStatusLabel size={statusLabelSize} label={selectedStatusType} />
        </StatusLabelWrapper>
      )}
      <TeamSection
        data-testid="featured-match-details-teams"
        showTeamsBackgrounds={showTeamsBackgrounds}
      >
        {showTeamsBackgrounds && team1LogoSrc && (
          <TeamImageWrapper position="left">
            <TeamImage src={team1LogoSrc} />
          </TeamImageWrapper>
        )}
        <TeamsInfoWrapper>
          <TeamStandings
            size={teamStandingSize}
            teamOneLogo={team1LogoSrc}
            teamOneName={teamOneName}
            teamTwoLogo={team2LogoSrc}
            teamTwoName={teamTwoName}
            isLive={selectedStatusType === 'live'}
            matchMissingScore
            hideScore
          />
          <DescriptionWrapper>
            <Typography
              type={`paragraph-${descriptionTypographySize}`}
            >{`Best Of ${matchBestOf}`}</Typography>
          </DescriptionWrapper>
        </TeamsInfoWrapper>
        {showTeamsBackgrounds && team2LogoSrc && (
          <TeamImageWrapper position="right">
            <TeamImage src={team2LogoSrc} />
          </TeamImageWrapper>
        )}
      </TeamSection>
      <LinksWrapper>
        <Button variant="feature" to={matchLink} size={buttonSize} icon="streamFilled">
          {selectedStatusType === 'live' ? 'Watch live' : 'Watch replay'}
        </Button>
        {selectedStatusType === 'live' && (
          <Link to={matchLinkFromStart} withIcon>
            <Typography type={`title-${linkTextTypographySize}`}>or watch from start</Typography>
            <Icon type="arrowRight" size={14} />
          </Link>
        )}
      </LinksWrapper>
    </Container>
  );
};

FeaturedMatchDetails.propTypes = {
  matchId: PropTypes.string.isRequired,
  statusLabel: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default FeaturedMatchDetails;
