import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};

const getState = state => state;

const makeGetEvents = () => createSelector([getState], state => state.events ?? emptyObject);

export const useGetEvents = () => useMemoizedGqlSelector(makeGetEvents);

export default makeGetEvents;
