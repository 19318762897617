import styled from 'styled-components';
import { animated } from 'react-spring';
import type { OverlayType } from './Overlay.types';

export const Overlay = styled(animated.div)<OverlayType>`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  z-index: ${({ $topMost }) => ($topMost ? '999999999' : 'inherit')};
`;

export const PlainOverlay = styled.div`
  height: 100%;
  width: 100%;
`;

export const OverlayContent = styled(animated.div)<OverlayType>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: top left;
  z-index: ${({ $topMost }) => ($topMost ? '999999999' : 'inherit')};
`;
