const FONT_FAMILY_HEADING = "'Barlow', sans-serif";
const FONT_FAMILY_PARAGRAPH = FONT_FAMILY_HEADING;
const FONT_FAMILY_TITLE = FONT_FAMILY_HEADING;

export default {
  fonts: {
    preloads: [
      'https://assets.znipe.tv/fonts/Barlow/Barlow-Regular.woff2',
      'https://assets.znipe.tv/fonts/Barlow/Barlow-Regular-Italic.woff2',
      'https://assets.znipe.tv/fonts/Barlow/Barlow-SemiBold.woff2',
    ],
    fontFaces: [
      'https://assets.znipe.tv/fonts/Barlow/Barlow.css',
      'https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap',
    ],
  },
  heading: {
    xl: {
      fontSize: '40px',
      lineHeight: '56px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    l: {
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    m: {
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    s: {
      fontSize: '18px',
      lineHeight: '32px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    xs: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
  },
  paragraph: {
    l: {
      fontSize: '18px',
      lineHeight: '28px',
      letterSpacing: '0.02em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
    m: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
    s: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
  },
  title: {
    xxl: {
      fontSize: '39px',
      lineHeight: '56px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    xl: {
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    l: {
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    m: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    s: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.07em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    xs: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.07em',
      fontFamily: FONT_FAMILY_TITLE,
    },
  },
};
