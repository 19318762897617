import styled from 'styled-components';

const Headline = styled.h1`
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  transform: skewX(-0.43rad);
  margin-left: 8px;
  /* stylelint-disable property-no-vendor-prefix */
  background: ${({ theme }) => theme.onboardingGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px transparent;
`;

export default Headline;
