import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import { BackgroundLayer } from '../Shared.styles';

export const List = styled.div`
  width: 100%;
`;

export const Row = styled.div.attrs(() => ({
  type: 'button',
}))`
  ${buttonDefaultStyle}
  position: relative;
  width: 100%;
  padding: 8px;
  @media ${device.desktop} {
    padding: 8px 16px;
    &:focus,
    &:hover {
      cursor: pointer;
      ${BackgroundLayer} {
        opacity: 10%;
      }
    }
  }
`;
