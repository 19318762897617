import { schema } from 'normalizr';
import player from './player.normalizr';
import team from './team.normalizr';

const loadout = new schema.Entity('loadouts', {
  player,
  team,
});

export default loadout;
