import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from 'tv-config/config';

const isProductionEnv = process.env.NODE_ENV === 'production';

const setupSentry = app => {
  if (isProductionEnv) {
    Sentry.init({
      dsn: config.SENTRY_DSN,
      release: config.VERSION,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      // We don't want to send all events in prod as that's to much data
      tracesSampleRate: config.ENVIRONMENT === 'production' ? 0.3 : 1.0, // @TODO Tweek these
      environment: config.ENVIRONMENT,
      // maxBreadcrumbs: 50, // Amount of lines in log coming up to error. Default to 100. @TODO Tweek
      // tunnel: ..., // If we need a proxy for events to bypass add blockers
      replaysSessionSampleRate: config.ENVIRONMENT === 'production' ? 0.05 : 1.0,
      replaysOnErrorSampleRate: 1.0,
      denyUrls: [
        /znipe-video\.akamaized\.net/,
        /rex\.rewards\.lolesports/,
        /accounts\.rewards\.lolesports/,
        /osano\.com/,
        /www\.paypalobjects\.com/,
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      ignoreErrors: [
        'AbortError: Fetch is aborted', // When a request is aborted in safari
        'The network connection was lost.', // Usually mobile users losing conenction
        'ResizeObserver loop limit exceeded', // https://stackoverflow.com/a/50387233/2319925
        // Fetch errors that are thrown when things are usually OK
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: cancelled',
        // False errors from old browsers
        'AbortController is not defined',
        'Error: AbortError: The operation was aborted. ',
      ],
    });
  }
  const App = isProductionEnv ? Sentry.withProfiler(app) : app;
  const ErrorBoundary = isProductionEnv ? Sentry.ErrorBoundary : require('./ErrorBoundry').default; // eslint-disable-line global-require

  return { App, ErrorBoundary };
};

export default setupSentry;
