import { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { GridContainer, ColumnHeader, GridCell, Label } from './GridTable.styles';

const GridTable = ({
  columns,
  data,
  headerHeight = 29,
  borderType = 'horizontal',
  withLabels = false,
  fluidFontSizes = false,
  showGridLines = true,
}) => {
  const columnHeader = useMemo(
    () =>
      columns.map(column => (
        <ColumnHeader key={column} data-testid="header">
          {column}
        </ColumnHeader>
      )),
    [columns],
  );

  const getGridCell = useCallback(
    (cell, cellIndex, row, rowIndex) => {
      let cellData = cell;
      const isLastRow = rowIndex === data.length - 1;
      const isLastCell = cellIndex === row.length - 1;
      const shouldShowBorder = borderType === 'vertical' ? !isLastCell : !isLastRow;
      const showBorder = showGridLines ? shouldShowBorder : showGridLines;

      const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (typeof cell === 'number') {
        cellData = numberWithCommas(cell);
      }

      return (
        <GridCell
          // eslint-disable-next-line react/no-array-index-key
          key={cellIndex}
          showBorder={showBorder}
          borderType={borderType}
          data-testid={`cell-${rowIndex}-${cellIndex}`}
          fluidFontSizes={fluidFontSizes}
        >
          <div data-testid={`${columns?.[cellIndex]}-value`}>{cellData}</div>
          {withLabels && (
            <Label data-testid={`${columns?.[cellIndex]}-label`} fluidFontSizes={fluidFontSizes}>
              {columns?.[cellIndex]}
            </Label>
          )}
        </GridCell>
      );
    },
    [showGridLines, withLabels, borderType, columns, data.length, fluidFontSizes],
  );

  const gridRows = useMemo(
    () =>
      data?.map((row, rowIndex) =>
        row.map((cell, cellIndex) => getGridCell(cell, cellIndex, row, rowIndex)),
      ),
    [data, getGridCell],
  );

  return (
    <GridContainer
      columns={columns}
      headerHeight={headerHeight}
      withLabels={withLabels}
      widerGap={!showGridLines}
    >
      {!withLabels && columnHeader}
      {gridRows}
    </GridContainer>
  );
};

GridTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string.isRequired),
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ),
  headerHeight: PropTypes.number,
  borderType: PropTypes.oneOf(['horizontal', 'vertical']),
  withLabels: PropTypes.bool,
  fluidFontSizes: PropTypes.bool,
  showGridLines: PropTypes.bool,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(GridTable, areEqual);
