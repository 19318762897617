import { useCallback, useMemo, useRef } from 'react';
import { createSelector } from 'reselect';
import isEqual from 'lodash.isequal';
import { DEAD_PLAYER_HANDLING, useCSGOStatsEpic } from 'tv-epics/csgo/csgoStatsEpic';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';

const KILL_TYPE = 'KILL';
const emptyObject = {};
const emptyArray = [];

const getGatEventIs = ({ gats }, { gatId }) => gats[gatId]?.eventFeed ?? emptyArray;
const getAllEvents = ({ eventFeed }) => eventFeed ?? emptyObject;
const getEvents = createSelector([getAllEvents, getGatEventIs], (events, ids) =>
  ids.map(id => events[id] ?? emptyObject),
);

const useDeadPlayerInfo = (gameId, playerId) => {
  const lastKill = useRef(null);

  const getRoundNumber = useCallback(
    state => {
      const { currentRound, currentRoundNumber } = state.gats[gameId] ?? emptyObject;
      return currentRoundNumber ?? state.rounds[currentRound]?.roundNumber ?? 1;
    },
    [gameId],
  );

  const killSelector = useMemo(
    () =>
      createSelector([getRoundNumber, getEvents], (roundNumber, events) =>
        Object.values(events).find(event => {
          if (event.type !== KILL_TYPE) return false;
          if (event.roundNumber !== roundNumber) return false;
          return event.victimId === playerId;
        }),
      ),
    [getRoundNumber, playerId],
  );

  const currentRoundNumber = useIntensiveSelector(getRoundNumber);
  const killInfo = useIntensiveSelector(state => {
    const kill = killSelector(state, { gatId: gameId });

    if (!kill) return undefined;

    const { gameTime = 0 } = state.gats[gameId] ?? emptyObject;

    return gameTime > kill.gameOffset ? kill : undefined;
  });

  const variables = useMemo(
    () => ({
      fromRound: currentRoundNumber,
    }),
    [currentRoundNumber],
  );
  useCSGOStatsEpic(gameId, DEAD_PLAYER_HANDLING, variables);

  if (!isEqual(lastKill.current, killInfo)) {
    lastKill.current = killInfo;
  }

  return lastKill.current;
};

export default useDeadPlayerInfo;
