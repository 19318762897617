import styled, { css } from 'styled-components';
import { ZERO, MINIMIZED } from './SeekBar.constants';

export const Tooltip = styled.div`
  display: none;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: 30px;
  cursor: pointer;
  overflow: hidden;
  color: ${({ theme }) => theme.seekBar.tooltipText};
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  padding: 5px;
  position: absolute;
  left: 0;
  transform: translate(-50%, -130%);
  background: ${({ theme }) => theme.seekBar.tooltipBackground};
  border-radius: 5px;
  ${({ $isActive }) =>
    $isActive &&
    css`
      display: flex;
    `};
`;

export const LoadingWrapper = styled.span`
  position: relative;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  width: 0;
  transition: width 0.2s ease-out;
  ${({ isActive }) =>
    isActive &&
    css`
      margin-left: 5px;
      margin-right: -5px;
      width: 25px;
    `};
  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

export const Bar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.UIAccent};
  display: flex;
  align-items: center;
  height: 100%;
`;

export const BufferBar = styled(Bar)`
  background: ${({ theme }) => theme.seekBar.bufferColor};
`;

export const HoverBar = styled(Bar)`
  background: ${({ theme }) => theme.seekBar.hoverColor};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
`;

export const Track = styled(Bar)`
  transform: translateX(0%);
  background: ${({ theme }) => theme.seekBar.trackColor};
`;

export const Handle = styled.div`
  height: ${ZERO};
  width: ${ZERO};
  border-radius: 50%;
  background: ${({ theme }) => theme.UIAccent};
`;

export const Input = styled.input`
  height: 15px;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  left: 0;
  top: -50%;
  width: 100%;
  opacity: 0;
  margin: 0;
  &:hover,
  &:focus {
    cursor: pointer;
  }

  &::-moz-range-thumb {
    border: none;
    border-radius: 0;
    width: 0;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
  }
`;

export const Slider = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  height: 12px;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 10px;
    `};

  ${({ isMobile, isFullscreen }) =>
    isMobile &&
    !isFullscreen &&
    css`
      margin-bottom: -5px;
    `}
`;

export const Bars = styled.div`
  overflow: hidden;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
  height: ${MINIMIZED};
`;
