import colors from 'znipe-styles/colors';

export default {
  proview: {
    freeMonthsInfoColor: colors.riotTextSecondary,
  },
  default: {
    freeMonthsInfoColor: colors.riotTextSecondary,
  },
};
