import { memo } from 'react';
import PropTypes from 'prop-types';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';

const ChatButton = ({ onClick, active, size = 'medium' }) => (
  <NavButton
    id="stage-chat-button"
    label="Chat"
    iconType="chat"
    onClick={onClick}
    isToggledOn={active}
    size={size}
    showLabel
  />
);

ChatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['medium', 'large']),
};

export default memo(ChatButton);
