import { SET_TEAMS, SET_SINGLE_TEAM } from 'tv-constants/redux';

export const setTeams = (teams = {}) => ({
  type: SET_TEAMS,
  payload: teams,
});

export const setSingleTeam = (team = {}) => ({
  type: SET_SINGLE_TEAM,
  payload: team,
});
