import { forwardRef, ElementType } from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';

export const sanitizeHTML = (html: string) =>
  (dompurify?.sanitize && dompurify?.sanitize(html)) ?? '';

type ComponentProps = {
  HTMLString: string;
};
const withDangerouslySetInnerHTML = (ComposedComponent: ElementType) => {
  const DangerouslySetInnerHTML = forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {
    const { HTMLString, ...passedProps } = props;
    return (
      <ComposedComponent
        ref={ref}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: It is sanitized
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(HTMLString),
        }}
        {...passedProps}
      />
    );
  });
  DangerouslySetInnerHTML.propTypes = {
    HTMLString: PropTypes.string.isRequired,
  };
  return DangerouslySetInnerHTML;
};

export default withDangerouslySetInnerHTML;
