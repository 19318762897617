import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { Container, InnerContainer, Image } from './Avatar.styles';

const Avatar = ({ imageUrl = '', icon = '' }) => (
  <Container>
    <InnerContainer>
      {icon ? (
        <Icon type={icon} />
      ) : (
        <Image data-testid="avatar-image" alt="avatar image" src={imageUrl} />
      )}
    </InnerContainer>
  </Container>
);

Avatar.propTypes = {
  icon: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default memo(Avatar);
