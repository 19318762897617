import styled, { css } from 'styled-components';
import isIE from 'znipe-utils/web/isIE';

const bluredGradientStyles = css`
  background: linear-gradient(
    ${({ $reversedGradient }) => ($reversedGradient ? '180deg' : '0deg')},
    ${({ theme }) => theme.primaryBackgroundColor} 0%,
    rgba(11, 13, 14, 0.9) 46.81%,
    rgba(11, 13, 14, 0.65) 99.23%
  );
  backdrop-filter: blur(4px);
`;

const GradientWithoutStyles = css`
  background: linear-gradient(
    ${({ $reversedGradient }) => ($reversedGradient ? '180deg' : '0deg')},
    ${({ theme }) => theme.primaryBackgroundColor} 0%,
    rgba(11, 13, 14, 0.9) 46.81%,
    rgba(11, 13, 14, 0.95) 99.23%
  );
`;

const Overlay = styled.div`
  ${isIE() ? GradientWithoutStyles : bluredGradientStyles}
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: auto;
  ${({ $top }) => css`
    top: ${$top}px;
  `}
  ${({ $padding }) => css`
    padding: ${$padding}px;
  `}
`;

export default Overlay;
