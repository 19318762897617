import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import gradients from 'znipe-styles/gradients';
import {
  PredictionsHeaderWrapper,
  OverflowHidden,
  GradientWrapper,
  IconWrapper,
  ScoreWrapper,
  WinBackground,
  WinLabel,
  LoseLabel,
} from './PredictionHeader.styles';

const localtheme = {
  ubisoft: {
    lossBackground: 'radial-gradient(49.92% 92.61% at 50% 100%, #162A46 2.08%, #0C1117 100%);',
    winBackground:
      'linear-gradient(90deg, rgba(210, 162, 86, 0.2) 0%, rgba(244, 223, 173, 0.2) 100%);',
    color: gradients.glimmeringGold,
  },
  proview: {
    lossBackground: 'radial-gradient(49.92% 92.61% at 50% 100%, #323232 2.08%, #0B0D0E 100%);',
    winBackground:
      'linear-gradient(90deg, rgba(210, 162, 86, 0.2) 0%, rgba(244, 223, 173, 0.2) 100%);',
    color: gradients.glimmeringGold,
  },
  default: {
    lossBackground: 'radial-gradient(49.92% 92.61% at 50% 100%, #162A46 2.08%, #0C1117 100%);',
    winBackground:
      'linear-gradient(90deg, rgba(210, 162, 86, 0.2) 0%, rgba(244, 223, 173, 0.2) 100%);',
    color: gradients.glimmeringGold,
  },
};

const titleSize = size => {
  switch (size) {
    case 'small':
      return 'title-xs';
    case 'medium':
      return 'title-s';
    default:
      return 'title-xs';
  }
};

const PredictionsHeader = ({ wins = 0, wrongs = 0, size = 'small' }) => {
  const { predictions = {} } = useThemeContext();

  return (
    <GradientWrapper $size={size} $background={predictions.lossBackground}>
      <PredictionsHeaderWrapper $size={size}>
        <IconWrapper $size={size}>
          <Icon type="trophy" />
        </IconWrapper>
        <OverflowHidden>
          <ScoreWrapper>
            <WinBackground $background={predictions.winBackground} $size={size} />
            <WinLabel type={titleSize(size)} $color={predictions.color} $size={size}>
              {wins} WINS
            </WinLabel>
            <LoseLabel type={titleSize(size)} $size={size}>
              {wrongs} WRONG
            </LoseLabel>
          </ScoreWrapper>
        </OverflowHidden>
      </PredictionsHeaderWrapper>
    </GradientWrapper>
  );
};

PredictionsHeader.propTypes = {
  wins: PropTypes.number,
  wrongs: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default memo(withTheme(PredictionsHeader, localtheme, 'predictions'));
