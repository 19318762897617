import { RefObject, useCallback, useMemo, useState, useEffect } from 'react';
import useKeyBindings from 'znipe-hooks/useKeyBindings';
import useChatContext from './useChatContext';
import { UserListItem } from '../contexts/ChatContext';

const useMentionAutoComplete = (query: string, onSelect?: (user: UserListItem) => void) => {
  const [result, setResult] = useState<UserListItem[]>([]);
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);
  const { chatStore } = useChatContext() ?? {};

  const selectFocusedItem = useCallback(() => {
    if (!onSelect || !result) return;
    const focusedResult: UserListItem = result[focusedItemIndex];
    onSelect(focusedResult);
  }, [focusedItemIndex, result, onSelect]);

  const focusPrevItem = useCallback(() => {
    setFocusedItemIndex(prev => {
      if (prev === -1) return 0;
      return prev > 0 ? prev - 1 : prev;
    });
  }, []);

  const focusNextItem = useCallback(() => {
    setFocusedItemIndex(prev => (prev < result.length - 1 ? prev + 1 : prev));
  }, [result]);

  useEffect(() => {
    if (!chatStore) return;
    const { users } = chatStore.getState();

    setResult([...users.filter(user => user.username.indexOf(query) === 0)]);
  }, [chatStore, query]);

  const keyBindings = useMemo(
    () => ({
      Enter: selectFocusedItem,
      Tab: selectFocusedItem,
      ArrowUp: focusPrevItem,
      ArrowDown: focusNextItem,
    }),
    [focusNextItem, focusPrevItem, selectFocusedItem],
  );

  const containerRef = useKeyBindings(keyBindings, false) as RefObject<HTMLDivElement>;

  return {
    result,
    containerRef,
    focusedItemIndex,
  };
};

export default useMentionAutoComplete;
