import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import GreenRing from './green_ring.gif';

const GifImage = styled.img`
  position: absolute;
  z-index: ${props => props.zIndex};
  opacity: 0.3;
  mix-blend-mode: lighten;
  width: 100%;
  top: 0px;
  right: 0px;
  ${props =>
    props.dimension &&
    css`
      max-width: ${props.dimension};
      max-height: ${props.dimension};
    `}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const GreenRingAnimation = ({ dimension = '' }) => (
  <Container>
    <GifImage
      dimension={dimension}
      data-testid="green-ring-animation"
      src={GreenRing}
      alt="Green ring animation"
    />
  </Container>
);

GreenRingAnimation.propTypes = {
  dimension: PropTypes.string,
};

export default GreenRingAnimation;
