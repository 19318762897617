import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetLocalizationMap from './makeGetLocalizationMap';

const makeGetStrings = () => {
  const getLocalization = makeGetLocalizationMap();
  return createSelector([getLocalization], localization => localization.strings ?? {});
};

export const useStrings = () => useMemoizedSelector(makeGetStrings);

export default makeGetStrings;
