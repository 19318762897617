import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { chatPopOverMaxHeight, chatPopOverWidth } from 'znipe-constants/chat';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import EmojiQuickSelect from './EmojiQuickSelect/EmojiQuickSelect';
import EmojiCollection from './EmojiCollection/EmojiCollection';
import ChatPopOver from '../ChatPopOver/ChatPopOver';
import { Container, PopOverWrapper } from './Emojis.styles';

type EmojisProps = {
  isOpen?: boolean;
  size?: 'small' | 'medium' | 'large';
  onSelect: (shortcode: string, action: string, trigger: string) => void;
};

const Emojis: React.FC<EmojisProps> = ({ isOpen = false, size = 'small', onSelect }) => {
  const { analyticsManager } = useChatContext() ?? {};
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const sendAnalytics = useCallback(
    (action: string, trigger: string) => {
      if (!analyticsManager) return;
      analyticsManager.trackUIEvent({ action, trigger });
    },
    [analyticsManager],
  );

  useEffect(() => {
    if (!isOpen) {
      setIsPopOverOpen(false);
    }
  }, [isOpen]);

  const onQuickSelectorMoreClick = useCallback(() => {
    const action = isPopOverOpen ? 'close' : 'open';
    sendAnalytics(`chat_popover_${action}`, 'button_quick_select_more_emojis');
    setIsPopOverOpen(!isPopOverOpen);
  }, [isPopOverOpen, sendAnalytics]);

  const minHeight = useMemo(
    () => (chatPopOverMaxHeight?.[size] || chatPopOverMaxHeight.small) - 16 * 2,
    [size],
  );

  const onSelectFromPopOver = useCallback(
    (shortcode: string) => onSelect(shortcode, 'emoji_select', 'expanded_menu'),
    [onSelect],
  );

  const onClosePopOver = useCallback(() => {
    sendAnalytics('chat_popover_close', 'popover_close');
    setIsPopOverOpen(false);
  }, [sendAnalytics]);

  return (
    <Container>
      {isOpen && isPopOverOpen && (
        <PopOverWrapper
          $width={chatPopOverWidth?.[size] || chatPopOverWidth.small}
          data-testid="popover-wrapper"
        >
          <ChatPopOver size={size} header="Emojis" onCloseClick={onClosePopOver}>
            <EmojiCollection onSelect={onSelectFromPopOver} minHeight={minHeight} hasSearch />
          </ChatPopOver>
        </PopOverWrapper>
      )}
      <EmojiQuickSelect
        isOpen={isOpen}
        onSelect={onSelect}
        onClickMore={onQuickSelectorMoreClick}
      />
    </Container>
  );
};

export default Emojis;
