import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import { SCHEDULE, VODS, topicTypes } from 'tv-elements/data-display/NoResults/constants';
import Section from 'tv-elements/layout/Section/Section';
import { LIST } from 'tv-elements/layout/Section/Section.constants';
import VODItem from 'tv-modules/VODListing/VODItem/VODItem';
import ScheduleMatchItem from 'tv-modules/Schedule/MatchItem/MatchItem';
import ScheduleMatchLoading from 'tv-modules/Schedule/MatchItem/MatchItem.loading';
import VODItemLoading from 'tv-modules/VODListing/VODItem/VODItem.loading';
import PremiumPromoSection from 'tv-modules/Premium/PremiumPromoSection/PremiumPromoSection';
import { SectionWrapper, SectionLoading } from 'tv-routes/Matches/Matches.styles';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import InfiniteScrollWrapper from 'znipe-elements/feedback/InfiniteScrollWrapper/InfiniteScrollWrapper';

export const VOD = 'vod';

const singletonPadding = '24px 0';

const GroupedMatchList = ({
  type = VOD,
  groupedItems = {},
  size = 'small',
  callback = () => {},
  topicType,
  fullWidth = false,
  isLoading = false,
}) => {
  const matchItemSize = useMemo(() => (size === 'large' ? 'medium' : size), [size]); // Match item does not have a large size
  const singletonNoMatches = (
    <NoResults padding={singletonPadding} type={SCHEDULE} topic={topicType} />
  );
  const singletonNoVods = <NoResults padding={singletonPadding} type={VODS} topic={topicType} />;
  const sectionLoading = useMemo(
    () => (
      <SectionLoading>
        <TextSkeleton width={size === 'small' ? 150 : 250} height={size === 'small' ? 20 : 26} />
        {type === 'vod' ? (
          <VODItemLoading size={size} />
        ) : (
          <ScheduleMatchLoading size={matchItemSize} />
        )}
      </SectionLoading>
    ),
    [size, matchItemSize, type],
  );
  const tabLoading = useMemo(
    () => (
      <SectionWrapper $noPadding>
        {sectionLoading}
        {sectionLoading}
        {sectionLoading}
      </SectionWrapper>
    ),
    [sectionLoading],
  );

  const generateItems = useCallback(
    ({ id }) => {
      if (type === VOD) {
        return <VODItem key={id} matchId={id} size={size} />;
      }
      return <ScheduleMatchItem key={id} matchId={id} size={matchItemSize} />;
    },
    [size, type, matchItemSize],
  );

  const generateSections = useCallback(
    (date, index) => (
      <Section type={LIST} title={date} noTopMargin fullWidth={fullWidth} key={`section-${date}`}>
        {groupedItems[date].map(generateItems)}
        {index === 0 && <PremiumPromoSection type="povs" />}
      </Section>
    ),
    [generateItems, groupedItems, fullWidth],
  );

  const items = useMemo(
    () =>
      Object.keys(groupedItems)
        ?.filter(item => !!groupedItems[item]?.length)
        ?.map(generateSections),
    [generateSections, groupedItems],
  );

  if (isLoading && items.length === 0) {
    return tabLoading;
  }

  if (items.length === 0) {
    return (
      <SectionWrapper $noPadding data-testid={type === VOD ? 'no-vods' : 'no-matches'}>
        {type === VOD ? singletonNoVods : singletonNoMatches}
      </SectionWrapper>
    );
  }

  return (
    <>
      <SectionWrapper $noPadding>{items}</SectionWrapper>
      {callback && <InfiniteScrollWrapper callback={callback} />}
    </>
  );
};

GroupedMatchList.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf([VOD, SCHEDULE]),
  topicType: PropTypes.oneOf(topicTypes),
  callback: PropTypes.func,
  groupedItems: PropTypes.shape({
    items: PropTypes.PropTypes.shape({}),
    keys: PropTypes.arrayOf(PropTypes.string),
  }),
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default memo(GroupedMatchList);
