import { memo } from 'react';
import PropTypes from 'prop-types';
import Section from 'tv-elements/layout/Section/Section';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { HighlightsSectionWrapper } from 'tv-elements/layout/PageLayout/PageLayout';
import { LIST, GRID } from 'tv-elements/layout/Section/Section.constants';
import InfiniteScrollWrapper from 'znipe-elements/feedback/InfiniteScrollWrapper/InfiniteScrollWrapper';

const HighlightScroll = ({ children, callback = () => {} }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  return (
    <>
      <HighlightsSectionWrapper>
        <Section noTopMargin type={isTabletOrGreater ? GRID : LIST}>
          {children}
        </Section>
      </HighlightsSectionWrapper>
      {callback && <InfiniteScrollWrapper callback={callback} />}
    </>
  );
};

HighlightScroll.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default memo(HighlightScroll);
