import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getProductPrice } from 'tv-actions/old/products';

const useGetPrice = (currency, productId, period) => {
  const dispatch = useDispatch();

  const getPrice = useCallback(
    async promoCode => {
      if (!productId) return {};
      const options = {
        period,
        ...(currency ? { currency } : {}),
        ...(promoCode ? { promoCode } : {}),
      };

      return dispatch(getProductPrice(productId, options));
    },
    [dispatch, currency, productId, period],
  );

  return getPrice;
};

export default useGetPrice;
