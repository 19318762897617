import { schema } from 'normalizr';
import character from './character.normalizr';
import kill from './kill.normalizr';
import monsterKill from './monsterKill.normalizr';
import pause from './pause.normalizr';
import player from './player.normalizr';
import team from './team.normalizr';
import round from './round.normalizr';
import sequential from './sequential.normalizr';
import timeSeriesEntry from './timeSeriesEntry.normalizr';
import structureDestroyed from './structureDestroyed.normalizr';

const groupByTeam = players =>
  Object.entries(players ?? {}).reduce(
    (acc, [id, currentPlayer]) =>
      currentPlayer.teamId
        ? {
            ...acc,
            [currentPlayer.teamId]: {
              ...(acc[currentPlayer.teamId] ?? {}),
              [id]: currentPlayer,
            },
          }
        : acc,
    {},
  );

const gat = new schema.Entity(
  'gats',
  {
    baronQueue: [sequential],
    dragonQueue: [sequential],
    kills: [kill],
    monsterKills: [monsterKill],
    pauses: [pause],
    players: [player],
    rounds: [round],
    teams: [team],
    team1: [player],
    team2: [player],
    teamOneBans: [character],
    teamOnePicks: [character],
    teamTwoBans: [character],
    teamTwoPicks: [character],
    timeSeries: [timeSeriesEntry],
    structuresDestroyed: [structureDestroyed],
  },
  {
    processStrategy: values => {
      const groupedByTeam = groupByTeam(values.players);
      const [team1Id, team2Id] = Object.keys(groupedByTeam).sort();
      const team1 = groupedByTeam[team1Id] ?? [];
      const team2 = groupedByTeam[team2Id] ?? [];
      return {
        ...values,
        team1,
        team2,
        teams: values.teams ?? Object.keys(groupedByTeam),
      };
    },
  },
);

export default gat;
