import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Typography from 'znipe-elements/general/Typography/Typography';

export const themes = {
  proview: {
    containerLabelColor: '#80D1FF',
    containerEmptyLabelColor: '#D4CB71',
    containerRemoveLabelColor: '#FF5B6F',
  },
  default: {
    containerLabelColor: '#80D1FF',
    containerEmptyLabelColor: '#D4CB71',
    containerRemoveLabelColor: '#FF5B6F',
  },
};

export const Label = styled(Typography)`
  margin: 4px 8px 0 8px;
  color: ${({ isEmpty, theme }) =>
    isEmpty ? theme.streamItem.containerEmptyLabelColor : theme.streamItem.containerLabelColor};

  color: ${({ theme, isEmpty, isRemoveableStream }) => {
    if (isEmpty) return theme.streamItem.containerEmptyLabelColor;
    if (isRemoveableStream) return theme.streamItem.containerRemoveLabelColor;
    return theme.streamItem.containerLabelColor;
  }};
  @media ${device.tablet} {
    font-size: 17px;
  }
  @media ${device.desktopLarge} {
    font-size: 20px;
  }
`;

export const LabelContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const StreamContainerContentWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ $isLocked }) =>
    !$isLocked
      ? css`
          max-width: 140px;
          @media ${device.tablet} {
            max-width: 210px;
          }
          @media ${device.desktopLarge} {
            max-width: 290px;
          }
        `
      : css`
          height: 100%;
          margin: unset;
          align-items: unset;
          padding: 8px 4px;
          @media ${device.tablet} {
            padding: 16px 8px;
          }
        `}
`;

export const AspectRatioBox = styled.div`
  min-height: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
`;

export const AspectRatioContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  width: ${props => props.size}px;
`;

export const StreamContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #15171a 0%, #000000 100%);
  border-radius: 2px;
  width: 100%;
  border: ${props =>
    props.isEmpty ? '1px dashed rgba(255, 255, 255, 0.2);' : '1px solid rgba(255, 255, 255, 0.15)'};
  cursor: pointer;

  grid-area: ${({ viewLayout, gridArea }) => {
    if (viewLayout < 3) return 'auto';
    if (viewLayout > 3) {
      switch (gridArea) {
        case 'stream-layout-item1':
          return '1 / 1 / 2 / 2';
        case 'stream-layout-item2':
          return '1 / 2 / 2 / 3';
        case 'stream-layout-item3':
          return '2 / 2 / 3 / 3';
        case 'stream-layout-item4':
          return '2 / 1 / 3 / 2';
        default:
          return '1';
      }
    }
    switch (gridArea) {
      case 'stream-layout-item1':
        return '1 / 1 / 3 / 2';
      case 'stream-layout-item2':
        return '1 / 2 / 2 / 3';
      case 'stream-layout-item3':
        return '2 / 2 / 3 / 3';
      default:
        return '1';
    }
  }};

  ${props =>
    props.viewLayout === 2 &&
    css`
      grid-row-end: auto;
    `}

  &:hover, &:focus {
    border: ${props =>
      props.isEmpty ? '1px dashed rgba(255, 255, 255, 0.6);' : '1px solid rgba(255, 255, 255, 0.6)'};
  }
`;
