import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import isBrowser from 'znipe-utils/web/isBrowser';

const getHeight = isClient => (isClient ? window.innerHeight : undefined);
const isClient = isBrowser();

// inspired by https://usehooks.com/useWindowSize/
const useWindowHeight = (delay = 0) => {
  const [height, setHeight] = useState(getHeight(isClient));

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = debounce(() => setHeight(window.innerHeight), delay);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [delay]);

  return height;
};

export default useWindowHeight;
