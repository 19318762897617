import styled from 'styled-components';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import colors from 'znipe-styles/colors';
import Typography from 'znipe-elements/general/Typography/Typography';

export const borderRadius = '3px';

export const BaseContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${borderRadius};

  background: ${({ $isSelected }) => convertHexToRGB(colors.white, $isSelected ? 0.3 : 0.08)};
  &:hover,
  &:focus {
    background: ${convertHexToRGB(colors.white, 0.2)};
  }
  &:active {
    background: ${convertHexToRGB(colors.white, 0.3)};
  }

  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(5px);
  }
`;

export const KDAWrapper = styled.div`
  min-height: 24px;

  display: flex;
  align-items: center;
  background: ${({ $backgroundFade }) =>
    $backgroundFade
      ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%)'
      : 'transparent'};
`;

export const PremiumIconWrapper = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: ${borderRadius};
  overflow: hidden;
`;

export const ResizeText = styled(Typography)`
  width: ${({ $maxWidth }) => $maxWidth || '100%'};
  max-width: 100%;
  overflow: hidden;
  user-select: none;
`;
