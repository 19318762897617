import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';
import createCombinedId from 'tv-epics/utils/createCombinedId';

const removeEmpty = obj => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (!Array.isArray(obj[key]) && obj[key] === Object(obj[key]))
      newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

const createLolPlayer = (player, gatId) => {
  const {
    summonerName,
    teamId,
    teamID,
    role,
    id,
    participantID,
    imagePath,
    character,
    accountID,
    perks = [{}],
  } = player;
  const { perkIds, perkStyle, perkSubStyle } = perks[0];
  const playerData = {
    name: summonerName,
    teamId: teamId ?? (gatId ? createCombinedId(teamID, gatId) : teamID),
    role,
    id: id ?? (gatId ? createCombinedId(participantID, gatId) : participantID),
    image: imagePath,
    character,
    steamId: accountID, // @TODO Change name of steamId
    perks: perkIds?.slice(0, 6), // Remove the minor runes as we do not show them
    perkStyle,
    perkSubStyle,
  };

  return removeEmpty(
    createPlayerObject(playerData, [
      'role',
      'image',
      'character',
      'perks',
      'perkStyle',
      'perkSubStyle',
    ]),
  );
};

export default createLolPlayer;
