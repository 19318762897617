import { ofType } from 'redux-observable';
import merge from 'deepmerge';
import { fetchGqlQuery } from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import getTimeSeriesInfo, { GROUP_TIMEFRAME } from 'tv-epics/utils/getTimeSeriesInfo';
import { BehaviorSubject, of, from, combineLatest } from 'rxjs';
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  groupBy,
  map,
  mergeMap,
  pairwise,
  scan,
  startWith,
  switchMap,
  tap,
  takeLast,
  takeUntil,
} from 'rxjs/operators';
import config from 'tv-config/config';

const { BOWSER_API_URL } = config;

const emptyObject = {};
const variableSubject = new BehaviorSubject({});
const createVariablePipe = subKey =>
  variableSubject.pipe(
    map(subjectValue => {
      const vars = subjectValue[subKey] ?? emptyObject;

      const { moduleVariables = emptyObject, modules = emptyObject } = vars;
      const modulesToEnable = Object.entries(modules).reduce((acc, [key, value]) => {
        if (value > 0) acc[key] = true;
        return acc;
      }, {});

      return merge.all([...Object.values(moduleVariables), modulesToEnable]);
    }),
    filter(vars => Object.keys(vars).length > 0),
  );

const addVariables = (id, variables, moduleType, SUBSCRIBE_ACTION) => {
  const { value } = variableSubject;
  const current = value[SUBSCRIBE_ACTION] ?? { moduleVariables: {}, modules: {} };
  current.moduleVariables[id] = variables ?? {};

  if (!current.modules[moduleType]) current.modules[moduleType] = 0;

  current.modules[moduleType] += 1;
  value[SUBSCRIBE_ACTION] = current;

  variableSubject.next(value);
};

const removeVariables = (id, moduleType, SUBSCRIBE_ACTION) => {
  const { value } = variableSubject;
  const current = variableSubject.value[SUBSCRIBE_ACTION] ?? emptyObject;
  delete current.moduleVariables[id];
  if (current.modules[moduleType] > 0) current.modules[moduleType] -= 1;
  value[SUBSCRIBE_ACTION] = current;
  variableSubject.next(value);
};

const equalizeTypes = type => action$ =>
  action$.pipe(
    scan((value, { type: current }) => Math.max(0, value + (current === type ? 1 : -1)), 0),
    filter(value => value === 0),
  );

const fetchOptions = {
  url: `${BOWSER_API_URL}/graphql`,
};

const createPlayerObject = (player, gameId) => ({
  ...player,
  id: `${gameId}_${player.playerId}`,
});

const createTeamObject = (team, gameId) => ({
  ...team,
  id: `${gameId}_${team.teamId}`,
});

const createData = (data, gameId) => ({
  ...data,
  gameId,
  id: gameId,
  players: data.players?.map(player => createPlayerObject(player, gameId)),
  teams: data.teams?.map(team => createTeamObject(team, gameId)),
});

const checkGameTimeUpdate = (prevState, newState, gameId) => {
  const prevGameTime = prevState?.gats?.[gameId]?.gameTime;
  const gameTime = newState?.gats?.[gameId]?.gameTime ?? -1;
  if (prevGameTime === gameTime || gameTime < 0) return false;
  const { gameTime: groupedGameTime } = getTimeSeriesInfo(gameId, gameTime);
  const { gameTime: prevGroupedGameTime } = getTimeSeriesInfo(gameId, prevGameTime);
  return groupedGameTime !== prevGroupedGameTime;
};

const createFetchPipe = (query, variables, gameId, gameTime) => {
  const updatedVariables = {
    ...variables,
    gameId,
    at: gameTime * 1000,
    prefetchAt: (gameTime + GROUP_TIMEFRAME) * 1000,
  };

  return from(fetchGqlQuery({ query, variables: updatedVariables }, fetchOptions, true)).pipe(
    map(({ data }) => createData(data, gameId)),
    catchError(() => of(false)),
    filter(Boolean),
    filter(data => Object.keys(data).length > 0),
  );
};

const factoryBowserEpic =
  (SUBSCRIBE_ACTION, UNSUBSCRIBE_ACTION, ACTION, query) => (action$, state$) =>
    action$.pipe(
      ofType(SUBSCRIBE_ACTION, UNSUBSCRIBE_ACTION),
      tap(({ type, variables, id, moduleType }) => {
        if (type === SUBSCRIBE_ACTION) addVariables(id, variables, moduleType, SUBSCRIBE_ACTION);
        else if (type === UNSUBSCRIBE_ACTION) removeVariables(id, moduleType, SUBSCRIBE_ACTION);
      }),
      // groupBy is used to close a branch of the pipe without canceling the entire pipe from getting new actions
      groupBy(({ gameId }) => gameId, null, equalizeTypes(SUBSCRIBE_ACTION)),
      mergeMap(group$ =>
        group$.pipe(
          exhaustMap(({ gameId, type }) => {
            if (type === UNSUBSCRIBE_ACTION)
              throw new Error(
                `You need to dispatch ${SUBSCRIBE_ACTION} before ${UNSUBSCRIBE_ACTION}`,
              );

            const gameTime$ = state$.pipe(
              startWith(emptyObject),
              pairwise(),
              filter(states => checkGameTimeUpdate(...states, gameId)),
              map(([, { gats }]) => gats?.[gameId]?.gameTime ?? -1),
              map(gameTime => getTimeSeriesInfo(gameId, gameTime).gameTime),
            );

            const allVariables$ = createVariablePipe(SUBSCRIBE_ACTION);

            return combineLatest([gameTime$, allVariables$]).pipe(
              delay(1), // Delay 1ms here for the action to have to unsubscribe after removing a variable
              switchMap(([gameTime, variables]) =>
                createFetchPipe(query, variables, gameId, gameTime),
              ),
            );
          }),
          map(payload => ({
            type: ACTION,
            gatId: payload.gameId,
            payload,
          })),
          // Cancel pipe when group is empty
          takeUntil(group$.pipe(takeLast(1))),
        ),
      ),
    );

export default factoryBowserEpic;
