import { schema } from 'normalizr';

const gatEvent = new schema.Entity(
  'eventFeed',
  {},
  {
    idAttribute: (event, parent) => `${parent.gameId}_${event.gameOffset}`,
    processStrategy: event => ({
      ...event,
      gameOffset: event.gameOffset / 1000,
    }),
  },
);

export default gatEvent;
