import { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useSpring, animated } from '@react-spring/web';
import PropTypes from 'prop-types';
import capitalize from 'znipe-utils/string/capitalizeString';
import { FilterWrapper } from 'tv-elements/layout/PageLayout/PageLayout';
import FilterMenu from 'tv-modules/Filters/FilterMenu/FilterMenu';
import FilterPanel from 'tv-modules/Filters/FilterPanel/FilterPanel';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import {
  PLAYERS,
  TEAMS,
  EVENTS,
  CLASSES,
  TOURNAMENTS,
  ROLES,
  defaultFilterSelection,
  selectionShape,
  filterOptionsShape,
  defaultFilterOptions,
  logoOptionShape,
  STANDARD,
  STANDINGS,
} from 'tv-modules/Filters/Filters.constants';
import {
  SORT_FEATURED,
  SORT_POPULAR,
  SORT_RECENT,
} from 'tv-modules/Filters/FilterMenu/FilterMenu.constants';
import generateHashCodeFromString from 'znipe-utils/misc/generateHashCodeFromString';
import { PanelWrapper, PanelOverlay } from 'znipe-elements/layout/MenuPanel/MenuPanel.styles';

const AnimatedPanelWrapper = animated(PanelWrapper);
const AnimatedPanelOverlay = animated(PanelOverlay);

const supportedFilterTypes = [PLAYERS, TEAMS, EVENTS, CLASSES, TOURNAMENTS];

const FiltersTabset = ({
  defaultSelectedFilters = defaultFilterSelection,
  eventOptions = [],
  sortOptions = [SORT_FEATURED, SORT_POPULAR, SORT_RECENT],
  filterOptions = defaultFilterOptions,
  onFilterChange = () => {},
  onSortChange,
  type = STANDARD,
  setTournament = () => {},
  tournamentOptions = [],
  tabs = [],
  activeTab = '',
  setTab = () => {},
}) => {
  const [selection, setSelection] = useState(defaultSelectedFilters);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  const prefersReducedMotion = usePrefersReducedMotion();

  const onOutsideClick = useCallback(() => setIsFilterPanelOpen(false), []);
  const panelRef = useOutsideClick(onOutsideClick);

  useEffect(() => {
    if (!isFilterPanelOpen) setTimeout(() => setShowFilterPanel(false), 200);
    return setShowFilterPanel(true);
  }, [isFilterPanelOpen]);

  const appliedFilters = useMemo(() => {
    const {
      players: playersFilter = [],
      teams: teamsFilter = [],
      events: eventsFilter = [],
      champions: championsFilter = [],
      classes: classesFilter = {},
      tournaments: tournamentsFilter = [],
    } = selection;

    const playerIdsArr = playersFilter.map(player => player?.id);
    const appliedPlayersFilter = playerIdsArr.join();

    const teamIdsArr = teamsFilter.map(team => team?.id);
    const appliedTeamsFilter = teamIdsArr.join();

    const eventIdsArr = eventsFilter.map(event => event?.id);
    const appliedEventsFilter = eventIdsArr.join();

    const championIdsArr = championsFilter.map(champion => champion?.champId);
    const appliedChampionsFilter = championIdsArr.join();

    const tournamentIdsArr = tournamentsFilter.map(tournament => tournament?.id);
    const appliedTournamentsFilter = tournamentIdsArr.join();

    const classNamesArr = Object.entries(classesFilter)
      .filter(([, selected]) => selected)
      .map(([classType]) => capitalize(classType));
    const appliedClassesFilter = classNamesArr.join();

    return {
      appliedEventsFilter,
      appliedPlayersFilter,
      appliedTeamsFilter,
      appliedClassesFilter,
      appliedChampionsFilter,
      appliedTournamentsFilter,
    };
  }, [selection]);

  useEffect(() => {
    const {
      players: playersFilter = [],
      teams: teamsFilter = [],
      events: eventsFilter = [],
      champions: championsFilter = [],
      classes: classesFilter = {},
      tournaments: tournamentsFilter = [],
    } = selection;

    const {
      appliedEventsFilter,
      appliedPlayersFilter,
      appliedTeamsFilter,
      appliedChampionsFilter,
      appliedClassesFilter,
      appliedTournamentsFilter,
    } = appliedFilters;

    const activeFilter = `${appliedEventsFilter}${appliedPlayersFilter}${appliedTeamsFilter}${appliedChampionsFilter}${appliedClassesFilter}${appliedTournamentsFilter}`;

    const requestHashId = generateHashCodeFromString(activeFilter);

    if (requestHashId === 0) {
      onFilterChange({});
    } else {
      onFilterChange({
        requestHashId,
        selection: {
          ...defaultFilterSelection,
          players: playersFilter,
          teams: teamsFilter,
          events: eventsFilter,
          champions: championsFilter,
          classes: classesFilter,
          tournaments: tournamentsFilter,
        },
        filterParams: {
          appliedEventsFilter,
          appliedPlayersFilter,
          appliedTeamsFilter,
          appliedChampionsFilter,
          appliedClassesFilter,
          appliedTournamentsFilter,
        },
      });
    }
  }, [selection, appliedFilters, onFilterChange]);

  const handleChangeSelection = useCallback(newSelection => {
    if (!newSelection) return;
    setSelection(newSelection);
  }, []);

  const availableFilterOptions = useMemo(() => {
    const { top, panel } = filterOptions;
    const options = [];
    if (top) options.push(...top);
    if (panel) options.push(...panel);
    return options;
  }, [filterOptions]);

  const selectionMatchingFilterOptions = useMemo(() => {
    if (availableFilterOptions.length === 0) return selection;
    const selectionCopy = selection;
    supportedFilterTypes.forEach(filterType => {
      if (!availableFilterOptions.includes(filterType))
        selectionCopy[filterType] = defaultFilterSelection[filterType];
    });
    return selectionCopy;
  }, [availableFilterOptions, selection]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run on activeTab change
  useEffect(() => {
    handleChangeSelection(selectionMatchingFilterOptions);
  }, [activeTab, handleChangeSelection, selectionMatchingFilterOptions]);

  const handleClickFilterButton = useCallback(() => setIsFilterPanelOpen(true), []);
  const handleClickPanelCloseButton = useCallback(() => setIsFilterPanelOpen(false), []);
  const handleClickClearAll = useCallback(
    () => handleChangeSelection(defaultFilterSelection),
    [handleChangeSelection],
  );
  const handleClickClear = useCallback(
    newValue => handleChangeSelection({ ...selection, ...newValue }),
    [selection, handleChangeSelection],
  );
  const handleSortChange = useCallback(
    sortInfo => {
      const sortType = sortInfo.find(currentItem => currentItem.selected) || {};
      onSortChange(sortType.id);
    },
    [onSortChange],
  );

  const animationPanel = useSpring({
    transform: `translateX(${isFilterPanelOpen ? 0 : 100}%)`,
    config: { tension: 800, friction: 60 },
    immediate: prefersReducedMotion,
  });

  const animationOverlay = useSpring({
    opacity: isFilterPanelOpen ? '70%' : '0%',
    config: { duration: 200 },
    immediate: prefersReducedMotion,
  });

  const isFilterPanelActive = useMemo(
    () =>
      Object.entries(selection)
        .map(([menu, menuSelection]) => {
          if (menu === CLASSES || menu === ROLES) {
            const allFalse = Object.keys(menuSelection).every(k => !menuSelection[k]);
            return !allFalse;
          }
          return menuSelection.length !== 0;
        })
        .filter(hasSelection => hasSelection).length > 0,
    [selection],
  );

  const useRolesFilter = useMemo(() => filterOptions?.panel?.includes(ROLES), [filterOptions]);

  return (
    <>
      <FilterWrapper>
        <FilterMenu
          eventOptions={eventOptions}
          selection={selection}
          sortTypes={sortOptions}
          onClickFilterButton={handleClickFilterButton}
          onChangeSelection={handleChangeSelection}
          onSortChange={handleSortChange}
          filterOptions={filterOptions}
          type={type}
          setTournament={setTournament}
          tournamentOptions={tournamentOptions}
          tabs={tabs}
          activeTab={activeTab}
          setTab={setTab}
        />
      </FilterWrapper>
      {showFilterPanel && (
        <>
          <AnimatedPanelWrapper ref={panelRef} style={animationPanel}>
            <FilterPanel
              selection={selection}
              eventOptions={eventOptions}
              menuOptions={filterOptions.panel}
              onChangeSelection={handleChangeSelection}
              onClose={handleClickPanelCloseButton}
              onClearAll={handleClickClearAll}
              onClear={handleClickClear}
              isActive={isFilterPanelActive}
              useRolesFilter={useRolesFilter}
            />
          </AnimatedPanelWrapper>
          <AnimatedPanelOverlay style={animationOverlay} />
        </>
      )}
    </>
  );
};

FiltersTabset.propTypes = {
  eventOptions: PropTypes.arrayOf(logoOptionShape),
  defaultSelectedFilters: PropTypes.shape(selectionShape),
  sortOptions: PropTypes.arrayOf(PropTypes.oneOf([SORT_FEATURED, SORT_POPULAR, SORT_RECENT])),
  filterOptions: PropTypes.shape(filterOptionsShape),
  onFilterChange: PropTypes.func,
  onSortChange: PropTypes.func,
  type: PropTypes.oneOf([STANDINGS, STANDARD]),
  setTournament: PropTypes.func,
  tournamentOptions: PropTypes.arrayOf(logoOptionShape),
  tabs: PropTypes.arrayOf(PropTypes.string),
  activeTab: PropTypes.string,
  setTab: PropTypes.func,
};

export { SORT_FEATURED, SORT_POPULAR, SORT_RECENT };

export default memo(FiltersTabset);
