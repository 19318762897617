import { useCallback, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import logger from 'znipe-logger';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import Typography from 'znipe-elements/general/Typography/Typography';
import Input from 'znipe-elements/data-entry/Input/Input';
import Checkbox from 'znipe-elements/data-entry/Checkbox/Checkbox';
import GoogleAuthButton from 'tv-modules/Authentication/GoogleAuthButton/GoogleAuthButton';
import { ERROR, LOADING, SUCCESS, firebaseErrors, defaultError } from '../constants';
import PasswordInput from '../PasswordInput/PasswordInput';
import {
  Form,
  Options,
  LinkTypography,
  LoadingButton,
  Separator,
  ErrorText,
} from '../Shared/Shared';
import { InfoWrapper } from './LoginForm.styles';
import setPersistentState from '../utils/setPersistentState';

const LoginForm = ({ continuePath }) => {
  const navigate = useNavigate();
  const [state, setState] = useState('');
  const [error, setError] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setFocus,
  } = useForm();
  const { pathname, state: locationState } = useLocation();

  const login = useCallback(
    data => {
      if (state === LOADING) return;
      setState(LOADING);
      const { email, password, persistence } = data;
      setPersistentState(persistence)
        .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
        .then(() => setState(SUCCESS))
        .catch(({ code }) => {
          setState(ERROR);
          setError(firebaseErrors[code] ?? {});
          firebase.auth().signOut();
        });
    },
    [state],
  );

  useEffect(() => {
    if (state === SUCCESS) {
      navigate(continuePath);
    }
  }, [continuePath, navigate, state]);

  const { signup, forgot } = useMemo(
    () => ({
      signup: `${pathname}/register`,
      forgot: `${pathname}/forgot-password`,
    }),
    [pathname],
  );

  const persistent = watch('persistence');
  const providedEmail = locationState?.email;

  useEffect(() => {
    if (providedEmail) setValue('email', providedEmail);
  }, [providedEmail, setValue]);

  useEffect(() => {
    try {
      if (providedEmail) setFocus('password');
      else setFocus('email');
    } catch (_e) {
      logger.error('Failed to focus');
    }
  }, [providedEmail, setFocus]);

  return (
    <>
      <Typography type="heading-m">Welcome back</Typography>
      <Form onSubmit={handleSubmit(login)}>
        <Input
          {...register('email', { required: true })}
          data-testid="email-input"
          errorMessage={error.message}
          error={error.type === 'email' || !!errors.email}
          isInline
          inputType="email"
          label="Email"
          defaultValue={providedEmail}
          disabled={!!providedEmail}
        />
        <PasswordInput
          {...register('password', { required: true })}
          data-testid="password-input"
          errorMessage={error.message}
          error={error.type === 'password' || !!errors.password}
        />
        <InfoWrapper>
          <Checkbox
            {...register('persistence')}
            data-testid="persistence-checkbox"
            label="Stay signed in"
            size="small"
          />
          <LinkTypography $center>
            <Link to={forgot}>Forgot password</Link>
          </LinkTypography>
        </InfoWrapper>
        {state === ERROR && !error.type && <ErrorText type="paragraph-s">{defaultError}</ErrorText>}
        <Options>
          <LoadingButton type="submit" loading={state === LOADING}>
            Login
          </LoadingButton>
          <Separator />
          <GoogleAuthButton onStateChange={setState} state={state} persistent={persistent} />
        </Options>
        <LinkTypography $center>
          Need an account? <Link to={signup}>Sign Up</Link>
        </LinkTypography>
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  continuePath: PropTypes.string.isRequired,
};

export default LoginForm;
