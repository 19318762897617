import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Input from 'znipe-elements/data-entry/Input/Input';
import inputThemes from 'znipe-elements/data-entry/Input/Input.themes';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import colors from 'znipe-styles/colors';
import { Container, Wrapper, Button, RestrictionsText } from './PasswordInput.styles';

const RevealButton = ({ onClick, type }) => (
  <Button type="button" onClick={onClick} data-testid="reveal-button">
    <Icon
      type={type === 'password' ? 'eyeOff' : 'eye'}
      inline
      size={16}
      fillColor={colors.grey65}
    />
  </Button>
);

RevealButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const PasswordInput = forwardRef(
  (
    {
      'data-testid': testId = 'password-input',
      errorMessage,
      error = false,
      showRestrictionsInfo = false,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [type, setType] = useState('password');
    const [valid, setIsValid] = useState(false);
    const { successGreen } = useThemeContext();

    const onRevealToggle = useCallback(() => {
      setType(prev => (prev === 'password' ? 'text' : 'password'));
    }, []);

    const handleChange = useCallback(
      e => {
        setIsValid(e.target.value.length >= 6);
        if (onChange) onChange(e);
      },
      [onChange],
    );

    return (
      <Container>
        <Wrapper>
          <Input
            {...props}
            label="Password"
            data-testid={testId}
            ref={ref}
            errorMessage={errorMessage}
            error={error}
            isInline
            inputType={type}
            onChange={handleChange}
          />
          <RevealButton onClick={onRevealToggle} type={type} />
        </Wrapper>
        {showRestrictionsInfo && (
          <RestrictionsText $error={error} type="paragraph-s" as="span">
            Must be 6 or more characters{' '}
            {valid && <Icon size={16} inline type="check" fillColor={successGreen} />}
          </RestrictionsText>
        )}
      </Container>
    );
  },
);

PasswordInput.propTypes = {
  'data-testid': PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  showRestrictionsInfo: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withTheme(PasswordInput, inputThemes, 'input');
