import { Buffer } from 'buffer';

export type Type = 'Match' | 'Video' | 'Clip';

export const encodeId = (id: string, type: Type) => Buffer.from(`${type}:${id}`).toString('base64');

export const constructPersistedQueryObject = (queryHash: string) => {
  if (!queryHash) return undefined;
  return {
    persistedQuery: {
      version: 1,
      sha256Hash: queryHash,
    },
  };
};
