import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSelector } from 'reselect';
import { useLocation, useNavigate } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { useGetMatchChannelId } from 'tv-selectors/match/makeGetMatchChannelId';
import makeGetMatchGamesIds from 'tv-selectors/match/makeGetMatchGamesIds';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { dispatchGqlData } from 'tv-actions/thunks';
import useGqlStore from 'tv-hooks/useGqlStore';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';
import objectToQueryString from 'znipe-utils/location/objectToQueryString';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { normalizeData } from 'znipe-utils/normalize/normalizeData';
import { rootSchema } from 'tv-schema/rootSchema.normalizr';
import makeGetNextChannelGame from 'tv-selectors/channels/makeGetNextChannelGame';
import makeGetOnLiveChannelEnd from 'tv-selectors/channels/makeGetOnLiveChannelEnd';
import makeGetGameStatus, { useGetGameStatus } from 'tv-selectors/games/makeGetGameStatus';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetGameNumber from 'tv-selectors/games/makeGetGameNumber';
import makeGetGameMap from 'tv-selectors/games/makeGetGameMap';
import { useGetGameStartTime } from 'tv-selectors/games/makeGetGameStartTime';
import cj from 'cj';

const sortedMatchesAndGames = matches => {
  if (!matches) return [];
  const formattedMatches = Array.isArray(matches) ? matches : Object.values(matches);
  const matchesWithSortedGames = formattedMatches.map(match => ({
    ...match,
    games: match?.games.sort((g1, g2) => g1.startTime - g2.startTime),
  }));

  return matchesWithSortedGames.sort(
    (m1, m2) => (m1.games?.[0]?.startTime ?? 0) - (m2.games?.[0]?.startTime ?? 0),
  );
};

const emptyMap = {};
const makeGetNextGameInfo = () => {
  const getNextChannelGame = makeGetNextChannelGame();
  const getOnLiveChannelEnd = makeGetOnLiveChannelEnd();
  const getGameStatus = makeGetGameStatus();
  const getMatchGamesIds = makeGetMatchGamesIds();
  const getGameNumber = makeGetGameNumber();
  const getGames = makeGetGameMap();
  const getCurrentMatchId = (_state, props) => props.matchId;
  const getNextMatchGame = createSelector(
    [getMatchGamesIds, getGameNumber, getGames],
    (matchGameIds, gameNumber, games) => {
      const nextGameId = matchGameIds.find(id => games[id]?.gameNumber === gameNumber + 1);
      return games[nextGameId] ?? emptyMap;
    },
  );

  return createSelector(
    [getNextChannelGame, getOnLiveChannelEnd, getGameStatus, getNextMatchGame, getCurrentMatchId],
    (nextChannelGame, onLiveChannelEnd, gameStatus, nextMatchGame, currentMatchId) => {
      const liveChannelEnding = gameStatus === 'live' && !nextChannelGame.id;
      const isVodGame = gameStatus === 'vod';

      if (isVodGame) {
        const { status: nextGameStatus, startTime: st = -1, id: nextId, matchId } = nextMatchGame;

        if (nextId && (nextGameStatus === 'vod' || nextGameStatus === 'live')) {
          return { nextMatchId: matchId ?? currentMatchId, nextGameId: nextId, endTime: st };
        }
      }

      if (liveChannelEnding) {
        const { presentationTimestamp, matchId, gameId } = onLiveChannelEnd;
        if (!matchId || !gameId) return emptyMap;
        return {
          nextGameId: gameId,
          nextMatchId: matchId,
          endTime: presentationTimestamp,
        };
      }

      if (!nextChannelGame.id) return emptyMap;

      // If the user is watching a live game, randomly delay redirection with 0 - 20s
      const randomWait = isVodGame ? 0 : Math.floor(Math.random() * 21);

      return {
        nextGameId: nextChannelGame.id,
        nextMatchId: nextChannelGame.matchId,
        endTime: nextChannelGame.startTime + randomWait,
      };
    },
  );
};

const useNextGameInfo = props => useMemoizedGqlSelector(makeGetNextGameInfo, props);

const useGameRedirect = (playerRef, providedMatchId, providedMatchGameIndex) => {
  const channelApp = useMemo(() => cj('channel'), []);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useGqlStoreDispatch();
  const [matchId, setMatchId] = useState(providedMatchId);
  const [matchGameIndex, setMatchGameIndex] = useState(providedMatchGameIndex);
  const channelId = useGetMatchChannelId({ matchId });
  const store = useGqlStore();
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex: matchGameIndex });
  const gameStatus = useGetGameStatus({ matchId, selectedMatchGameIndex: matchGameIndex });
  const isVodGame = gameStatus === 'vod';
  const liveStartTime = useGetGameStartTime({ matchId, selectedMatchGameIndex: matchGameIndex });
  const startTime = useMemo(() => (isVodGame ? 0 : liveStartTime), [liveStartTime, isVodGame]);
  const {
    nextGameId = '',
    nextMatchId = '',
    endTime = -1,
  } = useNextGameInfo({
    gameId,
    channelId,
    matchId,
    selectedMatchGameIndex: matchGameIndex,
  });

  useEffect(() => {
    setMatchId(providedMatchId);
    setMatchGameIndex(providedMatchGameIndex);
  }, [providedMatchGameIndex, providedMatchId]);

  const redirect = useCallback(() => {
    const { games = {} } = store.getState();
    const nextGame = games[nextGameId] ?? {};
    const { gameNumber: nextGameNumber } = nextGame;
    if (!nextGameNumber && matchId === nextMatchId) return;
    const nextGameIndex = nextGameNumber ? nextGameNumber - 1 : 0;
    setMatchGameIndex(nextGameIndex);
    setMatchId(nextMatchId);

    const searchObj = queryStringToObject(location.search);
    searchObj.g = nextGameIndex;
    searchObj.m = nextMatchId;
    const search = objectToQueryString(searchObj);

    navigate(`${location.pathname}${search}`, { state: location.state });
  }, [navigate, location, store, nextMatchId, nextGameId, matchId]);

  useEffect(() => {
    if (!isVodGame) playerRef.current.setSeekRange(startTime ?? 0, -1);
  }, [isVodGame, startTime, playerRef]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run on matchId or gameId change
  useEffect(() => {
    if (!nextGameId) return () => null;
    if (!isVodGame && (!endTime || endTime <= 0)) return () => null;
    const now = Date.now();
    const liveEnded$ = fromEvent(playerRef.current, 'timeupdate').pipe(
      filter(() => now < Date.now() + 5000), // Do not redirect again within 5s of loading a new stream
      map(({ currentTime }) => currentTime),
      filter(currentTime => currentTime >= endTime),
    );
    const vodEnded$ = fromEvent(playerRef.current, 'ended');

    const ended$ = isVodGame ? vodEnded$ : liveEnded$;

    const sub = ended$.subscribe(redirect);
    return () => sub.unsubscribe();
  }, [playerRef, nextMatchId, nextGameId, endTime, isVodGame, redirect]);

  useEffect(() => {
    if (!channelId) return () => null;

    const channelRef = channelApp.ref(channelId);

    const handleChannels = channelMessage => {
      if (!channelMessage) return;
      if (channelMessage.type !== 'PRODUCTION_CHANNEL_MESSAGE') return;
      const { message: channelInfo } = channelMessage;
      const channelInfoWithSortedMatches = {
        ...channelInfo,
        matches: sortedMatchesAndGames(channelInfo.matches),
      };
      const normalized = normalizeData(
        {
          channels: channelInfoWithSortedMatches,
        },
        rootSchema,
      );
      dispatch(dispatchGqlData(normalized.entities));
    };

    const channelSub = fromEvent(channelRef, 'value').subscribe(handleChannels);

    return () => {
      channelSub.unsubscribe();
    };
  }, [channelApp, channelId, dispatch]);

  const startOffset = useMemo(() => {
    const { t } = queryStringToObject(location.search);
    if (!t) return undefined;

    const time = parseInt(t, 10);

    if (isNaN(time)) return undefined;

    if (t < 0) return 0;

    return time;
  }, [location.search]);

  return useMemo(
    () => ({
      matchId,
      matchGameIndex,
      startTime: typeof startOffset === 'number' ? startTime + startOffset : undefined,
      seekRange: isVodGame ? undefined : { start: startTime },
    }),
    [matchId, matchGameIndex, startOffset, startTime, isVodGame],
  );
};

export default useGameRedirect;
