import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useMatchLink = (matchId = '', fromStart = false) => {
  const { packageSlug } = useParams();

  const baseUrl = useMemo(() => (packageSlug ? `/${packageSlug}` : ''), [packageSlug]);

  if (!matchId) {
    return `${baseUrl}/stage`;
  }

  const urlString = `${baseUrl}/stage?m=${matchId}`;
  return `${urlString}${fromStart ? '&start=0' : ''}`;
};

export default useMatchLink;
