import { memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import getRelativeDate from 'znipe-utils/date/getRelativeDate';
import ItemWithVideoPreview from 'tv-modules/Featured/ItemWithVideoPreview/ItemWithVideoPreview';
import Link from 'znipe-elements/general/Link/Link';
import PremiumBanner from 'tv-modules/Premium/PremiumBanner/PremiumBanner';
import useHighlightLink from 'tv-hooks/useHighlightLink';
import useGridAnimationOffset from 'tv-hooks/useGridAnimationOffset';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import { useGetVideoThumbnail } from 'tv-selectors/video/makeGetVideoThumbnail';
import { useGetVideoTitle } from 'tv-selectors/video/makeGetVideoTitle';
import { useGetVideoTournamentName } from 'tv-selectors/video/makeGetVideoTournamentName';
import { useGetVideoDate } from 'tv-selectors/video/makeGetVideoDate';
import { useGetVideoTeams } from 'tv-selectors/video/makeGetVideoTeams';
import { useGetIsVideoLocked } from 'tv-selectors/video/makeGetIsVideoLocked';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import HighlightSkeleton from './HighlightClip.loading';

import {
  Content,
  Title,
  Subtitle,
  Container,
  ImageContainer,
  LockWrapper,
} from './HighlightClip.styles';
import { SMALL, MEDIUM, TILE, LIST } from './HighlightClip.constants';

const HighlightClip = ({
  type = TILE,
  size = SMALL,
  highlightId,
  isSelected = false,
  ignoreRouteHistory = false,
  videoType = 'highlight',
  index = 0,
}) => {
  const capitalize = word => word.charAt(0).toUpperCase() + word.substring(1);

  const teamsInfo = useGetVideoTeams({ videoId: highlightId });
  const [teamOne, teamTwo] = teamsInfo;
  const title = useGetVideoTitle({ videoId: highlightId });
  const tournamentName = useGetVideoTournamentName({ videoId: highlightId });
  const date = useGetVideoDate({ videoId: highlightId });
  const isLocked = useGetIsVideoLocked({ videoId: highlightId });
  const thumbnailUrl = useGetVideoThumbnail({ videoId: highlightId });
  const videoSrc = useStreamSrcWithToken(highlightId, 'video');
  const { hasLoaded } = useOnImageLoad(thumbnailUrl);
  const componentHasLoaded = useLoadingDelay(hasLoaded);

  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();

  const highlightLink = useHighlightLink({ highlightId, type: videoType, isLocked });
  const elementSizes = useMemo(() => {
    if (size === SMALL) {
      return {
        heading: 'xs',
        paragraph: 's',
        imageWidth: type === TILE ? '100%' : '38%',
        height: type === LIST ? '148px' : '100%',
      };
    }
    return {
      heading: 's',
      paragraph: 'm',
      imageWidth: type === TILE ? '100%' : '42%',
      height: type === LIST ? '100%' : '100%',
    };
  }, [size, type]);

  const formattedDate = date ? capitalize(getRelativeDate(date)) : '';

  const subtitle = useMemo(() => {
    const arr = [];

    if (teamOne && teamTwo) {
      arr.push(`${teamOne} vs ${teamTwo}`);
    }
    if (tournamentName) {
      arr.push(tournamentName);
    }
    if (date) {
      arr.push(formattedDate);
    }
    return arr.join(' | ');
  }, [date, formattedDate, teamOne, teamTwo, tournamentName]);

  const containerRef = useRef();

  const columnCount = isDesktopExtraLargeOrGreater ? 4 : 3;
  const offset = useGridAnimationOffset(index, columnCount, 0);
  const animationOffset = useMemo(() => (type === LIST ? 0 : offset), [offset, type]);

  if (!componentHasLoaded) {
    return <HighlightSkeleton type={type} size={size} animationOffset={animationOffset} />;
  }

  return (
    <Link to={highlightLink} hideUnderline replace={ignoreRouteHistory}>
      <Container ref={containerRef} type={type} data-testid="highlight-clip-container">
        {isLocked && (
          <LockWrapper>
            <PremiumBanner targetRef={containerRef} />
          </LockWrapper>
        )}
        <ImageContainer type={type} height={elementSizes.height} width={elementSizes.imageWidth}>
          <ItemWithVideoPreview
            src={videoSrc}
            thumbnail={thumbnailUrl}
            orientation={type === TILE ? 'vertical' : 'horizontal'}
            size={TILE ? 'fullWidth' : 'small'}
            isSelected={isSelected}
          >
            <Content type={type} data-testid="text-content">
              <Title dataTestId="highlight-title" type={`heading-${elementSizes.heading}`}>
                {title}
              </Title>
              <Subtitle
                dataTestId="highlight-subtitle"
                type={`paragraph-${elementSizes.paragraph}`}
              >
                {subtitle}
              </Subtitle>
            </Content>
          </ItemWithVideoPreview>
        </ImageContainer>
      </Container>
    </Link>
  );
};

HighlightClip.propTypes = {
  type: PropTypes.oneOf([TILE, LIST]),
  size: PropTypes.oneOf([SMALL, MEDIUM]),
  highlightId: PropTypes.string.isRequired,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  ignoreRouteHistory: PropTypes.bool,
  videoType: PropTypes.oneOf(['highlight', 'editorial']),
};

export default memo(HighlightClip);
