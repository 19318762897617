import Helmet from 'react-helmet';

const Page404 = () => (
  <div className="page-404">
    <Helmet title="Znipe - Page not found" />
    <h1 className="page-404__header">Page not found</h1>
  </div>
);

export default Page404;
