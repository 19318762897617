import { memo, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import TopicLink, { ICON_AVATAR, TEXT } from 'tv-elements/data-display/TopicLink/TopicLink';
import TopicLinkSearchResult from './TopicLinkSearchResult';
import {
  TEAMS,
  CHAMPIONS,
  PLAYERS,
  EVENTS,
  CLASSES,
  YEARS,
  allFilterMenus,
  lolClassIcons,
  classesShape,
} from '../../Filters.constants';

const SubMenuContent = ({
  openSubMenu,
  searchResults,
  selection,
  onChangeSelection = () => {},
}) => {
  const yearOptions = useMemo(() => {
    const max = new Date().getFullYear();
    const length = 4;
    const years = [];
    for (let i = max; i > max - length; i--) years.push(i);
    return years;
  }, []);

  const handleClickClass = useCallback(
    classType => {
      const newValue = { ...selection, [classType]: !selection[classType] };
      onChangeSelection({ [CLASSES]: newValue });
    },
    [selection, onChangeSelection],
  );

  const handleClickYear = useCallback(
    (year, isSelected) => {
      const newValue = isSelected ? selection.filter(item => item !== year) : [...selection, year];
      onChangeSelection({ [YEARS]: newValue });
    },
    [selection, onChangeSelection],
  );

  switch (openSubMenu) {
    case PLAYERS:
    case TEAMS:
    case CHAMPIONS:
    case EVENTS:
      return (
        <TopicLinkSearchResult
          menu={openSubMenu}
          searchResults={searchResults?.hits}
          selection={selection}
          onChangeSelection={onChangeSelection}
        />
      );
    case CLASSES:
      return Object.entries(lolClassIcons).map(([title, image]) => {
        const classType = title.toLocaleLowerCase();
        const isSelected = selection[classType];
        const onClick = () => handleClickClass(classType);
        return (
          <Fragment key={title}>
            <TopicLink
              title={title}
              image={image}
              type={ICON_AVATAR}
              isEmpty={!isSelected}
              isSelected={isSelected}
              onClick={onClick}
            />
          </Fragment>
        );
      });
    case YEARS:
      return yearOptions.map(year => {
        const isSelected = selection.indexOf(year) !== -1;
        const onClick = () => handleClickYear(year, isSelected);
        return (
          <Fragment key={year}>
            <TopicLink
              title={year.toString()}
              type={TEXT}
              isEmpty={!isSelected}
              isSelected={isSelected}
              onClick={onClick}
            />
          </Fragment>
        );
      });
    default:
      return null;
  }
};

SubMenuContent.propTypes = {
  openSubMenu: PropTypes.oneOf(allFilterMenus).isRequired,
  searchResults: PropTypes.shape({
    hits: PropTypes.arrayOf(PropTypes.shape({})),
    count: PropTypes.number,
  }),
  selection: PropTypes.oneOfType([
    classesShape,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  onChangeSelection: PropTypes.func,
};

export default memo(SubMenuContent);
