import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'tv-hooks/useTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Typography from 'znipe-elements/general/Typography/Typography';
import BannerLayout from 'tv-elements/data-display/Banner/Layout/Layout';
import HeaderCover from 'tv-elements/data-display/Banner/HeaderCover/HeaderCover';
import useSetDisabledStatus from 'tv-modules/Onboarding/hooks/useSetDisabledStatus';
import { useIsOnboardingPlayed } from 'tv-selectors/auth/makeGetIsOnboardingStepPlayed';
import { useIsOnboardingDisabled } from 'tv-selectors/auth/makeGetIsOnboardingDisabled';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';
import { ClickWrapper, GradientContainer } from './IntroBanner.styles';

const BannerFooter = ({ targetId }) => {
  const setDisabledStatus = useSetDisabledStatus();
  const { secondaryTextColor } = useTheme();

  const onDisableHintsClick = useCallback(() => {
    setDisabledStatus(true);
    const element = document.querySelector(`[data-onboarding-id="${targetId}"]`);
    if (!element) return;
    element.dataset.bannerTargetActive = 'false';
  }, [targetId, setDisabledStatus]);

  return (
    <ClickWrapper onClick={onDisableHintsClick}>
      <Typography
        as="div"
        type="title-xs"
        align="right"
        color={secondaryTextColor}
        onClick={onDisableHintsClick}
      >
        Disable hints
      </Typography>
    </ClickWrapper>
  );
};
BannerFooter.propTypes = { targetId: PropTypes.string.isRequired };

const IntroBanner = ({ targetId, coverImage, title, text, isEnabled = true, margin }) => {
  const { onboardingGradient } = useThemeContext();
  const [hasFoundTargetElement, setHasFoundTargetElement] = useState();
  const hasAlreadyBeenPlayed = useIsOnboardingPlayed({ stepId: targetId });
  const isOnboardingDisabled = useIsOnboardingDisabled();
  const setPlayedStatus = useSetPlayedStatus(targetId);

  const onCloseClick = useCallback(() => setPlayedStatus(true), [setPlayedStatus]);

  const isVisible = isEnabled && !hasAlreadyBeenPlayed && !isOnboardingDisabled;

  useEffect(() => {
    if (!isVisible) return () => {};

    const element = document.querySelector(`[data-onboarding-id="${targetId}"]`);
    if (!element) return () => {};
    element.dataset.bannerTargetActive = 'true';
    setHasFoundTargetElement(true);

    // setup click listener on target
    const onTargetClick = () => {
      element.dataset.bannerTargetActive = 'false';
      setPlayedStatus(true);
    };
    element.addEventListener('click', onTargetClick);

    return () => {
      element.removeEventListener('click', onTargetClick);
    };
  }, [isVisible, setPlayedStatus, targetId]);

  if (!isVisible || !hasFoundTargetElement) return null;

  return (
    <GradientContainer $backgroundColor={onboardingGradient} $margin={margin}>
      <BannerLayout
        headerComponent={<HeaderCover coverImage={coverImage} />}
        footerComponent={<BannerFooter targetId={targetId} />}
        onCloseClick={onCloseClick}
        headlineComponent={title}
        bodyComponent={text}
      />
    </GradientContainer>
  );
};

IntroBanner.propTypes = {
  targetId: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  coverImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  margin: PropTypes.string,
};

export default IntroBanner;
