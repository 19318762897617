export type Shaka = {
  getMediaElement: () => HTMLVideoElement;
  getLoadMode: () => number;
  getStats: () => { playTime: number };
};

export type Video = {
  id: string;
  ref: {
    current: Shaka;
  };
  master: boolean;
  audioOnly: boolean;
  muted: boolean;
};

const findMaster = (potentialMasters: Video[], video: Video): Video[] => {
  if (!video.ref) return potentialMasters;
  if (video.master) return [video, ...potentialMasters];
  if (!video.muted) return [...potentialMasters, video];
  return potentialMasters;
};

const selectMaster = (videos: Video[] = []): Video | undefined => {
  if (videos.length < 1) return undefined;
  const videosWithVideoStream = videos.filter(video => !video.audioOnly);
  const potentialMasters = videosWithVideoStream.reduce(findMaster, []);
  // Pick the first in the list.
  // never pick a video that is audioOnly
  // Should be a video with "master: true" if it exist
  // else pick the first with audio on
  if (potentialMasters.length > 0) return potentialMasters[0];
  // If no potential masters, just pick one
  return videosWithVideoStream[0];
};

export default selectMaster;
