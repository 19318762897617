import { useCallback, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { isRiotDomain } from 'tv-utils/pageUtils';
import { login as riotLogin } from 'tv-modules/Authentication/utils/riotAuthHelper';
import isInIframe from 'tv-utils/isInIframe';

const useOnLoginClick = (alsoInIframe = false) => {
  const navigate = useNavigate();
  const { packageSlug } = useParams();
  const { pathname, search } = useLocation();

  const redirectPath = useMemo(() => {
    if (!global.document || pathname === '/') return '';
    return window.encodeURIComponent(`${pathname}${search}`);
  }, [pathname, search]);

  const onLoginClick = useCallback(() => {
    if (isRiotDomain()) {
      riotLogin();
      return;
    }
    const base = packageSlug ? `/${packageSlug}/login` : '/login';
    if (redirectPath) navigate(`${base}?redirect-path=${redirectPath}`);
    else navigate(base);
  }, [packageSlug, redirectPath, navigate]);

  if (!alsoInIframe && isInIframe()) return undefined;
  return onLoginClick;
};

export default useOnLoginClick;
