import { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetMatchGameTitle } from 'tv-selectors/match/makeGetMatchGameTitle';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useShouldShowOnboardingStep } from 'tv-selectors/auth/makeGetShouldShowOnboardingStep';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import BookCover from 'tv-modules/Onboarding/BookCover/BookCover';
import Header from 'tv-elements/layout/Header/Header';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import Typography from 'znipe-elements/general/Typography/Typography';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import themes from './StatsMenu.theme';
import { STATS_MAP, PLAYER_STATS, TEAM_STATS, PLAYER_SELECTOR } from './constants';
import {
  Container,
  StatsContent,
  HeaderWrapper,
  BodyContainer,
  TitleWrapper,
} from './StatsMenu.styles';

export const BOOKCOVER_ID = 'stage-stats-cover';

const StatsMenu = ({
  withPlayerSelector = false,
  withHeader = false,
  hideScrollbar = false,
  type = 'default',
  matchId,
  selectedMatchGameIndex,
  onClose = () => {},
  onViewChange = () => {},
  playerId,
  gatPlayerPosition,
  onScroll,
  onPovViewStreamClick,
}) => {
  const [ref, setRef] = useState(null); // Need to force a re-render when setting ref
  const game = useGetMatchGameTitle({ matchId });
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const hasExternalPlayerInfo = useMemo(
    () => !!playerId && typeof gatPlayerPosition === 'number',
    [playerId, gatPlayerPosition],
  );
  const [view, setView] = useState(hasExternalPlayerInfo ? PLAYER_STATS : TEAM_STATS);
  const [playerInfo, setPlayerInfo] = useState({});
  const showBookCover = useShouldShowOnboardingStep({ stepId: BOOKCOVER_ID });
  const isCompact = type === 'compact' || type === 'compact-overlay';
  const { statsMenu: statsMenuTheme } = useThemeContext();

  const PlayerSelector = useMemo(() => {
    if (!withPlayerSelector) return undefined;
    return STATS_MAP[game]?.[PLAYER_SELECTOR];
  }, [game, withPlayerSelector]);

  const onPlayerSelect = useCallback((pid, gatPos) => {
    setView(PLAYER_STATS);
    setPlayerInfo({ playerId: pid, gatPlayerPosition: gatPos });
  }, []);

  const onBackClick = useCallback(() => {
    setView(TEAM_STATS);
  }, []);

  const selectedStats = useMemo(() => {
    const stats = STATS_MAP[game] || STATS_MAP.default;
    return {
      PlayerStats: stats[PLAYER_STATS],
      TeamStats: stats[TEAM_STATS],
    };
  }, [game]);

  useEffect(() => {
    if (!hasExternalPlayerInfo) onBackClick();
    else onPlayerSelect(playerId, gatPlayerPosition);
  }, [playerId, gatPlayerPosition, onPlayerSelect, onBackClick, hasExternalPlayerInfo]);

  const { PlayerStats, TeamStats } = selectedStats;

  useEffect(() => {
    Object.entries(selectedStats).forEach(entry => {
      const component = entry[1];
      if (component.preload) component.preload();
    });
  }, [selectedStats]);

  useEffect(() => {
    onViewChange(view);
  }, [onViewChange, view]);

  return (
    <Container
      data-testid="stats-menu"
      ref={setRef}
      $hideScrollBar={hideScrollbar || (showBookCover && isDesktopOrGreater)}
    >
      {view === TEAM_STATS && withHeader && (
        <HeaderWrapper>
          <StickyComponent enableStickyScroll scrollTarget={ref}>
            <Header onClose={onClose} heading="Stats" componentId="stats-menu-header" />
          </StickyComponent>
        </HeaderWrapper>
      )}
      <BodyContainer>
        {view === TEAM_STATS && PlayerSelector && (
          <StatsContent $isCompact={isCompact}>
            <TitleWrapper>
              <Typography type="title-xs">player stats</Typography>
            </TitleWrapper>
            <PlayerSelector
              onItemClick={onPlayerSelect}
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              size="small"
              type="horizontal"
              gameTitle={game}
            />
          </StatsContent>
        )}
        {view === TEAM_STATS && (
          <StatsContent $isCompact={isCompact}>
            <TeamStats
              onScroll={onScroll}
              type={type}
              matchId={matchId}
              gameId={gameId}
              selectedMatchGameIndex={selectedMatchGameIndex}
            />
          </StatsContent>
        )}
        {view === PLAYER_STATS && (
          <PlayerStats
            scrollTarget={ref}
            playerId={playerInfo.playerId}
            gatPlayerPosition={playerInfo.gatPlayerPosition}
            type={type}
            onScroll={onScroll}
            matchId={matchId}
            gameId={gameId}
            selectedMatchGameIndex={selectedMatchGameIndex}
            onBackClick={onBackClick}
            onPovViewStreamClick={onPovViewStreamClick}
          />
        )}
        {showBookCover && isDesktopOrGreater && (
          <BookCover
            bookCoverId={BOOKCOVER_ID}
            headline={statsMenuTheme.bookCover.headline}
            paragraphs={statsMenuTheme.bookCover.paragraphs}
            buttonLabel={statsMenuTheme.bookCover.buttonLabel}
            headerImage={statsMenuTheme.bookCover.headerImage}
          />
        )}
      </BodyContainer>
    </Container>
  );
};

StatsMenu.propTypes = {
  withHeader: PropTypes.bool,
  withPlayerSelector: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
  matchId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onViewChange: PropTypes.func,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  playerId: PropTypes.string,
  gatPlayerPosition: PropTypes.number,
  type: PropTypes.oneOf(['compact', 'compact-overlay', 'overlay', 'default']),
  onScroll: PropTypes.func,
  onPovViewStreamClick: PropTypes.func,
};

export default withTheme(StatsMenu, themes, 'statsMenu');
