import { forwardRef, memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import Matchup from 'tv-modules/Standings/Matchup/Matchup';
import Typography from 'znipe-elements/general/Typography/Typography';
import EmptyColumn from '../EmptyColumn/EmptyColumn';
import { getNumberOfEmptyColumns } from '../utils/handleData';
import { Title, ColumnContainer, Column, MatchWrapper, BracketItem } from '../Bracket.styles';
import { columnsPropType, UPPER_BRACKET } from '../utils/constants';

const BracketUpper = forwardRef(
  ({ title = UPPER_BRACKET, type, columns = [], selectedTeams = [] }, ref) => {
    const [hoveredTeam, setHoveredTeam] = useState('');
    const isDesktopOrGreater = useGetIsDesktopOrGreater();
    const typographyType = useMemo(
      () => (isDesktopOrGreater ? 'title-l' : 'title-m'),
      [isDesktopOrGreater],
    );

    const columnItems = useMemo(
      () =>
        columns.map((column, index) => {
          const { roundNumber, roundName, matchups = [] } = column || {};
          const isFirstColumn = index === 0;
          const isLastColumn = index === columns.length - 1;
          const prevColumn = index !== 0 ? (columns[index - 1] || {}).matchups || [] : [];
          const nextColumn =
            index !== columns.length - 1 ? (columns[index + 1] || {}).matchups || [] : [];
          const isPrevColumnEmpty = prevColumn.length === 0;
          const isNextColumnEmpty = nextColumn.length === 0;
          const previousWinnersTo = prevColumn.map(matchup => matchup.winnerTo);
          const nextWinnersTo = nextColumn.map(matchup => matchup.winnerTo);
          const currentIds = matchups.map(matchup => matchup.id);
          const isCrossBracket =
            previousWinnersTo.length === currentIds.length ||
            nextWinnersTo.length === currentIds.length;
          if (matchups.length === 0) {
            return (
              <EmptyColumn
                numberOfMatchupsPrev={prevColumn.length}
                numberOfColumns={getNumberOfEmptyColumns(columns, index)}
                hoveredTeam={hoveredTeam}
                prevColumn={prevColumn}
                isPrevColumnEmpty={isPrevColumnEmpty}
              />
            );
          }
          return matchups.map((matchup, matchupIndex) => {
            const canHaveRightArm = !isLastColumn && !isNextColumnEmpty;
            const sameNumberOfMatchUpsNext =
              canHaveRightArm && matchups.length === columns[index + 1]?.matchups.length;
            const sameNumberOfMatchUpsPrev =
              !isPrevColumnEmpty && matchups.length === columns[index - 1]?.matchups.length;
            const toUpper = matchupIndex % 2 === 1 && canHaveRightArm && !sameNumberOfMatchUpsNext;
            const toLower =
              (sameNumberOfMatchUpsNext ||
                (matchupIndex % 2 === 0 && index !== columns.length - 1)) &&
              canHaveRightArm;
            const matchupTeams = [matchup.teamOne.id, matchup.teamTwo.id].find(team =>
              selectedTeams.includes(team),
            );
            const label = roundName || `Round ${roundNumber}`;
            return (
              <BracketItem
                key={matchup.id}
                bracketType="upper"
                isPrevColumnEmpty={isPrevColumnEmpty}
                isNextColumnEmpty={isNextColumnEmpty}
                isFirstColumn={isFirstColumn}
                isLastColumn={isLastColumn}
                isCrossBracket={isCrossBracket}
                fromUpper={!isPrevColumnEmpty}
                fromLower={!isPrevColumnEmpty && !sameNumberOfMatchUpsPrev}
                toUpper={toUpper}
                toLower={toLower}
                sameNumberOfMatchupsNext={sameNumberOfMatchUpsNext}
                sameNumberOfMatchupsPrev={sameNumberOfMatchUpsPrev}
                teamOneId={matchup.teamOne.id}
                teamTwoId={matchup.teamTwo.id}
                hoveredTeam={matchupTeams || hoveredTeam}
                winner={matchup.winner}
              >
                {isFirstColumn ? (
                  <Matchup
                    tournamentType={type}
                    label={matchupIndex === 0 ? label : ''}
                    teamOne={matchup.teamOne}
                    teamTwo={matchup.teamTwo}
                    winner={matchup.winner}
                    setHoveredTeam={setHoveredTeam}
                    selectedTeams={selectedTeams}
                    matchId={matchup.matchId}
                  />
                ) : (
                  <MatchWrapper isPrevColumnEmpty={isPrevColumnEmpty}>
                    <Matchup
                      tournamentType={type}
                      label={matchupIndex === 0 ? label : ''}
                      teamOne={matchup.teamOne}
                      teamTwo={matchup.teamTwo}
                      winner={matchup.winner}
                      matchId={matchup.matchId}
                      setHoveredTeam={setHoveredTeam}
                      selectedTeams={selectedTeams}
                    />
                  </MatchWrapper>
                )}
              </BracketItem>
            );
          });
        }),
      [columns, hoveredTeam, type, selectedTeams],
    );

    return (
      <div data-testid="upper-bracket">
        <Title bracketType="upper" data-testid="upper-bracket-title">
          <Typography type={typographyType}>{title}</Typography>
        </Title>
        <ColumnContainer bracketType="upper" ref={ref}>
          {columnItems.map((item, index) => {
            const { roundNumber } = columns[index] || {};
            return (
              <Column key={roundNumber.toString()} data-testid={`column-${roundNumber}`}>
                {item}
              </Column>
            );
          })}
        </ColumnContainer>
      </div>
    );
  },
);

BracketUpper.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  columns: columnsPropType,
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default memo(BracketUpper);
