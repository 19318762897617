export default {
  proview: {
    images: [
      'https://assets.znipe.tv/lol/MSI_C9.jpg',
      'https://assets.znipe.tv/lol/MSI_RNG_Win.jpg',
      'https://assets.znipe.tv/lol/Pro_View_Team.jpg',
      'https://assets.znipe.tv/lol/Worlds_Dragon.jpg',
    ],
  },
  default: {
    images: [
      'https://assets.znipe.tv/csgo/subscription/login_first.jpg',
      'https://assets.znipe.tv/csgo/subscription/login_second.jpg',
      'https://assets.znipe.tv/csgo/subscription/login_third.jpg',
      'https://assets.znipe.tv/csgo/subscription/login_fourth.jpg',
    ],
  },
};
