import { memo, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import Input from 'znipe-elements/data-entry/Input/Input';
import Checkbox from 'znipe-elements/data-entry/Checkbox/Checkbox';
import Icon from 'znipe-elements/general/Icon/Icon';
import inputThemes from 'znipe-elements/data-entry/Input/Input.themes';
import CcvInformation from 'tv-modules/Payment/CcvInformation/CcvInformation';
import withStripeElements from 'tv-modules/Payment/CardForm/withStripeElements';
import Header from 'tv-modules/Payment/SharedStyles/Header';
import cardFormThemes from './CardForm.themes';
import LoadingButton from '../SharedStyles/LoadingButton';

import {
  Form,
  GridArea,
  CcvInfoWrapper,
  CardIconsWrapper,
  FreeMonthsInfo,
  StyledCardNumberElement,
  StyledCardCvcElement,
  StyledCardExpiryElement,
} from './CardForm.styles';

export const getCvcCode = cardBrand => {
  switch (cardBrand) {
    case 'visa':
      return 'CVV';
    case 'mastercard':
      return 'CVC';
    case 'amex':
      return 'CID';
    case 'discover':
      return 'CID';
    case 'diners':
      return 'CVV';
    case 'jcb':
      return 'CVV';
    case 'unionpay':
      return 'CVN';
    default:
      return 'CVC/CVV';
  }
};

export const convertHyphensToCamelCase = word => word.replace(/-([a-z])/g, g => g[1].toUpperCase());

const CardForm = ({
  header = '',
  freeMonthsText,
  loading = false,
  onSubmit,
  buttonLabel,
  termsOfServices = [],
}) => {
  const [cardInfo, setCardInfo] = useState({ empty: true });
  const [cardFocus, setCardFocus] = useState(false);
  const [cardTouched, setCardTouched] = useState(false);

  const [cvcInfo, setCvcInfo] = useState({ empty: true });
  const [cvcFocus, setCvcFocus] = useState(false);
  const [cvcTouched, setCvcTouched] = useState(false);

  const [expiryInfo, setExpiryInfo] = useState({ empty: true });
  const [expiryFocus, setExpiryFocus] = useState(false);
  const [expiryTouched, setExpiryTouched] = useState(false);

  const themeContext = useThemeContext();

  const validationResolver = useCallback(
    data => {
      const cardValidation = !!cardInfo.complete;
      const cvcValidation = !!cvcInfo.complete;
      const expiryValidation = !!expiryInfo.complete;

      const realData = {
        ...data,
        card: cardValidation,
        cvc: cvcValidation,
        expiry: expiryValidation,
      };

      const errors = Object.keys(realData).reduce((acc, key) => {
        const dataPoint = realData[key];
        if (!dataPoint) return { ...acc, [key]: 'Error' };
        return acc;
      }, {});
      const hasErrors = Object.keys(errors).length > 0;

      const values = hasErrors
        ? {}
        : {
            ...data,
            card: cardInfo,
            cvc: cvcInfo,
            expiry: expiryInfo,
          };

      return {
        values,
        errors,
      };
    },
    [cardInfo, cvcInfo, expiryInfo],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ validationResolver });

  const inputOptions = useMemo(
    () => ({
      style: {
        base: {
          fontFamily: themeContext.primaryFont,
          color: themeContext.stripeCardForm.textColor,
          fontSize: themeContext.stripeCardForm.fontSize,
          fontWeight: themeContext.stripeCardForm.fontWeight,
          lineHeight: '40px',
        },
        invalid: {
          color: themeContext.stripeCardForm.errorColor,
        },
      },
      placeholder: '',
    }),
    [themeContext],
  );

  const handleCardChange = useCallback(event => {
    setCardInfo(event);
    return event;
  }, []);
  const onCardFocus = useCallback(() => setCardFocus(true), []);
  const onCardBlur = useCallback(() => {
    setCardFocus(false);
    setCardTouched(true);
  }, []);

  const handleCvcChange = useCallback(event => {
    setCvcInfo(event);
    return event;
  }, []);
  const onCvcFocus = useCallback(() => setCvcFocus(true), []);
  const onCvcBlur = useCallback(() => {
    setCvcFocus(false);
    setCvcTouched(true);
  }, []);

  const handleExpiryChange = useCallback(event => {
    setExpiryInfo(event);
    return event;
  }, []);
  const onExpiryFocus = useCallback(() => setExpiryFocus(true), []);
  const onExpiryBlur = useCallback(() => {
    setExpiryFocus(false);
    setExpiryTouched(true);
  }, []);

  const cardError = errors.card || (cardTouched && !cardInfo.complete);
  const expiryError = errors.expiry || (expiryTouched && !expiryInfo.complete);
  const cvcError = errors.cvc || (cvcTouched && !cvcInfo.complete);

  let cardType = 'placeholderCreditCard';
  if (cardError) {
    cardType = 'errorCreditCard';
  } else if (cardInfo.brand) {
    // Convert hyphens to camelCase
    cardType = convertHyphensToCamelCase(cardInfo.brand);
  }

  const cvcCodeFormat = getCvcCode(cardInfo.brand);

  return (
    <>
      {header && <Header>{header}</Header>}
      <Form data-testid="card-form" onSubmit={handleSubmit(onSubmit)}>
        <GridArea gridArea="card-name">
          <Input
            {...register('name', { required: true })}
            label="Name on card"
            error={!!errors.name}
          />
        </GridArea>
        <GridArea gridArea="card">
          <Input
            label={cardError ? 'Invalid card' : 'Credit card'}
            error={!!cardError}
            onChange={handleCardChange}
            customInput={StyledCardNumberElement}
            options={inputOptions}
            onFocus={onCardFocus}
            onBlur={onCardBlur}
            focus={cardFocus}
            active={!cardInfo.empty}
          />
          <CardIconsWrapper>
            <Icon type={cardType} />
          </CardIconsWrapper>
        </GridArea>
        <GridArea gridArea="exp">
          <Input
            label="mm / yy"
            error={!!expiryError}
            onChange={handleExpiryChange}
            customInput={StyledCardExpiryElement}
            options={inputOptions}
            onFocus={onExpiryFocus}
            onBlur={onExpiryBlur}
            focus={expiryFocus}
            active={!expiryInfo.empty}
          />
        </GridArea>
        <GridArea gridArea="cvc">
          <Input
            label={cvcCodeFormat}
            error={!!cvcError}
            onChange={handleCvcChange}
            customInput={StyledCardCvcElement}
            options={inputOptions}
            onFocus={onCvcFocus}
            onBlur={onCvcBlur}
            focus={cvcFocus}
            active={!cvcInfo.empty}
          />
          <CcvInfoWrapper>
            <CcvInformation code={cvcCodeFormat} type={cardType} />
          </CcvInfoWrapper>
        </GridArea>
        <GridArea gridArea="tos">
          {termsOfServices.map((to, index) => {
            const name = `tos-${index}`;
            return (
              <Checkbox
                {...register(name, { required: true })}
                key={name}
                label={to}
                longLabel
                error={!!errors[name]}
              />
            );
          })}
        </GridArea>
        <GridArea gridArea="submit">
          <LoadingButton type="submit" loading={loading}>
            {buttonLabel}
          </LoadingButton>
        </GridArea>
      </Form>
      {freeMonthsText && (
        <FreeMonthsInfo data-testid="free-months-info">{freeMonthsText}</FreeMonthsInfo>
      )}
    </>
  );
};

CardForm.propTypes = {
  termsOfServices: PropTypes.arrayOf(PropTypes.node),
  onSubmit: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  freeMonthsText: PropTypes.string,
  header: PropTypes.string,
  loading: PropTypes.bool,
};

export const ThemedCardForm = withTheme(
  withTheme(memo(CardForm), inputThemes, 'stripeCardForm'),
  cardFormThemes,
  'cardForm',
);

export default withStripeElements(ThemedCardForm);
