import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';

const getThumbnailSize = ({ size }) => {
  switch (size) {
    case 'large':
      return css`
        width: 298px;
        height: 168px;
      `;
    case 'medium':
      return css`
        width: 271px;
        height: 152px;
      `;
    default:
      return css`
        width: 128px;
        height: 72px;
      `;
  }
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  background: ${props => props.theme.secondaryBackgroundColor};
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme, $isLoading }) =>
      $isLoading ? theme.secondaryBackgroundColor : theme.bg2Fade};
  }
`;

export const Thumbnail = styled.div`
  position: relative;
  display: flex;
  ${getThumbnailSize}
  > * {
    position: absolute;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex: 1;
  color: ${colors.white};
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      > div:first-of-type {
        margin-bottom: ${({ size }) => (size === 'small' ? 14 : 27)}px;
      }
    `}
`;

export const DescriptionWrapper = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  @media ${device.desktopLarge} {
    margin-top: 8px;
  }
  > p {
    margin: 0;
  }
`;
