import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CelebrationImageWrapper = styled.div`
  align-self: center;
  margin: 30px 0 60px 0;
  min-height: 98px;
`;
