import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { subscribeToProduct, editProductSubscriptionPaymentMethod } from 'tv-actions/old/products';

const usePaymentRequest = (productId, promoCode, period, currency, provider, edit = false) => {
  const dispatch = useDispatch();

  const sendEditPaymentRequest = useCallback(
    data => {
      const options = {};
      if (period) options.period = period;
      if (promoCode) options.promoCode = promoCode;
      if (currency) options.currency = currency;
      return dispatch(editProductSubscriptionPaymentMethod(productId, provider, data, options));
    },
    [productId, promoCode, dispatch, period, currency, provider],
  );

  const sendPaymentRequest = useCallback(
    data => {
      const options = {};
      if (period) options.period = period;
      if (promoCode) options.promoCode = promoCode;
      if (currency) options.currency = currency;
      return dispatch(subscribeToProduct(productId, provider, data, options));
    },
    [productId, promoCode, dispatch, period, currency, provider],
  );
  return edit ? sendEditPaymentRequest : sendPaymentRequest;
};

export default usePaymentRequest;
