import { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FlourishContainer from 'znipe-elements/data-display/FlourishContainer/FlourishContainer';
import Typography from 'znipe-elements/general/Typography/Typography';
import gradients from 'znipe-styles/gradients';
import Icon from 'znipe-elements/general/Icon/Icon';
import useDimensions from 'react-cool-dimensions';
import { breakpoints } from 'znipe-styles/breakpoints';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import colors from 'znipe-styles/colors';
import { Button, Container, TextWrapper } from './Header.styles';

const Header = ({
  active = false,
  collapsable = false,
  isCollapsed = false,
  onClose = () => null,
  onCollapse = () => null,
  heading,
}) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();

  const [compact, setIsCompact] = useState(true);
  const { ref } = useDimensions({
    breakpoints: { s: 0, m: breakpoints.tablet },
    useBorderBoxSize: true,
    onResize: ({ currentBreakpoint }) => setIsCompact(currentBreakpoint === 's'),
  });
  const textType = `title-${compact ? 's' : 'm'}`;
  const collapsed = collapsable && isCollapsed;
  const animationDuration = collapsed ? 0.1 : 0.5;
  const iconSize = useMemo(() => {
    if (isDesktopLargeOrGreater) return 24;
    if (isDesktopOrGreater) return 16;
    if (isTabletOrGreater) return 24;
    return 16;
  }, [isDesktopLargeOrGreater, isDesktopOrGreater, isTabletOrGreater]);

  return (
    <FlourishContainer
      activeBackground={gradients.deepForestGreen}
      animationDuration={animationDuration}
      active={active}
      shouldPreserveAspectRatio={false}
    >
      <Container
        data-testid="header-element"
        ref={ref}
        $collapsable={collapsable}
        $compact={compact}
      >
        {collapsable && (
          <Button onClick={onCollapse} data-testid="collapse-button">
            <Icon
              inline
              size={24}
              fillColor="white"
              type={isCollapsed ? 'chevronLeft' : 'chevronRight'}
            />
          </Button>
        )}
        {!collapsed && (
          <TextWrapper
            $compact={compact}
            $animationDuration={animationDuration}
            $active={active}
            data-testid="text-wrapper"
          >
            <Typography type={textType}>{heading}</Typography>
            <Typography type={textType} color="UIAccent">
              Active
            </Typography>
          </TextWrapper>
        )}
        {!collapsable && (
          <Button onClick={onClose} data-testid="close-button">
            <Icon inline size={iconSize} type="close" fillColor={colors.white} />
          </Button>
        )}
      </Container>
    </FlourishContainer>
  );
};

Header.propTypes = {
  onClose: PropTypes.func,
  onCollapse: PropTypes.func,
  active: PropTypes.bool,
  collapsable: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  heading: PropTypes.string.isRequired,
};

export default memo(Header);
