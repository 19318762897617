import styled, { css } from 'styled-components';
import { XSMALL, SMALL, MEDIUM, AUTO, CIRCLE } from 'znipe-constants/loading';

const autoSizing = css`
  width: 100%;
  height: 100%;
`;

const sizes = {
  [XSMALL]: 48,
  [SMALL]: 64,
  [MEDIUM]: 88,
  [AUTO]: undefined,
};

const fixedSizing = css<{
  $size: 'xsmall' | 'small' | 'medium' | 'auto' | number;
}>`
  ${({ $size }) => {
    if (typeof $size === 'number') {
      return `
      width: ${$size}px;
      height: ${$size}px;
    `;
    }

    return `
    width: ${sizes[$size]}px;
    height: ${sizes[$size]}px;
  `;
  }}
`;

const Skeleton = styled.div<{
  $size: 'xsmall' | 'small' | 'medium' | 'auto' | number;
  $shape: 'circle' | 'square';
}>`
  ${({ $size }) => ($size === AUTO ? autoSizing : fixedSizing)};
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  border-radius: ${({ $shape }) => ($shape === CIRCLE ? 50 : 0)}%;
`;

const ImageSkeleton = ({
  size = SMALL,
  shape = CIRCLE,
}: {
  size?: 'xsmall' | 'small' | 'medium' | 'auto' | number;
  shape?: 'circle' | 'square';
}) => <Skeleton data-testid="image-skeleton" $size={size} $shape={shape} />;

export default ImageSkeleton;
