import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayer from 'tv-selectors/players/makeGetPlayer';

const makeGetPlayerTeamId = () => {
  const getPlayer = makeGetPlayer();
  return createSelector([getPlayer], player => player.teamId || '');
};

export const useGetPlayerTeamId = props => useMemoizedGqlSelector(makeGetPlayerTeamId, props);

export default makeGetPlayerTeamId;
