import { useState, useEffect, useCallback } from 'react';
import ColorThief from 'colorthief';
import RGBToHSL from 'znipe-utils/misc/convertRGBtoHSL';
import { from, fromEvent } from 'rxjs';
import { toArray, take, first, map, switchMap } from 'rxjs/operators';
import convertHexToHSL from 'znipe-utils/misc/convertHexToHSL';
import colors from 'znipe-styles/colors';
import useThemeContext from './useThemeContext';

const googleProxyURL =
  'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';

const emptyObject = {};

const useColorThief = (imageSrc, gradientBaseColor, gradientOptions = emptyObject) => {
  const theme = useThemeContext();
  const endColor = gradientBaseColor ?? theme.secondaryBackgroundColor;
  const defaultStartColor = convertHexToHSL(colors.white, { l: 20 });

  const createGradiantString = useCallback(
    (startClr, endClr) => {
      const {
        type = 'linear',
        direction = 'to bottom',
        startPos = '0%',
        endPos = '100%',
      } = gradientOptions;
      return `${type}-gradient(${direction}, ${startClr} ${startPos}, ${endClr} ${endPos})`;
    },
    [gradientOptions],
  );

  const [gradient, setGradient] = useState(createGradiantString(defaultStartColor, endColor));

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only recalculate on image change
  useEffect(() => {
    if (!imageSrc) return () => null;
    const image = new Image();
    const imageEvent = fromEvent(image, 'load');
    const colorThiefPipe = imageEvent.pipe(
      switchMap(() => {
        try {
          const colorThief = new ColorThief();
          return from(colorThief.getColor(image));
        } catch (_e) {
          return [0, 0, 0];
        }
      }),
      take(3),
      toArray(),
      map(val => {
        const [h, s] = RGBToHSL(...val);
        const startColor = `hsl(${h}, ${s}%, ${20}%)`;
        setGradient(createGradiantString(startColor, endColor));
      }),
      first(),
    );

    const subscribe = colorThiefPipe.subscribe();
    image.crossOrigin = 'Anonymous';
    image.src = `${googleProxyURL}${encodeURIComponent(imageSrc)}`;
    return () => subscribe.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endColor, imageSrc]);

  return gradient;
};

export default useColorThief;
