export const isRiotDomain = providedUrl => {
  if (providedUrl) return providedUrl.indexOf('lolesports.com') !== -1;
  if (!global.document) return false;
  const url = providedUrl ?? window.location.hostname.toLowerCase();
  return url.indexOf('lolesports.com') !== -1;
};

export const isLoginPage = () => {
  const regex = /^\/login$/;
  if (!global.document) return false;
  const pathname = window.location.pathname.toLowerCase();
  return !!pathname.match(regex);
};
