import { memo, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import gradients from 'znipe-styles/gradients';
import useSubscribePageLink from 'tv-hooks/useSubscribePageLink';
import Button from 'znipe-elements/general/Button_deprecated/Button';

const SMALL = 'small';
const MEDIUM = 'medium';

const SubscribeButton = ({ size = SMALL }) => {
  const buttonSize = useMemo(() => (size === MEDIUM ? 'small' : 'xsmall'), [size]);
  const linkTo = useSubscribePageLink();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(linkTo);
  }, [linkTo, navigate]);
  return (
    <Button
      variant="secondary"
      size={buttonSize}
      icon="zap"
      iconGradient={gradients.glimmeringGold}
      onClick={handleClick}
      data-testid="buy-button"
    >
      SUBSCRIBE
    </Button>
  );
};

SubscribeButton.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM]),
};

export default memo(SubscribeButton);
