import { memo, useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import useWindowHeight from 'znipe-hooks/useWindowHeight';
import SoundOptions from 'tv-modules/Player/SoundOptions/SoundOptions';
import themes from './SubMenu.themes';
import OptionItem from './OptionItem';
import {
  SubMenuWrapper,
  SubMenuHeader,
  SubMenuHeaderButton,
  SubMenuBody,
  Title,
  IconWrapper,
  SoundOptionsWrapper,
} from './SubMenu.styles';

const SubMenu = ({
  matchId,
  title,
  optionItems = [],
  onClickHeader,
  onClickOptionItem = () => {},
  selected,
  testid,
  visible = false,
}) => {
  const windowHeight = useWindowHeight();
  const subMenuBodyRef = useRef(null);
  const [hasScroll, setHasScroll] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Recalculate if windowHeight changes
  useEffect(() => {
    const element = subMenuBodyRef.current;
    if (element) {
      setHasScroll(element.scrollHeight > element.clientHeight);
    }
  }, [windowHeight]);

  const subMenuBody = useMemo(() => {
    switch (title) {
      case 'quality':
        return (
          <>
            {optionItems.map(option => (
              <OptionItem
                key={option.label}
                label={option.label}
                value={option.value}
                selectOption={onClickOptionItem}
                selected={selected === option.label}
              />
            ))}
          </>
        );
      case 'sound':
        return (
          <SoundOptionsWrapper>
            <SoundOptions matchId={matchId} />
          </SoundOptionsWrapper>
        );
      case 'caster language':
        return (
          <>
            {optionItems.map(option => (
              <OptionItem
                key={option.label}
                label={option.label}
                value={option.label}
                selectOption={onClickOptionItem}
                selected={selected === option.label}
                uppercase
              />
            ))}
          </>
        );
      default:
        return null;
    }
  }, [title, matchId, onClickOptionItem, optionItems, selected]);

  const Header = useMemo(
    () => (onClickHeader ? SubMenuHeaderButton : SubMenuHeader),
    [onClickHeader],
  );

  return (
    <SubMenuWrapper visible={visible}>
      <Header
        onClick={onClickHeader}
        hasScroll={hasScroll}
        data-testid={`submenu-header-${testid}`}
        visible={visible}
      >
        {onClickHeader && (
          <IconWrapper data-testid="back-chevron">
            <Icon type="chevronLeft" size={24} />
          </IconWrapper>
        )}
        <Title>{title}</Title>
      </Header>
      <SubMenuBody
        ref={subMenuBodyRef}
        hasScroll={hasScroll}
        data-testid={`submenu-body-${testid}`}
      >
        {subMenuBody}
      </SubMenuBody>
    </SubMenuWrapper>
  );
};

const optionItemsShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

SubMenu.propTypes = {
  matchId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  optionItems: PropTypes.arrayOf(PropTypes.shape(optionItemsShape)),
  onClickHeader: PropTypes.func,
  onClickOptionItem: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testid: PropTypes.string,
  visible: PropTypes.bool,
};

export default withTheme(memo(SubMenu), themes, 'settingsSubMenu');
