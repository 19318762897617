import styled, { keyframes } from 'styled-components';
import prefers from 'znipe-styles/prefers';

export const ErrorImageWrapper = styled.div`
  height: 120px;
  margin-bottom: 32px;
  svg {
    height: 100%;
  }
`;

const BouncingDot = () => keyframes`
  0% {bottom: 0}
  25% {bottom: 5px}
  50%, 100% {bottom: 0}
`;

export const Dots = styled.div`
  display: inline;
  margin-left: 4px;
  & > span {
    position: relative;
    @media ${prefers.motion} {
      animation-name: ${() => BouncingDot()};
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }

  & > span:nth-child(2) {
    animation-delay: 0.15s;
  }

  & > span:nth-child(3) {
    animation-delay: 0.3s;
  }
`;
