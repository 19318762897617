import { Emoji } from '../components/Emojis/Emojis.types';
import usePackageCustomization from './usePackageCustomization';

const quickSelectGeneralShortcodes = ['pray', 'crying', 'eyes', 'ac', 'joy'];
const quickSelectCounterstrikeShortcodes = ['gg', 'lets-go', 'ez', 'bomb'];

const quickSelectShortcodes = [
  ...quickSelectCounterstrikeShortcodes,
  ...quickSelectGeneralShortcodes,
];

const useEmojis = (emojiSet?: string[]) => {
  const { emojiPackageSet = [] } = usePackageCustomization(emojiSet);

  const quickSelectEmojis = quickSelectShortcodes
    .reduce<Emoji[]>((acc, shortcode) => {
      const foundEmoji = emojiPackageSet.find(emoji => emoji.shortcode === shortcode);
      if (foundEmoji) return [...acc, foundEmoji];
      return acc;
    }, [])
    .slice(0, 5);

  const allEmojis: Emoji[] = emojiPackageSet.sort((a: Emoji, b: Emoji) =>
    a.shortcode.toUpperCase() < b.shortcode.toUpperCase() ? -1 : 1,
  );

  return { allEmojis, quickSelectEmojis };
};

export default useEmojis;
