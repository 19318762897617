/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';

type PopOverWrapperProps = {
  $width?: number;
  $height?: number;
};

export const Container = styled.div`
  position: relative;
`;

export const PopOverWrapper = styled.div<PopOverWrapperProps>`
  position: absolute;
  z-index: 2;
  right: 12px;
  bottom: 0;
  width: ${({ $width }) => ($width ? `${$width}px` : 'inherit')};
  height: ${({ $height }) => ($height ? `${$height}px` : 'inherit')};
`;

export const EmojiSearchInput = styled.input`
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  background: transparent;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  font-family: inherit;
  padding: unset;
  caret-color: ${({ theme }) => theme.UIAccent};
`;

export const InputWrapper = styled(Typography).attrs({
  forwardedAs: 'div',
  type: 'paragraph-s',
})`
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.containerStroke};
  background: rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  &:focus-within {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid ${({ theme }) => theme.UIAccent};
  }
  padding: 9px 15px;
  overflow: hidden;
  margin-bottom: 0;
`;
