import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import LOLPlayerSelector from 'tv-modules/Selector/LOL/PlayerSelector/PlayerSelector';
import Selector from 'tv-modules/Selector/Selector';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import Icon from 'znipe-elements/general/Icon/Icon';
import UnlockPremium from 'tv-modules/Premium/UnlockPremium/UnlockPremium';
import {
  Label,
  LabelContent,
  StreamContainerContentWrapper,
  AspectRatioBox,
  AspectRatioContentWrapper,
  IconWrapper,
  StreamContainer,
  themes,
} from './StreamItem.styles';

const StreamItem = ({
  matchId = '',
  selectedMatchGameIndex = null,
  streamId = '',
  isEmpty = false,
  gridArea = '',
  viewLayout,
  layoutPosition = 0,
  userSelectedStreamId,
  userSelectedStreamAlreadyInView = false,
  currentStreamIsSelected = false,
  limitAddStream = true,
  onStreamRemove = () => {},
  onStreamAdd = () => {},
}) => {
  const competitiors = useGetGameCompetitors({ matchId, selectedMatchGameIndex });
  const globalStreams = useGetGameGlobalStreams({ matchId, selectedMatchGameIndex });

  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();

  let playerId = '';
  let teamId = '';
  let gatPlayerPosition = 0;

  let globalStreamName = '';
  let globalStreamImage = '';

  // find player information using streamId

  competitiors.forEach((comp, compIndex) => {
    const currentTeamId = comp.teamId || '';
    const { lineup = [] } = comp;

    // check if the selected streamId exsits in current competitor lineup
    const findPlayerIndex = lineup.findIndex(player => player.streamId === streamId);

    // setup player information
    if (findPlayerIndex !== -1) {
      const playerInfo = lineup[findPlayerIndex];

      playerId = playerInfo.playerId;
      teamId = currentTeamId;
      // gatPlayerPosition does not exist in redux but is calculated using competitior and player array position

      gatPlayerPosition =
        compIndex > 0
          ? ((competitiors[compIndex] || {}).lineup || []).length + findPlayerIndex
          : findPlayerIndex;
    }
  });

  if (!playerId) {
    const selectedGlobalStream = globalStreams.find(stream => stream.streamId === streamId) || {};
    globalStreamName = selectedGlobalStream.name || '';
    globalStreamImage = selectedGlobalStream.imageSrc || '';
  }

  const isUserSelectedStream = userSelectedStreamId === streamId;

  const getLabelIconSize = useCallback(() => {
    if (isTabletOrGreater) return 22;
    if (isDesktopOrGreater) return 26;
    return 16;
  }, [isTabletOrGreater, isDesktopOrGreater]);

  const getStreamItemSize = useCallback(() => {
    if (isTabletOrGreater) {
      return 'medium';
    }
    return 'small';
  }, [isTabletOrGreater]);

  const isReplaceableStream = !isEmpty && !isUserSelectedStream && !userSelectedStreamAlreadyInView;
  const isSwappableStream = userSelectedStreamAlreadyInView && !isUserSelectedStream;
  const isRemoveableStream = isUserSelectedStream;
  const isLocked = isEmpty && limitAddStream;

  return (
    <StreamContainer
      data-testid="stream-container"
      isEmpty={isEmpty}
      gridArea={gridArea}
      viewLayout={viewLayout}
      onClick={isUserSelectedStream ? onStreamRemove : onStreamAdd}
    >
      <AspectRatioBox>
        <AspectRatioContentWrapper>
          <Label
            type="paragraph-m"
            isEmpty={isEmpty}
            isReplaceableStream={isReplaceableStream}
            isSwappableStream={isSwappableStream}
            isRemoveableStream={isRemoveableStream}
          >
            {isEmpty && 'Add stream'}
            {isReplaceableStream && 'Replace'}
            {isSwappableStream && 'Swap With'}
            {isRemoveableStream && (
              <LabelContent>
                Remove
                <IconWrapper size={getLabelIconSize()}>
                  <Icon type="close" size={getLabelIconSize()} />
                </IconWrapper>
              </LabelContent>
            )}
          </Label>
          <StreamContainerContentWrapper layoutPosition={layoutPosition} $isLocked={isLocked}>
            {isLocked && <UnlockPremium size={isDesktopOrGreater ? 'medium' : 'small'} />}
            {isEmpty && !limitAddStream && (
              <IconWrapper>
                <Icon type="plus" size={24} />
              </IconWrapper>
            )}
            {!isEmpty && playerId && (
              <LOLPlayerSelector
                type="horizontal"
                matchId={matchId}
                playerId={playerId}
                teamId={teamId}
                gatPlayerPosition={gatPlayerPosition}
                isSelected={currentStreamIsSelected}
                size={getStreamItemSize()}
              />
            )}
            {!isEmpty && globalStreamName && (
              <Selector
                type="horizontal"
                label={globalStreamName}
                image={globalStreamImage}
                imageType="image"
                isSelected={currentStreamIsSelected}
                size={getStreamItemSize()}
                isGlobalStream
              />
            )}
          </StreamContainerContentWrapper>
        </AspectRatioContentWrapper>
      </AspectRatioBox>
    </StreamContainer>
  );
};

StreamItem.propTypes = {
  matchId: PropTypes.string,
  selectedMatchGameIndex: PropTypes.number,
  streamId: PropTypes.string,
  isEmpty: PropTypes.bool,
  gridArea: PropTypes.string,
  viewLayout: PropTypes.number.isRequired,
  limitAddStream: PropTypes.bool,
  layoutPosition: PropTypes.number,
  userSelectedStreamId: PropTypes.string.isRequired,
  userSelectedStreamAlreadyInView: PropTypes.bool,
  currentStreamIsSelected: PropTypes.bool,
  onStreamAdd: PropTypes.func,
  onStreamRemove: PropTypes.func,
};

export default withTheme(memo(StreamItem), themes, 'streamItem');
