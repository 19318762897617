import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const emptyList = [];
const makeGetSubscriptions = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.subscriptions ?? emptyList);
};

export const useGetSubscriptions = () => useMemoizedSelector(makeGetSubscriptions);

export default makeGetSubscriptions;
