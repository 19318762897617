import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useLocation, useParams } from 'react-router-dom';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import SadPoro from 'tv-modules/Subscription/images/SadPoro';
import { SuccessGif, ErrorText, ButtonWrapper } from '../Shared/Shared';
import { SUCCESS, ERROR, firebaseErrors, defaultError } from '../constants';
import { ErrorImageWrapper, Dots } from './VerifyEmail.styles';

const VerifyEmail = ({ continuePath = '' }) => {
  const [state, setState] = useState('');
  const [error, setError] = useState({});
  const { state: locationState } = useLocation();
  const { packageSlug } = useParams();
  const home = useMemo(() => (packageSlug ? `/${packageSlug}/home` : '/home'), [packageSlug]);

  useEffect(() => {
    const verificationCode = locationState?.actionCode ?? '';
    firebase
      .auth()
      .applyActionCode(verificationCode)
      .then(() => setState(SUCCESS))
      .catch(({ code }) => {
        setState(ERROR);
        setError(firebaseErrors[code]);
      });
  }, [locationState?.actionCode]);

  const ContinueButton = useMemo(
    () => (
      <ButtonWrapper>
        <Button variant="solid-color" size="small" to={continuePath ?? home}>
          Continue to site
        </Button>
      </ButtonWrapper>
    ),
    [continuePath, home],
  );

  if (state === SUCCESS) {
    return (
      <>
        <SuccessGif />
        <Typography type="heading-m">Email verified</Typography>
        {ContinueButton}
      </>
    );
  }

  if (state === ERROR) {
    return (
      <>
        <ErrorImageWrapper>
          <SadPoro />
        </ErrorImageWrapper>
        <ErrorText type="heading-m" align="center">
          {error.message ?? defaultError}
        </ErrorText>
        {ContinueButton}
      </>
    );
  }

  return (
    <Typography type="heading-m">
      Verifying email
      <Dots>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Dots>
    </Typography>
  );
};

VerifyEmail.propTypes = {
  continuePath: PropTypes.string,
};

export default VerifyEmail;
