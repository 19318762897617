import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import { Container, SelectIconWrapper, ImageWrapper } from './DropdownItem.styles';

const DropdownItem = ({
  id,
  title,
  subtitle = '',
  image = '',
  icon = '',
  selected = false,
  onSelect,
  isMultiSelect = false,
}) => {
  const { primaryTextColor, secondaryTextColor } = useThemeContext();

  const handleOnClick = useCallback(() => onSelect(id), [id, onSelect]);

  const handleEnterPress = useCallback(
    e => {
      if (e.keyCode === 13) handleOnClick();
    },
    [handleOnClick],
  );

  return (
    <Container
      selected={selected}
      isMultiSelect={isMultiSelect}
      data-testid="dropdown-item-container"
      onClick={handleOnClick}
      onKeyDown={handleEnterPress}
      role="menuitem"
      tabIndex={0}
      aria-labelledby="dropdown-item-title"
    >
      {image && (
        <ImageWrapper data-testid="image-wrapper">
          <img src={image} alt={title} width={32} height={32} />
        </ImageWrapper>
      )}
      {icon && <Icon type={icon} size={32} inline />}
      <div id="dropdown-item-title">
        <Typography color={primaryTextColor} type="title-s" dataTestId="title">
          {title}
        </Typography>
        {subtitle && (
          <Typography color={secondaryTextColor} type="paragraph-s" dataTestId="subtitle">
            {subtitle}
          </Typography>
        )}
      </div>
      {isMultiSelect && selected && (
        <SelectIconWrapper data-testid="dropdown-item-selected-icon">
          <Icon type="circleCheck" size={16} inline />
        </SelectIconWrapper>
      )}
    </Container>
  );
};

DropdownItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default memo(DropdownItem);
