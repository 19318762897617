import defaultTheme from './default.theme';
import proview from './proview.theme';
import esportal from './esportal.theme';
import efg from './efg.theme';
import faceit from './faceit.theme';
import svenskaCupen from './svenskaCupen.theme';
import znipebase from './znipebase.theme';
import ubisoft from './ubisoft.theme';

const themesMap = {
  proview,
  esportal,
  efg,
  'svenska-cupen': svenskaCupen,
  faceit,
  znipebase,
  ubisoft,
  znipe: defaultTheme,
  default: defaultTheme,
};

export type ThemeName = keyof typeof themesMap;

export default themesMap;
