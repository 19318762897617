import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

const SMALL_SIDEBAR_SIZE = '352';
const DESKTOP_LARGE_SIDEBAR_SIZE = '480';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ isDesktop, theme }) =>
    isDesktop ? theme.secondaryBackgroundColor : theme.tertiaryBackgroundColor};
  ${props =>
    props.isDesktop &&
    css`
      margin-top: auto;
    `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 16px;
`;

export const MainWrapper = styled.div`
  background: ${colors.black};
  display: flex;
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  max-width: ${({ $isCompact }) => ($isCompact ? '177vh' : 'calc(165vh - 237px)')};
  margin: auto;
`;

export const Sidebar = styled.div`
  background: ${props => props.theme.primaryBackgroundColor};
  height: 100%;
  width: ${SMALL_SIDEBAR_SIZE}px;
  max-height: calc(100vh - 120px);
  overflow: auto;
  @media ${device.desktopLarge} {
    width: ${DESKTOP_LARGE_SIDEBAR_SIZE}px;
  }
`;

export const SideBarContainer = styled.div`
  position: relative;
  ${({ $show }) =>
    $show &&
    css`
      width: ${SMALL_SIDEBAR_SIZE}px;
      @media ${device.desktopLarge} {
        width: ${DESKTOP_LARGE_SIDEBAR_SIZE}px;
      }
    `}
`;

export const MobileContentWrapper = styled.div`
  ${props =>
    !props.isPlaylist &&
    css`
      padding-top: 8px;
    `}
`;

export const TabWrapper = styled.div`
  margin-bottom: 16px;
`;

export const MobileContent = styled.div`
  max-width: 656px;
  margin: auto;
  padding: 0 8px;
  @media ${device.desktopLarge} {
    padding: 0 16px;
  }
`;

export const HighlightListWrapper = styled.div`
  padding: 16px 16px 0 16px;
  @media ${device.desktopLarge} {
    padding: 23px 16px 0 16px;
  }
`;
