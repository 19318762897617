import { memo, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import Measure from 'react-measure';
import Icon from 'znipe-elements/general/Icon/Icon';
import PlayerImage from 'znipe-elements/data-display/PlayerImage/PlayerImage';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import useColorThief from 'znipe-hooks/useColorThief';
import breakpoints from './playerCardBreakpoints';
import {
  Container,
  Content,
  Label,
  EllipsisTypography,
  IconWrapper,
  Marker,
  BackgroundLayer,
  PlayerImageGradient,
} from './PlayerCard.styles';
import {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
  LEFT,
  RIGHT,
  elementSizeSet,
  animationDuration,
} from './constants';
import PlayerCardLoading from './PlayerCard.loading';

const deprecatedDefaultPlayerImage =
  'https://assets.znipe.tv/teams/default/players/genericplayer.svg';
const defaultPlayerImageSmall = 'https://assets.znipe.tv/proview/players/default-player-img.png';
const defaultPlayerImage = 'https://assets.znipe.tv/players/default-player-img-medium.png';

const invalidImages = [deprecatedDefaultPlayerImage, defaultPlayerImageSmall];

const AnimatedBackground = animated(BackgroundLayer);

const PlayerCard = ({
  teamLogo = '',
  playerImage,
  label = '',
  isHomeTeam,
  noMarker = false,
  transparentBackground = false,
  backgroundAlignment = LEFT,
  noBottomGradient = false,
  size,
  canOverflow = false,
  iconType = '',
  roundedCorners = false,
  skipAnimation = false,
  onClick = () => {},
}) => {
  const useDefaultPlayerImage =
    playerImage?.indexOf('teams/test1') !== -1 || invalidImages.includes(playerImage);
  const selectedPlayerImage = useDefaultPlayerImage ? defaultPlayerImage : playerImage;

  const [containerWidth, setContainerWidth] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const backgroundGradient = useColorThief(teamLogo);
  const { hasLoaded: playerImageLoaded } = useOnImageLoad(playerImage);
  const { hasLoaded: teamLogoLoaded } = useOnImageLoad(teamLogo);

  const animationBackground = useSpring({
    height: isHovered ? '100%' : '0%',
    config: { tension: animationDuration, friction: 60 },
    immediate: prefersReducedMotion,
  });

  const handlePlayerCardResize = evt => {
    const {
      bounds: { width },
    } = evt;

    setContainerWidth(width);
  };

  const elementSize = useMemo(() => {
    switch (size) {
      case SMALL:
      case MEDIUM:
      case LARGE:
      case XLARGE:
        return elementSizeSet[size];
      default:
        return elementSizeSet[SMALL];
    }
  }, [size]);

  const dynamicElementSize = useMemo(() => {
    if (containerWidth <= breakpoints.xsmall) return elementSizeSet[SMALL];
    if (containerWidth <= breakpoints.small) return elementSizeSet[MEDIUM];
    if (containerWidth <= breakpoints.medium) return elementSizeSet[LARGE];
    return elementSizeSet[XLARGE];
  }, [containerWidth]);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  const componentHasLoaded = useMemo(
    () => Boolean(playerImageLoaded && teamLogoLoaded),
    [playerImageLoaded, teamLogoLoaded],
  );

  const showFullComponent = useLoadingDelay(componentHasLoaded);

  if (!showFullComponent) {
    return (
      <PlayerCardLoading
        transparentBackground={transparentBackground}
        size={size}
        roundedCorners={roundedCorners}
      />
    );
  }

  return (
    <Measure bounds onResize={handlePlayerCardResize}>
      {({ measureRef }) => (
        <Container
          data-testid="player-card"
          isHomeTeam={isHomeTeam}
          transparentBackground={transparentBackground}
          noMarker={noMarker}
          size={size}
          containerWidth={containerWidth}
          canOverflow={canOverflow}
          roundedCorners={roundedCorners}
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={measureRef}
        >
          {isHovered && noMarker && !skipAnimation && (
            <AnimatedBackground style={animationBackground} gradient={backgroundGradient} />
          )}
          <Content $hasLabel={label} $markerSize={noMarker ? 0 : elementSize.marker}>
            <Marker
              isHomeTeam={isHomeTeam}
              size={size}
              containerWidth={containerWidth}
              noMarker={noMarker}
              data-testid="marker"
            />
            <Label data-testid="player-card-label">
              {size ? (
                <EllipsisTypography
                  dataTestId="masthead-title"
                  type={elementSize.typography}
                  containerWidth={containerWidth}
                >
                  {label}
                </EllipsisTypography>
              ) : (
                <EllipsisTypography
                  dataTestId="masthead-title"
                  type={dynamicElementSize.typography}
                  containerWidth={containerWidth}
                >
                  {label}
                </EllipsisTypography>
              )}
            </Label>
            <PlayerImage
              image={selectedPlayerImage}
              teamLogo={teamLogo}
              type={backgroundAlignment}
            />
            {!noBottomGradient && <PlayerImageGradient />}
            {iconType && size ? (
              <IconWrapper spacing={elementSize.iconMargin}>
                <Icon type={iconType} size={elementSize.icon} />
              </IconWrapper>
            ) : (
              <IconWrapper spacing={dynamicElementSize.iconMargin}>
                <Icon type={iconType} size={dynamicElementSize.icon} />
              </IconWrapper>
            )}
          </Content>
        </Container>
      )}
    </Measure>
  );
};

PlayerCard.propTypes = {
  teamLogo: PropTypes.string,
  label: PropTypes.string,
  isHomeTeam: PropTypes.bool,
  playerImage: PropTypes.string.isRequired,
  noMarker: PropTypes.bool,
  canOverflow: PropTypes.bool,
  transparentBackground: PropTypes.bool,
  backgroundAlignment: PropTypes.oneOf([LEFT, RIGHT]),
  noBottomGradient: PropTypes.bool,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE, XLARGE]),
  iconType: PropTypes.string,
  roundedCorners: PropTypes.bool,
  skipAnimation: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(PlayerCard);
