import styled, { keyframes, css } from 'styled-components';
import prefers from 'znipe-styles/prefers';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';
import { TEXT, VIDEO, DEFAULT } from './Shimmer.constants';

const placeholderShimmerReverse = keyframes`
  0% {
    background-position: 150% 0%;
  }
  75%, 100% {
    background-position: -150% 0%;
  }
`;

const placeholderShimmer = keyframes`
  0% {
		background-position: -150% 0%;
  }
  75%, 100% {
		background-position: 150% 0%;
  }
`;

const placeholderShimmerMobile = keyframes`
  0% {
    background-position: -150% 0%;
  }
  75%, 100% {
    background-position: 250% 0%;
  }
`;

const absoluteStyles = css`
  position: absolute;
`;

const shimmerWidth = '225px';
const rotateDeg = '24deg';

const videoCss = css`
  transform: rotate(${rotateDeg});
  background-size: calc(${shimmerWidth} / 2);
  transform-origin: 50% 0%;
  width: 200%;
  height: 200%;
  @media ${device.desktop} {
    transform-origin: 100% 0%;
    background-size: ${shimmerWidth} 200%;
    width: 125%;
    height: 200%;
  }
`;

const textCss = css`
  background-size: ${shimmerWidth};
  transform: rotate(${rotateDeg});
  transform-origin: 100% 0%;
  width: 125%;
  height: 200px;
`;

const defaultCss = css`
  background-size: ${shimmerWidth};
  width: 100%;
  height: 100%;
`;

const styles = {
  [TEXT]: textCss,
  [VIDEO]: videoCss,
  [DEFAULT]: defaultCss,
};

const ShimmerDiv = styled.div`
  @media ${prefers.motion} {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      267.88deg,
      rgba(255, 255, 255, 0) 1.93%,
      rgba(255, 255, 255, 0.896907) 28.05%,
      ${colors.white} 50.65%,
      rgba(255, 255, 255, 0.905263) 72.75%,
      rgba(255, 255, 255, 0) 98.36%
    );
    background-repeat: no-repeat;
    background-attachment: fixed;
    ${({ $type }) => styles[$type]};
    opacity: 0.05;
    animation-duration: ${({ $duration }) => $duration}s;
    @media ${device.mobilePortraitSmall} {
      animation-duration: ${({ $duration }) => $duration / 4}s;
    }
    @media ${device.tablet} {
      animation-duration: ${({ $duration }) => $duration / 2}s;
    }
    @media ${device.desktopLarge} {
      animation-duration: ${({ $duration }) => $duration}s;
    }
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-play-state: running;
    animation-name: ${({ $reverseShimmer }) =>
      $reverseShimmer ? placeholderShimmerReverse : placeholderShimmerMobile};
    @media ${device.desktop} {
      animation-name: ${({ $reverseShimmer }) =>
        $reverseShimmer ? placeholderShimmerReverse : placeholderShimmer};
    }
    ${({ $overlay }) =>
      $overlay &&
      css`
        ${absoluteStyles}
      `};
  }
`;

export default ShimmerDiv;
