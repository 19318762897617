import produce from 'immer';
import deepmerge from 'deepmerge';
import {
  SET_VIDEOS,
  SET_PLAYLIST_VIDEOS,
  SET_STAGE_VIDEOS,
  SET_VIDEO_TOKENS,
} from 'tv-constants/redux';

export const initialState = {};

const tokensStore = (prevVideo = {}) => {
  const { tokenExpireTime, hlsToken, dashToken } = prevVideo;
  if (!tokenExpireTime || tokenExpireTime < Date.now()) return {};
  return { tokenExpireTime, hlsToken, dashToken };
};

const videosReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_STAGE_VIDEOS:
    case SET_VIDEOS: {
      Object.entries(payload).forEach(([key, value]) => {
        const prevTokens = tokensStore(state[key]);
        state[key] = value;
        Object.entries(prevTokens).forEach(([tokenKey, tokenValue]) => {
          if (!state[key][tokenKey]) state[key][tokenKey] = tokenValue; // DO not add the old token if a new is set
        });
      });
      return state;
    }
    case SET_PLAYLIST_VIDEOS: {
      Object.entries(payload).forEach(([key, value]) => {
        const prevTokens = tokensStore(state[key]);
        state[key] = value.video;
        Object.entries(prevTokens).forEach(([tokenKey, tokenValue]) => {
          if (!state[key][tokenKey]) state[key][tokenKey] = tokenValue; // DO not add the old token if a new is set
        });
      });
      return state;
    }

    case SET_VIDEO_TOKENS: {
      const streams = payload || {};
      state = deepmerge(state, streams);
      return state;
    }

    default:
      return state;
  }
}, initialState);

export default videosReducer;
