import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};
const emptyArray = [];
const getPackages = state => state.packages ?? emptyObject;
const getPackageNameFromProps = (_, props) => props.packageName ?? '';

const makeGetPackageProducts = () =>
  createSelector(
    [getPackages, getPackageNameFromProps],
    (packages, packageName) => packages[packageName]?.products ?? emptyArray,
  );

export const useGetPackageProducts = props => useMemoizedGqlSelector(makeGetPackageProducts, props);

export default makeGetPackageProducts;
