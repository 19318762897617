import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSubscribedTeamsIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedTeamsIds';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const emptyArray = [];

const makeGetSubscribedTeams = () => {
  const getTeams = makeGetTeams();
  const getSubscribedTeamsIds = makeGetSubscribedTeamsIds();
  return createSelector([getTeams, getSubscribedTeamsIds], (allTeams, subscribedTeams) => {
    if (subscribedTeams.length === 0) return emptyArray;
    return subscribedTeams.map(id => ({ ...allTeams?.[id], _type: 'teams' }));
  });
};

export const useGetSubscribedTeams = props => useMemoizedGqlSelector(makeGetSubscribedTeams, props);

export default makeGetSubscribedTeams;
