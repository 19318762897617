import { useCallback } from 'react';
import usePrevious from 'znipe-hooks/usePrevious';
import isEqual from 'lodash.isequal';
import { EMOJI_TAG_REGEX } from '../ChatLockup.constants';

const useShouldUpdateContent = (rawContent: string[]) => {
  const previousRawContent = usePrevious<string[]>(rawContent);
  return useCallback(() => {
    // If we added or removed and emoji
    if (rawContent.length !== previousRawContent?.length) {
      return true;
    }
    // If we only have a <br>
    if (rawContent.length === 1 && rawContent[0] === '<br>') {
      return true;
    }
    // We only had a <br> before
    if (previousRawContent.length === 1 && previousRawContent[0] === '<br>') {
      return true;
    }
    // If we added a new <br> to the end
    if (
      rawContent[rawContent.length - 1].endsWith('<br>') &&
      !previousRawContent[rawContent.length - 1].endsWith('<br>')
    ) {
      return true;
    }

    const changedSections = rawContent.reduce((acc, value, index) => {
      if (value === previousRawContent?.[index]) return acc;
      return acc + 1;
    }, 0);

    // An existing emoji was changed
    const isEmoji = (value: string) => !!value.match(EMOJI_TAG_REGEX);
    const emojisChanged = !isEqual(rawContent.filter(isEmoji), previousRawContent.filter(isEmoji));

    return emojisChanged || changedSections > 1;
  }, [previousRawContent, rawContent]);
};

export default useShouldUpdateContent;
