import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetPlayerName } from 'tv-selectors/players/makeGetPlayerName';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useCSGOStatsEpic, PLAYER_KDA as moduleType } from 'tv-epics/csgo/csgoStatsEpic';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import Selector from '../../Selector';

const PlayerSelector = ({
  size = 'small',
  playerId,
  gameId,
  teamId,
  streamId = '',
  teamSide = 'none',
  isSelected = false,
  onClick,
  onHover = () => {},
  type = 'vertical',
  isLocked = false,
  showKda = false,
}) => {
  const kda = useIntensiveSelector(state => state?.players?.[`${gameId}_${playerId}`]?.stats || {});

  const playerName = useGetPlayerName({ playerId });
  const playerImage = useGetPlayerImage({ playerId });
  const playerTeamLogo = useGetSelectedTeamLogo({ teamId });

  const variables = useMemo(
    () => ({
      playerIds: [playerId],
      kda: true,
      gameId,
    }),
    [gameId, playerId],
  );

  useCSGOStatsEpic(gameId, moduleType, variables);

  return (
    <Selector
      label={playerName}
      kda={showKda ? kda : undefined}
      teamSide={teamSide}
      teamLogo={playerTeamLogo}
      image={playerImage}
      isSelected={isSelected}
      onClick={onClick}
      onHover={onHover}
      size={size}
      streamId={streamId}
      type={type}
      isLocked={isLocked}
      game="csgo"
      streamType="player"
    />
  );
};

PlayerSelector.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf(['vertical', 'horizontal', 'slim-horizontal']),
  teamSide: PropTypes.oneOf(['none', 'teamOne', 'teamTwo']),
  playerId: PropTypes.string.isRequired,
  gameId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  streamId: PropTypes.string,
  isSelected: PropTypes.bool,
  isLocked: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  showKda: PropTypes.bool,
};

export default memo(PlayerSelector);
