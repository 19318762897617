import isBrowser from 'znipe-utils/web/isBrowser';
// Config file that reads from system environments on server-side and global config on client side.
const config = {
  NODE_ENV: process.env.NODE_ENV,
  BIGSMOKE_URL: process.env.BIGSMOKE_URL,
  ...((isBrowser() ? window : global).process?.env ?? {}),
};

if (config.NODE_ENV !== 'production') {
  config.BIGSMOKE_URL = config.BIGSMOKE_URL || 'wss://bigsmoke.staging.znipe.tv';
}

export default config;
