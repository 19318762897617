import { Children, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ProductionStatusLabel from 'znipe-elements/data-display/ProductionStatusLabel/ProductionStatusLabel';
import ImageSkeleton from 'znipe-elements/feedback/ImageSkeleton/ImageSkeleton';
import TextSkeleton, { XLARGE } from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import CardLoading from 'tv-elements/feedback/CardLoading/CardLoading';
import { STREAM_SELECTOR } from 'tv-elements/feedback/CardLoading/CardLoading.constants';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import {
  themes,
  ButtonArea,
  FullWidthContainer,
  FullWidthContentWrapper,
  FullWidthMiddleAreaLoading,
  MobileContainer,
  MobileContentWrapper,
  MobileSelectorLoading,
} from './StageMatchBar.styles';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${({ $noPadding }) => ($noPadding ? 0 : 16)}px;
`;

const MatchInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ $isLive }) =>
    $isLive
      ? css`
          & > *:last-child {
            margin-bottom: 50px;
          }
        `
      : css`
          & > *:first-child {
            margin-top: 30px;
          }
        `}
`;

const StreamsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  gap: 8px;
`;

const StageMatchbarLoading = ({ isLive = false, isDesktop = false }) => {
  const {
    loading: { numberOfStreamSelectors },
  } = useThemeContext();

  const TeamStreamsLoading = useMemo(
    () => (
      <StreamsContainer>
        {Children.toArray(
          [...Array(numberOfStreamSelectors)].map(() => <CardLoading type={STREAM_SELECTOR} />),
        )}
      </StreamsContainer>
    ),
    [numberOfStreamSelectors],
  );

  const MatchAndGameInfoLoading = useMemo(
    () => (
      <MatchInfoContainer $isLive={isLive}>
        {isLive && <ProductionStatusLabel label="live" size="xsmall" />}
        <TextSkeleton size={XLARGE} width={197} />
      </MatchInfoContainer>
    ),
    [isLive],
  );

  if (isDesktop) {
    return (
      <FullWidthContainer>
        <FullWidthContentWrapper>
          <ButtonArea $align="left">
            <ButtonWrapper>
              <ImageSkeleton size={40} shape="circle" />
            </ButtonWrapper>
            <ButtonWrapper>
              <ImageSkeleton size={40} shape="circle" />
            </ButtonWrapper>
          </ButtonArea>
          <FullWidthMiddleAreaLoading>
            {TeamStreamsLoading}
            {MatchAndGameInfoLoading}
            {TeamStreamsLoading}
          </FullWidthMiddleAreaLoading>
          <ButtonArea $align="right">
            <ButtonWrapper>
              <ImageSkeleton size={40} shape="circle" />
            </ButtonWrapper>
          </ButtonArea>
        </FullWidthContentWrapper>
      </FullWidthContainer>
    );
  }

  return (
    <MobileContainer>
      <MobileContentWrapper>
        <ButtonArea $align="right">
          <MobileSelectorLoading />
        </ButtonArea>
        {MatchAndGameInfoLoading}
        <ButtonArea $align="right">
          <ButtonWrapper $noPadding>
            <ImageSkeleton size={40} shape="circle" />
          </ButtonWrapper>
        </ButtonArea>
      </MobileContentWrapper>
    </MobileContainer>
  );
};

StageMatchbarLoading.propTypes = {
  isLive: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withTheme(StageMatchbarLoading, themes, 'loading');
