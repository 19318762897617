import { error } from 'znipe-logger';

const formatDateYYYYMMDD = (date, includeYear = true, separator = '.') => {
  try {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    const year = `${date.getFullYear()}`;

    const formattedMonth = month.length < 2 ? `0${month}` : month;
    const formattedDay = day.length < 2 ? `0${day}` : day;
    const array = [formattedMonth, formattedDay];
    if (includeYear) {
      array.unshift(year);
    }
    return array.join(separator);
  } catch (_e) {
    error('Invalid date object passed to formatDateYYYYMMDD');
    return '0000.00.00';
  }
};

export default formatDateYYYYMMDD;
