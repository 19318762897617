import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';
import prefers from 'znipe-styles/prefers';

export const buttonBasicStyles = ({ theme }) => {
  const buttonStyle = theme.screenOverlay.button;
  return css`
    border: none;
    outline: none;
    color: ${buttonStyle.textColor};
    background-color: transparent;
    cursor: pointer;
    font-weight: ${buttonStyle.fontWeight};
    font-size: ${buttonStyle.fontSize};
    line-height: ${buttonStyle.lineHeight};
  `;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 5;
  @media ${prefers.motion} {
    transition: opacity 0.5s ease;
  }
  background-color: rgba(
    0,
    0,
    0,
    ${({ isVisible, subMenuOpen }) => {
      if (!isVisible) return 0;
      if (subMenuOpen) return 0.75;
      return 0.5;
    }}
  );
  height: 100%;
  @media ${device.desktop} {
    z-index: 0;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  }
`;

export const Wrapper = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  @media ${prefers.motion} {
    transition: opacity 0.5s ease;
  }
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 5px 5px 0 5px;
  z-index: 2;
  @media ${device.mobilePortrait} {
    padding: 10px;
  }
  @media ${device.laptop} {
    position: relative;
    justify-content: flex-end;
    padding: 22px 34px 0 0;
  }
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  max-height: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: auto;
  padding: 0;
  @media ${device.mobilePortrait} {
    padding: 17px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: bottom;
`;

export const MainContent = styled.div`
  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: ${props => props.maxWidth || '380px'};
  margin: auto;
  max-height: 100%;
  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'auto')};
  pointer-events: auto;
  @media ${device.mobilePortrait} {
    padding: 0 10px;
    max-width: 100%;
    margin: 0 10px;
  }
  @media ${device.desktop} {
    max-width: 1100px;
  }
  @media ${device.desktopLarge} {
    max-width: 1500px;
    margin: 0;
  }
`;

export const InformationWrapper = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding: 7px;
  color: ${colors.white};
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr;
    align-items: center;
    gap: 0px 14px;
    z-index: 1;
  }

  @media ${device.desktop} {
    position: absolute;
  }
`;

export const Title = styled.h4`
  color: ${colors.white};
  text-align: right;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
  padding: 0;
`;

export const HeaderActionsWrapper = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
  ${buttonBasicStyles}
  @media ${device.tabletPotrait} {
    font-size: 14px;
  }
`;

export const ActionLabel = styled.div`
  margin-left: 9px;
`;

export const HeaderLeftWrapper = styled.div`
  display: flex;
`;

export const HeaderPlayerActionsWrapper = styled.div`
  display: flex;
`;

export const HeaderIconWrapper = styled.button.attrs({
  type: 'button',
})`
  padding: 3px;
  margin-left: 9px;
  border-radius: 14px;
  ${buttonBasicStyles}
  @media ${prefers.motion} {
    transition: transform 0.5s ease;
  }
  transform: ${({ isActive }) => (isActive ? 'rotate(90deg)' : 'rotate(0deg)')};
  border: 1px solid ${({ isActive, theme }) => (isActive ? theme.UIAccent : 'transparent')};
`;

export const MainItemWrapper = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const SettingMenuWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 5px;
  right: 40px;
  @media ${device.mobilePortrait} {
    right: 48px;
    top: 12px;
  }
  @media ${device.laptop} {
    right: 70px;
    top: 12px;
  }
  @media ${device.desktop} {
    right: 12px;
    top: 50px;
  }
`;

export const Sidebar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${props => `repeat(${props.numberOfElements}, auto)`};
  gap: 0;
  z-index: 1;
  pointer-events: auto;
  @media ${device.desktop} {
    gap: 24px 0;
  }
  @media ${device.desktopLarge} {
    gap: 32px 0;
  }
  ${props =>
    props.isCompactPlayer &&
    css`
      margin: auto 0px 74px 7px;
    `}

  ${props =>
    props.hideOnExtraLargeScreen &&
    css`
      @media ${device.desktopExtraLarge} {
        pointer-events: none;
        opacity: 0;
      }
    `}
`;
