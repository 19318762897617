const LOLEpicMonstersSpawnRules = [
  {
    monsterType: 'riftHerald',
    maxPerGame: 2,
    firstSpawn: 480000, // 08:00
    timeToRespawn: 360000, // 06:00
    noRespawnAfter: 1185000, // 19:45
  },
  {
    monsterType: 'baron',
    maxPerGame: Infinity,
    firstSpawn: 1200000, // 20:00
    timeToRespawn: 360000, // 06:00
    noRespawnAfter: Infinity,
  },
];

export default LOLEpicMonstersSpawnRules;
