const isRenderingOnServer = typeof window === 'undefined';

declare global {
  interface Window {
    msMatchMedia?: unknown;
  }
}

const isTouchDevice = () => {
  if (isRenderingOnServer) return false;
  const matchMedia = window.matchMedia || window.msMatchMedia;
  if (matchMedia) return matchMedia('(pointer:coarse)').matches;
  return false;
};

export default isTouchDevice;
