import { normalizeData } from 'znipe-utils/normalize/normalizeData';
import { rootSchema } from 'tv-schema/rootSchema.normalizr';

const formatFieldValue = value => [{ value }];

const formatPlayerNode = items => {
  const edges = [];
  items.forEach(({ id, nickname, image, teamName, teamLogoDark, teamLogoLight }) => {
    const node = {
      id,
      name: formatFieldValue(nickname),
      image: formatFieldValue(image),
      team: {
        name: formatFieldValue(teamName),
        logoDark: formatFieldValue(teamLogoDark),
        logoLight: formatFieldValue(teamLogoLight),
      },
    };
    edges.push({ node });
  });
  return edges;
};

const formatTeamNode = items => {
  const edges = [];
  items.forEach(({ id, country, name, shortname, logoDark, logoLight }) => {
    const node = {
      id,
      country: formatFieldValue(country),
      name: formatFieldValue(name),
      shortname: formatFieldValue(shortname),
      logoDark: formatFieldValue(logoDark),
      logoLight: formatFieldValue(logoLight),
    };
    edges.push({ node });
  });
  return edges;
};

const formartChampionNode = items => {
  const edges = [];
  items.forEach(({ id, champId, tags, image, name, title }) => {
    const node = {
      id,
      champId,
      class: tags,
      image,
      name: formatFieldValue(name),
      title: formatFieldValue(title),
    };
    edges.push({ node });
  });
  return edges;
};

const formatNodeEntry = items => {
  const edges = [];
  items.forEach(item => {
    const node = {
      ...item,
    };
    edges.push({ node });
  });
  return edges;
};

const getFormatter = type => {
  if (type === 'players') return formatPlayerNode;
  if (type === 'teams') return formatTeamNode;
  if (type === 'champions') return formartChampionNode;
  return formatNodeEntry;
};

const getDataKey = key => {
  if (key === 'riotChampions') return 'champions';
  if (key === 'matches') return 'vods';
  if (key === 'videos') return 'highlights';
  return key;
};

const formatSearchData = results => {
  const keys = Object.keys(results);
  const data = {};
  keys.forEach(currentKey => {
    const dataKey = getDataKey(currentKey);
    const categoryData = results[currentKey]?.hits || [];
    const formatterFunc = getFormatter(dataKey);
    const formatedData = formatterFunc ? formatterFunc(categoryData) : null;
    if (formatedData && formatedData.length > 0) {
      data[dataKey] = { edges: formatedData };
    }
  });
  const normalized = normalizeData(data, rootSchema);
  return normalized.entities;
};

export default formatSearchData;
