import { SVG } from 'tv-modules/Payment/ErrorPage/ErrorPage.styles';

const SuccessImage = () => (
  <SVG
    viewBox="0 0 143 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="card-success"
  >
    <path
      d="M127.883 32.3041V6.93066C127.883 4.16924 125.645 1.93066 122.883 1.93066H7.11523C4.35381 1.93066 2.11523 4.16924 2.11523 6.93066V70.1132C2.11523 72.8747 4.35381 75.1132 7.11523 75.1132H94.6347"
      stroke="#474747"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.4834 12.897H127.48" stroke="#474747" strokeWidth="3" />
    <path d="M2.11523 24.4736H128.112" stroke="#474747" strokeWidth="3" />
    <circle cx="119.998" cy="61.0884" r="21.0664" stroke="#474747" strokeWidth="3" />
    <path d="M9.5498 51.5903H48.3184" stroke="#474747" strokeWidth="3" strokeLinecap="round" />
    <path d="M9.5498 61.0884H33.0723" stroke="#474747" strokeWidth="3" strokeLinecap="round" />
    <path
      d="M107.826 64.0496L116.173 71.8571L132.326 53.8191"
      stroke="#474747"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </SVG>
);

export default SuccessImage;
