import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

const sizeTheme = {
  smallSize: '24px',
  mediumSize: '36px',
  largeSize: '48px',
  xlargeSize: '64px',
};

export const themes = {
  default: {
    ...sizeTheme,
    fallbackLogoFill: colors.znipeContainerStroke,
  },
  znipebase: {
    ...sizeTheme,
    fallbackLogoFill: colors.gloomyGrey,
  },
  ubisoft: {
    ...sizeTheme,
    fallbackLogoFill: colors.esportalIconBlue,
  },
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ $logoPlacement }) => ($logoPlacement === 'right' ? 'row-reverse' : 'row')};
  color: ${({ theme }) => theme.primaryTextColor};
  gap: ${({ size }) => (size === 'small' ? 4 : 8)}px;
`;

const ThemeXLarge = ({ theme }) => css`
  width: ${theme.teamLogo.xlargeSize};
  height: ${theme.teamLogo.xlargeSize};
`;

const ThemeLarge = ({ theme }) => css`
  width: ${theme.teamLogo.largeSize};
  height: ${theme.teamLogo.largeSize};
`;

const ThemeMedium = ({ theme }) => css`
  width: ${theme.teamLogo.mediumSize};
  height: ${theme.teamLogo.mediumSize};
`;

const ThemeSmall = ({ theme }) => css`
  width: ${theme.teamLogo.smallSize};
  height: ${theme.teamLogo.smallSize};
`;

const baseWrapperStyles = css`
  ${({ size }) => size === 'xlarge' && ThemeXLarge}
  ${({ size }) => size === 'large' && ThemeLarge}
  ${({ size }) => size === 'medium' && ThemeMedium}
  ${({ size }) => size === 'small' && ThemeSmall}
`;

export const ImageWrapper = styled.div`
  ${baseWrapperStyles}
`;

export const LazyWrapper = styled.div`
  ${baseWrapperStyles}
  ${({ useLazyLoading }) =>
    useLazyLoading &&
    css`
      height: auto;
      width: auto;
    `}
  > div {
    display: flex;
  }
`;

export const TextWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
