import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetIsMobile = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.isMobile ?? false);
};

export const useIsMobile = () => useMemoizedGqlSelector(makeGetIsMobile);

export default makeGetIsMobile;
