import { createSelector } from 'reselect';
import makeGetProductUserPrice from 'tv-selectors/products/makeGetProductUserPrice';
import makeGetSelectedProduct from 'tv-selectors/products/makeGetSelectedProduct';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductSubscriptionOriginalPrice = () => {
  const getProductUserPrice = makeGetProductUserPrice();
  const getSelectedProduct = makeGetSelectedProduct();
  return createSelector([getProductUserPrice, getSelectedProduct], (userPrice, product) => {
    if (userPrice.productType === 'subscription' && typeof userPrice.originalPrice === 'number') {
      return userPrice.originalPrice;
    }
    if (typeof product.subscriptionPrice === 'number') {
      return product.subscriptionPrice;
    }

    return null;
  });
};

export const useGetProductSubscriptionOriginalPrice = props =>
  useMemoizedSelector(makeGetProductSubscriptionOriginalPrice, props);

export default makeGetProductSubscriptionOriginalPrice;
