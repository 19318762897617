import { TOURNAMENTS, TEAMS } from 'tv-modules/Filters/Filters.constants';

export const ROUND_ROBIN = 'ROUND_ROBIN';
export const DOUBLE_ELIMINATION = 'DOUBLE_ELIMINATION';
export const SINGLE_ELIMINATION = 'SINGLE_ELIMINATION';
export const STANDINGS = 'standings';

export const filterOptions = {
  sort: true,
  top: [TOURNAMENTS],
  panel: [TEAMS],
};

export const initialState = {
  requestHashId: 0,
  selection: {
    teams: [],
    tournaments: [],
  },
  filterParams: {
    appliedTeamsFilter: '',
    appliedTournamentsFilter: '',
    appliedEventsFilter: '',
    appliedPlayersFilter: '',
    appliedClassesFilter: '',
  },
};

export const fallbackBracket = {
  type: '',
  schedules: [],
};
