import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetAuthToken = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.token ?? '');
};

export const useAuthToken = () => useMemoizedSelector(makeGetAuthToken);

export default makeGetAuthToken;
