import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon from 'znipe-elements/general/Icon/Icon';
import InputField from 'znipe-elements/data-entry/InputField/InputField';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { Container, IconWrapper } from './SearchMasthead.styles';

const themes = {
  default: {
    searchPlaceholderText: 'Search players, teams, matches...',
  },
  proview: {
    searchPlaceholderText: 'Search players, champions...',
  },
};

const SearchMasthead = ({
  browseUrl = '',
  onSearchChange = () => {},
  onClickBack = () => {},
  onClickDelete = () => {},
}) => {
  const navigate = useNavigate();
  const { searchMasthead } = useThemeContext();

  const { searchPlaceholderText } = searchMasthead;

  const handleClickSearch = useCallback(
    query => {
      if (query === '') return;
      navigate(`${browseUrl}/?s=${query}`);
    },
    [browseUrl, navigate],
  );

  const handleClickBack = useCallback(() => onClickBack(), [onClickBack]);

  return (
    <Container data-testid="container">
      <IconWrapper onClick={handleClickBack} data-testid="icon-back">
        <Icon type="chevronLeft" size={24} />
      </IconWrapper>
      <InputField
        onClickIcon={handleClickSearch}
        onSearchChange={onSearchChange}
        onClickDelete={onClickDelete}
        placeholder={searchPlaceholderText}
        size="medium"
        noBackground
      />
    </Container>
  );
};

SearchMasthead.propTypes = {
  browseUrl: PropTypes.string,
  onSearchChange: PropTypes.func,
  onClickBack: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default withTheme(memo(SearchMasthead), themes, 'searchMasthead');
