import { useMemo } from 'react';
import { PremiumIconWrapper } from '../Selector.styles';
import { ImageArea, Container } from './SelectorSlimHorizontal.styles';
import { CenterArea } from '../Horizontal/SelectorHorizontal.styles';
import PremiumIcon from '../../../data-display/PremiumIcon/PremiumIcon';
import useLabelTypography from '../useLabelTypography';
import { MEDIUM, propTypes, SMALL } from '../constants';

const SelectorSlimHorizontal = ({
  label,
  size = 'small',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  imageArea,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
}) => {
  const labelOptions = useMemo(() => {
    const baseOptions = { type: 'heading-xs', color: 'secondaryTextColor' };
    if (size === SMALL) return { ...baseOptions, maxSize: 10, maxWidth: '75px' };
    return baseOptions;
  }, [size]);
  const labelComponent = useLabelTypography(label, labelOptions);

  return (
    <Container
      data-testid={testId}
      $size={size}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      $isSelected={isSelected}
    >
      <ImageArea $size={size}>{imageArea}</ImageArea>
      <CenterArea>{labelComponent}</CenterArea>
      {isLocked && !hasPremiumAccess && (
        <PremiumIconWrapper>
          <PremiumIcon size={MEDIUM} />
        </PremiumIconWrapper>
      )}
    </Container>
  );
};

SelectorSlimHorizontal.propTypes = propTypes;

export default SelectorSlimHorizontal;
