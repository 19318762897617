import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

const MOBILE_STAGE_HEADER_HEIGHT = '65px';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    overflow: hidden;
  }
`;
export const PlayerContainer = styled.div`
  position: relative;
  background-color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.desktop} {
    flex: 1 1 auto;
    height: calc(100vh - 170px);
  }
`;

export const PlayerPositionWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: calc(100vw * 0.5625);
  @media ${device.desktop} {
    max-width: calc(165vh - 105px);
    min-height: auto;
    transition: margin-left 500ms;
    ${({ statsOpen }) =>
      statsOpen &&
      css`
        margin-left: 500px;
      `}
  }
`;

export const StatsSideMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 3px 15px;
  flex: 1 1 auto;
  > div {
    margin: 20px auto;
  }
`;

export const PoweredByZnipeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const DesktopStreamLayoutWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
`;

/* ------------PLACEHOLDERS UNTIL REAL COMES IN------------------- */

export const PlaceholderMobileNavMenu = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
`;

export const PlaceholderMenu = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  & > * {
    align-self: flex-start;
  }
`;

export const PlaceholderMenuContent = styled.img`
  width: 100%;
  margin-top: 16px;
  padding: 0 16px;
`;

/* ------ NEW STYLES ------------ */

const mastheadHeight = 64;
const matchbarHeight = 105;
const mastheadMatchbarHeightDesktop = matchbarHeight + mastheadHeight;
export const Container = styled.div`
  width: 100%;
  background: ${colors.black};
  @media ${device.desktop} {
    height: calc(
      100vh - ${({ $compact }) => ($compact ? matchbarHeight : mastheadMatchbarHeightDesktop)}px
    );
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  @media ${device.desktop} {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const DesktopSidebarHighlightContainer = styled.div`
  height: 100%;
  width: 352px;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const DesktopSidebarContainer = styled.div`
  height: 100%;
  ${({ $isMobile, $thin }) =>
    $isMobile
      ? css`
          width: 100%;
        `
      : css`
          width: ${$thin ? '300px;' : '352px;'};
        `}
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const ChatContainerWrapper = styled.div`
  max-height: calc(
    calc(${global.document ? `${window.innerHeight}px` : '100vh'} - ${MOBILE_STAGE_HEADER_HEIGHT}) -
      (100vw * 0.5625)
  );
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  @media ${device.desktop} {
    height: 100%;
    max-height: 100%;
    width: 300px;
  }
`;

export const MobileMenuContainer = styled.div`
  max-height: calc(
    calc(${global.document ? `${window.innerHeight}px` : '100vh'} - ${MOBILE_STAGE_HEADER_HEIGHT}) -
      (100vw * 0.5625)
  );
  height: 100vh;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const MobileHintContainer = styled.div`
  position: fixed;
  width: 100%;
  background-color: transparent;
  bottom: 0;
  max-height: calc(
    calc(${global.document ? `${window.innerHeight}px` : '100vh'} - 136px) - (100vw * 0.5625)
  );
  height: 100vh;
`;

export const MobileStatsContainer = styled.div`
  width: 100%;
  height: ${({ $viewHeight }) => ($viewHeight ? '100vh' : '100%')};
  max-height: 100%;
  max-width: 472px;
  margin: auto;
  @media ${device.tablet} {
    max-width: 100vw;
  }
`;

export const MenuContent = styled.div`
  position: relative;
  overflow-y: auto;
  display: flex;
  align-items: center;
  min-height: 100%;
`;

export const InnerModalWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const VideoFrameWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const PlayerSelectorWrapper = styled.div`
  width: 320px;
  margin: auto;
  padding: 16px 0;
  @media ${device.tablet} {
    width: 440px;
    padding: 24px 0;
  }
`;

export const TitleWrapper = styled.div`
  padding: 0 0 16px 16px;

  @media ${device.tablet} {
    padding-left: 0;
  }
`;

export const MobileStatsLoading = styled.div`
  width: calc(100vw - 32px);
  height: 200px;
  margin: 16px auto 0 auto;
  border-radius: 5px;
  background: ${({ theme }) => theme.secondaryBackgroundColor};

  @media ${device.tablet} {
    width: 656px;
  }
`;

export const NoAuthIconChatWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 40px 16px 0px 16px;
`;

export const NoAuthChatWrapper = styled.div`
  padding: 20px 16px 16px 16px;
`;
