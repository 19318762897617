import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';

const width = {
  small: '320px',
  medium: '375px',
  scaling: '100%',
};

export const Container = styled.div`
  width: ${({ $type }) => width[$type]};
  height: 100vh;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const PanelWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
  @media ${device.tablet} {
    width: initial;
    right: 0;
    left: unset;
    box-shadow: -10px 0 30px ${convertHexToRGB(colors.black, 0.7)};
  }
`;

export const PanelOverlay = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  opacity: 0;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;
