import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import Dropdown from 'znipe-elements/navigation/Dropdown/Dropdown';
import {
  DropdownSection,
  DropdownWrapper,
  TournamentDropdownWrapper,
} from './FilterMenuStandings.styles';
import { logoOptionShape } from '../Filters.constants';

const TOURNAMENTS = 'tournaments';
const TEAMS = 'teams';

const arrayOfObject = PropTypes.arrayOf(PropTypes.shape({}));

const selectionShape = {
  [TOURNAMENTS]: arrayOfObject,
  [TEAMS]: arrayOfObject,
};

const defaultFilterSelection = {
  [TOURNAMENTS]: [],
  [TEAMS]: [],
};

const FilterMenuStandings = ({
  tabs = [],
  activeTab,
  selection = defaultFilterSelection,
  setTab = () => {},
  setTournament = () => {},
  tournamentOptions = [],
  onChangeSelection = () => {},
}) => {
  const [tournamentFilter, setTournamentFilter] = useState(tournamentOptions);
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();

  const tabItems = useMemo(
    () =>
      tabs.map(tab => ({
        id: tab,
        title: tab,
        selected: tab.toLowerCase() === (activeTab ?? tabs[0] ?? '').toLowerCase(),
      })),
    [tabs, activeTab],
  );

  const selectedTournament = useMemo(
    () => tournamentFilter.filter(tournament => tournament.selected),
    [tournamentFilter],
  );

  const selectedTab = useMemo(() => {
    if (tabItems.length < 1) return [];
    const selectedItems = tabItems?.filter(tab => tab.selected);
    if (selectedItems.length) return selectedItems;
    const firstItem = { ...tabItems[0] };
    firstItem.selected = true;
    return [firstItem];
  }, [tabItems]);

  useEffect(() => {
    setTab(selectedTab);
  }, [selectedTab, setTab]);

  useEffect(() => {
    if (tournamentFilter.length !== tournamentOptions.length) {
      setTournamentFilter(tournamentOptions);
    }
  }, [tournamentFilter.length, tournamentOptions, tournamentOptions.length]);

  useEffect(() => {
    if (Object.keys(selectedTournament)?.length) {
      setTournament(selectedTournament);
    }
  }, [selectedTournament, setTournament]);

  const handleChangeTab = useCallback(
    newValue => {
      const selectedItem = newValue.filter(item => item.selected);
      onChangeSelection({
        ...selection,
        tab: selectedItem,
      });
      setTab(selectedItem);
    },
    [onChangeSelection, selection, setTab],
  );

  const handleChangeTournament = useCallback(
    newValue => {
      setTournamentFilter(newValue);
      onChangeSelection({
        ...selection,
        tournaments: newValue.filter(item => item.selected),
      });
    },
    [onChangeSelection, selection],
  );

  return (
    <DropdownSection data-testid="filter-menu-standings">
      <TournamentDropdownWrapper>
        <Dropdown
          type="single"
          items={tournamentFilter}
          onDataChange={handleChangeTournament}
          label="tournament"
          useModal={!isTabletOrGreater}
        />
      </TournamentDropdownWrapper>
      {isDesktopOrGreater && (
        <DropdownWrapper data-testid="tabs-dropdown">
          <Dropdown
            type="single"
            items={tabItems}
            onDataChange={handleChangeTab}
            label={activeTab}
          />
        </DropdownWrapper>
      )}
    </DropdownSection>
  );
};

FilterMenuStandings.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  activeTab: PropTypes.string,
  selection: PropTypes.shape(selectionShape),
  tournamentOptions: PropTypes.arrayOf(logoOptionShape),
  setTab: PropTypes.func,
  setTournament: PropTypes.func,
  onChangeSelection: PropTypes.func,
};

export default memo(FilterMenuStandings);
