import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from 'tv-selectors/tournaments/makeGetTournaments';
import makeGetPackageTournaments from 'tv-selectors/packages/makeGetPackageTournaments';

const emptyArray = [];

const makeGetPackageGameTitles = () => {
  const getTournaments = makeGetTournaments();
  const getPackageTournaments = makeGetPackageTournaments();
  return createSelector([getPackageTournaments, getTournaments], (tournamentIds, tournaments) => {
    if (tournamentIds.length < 1) return emptyArray;
    const games = tournamentIds.map(id => tournaments[id]?.game).filter(Boolean);
    return [...new Set(games)];
  });
};

export const useGetPackageGameTitles = props =>
  useMemoizedGqlSelector(makeGetPackageGameTitles, props);

export default makeGetPackageGameTitles;
