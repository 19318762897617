import { memo } from 'react';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import Typography from 'znipe-elements/general/Typography/Typography';
import PropTypes from 'prop-types';
import { SubscribeButton } from './Sidebar.styles';

// INFO: Hiding the notifications button until it is ready to use
/* {onNotificationsClick && (
  <NavButton
    iconType={isMuted ? 'notificationsOff' : 'notifications'}
    onClick={onNotificationsClick}
    testid="notifications-button"
    size="medium"
  />
)} */

const SidebarControls = ({
  onDeleteClick = () => {},
  isSubscribed = false,
  onSubscribeClick = () => {},
  buttonText = 'follow',
  // onNotificationsClick,
  // isMuted,
}) => {
  if (!isSubscribed) {
    return (
      <SubscribeButton role="button" onClick={onSubscribeClick} data-testid="subscribe-button">
        <Typography type="title-xs">{buttonText}</Typography>
      </SubscribeButton>
    );
  }

  return (
    <NavButton iconType="close" onClick={onDeleteClick} testid="delete-button" size="medium" />
  );
};

SidebarControls.propTypes = {
  onDeleteClick: PropTypes.func,
  onSubscribeClick: PropTypes.func,
  isSubscribed: PropTypes.bool,
  buttonText: PropTypes.string,
  /* onNotificationsClick: PropTypes.func,
  isMuted: PropTypes.bool, */
};

export default memo(SidebarControls);
