import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetFeaturedContents from 'tv-selectors/featured/makeGetFeaturedContentsMap';
import makeGetProps from 'tv-selectors/makeGetProps';

const emptyObject = {};

const makeGetSelectedFeaturedContent = () => {
  const getFeaturedContents = makeGetFeaturedContents();
  const getProps = makeGetProps();
  return createSelector([getFeaturedContents, getProps], (featuredContents, props) => {
    const id = props?.featuredId || '';
    if (!id) return emptyObject;
    return featuredContents[id] ?? emptyObject;
  });
};

export const useGetFeaturedContent = props =>
  useMemoizedGqlSelector(makeGetSelectedFeaturedContent, props);

export default makeGetSelectedFeaturedContent;
