const createCharacter = character => {
  const { championName, championImageSrc, imagePath, image, passive, spells } = character;
  return {
    name: championName,
    image: championImageSrc || imagePath || image,
    id: championName,
    passive,
    spells,
  };
};

export default createCharacter;
