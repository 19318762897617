import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import defaultTheme from 'znipe-themes/default.theme';
import { device, breakpoints } from 'znipe-styles/breakpoints';
import { fadeIn } from 'znipe-styles/animations';
import prefers from 'znipe-styles/prefers';

export const wrapperWidthCss = css`
  @media ${device.tablet} {
    width: 656px;
  }
  @media ${device.desktop} {
    width: 1040px;
  }
  @media ${device.desktopLarge} {
    width: 1232px;
  }
  @media ${device.desktopExtraLarge} {
    width: 1648px;
  }
`;

const bodyPaddingCss = css`
  padding-bottom: 24px;
  @media ${device.tablet} {
    padding-bottom: 48px;
  }
  @media ${device.desktop} {
    padding-top: 48px;
    padding-bottom: 96px;
  }
  @media ${device.desktopLarge} {
    padding-bottom: 128px;
  }
  @media ${device.desktopExtraLarge} {
    padding-top: 72px;
  }
  @media ${device.desktop4K} {
    padding-top: 96px;
  }
`;

const defaultBodyCss = css`
  @media ${device.desktop} {
    width: 1040px;
  }
  @media ${device.desktopLarge} {
    width: 1232px;
  }
  @media ${device.desktopExtraLarge} {
    width: 1648px;
  }
`;

const homeBodyCss = css`
  @media ${device.desktop} {
    width: 1040px;
  }
  @media ${device.desktopLarge} {
    width: 1232px;
  }
  @media ${device.desktopExtraLarge} {
    width: 1648px;
  }
`;

export const PageContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 56px;
  @media ${device.tablet} {
    margin-bottom: 75px;
  }
  @media ${device.desktop} {
    margin-bottom: 64px;
  }
`;

export const BodyAnimationWrapper = styled.div`
  @media ${prefers.motion} {
    opacity: 0;
    animation-name: ${fadeIn(0, 1)};
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
  }
`;

export const FeaturedMatchWrapper = styled.div`
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 280px;
  @media ${device.desktop} {
    width: 81%;
    max-width: 1200px;
  }
  @media ${device.desktopLarge} {
    width: 83%;
    max-width: 1600px;
  }
  @media ${device.desktopExtraLarge} {
    max-width: 1800px;
    width: 70%;
  }
`;

export const BodyWrapper = styled.div`
  width: 100vw;
  margin: 0 auto;
  ${({ noPadding }) => !noPadding && bodyPaddingCss}
  ${({ type }) => {
    if (type === 'fullWidth') {
      return css`
        padding-top: 0;
      `;
    }
    if (type === 'standings') {
      return null;
    }
    if (type === 'home') {
      return homeBodyCss;
    }
    return defaultBodyCss;
  }}
`;

const homeVideoHeightCss = css`
  @media ${device.tablet} {
    height: calc(656px * 9 / 16);
  }
  @media ${device.desktop} {
    height: calc(752px * 9 / 16);
  }
  @media ${device.desktopLarge} {
    height: calc(1040px * 9 / 16);
  }
  @media ${device.desktopExtraLarge} {
    height: calc(1232px * 9 / 16);
  }
  @media ${device.desktop4K} {
    height: calc(1496px * 9 / 16);
  }
`;

const defaultVideoHeightCss = css`
  @media ${device.desktop} {
    height: calc(880px * 9 / 16);
  }
  @media ${device.desktopLarge} {
    height: calc(1040px * 9 / 16);
  }
`;

const baseContentCss = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.znipeBackgroundTertiary};
`;

export const FullWidthContentWrapper = styled.div`
  ${baseContentCss}
`;

export const VideoWrapperForHome = styled.div`
  height: calc(100vw * 9 / 16);
  ${baseContentCss};
  ${homeVideoHeightCss};
`;

export const VideoWrapper = styled.div`
  height: calc(100vw * 9 / 16);
  ${baseContentCss};
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          width: 100vw;
        `
      : defaultVideoHeightCss};
`;

export const BracketsWrapper = styled.div`
  position: relative;
  padding-top: 8px;
  ${wrapperWidthCss}
  margin: auto;
  @media ${device.desktopLarge} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const GroupsWrapper = styled.div`
  position: relative;
  ${wrapperWidthCss}
  margin: auto;
  @media ${device.mobilePortrait} {
    margin: auto 16px;
  }
  @media ${device.tablet} {
    margin: auto;
  }
  @media ${device.desktopLarge} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const ContentWrapper = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    css`
      padding-top: 102px;
    `}
  @media ${device.mobilePortraitSmall} {
    & > div > div:first-child {
      padding: 0 8px;
      @media ${device.mobilePortrait} {
        padding: 0 16px;
      }
      @media ${device.desktop} {
        padding: 0;
      }
    }
  }
  @media ${device.tablet} and (max-width: ${breakpoints.desktop}px) {
    > div {
      height: calc(100% - 373px);
    }
  }
`;

export const InnerMargin = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  margin-bottom: 60px;
  @media ${device.tablet} {
    margin-bottom: 85px;
  }
`;

export const TabsContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin: ${({ isSticky }) => (isSticky ? 0 : 16)}px auto 16px auto;
  ${({ customMargin }) =>
    customMargin &&
    css`
      margin: ${customMargin};
    `}
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 0 8px;
      @media ${device.mobilePortrait} {
        padding: 0 16px;
      }
      @media ${device.desktop} {
        padding: 0;
      }
    `}
    ${wrapperWidthCss}
    ${({ isSticky }) =>
      isSticky &&
      css`
      width: 100%;
      min-width: 100%;
    `};
`;

export const StickyWrapper = styled.div`
  @media ${device.desktop} {
    > div > div {
      z-index: 4;
      left: 0;
    }
  }
`;

export const Footer = styled.div`
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  box-shadow: 0px -4px 4px 0px ${colors.black} 25%;
  width: 100%;
  position: fixed;
  z-index: 5;
  bottom: 0;
  @media ${device.desktop} {
    display: none;
  }
`;

Footer.defaultProps = {
  theme: defaultTheme,
};

export const TabSetWrapper = styled.div`
  overflow: scroll;
  display: flex;
  height: 64px;
  @media ${device.tablet} {
    margin: 0 auto;
  }
`;
