import styled, { css } from 'styled-components';

export const DateWrapper = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  display: flex;
  margin-top: -5px;
`;

export const DescriptionWrapper = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  > p {
    margin: 0;
  }
`;

const SmallContainerStyles = () => css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  padding: 4px 8px;
  height: 88px;
`;

const XSmallContainerStyles = () => css`
  ${SmallContainerStyles}
  height: 80px;
`;

const MediumContainerStyles = () => css`
  display: grid;
  align-items: center;
  grid-template-columns: ${props => (props.$isLoading ? '1fr' : 'min-content 1fr min-content')};
  ${props =>
    props.$isLoading &&
    css`
      place-items: center;
    `}
  padding: 20px 16px;
  min-height: 96px;
  ${DateWrapper} {
    margin: auto auto auto 0;
  }
  ${DescriptionWrapper} {
    margin: auto 0 auto auto;
    text-align: end;
    width: 170px;
    display: flex;
    flex-direction: column;
  }
`;

export const OuterContianer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  user-select: none;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryTextColor};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  &:hover,
  &:focus {
    background: ${({ theme, $isLoading }) =>
      $isLoading ? theme.secondaryBackgroundColor : theme.secondaryBakgroundFade};
  }
  ${({ size }) => {
    switch (size) {
      case 'xsmall':
        return XSmallContainerStyles();
      case 'small':
        return SmallContainerStyles();
      default:
        return MediumContainerStyles();
    }
  }}
`;

export const LargeTimeFormat = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LargeMinutesWrapper = styled.div`
  margin: 8px 0 0 4px;
`;

export const LiveWrapper = styled.div`
  margin-top: -8px;
  margin-bottom: 8px;

  ${({ size }) =>
    size === 'medium' &&
    css`
      margin: auto auto auto 0;
    `}
`;
