export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';

export const NONE = 'none';

export const FILTERS = 'filter';
export const SEARCH = 'search';
export const MYFEED = 'myfeed';
export const NOTIFICATIONS = 'notifications';
export const SCHEDULE = 'schedule';
export const VODS = 'vods';
export const PLAYLISTS = 'playlists';
export const HIGHLIGHTS = 'highlights';
export const STANDINGS = 'standings';
export const KNOCKOUTS = 'knockouts';
export const PLAYERS = 'players';
export const TEAMS = 'teams';
export const EDITORIALS = 'editorials';
export const CHAMPIONS = 'champions';
export const EVENTS = 'events';

export const EVENT = 'event';
export const PLAYER = 'player';
export const TEAM = 'team';
export const CHAMPION = 'champion';

export const menuTypes = [
  NONE,
  FILTERS,
  SEARCH,
  MYFEED,
  NOTIFICATIONS,
  SCHEDULE,
  VODS,
  HIGHLIGHTS,
  STANDINGS,
  PLAYERS,
  TEAMS,
  EDITORIALS,
  CHAMPIONS,
  EVENTS,
  KNOCKOUTS,
  PLAYLISTS,
];

export const textContent = {
  [NONE]: {
    primary: 'No results found',
    secondary: 'There are currently no results to be shown.',
  },
  [FILTERS]: {
    primary: 'No results found',
    secondary: 'Your active filters are too niche, adjust them to try again!',
  },
  [SEARCH]: {
    primary: 'No results found',
    secondary: 'The results are dodging, rephrase so we can get a hit!',
  },
  [MYFEED]: {
    primary: 'Nothing here yet',
    secondary: 'Content you are following will end up here in your feed!',
  },
  [NOTIFICATIONS]: {
    primary: 'You have no notifications!',
    secondary: 'Once you subscribe to some content, any updates will appear here.',
  },
  [SCHEDULE]: {
    primary: 'No schedule found',
    secondary: 'There are currently no further matches scheduled.',
  },
  [VODS]: {
    primary: 'No VODs found',
    secondary: 'There are currently no past matches to be shown.',
  },
  [HIGHLIGHTS]: {
    primary: 'No highlights found',
    secondary: 'There are currently no highlight clips to be shown.',
  },
  [STANDINGS]: {
    primary: 'No standings found',
    secondary: 'There are currently no standings available.',
  },
  [KNOCKOUTS]: {
    primary: 'No knockout stages found',
    secondary: 'There are currently no knockout stages available.',
  },
  [PLAYERS]: {
    primary: 'No players found',
    secondary: 'There are currently no players to be shown.',
  },
  [TEAMS]: {
    primary: 'No teams found',
    secondary: 'There are currently no teams to be shown.',
  },
  [EDITORIALS]: {
    primary: 'No editorials found',
    secondary: 'There are currently no editorials to be shown.',
  },
  [CHAMPIONS]: {
    primary: 'No champions found',
    secondary: 'There are currently no champions to be shown.',
  },
  [EVENTS]: {
    primary: 'No events found',
    secondary: 'There are currently no events to be shown.',
  },
  [PLAYLISTS]: {
    primary: 'No playlists found',
    secondary: 'There are currently no playlists to be shown.',
  },
};

export const topicTypes = [EVENT, PLAYER, TEAM, CHAMPION];

export const menuTypesWithTopics = [SCHEDULE, VODS, HIGHLIGHTS, STANDINGS];

export const topicSecondaryText = {
  [SCHEDULE]: 'There are currently no further matches scheduled for this [TOPIC].',
  [VODS]: 'There are currently no past matches for this [TOPIC].',
  [HIGHLIGHTS]: 'There are currently no highlight clips for this [TOPIC].',
  [STANDINGS]: 'There are currently no standings available for this [TOPIC].',
};
