import { useState, useCallback, useRef, useMemo, useEffect, useLayoutEffect } from 'react';
import { createSelector } from 'reselect';
import { useIsMobile } from 'tv-selectors/deviceInfo/makeGetIsMobile';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import ScrollContainer from 'react-indiana-drag-scroll';
import { BodyWrapper } from 'tv-elements/layout/PageLayout/PageLayout';
import Bracket from 'tv-modules/Standings/Bracket/Bracket';
import CompactBracket from 'tv-modules/Standings/CompactBracket/CompactBracket';
import GroupStage, {
  Container as GroupStageContainer,
} from 'tv-modules/Standings/GroupStage/GroupStage';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import { standingsSchema } from 'tv-schema/standings.normalizr';
import { useNavigate, useParams } from 'react-router-dom';
import FiltersTabset from 'tv-modules/Filters/FiltersTabset/FiltersTabset';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import Tabset from 'znipe-elements/navigation/Tabset/Tabset';
import BlockContainerLoading from 'znipe-elements/data-display/BlockContainer/BlockContainer.loading';
import usePackageName from 'tv-hooks/usePackageName';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import useWindowWidth from 'znipe-hooks/useWindowWidth';
import urlifyString from 'znipe-utils/web/urlifyString';
import GetStandingsData from './getStandingsPageData.graphql';
import { FilterPadding, TabsetWrapper, LoadingDropdownWrapper } from './Standings.styles';
import {
  ROUND_ROBIN,
  SINGLE_ELIMINATION,
  DOUBLE_ELIMINATION,
  STANDINGS,
  filterOptions,
  initialState,
} from './Standings.constants';

const emptyArray = [];
const emptyObject = {};

const tabOptionsSelector = createSelector(
  [(_, tournamentStageIds) => tournamentStageIds, state => state.tournamentStages],
  (tournamentStageIds, tournamentStages) =>
    tournamentStageIds?.length
      ? tournamentStageIds.map(id => tournamentStages[id]?.name).filter(Boolean)
      : emptyArray,
);

const tournamentEventsSelector = createSelector(
  [
    (_, standingsTournamentIds) => standingsTournamentIds,
    state => state.tournaments,
    state => state.events,
    state => state.tournamentStages,
  ],
  (standingsTournamentIds, tournaments, events, tournamentStages) => {
    const selectedTournaments = standingsTournamentIds.map(id => tournaments[id] ?? emptyObject);
    return selectedTournaments
      .sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
      .map(tournament => ({
        id: tournament.id,
        logo: tournament.tournamentLogo,
        name: events[tournament.event]?.name,
        country: tournament.location?.country,
        tournamentStageNames: tournament.tournamentStages?.map(id => tournamentStages[id]?.name),
      }));
  },
);

const Standings = () => {
  const isMobile = useIsMobile();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const windowWidth = useWindowWidth();
  const {
    packageSlug,
    tournament: tournamentParam,
    tournamentStage: tournamentStageParam,
  } = useParams();
  const packageName = usePackageName();
  const navigate = useNavigate();
  const standingsTournamentIds = useGqlSelector(
    state => state.pages?.standings?.tournaments ?? emptyArray,
  );
  const tournamentEvents = useGqlSelector(state =>
    tournamentEventsSelector(state, standingsTournamentIds),
  );

  const [pageFilters, setPageFilters] = useState(() => {
    const clonedInitialState = JSON.parse(JSON.stringify(initialState));
    if (!tournamentParam) return clonedInitialState;
    clonedInitialState.filterParams.appliedTournamentsFilter = tournamentParam;
    return clonedInitialState;
  });
  const [standingsMargin, setStandingsMargin] = useState('0');

  const { appliedTournamentsFilter: tournamentId } = pageFilters.filterParams;

  const tournamentStageIds = useGqlSelector(
    state => state.tournaments[tournamentId]?.tournamentStages ?? emptyArray,
  );

  const tabOptions = useGqlSelector(state => tabOptionsSelector(state, tournamentStageIds));

  const mobileTabLabels = useMemo(() => tabOptions.map(tab => ({ label: tab })), [tabOptions]);
  const [activeTab, setActiveTab] = useState(() => {
    if (!tournamentStageParam) return tabOptions[0];
    const selectedOption = tabOptions.find(option => urlifyString(option) === tournamentStageParam);
    return selectedOption ?? tabOptions[0];
  });

  const { type, tournamentStage } = useGqlSelector(state => {
    const selectedId = tournamentStageIds.find(
      id => state.tournamentStages[id]?.name === activeTab,
    );
    return { type: state.tournamentStages[selectedId]?.type, tournamentStage: selectedId };
  });

  const teams = useGqlSelector(
    state =>
      state.tournaments?.[tournamentId]?.teams?.map(id => state?.teams[id] ?? {}) ?? emptyArray,
  );
  const gameTitle = useMemo(() => (teams?.length > 0 ? teams[0].game : 'lol'), [teams]);

  const tournamentFilterOptions = useMemo(() => {
    const tournaments = Object.values(tournamentEvents);
    const selectedIndex = Math.max(
      tournaments.findIndex(({ id }) => id === tournamentParam),
      0,
    );
    return tournaments.map(({ id, name: title, logo: image, country: subtitle }, index) => ({
      id,
      title,
      image,
      subtitle,
      selected: index === selectedIndex,
    }));
  }, [tournamentEvents, tournamentParam]);

  const selectedTeams = useMemo(
    () => pageFilters?.selection?.teams?.map(({ id }) => id),
    [pageFilters.selection],
  );

  useEffect(() => {
    const selectedTournament = tournamentEvents.find(
      tournament => tournament.id === tournamentParam,
    );
    if (!selectedTournament) return;
    if (pageFilters.filterParams.appliedTournamentsFilter !== selectedTournament.id) {
      setPageFilters(oldFilters => {
        const newFilters = { ...oldFilters };
        newFilters.filterParams.appliedTournamentsFilter = selectedTournament.id;
        return newFilters;
      });
    }

    const selectedStageName = selectedTournament.tournamentStageNames.find(
      name => urlifyString(name) === tournamentStageParam,
    );
    if (!selectedStageName) setActiveTab(selectedTournament.tournamentStageNames[0]);
    else setActiveTab(selectedStageName);
  }, [
    pageFilters.filterParams.appliedTournamentsFilter,
    tournamentEvents,
    tournamentParam,
    tournamentStageParam,
  ]);

  const queryOptions = useMemo(
    () => ({
      variables: {
        package: packageName,
      },
      persisted: false,
    }),
    [packageName],
  );

  const { loading: queryDataLoading } = useReduxGqlQuery(
    GetStandingsData,
    queryOptions,
    {},
    standingsSchema,
  );

  const bracketRef = useRef(null);
  const wrapperRef = useRef(null);

  useLayoutEffect(() => {
    // update brackets to be aligned with dropdowns
    if (windowWidth && wrapperRef?.current?.offsetLeft) {
      setStandingsMargin(wrapperRef.current.offsetLeft);
    }
  }, [windowWidth]);

  const handleTournamentMenuChange = useCallback(tournaments => {
    const { id } = tournaments.find(item => item.selected);
    setPageFilters(prevState => ({
      ...prevState,
      filterParams: {
        ...prevState.filterParams,
        appliedTournamentsFilter: id,
      },
    }));
  }, []);

  useEffect(() => {
    if (tournamentEvents.length < 1) return;
    const baseUrl = packageSlug ? `/${packageSlug}` : '';
    const ROOT_PATH = `${baseUrl}/${STANDINGS}`;
    if (!tournamentId) {
      navigate(ROOT_PATH, { replace: true });
      return;
    }
    const tournamentPath = `${ROOT_PATH}/${tournamentId}`;
    if (!activeTab) {
      navigate(tournamentPath, { replace: true });
      return;
    }
    navigate(`${tournamentPath}/${urlifyString(activeTab)}`, { replace: true });
  }, [packageSlug, tournamentId, activeTab, tournamentEvents.length, navigate]);

  const handleFiltersTabsetChange = useCallback(
    tabChange => {
      const { id } = tabChange.find(tab => tab.selected) || { id: '' };
      if (id === activeTab) return;
      setActiveTab(id);
    },
    [activeTab],
  );

  const handleTabChange = useCallback(nextView => {
    setActiveTab(nextView?.label);
  }, []);

  const isTable = useMemo(() => type === ROUND_ROBIN, [type]);
  const isBracket = useMemo(
    () => type === SINGLE_ELIMINATION || type === DOUBLE_ELIMINATION,
    [type],
  );

  if (queryDataLoading && tournamentEvents.length < 1) {
    return (
      <BodyWrapper>
        <LoadingDropdownWrapper>
          <TextSkeleton width={230} height={40} />
        </LoadingDropdownWrapper>
        <GroupStageContainer>
          <BlockContainerLoading height={250} />
        </GroupStageContainer>
      </BodyWrapper>
    );
  }

  return (
    <>
      <BodyWrapper $standings ref={wrapperRef}>
        <FilterPadding>
          <FiltersTabset
            filterOptions={filterOptions}
            tournamentOptions={tournamentFilterOptions}
            setTournament={handleTournamentMenuChange}
            tabs={tabOptions}
            activeTab={activeTab}
            setTab={handleFiltersTabsetChange}
            type={STANDINGS}
          />
        </FilterPadding>
        {!isDesktopOrGreater && (
          <TabsetWrapper>
            <Tabset
              size="small"
              tabs={mobileTabLabels}
              activeTab={activeTab}
              onClickTab={handleTabChange}
              alignment="left"
              isMobile={isMobile}
            />
          </TabsetWrapper>
        )}
        {isTable && (
          <div data-testid="groups-container">
            <GroupStage
              tournamentStageId={tournamentStage}
              gameTitle={gameTitle}
              selectedTeams={selectedTeams}
            />
          </div>
        )}
      </BodyWrapper>
      {/* overflow: 'scroll' to allow sinple scroll without having to hold and drag  */}
      {isBracket && isTabletOrGreater && (
        <ScrollContainer style={{ overflow: 'scroll', paddingLeft: `${standingsMargin}px` }}>
          <Bracket
            tournamentStageId={tournamentStage}
            ref={bracketRef}
            teams={teams}
            selectedTeams={selectedTeams}
          />
        </ScrollContainer>
      )}
      {isBracket && !isTabletOrGreater && (
        <CompactBracket
          tournamentStageId={tournamentStage}
          teams={teams}
          selectedTeams={selectedTeams}
        />
      )}
    </>
  );
};

export default Standings;
