import { memo } from 'react';
import PropTypes from 'prop-types';
import AvatarWithTitlesSkeleton, {
  XSMALL,
  SMALL,
  MEDIUM,
} from 'znipe-elements/feedback/AvatarWithTitlesSkeleton/AvatarWithTitlesSkeleton';
import { Container } from './TopicLink.styles';

const TopicLinkLoading = ({ size = SMALL }) => (
  <Container $size={size} $roundedCorner>
    <AvatarWithTitlesSkeleton size={size === SMALL ? XSMALL : SMALL} />
  </Container>
);

TopicLinkLoading.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM]),
};

export default memo(TopicLinkLoading);
