import { memo } from 'react';
import PropTypes from 'prop-types';

import { Text, CardInfoSVG } from './CcvInformation.styles';

const CcvInformationImage = () => (
  <CardInfoSVG data-testid="general-info" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.81372"
      y="1.05347"
      width="243.953"
      height="129.712"
      rx="8"
      fill="#E9E9E9"
      stroke="#B9B9B9"
      strokeWidth="2"
    />
    <rect x="1.67017" y="21.1365" width="245.096" height="29.0991" fill="#B9B9B9" />
    <rect x="13.3362" y="60.2537" width="190.547" height="28.7132" fill="#D7D7D7" />
    <path
      d="M181.041 69.8701V79.1101H179.487V71.6341L177.317 73.0341L176.603 71.8721L179.613 69.8701H181.041ZM190.093 79.1101H184.143V77.7801L186.425 75.4281C187.116 74.7188 187.601 74.1634 187.881 73.7621C188.161 73.3608 188.301 72.9221 188.301 72.4461C188.301 71.9608 188.166 71.6154 187.895 71.4101C187.625 71.2048 187.181 71.1021 186.565 71.1021C185.987 71.1021 185.347 71.1674 184.647 71.2981L184.297 71.3541L184.199 70.1221C185.105 69.8514 186.043 69.7161 187.013 69.7161C188.945 69.7161 189.911 70.5748 189.911 72.2921C189.911 72.9641 189.762 73.5474 189.463 74.0421C189.174 74.5368 188.651 75.1341 187.895 75.8341L185.921 77.7521H190.093V79.1101ZM191.963 70.1641C192.831 69.8654 193.783 69.7161 194.819 69.7161C195.855 69.7161 196.607 69.9168 197.073 70.3181C197.549 70.7101 197.787 71.3354 197.787 72.1941C197.787 72.5674 197.713 72.9034 197.563 73.2021C197.423 73.5008 197.279 73.7201 197.129 73.8601C196.98 73.9908 196.793 74.1308 196.569 74.2801C197.064 74.5134 197.419 74.7701 197.633 75.0501C197.857 75.3208 197.969 75.7641 197.969 76.3801C197.969 77.3601 197.722 78.0881 197.227 78.5641C196.742 79.0308 195.963 79.2641 194.889 79.2641C194.469 79.2641 194.007 79.2268 193.503 79.1521C192.999 79.0868 192.598 79.0168 192.299 78.9421L191.865 78.8301L191.963 77.6261C192.99 77.8128 193.895 77.9061 194.679 77.9061C195.799 77.8968 196.359 77.4021 196.359 76.4221C196.359 75.9741 196.215 75.6334 195.925 75.4001C195.636 75.1668 195.253 75.0454 194.777 75.0361H192.915V73.7341H194.777C195.123 73.7341 195.44 73.5941 195.729 73.3141C196.028 73.0248 196.177 72.6794 196.177 72.2781C196.177 71.8674 196.047 71.5641 195.785 71.3681C195.533 71.1721 195.183 71.0741 194.735 71.0741C193.998 71.0741 193.237 71.1394 192.453 71.2701L192.089 71.3401L191.963 70.1641Z"
      fill="black"
    />
    <path
      d="M209.864 74.4096C209.864 83.9283 200.358 91.9669 188.193 91.9669C176.028 91.9669 166.523 83.9283 166.523 74.4096C166.523 64.8909 176.028 56.8523 188.193 56.8523C200.358 56.8523 209.864 64.8909 209.864 74.4096Z"
      stroke="#EB5757"
      strokeWidth="2"
    />
  </CardInfoSVG>
);

const GeneralInformation = ({ code }) => (
  <>
    <CcvInformationImage />
    <Text>{`The ${code} code is a 3-4 digit long number on the back of your card`}</Text>
  </>
);

GeneralInformation.propTypes = {
  code: PropTypes.string.isRequired,
};

export default memo(GeneralInformation);
