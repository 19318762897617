import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedPlaylist from './makeGetSelectedPlaylistV2';

const emptyArray = [];

const makeGetPlaylistHighlights = () => {
  const getSelectedPlaylist = makeGetSelectedPlaylist();
  return createSelector(
    [getSelectedPlaylist],
    selectedPlaylist => selectedPlaylist?.highlights ?? emptyArray,
  );
};

export const useGetPlaylistHighlights = props =>
  useMemoizedGqlSelector(makeGetPlaylistHighlights, props);

export default makeGetPlaylistHighlights;
