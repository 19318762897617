import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import ls from 'local-storage';
import deepmerge from 'deepmerge';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useIsOnboardingPlayed } from 'tv-selectors/auth/makeGetIsOnboardingStepPlayed';
import { LS_ONBOARDING } from 'tv-constants/localStorage';
import { updateAuth } from 'tv-actions/old/auth';

const useSetPlayedStatus = stepId => {
  const dispatch = useDispatch();
  const userId = useUserId();
  const isPlayed = useIsOnboardingPlayed({ stepId });
  const setPlayedStatus = useCallback(
    played => {
      if (!stepId || isPlayed === played) return;

      const currentOnboardingData = ls.get(LS_ONBOARDING) || {};
      const steps = {
        [stepId]: { played },
      };
      const newOnboardingObject = deepmerge(currentOnboardingData, { steps });
      ls.set(LS_ONBOARDING, newOnboardingObject);
      dispatch(updateAuth({ onboarding: newOnboardingObject }));

      if (!userId) return;

      const hintsPlayedRef = firebase
        .database()
        .ref('users')
        .child(userId)
        .child('onboarding')
        .child('steps')
        .child(stepId)
        .child('played');

      hintsPlayedRef.set(played);
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    [stepId, isPlayed, dispatch, userId],
  );

  return setPlayedStatus;
};

export default useSetPlayedStatus;
