import { combineEpics } from 'redux-observable';
import champSelectEpic from 'tv-epics/champSelectEpic';
import championKillsEpic from 'tv-epics/championKillsEpic';
import epicMonsterKillsEpic from 'tv-epics/epicMonsterKillsEpic';
import gamePauseEvents from 'tv-epics/gamePausesEpic';
import gameTimeInfoEpic from 'tv-epics/gameTimeInfoEpic';
import gatCacheEpic from 'tv-epics/gatCacheEpic';
import gatOffsetEpic from 'tv-epics/gatOffsetEpic';
import roundDamageSummary from 'tv-epics/roundDamageSummaryEpic';
import roundEndSummaryEpic from 'tv-epics/roundEndSummaryEpic';
import roundKillAssistsSummaryEpic from 'tv-epics/roundKillAssistsSummaryEpic';
import roundKillsSummary from 'tv-epics/roundKillsSummaryEpic';
import roundLoadoutEpic from 'tv-epics/roundLoadoutEpic';
import sequentialEpic from 'tv-epics/sequentialEpic';
import skillLevelUpEpic from 'tv-epics/skillLevelUpEpic';
import statsUpdateEpic from 'tv-epics/statsUpdateEpic';
import structuresDestroyedEpic from 'tv-epics/structuresDestroyedEpic';
import timeSeriesEpic from 'tv-epics/timeSeriesEpic';

import csgoStatsEpic from 'tv-epics/csgo/csgoStatsEpic';

export default combineEpics(
  champSelectEpic,
  championKillsEpic,
  csgoStatsEpic,
  epicMonsterKillsEpic,
  gamePauseEvents,
  gameTimeInfoEpic,
  gatCacheEpic,
  gatOffsetEpic,
  roundDamageSummary,
  roundEndSummaryEpic,
  roundKillAssistsSummaryEpic,
  roundKillsSummary,
  roundLoadoutEpic,
  sequentialEpic,
  skillLevelUpEpic,
  statsUpdateEpic,
  structuresDestroyedEpic,
  timeSeriesEpic,
);
