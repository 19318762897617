import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import SubscribeButton from '../SubscribeButton/SubscribeButton';
import {
  BUTTON_TYPE,
  FEATURES_TYPE,
  featuresListText,
  descriptionText,
} from './IntroducePremium.constants';
import {
  Container,
  Row,
  LogoRow,
  List,
  ListItem,
  CheckIconWrapper,
  LogoWrapper,
  ListSubButton,
} from './IntroducePremium.styles';

const proviewLogoSize = {
  small: { width: 80, height: 40 },
  medium: { width: 128, height: 64 },
};

const sizes = {
  desktop: {
    description: 'paragraph-m',
    pricing: 'title-s',
    logo: proviewLogoSize.small,
  },
  tablet: {
    description: 'paragraph-l',
    pricing: 'title-xs',
    logo: proviewLogoSize.medium,
  },
  mobile: {
    description: 'paragraph-m',
    pricing: 'title-xs',
    logo: proviewLogoSize.small,
  },
};

const IntroducePremium = ({ type = BUTTON_TYPE, monthlyPrice = '', isInteractable = true }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();

  const elementSize = useMemo(() => {
    if (isDesktopOrGreater) return sizes.desktop;
    if (isTabletOrGreater) return sizes.tablet;
    return sizes.mobile;
  }, [isTabletOrGreater, isDesktopOrGreater]);

  const pricingInfoRow = useMemo(
    () =>
      monthlyPrice && (
        <Row data-testid="price-info">
          <Typography type={elementSize.pricing}>{monthlyPrice} / MONTH</Typography>
        </Row>
      ),
    [monthlyPrice, elementSize],
  );

  const subscribeButtonSize = useMemo(
    () => (isTabletOrGreater && !isDesktopOrGreater ? 'medium' : 'small'),
    [isTabletOrGreater, isDesktopOrGreater],
  );

  const bottomContent = useMemo(() => {
    if (type === FEATURES_TYPE)
      return (
        <Row $type={type}>
          {isInteractable ? (
            <ListSubButton>
              <SubscribeButton size={subscribeButtonSize} />
            </ListSubButton>
          ) : (
            <List data-testid="features-list">
              {featuresListText.map(text => (
                <ListItem key={`feature-${text.slice(' ')[0]}`}>
                  <CheckIconWrapper>
                    <Icon type="check" size={16} />
                  </CheckIconWrapper>
                  <Typography type="paragraph-m">{text}</Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Row>
      );
    return (
      <>
        <Row $type={type} data-testid="buy-button">
          <SubscribeButton size={subscribeButtonSize} />
        </Row>
        {pricingInfoRow}
      </>
    );
  }, [type, pricingInfoRow, subscribeButtonSize, isInteractable]);

  return (
    <Container $isButtonType={type === BUTTON_TYPE} data-testid="container">
      <LogoRow>
        <LogoWrapper $logoSize={elementSize.logo}>
          <Icon type="proViewLogo" />
        </LogoWrapper>
      </LogoRow>
      <Row $type="description">
        <Typography type={elementSize.description}>{descriptionText[type]}</Typography>
      </Row>
      {bottomContent}
    </Container>
  );
};

IntroducePremium.propTypes = {
  type: PropTypes.oneOf([BUTTON_TYPE, FEATURES_TYPE]),
  monthlyPrice: PropTypes.string,
  isInteractable: PropTypes.bool,
};

export default memo(IntroducePremium);
