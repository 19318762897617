import MobileDetect from 'mobile-detect';

export const isMobile = () => {
  if (!global.document) return false;
  const md = new MobileDetect(window.navigator.userAgent) || {};
  return md.mobile();
};

export const isIOS = () => {
  if (!global.document) return false;
  const md = new MobileDetect(window.navigator.userAgent) || {};
  return md.is('iOS');
};
