import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Header from 'tv-elements/layout/Header/Header';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import BookCover from 'tv-modules/Onboarding/BookCover/BookCover';
import { useShouldShowOnboardingStep } from 'tv-selectors/auth/makeGetShouldShowOnboardingStep';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import themes from './HighlightList.theme';
import {
  Container,
  Content,
  EmptyDivider,
  DividerContainer,
  TimerDivider,
  BodyContainer,
} from './HighlightList.styles';
import PlaceholderCard from '../PlaceholderCard/PlaceholderCard';

export const BOOKCOVER_ID = 'stage-highlight-cover';

const HighlightList = ({
  highlightCards = [],
  timerCards = [],
  onClose = () => {},
  sidebar = false,
}) => {
  const showBookCover = useShouldShowOnboardingStep({ stepId: BOOKCOVER_ID });
  const { highlightList } = useThemeContext();

  const [ref, setRef] = useState(null); // Need to force a re-render when setting ref
  return (
    <Container data-testid="highlight-list" ref={setRef}>
      <StickyComponent enableStickyScroll scrollTarget={ref} independent>
        <Header onClose={onClose} heading="Highlights" />
      </StickyComponent>
      <BodyContainer $isShowingTutorial={showBookCover}>
        <Content $sidebar={sidebar}>
          {timerCards.length <= 0 && highlightCards.length <= 0 && (
            <PlaceholderCard data-testId="highlight-placeholder" />
          )}
          {timerCards}
          {timerCards.length > 0 && timerCards[0] && (
            <DividerContainer>
              <EmptyDivider />
              <TimerDivider $size="small" />
            </DividerContainer>
          )}
          {highlightCards}
        </Content>
        {showBookCover && (
          <BookCover
            bookCoverId={BOOKCOVER_ID}
            headline={highlightList.bookCover.headline}
            paragraphs={highlightList.bookCover.paragraphs}
            buttonLabel={highlightList.bookCover.buttonLabel}
            headerImage={highlightList.bookCover.headerImage}
          />
        )}
      </BodyContainer>
    </Container>
  );
};

HighlightList.propTypes = {
  highlightCards: PropTypes.arrayOf(PropTypes.node),
  timerCards: PropTypes.arrayOf(PropTypes.node),
  onClose: PropTypes.func,
  sidebar: PropTypes.bool,
};
export default withTheme(memo(HighlightList), themes, 'highlightList');
