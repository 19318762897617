import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';

const getChampionIdFromProps = (_, props) => props.championId ?? '';

const makeGetChampionLoreText = () => {
  const getChampions = makeGetChampions();
  return createSelector([getChampions, getChampionIdFromProps], (allChampions, championId) => {
    if (!allChampions || !championId) return '';
    return allChampions[championId]?.lore ?? '';
  });
};

export const useGetChampionLoreText = props =>
  useMemoizedGqlSelector(makeGetChampionLoreText, props);

export default makeGetChampionLoreText;
