import styled, { css } from 'styled-components';
import gradients from 'znipe-styles/gradients';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import { SMALL } from '../constants';

const verticalTextSize = {
  small: {
    fontSize: {
      1: 14,
      2: 11,
      3: 8,
    },
    lineHeight: 28,
  },
  medium: {
    fontSize: {
      1: 18,
      2: 13,
      3: 10,
    },
    lineHeight: 36,
  },
};

const getVerticalTextCss = (size = SMALL, level = 1) => {
  const sizeSet = verticalTextSize[size];
  return css`
    font-size: ${sizeSet.fontSize[level]}px;
    line-height: ${sizeSet.lineHeight}px;
  `;
};

const getTitleFontSize = (text, size) => {
  if (!text) return getVerticalTextCss();
  const textLength = text.length;
  if (textLength > 12) return getVerticalTextCss(size, 3);
  return textLength > 10 ? getVerticalTextCss(size, 2) : getVerticalTextCss(size, 1);
};

export const Container = styled.div`
  ${props => props.as === 'button' && buttonDefaultStyle}
  position: relative;
  display: flex;
  align-items: center;
  ${({ isHorizontal }) =>
    !isHorizontal &&
    css`
      padding: 16px 0;
      width: fit-content;
    `}
`;

export const VerticalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ size }) =>
    size === SMALL
      ? css`
          width: 80px;
          height: 70px;
        `
      : css`
          width: 96px;
          height: 94px;
        `}
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  ${({ size }) =>
    size === SMALL
      ? css`
          width: 48px;
          height: 48px;
        `
      : css`
          width: 64px;
          height: 64px;
        `}
`;

export const VerticalTitle = styled.div`
  color: ${({ theme }) => theme.primaryTextColor};
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  ${({ text, size }) => getTitleFontSize(text, size)};
`;

export const BackgroundLayer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 0;
  bottom: 0;
  background: ${gradients.searchResultItemHover};
`;
