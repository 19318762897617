import PropTypes from 'prop-types';
import { TeamImage, Image, Container } from './PlayerImage.styles';

const PlayerImage = ({ image, teamLogo, type = 'left' }) => (
  <Container>
    {teamLogo && <TeamImage alt="Team image" src={teamLogo} $type={type} />}
    <Image data-testid="player-img" alt="Player image" src={image} />
  </Container>
);

PlayerImage.propTypes = {
  image: PropTypes.string.isRequired,
  teamLogo: PropTypes.string,
  type: PropTypes.oneOf(['left', 'right']),
};
export default PlayerImage;
