import { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Background, Foreground } from './TextOverlay.styles';
import { SMALL, MEDIUM, LARGE, SOLID, OUTLINE } from './TextOverlay.constants';

const TextOverlay = ({
  backgroundText,
  foregroundText,
  strokeColor = '#ff7fb7',
  horizontalOffset = 0.75,
  size = SMALL,
  type = OUTLINE,
}) => {
  const textRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Need to recalculate on text change
  useEffect(() => {
    const { width } = textRef.current.firstChild.getBoundingClientRect();
    const translateValue = Math.round(width * horizontalOffset);
    setTranslateX(translateValue);
  }, [backgroundText, foregroundText, horizontalOffset]);

  return (
    <Container ref={textRef} data-testid="text-overlay-container">
      <Background size={size} data-testid="background-text" strokeColor={strokeColor} type={type}>
        {backgroundText}
      </Background>
      <Foreground size={size} translateX={translateX} data-testid="foreground-text" type={type}>
        {foregroundText}
      </Foreground>
    </Container>
  );
};

TextOverlay.propTypes = {
  backgroundText: PropTypes.string.isRequired,
  foregroundText: PropTypes.string.isRequired,
  strokeColor: PropTypes.string,
  type: PropTypes.oneOf([SOLID, OUTLINE]),
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  horizontalOffset: (props, propName, componentName) => {
    const { horizontalOffset } = props;
    if (typeof horizontalOffset !== 'number' || horizontalOffset < 0 || horizontalOffset > 1) {
      return new Error(
        `${propName} in ${componentName} is not within 0 to 1. Should be a decimal percentage`,
      );
    }
    return null;
  },
};

export default memo(TextOverlay);
