import objectToQueryString from 'znipe-utils/location/objectToQueryString';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';

const addQueriesToUrl = (url = '', queries) => {
  if (!queries) return url;
  const baseUrl = url.split('?')[0];
  const queryString = url.indexOf('?') !== -1 ? url.split('?')[1] : '';
  const queryObject = queryStringToObject(`?${queryString}`);
  const providedQueries = objectToQueryString({ ...queryObject, ...queries });
  return `${baseUrl}${providedQueries}`;
};

export default addQueriesToUrl;
