import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const themes = {
  default: {
    levelColor: colors.riotAccentGold,
  },
};

export const ChampionImageWrapper = styled.div`
  transform: ${({ $isCircle }) => ($isCircle ? 'scale(1.16)' : 'scale(1)')};
  display: flex;
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  bottom: 0;
  ${({ $alignment, $isCircle, $isLevelItem }) => {
    const leftMarginStyles = css`
      margin: auto auto -7px -7px;
    `;
    const rightMarginStyles = css`
      margin: auto -7px -7px auto;
    `;

    if ($alignment === 'left')
      return css`
        left: 0;
        ${!$isCircle && $isLevelItem && leftMarginStyles}
      `;
    if ($alignment === 'right')
      return css`
        right: 0;
        ${!$isCircle && $isLevelItem && rightMarginStyles}
      `;

    return css`
      left: 50%;
      transform: translateX(-50%);
      bottom: -9px;
      margin: 0;
    `;
  }}
`;

export const LevelContainer = styled.div`
  border: 1px solid ${colors.black};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.8);
  color: ${({ theme }) => theme.championAvatar.levelColor};
`;

export const LevelTextWrapper = styled.div`
  margin-left: -1px;
  margin-top: -1px;
`;

export const IconContainer = styled.div`
  height: ${({ $alignment }) => ($alignment === 'center' ? '24px' : '16px')};
`;

export const DefaultChampionWrapper = styled.div`
  margin: auto;
  height: 70%;
  color: ${({ theme }) => theme.containerStroke};
`;
