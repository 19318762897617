import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsLaptopOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.laptop || false);
};

export const useGetIsLaptopOrGreater = () => useMemoizedSelector(makeGetIsLaptopOrGreater);

export default makeGetIsLaptopOrGreater;
