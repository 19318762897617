import baseLoadable, {
  DefaultComponent,
  LoadableComponent,
  OptionsWithoutResolver,
} from '@loadable/component';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';

const fallback = <LoadingSpinner />;
const loadable = <Props,>(
  component: (props: Props) => Promise<DefaultComponent<Props>>,
  options?: OptionsWithoutResolver<Props>,
): LoadableComponent<Props> => {
  const loadableOptions: OptionsWithoutResolver<Props> = { fallback, ...options };

  return baseLoadable(component, loadableOptions);
};
export default loadable;
