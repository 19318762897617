import { schema } from 'normalizr';

const keys = ['id', 'name', 'htmlDescription', 'image', 'description'];

const perk = new schema.Entity(
  'perks',
  {},
  {
    processStrategy: values =>
      keys.reduce((acc, key) => {
        const val = values[key];
        if (val) acc[key] = val;
        return acc;
      }, {}),
  },
);

export default perk;
