import PropTypes from 'prop-types';

const { shape, number, string } = PropTypes;

export const predictionType = shape({
  id: string,
  variant: string,
  label: string,
  outcome: string,
  predictedValue: string,
  type: string,
  expiresIn: number,
  gameOffset: number,
  options: shape({
    id: shape({
      id: string,
      type: string,
      label: string,
      value: string,
      target: string,
      targetType: string,
    }),
  }),
});

export const scenarioType = shape({
  id: string,
  variant: string,
  label: string,
  outcome: string,
  type: string,
  expiresIn: number,
  gameOffset: number,
  options: shape({
    id: shape({
      id: string,
      type: string,
      label: string,
      value: string,
      target: string,
      targetType: string,
    }),
  }),
});
