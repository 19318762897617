import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import colors from 'znipe-styles/colors';
import styled from 'styled-components';
import Icon from 'znipe-elements/general/Icon/Icon';
import gradients from 'znipe-styles/gradients';
import useHasMounted from 'znipe-hooks/useHasMounted';

const Triangle = styled.div`
  position: absolute;
  height: 30px;
  width: 50px;
  background: ${gradients.glimmeringGold.replace('44.89deg', '320deg')};
  transform: rotate(-45deg);
  left: -19px;
  top: ${({ $size }) => ($size === 'medium' ? -10 : -18)}px;
`;

const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 2px;
  right: 1px;
  margin: ${({ $size }) => ($size === 'medium' ? 2 : 1)}px;
`;

const Container = styled.div.attrs(() => ({
  type: 'button',
}))`
  position: relative;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const TriangleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transform: rotate(180deg);
  width: ${({ $size }) => ($size === 'medium' ? 32 : 24)}px;
  height: ${({ $size }) => ($size === 'medium' ? 32 : 24)}px;
`;

const PremiumIcon = ({ size = 'small', onClick }) => {
  const iconSize = useMemo(() => (size === 'medium' ? 13 : 10), [size]);
  const mounted = useHasMounted();
  if (!mounted) return null;
  return (
    <Container
      $size={size}
      onClick={onClick}
      aria-hidden="true"
      data-testid="premium-icon-container"
    >
      <TriangleWrapper $size={size}>
        <Triangle $size={size} />
      </TriangleWrapper>
      <IconWrapper $size={size}>
        <Icon inline size={iconSize} type="zap" fillColor={colors.grey10} />
      </IconWrapper>
    </Container>
  );
};

PremiumIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  onClick: PropTypes.func,
};

export default memo(PremiumIcon);
