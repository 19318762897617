import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import useDimensions from 'react-cool-dimensions';
import Typography from 'znipe-elements/general/Typography/Typography';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import { GamePause, StrikeThrough } from './PauseMarker.styles';

const labels = {
  chrono_break: 'chrono break',
  default: 'pause',
};

const PauseMarker = ({ offset, timeStart, id, timeEnd }) => {
  const { ref, width: strikeThroughWidth } = useDimensions({
    useBorderBoxSize: true,
  });

  const pauseStart = useIntensiveSelector(state => (state.pauses[id]?.startTime ?? 0) + offset);
  const pauseEnd = useIntensiveSelector(state => {
    const duration = state.pauses[id]?.duration;
    if (!duration) return undefined;
    return pauseStart + duration;
  });
  const label = useIntensiveSelector(
    state => labels[state.pauses[id]?.actionType] ?? labels.default,
  );
  const end = pauseEnd ?? timeEnd;
  const width = useMemo(
    () => (((end - pauseStart) * 100) / (timeEnd - timeStart)).toFixed(2),
    [timeEnd, end, pauseStart, timeStart],
  );
  const showText = strikeThroughWidth > label.length * 8.5;
  const position = useMemo(
    () => (((pauseStart - timeStart) * 100) / (timeEnd - timeStart)).toFixed(2),
    [pauseStart, timeEnd, timeStart],
  );
  return (
    <GamePause data-testid="pause-marker" $pauseWidth={width} $left={position}>
      <StrikeThrough ref={ref} $showText={showText}>
        {showText && (
          <Typography as="span" type="paragraph-s">
            {label}
          </Typography>
        )}
      </StrikeThrough>
    </GamePause>
  );
};

PauseMarker.propTypes = {
  offset: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  timeStart: PropTypes.number.isRequired,
  timeEnd: PropTypes.number.isRequired,
};

export default memo(PauseMarker);
