import { ReactNode, useRef, useEffect, useMemo } from 'react';
import useMentionAutoComplete from 'znipe-chat/src/hooks/useMentionAutoComplete';
import {
  Container,
  ListItem,
  ScrollArea,
} from '../Emojis/EmojiAutoComplete/EmojiAutoComplete.styles';
import { getHighlightedTypography } from '../Emojis/EmojiAutoComplete/EmojiAutoComplete';
import { UserListItem } from '../../contexts/ChatContext';

type MentionAutoCompleteProps = {
  query?: string;
  onSelect: (user: UserListItem) => void;
};

const SCROLL_MIN_ITEM_COUNT = 4;

const MentionAutoComplete: React.FC<MentionAutoCompleteProps> = ({ query = '', onSelect }) => {
  const scrollRef = useRef<(HTMLElement | null)[]>([]);
  const { result, containerRef, focusedItemIndex } = useMentionAutoComplete(query, onSelect);

  useEffect(() => {
    const scrollTargetIndex =
      focusedItemIndex < SCROLL_MIN_ITEM_COUNT - 1
        ? 0
        : focusedItemIndex - SCROLL_MIN_ITEM_COUNT + 1;
    if (scrollTargetIndex > -1)
      scrollRef.current?.[scrollTargetIndex]?.scrollIntoView({ behavior: 'smooth' });
  }, [focusedItemIndex]);

  const queryResult = useMemo(
    () =>
      result.filter(Boolean).filter((item: UserListItem) => {
        if (!item || !item.username) return false;
        return item.username.includes(query);
      }) ?? [],
    [query, result],
  );

  if (!result.length) return null;

  return (
    <Container ref={containerRef} $hasScroll={result.length > SCROLL_MIN_ITEM_COUNT}>
      <ScrollArea
        data-testid="mention-auto-complete-container"
        $hasScrollFrom={SCROLL_MIN_ITEM_COUNT}
      >
        {queryResult.filter(Boolean).map<ReactNode>((user: UserListItem, index) => {
          const { username } = user;
          // TO DO: fix combined
          const combined = getHighlightedTypography(username, query, true);
          const onClick = () => onSelect(user);

          return (
            <ListItem
              ref={ref => {
                scrollRef.current[index] = ref as HTMLElement;
              }}
              key={user?.username}
              $hasFocus={focusedItemIndex === index}
              $height={30}
              $color={user?.color}
              onClick={onClick}
            >
              {combined}
            </ListItem>
          );
        })}
      </ScrollArea>
    </Container>
  );
};

export default MentionAutoComplete;
