import { useEffect, useState, useCallback, memo, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import throttle from 'lodash.throttle';
import Controls from 'tv-modules/Player/Controls/Controls';
import useListenToFullscreenEvent from 'tv-hooks/useListenToFullscreenEvent';
import { useGetIsCompactPlayer } from 'tv-selectors/ui/makeGetIsCompactPlayer';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import { animated } from '@react-spring/web';
import ReplayAnimation from '../components/ReplayAnimation/ReplayAnimation';
import ScreenControlsDesktop from '../components/ScreenControls/ScreenControlsDesktop';
import ScreenStats from '../components/ScreenStats/ScreenStats';
import ScreenPovSelectors from '../components/ScreenPovSelectors/ScreenPovSelectors';
import { VIEW_DEFAULT, VIEW_STATS, VIEW_STREAMS, VIEW_HIGHLIGHTS, VIEW_ROOMS } from '../constants';
import themes from '../ScreenOverlay.themes';
import {
  DesktopContainer,
  DesktopBody,
  DesktopFooter,
  FooterButtonsWrapper,
  SeekbarWrapper,
  CompletedVideoContentContainer,
  CompletedIconElement,
  CompletedIconWrapper,
  CompletedLabelWrapper,
  ReplayButtonContainer,
  ReplayAnimationWrapper,
} from './DesktopScreenOverlay.styles';

const AnimatedDesktopFooter = animated(DesktopFooter);

const DesktopScreenOverlay = ({
  matchId,
  selectedMatchGameIndex,
  playerRef = playerDefaultProps,
  playerContainerRef = { current: {} },
  hoverTimeout = 1500,
  hasFinishedPlaying = false,
  playlistAutoplayingEnabled = false,
  hasScreenControls = true,
  onWatchNext,
  onAutoplayCancel,
  springProps = {},
}) => {
  const [view, setView] = useState(VIEW_DEFAULT);
  const [isHovering, setIsHovering] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const persitHoverState = useRef(false);
  const timer = useRef(null);

  useListenToFullscreenEvent(playerContainerRef, setIsFullscreen);

  const handlePersistentHover = useCallback(newState => {
    persitHoverState.current = newState;
  }, []);

  const onStatsClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      const selectedView = view === VIEW_STATS ? VIEW_DEFAULT : VIEW_STATS;
      setView(selectedView);
      handlePersistentHover(true);
    },
    [view, handlePersistentHover],
  );

  const onStreamClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      const selectedView = view === VIEW_STREAMS ? VIEW_DEFAULT : VIEW_STREAMS;
      setView(selectedView);
      handlePersistentHover(true);
    },
    [view, handlePersistentHover],
  );

  const onHighlightClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      const selectedView = view === VIEW_HIGHLIGHTS ? VIEW_DEFAULT : VIEW_HIGHLIGHTS;
      setView(selectedView);
      handlePersistentHover(true);
    },
    [view, handlePersistentHover],
  );

  const onRoomClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      const selectedView = view === VIEW_ROOMS ? VIEW_DEFAULT : VIEW_ROOMS;
      setView(selectedView);
      handlePersistentHover(true);
    },
    [view, handlePersistentHover],
  );

  const onCloseView = useCallback(
    e => {
      if (e) e.stopPropagation();
      setView(VIEW_DEFAULT);
      handlePersistentHover(false);
    },
    [handlePersistentHover],
  );

  const handleMouseMove = useMemo(
    () =>
      throttle(e => {
        if (e.type !== 'mouseleave') {
          setIsHovering(true);
        }
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          if (!persitHoverState.current) {
            setIsHovering(persitHoverState.current);
          }
        }, hoverTimeout);
      }, 300),
    [hoverTimeout],
  );

  const isCompactPlayer = useGetIsCompactPlayer();

  const handleClick = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (!persitHoverState.current) {
        setIsHovering(false);
      }
    }, hoverTimeout);
  };

  const handleWatchAgain = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    player.play();
  }, [playerRef]);

  useEffect(() => {
    const player = playerContainerRef.current;
    if (!player) return () => {};
    player.addEventListener('mousemove', handleMouseMove);
    player.addEventListener('mouseleave', handleMouseMove);
    return () => {
      player.removeEventListener('mousemove', handleMouseMove);
      player.removeEventListener('mouseleave', handleMouseMove);
      handleMouseMove.cancel();
    };
  }, [playerContainerRef, handleMouseMove]);

  // reset view on fullscreen close
  useEffect(() => {
    if (!isFullscreen) {
      setView(VIEW_DEFAULT);
    }
  }, [isFullscreen]);

  useEffect(() => () => clearTimeout(timer.current), []);

  const springStylesSeekbar = useMemo(
    () => (isFullscreen ? {} : { width: springProps?.width, transform: springProps?.transform }),
    [springProps, isFullscreen],
  );

  const shouldShowStatsView = view === VIEW_STATS;
  const shouldShowStreamsView = view === VIEW_STREAMS;
  const shouldShowDefaultView = view === VIEW_DEFAULT;
  const shouldUseFullscreenOverlay =
    (isFullscreen && !isCompactPlayer && hasScreenControls) || view !== VIEW_DEFAULT;
  const shouldShowFooterButtons = isFullscreen && !isCompactPlayer && hasScreenControls;
  const isVisible = isHovering || hasFinishedPlaying;
  return (
    <DesktopContainer
      data-testid="desktop-screen-overlay"
      $shouldShowDefaultView={shouldShowDefaultView}
      $isVisible={isVisible}
      $shouldUseFullscreenOverlay={shouldUseFullscreenOverlay}
      onClick={handleClick}
    >
      <DesktopBody>
        {isVisible && isFullscreen && hasScreenControls && (
          <ScreenControlsDesktop
            view={view}
            visible={isVisible}
            onStatsClick={onStatsClick}
            onStreamClick={onStreamClick}
            onHighlightClick={onHighlightClick}
            onRoomClick={onRoomClick}
            onViewClose={onCloseView}
          >
            {shouldShowStatsView && (
              <ScreenStats matchId={matchId} selectedMatchGameIndex={selectedMatchGameIndex} />
            )}
            {shouldShowStreamsView && (
              <ScreenPovSelectors
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                playerRef={playerRef}
                allowPopingOut={false}
              />
            )}
          </ScreenControlsDesktop>
        )}
        {hasFinishedPlaying && (
          <CompletedVideoContentContainer>
            {playlistAutoplayingEnabled && onWatchNext && (
              <ReplayButtonContainer>
                <ReplayAnimationWrapper onClick={onWatchNext}>
                  <ReplayAnimation iconSize={40} onAnimationComplete={onWatchNext} />
                </ReplayAnimationWrapper>
                <CompletedLabelWrapper onClick={onAutoplayCancel}>
                  <Typography type="title-s">Cancel</Typography>
                </CompletedLabelWrapper>
              </ReplayButtonContainer>
            )}
            {(!playlistAutoplayingEnabled || !onWatchNext) && (
              <>
                <CompletedIconElement onClick={handleWatchAgain}>
                  <CompletedIconWrapper>
                    <Icon type="watchAgain" size={40} />
                  </CompletedIconWrapper>
                  <CompletedLabelWrapper>
                    <Typography type="title-s">Watch Again</Typography>
                  </CompletedLabelWrapper>
                </CompletedIconElement>
                {onWatchNext && (
                  <CompletedIconElement onClick={onWatchNext}>
                    <CompletedIconWrapper>
                      <Icon type="watchNext" size={40} />
                    </CompletedIconWrapper>
                    <CompletedLabelWrapper>
                      <Typography type="title-s">Watch next</Typography>
                    </CompletedLabelWrapper>
                  </CompletedIconElement>
                )}
              </>
            )}
          </CompletedVideoContentContainer>
        )}
      </DesktopBody>
      <AnimatedDesktopFooter
        data-testid="desktop-screen-overlay-controls"
        $isVisible={isVisible}
        $shouldUseFullscreenOverlay={shouldUseFullscreenOverlay}
        style={springStylesSeekbar}
      >
        {shouldShowFooterButtons && (
          <FooterButtonsWrapper>
            {/*   <NavButton
              size="large"
              iconType="stats"
              label="Stats"
              onClick={onStatsClick}
              showLabel
            /> */}
            <NavButton
              size="large"
              iconType="stream"
              label="Streams"
              onClick={onStreamClick}
              showLabel
            />
          </FooterButtonsWrapper>
        )}
        <SeekbarWrapper $useMaxWidth={shouldShowFooterButtons}>
          <Controls
            playerRef={playerRef}
            visible={isVisible}
            playerContainerRef={playerContainerRef}
            togglePersitentHoverState={handlePersistentHover}
            matchId={matchId}
            hasFinishedPlaying={hasFinishedPlaying}
          />
        </SeekbarWrapper>
        {/* {shouldShowFooterButtons && (
          <FooterButtonsWrapper>
            <NavButton
              size="large"
              iconType="highlights"
              label="Highlights"
              showLabel
              onClick={onHighlightClick}
            />
            <NavButton iconType="room" label="Rooms" onClick={onRoomClick} showLabel size="large" />
          </FooterButtonsWrapper>
        )} */}
      </AnimatedDesktopFooter>
    </DesktopContainer>
  );
};

const springProps = {
  id: PropTypes.number,
  key: PropTypes.string,
};

DesktopScreenOverlay.propTypes = {
  matchId: PropTypes.string.isRequired,
  hoverTimeout: PropTypes.number,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  hasFinishedPlaying: PropTypes.bool,
  playlistAutoplayingEnabled: PropTypes.bool,
  hasScreenControls: PropTypes.bool,
  onWatchNext: PropTypes.func,
  onAutoplayCancel: PropTypes.func,
  playerRef: playerPropTypes,
  playerContainerRef: playerPropTypes,
  springProps: PropTypes.shape({
    transform: PropTypes.shape(springProps),
    width: PropTypes.shape(springProps),
    translate: PropTypes.shape(springProps),
  }),
};

export default withTheme(memo(DesktopScreenOverlay), themes, 'screenOverlay');
