import { useMemo, useState, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import PopoutStreamButton from 'tv-modules/Player/PopoutStreamButton/PopoutStreamButton';
import { useGetStreamLabel } from 'tv-selectors/streams/makeGetStreamLabel';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useGetGameCompetitorsLineups } from 'tv-selectors/games/makeGetGameCompetitorsLineups';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import PoweredByZnipe from 'tv-elements/general/PoweredByZnipe/PoweredByZnipe';
import {
  Container,
  SubContainer,
  ActionContainer,
  LabelText,
  IconWrapper,
  CreditContainer,
  PoweredByWrapper,
  LabelIcon,
} from './VideoOverlay.styles';

const ActionElement = memo(({ children, label }) => {
  const tooltipLabel = label.toUpperCase();
  return (
    <ToolTip label={tooltipLabel} placement="bottom">
      {children}
    </ToolTip>
  );
});

const VideoOverlay = ({
  streamLabel = '',
  onWindowPopout = () => {},
  onSoundIconClick = () => {},
  onCloseClick = () => {},
  streamId = '',
  isSoundSource = false,
  matchId = '',
  selectedMatchGameIndex = 0,
  showLogo = false,
  isPopout = false,
  isPlayerPopout = false,
  showActions = true,
}) => {
  const [isPlayingAudio, setIsPlayingAudio] = useState(isSoundSource);
  const globalStreams = useGetGameGlobalStreams({
    matchId,
    selectedMatchGameIndex,
  });

  const matchGameLineups = useGetGameCompetitorsLineups({
    matchId,
    selectedMatchGame: selectedMatchGameIndex,
  });
  const gameCompetitors = useGetGameCompetitors({
    matchId,
    selectedMatchGameIndex,
  });

  const soundSource = useGetMatchPlayerStreams({
    matchId,
  });

  const selectedTeamId = useMemo(() => {
    let teamId = '';
    gameCompetitors.forEach(({ teamId: currentTeamId = '', lineup = [] }) => {
      const findPlayer = lineup.find(player => player.streamId === streamId);
      if (findPlayer) {
        teamId = currentTeamId;
      }
    });
    return teamId;
  }, [gameCompetitors, streamId]);

  const playerId = useMemo(() => {
    const playerInfo = matchGameLineups.find(lineup => lineup.streamId === streamId) || {};
    const selectedPlayerId = playerInfo.playerId || '';
    if (selectedPlayerId) return selectedPlayerId;
    const selectedGlobalStream = globalStreams.find(stream => stream.streamId === streamId);
    if (selectedGlobalStream) return `${streamId}_${selectedGlobalStream.type || ''}`;
    return '';
  }, [matchGameLineups, globalStreams, streamId]);

  const label = useGetStreamLabel({ matchId, streamId, playerId });
  const playerTeamLogo = useGetSelectedTeamLogo({ teamId: selectedTeamId });
  const selectedStream = useMemo(
    () => globalStreams.find(stream => stream.streamId === streamId) || {},
    [globalStreams, streamId],
  );

  const isEventStream = /(map|event)/.test(selectedStream.type);
  const eventImage = selectedStream.imageSrc;
  const eventStreamLabel = selectedStream.name;

  const isSoundPlaying = useMemo(
    () => isSoundSource || isPlayingAudio,
    [isPlayingAudio, isSoundSource],
  );

  const HeaderTitle = useCallback(() => {
    if (selectedTeamId && playerTeamLogo)
      return (
        <LabelIcon src={playerTeamLogo} alt={streamLabel || label} data-testid="player-icon" />
      );
    if (!selectedTeamId && isEventStream && eventImage)
      return <LabelIcon src={eventImage} alt={streamLabel || label} data-testid="event-icon" />;
    return <Icon type="streamFilled" size={24} />;
  }, [eventImage, isEventStream, label, playerTeamLogo, selectedTeamId, streamLabel]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!isSoundSource) {
      const currentStreamSound = soundSource.find(item => item?.id === streamId) || {};
      setIsPlayingAudio(currentStreamSound.master);
    }
  }, [isPlayingAudio, isSoundSource, soundSource, streamId]);

  return (
    <Container data-testid="video-overlay">
      <SubContainer>
        <IconWrapper data-testid="label-icon-wrapper">
          <HeaderTitle />
        </IconWrapper>
        <LabelText data-testid="overlay-label">
          {streamLabel || label || eventStreamLabel}
        </LabelText>
        <ActionContainer border={showActions} data-testid="actions-container">
          {showActions && (
            <>
              <ActionElement label={isSoundPlaying ? 'Is sound source' : 'Make sound source'}>
                <IconWrapper data-testid="sound-icon-wrapper" onClick={onSoundIconClick}>
                  {isSoundPlaying ? (
                    <Icon type="sound" size={24} />
                  ) : (
                    <Icon size={24} type="mute" />
                  )}
                </IconWrapper>
              </ActionElement>
              <ActionElement label={isPopout ? 'Pop In' : 'Pop Out'}>
                <IconWrapper data-testid="pop-icon-wrapper">
                  <PopoutStreamButton
                    matchId={matchId}
                    streamId={streamId}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                    onWindowPopout={onWindowPopout}
                    isPopout={isPopout}
                    isPlayerPopout={isPlayerPopout}
                    isVideoOverlay
                  />
                </IconWrapper>
              </ActionElement>
              <ActionElement label="Close">
                <IconWrapper data-testid="close-icon-wrapper" onClick={onCloseClick}>
                  <Icon type="close" size={24} />
                </IconWrapper>
              </ActionElement>
            </>
          )}
        </ActionContainer>
      </SubContainer>
      {showLogo && (
        <CreditContainer>
          <PoweredByWrapper data-testid="znipe-logo">
            <PoweredByZnipe visible useContainerSpace />
          </PoweredByWrapper>
        </CreditContainer>
      )}
    </Container>
  );
};

VideoOverlay.propTypes = {
  streamLabel: PropTypes.string,
  isSoundSource: PropTypes.bool,
  onSoundIconClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  streamId: PropTypes.string,
  matchId: PropTypes.string,
  selectedMatchGameIndex: PropTypes.number,
  showLogo: PropTypes.bool,
  showActions: PropTypes.bool,
  isPopout: PropTypes.bool,
  isPlayerPopout: PropTypes.bool,
  onWindowPopout: PropTypes.func,
};

ActionElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  label: PropTypes.string.isRequired,
};

export default VideoOverlay;
