export const TEAM = 'team';
export const TEAMS = 'teams';
export const PLAYER = 'player';
export const PLAYERS = 'players';
export const EVENT = 'event';
export const EVENTS = 'events';
export const CHAMPION = 'champion';

export const RIOTCHAMPION = 'riotChampion';
export const RIOT_CHAMPION = 'riot_champion';

export const TOPIC_TYPES = [TEAMS, PLAYERS, EVENTS];
