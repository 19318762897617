import { createSelector } from 'reselect';
import makeGetProductUserPrice from 'tv-selectors/products/makeGetProductUserPrice';
import makeGetSelectedProduct from 'tv-selectors/products/makeGetSelectedProduct';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductSubscriptionPrice = () => {
  const getProductUserPrice = makeGetProductUserPrice();
  const getSelectedProduct = makeGetSelectedProduct();
  return createSelector([getProductUserPrice, getSelectedProduct], (userPrice, product) => {
    if (userPrice.productType === 'subscription' && typeof userPrice.price === 'number') {
      return userPrice.price;
    }
    if (typeof product.subscriptionPrice === 'number') {
      return product.subscriptionPrice;
    }

    return 0;
  });
};

export const useGetProductSubscriptionPrice = props =>
  useMemoizedSelector(makeGetProductSubscriptionPrice, props);

export default makeGetProductSubscriptionPrice;
