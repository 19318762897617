import { createSelector } from 'reselect';
import makeGetState from 'tv-selectors/makeGetState';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetBrowser = () => {
  const getState = makeGetState();
  return createSelector([getState], state => state.browser || {});
};

export const useBrowser = () => useMemoizedSelector(makeGetBrowser);

export default makeGetBrowser;
