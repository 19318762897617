import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedGame from 'tv-selectors/games/makeGetSelectedGame';

const makeGetGameVodStartTimestamp = () => {
  const getSelectedGame = makeGetSelectedGame();
  return createSelector([getSelectedGame], game => game.vodStartTimestamp ?? 0);
};

export const useGetGameVodStartTimestamp = props =>
  useMemoizedGqlSelector(makeGetGameVodStartTimestamp, props);

export default makeGetGameVodStartTimestamp;
