import { schema } from 'normalizr';

const keys = ['id', 'name', 'image', 'description', 'cooldown'];

const spell = new schema.Entity(
  'spells',
  {},
  {
    processStrategy: values =>
      keys.reduce((acc, key) => {
        const val = values[key];
        if (val) acc[key] = val;
        return acc;
      }, {}),
  },
);

export default spell;
