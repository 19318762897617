import styled, { css } from 'styled-components';

export const AnimationContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const CardContainer = styled.div`
  position: relative;
  margin: 8px;
`;

export const RelativePositioning = styled.div`
  position: relative;
`;

export const BackgroundHandler = styled.div`
  width: 100%;
  height: 100%;
  background: blue;
`;

const cardContentHeight = {
  small: '40px',
  large: '60px',
};

export const CardContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: ${({ $size }) => cardContentHeight[$size]};
  padding-bottom: 12px;
  ${({ $size, $teamsCard }) =>
    $teamsCard && $size === 'large'
      ? css`
          min-height: 80px;
        `
      : css`
          min-height: ${cardContentHeight[$size]};
        `}
`;

export const TimerContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
`;

const teamVsTeamVerticalPositionLeft = {
  small: '-80px',
  large: '-100px',
};

const teamVsTeamVerticalPositionTop = {
  small: '-40px',
  large: '-30px',
};

export const TeamVsTeamWrapper = styled.div`
  position: absolute;
  left: ${({ $size }) => teamVsTeamVerticalPositionLeft[$size]};
  top: ${({ $size }) => teamVsTeamVerticalPositionTop[$size]};
`;
