import { useCallback, memo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { device } from 'znipe-styles/breakpoints';
import prefers from 'znipe-styles/prefers';
import PingPlayer from 'tv-modules/Ping/PingPlayer/PingPlayer';
import { useMakeGetHighlightPing } from 'tv-selectors/coreUI/makeGetHighlightPing';
import { setHighlightPing } from 'tv-actions/old/coreUI';

const PingWrapper = styled.div`
  width: 100%;
  max-width: 472px;
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 3;
  transform: ${({ $isVisible }) => `translate(${$isVisible ? 0 : '100%'})`};
  @media ${prefers.motion} {
    transition: 0.5s ease-out;
  }
  @media ${device.tablet} {
    max-width: 656px;
  }
  @media ${device.desktop} {
    max-width: 480px;
    bottom: 100px;
  }
  @media ${device.desktopLarge} {
    max-width: 768px;
  }
  @media ${device.desktopExtraLarge} {
    max-width: 960px;
  }
`;

const PingFrame = () => {
  const dispatch = useDispatch();
  const handlePingClose = useCallback(() => dispatch(setHighlightPing('', '')), [dispatch]);
  const highlightPing = useMakeGetHighlightPing();
  const videoId = highlightPing.videoId ?? '';
  const src = highlightPing.src ?? '';
  const startTime = highlightPing.startTime ?? 0;
  const endTime = highlightPing.endTime ?? 1;

  return (
    <PingWrapper data-testid="ping-frame" $isVisible={src}>
      {src && (
        <PingPlayer
          videoId={videoId}
          src={src}
          startTime={startTime}
          endTime={endTime}
          onClose={handlePingClose}
        />
      )}
    </PingWrapper>
  );
};

export default memo(PingFrame);
