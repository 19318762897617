import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetPredictions from './makeGetPredictions';

const getGatIdFromProps = (_, props) => props.gatId ?? '';

const makeGetGamePredictions = () => {
  const getPredictions = makeGetPredictions();
  return createSelector(
    [getPredictions, getGatIdFromProps],
    (predictions, gatId) => predictions[gatId] ?? '',
  );
};

export const useGamePrediction = props => useMemoizedSelector(makeGetGamePredictions, props);

export default makeGetGamePredictions;
