import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $isVisible }) =>
    $isVisible
      ? 'linear-gradient(180deg, rgba(0,0,0,0.57) 10%, rgba(0,0,0,0.28) 18%, rgba(255,255,255,0) 100%)'
      : 'none'};
`;

export const SeekbarWrapper = styled.div`
  position: absolute;
  top: 3px;
  width: 100%;
`;

export const PausePlayWrapper = styled.div`
  height: 32px;
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 18px;
  height: 30px;
  @media ${device.desktopExtraLarge} {
    top: 26px;
    right: 24px;
    height: 40px;
  }
`;
