import styled from 'styled-components';
import Shimmer from 'znipe-elements/feedback/Shimmer/Shimmer';

const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
`;

const VideoSkeleton = () => (
  <Skeleton data-testid="video-skeleton">
    <Shimmer type="video" />
  </Skeleton>
);

export default VideoSkeleton;
