import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';

const small = css`
  width: 24px;
  height: 24px;
`;
const medium = css`
  width: 40px;
  height: 40px;
`;
const large = css`
  width: 48px;
  height: 48px;
`;
const sizes = { small, medium, large };

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const ToggledOn = ({ theme }) => css`
  background-color: ${theme.containerStroke};
  border: 1px solid ${theme.UIAccent};
  box-shadow: inset 0px 0px 10px #000000;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0;
  margin-bottom: ${({ $showingLabel }) => ($showingLabel ? '4px' : '0px')};
  color: ${colors.white};
  background-color: ${({ theme }) => theme.tertiaryBackgroundColor};
  border: 1px solid ${({ theme }) => theme.containerStroke};
  flex-grow: 0;
  flex-shrink: 0;

  @media ${device.desktop} {
    /* stylelint-disable a11y/selector-pseudo-class-focus */
    &:hover {
      border: 1px solid ${({ theme }) => theme.UIAccent};
    }
    &:active {
      border: 1px solid ${({ theme }) => theme.UIAccent};
      box-shadow: 0px 0px 13px 0px #000000 inset;
    }
  }

  @media ${device.desktopLarge} {
    gap: 32px 0;
  }

  ${props => props.$isToggledOn && ToggledOn};

  ${({ $size }) => sizes[$size] ?? medium}
`;

const iconMedium = css`
  width: 16px;
  height: 16px;
`;
const iconLarge = css`
  width: 24px;
  height: 24px;
`;
const iconSizes = {
  medium: iconMedium,
  large: iconLarge,
};

export const IconWrapper = styled.div`
  ${({ $size }) => iconSizes[$size] ?? iconMedium}
`;
