import { StringTypes } from '../ChatLockup.types';
import { EMOJI_TAG_REGEX } from '../ChatLockup.constants';

const createStringTypes = (data: string): StringTypes => {
  const matches = data.match(EMOJI_TAG_REGEX);

  if (!matches) return data;
  const emojiIndex = data.search(EMOJI_TAG_REGEX);
  const before = data.substring(0, emojiIndex);
  const after = data.substring(emojiIndex + matches[0].length);

  const next = createStringTypes(after);
  return [before, matches[0], ...(Array.isArray(next) ? next : [next])].filter(
    Boolean,
  ) as StringTypes;
};

export default createStringTypes;
