import {
  SET_CONSENT,
  SET_OPTIONAL_COOKIES_CONSENT,
  SET_CRITICAL_COOKIES_CONSENT,
  SHOW_COOKIE_POPUP,
} from 'tv-constants/oldRedux';

export const setConsent = value => ({
  type: SET_CONSENT,
  value,
});

export const acceptOptionalCookies = value => ({
  type: SET_OPTIONAL_COOKIES_CONSENT,
  value,
});

export const acceptCookies = value => ({
  type: SET_CRITICAL_COOKIES_CONSENT,
  value,
});

export const showCookiePopUp = value => ({
  type: SHOW_COOKIE_POPUP,
  value,
});
