import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetStream from 'tv-selectors/streams/makeGetStream';

const makeGetStreamSrc = () => {
  const getStream = makeGetStream();
  return createSelector(
    [getStream],
    selectedStream => (selectedStream.hls || selectedStream.dash || {}).src || '',
  );
};

export const useGetStreamSrc = props => useMemoizedGqlSelector(makeGetStreamSrc, props);

export default makeGetStreamSrc;
