import { createResponsiveStateReducer, createResponsiveStoreEnhancer } from 'redux-responsive';
import * as Sentry from '@sentry/react';
import { enableBatching } from 'redux-batched-actions';
import thunkMiddleware from 'redux-thunk';
import MobileDetect from 'mobile-detect';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import znipeBreakpoints from 'znipe-styles/breakpoints';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from 'tv-epics/rootEpic';
import intensiveReducer, {
  initialState as intensiveReducerInitialState,
} from 'tv-reducers/intensive';

import apiMiddleware from 'tv-utils/apiMiddleware';

import competitors from 'tv-reducers/competitors';
import champions from 'tv-reducers/champions';
import eventsReducerV2 from 'tv-reducers/events';
import featuredReducer from 'tv-reducers/featured';
import gamesReducer from 'tv-reducers/games';
import globalStreamsReducer from 'tv-reducers/globalStreams';
import matchesReducer from 'tv-reducers/matches';
import streamsReducerV2 from 'tv-reducers/streams';
import playersReducer from 'tv-reducers/players';
import pageReducerV2 from 'tv-reducers/page';
import playlistsReducer from 'tv-reducers/playlists';
import searchReducer from 'tv-reducers/search';
import teamsReducerV2 from 'tv-reducers/teams';
import tournamentsReducerV2 from 'tv-reducers/tournaments';
import tournamentStagesReducer from 'tv-reducers/tournamentStages';
import contentSubscriptionsReducerV2 from 'tv-reducers/contentSubscriptions';
import videosReducer from 'tv-reducers/videos';
import channelsReducer from 'tv-reducers/channels';
import matchPreviewReducer, {
  initialState as matchPreviewReducerInitialState,
} from 'tv-reducers/matchPreview';
import rosterReducer from 'tv-reducers/roster';
import packagesReducer from 'tv-reducers/packges';

import authReducer, { initialState as authReducerInitialState } from 'tv-reducers/old/auth';
import consentReducer, {
  initialState as consentReducerInitialState,
} from 'tv-reducers/old/consent';
import controlReducer, {
  initialState as controlReducerInitialState,
} from 'tv-reducers/old/control';
import coreUIReducer, { initialState as coreUIReducerInitialState } from 'tv-reducers/old/coreUI';
import localizationReducer, {
  initialState as localizationInitialState,
} from 'tv-reducers/old/localization';
import productReducer, {
  initialState as productReducerInitialState,
} from 'tv-reducers/old/products';
import roomsReducer, { initialState as roomsReducerInitialState } from 'tv-reducers/old/rooms';
import predictionReducer, {
  initialState as predictionsReducerInitialState,
} from 'tv-reducers/old/predictions';
import uiReducer, { initialState as uiReducerInitialState } from 'tv-reducers/old/ui';

const useSentry = process.env.NODE_ENV === 'production' && global.document;

const setupStore = (
  defaultDeviceInfo = { deviceType: 'desktop', referrer: '', hostname: '', userSessionId: '' },
  userAgent = null,
  calculateInitialState = false,
  defaultStates = { initialState: {}, gqlInitialState: {} },
) => {
  const noChangeReducer = (state = {}) => state;

  const { deviceType, referrer, userSessionId, isNative, hostname } = defaultDeviceInfo;

  const defaultBreakpoints = {
    mobilePortraitSmall: znipeBreakpoints.mobilePortraitSmall - 1,
    mobilePortrait: znipeBreakpoints.mobilePortrait - 1,
    mobileLandscape: znipeBreakpoints.mobileLandscape - 1,
    tablet: znipeBreakpoints.tablet - 1,
    laptop: znipeBreakpoints.laptop - 1,
    desktop: znipeBreakpoints.desktop - 1,
    desktopLarge: znipeBreakpoints.desktopLarge - 1,
    desktopExtraLarge: znipeBreakpoints.desktopExtraLarge - 1,
    desktop4K: znipeBreakpoints.desktop4K - 1,
  };
  let initialMediaType = defaultStates?.initialState?.browser?.mediaType;
  if (!initialMediaType) {
    if (deviceType === 'mobile') initialMediaType = 'mobileLandscape';
    else if (deviceType === 'tablet') initialMediaType = 'laptop';
    else initialMediaType = 'desktopLarge';
  }

  const browserReducer = createResponsiveStateReducer(defaultBreakpoints, {
    initialMediaType,
    extraFields: () => ({
      width: global.document ? window.innerWidth : 0,
      height: global.document ? window.innerHeight : 0,
    }),
  });
  const unfilteredDeviceInfo = userAgent
    ? {
        deviceType,
        userAgent,
        referrer,
        isNative,
        hostname,
        userSession: userSessionId,
        isMobile: deviceType === 'mobile',
        isTablet: deviceType === 'tablet',
        isDesktop: deviceType === 'desktop',
        isIOS: new MobileDetect(userAgent).is('iOS') || false,
      }
    : {
        deviceType,
        referrer,
        isNative,
        hostname,
        userSession: userSessionId,
        isMobile: deviceType === 'mobile',
        isTablet: deviceType === 'tablet',
        isDesktop: deviceType === 'desktop',
      };

  // remove undefined values from device info
  const deviceInfo = Object.entries(unfilteredDeviceInfo).reduce((acc, [key, val]) => {
    if (val) acc[key] = val;
    return acc;
  }, {});

  const reducerMap = {};
  const reducers = [];

  const addReducer = (
    name,
    _reducer,
    initialState,
    reducersArr = reducers,
    reducersMap = reducerMap,
  ) => {
    let reducer = _reducer;
    if (typeof name === 'function') {
      reducer = name;
      return reducersArr.push(name);
    }

    if (typeof reducer === 'undefined') {
      return typeof reducersMap[name] === 'undefined'
        ? reducersMap[name]
        : reducersMap[name].reducer;
    }

    const current = reducersMap[name];
    if (typeof current === 'undefined' || reducer === current.reducer) {
      return Object.assign(reducersMap, { [name]: { reducer, initialState } });
    }
    throw new Error('Duplicate registration of same reducer name.');
  };

  addReducer('auth', authReducer, authReducerInitialState);
  addReducer('browser', browserReducer);
  addReducer('consent', consentReducer, consentReducerInitialState);
  addReducer('control', controlReducer, controlReducerInitialState);
  addReducer('coreUI', coreUIReducer, coreUIReducerInitialState);
  addReducer('deviceInfo', noChangeReducer, deviceInfo);
  addReducer('localization', localizationReducer, localizationInitialState);
  addReducer('products', productReducer, productReducerInitialState);
  addReducer('ui', uiReducer, uiReducerInitialState);
  addReducer('rooms', roomsReducer, roomsReducerInitialState);
  addReducer('predictions', predictionReducer, predictionsReducerInitialState);

  const reduxDevtools = '__REDUX_DEVTOOLS_EXTENSION__';

  const enhancer = compose(
    createResponsiveStoreEnhancer({ calculateInitialState }),
    applyMiddleware(apiMiddleware, thunkMiddleware),
    global[reduxDevtools]?.({
      name: 'Store',
    }) ?? compose,
    // Sentry currently only supports 1 redux store. Chose GraphQL Store for now
    // useSentry ? Sentry.createReduxEnhancer({}) : compose,
  );

  const combinedReducerShape = {};
  const initialState = {};
  const names = Object.keys(reducerMap);
  names.forEach(name => {
    const reducerValueMap = reducerMap[name];
    combinedReducerShape[name] = reducerValueMap.reducer;
    initialState[name] = reducerValueMap.initialState;
  });

  const allReducers = () =>
    [names.length ? combineReducers(combinedReducerShape) : state => state].concat(reducers);

  const reducer = enableBatching((currentState, action) => {
    const state = allReducers().reduce((nextState, r) => r(nextState, action), currentState);
    return state;
  });
  const store = createStore(reducer, initialState, enhancer);

  const gqlReducerMap = {};
  addReducer('competitors', competitors, {}, [], gqlReducerMap);
  addReducer('champions', champions, {}, [], gqlReducerMap);
  addReducer('contentSubscriptions', contentSubscriptionsReducerV2, {}, [], gqlReducerMap);
  addReducer('deviceInfo', noChangeReducer, deviceInfo, [], gqlReducerMap);
  addReducer('events', eventsReducerV2, {}, [], gqlReducerMap);
  addReducer('featured', featuredReducer, {}, [], gqlReducerMap);
  addReducer('globalStreams', globalStreamsReducer, {}, [], gqlReducerMap);
  addReducer('games', gamesReducer, {}, [], gqlReducerMap);
  addReducer('globalStreams', globalStreamsReducer, {}, [], gqlReducerMap);
  addReducer('matches', matchesReducer, {}, [], gqlReducerMap);
  addReducer(
    'matchPreviews',
    matchPreviewReducer,
    matchPreviewReducerInitialState,
    [],
    gqlReducerMap,
  );
  addReducer('players', playersReducer, {}, [], gqlReducerMap);
  addReducer('playlists', playlistsReducer, {}, [], gqlReducerMap);
  addReducer('videos', videosReducer, {}, [], gqlReducerMap);
  addReducer('teams', teamsReducerV2, {}, [], gqlReducerMap);
  addReducer('tournamentStages', tournamentStagesReducer, {}, [], gqlReducerMap);
  addReducer('tournaments', tournamentsReducerV2, {}, [], gqlReducerMap);
  addReducer('pages', pageReducerV2, {}, [], gqlReducerMap);
  addReducer('packages', packagesReducer, {}, [], gqlReducerMap);
  addReducer('rosters', rosterReducer, {}, [], gqlReducerMap);
  addReducer('search', searchReducer, {}, [], gqlReducerMap);
  addReducer('streams', streamsReducerV2, {}, [], gqlReducerMap);
  addReducer('channels', channelsReducer, {}, [], gqlReducerMap);

  const gqlReducers = Object.keys(gqlReducerMap)
    .sort()
    .reduce(
      (accReducers = {}, reducerName) => ({
        ...accReducers,
        [reducerName]: gqlReducerMap[reducerName].reducer,
      }),
      {},
    );

  const gqlStore = createStore(
    combineReducers(gqlReducers),
    { ...defaultStates?.gqlInitialState, deviceInfo },
    compose(
      applyMiddleware(thunkMiddleware),
      global[reduxDevtools]?.({
        name: 'GraphQL Store',
      }) ?? compose,
      useSentry ? Sentry.createReduxEnhancer({}) : compose,
    ),
  );

  const epicMiddleware = createEpicMiddleware();
  const intensiveStore = createStore(
    intensiveReducer,
    intensiveReducerInitialState,
    compose(
      applyMiddleware(epicMiddleware),
      global[reduxDevtools]?.({
        name: 'Intensive Store',
      }) ?? compose,
      // Sentry currently only supports 1 redux store. Chose GraphQL Store for now
      // useSentry ? Sentry.createReduxEnhancer({}) : compose,
    ),
  );

  epicMiddleware.run(rootEpic);

  return { store, intensiveStore, gqlStore };
};

export default setupStore;
