import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import gradients from 'znipe-styles/gradients';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import breakpoints from './playerCardBreakpoints';
import { SMALL, MEDIUM, LARGE, XLARGE } from './constants';

const containerCss = {
  [SMALL]: css`
    width: 65px;
    height: 90px;
  `,
  [MEDIUM]: css`
    width: 104px;
    height: 136px;
  `,
  [LARGE]: css`
    width: 144px;
    height: 188px;
  `,
  [XLARGE]: css`
    width: 272px;
    height: 355px;
  `,
};

export const EllipsisTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ containerWidth }) => containerWidth}px;
`;

export const Container = styled.div.attrs(() => ({
  type: 'button',
}))`
  ${buttonDefaultStyle}
  position: relative;
  background: ${({ theme, transparentBackground }) =>
    transparentBackground ? 'transparent' : theme.secondaryBackgroundColor};
  overflow: ${({ canOverflow }) => (canOverflow ? 'visible' : 'hidden')};

  ${({ noMarker, theme, $isLoading }) =>
    !noMarker &&
    !$isLoading &&
    css`
      &:hover,
      &:focus {
        background: linear-gradient(
          180deg,
          ${theme.containerStroke} 0%,
          ${theme.secondaryBackgroundColor} 100%
        );
      }
    `}
  ${({ size }) =>
    size
      ? containerCss[size]
      : css`
          padding-bottom: 135.75%;
        `}
  ${({ roundedCorners }) =>
    roundedCorners &&
    css`
      border-radius: 5px;
    `}
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: ${({ $hasLabel, $markerSize }) => {
    if ($hasLabel) {
      return `${$markerSize}px auto 75%`;
    }
    return `0px min-content`;
  }};
`;

export const PlayerImageGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${gradients.playerImageBackground};
  z-index: 3;
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  margin-top: ${({ $loadingMargin }) => $loadingMargin || 0}px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  z-index: 3;
  ${({ spacing }) => css`
    bottom: ${spacing}px;
    right: ${spacing}px;
  `}
`;

export const Marker = styled.div`
  width: 100%;
  height: ${({ size, containerWidth, noMarker }) => {
    if (noMarker) return '0px';
    return `${size === XLARGE || containerWidth > breakpoints.medium ? 8 : 4}px`;
  }};
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    `}
  background-color:  ${({ isHomeTeam, noMarker, theme, $isLoading }) => {
    if (noMarker || $isLoading || typeof isHomeTeam === 'undefined') return 'transparent';
    return isHomeTeam ? theme.teamOneColor : theme.teamTwoColor;
  }};
`;

export const BackgroundLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  background: ${({ gradient }) => gradient};
`;
