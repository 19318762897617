import CardSimple from 'znipe-elements/data-display/Card/CardSimple';
import PropTypes from 'prop-types';
import CountdownTimerLine from 'znipe-elements/feedback/CountdownTimerLine/CountdownTimerLine';
import { CardContentContainer, TimerContainer, CardContainer } from './PredictionInputCard.styles';
import PredictionInputHandlement from './PredictionInputHandlement/PredictionInputHandlement';
import PredictionCardRightIcon from '../PredictionCardRightIcon/PredictionCardRightIcon';
import { scenarioType } from '../../types';
import { variants } from '../../predictionConstants';

const PredictionInputCard = ({
  scenario,
  size = 'small',
  totalTime = 0,
  remainingTime = 0,
  gameId,
}) => {
  const { label, variant, options } = scenario;

  // we only support 3 options if the variant is not SELECT
  if (variant !== variants.SELECT && Object.keys(options).length > 3) return null;

  return (
    <CardContainer>
      <CardSimple title={label} underlinedHeader={false} headerPadding="16px 16px 0px 16px">
        <CardContentContainer $size={size} $teamsCard={variant === variants.TEAMS}>
          <PredictionInputHandlement
            scenario={scenario}
            options={options}
            gameId={gameId}
            size={size}
          />
          {size === 'large' && <PredictionCardRightIcon size={size} scenario={scenario} />}
        </CardContentContainer>
        <TimerContainer>
          {totalTime && (
            <CountdownTimerLine
              total={totalTime}
              remaining={remainingTime}
              borderRadius="0px 0px 5px 5px"
            />
          )}
        </TimerContainer>
      </CardSimple>
    </CardContainer>
  );
};

PredictionInputCard.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  remainingTime: PropTypes.number,
  totalTime: PropTypes.number,
  gameId: PropTypes.string.isRequired,
  scenario: scenarioType.isRequired,
};

export default PredictionInputCard;
