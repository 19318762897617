export const GROUP_TIMEFRAME = 5;

const getTimeSeriesInfo = (gatId, gameTime) => {
  const groupedGameTime = Math.floor(gameTime / GROUP_TIMEFRAME) * GROUP_TIMEFRAME;
  const id = groupedGameTime <= 0 ? `${gatId}:0` : `${gatId}:${groupedGameTime}`;
  return { id, gameTime: groupedGameTime };
};

export const getGameTimeFromId = id => parseInt(id.split(':')[1], 10);

export default getTimeSeriesInfo;
