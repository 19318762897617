/* eslint-disable no-return-assign */
import { useEffect, useRef, Fragment } from 'react';
import type { ReactNode } from 'react';
import useEmojiAutoComplete from 'znipe-chat/src/hooks/useEmojiAutoComplete';
import { Container, EmojiName, Text, ListItem, ScrollArea } from './EmojiAutoComplete.styles';
import type { Emoji } from '../Emojis.types';
import { tileSizes } from '../EmojiTile/EmojiTile.styles';

const HIGHLIGHT_TEXT = '[HIGHLIGHT_TEXT]';
const SCROLL_MIN_ITEM_COUNT = 4;
const imageSize = tileSizes.small.image;

const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const getHighlightedTypography = (fullText: string, query: string, isBold = false) => {
  const highlightString = query.replace(':', '');
  const escapedHighlightString = escapeRegExp(highlightString.replace(':', ''));
  const regex = new RegExp(escapedHighlightString, 'g');
  const replaced = fullText.replace(regex, `,${HIGHLIGHT_TEXT}`);
  return replaced.split(',').map(word => (
    <Fragment key={word}>
      {word.includes(HIGHLIGHT_TEXT) && (
        <Text $highlighted $bold={isBold === true}>
          {highlightString}
        </Text>
      )}
      <Text $bold={isBold === true}>{word.replace(HIGHLIGHT_TEXT, '')}</Text>
    </Fragment>
  ));
};

type EmojiAutoCompleteProps = {
  query?: string;
  onSelect: (shortcode: string, action: string, trigger: string) => void;
};

const EmojiAutoComplete: React.FC<EmojiAutoCompleteProps> = ({ query = '', onSelect }) => {
  const scrollRef = useRef<(HTMLElement | null)[]>([]);
  const { containerRef, focusedItemIndex, searchResults } = useEmojiAutoComplete(query, onSelect);

  useEffect(() => {
    const scrollTargetIndex =
      focusedItemIndex < SCROLL_MIN_ITEM_COUNT - 1
        ? 0
        : focusedItemIndex - SCROLL_MIN_ITEM_COUNT + 1;
    if (scrollTargetIndex > -1)
      scrollRef.current?.[scrollTargetIndex]?.scrollIntoView({ behavior: 'smooth' });
  }, [focusedItemIndex]);

  if (!searchResults.length) {
    return null;
  }

  return (
    <Container ref={containerRef} $hasScroll={searchResults.length > SCROLL_MIN_ITEM_COUNT}>
      <ScrollArea data-testid="auto-complete-container" $hasScrollFrom={SCROLL_MIN_ITEM_COUNT}>
        {searchResults.map<ReactNode>((emoji: Emoji, index) => {
          const { url, shortcode } = emoji;
          const combined = getHighlightedTypography(`:${shortcode}:`, query);
          const onClick = () => onSelect(shortcode, 'emoji_select', 'auto_complete');
          return (
            <ListItem
              ref={ref => {
                scrollRef.current[index] = ref as HTMLElement;
              }}
              key={shortcode}
              data-testid={`list-item-${shortcode}`}
              $hasFocus={focusedItemIndex === index}
              onClick={onClick}
            >
              <img width={imageSize} height={imageSize} src={url} alt={shortcode} />
              <EmojiName>{combined}</EmojiName>
            </ListItem>
          );
        })}
      </ScrollArea>
    </Container>
  );
};

export default EmojiAutoComplete;
