import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetGameGlobalStreamIds from 'tv-selectors/games/makeGetGameGlobalStreamIds';
import makeGetGlobalStreams from 'tv-selectors/globalStreams/makeGetGlobalStreams';

const makeGetGameGlobalStreams = () => {
  const getGameGlobalStreamIds = makeGetGameGlobalStreamIds();
  const getGlobalStreams = makeGetGlobalStreams();
  return createSelector(
    [getGameGlobalStreamIds, getGlobalStreams],
    (globalStreamIds, globalStreams) => {
      const globalStreamsInfo = [];
      globalStreamIds.forEach(id => {
        if (globalStreams[id]) {
          globalStreamsInfo.push(globalStreams[id]);
        }
      });
      return globalStreamsInfo;
    },
  );
};

export const useGetGameGlobalStreams = props =>
  useMemoizedGqlSelector(makeGetGameGlobalStreams, props);

export default makeGetGameGlobalStreams;
