import deepmerge from 'deepmerge';
import { handleActions } from 'redux-actions';
import {
  SET_CONSENT,
  SET_OPTIONAL_COOKIES_CONSENT,
  SET_CRITICAL_COOKIES_CONSENT,
  SHOW_COOKIE_POPUP,
} from 'tv-constants/oldRedux';

export const initialState = {
  allowOptionalCookies: false,
  cookieAccepted: false,
};

export default handleActions(
  {
    [SET_CONSENT]: (state, { value }) => deepmerge(state, value),

    [SET_OPTIONAL_COOKIES_CONSENT]: (state, { value }) => ({
      ...state,
      allowOptionalCookies: value,
    }),
    [SET_CRITICAL_COOKIES_CONSENT]: (state, { value }) => ({
      ...state,
      cookieAccepted: value,
    }),
    [SHOW_COOKIE_POPUP]: (state, { value }) => ({
      ...state,
      showCookiePopUp: value,
    }),
  },
  initialState,
);
