import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SeekBar from 'tv-modules/Player/Controls/components/SeekBar/SeekBar';
import Icon from 'znipe-elements/general/Icon/Icon';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import throttle from 'lodash.throttle';
import { Container, SeekbarWrapper, PausePlayWrapper, CloseWrapper } from './PingOverlay.styles';

const PingOverlay = ({
  playerRef = playerDefaultProps,
  isVisible = false,
  onClose = null,
  timeInfo = { currentTime: 0, seekRange: { start: 0, end: 1 } },
  pausable = false,
}) => {
  const [playing, setPlaying] = useState(false);
  const [seekbarInfo, setSeekbarInfo] = useState(timeInfo);

  const togglePlayPause = useCallback(() => {
    const player = playerRef?.current;
    if (!player) return;
    if (player.isPaused()) {
      player.play();
      setPlaying(true);
    } else {
      player.pause();
      setPlaying(false);
    }
  }, [playerRef]);

  const updatePlayerTime = useCallback(({ currentTime, seekRange }) => {
    const roundedTime = Math.floor(currentTime);
    setSeekbarInfo({ currentTime: roundedTime, seekRange });
  }, []);

  useEffect(() => {
    const player = playerRef?.current;
    if (!player) return () => {};
    setPlaying(!player.isPaused());
    const onPlay = () => setPlaying(true);
    const onPause = () => setPlaying(false);
    const handleTimeUpdate = throttle(updatePlayerTime, 1000);
    player.addEventListener('play', onPlay);
    player.addEventListener('playing', onPlay);
    player.addEventListener('pause', onPause);
    player.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      player.removeEventListener('play', onPlay);
      player.removeEventListener('playing', onPlay);
      player.removeEventListener('pause', onPause);
      player.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [playerRef, updatePlayerTime]);

  return (
    <Container data-testid="ping-overlay" $isVisible={isVisible}>
      {isVisible && (
        <CloseWrapper data-testid="ping-overlay-close-icon" onClick={onClose}>
          <Icon type="close" />
        </CloseWrapper>
      )}
      <SeekbarWrapper>
        <SeekBar playerRef={playerRef} timeInfo={seekbarInfo} />
      </SeekbarWrapper>
      {pausable && isVisible && (
        <PausePlayWrapper data-testid="toggle-play-container" onClick={togglePlayPause}>
          {playing ? <Icon type="pause" /> : <Icon type="play" />}
        </PausePlayWrapper>
      )}
    </Container>
  );
};

PingOverlay.propTypes = {
  playerRef: playerPropTypes,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  timeInfo: PropTypes.shape({
    currentTime: PropTypes.number,
    seekRange: PropTypes.shape({}),
  }),
  pausable: PropTypes.bool,
};

export default PingOverlay;
