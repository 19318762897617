import Typography from 'znipe-elements/general/Typography/Typography';
import withTheme from 'znipe-themes/hocs/withTheme';
import { Container, ButtonContainer, Dot } from './ToggleButton.styles';
import theme from './ToggleButton.theme';

export type Size = 'small' | 'medium' | 'large';

type ToggleButtonProps = {
  size?: Size;
  isActive?: boolean;
  label?: string;
  onClick?: () => void;
  color?: 'blue' | 'default';
  row?: boolean;
  disabled?: boolean;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  size = 'small',
  isActive = false,
  label = '',
  onClick,
  color = 'default',
  row = false,
  disabled = false,
}) => (
  <Container $row={row}>
    {label && <Typography type={size === 'small' ? 'title-xs' : 'title-m'}>{label}</Typography>}
    <ButtonContainer
      data-testid="toggle-container"
      $size={size}
      $isActive={isActive}
      $color={color}
      $disabled={disabled || !onClick}
      onClick={disabled ? undefined : onClick}
    >
      <Dot $size={size} $color={color} $isActive={isActive} />
    </ButtonContainer>
  </Container>
);

export default withTheme(ToggleButton, theme, 'toggleButton');
