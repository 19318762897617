import { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type FloatingProps = { $isAbove: boolean; $top: number };

export const FloatingContainer = styled.div<FloatingProps>`
  position: absolute;
  top: ${({ $top }) => `${$top}px`};
  left: 0;
  right: 0;
  transform: ${({ $isAbove }) => ($isAbove ? 'translateY(-100%)' : 'translateY(0)')};
`;

const FloatingDropdownMenu: React.FC<{
  children: (isAbove: boolean) => ReactNode;
  anticipatedHeight: number;
}> = ({ children, anticipatedHeight }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isAbove, setAbove] = useState(false);

  const makeSureItFitsIntoTheView = useCallback(() => {
    if (!containerRef.current) return;

    const { top, height } = containerRef.current.getBoundingClientRect();

    if (anticipatedHeight < top) {
      setAbove(true);
    } else if (top + height > window.innerHeight) {
      setAbove(top - height >= 0);
    } else {
      setAbove(false);
    }
  }, [anticipatedHeight]);

  useLayoutEffect(() => {
    makeSureItFitsIntoTheView();
    window.addEventListener('resize', makeSureItFitsIntoTheView);
    window.addEventListener('scroll', makeSureItFitsIntoTheView);
    return () => {
      window.removeEventListener('resize', makeSureItFitsIntoTheView);
      window.removeEventListener('scroll', makeSureItFitsIntoTheView);
    };
  }, [makeSureItFitsIntoTheView]);

  if (!children) return null;

  return <div ref={containerRef}>{children(isAbove)}</div>;
};

export default FloatingDropdownMenu;
