import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';

const getPlayerIdFromProps = (_, props) => props.playerId ?? '';

const makeGetPlayerGameTitle = () => {
  const getPlayers = makeGetPlayers();
  return createSelector([getPlayers, getPlayerIdFromProps], (allPlayers, playerId) => {
    if (!allPlayers || !playerId) return '';
    return allPlayers[playerId]?.game ?? '';
  });
};

export const useGetPlayerGameTitle = props => useMemoizedGqlSelector(makeGetPlayerGameTitle, props);

export default makeGetPlayerGameTitle;
