import { hints, HOME_WELCOME_STEP_ID, MY_FEED_TARGET_ID } from 'tv-routes/Home/Home.constants';

const theme = {
  default: {
    [HOME_WELCOME_STEP_ID]: {
      hints,
      headline: 'Welcome',
      subHeadline: 'Get to know the platform before diving in.',
      buttonLabel: 'Let’s go!',
      backgroundImage: 'https://assets.znipe.tv/onboarding/csgo/Bottom-image.png',
    },
    [MY_FEED_TARGET_ID]: {
      id: MY_FEED_TARGET_ID,
      coverImage: 'https://assets.znipe.tv/onboarding/csgo/Onboarding-banner-home-medium.png',
      title: 'Your custom homepage',
      text: 'Follow content from you favourite players and teams.',
      minSessions: 2,
    },
    myFeedOnboarding: {
      text: 'Start following your favourite players and teams to see all their latest matches and highlights in one place.',
      headline: 'Your custom homepage',
    },
    useOverlayTitles: false,
  },
  proview: {
    [MY_FEED_TARGET_ID]: {
      id: MY_FEED_TARGET_ID,
      coverImage: 'https://assets.znipe.tv/onboarding/lol/my-feed-header.png',
      title: 'Your personalised homepage',
      text: 'Follow content from you favourite players, teams, and champions.',
      minSessions: 2,
    },
    [HOME_WELCOME_STEP_ID]: {
      hints,
      headline: 'Welcome, summoner',
      subHeadline: 'Get to know Pro View before diving in.',
      buttonLabel: 'Enter Pro View',
      backgroundImage: 'https://assets.znipe.tv/onboarding/lol/proview-home-welcome-background.png',
    },
    myFeedOnboarding: {
      text: 'Create a custom feed with your favourite players, teams or champions. Once you follow someone, their content will show up here!',
      headline: 'Follow your faves',
    },
    useOverlayTitles: true,
  },
};

export default theme;
