import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

export const searchInputWidth = {
  desktop: 336,
  desktopLarge: 424,
};

export const OuterWrapper = styled.div`
  width: 100%;
  ${({ theme }) =>
    theme?.masthead?.border &&
    css`
      border-bottom: 1px solid ${theme.containerStroke};
    `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  width: 100%;
  padding: 16px 8px;
  @media ${device.mobilePortrait} {
    padding: 16px;
  }
  @media ${device.tablet} {
    min-height: 72px;
  }
  @media ${device.desktop} {
    padding: 0 24px;
    min-height: 64px;
  }
  @media ${device.desktopExtraLarge} {
    max-width: 1900px;
    margin: auto;
  }

  & > *:nth-child(2) {
    margin-left: auto;

    @media ${device.desktop} {
      margin-left: initial;
    }
  }
`;

export const SearchContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 56px;
  background: ${({ $isActive, theme }) =>
    $isActive ? theme.tertiaryBackgroundColor : theme.mastheadGradient};
  @media ${device.tablet} {
    height: 72px;
  }
`;

export const SearchIconWrapper = styled.div.attrs({
  type: 'button',
})`
  height: 24px;
  margin-left: auto;
  cursor: pointer;
  right: 8px;
  @media ${device.mobilePortrait} {
    right: 16px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
`;

export const NavigationWrapper = styled.div`
  position: relative;
  width: 100%;
  @media ${device.desktop} {
    flex-basis: calc(100% - ${searchInputWidth.desktop}px);
  }
  @media ${device.desktopLarge} {
    flex-basis: calc(100% - ${searchInputWidth.desktopLarge}px);
  }
  padding: 0 24px;
`;

export const DesktopContentWrapper = styled.div`
  width: 100%;
  @media ${device.desktopExtraLarge} {
    max-width: 1680px;
    position: relative;
  }
`;

export const themes = {
  proview: {
    backgroundColor: colors.black,
    border: true,
    menuType: 'menu',
  },
  default: {
    border: true,
    menuType: 'avatar',
  },
  'svenska-cupen': {
    border: true,
    menuType: 'avatar',
  },
  efg: {
    backgroundColor: colors.black,
    border: true,
    menuType: 'avatar',
  },
  faceit: {
    backgroundColor: '#181818',
    border: true,
    menuType: 'avatar',
  },
};
