import produce from 'immer';
import { SET_CHAMPIONS } from 'tv-constants/redux';
import getLastItem from 'znipe-utils/misc/getLastItem';

export const initialState = {};

const flattenPassives = object =>
  Object.keys(object).reduce((result = {}, item) => {
    const currentItem = object[item];
    result[item] = Array.isArray(currentItem) ? getLastItem(currentItem) : currentItem;
    return result;
  }, {});

const passivesAndSpells = ['passive', 'e', 'q', 'r', 'w'];

// List of keys to not flatten
// and keep as their original array
const doNotFlatten = [
  'otherOfSameClass',
  'topPlayers',
  'highlights',
  'latestMatches',
  'scheduledMatches',
];

const championsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_CHAMPIONS: {
      Object.entries(payload).forEach(([key, value]) => {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (!state[key]) state[key] = {};
          if (passivesAndSpells.includes(subKey)) {
            state[key][subKey] = flattenPassives(subValue);
          } else if (doNotFlatten.includes(subKey)) {
            state[key][subKey] = subValue;
          } else if (subKey === 'class') {
            state[key][subKey] = subValue?.[0];
          } else if (Array.isArray(subValue)) {
            state[key][subKey] = getLastItem(subValue);
          } else {
            state[key][subKey] = subValue;
          }
        }, {});
      });
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default championsReducer;
