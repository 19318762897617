import styled, { css } from 'styled-components';
import prefers from 'znipe-styles/prefers';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $collapsable }) => ($collapsable ? 'flex-start' : 'space-between')};
  padding: 0 16px;
  width: 100%;
  height: ${({ $compact }) => ($compact ? 56 : 72)}px;
  overflow: hidden;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
`;

export const marginLeft = css`
  margin-left: ${({ $compact }) => ($compact ? 8 : 16)}px;
`;

export const TextWrapper = styled.div`
  &:not(:first-child) {
    ${marginLeft};
  }
  > *:nth-child(2) {
    ${marginLeft};
    opacity: ${({ $active }) => ($active ? 1 : 0)};
    @media ${prefers.motion} {
      transition: opacity ${({ $animationDuration }) => $animationDuration}s linear;
    }
  }
`;
