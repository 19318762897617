import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Wrapper = styled.div`
  padding: 8px 18px;
  background: linear-gradient(
    188.66deg,
    ${({ theme }) => theme.promoCodeBanner.bannerStartColor} 4.47%,
    ${({ theme }) => theme.promoCodeBanner.bannerEndColor} 67.14%
  );
  opacity: 0.8;
  border-radius: 5px 5px 0 0;
  width: 100%;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 17px;
  line-height: 22px;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  color: ${({ theme }) => theme.promoCodeBanner.textColor};
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const OfferValue = styled.span`
  font-weight: 700;
`;

export const Comma = styled.span`
  @media ${device.tablet} {
    &::after {
      content: ',';
    }
  }
`;
