import { createSelector } from 'reselect';
import makeGetState from 'tv-selectors/makeGetState';

const emptyObject = {};
const makeGetUIMap = () => {
  const getState = makeGetState();
  return createSelector([getState], state => state.ui || emptyObject);
};

export default makeGetUIMap;
