import AnalyticsManager, {
  AnalyticsManagerType,
  getTimestamp,
  AnalyticsManagerInstanceType,
} from './src/AnalyticsManager';
import type {
  PlaybackEvent,
  PlaybackFields,
  SharedPlaybackFields,
} from './src/types/videoPlayback';

export default AnalyticsManager;
export { getTimestamp };
export type {
  AnalyticsManagerType,
  AnalyticsManagerInstanceType,
  PlaybackEvent,
  PlaybackFields,
  SharedPlaybackFields,
};
