import { useMemo } from 'react';
import useIntensiveStore from 'tv-hooks/useIntensiveStore';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import createPauseMarker from '../utils/createPauseMarker';

const emptyObject = {};
const emptyArray = [];

const getPauseIds = ({ gats }, { gatId }) => gats[gatId]?.pauses ?? emptyArray;
const getOffset = ({ gats }, { gatId }) => gats[gatId]?.offset ?? 0;
const getSetupDuration = ({ gats }, { gatId }) => gats[gatId]?.matchSetupDuration ?? 0;

const usePauses = (gatId, timeStart, timeEnd) => {
  const store = useIntensiveStore();
  const intensiveState = store.getState();
  const pauses = useIntensiveSelector(state => getPauseIds(state, { gatId }));
  const setupDuration = useIntensiveSelector(state => getSetupDuration(state, { gatId }));
  const offset = useIntensiveSelector(state => getOffset(state, { gatId }));

  const selectedPauses = useMemo(() => {
    const copy = [...pauses]; // This is needed because we can't modify the redux entry
    const firstPause = intensiveState.pauses[pauses[0]];
    if (firstPause && firstPause.startTime < 3) copy.shift();
    return copy;
  }, [intensiveState.pauses, pauses]);

  const pauseMarkers = useMemo(() => {
    let totalPauseTime = 0;
    return selectedPauses.map(id => {
      const finalOffset = setupDuration + offset + totalPauseTime;
      const marker = createPauseMarker(timeStart, timeEnd, finalOffset, id);
      const pause = intensiveState.pauses[id] ?? emptyObject;
      if (pause.duration) totalPauseTime += pause.duration;
      return marker;
    });
  }, [selectedPauses, setupDuration, offset, timeStart, timeEnd, intensiveState.pauses]);
  return pauseMarkers;
};

export default usePauses;
