import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';
import gradients from 'znipe-styles/gradients';

export const Card = styled.div`
  display: flex;
  border-radius: 5px;
  color: ${colors.white};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  flex-direction: ${({ $size }) => ($size === 'small' ? 'column' : 'row')};
  width: 100%;
  > div:first-of-type {
    display: flex;
    align-self: ${({ $size }) => ($size === 'small' ? 'auto' : 'center')};
  }
`;

export const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background: ${gradients.bg3Transparency};
  color: ${({ theme }) => theme.secondaryTextColor};
  p {
    margin-bottom: 0;
  }
  padding: 16px;
  @media ${device.tablet} {
    padding: 44px 16px;
  }
  @media ${device.desktopLarge} {
    padding: 52px 24px;
  }
`;
