import { handleActions } from 'redux-actions';
import { FETCH_LOCALIZATION_STRINGS_SUCCESS } from 'tv-constants/oldRedux';

export const initialState = {
  strings: {},
  locale: '',
};

export default handleActions(
  {
    [FETCH_LOCALIZATION_STRINGS_SUCCESS]: (_state, action) => {
      const { response = {}, locale = '' } = action;
      const { results: strings = {} } = response;
      return {
        strings,
        locale,
      };
    },
  },
  initialState,
);
