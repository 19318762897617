import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import PlayerCard from 'tv-elements/data-display/PlayerCard/PlayerCard';
import LogoCard from 'tv-elements/data-display/LogoCard/LogoCard';
import HighlightClip from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip';
import ChampionCard from 'tv-elements/data-display/ChampionCard/ChampionCard';
import TopicLink from 'tv-elements/data-display/TopicLink/TopicLink';
import VODItem from 'tv-modules/VODListing/VODItem/VODItem';

export const ITEM_TYPE_PLAYER = 'ITEM_TYPE_PLAYER';
export const ITEM_TYPE_LOGO_CARD = 'ITEM_TYPE_LOGO_CARD';
export const ITEM_TYPE_VIDEO = 'ITEM_TYPE_VIDEO';
export const ITEM_TYPE_CHAMPION = 'ITEM_TYPE_CHAMPION';
export const ITEM_TYPE_MATCH = 'ITEM_TYPE_MATCH';
export const ITEM_TYPE_TOPIC_LINK = 'ITEM_TYPE_TOPIC_LINK';

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';
export const SIZES = [SMALL, MEDIUM, LARGE, XLARGE];

export const heightOptions = {
  [SMALL]: 14,
  [MEDIUM]: 16,
  [LARGE]: 20,
  [XLARGE]: 24,
};

const ItemsWrapper = styled.div`
  ${({ $layout }) =>
    $layout === 'list' &&
    css`
      display: flex;
      flex-direction: column;
    `}

  ${({ $layout, $columnsPerRow }) =>
    $layout === 'grid' &&
    css`
      display: grid;
      grid-template-columns: repeat(${$columnsPerRow}, 1fr);
      grid-gap: 10px;
    `}
`;

const SectionSkeleton = ({
  size = 'large',
  layout = 'grid',
  itemType = ITEM_TYPE_PLAYER,
  numberOfItems = 12,
  columnsPerRow,
}) => {
  const items = useMemo(
    () =>
      [...Array(numberOfItems).keys()].map(key => {
        if (itemType === ITEM_TYPE_PLAYER)
          return <PlayerCard key={key} playerImage="" size={size} />;
        if (itemType === ITEM_TYPE_LOGO_CARD)
          return <LogoCard key={key} text="" imageSrc="" size={size} />;
        if (itemType === ITEM_TYPE_VIDEO)
          return <HighlightClip key={key} highlightId="" size={size} />;
        if (itemType === ITEM_TYPE_CHAMPION)
          return <ChampionCard key={key} championId="" size={size} />;
        if (itemType === ITEM_TYPE_MATCH) return <VODItem key={key} matchId="" size={size} />;
        if (itemType === ITEM_TYPE_TOPIC_LINK) return <TopicLink key={key} size={size} />;
        return null;
      }),
    [numberOfItems, size, itemType],
  );
  if (columnsPerRow)
    return (
      <ItemsWrapper $layout={layout} $columnsPerRow={columnsPerRow}>
        {items}
      </ItemsWrapper>
    );
  return items;
};

SectionSkeleton.propTypes = {
  layout: PropTypes.oneOf(['grid', 'list']),
  itemType: PropTypes.oneOf([ITEM_TYPE_PLAYER, ITEM_TYPE_LOGO_CARD, ITEM_TYPE_VIDEO]),
  size: PropTypes.string,
  columnsPerRow: PropTypes.number,
  numberOfItems: PropTypes.number,
};

export default SectionSkeleton;
