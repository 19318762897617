import { createSelector, createSelectorCreator } from 'reselect';
import { shallowEqual } from 'react-redux';
import strictMemoize from 'tv-utils/strictMemoize';

const createStrictStateAndListSelector = createSelectorCreator(
  strictMemoize,
  () => true,
  shallowEqual,
);
const createStrictStateConstantAndListSelector = createSelectorCreator(
  strictMemoize,
  () => true,
  shallowEqual,
  (a, b) => a === b,
);

const createStrictSelectorPlayers = createSelectorCreator(
  strictMemoize,
  () => true,
  shallowEqual,
  (a, b) => a === b,
  (a, b) => a === b,
);

const emptyObject = {};
const emptyArray = [];

const getCurrentTimeSeriesEntry = ({ gats }, { gatId }) => gats?.[gatId]?.currentTimeSeriesEntry;
const getGat = ({ gats }, { gatId }) => gats?.[gatId] ?? emptyObject;
const getPlayer = ({ players }, { playerId }) => players?.[playerId] ?? emptyObject;
const getPlayerId = (_, { playerId }) => playerId;
const getPlayers = ({ players }) => players ?? emptyObject;
const getStats = ({ stats }) => stats ?? emptyObject;
const getTeamId = ({ players }, { playerId }) => players?.[playerId]?.teamId;
const getTimeSeriesEntries = ({ timeSeriesEntries }) => timeSeriesEntries ?? emptyObject;

const makeGetPlayerStats = () =>
  createStrictStateAndListSelector(
    [getTimeSeriesEntries, getCurrentTimeSeriesEntry],
    (timeSeriesEntries, currentTimeSeriesEntry) =>
      timeSeriesEntries[currentTimeSeriesEntry]?.playerStats ?? emptyArray,
  );

const makeGetStatId = (getIds, getId, idType) =>
  createStrictStateConstantAndListSelector([getStats, getIds, getId], (stats, statIds, id) =>
    statIds.find(statId => stats[statId]?.[idType] === id),
  );

export const makeGetSelectedPlayerStats = () => {
  const getPlayerStats = makeGetPlayerStats();
  const getStatId = makeGetStatId(getPlayerStats, getPlayerId, 'player');
  return createSelector([getStats, getStatId], (stats, statId) => stats[statId] ?? emptyObject);
};

const makeGetPlayerIds = () => createSelector([getGat], gat => gat.players ?? emptyArray);
export const makeGetPlayerRole = () => createSelector([getPlayer], player => player.role ?? '');
const makeGetOpponentId = () => {
  const getPlayerIds = makeGetPlayerIds();
  const getPlayerRole = makeGetPlayerRole();
  return createStrictSelectorPlayers(
    [getPlayers, getPlayerIds, getPlayerId, getPlayerRole],
    (players, ids, playerId, role) =>
      ids.length > 0 ? ids.find(id => id !== playerId && players[id]?.role === role) : emptyArray,
  );
};

export const makeGetSelectedOpponentStats = () => {
  const getOpponentId = makeGetOpponentId();
  const getPlayerStats = makeGetPlayerStats();
  const getStatId = makeGetStatId(getPlayerStats, getOpponentId, 'player');
  return createSelector([getStats, getStatId], (stats, statId) => stats[statId] ?? emptyObject);
};

const makeGetTeamStats = () =>
  createStrictStateAndListSelector(
    [getTimeSeriesEntries, getCurrentTimeSeriesEntry],
    (timeSeriesEntries, currentTimeSeriesEntry) =>
      timeSeriesEntries[currentTimeSeriesEntry]?.teamStats ?? emptyArray,
  );

export const makeGetSelectedTeamStats = () => {
  const getTeamStats = makeGetTeamStats();
  const getStatId = makeGetStatId(getTeamStats, getTeamId, 'team');
  return createSelector([getStats, getStatId], (stats, statId) => stats[statId] ?? emptyObject);
};
