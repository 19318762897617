import styled from 'styled-components';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import { device } from 'znipe-styles/breakpoints';

export const IndicatorsWrapper = styled.div`
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${Scrollbar};
`;

export const StatsWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 380px;

  @media ${device.laptop} {
    max-width: 656px;
  }
`;
