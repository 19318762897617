import { memo } from 'react';
import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import themes from './CancelSuccessOrError.themes';

import { Container, Headline, TextContent, ImageWrapper } from './CancelSuccessOrError.styles';

export const successHeader = 'Subscription cancelled';
export const successText = "We're sorry to see you go, and hope to see you again soon!";
export const errorHeader = 'Error';
export const errorText =
  'Sorry, there was a technical error processing your request. Please contact support or try again later.';

const CancelSuccessOrError = ({
  buttonText = 'BACK TO MY PRO VIEW',
  onClick = () => {},
  isSuccess = true,
}) => {
  const {
    cancelSuccessOrError: { successImage, errorImage } = {},
  } = useThemeContext();
  const header = isSuccess ? successHeader : errorHeader;
  const text = isSuccess ? successText : errorText;
  const Image = isSuccess ? successImage : errorImage;
  const id = isSuccess ? 'cancel-confirmed' : 'cancel-error';
  return (
    <Container data-testid={`${id}-container`}>
      <Headline data-testid={`${id}-header`}>{header}</Headline>
      <TextContent data-testid={`${id}-text`}>{text}</TextContent>
      <ImageWrapper data-testid="cancel-confirmed-image">
        <Image />
      </ImageWrapper>
      <Button onClick={onClick} data-testid={`${id}-button`} variant="solid-color" size="small">
        {buttonText}
      </Button>
    </Container>
  );
};

CancelSuccessOrError.propTypes = {
  buttonText: PropTypes.string,
  isSuccess: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withTheme(memo(CancelSuccessOrError), themes, 'cancelSuccessOrError');
