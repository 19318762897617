import { memo } from 'react';
import PropTypes from 'prop-types';
import { useGetAuthSubscriptionByProductId } from 'tv-selectors/auth/makeGetAuthSubscriptionByProductId';
import withTheme from 'znipe-themes/hocs/withTheme';
import getDayDifference from 'znipe-utils/date/getDayDifference';
import withSuffix from 'znipe-utils/date/withSuffix';
import themes from './PromoCodeBanner.themes';
import { Wrapper, Text, OfferValue, Comma } from './PromoCodeBanner.styles';

const PromoCodeBanner = ({ productId, hasTrial = false, isFreeForever = false }) => {
  const subscriptionData = useGetAuthSubscriptionByProductId({
    productId,
  });

  const today = Date.now();
  const promoCode = subscriptionData.campaignCodes || [];
  const lastCode = promoCode[promoCode.length - 1] ?? {};
  const promoEnd = lastCode.campaignCodeEnds;
  const promoName = lastCode.code;
  const offerValue = lastCode.discount * 100;
  const daysRemaining = getDayDifference(today, promoEnd);
  const monthsRemaining = Math.floor(daysRemaining / 30.417);
  const timeRemaining = daysRemaining > 30 ? monthsRemaining : daysRemaining;
  const daysOrMonths = daysRemaining > 30 ? 'month' : 'day';

  const promoRemainingString = withSuffix(timeRemaining, daysOrMonths);

  return (
    <Wrapper data-testid="promocode-banner">
      <Text data-testid="promo-text" fullWidth={hasTrial}>
        {hasTrial && !isFreeForever && (
          <>
            Your promo code <OfferValue>{promoName}</OfferValue> will become active after the trial
            period ends
          </>
        )}
        {!hasTrial && !isFreeForever && (
          <>
            Active promo code: <OfferValue>{offerValue}% off</OfferValue>
            {promoRemainingString && (
              <>
                <Comma /> {promoRemainingString} remaining
              </>
            )}
          </>
        )}
        {/* biome-ignore lint/complexity/noUselessFragments: I believe this is needed to be detected as a node */}
        {isFreeForever && <>You have a free account.</>}
      </Text>
    </Wrapper>
  );
};

PromoCodeBanner.propTypes = {
  productId: PropTypes.string.isRequired,
  hasTrial: PropTypes.bool,
  isFreeForever: PropTypes.bool,
};

export default withTheme(memo(PromoCodeBanner), themes, 'promoCodeBanner');
