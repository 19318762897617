import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, Button, ContentWrapper, IconWrapper } from './HorizontalNavButton.styles';

const HorizontalNavButton = ({
  id = '',
  size = 'medium',
  iconType,
  label = '',
  onClick,
  isToggledOn = false,
  testid = '',
}) => (
  <Container id={id} $size={size} data-testid={`nav-button-${testid}`}>
    <Button
      onClick={onClick}
      $isToggledOn={isToggledOn}
      data-testid={`nav-button-${iconType}`}
      aria-label={label}
      $showingLabel={label}
    >
      <ContentWrapper>
        <IconWrapper $size={size}>
          <Icon type={iconType} fillColor="currentColor" />
        </IconWrapper>
        {label && (
          <Typography dataTestId="label" type={size === 'large' ? 'title-s' : 'title-xs'}>
            {label}
          </Typography>
        )}
      </ContentWrapper>
    </Button>
  </Container>
);

HorizontalNavButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isToggledOn: PropTypes.bool,
  testid: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
};

export default HorizontalNavButton;
