import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';
import convertHexToHSL from 'znipe-utils/misc/convertHexToHSL';

type UsernameProps = {
  $isModerator?: boolean;
  $color?: string;
  $clickable?: boolean;
};

export const Username = styled(Typography)<UsernameProps>`
  display: inline;
  color: ${({ $color = colors.grey53 }) => $color};
  font-weight: 700;
  white-space: nowrap;
  ${({ $clickable = false }) =>
    $clickable &&
    css<UsernameProps>`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: ${({ $color = colors.grey53 }) => convertHexToHSL($color, { l: 8, addMode: true })};
      }
    `}
`;

type LabelProps = {
  $isModerator?: boolean;
};

/* translateY to align message and label */
export const Label = styled.div<LabelProps>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
  transform: translateY(1px);
  ${({ $isModerator = false, theme }) =>
    $isModerator &&
    css`
      padding: 0 4px;
      border-radius: 2px;
      background-color: ${theme.containerStroke};
    `}
`;
