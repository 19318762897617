import { keyframes } from 'styled-components';

export const fadeIn = (from = 0, to = 1) => keyframes`
  from {
    opacity: ${from};
  }

  to {
    opacity: ${to};
  }
`;

export const fadeOut = (from = 1, to = 0) => keyframes`
  from {
  opacity: ${from};
  }

  to {
    opacity: ${to};
  }
`;

export const fadeInFadeOut = () => keyframes`
  0%,100% { opacity: 0 }
  30%, 80% { opacity: 1 }
`;

export const slideInHorizontal = (from = '-100%', to = 0) => keyframes`
  from {
    transform: translateX(${from});
  }

  to {
    transform: translateX(${to});
  }
`;

export const slideOutHorizontal = (from = 0, to = '-100%') => keyframes`
  from {
    transform: translateX(${from});
  }

  to {
    transform: translateX(${to});
  }
`;

export const slideInVertical = (from = '-100%', to = 0) => keyframes`
  from {
    transform: translateY(${from});
  }

  to {
    transform: translateY(${to});
  }
`;

export const slideOutVertical = (from = 0, to = '-100%') => keyframes`
  from {
    transform: translateY(${from});
  }

  to {
    transform: translateY(${to});
  }
`;

export const slideInFromRight = (from = '100%', to = 0) => keyframes`
  from {
    transform: translateX(${from});
    opacity: 0;
  }

  to {
    transform: translateX(${to});
    opacity: 1;
  }
`;

export const slideOutToRight = (from = 0, to = '100%') => keyframes`
  from {
    transform: translateX(${from});
    opacity: 1;
  }

  to {
    transform: translateX(${to});
    opacity: 0;
  }
`;

export const growHeight = (from = '0', to = '10px') => keyframes`
  from {
    height: ${from};
  }

  to {
    height: ${to}
  }
`;

export const fadeInBackgroundAlpha = (from = 0, to = 1) => keyframes`
  0% {
    background-color: rgba(0, 0, 0, ${from});
  }
  100% {
    background-color: rgba(0, 0, 0, ${to});
  }
`;
