import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import TeamLogo from 'znipe-elements/data-display/TeamLogo/TeamLogo';
import Score from 'znipe-elements/data-display/Score/Score';
import SectionIndicators from 'znipe-elements/data-display/SectionIndicators/SectionIndicators';
import VersusIcon from 'znipe-elements/data-display/VersusIcon/VersusIcon';
import EnergyExplodeAnimation from 'tv-elements/animation/EnergyExplodeAnimation/EnergyExplodeAnimation';
import useTheme from 'tv-hooks/useTheme';
import {
  Container,
  StyledScore,
  IconWrapper,
  TeamSectionWrapper,
  IndicatorsWrapper,
  MiddleSection,
  GifWrapper,
} from './TeamStandings.styles';

const TeamStandings = ({
  teamOneLogo,
  teamOneName = '',
  teamOneScore = 0,
  teamTwoScore = 0,
  teamTwoLogo,
  teamTwoName = '',
  bestOf = 0,
  teamOneGameWins = 0,
  teamTwoGameWins = 0,
  matchMissingScore = false,
  hideScore = false,
  iconType = '',
  isLive = false,
  size = 'small',
  explodeAnimationWidth = null,
  swapPlacement = false,
}) => {
  const { themeName } = useTheme();

  const iconElement = useMemo(() => {
    if (iconType)
      return (
        <IconWrapper size={size}>
          <Icon type={iconType} />
        </IconWrapper>
      );
    return <VersusIcon size={size} hideScore={hideScore} />;
  }, [hideScore, iconType, size]);

  const numberOfAvailableIndicators = Math.ceil(bestOf / 2);

  const isSvg = useCallback(src => /\.svg$/.test(src), []);
  return (
    <Container data-testid="team-standings">
      {isLive && (
        <GifWrapper>
          <EnergyExplodeAnimation explodeAnimationWidth={explodeAnimationWidth} />
        </GifWrapper>
      )}
      <TeamSectionWrapper size={size}>
        <TeamLogo
          logo={teamOneLogo}
          logoPlacement={swapPlacement ? 'right' : 'left'}
          label={teamOneName}
          useLazyLoading={!isSvg(teamOneLogo)}
          size={size}
          currentTheme={themeName}
        />
        {!matchMissingScore && (
          <IndicatorsWrapper data-testid="team-one-score-indicators">
            <SectionIndicators
              numberOfItems={numberOfAvailableIndicators}
              activeSection={teamOneGameWins}
              progressStyle
              size={size}
            />
          </IndicatorsWrapper>
        )}
      </TeamSectionWrapper>
      <MiddleSection size={size} showingMatchScoreIndicators={!matchMissingScore}>
        {!hideScore && (
          <StyledScore size={size}>
            <Score teamOrder={1} value={teamOneScore} />
          </StyledScore>
        )}

        {iconElement}

        {!hideScore && (
          <StyledScore size={size}>
            <Score teamOrder={2} value={teamTwoScore} />
          </StyledScore>
        )}
      </MiddleSection>
      <TeamSectionWrapper size={size} isReversed>
        <TeamLogo
          logo={teamTwoLogo}
          logoPlacement={swapPlacement ? 'left' : 'right'}
          label={teamTwoName}
          useLazyLoading={!isSvg(teamTwoLogo)}
          size={size}
          currentTheme={themeName}
        />
        {!matchMissingScore && (
          <IndicatorsWrapper data-testid="team-two-score-indicators" isReversed>
            <SectionIndicators
              numberOfItems={numberOfAvailableIndicators}
              activeSection={teamTwoGameWins}
              progressStyle
              size={size}
            />
          </IndicatorsWrapper>
        )}
      </TeamSectionWrapper>
    </Container>
  );
};

TeamStandings.propTypes = {
  teamOneLogo: PropTypes.string.isRequired,
  teamTwoLogo: PropTypes.string.isRequired,
  teamOneName: PropTypes.string,
  teamTwoName: PropTypes.string,
  teamOneScore: PropTypes.number,
  teamTwoScore: PropTypes.number,
  bestOf: PropTypes.number,
  teamOneGameWins: PropTypes.number,
  teamTwoGameWins: PropTypes.number,
  iconType: PropTypes.string,
  matchMissingScore: PropTypes.bool,
  hideScore: PropTypes.bool,
  isLive: PropTypes.bool,
  swapPlacement: PropTypes.bool,
  explodeAnimationWidth: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
};

export default TeamStandings;
