import { useMemo, useImperativeHandle, useRef, useCallback } from 'react';
import usePlayerEvents from 'znipe-player/src/hooks/usePlayerEvents';
import getPlayerFunctions from 'znipe-player/src/utils/getPlayerFunctions';
import ZnipeEventTarget from 'znipe-utils/events/ZnipeEventTarget';
import { useFeatureFlag } from 'znipe-link/link';

// Videos should be an array of video objects including references to shaka players
const useRefWithPlayerFunctionsAndEvents = (videos = [], ref, initialState = {}) => {
  const eventTarget = useMemo(() => new ZnipeEventTarget(), []);
  const localState = useRef({ ...initialState });

  usePlayerEvents(eventTarget, videos);

  const setState = useCallback((key, value) => {
    localState.current[key] = value;
  }, []);
  const qualityAdjustorStrategy = useFeatureFlag('quality-adjustor-strategy');

  const attachFunctionsToRef = () => {
    if (!eventTarget) return;
    const functions = getPlayerFunctions(videos, setState, { qualityAdjustorStrategy });
    Object.keys(functions).forEach(funcName => {
      eventTarget[funcName] = functions[funcName];
    });
  };

  attachFunctionsToRef();

  useImperativeHandle(ref, () => {
    attachFunctionsToRef();
    return eventTarget;
  });

  return useMemo(() => [eventTarget, localState, setState], [eventTarget, setState]);
};

export default useRefWithPlayerFunctionsAndEvents;
