import { useMemo } from 'react';
import useAccountInfo from './useAccountInfo';
import useUserCustomization from './useUserCustomization';
import usePackageCustomization from './usePackageCustomization';

export type Color = {
  hexCode: string;
  displayName: string;
  locked: boolean;
};

const useNameColors = (authManagerId?: string) => {
  const { isModerator = false } = useAccountInfo(authManagerId) ?? {};
  const { chatColorSets: unlockedColorSets } = useUserCustomization(authManagerId);

  const { chatColorSets } = usePackageCustomization();

  const allColorSets = useMemo(
    () =>
      chatColorSets
        ?.sort((a, b) => a.priority - b.priority)
        ?.map(({ colors, isUnlockable, humanReadableId }) =>
          colors.map(color => ({ ...color, isUnlockable, humanReadableId })),
        )
        ?.flat() ?? [],
    [chatColorSets],
  );

  const nameColors = useMemo(() => {
    const colorSetsToRender = isModerator
      ? allColorSets
      : allColorSets?.filter(({ isUnlockable }) => isUnlockable);
    return (
      colorSetsToRender
        ?.map(({ hexCode, displayName, humanReadableId }) => ({
          hexCode,
          displayName,
          locked: !unlockedColorSets?.includes(humanReadableId),
        }))
        ?.sort((a, b) => Number(a.locked) - Number(b.locked)) ?? []
    );
  }, [allColorSets, isModerator, unlockedColorSets]);

  return nameColors as Color[];
};

export default useNameColors;
