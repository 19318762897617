/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageLayout from 'tv-elements/layout/PageLayout/PageLayout';
import routes from './routes';
import { ScrollToTopOnMount } from './utils';

const handleUpdate = (callback, ...args) => {
  if (window && window.ga) {
    ga('set', 'page', global.location.pathname); // eslint-disable-line no-undef
    ga('set', 'title', document.title); // eslint-disable-line no-undef
    ga('send', 'pageview'); // eslint-disable-line no-undef
  }
  if (window && window.gtag) {
    // eslint-disable-next-line no-undef
    gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-8663141/provi0/provi0+unique',
    });
  }
  if (callback) callback(...args);
};

const routesList = routes.map(
  ({ componentDidMount, scrollToTopOnMount = false, element, redirect, ...args }) =>
    redirect ? (
      <Route key={args.path} {...args} element={redirect} replace />
    ) : (
      <Route
        {...args}
        key={args.path}
        element={
          <PageLayout {...args}>
            {scrollToTopOnMount && <ScrollToTopOnMount />}
            {element}
          </PageLayout>
        }
      />
    ),
);

const usePageViews = () => {
  const location = useLocation();
  // biome-ignore lint/correctness/useExhaustiveDependencies: Send event on location change
  useEffect(() => {
    handleUpdate();
  }, [location]);
};

const App = () => {
  usePageViews();
  return <Routes>{routesList}</Routes>;
};

export default App;
