import colors from 'znipe-styles/colors';
import styled, { css } from 'styled-components';
import Modal, { BaseModalBackground } from './StyledModal/StyledModal';

export const themes = {
  default: {
    color: colors.black,
    darkModeColor: colors.white,
    backgroundColor: colors.white,
    dakModeBackgroundColor: colors.black,
    fadingBackgroundColor: undefined,
    borderRadius: '0px',
  },
  esportal: {
    color: colors.black,
    darkModeColor: colors.grey65,
    backgroundColor: colors.white,
    dakModeBackgroundColor: colors.esportalBackgroundTertiary,
    fadingBackgroundColor: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '3px',
  },
};

export const StyledModal = Modal.styled`
  min-width: 200px;
  min-height: 100px;
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${props =>
    props.darkMode ? props.theme.modal.dakModeBackgroundColor : props.theme.modal.backgroundColor};
  color: ${props => (props.darkMode ? props.theme.modal.dakModeColor : props.theme.modal.color)};
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
  position: relative;
  border-radius: ${props => props.theme.modal.borderRadius}
`;

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
  background-color: ${props => props.theme.modal.fadingBackgroundColor};

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 10px 0 10px;
  ${props =>
    props.floating &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 0;
  border: none;
  background: transparent;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
