import { useMemo } from 'react';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import TransitionMarker from '../TransitionMarker/TransitionMarker';
import TransitionMarkerText from '../TransitionMarker/TransitionMarkerText';

const getOffset = ({ gats }, { gatId }) => gats[gatId]?.offset ?? 0;
const getSetupDuration = ({ gats }, { gatId }) => gats[gatId]?.matchSetupDuration ?? 0;

const markerPosition = (time = 0, timeStart = 0, timeEnd = 0) =>
  parseFloat((((time - timeStart) * 100) / (timeEnd - timeStart)).toFixed(2));

const useSeekBarMarkers = (gatId, timeStart, timeEnd) => {
  const setupDuration = useIntensiveSelector(state => getSetupDuration(state, { gatId }));
  const offset = useIntensiveSelector(state => getOffset(state, { gatId }));

  const pickBanStart = Math.max(offset, 0);

  const startPosition = useMemo(
    () => markerPosition(timeStart, timeStart, timeEnd),
    [timeStart, timeEnd],
  );
  const pickBanPosition = useMemo(
    () => markerPosition(pickBanStart, timeStart, timeEnd),
    [timeStart, pickBanStart, timeEnd],
  );
  const gameStartPosition = useMemo(
    () => markerPosition(pickBanStart + setupDuration, timeStart, timeEnd),
    [pickBanStart, setupDuration, timeEnd, timeStart],
  );
  const showPreshowText = useMemo(() => pickBanPosition > 10, [pickBanPosition]);
  const showPickAnBanText = useMemo(
    () => setupDuration / (timeEnd - timeStart) > 0.06,
    [setupDuration, timeStart, timeEnd],
  );
  const showGameStartText = useMemo(() => gameStartPosition < 92, [gameStartPosition]);
  const markers = useMemo(
    () => ({
      markerDots: [
        showPreshowText ? (
          <TransitionMarker
            key={`startMarker-${startPosition}`}
            data-testid="position-marker"
            position={startPosition}
          />
        ) : null,
        <TransitionMarker
          key={`pickBanMarker-${pickBanPosition}`}
          data-testid="position-marker"
          position={pickBanPosition}
        />,
        <TransitionMarker
          key={`gameStartMarker-${gameStartPosition}`}
          data-testid="position-marker"
          position={gameStartPosition}
        />,
      ],
      markerTexts: [
        showPreshowText ? (
          <TransitionMarkerText
            key={`startText-${startPosition}`}
            data-testid="text-marker"
            position={startPosition}
            text="Preshow"
          />
        ) : null,
        showPickAnBanText ? (
          <TransitionMarkerText
            key={`pickBanText-${pickBanPosition}`}
            data-testid="text-marker"
            position={pickBanPosition}
            text="Pick & Ban"
          />
        ) : null,
        showGameStartText ? (
          <TransitionMarkerText
            key={`gameStartText-${gameStartPosition}`}
            data-testid="text-marker"
            position={gameStartPosition}
            text="Game Start"
          />
        ) : null,
      ],
    }),
    [
      gameStartPosition,
      pickBanPosition,
      showGameStartText,
      showPickAnBanText,
      showPreshowText,
      startPosition,
    ],
  );

  if (offset === undefined || offset === null || !gatId) return { markerDots: [], markerTexts: [] };

  return markers;
};

export default useSeekBarMarkers;
