import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from './makeGetMatch';

const makeGetMatchTournamentId = () => {
  const getMatch = makeGetMatch();
  return createSelector([getMatch], match => match?.tournamentId || '');
};

export const useGetMatchTournamentId = props =>
  useMemoizedGqlSelector(makeGetMatchTournamentId, props);

export default makeGetMatchTournamentId;
