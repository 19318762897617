import { useSpring } from '@react-spring/web';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CSGOWeaponIcon, {
  weaponIdentifiers,
} from 'znipe-elements/data-display/WeaponIcon/CSGOWeaponIcon';
import { CenterWrapper, EliminatedText, Weapon, sizes } from '../EliminatedInfo.styles';
import { sizesShape } from '../../../constants';

const Center = ({ size, isHomeTeam, show, weapon, onAnimationEnd }) => {
  const [rests, setRests] = useState(0);

  useEffect(() => {
    if (!show) setRests(0);
  }, [show]);

  useEffect(() => {
    if (rests === 2) {
      onAnimationEnd();
      setRests(0);
    }
  }, [rests, onAnimationEnd]);

  const onRest = useCallback(() => {
    if (show) setRests(prev => prev + 1);
  }, [show]);

  const textSpring = useSpring({
    y: show ? 0 : -100,
    skew: '-20deg',
    immediate: !show,
    onRest,
  });
  const weaponSpring = useSpring({
    y: show ? 0 : 100,
    immediate: !show,
    onRest,
  });

  return (
    <CenterWrapper>
      <EliminatedText
        style={textSpring}
        $size={size}
        $isHomeTeam={isHomeTeam}
        data-testid="eliminated-text"
      >
        Eliminated
      </EliminatedText>
      <Weapon style={weaponSpring} data-testid="weapon" $size={sizes[size].centerItems}>
        <CSGOWeaponIcon weapon={weapon} />
      </Weapon>
    </CenterWrapper>
  );
};

Center.propTypes = {
  show: PropTypes.bool.isRequired,
  isHomeTeam: PropTypes.bool.isRequired,
  weapon: PropTypes.oneOf(weaponIdentifiers),
  size: sizesShape.isRequired,
  onAnimationEnd: PropTypes.func.isRequired,
};

export default Center;
