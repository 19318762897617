import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import Icon from 'znipe-elements/general/Icon/Icon';
import Avatar from 'znipe-elements/data-display/Avatar/v2/Avatar';
import ChampionAvatar from 'znipe-elements/data-display/Avatar/v2/ChampionAvatar/ChampionAvatar';
import PlayerAvatar from 'znipe-elements/data-display/Avatar/v2/PlayerAvatar/PlayerAvatar';
import PlayerCard from 'tv-elements/data-display/PlayerCard/PlayerCard';
import AvatarInfo from 'znipe-elements/data-display/AvatarInfo/AvatarInfo';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, IconWrapper } from './TopicLink.styles';
import TopicLinkLoading from './TopicLink.loading';

import {
  SMALL,
  MEDIUM,
  LARGE,
  TEAM,
  PLAYER,
  PLAYER_CARD,
  CHAMPION,
  ICON_AVATAR,
  TEXT,
  DEFAULT,
} from './TopicLink.constants';

const TopicLink = ({
  avatarIcon = '',
  size = SMALL,
  title = '',
  subtitle = '',
  tagLabel = '',
  image = '',
  secondaryImage = '',
  type = DEFAULT,
  hideChevronIcon = false,
  isSelected = false,
  isActive = false,
  isEmpty = false,
  noBackground = false,
  roundedCorner = true,
  onClick,
  onClickIcon = () => {},
}) => {
  const { hasLoaded } = useOnImageLoad(image);

  const selectedIconType = useMemo(() => {
    if (isEmpty) return '';
    if (isSelected) return 'circleCheck';
    if (isActive) return 'close';
    return hideChevronIcon ? '' : 'chevronRight';
  }, [isSelected, isEmpty, isActive, hideChevronIcon]);

  const elementSizes = useMemo(() => {
    if (size === MEDIUM)
      return {
        avatarInfoSize: 'medium',
      };
    return {
      avatarInfoSize: 'xsmall',
    };
  }, [size]);

  const { avatarInfoSize } = elementSizes;

  const showFullComponent = useLoadingDelay(hasLoaded);

  if (type !== ICON_AVATAR && !showFullComponent) return <TopicLinkLoading size={size} />;

  return (
    <Container
      $size={size}
      $type={type}
      $isEmpty={isEmpty}
      $isActive={isActive}
      $noBackground={noBackground}
      $roundedCorner={roundedCorner}
      $isClickable={onClick}
      onClick={onClick}
      data-testid="topic-link"
    >
      {type === TEXT ? (
        <Typography type="heading-s">{title}</Typography>
      ) : (
        <AvatarInfo
          size={avatarInfoSize}
          title={title}
          icon={avatarIcon}
          subtitle={subtitle}
          tagLabel={tagLabel}
        >
          {(type === TEAM || type === DEFAULT) && <Avatar imageUrl={image} />}
          {type === CHAMPION && <ChampionAvatar shape="circle" imageUrl={image} hasBorder />}
          {type === PLAYER && <PlayerAvatar imageUrl={image} />}
          {type === ICON_AVATAR && <Avatar icon={image} />}
          {type === PLAYER_CARD && (
            <PlayerCard
              noMarker
              skipAnimation
              transparentBackground
              backgroundAlignment="left"
              teamLogo={secondaryImage}
              playerImage={image}
            />
          )}
        </AvatarInfo>
      )}

      {selectedIconType && (
        <IconWrapper data-testid="selected-icon" onClick={onClickIcon}>
          <Icon size={24} type={selectedIconType} />
        </IconWrapper>
      )}
    </Container>
  );
};

TopicLink.propTypes = {
  type: PropTypes.oneOf([TEAM, PLAYER, CHAMPION, ICON_AVATAR, PLAYER_CARD, TEXT, DEFAULT]),
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tagLabel: PropTypes.string,
  image: PropTypes.string,
  secondaryImage: PropTypes.string,
  avatarIcon: PropTypes.string,
  hideChevronIcon: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  noBackground: PropTypes.bool,
  roundedCorner: PropTypes.bool,
  onClick: PropTypes.func,
  onClickIcon: PropTypes.func,
};

export {
  SMALL,
  MEDIUM,
  LARGE,
  TEAM,
  PLAYER,
  PLAYER_CARD,
  CHAMPION,
  ICON_AVATAR,
  TEXT,
  DEFAULT,
} from './TopicLink.constants';

export default memo(TopicLink);
