import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSubscribedTeams from 'tv-selectors/team/makeGetSubscribedTeams';
import makeGetSubscribedPlayers from 'tv-selectors/players/makeGetSubscribedPlayers';
import makeGetSubscribedEvents from 'tv-selectors/events/makeGetSubscribedEvents';
import makeGetSubscribedChampions from 'tv-selectors/champions/makeGetSubscribedChampions';

const makeGetAllContentSubscriptions = () => {
  const getSubscribedTeams = makeGetSubscribedTeams();
  const getSubscribedPlayers = makeGetSubscribedPlayers();
  const getSubscribedEvents = makeGetSubscribedEvents();
  const getSubscribedChampions = makeGetSubscribedChampions();

  return createSelector(
    [getSubscribedTeams, getSubscribedPlayers, getSubscribedEvents, getSubscribedChampions],
    (allTeams, allPlayers, allEvents, allChampions) => [
      ...allTeams,
      ...allPlayers,
      ...allEvents,
      ...allChampions,
    ],
  );
};

export const useGetAllContentSubscriptions = () =>
  useMemoizedGqlSelector(makeGetAllContentSubscriptions);

export default makeGetAllContentSubscriptions;
