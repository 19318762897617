import {
  useEffect,
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  isValidElement,
} from 'react';
import type ReactDOM from 'react-dom/client';
import { warn } from 'znipe-logger';
import { useFeatureFlag } from 'znipe-link';
import AnalyticsManager from 'znipe-analytics-manager';
import TeamLogo from 'znipe-elements/data-display/TeamLogo/TeamLogo';
import Slide from 'znipe-elements/layout/Animation/Slide/Slide';
import Overlay from 'znipe-elements/layout/Overlay/Overlay';
import ReactDomOverlay from 'znipe-elements/layout/Overlay/ReactDomOverlay';
import useColorThief from 'znipe-hooks/useColorThief';
import useThemeContext from 'znipe-hooks/useThemeContext';
import useInitialLoadCallback from 'znipe-chat/src/hooks/useInitialLoadCallback';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import useListenForMessages from 'znipe-chat/src/hooks/useListenForMessages';
import { DEFAULT_RESPONSIVE_BREAKPOINTS } from 'znipe-chat/src/constants';
import useActionCallback from '../../hooks/useActionCallback';
import ChatLockup, { Direction } from '../ChatLockup/ChatLockup';
import Feed from '../Feed/Feed';
import Identity from '../Identity/Identity';
import Gifs from '../Gifs/Gifs';
import ChatButton from '../Buttons/ChatButton/ChatButton';
import Allegiance from '../Identity/Allegiance/Allegiance';
import MessageOptions from '../Entry/MessageOptions/MessageOptions';
import Alert from '../Alert/Alert';
import LoginCTA from '../LoginCTA/LoginCTA';
import { Container, Background, OverlayWrapper, FeedWrapper, DisabledOverlay } from './Chat.styles';
import {
  OVERLAY_IDENTITY,
  OVERLAY_GIFS,
  OVERLAY_MESSGAE_OPTIONS,
  OVERLAY_ALLEGIANCE,
} from './Chat.constants';
import { TRANSITION_DURATION } from '../Emojis/EmojiQuickSelect/EmojiQuickSelect.styles';
import { getSelectedContent } from '../ChatLockup/utils/DOMNode';

const emptyFunction = () => null;

const gradientOptions = {
  type: 'radial',
  direction: 'ellipse at top right',
  startPos: '0%',
  endPos: '25%',
};

export type BreakpointConfig = { medium: number };

const requiredFields = ['username', 'userId', 'messageId'];

type DataField = (typeof requiredFields)[number] | 'color';

type RequestData = {
  [key: DataField]: string;
};

const validateRequiredFields = (fieldsToCheck: DataField[] = [], data: RequestData) => {
  if (!fieldsToCheck.length) return true;
  const fields = fieldsToCheck.filter(field => !data[field]);

  if (fields.length) {
    warn(
      `You must provide ${fields.slice(0, fields.length - 1).join(', ')}${
        fields.length > 1 ? ' & ' : ''
      }${fields[fields.length - 1]} in the mention call`,
    );

    return false;
  }

  return true;
};

type ChatProps = {
  compact?: boolean;
  showAllegiance?: boolean;
  resetOnContextChange?: boolean;
  responsiveBreakpoints?: BreakpointConfig;
  autofocusTextInput: boolean;
  characterLimit?: number;
};

type RequiredData = {
  username: string;
  userId: string;
  messageId: string;
};

type MentionData = RequiredData & {
  color: string;
};

type ReportData = RequiredData;
type BanUserData = RequiredData;
type RemoveMessageData = RequiredData;
type AllowUserData = RequiredData;

export type ChatRef = {
  mention: (data: MentionData) => void;
  report: (data: ReportData) => void;
  banUser?: (data: BanUserData) => void;
  removeMessage?: (data: RemoveMessageData) => void;
  allowUser?: (data: AllowUserData) => void;
  setOverlay: (overlay: React.ReactNode, reactDOM: typeof ReactDOM) => void;
  removeOverlay: () => void;
};

const Chat = forwardRef<ChatRef, ChatProps>(
  (
    {
      compact = false,
      showAllegiance = false,
      resetOnContextChange = false,
      autofocusTextInput,
      responsiveBreakpoints = DEFAULT_RESPONSIVE_BREAKPOINTS,
      characterLimit,
    },
    ref,
  ) => {
    const chatContext = useChatContext();
    const {
      chatId,
      authToken,
      chatStatus,
      accountInfo = { uid: undefined, isModerator: false },
      modalInfo: chatModalInfo,
      setModalInfo: setChatModalInfo,
      alert,
      setAlert,
      userAvatar,
      avatars,
      setUserAvatar,
      analyticsManager,
      setReplyData,
    } = chatContext || {};
    const { uid, isModerator } = accountInfo;
    const [initialMessagesLoaded, setInitialMessagesLoaded] = useState(false);
    const loadChatCallback = useInitialLoadCallback();
    const actionCallback = useActionCallback();
    const toggleIdentity = useCallback(
      (trigger: string) => {
        const action = chatModalInfo?.modal === OVERLAY_IDENTITY ? 'close' : 'open';
        if (trigger && analyticsManager) {
          analyticsManager.trackUIEvent({
            action: `chat_identity_${action}`,
            trigger,
          });
        }
        setChatModalInfo(action === 'close' ? {} : { modal: OVERLAY_IDENTITY });
      },
      [setChatModalInfo, chatModalInfo?.modal, analyticsManager],
    );
    const [isEmojiOpen, setIsEmojiOpen] = useState(false);
    const [overlay, setOverlay] = useState<React.ReactNode | null>(null);
    // const showGifs = useCallback(() => setChatModal(OVERLAY_GIFS), [setChatModal]);

    useListenForMessages();

    const zaHost = useFeatureFlag('za-host');
    useEffect(() => {
      if (zaHost) AnalyticsManager.setEndpoint(`${zaHost}/v4/za/events`);
    }, [zaHost]);

    useImperativeHandle(ref, () => {
      const reportBanRemoveCommonAction = async (data: RequiredData, type: DataField) => {
        if (!validateRequiredFields(requiredFields, data)) {
          return;
        }

        const actionData = { ...data, channel: chatId };
        await actionCallback(type, actionData);
      };

      const handlers: ChatRef = {
        mention: (data: MentionData) => {
          if (!validateRequiredFields([...requiredFields, 'color'], data)) {
            return;
          }

          setReplyData({ ...data, serverMessageId: data.messageId });
        },
        report: (data: ReportData) => reportBanRemoveCommonAction(data, 'report'),
        setOverlay: (overlayToAdd: React.ReactNode, reactDOM?: typeof ReactDOM) => {
          if (!overlayToAdd) return;
          if (isValidElement(overlayToAdd)) {
            if (!reactDOM) throw Error('ReactDOM is required when passing a ReactElement');
            setOverlay(<ReactDomOverlay ReactDom={reactDOM} component={overlayToAdd} />);
            return;
          }
          setOverlay(overlayToAdd);
        },
        removeOverlay: () => setOverlay(null),
      };

      if (isModerator) {
        handlers.banUser = data => reportBanRemoveCommonAction(data, 'ban');
        handlers.removeMessage = data => reportBanRemoveCommonAction(data, 'remove');
        handlers.allowUser = async data => {
          if (!validateRequiredFields(['userId', 'messageId'], data)) {
            return;
          }

          await actionCallback('allow', data);
        };
      }

      return handlers;
    });

    useEffect(() => {
      // if chat provider is missing throw an error
      if (!chatContext) {
        throw new Error(
          'The chat module is missing context provider, please wrap ChatProvider around the module',
        );
      }
    }, [chatContext]);

    useEffect(() => {
      // if not allegiance is selected dispatch allegiance modal
      if (!uid || userAvatar != null) return;
      if (showAllegiance && avatars?.length) {
        setChatModalInfo({ modal: OVERLAY_ALLEGIANCE });
        return;
      }
      if (!showAllegiance) {
        setUserAvatar('');
      }
    }, [userAvatar, showAllegiance, avatars, uid, setUserAvatar, setChatModalInfo]);

    const IdentityTarget = useMemo(
      () => (
        <ChatButton onClick={() => toggleIdentity('button_identity')} size={24} icon="palette" />
      ),
      [toggleIdentity],
    );

    useEffect(() => {
      // if not allegiance is selected dispatch allegiance modal
      if (!uid || userAvatar != null) return;
      if (showAllegiance && avatars?.length) {
        setChatModalInfo({ modal: OVERLAY_ALLEGIANCE });
        return;
      }
      if (!showAllegiance) {
        setUserAvatar('');
      }
    }, [userAvatar, showAllegiance, avatars, uid, setUserAvatar, setChatModalInfo]);

    const [emojisButtonDisabled, setEmojisButtonDisabled] = useState(false);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Toggle the animation on emoji open state change
    useEffect(() => {
      setEmojisButtonDisabled(prev => !prev);
      setTimeout(() => setEmojisButtonDisabled(prev => !prev), TRANSITION_DURATION);
    }, [isEmojiOpen]);

    const EmojiTarget = useMemo(() => {
      const styles = `
        transition: transform 0.2s ease;
        transform: rotate(${isEmojiOpen ? '30' : '0'}deg);
    `;
      const onClick = () => {
        const action = isEmojiOpen ? 'close' : 'open';
        if (analyticsManager) {
          analyticsManager.trackUIEvent({
            action: `chat_emoji_quick_select_${action}`,
            trigger: 'button_emoji_quick_select',
          });
        }
        setIsEmojiOpen(!isEmojiOpen);
      };
      return (
        <ChatButton
          onClick={onClick}
          icon={isEmojiOpen ? 'laughingIconEyesClosed' : 'laughingIconEyesOpen'}
          isActive={isEmojiOpen}
          size={24}
          additionalStyles={styles}
          disabled={emojisButtonDisabled}
        />
      );
    }, [isEmojiOpen, emojisButtonDisabled, analyticsManager]);
    /*
  const GifTarget = useMemo(() => <IconButton onClick={showGifs} icon="gifIcon" $size={24} />, [
    showGifs,
  ]);
  */

    const theme = useThemeContext();
    const { modal: chatModal, props: chatModalProps = {} } = chatModalInfo ?? {};
    const backgroundGradient = useColorThief(
      userAvatar,
      theme.secondaryBackgroundColor,
      gradientOptions,
    );

    useEffect(() => {
      if (!chatId || !authToken) return;
      if (!resetOnContextChange && initialMessagesLoaded) return;
      loadChatCallback().finally(() =>
        resetOnContextChange ? null : setInitialMessagesLoaded(true),
      );
    }, [chatId, authToken, initialMessagesLoaded, resetOnContextChange, loadChatCallback]);

    const containerRef = useRef<HTMLDivElement>(null);
    const [chatLockupDirection, setChatLockupDirection] = useState<Direction>('vertical');

    const handleCopy = useCallback((event: ClipboardEvent) => {
      const selectedContent = getSelectedContent(containerRef);
      if (selectedContent?.length) {
        void navigator.clipboard.writeText(selectedContent.join(''));
        event.preventDefault();
      }
    }, []);

    useEffect(() => {
      const currentNode = containerRef.current;
      currentNode?.addEventListener('copy', handleCopy);

      return () => {
        currentNode?.removeEventListener('copy', handleCopy);
      };
    }, [handleCopy]);

    useEffect(() => {
      const element = containerRef.current;
      if (!element) return emptyFunction;
      const { medium: mediumBreakpoint } = responsiveBreakpoints;
      const resizeObserver = new ResizeObserver(() =>
        setChatLockupDirection(element.clientWidth >= mediumBreakpoint ? 'horizontal' : 'vertical'),
      );
      resizeObserver.observe(element);
      return () => resizeObserver.disconnect();
    }, [responsiveBreakpoints]);

    return (
      <Container ref={containerRef}>
        <Overlay overlay={overlay} topMost>
          {showAllegiance && (
            <Background $gradient={backgroundGradient}>
              <TeamLogo logo={userAvatar} useLazyLoading={false} size="xlarge" />
            </Background>
          )}
          <FeedWrapper>
            <Feed isMobile={compact} />
            {chatStatus?.disabled && <DisabledOverlay />}
            {uid ? (
              <ChatLockup
                placeholder="Cheer and banter..."
                autofocusTextInput={autofocusTextInput}
                direction={chatLockupDirection}
                isEmojiOpen={isEmojiOpen}
                characterLimit={characterLimit}
              >
                {/* !isMobile ? <Gifs type="compact" size="small" targetElement={GifTarget} /> : GifTarget */}
                {!compact ? (
                  <Identity type="compact" size="small" targetElement={IdentityTarget} />
                ) : (
                  IdentityTarget
                )}
                {EmojiTarget}
              </ChatLockup>
            ) : (
              <LoginCTA />
            )}
          </FeedWrapper>

          {compact && chatModal === OVERLAY_GIFS && (
            <OverlayWrapper>
              <Gifs type="regular" size="small" />
            </OverlayWrapper>
          )}

          {compact && chatModal === OVERLAY_IDENTITY && (
            <OverlayWrapper>
              <Slide type="bottom" height="auto" position="relative" show>
                <Identity type="regular" size="small" onClose={toggleIdentity} />
              </Slide>
            </OverlayWrapper>
          )}

          {chatModal === OVERLAY_ALLEGIANCE && (
            <OverlayWrapper $hideContent>
              <Allegiance />
            </OverlayWrapper>
          )}

          {Boolean(compact && chatModal === OVERLAY_MESSGAE_OPTIONS) && (
            <MessageOptions
              onClose={() => setChatModalInfo({})}
              messageId={chatModalProps?.messageId}
            />
          )}

          {alert && (
            <OverlayWrapper $transparent $hideContent>
              <Alert
                action={alert.action}
                description={alert.description}
                onClose={() => setAlert(null)}
                onResolve={alert.onResolve}
                resolveText={alert.resolveText}
              />
            </OverlayWrapper>
          )}
        </Overlay>
      </Container>
    );
  },
);

export default Chat;
