import { createSelector } from 'reselect';
import makeGetVideoId from 'tv-selectors/video/makeGetVideoId';
import makeGetAllVideos from 'tv-selectors/video/makeGetAllVideos';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};

const makeGetVideo = () => {
  const getVideoId = makeGetVideoId();
  const getVideos = makeGetAllVideos();
  return createSelector(
    [getVideoId, getVideos],
    (videoId, videos) => videos?.[videoId] ?? emptyObject,
  );
};

export const useGetVideo = props => useMemoizedGqlSelector(makeGetVideo, props);

export default makeGetVideo;
