import { memo, useMemo } from 'react';
import Helmet from 'react-helmet';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import { DefaultTheme } from 'styled-components';
import typographyThemes from '../Typography.themes';

type FontsProps = {
  providedThemeName?: keyof typeof typographyThemes;
};

type TypographyTheme = {
  typography?: { fonts: { preloads: string[]; fontFaces: string[] } };
};
const Fonts: React.FC<FontsProps> = ({ providedThemeName }) => {
  const fonts = (useThemeContext() as DefaultTheme & TypographyTheme).typography?.fonts;

  const { preloads = [], fontFaces = [] } = useMemo(() => {
    const selectedFonts = providedThemeName ? typographyThemes[providedThemeName].fonts : undefined;
    return selectedFonts ?? fonts ?? typographyThemes.default.fonts;
  }, [fonts, providedThemeName]);

  return (
    <Helmet>
      {preloads.map(link => (
        <link key={link} rel="preload" href={link} as="font" crossOrigin="" />
      ))}
      {fontFaces.map(link => (
        <link key={link} href={link} rel="stylesheet" />
      ))}
    </Helmet>
  );
};

export default withTheme(memo(Fonts), typographyThemes, 'typography');
