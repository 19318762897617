import { memo, forwardRef, useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import { OuterContainer, FinalWrapper } from './Bracket.styles';
import EmptyColumn from './EmptyColumn/EmptyColumn';
import BracketUpper from './BracketUpper/BracketUpper';
import BracketLower from './BracketLower/BracketLower';
import BracketFinal from './BracketFinal/BracketFinal';
import BracketLoading from './Bracket.loading';
import { getColumnsData, getUpperBracketWithEmptyColumn } from './utils/handleData';
import {
  UPPER_BRACKET,
  LOWER_BRACKET,
  GRAND_FINAL,
  rawTeamShape,
  titleHeightDesktop,
  titleHeightMobile,
  finalTitleHeightAndPadding,
} from './utils/constants';

const emptyObject = {};
const Bracket = forwardRef(({ tournamentStageId = '', teams = [], selectedTeams = [] }, ref) => {
  const { type, schedules } = useGqlSelector(
    state => state.tournamentStages[tournamentStageId] ?? emptyObject,
  );
  const upperBracketRef = useRef(null);
  const lowerBracketRef = useRef(null);
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const titleHeight = useMemo(
    () => (isDesktopOrGreater ? titleHeightDesktop : titleHeightMobile),
    [isDesktopOrGreater],
  );
  const finalHeightAdjustment = useMemo(
    () =>
      isDesktopOrGreater
        ? finalTitleHeightAndPadding + titleHeight / 2
        : finalTitleHeightAndPadding,
    [isDesktopOrGreater, titleHeight],
  );
  const [finalBracketHeight, setFinalBracketHeight] = useState(null);
  const [finalArmMarginBottom, setFinalArmMarginBottom] = useState(null);

  useEffect(() => {
    if (!upperBracketRef.current || !lowerBracketRef.current) {
      return;
    }
    const upperHeight = upperBracketRef.current.offsetHeight;
    const lowerHeight = lowerBracketRef.current.offsetHeight;
    setFinalBracketHeight(upperHeight / 2 + lowerHeight / 2 + finalHeightAdjustment);
    setFinalArmMarginBottom(lowerHeight / 2 - titleHeight / 2);
  }, [finalHeightAdjustment, titleHeight]);

  if (!teams?.length || !tournamentStageId || !schedules?.length) {
    return <NoResults type="knockouts" />;
  }

  const lowerBracketColumns = getColumnsData(schedules, LOWER_BRACKET, teams);
  const upperBracketColumns = getUpperBracketWithEmptyColumn(
    schedules,
    getColumnsData(schedules, UPPER_BRACKET, teams),
  );
  const finalColumns = getColumnsData(schedules, GRAND_FINAL, teams);
  const hasFinalColumn = finalColumns.length !== 0 && finalColumns[0].matchups.length !== 0;

  if (!upperBracketColumns) {
    return <BracketLoading />;
  }
  return (
    <OuterContainer ref={ref}>
      <div>
        <BracketUpper
          columns={upperBracketColumns}
          type={type}
          ref={upperBracketRef}
          selectedTeams={selectedTeams}
        />
        {lowerBracketColumns.length > 0 && (
          <BracketLower
            columns={lowerBracketColumns}
            type={type}
            ref={lowerBracketRef}
            selectedTeams={selectedTeams}
          />
        )}
      </div>
      {hasFinalColumn && (
        <FinalWrapper armMarginBottom={`${finalArmMarginBottom}px`}>
          <EmptyColumn
            numberOfMatchupsPrev={2}
            numberOfColumns={1}
            compact
            height={`${finalBracketHeight}px`}
          />
          <BracketFinal
            column={finalColumns[0]}
            type={type}
            height={`${finalBracketHeight + finalArmMarginBottom}px`}
            paddingTop={`${finalBracketHeight / 2 - 97}px`}
          />
        </FinalWrapper>
      )}
    </OuterContainer>
  );
});

Bracket.propTypes = {
  tournamentStageId: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.shape(rawTeamShape)),
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default memo(Bracket);
