import { useState, useEffect } from 'react';
import { map, distinctUntilChanged } from 'rxjs/operators';
import useChatContext from './useChatContext';

const useMessage = (id?: string) => {
  const { chatStore } = useChatContext();
  const [message, setMessage] = useState(id ? chatStore?.getState().messages[id] : undefined);

  useEffect(() => {
    const message$ = chatStore?.subject.pipe(
      map(({ messages }) => (id ? messages[id] : undefined)),
      distinctUntilChanged(),
    );

    const subscription = message$?.subscribe(setMessage);

    return () => subscription?.unsubscribe();
  }, [id, chatStore]);

  return message;
};

export default useMessage;
