import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from './makeGetMatch';

const makeGetMatchTournament = () => {
  const getMatch = makeGetMatch();
  return createSelector([getMatch], match => match?.tournament || '');
};

export const useGetMatchTournament = props => useMemoizedGqlSelector(makeGetMatchTournament, props);

export default makeGetMatchTournament;
