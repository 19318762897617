import { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useSpring, animated, config } from '@react-spring/web';
import {
  Button,
  IconWrapper,
  Wrapper,
  DescriptionWrapper,
  LabelWrapper,
  ImageWrapper,
} from './DropdownButton.styles';

const DropdownButton = ({
  label = 'popular',
  description = '',
  image = '',
  icon = '',
  onClick,
  isOpen = false,
  isModalType = false,
  noAnimation = false,
  error = false,
  customWidth = '',
  renderSelected,
  $isAbove = false,
}) => {
  const { primaryTextColor, secondaryTextColor } = useThemeContext();

  const AnimatedIconWrapper = animated(IconWrapper);
  const iconAnimation = useSpring({
    transform: isOpen ? 'rotate(-270deg)' : 'rotate(-90deg)',
    from: { transform: 'rotate(-90deg)' },
    config: config.gentle,
    immediate: noAnimation,
  });

  return (
    <Button
      onClick={onClick}
      data-testid="dropdown-button"
      aria-haspopup="true"
      aria-expanded={isOpen}
      tabIndex={0}
      $isDropdownOpen={!isModalType && isOpen}
      $error={error}
      $customWidth={customWidth}
      $isAbove={$isAbove}
    >
      {renderSelected ? (
        renderSelected({ image, description, title: label, icon })
      ) : (
        <Wrapper>
          {description && (
            <DescriptionWrapper>
              <Typography color={secondaryTextColor} type="paragraph-s" dataTestId="description">
                {description}
              </Typography>
            </DescriptionWrapper>
          )}
          {image && (
            <ImageWrapper>
              <img src={image} alt={label} width={24} height={24} />
            </ImageWrapper>
          )}
          <LabelWrapper>
            <Typography color={primaryTextColor} type="title-s" dataTestId="label">
              {label}
            </Typography>
          </LabelWrapper>
        </Wrapper>
      )}
      <AnimatedIconWrapper style={iconAnimation} data-testid="animation-wrapper">
        <Icon fillColor={primaryTextColor} type="chevronLeft" size={16} inline />
      </AnimatedIconWrapper>
    </Button>
  );
};

DropdownButton.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isModalType: PropTypes.bool,
  noAnimation: PropTypes.bool,
  error: PropTypes.bool,
  customWidth: PropTypes.string,
  renderSelected: PropTypes.func,
  $isAbove: PropTypes.bool,
};

export default memo(DropdownButton);
