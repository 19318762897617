export const SORT_FEATURED = 'featured';
export const SORT_POPULAR = 'popular';
export const SORT_RECENT = 'recent';

export const sortItemsDetails = {
  [SORT_FEATURED]: {
    id: 'featured',
    title: 'featured',
    subtitle: 'A mix of handpicked, most popular and recent content.',
  },
  [SORT_POPULAR]: {
    id: 'popular',
    title: 'popular',
    subtitle: 'A mix of handpicked, most popular and recent content.',
  },
  [SORT_RECENT]: {
    id: 'recent',
    title: 'recent',
    subtitle: 'Show most recent content first.',
  },
};

export const events = [
  {
    id: '1',
    title: 'LCS',
    selected: false,
  },
  {
    id: '2',
    title: 'ESL',
    selected: false,
  },
];
