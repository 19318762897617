import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import typographyTypes from 'znipe-elements/general/Typography/Typography.styles';
import { SizeTypes } from './ToolTip.types';

export const initialTranslate = {
  top: 'translateY(-50%)',
  bottom: 'translateY(50%)',
  left: 'translateX(-50%)',
  right: 'translateX(50%)',
};

const defaultSizes = {
  small: { borderRadius: '4px', padding: '0 12px', height: '32px' },
  medium: { borderRadius: '8px', padding: '0 16px', height: '40px' },
};

type TypographyType = keyof typeof typographyTypes;

export const LabelText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

type TextProps = { type: TypographyType };

export const Text = styled(Typography).attrs(({ type }: TextProps) => ({ type }))``;

type HotkeyProps = {
  $textColor?: string;
};

export const Hotkey = styled(Text)<HotkeyProps>`
  color: ${({ theme }) => theme.UIAccent} !important;
  &:before {
    content: '(';
    margin-right: 3px;
    color: ${({ theme, $textColor }) => $textColor || theme.textSecondary};
  }
  &:after {
    content: ')';
    margin-left: 3px;
    color: ${({ theme, $textColor }) => $textColor || theme.textSecondary};
  }
`;

export const FloatingWrapper = styled.div`
  z-index: 1;
`;

type FloatingProps = {
  $size?: SizeTypes;
  $textColor?: string;
  $borderColor?: string;
  $backgroundColor?: string;
  $tailColor?: string;
  $uppercase?: boolean;
};

export const Tail = styled.div``;

export const ToolTipContainer = styled.div<FloatingProps>`
  user-select: none;
  z-index: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.5);

  ${({ theme, $size = 'medium', $textColor, $backgroundColor, $borderColor, $tailColor }) => {
    const { borderRadius, padding, height } = defaultSizes[$size];
    return css`
      height: ${height};
      border-radius: ${borderRadius};
      padding: ${padding};
      background-color: ${$backgroundColor || theme.primaryBackgroundColor};
      border: 1px solid ${$borderColor || theme.tertiaryBackgroundColor};
      ${Tail} {
        fill: ${$tailColor || $borderColor || $backgroundColor || theme.tertiaryBackgroundColor};
      }
      ${Text} {
        display: block;
        color: ${$textColor || theme.textPrimary};
      }
    `;
  }}
  p {
    margin-bottom: unset;
  }
`;

type ReferenceProps = {
  $fluidWidth?: boolean;
};

export const Reference = styled.div<ReferenceProps>`
  outline: none;
  display: flex;
  align-items: center;
  ${({ $fluidWidth }) => $fluidWidth && 'width: 100%'};
`;
