import styled from 'styled-components';
import Icon, { IconName } from 'znipe-elements/general/Icon/Icon';
import { useState } from 'react';
import { Button } from '../Buttons.styles';

const StyledButton = styled(Button)`
  &:hover::after,
  &:focus::after {
    background: inherit;
  }
`;

type ChatButtonProps = {
  onClick: () => void;
  icon: IconName;
  size: number;
  isActive?: boolean;
  disabled?: boolean;
  additionalStyles?: string;
};
const ChatButton: React.FC<ChatButtonProps> = ({
  onClick,
  icon,
  size,
  isActive,
  disabled = false,
  additionalStyles,
}) => {
  const [hoverState, setHoverState] = useState(false);

  return (
    <StyledButton
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      data-testid="styled-chat-button"
      onClick={onClick}
      disabled={disabled}
      $size={size}
    >
      <Icon
        type={icon}
        fillColor={hoverState || isActive ? 'white' : 'rgba(255, 255, 255, 0.6)'}
        size={size}
        inline
        additionalStyles={additionalStyles}
      />
    </StyledButton>
  );
};

export default ChatButton;
