import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const TeamVsTeamWrapper = styled.div`
  position: absolute;
  left: -100px;
  top: -22px;
`;

export const TeamLogoWrapper = styled.div`
  position: absolute;
  left: -88px;
  top: -26px;
`;

export const RelativePositioning = styled.div`
  position: relative;
`;

export const LogoBackgroundImage = styled.div`
  position: absolute;
  left: -20px;
  top: 10px;
  opacity: 0.5;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 110px;
  min-height: 94px;
`;

export const LogoImagePosition = styled.div`
  position: absolute;
  top: -40px;
  left: -120px;
`;

export const SelectImagePosition = styled.div`
  position: absolute;
  top: -20px;
  left: -120px;
`;

export const QuestionmarkContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const QuestionMark = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 8px;
  text-align: center;
  z-index: 2;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  text-transform: uppercase;
  @supports (-webkit-text-stroke: 3px transparent) {
    background: ${({ theme }) => theme.teamAGradient};
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable property-no-vendor-prefix */
  }
`;
