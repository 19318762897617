import { createSelector } from 'reselect';
import makeGetProductUserPrice from 'tv-selectors/products/makeGetProductUserPrice';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductSubscriptionDiscountPeriods = () => {
  const getProductUserPrice = makeGetProductUserPrice();
  return createSelector([getProductUserPrice], userPrice => {
    if (userPrice.productType === 'subscription' && typeof userPrice.discountPeriods === 'number') {
      return userPrice.discountPeriods;
    }

    return 0;
  });
};

export const useGetProductSubscriptionDiscountPeriods = props =>
  useMemoizedSelector(makeGetProductSubscriptionDiscountPeriods, props);

export default makeGetProductSubscriptionDiscountPeriods;
