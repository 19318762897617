import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

const getDeviceType = () => {
  if (md.mobile()) return 'mobile';
  if (md.tablet()) return 'tablet';

  return 'desktop';
};

export default getDeviceType;
