import { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Modal from './Modal';

const ModalWithButton = forwardRef(
  (
    {
      children,
      onAfterOpen,
      onBeforeClose,
      footerChildren,
      buttonText = '',
      floatingHeader = false,
      darkMode = false,
      toggleButton = Button,
      compactButton = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen]);

    const ToggleButton = toggleButton;

    useImperativeHandle(ref, () => ({ toggleModal }), [toggleModal]);

    return (
      <>
        <ToggleButton onClick={toggleModal} compact={compactButton}>
          {buttonText}
        </ToggleButton>
        <Modal
          isOpen={isOpen}
          onAfterOpen={onAfterOpen}
          onBeforeClose={onBeforeClose}
          footerChildren={footerChildren}
          floatingHeader={floatingHeader}
          darkMode={darkMode}
          onCloseClick={toggleModal}
        >
          {children}
        </Modal>
      </>
    );
  },
);

ModalWithButton.propTypes = {
  children: PropTypes.node.isRequired, // content inside modal
  buttonText: PropTypes.node,
  onAfterOpen: PropTypes.func,
  onBeforeClose: PropTypes.func,
  floatingHeader: PropTypes.bool,
  darkMode: PropTypes.bool,
  toggleButton: PropTypes.elementType,
  footerChildren: PropTypes.arrayOf(PropTypes.element),
  compactButton: PropTypes.bool,
};

export default ModalWithButton;
