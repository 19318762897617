import { memo } from 'react';
import PropTypes from 'prop-types';
import TopicLink, {
  TEAM,
  CHAMPION,
  PLAYER,
  DEFAULT,
} from 'tv-elements/data-display/TopicLink/TopicLink';

import { TEAMS, CHAMPIONS, PLAYERS, EVENTS } from '../../Filters.constants';

const topicLinkType = {
  [TEAMS]: TEAM,
  [CHAMPIONS]: CHAMPION,
  [PLAYERS]: PLAYER,
  [EVENTS]: DEFAULT,
};

const getTitle = (type, result) => {
  if (type === PLAYERS) return result.nickname;
  if (type === EVENTS) return result.eventName || result.name;
  return result.name;
};

const getSubtitle = (type, result) => {
  if (type === PLAYERS) return result.teamName;
  if (type === TEAMS) return result.country;
  if (type === CHAMPIONS) return result.title;
  return result.region;
};

const getImage = (type, result) => {
  if (type === TEAMS) return result.logoLight;
  return result.image;
};

const getAvatarIcon = (type, result) => {
  if (type === PLAYERS) return 'supportRole';
  if (type === CHAMPIONS && result.class) return `${result.class.toLowerCase()}Class`;
  return '';
};

const FilterTopicLink = ({
  data,
  type,
  isSelected = false,
  isActive = false,
  onClick = () => {},
  onClickIcon = () => {},
}) => {
  const title = getTitle(type, data);
  const subtitle = getSubtitle(type, data);
  const image = getImage(type, data);
  const avatarIcon = getAvatarIcon(type, data);

  return (
    <TopicLink
      title={title}
      subtitle={subtitle}
      image={image}
      avatarIcon={avatarIcon}
      type={topicLinkType[type]}
      isEmpty={!isActive && !isSelected}
      isSelected={!isActive && isSelected}
      isActive={isActive}
      onClick={onClick}
      onClickIcon={onClickIcon}
      noBackground={isActive}
      roundedCorner={false}
    />
  );
};

FilterTopicLink.propTypes = {
  data: PropTypes.shape({}).isRequired,
  type: PropTypes.oneOf([TEAMS, CHAMPIONS, PLAYERS, EVENTS]).isRequired,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onClickIcon: PropTypes.func,
};

export default memo(FilterTopicLink);
