import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import { DropdownWrapper, ButtonWrapper } from '../FilterMenu/FilterMenu.styles';

export const DropdownSection = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TournamentDropdownWrapper = styled(DropdownWrapper)`
  width: 100%;
  margin: 0;
  min-width: 230px;
  @media ${device.tablet} {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export { DropdownWrapper, ButtonWrapper };
