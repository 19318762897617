import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsDesktopOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.desktop || false);
};

export const useGetIsDesktopOrGreater = () => useMemoizedSelector(makeGetIsDesktopOrGreater);

export default makeGetIsDesktopOrGreater;
