const isYesterday = someDate => {
  const today = new Date();
  const dateCopy = new Date(someDate.getTime());
  return (
    dateCopy.getDate() === today.getDate() - 1 &&
    dateCopy.getMonth() === today.getMonth() &&
    dateCopy.getFullYear() === today.getFullYear()
  );
};

export default isYesterday;
