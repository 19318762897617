import { useMemo } from 'react';
import { createSelectorCreator } from 'reselect';
import { shallowEqual } from 'react-redux';
import strictMemoize from 'tv-utils/strictMemoize';
import { useGamePrediction } from 'tv-selectors/predictions/makeGetGamePredictions';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { updateOutcomePrediction } from './predictionActions';
import PredictionInputCard from './components/PredictionInput/PredictionInputCard';

const createStrictEventsSelector = createSelectorCreator(strictMemoize, () => true, shallowEqual);

const emptyObject = {};
const emptyArray = [];
const getScenarios = ({ scenarios }) => scenarios ?? emptyObject;
const getGameTime = ({ gats }, { gatId }) => gats[gatId]?.gameTime ?? -1;
const getGatScenariosIds = ({ gats }, { gatId }) => gats[gatId]?.scenarios ?? emptyArray;

const getGameScenarios = createStrictEventsSelector(
  [getGatScenariosIds, getScenarios, getGameTime],
  (ids, scenarios, gameTime) =>
    ids
      .map(id => scenarios[id])
      .filter(({ outcome }) => outcome === null)
      .filter(({ expiresIn, gameOffset }) => gameOffset / 1000 + expiresIn / 1000 > gameTime)
      .sort((e1, e2) => e2.gameOffset - e1.gameOffset),
);

const getGameScenariosWithOutcome = createStrictEventsSelector(
  [getGatScenariosIds, getScenarios],
  (ids, scenarios) =>
    ids
      .map(id => scenarios[id])
      .filter(({ outcome }) => outcome !== null)
      .sort((e1, e2) => e2.gameOffset - e1.gameOffset),
);

const makeGetGameTime = () => createStrictEventsSelector([getGameTime], gameTime => gameTime);

const useScenarioInputCard = gatId => {
  const userId = useUserId();
  const getCurrentGameTime = makeGetGameTime();
  const gameTime = useIntensiveSelector(state => getCurrentGameTime(state, { gatId }));

  const scenarioCards = useIntensiveSelector(state => getGameScenarios(state, { gatId }));

  const scenarioCardsWithOutcome = useIntensiveSelector(state =>
    getGameScenariosWithOutcome(state, { gatId }),
  );

  const gamePredictions = useGamePrediction({ gatId });
  const gamePredictionIds = Object.keys(gamePredictions);

  scenarioCardsWithOutcome.forEach(scenario => {
    if (gamePredictionIds.includes(scenario.id)) {
      updateOutcomePrediction(gatId, userId, scenario);
    }
  });

  const filteredScenarioCards = scenarioCards.filter(
    scenario => !gamePredictionIds.includes(scenario.id),
  );

  return useMemo(
    () =>
      Object.values(filteredScenarioCards).map(scenario => (
        <PredictionInputCard
          scenario={scenario}
          size="small"
          remainingTime={scenario.expiresIn / 1000 - (gameTime - scenario.gameOffset / 1000)}
          totalTime={scenario.expiresIn / 1000}
          gameId={gatId}
          key={scenario.id}
        />
      )),
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    [filteredScenarioCards, gameTime, gatId],
  );
};

export default useScenarioInputCard;
