import styled, { css } from 'styled-components';
import prefers from 'znipe-styles/prefers';
import { device } from 'znipe-styles/breakpoints';

const getOverlayBackgroundStyle = ({
  $shouldUseFullscreenOverlay,
  $isVisible,
  $shouldShowDefaultView,
  theme,
}) => {
  if ($shouldShowDefaultView && $shouldUseFullscreenOverlay && $isVisible)
    return css`
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 9.15%, rgba(0, 0, 0, 0) 21%);
      @media ${device.desktopExtraLarge} {
        background: transparent;
      }
    `;

  return css`
    background: ${$shouldUseFullscreenOverlay && $isVisible ? theme.overlayBackground : 'none'};
  `;
};

export const DesktopContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 5;
  height: 100%;
  ${getOverlayBackgroundStyle};
  pointer-events: ${({ $shouldUseFullscreenOverlay }) =>
    $shouldUseFullscreenOverlay ? 'auto ' : 'none'};
  @media ${prefers.motion} {
    transition: opacity 0.5s ease;
  }
  @media ${device.desktop} {
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    ${({ $isVisible }) =>
      !$isVisible &&
      css`
        cursor: none;
      `};
  }
`;

export const DesktopBody = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const DesktopFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  pointer-events: auto;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible ' : 'hidden')};
  ${({ $shouldUseFullscreenOverlay }) =>
    !$shouldUseFullscreenOverlay &&
    css`
      background: linear-gradient(
        180deg,
        rgba(14, 16, 18, 0) 0%,
        rgba(14, 16, 18, 0.61) 12.5%,
        #000000 100%
      );
    `}
`;

export const FooterButtonsWrapper = styled.div`
  display: none;
  @media ${device.desktopExtraLarge} {
    z-index: 1;
    display: grid;
    grid-template-columns: min-content auto;
    /*grid-gap: 40px;*/
  }
`;

export const SeekbarWrapper = styled.div`
  width: 100%;
  @media ${device.desktopExtraLarge} {
    ${({ $useMaxWidth }) => css`
      max-width: ${$useMaxWidth ? '1920px' : '2560px'};
      padding: ${$useMaxWidth ? '0 40px' : '0 5px'};
    `};
  }
`;

export const CompletedVideoContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: all;
`;

export const CompletedIconElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 24px;
  cursor: pointer;
`;

export const CompletedIconWrapper = styled.div`
  margin: auto;
`;

export const CompletedLabelWrapper = styled.div`
  margin-top: 8px;
  text-align: center;
`;

export const ReplayButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const ReplayAnimationWrapper = styled.div`
  width: 84px;
  height: 84px;
`;
