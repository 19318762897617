import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from './makeGetMatch';

const makeGetMatchChannelId = () => {
  const getMatch = makeGetMatch();
  return createSelector([getMatch], match => match?.channel ?? match?.channelId);
};

export const useGetMatchChannelId = props => useMemoizedGqlSelector(makeGetMatchChannelId, props);

export default makeGetMatchChannelId;
