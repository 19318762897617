import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getProductReceiptSecret } from 'tv-actions/old/products';

const useGetReceiptSecretRequest = productId => {
  const dispatch = useDispatch();
  const getSecret = useCallback(
    invoiceId => {
      const options = {};
      if (invoiceId) options.invoiceId = invoiceId;
      return dispatch(getProductReceiptSecret(productId, options));
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    [productId, dispatch],
  );
  return getSecret;
};

export default useGetReceiptSecretRequest;
