export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';
export const AUTO = 'auto';

export const LEFT = 'left';
export const RIGHT = 'right';

export const animationDuration = 400;

export const elementSizeSet = {
  [SMALL]: {
    typography: 'heading-xs',
    icon: 15,
    iconMargin: 3,
    marker: 4,
    skeleton: {
      height: 15,
      width: 48,
      topMargin: 6,
    },
  },
  [MEDIUM]: {
    typography: 'heading-s',
    icon: 24,
    iconMargin: 4,
    marker: 4,
    skeleton: {
      height: 18,
      width: 62,
      topMargin: 10,
    },
  },
  [LARGE]: {
    typography: 'heading-m',
    icon: 33,
    iconMargin: 6,
    marker: 4,
    skeleton: {
      height: 24,
      width: 83,
      topMargin: 16,
    },
  },
  [XLARGE]: {
    typography: 'heading-xl',
    icon: 62,
    iconMargin: 10,
    marker: 8,
    skeleton: {
      height: 39,
      width: 136,
      topMargin: 22,
    },
  },
};
