import PropTypes from 'prop-types';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import EliminatedInfo from '../DeadPlayerInfo/EliminatedInfo/EliminatedInfo';
import useIsHomeTeam from '../hooks/useIsHomeTeam';
import { sizesShape } from '../constants';
import Cell from './components/Cell';
import { Stats, StatsSection } from './StatsInfo.styles';

const selectColor = (a, b) => {
  const diff = a - b;
  if (diff > 0) return 'beanGreen';
  if (diff < 0) return 'errorLight';
  return 'white';
};

const emptyObject = {};

const StatsInfo = ({ gameId, size = 'xsmall', show = false, actorId, victimId, weapon }) => {
  const actorStats = useIntensiveSelector(
    state => state.players?.[`${gameId}_${actorId}`]?.stats ?? emptyObject,
  );
  const victimStats = useIntensiveSelector(
    state => state.players?.[`${gameId}_${victimId}`]?.stats ?? emptyObject,
  );

  const isHomeTeam = useIsHomeTeam(gameId, actorId);

  const actorKills = actorStats.kills ?? 0;
  const actorADR = (actorStats.averageDamagePerRound ?? 0).toFixed(2);
  const actorHSP = (actorStats.headshotPercentage ?? 0).toFixed(2);
  const victimKills = victimStats.kills ?? 0;
  const victimADR = (victimStats.averageDamagePerRound ?? 0).toFixed(2);
  const victimHSP = (victimStats.headshotPercentage ?? 0).toFixed(2);

  if (!show) return null;
  return (
    <>
      <EliminatedInfo
        actorId={actorId}
        victimId={victimId}
        size={size}
        isHomeTeam={isHomeTeam}
        show={show}
        weapon={weapon}
      />
      <Stats $size={size}>
        <StatsSection $size={size} data-testid="actor-section">
          <Cell
            size={size}
            color={selectColor(actorKills, victimKills)}
            title="Kills"
            value={actorKills}
          />
          <Cell size={size} color={selectColor(actorADR, victimADR)} title="ADR" value={actorADR} />
          {size !== 'xsmall' && (
            <Cell
              size={size}
              color={selectColor(actorHSP, victimHSP)}
              title="HS%"
              value={`${actorHSP}%`}
            />
          )}
        </StatsSection>
        <StatsSection $size={size} data-testid="victim-section">
          <Cell
            size={size}
            color={selectColor(victimKills, actorKills)}
            title="Kills"
            value={victimKills}
          />
          <Cell
            size={size}
            color={selectColor(victimADR, actorADR)}
            title="ADR"
            value={victimADR}
          />
          {size !== 'xsmall' && (
            <Cell
              size={size}
              color={selectColor(victimHSP, actorHSP)}
              title="HS%"
              value={`${victimHSP}%`}
            />
          )}
        </StatsSection>
      </Stats>
    </>
  );
};

StatsInfo.propTypes = {
  size: sizesShape,
  gameId: PropTypes.string.isRequired,
  actorId: PropTypes.string,
  victimId: PropTypes.string,
  weapon: PropTypes.string,
  show: PropTypes.bool,
};

export default StatsInfo;
