import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};
const getPageType = (_, props) => props.pageType ?? '';
const getPages = state => state.pages ?? emptyObject;

const makeGetPage = () =>
  createSelector([getPages, getPageType], (pages, pageType) => pages[pageType] ?? emptyObject);

/**
 *
 * @param {{pageType: string}} props The page type to filter on, i.e. "home"
 */
export const useGetPage = props => useMemoizedGqlSelector(makeGetPage, props);

export default makeGetPage;
