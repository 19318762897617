import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ErrorPage from 'tv-modules/Payment/ErrorPage/ErrorPage';
import SadPoro from 'tv-modules/Subscription/images/SadPoro';

const ErrorImageWrapper = styled.div`
  height: 120px;

  svg {
    height: 100%;
  }
`;

const ErrorImage = () => (
  <ErrorImageWrapper data-testid="error-image">
    <SadPoro />
  </ErrorImageWrapper>
);

const BaseError = ({ header = 'New subscriptions are currently disabled', description = '' }) => (
  <ErrorPage header={header} description={description} image={ErrorImage} />
);

BaseError.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
};

export default memo(BaseError);
