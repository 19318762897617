import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Meta = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryTextColor};
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 4px;
`;
