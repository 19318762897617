import { memo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import ModalProvider from './StyledModal/ModalProvider';

import {
  themes,
  StyledModal,
  FadingBackground,
  ModalHeader,
  CloseButton,
  ModalFooter,
} from './Modal.styles';

const Modal = memo(
  ({
    isOpen,
    onAfterOpen,
    onBeforeClose,
    footerChildren,
    floatingHeader,
    children,
    darkMode,
    dialogStyles,
    onCloseClick,
  }) => {
    const [opacity, setOpacity] = useState(0);
    const closeTimeout = useRef(null);

    const showModal = useCallback(() => {
      const timeout = setTimeout(() => {
        setOpacity(1);
      }, 10);

      return () => {
        clearTimeout(timeout);
        if (closeTimeout.current) clearTimeout(closeTimeout.current);
      };
    }, []);

    const closeModal = useCallback(
      () =>
        new Promise(resolve => {
          setOpacity(0);
          closeTimeout.current = setTimeout(resolve, 200);
        }),
      [],
    );

    return (
      <ModalProvider backgroundComponent={FadingBackground}>
        <StyledModal
          darkMode={darkMode}
          isOpen={isOpen}
          afterOpen={() => {
            showModal();
            if (onAfterOpen) {
              onAfterOpen();
            }
          }}
          beforeClose={() => {
            if (onBeforeClose) {
              onBeforeClose();
            }
            closeModal();
          }}
          onEscapeKeydown={onCloseClick}
          opacity={opacity}
          backgroundProps={{ opacity }}
          style={dialogStyles}
        >
          <ModalHeader data-testid="modal-header" floating={floatingHeader}>
            <CloseButton data-testid="close-button" onClick={onCloseClick}>
              <Icon inline type="close" size={24} fillColor={darkMode ? 'white' : 'black'} />
            </CloseButton>
          </ModalHeader>
          {children}
          {footerChildren && (
            <ModalFooter data-testid="modal-footer" onClick={onCloseClick}>
              {footerChildren.map(button => button)}
            </ModalFooter>
          )}
        </StyledModal>
      </ModalProvider>
    );
  },
);

Modal.propTypes = {
  children: PropTypes.node.isRequired, // content inside modal
  onAfterOpen: PropTypes.func,
  onCloseClick: PropTypes.func.isRequired,
  onBeforeClose: PropTypes.func,
  floatingHeader: PropTypes.bool,
  isOpen: PropTypes.bool,
  darkMode: PropTypes.bool,
  dialogStyles: PropTypes.shape({}),
  footerChildren: PropTypes.arrayOf(PropTypes.element),
};

export default withTheme(Modal, themes, 'modal');
