import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useGatId from 'tv-hooks/useGatId';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import convertTimestampToReadableTime from 'znipe-utils/date/convertTimestampToReadableTime';
import Typography from 'znipe-elements/general/Typography/Typography';

const DebugMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
`;

const StatsDebugMenu = ({ matchId, selectedMatchGameIndex }) => {
  const gatId = useGatId(matchId, selectedMatchGameIndex);
  const gameTime = useIntensiveSelector(state => state.gats[gatId]?.gameTime);

  const humanReadableGameTime = convertTimestampToReadableTime(gameTime);

  return (
    <DebugMenu>
      <Typography type="heading-m">Stats times</Typography>
      <Typography type="heading-xs">GameClock: {humanReadableGameTime}</Typography>
      <Typography type="heading-xs">Seconds: {Math.floor(gameTime)}</Typography>
    </DebugMenu>
  );
};

StatsDebugMenu.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
};

export default memo(StatsDebugMenu);
