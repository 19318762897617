import { memo } from 'react';
import PropTypes from 'prop-types';
import { OptionWrapper, Dot, Label } from './SubMenu.styles';

const OptionItem = ({ label, value, selectOption, selected = false, uppercase = false }) => (
  <OptionWrapper
    onClick={() => selectOption(value)}
    data-testid={`option-item-${label}`}
    uppercase={uppercase}
  >
    <Dot selected={selected} />
    <Label selected={selected}>{label}</Label>
  </OptionWrapper>
);

OptionItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectOption: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  uppercase: PropTypes.bool,
};

export default memo(OptionItem);
