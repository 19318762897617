import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const sizes = {
  xsmall: {
    height: 48,
    width: 184,
    centerItems: 16,
    nameType: 'heading-xs',
    nameSize: 14,
  },
  small: {
    height: 56,
    width: 312,
    centerItems: 24,
    nameType: 'heading-xs',
    nameSize: 14,
  },
  medium: {
    height: 88,
    width: 480,
    centerItems: 32,
    nameType: 'heading-s',
    nameSize: 18,
  },
  large: {
    height: 128,
    width: 680,
    centerItems: 40,
    nameType: 'heading-m',
    nameSize: 24,
  },
  xlarge: {
    height: 128,
    width: 800,
    centerItems: 48,
    nameType: 'heading-l',
    nameSize: 32,
  },
};

export const Container = styled(animated.div)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px #000000;
  border-radius: 5px;
  height: ${({ $size }) => sizes[$size].height}px;
  width: ${({ $size }) => sizes[$size].width}px;
`;

export const SizeContainer = styled.div`
  position: absolute;
  height: ${({ $size }) => sizes[$size].height * ($size === 'xsmall' ? 1.1 : 1.4)}px;
  width: ${({ $size }) => sizes[$size].height * ($size === 'xsmall' ? 1.1 : 1.4)}px;
  ${({ $type }) => $type}: 0;
  bottom: 0;
`;

export const PlayerContainer = styled(animated.div)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
`;

export const EliminatedText = styled(animated.span)`
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ $size }) => sizes[$size].centerItems}px;
  line-height: ${({ $size }) => sizes[$size].centerItems}px;
  color: ${({ theme }) => theme.primaryTextColor};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  width: max-content;
  @supports (-webkit-text-stroke: 3px transparent) {
    transform: skew(-20deg);
    background: ${({ theme, $isHomeTeam }) =>
      $isHomeTeam ? theme.teamAGradient : theme.teamBGradient};
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable property-no-vendor-prefix */
  }
`;

export const PlayerName = styled.div`
  position: absolute;
  width: ${({ $size }) => sizes[$size].height * ($size === 'xsmall' ? 1.1 : 1.4)}px;
  bottom: 0;
  ${({ $type }) => $type}: 0;
  display: flex;
  justify-content: center;

  > * {
    text-shadow:
      1px 0 0 #000,
      0 -1px 0 #000,
      0 1px 0 #000,
      -1px 0 0 #000;
  }
`;

export const Weapon = styled(animated.div)`
  height: ${({ $size }) => $size}px;

  > img {
    height: 100%;
  }
`;
