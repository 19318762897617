import { useMemo } from 'react';
import AvatarPreview from './components/AvatarPreview';
import { AvatarsWrapper, FeaturedWraper } from './AvatarPicker.styles';
import useChatContext from '../../../hooks/useChatContext';
import ContentSection from '../../ContentSection/ContentSection';
import type { FeaturedAvatar } from 'znipe-chat/src/contexts/ChatContext';

type Avatar = {
  id: string;
  label: string;
  shortLabel?: string;
  img: string;
  default: boolean;
};

export type Size = 'small' | 'medium' | 'large' | 'xlarge';

type AvatarPickerProps = {
  label?: string;
  featuredAvatars?: FeaturedAvatar[];
  avatars?: Avatar[];
  selectedAvatar?: string | null;
  columnsPerRow?: number;
  featuredAvatarSize?: Size;
  avatarSize?: Size;
  onAvatarSelect: (avatarId: string) => void;
};

const AvatarPicker: React.FC<AvatarPickerProps> = ({
  label = 'Avatar',
  featuredAvatars,
  avatars,
  selectedAvatar,
  columnsPerRow = 4,
  featuredAvatarSize = 'medium',
  avatarSize = 'small',
  onAvatarSelect,
}) => {
  const { analyticsManager } = useChatContext();
  const numberOfFeaturedAvatars = useMemo(() => featuredAvatars?.length ?? 0, [featuredAvatars]);
  return (
    <ContentSection header={label}>
      {numberOfFeaturedAvatars > 0 && (
        <FeaturedWraper>
          <AvatarsWrapper
            data-testid="featured-avatars-wrapper"
            $hasFeaturedAvatars={!!numberOfFeaturedAvatars}
            $columnsPerRow={Math.min(numberOfFeaturedAvatars, columnsPerRow)}
          >
            {featuredAvatars
              ?.sort((a, b) => Number(b.default) - Number(a.default))
              .map(avatar => (
                <AvatarPreview
                  key={avatar.id}
                  label={avatar.label}
                  shortLabel={avatar.shortLabel}
                  img={avatar.img}
                  isSelected={avatar.id === selectedAvatar}
                  size={featuredAvatarSize}
                  isHomeTeam={avatar.isHomeTeam}
                  isAwayTeam={avatar.isAwayTeam}
                  onClick={() => {
                    analyticsManager?.trackUIEvent({
                      action: `avatar_change`,
                      trigger: 'button_featured_avatar_preview',
                      value: avatar.id,
                    });
                    onAvatarSelect?.(avatar.id);
                  }}
                />
              ))}
          </AvatarsWrapper>
        </FeaturedWraper>
      )}
      <AvatarsWrapper
        data-testid="avatars-wrapper"
        $hasFeaturedAvatars={!!numberOfFeaturedAvatars}
        $columnsPerRow={columnsPerRow}
      >
        <AvatarPreview
          label="NONE"
          shortLabel="NONE"
          isSelected={selectedAvatar === ''}
          size={avatarSize}
          isHomeTeam={false}
          isAwayTeam={false}
          img="https://assets.znipe.tv/chat-avatars/slash.svg"
          onClick={() => {
            analyticsManager?.trackUIEvent({
              action: `avatar_change`,
              trigger: 'button_featured_avatar_preview',
              value: '',
            });
            onAvatarSelect('');
          }}
        />
        {avatars
          ?.sort((a, b) => Number(b.default) - Number(a.default))
          .map(avatar => (
            <AvatarPreview
              key={avatar.id}
              label={avatar.label}
              shortLabel={avatar.shortLabel}
              img={avatar.img}
              isSelected={avatar.img === selectedAvatar}
              size={avatarSize}
              onClick={() => {
                analyticsManager?.trackUIEvent({
                  action: `avatar_change`,
                  trigger: 'button_avatar_preview',
                  value: avatar.id,
                });
                onAvatarSelect(avatar.id);
              }}
            />
          ))}
      </AvatarsWrapper>
    </ContentSection>
  );
};

export default AvatarPicker;
