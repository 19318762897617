/* eslint-disable no-plusplus */
import intersection from 'lodash.intersection';
import { UPPER_BRACKET, LOWER_BRACKET, emptyTeam } from './constants';

export const getWinner = (teamOne, teamTwo, teamOneScore, teamTwoScore) => {
  if (!teamOne || !teamTwo) return undefined;
  if (teamOneScore > teamTwoScore) return teamOne;
  if (teamOneScore < teamTwoScore) return teamTwo;
  return undefined;
};

const getTeam = (teamId, score, teamsData) => {
  if (!teamId) return emptyTeam;
  const currentTeam = teamsData.find(team => team.id === teamId);
  const { logoLight, logoDark, name } = currentTeam || { logoDark: null, name: 'TBA' };
  return {
    id: teamId || '',
    logo: logoLight || logoDark,
    name,
    score: score || 0,
  };
};

const getMatchup = (item, teams) => {
  const teamIds = item.tournamentTeams;
  const [teamOne, teamTwo] = teamIds ?? [];
  const scores = item.results;
  const [teamOneScore, teamTwoScore] = scores ?? [];
  const { loserTo, winnerTo, matchId } = item;
  return {
    id: item.id,
    teamOne: getTeam(teamOne, teamOneScore, teams),
    teamTwo: getTeam(teamTwo, teamTwoScore, teams),
    winner: getWinner(teamOne, teamTwo, teamOneScore, teamTwoScore),
    loserTo,
    winnerTo,
    matchId,
  };
};

export const getColumnsData = (schedules, type, teams) => {
  const targetSchedule = schedules.find(schedule => schedule.scheduleName === type);
  if (!targetSchedule || !targetSchedule?.rounds) return [];
  return targetSchedule.rounds.reduce(
    (acc, { matchups, roundNumber, roundName }) => [
      ...acc,
      {
        roundNumber,
        roundName,
        matchups: matchups?.map(item => getMatchup(item, teams)) || [],
      },
    ],
    [],
  );
};

export const getUpperBracketWithEmptyColumn = (schedules, oldUpperRounds) => {
  const upperRounds = schedules.find(schedule => schedule.scheduleName === UPPER_BRACKET);
  const lowerRounds = schedules.find(schedule => schedule.scheduleName === LOWER_BRACKET);
  if (!lowerRounds) {
    return oldUpperRounds.map((round, index) => ({
      ...round,
      roundNumber: upperRounds.rounds[index].roundNumber,
    }));
  }

  const upperBracketLoserToIds =
    upperRounds?.rounds.map(({ roundNumber, matchups }) => ({
      roundNumber,
      ids: matchups.map(item => item.loserTo),
    })) || [];
  const lowerBracketIds =
    lowerRounds?.rounds.map(({ roundNumber, matchups }) => ({
      roundNumber,
      ids: matchups.map(item => item.id),
    })) || [];
  let oldDataIndex = 0;
  const newUpperRounds = lowerBracketIds.map(item => {
    const lowerIds = item.ids;
    const targetUpperLoserToIds = upperBracketLoserToIds.slice(
      oldDataIndex,
      upperBracketLoserToIds.length,
    );
    const hasMatchingIds = !!targetUpperLoserToIds.find(innerItem => {
      const inter = intersection(innerItem.ids, lowerIds);
      return inter.length !== 0;
    });

    if (hasMatchingIds) {
      const val = oldUpperRounds[oldDataIndex];
      oldDataIndex++;
      return val;
    }
    return { roundNumber: item.roundNumber, matchups: [] };
  });

  return newUpperRounds;
};

export const getNumberOfEmptyColumns = (columns, startIndex) => {
  const targetColumns = columns.slice(startIndex, columns.length);
  const firstNoneEmptyMatchupIndex = targetColumns.findIndex(s => s.matchups.length > 1);
  const numberOfEmptyMatchups = targetColumns.slice(0, firstNoneEmptyMatchupIndex).length;
  return numberOfEmptyMatchups;
};
