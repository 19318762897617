import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const ScrollWrapper = styled.div`
  display: flex;
`;

const ScrollFirstChildStyles = css`
  margin-left: 8px;
  @media ${device.mobilePortrait} {
    margin-left: unset;
  }
`;

export const ScheduleItemStack = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  &:first-of-type {
    ${ScrollFirstChildStyles}
  }
`;

export const ScrollItemsFlex = styled(ScrollWrapper)`
  > a,
  > div {
    min-width: 224px;
    @media ${device.desktop} {
      min-width: 256px;
    }
    @media ${device.desktopLarge} {
      min-width: 224px;
    }
  }
  > div:not(:last-of-type),
  > a:not(:last-of-type) {
    margin-right: 8px;
  }
`;

export const SpaceDiv = styled.div`
  height: 16px;
  @media ${device.desktop} {
    height: 20px;
  }
  @media ${device.desktopLarge} {
    height: 24px;
  }
`;

export const SpotlightWrapper = styled.div`
  &:first-child {
    ${ScrollFirstChildStyles}
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  border: 0;
  margin-bottom: 16px;
  padding: 0 8px;
  @media ${device.desktop} {
    padding: 0;
  }
`;

export const DesktopSidebarContainer = styled.div`
  height: 100vh;
  width: 352px;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  @media ${device.desktopLarge} {
    width: 480px;
  }
`;

export const ScheduledMatchListContainer = styled.div`
  display: flex;
`;

export const FullScheduleButtonWrapper = styled.div`
  width: 126px;
  @media ${device.desktop} {
    width: 144px;
  }
  @media ${device.desktopLarge} {
    width: 208px;
  }
  @media ${device.desktopExtraLarge} {
    width: 168px;
  }
`;

export const EmptyStateWrapper = styled.div`
  position: fixed;
  top: calc(65px + 72px);
  bottom: 56px;
  left: 0;
  right: 0;
  @media ${device.desktop} {
    bottom: 0;
  }
`;
