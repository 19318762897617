import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetSelectedTeam from 'tv-selectors/team/makeGetSelectedTeam';

export const defaultLogoUrl = 'https://assets.znipe.tv/teams/default/default_logo.svg';

const makeGetSelectedTeamLogo = () => {
  const getSelectedTeam = makeGetSelectedTeam();
  const getProps = makeGetProps();
  return createSelector([getSelectedTeam, getProps], (selectedTeam, props) => {
    const {
      logoDark = '',
      logoSrc = '',
      logoLight = '',
    } = selectedTeam || {
      logoDark: '',
      logoSrc: '',
      logoLight: '',
    };
    return props.useDarkLogos
      ? logoDark || logoLight || logoSrc || defaultLogoUrl
      : logoLight || logoDark || logoSrc || defaultLogoUrl;
  });
};

export const useGetSelectedTeamLogo = props =>
  useMemoizedGqlSelector(makeGetSelectedTeamLogo, props);

export default makeGetSelectedTeamLogo;
