import { useMemo } from 'react';
import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';

const useIsHomeTeam = (gameId, actorId) => {
  const competitors = useGetGameCompetitors({ gameId });

  return useMemo(
    () => competitors[0]?.lineup.map(player => player.playerId)?.includes(actorId),
    [actorId, competitors],
  );
};

export default useIsHomeTeam;
