import styled, { css, keyframes } from 'styled-components';

const shrinkBounce = keyframes`
  0%{
    transform: scale(1);
  }
  33%{    
    transform: scale(.85);
  }
  100%{
    transform: scale(1);    
  }
`;
const checkboxCheck = ({ theme, $size }) => keyframes`
  0%{
    width: 0;
    height: 0;
    border-color: ${theme.checkbox.tickColor};
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33%{
    width: ${$size.boxSize / 4}px;
    height: 0;
    border-color: ${theme.checkbox.tickColor};
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  40%{    
    width: ${$size.boxSize / 4}px;
    height: 2px;    
    border-color: ${theme.checkbox.tickColor};
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  50%{    
    width: ${$size.boxSize / 4}px;
    height: ${$size.boxSize / 2 + $size.increment * 5}px;    
    border-color: ${theme.checkbox.tickColor};
    transform: ${`translate3d(${$size.leftTranslate}px, ${$size.increment}px, 0) rotate(45deg);`}
  }
  60%{    
    width: ${$size.boxSize / 4}px;
    height: ${$size.boxSize / 2 + $size.increment * 4}px;
    border-color: ${theme.checkbox.tickColor};
    transform: ${`translate3d(${$size.leftTranslate}px, ${
      $size.increment * 2
    }px, 0) rotate(45deg);`}
  }
  70%{    
    width: ${$size.boxSize / 4}px;
    height: ${$size.boxSize / 2 + $size.increment * 3}px;   
    border-color: ${theme.checkbox.tickColor};
    transform: ${`translate3d(${$size.leftTranslate}px, ${
      $size.increment * 3
    }px, 0) rotate(45deg);`}
  }
  80%{    
    width: ${$size.boxSize / 4}px;
    height: ${$size.boxSize / 2 + $size.increment * 2}px;    
    border-color: ${theme.checkbox.tickColor};
    transform: ${`translate3d(${$size.leftTranslate}px, ${
      $size.increment * 4
    }px, 0) rotate(45deg);`}
  }
  90%{    
    width: ${$size.boxSize / 4}px;
    height: ${$size.boxSize / 2 + $size.increment}px;    
    border-color: ${theme.checkbox.tickColor};
    transform: ${`translate3d(${$size.leftTranslate}px, ${
      $size.increment * 5
    }px, 0) rotate(45deg);`}
  }
  100%{    
    width: ${$size.boxSize / 4}px;
    height: ${$size.boxSize / 2}px;    
    border-color: ${theme.checkbox.tickColor};
    transform: ${`translate3d(${$size.leftTranslate}px, ${
      $size.increment * 6
    }px, 0) rotate(45deg);`}
  }
`;

export const Input = styled.input`
  height: 0px;
  width: 0px;
  visibility: hidden;
  margin: 0;
  position: absolute;
`;

export const CheckBox = styled.span`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  ${({ $size }) => css`
    width: ${$size.boxSize}px;
    height: ${$size.boxSize}px;
    min-width: ${$size.boxSize}px;
    min-height: ${$size.boxSize}px;
    margin-right: ${$size.marginRight}px;
  `};
  border-radius: 2px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  border: 1px solid ${({ error, theme }) => (error ? theme.errorLight : theme.containerStroke)};
  background: ${({ theme }) => theme.tertiaryBackgroundColor};

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  p {
    margin-bottom: unset;
  }
  ${props =>
    props.longLabel
      ? css`
          color: ${props.theme.checkbox.labelColorSecondary};
          word-wrap: break-word;
          align-items: flex-start;
        `
      : css`
          color: ${props.theme.secondaryTextColor};
        `};
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;

  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    `};

  ${/* sc-selector */ Input}:checked + ${Label} {
    ${CheckBox} {
      border: ${({ $size }) => $size.boxSize / 2}px solid ${({ theme }) => theme.highlight};
      animation: ${shrinkBounce} 200ms cubic-bezier(0.4, 0, 0.23, 1);
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: -7px;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;

        transform: ${$size =>
          `translate3d(${$size.leftTranslate}px, ${$size.increment * 6}px, 0) rotate(45deg)`};
        transform-origin: 0% 100%;
        animation: ${props => checkboxCheck(props)} 100ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
          forwards;
        @media (prefers-reduced-motion: reduce) {
          animation: none;
        }
      }
    }
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
`;
