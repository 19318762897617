import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import { sizesShape } from '../../constants';

const CellStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const textSizes = {
  xsmall: {
    value: 'title-xs',
    title: 'title-xs',
  },
  small: {
    value: 'title-xs',
    title: 'title-xs',
  },
  medium: {
    value: 'heading-s',
    title: 'heading-xs',
  },
  large: {
    value: 'heading-m',
    title: 'heading-s',
  },
  xlarge: {
    value: 'heading-l',
    title: 'heading-m',
  },
};

const Cell = ({ value, color, title, size }) => (
  <CellStyles>
    <Typography as="span" type={textSizes[size].value} color={color}>
      {value}
    </Typography>
    <Typography as="span" type={textSizes[size].value} color="secondaryTextColor">
      {title}
    </Typography>
  </CellStyles>
);

Cell.propTypes = {
  value: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: sizesShape.isRequired,
};

export default Cell;
