import produce from 'immer';
import {
  SET_CONTENT_SUBSCRIPTIONS,
  POP_CONTENT_SUBSCRIPTIONS,
  PUSH_CONTENT_SUBSCRIPTIONS,
  UPDATE_CONTENT_SUBSCRIPTIONS,
} from 'tv-constants/redux';

export const initialState = {};

const TEAM = 'team';
const PLAYER = 'player';
const EVENT = 'event';
const CHAMPION = 'riotChampion';

const contentSubscriptionsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_CONTENT_SUBSCRIPTIONS: {
      let contentType = '';
      Object.entries(payload).forEach(([, value]) => {
        [TEAM, PLAYER, EVENT, CHAMPION].forEach(item => {
          if (value[item]) contentType = item;
        });
        if (!state[contentType]) state[contentType] = {};
        if (typeof value[contentType] === 'string') {
          state[contentType][value[contentType]] = value;
        }
      });
      return state;
    }
    case POP_CONTENT_SUBSCRIPTIONS: {
      const { type: contentType, id } = payload;
      delete state[contentType]?.[id];
      return state;
    }
    case PUSH_CONTENT_SUBSCRIPTIONS: {
      const { type: contentType, id, nodeId } = payload;
      if (!state[contentType]) state[contentType] = {};
      state[contentType][id] = { id, nodeId };
      return state;
    }
    case UPDATE_CONTENT_SUBSCRIPTIONS: {
      const { type: contentType, id, muteState } = payload;
      state[contentType][id].muted = muteState;
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default contentSubscriptionsReducer;
