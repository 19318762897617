import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';

const emptyArray = [];

const getIdsFromProps = (_, props) => props.ids || [];

const makeGetSpotlightPlayers = () => {
  const getPlayers = makeGetPlayers();
  return createSelector([getPlayers, getIdsFromProps], (allPlayers, ids) => {
    if (!allPlayers || !ids?.length) return emptyArray;
    return ids.map(id => allPlayers[id]);
  });
};

export const useGetSpotlightPlayers = props =>
  useMemoizedGqlSelector(makeGetSpotlightPlayers, props);

export default makeGetSpotlightPlayers;
