import colors from 'znipe-styles/colors';

export default {
  proview: {
    iconColor: colors.riotAccentOrange,
    backgroundColor: '#f1f1f1',
    itemColor: '#292d31',
    taglineColor: '#687077',
  },
  default: {
    iconColor: colors.riotAccentOrange,
    backgroundColor: '#f1f1f1',
    itemColor: '#292d31',
    taglineColor: '#687077',
  },
};
