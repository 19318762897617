import { SET_GAT_GAME_TITLE, RESET_GAT } from 'tv-reducers/intensive';

export const setGatGameTitle = (gatId, gameTitle) => ({
  type: SET_GAT_GAME_TITLE,
  gatId,
  gameTitle,
});

export const resetGat = gatId => ({
  type: RESET_GAT,
  gatId,
});
