import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};
const getPackages = state => state.packages ?? emptyObject;
const getPackageNameFromProps = (_, props) => props.packageName ?? '';

const makeGetPackageId = () =>
  createSelector(
    [getPackages, getPackageNameFromProps],
    (packages, packageName) => packages[packageName]?.id ?? '',
  );

export const useGetPackageId = props => useMemoizedGqlSelector(makeGetPackageId, props);

export default makeGetPackageId;
