import { useRegion } from 'tv-selectors/auth/makeGetRegion';

// Only includes logic for pro view right now, needs to be expanded later
const useSelectCurrency = () => {
  const region = useRegion();

  switch (region) {
    case 'TR1':
    case 'RU':
    case 'EUN1':
    case 'EUW1':
      return 'eur';
    default:
      return 'usd';
  }
};

export default useSelectCurrency;
