import { memo } from 'react';
import styled from 'styled-components';
import Icon from 'znipe-elements/general/Icon/Icon';
import useThemeContext from 'znipe-hooks/useThemeContext';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import colors from 'znipe-styles/colors';

const Container = styled.a`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

const Divider = styled.div`
  height: 30px;
  border-left: 2px solid ${({ theme }) => theme.containerStroke};
  margin-left: 16px;
`;

const emptyTheme = { url: '', icon: '', iconColor: '', caretColor: '', tooltip: '' };

const getTheme = themeName => {
  if (themeName !== 'proview') return emptyTheme;
  return {
    url: 'https://lolesports.com',
    icon: 'lolEsports',
    iconColor: colors.white,
    caretColor: colors.pvHighlight,
    tooltip: 'Back to lolesports',
  };
};

const ExternalLink = () => {
  const theme = useThemeContext();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const { themeName } = theme;
  const { url, icon, iconColor, caretColor, tooltip } = getTheme(themeName);
  if (themeName !== 'proview') return null;
  return (
    <ToolTip placement="bottom" label={tooltip}>
      <Container href={url}>
        <Icon size={24} type="caretLeft" fillColor={caretColor} />
        <Icon size={24} type={icon} fillColor={iconColor} />
        {isDesktopOrGreater && <Divider />}
      </Container>
    </ToolTip>
  );
};

export default memo(ExternalLink);
