import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import gradients from 'znipe-styles/gradients';
import colors from 'znipe-styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  text-transform: uppercase;
  @media ${device.tablet} {
    text-transform: initial;
  }
`;

export const Row = styled.div`
  width: 100%;
  ${({ isHeader }) =>
    isHeader
      ? css`
          padding: 0 16px 4px 16px;
        `
      : css`
          height: 48px;
          padding: 0 16px;
          @media ${device.tablet} {
            height: 56px;
          }
          @media ${device.desktop} {
            height: 48px;
          }
          @media ${device.desktopLarge} {
            height: 56px;
          }
          @media ${device.desktopExtraLarge} {
            height: 64px;
          }
        `};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${({ theme }) => theme.tertiaryBackgroundColor};
      border: 1px solid ${colors.bubblePink};
    `}
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${gradients.edgeFadingLine};
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  p {
    margin: auto;
  }
  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `}
  ${({ isHeader, theme }) =>
    isHeader &&
    css`
      color: ${theme.secondaryTextColor};
    `}
  ${({ isLabel }) =>
    isLabel &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${({ isNumberColumn }) =>
    isNumberColumn &&
    css`
      width: 100%;
    `}
`;

export const LabelColumns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
`;

export const NumberColumnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${({ numberOfColumns }) =>
    numberOfColumns === 2 &&
    css`
      width: 112px;
      @media ${device.tablet} {
        width: 144px;
      }
      @media ${device.desktop} {
        width: 112px;
      }
      @media ${device.desktopLarge} {
        width: 144px;
      }
    `}
`;

export const NumberColumns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 204px;
  @media ${device.tablet} {
    width: 344px;
  }
  @media ${device.desktop} {
    width: 232px;
  }
  @media ${device.desktopLarge} {
    width: 344px;
  }
  @media ${device.desktopExtraLarge} {
    width: 416px;
  }
`;

const iconSizeCss = css`
  width: 24px;
  height: 24px;
  @media ${device.tablet} {
    width: 32px;
    height: 56px;
  }
  @media ${device.desktop} {
    width: 24px;
    height: 48px;
  }
  @media ${device.desktopLarge} {
    width: 32px;
    height: 56px;
  }
  @media ${device.desktopExtraLarge} {
    width: 40px;
    height: 64px;
  }
`;

export const IconWrapper = styled.div`
  ${iconSizeCss}
  display: flex;
  align-items: center;
  margin-right: 8px;
  @media ${device.tablet} {
    width: 32px;
    margin-right: 16px;
  }
  @media ${device.desktop} {
    width: 24px;
    margin-right: 8px;
  }
  @media ${device.desktopLarge} {
    width: 32px;
    margin-right: 16px;
  }
  @media ${device.desktopExtraLarge} {
    width: 40px;
  }
  ${({ hasMarginLeft }) =>
    hasMarginLeft &&
    css`
      margin-left: 16px;
      @media ${device.tablet} {
        margin-left: 24px;
      }
      @media ${device.desktopLarge} {
        margin-left: 16px;
      }
    `}
`;

export const TeamLogo = styled.div`
  ${iconSizeCss}
  ${({ logo }) =>
    logo &&
    css`
      border-radius: 12px;
    `}
  background: url(${({ logo }) => logo}) no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
`;
