import { Fragment, memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'znipe-elements/data-display/Carousel/Carousel';
import StageSelector from 'tv-modules/Standings/StageSelector/StageSelector';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import {
  UPPER_BRACKET,
  LOWER_BRACKET,
  GRAND_FINAL,
  rawTeamShape,
} from 'tv-modules/Standings/Bracket/utils/constants';
import {
  getColumnsData,
  getUpperBracketWithEmptyColumn,
} from 'tv-modules/Standings/Bracket/utils/handleData';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import MatchupList from './components/MatchupList';
import { BracketMatchupLoading } from '../Bracket/Bracket.styles';
import { ContentWrapper, Container, ListContainer } from './CompactBracket.styles';

const SEMIFINALS_TITLE = 'Semifinals';
const GRAND_FINALS_TITLE = 'Grand finals';

const emptyArray = [];
const CompactBracket = ({ tournamentStageId = '', teams = [], selectedTeams = [] }) => {
  const schedules = useGqlSelector(
    state => state.tournamentStages[tournamentStageId]?.schedules ?? emptyArray,
  );
  const [activeIndex, setActiveIndex] = useState(0);

  const lowerColumns = useMemo(
    () => getColumnsData(schedules, LOWER_BRACKET, teams),
    [schedules, teams],
  );

  const upperColumns = useMemo(
    () =>
      getUpperBracketWithEmptyColumn(schedules, getColumnsData(schedules, UPPER_BRACKET, teams)),
    [schedules, teams],
  );

  const finalColumns = useMemo(
    () => getColumnsData(schedules, GRAND_FINAL, teams),
    [schedules, teams],
  );

  const columns = useMemo(() => {
    const selectorTitles = [
      ...upperColumns.map((_item, index) =>
        index === upperColumns.length - 1 ? SEMIFINALS_TITLE : `Round ${index + 1}`,
      ),
    ];
    if (finalColumns.length > 0) selectorTitles.push(GRAND_FINALS_TITLE);
    else selectorTitles[selectorTitles.length - 1] = GRAND_FINALS_TITLE;

    return selectorTitles;
  }, [upperColumns, finalColumns]);

  const grandFinalSlide =
    finalColumns.length === 0
      ? []
      : [<MatchupList final={finalColumns[0]} selectedTeams={selectedTeams} />];

  const slides = [
    ...upperColumns.map((upperItem, index) => (
      <MatchupList upper={upperItem} lower={lowerColumns[index]} selectedTeams={selectedTeams} />
    )),
    ...grandFinalSlide,
  ];
  const slideIds = slides.map(() => generateUniqueId());

  if (!teams?.length || !tournamentStageId || !schedules?.length) {
    return <NoResults type="knockouts" />;
  }

  return (
    <Container data-testid="compact-bracket-container">
      <StageSelector
        stages={columns}
        activeIndex={activeIndex}
        onClickLeft={setActiveIndex}
        onClickRight={setActiveIndex}
      />
      <ContentWrapper>
        <Carousel activeSlide={activeIndex} onChange={setActiveIndex} hideNavigation>
          {!slides ? (
            <ListContainer>
              <BracketMatchupLoading compactBracket />
              <BracketMatchupLoading compactBracket />
              <BracketMatchupLoading compactBracket />
              <BracketMatchupLoading compactBracket />
            </ListContainer>
          ) : (
            slides.map((slide, index) => <Fragment key={slideIds[index]}>{slide}</Fragment>)
          )}
        </Carousel>
      </ContentWrapper>
    </Container>
  );
};

CompactBracket.propTypes = {
  tournamentStageId: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.shape(rawTeamShape)),
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default memo(CompactBracket);
