import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetContentSubscriptions from 'tv-selectors/contentSubscriptions/makeGetContentSubscriptions';

const emptyArray = [];

const makeGetSubscribedChampionIds = () => {
  const getContentSubscriptions = makeGetContentSubscriptions();
  return createSelector([getContentSubscriptions], subscriptions => {
    if (!subscriptions?.riotChampion) return emptyArray;
    const ids = Object.keys(subscriptions.riotChampion);
    return ids ?? emptyArray;
  });
};

export const useGetSubscribedChampionIds = () =>
  useMemoizedGqlSelector(makeGetSubscribedChampionIds);

export default makeGetSubscribedChampionIds;
