import styled from 'styled-components';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import { Tile, emojiTileFeedbackStyles } from '../EmojiTile/EmojiTile.styles';

type ContainerProps = {
  $isOpen: boolean;
  $hideOverflow: boolean;
};

export const TRANSITION_DURATION = 200; // ms

export const Container = styled.div<ContainerProps>`
  padding: ${props => (props.$isOpen ? '2px' : '0')} 12px 0;
  height: ${props => (props.$isOpen ? '42px' : '0')};
  overflow: ${({ $hideOverflow }) => ($hideOverflow ? 'hidden' : 'visible')};
  transition:
    height ${TRANSITION_DURATION}ms ease-in-out,
    padding ${TRANSITION_DURATION}ms ease-in-out;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.containerStroke};
  display: flex;
  flex-direction: row;
  @media (prefers-reduced-motion) {
    transition: none;
  }
  ${Tile} {
    &:hover,
    &:focus,
    &:active {
      background: unset;
    }
  }
`;

export const Button = styled.button`
  ${buttonDefaultStyle};
  flex-grow: 1;
  border-radius: 3px;
  ${emojiTileFeedbackStyles};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MoreEmojisButton = styled(Button)`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;
