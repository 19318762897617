/* eslint-disable react/no-array-index-key */
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Row,
  Cell,
  TeamLogo,
  LabelColumns,
  NumberColumns,
  NumberColumnsWrapper,
  IconWrapper,
} from './GroupsTable.styles';

const defaultNumbers = {
  wins: 0,
  ties: 0,
  loses: 0,
  rd: 0,
  points: 0,
};

const SMALL = { number: 'title-m', teamName: 'paragraph-m', icon: 24 };
const MEDIUM = { number: 'title-l', teamName: 'paragraph-l', icon: 32 };
const LARGE = { number: 'title-xl', teamName: 'paragraph-l', icon: 40 };

const TableRow = ({
  id,
  logo = '',
  rank = null,
  name = 'tba',
  numbers = defaultNumbers,
  isSelected = false,
}) => {
  const { containerStroke } = useThemeContext();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();

  const sizes = useMemo(() => {
    if (isDesktopExtraLargeOrGreater) return LARGE;
    if (isDesktopLargeOrGreater) return MEDIUM;
    if (isDesktopOrGreater) return SMALL;
    if (isTabletOrGreater) return MEDIUM;
    return SMALL;
  }, [
    isTabletOrGreater,
    isDesktopOrGreater,
    isDesktopLargeOrGreater,
    isDesktopExtraLargeOrGreater,
  ]);

  const numberCells = useMemo(
    () =>
      Object.entries(numbers).map(([key, value]) => (
        <Cell key={`${id}-${key}`} isNumberColumn data-testid="cell-number">
          <Typography type={sizes.number}>{value}</Typography>
        </Cell>
      )),
    [id, numbers, sizes.number],
  );

  const numberOfColumns = useMemo(() => Object.keys(numbers).length, [numbers]);

  return (
    <Row data-testid="row-team" $isSelected={isSelected}>
      <LabelColumns>
        {rank && (
          <Cell isLabel data-testid="cell-rank">
            <Typography type={sizes.number}>{rank}</Typography>
          </Cell>
        )}
        <Cell isLabel>
          <IconWrapper hasMarginLeft={rank}>
            {logo !== '' ? (
              <TeamLogo logo={logo} data-testid="team-logo" />
            ) : (
              <Icon type="fallbackTeamLogo" size={sizes.icon} fillColor={containerStroke} />
            )}
          </IconWrapper>
        </Cell>
        <Cell isLabel data-testid="cell-name">
          <Typography type={sizes.teamName}>{name}</Typography>
        </Cell>
      </LabelColumns>
      <NumberColumnsWrapper numberOfColumns={numberOfColumns}>
        <NumberColumns>{numberCells}</NumberColumns>
      </NumberColumnsWrapper>
    </Row>
  );
};

TableRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  logo: PropTypes.string,
  rank: PropTypes.number,
  isSelected: PropTypes.bool,
  numbers: PropTypes.objectOf(PropTypes.number),
};

export default memo(TableRow);
