import { createContext, useContext } from 'react';
import { StoreApi, UseBoundStore, create, useStore } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import type { IconName } from 'znipe-elements/general/Icon/Icon';
import type { ControlFeedbackPosition } from 'znipe-watch/src/modules/ControlFeedback/ControlFeedback';
import type { StoreSubscribeWithSelector, Write } from 'znipe-watch/src/stores/zustand.types';

export type UiInfoStore = {
  fullscreen: boolean;
  menu: string | null;
  debug: boolean;
  inSync: { [key: string]: boolean };
  useClippingTool: boolean;
  setUseClippingTool: (useClippingTool: boolean) => void;
  poster: string;
  setPoster: (poster: string) => void;
  setFullscreen: (fullscreen: boolean) => void;
  setDebug: (debug: boolean) => void;
  setMenu: (menu: string | null) => void;
  setInSync: (id: string, inSync: boolean) => void;
  controlFeedback?: { icon: IconName; position?: ControlFeedbackPosition };
  triggerControlFeedback: (icon: IconName, position?: ControlFeedbackPosition) => void;
};

export const createUiInfoStore = () =>
  create<UiInfoStore>()(
    subscribeWithSelector(
      devtools(
        (set, get) => ({
          menu: null,
          fullscreen: false,
          debug: false,
          inSync: {},
          poster: '',
          useClippingTool: false,
          setUseClippingTool: (useClippingTool: boolean) => set({ useClippingTool }),
          setPoster: (poster: string) => set({ poster }),
          setFullscreen: (fullscreen: boolean) =>
            set(() => ({ fullscreen }), false, 'setFullscreen'),
          setDebug: (debug: boolean) => set(() => ({ debug }), false, 'setDebug'),
          setMenu: (menu: string | null) => set(() => ({ menu }), false, 'setMenu'),
          setInSync: (id: string, value: boolean) =>
            set(() => ({ inSync: { ...get().inSync, [id]: value } }), false, 'setInSync'),
          triggerControlFeedback: (icon: IconName, position?: ControlFeedbackPosition) =>
            set(() => ({ controlFeedback: { icon, position } })),
        }),
        { name: 'UiInfo' },
      ),
    ),
  );

export type UiInfoType = UseBoundStore<
  Write<StoreApi<UiInfoStore>, StoreSubscribeWithSelector<UiInfoStore>>
>;
export const UiInfoContext = createContext<UiInfoType | null>(null);

export const useUiInfoStore = () => useContext(UiInfoContext);

const useUiInfo = <T>(selector: (state: UiInfoStore) => T): T => {
  const store = useUiInfoStore();
  if (!store) {
    throw new Error('no store available');
  }
  return useStore(store, selector);
};

export default useUiInfo;
