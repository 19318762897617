import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from 'tv-selectors/match/makeGetMatch';

const emptyArray = [];

const makeGetMatchPlayerStreams = () => {
  const getSelectedMatch = makeGetMatch();
  return createSelector(
    [getSelectedMatch],
    selectedMatch => selectedMatch.playerStreams || emptyArray,
  );
};

export const useGetMatchPlayerStreams = props =>
  useMemoizedGqlSelector(makeGetMatchPlayerStreams, props);

export default makeGetMatchPlayerStreams;
