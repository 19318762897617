import produce from 'immer';
import { SET_PLAYERS, SET_SINGLE_PLAYER } from 'tv-constants/redux';
import getLastItem from 'znipe-utils/misc/getLastItem';

export const initialState = {};

const playersReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_PLAYERS: {
      Object.entries(payload).forEach(([key, value]) => {
        // ! TODO: replace this mess when the gql endpoint is updated with string filters
        // * https://github.com/znipeesport/znipe/issues/1666
        Object.entries(value).forEach(([k, v]) => {
          if (!state[key]) state[key] = {};
          if (k === 'team' && v?.logoLight) {
            state[key].logoLight = getLastItem(v.logoLight);
          } else if (Array.isArray(v)) {
            state[key][k] = getLastItem(v);
          } else {
            state[key][k] = v?.name ? getLastItem(v.name) : v;
          }
        }, {});
      });
      return state;
    }
    case SET_SINGLE_PLAYER: {
      Object.keys(payload).forEach(key => {
        Object.entries(payload[key]).forEach(([k, v]) => {
          if (k === 'team') k = 'teamId';
          if (!state[key]) state[key] = {};
          if (Array.isArray(v)) {
            state[key][k] = getLastItem(v);
          } else {
            state[key][k] = v;
          }
        }, {});
      });
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default playersReducer;
