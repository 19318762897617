import colors from 'znipe-styles/colors';

export default {
  proview: {
    labelColor: colors.riotBackgroundRule,
    labelColorSecondary: colors.riotTextSecondary,
    borderColor: colors.regsGrey,
    tickColor: colors.white,
  },
  znipebase: {
    labelColor: colors.riotTextSecondary,
    labelColorSecondary: colors.riotTextSecondary,
    borderColor: colors.znipeContainerStroke,
    tickColor: colors.white,
  },
  default: {
    labelColor: colors.riotBackgroundRule,
    labelColorSecondary: colors.riotTextSecondary,
    borderColor: colors.regsGrey,
    tickColor: colors.white,
  },
};
