import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    width: 500px;
  }

  @media ${device.desktop} {
    width: 630px;
  }
`;

export const List = styled.div`
  width: 100%;
  padding-top: 13px;

  @media ${device.tablet} {
    width: calc(100% + 28px);
    transform: translateX(-14px);
  }

  @media ${device.desktop} {
    width: calc(100% + 32px);
    transform: translateX(-16px);
  }
`;

export const Item = styled.div`
  display: grid;
  padding: 10px 8px;
  grid-template-columns: auto 60px;
  grid-template-rows: auto;
  grid-template-areas:
    'Date Status'
    'Invoice Status'
    'Amount Status'
    'Button Button';
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.billingHistory.bgColorListItemDark};
  }

  @media ${device.tablet} {
    height: 45px;
    padding: 0;
    grid-template-columns: 5% 23% 30% 16% 26%;
    ${({ withButton }) =>
      withButton
        ? css`
            grid-template-areas: 'Icon Date Invoice Amount Button';
          `
        : css`
            grid-template-areas: 'Icon Date Invoice Amount Status';
          `}
    ${({ isHeader }) =>
      isHeader &&
      css`
        text-transform: uppercase;
        color: ${({ theme }) => theme.billingHistory.textColorHeader};
      `}
    &:nth-child(odd) {
      background-color: transparent;
    }
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.billingHistory.bgColorListItemDark};
    }
  }

  @media ${device.desktop} {
    grid-template-columns: 5% 25% 32% 16% 22%;
  }
`;

const Paid = ({ theme }) => css`
  color: ${theme.billingHistory.textColorPaid};
`;
const Unpaid = ({ theme }) => css`
  color: ${theme.billingHistory.textColorUnpaid};
`;
const Pending = ({ theme }) => css`
  color: ${theme.billingHistory.textColorPending};
`;

export const Field = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  ${props => props.status === 'paid' && Paid}
  ${props => props.status === 'unpaid' && Unpaid}
  ${props => props.status === 'pending' && Pending}

  ${props =>
    props.as === 'a' &&
    css`
      color: ${props.theme.billingHistory.textColor};
      text-decoration: underline;
    `}

  @media ${device.mobile} {
    ${({ gridArea }) =>
      gridArea === 'Icon' &&
      css`
        padding-top: 10px;
      `};

    ${({ gridArea }) =>
      gridArea === 'Status' &&
      css`
        grid-row: 1 / 4;
        text-transform: uppercase;
      `};

    ${({ gridArea }) =>
      ['Date', 'Invoice', 'Amount'].indexOf(gridArea) !== -1 &&
      css`
        &::before {
          content: '${`${gridArea}: `}';
        }
      `};

    ${({ gridArea }) =>
      gridArea === 'Status' &&
      css`
        justify-self: right;
        align-content: center;
        font-size: 18px;
        line-height: 41px;
      `};
  }

  @media ${device.tablet} {
    font-size: ${({ isHeader }) => (isHeader ? '16px' : '18px')};
    line-height: 41px;
    &::before {
      content: none !important;
    }

    ${({ gridArea }) =>
      gridArea === 'Icon' &&
      css`
        background-color: white;
        width: 100%;
        height: 45px;
        text-align: center;
      `};

    ${({ gridArea }) =>
      gridArea === 'Amount' &&
      css`
        justify-self: left;
        ${({ isHeader, theme }) =>
          isHeader
            ? css`
                color: ${theme.billingHistory.textColorHeader};
                font-size: 16px;
                line-height: 41px;
              `
            : css`
                color: ${theme.billingHistory.textColor};
              `};
      `};

    ${({ gridArea }) =>
      gridArea === 'Status' &&
      css`
        justify-self: center;
      `};

    ${({ gridArea }) =>
      ['Date', 'Invoice', 'Amount'].indexOf(gridArea) !== -1 &&
      css`
        padding: 0 8px;
        justify-self: left;
      `};
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  position: relative;
  padding-top: 10px;
  width: 162px;
`;

export const AuthorizeButtonWrapper = styled.div`
  padding-top: 10px;
  grid-area: Button;
  display: grid;
  justify-self: center;
  position: relative;

  @media ${device.tablet} {
    ${({ isListItem }) =>
      isListItem &&
      css`
        padding-top: 6px;
        > button {
          font-size: 14px;
          height: 30px;
          width: 120px;
        }
      `}
  }
`;

export const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: ${props => props.height || '90%'};
  position: relative;
`;

export const ButtonLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const Disclaimer = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin: 16px 0px;
`;
