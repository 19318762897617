import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CHAMPIONS, EVENTS, PLAYERS, TEAMS } from '../../Filters.constants';
import FilterTopicLink from './FilterTopicLink';

const SELECTED = 'selected';
const NOT_SELECTED = 'notSelected';

const TopicLinkSearchResult = ({
  menu,
  searchResults = [],
  selection,
  onChangeSelection = () => {},
  onClear,
}) => {
  const selectedIds = useMemo(
    () => (selection ? selection.map(item => item.id) : searchResults.map(item => item.id)),
    [selection, searchResults],
  );

  const partitionTopics = useCallback(
    () =>
      searchResults.reduce(
        (acc, current) => {
          const isSelected = selectedIds.indexOf(current.id) !== -1;
          acc[isSelected ? SELECTED : NOT_SELECTED].push(current);
          return acc;
        },
        { [SELECTED]: [], [NOT_SELECTED]: [] },
      ),
    [searchResults, selectedIds],
  );

  const sortTopics = useCallback(
    topics =>
      topics.sort((a, b) => {
        if (menu === PLAYERS) return a.nickname?.toLowerCase() > b.nickname?.toLowerCase() ? 1 : -1;
        if (menu === EVENTS)
          return a.eventName?.toLowerCase() > b.eventName?.toLowerCase() ? 1 : -1;
        return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1;
      }),
    [menu],
  );

  const sortedSearchResults = useMemo(() => {
    const { selected, notSelected } = partitionTopics();
    return [...sortTopics(selected), ...sortTopics(notSelected)];
  }, [partitionTopics, sortTopics]);

  const handleClickTopic = useCallback(
    (data, isSelected) => {
      const newValue = isSelected
        ? selection.filter(item => item.id !== data.id)
        : [...selection, data];
      onChangeSelection({ [menu]: newValue });
    },
    [menu, selection, onChangeSelection],
  );

  return sortedSearchResults?.map(result => {
    const { id } = result;
    const isSelected = selectedIds.indexOf(id) !== -1;
    const onClick = onClear ? null : () => handleClickTopic(result, isSelected);
    const onClickIcon = () => onClear && onClear(menu, id);
    return (
      <FilterTopicLink
        key={id}
        data={result}
        type={menu}
        isSelected={isSelected}
        isActive={!selection}
        onClick={onClick}
        onClickIcon={onClickIcon}
      />
    );
  });
};

TopicLinkSearchResult.propTypes = {
  menu: PropTypes.oneOf([TEAMS, CHAMPIONS, PLAYERS, EVENTS]).isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  selection: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeSelection: PropTypes.func,
  onClear: PropTypes.func,
};

export default memo(TopicLinkSearchResult);
