import { useMemo } from 'react';
import useThemeContext from 'znipe-hooks/useThemeContext';

const useFlourishValues = () => {
  const theme = useThemeContext();
  const { themeName, mastheadGradient } = theme;

  const flourish = useMemo(() => {
    if (themeName === 'proview') return 'waves';
    return 'edgedWaves';
  }, [themeName]);

  return { background: mastheadGradient, flourish };
};

export default useFlourishValues;
