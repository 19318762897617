import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import ls from 'local-storage';
import { setAuth } from 'tv-actions/old/auth';
import { LS_AUTH } from 'tv-constants/localStorage';

export const getUserMetaData = ({ user }) =>
  firebase // eslint-disable-line no-undef
    .database()
    .ref('/users')
    .child(user.uid)
    .once('value')
    .then(snap => snap.val());

export const getUserToken = () =>
  firebase // eslint-disable-line no-undef
    .auth()
    .currentUser.getIdToken();

export const collectUserData = loggedInUser =>
  Promise.all([getUserMetaData(loggedInUser), getUserToken()]).then(results => {
    const [userMetaData, userToken] = results;

    return {
      userMetaData: userMetaData || {},
      userToken,
      loggedInUser,
    };
  });

export const mergeUserDataWithToken = async (loggedInUser, userMetaData) => {
  const userToken = await getUserToken();

  return {
    userMetaData: userMetaData || {},
    userToken,
    loggedInUser,
  };
};

export const setUserName = (user, name) =>
  firebase // eslint-disable-line no-undef
    .database()
    .ref('users')
    .child(user.uid)
    .update({
      username: name,
    })
    .then(() => user);

export const setUserEmailConsent = (user, emailConsent) =>
  firebase // eslint-disable-line no-undef
    .database()
    .ref('users')
    .child(user.uid)
    .child('emailSubscriptions')
    .update(emailConsent)
    .then(() => user);

export const setOnboarding = (user, onboarding) =>
  firebase // eslint-disable-line no-undef
    .database()
    .ref('users')
    .child(user.uid)
    .update({
      onboarding,
    })
    .then(() => user);

export const updateAuthInStore = (dispatch, userData) => {
  const user = userData.loggedInUser;
  const userMetaData = userData.userMetaData || {};
  const isLinkedToFaceIt =
    userMetaData &&
    Object.prototype.hasOwnProperty.call(userMetaData, 'externalIds') &&
    Object.prototype.hasOwnProperty.call(userMetaData.externalIds, 'faceIt');
  const isLinkedToRiot =
    userMetaData &&
    Object.prototype.hasOwnProperty.call(userMetaData, 'externalIds') &&
    Object.prototype.hasOwnProperty.call(userMetaData.externalIds, 'riot');
  const isLinkedToLGUPlus =
    userMetaData &&
    Object.prototype.hasOwnProperty.call(userMetaData, 'externalIds') &&
    Object.prototype.hasOwnProperty.call(userMetaData.externalIds, 'lguplus');

  const authObject = {
    isAdmin: userMetaData.isAdmin,
    onboarding: userMetaData.onboarding ?? {},
    isAuthenticated: true,
    isFetching: false,
    purchasedItems: userMetaData.purchasedItems || [],
    trialedItems: userMetaData.trialedItems || [],
    token: userData.userToken,
    userEmail: user.email,
    emailVerified: user.emailVerified,
    userId: user.uid,
    username: userMetaData.username,
    subscriptions: userMetaData.subscriptions || [],
    paymentMethod: (userMetaData.payment || {}).selected, // ?? selected.. a element?
    activeBadge: (userMetaData.badges || {}).selected, // TODO change this
    locale: userMetaData.locale || '',
    isLinkedToFaceIt,
    isLinkedToRiot,
    isLinkedToLGUPlus,
    partnerEntitlements: userMetaData.partnerEntitlements || {},
    preferences: userMetaData.preferences || {},
  };

  if (isLinkedToRiot) {
    authObject.region = userMetaData.region || '';
  }

  ls.set(LS_AUTH, authObject);
  dispatch(setAuth(authObject));
};
