import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { Button, Text, Chevron } from './PaymentTypeButton.styles';
import themes from './PaymentTypeButton.themes';

const PaymentTypeButton = ({ className, icon, label, transparent = false, to }) => {
  const { paymentTypeButton = {} } = useThemeContext();
  return (
    <Button
      data-testid="payment-type-button"
      className={className}
      isTransparent={transparent}
      to={to}
    >
      <Icon inline type={icon} fillColor={paymentTypeButton.iconColor} size={25} />
      <Text>{label}</Text>
      {!transparent && (
        <Chevron>
          <Icon type="chevronRight" inline fillColor={paymentTypeButton.iconColor} />
        </Chevron>
      )}
    </Button>
  );
};

PaymentTypeButton.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]).isRequired,
  transparent: PropTypes.bool,
};

export default withTheme(memo(PaymentTypeButton), themes, 'paymentTypeButton');
