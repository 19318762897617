export const CIRCLE = 'circle';
export const SQUARE = 'square';
export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';
export const AUTO = 'auto';
export const FEATURED = 'featured';
export const HIGHLIGHT = 'highlight';
