import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const makeGetTeamName = () => {
  const getTeams = makeGetTeams();
  return createSelector([getTeams, getTeamIdFromProps], (allTeams, teamId) => {
    if (!allTeams || !teamId) return '';
    return allTeams[teamId]?.name ?? '';
  });
};

export const useGetTeamName = props => useMemoizedGqlSelector(makeGetTeamName, props);

export default makeGetTeamName;
