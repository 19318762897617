import loadable from 'znipe-utils/web/loadable';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';

export const PLAYER_STATS = 'PLAYER_SATS';
export const TEAM_STATS = 'TEAM_STATS';
export const PLAYER_SELECTOR = 'PLAYER_SELECTOR';

export const STATS_MAP = {
  lol: {
    [PLAYER_SELECTOR]: loadable(
      () => import('tv-modules/Selector/StatsSelectorGroup/StatsSelectorGroup'),
    ),
    [PLAYER_STATS]: loadable(() => import('tv-modules/Stats/LOL/PlayerStats/PlayerStats')),
    [TEAM_STATS]: loadable(() => import('tv-modules/Stats/LOL/TeamStats/TeamStats')),
  },
  csgo: {
    [PLAYER_SELECTOR]: loadable(
      () => import('tv-modules/Selector/StatsSelectorGroup/StatsSelectorGroup'),
    ),
    [PLAYER_STATS]: loadable(() => import('tv-modules/Stats/CSGO/PlayerStats/PlayerStats')),
    [TEAM_STATS]: loadable(() => import('tv-modules/Stats/CSGO/TeamStats/TeamStats')),
  },
  default: {
    [PLAYER_SELECTOR]: LoadingSpinner,
    [PLAYER_STATS]: LoadingSpinner,
    [TEAM_STATS]: LoadingSpinner,
  },
};
