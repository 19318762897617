import { memo, forwardRef, useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Container,
  Wrapper,
  Required,
  Label,
  inputStyles,
  InlineLabelContent,
  InlineLabel,
  MaxLength,
  Comment,
} from 'znipe-elements/data-entry/Input/Input.styles';
import themes from 'znipe-elements/data-entry/Input/Input.themes';

const Textarea = styled.textarea`
  ${inputStyles};
  padding-top: ${({ isInline, theme: { input } }) => (isInline ? '30px' : input.verticalPadding)};
  height: initial;
  border: 1px solid
    ${({ error, theme: { containerStroke, errorRed } }) => (error ? errorRed : containerStroke)};
  resize: none;
`;

const TextInput = forwardRef(
  (
    {
      maxLength,
      name,
      label,
      rows = 6,
      error = false,
      active = false,
      isRequired = false,
      showLengthCount = false,
      labelPosition = 'left',
      labelAlign = 'flex-start',
      onChange,
      centerAlign = '',
      customWidth = '',
      defaultValue = '',
      comment = '',
    },
    ref,
  ) => {
    const [data, setData] = useState(defaultValue);
    const [currentLength, setCurrentLength] = useState(0);

    const handleChange = useCallback(
      e => {
        if (onChange) onChange(e);
        if (e.target) {
          setData(e.target.value);
          setCurrentLength(e.target.value.length);
        }
      },
      [onChange],
    );

    useEffect(() => {
      setCurrentLength(defaultValue.length);
    }, [defaultValue]);

    const getMaxLengthCounter = () => {
      if (showLengthCount && maxLength) {
        const remainingLength = maxLength - currentLength;
        const isEmpty = remainingLength === maxLength;
        return <MaxLength isEmpty={isEmpty}>{`${remainingLength}/${maxLength}`}</MaxLength>;
      }
      return null;
    };

    const isInline = label && labelPosition === 'inline';
    const isHorizontal = label && labelPosition === 'top';

    const commentElement = useMemo(
      () =>
        comment && (
          <Comment centerAlign={centerAlign} customWidth={customWidth}>
            <Typography type="paragraph-s">{comment}</Typography>
          </Comment>
        ),
      [comment, centerAlign, customWidth],
    );

    return (
      <Container>
        <Wrapper data-testid="text-input" isHorizontal={isHorizontal}>
          {!isInline && label && (
            <Label
              data-testid={`label-${labelPosition}`}
              error={error}
              position={labelPosition}
              centerAlign={centerAlign}
              labelAlign={labelAlign}
            >
              <Typography type="heading-xs">{label}</Typography>
              {isRequired && <Required />}
            </Label>
          )}
          {isHorizontal && commentElement}
          <Textarea
            name={name}
            data-testid="input"
            error={error}
            autoComplete="autocomplete-off"
            isInline={isInline}
            ref={ref}
            onChange={handleChange}
            maxLength={maxLength}
            rows={rows}
            centerAlign={centerAlign}
            customWidth={customWidth}
          />
          {isInline && label && (
            <InlineLabel data-testid="label-inline" error={error}>
              <InlineLabelContent hasData={active || data.length > 0}>
                <Typography type="heading-xs">{label}</Typography>
                {isRequired && <Required />}
              </InlineLabelContent>
            </InlineLabel>
          )}
          {getMaxLengthCounter()}
        </Wrapper>
        {!isHorizontal && commentElement}
      </Container>
    );
  },
);

TextInput.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.number,
  name: PropTypes.string,
  error: PropTypes.bool,
  active: PropTypes.bool,
  isRequired: PropTypes.bool,
  showLengthCount: PropTypes.bool,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  labelPosition: PropTypes.oneOf(['inline', 'left', 'top']),
  labelAlign: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  centerAlign: PropTypes.string,
  customWidth: PropTypes.string,
  defaultValue: PropTypes.string,
  comment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const DefaultInput = withTheme(memo(TextInput), themes, 'input');

export default DefaultInput;
