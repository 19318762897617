import styled, { css } from 'styled-components';
import prefers from 'znipe-styles/prefers';
import { Container as RolesFilterContainer } from 'tv-modules/Filters/RolesFilter/RolesFilter.styles';

export const Container = styled.div`
  width: 200%;
  display: flex;
  overflow-x: hidden;
`;

export const Slide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${RolesFilterContainer} {
    margin: auto;
  }
`;

export const MenuSelectorWrapper = styled.div`
  padding: 0 16px;
`;

export const SlideContainer = styled.div`
  width: 100%;
  display: flex;
  @media ${prefers.motion} {
    transition: 300ms ease;
  }
  ${({ currentSlide }) =>
    currentSlide &&
    css`
      transform: translateX(-50%);
    `}
`;
