// https://stackoverflow.com/a/65563155/2319925
const detectRobot = userAgent => {
  const robots = new RegExp(
    [
      // GENERAL TERMS
      /bot/,
      /spider/,
      /crawl/,
      // GOOGLE ROBOTS
      /APIs-Google/,
      /AdsBot/,
      /Googlebot/,
      /mediapartners/,
      /Google Favicon/,
      /FeedFetcher/,
      /Google-Read-Aloud/,
      /DuplexWeb-Google/,
      /googleweblight/,
      // OTHER ENGINES
      /bing/,
      /yandex/,
      /baidu/,
      /duckduck/,
      /yahoo/,
      /ecosia/,
      /ia_archiver/,
      // SOCIAL MEDIA
      /facebook/,
      /instagram/,
      /pinterest/,
      /reddit/,
      /slack/,
      /twitter/,
      /whatsapp/,
      /youtube/,
      // OTHER
      /semrush/,
    ]
      .map(r => r.source)
      .join('|'),
    'i',
  ); // BUILD REGEXP + "i" FLAG

  return robots.test(userAgent);
};

export default detectRobot;
