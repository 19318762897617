import { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetMatchAvailableGamesInfo } from 'tv-selectors/match/makeGetMatchAvailableGamesInfo';
import { useGetMatchBestOf } from 'tv-selectors/match/makeGetMatchBestOf';
import { useGetMatchStatus } from 'tv-selectors/match/makeGetMatchStatus';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import PopOver from 'znipe-elements/data-display/PopOver/PopOver';
import Typography from 'znipe-elements/general/Typography/Typography';
import withTheme from 'znipe-themes/hocs/withTheme';
import Localized from 'tv-elements/data-display/Localized/Localized';

import {
  themes,
  Container,
  GamesList,
  MobileGameLabel,
  GameWrapper,
  StatusWrapper,
  PopOverContainer,
  PopOverWrapper,
} from './MatchGameSelector.styles';

const GameSelectorPopOverContent = ({ isVodMatch = false, gameLabel = 'game' }) => (
  <PopOverContainer data-testid="game-selector-popover">
    <Typography type="title-xs">
      {isVodMatch ? (
        <Localized string={`The match concluded before this ${gameLabel} was played`} />
      ) : (
        <Localized string="Not played yet" />
      )}
    </Typography>
  </PopOverContainer>
);

GameSelectorPopOverContent.propTypes = {
  isVodMatch: PropTypes.bool,
  gameLabel: PropTypes.oneOf(['game', 'set']),
};

const selectGameStatus = (gameStatus = '', isVodMatch) => {
  if (gameStatus.toLowerCase() === 'vod') {
    return <Localized string="VOD" />;
  }
  if (isVodMatch) {
    return <Localized string="VOD" />;
  }
  return <Localized string="Live" />;
};

const MatchGameSelector = ({
  matchId,
  selectedMatchGameIndex,
  dontShowSelectedGame = false,
  gameLabel = 'game',
  setSelectedMatchGame = () => {},
  stageUrl = '',
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const matchGames = useGetMatchAvailableGamesInfo({ matchId });
  const matchBestOf = useGetMatchBestOf({ matchId });
  const isVodMatch = useGetMatchStatus({ matchId }) === 'vod';
  const currentSelectedMatchGameIndex = parseInt(selectedMatchGameIndex, 10);
  const isMobile = !useGetIsDesktopOrGreater();

  const handlePropagation = useCallback(event => {
    if (event) event.stopPropagation();
  }, []);

  const handleOnClick = useCallback(
    (gameId, e) => {
      handlePropagation(e);
      if (setSelectedMatchGame) {
        setSelectedMatchGame(gameId, matchId);
      } else {
        const path = stageUrl || (global.document ? location.pathname : '');
        navigate(`${path}?m=${matchId}&g=${gameId}`);
        window.parent.postMessage(
          {
            messageType: 'route-update',
            path: `vods/${matchId}/${gameId}`,
          },
          '*',
        );
      }
    },
    [matchId, setSelectedMatchGame, stageUrl, navigate, location, handlePropagation],
  );

  const numberOfAvailableGames = matchGames.length || 0;
  const numberOfFakeGames = Math.max(matchBestOf - numberOfAvailableGames, 0);
  const arrayofNElements = [
    ...Array(Number.isFinite(numberOfFakeGames) ? numberOfFakeGames : 0).keys(),
  ];

  return (
    <Container>
      <GamesList data-testid="games-list" useVerticalLayout={isMobile}>
        {matchBestOf > 1 &&
          matchGames.map((game, i) => (
            <GameWrapper
              key={game.id}
              onClick={e => handleOnClick(i, e)}
              isSelected={!dontShowSelectedGame && currentSelectedMatchGameIndex === i}
              data-testid={`game-element-${i + 1}`}
              role="button"
              useButtonStyle={isMobile}
              isPreviousGame={!dontShowSelectedGame && currentSelectedMatchGameIndex > i}
            >
              {isMobile && (
                <MobileGameLabel data-testid="mobile-game-label">
                  <Localized string={gameLabel} />
                </MobileGameLabel>
              )}
              <Typography type="title-xs">{i + 1}</Typography>
              {isMobile && (
                <StatusWrapper gameStatus={game.status} data-testid="status-label">
                  {selectGameStatus(game.status, isVodMatch)}
                </StatusWrapper>
              )}
            </GameWrapper>
          ))}
        {arrayofNElements.map(n => {
          const currentLoopIndex = n + 1;
          const currentFakeGameId = numberOfAvailableGames + currentLoopIndex;

          return (
            <Fragment key={n}>
              <PopOverWrapper isMobile={isMobile}>
                <PopOver
                  customBorderRadius="25px"
                  useBorderRadius
                  animationType="fadeIn"
                  fadeDurationMs={250}
                  popOverAreaBackground="transparent"
                  targetElement={
                    <GameWrapper
                      data-testid={`game-element-${currentFakeGameId}`}
                      role="button"
                      useButtonStyle={isMobile}
                    >
                      {isMobile && (
                        <MobileGameLabel data-testid="mobile-game-label">
                          {gameLabel}
                        </MobileGameLabel>
                      )}
                      <Typography type="title-xs">{currentFakeGameId}</Typography>
                      {isMobile && (
                        <StatusWrapper gameStatus="upcoming" data-testid="status-label">
                          {selectGameStatus('upcoming', isVodMatch)}
                        </StatusWrapper>
                      )}
                    </GameWrapper>
                  }
                >
                  <GameSelectorPopOverContent isVodMatch={isVodMatch} gameLabel={gameLabel} />
                </PopOver>
              </PopOverWrapper>
            </Fragment>
          );
        })}
      </GamesList>
    </Container>
  );
};

MatchGameSelector.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  dontShowSelectedGame: PropTypes.bool,
  gameLabel: PropTypes.string,
  setSelectedMatchGame: PropTypes.func,
  stageUrl: PropTypes.string,
};

export default withTheme(MatchGameSelector, themes, 'matchGameSelector');
