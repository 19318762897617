import { useCallback } from 'react';
import useChatContext from './useChatContext';
import { LATEST_MESSAGES_SEPARATOR } from '../constants';
import config from '../../config';
import type { Messages } from 'znipe-chat/src/utils/chatStore';

const baseUrl = `${config.QUARTERBACK_API_URL}/v1/chat/latest-messages`;

type LatestMessage = {
  clientMessageId: string;
  serverMessageId: string;
  userId: string;
  channel: string;
  createdTimestamp: string;
  partnerId: string;
  username: string;
  color: string;
  avatarId: string;
  message: string;
};

type LatestMessagesResponse = {
  message: string;
  results: LatestMessage[];
};

export type ChatStoreInfo = {
  messageIds: string[];
  messages: Messages;
};

const getChatStoreState = (results: LatestMessage[]): ChatStoreInfo => {
  const messages = results.reduce(
    (acc, messageObject) => ({
      ...acc,
      [messageObject.serverMessageId]: {
        ...messageObject,
        timestamp: messageObject.createdTimestamp,
      },
    }),
    {} as Messages,
  );

  const allMessageIds = Object.keys(messages);

  const messageIds =
    allMessageIds?.length < 1 ? allMessageIds : [...allMessageIds, LATEST_MESSAGES_SEPARATOR];

  return { messageIds, messages };
};

const useInitialLoadCallback = () => {
  const { chatId, authToken, chatStore, accountInfo } = useChatContext();

  return useCallback(async () => {
    if (!chatId || !authToken || !chatStore) return [];
    const requestUrl = `${baseUrl}?channel=${chatId}&window=15m&limit=30`;
    const fetchOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${authToken}`,
      },
      method: 'GET',
    };
    const res = await fetch(requestUrl, fetchOptions);
    const { results } = (await res.json()) as LatestMessagesResponse;
    chatStore.setAccount(accountInfo);
    chatStore.loadChat(getChatStoreState(results));

    return results;
  }, [authToken, chatId, chatStore, accountInfo]);
};

export default useInitialLoadCallback;
