import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { XSMALL, SMALL, MEDIUM, LARGE, XLARGE, CIRCLE, SQUARE } from 'znipe-constants/loading';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import ImageSkeleton from 'znipe-elements/feedback/ImageSkeleton/ImageSkeleton';
import { Card, TextWrapper, ImageWrapper, IconCompensation } from './CardLoading.styles';
import { CHAMPION, LOGO, STREAM_SELECTOR } from './CardLoading.constants';

const getCardSizes = (cardType, cardSize) => {
  if (cardType === STREAM_SELECTOR) {
    return {
      lineTwoHeight: XSMALL,
      textWrapperWidth: 40,
    };
  }
  if (cardType === CHAMPION) {
    switch (cardSize) {
      case MEDIUM:
        return {
          imageSize: 80,
          lineOneHeight: XLARGE,
          lineTwoHeight: MEDIUM,
          textWrapperWidth: 155,
        };
      case SMALL:
        return {
          imageSize: 72,
          lineOneHeight: MEDIUM,
          lineTwoHeight: MEDIUM,
          textWrapperWidth: 123,
        };
      default:
        return {
          imageSize: 64,
          lineOneHeight: MEDIUM,
          lineTwoHeight: MEDIUM,
          textWrapperWidth: 91,
        };
    }
  }
  switch (cardSize) {
    case MEDIUM:
      return {
        imageSize: 128,
        lineTwoHeight: XLARGE,
        textWrapperWidth: 110,
      };
    case SMALL:
      return {
        imageSize: 96,
        lineTwoHeight: LARGE,
        textWrapperWidth: 92,
      };
    default:
      return {
        imageSize: 72,
        lineTwoHeight: MEDIUM,
        textWrapperWidth: 60,
      };
  }
};

const CardLoading = ({ type = LOGO, size = SMALL }) => {
  const imageShape = useMemo(() => (type === CHAMPION ? CIRCLE : SQUARE), [type]);
  const { imageSize, lineOneHeight, lineTwoHeight, textWrapperWidth } = useMemo(
    () => getCardSizes(type, size),
    [type, size],
  );
  const textGap = useMemo(() => (size === XSMALL ? 7 : 15), [size]);

  return (
    <Card data-testid="card-loading" $size={size} $type={type}>
      <TextWrapper $width={textWrapperWidth} $textGap={textGap} $type={type}>
        {type === CHAMPION && <TextSkeleton size={lineOneHeight} />}
        <TextSkeleton size={lineTwoHeight} />
      </TextWrapper>
      {type !== STREAM_SELECTOR && (
        <ImageWrapper>
          <ImageSkeleton shape={imageShape} size={imageSize} />
          {type === CHAMPION && <IconCompensation $size={size} />}
        </ImageWrapper>
      )}
    </Card>
  );
};

CardLoading.propTypes = {
  type: PropTypes.oneOf([CHAMPION, LOGO, STREAM_SELECTOR]),
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM]),
};

export default CardLoading;
