import { useEffect } from 'react';
import { fromEvent, of } from 'rxjs';
import { map, exhaustMap } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import LOLEpicMonstersSpawnRules from 'znipe-utils/misc/LOLEpicMonstersSpawnRules';
import { SEQUENTIAL_EVENT } from 'tv-reducers/intensive';

export const SUBSCRIBE_SEQUENTIAL = 'SUBSCRIBE_SEQUENTIAL';
export const UNSUBSCRIBE_SEQUENTIAL = 'UNSUBSCRIBE_SEQUENTIAL';

export const useSequentialEpic = (matchId, sequential) => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId || !sequential) return undefined;
    dispatch({ type: SUBSCRIBE_SEQUENTIAL, gatId, sequential });
    return () => dispatch({ type: UNSUBSCRIBE_SEQUENTIAL, gatId, sequential });
  }, [dispatch, gatId, sequential]);
};

const keys = {
  dragonQueue: {
    stopTime: 'nextDragonSpawnTime',
    type: 'nextDragonName',
  },
};

const createId = (gatId, sequential, index) => `${gatId}:${sequential}:${index}`;
const createSequentialEntry = (entry, gatId, sequential) => {
  const { stopTime, type, startTime } = keys[sequential] ?? {};
  const gameTime = Math.floor(entry.gameTime / 1000);

  return {
    id: createId(gatId, sequential, entry.sequenceIndex),
    startTime: entry[startTime] ?? entry.startTime ?? gameTime,
    stopTime: entry[stopTime] ?? entry.stopTime,
    type: entry[type] ?? entry.type,
  };
};

const emptyObject = {};
const emptyArray = [];
// This a solution until we actually get this data into the parsed data
const spawnRules = LOLEpicMonstersSpawnRules.filter(
  ({ monsterType }) => monsterType === 'baron' || monsterType === 'riftHerald',
).sort((a, b) => a.firstSpawn - b.firstSpawn);
const findNextRule = gameTime =>
  spawnRules.find(rule => {
    const { firstSpawn, timeToRespawn, noRespawnAfter } = rule;
    if (gameTime + timeToRespawn / 1000 >= noRespawnAfter / 1000) return false;
    if (gameTime + timeToRespawn / 1000 <= firstSpawn / 1000) return false;
    return true;
  });

export const calculateBaronQueue = (state, gatId, sequential) => {
  const gat = state?.gats?.[gatId] ?? emptyObject;
  const { baronQueue = [] } = gat;

  const monsterKills =
    gat.monsterKills?.map(id => state?.monsterKills[id] ?? emptyObject) ?? emptyArray;
  const baronKills = monsterKills.filter(({ type }) => type === 'baron' || type === 'riftHerald');

  const newEntries = [];
  if (baronQueue.length === 0) {
    for (let i = 0; i < spawnRules.length; i++) {
      const { firstSpawn, monsterType } = spawnRules[i];
      const { noRespawnAfter = 0 } = spawnRules[i - 1] ?? emptyObject;
      const entry = {
        startTime: noRespawnAfter / 1000,
        stopTime: firstSpawn / 1000,
        type: monsterType,
        sequenceIndex: i,
      };
      newEntries.push(entry);
    }
  }

  baronKills.forEach(kill => {
    const { gameTime: killTime } = kill;
    const rule = findNextRule(killTime);
    const { monsterType, timeToRespawn, firstSpawn } = rule;
    const startTime = Math.floor(killTime);
    const firstSpawnTime = firstSpawn / 1000;
    const firstTime = startTime < firstSpawnTime;
    const sequenceIndex = firstTime ? spawnRules.indexOf(rule) : startTime;
    const id = createId(gatId, sequential, sequenceIndex);
    const addAnyways = firstTime && state?.sequentials?.[id]?.startTime !== startTime;
    if (!addAnyways && baronQueue.includes(id)) return;
    const stopTime = firstTime ? firstSpawnTime : startTime + timeToRespawn / 1000;
    const entry = {
      startTime,
      stopTime,
      type: monsterType,
      sequenceIndex,
    };
    if (firstTime) {
      // replace the one previously created
      const index = newEntries.findIndex(
        ({ sequenceIndex: si, type }) => si === sequenceIndex && type === monsterType,
      );
      if (index >= 0) {
        newEntries[index] = entry;
        return;
      }
    }
    newEntries.push(entry);
  });

  return of(...newEntries);
};
// Baron hack end

const sequentialEpic = factorySubscribeEpic(
  [SUBSCRIBE_SEQUENTIAL, UNSUBSCRIBE_SEQUENTIAL],
  ({ gatId, sequential }) => `${gatId}-${sequential}`,
  ({ gatId, sequential }, state$) => {
    if (sequential === 'baronQueue') {
      return state$.pipe(exhaustMap(state => calculateBaronQueue(state, gatId, sequential)));
    }

    return fromEvent(
      firebase.database().ref(`/stats/${gatId}/sequential/${sequential}`),
      'child_added',
    ).pipe(map(([snap]) => snap.val()));
  },
  ({ gatId, sequential }) =>
    payload => ({
      type: SEQUENTIAL_EVENT,
      gatId,
      payload: {
        id: gatId,
        [sequential]: payload.map(entry => createSequentialEntry(entry, gatId, sequential)),
      },
    }),
);

export default sequentialEpic;
