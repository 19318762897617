import { useMemo } from 'react';
import type { ReplyTo } from '../../../utils/chatStore';

const useUsersearchQuery = (message: string, replyData?: ReplyTo) =>
  useMemo(() => {
    if (!message.length) return '';
    if (replyData?.username) return '';

    if (message.indexOf('@') > -1 && !replyData?.username) {
      return message.split('@')[1];
    }

    return '';
  }, [message, replyData?.username]);

export default useUsersearchQuery;
