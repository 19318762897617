import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsCompactPlayer } from 'tv-selectors/ui/makeGetIsCompactPlayer';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import { VIEW_DEFAULT } from '../../constants';
import { Content, MainContent, Sidebar } from '../../ScreenOverlay.styles';

const ScreenControlsDesktop = ({
  view = '',
  children = [],
  onStreamClick,
  onViewClose,
  // onStatsClick,
  // onHighlightClick,
  // onRoomClick,
}) => {
  const isCompactPlayer = useGetIsCompactPlayer();

  const sidebarItems = useMemo(
    () => [
      /*    {
      iconType: 'stats',
      label: 'Stats',
      showLabel: true,
      viewId: 'stats',
      onClick: onStatsClick,
    }, */
      {
        iconType: 'stream',
        label: 'Streams',
        showLabel: true,
        viewId: 'streams',
        onClick: onStreamClick,
      },
      /*    {
      iconType: 'highlights',
      label: 'Highlights',
      showLabel: true,
      viewId: 'highlights',
      onClick: onHighlightClick,
    },
    {
      iconType: 'room',
      label: 'Rooms',
      showLabel: true,
      viewId: 'rooms',
      onClick: onRoomClick,
    }, */
    ],
    [onStreamClick],
  );

  const availableNavItems = useMemo(() => {
    if (!isCompactPlayer) return sidebarItems;
    return sidebarItems.filter(({ viewId }) => viewId === 'streams');
  }, [isCompactPlayer, sidebarItems]);
  const numberOfNavItems = availableNavItems.length;

  return (
    <Content data-testid="desktop-screen-controls">
      <Sidebar
        data-testid="sidebar"
        numberOfElements={numberOfNavItems}
        isCompactPlayer={isCompactPlayer}
        hideOnExtraLargeScreen={!isCompactPlayer}
      >
        {availableNavItems.map(({ viewId, iconType, label, showLabel, onClick }) => (
          <NavButton
            key={label}
            iconType={iconType}
            label={label}
            showLabel={showLabel}
            onClick={onClick}
            isToggledOn={view === viewId}
            size="large"
            type={isCompactPlayer ? 'horizontal' : undefined}
          />
        ))}
      </Sidebar>
      <MainContent justifyContent="flex-end">{children}</MainContent>
      <Sidebar data-testid="sidebar-right" numberOfElements={1}>
        {view !== VIEW_DEFAULT && (
          <NavButton iconType="close" label="Close" showLabel size="large" onClick={onViewClose} />
        )}
      </Sidebar>
    </Content>
  );
};

ScreenControlsDesktop.propTypes = {
  view: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onStreamClick: PropTypes.func.isRequired,
  onViewClose: PropTypes.func.isRequired,
  // onStatsClick: PropTypes.func.isRequired,
  // onHighlightClick: PropTypes.func.isRequired,
  // onRoomClick: PropTypes.func.isRequired,
};

export default ScreenControlsDesktop;
