import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetUIMap from './makeGetUIMap';

const makeGetDebug = () => {
  const getUIMap = makeGetUIMap();
  return createSelector([getUIMap], ui => ui.debug ?? false);
};

export const useDebug = () => useMemoizedSelector(makeGetDebug);

export default makeGetDebug;
