import { schema } from 'normalizr';
import player from './player.normalizr';

const kill = new schema.Entity(
  'kills',
  {
    actor: player,
    subject: player,
  },
  {
    processStrategy: ({
      actor: { location: actorLocation, ...actor },
      subject: { location: subjectLocation, ...subject },
      ...rest
    }) => ({
      ...rest,
      actor,
      subject,
      actorLocation,
      subjectLocation,
    }),
  },
);

export default kill;
