import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import { Wrapper, Text, themes } from './WarningMessage.styles';

const WarningMessage = ({ message }) => {
  if (message === '') {
    return null;
  }
  return (
    <Wrapper data-testid="warning-message">
      <div data-testid="icon-wrapper">
        <Icon type="warningTriangle" size={28} />
      </div>
      {message && <Text>{message}</Text>}
    </Wrapper>
  );
};

WarningMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default withTheme(WarningMessage, themes, 'warningMessage');
