import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

const ThemeLarge = ({ theme }) => css`
  width: ${theme.avatarLargeSize || '60px'};
  height: ${theme.avatarLargeSize || '60px'};
`;

const ThemeMedium = ({ theme }) => css`
  width: ${theme.avatarMediumSize || '48px'};
  height: ${theme.avatarMediumSize || '48px'};
`;

const ThemeSmall = ({ theme }) => css`
  width: ${theme.avatarSmallSize || '40px'};
  height: ${theme.avatarSmallSize || '40px'};
`;

const ThemeXSmall = ({ theme }) => css`
  width: ${theme.avatarXSmallSize || '30px'};
  height: ${theme.avatarXSmallSize || '30px'};
`;

const ThemeCustom = ({ theme }) => css`
  width: ${theme.avatarCustomSize || '45px'};
  height: ${theme.avatarCustomSize || '45px'};
`;

const ThemeLargeRound = ({ theme }) => css`
  width: ${theme.avatarLargeSizeRound || '54px'};
  height: ${theme.avatarLargeSizeRound || '54px'};
`;

const ThemeMediumRound = ({ theme }) => css`
  width: ${theme.avatarMediumSizeRound || '44px'};
  height: ${theme.avatarMediumSizeRound || '44px'};
`;

const ThemeSmallRound = ({ theme }) => css`
  width: ${theme.avatarSmallSizeRound || '34px'};
  height: ${theme.avatarSmallSizeRound || '34px'};
`;

const ThemeXSmallRound = ({ theme }) => css`
  width: ${theme.avatarSmallSizeRound || '24px'};
  height: ${theme.avatarSmallSizeRound || '24px'};
`;

const getBorderWeight = size => {
  switch (size) {
    case 'large':
      return '3px';
    case 'medium':
      return '3px';
    default:
      return '2px';
  }
};

const getSize = (size, shape) => {
  const isRound = shape === 'round';
  switch (size) {
    case 'large':
      return isRound ? ThemeLargeRound : ThemeLarge;
    case 'medium':
      return isRound ? ThemeMediumRound : ThemeMedium;
    case 'small':
      return isRound ? ThemeSmallRound : ThemeSmall;
    case 'xsmall':
      return isRound ? ThemeXSmallRound : ThemeXSmall;
    case 'custom':
      return isRound ? ThemeMediumRound : ThemeCustom;
    default:
      return isRound ? ThemeLargeRound : ThemeLarge;
  }
};

export const ImageContainer = styled.div`
  ${({ size, $shape }) => getSize(size, $shape)}
  border-radius: ${props => props.$shape === 'round' && '100%'};
  ${({ isPlaceholder, withBorder, size, highlight, theme }) =>
    !isPlaceholder &&
    withBorder &&
    css`
      border-width: ${getBorderWeight(size)};
      border-style: solid;
      border-color: ${highlight ? theme.avatarBorderActiveColor : theme.borderColor};
    `}
  overflow: hidden;
`;

export const AvatarWrapper = styled.div`
  img {
    ${props => props.$shape === 'round' && props.size === 'large' && ThemeLargeRound}
    ${props => props.$shape === 'round' && props.size === 'medium' && ThemeMediumRound}
    ${props => props.$shape === 'round' && props.size === 'small' && ThemeSmallRound}
    ${props => props.$shape === 'round' && props.size === 'xsmall' && ThemeXSmallRound}
    ${props => props.$shape === 'round' && props.size === 'custom' && ThemeMediumRound}
    ${props =>
      props.dimmed &&
      css`
        opacity: 0.45;
      `}
  }

  svg {
    ${props =>
      props.$shape === 'round' &&
      css`
        border-radius: 100%;
      `}
    ${props =>
      props.withBorder &&
      css`
        border: 3px solid
          ${props.highlight ? props.theme.avatarBorderActiveColor : props.theme.borderColor};
      `}
  }
`;

const LevelCircleLarge = () => css`
  width: 20px;
  height: 20px;
  font-size: 12px;
`;

const LevelCircleMedium = () => css`
  width: 18px;
  height: 18px;
  font-size: 11px;
`;

const LevelCircleXSmall = () => css`
  width: 15px;
  height: 15px;
  font-size: 9px;
`;

export const LevelBadge = styled.div`
  font-family: ${props => props.theme.avatarLevelFont};
  position: absolute;
  color: ${props => props.theme.avatarLevelTextColor};
  ${props =>
    props.type === 'circle' &&
    props.shape === 'round' &&
    css`
      bottom: -2px;
      ${props.position === 'left' ? 'left: 0' : 'right: 0'};
      ${props.size === 'large' && LevelCircleLarge}
      ${props.size === 'medium' && LevelCircleMedium}
      ${props.size === 'custom' && LevelCircleMedium}
      ${props.size === 'small' && LevelCircleMedium}
      ${props.size === 'xsmall' && LevelCircleXSmall}
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props.theme.avatarLevelCircleBackgroundColor};
      border-width: 1px;
      border-style: solid;
      border-color: ${props.theme.avatarLevelCircleBorderColor};
      border-image: initial;
      border-radius: 100%;
    `}
  ${props =>
    props.type === 'circle' &&
    props.shape === 'square' &&
    css`
      bottom: -4px;
      ${props.position === 'left' ? 'left: -4px' : 'right: -4px'};
      ${props.size === 'large' && LevelCircleLarge}
      ${props.size === 'medium' && LevelCircleMedium}
      ${props.size === 'custom' && LevelCircleMedium}
      ${props.size === 'small' && LevelCircleMedium}
      ${props.size === 'xsmall' && LevelCircleXSmall}
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props.theme.avatarLevelCircleBackgroundColor};
      border-width: 1px;
      border-style: solid;
      border-color: ${props.theme.avatarLevelCircleBorderColor};
      border-image: initial;
      border-radius: 100%;
      font-style: italic;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.8);
    `}
  ${props =>
    props.type === 'default' &&
    props.size !== 'small' &&
    css`
      bottom: 4px;
      ${props.position === 'left' ? 'left: 4px' : 'right: 4px'};
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
    `}

  ${props =>
    props.type === 'default' &&
    props.size === 'small' &&
    css`
      position: absolute;
      bottom: 2px;
      ${props.position === 'left' ? 'left: 2px' : 'right: 2px'};
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
    `}
`;

export const LevelNumber = styled.div`
  line-height: 1;
  letter-spacing: 0.07em;
`;

const getIconPosition = ({ position, offset }) => {
  if (!position)
    return css`
      left: 50%;
      bottom: -${offset}px;
    `;

  switch (position) {
    case 'left':
      return css`
        bottom: 0px;
        left: 0%;
      `;
    case 'right':
    default:
      return css`
        bottom: 0px;
        left: 100%;
      `;
  }
};

export const IconWrapper = styled.div`
  position: absolute;
  transform: translate(-50%);
  ${props => getIconPosition(props)};
`;
