import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
  overflow-x: hidden;
  padding: ${props => (props.showTeamsBackgrounds ? 0 : '0 24px 24px 24px')};
  background: ${props => props.theme.bg2Fade};
  ${props =>
    props.size === 'small' &&
    css`
      > div:not(:last-child) {
        margin-bottom: 24px;
      }
    `}
`;

export const LoadingContentContainer = styled(Container)`
  justify-content: center;
  padding: 24px;
  > div:first-of-type {
    margin-bottom: 27px;
  }
  > div:last-of-type {
    margin-top: 17px;
    margin-bottom: 0;
    @media ${device.desktop} {
      margin-top: 48px;
    }
  }
  &:hover,
  &:focus {
    cursor: default;
  }
`;

export const StatusLabelWrapper = styled.div`
  position: relative;
  min-height: 15px;
  min-width: 45px;
`;

export const TeamsInfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${props => (props.size === 'large' ? 'auto auto 24px auto' : '0')};
`;

export const TeamSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
  overflow: hidden;
  ${props =>
    props.showTeamsBackgrounds &&
    css`
      position: relative;
      width: 100%;
      @media ${device.desktop} {
        min-height: 180px;
      }
    `};
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto auto auto;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
  > a {
    color: ${colors.white};
  }
`;

export const DescriptionWrapper = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  text-align: center;
`;

export const TeamImageWrapper = styled.div`
  position: absolute;
  ${props => css`
    ${props.position}: -90px;
  `}
  width: 180px;
  height: 180px;
  opacity: 0.2;
`;

export const TeamImage = styled.img`
  width: 100%;
  height: 100%;
`;
