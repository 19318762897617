import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const getTypeFromProps = (_, props) => props.topicType;

const makeGetTopicId = () => {
  const getPage = makeGetPage();
  return createSelector([getPage, getTypeFromProps], (page, type) => page?.[type]?.[0] ?? '');
};

export const useGetTopicId = props => useMemoizedGqlSelector(makeGetTopicId, props);

export default makeGetTopicId;
