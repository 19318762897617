/**
 * @summary Create an Event work-alike object based on the provided dictionary.
 * The event should contain all of the same properties from the dict.
 */
class ZnipeEvent extends Event {
  stopped = false;

  constructor(type: string, dict: Record<string, unknown> = {}) {
    super(type, dict);
    if (!type) throw Error('ZnipeEvent requires a type');
    if (dict.type) throw Error('Type is reserved value of ZnipeEvent');
    Object.entries(dict).forEach(([key, value]) => {
      Object.defineProperty(this, key, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value,
        writable: true,
        enumerable: true,
      });
    });

    this.stopped = false;
  }

  stopImmediatePropagation = () => {
    this.stopped = true;
  };
}

export default ZnipeEvent;
