import { memo } from 'react';
import PropTypes from 'prop-types';
import Matchup from 'tv-modules/Standings/Matchup/Matchup';
import Typography from 'znipe-elements/general/Typography/Typography';
import { teamShape } from 'tv-modules/Standings/Bracket/utils/constants';
import { SubListContainer, Title, ItemsWrapper } from '../CompactBracket.styles';

const SubList = ({ matchups = [], title = '', selectedTeams = [] }) => {
  if (matchups.length === 0) return null;
  return (
    <SubListContainer>
      {title !== '' && (
        <Title data-testid="sublist-title">
          <Typography type="title-s">{title}</Typography>
        </Title>
      )}
      <ItemsWrapper data-testid="items-wrapper">
        {matchups.map(matchup => {
          const { teamOne, teamTwo, winner } = matchup;
          return (
            <Matchup
              key={matchup.id}
              teamOne={teamOne}
              teamTwo={teamTwo}
              winner={winner}
              selectedTeams={selectedTeams}
              matchId={matchup.matchId}
            />
          );
        })}
      </ItemsWrapper>
    </SubListContainer>
  );
};

SubList.propTypes = {
  matchups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      teamOne: PropTypes.shape(teamShape),
      teamTwo: PropTypes.shape(teamShape),
      winner: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default memo(SubList);
