import SadPoro from 'tv-modules/Subscription/images/SadPoro';

export default {
  proview: {
    successImage: SadPoro,
    errorImage: SadPoro,
  },
  default: {
    successImage: SadPoro,
    errorImage: SadPoro,
  },
};
