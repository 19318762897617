import { createSelector } from 'reselect';
import makeGetState from 'tv-selectors/makeGetState';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductsMap = () => {
  const getState = makeGetState();
  return createSelector([getState], state => state.products || {});
};

export const useGetProductsMap = () => useMemoizedSelector(makeGetProductsMap);

export default makeGetProductsMap;
