import { memo, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useGetAuthSubscriptionByProductId } from 'tv-selectors/auth/makeGetAuthSubscriptionByProductId';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import PaymentSummary from 'tv-modules/Subscription/PaymentSummary/PaymentSummary';
import MembershipStatus from 'tv-modules/Subscription/MembershipStatus/MembershipStatus';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Icon from 'znipe-elements/general/Icon/Icon';
import Link from 'znipe-elements/general/Link/Link';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import WarningMessage from 'znipe-elements/data-display/WarningMessage/WarningMessage';
import { Container, Padding, ContentWrapper, List, Item, ButtonWrapper } from './MyProView.styles';
import themes from './MyProView.themes';

export const redeemPromoCodeLink = { icon: 'heart', text: 'Redeem promo code', to: `/redeem` };
export const updatePaymentMethodLink = {
  icon: 'creditCard',
  text: 'Update payment method',
  to: `/payment`,
};
export const billingLink = { icon: 'clock', text: 'View billing history', to: `/billing` };

export const resubscribeLink = {
  icon: 'creditCard',
  text: 'Resubscribe',
  to: `/subscribe`,
};

const MyProView = ({ productId, baseUrl }) => {
  const { packageSlug } = useParams();
  const { myProView = {} } = useThemeContext();
  const [loading, setLoading] = useState(true);
  const [showSubDetails, setShowSubDetails] = useState(false);
  const productSubscription = useGetAuthSubscriptionByProductId({ productId });
  const isMissingSubscription = Object.keys(productSubscription).length < 1;
  const hasCancelled = productSubscription.canceled || false;
  const billingEndDate = productSubscription.currentBillingEnd;
  const subscriptionExpired = Date.parse(billingEndDate) < Date.now();
  const invoiceIsPastDue = productSubscription.invoiceIsPastDue || false;

  useEffect(() => {
    setShowSubDetails(() => {
      const userHasErrors =
        invoiceIsPastDue ||
        hasCancelled ||
        isMissingSubscription ||
        subscriptionExpired ||
        !billingEndDate;
      return !userHasErrors;
    });
    setLoading(false);
  }, [invoiceIsPastDue, hasCancelled, isMissingSubscription, subscriptionExpired, billingEndDate]);

  const linksToRender = useMemo(() => {
    if (hasCancelled || isMissingSubscription || subscriptionExpired) {
      return [resubscribeLink, billingLink];
    }
    return [redeemPromoCodeLink, updatePaymentMethodLink, billingLink];
  }, [hasCancelled, isMissingSubscription, subscriptionExpired]);

  const hasAccountWarning = invoiceIsPastDue || subscriptionExpired || !billingEndDate;

  if (!productId || loading) return <LoadingSpinner fillColor="black" />;

  return (
    <Container>
      {hasCancelled && <MembershipStatus productId={productId} hasCancelled />}

      {hasAccountWarning && (
        <WarningMessage message="Your subscription has been paused because last payment has been declined or your subscription expired. Please update your payment method below." />
      )}

      {showSubDetails && (
        <>
          <Padding>
            <MembershipStatus productId={productId} />
          </Padding>
          <PaymentSummary productId={productId} />
        </>
      )}

      <ContentWrapper>
        <List data-testid="links-list">
          {linksToRender?.map(item => {
            const packageBase = packageSlug ? `/${packageSlug}` : '';
            const url = item.to.indexOf('/subscribe') !== -1 ? packageBase : baseUrl;
            return (
              <Item key={item.icon} data-testid="link">
                <Link to={`${url}${item.to}`} withIcon>
                  <Icon type={item.icon} fillColor={myProView.iconColor} size={24} inline />
                  {item.text}
                </Link>
              </Item>
            );
          })}
        </List>
        {showSubDetails && (
          <ButtonWrapper data-testid="cancel-button">
            <Button variant="text" size="small" textColor="grey30" to={`${baseUrl}/cancel`}>
              Cancel subscription
            </Button>
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};

MyProView.propTypes = {
  productId: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default withTheme(memo(MyProView), themes, 'myProView');
