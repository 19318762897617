import { useCallback, useMemo } from 'react';
import colors from 'znipe-styles/colors';
import isTouchDevice from 'znipe-utils/web/isTouchDevice';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import UserLabel from '../../UserLabel/UserLabel';
import EmojiTile from '../../Emojis/EmojiTile/EmojiTile';
import {
  MessageWrapper,
  Timestamp,
  ReplyTarget,
  ReplyTagWrapper,
  MessageHtml,
  EmojiContainer,
} from './Message.styles';
import {
  EMOJI_TAG_REGEX,
  generateTextToSpan,
  getActionHandledMessage,
  parseEmoji,
  stripHtmlFromMessage,
} from './Message.utils';
import { ACTION_TYPES, REMOVED, REPORTED } from '../../../constants';
import type { ReplyTo } from '../../../utils/chatStore';

type MessageProps = {
  serverMessageId?: string;
  username?: string;
  userId?: string;
  timestamp?: string;
  message: string;
  color?: string;
  isModeratorMessage?: boolean;
  isModeratorUser?: boolean;
  signifierIds?: string[];
  badges?: string[];
  appliedAction?: (typeof ACTION_TYPES)[number];
  deleted?: boolean;
  replyData?: ReplyTo | null;
  messageId?: string;
};

const Message: React.FC<MessageProps> = ({
  serverMessageId = '',
  username,
  userId,
  color,
  isModeratorMessage = false,
  isModeratorUser = false,
  signifierIds,
  badges,
  message,
  timestamp,
  replyData,
  deleted = false,
  appliedAction,
  messageId,
}) => {
  const { username: replyTargetName, color: replyColor } = replyData || {};
  const { allEmojis } = useChatContext();

  const emojiImage = useCallback(
    (emoji: string) => {
      const { url } = allEmojis?.find(({ shortcode }) => shortcode === emoji) ?? { url: undefined };
      return url;
    },
    [allEmojis],
  );

  const replaceComponent = useCallback(
    (text: string, target: RegExp) => {
      const makeComponent = (emoji: string, url: string, index: number) => (
        <EmojiContainer data-testid="emoji-container" key={emoji + index + serverMessageId}>
          <EmojiTile src={url} size="small" label={emoji} customAlt={`:${emoji}:`} />
        </EmojiContainer>
      );

      let index = 0;

      return text
        .replace(/\s\s+/g, ' ')
        .split(target)
        .filter(x => x !== ' ')
        .filter(Boolean)
        .reduce<null | React.ReactNode[]>((acc, current) => {
          index += 1;
          const currentIsEmoji = current.match(EMOJI_TAG_REGEX);
          let content: string | undefined;
          let emojiUrl: string | undefined;
          if (currentIsEmoji) {
            content = current.split(':').join('');
            emojiUrl = emojiImage(content);
          }

          if (acc === null) {
            if (currentIsEmoji && emojiUrl && content) {
              return [makeComponent(content, emojiUrl, index)];
            }
            return generateTextToSpan(current, serverMessageId);
          }

          if (currentIsEmoji && emojiUrl && content) {
            return [...acc, makeComponent(content, emojiUrl, index)];
          }

          return [...acc, ...generateTextToSpan(current, serverMessageId)];
        }, null);
    },
    [serverMessageId, emojiImage],
  );

  const cleanMessage = useMemo(() => {
    const actionHandledMessage = getActionHandledMessage(appliedAction, deleted, isModeratorUser);
    if (actionHandledMessage) return generateTextToSpan(actionHandledMessage, serverMessageId);

    const foundEmojis = parseEmoji(message.replace(/\s\s+/g, ' '));
    if (!foundEmojis.length)
      return generateTextToSpan(stripHtmlFromMessage(message), serverMessageId);

    return replaceComponent(stripHtmlFromMessage(message), EMOJI_TAG_REGEX);
  }, [appliedAction, deleted, isModeratorUser, message, replaceComponent, serverMessageId]);

  const dimmedText = useMemo(() => {
    if (isModeratorUser) return REMOVED === appliedAction || deleted;
    return [REMOVED, REPORTED].includes(appliedAction ?? '') || deleted;
  }, [appliedAction, deleted, isModeratorUser]);

  return (
    <MessageWrapper type={isTouchDevice() ? 'paragraph-m' : 'paragraph-s'} $dimmedText={dimmedText}>
      <MessageHtml tabIndex={0} data-testid="message-html">
        {timestamp && (
          <Timestamp color={colors.white} type="title-xs">
            {timestamp}
          </Timestamp>
        )}
        {username && (
          <UserLabel
            username={username}
            userId={userId}
            color={color}
            isModerator={isModeratorMessage}
            signifierIds={signifierIds}
            badges={badges}
            messageId={messageId}
          />
        )}
        {replyTargetName && replyColor && !dimmedText && (
          <ReplyTagWrapper>
            <ReplyTarget $color={replyColor} type="heading-xs">
              {`@${replyTargetName}`}
            </ReplyTarget>
          </ReplyTagWrapper>
        )}
        {cleanMessage}
      </MessageHtml>
    </MessageWrapper>
  );
};

export default Message;
