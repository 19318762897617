import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Logo = styled.svg`
  width: 150px;
  margin: 50px 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FreeMonthsInfo = styled.span`
  display: flex;
  align-items: center;
  margin-top: 45px;
  color: ${props => props.theme.payPalForm.freeMonthsInfoColor};
  font-size: 14px;
  line-height: 150%;
  text-align: center;
`;

export const TermsOfService = styled.div`
  margin: 20px 0;
`;

export const ButtonContainer = styled.div`
  position: relative;
  padding: 0;
  background: transparent;
  width: 250px;
  height: 35px;
  @media ${device.tablet} {
    width: 350px;
    height: 45px;
  }
`;
