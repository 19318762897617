import styled, { css } from 'styled-components';
import Arrow from 'znipe-elements/data-display/Markers/Arrows/Arrow.styles';

export const RoleIconWrapper = styled.div`
  text-align: center;
  height: fit-content;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const imgSize = {
  small: 32,
  medium: 42,
};

export const Image = styled.img``;

export const Info = styled.div<{ $reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;

export const GroupInfoWrapper = styled.div<{ $fixArrowPos?: boolean }>`
  display: flex;

  ${({ $fixArrowPos }) =>
    $fixArrowPos &&
    css`
      ${Arrow} {
        margin-bottom: 24px;
      }
    `}
`;

export const Group = styled.div`
  display: flex;
`;

const verticalCss = css`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  ${GroupInfoWrapper} {
    flex-direction: column;
  }

  ${Group} {
    flex-direction: column;
    gap: 8px;
  }
`;

const horizontalCss = css<{ $size: 'small' | 'medium' }>`
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${GroupInfoWrapper} {
    flex-direction: row;
    align-items: center;
  }

  ${Group} {
    flex-direction: row;
    gap: ${({ $size }) => ($size === 'small' ? 4 : 8)}px;
  }
`;

const cssTypes = {
  vertical: verticalCss,
  horizontal: horizontalCss,
};

export const Container = styled.div<{
  $type: 'vertical' | 'horizontal';
  $size: 'small' | 'medium';
}>`
  display: flex;
  ${({ $type }) => cssTypes[$type]}

  ${Image} {
    height: ${({ $size }) => imgSize[$size]}px;
    width: ${({ $size }) => imgSize[$size]}px;
  }
`;
