import subscribe from 'znipe-utils/web/subscribe';
import * as shaka from 'shaka-player';
import isBrowser from 'znipe-utils/web/isBrowser';
import AnalyticsManager from 'znipe-analytics-manager';
import queueEvent, { getCurrentProduct } from './utils/znipe-analytics-utils';

const UNKNOWN_POSITION = -1;
const isClient = isBrowser();

const getLocation = () => (isClient ? window.location.href : '');

class ShakaEventListeners {
  /**
   *
   * @param {Object} config - An object containing necessary Ids
   * @param {String} config.userSession - Id of the Apps session
   * @param {String} config.playbackSession - Id of the Znipe player
   * @param {String} config.streamSession - Id of the content session
   */
  constructor(config = {}, clientConfig = {}) {
    this.config = {
      ...config,
    };
    this.clientConfig = clientConfig;
    this.eventsApiUrl = clientConfig.ANALYTICS_EVENT_API_URL;
  }

  /**
   * @member config
   * @desc Object including necessary information for the analytics
   */
  config = {};

  /**
   * @member subscriptions
   * @desc Array of event listeners unsubscribe functions
   */
  subscriptions = [];

  /**
   * @member contentType
   * @desc Value describing current stream content type.
   */
  contentType = null;

  /**
   * @member shakaPlayerInstance
   * @desc The player instance passed
   */
  shakaPlayerInstance = null;

  /**
   * @member shakaVideoElement
   * @desc The instance of video element used for playing video
   */
  shakaVideoElement = null;

  /**
   * @member bytesDownloaded
   * @desc Bytes downloaded by the player.
   */
  bytesDownloaded = 0;

  /**
   * @member numberOfSeeksDone
   * @desc Number of seeks done in stream session.
   */
  numberOfSeeksDone = 0;

  /**
   * @member shakaStartCurrentTime
   * @desc mediaPosition of started event.
   */
  shakaStartCurrentTime = 0;

  /**
   * @member master
   * @desc Indicates if it's the main window that should handle the heartbeats
   */
  master = false;

  // The different states the player can be in.
  PlayerStateEnum = {
    Idle: 0,
    // Indicates that the player is initializing.
    Init: 1,
    // Indicates that the player is able to play, (i.e. content in buffer)
    Ready: 2,
    // Indicates that the player is playing video.
    Playing: 4,
    // Indicates that the player is paused.
    Pause: 8,
    // Indicates that the player is buffering.
    Rebuffer: 16,
    // Indicates that the session is completed with either error or successful playback.
    SessionComplete: 32,
  };

  /**
   * @member playerState
   * @desc Indicates the current state of the player. Refer "PlayerStateEnum", for possible values.
   */
  playerState = this.PlayerStateEnum.Idle;

  /**
   * @member currentTrackInfo
   * @desc The rendition track used by the player.
   */
  currentTrackInfo = null;

  /**
   * @member isSeeking
   * @desc Tells whether or not player is performing a seek operation
   */
  isSeeking = false;

  /**
   * @member isStarted
   * @desc Indicates whether or not player has done is initial buffering and is ready to start
   */
  isStarted = false;

  /**
   * @member heartBeatIntervalId
   * @desc Playback heartbeat interval id
   */
  heartbeatIntervalId;

  /**
   * @member analyticsManager
   * @desc Analytics manager instance
   */
  analyticsManager;

  /**
   * @function setData
   * @param {String} key  The key to be added
   * @param {String} value The data associated with the key
   * @summary Sets a value in the config that will be sent in
   */
  setData = (key, value) => {
    this.config[key] = value;
  };

  initializeLibrary = () => {
    this.playerState = this.PlayerStateEnum.Init;
  };

  onError = event => {
    if (event.severity === 2) {
      // @TODO Send error code, handleError(event.code);
      this.playerState = this.PlayerStateEnum.SessionComplete;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  convertSecondsToMilliseconds = time => {
    if (typeof time !== 'number') return time;
    return parseInt(time * 1000, 10);
  };

  onStateChanged = event => {
    switch (event.state) {
      case 'media-source':
        if (this.shakaVideoElement) return;
        this.shakaVideoElement = this.shakaPlayerInstance.getMediaElement();
        if (this.shakaVideoElement) {
          this.addVideoElementListeners();
        } else {
          // console.error('Video Element not found');
        }
        break;
      case 'detach':
        this.removeAllListeners();
        break;
      default:
    }
  };

  onAdaptation = () => {
    const tracks = this.shakaPlayerInstance.getVariantTracks();
    const currentTrack = tracks.find(
      track => track.active && track.id !== this.currentTrackInfo?.id,
    );
    if (!currentTrack) return;
    const { videoBandwidth, bandwidth, frameRate, height, width } = currentTrack;
    this.currentTrackInfo = currentTrack;
    const bitrate = videoBandwidth || bandwidth;
    const resolution = height && width ? `${width}x${height}` : '';
    const trackInfo = { bitrateKbps: Math.round(bitrate / 1000), fps: frameRate, resolution };
    const { packageName, isPremiumUser, master, ...config } = this.config;
    queueEvent(
      {
        ...config,
        mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
        type: 'formatchanged',
        metadata: {
          packageName,
          isPremiumUser,
          ma: master,
          ...trackInfo,
        },
      },
      this.analyticsManager,
      this.clientConfig.NODE_ENV,
    );
  };

  /**
   * Used by shaka container to signal start of video load.
   */
  sendLoadVideoEvent = sourceUrl => {
    const {
      streamId,
      regionId,
      matchId,
      videoOrigin,
      videoId,
      packageName,
      isPremiumUser,
      productId,
      playerTag,
      master,
      ...config
    } = this.config;

    queueEvent(
      {
        type: 'loadvideo',
        mediaPosition: UNKNOWN_POSITION,
        metadata: {
          productName: getCurrentProduct(this.clientConfig.PRODUCT_NAME),
          url: sourceUrl,
          productVersion: this.clientConfig.VERSION,
          streamId,
          matchId,
          videoId,
          videoOrigin,
          userAgent: isClient ? navigator.userAgent : '',
          referrer: isClient ? document.referrer : '',
          location: getLocation(),
          packageName,
          isPremiumUser,
          productId,
          playerTag,
          ma: master,
        },
        customerData: {
          regionId,
        },
        ...config,
      },
      this.analyticsManager,
      this.clientConfig.NODE_ENV,
    );
  };

  sendSeekedEvent = (seekedFrom, seekedTo, seekTrigger = 'unknown') => {
    // Don't send seeked events if we are not playing yet.
    if (this.playerState < this.PlayerStateEnum.Ready) return;
    const { packageName, isPremiumUser, master, ...config } = this.config;
    queueEvent(
      {
        type: 'seeked',
        mediaPosition: this.convertSecondsToMilliseconds(seekedTo),
        ...config,
        metadata: {
          seekedFromPosition: `${this.convertSecondsToMilliseconds(seekedFrom)}`,
          packageName,
          isPremiumUser,
          ma: master,
          seekTrigger,
        },
      },
      this.analyticsManager,
      this.clientConfig.NODE_ENV,
    );
    this.numberOfSeeksDone += 1;
  };

  /**
   * @function onReady
   * @summary Callback method called when player is ready to play buffered data.
   */
  onReady = () => {
    const { streamId, regionId, matchId, isPremiumUser, packageName, master, ...config } =
      this.config;
    if (!this.isStarted) {
      this.isStarted = true;
      this.shakaStartCurrentTime = this.shakaVideoElement.currentTime;
      queueEvent(
        {
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaStartCurrentTime),
          type: 'started',
          metadata: {
            contentType: this.contentType,
            isPremiumUser,
            location: getLocation(),
            packageName,
            ma: master,
          },
          ...config,
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
    }
    if (this.isSeeking) {
      if (this.numberOfSeeksDone < 1) {
        this.sendSeekedEvent(this.shakaStartCurrentTime, this.shakaVideoElement.currentTime);
      }
      this.isSeeking = false;
    }
    queueEvent(
      {
        type: 'ready',
        mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
        metadata: {
          isPremiumUser,
          packageName,
          liveEdgeOffsetMs: this.getLiveEdgeOffsetMs(),
          ma: master,
        },
        ...config,
      },
      this.analyticsManager,
      this.clientConfig.NODE_ENV,
    );
    this.playerState = this.PlayerStateEnum.Ready;
  };

  /**
   * @function onBuffering
   * @summary Callback method called when player can't keep up with playback due to buffering of data.
   */
  onBuffering = event => {
    if (event.buffering) {
      const { isPremiumUser, packageName, master, ...config } = this.config;
      this.playerState = this.PlayerStateEnum.Rebuffer;
      queueEvent(
        {
          type: 'buffering',
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
          metadata: {
            isPremiumUser,
            packageName,
            ma: master,
          },
          ...config,
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
    }
  };

  /**
   * @function onSeeking
   * @summary Callback method called when video is seeked.
   */
  onSeeking = () => {
    // This function now only sets seek state as we send events manually.
    // Shaka Player (for live) fires seek event before buffer and play events.
    if (
      this.PlayerStateEnum.Idle === this.playerState ||
      this.PlayerStateEnum.SessionComplete === this.playerState
    ) {
      this.initializeLibrary();
    } else if (this.playerState >= this.PlayerStateEnum.Ready) {
      this.isSeeking = true;
    }
  };

  /**
   * @function onPlaying
   * @summary Callback method called when video starts to play.
   */
  onPlaying = () => {
    if (this.playerState === this.PlayerStateEnum.Playing) return; // Don't send 2 playing events in a row
    if (this.PlayerStateEnum.Rebuffer === this.playerState) {
      const { isPremiumUser, packageName, master, ...config } = this.config;
      queueEvent(
        {
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
          type: 'playing',
          ...config,
          metadata: {
            isPremiumUser,
            packageName,
            ma: master,
          },
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
    } else if (this.PlayerStateEnum.Pause === this.playerState) {
      const { isPremiumUser, packageName, master, ...config } = this.config;
      queueEvent(
        {
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
          type: 'playing',
          ...config,
          metadata: {
            isPremiumUser,
            packageName,
            ma: master,
          },
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
    } else if (this.playerState < this.PlayerStateEnum.Ready) {
      const { streamId, regionId, matchId, isPremiumUser, packageName, master, ...config } =
        this.config;
      this.shakaStartCurrentTime = this.shakaVideoElement.currentTime;
      queueEvent(
        {
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaStartCurrentTime),
          type: 'started',
          metadata: {
            productName: getCurrentProduct(this.clientConfig.PRODUCT_NAME),
            location: getLocation(),
            productVersion: this.clientConfig.VERSION,
            contentType: this.contentType,
            streamId,
            matchId,
            isPremiumUser,
            packageName,
            ma: master,
          },
          customerData: {
            regionId,
          },
          ...config,
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
    }
    this.playerState = this.PlayerStateEnum.Playing;
  };

  /**
   * @function onPause
   * @summary Callback method on video pause.
   */
  onPause = () => {
    // Seek can cause pause to occur
    if (
      !this.shakaVideoElement.seeking &&
      this.playerState !== this.PlayerStateEnum.SessionComplete
    ) {
      const { isPremiumUser, packageName, master, ...config } = this.config;
      queueEvent(
        {
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
          type: 'paused',
          ...config,
          metadata: {
            isPremiumUser,
            packageName,
            ma: master,
          },
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
      this.playerState = this.PlayerStateEnum.Pause;
    }
  };

  /**
   * @function onEnded
   * @summary Callback method on video end.
   */
  onEnded = () => {
    if (
      this.PlayerStateEnum.SessionComplete !== this.playerState &&
      this.playerState > this.PlayerStateEnum.Init
    ) {
      const { isPremiumUser, packageName, master, ...config } = this.config;
      queueEvent(
        {
          mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
          type: 'ended',
          metadata: {
            isPremiumUser,
            packageName,
            ma: master,
          },
          ...config,
        },
        this.analyticsManager,
        this.clientConfig.NODE_ENV,
      );
      this.playerState = this.PlayerStateEnum.SessionComplete;
    }
  };

  getLiveEdgeOffsetMs = () =>
    this.contentType === 'live'
      ? Math.floor(
          (this.shakaPlayerInstance.seekRange().end - this.shakaVideoElement.currentTime) * 1000,
        )
      : undefined;

  sendHeartbeatEvent = () => {
    const {
      streamId,
      matchId,
      videoId,
      packageName,
      isPremiumUser,
      master,
      isFullscreen,
      initializedDate,
      ...config
    } = this.config;

    queueEvent(
      {
        type: 'heartbeat',
        mediaPosition: this.convertSecondsToMilliseconds(this.shakaVideoElement.currentTime),
        metadata: {
          matchId,
          videoId,
          packageName,
          isPremiumUser,
          location: getLocation(),
          liveEdgeOffsetMs: this.getLiveEdgeOffsetMs(),
          pa: this.shakaVideoElement.paused,
          mu: this.shakaVideoElement.muted,
          fo: isClient ? document.hasFocus() : false,
          vi: isClient ? document.visibilityState === 'visible' : false,
          ma: master,
          fu: isFullscreen,
          et: initializedDate ? Date.now() - initializedDate : undefined,
        },
        ...config,
      },
      this.analyticsManager,
      this.clientConfig.NODE_ENV,
    );
  };

  addVideoElementListeners = () => {
    if (this.shakaVideoElement) {
      this.subscriptions.push(subscribe(this.shakaPlayerInstance, 'buffering', this.onBuffering));
      this.subscriptions.push(subscribe(this.shakaVideoElement, 'seeking', this.onSeeking));
      this.subscriptions.push(subscribe(this.shakaVideoElement, 'playing', this.onPlaying));
      this.subscriptions.push(subscribe(this.shakaVideoElement, 'pause', this.onPause));
      this.subscriptions.push(subscribe(this.shakaVideoElement, 'ended', this.onEnded));
      this.subscriptions.push(subscribe(this.shakaVideoElement, 'canplay', this.onReady));
    }
  };

  handleHeartbeat = (resetTimer = false) => {
    if (resetTimer) {
      clearInterval(this.heartbeatIntervalId);
      this.heartbeatIntervalId = null;
    }
    if (this.heartbeatIntervalId || !this.contentType) return;
    const heartbeatPeriodSeconds = this.contentType === 'live' ? 20 : 300;
    this.sendHeartbeatEvent();
    this.heartbeatIntervalId = setInterval(this.sendHeartbeatEvent, heartbeatPeriodSeconds * 1000);
  };

  onStreamInfoReady = () => {
    const isLive = this.shakaPlayerInstance.isLive();
    const deliveryType = isLive ? 'live' : 'vod';
    if (!this.contentType || !['live', 'vod', 'highlight'].includes(this.contentType)) {
      this.contentType = deliveryType;
    }
    this.handleHeartbeat();

    // this.mediaAnalyticsLibrary.setStreamURL(this.shakaPlayerInstance.getAssetUri(), true);
  };

  onLoad = () => {
    this.initializeLibrary();
  };

  downloadFilter = (type, response) => {
    if (type !== shaka.net.NetworkingEngine.RequestType.SEGMENT) {
      return;
    }
    // Log that segment has finished downloading.
    this.bytesDownloaded += response.data.byteLength;
  };

  /**
   * @function removeAllListeners
   * @summary Removes all the event listeners
   */
  removeAllListeners = () => {
    this.onEnded();

    this.subscriptions.forEach(subscription => subscription());

    this.shakaPlayerInstance?.getNetworkingEngine()?.unregisterResponseFilter(this.downloadFilter);
    this.bytesDownloaded = 0;
  };

  setAnalyticsManager = (managerId, module) => {
    this.analyticsManager = new AnalyticsManager(
      module ?? 'video-playback',
      managerId,
      this.eventsApiUrl,
    );
  };

  loadMediaAnalytics = () => {
    if (!this.shakaPlayerInstance) return;
    this.shakaVideoElement = this.shakaPlayerInstance.getMediaElement();
    this.addVideoElementListeners();
    this.subscriptions.push(
      subscribe(this.shakaPlayerInstance, 'onstatechange', this.onStateChanged),
    );
    this.subscriptions.push(subscribe(this.shakaPlayerInstance, 'adaptation', this.onAdaptation));
    this.subscriptions.push(
      subscribe(this.shakaPlayerInstance, 'variantchanged', this.onAdaptation),
    );
    this.subscriptions.push(subscribe(this.shakaPlayerInstance, 'error', this.onError));
    this.subscriptions.push(subscribe(this.shakaPlayerInstance, 'loading', this.onLoad));
    this.subscriptions.push(subscribe(this.shakaPlayerInstance, 'loaded', this.onStreamInfoReady));
    this.shakaPlayerInstance.getNetworkingEngine().registerResponseFilter(this.downloadFilter);
  };

  /**
   * @function setMediaPlayer
   * @param {Object/Function} shakaPlayer The player to be tracked.
   * @summary An API to set the player to be tracked.
   */
  setMediaPlayer = shakaPlayer => {
    this.resetMediaPlayer();
    this.shakaPlayerInstance = shakaPlayer;
    this.loadMediaAnalytics();
  };

  /**
   * @function resetMediaPlayer
   * @summary An API to reset the player being tracked.
   */
  resetMediaPlayer = () => {
    if (this.shakaPlayerInstance) {
      // If player is valid, remove listeners first.
      this.removeAllListeners();
      this.shakaPlayerInstance = null;
    }
    if (this.analyticsManager) {
      AnalyticsManager.flush();
    }
    this.numberOfSeeksDone = 0;
    clearInterval(this.heartbeatIntervalId);
    this.heartbeatIntervalId = null;
  };
}
export default ShakaEventListeners;
