import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import TeamStandings from 'tv-modules/TeamStandings/TeamStandings';
import { useGetMatchStatus } from 'tv-selectors/match/makeGetMatchStatus';
import { useGetMatchScore } from 'tv-selectors/match/makeGetMatchScore';
import { useGetTeamShortName } from 'tv-selectors/team/makeGetTeamShortName';
import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';
import { useGetCompetitors } from 'tv-selectors/competitors/makeGetCompetitors';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetMatchBestOf } from 'tv-selectors/match/makeGetMatchBestOf';
import { useGetMatchAvailableGamesInfo } from 'tv-selectors/match/makeGetMatchAvailableGamesInfo';
import { Container, Meta } from './MatchInfo.styles';

const MatchInfo = ({ matchId, selectedMatchGame, showBestOfLabel = false, size = 'small' }) => {
  const [competitor1 = {}, competitor2 = {}] = useGetGameCompetitors({
    matchId,
    selectedMatchGameIndex: selectedMatchGame,
  });

  const competitors = useGetCompetitors(matchId);

  const team1Id = competitor1.teamId;
  const team2Id = competitor2.teamId;
  const team1LogoSrc = useGetSelectedTeamLogo({ teamId: team1Id, useDarkLogos: false });
  const team2LogoSrc = useGetSelectedTeamLogo({ teamId: team2Id, useDarkLogos: false });
  const teamOneName = useGetTeamShortName({ teamId: team1Id });
  const teamTwoName = useGetTeamShortName({ teamId: team2Id });
  const matchScores = useGetMatchScore({ matchId });
  const matchStatus = useGetMatchStatus({ matchId });
  const matchBestOf = useGetMatchBestOf({ matchId });
  const availableGames = useGetMatchAvailableGamesInfo({ matchId });

  const productionStatus = matchStatus === 'finished' ? 'live' : matchStatus || 'vod';
  const team1MatchScore = matchScores[team1Id] || 0;
  const team2MatchScore = matchScores[team2Id] || 0;
  const bestOfLabel = `Best Of ${matchBestOf}`;

  const currentScores = useMemo(() => {
    const scores = {};
    scores[team1Id] = 0;
    scores[team2Id] = 0;

    availableGames.forEach((game, i) => {
      const gameCompetitors = game.competitors || [];
      const competitorId1 = gameCompetitors[0] || '';
      const competitorId2 = gameCompetitors[1] || '';

      const gameScores = game.scores || [];

      const firstTeamScoresInfo = gameScores.find(s => s.competitorId === competitorId1) || {};
      const secondTeamScoresInfo = gameScores.find(s => s.competitorId === competitorId2) || {};
      const teamOnePoints = firstTeamScoresInfo?.points || 0;
      const teamTwoPoints = secondTeamScoresInfo?.points || 0;
      if (teamOnePoints === teamTwoPoints || i >= selectedMatchGame) return;

      if (teamOnePoints >= teamTwoPoints) {
        const cmp = competitors[competitorId1];
        scores[cmp.teamId] += 1;
      } else {
        const cmp = competitors[competitorId2];
        scores[cmp.teamId] += 1;
      }
    });
    return scores;
  }, [availableGames, competitors, selectedMatchGame, team1Id, team2Id]);

  const isVod = productionStatus === 'vod';
  const matchMissingScore =
    matchBestOf < 2 || (isVod && team1MatchScore === 0 && team2MatchScore === 0);

  return (
    <Container>
      <TeamStandings
        size={size}
        teamOneLogo={team1LogoSrc}
        teamOneName={teamOneName}
        teamOneScore={currentScores[team1Id]}
        teamTwoLogo={team2LogoSrc}
        teamTwoName={teamTwoName}
        teamTwoScore={currentScores[team2Id]}
        bestOf={matchBestOf}
        teamOneGameWins={currentScores[team1Id]}
        teamTwoGameWins={currentScores[team2Id]}
        matchMissingScore={matchMissingScore}
      />
      {showBestOfLabel && <Meta data-testid="meta-label">{bestOfLabel}</Meta>}
    </Container>
  );
};

MatchInfo.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGame: PropTypes.number.isRequired,
  showBestOfLabel: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default memo(MatchInfo);
