const SadPoro = () => (
  <svg data-testid="sad-poro" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M287.53 64.51L286.89 41.17C286.89 41.17 258.34 49.29 247.8 60.42C239.47 45.12 225.05 0 225.05 0C225.05 0 185.38 14.05 154.26 49.09L136.53 35.41C136.53 35.41 -12.9999 202 19.1301 289L28.6401 283.25C16.2401 324.25 26.0101 355.85 26.0101 355.85C8.7738e-05 394.94 21.4201 400 33.8201 400H378.72C396.99 400 410.17 376.73 378.72 352.16C378.72 352.16 385.32 318.27 377.17 275.56L389.27 280.07C389.27 280.07 392.71 139.17 287.53 64.51Z"
      fill="#C0A685"
    />
    <path
      d="M317.66 249.32C363.135 249.32 400 212.455 400 166.98C400 121.505 363.135 84.6399 317.66 84.6399C272.185 84.6399 235.32 121.505 235.32 166.98C235.32 212.455 272.185 249.32 317.66 249.32Z"
      fill="#C0A685"
    />
    <path
      d="M236.1 171.07C236.1 171.07 254.29 118.55 293.05 104.44C333.26 89.8101 403.93 126.34 380.49 201.63C380.092 202.891 379.324 204.003 378.285 204.82C377.246 205.638 375.985 206.124 374.666 206.214C373.347 206.305 372.032 205.996 370.891 205.329C369.75 204.661 368.837 203.665 368.27 202.47C363.93 193.05 356.05 184.31 342.14 189.37C315.71 199 326.52 226 326.52 226L236.1 171.07Z"
      fill="#754D29"
    />
    <path
      d="M278.42 112.2C282.946 109 287.862 106.392 293.05 104.44C296.414 103.275 299.889 102.458 303.42 102C321.58 113.42 345.9 138 357.71 190.1C353.61 187.82 348.58 187.01 342.13 189.35C340.484 190.037 338.901 190.866 337.4 191.83C321.22 124.84 283.41 113.37 278.42 112.2Z"
      fill="#603A17"
    />
    <path
      d="M82.34 249.32C127.815 249.32 164.68 212.455 164.68 166.98C164.68 121.505 127.815 84.6399 82.34 84.6399C36.8649 84.6399 0 121.505 0 166.98C0 212.455 36.8649 249.32 82.34 249.32Z"
      fill="#C0A685"
    />
    <path
      d="M167.42 171.95C167.42 171.95 149.23 119.43 110.47 105.32C70.2601 90.6801 -0.409894 127.21 23.0001 202.5C23.3979 203.761 24.1663 204.873 25.2051 205.69C26.2439 206.508 27.5049 206.994 28.8238 207.084C30.1428 207.175 31.4584 206.866 32.5993 206.199C33.7402 205.531 34.6535 204.535 35.2201 203.34C39.5601 193.92 47.4401 185.18 61.3501 190.24C87.8101 199.86 77.0001 226.86 77.0001 226.86L167.42 171.95Z"
      fill="#754D29"
    />
    <path
      d="M125.1 113.08C120.574 109.88 115.658 107.272 110.47 105.32C107.105 104.159 103.63 103.344 100.1 102.89C81.9401 114.31 57.6201 138.89 45.8101 190.99C49.9101 188.71 54.9401 187.9 61.3901 190.24C63.0363 190.926 64.619 191.756 66.1201 192.72C82.3001 125.72 120.11 114.25 125.1 113.08Z"
      fill="#603A17"
    />
    <path
      d="M216.41 25.26C216.41 25.26 50.1599 132.14 50.1599 298.08C50.1599 357.61 114.54 384.96 200.32 384.96C286.1 384.96 348.91 360.58 348.91 317.15C348.91 187.61 241.25 157.92 216.41 25.26Z"
      fill="white"
    />
    <path
      d="M46.72 312C46.72 146.84 128.91 101.69 144.22 57.78C144.22 57.78 192.66 94.5 165.63 142.78"
      fill="white"
    />
    <path
      d="M96.2499 158.86C96.2499 158.86 49.8399 168.39 43.1299 194.48C43.1299 194.48 69.0699 190.73 83.7499 208.23"
      fill="white"
    />
    <path
      d="M71.7199 184.8C70.3099 185.89 37.0299 214.18 33.9099 250.89C33.9099 250.89 64.8499 231.2 84.2199 234.64"
      fill="white"
    />
    <path
      d="M55.7799 224.8C11.8699 340.89 71.9199 384.8 71.9199 384.8H107.08L120.94 350.8"
      fill="white"
    />
    <path
      d="M327.19 202.14C405.63 329.48 337.5 384.84 337.5 384.84H299.5L282.94 350.84"
      fill="white"
    />
    <path
      d="M269.53 64.79C228.75 82.79 221.72 114.48 221.72 114.48L257 132.76L231.6 148.86L322 224.17C352.63 219.8 364.91 184.87 364.91 184.87C294.79 158.86 269.53 64.79 269.53 64.79Z"
      fill="white"
    />
    <path d="M334.22 214C334.22 214 357.5 220.56 366.09 251.66L338.75 242.44" fill="white" />
    <path
      d="M76.72 167.76C76.72 167.76 63.59 152.3 80 123.7C80 123.7 100.78 138.39 110.31 135.42"
      fill="white"
    />
    <path
      d="M339 174.33C339 174.33 320.56 151.67 314.31 120.42C308.771 127.962 304.884 136.586 302.9 145.73"
      fill="white"
    />
    <path
      d="M298.37 204.35C290.964 198.983 284.848 192.031 280.47 184C262.47 189.87 250.74 207.62 254.11 226.87C255.908 236.986 261.625 245.983 270.02 251.907C278.414 257.832 288.806 260.204 298.94 258.51C319.24 254.96 332.54 236.21 330.26 216.01C321.06 216.27 309.42 212.57 298.37 204.35Z"
      fill="#1E252B"
    />
    <path
      d="M155.68 224.74C153.04 245.83 133.79 261.13 112.68 258.49C91.57 255.85 76.29 236.6 78.93 215.49L121.93 181.74C132.076 183.038 141.303 188.277 147.618 196.323C153.933 204.369 156.829 214.576 155.68 224.74V224.74Z"
      fill="#1E252B"
    />
    <path
      d="M105.542 206.556C123.25 193.275 131.798 174.764 124.634 165.212C117.47 155.66 97.3064 158.683 79.5979 171.964C61.8895 185.245 53.3417 203.756 60.5059 213.308C67.6702 222.86 87.8335 219.837 105.542 206.556Z"
      fill="white"
    />
    <path
      d="M38.9999 369L60.8799 346C63.9312 342.752 67.6153 340.164 71.7051 338.395C75.795 336.626 80.2038 335.713 84.6599 335.713C89.116 335.713 93.5248 336.626 97.6146 338.395C101.704 340.164 105.389 342.752 108.44 346L130.29 369.15C131.55 370.472 132.392 372.136 132.709 373.935C133.026 375.734 132.804 377.586 132.07 379.259C131.337 380.932 130.126 382.35 128.588 383.336C127.051 384.322 125.256 384.831 123.43 384.8H45.8799C44.0619 384.773 42.2902 384.223 40.7768 383.215C39.2635 382.207 38.0726 380.785 37.3467 379.118C36.6208 377.451 36.3907 375.61 36.6839 373.816C36.9771 372.021 37.7812 370.349 38.9999 369V369Z"
      fill="#764E2A"
    />
    <path
      d="M369 369.59L347.44 346.72C344.407 343.501 340.748 340.937 336.688 339.184C332.628 337.431 328.252 336.527 323.83 336.527C319.408 336.527 315.032 337.431 310.972 339.184C306.912 340.937 303.253 343.501 300.22 346.72L278.66 369.59C273.05 375.41 277.37 385.12 285.34 385.12H362.34C364.143 385.131 365.91 384.609 367.419 383.621C368.927 382.633 370.111 381.222 370.822 379.564C371.533 377.907 371.739 376.077 371.415 374.302C371.091 372.528 370.251 370.889 369 369.59V369.59Z"
      fill="#764E2A"
    />
    <path
      d="M251.6 316.32C241.67 298.88 223.2 286.89 201.72 286.89C191.531 286.911 181.532 289.65 172.753 294.823C163.975 299.996 156.735 307.417 151.78 316.32"
      stroke="#754D29"
      strokeWidth="23.33"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M144.43 269.895C144.482 260.004 126.432 251.891 104.115 251.774C81.7973 251.657 63.6634 259.581 63.6116 269.472C63.5598 279.363 81.6098 287.477 103.927 287.593C126.245 287.71 144.379 279.787 144.43 269.895Z"
      fill="#39C5F3"
    />
    <path
      d="M339.733 269.777C339.785 259.886 321.735 251.773 299.418 251.656C277.1 251.539 258.966 259.463 258.914 269.354C258.863 279.245 276.912 287.359 299.23 287.476C321.548 287.592 339.681 279.669 339.733 269.777Z"
      fill="#39C5F3"
    />
  </svg>
);
export default SadPoro;
