import PredictionDisplayedResult from './PredictionDisplayResult';
import PredictionDisplaySelectOptionResult from './PredictionDisplaySelectOptionResult';
import { predictionType } from '../../../types';
import { variants } from '../../../predictionConstants';

const PredictionOutcomeHandlement = ({ prediction }) => {
  const { variant } = prediction;
  switch (variant) {
    case variants.TEAM:
    case variants.TEAMS:
    case variants.PLAYER:
    case variants.ONE_VERSUS_ONE:
    case variants.ONE_VERSUS_MANY:
    case variants.TEXT:
      return (
        <PredictionDisplayedResult
          prediction={prediction}
          numberOfOptions={Object.keys(prediction.options).length}
        />
      );
    case variants.SELECT:
      return <PredictionDisplaySelectOptionResult prediction={prediction} />;
    default:
      return null;
  }
};

PredictionOutcomeHandlement.propTypes = {
  prediction: predictionType.isRequired,
};

export default PredictionOutcomeHandlement;
