import produce from 'immer';
import deepmerge from 'deepmerge';
import { SET_TOURNAMENT_STAGES } from 'tv-constants/redux';

export const initialState = {};

const customMerge = key => {
  if (key === 'schedules') {
    return (val1, val2) => {
      const mergedVals = [...val1, ...val2];
      const ids = [...new Set(mergedVals.map(({ scheduleName }) => scheduleName))];

      return ids.reduce(
        (acc, id) => [
          ...acc,
          {
            ...val1.find(({ scheduleName }) => scheduleName === id),
            ...val2.find(({ scheduleName }) => scheduleName === id),
          },
        ],
        [],
      );
    };
  }
  return undefined;
};

const tournamentStagesReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_TOURNAMENT_STAGES:
      return deepmerge(state, payload, { customMerge });
    default:
      return state;
  }
}, initialState);

export default tournamentStagesReducer;
