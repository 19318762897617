import styled, { css } from 'styled-components';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import { XSMALL, SMALL, MEDIUM } from './constants';

const xsmallContainerCss = css`
  min-width: 128px;
  max-width: 159px;
  min-height: 168px;
`;

const smallContainerCss = css`
  min-width: 160px;
  max-width: 296px;
  min-height: 208px;
`;

const mediumContainerCss = css`
  min-width: 296px;
  max-width: 400px;
  min-height: 300px;
`;

const paddingTop = {
  [XSMALL]: 8,
  [SMALL]: 16,
  [MEDIUM]: 40,
};

export const Container = styled.div.attrs(() => ({
  type: 'button',
}))`
  ${buttonDefaultStyle}
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  ${({ size }) => {
    if (size === XSMALL) return xsmallContainerCss;
    if (size === SMALL) return smallContainerCss;
    return mediumContainerCss;
  }};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: ${({ size }) => paddingTop[size]}px;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const BackgroundLayer = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 0;
  top: 0;
  border-radius: 5px;
  background: ${({ gradient }) => gradient};
`;

export const RepeatingTextLayer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
`;
