const getParameterByName = (name, url) => {
  if (!global.document) return '';
  const selectedUrl = url || window.location.href;
  const fixedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${fixedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(selectedUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const parseURLQueriesByName = (name, url) => getParameterByName(name, url);

export default parseURLQueriesByName;
