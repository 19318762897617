import logger from 'znipe-logger';

const callAPIMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const { types, callAPI, shouldCallAPI = () => true, payload = {} } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return undefined;
    }

    const [requestType, successType, failureType] = types;
    dispatch({ payload, ...{ type: requestType } });

    const executeApiCall = (token, isAdmin) => {
      const startTime = Date.now();
      return callAPI(token, isAdmin)
        .then(response => {
          try {
            return response.json().then(json => ({ json, response }));
          } catch (_error) {
            return { json: response, response };
          }
        })
        .then(
          ({ json, response }) => {
            if (
              json.api_status !== 'OK' &&
              (!response.ok || (json.status && json.status === 'error'))
            ) {
              dispatch({
                ...payload,
                response: json,
                type: failureType,
              });
              return Promise.reject(json);
            }
            if (!global.document)
              logger.log('It took', Date.now() - startTime, 'ms to fetch action', successType);
            dispatch({
              ...payload,
              response: json,
              type: successType,
            });
            return json;
          },
          error => {
            if (!global.document)
              logger.log('It took', Date.now() - startTime, 'ms fail fetch action', failureType);
            dispatch({ payload, ...{ error, type: failureType } });
            return Promise.reject(error);
          },
        );
    };

    const { auth } = getState();
    const token = auth?.token;
    const isAdmin = auth?.isAdmin ?? false;

    return executeApiCall(token, isAdmin);
  };

export default callAPIMiddleware;
