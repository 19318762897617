import { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import { Marker } from '../Filters.styles';
import { SMALL, MEDIUM, LEFT, RIGHT, NONE } from './constants';
import {
  Container,
  InnerContainer,
  IconWrapper,
  TextWrapper,
  ArrowWrapper,
  MarkerWrapper,
} from './MenuSelector.styles';

const MenuSelector = ({
  label,
  iconType,
  markerActive = false,
  markerNumber = 0,
  withUnderline = true,
  arrowType = RIGHT,
  onClickMenu = () => {},
}) => {
  const markerElement = useMemo(() => {
    if (markerNumber > 0) {
      return (
        <MarkerWrapper hasLeftArrow={arrowType === LEFT} data-testid={`marker-medium-${label}`}>
          <Marker type={MEDIUM}>
            <Typography type="title-s">{markerNumber}</Typography>
          </Marker>
        </MarkerWrapper>
      );
    }
    if (markerActive)
      return (
        <MarkerWrapper data-testid="marker-small">
          <Marker type={SMALL} />
        </MarkerWrapper>
      );
    return null;
  }, [markerNumber, markerActive, arrowType, label]);

  const leftArrowAndLabel = useMemo(
    () => (
      <InnerContainer>
        {arrowType === LEFT && (
          <ArrowWrapper type="left" data-testid="arrow-left">
            <Icon type="chevronLeft" size={16} />
          </ArrowWrapper>
        )}
        {iconType && (
          <IconWrapper type="label" data-testid="label-icon">
            <Icon type={iconType} size={24} />
          </IconWrapper>
        )}
        <TextWrapper>
          <Typography type="title-s">{label}</Typography>
        </TextWrapper>
      </InnerContainer>
    ),
    [label, iconType, arrowType],
  );

  const markerAndRightArrow = useMemo(
    () => (
      <InnerContainer>
        {markerElement}
        {arrowType === RIGHT && (
          <ArrowWrapper type="right" data-testid="arrow-right">
            <Icon type="chevronRight" size={16} />
          </ArrowWrapper>
        )}
      </InnerContainer>
    ),
    [arrowType, markerElement],
  );

  const handleClickMenu = useCallback(() => {
    if (arrowType === RIGHT) {
      onClickMenu(label);
    }
    if (arrowType === LEFT) {
      onClickMenu(null);
    }
  }, [onClickMenu, arrowType, label]);

  return (
    <Container
      arrowType={arrowType}
      withUnderline={withUnderline}
      onClick={handleClickMenu}
      data-testid="container"
    >
      {leftArrowAndLabel}
      {markerAndRightArrow}
    </Container>
  );
};

MenuSelector.propTypes = {
  label: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  markerActive: PropTypes.bool,
  markerNumber: PropTypes.number,
  withUnderline: PropTypes.bool,
  arrowType: PropTypes.oneOf([LEFT, RIGHT, NONE]),
  onClickMenu: PropTypes.func,
};

export default memo(MenuSelector);
