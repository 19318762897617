import styled, { css } from 'styled-components';

export const VideoItem = styled.div`
  padding-bottom: 16px;
  ${({ clipType }) =>
    clipType === 'list' &&
    css`
      padding-top: 16px;
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.borderColor};
      }
      &:focus,
      &:hover {
        background: ${props => props.theme.secondaryBakgroundFade};
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
`;

export const ToggleWrapper = styled.div`
  margin: 0 8px 8px auto;
`;

export const VideosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $autoPlayEnabled }) =>
    !$autoPlayEnabled &&
    css`
      ${VideoItem}:first-child {
        padding-top: 0;
      }
    `}
`;
