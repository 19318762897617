import { useEffect } from 'react';
import usePackageName from 'tv-hooks/usePackageName';
import useSelectCurrency from 'tv-hooks/useSelectCurrency';
import { formatPrice } from 'tv-routes/Subscribe/hooks/usePriceInfoText';
import useGetPrice from 'tv-modules/Payment/hooks/useGetPrice';
import { useGetPackageProducts } from 'tv-selectors/packages/makeGetPackageProducts';
import { useGetProductSubscriptionOriginalPrice } from 'tv-selectors/products/makeGetProductSubscriptionOriginalPrice';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import logger from 'znipe-logger';

const paymentPeriod = 'monthly';

const useFormattedMonthlySubscriptionPrice = () => {
  const packageName = usePackageName();
  const currency = useSelectCurrency();
  const isAuthenticated = useIsAuthenticated();
  const packageProducts = useGetPackageProducts({
    packageName,
  });
  const productId = packageProducts[0];

  const fetchedProductPrice = useGetProductSubscriptionOriginalPrice({ productId });
  const getPrice = useGetPrice(currency, productId, paymentPeriod);

  useEffect(() => {
    if (isAuthenticated && fetchedProductPrice === null) {
      getPrice().catch(err => logger.error('Could not fetch price', err));
    }
  }, [fetchedProductPrice, isAuthenticated, getPrice]);

  const formattedPrice = formatPrice(fetchedProductPrice, currency);

  return formattedPrice;
};

export default useFormattedMonthlySubscriptionPrice;
