import { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import TeamStandings from 'tv-modules/TeamStandings/TeamStandings';
import Typography from 'znipe-elements/general/Typography/Typography';
import StreamThumbnailPreview from 'znipe-elements/data-display/StreamThumbnailPreview/StreamThumbnailPreview';
import { useMatchTeamsLogos } from 'tv-selectors/match/makeGetMatchTeamsLogos';
import { useGetMatchBestOf } from 'tv-selectors/match/makeGetMatchBestOf';
import { useGetTournament } from 'tv-selectors/tournaments/makeGetTournament';
import { useMatchTeams } from 'tv-selectors/match/makeGetMatchTeams';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import useMatchLink from 'tv-hooks/useMatchLink';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import Link from 'znipe-elements/general/Link/Link';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import { Container, Thumbnail, Content, DescriptionWrapper } from './VODItem.styles';
import { SMALL, MEDIUM, LARGE, EVENT, MATCH } from './VODItem.constants';
import VodItemLoading from './VODItem.loading';

const getTournamentStageLabel = word => {
  if (typeof word !== 'string') return '';
  const splited = word.split(':')?.[1]?.replace('-', ' ');
  if (splited.length < 1) return '';
  return splited.charAt(0).toUpperCase() + splited.substring(1);
};

const getTeamName = (isDesktopLargeOrGreater, size, team) => {
  if (!team) return '';
  const { name, shortName } = team;
  if (isDesktopLargeOrGreater && size === LARGE) {
    return name;
  }
  return shortName || name;
};

const VODItem = ({ matchId, size = SMALL }) => {
  const containerRef = useRef(null);
  const matchLink = useMatchLink(matchId);
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const tournamentData = useGetTournament({ matchId });
  const { tournamentStages = [], matchCardImageUrl } = tournamentData ?? {};

  const tournamentStageLabel = useMemo(
    () => getTournamentStageLabel(tournamentStages?.[0]) || 'Tour. Stage',
    [tournamentStages],
  );

  const bestOfLabel = useMemo(
    () => `${tournamentStageLabel} | ${isDesktopLargeOrGreater ? 'Best of ' : 'Bo'}`,
    [tournamentStageLabel, isDesktopLargeOrGreater],
  );

  const [team1LogoSrc = '', team2LogoSrc = ''] = useMatchTeamsLogos({ matchId });
  const [teamOne, teamTwo] = useMatchTeams({ matchId });

  const teamOneLabel = getTeamName(isDesktopLargeOrGreater, size, teamOne) || '';
  const teamTwoLabel = getTeamName(isDesktopLargeOrGreater, size, teamTwo) || '';
  const matchBestOf = useGetMatchBestOf({ matchId });

  const gameGlobalStreams = useGetGameGlobalStreams({ matchId });
  const eventStreamId = useMemo(() => {
    const { streamId } = gameGlobalStreams.find(stream => stream.type === EVENT) || {};
    return streamId;
  }, [gameGlobalStreams]);

  const videoSrc = useStreamSrcWithToken(eventStreamId, MATCH);
  const invertTeamLogoPlacement = size === LARGE && isDesktopLargeOrGreater;

  const componentHasLoaded = useLoadingDelay(!!videoSrc, undefined, true);

  const teamStandingsSize = useMemo(() => (size === SMALL ? SMALL : LARGE), [size]);
  const typographySize = useMemo(() => (size === SMALL ? 'paragraph-s' : 'title-m'), [size]);

  if (!componentHasLoaded) {
    return <VodItemLoading size={size} />;
  }

  return (
    <Link to={matchLink} hideUnderline>
      <Container ref={containerRef} data-testid="vod-item">
        <Thumbnail size={size}>
          <StreamThumbnailPreview
            targetRef={containerRef}
            src={videoSrc}
            alt={`${teamOne?.name} vs ${teamTwo?.name}`}
            thumbnail={videoSrc ? undefined : matchCardImageUrl}
          />
        </Thumbnail>
        <Content size={size}>
          <TeamStandings
            size={teamStandingsSize}
            teamOneLogo={team1LogoSrc}
            teamOneName={teamOneLabel}
            teamTwoLogo={team2LogoSrc}
            teamTwoName={teamTwoLabel}
            hideScore
            swapPlacement={invertTeamLogoPlacement}
            matchMissingScore
          />
          <DescriptionWrapper>
            <Typography dataTestId="vod-item-description" type={typographySize}>
              {matchBestOf > 1 ? `${bestOfLabel + matchBestOf}` : ''}
            </Typography>
          </DescriptionWrapper>
        </Content>
      </Container>
    </Link>
  );
};

VODItem.propTypes = {
  matchId: PropTypes.string.isRequired,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
};

export default VODItem;
