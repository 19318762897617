import {
  POV_SELECTOR_STREAM_ID,
  SIDEBAR_STATS_VISIBLITIY,
  SIDEBAR_HIGHLIGHTS_VISIBLITIY,
  SIDEBAR_ROOMS_VISIBLITIY,
  SIDEBAR_GAMES_VISIBILITY,
  SIDEBAR_STREAMS_VISIBILITY,
  SIDEBAR_PREDICTIONS_VISIBLITIY,
  SIDEBAR_CHAT_VISIBILITY,
  POV_SELECTOR_RESET,
} from './Stage.constants';

const reducer = (state, { type, ...action }) => {
  switch (type) {
    case POV_SELECTOR_STREAM_ID:
      return {
        ...state,
        streamSelectorState: {
          selectedStreamId: action.selectedStreamId,
        },
      };
    case SIDEBAR_STATS_VISIBLITIY:
      return {
        ...state,
        statsSidebarVisible: action.isVisible,
        highlightsSidebarVisible: action.largeScreen ? state.highlightsSidebarVisible : false,
        chatSidebarVisible: action.largeScreen ? state.chatSidebarVisible : false,
        predictionsSidebarVisible: false,
        gamesSidebarVisibility: false,
        streamsSidebarVisibility: false,
      };
    case SIDEBAR_HIGHLIGHTS_VISIBLITIY:
      return {
        ...state,
        highlightsSidebarVisible: action.isVisible,
        statsSidebarVisible: action.largeScreen ? state.statsSidebarVisible : false,
        roomsSidebarVisible: false,
        gamesSidebarVisibility: false,
        streamsSidebarVisibility: false,
        chatSidebarVisible: false,
      };
    case SIDEBAR_ROOMS_VISIBLITIY:
      return {
        ...state,
        roomsSidebarVisible: action.isVisible,
        predictionsSidebarVisible: action.largeScreen ? state.predictionsSidebarVisible : false,
        statsSidebarVisible: action.largeScreen ? state.statsSidebarVisible : false,
        highlightsSidebarVisible: false,
        gamesSidebarVisibility: false,
        streamsSidebarVisibility: false,
        chatSidebarVisible: false,
      };
    case SIDEBAR_GAMES_VISIBILITY:
      return {
        ...state,
        gamesSidebarVisibility: action.isVisible,
        highlightsSidebarVisible: false,
        statsSidebarVisible: false,
        predictionsSidebarVisible: false,
        roomsSidebarVisible: false,
        streamsSidebarVisibility: false,
        chatSidebarVisible: false,
      };
    case SIDEBAR_PREDICTIONS_VISIBLITIY:
      return {
        ...state,
        predictionsSidebarVisible: action.isVisible,
        highlightsSidebarVisible: action.largeScreen ? state.highlightsSidebarVisible : false,
        chatSidebarVisible: action.largeScreen ? state.chatSidebarVisible : false,
        statsSidebarVisible: false,
        gamesSidebarVisibility: false,
        streamsSidebarVisibility: false,
      };
    case SIDEBAR_STREAMS_VISIBILITY:
      return {
        ...state,
        streamsSidebarVisibility: action.isVisible,
        statsSidebarVisible: false,
        predictionsSidebarVisible: false,
        highlightsSidebarVisible: false,
        roomsSidebarVisible: false,
        gamesSidebarVisibility: false,
        chatSidebarVisible: false,
      };
    case SIDEBAR_CHAT_VISIBILITY:
      return {
        ...state,
        chatSidebarVisible: action.isVisible,
        predictionsSidebarVisible: action.largeScreen ? state.predictionsSidebarVisible : false,
        statsSidebarVisible: action.largeScreen ? state.statsSidebarVisible : false,
        highlightsSidebarVisible: false,
        roomsSidebarVisible: false,
        gamesSidebarVisibility: false,
        streamsSidebarVisibility: false,
      };
    case POV_SELECTOR_RESET:
      return {
        ...state,
        streamSelectorState: { selectedStreamId: undefined },
      };
    default:
      return state;
  }
};

export default reducer;
