import { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Header from 'tv-elements/layout/Header/Header';
import Typography from 'znipe-elements/general/Typography/Typography';
import LoginButton from 'tv-modules/Authentication/LoginButton/LoginButton';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useAuthToken } from 'tv-selectors/auth/makeGetAuthToken';
import { useCSGOStatsEpic, SCENARIOS } from 'tv-epics/csgo/csgoStatsEpic';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import { HeaderWrapper, Container, NoAuthPredictionWrapper } from './Predictions.styles';
import PredictionHeader from './components/PredictionHeader/PredictionsHeader';
import useScenarioInputCard from './useScenarioInputCard';
import usePredictionOutcomeCards from './usePredictionOutcomeCard';
import usePredictionWins from './usePredictionWinsAndLosses';

export const BOOKCOVER_ID = 'stage-predictions-cover';

const Predictions = ({ onClose, matchId, selectedMatchGameIndex }) => {
  const [ref, setRef] = useState(null);
  const authToken = useAuthToken();

  const variables = useMemo(
    () => ({
      fromRound: 1,
    }),
    [],
  );

  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });

  useCSGOStatsEpic(gameId, SCENARIOS, variables);

  const scenarioInputCards = useScenarioInputCard(gameId);
  const predictionOutcomeCards = usePredictionOutcomeCards(gameId);

  const { wins, wrongs } = usePredictionWins(gameId);

  return (
    <Container ref={setRef}>
      <HeaderWrapper $authenticated={authToken}>
        <StickyComponent enableStickyScroll scrollTarget={ref}>
          <Header onClose={onClose} heading="Predictions" componentId="predictions-menu-header" />
          {authToken && <PredictionHeader wins={wins} wrongs={wrongs} size="small" />}
        </StickyComponent>
      </HeaderWrapper>
      {authToken ? (
        <>
          {scenarioInputCards}
          {predictionOutcomeCards}
        </>
      ) : (
        <NoAuthPredictionWrapper>
          <Typography type="heading-s" color="white">
            Predict on game events!
          </Typography>
          <Typography type="paragraph-m" color="secondaryTextColor">
            Log in to make predictions and see if you are correct!
          </Typography>
          <LoginButton />
        </NoAuthPredictionWrapper>
      )}
    </Container>
  );
};

Predictions.propTypes = {
  onClose: PropTypes.func.isRequired,
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
};

export default memo(Predictions);
