import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetIsIOS = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.isIOS ?? false);
};

export const useIsIOS = () => useMemoizedGqlSelector(makeGetIsIOS);

export default makeGetIsIOS;
