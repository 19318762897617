import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import IntroducePremium from 'tv-modules/Premium/IntroducePremium/IntroducePremium';
import PremiumBanner from 'tv-modules/Premium/PremiumBanner/PremiumBanner';
import useFormattedMonthlySubscriptionPrice from 'tv-hooks/useFormattedMonthlySubscriptionPrice';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(${({ theme }) => convertHexToRGBDigits(theme.primaryBackgroundColor)}, 0.8);
  backdrop-filter: blur(10px);
  position: absolute;
  z-index: 2;
  padding-left: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

const PremiumFeaturesModal = ({ isInteractable = true }) => {
  const formattedPrice = useFormattedMonthlySubscriptionPrice();
  return (
    <Container>
      <RibbonWrapper>
        <Typography type="title-s">{formattedPrice} / MONTH</Typography>
        <PremiumBanner animate={false} />
      </RibbonWrapper>
      <ContentWrapper>
        <IntroducePremium type="features" isInteractable={isInteractable} />
      </ContentWrapper>
    </Container>
  );
};

PremiumFeaturesModal.propTypes = {
  isInteractable: PropTypes.bool,
};

export default memo(PremiumFeaturesModal);
