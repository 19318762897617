import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';
import gradients from 'znipe-styles/gradients';
import convertHexToHSL from 'znipe-utils/misc/convertHexToHSL';

export const themes = {
  proview: {
    numberOfStreamSelectors: 5,
    backgroundColor: 'linear-gradient(90deg, #04212F 0%, #14171A 48.96%, #160304 100%)',
  },
  esportal: {
    numberOfStreamSelectors: 5,
  },
  default: {
    numberOfStreamSelectors: 5,
  },
  faceit: {
    numberOfStreamSelectors: 5,
    backgroundColor: css`
      ${({ theme }) => {
        const darkBackgroundSecondary = convertHexToHSL(theme.secondaryBackgroundColor, { l: 5 });
        return `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${darkBackgroundSecondary}  50%, ${theme.secondaryBackgroundColor} 100%)`;
      }}
    `,
  },
};

const alignMap = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const tutorialOpacity = 0.4;

export const MobileContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 56px [selector] 1fr [matchInfo] 56px [rightArea];
  grid-gap: 10px;
  height: 100%;
  width: 100%;
  margin: auto;
  align-content: center;
  @media ${device.tablet} {
    grid-template-columns: 64px [selector] 1fr [matchInfo] 64px [rightArea];
  }
`;

export const HintWrapper = styled.div`
  &:not([data-has-hint='true']) {
    ${({ $showTutorial }) => {
      if (!$showTutorial) return css``;
      return css`
        opacity: ${tutorialOpacity};
        pointer-events: none;
        cursor: unset;
      `;
    }}
  }
`;

export const FullWidthContainer = styled.div`
  display: flex;
  align-items: center;
  height: 105px;
  width: 100%;
  z-index: 4;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -2px 16px rgb(0 0 0 / 25%);
`;

export const FullWidthMiddleArea = styled.div`
  display: grid;
  justify-content: center;
  height: 100%;
  grid-template-columns: auto ${({ $isWide }) => ($isWide ? 372 : 220)}px auto;
`;

export const FullWidthMiddleAreaLoading = styled(FullWidthMiddleArea)`
  @media (min-width: 1500px) {
    grid-template-columns: auto 372px auto;
  }
`;

export const FullWidthContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 0;
  @media ${device.desktopExtraLarge} {
    max-width: 2560px;
  }
`;

export const RightArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 11px;
  z-index: 0;
`;

export const StreamSelectors = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ $isWide }) => ($isWide ? 30 : 15)}px;
`;

export const MatchAndGameInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  min-height: 85px;
  align-items: center;
  justify-content: ${({ $align }) => alignMap[$align]};
  > div {
    min-width: 70px;
    width: fit-content;
  }
`;

export const MatchInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const MatchInfoContentWrapper = styled.div`
  margin: auto;
`;

export const GlobalStreamItemDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GlobalStreamItemImage = styled.img`
  width: 24px;
  margin-right: 20px;
`;

export const GlobalStreamSubLabel = styled.span`
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const GlobalStreamPremiumWrapper = styled.div`
  position: absolute;
  right: ${({ $isSelected }) => ($isSelected ? '-3px' : 0)};
  bottom: 0;
`;

export const PremiumBorder = styled.div`
  ${({ $isPremium }) => {
    if ($isPremium) {
      return css`
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: ${gradients.glimmeringGoldFade};
      `;
    }
    return css``;
  }}
`;

export const GlobalStreamItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 12px 16px;
  border-radius: ${({ $streamIndex, $streamLength }) => {
    switch ($streamIndex) {
      case 0:
        return '4px 0 0 4px';
      case $streamLength - 1:
        return '0 4px 4px 0';
      default:
        return '0';
    }
  }};
  &:hover,
  &:focus ${GlobalStreamSubLabel} {
    color: ${colors.white};
  }
`;

export const MobileContainer = styled.div`
  width: 100%;
  height: 72px;
  z-index: ${({ $showTutorial }) => ($showTutorial ? 3 : 1)};
  position: relative;
  background: ${({ theme }) => theme.secondaryBackgroundColor};

  @media ${device.tablet} {
    height: 88px;
  }
`;

export const MobileSelectorLoading = styled.div`
  height: 80%;
  width: 100%;
  background: ${({ theme }) => theme.tertiaryBackgroundColor};

  @media ${device.tablet} {
    height: 95%;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 33%;
  > div {
    width: unset;
  }
`;

export const IconContainer = styled.div`
  width: 24px;
  margin-right: 16px;
`;

export const StreamButtonPositioner = styled.div`
  margin-bottom: 8px;
  position: relative;
  top: 1.5px;
  @media (max-width: 1500px) {
    top: 2px;
  }
`;

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ $hasGlobalStreams }) => {
    if ($hasGlobalStreams) return css``;
    return css`
      justify-content: center;
    `;
  }}
`;
