import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.settings.backgroundColor};
  padding: 45px 15px;
`;

export const HeaderContainer = styled.div`
  height: 90px;
  width: 100%;
  color: ${({ theme }) => theme.settings.color};
`;
export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: ${({ theme }) => theme.settings.color};
`;

export const PageWrapper = styled.div`
  margin-top: 90px;
  background-color: ${({ theme }) => theme.settings.backgroundColor};
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

export const ContentWrapper = styled.div`
  padding: 20px 15px;
  width: 100%;
  position: relative;
  @media ${device.tablet} {
    width: 500px;
    padding: 20px 0px;
  }
  @media ${device.desktop} {
    width: 630px;
  }
`;
