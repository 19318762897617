/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import PopOver from 'znipe-elements/data-display/PopOver/PopOver';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import {
  CONTENT_PADDING,
  CloseButton,
  Container,
  ContentWrapper,
  Header,
  ScrollArea,
} from './PopOverSection.styles';

const emptyFunction = () => null;

export type PopOverSectionProps = {
  children: ReactNode;
  targetElement?: ReactNode;
  type?: 'regular' | 'compact';
  header?: string;
  minWidth?: number;
  maxHeight?: number;
  align?: 'start' | 'center' | 'end';
  allowScroll?: boolean;
  onCloseClick?: () => void;
};

const PopOverSection: React.FC<PopOverSectionProps> = ({
  children,
  targetElement,
  type = 'regular',
  header = '',
  minWidth = -1,
  maxHeight = -1,
  align = 'center',
  allowScroll = true,
  onCloseClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback((openState: boolean) => setIsOpen(openState), []);
  const onClose = useCallback(() => {
    setIsOpen(false);
    if (onCloseClick) onCloseClick();
  }, [onCloseClick]);

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const element = contentWrapperRef.current;
    if (!element) return emptyFunction;
    const resizeObserver = new ResizeObserver(() => {
      const childrenElements = element?.children;
      const childrenHeight = Object.values(childrenElements).reduce<number>(
        (acc, child) => acc + child.clientHeight,
        0,
      );
      if (childrenHeight > 0 && maxHeight > 0) {
        setHasScroll(childrenHeight > maxHeight - CONTENT_PADDING * 2);
      }
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [maxHeight]);

  const contentRef = useOutsideClick(
    targetElement ? emptyFunction : onClose,
  ) as React.MutableRefObject<HTMLDivElement>;

  const content = useMemo(
    () => (
      <Container ref={contentRef} $hasTarget={!!targetElement} $width={minWidth}>
        {header && (
          <Header data-testid="header-wrapper">
            {header && <Typography type="title-s">{header}</Typography>}
            {onClose && (
              <CloseButton data-testid="popover-close" onClick={onClose}>
                <Icon type="chevronDown" size={24} />
              </CloseButton>
            )}
          </Header>
        )}
        <ScrollArea $type={type} $maxHeight={maxHeight} $allowScroll={allowScroll}>
          <ContentWrapper ref={contentWrapperRef} $hasScroll={hasScroll} $allowScroll={allowScroll}>
            {children}
          </ContentWrapper>
        </ScrollArea>
      </Container>
    ),
    [
      contentRef,
      targetElement,
      minWidth,
      header,
      onClose,
      type,
      maxHeight,
      allowScroll,
      hasScroll,
      children,
    ],
  );
  if (!targetElement) return content;
  return (
    <PopOver
      targetElement={targetElement}
      externalOpenState={isOpen}
      onOpenStateChange={toggleOpen}
      minWidth={minWidth > 0 ? `${minWidth}px` : undefined}
      align={align}
      customBorderRadius="4px"
      useBorderRadius
    >
      {content}
    </PopOver>
  );
};

export default PopOverSection;
