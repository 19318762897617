const getPercentage = {
  x: (element, { x }) => {
    if (!element) return 0;
    const { left, width } = element.getBoundingClientRect();
    const currentX = x - left;
    return (currentX < 0 ? 0 : Math.min(currentX, width)) / width;
  },
  y: (element, { y }) => {
    if (!element) return 0;
    const { top, height } = element.getBoundingClientRect();
    const currentY = height - (y - top);
    return (currentY < 0 ? 0 : Math.min(currentY, height)) / height;
  },
};

export const initialState = {
  percentage: 0,
  dragging: false,
  hoverPercentage: 0,
  hovering: false,
};

export default (state, { type, ...action }) => {
  switch (type) {
    case 'DRAG_START':
      return {
        ...state,
        percentage: getPercentage[action.vertical ? 'y' : 'x'](action.element, action),
        dragging: true,
        element: action.element,
      };
    case 'DRAG_MOVE':
      return {
        ...state,
        percentage: getPercentage[action.vertical ? 'y' : 'x'](state.element, action),
      };
    case 'DRAG_END':
      return {
        ...state,
        percentage: getPercentage[action.vertical ? 'y' : 'x'](state.element, action),
        dragging: false,
      };
    case 'HOVER_START':
      return {
        ...state,
        hoverPercentage: getPercentage[action.vertical ? 'y' : 'x'](action.element, action),
        hovering: true,
        hoverElement: action.element,
      };
    case 'HOVER_MOVE':
      return {
        ...state,
        hoverPercentage: getPercentage[action.vertical ? 'y' : 'x'](state.hoverElement, action),
        hovering: true,
      };
    case 'HOVER_END':
      return {
        ...state,
        hovering: false,
      };
    case 'SET_STATE':
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};
