import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSubscribedEventIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedEventIds';
import makeGetEvents from 'tv-selectors/events/makeGetEvents';

const emptyArray = [];

const makeGetSubscribedEvents = () => {
  const getEvents = makeGetEvents();
  const getSubscribedEventIds = makeGetSubscribedEventIds();
  return createSelector([getEvents, getSubscribedEventIds], (allEvents, subscribedEvents) => {
    if (subscribedEvents.length === 0) return emptyArray;
    return subscribedEvents.map(id => ({ ...allEvents?.[id], _type: 'events' }));
  });
};

export const useGetSubscribedEvents = props =>
  useMemoizedGqlSelector(makeGetSubscribedEvents, props);

export default makeGetSubscribedEvents;
