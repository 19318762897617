import styled from 'styled-components';

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  margin-bottom: 2px;
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
  border-radius: 3px;
  background: linear-gradient(
    89.98deg,
    ${({ theme }) => theme.tertiaryBackgroundColor} 0.02%,
    rgba(39, 48, 57, 0) 52.78%,
    rgba(255, 255, 255, 0.03)
  );
  > div:first-child {
    margin-right: 10px;
  }
`;
