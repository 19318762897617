import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import gradients from 'znipe-styles/gradients';

const tabletContainerWidth = '448px';

export const GlobalStreamsWrapper = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: flex-start;
  min-width: 296px;

  @media (min-width: 400px) {
    min-width: 352px;
  }

  @media ${device.tablet} {
    min-width: ${tabletContainerWidth};
  }

  > * {
    max-width: 50%;
    &:not(:last-child) {
      margin-right: 4px;
      @media ${device.tablet} {
        margin-right: 10px;
      }
    }
  }
`;

export const PovStreamsWrapper = styled.div`
  margin: auto;
  max-width: 350px;
  @media (min-width: 520px) {
    max-width: 450px;
    padding: 0 20px;
  }
  @media ${device.tablet} {
    max-width: 632px;
  }
  @media ${device.desktop} {
    max-width: 75%;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:nth-child(2) {
    @media ${device.tablet} {
      max-width: ${tabletContainerWidth};
    }
  }
`;

export const PremiumBorder = styled.div`
  width: 100%;
  ${({ $isPremium }) => {
    if ($isPremium) {
      return css`
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: ${gradients.glimmeringGoldFade};
      `;
    }
    return css``;
  }}
`;
