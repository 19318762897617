import { schema } from 'normalizr';

const scenarios = new schema.Entity(
  'scenarios',
  {},
  {
    processStrategy: scenario => ({
      ...scenario,
      options: scenario.options.reduce(
        (acc, option) => ({
          ...acc,
          [option.id]: option,
        }),
        {},
      ),
    }),
  },
);

export default scenarios;
