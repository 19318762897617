import { createSelector } from 'reselect';
import makeGetSelectedGame from './makeGetSelectedGame';

const emptyArray = [];

const makeGetGameGlobalStreams = () => {
  const getGame = makeGetSelectedGame();
  return createSelector([getGame], game => game.globalStreams || emptyArray);
};

export default makeGetGameGlobalStreams;
