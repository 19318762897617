import formatDate from './formatDate';

const calculateDaysSinceStartTime = ISODate => {
  const dateTime = new Date(ISODate).getTime();
  let showYear = false;

  if (dateTime) {
    const dateNow = Date.now();
    const days = Math.ceil((dateNow - dateTime) / 1000 / 60 / (60 * 24));

    if (days > 0 && days < 15) {
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }

    showYear = !isNaN(days) ? days > 365 : false;
  }
  return formatDate(ISODate, showYear);
};

export default calculateDaysSinceStartTime;
