/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { ConnectingBracket, LineWrapper, MergeLeft, MergeRight } from '../Bracket.styles';

const EmptyColumn = ({
  numberOfMatchupsPrev,
  numberOfColumns,
  hoveredTeam = '',
  prevColumn = [],
  compact = false,
  height = 'inherit',
}) => (
  <ConnectingBracket
    numberOfColumns={numberOfColumns}
    compact={compact}
    height={height}
    data-testid="empty-column"
  >
    {Array.from(Array(numberOfMatchupsPrev)).map((_x, i) => {
      const isOdd = i % 2 === 0;
      const testId = `connecting-line-${i}`;
      return (
        <LineWrapper isOddChild={isOdd} key={testId} compact={compact} data-testid={testId}>
          <MergeLeft
            active={hoveredTeam && prevColumn[i].winner === hoveredTeam}
            isOddChild={isOdd}
            compact={compact}
          />
          <MergeRight
            active={hoveredTeam && prevColumn[i].winner === hoveredTeam}
            isOddChild={isOdd}
            compact={compact}
          />
        </LineWrapper>
      );
    })}
  </ConnectingBracket>
);

const teamShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  score: PropTypes.number,
};

EmptyColumn.propTypes = {
  numberOfMatchupsPrev: PropTypes.number.isRequired,
  numberOfColumns: PropTypes.number.isRequired,
  hoveredTeam: PropTypes.string,
  prevColumn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      teamOne: PropTypes.shape(teamShape),
      teamTwo: PropTypes.shape(teamShape),
      winner: PropTypes.string,
    }),
  ),
  compact: PropTypes.bool,
  height: PropTypes.string,
};

export default EmptyColumn;
