import { useMemo } from 'react';
import { useGamePrediction } from 'tv-selectors/predictions/makeGetGamePredictions';

const usePredictionWins = gatId => {
  const gamePredictions = useGamePrediction({ gatId });

  const wins = useMemo(
    () =>
      Object.values(gamePredictions).filter(
        prediction => prediction.predictedValue === prediction.outcome,
      ).length,
    [gamePredictions],
  );

  const wrongs = useMemo(
    () =>
      Object.values(gamePredictions).filter(
        prediction =>
          prediction.predictedValue !== prediction.outcome && prediction.outcome !== 'pending',
      ).length,
    [gamePredictions],
  );

  const results = useMemo(
    () => ({
      wins,
      wrongs,
    }),
    [wrongs, wins],
  );

  return results;
};

export default usePredictionWins;
