import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import CoverOverlay from 'tv-modules/Onboarding/Overlay/Overlay';

const MOBILE_HEADER_MARGIN = '16px';
const TABLET_HEADER_MARGIN = '36px';
const DESKTOP_HEADER_MARGIN = '24px';
const DESKTOP_LARGE_HEADER_MARGIN = '36px';

export const HeaderWrapper = styled.div`
  height: 56px;
  @media ${device.desktop} {
    > div {
      position: fixed;
      z-index: 2;
    }
  }
`;

export const PlayerStatsHeader = styled(HeaderWrapper)`
  height: 72px;
  margin-bottom: 16px;

  @media ${device.tablet} {
    height: 88px;
    margin-bottom: 24px;
  }
  @media ${device.desktop} {
    height: 72px;
    margin-bottom: 16px;
  }
  @media ${device.desktopLarge} {
    height: 88px;
    margin-bottom: 24px;
  }
`;

export const Container = styled.div`
  max-height: 100%;
  ${Scrollbar};
  overflow-y: ${({ $hideScrollBar }) => ($hideScrollBar ? 'hidden' : 'auto')};
  overflow-x: hidden;
  ${CoverOverlay} {
    margin-top: -${MOBILE_HEADER_MARGIN};
    min-height: calc(100% + ${MOBILE_HEADER_MARGIN});
    @media ${device.tablet} {
      margin-top: -${TABLET_HEADER_MARGIN};
      min-height: calc(100% + ${TABLET_HEADER_MARGIN});
    }
    @media ${device.desktop} {
      margin-top: -${DESKTOP_HEADER_MARGIN};
      min-height: calc(100% + ${DESKTOP_HEADER_MARGIN});
    }
    @media ${device.desktopLarge} {
      margin-top: -${DESKTOP_LARGE_HEADER_MARGIN};
      min-height: calc(100% + ${DESKTOP_LARGE_HEADER_MARGIN});
    }
  }
`;

export const StatsContent = styled.div`
  margin-top: 16px;
  padding: ${({ $isCompact }) => ($isCompact ? '0 16px' : 0)};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 56px;
  width: 100%;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
`;

export const BodyContainer = styled.div`
  position: relative;
`;

export const TitleWrapper = styled.div`
  margin: 24px 0;
`;
