import styled, { css } from 'styled-components';

const getContainerSize = ({ size, orientation, showChildren }) => {
  if (orientation === 'vertical' && size === 'fullWidth')
    return css`
      flex-direction: column;
      max-width: 100%;
      min-height: inherit;
    `;

  if (orientation === 'vertical')
    return css`
      flex-direction: column;
      min-height: ${showChildren ? '230px' : 'inherit'};
    `;

  // horizontal styling
  if (size === 'medium') {
    return css`
      max-width: 100%;
    `;
  }

  return css`
    max-width: 100%;
  `;
};

const getThumbnailSize = () => css`
  width: 100%;
  padding-bottom: 56.25%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2px;
  display: flex;
  position: relative;
  align-items: ${({ orientation }) => (orientation === 'horizontal' ? 'stretch' : 'center')};
  border-radius: ${props => (props.noBorderRadius ? 'inherit' : '16px')};
  overflow: hidden;
  ${getContainerSize}
`;

export const ThumbnailContainer = styled.div`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      background: ${({ theme }) => theme.bg2Fade};
    `}
  ${({ orientation, size }) => {
    if (orientation === 'vertical')
      return css`
        width: 100%;
      `;

    // horizontal styliing
    if (size === 'medium')
      return css`
        width: 59%;
      `;
    return css`
      width: 57%;
    `;
  }}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const Thumbnail = styled.div`
  position: relative;
  display: flex;
  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      border: 1px solid ${theme.UIAccent};
    `}

  ${getThumbnailSize}
  > * {
    position: absolute;
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  min-height: ${props => (props.size === 'large' ? '180px' : 'auto')};
  flex: 1;
`;

export const NowPlayingTextWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.UIAccent};
`;
