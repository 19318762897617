import { useState, useEffect, useMemo, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import logger from 'znipe-logger';
import Input from 'znipe-elements/data-entry/Input/Input';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Form,
  LinkTypography,
  LoadingButton,
  SuccessGif,
  ErrorText,
  FakeLink,
} from '../Shared/Shared';
import { LOADING, SUCCESS, ERROR, SENT, RESENT, firebaseErrors, defaultError } from '../constants';

const header = {
  default: 'Forgot Password?',
  [SENT]: 'Email sent',
  [RESENT]: 'Resent email',
};

const ForgotPasswordForm = () => {
  const [error, setError] = useState({});
  const [state, setState] = useState('');
  const [subPage, setSubPage] = useState();
  const {
    register,
    formState: { errors },
    setFocus,
    handleSubmit,
    watch,
  } = useForm();
  const { pathname, state: locationState } = useLocation();

  const { signup, login } = useMemo(
    () => ({
      signup: pathname.replace('/forgot-password', '/register'),
      login: pathname.replace('/forgot-password', ''),
    }),
    [pathname],
  );

  useEffect(() => {
    try {
      setFocus('email');
    } catch (_e) {
      logger.error('Failed to focus');
    }
  }, [setFocus]);

  useEffect(() => {
    if (state === SUCCESS) setError({});
  }, [state]);

  const sendEmail = useCallback(
    data => {
      if (state === LOADING) return;
      setState(LOADING);

      const { email } = data;

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => setState(SUCCESS))
        .then(() => setSubPage(prev => (prev ? RESENT : SENT)))
        .catch(({ code }) => {
          setState(ERROR);
          setError(firebaseErrors[code] ?? {});
        });
    },
    [state],
  );

  return (
    <>
      {locationState?.error && (
        <ErrorText type="paragraph-s">{locationState?.error.message ?? defaultError}</ErrorText>
      )}
      <Typography type="heading-m">{header[subPage] ?? header.default}</Typography>
      {!subPage && <LinkTypography>We&apos;ll send you a reset link to your email.</LinkTypography>}
      <Form onSubmit={handleSubmit(sendEmail)} $hiddeInput={!!subPage}>
        {subPage ? (
          <SuccessGif />
        ) : (
          <Input
            {...register('email', { required: true })}
            data-testid="email-input"
            errorMessage={error.message}
            error={error.type === 'email' || !!errors.email}
            isInline
            inputType="email"
            label="Email"
            disabled={!!subPage}
          />
        )}
        {subPage && (
          <LinkTypography $center>
            An email has been sent to <b>{watch('email')}</b> with further instructions
          </LinkTypography>
        )}
        {subPage ? (
          <Button to={login} size="small" variant="solid-color" type="button">
            Log In
          </Button>
        ) : (
          <LoadingButton type="submit" loading={state === LOADING}>
            Send link
          </LoadingButton>
        )}
        <LinkTypography $center>
          {subPage ? (
            <>
              Didn&apos;t get the email? <FakeLink type="submit">Resend</FakeLink>
            </>
          ) : (
            <>
              Need an account? <Link to={signup}>Sign Up</Link>
            </>
          )}
        </LinkTypography>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
