import { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useMatchTeamsLogos } from 'tv-selectors/match/makeGetMatchTeamsLogos';
import { useGetMatchTeamsShortNames } from 'tv-selectors/match/makeGetMatchTeamsShortNames';
import SelectorGroup from 'znipe-elements/navigation/SelectorGroup/SelectorGroup';
import SelectedVideoHighlight from 'tv-hocs/SelectedVideoHighlight/SelectedVideoHighLight';
import LOLPlayerSelector from '../LOL/PlayerSelector/PlayerSelector';
import CSGOPlayerSelector from '../CSGO/PlayerSelector/PlayerSelector';

const IconWrapper = styled.div`
  color: ${props => props.theme.roleIconColor};
  height: 100%;
`;

const roles = [
  <IconWrapper>
    <Icon type="topRole" />
  </IconWrapper>,
  <IconWrapper>
    <Icon type="jungleRole" />
  </IconWrapper>,
  <IconWrapper>
    <Icon type="midRole" />
  </IconWrapper>,
  <IconWrapper>
    <Icon type="bottomRole" />
  </IconWrapper>,
  <IconWrapper>
    <Icon type="supportRole" />
  </IconWrapper>,
];

const StatsSelectorGroup = ({
  matchId,
  selectedMatchGameIndex,
  size = 'small',
  type = 'vertical',
  hasPremiumContent = false,
  hideChampionInfo = false,
  onItemClick = () => null,
  onItemHover = () => null,
  gameTitle,
}) => {
  const competitors = useGetGameCompetitors({ matchId, selectedMatchGameIndex });
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });
  const [teamOneLogoSrc = '', teamTwoLogoSrc = ''] = useMatchTeamsLogos({
    matchId,
    useDarkLogos: false,
  });
  const [teamOneName, teamTwoName] = useGetMatchTeamsShortNames({ matchId });

  const renderSelector = useCallback(
    (selectorSize, player, teamId, team, gatPlayerPosition) => {
      const selectorType = type === 'vertical' ? 'horizontal' : 'vertical';
      const { playerId, streamId, locked } = player;
      const isSelected = !!playerStreams.find(s => (s || {}).id === streamId);
      const handleClick = () => {
        onItemClick(playerId, gatPlayerPosition, streamId, teamId);
      };

      return gameTitle === 'lol' ? (
        <SelectedVideoHighlight streamId={streamId} matchId={matchId}>
          <LOLPlayerSelector
            type={selectorType}
            matchId={matchId}
            gameId={gameId}
            playerId={playerId}
            teamId={teamId}
            streamId={streamId}
            team={team}
            gatPlayerPosition={gatPlayerPosition}
            onClick={handleClick}
            size={selectorSize}
            isSelected={isSelected}
            isLocked={hasPremiumContent && locked}
            onHover={onItemHover}
            hideChampionInfo={hideChampionInfo}
          />
        </SelectedVideoHighlight>
      ) : (
        <SelectedVideoHighlight streamId={streamId} matchId={matchId}>
          <CSGOPlayerSelector
            type={selectorType}
            gameId={gameId}
            playerId={playerId}
            teamId={teamId}
            streamId={streamId}
            teamSide={team}
            onClick={handleClick}
            size={selectorSize}
            isSelected={isSelected}
            isLocked={hasPremiumContent && locked}
            onHover={onItemHover}
            statsSelector
          />
        </SelectedVideoHighlight>
      );
    },
    [
      type,
      matchId,
      gameId,
      playerStreams,
      hasPremiumContent,
      onItemHover,
      onItemClick,
      hideChampionInfo,
      gameTitle,
    ],
  );

  const renderGroupOneSelector = useCallback(
    (index, selectorSize) => {
      const { lineup = [], teamId = '' } = competitors[0] ?? {};
      const player = lineup[index] ?? {};
      return renderSelector(selectorSize, player, teamId, 'teamOne', index);
    },
    [renderSelector, competitors],
  );
  const renderGroupTwoSelector = useCallback(
    (index, selectorSize) => {
      const { lineup = [], teamId = '' } = competitors[1] ?? {};
      const player = lineup[index] ?? {};
      return renderSelector(selectorSize, player, teamId, 'teamTwo', index + 5);
    },
    [renderSelector, competitors],
  );
  const renderRoleIcon = useCallback(index => roles[index], []);

  const { lineup: firstLineup = [] } = competitors[0] || {};
  const { lineup: secondLineup = [] } = competitors[1] || {};
  return (
    <SelectorGroup
      groupOneLength={firstLineup.length}
      groupTwoLength={secondLineup.length}
      renderGroupOneSelector={renderGroupOneSelector}
      renderGroupTwoSelector={renderGroupTwoSelector}
      renderRoleIcon={gameTitle === 'lol' ? renderRoleIcon : undefined}
      groupOneLogo={teamOneLogoSrc}
      groupTwoLogo={teamTwoLogoSrc}
      groupOneName={teamOneName}
      groupTwoName={teamTwoName}
      type={type}
      size={size}
      teamMarkers
    />
  );
};

StatsSelectorGroup.propTypes = {
  type: PropTypes.oneOf(['vertical', 'horizontal']),
  size: PropTypes.oneOf(['small', 'medium']),
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  hasPremiumContent: PropTypes.bool,
  hideChampionInfo: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemHover: PropTypes.func,
  gameTitle: PropTypes.oneOf(['lol', 'csgo']).isRequired,
};

export default StatsSelectorGroup;
