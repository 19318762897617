import { memo, useCallback, useMemo, useState } from 'react';
import usePackageName from 'tv-hooks/usePackageName';
import PropTypes from 'prop-types';
import { useAuthToken } from 'tv-selectors/auth/makeGetAuthToken';
import debounce from 'debounce';
import logger from 'znipe-logger';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { fetchGqlQuery } from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import config from 'tv-config/config';
import MenuPanel from 'znipe-elements/layout/MenuPanel/MenuPanel';
import {
  popContentSubscriptionId,
  pushContentSubscriptionId,
  updateContentSubscriptionId,
} from 'tv-actions/contentSubscriptions';
import { useGetAllContentSubscriptions } from 'tv-selectors/contentSubscriptions/makeGetAllContentSubscriptions';
import { useGetAllContentSubscriptionIds } from 'tv-selectors/contentSubscriptions/makeGetAllContentSubscriptionIds';
import { useGetPackageId } from 'tv-selectors/packages/makeGetPackageId';
import ListWrapper from './ListWrapper';
import { Content } from './Sidebar.styles';
import { fetchSearchResults } from './utils/fetchSearchResults';
import { createInputObject } from './utils/createInputObject';
import Search from './Search';
import { DeleteSubQuery, AddSubQuery, UpdateSubQuery } from './Sidebar.queries';
import { CHAMPION, RIOTCHAMPION, RIOT_CHAMPION } from './Sidebar.constants';

const defaultItems = { name: '', shortName: '', firstName: '', nickname: '', team: '' };

const Sidebar = ({
  onClose = () => undefined,
  title = 'FOLLOWING',
  onMutationChange = () => {},
}) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const authToken = useAuthToken();
  const packageName = usePackageName();
  const packageId = useGetPackageId({ packageName });
  const [searchItems, setSearchItems] = useState([]);
  const [query, setQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const gqlDispatch = useGqlStoreDispatch();

  const allTopicContent = useGetAllContentSubscriptions();
  const contentIds = useGetAllContentSubscriptionIds();

  const fetchOptions = useMemo(
    () => ({
      url: config.GRAPHQL_API_URL,
      headers: { Authorization: `Token ${authToken}` },
    }),
    [authToken],
  );

  const allContentSubscriptions = useMemo(() => {
    const regex = new RegExp(query, 'gi');
    if (!query) return allTopicContent;
    return allTopicContent.filter(
      ({ name = '', shortName = '', firstName = '', nickname = '', team = '' } = defaultItems) => {
        const allStrings = [name, shortName, firstName, nickname, team].join('');
        return allStrings.match(regex);
      },
    );
  }, [query, allTopicContent]);

  const allListItems = useMemo(() => {
    if (query) return searchItems;
    return allContentSubscriptions.concat(searchItems);
  }, [allContentSubscriptions, query, searchItems]);

  const handleSearchChange = useMemo(
    () =>
      debounce(async newQuery => {
        if (newQuery) {
          setSearchLoading(true);
          if (newQuery.length >= 3) setQuery(newQuery);
          const { hits } = await fetchSearchResults(newQuery);
          // eslint-disable-next-line no-underscore-dangle
          setSearchItems(hits.sort((a, b) => (a._score > b._score ? -1 : 1)));
          setSearchLoading(false);
        } else {
          setQuery('');
          setSearchItems([]);
        }
      }, 300),
    [],
  );

  const handleDeleteSubscriptionClick = useCallback(
    async (id, type, nodeId) => {
      try {
        const subType = type === CHAMPION ? RIOTCHAMPION : type;
        const variables = { nodeId };
        await fetchGqlQuery({ query: DeleteSubQuery, variables }, fetchOptions);
        gqlDispatch(popContentSubscriptionId(id, subType));
        onMutationChange(true);
      } catch (error) {
        logger.error(error);
      }
    },
    [fetchOptions, gqlDispatch, onMutationChange],
  );

  const handleAddSubscriptionClick = useCallback(
    async (id, type) => {
      try {
        const subType = type === CHAMPION ? RIOTCHAMPION : type;
        const inputType = type === CHAMPION ? RIOT_CHAMPION : type;
        const variables = { input: createInputObject(subType, id, packageId, false, inputType) };
        await fetchGqlQuery({ query: AddSubQuery, variables }, fetchOptions);
        gqlDispatch(pushContentSubscriptionId(id, subType));
        onMutationChange(true);
      } catch (error) {
        logger.error(error);
      }
    },
    [packageId, fetchOptions, gqlDispatch, onMutationChange],
  );

  const handleNotificationsClick = useCallback(
    async (id, type, nodeId, newMutedState) => {
      try {
        const subType = type === CHAMPION ? RIOTCHAMPION : type;
        const variables = {
          id: nodeId,
          input: createInputObject(type, id, packageId, newMutedState),
        };
        await fetchGqlQuery({ query: UpdateSubQuery, variables }, fetchOptions);
        gqlDispatch(updateContentSubscriptionId(id, subType, newMutedState));
      } catch (error) {
        logger.error(error);
      }
    },
    [packageId, fetchOptions, gqlDispatch],
  );

  const noResultsFound = !searchItems?.length && !!query;

  return (
    <MenuPanel type={isTabletOrGreater ? 'small' : 'scaling'} header={title} onClose={onClose}>
      <Content data-testid="subscriptions-sidebar">
        <Search
          onSearchChange={handleSearchChange}
          noResultsFound={noResultsFound}
          loading={searchLoading}
        />
        <ListWrapper
          listData={allListItems}
          allContentIds={contentIds}
          onDeleteClick={handleDeleteSubscriptionClick}
          onNotificationsClick={handleNotificationsClick}
          onSubscribeClick={handleAddSubscriptionClick}
        />
      </Content>
    </MenuPanel>
  );
};

Sidebar.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onMutationChange: PropTypes.func,
};

export default memo(Sidebar);
