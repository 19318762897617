import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
