import { useState, useMemo, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, Navigate, Routes, useNavigate, useParams, useLocation } from 'react-router-dom';
import useTheme from 'tv-hooks/useTheme';
import withTheme from 'znipe-themes/hocs/withTheme';
import parseURLQueriesByName from 'tv-utils/parseURLQueriesByName';
import LoginForm from 'tv-modules/Authentication/LoginForm/LoginForm';
import RegisterForm from 'tv-modules/Authentication/RegisterForm/RegisterForm';
import ForgotPasswordForm from 'tv-modules/Authentication/ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordForm from 'tv-modules/Authentication/ResetPasswordForm/ResetPasswordForm';
import VerifyEmail from 'tv-modules/Authentication/VerifyEmail/VerifyEmail';
import Fonts from 'znipe-elements/general/Typography/Fonts/Fonts';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import GlobalStyle from 'znipe-styles/global.styles';
import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
// As a temporary solution, we're using the payment flows design
// This will be refactored as soon as the new input field is built
import PaymentHeader from 'tv-modules/Payment/PaymentHeader/PaymentHeader';
import BackButton from 'tv-routes/Subscribe/BackButton';
import {
  PageContainer,
  FormContainer,
  Content,
  CloseWrapper,
} from 'tv-routes/Subscribe/Subscribe.styles';
import themes from 'tv-routes/Subscribe/Subscribe.themes';
import useQueryObject from 'tv-hooks/useQueryObject';

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(true);
  const { pathname, search } = useLocation();
  const { 'redirect-path': queryPath } = useQueryObject();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const navigate = useNavigate();
  const { packageSlug } = useParams();

  const redirectPath = useMemo(() => {
    if (!global.document || !queryPath) return packageSlug ? `/${packageSlug}/home` : '/home';
    return window.decodeURIComponent(queryPath);
  }, [packageSlug, queryPath]);

  const onCloseClick = useCallback(() => {
    navigate(redirectPath);
  }, [navigate, redirectPath]);

  const useThinHeader = useMemo(() => !pathname.match(/.*\/login(\/register)?$/), [pathname]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run once
  useEffect(() => {
    const accountAction = parseURLQueriesByName('mode');
    const actionCode = parseURLQueriesByName('oobCode');
    let actionPath;
    if (accountAction === 'verifyEmail') {
      actionPath = pathname.replace('/action', '/verify-email');
    }
    if (accountAction === 'resetPassword') {
      actionPath = pathname.replace('/action', '/reset-password');
    }
    if (actionPath) {
      navigate(actionPath, { state: { actionCode } });
    }
    // Only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
    const timeout = setTimeout(() => setAnimate(false), 600);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Fonts />
      <GlobalStyle />
      <PageContainer isLoading={loading}>
        <FormContainer>
          {isTabletOrGreater && (
            <CloseWrapper onClick={onCloseClick}>
              <Icon size={32} type="close" fillColor={colors.white} inline />
            </CloseWrapper>
          )}
          <PaymentHeader animationTime={0.4} animate={animate} thin={useThinHeader} />
          <Content>
            {!isTabletOrGreater && (
              <CloseWrapper $mobile onClick={onCloseClick}>
                <Icon size={24} type="close" fillColor={colors.black} inline />
              </CloseWrapper>
            )}
            <Routes>
              <Route path="/" element={<LoginForm continuePath={redirectPath} />} />
              <Route path="register/*" element={<RegisterForm continuePath={redirectPath} />} />
              <Route
                path="forgot-password"
                element={
                  <>
                    <BackButton to={`..${search}`}>Back to Login</BackButton>
                    <ForgotPasswordForm />
                  </>
                }
              />
              <Route path="reset-password" element={<ResetPasswordForm />} />
              <Route path="verify-email" element={<VerifyEmail continuePath={redirectPath} />} />
              <Route path="*" element={<Navigate to={search} />} />
            </Routes>
          </Content>
        </FormContainer>
      </PageContainer>
    </>
  );
};

const LoginWithTheme = withTheme(Login, themes, 'subscribe');

const ThemeWrapper = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <LoginWithTheme />
    </ThemeProvider>
  );
};

// Using subscribe theme as we're using the same layout for now
export default ThemeWrapper;
