import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetVideoToken = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => {
    const timeNow = Date.now();
    if (timeNow > video.tokenExpireTime) return '';
    return video.hlsToken || video.dashToken || '';
  });
};

export const useGetVideoToken = props => useMemoizedGqlSelector(makeGetVideoToken, props);

export default makeGetVideoToken;
