import { memo } from 'react';
import PropTypes from 'prop-types';
import Helmet, { getTitlePackageName } from 'tv-elements/general/Helmet/Helmet';
import { useMatchTeams } from 'tv-selectors/match/makeGetMatchTeams';

const createTitle = (team1, team2, packageName) =>
  `${team1} vs ${team2} | ${getTitlePackageName(packageName)}`;

const createDescription = (team1, team2) =>
  `Watch ${team1} and ${team2} battle it out from the pro's perspectives!`;

const HelmetWrapper = memo(({ matchId, packageName }) => {
  const [team1, team2] = useMatchTeams({ matchId });
  const title = createTitle(team1?.shortName, team2?.shortName, packageName);
  const description = createDescription(team1?.name, team2?.name);
  return <Helmet title={title} description={description} />;
});

HelmetWrapper.propTypes = {
  matchId: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
};

export default memo(HelmetWrapper);
