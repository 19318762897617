const convertSecondsToHMS = totalSeconds => {
  if (typeof totalSeconds !== 'number' || isNaN(totalSeconds)) return null;
  const absTime = Math.abs(totalSeconds);
  const totalTime = Math.floor(absTime);
  const hours = Math.floor(totalTime / 3600);
  const minutes = Math.floor((totalTime - hours * 3600) / 60);
  const seconds = totalTime - hours * 3600 - minutes * 60;

  return { hours, minutes, seconds };
};

export default convertSecondsToHMS;
