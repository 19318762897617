import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedPlaylist from './makeGetSelectedPlaylistV2';

const makeGetPlaylistTitle = () => {
  const getSelectedPlaylist = makeGetSelectedPlaylist();
  return createSelector([getSelectedPlaylist], selectedPlaylist => selectedPlaylist?.title ?? '');
};

export const useGetPlaylistTitle = props => useMemoizedGqlSelector(makeGetPlaylistTitle, props);

export default makeGetPlaylistTitle;
