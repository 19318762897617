import { createSelector } from 'reselect';

const emptyObject = {};
const emptyArray = [];
const getPackages = state => state.packages ?? emptyObject;
const getPackageNameFromProps = (_, props) => props.packageName ?? '';

const makeGetPackageTournaments = () =>
  createSelector(
    [getPackages, getPackageNameFromProps],
    (packages, packageName) => packages[packageName]?.tournaments ?? emptyArray,
  );

export default makeGetPackageTournaments;
