import colors from 'znipe-styles/colors';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import convertHexToHSL from 'znipe-utils/misc/convertHexToHSL';

const gradients = {
  cottonCandy: `linear-gradient(to bottom left, #FFFFFF 0%, ${colors.bubblePink} 100%)`,
  sourApple: `linear-gradient(to bottom left, #FFFFFF 0%, ${colors.beanGreen} 100%)`,
  bubbleBlue: `linear-gradient(to bottom left, #FFFFFF 0%, ${colors.tooBlue} 100%)`,
  peachParty: `linear-gradient(to bottom left, #FFFFFF 0%, ${colors.pastelYellow} 100%)`,
  plushPurple: `linear-gradient(to bottom left, #FFFFFF 0%, ${colors.lavender} 100%)`,
  burgundy: 'linear-gradient(to bottom left, #DA4453 0%, #89216B 100%)',
  orange: 'linear-gradient(to bottom left, #FFB800 0%, #FF5C00 100%)',
  lightBlue: 'linear-gradient(to bottom left, #3DE0E0 0%, #246493 100%)',
  mellowLime: 'linear-gradient(to bottom left, #BBDD5A 0%, #179395 100%)',
  grape: 'linear-gradient(to bottom left, #E361D6 0%, #7E3FCE 100%)',
  dragonSlayer: 'linear-gradient(to bottom left, #C7BBDC 0%, #8246EC 100%)',
  handOfBaron: 'linear-gradient(to bottom left, #DEAFF1 0%, #7C31AE 100%)',
  madEyes: 'linear-gradient(to bottom left, #D77EDF 0%, #942E9D 100%)',
  blazingInferno: 'linear-gradient(to bottom left, #F26E24 0%, #FF5A50 100%)',
  livingOcean: 'linear-gradient(to bottom left, #82FE8F 0%, #67C4AF 100%)',
  quickWinds: 'linear-gradient(to bottom left, #ABD0EC 0%, #48A7FF 100%)',
  risingMountains: 'linear-gradient(to bottom left, #DC8435 0%, #A7805D 100%)',
  hp: 'linear-gradient(to bottom left, #D77EDF 0%, #5EDF50 100%)',
  abilityResource: 'linear-gradient(to bottom left, #1A55AE 0%, #5ECDFD 100%)',
  ravenousRedGradient: 'linear-gradient(to bottom left, #FF7F7F 0%, #DE2F2F 100%)',

  rubySea: 'linear-gradient(90deg, #C31432 0%, #360D53 100%)',
  twillightOcean: 'linear-gradient(90deg, #6116A5 0%, #1D97C2 100%)',
  sunrise: `linear-gradient(90deg, #D46628 0%, ${colors.sunflower} 100%)`,

  // Legacy, cleanup and remove later
  backgroundTeamA: 'linear-gradient(90deg, #11171F 0%, #122334 100%)', // This should not be in this file. Probably use teamAGradiant?
  backgroundTeamB: 'linear-gradient(90deg, #11171F 0%, #272B1E 100%)', // This should not be in this file. Probably use teamBGradiant?
  backgroundAvsB: 'linear-gradient(90deg, #122334 0%, #171C1F 48.96%, #272B1E 100%)', // Renamed to teamVsTeam and added to theme
  seaBreeze: 'linear-gradient(225.34deg, #FFFFFF 0%, #7FF0FF 100.61%)', // Renameed to bubbleBlue
  deepForestGreen: 'linear-gradient(90deg, #171F2B 0%, #062D1D 100%)', // Should be used from theme
  subtleContainer: 'linear-gradient(87.88deg, #121821 0%, #171F2B 100%)', // Use from theme
  unspecifiedGradient: 'linear-gradient(90deg, #0c1117 0%, #273039 100%)', // Should not exist in this file
  cottonCandyHalfOpacity:
    'linear-gradient(225.34deg,rgba(255,255,255,0.5) 0%,rgba(255,127,183,0.5) 101.17%)', // Should not exist in this file
  edgeFadingLine:
    'linear-gradient(90deg, rgba(39, 48, 57, 0) 0%, #273039 51.56%, rgba(39, 48, 57, 0) 100%)', // Should not exist in this file
  playerImageBackground:
    'linear-gradient(180deg,rgba(0,0,0,0) 86%,rgba(0,0,0,0.3) 93.11%,rgba(0,0,0,0.7) 100%)', // Should not exist in this file
  whiteHover:
    'radial-gradient(35.43% 35.43% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);', // Should not exist in this file
  blueHover:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(255, 255, 255, 0.25) 0%, rgba(171, 184, 186, 0.25) 100%);', // Should not exist in this file
  blueActive:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(163, 190, 194, 0.3) 0%, rgba(54, 71, 73, 0.3) 100%);', // Should not exist in this file
  yellowHover:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(255, 255, 255, 0.2) 0%, rgba(186, 180, 171, 0.2) 100%);', // Should not exist in this file
  yellowActive:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(194, 182, 163, 0.2) 0%, rgba(73, 66, 54, 0.2) 100%);', // Should not exist in this file
  lavenderHover:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(255, 255, 255, 0.25) 0%, rgba(177, 171, 186, 0.25) 100%);', // Should not exist in this file
  lavenderActive:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(163, 170, 194, 0.3) 0%, rgba(54, 59, 73, 0.3) 100%);', // Should not exist in this file
  pinkHover:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(255, 255, 255, 0.25) 0%, rgba(186, 171, 178, 0.25) 100%);', // Should not exist in this file
  pinkActive:
    'radial-gradient(50% 49.37% at 50% 49.37%, rgba(194, 163, 176, 0.3) 0%, rgba(73, 54, 62, 0.3) 100%);', // Should not exist in this file
  greenHover: `radial-gradient(50% 49.37% at 50% 49.37%, ${convertHexToRGB(
    colors.white,
    0.25,
  )}, ${convertHexToHSL(colors.beanGreen, { s: 10, l: 70 })} 100%)`, // Should not exist in this file
  greenActive: `radial-gradient(50% 49.37% at 50% 49.37%, ${convertHexToHSL(colors.beanGreen, {
    s: 20,
    l: 70,
  })} , ${convertHexToHSL(colors.beanGreen, {
    s: 15,
    l: 25,
  })} 100%)`, // Should not exist in this file
  bg2Fade: 'linear-gradient(265.24deg, #1B2432 1.21%, #121821 99.02%)', // Should be used from theme
  thumbnailGradient:
    'linear-gradient(360deg, #0C1117 22.55%, rgba(12, 17, 23, 0) 112.98%), radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.39) 100%);', // Should not exist in this file
  searchResultItemHover:
    'linear-gradient(180deg, rgba(182, 185, 191, 0) 0%, rgba(182, 185, 191, 0.1) 53.12%)', // Should not exist in this file
  teamOrder: 'linear-gradient(90deg, #14171A 0%, #05202E 100%)', // Not in this file (Maybe theme.teamAColor)
  teamChaos: 'linear-gradient(90deg, #14171A 0%, #160304 100%)', // Not in this file (Maybe theme.teamBColor)
  proviewBg2Fade: `linear-gradient(85.34deg, ${
    colors.pvBackgroundSecondary
  } 1.73%, ${convertHexToHSL(colors.pvBackgroundSecondary, { l: 14 })} 99.78%)`, // Should be used from theme
  proviewSubtleContainer: `linear-gradient(87.88deg, ${colors.pvBackgroundSecondary} 0%, ${colors.pvBackgroundTertiary} 100%)`, // Should be used from theme
  simpleFade: `linear-gradient(180deg, transparent 0%, ${colors.black} 100%)`,
  glimmeringGold: `linear-gradient(44.89deg, #d2a256 7.67%, #f4dfad 100%)`,
  glimmeringGoldFade: `linear-gradient(135deg, transparent 0%, #DEB774 100%)`,
  imageFadeout: `linear-gradient(
    256deg,
    ${colors.white} 44.24%,
    ${convertHexToRGB(colors.white, 0.56)} 53.87%,
    ${convertHexToRGB(colors.white, 0.17)} 62.5%,
    ${convertHexToRGB(colors.white, 0)} 70.07%
  )`,
};

const defaultTheme = theme => {
  const teamASecondColor = '#122334';
  const teamBSecondColor = '#272B1E';
  return {
    teamAGradient: `linear-gradient(to bottom left, #7FF0FF 0%, ${theme.teamOneColor} 100%)`,
    teamBGradient: `linear-gradient(to bottom left, #FFCC7F 0%, ${theme.teamTwoColor} 100%)`,
    backgroundTeamA: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamASecondColor} 100%)`,
    backgroundTeamB: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamBSecondColor} 100%)`,
    teamVsTeam: `linear-gradient(to right, ${teamASecondColor}  0%, ${theme.secondaryBackgroundColor}  50%, ${teamBSecondColor} 100%)`,
    deepForestGreen: `linear-gradient(to left, #052D1D 0%, ${theme.tertiaryBackgroundColor} 100%)`,
    deepRubyRed: `linear-gradient(to left, #2E030D 0%, ${theme.tertiaryBackgroundColor} 100%)`,
    subtleContainer: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${theme.tertiaryBackgroundColor} 100%)`,
    onboardingGradient: gradients.orange,
    bg2Fade: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${convertHexToHSL(
      theme.secondaryBackgroundColor,
      { l: 5, addMode: true },
    )} 100%)`,
    bg2Pop: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${convertHexToHSL(
      theme.secondaryBackgroundColor,
      { s: 27, l: 23 },
    )} 100%)`,
    bg3Transparency: `linear-gradient(to bottom right, ${
      theme.secondaryBackgroundColor
    }  0%, ${convertHexToRGB(theme.tertiaryBackgroundColor, 0)} 100%)`,
    tertiaryToHighlight: `linear-gradient(115.82deg, ${convertHexToRGB(
      theme.tertiaryBackgroundColor,
      0.3,
    )} 0%, ${convertHexToRGB(theme.highlight, 0.3)} 100%)`,
    tertiaryToTeamA: `linear-gradient(115.82deg, ${convertHexToRGB(
      theme.tertiaryBackgroundColor,
      0.3,
    )} 0%, ${convertHexToRGB(theme.teamOneColor, 0.3)} 100%)`,
    tertiaryToAmberYellow: `linear-gradient(115.82deg, ${convertHexToRGB(
      theme.tertiaryBackgroundColor,
      0.3,
    )} 0%, ${convertHexToRGB(theme.amberYellow, 0.3)} 100%)`,
    tertiaryToErrorRed: `linear-gradient(115.82deg, ${convertHexToRGB(
      theme.tertiaryBackgroundColor,
      0.3,
    )} 0%, ${convertHexToRGB(theme.errorRed, 0.3)} 100%)`,
  };
};

const proviewTheme = theme => {
  const teamASecondColor = convertHexToHSL(theme.teamOneColor, { l: 10 });
  const teamBSecondColor = convertHexToHSL(theme.teamTwoColor, { l: 5 });
  return {
    teamAGradient: `linear-gradient(to bottom left, #7FF0FF 0%, ${theme.teamOneColor} 100%)`,
    teamBGradient: `linear-gradient(to bottom left, #FF7F7F 0%, ${theme.teamTwoColor} 100%)`,
    backgroundTeamA: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamASecondColor}  100%)`,
    backgroundTeamB: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamBSecondColor} 100%)`,
    teamVsTeam: `linear-gradient(to right, ${teamASecondColor}  0%, ${theme.secondaryBackgroundColor}  50%, ${teamBSecondColor} 100%)`,
  };
};

const esportalTheme = theme => {
  const teamASecondColor = convertHexToHSL(theme.teamOneColor, { l: 10 });
  const teamBSecondColor = convertHexToHSL(theme.teamTwoColor, { l: 5 });
  return {
    teamAGradient: `linear-gradient(to bottom left, #A1D8F6 0%, ${theme.teamOneColor} 100%)`,
    teamBGradient: `linear-gradient(to bottom left, #FFF963 0%, ${theme.teamTwoColor} 100%)`,
    // All below are same as proview theme, Should we re-use them?
    backgroundTeamA: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamASecondColor}  100%)`,
    backgroundTeamB: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamBSecondColor} 100%)`,
    teamVsTeam: `linear-gradient(to right, ${teamASecondColor}  0%, ${theme.secondaryBackgroundColor}  50%, ${teamBSecondColor} 100%)`,
  };
};

const efgTheme = theme => {
  const teamASecondColor = convertHexToHSL(theme.teamOneColor, { l: 10 });
  const teamBSecondColor = convertHexToHSL(theme.teamTwoColor, { l: 5 });
  return {
    teamAGradient: `linear-gradient(to bottom left, #A1D8F6 0%, ${theme.teamOneColor} 100%)`,
    teamBGradient: `linear-gradient(to bottom left, #FFF963 0%, ${theme.teamTwoColor} 100%)`,
    // All below are same as proview theme, Should we re-use them?
    backgroundTeamA: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamASecondColor}  100%)`,
    backgroundTeamB: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamBSecondColor} 100%)`,
    teamVsTeam: `linear-gradient(to right, ${teamASecondColor}  0%, ${theme.secondaryBackgroundColor}  50%, ${teamBSecondColor} 100%)`,
  };
};

const svenskaCupenTheme = theme => {
  const teamASecondColor = convertHexToHSL(theme.teamOneColor, { l: 10 });
  const teamBSecondColor = convertHexToHSL(theme.teamTwoColor, { l: 10 });
  return {
    teamVsTeam: `linear-gradient(to right, ${teamASecondColor}  0%, ${theme.secondaryBackgroundColor}  50%, ${teamBSecondColor} 100%)`,
  };
};

const faceitTheme = theme => {
  const teamASecondColor = convertHexToHSL(theme.teamOneColor, { l: 10 });
  const teamBSecondColor = convertHexToHSL(theme.teamTwoColor, { l: 10 });
  const UIAccentLight = convertHexToHSL(theme.UIAccent, { l: 60 });
  return {
    teamAGradient: `linear-gradient(to bottom left, #45C7FF 0%, ${theme.teamOneColor} 100%)`,
    teamBGradient: `linear-gradient(to bottom left, #FFDA4F 0%, ${theme.teamTwoColor} 100%)`,
    backgroundTeamA: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamASecondColor}  100%)`,
    backgroundTeamB: `linear-gradient(to right, ${theme.secondaryBackgroundColor}  0%, ${teamBSecondColor} 100%)`,
    teamVsTeam: `linear-gradient(to right, ${teamASecondColor}  0%, ${theme.secondaryBackgroundColor}  50%, ${teamBSecondColor} 100%)`,
    onboardingGradient: `linear-gradient(to right, ${colors.faceitUIAccent} 0%, ${UIAccentLight} 100%)`,
  };
};

const themeMap = {
  esportal: esportalTheme,
  efg: efgTheme,
  proview: proviewTheme,
  'svenska-cupen': svenskaCupenTheme,
  faceit: faceitTheme,
};

export const getThemableGradients = theme => ({
  ...defaultTheme(theme),
  ...(themeMap[theme.themeName]?.(theme) ?? {}),
});

export default gradients;
