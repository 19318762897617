import { useEffect } from 'react';
import useGatId from 'tv-hooks/useGatId';
import { resetGat } from 'tv-actions/intensive';
import { removeGatFromFetchedData } from 'tv-epics/statsUpdateEpic';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';

const useResetGatStats = (matchId, selectedMatchGameIndex) => {
  const intensiveDispatch = useIntensiveDispatch();
  const gatId = useGatId(matchId, selectedMatchGameIndex);
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });

  const selectedGatId = gatId || gameId;

  useEffect(
    () => () => {
      intensiveDispatch(resetGat(selectedGatId));
      removeGatFromFetchedData(selectedGatId);
    },
    [intensiveDispatch, selectedGatId],
  );
};

export default useResetGatStats;
