import { shallowEqual } from 'react-redux';

/* This is a custom memozie function used for reselect.
--------------------------------------------
  const createSelector = createSelectorCreator(
    strictMemoize,
    (a, b) => a === b,
    (a, b) => a.length === b.length,
    shallowEqual,
    deepEqual,
  );

  const selector = createSelector(
    [strictArg, lengthArg, shallowArg, deepArg) => {...}
  );
--------------------------------------------

 Where argument 1-x is the corresponding equality check function for the dependeancy.
 */

const areArgumentsShallowlyEqual = (equalityFuncs, prev, next) => {
  if (prev === null || next === null || prev.length !== next.length) {
    return false;
  }

  // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
  for (let i = 0; i < prev.length; i++) {
    const equalityFunc = equalityFuncs[i] ?? shallowEqual;
    if (!equalityFunc(prev[i], next[i])) {
      return false;
    }
  }

  return true;
};

const strictMemoize = (func, ...equalityFuncs) => {
  let lastArgs = null;
  let lastResult = null;
  return (...args) => {
    const argsCopy = [...args];
    if (!areArgumentsShallowlyEqual(equalityFuncs, lastArgs, argsCopy)) {
      lastResult = func(...args);
    }

    lastArgs = argsCopy;
    return lastResult;
  };
};

export default strictMemoize;
