import { batch } from 'react-redux';
import { setCompetitors } from 'tv-actions/competitors';
import { setEvents } from 'tv-actions/events';
import { setFeaturedContent } from 'tv-actions/featured';
import { setStreams } from 'tv-actions/streams';
import { setVideos, setStageVideos } from 'tv-actions/videos';
import { setMatches } from 'tv-actions/matches';
import { setPlaylist } from 'tv-actions/playlists';
import { setTeams, setSingleTeam } from 'tv-actions/teams';
import { setTournaments } from 'tv-actions/tournament';
import { setGames } from 'tv-actions/games';
import { setPage } from 'tv-actions/page';
import { setPlayers, setSinglePlayer } from 'tv-actions/players';
import { setRoster } from 'tv-actions/roster';
import { setGlobalStreams } from 'tv-actions/globalStreams';
import { setContentSubscriptions } from 'tv-actions/contentSubscriptions';
import { setPackages } from 'tv-actions/packages';
import { setChampions } from 'tv-actions/champions';
import { setTournamentStages } from 'tv-actions/tournamentStages';
import { setChannels } from 'tv-actions/channels';
import deepmerge from 'deepmerge';

export const dispatchGqlData = (data = {}, options) => {
  const {
    champion,
    champions,
    competitors,
    contentSubscription,
    data: pageItems,
    event,
    events,
    featuredContents,
    featuredHighlight,
    games,
    globalStreams,
    highlights,
    matches,
    packages,
    player,
    playerDetails,
    players,
    playlists,
    roster,
    rosterPlayers,
    streamUrls,
    team,
    teams,
    tournaments,
    videos,
    tournamentStages,
    channels,
  } = data;

  return dispatch => {
    batch(() => {
      if (playerDetails || players || rosterPlayers) {
        dispatch(setPlayers(deepmerge(playerDetails || {}, rosterPlayers || {}, players || {})));
      }
      if (player) {
        dispatch(setSinglePlayer(player));
      }
      if (teams) {
        dispatch(setTeams(teams));
      }
      if (team) {
        dispatch(setSingleTeam(team));
      }
      if (competitors) {
        dispatch(setCompetitors(competitors));
      }
      if (event || events) {
        dispatch(setEvents({ ...(event || {}), ...(events || {}) }));
      }
      if (featuredContents) {
        dispatch(setFeaturedContent(featuredContents));
      }
      if (games) {
        dispatch(setGames(games));
      }
      if (globalStreams) {
        dispatch(setGlobalStreams(globalStreams));
      }
      if (globalStreams || streamUrls) {
        dispatch(setStreams(globalStreams, streamUrls));
      }
      if (globalStreams) {
        dispatch(setGlobalStreams(globalStreams));
      }
      if (highlights && featuredHighlight) {
        dispatch(setStageVideos(highlights, featuredHighlight));
      }
      if (highlights || (videos && !featuredHighlight)) {
        dispatch(setVideos({ ...highlights, ...videos }));
      }
      if (matches) {
        dispatch(setMatches(matches));
      }
      if (players) {
        dispatch(setPlayers(players));
      }
      if (playlists) {
        dispatch(setPlaylist(playlists));
      }
      if (roster) {
        dispatch(setRoster(roster));
      }
      if (tournamentStages) {
        dispatch(setTournamentStages(tournamentStages));
      }
      if (tournaments) {
        dispatch(setTournaments(tournaments));
      }
      if (pageItems) {
        dispatch(setPage(pageItems, options));
      }
      if (contentSubscription) {
        dispatch(setContentSubscriptions(contentSubscription));
      }
      if (packages) {
        dispatch(setPackages(packages));
      }
      if (champion || champions) {
        dispatch(setChampions({ ...(champions || {}), ...(champion || {}) }));
      }
      if (channels) {
        dispatch(setChannels(channels));
      }
    });
  };
};
