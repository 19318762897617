const themes = {
  default: {
    bookCover: {
      headline: 'Statistics',
      paragraphs: [
        'Get a complete overview of the match with real-time stats like a live scoreboard.',
        "Dive into a player's stats to find out if they are crushing their opponent.\n",
      ],
      buttonLabel: 'Got it',
      headerImage: 'https://assets.znipe.tv/onboarding/csgo/BookCoverFlourish_stats.png',
    },
  },
  proview: {
    bookCover: {
      headline: 'Statistics',
      paragraphs: [
        'Get a complete overview of the match with real-time stats like gold difference.',
        'Dive into a player’s stats to find out if they are crushing their opponent.',
      ],
      buttonLabel: 'Got it',
      headerImage: 'https://assets.znipe.tv/onboarding/lol/stats-coverbook-header.svg',
    },
  },
};

export default themes;
