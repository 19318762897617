import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const emptyArray = [];

const makeGetVodMatches = () => {
  const getPage = makeGetPage();
  return createSelector([getPage], page => page?.vodMatches ?? emptyArray);
};

/**
 *
 * @param {{pageType: string}} props The page type to filter on, i.e. "home"
 */
export const useGetVodMatches = props => useMemoizedGqlSelector(makeGetVodMatches, props);

export default makeGetVodMatches;
