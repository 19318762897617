import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const emptyArray = [];

const getLimitFromProps = (_, props) => props?.limit;

const makeGetLatestMatches = () => {
  const getPage = makeGetPage();
  return createSelector([getPage, getLimitFromProps], (page, limit) => {
    if (limit) {
      return page?.latestMatches?.slice(0, limit) ?? emptyArray;
    }
    return page?.latestMatches ?? emptyArray;
  });
};

/**
 *
 * @param {{pageType: string}} props The page type to filter on, i.e. "home"
 */
export const useGetLatestMatches = props => useMemoizedGqlSelector(makeGetLatestMatches, props);

export default makeGetLatestMatches;
