import { memo, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import useMatchLink from 'tv-hooks/useMatchLink';
import {
  Container,
  Description,
  ActionsContainer,
  ContentWrapper,
  Border,
} from './VideoDescription.styles';

const VideoDescription = ({ title = '', description = '', matchId = '' }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const navigate = useNavigate();
  const matchLink = useMatchLink(matchId);

  const sizes = useMemo(() => {
    const mediumVersion = {
      title: 'heading-m',
      description: 'paragraph-l',
      button: 'small',
    };

    const smallVersion = {
      title: 'heading-s',
      description: 'paragraph-m',
      button: 'xsmall',
    };

    if (isDesktopLargeOrGreater) return mediumVersion;
    if (isDesktopOrGreater) return smallVersion;
    if (isTabletOrGreater) return mediumVersion;
    return smallVersion;
  }, [isDesktopLargeOrGreater, isDesktopOrGreater, isTabletOrGreater]);

  const fullMatchLinkClick = useCallback(() => {
    navigate(matchLink);
  }, [navigate, matchLink]);

  return (
    <Container data-testid="video-description-container" isDesktop={isDesktopOrGreater}>
      <ContentWrapper>
        <Typography dataTestId="video-description-title" type={sizes.title}>
          {title}
        </Typography>
        <Description>
          <Typography dataTestId="video-description-description" type={sizes.description}>
            {description}
          </Typography>
        </Description>
      </ContentWrapper>
      {isDesktopOrGreater && matchId && <Border data-testid="video-description-border" />}
      <ActionsContainer isDesktop={isDesktopOrGreater}>
        {/* <Button variant="secondary" size={sizes.button} icon="share">
          Share
        </Button> */}
        {matchId && (
          <Button
            variant="text"
            size={sizes.button}
            icon="chevronRight"
            iconPosition="right"
            onClick={fullMatchLinkClick}
          >
            Watch full match
          </Button>
        )}
      </ActionsContainer>
    </Container>
  );
};

VideoDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  matchId: PropTypes.string,
};

export default memo(VideoDescription);
