import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetUIMap from 'tv-selectors/ui/makeGetUIMap';

const makeGetIsCompactPlayer = () => {
  const getUIMap = makeGetUIMap();
  return createSelector([getUIMap], ui => ui.isCompactPlayer);
};

export const useGetIsCompactPlayer = props => useMemoizedSelector(makeGetIsCompactPlayer, props);

export default makeGetIsCompactPlayer;
