import { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError && process.env.NODE_ENV !== 'production') {
      const RedBox = require('redbox-react').default; // eslint-disable-line global-require
      // You can render any custom fallback UI
      return <RedBox error={error} />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
