import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PremiumContentContext from 'tv-contexts/PremiumContentContext';
import useSubscribePageLink from 'tv-hooks/useSubscribePageLink';

const useOnPremiumAction = (onClickAction, isLocked) => {
  const subscribePageLink = useSubscribePageLink();
  const { userHasPremiumAccess, onUnAvailableAction } = useContext(PremiumContentContext);
  const navigate = useNavigate();

  const goToSubscribePage = useCallback(() => {
    navigate(subscribePageLink);
  }, [subscribePageLink, navigate]);

  const selectedPremiumAction = onUnAvailableAction || goToSubscribePage;

  if (!userHasPremiumAccess && isLocked) {
    return selectedPremiumAction;
  }

  return onClickAction;
};

export default useOnPremiumAction;
