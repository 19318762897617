import { useMemo } from 'react';
import useDeepCompareEffect from 'znipe-hooks/useDeepCompareEffect';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import factoryBowserEpic from 'tv-utils/factoryBowserEpic';
import { CSGO_STATE } from 'tv-reducers/intensive';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import CSGOQuery from 'znipe-gql/queries/bowser/csgo.graphql';

export const SUBSCRIBE_CSGO_STATS = 'SUBSCRIBE_CSGO_STATS';
export const UNSUBSCRIBE_CSGO_STATS = 'UNSUBSCRIBE_CSGO_STATS';
export const GAME_PERFORMANCE = 'gamePerformance';
export const PLAYER_UTILITY_LEVEL = 'playerUtilityLevel';
export const TEAM_UTILITY_LEVEL = 'teamUtilityLevel';
export const ROUND_SUMMARY = 'roundSummary';
export const HIGHLIGHT_LIST = 'highlightList';
export const DEAD_PLAYER_HANDLING = 'deadPlayerHandling';
export const BOMB_TIMER = 'bombTimer';
export const PLAYER_KDA = 'playerKda';
export const SCOREBOARD = 'scoreboard';
export const SCENARIOS = 'scenarios';

const modules = [
  GAME_PERFORMANCE,
  PLAYER_UTILITY_LEVEL,
  TEAM_UTILITY_LEVEL,
  ROUND_SUMMARY,
  HIGHLIGHT_LIST,
  DEAD_PLAYER_HANDLING,
  BOMB_TIMER,
  PLAYER_KDA,
  SCOREBOARD,
  SCENARIOS,
];

export const useCSGOStatsEpic = (gameId, moduleType, variables = {}) => {
  const id = useMemo(() => generateUniqueId(), []);
  const dispatch = useIntensiveDispatch();
  useDeepCompareEffect(() => {
    if (!gameId || !modules.includes(moduleType)) return undefined;
    dispatch({ type: SUBSCRIBE_CSGO_STATS, gameId, moduleType, variables, id });
    return () => dispatch({ type: UNSUBSCRIBE_CSGO_STATS, gameId, moduleType, variables, id });
  }, [dispatch, gameId, variables, id]);
};

const csgoStatsEpic = factoryBowserEpic(
  SUBSCRIBE_CSGO_STATS,
  UNSUBSCRIBE_CSGO_STATS,
  CSGO_STATE,
  CSGOQuery,
);

export default csgoStatsEpic;
