import getDayDifference from 'znipe-utils/date/getDayDifference';
import isToday from 'znipe-utils/date/isToday';
import isYesterday from 'znipe-utils/date/isYesterday';
import isTomorrow from 'znipe-utils/date/isTomorrow';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Relative date formatting
 * Full specs at https://www.notion.so/znipe/Date-Formatting-f3eddc1f1652441db21a8132607fa851
 * @param {Date} today Today's date as a ISO timestamp or date object
 * @param {Date} targetDate The target date as a ISO timestamp or date object
 * @returns {string} The relative time between today and end date, i.e. "yesterday"
 */
const getRelativeDate = (targetDate, today = new Date()) => {
  if (!targetDate) {
    throw Error('A target date must be defined');
  }

  const diff = getDayDifference(today, targetDate);

  // Recent dates
  if (isToday(new Date(targetDate))) {
    return 'today';
  }
  if (isTomorrow(new Date(targetDate))) {
    return 'tomorrow';
  }
  if (isYesterday(new Date(targetDate))) {
    return 'yesterday';
  }
  if (diff === -2) {
    return '2 days ago';
  }
  if (diff === -3) {
    return '3 days ago';
  }
  const dateObj = new Date(targetDate);
  const todayDateObj = new Date(today);

  const monthIndex = dateObj.getMonth();
  const dayIndex = dateObj.getDay();
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  if (year === todayDateObj.getFullYear()) {
    return `${days[dayIndex]}, ${day} ${monthNames[monthIndex]}`;
  }
  return `${days[dayIndex]}, ${day} ${monthNames[monthIndex]} ${year}`;
};

export default getRelativeDate;
