import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import prefers from 'znipe-styles/prefers';

const SMALL = 'small';
const MEDIUM = 'medium';

const FlexStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  border-radius: 5px;
  overflow: hidden;
  ${FlexStyle};
  position: relative;
  padding: 16px 0;
  width: 100%;
  ${({ size }) => {
    switch (size) {
      case MEDIUM:
        return css`
          min-width: 192px;
          max-width: 400px;
        `;
      case SMALL:
        return css`
          min-width: 160px;
          max-width: 296px;
        `;
      default:
        return css`
          min-width: 128px;
          max-width: 159px;
        `;
    }
  }}
`;

export const Title = styled(Typography)``;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.secondaryTextColor};
  text-transform: capitalize;
`;

export const IconWrapper = styled.div`
  align-self: flex-end;
  margin-right: 16px;
`;

export const AvatarWrapper = styled.div`
  width: 40%;
  min-width: ${({ size }) => {
    switch (size) {
      case MEDIUM:
        return '80px';
      case SMALL:
        return '72px';
      default:
        return '64px';
    }
  }};
`;

export const Section = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  ${FlexStyle}
`;

export const Shimmer = styled.div`
  position: absolute;
  height: 200px;
  width: 600px;
  left: -150px;
  top: 0;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(-30deg);
  z-index: 2;
`;

export const BodySection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ size }) => (size === MEDIUM ? 24 : 12)}px;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
  @media ${prefers.motion} {
    transition: 0.3s opacity;
  }
`;

export const GradientOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.secondaryBackgroundColor} 67.71%,
    rgba(18, 24, 33, 0) 100%
  );
`;

export const TextContainer = styled.div`
  z-index: 1;
  width: 100%;
  ${({ size }) => {
    switch (size) {
      case MEDIUM:
        return css`
          max-width: 172px;
        `;
      case SMALL:
        return css`
          max-width: 140px;
        `;
      default:
        return css`
          max-width: 108px;
        `;
    }
  }}
`;

export const PreviewWrapper = styled.div`
  opacity: ${({ $isHovering }) => ($isHovering ? 1 : 0)};
  @media ${prefers.motion} {
    transition: 0.3s opacity;
  }
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80%;
  width: 100%;
  video {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;
