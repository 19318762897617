import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, Button, IconWrapper, OptionWrapper } from './PostTab.styles';

const emptyCallback = () => {};

const PostTab = ({
  tabs,
  width = '60px',
  centerAlign = '',
  customWidth = '',
  selectedOption = '',
  isInline = false,
  onClickOption,
}) => {
  const [showOptions, setShowOptions] = useState(null);

  const handleClickOption = e => {
    if (onClickOption) onClickOption(e);
    setShowOptions(!showOptions);
  };

  return (
    <Container
      width={width}
      centerAlign={centerAlign}
      customWidth={customWidth}
      elementWidth="60px"
    >
      <Button
        onClick={() => setShowOptions(!showOptions)}
        role="button"
        tabIndex="0"
        onKeyPress={null}
        width={width}
        data-testid="post-tab-button"
      >
        <Typography type="title-xs">{selectedOption || tabs[0]}</Typography>
        <IconWrapper>
          <Icon type="arrowUpDown" size={16} />
        </IconWrapper>
      </Button>
      {showOptions && (
        <OptionWrapper isInline={isInline} role="listbox" tabIndex="0">
          {tabs.map(option => (
            <option
              key={option}
              id={option}
              onClick={handleClickOption}
              data-testid="post-tab-option"
              onKeyUp={emptyCallback}
              onKeyDown={emptyCallback}
            >
              {option}
            </option>
          ))}
        </OptionWrapper>
      )}
    </Container>
  );
};

PostTab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.string,
  centerAlign: PropTypes.string,
  customWidth: PropTypes.string,
  selectedOption: PropTypes.string,
  isInline: PropTypes.bool,
  onClickOption: PropTypes.func,
};

export default memo(PostTab);
