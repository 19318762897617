import { useCallback, useMemo, useState } from 'react';
import getResizeUrl from 'znipe-utils/misc/getResizeURL';
import styled, { css } from 'styled-components';

const sizes = {
  small: 12,
  medium: 16,
};

type AvatarImageProps = {
  $size?: keyof typeof sizes;
  $borderType?: string;
  $hidden?: boolean;
};

const AvatarImage = styled.img<AvatarImageProps>`
  user-select: none;
  box-sizing: border-box;
  height: ${({ $size }) => ($size != null ? sizes[$size] : sizes.small)}px;
  width: ${({ $size }) => ($size != null ? sizes[$size] : sizes.small)}px;
  color: transparent;
  ${({ $hidden }) =>
    $hidden &&
    css`
      display: none;
    `}
  ${({ $borderType }) =>
    $borderType === 'round' &&
    css`
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.containerStroke};
      background: ${({ theme }) => theme.tertiaryBackgroundColor};
    `}
`;

type AvatarProps = {
  src: string;
  size?: keyof typeof sizes;
  borderType?: string;
};
const Avatar: React.FC<AvatarProps> = ({ src, size = 'small', borderType = 'round' }) => {
  const [loadFailed, setLoadFailed] = useState(false);
  const resizeSrc = useMemo(() => {
    const isSVG = src && src.endsWith('.svg');
    if (isSVG) return src;
    return getResizeUrl(src, size != null ? sizes[size] : sizes.small);
  }, [src, size]);

  const handleError = useCallback(() => (!loadFailed ? setLoadFailed(true) : null), [loadFailed]);

  return (
    <AvatarImage
      src={resizeSrc}
      alt="Chat avatar"
      $size={size}
      $borderType={borderType}
      $hidden={loadFailed}
      onError={handleError}
    />
  );
};

export default Avatar;
