import { useEffect, useCallback, useState } from 'react';
import { batch } from 'react-redux';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import { useAuthToken } from 'tv-selectors/auth/makeGetAuthToken';
import { setStreamTokens } from 'tv-actions/streams';
import { setVideoTokens } from 'tv-actions/videos';
import config from 'tv-config/config';
import logger from 'znipe-logger';

const { QUARTERBACK_API_URL } = config;
const CREDENTIALS_ENDPOINT = `${QUARTERBACK_API_URL}/v4/auth/tokens/akamai/streams`;
const INTERVAL_TIME = 270000;

const useFetchVideoCredentials = (requestedVideo, shouldUseInterval = false) => {
  const isAuthenticated = useIsAuthenticated();
  const userToken = useAuthToken();
  const dispatch = useGqlStoreDispatch();
  const [loading, setLoading] = useState(!!requestedVideo);

  const getToken = useCallback(
    (tokenRequest, onSuccess) => {
      if (!tokenRequest || Object.values(tokenRequest).length === 0) {
        logger.error('Expecting the request input to be an object containing ids');
        return;
      }

      const { matches: requestedMatches, videos: requestVideos } = tokenRequest || {};

      if (
        (!requestedMatches && !requestVideos) ||
        (requestedMatches?.length === 0 && requestVideos?.length === 0)
      )
        return;

      const headers = {
        'Content-Type': 'application/json',
      };

      if (userToken) headers.Authorization = `Token ${userToken}`;

      setLoading(true);

      fetch(CREDENTIALS_ENDPOINT, {
        method: 'POST',
        headers,
        body: JSON.stringify(tokenRequest),
      })
        .then(res => res.json())
        .then(({ results }) => {
          const { matches, videos } = results || {};
          const tokenExpireTime = Date.now() + INTERVAL_TIME;
          const videoTokens = {};
          const streamTokens = {};
          if (matches?.length > 0) {
            matches.forEach(currentMatch => {
              const { tokens } = currentMatch || [];
              tokens?.forEach(currentToken => {
                streamTokens[currentToken.streamId] = {
                  tokenExpireTime,
                  hls: {
                    token: currentToken.hls,
                  },
                  dash: {
                    token: currentToken.dash,
                  },
                };
              });
            });
          }
          if (videos?.length > 0) {
            videos.forEach(currentMatch => {
              const { tokens: currentToken } = currentMatch || [];
              videoTokens[currentToken.streamId] = {
                tokenExpireTime,
                hlsToken: currentToken.hls,
                dashToken: currentToken.dash,
              };
            });
          }
          batch(() => {
            dispatch(setStreamTokens(streamTokens));
            dispatch(setVideoTokens(videoTokens));
          });

          if (onSuccess) onSuccess();
          setLoading(false);
        })
        .catch(err => {
          logger.error('Could not fetch token', err);
          setLoading(false);
        });
    },
    [userToken, dispatch],
  );

  useEffect(() => {
    const { REQUIRES_AUTH } = config;
    const requiresAuth =
      typeof REQUIRES_AUTH === 'string' ? REQUIRES_AUTH === 'true' : Boolean(REQUIRES_AUTH);

    const needsToAuthenticate = requiresAuth && !isAuthenticated;
    if (needsToAuthenticate) {
      logger.error('User need to be signed in to fetch credentials');
      return () => null;
    }

    if (requestedVideo) getToken(requestedVideo);
    if (shouldUseInterval) {
      // set up fetching interval
      const interval = setInterval(() => getToken(requestedVideo), INTERVAL_TIME);
      return () => clearInterval(interval);
    }
    return () => null;
  }, [requestedVideo, shouldUseInterval, isAuthenticated, getToken]);
  return { fetchToken: getToken, loading };
};

export default useFetchVideoCredentials;
