import { createSelector } from 'reselect';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const emptyObject = {};

const makeGetSelectedTeam = () => {
  const getTeams = makeGetTeams();
  const getProps = makeGetProps();
  return createSelector([getTeams, getProps], (teams, props) => {
    const teamId = props.teamId || props.teamID;
    return teams[teamId] || emptyObject;
  });
};

export default makeGetSelectedTeam;
