import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const makeGetTeamFoundedDate = () => {
  const getTeams = makeGetTeams();
  return createSelector([getTeams, getTeamIdFromProps], (allTeams, teamId) => {
    if (!allTeams || !teamId) return '';
    return allTeams[teamId]?.founded?.split('T')[0] ?? null;
  });
};

export const useGetTeamFoundedDate = props => useMemoizedGqlSelector(makeGetTeamFoundedDate, props);

export default makeGetTeamFoundedDate;
