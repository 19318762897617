// eslint-disable-next-line import/prefer-default-export
export const highlightTableSide = (predictedValue, outcome, optionValue) => {
  if (outcome === predictedValue) {
    if (predictedValue === optionValue) {
      return true;
    }
  }
  if (predictedValue === optionValue) {
    return true;
  }
  return false;
};
