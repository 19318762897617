import { useLocation } from 'react-router-dom';
import useThemeContext from 'znipe-hooks/useThemeContext';

const usePageColors = () => {
  const theme = useThemeContext();
  const { pathname = '' } = useLocation();
  const pageColors = theme.colorSchemeMap || {};
  if (pathname === '/') return theme.colorSchemeMap.home || theme.colorSchemeMap.default;

  const pathnameLC = pathname.toLowerCase();
  const pathArr = pathnameLC.split('/');
  const currentPageName = pathArr.find(subPath => subPath && pageColors[subPath]) || '';

  return pageColors[currentPageName] || pageColors.default || {};
};

export default usePageColors;
