import isBrowser from 'znipe-utils/web/isBrowser';
// Config file that reads from system environments on server-side and global config on client side.
const config = {
  NODE_ENV: process.env.NODE_ENV,
  QUARTERBACK_API_URL: process.env.QUARTERBACK_API_URL,
  RYDER_API_URL: process.env.RYDER_API_URL,
  ...((isBrowser() ? window : global).process?.env ?? {}),
};

if (config.NODE_ENV !== 'production') {
  config.QUARTERBACK_API_URL = config.QUARTERBACK_API_URL || 'https://quarterback.espoespo.com';
  config.RYDER_API_URL = config.RYDER_API_URL || 'https://ryder.staging.znipe.tv';
}

export default config;
