export const singletonPadding = '24px 0';

export const desktopExtraLargeSizes = {
  featuredMatch: 'xlarge',
  featuredHighlight: 'large',
  matchRerun: 'xlarge',
  playlist: 'large',
  vodItem: 'large',
  highlightClip: 'medium',
  typeSize: 'title-m',
  numberOfVisibleHighlights: 8,
  numberOfVisiblePlaylists: 4,
  numberOfEditorials: 4,
  playerCardSize: 'large',
  topicLinkSize: 'medium',
  topicCardSize: 'medium',
  championCardSize: 'small',
};

export const desktopLargeSizes = {
  featuredMatch: 'xlarge',
  featuredHighlight: 'large',
  matchRerun: 'xlarge',
  playlist: 'large',
  vodItem: 'large',
  highlightClip: 'medium',
  typeSize: 'title-m',
  numberOfVisibleHighlights: 6,
  numberOfVisiblePlaylists: 4,
  numberOfEditorials: 3,
  playerCardSize: 'medium',
  topicLinkSize: 'medium',
  topicCardSize: 'small',
  championCardSize: 'medium',
};

export const desktopSizes = {
  featuredMatch: 'medium',
  featuredHighlight: 'large',
  matchRerun: 'medium',
  playlist: 'medium',
  vodItem: 'medium',
  highlightClip: 'small',
  typeSize: 'title-s',
  numberOfVisibleHighlights: 6,
  numberOfVisiblePlaylists: 4,
  numberOfEditorials: 3,
  playerCardSize: 'medium',
  topicLinkSize: 'medium',
  topicCardSize: 'small',
  championCardSize: 'medium',
};

export const tabletSizes = {
  featuredMatch: 'medium',
  featuredHighlight: 'small',
  matchRerun: 'medium',
  playlist: 'medium',
  vodItem: 'medium',
  highlightClip: 'small',
  typeSize: 'title-xs',
  numberOfVisibleHighlights: 4,
  numberOfVisiblePlaylists: 3,
  numberOfEditorials: 4,
  playerCardSize: 'large',
  topicLinkSize: 'medium',
  topicCardSize: 'small',
  championCardSize: 'medium',
};

export const mobileSizes = {
  featuredMatch: 'small',
  featuredHighlight: 'small',
  matchRerun: 'small',
  playlist: 'small',
  vodItem: 'small',
  highlightClip: 'small',
  typeSize: 'title-xs',
  numberOfVisibleHighlights: 3,
  numberOfVisiblePlaylists: 2,
  numberOfEditorials: 3,
  playerCardSize: 'medium',
  topicLinkSize: 'medium',
  topicCardSize: 'small',
  championCardSize: 'medium',
};

export const hints = [
  {
    hintContainer: '#desktop-search',
    label: 'Search',
    description: 'Find any content',
    height: 'tall',
    direction: 'left',
    position: 'bottom',
    positionOffset: 10,
  },
  {
    hintContainer: '#masthead-navigation-browse',
    label: 'Browse',
    description: 'All the players, teams, champions and more',
    height: 'tall',
    direction: 'left',
    position: 'bottom',
    positionOffset: 2,
  },
  {
    hintContainer: '#tab-my-feed',
    label: 'My Feed',
    description: 'Your personalized content',
    height: 'medium',
    direction: 'left',
    position: 'bottom',
    positionOffset: 2,
  },
  {
    hintContainer: '#tab-discover',
    label: 'Discover',
    description: 'The latest content',
    height: 'medium',
    direction: 'right',
    position: 'bottom',
    positionOffset: 2,
  },
];

export const HOME_WELCOME_STEP_ID = 'home-welcome';
export const DISCOVER = 'Discover';
export const MY_FEED = 'My feed';
export const ME = 'me';
export const EDIT_TEXT = 'Edit';
export const MY_FEED_TARGET_ID = 'my-feed-banner';
