import { schema } from 'normalizr';
import character from './character.normalizr';
import item from './item.normalizr';
import player, { createPlayerObject } from './player.normalizr';
import spell from './spell.normalizr';
import team from './team.normalizr';

export const createStatsObject = (gameId, roundValue, stats, playerInfo) => {
  const playerObject = createPlayerObject(playerInfo);
  return {
    ...stats,
    id: `${gameId}:${roundValue}:${playerObject.id}`,
    round: roundValue,
    player: playerObject,
  };
};

export const statKeysToIncrement = [
  'weaponDamage',
  'headshotDamage',
  'damageArmor',
  'utilityDamage',
  'kills',
  'deaths',
  'gameTime',
];
export const addTogether = (val1, val2) => (val1 ?? 0) + (val2 ?? 0);

const mergeStats = (oldStats, newStats) => ({
  ...oldStats,
  ...newStats,
  ...statKeysToIncrement
    .filter(key => key in newStats)
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: addTogether(oldStats?.[key], newStats?.[key]),
      }),
      {},
    ),
});

const stat = new schema.Entity(
  'stats',
  {
    player,
    team,
    items: [item],
    spell1: spell,
    spell2: spell,
    character,
  },
  {
    mergeStrategy: mergeStats,
  },
);

export default stat;
