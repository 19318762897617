import styled, { css } from 'styled-components';

type SVGProps = {
  hoverColor?: string;
  activeColor?: string;
  useStroke?: boolean;
};
const SVG = styled.svg.attrs(() => ({ className: 'styled-component-icon__svg' }))<SVGProps>`
  width: auto;
  height: 100%;

  &:hover,
  :focus {
    ${props =>
      props.hoverColor &&
      css`
        ${props.useStroke ? `stroke: ${props.hoverColor}` : `fill: ${props.hoverColor}`};
      `}
  }
  &:active {
    ${props =>
      props.activeColor &&
      css`
        ${props.useStroke ? `stroke: ${props.activeColor}` : `fill: ${props.activeColor}`};
      `};
  }
`;

type PathProps = {
  hoverColor?: string;
  activeColor?: string;
};
/* stylelint-disable selector-type-no-unknown, a11y/selector-pseudo-class-focus */
export const Path = styled.path.attrs(() => ({
  className: 'styled-component-icon__path',
}))<PathProps>`
  ${SVG}:hover & {
    ${props => props.hoverColor && `stroke: ${props.hoverColor}`};
  }
  ${SVG}:focus & {
    ${props => props.hoverColor && `stroke: ${props.hoverColor}`};
  }
  ${SVG}:active & {
    ${props => props.activeColor && `stroke: ${props.activeColor}`};
  }
`;
/* stylelint-enable selector-type-no-unknown, a11y/selector-pseudo-class-focus */

export default SVG;
