import colors from 'znipe-styles/colors';
import gradients, { getThemableGradients } from 'znipe-styles/gradients';
import defaultTheme from './default.theme';
import { Theme } from './theme.types';

const theme: Theme = {
  ...defaultTheme,
  themeName: 'znipebase',
  mobileOnly: false,
  primaryFont: "'Barlow', sans-serif",
  secondaryFont: 'Titillium Web',

  teamOneColor: colors.znipeBlue,
  teamTwoColor: colors.rogueRed,

  buttonPrimary: colors.znipeBlue,

  // Background Colors
  primaryBackgroundColor: colors.baseBackgroundPrimary,
  secondaryBackgroundColor: colors.baseBackgroundSecondary,
  tertiaryBackgroundColor: colors.baseBackgroundTertiary,
  containerStroke: colors.gloomyGrey,
  highlight: colors.steelBlue,

  // Accents
  UIAccent: colors.sunflower,
  errorRed: colors.rogueRed,
  errorLight: colors.coral,
  amberYellow: colors.peeled,
  successGreen: colors.goGreen,

  // TODO cleanup
  bodyBackground: colors.gorgeousGrey,
  bodyColor: colors.paperGrey,
  secondaryColor: colors.slateGrey,
  dashboardItemColor: colors.white,
  pageWidth: '1200px',
  listItemDividerColor: colors.gloomyGrey,
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment , @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
  eventListItemBackgroundColor: (colors as any).grey, // @TODO fix
  eventListItemBorderColor: colors.onyxGrey,
  eventListItemPrimaryTextColor: colors.white,
  eventListItemSecondaryTextColor: colors.midGrey,
  eventListItemTertiaryTextColor: colors.darkGrey,
  borderColor: colors.onyxGrey,
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment , @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
  autosuggestInputBackgroundColor: (colors as any).darkBlue, // @TODO fix
  autosuggestInputTextColor: colors.darkGrey,
  colorSchemeMap: {
    default: {
      gradient: gradients.sunrise,
      rootColor: colors.sunflower,
      hoverGradient: gradients.blueHover,
      activeGradient: gradients.blueActive,
    },
  },
};

export default { ...theme, ...getThemableGradients(theme) } as Theme; // @TODO remove type assertion once getThemableGradients has proper type;
