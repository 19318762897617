import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: ${({ $type, $isDesktopOrGreater }) => {
    switch ($type) {
      case 'teams':
      case 'events':
        return 'repeat(4, 1fr)}';
      case 'champions':
      case 'players':
        return $isDesktopOrGreater ? 'repeat(6, 1fr)}' : 'repeat(4, 1fr)}';
      default:
        return 'repeat(6, 1fr)}';
    }
  }};
`;

const ListWrapperElement = ({
  shouldUseWrapper = false,
  type = '',
  isDesktopOrGreater = true,
  children = [],
}) => {
  if (shouldUseWrapper)
    return (
      <ListWrapper $type={type} $isDesktopOrGreater={isDesktopOrGreater}>
        {children}
      </ListWrapper>
    );
  return children;
};

ListWrapperElement.propTypes = {
  shouldUseWrapper: PropTypes.bool,
  type: PropTypes.string,
  isDesktopOrGreater: PropTypes.bool,
  children: PropTypes.node,
};

export default ListWrapperElement;
