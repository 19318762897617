import { useEffect } from 'react';
import { from, of, BehaviorSubject } from 'rxjs';
import { map, filter, exhaustMap, pairwise, startWith } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { TOTAL_DAMAGE_TO_CHAMPIONS } from 'tv-modules/Stats/LOL/constants';
import { TIME_SERIES_EVENT } from 'tv-reducers/intensive';
import getTimeSeriesInfo from './utils/getTimeSeriesInfo';
import createLolPlayer from './utils/createLolPlayer';
import createLolCharacter from './utils/createLolCharacter';
import createLolItem from './utils/createLolItem';
import createLolSpell from './utils/createLolSpell';
import createCombinedId from './utils/createCombinedId';

export const SUBSCRIBE_STATS_UPDATE = 'SUBSCRIBE_STATS_UPDATE';
export const UNSUBSCRIBE_STATS_UPDATE = 'UNSUBSCRIBE_STATS_UPDATE';

const emptyArray = [];

export const useStatsUpdateEpic = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_STATS_UPDATE, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_STATS_UPDATE, gatId });
  }, [dispatch, gatId]);
};

const fetchedData = new BehaviorSubject({});

export const addFetchedData = (keys, gatId) => {
  const current = fetchedData.value;
  const set = current?.[gatId] ?? new Set();
  keys.forEach(key => set.add(key));
  current[gatId] = set;
  fetchedData.next(current);
};

export const removeGatFromFetchedData = gatId => {
  const current = fetchedData.value;
  if (current[gatId]) delete current[gatId];

  fetchedData.next(current);
};

const setupFirebaseFetch = (prevState, newState, gatId) => {
  const prevGameTime = prevState?.gats?.[gatId]?.gameTime;
  const gameTime = newState?.gats?.[gatId]?.gameTime;
  if (prevGameTime === gameTime || !gameTime || gameTime < 0) return of(emptyArray);
  const { id, gameTime: groupedGameTime } = getTimeSeriesInfo(gatId, gameTime);
  const { id: nextId } = getTimeSeriesInfo(gatId, gameTime + 5);
  const cache = fetchedData.value?.[gatId];
  if (cache?.has(id) && cache?.has(nextId)) return of(emptyArray);
  return from(
    firebase
      .database()
      .ref(`/gat/${gatId}`)
      .orderByChild('gameTime')
      .startAt(groupedGameTime * 1000)
      .limitToFirst(30)
      .once('value'),
  ).pipe(
    map(snap => snap.val()),
    filter(Boolean),
    map(values => Object.values(values)),
    map(values => values.filter(({ actionType }) => actionType === 'stats_update')),
  );
};

const playerValuesToSumUp = [TOTAL_DAMAGE_TO_CHAMPIONS];
const createTeam = (team, players, gatId) => {
  const id = team.teamID || team.teamId || team.id;
  const extraValues = playerValuesToSumUp.reduce((acc, key) => {
    const sum = players
      .filter(({ teamID }) => teamID === id)
      .map(player => player[key])
      .filter(val => typeof val === 'number')
      .reduce((a, b) => a + b, 0);
    acc[key] = sum;
    return acc;
  }, {});
  return {
    ...team,
    gold: team.totalGold,
    kills: team.championsKills,
    id: createCombinedId(id, gatId),
    ...extraValues,
  };
};

// @TODO Normalize names of properties. Maybe in gat transformer?
const createPlayer = (player, index, gatId) => ({
  ...player,
  kills: player.CHAMPIONS_KILLED,
  assists: player.ASSISTS,
  deaths: player.NUM_DEATHS,
  gold: player.totalGold,
  id: createCombinedId(
    // @TODO Do not use participantId later. Aldo don't use index in lol....
    player.participantID ?? player.playerID ?? player.playerId ?? player.id ?? index + 1,
    gatId,
  ),
  teamId: createCombinedId(player.teamID, gatId),
  name: player.summonerName,
  spell1: createLolSpell(player.summonerSpell1),
  spell1Cooldown: player.summonerSpell1?.coolDownRemaining,
  spell2: createLolSpell(player.summonerSpell2),
  spell2Cooldown: player.summonerSpell2?.coolDownRemaining,
  items: player.items?.map(createLolItem),
  character: createLolCharacter(player),
});

export const createTimeSeriesObject = (statsUpdate, gatId) => {
  const { details = {}, gameTime } = statsUpdate;
  const { teams, participants } = details;
  const { id, gameTime: groupedGameTime } = getTimeSeriesInfo(gatId, gameTime / 1000);
  return {
    id,
    gameId: gatId,
    gameTime: groupedGameTime,
    teams: teams.map(team => createTeam(team, participants, gatId)),
    players: participants.map((player, index) => createPlayer(player, index, gatId)),
  };
};

const emptyObject = {};
const timeSeriesEpic = factorySubscribeEpic(
  [SUBSCRIBE_STATS_UPDATE, UNSUBSCRIBE_STATS_UPDATE],
  ({ gatId }) => gatId,
  ({ gatId }, state$) =>
    state$.pipe(
      startWith(emptyObject),
      pairwise(),
      exhaustMap(([prevState, newState]) => setupFirebaseFetch(prevState, newState, gatId)),
      filter(val => Boolean(val?.length)),
    ),
  ({ gatId }) =>
    payload => {
      const data = payload[0];
      const timeSeriesById = data
        .map(statsUpdate => createTimeSeriesObject(statsUpdate, gatId))
        .reduce((acc, entry) => {
          const cache = fetchedData.value?.[gatId];
          const { id } = entry;
          if (cache?.has(id)) return acc;
          acc[id] = entry;
          return acc;
        }, {});
      const ids = Object.keys(timeSeriesById);
      const timeSeries = Object.values(timeSeriesById);
      addFetchedData(ids, gatId);
      const players = timeSeries[0]?.players?.map(createLolPlayer) ?? emptyArray;

      return {
        type: TIME_SERIES_EVENT,
        gatId,
        payload: { id: gatId, timeSeries, players },
      };
    },
);

export default timeSeriesEpic;
