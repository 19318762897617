import { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import Chat, { ChatProvider } from 'znipe-chat';
import { useAuthToken } from 'tv-selectors/auth/makeGetAuthToken';
import { useUsername } from 'tv-selectors/auth/makeGetUsername';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useChatColor } from 'tv-selectors/auth/makeGetChatColor';
import { useChatAvatar } from 'tv-selectors/auth/makeGetChatAvatar';
import { useIsAdmin } from 'tv-selectors/auth/makeGetIsAdmin';
import { useGetMatchTournamentId } from 'tv-selectors/match/makeGetMatchTournamentId';
import Slide from 'znipe-elements/layout/Animation/Slide/Slide';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import Header from 'tv-elements/layout/Header/Header';
import config from 'tv-config/config';
import useGqlQuery from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import useOnLoginClick from 'tv-modules/Authentication/LoginButton/useOnLoginClick';
import chatAvatarsQuery from './chatAvatars.graphql';
import { ChatContainer, ChatContainerWrapper } from './Stage.styles';

const emptyArray = [];
const emptyObject = {};

const ChatSideMenu = forwardRef(
  ({ chatId, visible = false, onClose, matchId = '', type = 'right' }, ref) => {
    const authToken = useAuthToken();
    const username = useUsername();
    const userId = useUserId();
    const color = useChatColor();
    const avatar = useChatAvatar();
    const isAdmin = useIsAdmin();
    const tournamentId = useGetMatchTournamentId({ matchId });
    const onLoginClick = useOnLoginClick();

    const preferencesRef = useMemo(() => {
      if (!userId) return null;
      return firebase.database().ref('users').child(userId).child('preferences').child('chat');
    }, [userId]);

    const onSettingsChange = useCallback(
      (value, key) => {
        if (!preferencesRef || !key || !value) return;
        preferencesRef.child(key).set(value);
      },
      [preferencesRef],
    );

    const gqlOptions = useMemo(
      () => ({
        url: config.GRAPHQL_API_URL,
        ssr: false,
        variables: { tournamentId },
      }),
      [tournamentId],
    );

    const { data } = useGqlQuery(chatAvatarsQuery, gqlOptions);

    const { avatars = emptyArray, featuredAvatars = emptyArray } = useMemo(() => {
      const { chatAvatars } = data || {};
      if (!chatAvatars) return emptyObject;

      return chatAvatars.reduce(
        (acc, chatAvatar) => {
          const { id, label, labelShort, url, urlDark, featured, isDefault } = chatAvatar;

          const newAvatar = {
            id,
            label,
            default: isDefault,
            shortLabel: labelShort ?? label,
            img: url ?? urlDark,
          };

          if (featured) acc.featuredAvatars.push(newAvatar);
          acc.avatars.push(newAvatar);

          return acc;
        },
        { avatars: [], featuredAvatars: [] },
      );
    }, [data]);

    const account = useMemo(
      () => ({
        username,
        uid: userId,
        isAdmin,
      }),
      [username, userId, isAdmin],
    );

    const isBottomType = type === 'bottom';

    return (
      <ChatProvider
        chatId={chatId}
        authToken={authToken}
        featuredAvatars={featuredAvatars}
        avatars={avatars}
        account={account}
        color={color}
        avatar={avatar}
        onSettingsChange={onSettingsChange}
        onLoginClick={onLoginClick}
      >
        <Slide
          show={visible}
          type={type}
          width={isBottomType ? undefined : 'auto'}
          height={isBottomType ? 'auto' : undefined}
          position={isBottomType ? 'fixed' : 'absolute'}
          ref={ref}
        >
          <ChatContainerWrapper>
            <ChatContainer>
              <StickyComponent enableStickyScroll independent>
                <Header heading="Chat" onClose={onClose} />
              </StickyComponent>
              <Chat showAllegiance={!!authToken} />
            </ChatContainer>
          </ChatContainerWrapper>
        </Slide>
      </ChatProvider>
    );
  },
);

ChatSideMenu.propTypes = {
  chatId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  matchId: PropTypes.string,
  type: PropTypes.oneOf(['bottom', 'right']),
};

export default ChatSideMenu;
