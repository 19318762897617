import styled from 'styled-components';
import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';

type ContainerProps = {
  $color: string;
};

export const Container = styled.div<ContainerProps>`
  background: ${({ $color }) => $color};
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ColorItemProps = {
  hexCode: string;
  isLocked?: boolean;
};

const ColorItem: React.FC<ColorItemProps> = ({ hexCode, isLocked = false }) => (
  <Container $color={hexCode}>
    {isLocked && <Icon type="lockIcon" size={14} fillColor={colors.black} inline />}
  </Container>
);

export default ColorItem;
