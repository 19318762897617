import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSearch from 'tv-selectors/search/makeGetSearch';

const emptyArray = [];

const makeGetSearchHistoryQueries = () => {
  const getSearch = makeGetSearch();
  return createSelector([getSearch], search => search.historyQueries ?? emptyArray);
};

export const useGetSearchHistoryQueries = () => useMemoizedGqlSelector(makeGetSearchHistoryQueries);

export default makeGetSearchHistoryQueries;
