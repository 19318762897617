import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetVideoTitle = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => video.title || '');
};

export const useGetVideoTitle = props => useMemoizedGqlSelector(makeGetVideoTitle, props);

export default makeGetVideoTitle;
