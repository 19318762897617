export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const AUTO = 'auto';
export const SIZES = [SMALL, MEDIUM, LARGE];

export const FOLLOW = 'follow';
export const FOLLOWING = 'following';
export const UNFOLLOW = 'unfollow';

export const HELMET_DESCRIPTIONS = {
  proview: 'Find content specifically related to one of many topics available on Pro View.',
  default: 'Find content specifically related to one of many topics available on Znipe TV.',
};

export const LOGIN_CTA = {
  default: {
    heading: 'Sign in to follow topics',
    description: 'You need to sign in in order to follow topics',
    icon: 'znipeLogo',
  },
  proview: {
    heading: 'Sign in to follow topics',
    description: 'You need to sign with you Riot account in in order to follow topics',
    icon: 'riotFistbump',
  },
};
