import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const getSize = ({ size }) => {
  switch (size) {
    case 'xsmall':
      return css`
        width: 4px;
        height: 4px;
      `;

    case 'small':
      return css`
        width: 6px;
        height: 6px;
      `;

    default:
      return css`
        width: 8px;
        height: 8px;
      `;
  }
};

const LiveDotStyle = styled.span`
  background-color: ${props => props.theme.liveColor};
  border-radius: 100%;
  ${getSize}
`;

const LiveDot = ({ size = 'small' }) => <LiveDotStyle data-testid="live-dot" size={size} />;

LiveDot.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium']),
};

export default LiveDot;
