import produce from 'immer';
import { SET_EVENT } from 'tv-constants/redux';

export const initialState = {};

const eventsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_EVENT: {
      Object.entries(payload).forEach(([key, value]) => (state[key] = value));
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default eventsReducer;
