import { schema } from 'normalizr';

const generatePauseId = ({ sequenceId }, { id }) => `${id}:${sequenceId}`;
const pause = new schema.Entity(
  'pauses',
  {},
  {
    idAttribute: generatePauseId,
    processStrategy: (values, parent) => ({
      ...values,
      id: generatePauseId(values, parent),
      gameId: values.gameTime / 1000,
    }),
  },
);

export default pause;
