import { createSelector } from 'reselect';
import makeGetMatchAvailableGamesInfo from 'tv-selectors/match/makeGetMatchAvailableGamesInfo';
import makeGetCompetitors from 'tv-selectors/competitors/makeGetCompetitors';

const emptyObject = {};

const makeGetMatchTeamsCompetitors = () => {
  const getCompetitors = makeGetCompetitors();
  const getMatchAvailableGamesInfo = makeGetMatchAvailableGamesInfo();
  return createSelector([getCompetitors, getMatchAvailableGamesInfo], (competitors, games) => {
    if (games.length === 0) return emptyObject;
    const teamCompetitors = {};
    games.forEach(game => {
      (game.competitors || []).forEach(competitorId => {
        const competitorInfo = competitors[competitorId];
        const compTeamId = competitorInfo.teamId;
        const currentCompVal = teamCompetitors[compTeamId];
        if (currentCompVal) {
          teamCompetitors[compTeamId] = currentCompVal.concat([competitorId]);
        } else {
          teamCompetitors[compTeamId] = [competitorId];
        }
      });
    });

    return teamCompetitors;
  });
};

export default makeGetMatchTeamsCompetitors;
