import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-bottom: 4px;
  flex-direction: ${({ $iconPosition }) => ($iconPosition === 'right' ? 'row-reverse' : 'row')};
  transform: perspective(5px) rotateX(3deg);
`;

const buttonSizing = css`
  min-width: 174px;
  min-height: 24px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0;
`;

const buttonPositioning = css`
  transform: perspective(5px) rotateX(-3deg);
  position: relative;
  top: -1.5;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const buttonBorders = css`
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const baseStyles = css`
  background: ${convertHexToRGB(colors.white, 0.1)};
  color: ${({ theme }) => theme.primaryTextColor};
  text-transform: uppercase;
  cursor: pointer;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const Component = styled.button`
  ${buttonPositioning}
  ${buttonBorders}
  ${baseStyles}
  ${buttonSizing}

  ${({ disabled, $hoverGradient }) =>
    disabled
      ? css`
          cursor: not-allowed;
          opacity: 0.5;
        `
      : css`
          &:hover {
            outline: none;
            background: ${$hoverGradient};
          }
          &:active,
          &:focus {
            outline: none;
          }
        `}
    ${({ $isToggledOn, $hoverGradient }) => {
      if ($isToggledOn) {
        return css`
        background: ${$hoverGradient};
      `;
      }
      return css``;
    }}
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
