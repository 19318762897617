import { SET_PLAYERS, SET_SINGLE_PLAYER } from 'tv-constants/redux';

export const setPlayers = (players = {}) => ({
  type: SET_PLAYERS,
  payload: players,
});

export const setSinglePlayer = (player = {}) => ({
  type: SET_SINGLE_PLAYER,
  payload: player,
});
