import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetContentSubscriptions from 'tv-selectors/contentSubscriptions/makeGetContentSubscriptions';

const emptyArray = [];

const makeGetSubscribedTeamsIds = () => {
  const getContentSubscriptions = makeGetContentSubscriptions();
  return createSelector([getContentSubscriptions], subscriptions => {
    if (!subscriptions?.team) return emptyArray;
    const ids = Object.keys(subscriptions.team);
    return ids ?? emptyArray;
  });
};

export const useGetSubscribedTeamsIds = props =>
  useMemoizedGqlSelector(makeGetSubscribedTeamsIds, props);

export default makeGetSubscribedTeamsIds;
