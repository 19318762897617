import {
  playerNodeReducer,
  teamNodeReducer,
  tournamentNodeReducer,
  championNodeReducer,
} from 'tv-utils/formatGqlNode';

const formatFilterSubmenuData = result => {
  const { players, teams, events, champions } = result;
  return {
    players: {
      hits: players?.edges.reduce(playerNodeReducer, []),
      pageInfo: players?.pageInfo,
    },
    teams: { hits: teams?.edges.reduce(teamNodeReducer, []), pageInfo: teams?.pageInfo },
    events: { hits: events?.edges.reduce(tournamentNodeReducer, []) },
    champions: {
      hits: champions?.edges.reduce(championNodeReducer, []),
      pageInfo: champions?.pageInfo,
    },
  };
};

export default formatFilterSubmenuData;
