import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px 16px 16px;
  z-index: 1;

  border-top: solid 1px ${({ theme }) => theme.containerStroke};
  p {
    margin: 0;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
`;
