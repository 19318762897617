import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import { device } from 'znipe-styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled(Typography)`
  text-transform: ${({ $capitalize }) => ($capitalize ? 'capitalize' : 'initial')};
  color: ${({ theme }) => theme.secondaryTextColor};
  margin: 0;
`;

export const Title = styled(Typography)`
  margin: 0 8px 0 0;
  @media ${device.desktop} {
    margin: 0 16px 0 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
