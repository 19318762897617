import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';
import makeGetTeamRoster from 'tv-selectors/team/makeGetTeamRoster';

const emptyArray = [];

const makeGetTeamMates = () => {
  const getPlayers = makeGetPlayers();
  const getTeamRoster = makeGetTeamRoster();
  return createSelector([getPlayers, getTeamRoster], (allPlayers, teamRoster) => {
    if (!teamRoster?.length) return emptyArray;
    return teamRoster?.map(id => allPlayers[id]).sort((a, b) => (a.nickname < b.nickname ? -1 : 1));
  });
};

export const useGetTeamMates = props => useMemoizedGqlSelector(makeGetTeamMates, props);

export default makeGetTeamMates;
