import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import ListItemLoading from './ListItem.loading';
import { ItemsList } from './Sidebar.styles';

const itemTypes = ['playerDetails', 'eventDetails', 'teamDetails'];

const ListWrapper = ({
  listData = null,
  onDeleteClick,
  onNotificationsClick,
  onSubscribeClick,
  allContentIds = [],
}) => {
  const generateListItems = useCallback(
    item => {
      if (!item) return null;
      const {
        id,
        nickname,
        firstName,
        nameLong,
        name,
        imageSrc,
        image,
        team,
        teamName,
        shortName,
        country,
        logoSrc,
        logoLight,
        logo,
        title,
        _type,
        champId,
        logoDark,
      } = item || {};

      const isSubscribed = allContentIds.includes(champId || id);
      let type = '';

      itemTypes.forEach(key => {
        if (item[key]) {
          type = key.replace('Details', '');
        } else if (_type?.slice(0, -1) === 'riotChampion' || item.champId) {
          type = 'champion';
        } else if (_type) {
          type = _type?.slice(0, -1);
        }
      });

      const itemTitle = nickname || name || firstName || shortName;
      const itemSubtitle = team || teamName || nameLong || country || title;
      const itemImage = imageSrc || image || logo || logoSrc || logoLight || logoDark;

      return (
        <ListItem
          key={id}
          title={itemTitle}
          type={type}
          champId={champId}
          hideLabel
          subtitle={itemSubtitle}
          direction="horizontal"
          imageUrl={itemImage}
          onDeleteClick={onDeleteClick}
          onNotificationsClick={onNotificationsClick}
          onSubscribeClick={onSubscribeClick}
          isSubscribed={isSubscribed}
          id={id}
        />
      );
    },
    [allContentIds, onDeleteClick, onNotificationsClick, onSubscribeClick],
  );

  if (!listData)
    return (
      <ItemsList data-testid="list-wrapper">
        <ListItemLoading />
        <ListItemLoading />
        <ListItemLoading />
      </ItemsList>
    );

  return <ItemsList data-testid="list-wrapper">{listData.map(generateListItems)}</ItemsList>;
};

ListWrapper.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  onNotificationsClick: PropTypes.func.isRequired,
  onSubscribeClick: PropTypes.func.isRequired,
  allContentIds: PropTypes.arrayOf(PropTypes.string),
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      _type: PropTypes.string,
    }),
  ),
};

export default memo(ListWrapper);
