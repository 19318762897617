import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import withTheme from 'znipe-themes/hocs/withTheme';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import CelebrationImage from 'tv-modules/Payment/CelebrationImage/CelebrationImage';
import Description from 'tv-modules/Payment/SharedStyles/Description';
import Header from 'tv-modules/Payment/SharedStyles/Header';
import {
  Wrapper,
  FreeMonthsInfo,
  CelebrationImageWrapper,
  StyledPaymentTypeButton,
} from './SelectPaymentMethod.styles';
import themes from './SelectPaymentMethod.themes';

const SelectPaymentMethod = ({
  header = '',
  description,
  freeMonthsText,
  disablePromoCode = false,
  disableImage = false,
}) => {
  const hasFreeMonthInfo = !!freeMonthsText;
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const { search } = useLocation();

  const createUrl = useCallback(path => `../payment/${path}${search}`, [search]);

  return (
    <Wrapper data-testid="select-payment-method">
      {header && <Header>{header}</Header>}
      {description && <Description data-testid="description">{description}</Description>}
      {!disableImage && (!isTabletOrGreater || !hasFreeMonthInfo) && (
        <CelebrationImageWrapper>
          <CelebrationImage size="72px" />
        </CelebrationImageWrapper>
      )}
      <StyledPaymentTypeButton
        data-testid="credit-card-button"
        icon="creditCard"
        label="Add credit or debit card"
        marginTop={isTabletOrGreater && hasFreeMonthInfo}
        to={createUrl('card')}
      />
      <StyledPaymentTypeButton
        data-testid="paypal-button"
        icon="paypal"
        label="Add PayPal"
        to={createUrl('paypal')}
      />
      {!disablePromoCode && (
        <StyledPaymentTypeButton
          data-testid="promo-code-button"
          icon="heart"
          label="Redeem Promo Code"
          transparent
          to={createUrl('coupon')}
        />
      )}
      {freeMonthsText && (
        <FreeMonthsInfo data-testid="free-months-info">
          {isTabletOrGreater && (
            <CelebrationImageWrapper>
              <CelebrationImage size="48px" />
            </CelebrationImageWrapper>
          )}
          {freeMonthsText}
        </FreeMonthsInfo>
      )}
    </Wrapper>
  );
};

SelectPaymentMethod.propTypes = {
  header: PropTypes.node,
  description: PropTypes.node,
  freeMonthsText: PropTypes.string,
  disablePromoCode: PropTypes.bool,
  disableImage: PropTypes.bool,
};

export default withTheme(memo(SelectPaymentMethod), themes, 'selectPaymentMethod');
