import { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useSpring, animated, config } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import Typography from 'znipe-elements/general/Typography/Typography';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import { LEFT, RIGHT, PADDING } from './PremiumBanner.constants';
import {
  Container,
  TextWrapper,
  IconWrapper,
  Wrapper,
  NonAnimateContainer,
} from './PremiumBanner.styles';

const AnimatedContainer = animated(Container);
const AnimatedTextWrapper = animated(TextWrapper);

const PremiumBanner = ({
  text = 'premium',
  direction = LEFT,
  icon = 'zap',
  targetRef,
  animate = true,
}) => {
  const [fullWidth, setFullWidth] = useState(0);
  const [iconWidth, setIconWidth] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const hasPremiumAccess = useHasPremiumAccess();

  const { width, opacity } = useSpring({
    width: isHovering ? fullWidth : iconWidth,
    opacity: isHovering ? 1 : 0,
    config: config.stiff,
    immediate: prefersReducedMotion,
  });

  const handleHoverEnter = useCallback(() => setIsHovering(true), []);
  const handleHoverLeave = useCallback(() => setIsHovering(false), []);

  // set mouse events on parent
  useEffect(() => {
    const target = (targetRef || {}).current;
    if (!target) return () => {};
    target.addEventListener('mouseenter', handleHoverEnter);
    target.addEventListener('mouseleave', handleHoverLeave);
    return () => {
      target.removeEventListener('mouseenter', handleHoverEnter);
      target.removeEventListener('mouseleave', handleHoverLeave);
    };
  }, [targetRef, handleHoverEnter, handleHoverLeave]);

  const callbackRef = useCallback((node, callback) => {
    if (node !== null) {
      const children = Array.from(node.children);
      const totalSize = children.reduce((result = 0, child) => {
        let copy = result;
        const padding = PADDING * 2;
        copy += (child?.scrollWidth ?? 0) + padding;
        return copy;
      }, 0);
      const offset = children.length > 1 ? -10 : 0;
      callback(totalSize + offset);
    }
  }, []);

  if (hasPremiumAccess) return null;

  if (!animate) {
    return (
      <NonAnimateContainer>
        <Icon type="zap" size={16} />
        <Typography type="title-xs">{text}</Typography>
      </NonAnimateContainer>
    );
  }

  return (
    <AnimatedContainer
      style={{ width }}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      data-testid="banner-container"
      aria-label={text}
    >
      <Wrapper
        ref={ref => callbackRef(ref, setFullWidth)}
        $direction={direction}
        data-testid="banner-wrapper"
      >
        <IconWrapper
          ref={ref => callbackRef(ref, setIconWidth)}
          aria-label={`${icon} icon`}
          data-testid="icon-wrapper"
        >
          <Icon inline size={16} type={icon} fillColor={colors.grey10} />
        </IconWrapper>
        <AnimatedTextWrapper
          style={{
            opacity,
          }}
          data-testid="text-wrapper"
        >
          <Typography type="title-xs" color={colors.grey10} dataTestId="banner-text">
            {text}
          </Typography>
        </AnimatedTextWrapper>
      </Wrapper>
    </AnimatedContainer>
  );
};

PremiumBanner.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  direction: PropTypes.oneOf([LEFT, RIGHT]),
  animate: PropTypes.bool,
  targetRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};

export default memo(PremiumBanner);
