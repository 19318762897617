import {
  SET_CONTENT_SUBSCRIPTIONS,
  POP_CONTENT_SUBSCRIPTIONS,
  PUSH_CONTENT_SUBSCRIPTIONS,
  UPDATE_CONTENT_SUBSCRIPTIONS,
} from 'tv-constants/redux';

export const setContentSubscriptions = (subscriptions = {}) => ({
  type: SET_CONTENT_SUBSCRIPTIONS,
  payload: subscriptions,
});

export const popContentSubscriptionId = (id = '', type = '') => ({
  type: POP_CONTENT_SUBSCRIPTIONS,
  payload: { id, type },
});

export const pushContentSubscriptionId = (id = '', type = '') => ({
  type: PUSH_CONTENT_SUBSCRIPTIONS,
  payload: { id, type },
});

export const updateContentSubscriptionId = (id = '', type = '', muteState = false) => ({
  type: UPDATE_CONTENT_SUBSCRIPTIONS,
  payload: { id, type, muteState },
});
