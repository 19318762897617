import { createSelector } from 'reselect';

const emptyObject = {};

const getState = state => state;

const makeGetContentSubscriptions = () =>
  createSelector([getState], state => state.contentSubscriptions ?? emptyObject);

export default makeGetContentSubscriptions;
