import { useGetProductSubscriptionPrice } from 'tv-selectors/products/makeGetProductSubscriptionPrice';
import { useGetProductSubscriptionOriginalPrice } from 'tv-selectors/products/makeGetProductSubscriptionOriginalPrice';
import { useGetProductSubscriptionCurrency } from 'tv-selectors/products/makeGetProductSubscriptionCurrency';
import { useGetProductSubscriptionDiscountPeriods } from 'tv-selectors/products/makeGetProductSubscriptionDiscountPeriods';
import { useGetProductSubscriptionTrialDays } from 'tv-selectors/products/makeGetProductSubscriptionTrialDays';

// price is assumed to be in cents or it's equivalent
export const formatPrice = (price, currency) => {
  if (typeof price !== 'number' || typeof currency !== 'string') return undefined;
  const currencySymbols = {
    usd: '$',
    eur: '€',
    brl: 'R$',
  };
  const priceFormat = (price / 100).toFixed(2);

  const currencyDescriptor = currencySymbols[currency] || `${currency.toUpperCase()} `;
  return `${currencyDescriptor}${priceFormat}`;
};

const usePriceInfoText = productId => {
  const price = useGetProductSubscriptionPrice({ productId });
  const originalPrice = useGetProductSubscriptionOriginalPrice({ productId });
  const currency = useGetProductSubscriptionCurrency({ productId });
  const discountPeriods = useGetProductSubscriptionDiscountPeriods({ productId });
  const trialDays = useGetProductSubscriptionTrialDays({ productId });

  const trialMonths = Math.round(trialDays / 30.417); // This is how google.com converts days to months
  const formattedPrice = formatPrice(price, currency);
  const formattedOriginalPrice = formatPrice(originalPrice, currency);
  const discount = price === 0 ? 1 : 1 - price / originalPrice;
  const end = 'You can cancel your subscription any time.';
  // Free forever
  if (discount === 1 && discountPeriods < 0) {
    return `Your subscription is free forever. ${end}`;
  }

  // Promo code giving discount that is less than 100%
  if (discount > 0 && discount < 1 && discountPeriods !== 0) {
    const monthOrMonths = trialMonths > 1 ? 'months' : 'month';
    const start = trialMonths > 0 ? `After ${trialMonths} free ${monthOrMonths} you` : 'You';

    // Discount forever
    if (discountPeriods < 0)
      return `${start} will be charged a monthly subscription of ${formattedPrice}. ${end}`;

    return `${start} will be charged a monthly subscription of ${formattedPrice} for ${discountPeriods} months. Afterwards you will be charged ${formattedOriginalPrice} a month. ${end}`;
  }

  // Trial months + promo code free months
  const nrOfFreeMonths = trialMonths + (discount === 1 ? discountPeriods : 0);

  const monthOrMonths = nrOfFreeMonths > 1 ? 'months' : 'month';
  const start = nrOfFreeMonths > 0 ? `After ${nrOfFreeMonths} free ${monthOrMonths} you` : 'You';

  return `${start} will be charged a monthly subscription of ${formattedOriginalPrice}. ${end}`;
};

export default usePriceInfoText;
