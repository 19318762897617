import { LS_AUTH, LS_CONSENT, LS_ONBOARDING, ZNIPE_STATE_CONFIG } from 'tv-constants/localStorage';
import { setAuth } from 'tv-actions/old/auth';
import { setConsent, showCookiePopUp } from 'tv-actions/old/consent';
import { setControl } from 'tv-actions/old/control';
import AnalyticsManager from 'znipe-analytics-manager';
import setCookie from 'znipe-utils/web/setCookie';
import ls from 'local-storage';
import { COOKIE_MAX_AGE_SECONDS } from 'tv-utils/startUserSessionHeartBeat';
import { SET_SELECTED_TEAM_IN_EVENT } from 'tv-constants/oldRedux';
import config from 'tv-config/config';

export const restoreStoreFromLS = store => {
  const auth = ls.get(LS_AUTH) || {};
  const onboarding = ls.get(LS_ONBOARDING) || {};
  const control = ls.get(ZNIPE_STATE_CONFIG) || {};
  const consents = ls.get(LS_CONSENT) || {};
  const chat = ls.get('CHAT_CONFIG') || {};
  const coreUI = ls.get('CORE_UI_CONFIG') || {};

  const { isFetching, isLinkedToLGUPlus, ...filteredAuth } = auth;

  store.dispatch(setAuth({ ...filteredAuth, onboarding }));
  if (!consents.allowOptionalCookies) store.dispatch(showCookiePopUp(true));
  store.dispatch(setConsent(consents));

  store.dispatch({
    type: 'SET_CHAT_CONFIG',
    chat,
  });
  store.dispatch({
    type: 'SET_CURE_UI',
    coreUI,
  });
  store.dispatch(setControl(control));
};

export const storeStoreInLS = store => {
  const { auth, control, consent, chat, deviceInfo, coreUI } = store.getState();
  const { externalJwt, ...restAuth } = auth;
  ls.set(LS_AUTH, restAuth);

  const { mute, volume, showZnipePing, quality } = control;
  const stateObj = { mute, volume, showZnipePing, quality };
  ls.set(ZNIPE_STATE_CONFIG, stateObj);

  /** Flushes analytics event queue and sends them to API */
  AnalyticsManager.flush();

  /** Saves the user session in a cookie */
  const { userSession } = deviceInfo;
  setCookie('userSession', userSession, COOKIE_MAX_AGE_SECONDS, '/');

  const optionalCookiesAllowed = consent.allowOptionalCookies;
  if (optionalCookiesAllowed) {
    ls.set(LS_CONSENT, consent);
    ls.set('CHAT_CONFIG', chat);
    const { floatableStreamZIndex, ...restCOREUI } = coreUI;
    ls.set('CORE_UI_CONFIG', restCOREUI.remove('selectedPaymentOption'));
  } else {
    ls.remove(LS_CONSENT);
    ls.remove('CHAT_CONFIG');
    ls.remove(SET_SELECTED_TEAM_IN_EVENT);
    ls.remove('CORE_UI_CONFIG');
  }
};
