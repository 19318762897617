import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetContentSubscriptions from 'tv-selectors/contentSubscriptions/makeGetContentSubscriptions';

const emptyArray = [];

const makeGetSubscribedEventIds = () => {
  const getContentSubscriptions = makeGetContentSubscriptions();
  return createSelector([getContentSubscriptions], subscriptions => {
    if (!subscriptions?.event) return emptyArray;
    const ids = Object.keys(subscriptions.event);
    return ids ?? emptyArray;
  });
};

export const useGetSubscribedEventIds = () => useMemoizedGqlSelector(makeGetSubscribedEventIds);

export default makeGetSubscribedEventIds;
