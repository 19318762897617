import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import SectionSkeleton, {
  ITEM_TYPE_PLAYER,
  ITEM_TYPE_LOGO_CARD,
  ITEM_TYPE_VIDEO,
  ITEM_TYPE_CHAMPION,
  ITEM_TYPE_MATCH,
  ITEM_TYPE_TOPIC_LINK,
} from 'tv-elements/feedback/SectionSkeleton/SectionSkeleton';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { SCROLLLIST, LIST } from 'tv-elements/layout/Section/Section.constants';
import { SectionContainerMargins } from 'tv-elements/layout/Section/Section.styles';
import CategorySection from './CategorySection';
import {
  VODS,
  EVENTS,
  EDITORIALS,
  CHAMPIONS,
  TEAMS,
  PLAYERS,
  HIGHLIGHTS,
  elementSizesPropType,
  categoryTypePropType,
} from '../Browse.constants';

const SectionWrapper = styled.div`
  ${({ $noTopMargin }) => !$noTopMargin && SectionContainerMargins}
`;
const TextWrapper = styled.div`
  ${({ $hasMargin }) =>
    $hasMargin &&
    css`
      margin-left: 15px;
    `}
`;

const TitleLoading = () => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  return (
    <TextWrapper $hasMargin={!isTabletOrGreater}>
      <TextSkeleton width={170} height={31} />
    </TextWrapper>
  );
};

const TabLoading = ({ type, elementSizes, hasSectionTitles }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const tabInfo = useMemo(
    () => ({
      [PLAYERS]: {
        itemType: isDesktopOrGreater ? ITEM_TYPE_PLAYER : ITEM_TYPE_TOPIC_LINK,
        sizeId: 'playerCardSize',
        numberOfItems: 10,
      },
      [TEAMS]: {
        itemType: isDesktopOrGreater ? ITEM_TYPE_LOGO_CARD : ITEM_TYPE_TOPIC_LINK,
        sizeId: 'topicCardSize',
        numberOfItems: 8,
      },
      [CHAMPIONS]: {
        itemType: isDesktopOrGreater ? ITEM_TYPE_CHAMPION : ITEM_TYPE_TOPIC_LINK,
        sizeId: 'topicCardSize',
        numberOfItems: 8,
      },
      [VODS]: {
        itemType: ITEM_TYPE_MATCH,
        sizeId: 'matchItemSize',
        numberOfItems: 8,
      },
      [EDITORIALS]: {
        itemType: ITEM_TYPE_VIDEO,
        sizeId: 'videoListingSize',
        numberOfItems: 8,
      },
      [HIGHLIGHTS]: {
        itemType: ITEM_TYPE_VIDEO,
        sizeId: 'videoListingSize',
        numberOfItems: 8,
      },
      [EVENTS]: {
        itemType: isDesktopOrGreater ? ITEM_TYPE_LOGO_CARD : ITEM_TYPE_TOPIC_LINK,
        sizeId: 'topicCardSize',
        numberOfItems: 4,
      },
    }),
    [isDesktopOrGreater],
  );
  const { itemType, sizeId, numberOfItems } = tabInfo[type];

  return (
    <>
      {hasSectionTitles && <TitleLoading />}
      <CategorySection noTopMargin type={type} elementSizes={elementSizes}>
        <SectionSkeleton
          itemType={itemType}
          size={elementSizes[sizeId]}
          numberOfItems={numberOfItems}
        />
      </CategorySection>
    </>
  );
};

TabLoading.propTypes = {
  type: categoryTypePropType.isRequired,
  elementSizes: elementSizesPropType.isRequired,
  hasSectionTitles: PropTypes.bool.isRequired,
};

const AllTabLoading = ({ elementSizes }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  return (
    <>
      <SectionWrapper $noTopMargin>
        <TitleLoading />
        <CategorySection
          noTopMargin
          type={PLAYERS}
          elementSizes={elementSizes}
          layout={isTabletOrGreater ? LIST : SCROLLLIST}
        >
          <SectionSkeleton
            itemType={ITEM_TYPE_PLAYER}
            size={elementSizes.playerCardSize}
            numberOfItems={6}
          />
        </CategorySection>
      </SectionWrapper>
      <SectionWrapper>
        <TitleLoading />
        <CategorySection noTopMargin type={TEAMS} elementSizes={elementSizes}>
          <SectionSkeleton
            itemType={isDesktopOrGreater ? ITEM_TYPE_LOGO_CARD : ITEM_TYPE_TOPIC_LINK}
            size={elementSizes.topicCardSize}
            numberOfItems={4}
          />
        </CategorySection>
      </SectionWrapper>
      <SectionWrapper>
        <TitleLoading />
        <CategorySection noTopMargin type={EDITORIALS} elementSizes={elementSizes}>
          <SectionSkeleton
            itemType={ITEM_TYPE_VIDEO}
            size={elementSizes.videoListingSize}
            numberOfItems={3}
          />
        </CategorySection>
      </SectionWrapper>
    </>
  );
};

AllTabLoading.propTypes = {
  elementSizes: elementSizesPropType.isRequired,
};

const SectionLoading = ({ type, elementSizes, hasSectionTitles = true }) => {
  if (type === 'all') return <AllTabLoading elementSizes={elementSizes} />;
  return <TabLoading type={type} elementSizes={elementSizes} hasSectionTitles={hasSectionTitles} />;
};

SectionLoading.propTypes = {
  type: categoryTypePropType.isRequired,
  elementSizes: elementSizesPropType.isRequired,
  hasSectionTitles: PropTypes.bool,
};

export default SectionLoading;
