import { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logger from 'znipe-logger';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useIsMobile } from 'tv-selectors/deviceInfo/makeGetIsMobile';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetPage } from 'tv-selectors/page/makeGetPage';
import { useGetEvents as useGetTournamentEvents } from 'tv-selectors/events/makeGetEvents';
import { TabsetWrapper, BodyWrapper } from 'tv-elements/layout/PageLayout/PageLayout';
import Tabset from 'znipe-elements/navigation/Tabset/Tabset';
import { SEARCH_RESULTS_ENDPOINT } from 'tv-constants/apiEndpoints';
import Typography from 'znipe-elements/general/Typography/Typography';
import FiltersTabset from 'tv-modules/Filters/FiltersTabset/FiltersTabset';
import usePackageName from 'tv-hooks/usePackageName';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import { LOL_PATCH_VERSION } from 'znipe-constants/misc';
import { dispatchGqlData } from 'tv-actions/thunks';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import usePackageIncludesGame from 'tv-hooks/usePackageIncludesGame';
import AllResults from './components/AllResults';
import CategoryResults from './components/CategoryResult';
import useFetchBrowseCredentials from './hooks/useFetchBrowseCredentials';
import formatSearchData from './utils/formatSearchData';
import {
  tabs,
  breakpointSizes,
  ALL,
  PLAYERS,
  CHAMPIONS,
  TEAMS,
  VODS,
  HIGHLIGHTS,
  EDITORIALS,
  // SCHEDULE,
  // EVENTS,
  filterOptionsDesktop,
  filterOptionsMobile,
} from './Browse.constants';
import {
  AllTabQuery,
  ChampionsTabQuery,
  EditorialsTabQuery,
  HighlightsTabQuery,
  PlayersTabQuery,
  TeamsTabQuery,
  VodsTabQuery,
  PackagesQuery,
} from './Browse.queries';
import { SearchTermWrapper } from './Browse.styles';

const Browse = () => {
  const packageName = usePackageName();
  const { packageSlug, subpage } = useParams();
  const navigate = useNavigate();
  const gqlDispatch = useGqlStoreDispatch();
  const baseUrl = useMemo(() => (packageSlug ? `/${packageSlug}` : ''), [packageSlug]);
  const { s: searchQuery } = useQueryObject();

  const packageIncludesLol = usePackageIncludesGame('lol', packageSlug);

  const selectedTabs = useMemo(() => {
    if (packageIncludesLol) return tabs;
    return tabs.filter(({ label }) => label !== CHAMPIONS);
  }, [packageIncludesLol]);

  const tabOptions = useMemo(() => selectedTabs.map(tab => tab.label), [selectedTabs]);

  const initialTab = useMemo(() => {
    const defaultTab = 'all';
    if (!subpage) return defaultTab;
    const tabNameExists = selectedTabs.find(tab => tab.label === subpage?.toLowerCase());
    if (tabNameExists) return tabNameExists.label;
    return defaultTab;
  }, [subpage, selectedTabs]);

  const [activeTab, setActiveTab] = useState(initialTab);
  const [pageFilters, setPageFilters] = useState({});
  const [nextCursor, setNextCursor] = useState(undefined);
  const [isSearchDataLoading, setIsSearchDataLoading] = useState(searchQuery);
  const isMobile = useIsMobile();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const elementSizes = useMemo(() => {
    if (isDesktopLargeOrGreater) return breakpointSizes.desktopLarge;
    if (isDesktopOrGreater) return breakpointSizes.desktop;
    if (isTabletOrGreater) return breakpointSizes.tablet;
    return breakpointSizes.default;
  }, [isTabletOrGreater, isDesktopOrGreater, isDesktopLargeOrGreater]);

  const tournamentEvents = useGetTournamentEvents();

  useEffect(() => {
    const regex = new RegExp(subpage, 'i');
    const selectedTab = tabOptions.find(item => regex.test(item))?.label || initialTab;
    setActiveTab(selectedTab);
  }, [initialTab, subpage, tabOptions]);

  useEffect(() => {
    if (!searchQuery) return;
    setIsSearchDataLoading(true);
    const filterQuery = packageName
      ? `&f=packages.humanReadableId:${packageName},patch:${LOL_PATCH_VERSION},status:vod`
      : '';

    fetch(`${SEARCH_RESULTS_ENDPOINT}?q=${searchQuery}${filterQuery}`)
      .then(res => res.json())
      .then(({ results = {} }) => {
        const normalizedResults = formatSearchData(results);
        gqlDispatch(
          dispatchGqlData(normalizedResults, {
            pageType: 'browse-result',
          }),
        );
        setIsSearchDataLoading(false);
      })
      .catch(err => {
        setIsSearchDataLoading(false);
        logger.error('Failed to fetch search data', err);
      });
  }, [packageName, searchQuery, gqlDispatch]);

  const handleTabClick = useCallback(
    nextView => {
      const queryParams = searchQuery ? `?s=${searchQuery}` : '';
      const targetLabel = nextView?.label;
      const nextPath =
        targetLabel === ALL ? `${baseUrl}/browse` : `${baseUrl}/browse/${targetLabel}`;
      navigate(`${nextPath}${queryParams}`);
      setActiveTab(targetLabel);
      setNextCursor(undefined);
      if (window) window.scrollTo(0, 0);
    },
    [navigate, baseUrl, searchQuery],
  );

  const { tabsetSize } = elementSizes;

  const allQueryString = useMemo(() => {
    if (activeTab === PLAYERS) return PlayersTabQuery;
    if (activeTab === TEAMS) return TeamsTabQuery;
    if (activeTab === EDITORIALS) return EditorialsTabQuery;
    if (activeTab === VODS) return VodsTabQuery;
    if (activeTab === CHAMPIONS) return ChampionsTabQuery;
    if (activeTab === HIGHLIGHTS) return HighlightsTabQuery;
    return AllTabQuery;
  }, [activeTab]);

  const queryFilters = useMemo(() => {
    const {
      appliedEventsFilter,
      appliedPlayersFilter,
      appliedTeamsFilter,
      appliedClassesFilter,
      appliedChampionsFilter,
    } = pageFilters?.filterParams || {};
    return {
      eventsFilter: appliedEventsFilter || undefined,
      playersFilter: appliedPlayersFilter || undefined,
      teamsFilter: appliedTeamsFilter || undefined,
      classesFilter: appliedClassesFilter || undefined,
      championsFilter: appliedChampionsFilter || undefined,
    };
  }, [pageFilters]);

  const allQueryOptions = useMemo(
    () => ({
      variables: {
        package: packageName,
        patch: LOL_PATCH_VERSION,
        nextCursor,
        includeChampions: packageIncludesLol,
        ...queryFilters,
      },
    }),
    [packageName, queryFilters, nextCursor, packageIncludesLol],
  );

  useReduxGqlQuery(PackagesQuery, allQueryOptions);

  const allData = useReduxGqlQuery(
    !searchQuery ? allQueryString : null,
    allQueryOptions,
    undefined,
    undefined,
    undefined,
    `browse-${activeTab}`,
  );

  /*
  TODO: enable this when want to display browse sections again

  const isAllTab = useMemo(() => activeTab === 'all', [activeTab]);
    const shouldFetchSubSectionData =
    !searchQuery &&
    !isAllTab &&
    activeTab !== VODS &&
    activeTab !== SCHEDULE &&
    activeTab !== EVENTS;

  const topQueryOptions = useMemo(
    () => ({ variables: { package: packageName, patch: LOL_PATCH_VERSION, count: 1 } }),
    [packageName],
  );

  useReduxGqlQuery(
    shouldFetchSubSectionData ? allQueryString : null,
    topQueryOptions,
    undefined,
    undefined,
    undefined,
    `browse-${activeTab}-top`,
  );

  const highlightedQueryOptions = useMemo(
    () => ({ variables: { package: packageName, patch: LOL_PATCH_VERSION, count: 4 } }),
    [packageName],
  );

  useReduxGqlQuery(
    shouldFetchSubSectionData ? allQueryString : null,
    highlightedQueryOptions,
    undefined,
    undefined,
    undefined,
    `browse-${activeTab}-highlighted`,
  );
  */
  const browseData = useGetPage({
    pageType: `browse-${activeTab}`,
  });

  const topTabData = useGetPage({
    pageType: `browse-${activeTab}-top`,
  });

  const highligtedTabData = useGetPage({
    pageType: `browse-${activeTab}-highlighted`,
  });

  const searchResult = useGetPage({
    pageType: `browse-result`,
  });

  const fetchMoreData = useCallback(cursor => {
    setNextCursor(cursor);
  }, []);

  const handleFilterChange = useCallback(filter => {
    setPageFilters(filter);
  }, []);

  const isUsingFilter = useMemo(
    () => Boolean(Object.values(pageFilters.filterParams || {}).join('')),
    [pageFilters],
  );

  const eventFilterOptions = useMemo(() => {
    if (tournamentEvents) {
      return Object.values(tournamentEvents).map(
        ({ id, name: title, logo: image, country: subtitle }) => ({
          id,
          title,
          image,
          subtitle,
        }),
      );
    }
    return [];
  }, [tournamentEvents]);

  const filterOptions = useMemo(() => {
    const options = isDesktopOrGreater
      ? filterOptionsDesktop[activeTab]
      : filterOptionsMobile[activeTab];

    if (packageIncludesLol) return options;

    return {
      ...options,
      panel: options?.panel?.filter(panel => panel !== CHAMPIONS) ?? null,
    };
  }, [isDesktopOrGreater, activeTab, packageIncludesLol]);

  useFetchBrowseCredentials(activeTab, searchQuery ? searchResult : browseData);

  const isLoadingMoreData = searchQuery ? isSearchDataLoading : allData.loading;

  const TabsetElement = useMemo(
    () => (
      <>
        <TabsetWrapper>
          <Tabset
            size={tabsetSize}
            activeTab={activeTab}
            tabs={selectedTabs}
            alignment="left"
            onClickTab={handleTabClick}
            isMobile={isMobile}
          />
        </TabsetWrapper>
        {activeTab !== 'all' && !searchQuery && (
          <FiltersTabset
            activeTab={activeTab}
            eventOptions={eventFilterOptions}
            filterOptions={filterOptions}
            onFilterChange={handleFilterChange}
          />
        )}
      </>
    ),
    [
      tabsetSize,
      activeTab,
      selectedTabs,
      handleTabClick,
      isMobile,
      searchQuery,
      eventFilterOptions,
      filterOptions,
      handleFilterChange,
    ],
  );

  const SearchTermElement = useMemo(() => {
    if (!isDesktopOrGreater) return null;
    if (!searchQuery) return null;
    return (
      <SearchTermWrapper>
        <Typography type="heading-l">{searchQuery}</Typography>
      </SearchTermWrapper>
    );
  }, [isDesktopOrGreater, searchQuery]);

  return (
    <>
      {!isDesktopOrGreater && TabsetElement}
      <BodyWrapper>
        {SearchTermElement}
        {isDesktopOrGreater && TabsetElement}
        {activeTab === 'all' ? (
          <AllResults
            seachResult={searchQuery ? searchResult : browseData}
            elementSizes={elementSizes}
            searchQuery={searchQuery}
            isLoading={isLoadingMoreData}
          />
        ) : (
          <CategoryResults
            topResultData={topTabData[activeTab]}
            highlightedResultsData={highligtedTabData[activeTab]}
            allResults={searchQuery ? searchResult : browseData}
            elementSizes={elementSizes}
            searchQuery={searchQuery}
            type={activeTab}
            isUsingFilter={isUsingFilter}
            setDataCursor={fetchMoreData}
            isLoading={isLoadingMoreData}
          />
        )}
      </BodyWrapper>
    </>
  );
};

export default Browse;
