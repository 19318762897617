import { useRef, useCallback, useEffect } from 'react';
import { error } from 'znipe-logger';

/*
Source:
https://medium.freecodecamp.org/how-to-detect-an-outside-click-with-react-and-hooks-25dbaa30abcd
https://codesandbox.io/s/8484x6zpnj
*/

const useOutsideClick = <T extends HTMLElement>(onClick: (e: MouseEvent | TouchEvent) => void) => {
  const ref = useRef<T>(null);
  const useEffectRuns = useRef(0);

  const handleClick = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!ref.current) return;
      const inside = e.target && ref.current.contains(e.target as Node);
      if (inside) return;

      onClick(e);
    },
    [onClick],
  );

  const handleDocumentClick = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const rootNode = ref.current?.getRootNode() ?? document;
      if (
        e.target &&
        !ref.current?.contains(e.target as Node) &&
        (rootNode as ShadowRoot).host !== e.target
      ) {
        onClick(e);
      }
    },
    [onClick],
  );

  useEffect(() => {
    useEffectRuns.current += 1;
    if (useEffectRuns.current > 20) {
      error(
        'It seems like you are creating a new onClick function on each render. This is causing a infinite loop in useOutsideClick',
      );
    }
    const rootNode = ref.current?.getRootNode() ?? document;

    if (rootNode instanceof ShadowRoot) {
      document.addEventListener('mousedown', handleDocumentClick);
      document.addEventListener('touchstart', handleDocumentClick);
    }
    (rootNode as Document).addEventListener('mousedown', handleClick);
    (rootNode as Document).addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      document.removeEventListener('touchstart', handleDocumentClick);
      (rootNode as Document).removeEventListener('mousedown', handleClick);
      (rootNode as Document).removeEventListener('touchstart', handleClick);
    };
  }, [handleClick, handleDocumentClick]);

  return ref;
};

export default useOutsideClick;
