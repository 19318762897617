import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const emptyArray = [];

const makeGetEditorials = () => {
  const getPage = makeGetPage();
  return createSelector([getPage], page => page?.editorials ?? emptyArray);
};

export const useGetEditorials = props => useMemoizedGqlSelector(makeGetEditorials, props);

export default makeGetEditorials;
