import colors from 'znipe-styles/colors';

export default {
  proview: {
    link: {
      labelColor: colors.spaceGreen,
    },
    control: {
      borderColorIconActive: colors.spaceGreen,
      bgColorIconActive: colors.riotBackgroundRule,
    },
    button: {
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '22px',
      textColor: colors.riotTextPrimary,
    },
  },
  default: {
    link: {
      labelColor: colors.spaceGreen,
    },
    control: {
      borderColorIconActive: colors.riotAccentBlue,
      bgColorIconActive: colors.riotBackgroundRule,
    },
    button: {
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '22px',
      textColor: colors.riotTextPrimary,
    },
  },
};
