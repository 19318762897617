import { memo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { StyledHeader, ImageWrapper, Image } from './PaymentHeader.styles';
import themes from './PaymentHeader.themes';

const Header = ({ thin = false, animate = false, animationTime = 0.6, animationDelay = 0 }) => {
  const themeContext = useThemeContext();
  const { images } = themeContext.paymentHeader;
  return (
    <StyledHeader
      data-testid="payment-header"
      thin={thin}
      animate={animate}
      animationDelay={animationTime + animationDelay}
    >
      {images.map((imageSrc, index) => (
        <ImageWrapper
          key={imageSrc}
          thin={thin}
          skew={index !== 0 && index !== images.length - 1}
          index={index}
          animate={animate}
          animationTime={animationTime / images.length}
          animationDelay={animationDelay}
        >
          <Image src={imageSrc} />
        </ImageWrapper>
      ))}
    </StyledHeader>
  );
};

Header.propTypes = {
  thin: PropTypes.bool,
  animate: PropTypes.bool,
  animationTime: PropTypes.number,
  animationDelay: PropTypes.number,
};

export default withTheme(memo(Header), themes, 'paymentHeader');
