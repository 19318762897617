import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';

const getChampionIdFromProps = (_, props) => props.championId ?? '';

const makeGetChampionImage = () => {
  const getChampions = makeGetChampions();
  return createSelector([getChampions, getChampionIdFromProps], (allChampions, championId) => {
    if (!allChampions || !championId) return '';
    return allChampions[championId]?.image ?? '';
  });
};

export const useGetChampionImage = props => useMemoizedGqlSelector(makeGetChampionImage, props);

export default makeGetChampionImage;
