import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Arrow from 'znipe-elements/data-display/Markers/Arrows/Arrow';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Group,
  Container,
  GroupInfoWrapper,
  Info,
  Image,
  ContentWrapper,
  RoleIconWrapper,
} from './SelectorGroup.styles';

type Size = 'small' | 'medium';

const useGroup = (
  size: Size,
  renderFunc: (index: number, size: Size) => React.ReactNode,
  length: number,
  name?: string,
  renderRoleIcon?: (index: number, size: Size) => React.ReactNode,
) =>
  useMemo(
    () =>
      Array.from({ length }, (_, index) => (
        <ContentWrapper
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={`${name}-${index}`}
        >
          {renderFunc(index, size)}
          {renderRoleIcon && <RoleIconWrapper>{renderRoleIcon(index, size)}</RoleIconWrapper>}
        </ContentWrapper>
      )),
    [size, renderFunc, length, name, renderRoleIcon],
  );

type GroupInfoProps = {
  name?: string;
  image?: string;
  reverse?: boolean;
  size?: Size;
};

const GroupInfo = ({ name = '', image = '', reverse = false, size = 'medium' }: GroupInfoProps) => (
  <Info $reverse={reverse}>
    {image && <Image src={image} alt={name ?? 'logo'} />}
    <Typography type={`title-${size === 'small' ? 'l' : 'xl'}`} color="primaryTextColor">
      {name}
    </Typography>
  </Info>
);

GroupInfo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  name: PropTypes.string,
  image: PropTypes.string,
  reverse: PropTypes.bool,
};

type SelectorGroupProps = {
  type?: 'vertical' | 'horizontal';
  size?: Size;
  groupOneLength?: number;
  groupTwoLength?: number;
  renderGroupOneSelector?: (index: number, size: Size) => React.ReactNode;
  renderGroupTwoSelector?: (index: number, size: Size) => React.ReactNode;
  groupOneLogo?: string;
  groupTwoLogo?: string;
  groupOneName?: string;
  groupTwoName?: string;
  teamMarkers?: boolean;
  renderRoleIcon?: (index: number, size: Size) => React.ReactNode;
};

const SelectorGroup = ({
  type = 'vertical',
  size = 'small',
  groupOneLength = 0,
  groupTwoLength = 0,
  renderGroupOneSelector = () => null,
  renderGroupTwoSelector = () => null,
  groupOneLogo,
  groupTwoLogo,
  groupOneName,
  groupTwoName,
  teamMarkers = false,
  renderRoleIcon,
}: SelectorGroupProps) => {
  const shouldRenderRoleIcons = renderRoleIcon && type === 'horizontal';
  const groupOne = useGroup(
    size,
    renderGroupOneSelector,
    groupOneLength,
    groupOneName,
    shouldRenderRoleIcons ? renderRoleIcon : undefined,
  );
  const groupTwo = useGroup(size, renderGroupTwoSelector, groupTwoLength, groupTwoName);
  const showMarkers = teamMarkers && type === 'horizontal';
  return (
    <Container $type={type} $size={size} data-testid="selector-group">
      {groupOne.length > 0 && (
        <GroupInfoWrapper $fixArrowPos={showMarkers && shouldRenderRoleIcons}>
          {(groupOneLogo || groupOneName) && type === 'vertical' && (
            <GroupInfo image={groupOneLogo} name={groupOneName} size={size} />
          )}
          {showMarkers && <Arrow team={1} direction="right" />}
          <Group>{groupOne}</Group>
          {showMarkers && <Arrow team={1} direction="left" />}
        </GroupInfoWrapper>
      )}
      {groupTwo.length > 0 && (
        <GroupInfoWrapper>
          {(groupTwoLogo || groupTwoName) && type === 'vertical' && (
            <GroupInfo image={groupTwoLogo} name={groupTwoName} reverse size={size} />
          )}
          {showMarkers && <Arrow team={2} direction="right" />}
          <Group>{groupTwo}</Group>
          {showMarkers && <Arrow team={2} direction="left" />}
        </GroupInfoWrapper>
      )}
    </Container>
  );
};

SelectorGroup.propTypes = {
  type: PropTypes.oneOf(['vertical', 'horizontal']),
  size: PropTypes.oneOf(['small', 'medium']),
  groupOneLength: PropTypes.number,
  groupTwoLength: PropTypes.number,
  renderGroupOneSelector: PropTypes.func,
  renderGroupTwoSelector: PropTypes.func,
  renderRoleIcon: PropTypes.func,
  groupOneLogo: PropTypes.string,
  groupTwoLogo: PropTypes.string,
  groupOneName: PropTypes.string,
  groupTwoName: PropTypes.string,
  teamMarkers: PropTypes.bool,
};

export default SelectorGroup;
