import { getContent, getSelection, nodeWalk } from './DOMNode';

const fallbackEnd = (elem: Node) => {
  getSelection(elem)?.selectAllChildren(elem);
  getSelection(elem)?.collapseToEnd();
};

const setPosition = (elem: HTMLElement, position: number) => {
  const selection = getSelection(elem);
  const range = document.createRange();
  range.setStart(elem, Math.max(0, position));
  range.collapse(true);

  if (!selection) {
    fallbackEnd(elem);
    return;
  }

  selection.removeAllRanges();
  selection.addRange(range);
};

const moveToEnd = (elem: HTMLElement) => {
  let targetNode: Node | null = null;
  // Find the last child node
  nodeWalk(elem.lastChild || elem, (node: Node) => {
    targetNode = node;
    return true;
  });

  if (!targetNode) {
    fallbackEnd(elem);
    return;
  }

  const htmlContent = getContent(targetNode);
  setPosition(targetNode, htmlContent?.length ?? 0);
};

const findNode = (elem: HTMLElement, position: number) => {
  let totalCharacters = 0;
  let targetNode: Node | null = null;
  nodeWalk(elem, (node: Node) => {
    if (
      node.firstChild &&
      node instanceof Element &&
      node.getAttribute('contenteditable') !== 'false'
    ) {
      return true;
    }

    if (node instanceof Element && node.getAttribute('contenteditable') === 'false') {
      totalCharacters += 1;
      return true;
    }

    if (
      node.parentNode instanceof Element &&
      node.parentNode.getAttribute('contenteditable') === 'false'
    ) {
      return true;
    }

    const htmlContent = getContent(node);

    // The -1 is to put the caret at index 0 of the next node instead of the end of the current node
    if (totalCharacters + (htmlContent?.length ?? 0 - 1) >= position) {
      targetNode = node;
      return false;
    }

    totalCharacters += htmlContent?.length ?? 0;

    return true;
  });

  return { totalCharacters, targetNode };
};

const setCaretPosition = (elem: HTMLElement, position: number) => {
  const { totalCharacters, targetNode } = findNode(elem, position);

  elem.focus();
  if (!targetNode) {
    moveToEnd(elem);
    return;
  }

  const selection = getSelection(elem);

  if (!selection) {
    moveToEnd(elem);
    return;
  }

  setPosition(targetNode, position - totalCharacters);
};

export default setCaretPosition;
