import styled, { css, keyframes } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

const overflow = keyframes`
  to {
    overflow: hidden;
  }
`;

export const StyledHeader = styled.div`
  position: relative;
  height: ${props => (props.thin ? 80 : 105)}px;
  width: 100%;
  @media ${device.tablet} {
    height: ${props => (props.thin ? 80 : 195)}px;
    border-radius: 8px 8px 0px 0px;
  }

  animation: ${overflow} 0s ${props => props.animationDelay}s forwards;
  @media (prefers-reduced-motion: reduce) {
    overflow: hidden;
  }
`;

export const Image = styled.img`
  position: absolute;
  width: 130%;
  height: 100%;
  object-fit: cover;
`;

const getSkew = (props, isTablet) => {
  if (!props.thin) return 13;
  if (isTablet) return 30;
  return 16;
};

const getLeftPosition = props => css`
  left: ${props.skew ? props.index * 25 : Math.max(0, props.index * 25 - 3)}%;
`;

const getExpectedCss = (props, isTablet) => css`
  overflow: hidden;
  ${
    props.skew
      ? css`
        z-index: 1;
        transform: scale(1) skewX(-${getSkew(props, isTablet)}deg);
      `
      : css`
        transform: scale(1);
      `
  };
  opacity: 1;
  ${getLeftPosition(props)};
`;

const flyIn = (props, isTablet) => keyframes`
   to { 
     ${getExpectedCss(props, isTablet)};
   }
`;

const withNormalCss = css`
  ${props => getExpectedCss(props)}

  @media ${device.tablet} {
    ${props => getExpectedCss(props, true)}
  }
`;

const withAnimationCss = css`
  animation: ${props => flyIn(props)} ${props => props.animationTime}s
    ${props => props.animationDelay + props.index * props.animationTime}s ease-out forwards;

  @media ${device.tablet} {
    animation: ${props => flyIn(props, true)} ${props => props.animationTime}s
      ${props => props.animationDelay + props.index * props.animationTime}s ease-out forwards;
  }

  opacity: 0;
  transform: scale(2);
  ${props => getLeftPosition(props)};

  ${props =>
    props.skew &&
    css`
      z-index: 1;
      transform: scale(2) skewX(-${getSkew(props)}deg);

      @media ${device.tablet} {
        transform: scale(2) skewX(-${getSkew(props, true)}deg);
      }
    `};

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    ${withNormalCss}
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${props => (props.skew ? 25 : 30)}%;
  ${props => (props.animate ? withAnimationCss : withNormalCss)}
  &:last-child {
    margin-left: -5px;
  }
  & ${Image} {
    ${props =>
      props.skew &&
      css`
        transform: skewX(${getSkew(props)}deg);

        @media ${device.tablet} {
          transform: skewX(${getSkew(props, true)}deg);
        }
      `};
  }
`;
