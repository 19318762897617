import colors from 'znipe-styles/colors';

export const REMOVED = 'removed';
export const REPORTED = 'reported';
export const MUTED = 'muted';
export const BANNED = 'banned';
export const ALLOWED = 'allowed';
export const ACTION_TYPES = [REMOVED, REPORTED, MUTED, BANNED, ALLOWED] as const;

export const ADMIN_COLOR = colors.strawberry;

export const COLORS = {
  default: [
    colors.gemstone,
    colors.lilac,
    colors.pinkPanther,
    colors.cyan,
    colors.limeJuice,
    colors.sunshine,
    colors.tangerine,
    colors.grey53,
  ],
};

export const LATEST_MESSAGES_SEPARATOR = 'LATEST_MESSAGES_SEPARATOR';

export const DEFAULT_RESPONSIVE_BREAKPOINTS = { medium: 500 };
