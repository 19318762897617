import colors from 'znipe-styles/colors';

export default {
  proview: {
    textColor: colors.white,
    bannerStartColor: '#da4453',
    bannerEndColor: '#89216b',
  },
  default: {
    textColor: colors.white,
    bannerStartColor: '#da4453',
    bannerEndColor: '#89216b',
  },
};
