import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  cursor: ${({ $isLive }) => ($isLive ? 'pointer' : 'initial')};
  gap: 8px;
`;

export const RedDot = styled.div`
  width: 5px;
  height: 5px;
  background: ${({ $onAir, theme }) => ($onAir ? 'red' : theme.textSecondary)};
  border-radius: 100%;
`;
