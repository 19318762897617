import { useEffect, useState, useMemo, useCallback } from 'react';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import parseJSONString from 'znipe-utils/string/parseJSONString';
import { LS_ZNIPE_FREEMIUM_COOLDOWN } from 'tv-constants/localStorage';
import logger from 'znipe-logger';

const addDays = days => {
  const date = new Date(new Date().valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const makeVariation = () => {
  const rand = Math.random();
  if (rand <= 0.3) return 1;
  if (rand > 0.3 && rand <= 0.6) return -1;
  return 0;
};

const defaultProps = { pageType: '' };

const useFreemiumShowBanner = ({ pageType } = defaultProps) => {
  const isPremiumUser = useHasPremiumAccess();

  const [showFreemiumBanner, setShowFreemiumBanner] = useState(false);
  const today = useMemo(() => new Date(), []);

  if (!pageType) {
    logger.error('useFreemiumShowBanner needs a pageType prop');
  }

  useEffect(() => {
    if (isPremiumUser || !pageType) return;
    const currentCooldowns = localStorage.getItem(LS_ZNIPE_FREEMIUM_COOLDOWN);
    const lsObject = parseJSONString(currentCooldowns);
    const pageCooldownExpire = lsObject?.[pageType];

    if (!pageCooldownExpire || (pageCooldownExpire < today.toISOString() && !showFreemiumBanner)) {
      setShowFreemiumBanner(true);
    }
  }, [isPremiumUser, pageType, today, showFreemiumBanner]);

  const onBannerHide = useCallback(() => {
    if (isPremiumUser || !pageType) return;
    const currentCooldowns = localStorage.getItem(LS_ZNIPE_FREEMIUM_COOLDOWN);
    const lsObject = parseJSONString(currentCooldowns);

    if (!currentCooldowns || !lsObject?.[pageType] || lsObject?.[pageType] < today.toISOString()) {
      const daysToAdd = 4 + makeVariation();
      localStorage.setItem(
        LS_ZNIPE_FREEMIUM_COOLDOWN,
        JSON.stringify({
          ...lsObject,
          [pageType]: addDays(daysToAdd),
        }),
      );
      setShowFreemiumBanner(false);
    }
  }, [pageType, today, isPremiumUser]);

  return { isVisible: showFreemiumBanner && !isPremiumUser, onBannerHide };
};

export default useFreemiumShowBanner;
