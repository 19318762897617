import PropTypes from 'prop-types';

export const UPPER_BRACKET = 'Upper bracket';
export const LOWER_BRACKET = 'Lower bracket';
export const GRAND_FINAL = 'Grand final';
export const PLAYOFFS = 'Playoffs';

export const SINGLE_ELIMINATION = 'SINGLE_ELIMINATION';
export const DOUBLE_ELIMINATION = 'DOUBLE_ELIMINATION';

export const finalTitleHeightAndPadding = 64;
export const titleHeightDesktop = 36;
export const titleHeightMobile = 24;

export const emptyTeam = { id: '', logo: null, name: 'TBA', score: 0 };

export const rawTeamShape = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string,
  game: PropTypes.string,
  logoLight: PropTypes.string.isRequired,
  logoDark: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export const teamShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  score: PropTypes.number,
};

export const columnPropShape = {
  name: PropTypes.string,
  matchups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      teamOne: PropTypes.shape(teamShape),
      teamTwo: PropTypes.shape(teamShape),
      winner: PropTypes.string,
    }),
  ),
};

export const columnsPropType = PropTypes.arrayOf(PropTypes.shape(columnPropShape));

const matchupShape = {
  id: PropTypes.string,
  winnerTo: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.number),
  competitors: PropTypes.arrayOf(PropTypes.string),
};

export const bracketShape = {
  name: PropTypes.string,
  type: PropTypes.string,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      scheduleName: PropTypes.string,
      type: PropTypes.string,
      rounds: PropTypes.arrayOf(
        PropTypes.shape({
          roundNumber: PropTypes.number,
          matchups: PropTypes.arrayOf(PropTypes.shape(matchupShape)),
        }),
      ),
    }),
  ),
};
