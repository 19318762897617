import type { AnalyticsManagerInstanceType } from 'znipe-analytics-manager';
import { createContext } from 'react';
import { RefType } from 'znipe-cj/index';
import colors from 'znipe-styles/colors';
import type {
  OVERLAY_IDENTITY,
  OVERLAY_GIFS,
  OVERLAY_MESSGAE_OPTIONS,
  OVERLAY_ALLEGIANCE,
} from 'znipe-chat/src/components/Chat/Chat.constants';
import { Color } from 'znipe-chat/src/hooks/useNameColors';
import type ZnipeEventTarget from 'znipe-utils/events/ZnipeEventTarget';
import { Emoji } from '../components/Emojis/Emojis.types';
import type { ChatStore, ReplyTo } from 'znipe-chat/src/utils/chatStore';

// Message types
export const BAN_TYPE = 'BAN_DM';
export const MESSAGE_TYPE = 'MESSAGE';
export const DELETE_MESSAGE_TYPE = 'DELETE_MESSAGE';
export const BAN_MESSAGE_TYPE = 'BAN_MESSAGE';
export const STATUS_TYPE = 'STATUS';

export type AccountInfo = {
  username: string;
  uid: string;
  isModerator?: boolean;
};

type SpamFilter = {
  maxMessages: number;
  timeWindow: number;
};

export type ChatStatus = {
  spamFilter?: SpamFilter;
  disabled?: boolean;
  maxMessageLength?: number;
};

export type UserListItem = {
  userId: string;
  username: string;
  color: string;
};

export type ModalInfo = {
  modal?:
    | typeof OVERLAY_IDENTITY
    | typeof OVERLAY_GIFS
    | typeof OVERLAY_MESSGAE_OPTIONS
    | typeof OVERLAY_ALLEGIANCE;
  props?: {
    messageId?: string;
  };
};

export type Avatar = {
  id: string;
  label: string;
  shortLabel: string;
  default: boolean;
  img: string;
};

export type FeaturedAvatar = {
  id: string;
  label: string;
  shortLabel?: string;
  img: string;
  isHomeTeam?: boolean;
  isAwayTeam?: boolean;
  default: boolean;
};

type ChatChannel = RefType<'CHAT'>;

export type BanStatus = {
  reason?: string;
  banEnd?: string;
};

export type ChatSignifier = {
  humanReadableId: string;
  displayName: string;
  priority: number;
  url: string;
};

export type AlertData = {
  action: string;
  description: string;
  onClose?: () => void;
  onResolve?: () => void;
  resolveText?: string;
};

export type UserData = {
  username: string;
  userId: string;
  color: string;
  messageId?: string;
  isModerator?: boolean;
  getBoundingClientRect: () => void;
};

export type ChatContextType = {
  chatId?: string;
  authToken?: string;
  chatStatus?: ChatStatus;
  setChatStatus?: React.Dispatch<React.SetStateAction<ChatStatus>>;
  avatarId: string | null;
  userAvatar?: string | null;
  avatars?: Avatar[];
  usernameColor: string;
  chatSignifiers?: ChatSignifier[];
  signifierIds?: string[];
  allEmojis?: Emoji[];
  emojiSet?: string[];
  authManagerId?: string;
  nameColors?: Color[];
  modalInfo: ModalInfo;
  setModalInfo: React.Dispatch<React.SetStateAction<ModalInfo>>;
  featuredAvatars?: FeaturedAvatar[];
  quickSelectEmojis?: Emoji[];
  setUserAvatar: React.Dispatch<React.SetStateAction<string>>;
  setUsernameColor: (color: string) => void;
  accountInfo?: AccountInfo;
  replyData: ReplyTo | null;
  setReplyData: React.Dispatch<React.SetStateAction<ReplyTo | null>>;
  alert: AlertData | null;
  setAlert: React.Dispatch<React.SetStateAction<AlertData | null>>;
  chatStore?: ChatStore;
  chatChannel?: ChatChannel;
  banStatus: BanStatus | null;
  setBanStatus: React.Dispatch<React.SetStateAction<BanStatus | null>>;
  onLoginClick?: () => void;
  onUsernameClick?: (userdata: UserData) => void;
  analyticsManager?: AnalyticsManagerInstanceType;
  eventTarget?: ZnipeEventTarget;
};

const ChatContext = createContext<ChatContextType>({
  setChatStatus: () => {},
  setUserAvatar: () => {},
  setUsernameColor: () => {},
  setReplyData: () => {},
  setAlert: () => {},
  setBanStatus: () => {},
  setModalInfo: () => {},
  banStatus: null,
  modalInfo: {},
  alert: null,
  avatarId: null,
  usernameColor: colors.grey53,
  replyData: null,
});

export const { Provider, Consumer } = ChatContext;

export default ChatContext;
