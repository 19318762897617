export const variants = {
  TEAM: 'TEAM',
  TEAMS: 'TEAMS',
  PLAYER: 'PLAYER',
  ONE_VERSUS_ONE: 'ONE_VERSUS_ONE',
  ONE_VERSUS_MANY: 'ONE_VERSUS_MANY',
  TEXT: 'TEXT',
  SELECT: 'SELECT',
};

export const targetTypes = {
  PLAYER: 'PLAYER',
  TEAM: 'TEAM',
};

export const status = {
  win: 'win',
  wrong: 'wrong',
  pending: 'pending',
};

export const exceptions = ['ROUND_BOMB_PLANT'];
