import styled, { css } from 'styled-components';
import { MEDIUM, LARGE } from './constants';

export const getFontSize = ({ size }) => {
  switch (size) {
    case LARGE:
    case MEDIUM:
      return css`
        font-size: 69px;
        line-height: 89px;
      `;
    default:
      return css`
        font-size: 54px;
        line-height: 74px;
      `;
  }
};

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const InitialName = styled.span`
  display: none;
  position: absolute;
`;

export const Content = styled.div`
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 600;
  ${getFontSize}
  color: ${({ theme }) => theme.textPrimary};
  text-transform: uppercase;
  margin: -18px 0 0 -18px;
  padding: 0;
  opacity: 0.07;
  user-select: none;
  transform: skew(-20deg);
`;

export const StrokeText = styled.span`
  @supports (-webkit-text-stroke: 2px transparent) {
    color: ${({ theme }) => theme.secondaryBackgroundColor};
    background: linear-gradient(
      225.34deg,
      ${({ theme }) => theme.bodyColor} 0%,
      ${({ theme }) => theme.textPrimary} 100%
    );
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;
    @supports not (-moz-appearance: none) {
      /* Adding extra stroke thickness to Firefox */
      -webkit-text-stroke: 2px transparent;
    }
    /* stylelint-enable property-no-vendor-prefix */
  }
`;
