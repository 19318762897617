import styled, { css } from 'styled-components';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 13px 8px;
  height: 40px;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
`;

export const ArrowButton = styled.button.attrs({
  type: 'button',
})`
  ${buttonDefaultStyle}
  width: 24px;
  height: 24px;
  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}
`;
