import styled, { css } from 'styled-components';
import gradients from 'znipe-styles/gradients';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

export const themes = {
  default: {
    scoreTextWidth: '16px',
    highlightColor: gradients.cottonCandy,
    highlightShadow: '0px 0px 6px rgba(255, 127, 183, 0.7)',
    highlightHalfOpacity: gradients.cottonCandyHalfOpacity,
  },
};

const containerWidthCss = css`
  width: 100%;
  @media ${device.tablet} {
    width: 264px;
  }
  @media ${device.desktop} {
    width: 240px;
  }
  @media ${device.desktopLarge} {
    width: 248px;
  }
  @media ${device.desktopExtraLarge} {
    width: 298px;
  }
`;

export const LabelWrapper = styled.div`
  position: relative;
  width: fit-content;
  ${containerWidthCss};
`;

export const Label = styled.label`
  color: ${({ color }) => color};
  position: absolute;
  top: -28px;
  @media ${device.tablet} {
    top: -36px;
  }
`;

export const OuterContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  height: 97px;
  position: relative;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  ${containerWidthCss}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  & > button {
    margin: auto;
  }
`;

export const LogoWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  ${({ highlight }) =>
    highlight &&
    css`
      opacity: 50%;
    `};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  ${({ highlight }) =>
    highlight &&
    css`
      opacity: 50%;
    `};
`;

export const TeamInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  justify-content: center;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  & > p {
    margin: auto;
  }
`;

const hoverStyleCss = css`
  ${({ theme, useHoverStyle, hoveringOnLoser, isWinner }) =>
    useHoverStyle &&
    (() => {
      const bgColor = isWinner ? theme.tertiaryBackgroundColor : theme.secondaryBackgroundColor;
      return css`
        &:hover,
        &:focus {
          border: solid 1px transparent;
          background-image: linear-gradient(${bgColor}, ${bgColor}),
            ${hoveringOnLoser ? theme.matchup.highlightHalfOpacity : theme.matchup.highlightColor};
          background-origin: border-box;
          background-clip: content-box, border-box;
        }
      `;
    })}
`;

const teamWrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.containerStroke};
  background: ${({ theme, isWinner }) =>
    isWinner ? theme.tertiaryBackgroundColor : theme.secondaryBackgroundColor};
  ${hoverStyleCss}
`;

export const VerticalBar = styled.div`
  width: 7px;
  height: 47px;
  ${({ theme, highlight }) =>
    highlight
      ? css`
          background: ${theme.matchup.highlightColor};
          box-shadow: ${theme.matchup.highlightShadow};
        `
      : css`
          background: ${theme.containerStroke};
        `};
  ${({ position }) =>
    position === 'top'
      ? css`
          border-top-left-radius: 4px;
        `
      : css`
          border-bottom-left-radius: 4px;
        `}
`;

const FilterSelected = css`
  border: solid 1px transparent;
  background-image: linear-gradient(
      ${({ theme }) => theme.tertiaryBackgroundColor},
      ${({ theme }) => theme.tertiaryBackgroundColor}
    ),
    linear-gradient(${colors.white}, ${colors.bubblePink});
  background-origin: border-box;
  background-clip: content-box, border-box;
  ${VerticalBar} {
    border-bottom: ${({ $isSelectedByFilter }) => ($isSelectedByFilter ? '1px solid' : 'none')};
  }
`;

export const Top = styled.div`
  ${teamWrapperCss};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${({ $isSelectedByFilter }) => $isSelectedByFilter && FilterSelected}
  &,
  &:focus,
  &:hover {
    border-bottom: ${({ $isSelectedByFilter }) => ($isSelectedByFilter ? '1px solid' : 'none')};
  }
`;

export const Bottom = styled.div`
  ${teamWrapperCss};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  ${({ $isSelectedByFilter }) => $isSelectedByFilter && FilterSelected}
  &,
  &:focus,
  &:hover {
    border-top: ${({ $isSelectedByFilter }) => ($isSelectedByFilter ? '1px solid' : 'none')};
  }
`;

export const TeamDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme, highlight, hoveringOnLoser }) => {
    if (hoveringOnLoser) {
      return theme.matchup.highlightHalfOpacity;
    }
    return highlight ? theme.matchup.highlightColor : theme.containerStroke;
  }};
`;

export const TeamLogo = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 12px;
  background: url(${({ logo }) => logo}) no-repeat;
  background-position: center;
  background-size: contain;
`;
