import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const emptyArray = [];

const makeGetSpotlightPlayerIds = () => {
  const getPage = makeGetPage();
  return createSelector([getPage], page => page?.spotlightPlayers ?? page?.players ?? emptyArray);
};

export const useGetSpotlightPlayerIds = props =>
  useMemoizedGqlSelector(makeGetSpotlightPlayerIds, props);

export default makeGetSpotlightPlayerIds;
