import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useGetMatchGameTitle } from 'tv-selectors/match/makeGetMatchGameTitle';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import useSubscribePageLink from 'tv-hooks/useSubscribePageLink';
import StreamPreviewOnHover from 'tv-modules/Player/StreamPreview/StreamPreviewOnHover';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import LOLPlayerSelector from 'tv-modules/Selector/LOL/PlayerSelector/PlayerSelector';
import CSGOPlayerSelector from 'tv-modules/Selector/CSGO/PlayerSelector/PlayerSelector';
import SelectorGroup from 'znipe-elements/navigation/SelectorGroup/SelectorGroup';
import Popup from 'tv-modules/Onboarding/Popup/Popup';
import SelectedVideoHighlight from 'tv-hocs/SelectedVideoHighlight/SelectedVideoHighLight';
import { playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import { multiPovPopupInfo } from 'tv-routes/Stage/Stage.constants';
import { useGetGameHasStats } from 'tv-selectors/games/makeGetGameHasStats';
import { StreamSelectors, HintWrapper } from '../StageMatchBar.styles';

const TOOLTIP_LABEL = 'Currently watching';

const HintTarget = ({ children, showTutorial, locked }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <HintWrapper
      $showTutorial={render && showTutorial}
      className={locked ? 'locked-pov-selector' : 'free-pov-selector'}
    >
      {children}
    </HintWrapper>
  );
};

HintTarget.propTypes = {
  children: PropTypes.node.isRequired,
  showTutorial: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
};

const TeamStreams = ({
  competitor,
  startIndex,
  matchId,
  selectedMatchGameIndex,
  onStreamItemClick,
  playerRef,
  onStreamPopout,
  showTutorial = false,
}) => {
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });
  const gameTitle = useGetMatchGameTitle({ matchId });
  const navigate = useNavigate();
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const { lineup = [], teamId = '' } = competitor;
  const hasPremiumAccess = useHasPremiumAccess();
  const subscribePageLink = useSubscribePageLink();
  const hasStats = useGetGameHasStats({ matchId, selectedMatchGameIndex });

  const renderPovItem = useCallback(
    (index, size) => {
      const { playerId = '', streamId = '', locked } = lineup[index] || {};
      const gatPlayerPosition = startIndex + index;
      const isSelected = !!playerStreams.find(s => (s || {}).id === streamId);
      const onLOLPlayerSelectorClick = () => {
        if (locked && !hasPremiumAccess) {
          navigate(subscribePageLink);
        } else onStreamItemClick(streamId);
      };

      return (
        <Popup
          popupId={multiPovPopupInfo.popupId}
          headline={multiPovPopupInfo.headline}
          description={multiPovPopupInfo.description}
          timer={multiPovPopupInfo.timer}
          direction="right"
          disabled={!(index === 0 && startIndex === 5)}
        >
          <StreamPreviewOnHover
            key={streamId}
            matchId={matchId}
            selectedMatchGameIndex={selectedMatchGameIndex}
            placement="over"
            streamId={streamId}
            playerRef={playerRef}
            onStreamPopout={onStreamPopout}
            disable={isSelected || (showTutorial && (locked || startIndex === 5))}
            isLocked={locked}
            onStreamItemClick={
              playerStreams.length < 2 && isSelected ? undefined : () => onStreamItemClick(streamId)
            }
          >
            <ToolTip label={TOOLTIP_LABEL} isVisible={isSelected}>
              <HintTarget showTutorial={showTutorial} locked={locked}>
                <SelectedVideoHighlight matchId={matchId} streamId={streamId}>
                  {gameTitle === 'lol' ? (
                    <LOLPlayerSelector
                      size={size}
                      key={playerId}
                      matchId={matchId}
                      playerId={playerId}
                      streamId={streamId}
                      teamId={teamId}
                      gatPlayerPosition={gatPlayerPosition}
                      isSelected={isSelected}
                      isLocked={locked}
                      onClick={
                        playerStreams.length < 2 && isSelected
                          ? undefined
                          : onLOLPlayerSelectorClick
                      }
                    />
                  ) : (
                    <CSGOPlayerSelector
                      size={size}
                      key={playerId}
                      gameId={gameId}
                      playerId={playerId}
                      streamId={streamId}
                      teamId={teamId}
                      isSelected={isSelected}
                      isLocked={locked}
                      showKda={hasStats}
                      onClick={
                        playerStreams.length < 2 && isSelected
                          ? undefined
                          : () => onStreamItemClick(streamId)
                      }
                    />
                  )}
                </SelectedVideoHighlight>
              </HintTarget>
            </ToolTip>
          </StreamPreviewOnHover>
        </Popup>
      );
    },
    [
      lineup,
      startIndex,
      playerStreams,
      matchId,
      selectedMatchGameIndex,
      playerRef,
      onStreamPopout,
      showTutorial,
      gameTitle,
      teamId,
      gameId,
      hasPremiumAccess,
      onStreamItemClick,
      navigate,
      subscribePageLink,
      hasStats,
    ],
  );

  return (
    <StreamSelectors>
      <SelectorGroup
        groupOneLength={lineup.length}
        renderGroupOneSelector={renderPovItem}
        size="medium"
        type="horizontal"
      />
    </StreamSelectors>
  );
};

TeamStreams.propTypes = {
  showTutorial: PropTypes.bool,
  competitor: PropTypes.shape({
    id: PropTypes.string,
    teamId: PropTypes.string,
    lineup: PropTypes.arrayOf(
      PropTypes.shape({
        playerId: PropTypes.string,
      }),
    ),
  }).isRequired,
  startIndex: PropTypes.number.isRequired,
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  onStreamItemClick: PropTypes.func.isRequired,
  onStreamPopout: PropTypes.func.isRequired,
  playerRef: playerPropTypes.isRequired,
};

export default memo(TeamStreams);
