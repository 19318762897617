const SECONDS_IN_YEAR = 31536000;
const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

const getTimeDiffAsString = (diffSec: number, short = false) => {
  const numYears = Math.floor(diffSec / SECONDS_IN_YEAR);
  if (numYears > 0) return `${numYears} year${numYears > 1 ? 's' : ''}`;
  const numDays = Math.floor(diffSec / SECONDS_IN_DAY);
  if (numDays > 0) return `${numDays} day${numDays > 1 ? 's' : ''}`;
  const numHours = Math.floor(diffSec / SECONDS_IN_HOUR);
  if (numHours > 0) return `${numHours} ${short ? 'hr' : 'hour'}${numHours > 1 ? 's' : ''}`;
  const numMinutes = Math.floor(diffSec / SECONDS_IN_MINUTE);
  if (numMinutes > 0)
    return `${numMinutes} ${short ? 'min' : 'minute'}${numMinutes > 1 ? 's' : ''}`;
  if (diffSec > 0) return 'Less than one minute';
  return '';
};

export default getTimeDiffAsString;
