import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const PrivacyPage = styled.div`
  margin: auto;
  width: 60%;
  margin-bottom: 7%;

  a {
    color: #299ded;

    &:active,
    &:visited {
      color: #299ded;
    }

    &:hover,
    &:focus {
      color: #65c0ff;
    }
  }
`;

const SupportEmail = ({ text = 'support@znipe.se' }) => (
  <a href="mailto:support@znipe.se">{text}</a>
);

SupportEmail.propTypes = {
  text: PropTypes.string,
};

const Privacy = () => (
  <PrivacyPage>
    <Helmet title="Znipe´s Privacy Policy" />
    <div className="privacy-page__content">
      <h1>Znipe TV Privacy Policy</h1>
      <h4>Effective: 25th May 2018</h4>

      <p>
        This Privacy Policy outlines how Znipe collects, uses and processes personal data, with whom
        we may share such data with, and your choices surrounding that data.
        <br />
        <br />
        Throughout this Privacy Policy we refer to various capitalized terms. These terms are
        defined in
        <Link to="terms-of-use" target="_blank">
          {' '}
          Terms and Conditions of Use{' '}
        </Link>{' '}
        ( the “Terms and Conditions of Use” ). If you do not agree with the terms of this Privacy
        Policy, then please don&apos;t use the Service.
      </p>

      <h4>Key changes</h4>
      <p>
        Improved navigation and structure including formatting sections with anchor links to
        sections.
        <br />
        <br />
        Simplified wording to better communicate policies using plain language.
        <br />
        <br />
        Clearer explanation of the personal data we collect and share with third parties and for
        what purpose
        <br />
        <br />
        More clarity of the rights you have over your personal data and making it easier for you to
        control that data.
      </p>

      <h2>Table of contents</h2>
      <p>
        <a href="/privacy#what-information">• What information we collect about you</a>
        <br />
        <br />
        <a href="/privacy#how-collect">• How we use information we collect</a>
        <br />
        <br />
        <a href="/privacy#how-share">• How we share information we collect</a>
        <br />
        <br />
        <a href="/privacy#request-data">• How to control and access your information</a>
        <br />
        <br />
        <a href="/privacy#transfer-countries">
          • How we transfer information we collect internationally
        </a>
        <br />
        <br />
        <a href="/privacy#age-restriction">• Age restrictions regarding children</a>
        <br />
        <br />
        <a href="/privacy#cookies">• Cookies and tracking notices</a>
        <br />
        <br />
        <a href="/privacy#contact">• Contacting Znipe</a>
        <br />
        <br />
      </p>
      <h2 id="what-information">What information we collect about you</h2>
      <h4>Account information</h4>
      <p>
        When creating a Znipe account we collect primary information such as your nickname, email
        address, and chosen password. Additional information may be requested such as gender, date
        of birth, location, and personal preferences related to the Service such as interests around
        game titles and esports but are not considered mandatory. This additional information may be
        requested upon account creation or through other communications and will be clearly labeled
        as non-mandatory data collection. Please note we are unable to provide the Service without
        the primary information.
      </p>
      <h4>Content you provide through the Service</h4>
      <p>
        We collect information about your interactions with Znipe including any Content, other users
        and advertising on the Service. This also includes content that you post to the Service.
        <br />
        <br />
        We also collect technical data, which may include URL information, cookie data, your IP
        address and the types and identity of devices and network you are using to access or connect
        to the Service.
      </p>
      <h4>Payment data</h4>
      <p>
        If you purchase any Paid Service your credit or debit card information (such as card type
        and expiration date) and other financial data that we need to process your payment may be
        collected and stored by us and/or the payment processors with which we work.
        <br />
        <br />
        We may also collect some limited information, such as your postal code, mobile number, and
        details of your transaction history, all of which are necessary to provide the Service. In
        addition, the payment processors generally provide us with some limited information related
        to you, such as a unique token that enables you to make additional purchases using the
        information they’ve stored, and your card’s type, expiration date, and certain digits of
        your card number.
      </p>
      <h2 id="how-collect">How we use information we collect</h2>
      <p>
        We will process the information set out above for the following purposes:
        <br />
        <br />
        1. to administrate your account, to enable and provide the Service including, as applicable,
        integration with third party services, and to provide, personalize and improve your
        experience with the Service and any advertising made available on or outside the Service;
        <br />
        <br />
        2. to ensure technical functionality of the Service, develop new products and services, and
        analyse your use of the Service including your interaction with advertising made available
        on the Service, as applicable;
        <br />
        <br />
        3. to communicate with you for Service-related or research purposes including via emails,
        notifications, text messages, or other messages, which you agree to receive;
        <br />
        <br />
        4. to communicate with you, either directly or through one of our partners, for marketing
        and promotional purposes via emails, notifications, or other messages, consistent with any
        permissions you may have communicated to us;
        <br />
        <br />
        5. to enable and promote the Service and other services or products, either within or
        outside the Service;
        <br />
        <br />
        6. to process your payment or prevent or detect fraud;
        <br />
        <br />
        7. to enforce this Privacy Policy, the Terms and Conditions of Use, and any other terms that
        you have agreed to, including to protect the rights, property, or safety of Znipe, its
        users, or any other person, or the copyright-protected content of the Service;
        <br />
        <br />
        8. as otherwise stated in this Privacy Policy.
      </p>
      <h2 id="how-share">How we share information we collect</h2>
      <h4>Marketing and advertising</h4>
      <p>
        We may share information with advertising partners in order to send you promotional
        communications about Znipe or to show you more tailored content, including relevant
        advertising for products and services that may be of interest to you, and to understand how
        users interact with advertisements. The information we share is in a de-identified format
        (for example, through the use of hashing) that does not personally identify you.
      </p>
      <h4>Service providers and others</h4>
      <p>
        We may from time to time share your information with service providers to perform functions
        and process user data and help provide our Service, consistent with this Privacy Policy.
        Where a third party processes user data on our behalf (e.g., a hosting service provider), it
        is subject to security and confidentiality obligations consistent with this Privacy Policy
        and applicable law. Where a third party processes user data on its own behalf, its
        processing is subject to its own Privacy Policy and applicable law.
        <br />
        <br />
        In addition to the above, we may also share your information with third parties for these
        limited purposes:
      </p>
      <p style={{ marginLeft: '30px' }}>
        • to allow a merger, acquisition, or sale of all or a portion of our assets;
        <br />• to respond to legal process (e.g., a court order or subpoena), if we believe in good
        faith that it is necessary to do so;
        <br />• to comply with requirements of mandatory applicable law;
        <br />• to protect the safety of any person;
        <br />• to protect the rights and property of Znipe, including to enforce the Terms and
        Conditions of Use and any other terms that you have agreed to;
        <br />• and to address fraud, security, or technical issues;
        <br />• to publish de-identified or aggregate information about the use of the Service; and
        <br />• to allow other companies in the Znipe group, as applicable, to use your information
        as specified in this Privacy Policy.
      </p>
      <h2 id="request-data">How to control and access your information</h2>
      <p>
        You, being the person whose personal data is being processed have the right to access your
        personal data, request rectification, erasure or blocking of any personal data that is
        inaccurate. To request to access, erase, or modify your data or account, send an email to{' '}
        <SupportEmail />
        with your request, full name, user name, and email associated with your account. We will
        send you an email with a confirmation of your request. After we send this confirmation the
        process may take up to 30 days.
      </p>
      <h2 id="transfer-countries">How we transfer information we collect internationally</h2>
      <p>
        Znipe may transfer, processes and store information about our users on servers located in a
        number of countries. Accordingly, Znipe may share your information with other companies in
        the Znipe group, or subcontract processing to, or share your information with, third parties
        located in countries other than your home country in order to carry out the activities
        specified in this Privacy Policy.
        <br />
        <br />
        Your personal information may therefore be subject to privacy laws that are different from
        those in your country of residence. Information collected within the European Economic Area
        (“EEA”) and Switzerland may, for example, be transferred to and processed by third parties
        identified above, located in a country outside of the EEA and Switzerland, where you may
        have fewer legal rights in relation to your information.
      </p>
      <h2 id="age-restriction">Age restrictions regarding children</h2>
      <p>
        The Znipe Service is not directed to children under the age of 13. (In some countries,
        stricter age limits may apply. See our (
        <Link to="terms-of-use" target="_blank">
          {' '}
          Terms and Conditions of Use.{' '}
        </Link>
        ). We do not knowingly collect personal information from children under 13 or under the
        applicable age limit (the “Age Limit”). If you are under the Age Limit, do not use the
        Service and do not provide any personal information to us.
        <br />
        <br />
        If you are a parent of a child under the Age Limit and become aware that your child has
        provided personal information to Znipe, please contact us at <SupportEmail /> and you may
        request exercise of your applicable access, rectification, cancellation, and/or objection
        rights. If you are a California resident under the age of 18 and you wish to remove publicly
        available content, also please contact us at <SupportEmail />.
      </p>
      <h2 id="cookies">Cookies and tracking notices</h2>
      <p>
        Znipe uses Cookies, local storage, and tracking technologies (i.e pixels) to provide
        functionality and recognize you when you use the Service. Non essential Cookies can be opted
        in or out of easily through the website footer. Note that not enabling Cookies may result in
        a less personalized experience.
        <br />
        <br />A cookie is a very small file, a small piece of data, sent from a website and stored
        in the memory of a user&apos;s web browser, mobile phone, or other device while the user is
        browsing that website. A cookie can help the website provider to recognize your device the
        next time you visit that website, provide you with access to certain functions on the
        website and/or register your surf patterns.
        <br />
        <br />
        When you visit our website, it may leave a Cookie in the memory of your web browser. Most
        major commercial Internet sites use them and they make your Internet-surfing more useful and
        less time-consuming for you because they store information that is reusable each time you
        visit this site, such as passwords and your preferences including information you have
        chosen to share with the site.
        <br />
        <br />
        Cookies <b>cannot access and read the files on your hard drive</b> and{' '}
        <b>cannot be used as a virus.</b>
        The purpose of using Cookies is to provide information and products that are more meaningful
        to you without asking you the same questions every time you visit the website. You may get
        Cookies from our advertisers. There are other technologies such as pixel tags, web bugs, web
        storage and other similar files and technologies that may carry the same functions as
        Cookies. In this Privacy Policy, we use the term “Cookies” for Cookies and all such similar
        technologies.
        <br />
        <br />
        Our website uses session Cookies, which disappear when you shut down your web browser as
        well as persistent Cookies, which are saved on your computer. We cannot pre-screen these
        Cookies since they come directly to you from other computers.
        <br />
        <br />
        If you prefer, you can set your browser to refuse Cookies or to inform you every time a
        website requests permission to store a Cookie. Previously stored Cookies can also be deleted
        via the web browser. However, if you do that, some parts of this site and other sites may
        not work as well.
        <br />
        <br />
        Technologies like local storage is used to provide a better experience for the user by
        remembering their configurations within the platform such as authentication state, chat
        preferences and views of one-time interactions such as a walk through tutorial.
        <br />
        <br />
        We may use the following Cookies for the purposes set out below:
      </p>
      <h4>Functional Cookies</h4>
      <p>
        We use functional Cookies to operate certain functions of the Service in accordance with
        your choices, meaning that when you continue to use or come back to the website, the site
        will be provided as you have previously requested, e.g. remembering your username and
        customization the Service.
      </p>
      <h4>Analytics Cookies</h4>
      <p>
        We use analytics Cookies to analyze how the website and Service is accessed, used or is
        performing. We use the information to maintain, operate and improve the Service.
      </p>
      <h4>Third party Cookies</h4>
      <p>
        We may allow our partners to use Cookies on the website for the same purposes identified
        above.
        <br />
        <br />
        We may also use service providers acting on our behalf to use Cookies for the purposes
        identified above. The site uses third party services to track activity related to the
        Service, e.g. by placing Cookies in your system. You can install an{' '}
        <a href="http://www.allaboutCookies.org/manage-Cookies/opt-out-Cookies.html">opt-out </a>
        in your web browser if you do not want any information to be collected and used by Google
        Analytics.
        <br />
        <br />
        Most web browsers allow you to manage your Cookie preferences. You can set your browser to
        refuse Cookies, or delete certain Cookies. In general you should also be able to manage
        similar technologies by using your browser’s preferences.
        <br />
        <br />
        Please note that if you choose to block Cookies, the functionality of the Service may be
        affected.
        <br />
        <br />
        For more information about the use of Cookies and how to block them, visit
        <a href="http://www.allaboutCookies.org" target="_blank" rel="noopener noreferrer">
          {' '}
          allaboutCookies.org
        </a>
        ,
        <a href="//youronlinechoices.eu" target="_blank" rel="noopener noreferrer">
          {' '}
          youronlinechoices.eu
        </a>{' '}
        (Europe), or{' '}
        <a href="//aboutads.info/choices" target="_blank" rel="noopener noreferrer">
          aboutads.info/choices
        </a>{' '}
        (United States). If you have any questions or comments about our use of Cookies, please
        contact Customer Support.
      </p>
      <h2 id="contact">Contacting Znipe</h2>
      <p>
        Your information is controlled by Znipe Esports AB. If you have any questions about how your
        information is handled or have any concerns, please reach out to us at <SupportEmail /> or
        our mailing address at:
        <br />
        <br />
        Znipe Esports AB,
        <br />
        Oxenstiernsgatan 15A,
        <br />
        Floor 6, 115 26 Stockholm, Sweden
      </p>
    </div>
  </PrivacyPage>
);

export default Privacy;
