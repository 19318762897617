import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $position }) => {
    if ($position === 'left') return 'flex-start';
    if ($position === 'right') return 'flex-end';
    return 'center';
  }};
  list-style: none;
  max-width: 656px;
  width: 100%;
  height: 100%;
`;

export const TabWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex: 1 1 auto;
  position: relative;
  max-width: ${({ $width }) => $width ?? 0};
  user-select: none;
  height: 100%;
`;

const ControlledMenu = ({ items, position = 'center' }) => {
  const menuItems = useMemo(
    () =>
      items.map(({ component, key, width }) => (
        <TabWrapper data-testid="controlled-list-item" key={key} $width={width}>
          {component}
        </TabWrapper>
      )),
    [items],
  );
  return <List $position={position}>{menuItems}</List>;
};

ControlledMenu.propTypes = {
  position: PropTypes.oneOf(['left', 'right', 'center']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    }),
  ).isRequired,
};

export default memo(ControlledMenu);
