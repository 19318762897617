import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const emptyArray = [];

const makeGetScheduledMatches = () => {
  const getPage = makeGetPage();
  return createSelector([getPage], page => page?.scheduledMatches ?? emptyArray);
};

/**
 *
 * @param {{pageType: string}} props The page type to filter on, i.e. "home"
 */
export const useGetScheduledMatches = props =>
  useMemoizedGqlSelector(makeGetScheduledMatches, props);

export default makeGetScheduledMatches;
