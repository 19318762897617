import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetIsOnboardingStepPlayed = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector(
    [getAuthMap, (_, props) => props.stepId],
    (auth, stepId) => auth.onboarding?.steps?.[stepId]?.played ?? false,
  );
};

export const useIsOnboardingPlayed = props =>
  useMemoizedSelector(makeGetIsOnboardingStepPlayed, props);

export default makeGetIsOnboardingStepPlayed;
