import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'tv-hooks/useTheme';
import Icon from 'znipe-elements/general/Icon/Icon';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import { Container, RoleButton, IconWrapper } from './RolesFilter.styles';

const roles = ['topRole', 'jungleRole', 'midRole', 'bottomRole', 'supportRole'];

const tooltipText = {
  topRole: 'TOP LANE',
  jungleRole: 'JUNGLE',
  midRole: 'MID LANE',
  bottomRole: 'BOTTOM LANE',
  supportRole: 'SUPPORT',
};

export const defaultSelection = roles.reduce((acc, role) => ({ ...acc, [role]: false }), {});

const RolesFilter = ({ selection, onChange = () => {} }) => {
  const theme = useTheme();

  const handleChange = useCallback(
    role => onChange({ ...selection, [role]: !selection[role] }),
    [onChange, selection],
  );

  return (
    <Container data-testid="roles-filter">
      {roles.map(role => {
        const isSelected = selection[role];
        const onClick = () => handleChange(role);
        return (
          <RoleButton key={role}>
            <ToolTip label={tooltipText[role]}>
              <IconWrapper
                isSelected={isSelected}
                onClick={onClick}
                color={isSelected ? theme.UIAccent : theme.textPrimary}
                data-testid={`icon-${role}`}
              >
                <Icon type={role} size={32} />
              </IconWrapper>
            </ToolTip>
          </RoleButton>
        );
      })}
    </Container>
  );
};

RolesFilter.propTypes = {
  selection: PropTypes.shape({
    topRole: PropTypes.bool,
    jungleRole: PropTypes.bool,
    midRole: PropTypes.bool,
    bottomRole: PropTypes.bool,
    supportRole: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
};

export default memo(RolesFilter);
