import styled, { css } from 'styled-components';

export const LineSVG = styled.svg`
  transform: ${({ $direction, $position }) =>
    `scaleX(${$direction === 'left' ? 1 : -1}) scaleY(${$position === 'top' ? 1 : -1})`};
  float: ${({ $direction }) => $direction};
`;

const nudgeText = ({ $direction }) =>
  $direction === 'left'
    ? css`
        padding-left: 6px;
      `
    : css`
        padding-right: 6px;
      `;

export const Container = styled.div`
  color: unset;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  transform: translateY(
    ${({ $positionOffset, $position }) =>
      $position === 'top' ? `calc(-100% - ${$positionOffset}px)` : `${$positionOffset}px`}
  );
  top: ${({ $position }) => ($position === 'top' ? 0 : '100%')};
  ${({ $direction }) =>
    $direction === 'left'
      ? css`
          left: calc(50% - 6px);
          align-items: flex-start;
        `
      : css`
          right: calc(50% - 6px);
          align-items: flex-end;
        `};
  z-index: 2;

  & > *:nth-child(1) {
    order: ${({ $position }) => ($position === 'top' ? 1 : 2)};
    ${nudgeText};
  }
  & > *:nth-child(2) {
    order: ${({ $position }) => ($position === 'top' ? 2 : 3)};
    ${nudgeText};
    margin-bottom: 0;
  }
  & > *:nth-child(3) {
    order: ${({ $position }) => ($position === 'top' ? 3 : 1)};
    margin-bottom: ${({ $position }) => ($position === 'top' ? 0 : '8px')};
    margin-top: ${({ $position }) => ($position === 'top' ? '8px' : 0)};
  }
`;
