import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import ReplayAnimation from '../../ReplayAnimation/ReplayAnimation';
import { MainItemWrapper } from '../../../ScreenOverlay.styles';
import { ControlIconWrapper, ControlLabelWrapper, ReplayContainer } from '../ScreenControls.styles';

const MobileVideoCompleted = ({
  playlistAutoplayingEnabled = false,
  onWatchNext = () => {},
  onAutoplayCancel = () => {},
  onWatchAgain = () => {},
}) => {
  if (playlistAutoplayingEnabled && onWatchNext)
    return (
      <ReplayContainer>
        <ReplayAnimation onClick={onWatchNext} onAnimationComplete={onWatchNext} />
        <ControlLabelWrapper onClick={onAutoplayCancel}>
          <Typography type="title-xs">Cancel</Typography>
        </ControlLabelWrapper>
      </ReplayContainer>
    );
  return (
    <>
      <MainItemWrapper onClick={onWatchAgain}>
        <ControlIconWrapper>
          <Icon type="watchAgain" size={32} />
        </ControlIconWrapper>
        <ControlLabelWrapper>
          <Typography type="title-xs">Watch again</Typography>
        </ControlLabelWrapper>
      </MainItemWrapper>
      {onWatchNext && (
        <MainItemWrapper onClick={onWatchNext}>
          <ControlIconWrapper>
            <Icon type="watchNext" size={32} />
          </ControlIconWrapper>
          <ControlLabelWrapper>
            <Typography type="title-xs">Watch Next</Typography>
          </ControlLabelWrapper>
        </MainItemWrapper>
      )}
    </>
  );
};

MobileVideoCompleted.propTypes = {
  playlistAutoplayingEnabled: PropTypes.bool,
  onWatchNext: PropTypes.func,
  onAutoplayCancel: PropTypes.func,
  onWatchAgain: PropTypes.func,
};

export default MobileVideoCompleted;
