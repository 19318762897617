import { useSpring } from '@react-spring/web';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';

export const RIGHT_SIDEBAR_SPRING = 'RIGHT_SIDE_BAR_SPRING';
export const LEFT_SIDEBAR_SPRING = 'LEFT_SIDE_BAR_SPRING';
export const STAGE_SPRING = 'STAGE_SPRING';
export const PLAYER_SPRING = 'PLAYER_SPRING';

const transformOutput = (rightMenu, leftMenu, leftSidebarWidth, rightSidebarWidth) => {
  if (leftMenu && rightMenu) {
    return `translateX(${(leftSidebarWidth - rightSidebarWidth) / 2}px)`;
  }
  if (leftMenu && !rightMenu) {
    return `translateX(${leftSidebarWidth / 2}px)`;
  }
  if (rightMenu && !leftMenu) {
    return `translateX(-${rightSidebarWidth / 2}px)`;
  }
  return `translateX(0px)`;
};

const widthOutput = (
  rightMenu,
  leftMenu,
  leftSidebarWidth,
  rightSidebarWidth,
  stageWidth,
  desktop,
) => {
  if (leftMenu && rightMenu) {
    return `${stageWidth - leftSidebarWidth - rightSidebarWidth}px`;
  }
  if (leftMenu && !rightMenu) {
    return `${stageWidth - leftSidebarWidth}px`;
  }
  if (!leftMenu && rightMenu) {
    return `${stageWidth - rightSidebarWidth}px`;
  }
  if (desktop) {
    return `${stageWidth}px`;
  }
  return '100%';
};

const translateOutput = (rightMenu, leftMenu, rightSidebarWidth, leftSidebarWidth) => {
  if (leftMenu && !rightMenu) {
    return `translateX(-${leftSidebarWidth}px)`;
  }
  if (!leftMenu && rightMenu) {
    return `translateX(${rightSidebarWidth}px)`;
  }
  return 'translateX(0)';
};

const usePlayerScaling = (rightMenu = false, leftMenu = false) => {
  const nodes = useRef({});
  const [rightSidebarWidth, setRightSidebarWidth] = useState(0);
  const [leftSidebarWidth, setLeftSidebarWidth] = useState(0);
  const [stageWidth, setStageWidth] = useState(0);

  const prefersReducedMotion = usePrefersReducedMotion();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();

  const spring = useSpring({
    transform: transformOutput(rightMenu, leftMenu, leftSidebarWidth, rightSidebarWidth),
    width: widthOutput(
      rightMenu,
      leftMenu,
      leftSidebarWidth,
      rightSidebarWidth,
      stageWidth,
      isDesktopOrGreater,
    ),
    translate: translateOutput(rightMenu, leftMenu, rightSidebarWidth, leftSidebarWidth),
    cancel: !isDesktopOrGreater,
    immediate: prefersReducedMotion,
  });

  const callbacks = useMemo(() => {
    const createCallback = (callback, type) => node => {
      if (node !== null && callback) {
        callback(node.offsetWidth);
        if (type) nodes.current[type] = node;
      }
    };
    return {
      [STAGE_SPRING]: createCallback(setStageWidth, STAGE_SPRING),
      [RIGHT_SIDEBAR_SPRING]: createCallback(setRightSidebarWidth, RIGHT_SIDEBAR_SPRING),
      [LEFT_SIDEBAR_SPRING]: createCallback(setLeftSidebarWidth, LEFT_SIDEBAR_SPRING),
      [PLAYER_SPRING]: () => {},
    };
  }, []);

  const createSpringRef = useCallback(type => callbacks[type], [callbacks]);

  useEffect(() => {
    const handleWindowResize = () => {
      Object.entries(nodes.current).forEach(([type, node]) => callbacks[type](node));
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [callbacks]);

  return { createSpringRef, spring };
};

export default usePlayerScaling;
