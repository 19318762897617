import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import Typography from 'znipe-elements/general/Typography/Typography';
import TextOverlay from 'znipe-elements/general/TextOverlay/TextOverlay';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import Link from 'znipe-elements/general/Link/Link';
import Icon from 'znipe-elements/general/Icon/Icon';
import {
  SectionWrapper,
  Title,
  ListWrapper,
  ListItem,
  SeeMoreSection,
  LinkWrapper,
} from './Section.styles';
import { GRID, LIST, SCROLLLIST, PLAYLISTGRID } from './Section.constants';

const Section = ({
  title = '',
  direction = 'vertical',
  content = [],
  children,
  type = GRID,
  fullWidth = false,
  titleColor = '',
  backgroundTitle = '',
  foregroundTitle = '',
  maxSectionHeaderSize = 'medium',
  link = '',
  onSeeAllLinkClick = null,
  noTitleMargin = false,
  noTopMargin = false,
}) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();
  const typographyType = useMemo(() => {
    if (direction === 'vertical') {
      if (isDesktopLargeOrGreater) {
        return 'title-xl';
      }
      if (isTabletOrGreater) {
        return 'title-l';
      }

      return 'title-m';
    }

    // direction === 'horizontal'
    if (isDesktopExtraLargeOrGreater) {
      return 'title-m';
    }
    if (isDesktopOrGreater) {
      return 'title-s';
    }

    return 'title-xs';
  }, [
    direction,
    isTabletOrGreater,
    isDesktopOrGreater,
    isDesktopLargeOrGreater,
    isDesktopExtraLargeOrGreater,
  ]);

  const linkTypographySize = useMemo(() => {
    if (isDesktopLargeOrGreater) return 'title-l';
    if (isTabletOrGreater) return 'title-m';
    return 'title-s';
  }, [isDesktopLargeOrGreater, isTabletOrGreater]);

  const headLineSize = useMemo(() => {
    if (isDesktopLargeOrGreater && maxSectionHeaderSize === 'large') return 'large';
    if (isTabletOrGreater && maxSectionHeaderSize !== 'small') return 'medium';
    return 'small';
  }, [isTabletOrGreater, isDesktopLargeOrGreater, maxSectionHeaderSize]);

  const listData = useMemo(
    () =>
      content.map(item => (
        <ListItem key={generateUniqueId()} direction={direction}>
          {item}
        </ListItem>
      )),
    [content, direction],
  );

  return (
    <SectionWrapper
      direction={direction}
      fullWidth={fullWidth}
      data-testid="section-wrapper"
      noTopMargin={noTopMargin}
    >
      {title && (
        <Title
          noTitleMargin={noTitleMargin}
          fullWidth={fullWidth}
          direction={direction}
          type={type}
          data-testid="section-title"
        >
          <Typography type={typographyType}>{title}</Typography>
        </Title>
      )}
      {!title && backgroundTitle && foregroundTitle && (
        <Title
          noTitleMargin={noTitleMargin}
          fullWidth={fullWidth}
          direction={direction}
          data-testid="section-title"
          type={type}
          isGradientText
        >
          <TextOverlay
            backgroundText={backgroundTitle}
            foregroundText={foregroundTitle}
            strokeColor={titleColor}
            size={headLineSize}
            type="solid"
            horizontalOffset={0.55}
          />
        </Title>
      )}
      <ListWrapper
        fullWidth={fullWidth}
        type={type}
        direction={direction}
        data-testid="list-wrapper"
      >
        {children || listData}
      </ListWrapper>
      {(link || onSeeAllLinkClick) && (
        <SeeMoreSection>
          {onSeeAllLinkClick && (
            <LinkWrapper onClick={onSeeAllLinkClick}>
              <Typography type={linkTypographySize}>see all</Typography>
              <Icon type="arrowRight" size={14} />
            </LinkWrapper>
          )}
          {link && (
            <Link to={link} withIcon>
              <Typography type={linkTypographySize}>see all</Typography>
              <Icon type="arrowRight" size={14} />
            </Link>
          )}
        </SeeMoreSection>
      )}
    </SectionWrapper>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  backgroundTitle: PropTypes.string,
  foregroundTitle: PropTypes.string,
  maxSectionHeaderSize: PropTypes.oneOf(['small', 'medium', 'large']),
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  content: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.oneOf([GRID, LIST, SCROLLLIST, PLAYLISTGRID]),
  fullWidth: PropTypes.bool,
  noTitleMargin: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  onSeeAllLinkClick: PropTypes.func,
  link: PropTypes.string,
};

export default Section;
