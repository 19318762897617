import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import colors from 'znipe-styles/colors';

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const LoadingButton = ({ loading = false, children = null, type = 'button' }) => (
  <Button variant="solid-color" type={type} size="small">
    {loading ? (
      <LoadingSpinnerWrapper>
        <LoadingSpinner fillColor={colors.white} />
      </LoadingSpinnerWrapper>
    ) : (
      children
    )}
  </Button>
);

LoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default LoadingButton;
