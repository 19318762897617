import {
  PLAYERS,
  TEAMS,
  EVENTS,
  CHAMPIONS,
  ONBOARDING_FITLER_TARGET_ID,
} from 'tv-modules/Filters/Filters.constants';

export const themes = {
  default: {
    banner: {
      targetId: ONBOARDING_FITLER_TARGET_ID,
      coverImage: 'https://assets.znipe.tv/onboarding/csgo/Onboarding-banner-vods-small.png',
      title: 'Find the right match for you',
      text: 'Filter matches by players and teams to explore the content you’re looking for.',
    },
  },
  proview: {
    banner: {
      targetId: ONBOARDING_FITLER_TARGET_ID,
      coverImage: 'https://assets.znipe.tv/onboarding/lol/vods-header.png',
      title: 'Looking for your favourite?',
      text: 'Filter matches by players and teams, as well as champions on VODs.',
    },
  },
};
export const SCHEDULE = 'schedule';
export const VODS = 'vods';

export const filterOptionsDesktop = {
  schedule: {
    sort: false,
    top: [EVENTS],
    panel: [PLAYERS, TEAMS],
  },
  vods: {
    sort: false,
    top: [EVENTS],
    panel: [PLAYERS, TEAMS, CHAMPIONS],
  },
};

export const filterOptionsMobile = {
  schedule: {
    sort: false,
    top: null,
    panel: [PLAYERS, TEAMS, EVENTS],
  },
  vods: {
    sort: false,
    top: null,
    panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
  },
};
