import { useRef, useCallback } from 'react';
import { getSelection } from '../utils/DOMNode';
import getCaretPosition from '../utils/getCaretPosition';
import setCaretPosition from '../utils/setCaretPosition';
import { LARGE_NUMBER } from '../ChatLockup.constants';

export type CaretPositionFunctions = {
  caretPosition: React.MutableRefObject<[number, number]>;
  storedFocusNode: React.MutableRefObject<Node | null>;
  storeFocusNode: () => void;
  getCaretPosition: (offset?: number, collapseTo?: 'focus' | 'anchor') => [number, number];
  focus: (position?: number) => void;
  select: (nextFocusPos: number, direction: 'forward' | 'backward') => void;
};

const useCaretPosition = (
  contentRef: React.RefObject<HTMLDivElement | undefined>,
): CaretPositionFunctions => {
  const caretPosition = useRef<[number, number]>([0, 0]);
  const storedFocusNode = useRef<Node | null>(null);

  const storeFocusNode = useCallback(() => {
    if (!contentRef.current) return;
    const selection = getSelection(contentRef.current);
    if (!selection?.focusNode || !contentRef.current.contains(selection.focusNode)) return;
    storedFocusNode.current = selection.focusNode;
  }, [contentRef]);

  const handleCaretPosition = useCallback(
    (offset = 0, collapseTo?: 'focus' | 'anchor') => {
      if (!contentRef.current) return caretPosition.current;
      const selection = getCaretPosition(contentRef.current) ?? caretPosition.current; // If we have a selection, use it, otherwise use the current caret position
      caretPosition.current = selection.map(value => value + offset) as [number, number];

      if (collapseTo === 'focus') {
        // eslint-disable-next-line prefer-destructuring
        caretPosition.current[0] = caretPosition.current[1];
      } else if (collapseTo === 'anchor') {
        // eslint-disable-next-line prefer-destructuring
        caretPosition.current[1] = caretPosition.current[0];
      }

      return caretPosition.current;
    },
    [contentRef],
  );

  const focus = useCallback(
    (position = LARGE_NUMBER) => {
      if (!contentRef.current) return;
      setCaretPosition(contentRef.current, position);
      handleCaretPosition();
      storeFocusNode();
    },
    [contentRef, handleCaretPosition, storeFocusNode],
  );

  const select = useCallback(
    (nextFocusPos: number, direction: 'forward' | 'backward') => {
      if (!contentRef.current) return;
      const selection = getSelection(contentRef.current);
      if (!selection) return;
      const MAX_CHARACTERS = 500;
      for (let i = 0; i < MAX_CHARACTERS && handleCaretPosition()[1] !== nextFocusPos; i++) {
        selection.modify('extend', direction, 'character');
      }

      handleCaretPosition();
      storeFocusNode();
    },
    [contentRef, handleCaretPosition, storeFocusNode],
  );

  return {
    caretPosition,
    storedFocusNode,
    storeFocusNode,
    getCaretPosition: handleCaretPosition,
    focus,
    select,
  };
};

export default useCaretPosition;
