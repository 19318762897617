import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { Arrow } from '../Carousel.styles';

const NavArrow = ({ type, onClick, dimmed }) => {
  if (type === 'prev') {
    return (
      <Arrow onClick={onClick} dimmed={dimmed} data-testid="back-arrow">
        <Icon type="chevronLeft" size={20} />
      </Arrow>
    );
  }

  if (type === 'next') {
    return (
      <Arrow onClick={onClick} dimmed={dimmed} data-testid="forward-arrow" rotated>
        <Icon type="chevronLeft" size={20} />
      </Arrow>
    );
  }

  return null;
};

NavArrow.propTypes = {
  type: PropTypes.oneOf(['prev', 'next']).isRequired,
  onClick: PropTypes.func.isRequired,
  dimmed: PropTypes.bool.isRequired,
};

export default NavArrow;
