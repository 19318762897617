import { useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'znipe-elements/navigation/Dropdown/Dropdown';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { scenarioType } from '../../../types';
import {
  DropDownSelectContainer,
  SelectConfirmContainer,
} from './PredictionInputHandlement.styles';
import { predict } from '../../../predictionActions';

const DropdownPredictInput = ({ scenario, gameId }) => {
  const { options } = scenario;
  const [optionItems, setOptionItems] = useState([]);
  const [predictedValue, setPredictedValue] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const userId = useUserId();

  const optionItemsFormatted = useMemo(
    () =>
      Object.values(options).map(option => ({
        id: option.id,
        title: option.label,
        value: option.value,
        selected: false,
      })),
    [options],
  );

  useEffect(() => {
    if (optionItemsFormatted.length !== 0 && optionItems.length === 0) {
      setOptionItems(optionItemsFormatted);
    }
  }, [optionItems.length, optionItemsFormatted]);

  const onPredict = () => {
    predict(scenario, predictedValue, userId, gameId);
  };

  const handleChange = inputValue => {
    const { value, title } = inputValue.filter(option => option.selected === true)[0];
    setPredictedValue(value);
    setSelectedLabel(title);
  };

  return (
    <DropDownSelectContainer>
      <Dropdown
        type="single"
        items={optionItems}
        onDataChange={handleChange}
        size="small"
        label={selectedLabel || 'Select player'}
      />
      {predictedValue && (
        <SelectConfirmContainer>
          <Button variant="secondary" size="xsmall" onClick={onPredict} fluid>
            Confirm
          </Button>
        </SelectConfirmContainer>
      )}
    </DropDownSelectContainer>
  );
};

DropdownPredictInput.propTypes = {
  scenario: scenarioType.isRequired,
  gameId: PropTypes.string.isRequired,
};

export default memo(DropdownPredictInput);
