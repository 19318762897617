import PropTypes from 'prop-types';

import VerticalPlayerSelector from './Vertical/types/PlayerSelector';
import VerticalEventSelector from './Vertical/types/EventSelector';
import VerticalMapSelector from './Vertical/types/MapSelector';

import HorizontalPlayerSelector from './Horizontal/types/PlayerSelector';
import HorizontalEventSelector from './Horizontal/types/EventSelector';
import HorizontalMapSelector from './Horizontal/types/MapSelector';

import SlimHorizontalEventSelector from './SlimHorizontal/types/EventSelector';
import SlimHorizontalMapSelector from './SlimHorizontal/types/MapSelector';

import {
  propTypes,
  STREAM_TYPE_EVENT,
  STREAM_TYPE_MAP,
  STREAM_TYPE_PLAYER,
  TYPE_HORIZONTAL,
  TYPE_SLIM_HORIZONTAL,
  TYPE_VERTICAL,
} from './constants';

const types = {
  [TYPE_HORIZONTAL]: {
    [STREAM_TYPE_PLAYER]: HorizontalPlayerSelector,
    [STREAM_TYPE_EVENT]: HorizontalEventSelector,
    [STREAM_TYPE_MAP]: HorizontalMapSelector,
  },
  [TYPE_VERTICAL]: {
    [STREAM_TYPE_PLAYER]: VerticalPlayerSelector,
    [STREAM_TYPE_EVENT]: VerticalEventSelector,
    [STREAM_TYPE_MAP]: VerticalMapSelector,
  },
  [TYPE_SLIM_HORIZONTAL]: {
    [STREAM_TYPE_EVENT]: SlimHorizontalEventSelector,
    [STREAM_TYPE_MAP]: SlimHorizontalMapSelector,
  },
};

const Selector = ({ type, streamType, 'data-testid': testId, ...props }) => {
  const Component = types[type]?.[streamType];

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component data-testid={testId || `stream-selector-${type}-${streamType}`} {...props} />;
};

Selector.propTypes = {
  ...propTypes,
  type: PropTypes.oneOf([TYPE_VERTICAL, TYPE_HORIZONTAL, TYPE_SLIM_HORIZONTAL]).isRequired,
  streamType: PropTypes.oneOf(['player', 'event', 'map']).isRequired,
};

export default Selector;
