import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetSelectedMatchTeams from 'tv-selectors/match/makeGetMatchTeams';

export const defaultLogoUrl = 'https://assets.znipe.tv/teams/default/default_logo.svg';

const defaultProps = { useDarkLogos: false };

const makeGetMatchTeamsLogos = () => {
  const getSelectedMatchTeams = makeGetSelectedMatchTeams();
  const getProps = makeGetProps();
  return createSelector([getSelectedMatchTeams, getProps], (teams, props) => {
    const { useDarkLogos } = props || defaultProps;
    return useDarkLogos
      ? teams.map(team => team.logoDark || team.logoLight || team.logoSrc || defaultLogoUrl)
      : teams.map(team => team.logoLight || team.logoDark || team.logoSrc || defaultLogoUrl);
  });
};

export const useMatchTeamsLogos = props => useMemoizedGqlSelector(makeGetMatchTeamsLogos, props);

export default makeGetMatchTeamsLogos;
