import { useParams } from 'react-router-dom';
import useQueryObject from 'tv-hooks/useQueryObject';
import addQueriesToUrl from 'znipe-utils/location/addQueriesToUrl';
import useSubscribePageLink from 'tv-hooks/useSubscribePageLink';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';

const useHighlightLink = (options = {}) => {
  const {
    highlightId = '',
    playlistId = '',
    parent = false,
    type = 'highlight',
    isLocked,
  } = options;
  const subscribeLink = useSubscribePageLink();
  const hasPremiumAccess = useHasPremiumAccess();
  const { packageSlug } = useParams();
  const { l: playlistIdQuery } = useQueryObject();

  if (isLocked && !hasPremiumAccess) return subscribeLink;

  const suffix = parent ? 's' : '';
  const pathname = packageSlug ? `/${packageSlug}/${type}${suffix}/` : `/${type}${suffix}/`;
  const queryObject = {};

  const selectedPlaylistId = playlistId || playlistIdQuery;
  if (selectedPlaylistId) queryObject.l = selectedPlaylistId;
  if (highlightId) queryObject.h = highlightId;
  const urlString = addQueriesToUrl(pathname, queryObject);

  return urlString;
};

export default useHighlightLink;
