import { interval, timer, from, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { exhaustMap, switchMap, delayWhen, map, catchError } from 'rxjs/operators';
import firebase from 'firebase/app';
import config from 'tv-config/config';
import getCookie from 'znipe-utils/web/getCookie';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';
import getDeviceId from 'znipe-utils/web/getDeviceId';
import getUserSessionId from 'tv-utils/getUserSessionId';
import AnalyticsManager from 'znipe-analytics-manager';

const analyticsManager = new AnalyticsManager('proview');
AnalyticsManager.setEndpoint(config.ANALYTICS_EVENT_API_URL);

// @TODO: Remove riotQA when riot is done testing
const useRiotQa = Boolean(
  global.document && queryStringToObject(window.location.search)['riot-qa'],
);

const baseAuthUrl = useRiotQa
  ? 'https://stage.login.i.lolesports.com'
  : 'https://login.lolesports.com';
const rewardsBaseUrl = useRiotQa
  ? 'https://qa-account.rewards.lolesports.com/v1/session'
  : 'https://account.rewards.lolesports.com/v1/session';
const rsoAuthUrl = useRiotQa
  ? 'https://stage.auth.accounts.riotgames.com/authorize?client_id=esports-rna-stage&redirect_uri=https://qa-account.rewards.lolesports.com/v1/session/oauth-callback&response_type=code&scope=openid&prompt=none'
  : 'https://auth.riotgames.com/authorize?client_id=esports-rna-prod&redirect_uri=https://account.rewards.lolesports.com/v1/session/oauth-callback&response_type=code&scope=openid&prompt=none';

const getRedirectUri = () => window.encodeURIComponent(window.location.href);

let subscription = null;

// Copied from riots slack code snippet
export const getInfoFromIdHint = () => {
  const cookie = getCookie('id_hint');
  if (!cookie) return {};
  const idHint = window.decodeURIComponent(cookie);
  const parts = idHint.split(/=|&/);
  const puuid = parts[parts.indexOf('sub') + 1];
  const username =
    parts.indexOf('game_name') > -1
      ? parts[parts.indexOf('game_name') + 1]
      : parts[parts.indexOf('summoner') + 1];
  const userId = parts[parts.indexOf('id') + 1];
  const region = parts[parts.indexOf('region') + 1];
  const isLoggedIn = !!puuid;
  const info = { puuid, userId, username, region, isLoggedIn };
  return info;
};

const sendNavigationEvent = () => {
  const { hostname, protocol, pathname, search } = global.location;
  const prevPath = `${protocol}//${hostname}${pathname ?? ''}${search ?? ''}`;
  const deviceId = getDeviceId();
  const userSession = getUserSessionId();
  const fields = {
    deviceId,
    userSession,
    referrer: prevPath,
    metadata: {
      hostname,
      isPremiumUser: false,
      packageName: 'proview',
      packageId: '',
    },
    customerData: {
      regionId: '',
    },
    uri: 'riot://authorize',
    userId: '',
  };
  analyticsManager.trackNavigationEvent(fields);
};

export const logout = async cb => {
  if (subscription) subscription.unsubscribe();
  // Logout from firebase
  const firebaseLogout = firebase.auth().signOut();
  // Log out from web session service
  const webSessionLogout = fetch(`${rewardsBaseUrl}/logout`, { credentials: 'include' });
  await Promise.all([firebaseLogout, webSessionLogout]);
  if (cb) cb();
  // logout from lol-esports.com
  const query = config.REQUIRES_AUTH ? '' : `?redirect_uri=${getRedirectUri()}`;

  window.location.href = `${baseAuthUrl}/out${query}`;
};

export const login = () => {
  sendNavigationEvent();
  window.location.href = `${baseAuthUrl}?redirect_uri=${getRedirectUri()}`;
};

const cancel = of({ cancel: true });

// Authenticate towards Znipes API
const authenticate$ = fromFetch(`${config.RIOT_AUTH_API}/v4/auth/tokens/riot-games/login`, {
  credentials: 'include',
})
  .pipe(
    switchMap(response => response.json()),
    catchError(logout),
  )
  .pipe(
    switchMap(({ results }) => {
      const { token, claims } = results;
      if (!token) return logout();
      return from(firebase.auth().signInWithCustomToken(token)).pipe(
        switchMap(user => {
          if (!user) return logout();
          const { additionalUserInfo = {} } = user;
          const { isNewUser } = additionalUserInfo;
          if (isNewUser && window.gtag) {
            // eslint-disable-next-line no-undef
            gtag('event', 'conversion', {
              allow_custom_scripts: true,
              send_to: 'DC-8663141/provi0/provi0+unique',
            });
          }
          // This should probably be done by the api
          const { sub, gameName, region, locale } = claims;
          const updateObject = {};
          updateObject[`/users/${sub}/username`] = gameName;
          updateObject[`/users/${sub}/region`] = region;
          updateObject[`/users/${sub}/locale`] = locale;
          updateObject[`/users/${sub}/externalIds/riot`] = sub;

          return from(firebase.database().ref().update(updateObject));
        }),
      );
    }),
    catchError(logout),
  );

export default onFailedToAuthenticate => {
  const tokenRenewal$ = timer(0, 1000).pipe(
    exhaustMap(iter =>
      fromFetch(`${rewardsBaseUrl}/${iter > 0 ? 'refresh' : 'token'}`, { credentials: 'include' })
        .pipe(
          switchMap(response => {
            // If we fail to fetch a token, then we need to re-authenticate to riots web session service
            if (response.status === 404) {
              window.location.href = `${rsoAuthUrl}&state=${window.location.href}`;
              return cancel;
            }

            // Not logged in on lolesports
            if (!response.ok) {
              if (config.REQUIRES_AUTH) {
                login();
              } else {
                firebase.auth().signOut();
                if (onFailedToAuthenticate) onFailedToAuthenticate();
                subscription.unsubscribe();
              }
              return cancel;
            }

            return response.json();
          }),
          catchError(logout),
        )
        .pipe(
          switchMap(json => {
            if (json.cancel) return cancel;
            // iter 0 means it's the first time running, so we should authenticate towards our api
            if (iter > 0) return of(json);
            return authenticate$.pipe(map(() => json));
          }),
        )
        .pipe(
          // Wait with fetching a new generate a session until 30 - 90s before it expires
          map(json => {
            if (json.cancel) return 0;
            const { session_refresh_interval_seconds: remaining } = json;
            const min = remaining - 90;
            const max = remaining - 30;
            const nextRefreshSeconds = Math.floor(Math.random() * (max - min + 1)) + min;
            return nextRefreshSeconds * 1000;
          }),
          delayWhen(interval),
        ),
    ),
  );

  const sub = tokenRenewal$.subscribe();
  if (subscription) subscription.unsubscribe();
  subscription = sub;
  return sub;
};
