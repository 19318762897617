import { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Route, Navigate, Routes, useNavigate, useLocation } from 'react-router-dom';
import GlobalStyle from 'znipe-styles/global.styles';
import useTheme from 'tv-hooks/useTheme';
import withTheme from 'znipe-themes/hocs/withTheme';
import Fonts from 'znipe-elements/general/Typography/Fonts/Fonts';
import useSelectCurrency from 'tv-hooks/useSelectCurrency';
import StripeCardForm from 'tv-modules/Payment/CardForm/StripeCardForm';
import PayPalForm from 'tv-modules/Payment/PayPalForm/PayPalForm';
import PayPalError from 'tv-modules/Payment/ErrorPage/PayPalError/PayPalError';
import CancelInfo from 'tv-modules/Subscription/CancelInfo/CancelInfo';
import CancelSuccessOrError from 'tv-modules/Subscription/CancelSuccessOrError/CancelSuccessOrError';
import CancelSubscriptionForm from 'tv-modules/Subscription/CancelSubscriptionForm/CancelSubscriptionForm';
import BillingHistory from 'tv-modules/Subscription/BillingHistory/BillingHistory';
import SelectPaymentMethod from 'tv-modules/Payment/SelectPaymentMethod/SelectPaymentMethod';
import RedeemCoupon from 'tv-modules/Subscription/RedeemCoupon/RedeemCoupon';
import SuccessPage from 'tv-modules/Payment/SuccessPage/SuccessPage';
import ErrorPage from 'tv-modules/Payment/ErrorPage/ErrorPage';
import Header from 'tv-modules/Settings/Header/Header';
import Footer from 'tv-modules/Settings/Footer/Footer';
import UpdateCardSuccessOrError from 'tv-modules/Settings/UpdateCardSuccessOrError/UpdateCardSuccessOrError';
import TermsOfService from 'tv-modules/Legal/TermsOfService/TermsOfService';
import { useGetPackageProducts } from 'tv-selectors/packages/makeGetPackageProducts';
import { useGetProductName } from 'tv-selectors/products/makeGetProductName';
import MyProView from 'tv-modules/Subscription/MyProView/MyProView';
import usePackageName from 'tv-hooks/usePackageName';
import { getProduct } from 'tv-actions/old/products';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import SettingsQuery from 'znipe-gql/queries/getPackage.graphql';
import themes from './Settings.themes';
import { PageContainer, PageWrapper, ContentWrapper } from './Settings.styles';

const Page = ({ children }) => (
  <PageWrapper>
    <ContentWrapper>{children}</ContentWrapper>
  </PageWrapper>
);

const paymentPeriod = 'monthly';

const Settings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const packageName = usePackageName();
  const packageProductIds = useGetPackageProducts({ packageName });
  const productId = packageProductIds[0]; // @TODO select the correct one when we have more than 1
  const productName = useGetProductName({ productId }) || '';
  const currency = useSelectCurrency();
  const [promoCode, setPromoCode] = useState('');
  const dispatch = useDispatch();

  const queryOptions = useMemo(
    () => ({
      ssr: true,
      cacheTime: 30,
      variables: {
        package: packageName,
      },
    }),
    [packageName],
  );

  useReduxGqlQuery(SettingsQuery, queryOptions);

  useEffect(() => {
    // 'usd' should be calculate in some nice way on server side. Probably by location
    if (!productId) return;
    dispatch(getProduct(productId, { period: paymentPeriod, currency: 'usd' }));
  }, [dispatch, productId]);

  const onCancelSuccess = useCallback(() => {
    navigate('cancel/confirmed');
  }, [navigate]);

  const onCancelFailure = useCallback(() => {
    navigate('cancel/error');
  }, [navigate]);

  const onUpdatePaymentSuccess = useCallback(() => {
    navigate('payment/confirmed');
  }, [navigate]);

  const onUpdateCardFailure = useCallback(() => {
    navigate('payment/card/error');
  }, [navigate]);

  const onUpdatePayPalFailure = useCallback(() => {
    navigate('payment/paypal/error');
  }, [navigate]);

  const handlePromoSubmit = useCallback(
    code => {
      if (code) {
        setPromoCode(code);
        navigate('redeem/success');
      } else navigate('redeem/error');
    },
    [navigate],
  );

  const goToRoot = useCallback(() => {
    navigate('');
  }, [navigate]);

  const closePath = useMemo(() => {
    const { state } = location;
    if (state?.from) return state.from;
    return packageName ? `/${packageName}/home` : '/home';
  }, [location, packageName]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Fonts />
      <PageContainer>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header title="My Pro View" closePath={closePath} />
                <Page>
                  <MyProView productId={productId} baseUrl=".." />
                </Page>
              </>
            }
          />
          <Route
            path="redeem"
            element={
              <>
                <Header backUrl=".." title="Redeem promo code" closePath={closePath} />
                <Page>
                  <RedeemCoupon productId={productId} onSuccess={handlePromoSubmit} />
                </Page>
              </>
            }
          />
          <Route
            path="redeem/success"
            element={
              <>
                <Header title="Redeem promo code" closePath={closePath} />
                <Page>
                  <SuccessPage
                    header="Hurray!"
                    to=".."
                    promoCode={!!promoCode}
                    productId={productId}
                  />
                </Page>
              </>
            }
          />
          <Route
            path="redeem/error"
            element={
              <>
                <Header title="Redeem promo code" closePath={closePath} />
                <Page>
                  <ErrorPage
                    header="Something has gong wrong"
                    description="Sorry, there was a technical error processing your promo code. Please contact support or try again later."
                    buttonLabel="Back to my pro view"
                    to=".."
                  />
                </Page>
              </>
            }
          />
          <Route
            path="cancel"
            element={
              <>
                <Header backUrl=".." title="Cancel subscription" closePath={closePath} />
                <Page>
                  <CancelInfo />
                </Page>
              </>
            }
          />
          <Route
            path="cancel/form"
            element={
              <>
                <Header backUrl="../cancel" title="Cancellation reasons" closePath={closePath} />
                <Page>
                  <CancelSubscriptionForm
                    productId={productId}
                    onSuccess={onCancelSuccess}
                    onFailure={onCancelFailure}
                    goBackTo=".."
                    productName={productName}
                  />
                </Page>
              </>
            }
          />
          <Route
            path="cancel/confirmed"
            element={
              <>
                <Header closePath={closePath} />
                <Page>
                  <CancelSuccessOrError onClick={goToRoot} />
                </Page>
              </>
            }
          />
          <Route
            path="cancel/error"
            element={
              <>
                <Header closePath={closePath} />
                <Page>
                  <CancelSuccessOrError onClick={goToRoot} isSuccess={false} />
                </Page>
              </>
            }
          />
          <Route
            path="billing"
            element={
              <>
                <Header backUrl=".." title="My billing history" closePath={closePath} />
                <Page>
                  <BillingHistory productId={productId} />
                </Page>
              </>
            }
          />
          <Route
            path="payment"
            element={
              <>
                <Header backUrl=".." title="Payment Method" closePath={closePath} />
                <Page>
                  <SelectPaymentMethod disablePromoCode disableImage />
                </Page>
              </>
            }
          />
          <Route
            path="payment/card"
            element={
              <>
                <Header backUrl="../payment" title="Card details" closePath={closePath} />
                <Page>
                  <StripeCardForm
                    onSuccess={onUpdatePaymentSuccess}
                    onFailure={onUpdateCardFailure}
                    productId={productId}
                    buttonLabel="Add card"
                    termsOfServices={[<TermsOfService productName={productName} />]}
                    currency={currency}
                    period={paymentPeriod}
                    edit
                  />
                </Page>
              </>
            }
          />
          <Route
            path="payment/confirmed"
            element={
              <>
                <Header closePath={closePath} />
                <Page>
                  <UpdateCardSuccessOrError to=".." />
                </Page>
              </>
            }
          />
          <Route
            path="payment/card/error"
            element={
              <>
                <Header closePath={closePath} />
                <Page>
                  <UpdateCardSuccessOrError to=".." isSuccess={false} />
                </Page>
              </>
            }
          />
          <Route
            path="payment/paypal"
            element={
              <>
                <Header backUrl="../payment" title="Add PayPal" closePath={closePath} />
                <Page>
                  <PayPalForm
                    onSuccess={onUpdatePaymentSuccess}
                    onFailure={onUpdatePayPalFailure}
                    productId={productId}
                    currency={currency}
                    period={paymentPeriod}
                    edit
                  />
                </Page>
              </>
            }
          />
          <Route
            path="payment/paypal/error"
            element={
              <>
                <Header closePath={closePath} />
                <Page>
                  <PayPalError description="The provided paypal account has insufficient funds. Please try another payment method." />{' '}
                </Page>
              </>
            }
          />
          <Route path="*" element={<Navigate to="" />} />
        </Routes>
        <Footer />
      </PageContainer>
    </ThemeProvider>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};

export default withTheme(Settings, themes, 'settings');
