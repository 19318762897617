import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
// We are setting label sizes here as the checkbox does not use the correct typography...
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-size: 14px;
    line-height: 20px;
  }
`;
