import styled, { css } from 'styled-components';

export const PredictionsActionsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-top: ${({ $extraMaginTop }) => $extraMaginTop && '8px'};
  width: ${({ $size, $TwoButtonWidthException }) => {
    if ($TwoButtonWidthException) return css`268px`;
    if ($size === 'small') return css`100%`;
    return css`320px`;
  }};
`;

export const ButtonContainer = styled.div`
  ${({ $moreThanTwoButtons }) =>
    $moreThanTwoButtons &&
    css`
      width: 30%;
    `}
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 88px;
  min-height: 88px;
`;

export const TeamWrapper = styled.div`
  max-width: 88px;
  min-height: 88px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const LogoBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  ${({ $placeRight }) =>
    $placeRight
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
  opacity: 0.5;
`;

export const VersusIconWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ImageAndButtonContainer = styled.div`
  position: relative;
  width: 50%;
`;

export const ButtonCenterPositioner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const DropDownSelectContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`;

export const SelectConfirmContainer = styled.div`
  margin-left: 8px;
  max-width: 100px;
  border: 1px solid white;
  border-radius: 4px;
`;

export const ConfirmButtonStyle = styled.div`
  ${({ $isConfirm }) =>
    $isConfirm
      ? css`
          border: 1px solid white;
          border-radius: 4px;
        `
      : css`
          border: 1px solid transparent;
        `};
`;
