import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import isInIframe from 'tv-utils/isInIframe';
import useStreamFunctions from 'tv-hooks/useStreamFunctions';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameCompetitorsLineups } from 'tv-selectors/games/makeGetGameCompetitorsLineups';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useGetGameVodStartTimestamp } from 'tv-selectors/games/makeGetGameVodStartTimestamp';
import { setExternalJwt } from 'tv-actions/old/auth';

export const createEventHandlers = args => ({
  timeupdate: e => {
    const { target } = e;
    const date = target.getCurrentDateTime(args.vodStartTimestamp);
    return { pts: e.currentTime, dateTime: date.toISOString() };
  },
  play: () => {},
  playing: () => {},
  pause: () => {},
  buffering: e => e.buffering.buffering,
  ended: () => {},
});

export const events = Object.keys(createEventHandlers());

const sendMessage = (event, data) =>
  window.parent.postMessage(JSON.stringify({ event, data }), '*');

// @TODO Remove this after POC. This is just a temp solution until we have an API solution
const useGetStreamId = (matchId, selectedMatchGameIndex) => {
  const lineups = useGetGameCompetitorsLineups({ matchId, selectedMatchGameIndex });
  const globalStreams = useGetGameGlobalStreams({ matchId, selectedMatchGameIndex });
  return useCallback(
    id => {
      if (typeof id === 'string') return id;
      if (id === 11) return globalStreams.find(({ type }) => type === 'event')?.streamId;
      if (id === 12) return globalStreams.find(({ type }) => type === 'map')?.streamId;
      return lineups[id - 1]?.streamId;
    },
    [lineups, globalStreams],
  );
};

// @TODO Remove this after POC. This is just a temp solution until we have an API solution
const useGetStreamIndex = (matchId, selectedMatchGameIndex) => {
  const lineups = useGetGameCompetitorsLineups({ matchId, selectedMatchGameIndex });
  const globalStreams = useGetGameGlobalStreams({ matchId, selectedMatchGameIndex });
  return useCallback(
    id => {
      if (typeof id === 'number') return id;
      const findStream = ({ streamId }) => streamId === id;
      const globalStream = globalStreams.find(findStream);
      if (globalStream) {
        return globalStream.type === 'map' ? 12 : 11;
      }
      return lineups.findIndex(findStream) + 1;
    },
    [lineups, globalStreams],
  );
};

// Events that are not from the video player
const useSpecialEvents = (matchId, gameIndex) => {
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const getStreamIndex = useGetStreamIndex(matchId, gameIndex); // @TODO Remove this after the POC

  // Layout positions. [{ streamId, position }, ...]
  useEffect(() => {
    if (!isInIframe()) return;
    const videoPlayerSteams = playerStreams?.filter(stream => !stream.audioOnly) ?? [];
    const positionData = videoPlayerSteams.map(({ id }, index) => ({
      streamId: id,
      position: index + 1,
      streamIndex: getStreamIndex(id), // @TODO Remove this after the POC
    }));
    sendMessage('layoutupdate', positionData);
  }, [getStreamIndex, playerStreams]);
};

const useIframeMessenger = (playerRef, matchId, gameIndex, callbacks = {}) => {
  const streamFunctions = useStreamFunctions(matchId, gameIndex, callbacks);
  const dispatch = useDispatch();

  const getStreamId = useGetStreamId(matchId, gameIndex);
  const functions = useMemo(
    () => ({
      ...streamFunctions,
      // @TODO revert changes to not support a number for streamId later
      displayStream: ({ streamId, position }) => {
        const selectedStreamId = getStreamId(streamId);
        if (!selectedStreamId) return;
        streamFunctions.displayStream(selectedStreamId, position);
      },
      removeStream: streamId => {
        const selectedStreamId = getStreamId(streamId);
        if (!selectedStreamId) return;
        streamFunctions.removeStream(selectedStreamId);
      },
      refreshJwt: jwt => {
        if (!jwt) return;
        dispatch(setExternalJwt(jwt));
      },
    }),
    [streamFunctions, getStreamId, dispatch],
  );

  const vodStartTimestamp = useGetGameVodStartTimestamp({
    matchId,
    selectedMatchGameIndex: gameIndex,
  });

  useSpecialEvents(matchId, gameIndex);

  const eventHandlers = useMemo(() => {
    const args = { vodStartTimestamp };
    return createEventHandlers(args);
  }, [vodStartTimestamp]);

  const createMessenger = useCallback(
    (player, event) =>
      fromEvent(player, event)
        .pipe(
          map(eventHandlers[event]),
          map(data => sendMessage(event, data)),
        )
        .subscribe(),
    [eventHandlers],
  );

  useEffect(() => {
    const player = playerRef.current;
    if (!isInIframe() || !player) return () => {};

    const subs = events.map(event => createMessenger(player, event));

    return () => subs.forEach(sub => sub.unsubscribe());
  }, [createMessenger, playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    if (!isInIframe() || !player) return () => {};

    const messageSub = fromEvent(window, 'message')
      .pipe(
        map(e => {
          try {
            return JSON.parse(e.data);
          } catch (_err) {
            return e.data;
          }
        }),
      )
      .subscribe(({ event, data }) => {
        if (typeof functions[event] === 'function') functions[event](data);
        else if (typeof player[event] === 'function') player[event](data);
      });

    return () => messageSub.unsubscribe();
  }, [functions, playerRef]);
};

export default useIframeMessenger;
