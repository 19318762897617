import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useGetAuthSubscriptionByProductId } from 'tv-selectors/auth/makeGetAuthSubscriptionByProductId';
import PromoCodeForm from 'tv-modules/Payment/PromoCodeForm/PromoCodeForm';
import WarningMessage from 'znipe-elements/data-display/WarningMessage/WarningMessage';
import withSuffix from 'znipe-utils/date/withSuffix';
import getDayDifference from 'znipe-utils/date/getDayDifference';
import { device } from 'znipe-styles/breakpoints';

const Text = styled.p``;

const Padding = styled.div`
  margin-bottom: 20px;
  @media ${device.tablet} {
    margin-bottom: 30px;
  }
  @media ${device.desktop} {
    margin-bottom: 50px;
  }
`;

const PromoWrapper = styled.div`
  @media ${device.tablet} {
    width: 290px;
    margin: 0 auto;
  }
`;

const RedeemCoupon = ({ productId, onSuccess }) => {
  const [trialIsActive, setTrialIsActive] = useState(false);
  const { campaignCodes } = useGetAuthSubscriptionByProductId({ productId });
  const hasExistingPromo = !!campaignCodes;
  const trialEnd =
    (hasExistingPromo && campaignCodes[campaignCodes.length - 1]?.campaignCodeEnds) ?? '';
  const daysRemaining = getDayDifference(new Date(), trialEnd);
  const daysText = withSuffix(daysRemaining, 'day');

  useEffect(() => {
    setTrialIsActive(new Date(trialEnd) > new Date());
  }, [trialEnd]);

  return (
    <>
      {trialIsActive && (
        <Padding>
          <WarningMessage
            message={`You already have a promo code active which expires in ${`${daysText}`}. Adding a new one will override it!`}
          />
        </Padding>
      )}
      <Text data-testid="redeem-text">
        If you have received any promo codes, you can redeem them below. Only one code can be active
        at a time.
      </Text>
      <PromoWrapper>
        <PromoCodeForm productId={productId} onSuccess={onSuccess} edit />
      </PromoWrapper>
    </>
  );
};

RedeemCoupon.propTypes = {
  productId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default memo(RedeemCoupon);
