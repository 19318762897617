import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { calculateResponsiveState } from 'redux-responsive';
import Routes from 'tv-routes/config/setupRoutes';
import ReactReduxIntensiveContext from 'tv-contexts/ReactReduxIntensiveContext';
import GqlStoreContext from 'tv-contexts/GqlStoreContext';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'znipe-styles/global.styles';
import defaultTheme from 'znipe-themes';
import wrap from 'znipe-utils/web/wrap';
import { GqlClientContext } from 'znipe-gql/GqlClientContext';
import GglCacheClient from 'znipe-gql/GqlCacheClient';
import GqlClient from 'znipe-gql/GqlClient';
import { isIOS } from 'tv-utils/MobileDetect';
import jwtAuthHelper from 'tv-modules/Authentication/utils/jwtAuthHelper';
import { isRiotDomain, isLoginPage } from 'tv-utils/pageUtils';
import setupStore from 'tv-config/setupStore/setupStore';
import setupFirebase from 'tv-config/setupFirebase/setupFirebase';
import { setExternalJwt, setFetchingStatus } from 'tv-actions/old/auth';
import startUserSessionHeartBeat from 'tv-utils/startUserSessionHeartBeat';
import getUserSessionId from 'tv-utils/getUserSessionId';
import riotAuthHelper from 'tv-modules/Authentication/utils/riotAuthHelper';
import detectRobot from 'znipe-utils/web/detectRobot';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';
import registerServiceWorker from './utils/registerServiceWorker';
import setupFirebaseLoginHandler from './utils/setupFirebaseLoginHandler';
import loadPolyfills from './utils/loadPolyfills';
import setupSentry from './utils/setupSentry';
import getServerDataFromHTML from './utils/getServerDataFromHTML';
import { restoreStoreFromLS, storeStoreInLS } from './utils/localStorage';
import setupKeyBindings from './utils/setupKeyBindings';
import disconnectFromRoom from './utils/disconnectFromRoom';
import getDeviceType from './utils/getDeviceType';

document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

registerServiceWorker();
const userSessionId = getUserSessionId();
const heartBeat = startUserSessionHeartBeat(userSessionId);
const { App, ErrorBoundary } = setupSentry(Routes);
const deviceType = getDeviceType();

const setupUnloadHandlers = (authHelper, store) => {
  const handleOnUnload = () => {
    if (authHelper) authHelper.unsubscribe();
    storeStoreInLS(store);
    clearInterval(heartBeat);
    disconnectFromRoom(store);
  };

  if (isIOS()) {
    global.addEventListener('pagehide', () => {
      handleOnUnload();
    });
  } else {
    window.onunload = window.onbeforeunload = () => handleOnUnload(); // eslint-disable-line no-multi-assign
  }
};

const setupGql = () => {
  const gqlCacheExpireTimes = getServerDataFromHTML('__GQL_CACHE_EXPIRE_TIMES__');
  const gqlCacheClient = new GglCacheClient();
  const gqlClient = new GqlClient();

  gqlCacheClient.init({ expireTimes: gqlCacheExpireTimes });

  return {
    cache: gqlCacheClient,
    client: gqlClient,
  };
};

const onClientLoad = async () => {
  await loadPolyfills();
  const params = queryStringToObject(window.location.search);
  const isNative = 'isNative' in params;
  const initialState = getServerDataFromHTML('__INITIAL_STATE__');
  const gqlInitialState = getServerDataFromHTML('__GQL_INITIAL_STATE__');
  const stores = setupStore(
    { deviceType, referrer: document.referrer, userSessionId, isNative },
    window.navigator.userAgent,
    false,
    { initialState, gqlInitialState },
  );
  const { store, intensiveStore, gqlStore } = stores;
  setupKeyBindings(store);
  restoreStoreFromLS(store);

  const firebaseApp = await setupFirebase();
  const gqlClientProviderValue = setupGql();

  let authHelper = null;
  if (firebaseApp && !detectRobot(window.navigator.userAgent)) {
    setupFirebaseLoginHandler(store);

    if (isRiotDomain() && !isLoginPage()) {
      const auth = store.getState().auth ?? {};
      // Should not setup auth helper if signed in with a znipe account that is an admin
      if (auth.get('isLinkedToRiot') || !auth.get('isAdmin')) {
        store.dispatch(setFetchingStatus(true));
        const onFail = () => store.dispatch(setFetchingStatus(false));
        authHelper = riotAuthHelper(onFail);
      }
    } else if ('jwt' in params) {
      authHelper = jwtAuthHelper(params.jwt);
      store.dispatch(setExternalJwt(params.jwt));
    }
  }

  window.addEventListener('resize', () => store.dispatch(calculateResponsiveState(window)));
  setupUnloadHandlers(authHelper, store);

  await loadableReady(() => {
    hydrateRoot(
      document.getElementById('mount'),
      wrap(
        <Provider
          store={store}
          serverState={{
            ...initialState,
            deviceInfo: store.getState().deviceInfo,
            control: store.getState().control,
            auth: store.getState().auth,
          }} // A hack so we get the userSession in the initial state
        >
          <Provider store={intensiveStore} context={ReactReduxIntensiveContext}>
            <Provider store={gqlStore} context={GqlStoreContext} serverState={gqlInitialState}>
              <GqlClientContext.Provider value={gqlClientProviderValue}>
                <ThemeProvider theme={defaultTheme}>
                  <GlobalStyle />
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </ThemeProvider>
              </GqlClientContext.Provider>
            </Provider>
          </Provider>
        </Provider>,
        ErrorBoundary,
      ),
    );
  });

  store.dispatch(calculateResponsiveState(window));
};

onClientLoad();
