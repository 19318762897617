import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { LIST, GRID, SCROLLLIST } from 'tv-elements/layout/Section/Section.constants';
import Section from 'tv-elements/layout/Section/Section';
import ListWrapperElement from './ListWrapperElement';
import MatchResultItem from './MatchResultItem';
import PlayerResultItem from './PlayerResultItem';
import TeamResultItem from './TeamResultItem';
import VideoResultItem from './VideoResultItem';
import ChampionResultItem from './ChampionResultItem';
import EventResultItem from './EventResultItem';
import {
  VODS,
  EVENTS,
  EDITORIALS,
  CHAMPIONS,
  TEAMS,
  PLAYERS,
  HIGHLIGHTS,
  elementSizesPropType,
  categoryTypePropType,
} from '../Browse.constants';

const CategorySection = ({
  title = '',
  type,
  data = [],
  elementSizes,
  noTopMargin = false,
  children,
  loadMoreElement,
  layout,
}) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const resultItemComponentInfo = useMemo(
    () => ({
      [PLAYERS]: {
        component: PlayerResultItem,
        dataPresentation: 'item',
        dataId: 'playerId',
      },
      [TEAMS]: {
        component: TeamResultItem,
        dataPresentation: 'item',
        dataId: 'teamId',
      },
      [EDITORIALS]: {
        component: VideoResultItem,
        dataPresentation: isDesktopOrGreater ? 'item' : 'list',
        dataId: 'videoId',
      },
      [VODS]: {
        component: MatchResultItem,
        dataPresentation: 'list',
        dataId: 'matchId',
      },
      [CHAMPIONS]: {
        component: ChampionResultItem,
        dataPresentation: 'item',
        dataId: 'championId',
      },
      [HIGHLIGHTS]: {
        component: VideoResultItem,
        dataPresentation: isDesktopOrGreater ? 'item' : 'list',
        dataId: 'videoId',
      },
      [EVENTS]: {
        component: EventResultItem,
        dataPresentation: 'item',
        dataId: 'eventId',
      },
    }),
    [isDesktopOrGreater],
  );
  if (!children && data.length === 0) return null;
  const isVideoList = type === HIGHLIGHTS || type === EDITORIALS;
  const isMatchList = type === VODS;
  const shouldUseWrapper = isDesktopOrGreater && !isVideoList && !isMatchList;
  const sectionLayout = layout || (isDesktopOrGreater && isVideoList ? GRID : LIST);

  const ResultItem = resultItemComponentInfo[type].component;
  const resultItemDataType = resultItemComponentInfo[type].dataPresentation;
  const resultItemId = resultItemComponentInfo[type].dataId;
  return (
    <>
      <Section title={title} noTopMargin={noTopMargin} direction="vertical" type={sectionLayout}>
        <ListWrapperElement
          type={type}
          shouldUseWrapper={shouldUseWrapper}
          isDesktopOrGreater={isDesktopOrGreater}
        >
          {resultItemDataType === 'item' ? (
            data.map(itemId => {
              const customProps = { [resultItemId]: itemId };
              return (
                <ResultItem
                  key={itemId}
                  elementSizes={elementSizes}
                  isDesktopOrGreater={isDesktopOrGreater}
                  videoType={type === EDITORIALS ? 'editorial' : 'highlight'}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...customProps}
                />
              );
            })
          ) : (
            <ResultItem
              resultList={data}
              elementSizes={elementSizes}
              videoType={type === EDITORIALS ? 'editorial' : 'highlight'}
            />
          )}
          {children}
        </ListWrapperElement>
      </Section>
      {loadMoreElement}
    </>
  );
};

CategorySection.propTypes = {
  title: PropTypes.string,
  type: categoryTypePropType.isRequired,
  data: PropTypes.arrayOf(PropTypes.node),
  elementSizes: elementSizesPropType.isRequired,
  noTopMargin: PropTypes.bool,
  loadMoreElement: PropTypes.element,
  children: PropTypes.node,
  layout: PropTypes.oneOf([GRID, LIST, SCROLLLIST]),
};

export default memo(CategorySection);
