import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import { fadeIn } from 'znipe-styles/animations';
import prefers from 'znipe-styles/prefers';
import colors from 'znipe-styles/colors';

const pulseAnimation = css`
  @keyframes pulse {
    0%,
    100% {
      box-shadow: 0px 0px 2px 0px ${({ theme }) => theme.onboardingStroke};
    }
    50% {
      box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.onboardingStroke};
    }
  }
`;

export const LayoutContainer = styled.div`
  position: relative;
  min-height: 100vh;

  /* onboarding elements */
  [data-banner-target-active='true'] {
    border: 2px solid ${({ theme }) => theme.onboardingStroke};
    border-radius: 3px;
    margin: 2px;
    animation: pulse 3s ease infinite;
    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  ${({ $disableScroll }) =>
    $disableScroll &&
    css`
      overflow-y: hidden;
    `}
  overflow-x: hidden;
  @media ${device.desktop} {
    overflow-x: unset;
  }
  ${pulseAnimation}
`;

const contentWidths = css`
  max-width: 560px;
  @media ${device.tablet} {
    max-width: 656px;
    margin: auto;
  }
  @media ${device.desktop} {
    max-width: 1040px;
  }
  @media ${device.desktopLarge} {
    max-width: 1232px;
  }
  @media ${device.desktopExtraLarge} {
    max-width: 1648px;
  }
`;

const contentWidthPadding = css`
  @media ${device.tablet} {
    padding-bottom: 80px;
  }
  @media ${device.desktop} {
    padding-bottom: 96px;
  }
  @media ${device.desktopLarge} {
    padding-bottom: 128px;
  }
`;

export const PageContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 56px;
  @media ${device.tablet} {
    margin-bottom: 75px;
  }
  @media ${device.desktop} {
    margin-bottom: 64px;
  }
`;

export const TabsetWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 72px;
  width: 100%;
  position: relative;
  @media ${device.desktop} {
    min-height: 88px;
  }
  background: ${({ hasBackground, theme }) =>
    hasBackground ? theme.navMenuBackgroundColor : 'transparent'};
  ${({ hasBackground }) => hasBackground && contentWidths}
  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -25px;
    width: 100%;
    height: 25px;
    background: linear-gradient(
      180deg,
      ${({ hasBackground, theme }) =>
        hasBackground ? theme.navMenuBackgroundColor : 'transparent'},
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  ${contentWidths}
  @media ${device.desktop} {
    margin-bottom: 24px;
  }
`;

export const FeaturedWrapper = styled.div`
  width: 100%;
  margin: auto;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ${TabsetWrapper} {
    margin-bottom: 0;
  }

  @media ${device.desktop} {
    max-width: 1200px;
  }
  @media ${device.desktopLarge} {
    max-width: 1600px;
    > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  @media ${device.desktopExtraLarge} {
    max-width: 1800px;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 56px;
  position: relative;
  ${contentWidths}
  ${({ $standings }) =>
    $standings
      ? css`
          padding-bottom: 0;
        `
      : contentWidthPadding}
`;

export const FooterWrapper = styled.div`
  display: block;
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  box-shadow: 0px -4px 4px 0px ${colors.black} 25%;
  width: 100%;
  position: fixed;
  z-index: 5;
  bottom: 0;
  @media ${device.desktop} {
    display: none;
  }
`;

export const BodyAnimationWrapper = styled.div`
  @media ${prefers.motion} {
    opacity: 0;
    animation-name: ${fadeIn(0, 1)};
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
  }
`;

export const VideoElement = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`;

export const HighlightsSectionWrapper = styled.div`
  margin-top: 16px;
`;
