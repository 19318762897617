import { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavArrow from './components/NavArrow';
import NavDot from './components/NavDot';
import { Container, NavigationWrapper, Navigation, StyledSlider } from './Carousel.styles';

const defaultSettings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: false,
  swipe: true,
  useCSS: false,
  useTransform: false,
  lazyLoad: false,
  speed: 75,
};

const Carousel = ({
  children,
  hideNavigation = false,
  disableSwipe = false,
  width,
  height,
  onChange,
  initialSlide = 0,
  activeSlide = 0,
  extraTopPush = false,
}) => {
  const settings = {
    ...defaultSettings,
    swipe: !disableSwipe,
    initialSlide,
  };
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const slideRef = useRef(null);
  const slideSize = children.length;
  const isMountedRef = useRef(null);

  const handleClickPrev = useCallback(() => {
    if (currentSlide > 0) {
      setCurrentSlide(prevSlideState => prevSlideState - 1);
      if (onChange) onChange(currentSlide - 1);
    }
  }, [currentSlide, onChange]);

  const handleClickNext = useCallback(() => {
    if (currentSlide < slideSize - 1) {
      setCurrentSlide(prevSlideState => prevSlideState + 1);
      if (onChange) onChange(currentSlide + 1);
    }
  }, [currentSlide, onChange, slideSize]);

  const handleSlide = useCallback(
    slideDir => {
      setCurrentSlide(prevSlideState => {
        if (slideDir === 'left' && currentSlide < slideSize - 1) {
          return prevSlideState + 1;
        }
        if (slideDir === 'right' && currentSlide > 0) {
          return prevSlideState - 1;
        }
        return prevSlideState;
      });
    },
    [currentSlide, slideSize],
  );

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      slideRef.current.slickGoTo(currentSlide);
      if (onChange) {
        onChange(currentSlide);
      }
    }
  }, [currentSlide, onChange]);

  useEffect(() => {
    if (isMountedRef.current && !initialSlide) {
      setCurrentSlide(activeSlide);
    }
  }, [activeSlide, initialSlide]);

  return (
    <Container width={width} height={height} data-testid="container">
      <StyledSlider {...settings} onSwipe={handleSlide} ref={slideRef} extraTopPush={extraTopPush}>
        {children}
      </StyledSlider>
      {!hideNavigation && (
        <NavigationWrapper data-testid="navigation-wrapper">
          <Navigation>
            <NavArrow type="prev" onClick={handleClickPrev} dimmed={currentSlide === 0} />

            {children.map((child, iter) => (
              <NavDot itemKey={`dot_${child.key}`} isActive={iter === currentSlide} />
            ))}

            <NavArrow
              type="next"
              onClick={handleClickNext}
              dimmed={currentSlide === slideSize - 1}
            />
          </Navigation>
        </NavigationWrapper>
      )}
    </Container>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(props => {
    let error;
    props.forEach(child => {
      if (!child.key) {
        error = new Error('Invalid PropType children: no key provided.');
      }
    });
    return error;
  }).isRequired,
  hideNavigation: PropTypes.bool,
  disableSwipe: PropTypes.bool,
  extraTopPush: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  onChange: PropTypes.func,
  initialSlide: PropTypes.number,
  activeSlide: PropTypes.number,
};

export default Carousel;
