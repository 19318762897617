import { useRef, useMemo } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import useOnScreen from 'znipe-hooks/useOnScreen';
import StyledLoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';

const HiddenSpan = styled.span`
  visibility: hidden;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  height: 60px;
`;

const useOnPageScrollEnd = (callback, isLoading, rootMargin = '50px') => {
  const itemRef = useRef(null);
  const handleChange = useMemo(
    () =>
      debounce(isOnScreen => {
        if (isOnScreen) {
          callback();
        }
      }, 1000),
    [callback],
  );
  useOnScreen(itemRef, rootMargin, handleChange);

  return (
    <>
      <HiddenSpan data-testid="infinite-scroll-wrapper" ref={itemRef} id="lastItem" />
      {isLoading && (
        <SpinnerWrapper>
          <StyledLoadingSpinner position="absolute" fillColor="white" />
        </SpinnerWrapper>
      )}
    </>
  );
};

export default useOnPageScrollEnd;
