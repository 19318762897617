import styled, { css, keyframes } from 'styled-components';
import colors from 'znipe-styles/colors';

export const fadeInOver = (offset, margin) => keyframes`
  from {
    opacity: 0;
    transform: translateY(${offset}px);
    
  }

  to {
    opacity: 1;
    transform: translateY(${offset - margin}px);
    
  }
`;

export const fadeOutOver = (offset, margin) => keyframes`
  from {
    opacity: 1;
    transform: translateY(${offset - margin}px);
    
  }

  to {
    opacity: 0;
    transform: translateY(${offset}px);
    
  }
`;

export const fadeInUnder = (offset, margin) => keyframes`
  from {
    opacity: 0;
    transform: translateY(${offset}px);
    
  }

  to {
    opacity: 1;
    transform: translateY(${offset + margin}px);
  }
`;

export const fadeOutUnder = (offset, margin) => keyframes`
  from {
    opacity: 1;
    transform: translateY(${offset + margin}px);
    
  }

  to {
    opacity: 0;

    transform: translateY(${offset}px);
    
  }
`;

export const fadeInLeft = (offset, margin) => keyframes`
  from {
    opacity: 0;
    left: ${offset}px;
    
  }

  to {
    opacity: 1;
    left: ${offset - margin}px;
    
  }
`;

export const fadeOutLeft = (offset, margin) => keyframes`
  from {
    opacity: 1;
    left: ${offset - margin}px;
    
  }

  to {
    opacity: 0;
    left: ${offset}px;
    
  }
`;
export const fadeInRight = (offset, margin) => keyframes`
  from {
    opacity: 0;
    left: ${offset}px;
    
  }

  to {
    opacity: 1;
    left: ${offset + margin}px;
    
  }
`;

export const fadeOutRight = (offset, margin) => keyframes`
  from {
    opacity: 1;
    left:${offset + margin}px;
    
  }

  to {
    opacity: 0;
    left: ${offset}px;
    
  }
`;

export const StreamContainer = styled.div`
  position: relative;
  border: solid 1px ${colors.white};
  width: ${({ size }) =>
    size
      ? css`
          ${size}px;
        `
      : '100%'};
  > *:first-child {
    z-index: 0;
  }
`;

export const TargetWrapper = styled.div`
  outline: none;
`;

export const HoverContainer = styled.div`
  position: relative;
`;

export const StreamPreviewPlaceholder = styled.div`
  width: ${({ $width }) => $width || 470}px;
  padding-bottom: 56.25%;
`;

export const PlaceholderVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const FloatingContainer = styled.div`
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  cursor: ${({ $noPremiumAccessAndLocked }) => ($noPremiumAccessAndLocked ? 'auto' : 'pointer')};
  display: inline-table;
  position: absolute;
  top: -8px;
  z-index: 99999999;
  opacity: 0;

  ${({ offsetPos, margin, placement, animate, visible }) => {
    if (!offsetPos || !animate) return undefined;
    let animationFunction = null;
    const isHorizontalAnimation = placement === 'left' || placement === 'right';

    switch (placement) {
      case 'over':
        animationFunction = visible ? fadeInOver : fadeOutOver;
        break;
      case 'under':
        animationFunction = visible ? fadeInUnder : fadeOutUnder;
        break;
      case 'left':
        animationFunction = visible ? fadeInLeft : fadeOutLeft;
        break;
      case 'right':
        animationFunction = visible ? fadeInRight : fadeOutRight;
        break;
      default:
    }

    if (!animationFunction) return undefined;

    const offset = isHorizontalAnimation ? offsetPos.horizontal : offsetPos.vertical;
    const left = isHorizontalAnimation ? undefined : `${offsetPos.horizontal}px`;
    const top = isHorizontalAnimation ? `${offsetPos.vertical}px` : undefined;

    return css`
      left: ${left};
      top: ${top};

      animation: ${() => animationFunction(offset, margin)} 500ms forwards;
    `;
  }}
`;
