import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { ROUND_KILLS_SUMMARY_EVENT } from 'tv-reducers/intensive';
import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';

export const SUBSCRIBE_ROUND_KILLS_SUMMARY = 'SUBSCRIBE_ROUND_KILLS_SUMMARY';
export const UNSUBSCRIBE_ROUND_KILLS_SUMMARY = 'UNSUBSCRIBE_ROUND_KILLS_SUMMARY';

export const useRoundKillsSummary = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_ROUND_KILLS_SUMMARY, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_ROUND_KILLS_SUMMARY, gatId });
  }, [dispatch, gatId]);
};

export const groupKillsSummariesByRound = summaries =>
  summaries?.reduce((acc, summary) => {
    const kill = {
      actor: summary.actor,
      subject: summary.subject,
      weapon: summary.weapon,
      gameTime: summary.gameTime,
      ...(summary.modifiers ?? {}),
    };

    const id = summary.round;
    const accVal = acc[id] || { kills: [], deaths: [] };

    return {
      ...acc,
      [id]: {
        kills: [...accVal.kills, kill],
        deaths: [...accVal.deaths, { ...kill.subject, gameTime: summary.gameTime }],
        round: id,
        gameId: summary.gameId,
        gameTime: summary.gameTime,
      },
    };
  }, {});

export const getPlayers = payload =>
  payload?.reduce((acc, { actor, subject }) => {
    const actorObject = createPlayerObject(actor);
    const subjectObject = createPlayerObject(subject);
    return {
      ...acc,
      [actorObject.id]: actorObject,
      [subjectObject.id]: subjectObject,
    };
  }, {});

const roundKillsSummary = factorySubscribeEpic(
  [SUBSCRIBE_ROUND_KILLS_SUMMARY, UNSUBSCRIBE_ROUND_KILLS_SUMMARY],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('type').equalTo('KillEvent'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const mappedPayload = groupKillsSummariesByRound(payload);
      const players = getPlayers(payload);
      return {
        type: ROUND_KILLS_SUMMARY_EVENT,
        gatId,
        payload: { id: gatId, rounds: Object.values(mappedPayload), players },
      };
    },
);

export default roundKillsSummary;
