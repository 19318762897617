import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ProfileLabel from 'tv-elements/data-display/ProfileLabel/ProfileLabel';

const ProfileList = ({ items, size }) => {
  const filter = items.filter(({ text }) => Boolean(text));

  const generateLabels = useCallback(
    item => {
      const { title, icon, text } = item;
      return <ProfileLabel key={title} icon={icon} text={text} size={size} title={title} />;
    },
    [size],
  );

  return filter.map(generateLabels);
};

ProfileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  size: PropTypes.string.isRequired,
};

export default memo(ProfileList);
