import PropTypes from 'prop-types';
import ShimmerDiv from './Shimmer.styles';
import { TEXT, VIDEO, DEFAULT } from './Shimmer.constants';

const Shimmer = ({
  overlay = true,
  duration = 5,
  delay = 0,
  rotate = true,
  reverseShimmer = false,
  type = DEFAULT,
}) => (
  <ShimmerDiv
    data-testid="skeleton-shimmer"
    $overlay={overlay}
    $duration={duration}
    $delay={delay}
    $rotate={rotate}
    $reverseShimmer={reverseShimmer}
    $type={type}
  />
);

Shimmer.propTypes = {
  overlay: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
  rotate: PropTypes.bool,
  reverseShimmer: PropTypes.bool,
  type: PropTypes.oneOf([TEXT, VIDEO, DEFAULT]),
};

export default Shimmer;
