import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetVolume = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.volume ?? 1);
};

export const useVolume = () => useMemoizedSelector(makeGetVolume);

export default makeGetVolume;
