import styled, { css, DefaultTheme } from 'styled-components';
import colors from 'znipe-styles/colors';
import prefers from 'znipe-styles/prefers';
import type { Size } from './ToggleButton';

const getButtonContainerSize = ({ $size }: { $size: Size }) => {
  switch ($size) {
    case 'large':
      return css`
        width: 64px;
        height: 32px;
      `;
    case 'medium':
      return css`
        width: 48px;
        height: 24px;
      `;
    default:
      return css`
        width: 32px;
        height: 16px;
      `;
  }
};

const getDotSize = ({ $size }: { $size: Size }) => {
  switch ($size) {
    case 'large':
      return '24px';
    case 'medium':
      return '15px';
    default:
      return '10px';
  }
};

type ContainerProps = {
  $row: boolean;
};

type DotProps = {
  $size: Size;
  $color: string;
  $isActive: boolean;
};

type ButtonContainerProps = {
  $size: Size;
  $isActive: boolean;
  $color: 'blue' | 'default';
  $disabled: boolean;
  theme: DefaultTheme & {
    toggleButton: {
      blue: { background: string; activeBackground: string };
      default: { background: string; activeBackground: string };
    };
  };
};

export const Container = styled.div<ContainerProps>`
  display: ${({ $row }) => ($row ? 'flex' : 'inline-flex')};
  align-items: center;
  gap: 8px;
  justify-content: ${({ $row }) => ($row ? 'space-between' : 'flex-start')};
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const Dot = styled.div<DotProps>`
  width: ${getDotSize};
  height: ${getDotSize};
  background: ${({ theme, $color, $isActive }) => {
    if ($color === 'blue' && $isActive) return colors.white;
    if ($color === 'blue') return theme.highlight;
    return theme.containerStroke;
  }};
  border-radius: 100%;
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  ${getButtonContainerSize};
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ $isActive, theme, $color }) => {
    const varName = $isActive ? 'activeBackground' : 'background';
    const themeColor = theme.toggleButton[$color]?.[varName];

    if (themeColor) return themeColor;

    if ($color === 'blue' && !$isActive) return theme.containerStroke;
    if ($color === 'blue') return theme.teamAGradient;

    return $isActive ? theme.UIAccent : colors.grey75;
  }};
  border: 1px solid ${({ theme }) => theme.containerStroke};
  border-radius: 25px;
  padding: 3px;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
  @media ${prefers.motion} {
    transition: 200ms ease-out;
  }
  ${Dot} {
    margin-left: ${props => (props.$isActive ? ` calc(100% - ${getDotSize(props)})` : 0)};
    @media ${prefers.motion} {
      transition: 200ms ease-out;
    }
  }
`;
