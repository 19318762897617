import colors from 'znipe-styles/colors';

export default {
  proview: {
    height: '50px',
    textColor: colors.riotBackgroundRule,
    borderRadius: '4px',
    marginAsterisk: '5px',
    darkBackground: '#15161a',
    lightBackground: '#F0F2F8',
    iconSize: 12,
    placeholder: {
      color: colors.grey3,
      fontStyle: 'normal',
    },
    maxLengthColor: {
      default: colors.slateGrey,
      almostMax: colors.youngYellow,
      max: colors.rogueRed,
    },
    bgColor: {
      default: colors.grey1,
      focus: colors.white,
      error: colors.white,
      selectHover: colors.white,
      selected: colors.slateGrey,
    },
    borderColor: {
      default: 'transparent',
      focus: colors.riotBackgroundRule,
      error: colors.riotTeamChaosPrimary,
    },
    errorMessageColor: colors.riotTeamChaosPrimary,
    errorBorderWidth: '1px',
    verticalPadding: '10px',
    horizontalPadding: '20px',
    optionRowHeight: '28px',
  },
  znipebase: {
    height: '40px',
    textColor: colors.paperGrey,
    borderRadius: '2px',
    marginAsterisk: '0px',
    darkBackground: '#15161a',
    lightBackground: '#F0F2F8',
    iconSize: 16,
    placeholder: {
      color: colors.slateGrey,
      fontStyle: 'italic',
    },
    maxLengthColor: {
      default: colors.slateGrey,
      almostMax: colors.youngYellow,
      max: colors.rogueRed,
    },
    bgColor: {
      default: colors.gallantGrey,
      focus: colors.gallantGrey,
      error: colors.gallantGrey,
      selectHover: colors.gorgeousGrey,
      selected: colors.slateGrey,
    },
    errorMessageColor: colors.rogueRed,
    errorBorderWidth: '2px',
    verticalPadding: '8px',
    horizontalPadding: '16px',
    optionRowHeight: '24px',
  },
  default: {
    height: '50px',
    borderRadius: '4px',
    marginAsterisk: '5px',
    iconSize: 12,
    placeholder: {
      color: colors.grey3,
      fontStyle: 'normal',
    },
    maxLengthColor: {
      default: colors.slateGrey,
      almostMax: colors.youngYellow,
      max: colors.rogueRed,
    },
    bgColor: {
      default: 'rgba(255, 255, 255, 0.05)',
      error: colors.white,
      selectHover: colors.white,
      selected: colors.slateGrey,
    },
    errorMessageColor: colors.riotTeamChaosPrimary,
    errorBorderWidth: '1px',
    verticalPadding: '10px',
    horizontalPadding: '20px',
    optionRowHeight: '28px',
  },
};
