import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedGame from 'tv-selectors/games/makeGetSelectedGame';

const makeGetGameStatus = () => {
  const getSelectedGame = makeGetSelectedGame();
  return createSelector([getSelectedGame], game => game.status?.toLowerCase() ?? '');
};

export const useGetGameStatus = props => useMemoizedGqlSelector(makeGetGameStatus, props);

export default makeGetGameStatus;
