import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetPlayerFullName } from 'tv-selectors/players/makeGetPlayerFullName';
import { useGetPlayerCountry } from 'tv-selectors/players/makeGetPlayerCountry';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetTeamCountry } from 'tv-selectors/team/makeGetTeamCountry';
import { useGetTeamFoundedDate } from 'tv-selectors/team/makeGetTeamFoundedDate';
import { useGetTeamMates } from 'tv-selectors/team/makeGetTeamMates';
import { useGetTeamHistory } from 'tv-selectors/team/makeGetTeamHistory';
import { PLAYER, TEAM, EVENT, CHAMPION } from 'tv-routes/Topic/Topics.constants';
import { SMALL, MEDIUM, LARGE, AUTO } from 'tv-modules/TopicHeader/TopicHeader.constants';
import { useGetTournamentCountry } from 'tv-selectors/tournaments/makeGetTournamentCountry';
import { useGetTournamentDates } from 'tv-selectors/tournaments/makeGetTournamentDates';
import Typography from 'znipe-elements/general/Typography/Typography';
import AbilityDetailsList from 'tv-modules/Abilities/AbilityDetailsList/AbilityDetailsList';
import { useGetChampionClass } from 'tv-selectors/champions/makeGetChampionClass';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import Card from 'znipe-elements/data-display/Card/Card';
import SimpleCard from 'znipe-elements/data-display/Card/CardSimple';
import ProfileList from './components/ProfileList';
import TeamMateCard from './components/TeamMateCard';
import EventGroups from './components/EventGroups';
import ChampionInfo from './components/ChampionInfo';
import PlayerInfo from './components/PlayerInfo';
import TeamInfo from './components/TeamInfo';
import {
  playerProfileItems,
  teamProfileItems,
  tournamentProfileItems,
  championProfileItems,
} from './utils/createProfileItems';
import { smallSizes, mediumSizes, largeSizes } from './TopicAbout.constants';
import {
  Grid,
  SectionTitle,
  Profile,
  ProfileGrid,
  Content,
  ChampionAbilities,
  ChampionAbilitiesWrapper,
} from './TopicAbout.styles';

const TopicAbout = ({ size = LARGE, topicId, type = PLAYER, tournamentId = '' }) => {
  const playerName = useGetPlayerFullName({ playerId: topicId });
  const playerCountry = useGetPlayerCountry({ playerId: topicId });
  const teamId = useGetPlayerTeamId({ playerId: topicId }) || topicId;
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  const teamCountry = useGetTeamCountry({ teamId });
  const teamFounded = useGetTeamFoundedDate({ teamId });
  const teamMates = useGetTeamMates({ teamId });
  const teamHistory = useGetTeamHistory({ teamId });
  const tournamentCountry = useGetTournamentCountry({ tournamentId: topicId });
  const tournamentDates = useGetTournamentDates({ tournamentId: topicId });
  const championClass = useGetChampionClass({ championId: topicId });
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();

  const elementSizes = useMemo(() => {
    if (size === LARGE) {
      return largeSizes;
    }
    if (size === MEDIUM) {
      return mediumSizes;
    }
    return smallSizes;
  }, [size]);

  const profileItems = useMemo(() => {
    if (type === PLAYER) {
      return playerProfileItems(playerName, playerCountry);
    }
    if (type === TEAM) {
      return teamProfileItems('', teamCountry, teamFounded);
    }
    if (type === EVENT) {
      return tournamentProfileItems(tournamentDates, tournamentCountry);
    }
    if (type === CHAMPION) {
      return championProfileItems(championClass);
    }
    return [];
  }, [
    playerCountry,
    playerName,
    teamCountry,
    teamFounded,
    tournamentCountry,
    tournamentDates,
    championClass,
    type,
  ]);

  const teamMateCardSize = useMemo(() => {
    const sizeBreakpoint = type === PLAYER ? isTabletOrGreater : isDesktopOrGreater;
    return sizeBreakpoint ? AUTO : elementSizes.card;
  }, [type, isTabletOrGreater, isDesktopOrGreater, elementSizes.card]);

  const teamMateCards = useMemo(() => {
    if (!teamMates?.length) return [];
    return teamMates.map(player => (
      <TeamMateCard
        key={player.id}
        id={player.id}
        image={player.image}
        nickname={player.nickname}
        size={teamMateCardSize}
        teamLogo={teamLogo}
        isSelf={player.id === topicId}
      />
    ));
  }, [teamMates, teamMateCardSize, teamLogo, topicId]);

  const ChampionsComponent = useMemo(
    () => (
      <ChampionAbilities>
        <Typography type="title-m">abilities</Typography>
        <Card>
          <ChampionAbilitiesWrapper>
            <AbilityDetailsList topicId={topicId} />
          </ChampionAbilitiesWrapper>
        </Card>
      </ChampionAbilities>
    ),
    [topicId],
  );

  const TeamHistory = useMemo(() => {
    if (!teamHistory) {
      return null;
    }
    return (
      <SimpleCard transparent size="large" title="team history">
        {teamHistory}
      </SimpleCard>
    );
  }, [teamHistory]);

  return (
    <Grid $type={type} $isDesktop={isDesktopOrGreater}>
      <Content $type={type}>
        {type === EVENT && <EventGroups tournamentId={tournamentId} size={size} />}
        {type === PLAYER && (
          <PlayerInfo topicId={topicId} size={size} teamMateCards={teamMateCards} />
        )}
        {type === TEAM && <TeamInfo size={size} teamMateCards={teamMateCards} />}
        {type === CHAMPION && <ChampionInfo topicId={topicId} elementSizes={elementSizes} />}
      </Content>
      <Profile $type={type}>
        <SectionTitle dataTestId="profile-title" type={elementSizes.title}>
          {type === PLAYER ? 'profile' : 'info'}
        </SectionTitle>
        <ProfileGrid>
          <ProfileList items={profileItems} size={elementSizes.component} />
        </ProfileGrid>
        {type === CHAMPION && isDesktopOrGreater && ChampionsComponent}
        {type === TEAM && isDesktopOrGreater && TeamHistory}
      </Profile>
      {type === TEAM && !isDesktopOrGreater && TeamHistory}
    </Grid>
  );
};

TopicAbout.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  topicId: PropTypes.string.isRequired,
  tournamentId: PropTypes.string,
  type: PropTypes.oneOf([PLAYER, TEAM, EVENT, CHAMPION]),
};

export default memo(TopicAbout);
