import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { Container, StickyElement } from './StickyComponent.styles';

const StickyComponent = ({
  children = null,
  componentId = 'sticky-component',
  enableStickyScroll = false,
  onScrollChange,
  onStickyChange,
  height = 'auto',
  scrollTarget,
  independent = false,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [stickyElementHeight, setStickyElementHeight] = useState(0);
  const [hasMounted, setMounted] = useState(false);
  const [targetTopPosition, setTargetTopPosition] = useState(0);
  const { themeName } = useThemeContext();

  const setStickyState = useCallback(
    stickyState => {
      setIsSticky(stickyState);
      if (onStickyChange) onStickyChange(stickyState);
      if ((themeName || '').indexOf('lg') !== -1) {
        window.parent.postMessage(
          { type: 'BNB_EVENT', param: { visibility: stickyState ? 0 : 1 } },
          '*',
        );
      }
    },
    [onStickyChange, themeName],
  );

  const onScroll = useMemo(
    () =>
      throttle(scrollEvent => {
        const element = document.getElementById(componentId);
        if (!element) return;
        const rect = element.getBoundingClientRect();
        if (rect.top < targetTopPosition) {
          if (isSticky) return;
          setStickyState(true);
        } else {
          setStickyState(false);
        }
        if (onScrollChange) onScrollChange(scrollEvent);
      }, 25),
    [componentId, isSticky, onScrollChange, setStickyState, targetTopPosition],
  );

  const calculateTargetTopPosition = useCallback(() => {
    const allStickyElementAvailableInDOM = document.getElementsByClassName(
      'sticky-component-element',
    );
    const arrayOfStickyElements = [].slice.call(allStickyElementAvailableInDOM);
    arrayOfStickyElements.sort((a, b) => a.offsetTop - b.offsetTop);
    const targetElementIndex = arrayOfStickyElements.findIndex(
      el => el.dataset.elementid === `${componentId}-child-element`,
    );
    const calculatedTopPosition =
      !independent && targetElementIndex > 0
        ? arrayOfStickyElements[targetElementIndex - 1].offsetHeight
        : 0;
    setTargetTopPosition(calculatedTopPosition);
  }, [componentId, independent]);

  useEffect(() => {
    if (!global.document) return undefined;
    const target = scrollTarget || global;
    if (!hasMounted) {
      setMounted(true);
      if (target.scrollTo) target.scrollTo(0, 0);
    }
    const element = document.getElementById(componentId);
    if (!element) return undefined;
    const rect = element.getBoundingClientRect() || {};
    setStickyElementHeight(rect.height);
    target.addEventListener('scroll', onScroll);
    calculateTargetTopPosition();
    return () => target.removeEventListener('scroll', onScroll);
  }, [onScroll, hasMounted, componentId, calculateTargetTopPosition, scrollTarget]);

  const elementIsSticky = isSticky && enableStickyScroll;

  return (
    <Container
      id={componentId}
      $paddingBottom={elementIsSticky ? `${stickyElementHeight}px` : 0}
      $height={height}
    >
      <StickyElement
        data-elementid={`${componentId}-child-element`}
        className="sticky-component-element"
        data-testid="sticky-component-children"
        $topPosition={`${targetTopPosition}px`}
        $isSticky={elementIsSticky}
      >
        {children}
      </StickyElement>
    </Container>
  );
};

StickyComponent.propTypes = {
  componentId: PropTypes.string,
  children: PropTypes.node,
  enableStickyScroll: PropTypes.bool,
  onScrollChange: PropTypes.func,
  onStickyChange: PropTypes.func,
  height: PropTypes.string,
  scrollTarget: PropTypes.shape({}),
  independent: PropTypes.bool,
};

export default StickyComponent;
