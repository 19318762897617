import { memo, useMemo, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useSpring, animated } from '@react-spring/web';
import RepeatingTextBackground from 'tv-elements/data-display/RepeatingTextBackground/RepeatingTextBackground';
import { useGetSelectedPlayerNickname } from 'tv-selectors/players/makeGetSelectedPlayerNickname';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetTeamName } from 'tv-selectors/team/makeGetTeamName';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetTeamCountry } from 'tv-selectors/team/makeGetTeamCountry';
import { useNavigate, useParams } from 'react-router-dom';
import { PLAYER, TEAM, EVENT, CHAMPION, RIOTCHAMPION } from 'tv-routes/Topic/Topics.constants';
import { useGetNodeId } from 'tv-selectors/contentSubscriptions/makeGetNodeId';
import Typography from 'znipe-elements/general/Typography/Typography';
import { useGetTournamentName } from 'tv-selectors/tournaments/makeGetTournamentName';
import { useGetTournamentCountry } from 'tv-selectors/tournaments/makeGetTournamentCountry';
import { useGetTournamentLogo } from 'tv-selectors/tournaments/makeGetTournamentLogo';
import { useGetTournamentHumanReadableId } from 'tv-selectors/tournaments/makeGetTournamentHumanReadableId';
import { useGetChampionName } from 'tv-selectors/champions/makeGetChampionName';
import { useGetChampionTitle } from 'tv-selectors/champions/makeGetChampionTitle';
import { useGetChampionClass } from 'tv-selectors/champions/makeGetChampionClass';
import { useGetChampionImage } from 'tv-selectors/champions/makeGetChampionImage';
import { useGetChampionChampId } from 'tv-selectors/champions/makeGetChampionChampId';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import useColorThief from 'znipe-hooks/useColorThief';
import { useGetEventId } from 'tv-selectors/events/makeGetEventId';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import HelmetWrapper, { getTitlePackageName } from 'tv-elements/general/Helmet/Helmet';
import usePackageName from 'tv-hooks/usePackageName';
import debounce from 'lodash.debounce';
import LoginCTAModal from 'tv-modules/Authentication/LoginCTAModal/LoginCTAModal';
import TopicInfo from './components/TopicInfo/TopicInfo';
import TopicHeaderLoading from './TopicHeader.loading';
import {
  SIZES,
  SMALL,
  MEDIUM,
  LARGE,
  FOLLOW,
  UNFOLLOW,
  FOLLOWING,
  HELMET_DESCRIPTIONS,
  LOGIN_CTA,
} from './TopicHeader.constants';
import {
  Background,
  RightSection,
  Content,
  LeftSection,
  IconWrapper,
  ChampionImage,
  ChampionImageWrapper,
} from './TopicHeader.styles';

const AnimatedBackground = animated(Background);

const TopicHeader = ({
  size = SMALL,
  showMini = false,
  onSubscribeClick,
  onUnsubscribeClick,
  topicId,
  type = PLAYER,
}) => {
  const waitingForSub = useRef(false);
  const navigate = useNavigate();
  const prefersReducedMotion = usePrefersReducedMotion();
  const isAuthenticated = useIsAuthenticated();

  const { packageSlug } = useParams();
  const packageName = usePackageName();

  const [isHovering, setIsHovering] = useState(false);

  const playerNickname = useGetSelectedPlayerNickname({ playerId: topicId });
  const playerImage = useGetPlayerImage({ playerId: topicId });
  const teamId = useGetPlayerTeamId({ playerId: topicId }) || topicId;
  const teamName = useGetTeamName({ teamId });
  const teamCountry = useGetTeamCountry({ teamId: topicId });
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  const tournamentName = useGetTournamentName({ tournamentId: topicId });
  const tournamentCountry = useGetTournamentCountry({ tournamentId: topicId });
  const tournamentLogo = useGetTournamentLogo({ tournamentId: topicId });
  const tournamentHumanReadableId = useGetTournamentHumanReadableId({ tournamentId: topicId });
  const championName = useGetChampionName({ championId: topicId });
  const championTitle = useGetChampionTitle({ championId: topicId });
  const championClass = useGetChampionClass({ championId: topicId });
  const championImage = useGetChampionImage({ championId: topicId });
  const championId = useGetChampionChampId({ championId: topicId });
  const roleIcon = useMemo(() => `${championClass.toLowerCase()}Class`, [championClass]);
  const nodeType = useMemo(() => (type === CHAMPION ? RIOTCHAMPION : type), [type]);
  const selectedEventId = useGetEventId({ eventSlug: tournamentHumanReadableId });

  const nodeId = useGetNodeId({ id: selectedEventId || topicId, type: nodeType });
  const isSubscribed = !!nodeId;

  const championSplashImage = useMemo(() => {
    if (type !== CHAMPION) return '';
    // needs to be change to our own version of the image images can be >150kb
    const query = championName.replace(' ', '');
    return `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${query}_0.jpg`;
  }, [championName, type]);

  const title = useMemo(() => {
    if (type === PLAYER) return playerNickname;
    if (type === TEAM) return teamName;
    if (type === EVENT) return tournamentName;
    if (type === CHAMPION) return championName;
    return '';
  }, [playerNickname, teamName, tournamentName, championName, type]);

  const subtitle = useMemo(() => {
    if (type === PLAYER) return teamName;
    if (type === TEAM) return teamCountry;
    if (type === EVENT) return tournamentCountry;
    if (type === CHAMPION) return championTitle;
    return '';
  }, [teamCountry, tournamentCountry, teamName, type, championTitle]);

  const cardImage = useMemo(() => {
    if (type === PLAYER) return playerImage;
    if (type === TEAM) return teamLogo;
    if (type === EVENT) return tournamentLogo;
    if (type === CHAMPION) return championImage;
    return '';
  }, [playerImage, tournamentLogo, teamLogo, type, championImage]);

  const browsePath = useMemo(() => {
    if (type === PLAYER) return '/players';
    if (type === TEAM) return '/teams';
    if (type === EVENT) return '/events';
    if (type === CHAMPION) return '/champions';
    return '';
  }, [type]);

  const logo = useMemo(() => {
    if (type === PLAYER) return teamLogo;
    return '';
  }, [teamLogo, type]);

  const elementSizes = useMemo(() => {
    if (size === LARGE) {
      return {
        headerHeight: '152px',
        miniHeaderHeight: '72px',
      };
    }
    if (size === MEDIUM) {
      return {
        headerHeight: '136px',
        miniHeaderHeight: '64px',
      };
    }
    return {
      headerHeight: '120px',
      miniHeaderHeight: '56px',
    };
  }, [size]);

  const handleBackClick = useCallback(
    () => navigate(packageSlug ? `/${packageSlug}/browse${browsePath}` : `/browse${browsePath}`),
    [packageSlug, navigate, browsePath],
  );

  const backgroundProps = useSpring({
    height: showMini ? elementSizes.miniHeaderHeight : elementSizes.headerHeight,
    immediate: prefersReducedMotion,
  });

  const props = useSpring({
    transform: showMini ? `translateY(${-100}%)` : `translateY(${0}%)`,
    immediate: prefersReducedMotion,
  });

  const loginCTA = LOGIN_CTA[packageName] ?? LOGIN_CTA.default;
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const toggleLoginModal = useCallback(() => setLoginModalOpen(prev => !prev), []);
  const handleButtonClick = useMemo(
    () =>
      debounce(async () => {
        if (!isAuthenticated) {
          toggleLoginModal();
          return;
        }
        if (waitingForSub.current) return;
        waitingForSub.current = true;
        const subscriptionId = type === CHAMPION ? championId : topicId;
        if (isSubscribed) {
          await onUnsubscribeClick(topicId, type, nodeId);
          waitingForSub.current = false;
          return;
        }
        setIsHovering(false);
        await onSubscribeClick(subscriptionId, type, topicId);
        waitingForSub.current = false;
      }, 100),
    [
      type,
      championId,
      topicId,
      isSubscribed,
      onSubscribeClick,
      onUnsubscribeClick,
      nodeId,
      toggleLoginModal,
      isAuthenticated,
    ],
  );

  const followButtonText = useMemo(() => {
    if (isSubscribed && isHovering) return UNFOLLOW;
    if (isSubscribed) return FOLLOWING;
    return FOLLOW;
  }, [isSubscribed, isHovering]);

  const backgroundGradient = useColorThief(championSplashImage || logo || cardImage);

  const { hasLoaded } = useOnImageLoad(cardImage);
  const componenthasLoaded = useLoadingDelay(hasLoaded);

  if (!componenthasLoaded) {
    return <TopicHeaderLoading size={size} />;
  }

  return (
    <AnimatedBackground
      gradient={backgroundGradient}
      style={backgroundProps}
      data-testid="topic-header"
    >
      <HelmetWrapper
        title={`${title || 'Topic'} | ${getTitlePackageName(packageName)}`}
        description={HELMET_DESCRIPTIONS[packageName] ?? HELMET_DESCRIPTIONS.default}
      />
      <animated.div style={props}>
        <RepeatingTextBackground text={title} textSize={size} />
      </animated.div>

      <Content>
        <LeftSection $showMini={showMini}>
          <IconWrapper onClick={handleBackClick}>
            <Icon type="arrowLeftExtended" size={24} inline />
          </IconWrapper>
          <TopicInfo
            headline={title}
            subtitle={subtitle}
            teamLogo={logo}
            image={cardImage}
            showMini={showMini}
            size={size}
            icon={roleIcon}
            type={type}
          />
        </LeftSection>
        <RightSection
          $showMini={showMini}
          $isSubscribed={isSubscribed}
          $isHovering={isHovering}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <Button
            data-testid="follow-button"
            variant="secondary"
            size="small"
            onClick={handleButtonClick}
            shimmer
            icon={followButtonText === FOLLOWING ? 'tickFilled' : ''}
            iconPosition="right"
          >
            <Typography type="title-m">{followButtonText}</Typography>
          </Button>
        </RightSection>
      </Content>
      {type === CHAMPION && (
        <ChampionImageWrapper $showMini={showMini} $width={400}>
          <ChampionImage src={championSplashImage} alt={championName} />
        </ChampionImageWrapper>
      )}
      {!isAuthenticated && (
        <LoginCTAModal
          isOpen={loginModalOpen}
          description={loginCTA.description}
          heading={loginCTA.heading}
          icon={loginCTA.icon}
          onCloseClick={toggleLoginModal}
        />
      )}
    </AnimatedBackground>
  );
};

TopicHeader.propTypes = {
  showMini: PropTypes.bool,
  size: PropTypes.oneOf(SIZES),
  onSubscribeClick: PropTypes.func,
  onUnsubscribeClick: PropTypes.func,
  topicId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PLAYER, TEAM, EVENT, CHAMPION]),
};

export default memo(TopicHeader);
