import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;

  max-width: 446px;
  @media ${device.tablet} {
    max-width: 676px;
  }
  @media ${device.desktop} {
    max-width: 850px;
  }
  @media ${device.desktopLarge} {
    max-width: 1251px;
  }
`;

export const ContainerContent = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: ${props => {
    if (props.viewLayout === 3) {
      if (props.isCompact) {
        return '1fr 1fr';
      }
      return '2fr 1fr';
    }
    return 'repeat(2, 1fr)';
  }};
  width: 100%;
  grid-gap: 6px;
  @media ${device.tablet} {
    grid-gap: 9px;
  }
  @media ${device.desktop} {
    grid-gap: 12px;
  }
  @media ${device.desktopLarge} {
    grid-gap: 20px;
  }
`;

export const BackAction = styled.button`
  margin: 0 5px auto 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: ${colors.white};
  cursor: pointer;
`;

export const ActionLabel = styled.div`
  margin-left: 9px;
  color: ${props => (props.hasColor ? props.theme.screenPovSelectors.labelColor : colors.white)};
`;
