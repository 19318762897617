import { memo, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import NeonBorder from 'znipe-elements/general/NeonBorder/NeonBorder';
import {
  SINGLE_ELIMINATION,
  DOUBLE_ELIMINATION,
} from 'tv-modules/Standings/Bracket/utils/constants';
import useMatchLink from 'tv-hooks/useMatchLink';
import TeamInfo from './TeamInfo';
import {
  themes,
  Label,
  LabelWrapper,
  OuterContainer,
  Container,
  Top,
  Bottom,
  TeamDivider,
  VerticalBar,
} from './Matchup.styles';

const TOP = 'top';
const BOTTOM = 'bottom';

const defaultEmptyTeam = {
  id: '',
  name: 'TBA',
  score: 0,
  logo: '',
};

const Matchup = ({
  label,
  teamOne = defaultEmptyTeam,
  teamTwo = defaultEmptyTeam,
  isLive = false,
  onClickWatch = () => {},
  winner,
  tournamentType = DOUBLE_ELIMINATION,
  setHoveredTeam = () => {},
  selectedTeams = [],
  matchId = '',
}) => {
  const [buttonVisible, setButtonVisible] = useState(false);
  const [hoveringOnTop, setHoveringOnTop] = useState(false);
  const [hoveringOnBottom, setHoveringOnBottom] = useState(false);
  const { secondaryTextColor } = useThemeContext();
  const handleMouseEnter = useCallback(() => {
    if (isLive) setButtonVisible(true);
  }, [isLive]);

  const handleMouseLeave = useCallback(() => {
    if (isLive) setButtonVisible(false);
  }, [isLive]);

  const handleMouseEnterTop = useCallback(
    team => {
      setHoveringOnTop(true);
      setHoveredTeam(team);
    },
    [setHoveredTeam],
  );
  const handleMouseLeaveTop = useCallback(() => {
    setHoveringOnTop(false);
    setHoveredTeam('');
  }, [setHoveredTeam]);
  const handleMouseEnterBottom = useCallback(
    team => {
      setHoveringOnBottom(true);
      setHoveredTeam(team);
    },
    [setHoveredTeam],
  );
  const handleMouseLeaveBottom = useCallback(() => {
    setHoveringOnBottom(false);
    setHoveredTeam('');
  }, [setHoveredTeam]);

  const isDoubleElimination = tournamentType === DOUBLE_ELIMINATION;
  const hasWinner = winner === teamOne.id || winner === teamTwo.id;

  const content = useMemo(() => {
    const useHoverStyle = hasWinner && (hoveringOnTop || hoveringOnBottom);
    const hoveringOnLoser =
      (hoveringOnTop && winner === teamTwo.id) || (hoveringOnBottom && winner === teamOne.id);

    return (
      <Container isLive={isLive} data-testid="match-container">
        {buttonVisible ? (
          <Button
            data-testid="watch-live-button"
            variant="primary"
            size="small"
            icon="streamFilled"
            onClick={onClickWatch}
          >
            watch live
          </Button>
        ) : (
          <>
            <Top
              isWinner={winner === teamOne.id}
              useHoverStyle={useHoverStyle}
              hoveringOnLoser={hoveringOnLoser}
              onMouseEnter={() => handleMouseEnterTop(teamOne.id)}
              onMouseLeave={handleMouseLeaveTop}
              data-testid="team-top"
              $isSelectedByFilter={selectedTeams?.includes(teamOne.id)}
            >
              <VerticalBar position={TOP} highlight={winner === teamOne.id} />
              <TeamInfo
                id={teamOne.id}
                name={teamOne.name}
                score={teamOne.score}
                logo={teamOne.logo}
                isLoser={winner === teamTwo.id}
                isDoubleElimination={isDoubleElimination}
                highlightArrow={hoveringOnTop}
              />
            </Top>
            <TeamDivider highlight={useHoverStyle} hoveringOnLoser={hoveringOnLoser} />
            <Bottom
              isWinner={winner === teamTwo.id}
              useHoverStyle={useHoverStyle}
              hoveringOnLoser={hoveringOnLoser}
              onMouseEnter={() => handleMouseEnterBottom(teamTwo.id)}
              onMouseLeave={handleMouseLeaveBottom}
              data-testid="team-bottom"
              $isSelectedByFilter={selectedTeams?.includes(teamTwo.id)}
            >
              <VerticalBar position={BOTTOM} highlight={winner === teamTwo.id} />
              <TeamInfo
                id={teamTwo.id}
                name={teamTwo.name}
                score={teamTwo.score}
                logo={teamTwo.logo}
                isLoser={winner === teamOne.id}
                isDoubleElimination={isDoubleElimination}
                highlightArrow={hoveringOnBottom}
              />
            </Bottom>
          </>
        )}
      </Container>
    );
  }, [
    hasWinner,
    hoveringOnTop,
    hoveringOnBottom,
    winner,
    teamTwo.id,
    teamTwo.name,
    teamTwo.score,
    teamTwo.logo,
    teamOne.id,
    teamOne.name,
    teamOne.score,
    teamOne.logo,
    isLive,
    buttonVisible,
    onClickWatch,
    handleMouseLeaveTop,
    selectedTeams,
    isDoubleElimination,
    handleMouseLeaveBottom,
    handleMouseEnterTop,
    handleMouseEnterBottom,
  ]);

  const matchLink = useMatchLink(matchId);
  const navigate = useNavigate();
  const gotoMatch = useMemo(() => {
    if (!matchId) return undefined;

    return () => navigate(matchLink);
  }, [matchId, navigate, matchLink]);

  if (label) {
    return (
      <LabelWrapper>
        <Label data-testid="label" color={secondaryTextColor}>
          <Typography type="title-s">{label}</Typography>
        </Label>
        <OuterContainer
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={gotoMatch}
        >
          {isLive ? <NeonBorder overlapContent>{content}</NeonBorder> : content}
        </OuterContainer>
      </LabelWrapper>
    );
  }

  return (
    <OuterContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={gotoMatch}
    >
      {isLive ? <NeonBorder overlapContent>{content}</NeonBorder> : content}
    </OuterContainer>
  );
};

const teamShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  logo: PropTypes.string,
};

Matchup.propTypes = {
  matchId: PropTypes.string,
  label: PropTypes.string,
  teamOne: PropTypes.shape(teamShape),
  teamTwo: PropTypes.shape(teamShape),
  isLive: PropTypes.bool,
  winner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClickWatch: PropTypes.func,
  tournamentType: PropTypes.oneOf([SINGLE_ELIMINATION, DOUBLE_ELIMINATION]),
  setHoveredTeam: PropTypes.func,
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default withTheme(memo(Matchup), themes, 'matchup');
