import { useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
`;

const IFrame = styled.iframe`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999;
`;

const Root = () => {
  // @TODO This is a custom hook on another branch, Use the "useDisableWindowScroll" hook when available
  useEffect(() => {
    if (global.document) {
      document.body.classList.add('disable-scroll');
    }
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, []);

  return (
    <Container>
      <IFrame
        src="https://web.znipe.tv"
        allowFullScreen
        allow="autoplay; fullscreen"
        title="znipe-tv"
      />
    </Container>
  );
};

export default Root;
