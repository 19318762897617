export default {
  /* Main colors */
  goldYellow: '#f99f28',
  znipeBlue: '#1c84de',
  znipeIconBlue: '#6C7C93',

  znipeBackgroundPrimary: '#0C1117',
  znipeBackgroundSecondary: '#121821',
  znipeBackgroundTertiary: '#171F2B',
  znipeContainerStroke: '#273039',

  velvetCream: '#DE1C49',
  trollGreen: '#4BA03F',
  slimeGreen: '#5AE881',
  spaceGreen: '#1cde90',
  flamingo: '#FF7070',
  coral: '#fd4b4b',
  peeled: '#e28212',
  gentlyPeeled: '#ff9b28',

  strawberry: '#F54C6A',
  gemstone: '#859FFF',
  lilac: '#D085FF',
  pinkPanther: '#FF6BD5',
  cyan: '#47FFD3',
  alien: '#47FF6F',
  sunshine: '#FFF847',
  tangerine: '#FFAA47',
  skyBlue: '#6AB4F5',
  limeJuice: '#9CFC00',

  /* Grayscale */
  grey1: '#EDEDED',
  grey2: '#D1D2D3',
  grey3: '#999999',
  grey10: '#181919',
  grey20: '#303133',
  grey30: '#494A4D',
  grey53: '#878787',
  grey65: '#9DA1A6',
  grey75: '#B6B9BF',
  grey85: '#CED2D9',
  grey90: '#DADEE5',
  onyxGrey: '#33363a',
  stupidGrey: '#7c7c7c',
  darkGrey: '#666e75',
  midGrey: '#a3a3a3',
  regsGrey: '#d1d2d3',
  paleGrey: '#f4f4f4',
  paperGrey: '#f1f1f1',
  shadowGrey: '#1b1b1b',
  white: '#fff',
  silver: '#bfbfbf',
  black: '#000',

  /* Riot colors */
  riotBackgroundPrimary: '#0b0d0e',
  riotBackgroundSecondary: '#14171a',
  riotBackgroundRule: '#292d31',
  riotBackgroundArticle: '#eeeeee',

  riotTextPrimary: '#fff',
  riotTextSecondary: '#687077',

  riotAccentBlue: '#5abbd4',
  riotAccentGold: '#c79e57',
  riotAccentOrange: '#e7920e',
  riotAccentOrangeDark: '#F2B196',
  riotAccentOrangeLight: '#F4E8E3',

  riotTeamChaosPrimary: '#de2f2f',
  riotTeamChaosSecondary: '#844c4c',
  riotTeamOrderPrimary: '#1580b6',
  riotTeamOrderSecondary: '#4c7184',

  // proview colors
  liveRed: '#eb5757',
  palePink: '#feeaea',

  // ubisoft colors
  blindingYellow: '#fbff28',
  r6TeamA: '#3755c0',
  r6TeamB: '#de5124',

  // gradient colors
  gradientStart: 'rgba(0, 0, 0, 0.67)',

  // znipebase colors
  baseBackgroundPrimary: '#171A24',
  baseBackgroundSecondary: '#1E202A',
  baseBackgroundTertiary: '#252832',
  optimisticOrange: '#cb480f',
  gloomyGrey: '#38414e',
  gorgeousGrey: '#171a24',
  gallantGrey: '#252832',
  slateGrey: '#8c8c8c',
  youngYellow: '#ffc107',
  rogueRed: '#de2f2f',
  bashfulBlue: '#1c84de',
  goGreen: '#2a8333',
  shinyGreen: '#49ec59',
  oddOrange: '#e7920e',
  sunflower: '#ffe713',
  steelBlue: '#6a7693',
  sandy: '#9D7D4D',
  camouflage: '#75936A',
  grapejuice: '#825D88',
  bloodMoon: '#98525F',
  oceanWaves: '#467298',

  // znipebase colors - old
  almostBlack: '#12141c',
  dangerRed: '#ff4d4f',
  veryDarkBlueGrey: '#292d39',
  pureOrange: '#ffb800',
  vividGreen: '#aae306',

  // CSGO game color
  CTBlue: '#589DE2',
  TYellow: '#C9AA32',

  // esportal colors
  esportalBackgroundPrimary: '#0E0F14',
  esportalBackgroundSecondary: '#161720',
  esportalBackgroundTertiary: '#1D1F29',
  esportalContainerStroke: '#2F3245',
  esportalIconBlue: '#6C7193',

  // EFG colors
  efgBackgroundPrimary: '#0E0F14',
  efgBackgroundSecondary: '#20222D',
  efgBackgroundTertiary: '#2A2E3C',
  efgContainerStroke: '#34374C',
  efgHighlight: '#6C7193',

  efgUIAccent: '#C44D27',
  efgTeamA: '#2935A6',
  efgTeamB: '#A62929',

  // Faceitcolours
  faceitBackgroundPrimary: '#161616',
  faceitBackgroundSecondary: '#1F1F1F',
  faceitBackgroundTertiary: '#303030',
  faceitContainerStroke: '#484848',
  faceitHighlight: '#AAABB2',

  faceitUIAccent: '#FF5500',
  faceitTeamA: '#00AAFF',
  faceitTeamB: '#FFBF00',
  faceitErrorRed: '#FF002B',
  faceitErrorLight: '#FF4B6A',
  faceitSuccessGreen: '#32D35A',
  faceitOnboardingStroke: '#FF5500',

  // proview colors
  pvBackgroundPrimary: '#0B0D0E',
  pvBackgroundSecondary: '#14171A',
  pvBackgroundTertiary: '#1C1E22',
  pvContainerStroke: '#21262B',
  pvHighlight: '#4E565E',
  teamOrder: '#1c84de',
  teamChaos: '#de2f2f',
  proviewContainerBg: '#161A1D',
  proviewContainerBorder: '#454D54',

  proviewTeamOrder: '#1c84de',
  proviewTeamChaos: '#de2f2f',

  // Global Library Colors
  tooBlue: '#7FF0FF',
  pastelYellow: '#FFCC7F',
  bubblePink: '#FF7FB7',
  beanGreen: '#7FFF7F',
  lavender: '#B880FF',
  ravenousRed: '#FF7F7F',

  // Alpha Transparencies
  white08: 'rgba(255, 255, 255, 0.08)',
  white12: 'rgba(255, 255, 255, 0.12)',
  white16: 'rgba(255, 255, 255, 0.16)',
  white20: 'rgba(255, 255, 255, 0.20)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white30: 'rgba(255, 255, 255, 0.30)',

  black08: 'rgba(0, 0, 0, 0.08)',
  black12: 'rgba(0, 0, 0, 0.12)',
  black16: 'rgba(0, 0, 0, 0.16)',
  black20: 'rgba(0, 0, 0, 0.20)',
};
