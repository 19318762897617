import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { ROUND_KILL_ASSISTS_SUMMARY_EVENT } from 'tv-reducers/intensive';
import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';

export const SUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY = 'SUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY';
export const UNSUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY = 'UNSUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY';

export const useRoundKillsAssistsSummary = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY, gatId });
  }, [dispatch, gatId]);
};

export const groupKillAssistsSummariesByRound = summaries =>
  summaries?.reduce((acc, summary) => {
    const assist = {
      actor: summary.actor,
      subject: summary.subject,
      gameTime: summary.gameTime,
    };

    const id = summary.round;
    const accVal = acc[id] || { assists: [] };

    return {
      ...acc,
      [id]: {
        assists: [...accVal.assists, assist],
        round: id,
        gameId: summary.gameId,
        startTime: Math.min(acc.startTime ?? Infinity, summary.gameTime),
        endTime: Math.max(acc.startTime ?? 0, summary.gameTime),
      },
    };
  }, {});

export const getPlayers = payload =>
  payload?.reduce((acc, { actor, subject }) => {
    const actorObject = createPlayerObject(actor);
    const subjectObject = createPlayerObject(subject);
    return {
      ...acc,
      [actorObject.id]: actorObject,
      [subjectObject.id]: subjectObject,
    };
  }, {});

const roundKillsSummary = factorySubscribeEpic(
  [SUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY, UNSUBSCRIBE_ROUND_KILL_ASSISTS_SUMMARY],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('type').equalTo('KillAssistEvent'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const mappedPayload = groupKillAssistsSummariesByRound(payload);
      const players = getPlayers(payload);
      return {
        type: ROUND_KILL_ASSISTS_SUMMARY_EVENT,
        gatId,
        payload: { id: gatId, rounds: Object.values(mappedPayload), players },
      };
    },
);

export default roundKillsSummary;
