import colors from 'znipe-styles/colors';

export default {
  proview: {
    color: colors.riotBackgroundRule,
    backButtonColor: colors.riotTextSecondary,
    backgroundImageTablet: 'https://assets.znipe.tv/lol/Baron_Pit_Background.jpg',
    backgroundImageDesktop: 'https://assets.znipe.tv/lol/Baron_Pit_Background.jpg',
    error: {
      header: 'Sunsetting Pro View',
      description:
        'LoL Esports will be sunsetting Pro View starting February 1st, 2022. The option to subscribe has been disabled.',
    },
    backgroundColor: colors.white,
  },
  default: {
    backButtonColor: colors.riotTextSecondary,
    backgroundImageTablet: 'https://assets.znipe.tv/onboarding/csgo/Bottom-image.png',
    backgroundImageDesktop: 'https://assets.znipe.tv/onboarding/csgo/Bottom-image.png',
  },
};
