import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const List = styled.ul`
  align-self: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 75px;
  max-width: fit-content;
  @media ${device.laptop} {
    margin-bottom: 66px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  align-content: center;
  > *:first-child {
    margin-right: 20px;
  }
`;
