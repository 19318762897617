const RGBToHSL = (r, g, b) => {
  // Make r, g, and b fractions of 1
  const red = r / 255;
  const green = g / 255;
  const blue = b / 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(red, green, blue);
  const cmax = Math.max(red, green, blue);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;
  // Calculate hue
  // No difference
  if (delta === 0) h = 0;
  // Red is max
  else if (cmax === red) h = ((green - blue) / delta) % 6;
  // Green is max
  else if (cmax === green) h = (blue - red) / delta + 2;
  // Blue is max
  else h = (red - green) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;
  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  return [h, s, l];
};

export default RGBToHSL;
