import produce from 'immer';
import deepmerge from 'deepmerge';
import { SET_STREAMS, SET_STREAM_TOKENS } from 'tv-constants/redux';

export const initialState = {};

const streamsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_STREAMS: {
      const streamUrls = payload.streamUrls || {};
      const globalStreams = payload.globalStreams || {};
      const newValues = Object.entries(streamUrls).reduce((acc, [key, value]) => {
        const addedState = { ...value };
        if (globalStreams[key]?.streamId === key) {
          addedState.type = globalStreams?.type || '';
        }
        return { ...acc, [key]: addedState };
      }, {});
      return deepmerge(state, newValues);
    }
    case SET_STREAM_TOKENS: {
      const streams = payload || {};
      state = deepmerge(state, streams);
      return state;
    }

    default:
      return state;
  }
}, initialState);

export default streamsReducer;
