import { memo, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'znipe-utils/string/capitalizeString';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import TopicLink, { ICON_AVATAR, TEXT } from 'tv-elements/data-display/TopicLink/TopicLink';
import TopicLinkSearchResult from './TopicLinkSearchResult';
import { Header, Content } from './ActiveFilters.styles';
import { YEARS, CLASSES, EVENTS, selectionShape, allFilterMenus } from '../../Filters.constants';

const getNewSelectionValue = (topic, currentValue, targetValue) => {
  if (topic === YEARS) return currentValue.filter(value => value !== targetValue);
  if (topic === CLASSES) return { ...currentValue, [targetValue]: !currentValue[targetValue] };
  return currentValue.filter(value => value.id !== targetValue);
};

const ActiveFilters = ({
  selection,
  menuOptions = [],
  onClickClearAll = () => {},
  onClickClear = () => {},
}) => {
  const handleClickClear = useCallback(
    (topic, targetValue) => {
      const currentValue = selection[topic];
      onClickClear({ [topic]: getNewSelectionValue(topic, currentValue, targetValue) });
    },
    [selection, onClickClear],
  );

  const getTopicLinkElements = useCallback(
    (menu, data) => {
      if (menu === YEARS) {
        return data.map(year => {
          const onClickIcon = () => handleClickClear(menu, year);
          return (
            <TopicLink
              key={year}
              title={year.toString()}
              type={TEXT}
              onClickIcon={onClickIcon}
              isActive
              noBackground
            />
          );
        });
      }
      if (menu === CLASSES) {
        const selected = Object.keys(data).filter(key => data[key]);
        return selected.map(type => {
          const onClickIcon = () => handleClickClear(menu, type);
          return (
            <TopicLink
              key={type}
              title={capitalize(type)}
              image={`${type}Class`}
              type={ICON_AVATAR}
              onClickIcon={onClickIcon}
              isActive
              noBackground
            />
          );
        });
      }
      let searchResults = data;
      if (menu === EVENTS) {
        searchResults = searchResults.map(result => ({ ...result, eventName: result.title }));
      }
      return (
        <TopicLinkSearchResult
          key={menu}
          menu={menu}
          searchResults={searchResults}
          onClear={handleClickClear}
        />
      );
    },
    [handleClickClear],
  );

  const contentElements = useMemo(
    () =>
      Object.entries(selection).reduce((acc, [menu, data]) => {
        if (menuOptions.indexOf(menu) === -1) return acc;
        return [...acc, getTopicLinkElements(menu, data)];
      }, []),
    [selection, menuOptions, getTopicLinkElements],
  );

  const contentRef = useRef(null);
  const [contentCount, setContentCount] = useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const element = contentRef.current;
    if (element) setContentCount(element.children.length);
  }, [contentElements]);

  return (
    <>
      {contentCount > 0 && (
        <Header>
          <Typography type="title-xs">
            {`${contentCount} ACTIVE FILTER${contentCount > 1 ? 'S' : ''}`}
          </Typography>
          <Button
            data-testid="clear-button"
            variant="secondary"
            size="xsmall"
            onClick={onClickClearAll}
          >
            CLEAR ALL
          </Button>
        </Header>
      )}
      <Content ref={contentRef}>{contentElements}</Content>
    </>
  );
};

ActiveFilters.propTypes = {
  selection: PropTypes.shape(selectionShape).isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.oneOf(allFilterMenus)),
  onClickClear: PropTypes.func,
  onClickClearAll: PropTypes.func,
};

export default memo(ActiveFilters);
