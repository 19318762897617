import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedMatchTeams from 'tv-selectors/match/makeGetMatchTeams';

const makeGetMatchTeamsShortNames = () => {
  const getSelectedMatchTeams = makeGetSelectedMatchTeams();
  return createSelector([getSelectedMatchTeams], teams =>
    teams.map(team => team.shortName || team.name || ''),
  );
};

export const useGetMatchTeamsShortNames = props =>
  useMemoizedGqlSelector(makeGetMatchTeamsShortNames, props);

export default makeGetMatchTeamsShortNames;
