import { useMemo } from 'react';
import PropTypes from 'prop-types';
import VideoSkeleton from 'znipe-elements/feedback/VideoSkeleton/VideoSkeleton';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import { Container, Content, ThumbnailContainer } from './ItemWithVideoPreview.styles';
import { LoadingContentContainer } from '../FeaturedMatchDetails/FeaturedMatchDetails.styles';
import { smallSkeletonSizes, largeSkeletonSizes } from './ItemWithVideoPreview.constants';

const ItemWithVideoPreviewLoading = ({ size = 'small', orientation = 'vertical' }) => {
  const { primary, secondary, button } = useMemo(() => {
    switch (size) {
      case 'small':
        return smallSkeletonSizes;
      default:
        return largeSkeletonSizes;
    }
  }, [size]);

  return (
    <Container data-testid="item-with-video-preview" orientation={orientation} size={size}>
      <ThumbnailContainer
        data-testid="item-with-video-preview-thumbnail-wrapper"
        orientation={orientation}
        size={size}
        $isLoading
      >
        <VideoSkeleton />
      </ThumbnailContainer>
      <Content size={size}>
        <LoadingContentContainer>
          <TextSkeleton width={primary.width} height={primary.height} />
          <TextSkeleton width={secondary.width} height={secondary.height} />
          <TextSkeleton width={button.width} height={button.height} />
        </LoadingContentContainer>
      </Content>
    </Container>
  );
};

ItemWithVideoPreviewLoading.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
};

export default ItemWithVideoPreviewLoading;
