import { useRef } from 'react';
import useFeatureFlagsContext from './useFeatureFlagsContext';

const useFeatureFlag = (flag: string) => {
  const registered = useRef(false);
  const { flags, registerFlag } = useFeatureFlagsContext();

  if (!registered.current && registerFlag) {
    void registerFlag(flag);
    registered.current = true;
  }

  return flags?.[flag]?.value;
};

export default useFeatureFlag;
