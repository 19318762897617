import PropTypes from 'prop-types';
import { AnimationContainer, AnimatedObject } from './BufferAnimation.styles';

const BufferAnimation = ({ isActive = false }) => (
  <AnimationContainer $show={isActive}>
    <AnimatedObject />
    <AnimatedObject />
    <AnimatedObject />
    <AnimatedObject />
    <AnimatedObject />
    <AnimatedObject />
  </AnimationContainer>
);

BufferAnimation.propTypes = {
  isActive: PropTypes.bool,
};

export default BufferAnimation;
