import { useEffect } from 'react';
import { fromEvent, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import firebase from 'firebase/app';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import { GAT_OFFSET_EVENT } from 'tv-reducers/intensive';
import useGatId from 'tv-hooks/useGatId';

export const SUBSCRIBE_GAT_OFFSET = 'SUBSCRIBE_GAT_OFFSET';
export const UNSUBSCRIBE_GAT_OFFSET = 'UNSUBSCRIBE_GAT_OFFSET';

export const useSubscribeGatOffset = (matchId, gameId) => {
  const dispatch = useIntensiveDispatch();
  // We are going to move over to use the gameId instead of gatId for everything later, but ned to keep both for now
  const gatId = useGatId(matchId) || gameId;

  useEffect(() => {
    if (!matchId || !gameId || !gatId) return undefined;
    dispatch({ type: SUBSCRIBE_GAT_OFFSET, matchId, gameId, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_GAT_OFFSET, matchId, gameId });
  }, [dispatch, matchId, gameId, gatId]);
};

const getSnap = snap => snap.val();

const calculateChampSelectDuration = events => {
  const [champSelectStartTimestamp, gameStartTimestamp, addition = 0] = events ?? [];
  if (!champSelectStartTimestamp || !gameStartTimestamp) return 0;

  const start = new Date(champSelectStartTimestamp);
  const stop = new Date(gameStartTimestamp);
  const duration = stop.getTime() - start.getTime();
  return duration / 1000 + addition;
};

// In lol, they use the pauses before 3s to let the production get ready, so we count them into the match setup duration
const calculatePauseDuration = (state, gatId) => {
  const { pauses = [], gameTitle } = state.gats[gatId] ?? {};
  if (gameTitle !== 'lol') return 0;
  let totalSetupPause = 0;
  // Use regular for loop to be able to break in the middle
  for (let i = 0; i < pauses.length; i++) {
    const id = pauses[i];
    const pause = state.pauses?.[id];
    const { startTime = 0, duration = Infinity } = pause;
    if (startTime > 3) break; // Only take pauses before 3s
    totalSetupPause += duration;
  }
  return totalSetupPause;
};

const emptyArray = [];

const gatOffsetEpic = factorySubscribeEpic(
  [SUBSCRIBE_GAT_OFFSET, UNSUBSCRIBE_GAT_OFFSET],
  ({ matchId, gameId }) => `${matchId}-${gameId}`,
  ({ matchId, gameId, gatId }, state$) =>
    combineLatest([
      fromEvent(
        firebase.database().ref(`/matches/${matchId}/games/${gameId}/offset`),
        'value',
      ).pipe(map(getSnap)),
      combineLatest([
        fromEvent(
          firebase.database().ref(`gat/${gatId}/championSelectStart/timestamp`),
          'value',
        ).pipe(map(getSnap)),
        fromEvent(firebase.database().ref(`/gat/${gatId}/gameStart/timestamp`), 'value').pipe(
          map(getSnap),
        ),
        state$.pipe(map(state => calculatePauseDuration(state, gatId))),
      ]).pipe(
        map(calculateChampSelectDuration),
        filter(setupDuration => setupDuration !== state$.value.gats?.[gatId]?.matchSetupDuration),
      ),
    ]),
  ({ gatId }) =>
    events => {
      const [offset = 0, matchSetupDuration = 0] = events[events.length - 1] ?? emptyArray;
      return {
        type: GAT_OFFSET_EVENT,
        gatId,
        offset,
        matchSetupDuration,
      };
    },
);

export default gatOffsetEpic;
