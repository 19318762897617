export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const TEAM = 'team';
export const PLAYER = 'player';
export const PLAYER_CARD = 'player_card';
export const CHAMPION = 'champion';
export const ICON_AVATAR = 'icon_avatar';
export const TEXT = 'text';
export const DEFAULT = 'default';
