import styled from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';
import theme from 'znipe-themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const Headline = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: ${theme.heading2FontSize};
  line-height: ${theme.heading2LineHeight};
  text-align: center;
  margin-bottom: 14px;
  color: ${colors.riotBackgroundRule};
  @media ${device.tablet} {
    margin-bottom: 29px;
    font-size: ${theme.heading1FontSize};
    line-height: ${theme.heading1LineHeight};
  }
`;

export const TextContent = styled.p`
  font-size: ${theme.heading5FontSize};
  line-height: ${theme.heading4LineHeight};
  text-align: center;
  color: ${colors.riotBackgroundRule};
  margin-bottom: 14px;
  @media ${device.tablet} {
    margin-bottom: 77px;
    font-size: ${theme.heading4FontSize};
  }
  @media ${device.laptop} {
    line-height: ${theme.paragraphLineHeightBody};
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 47px;
  width: 72px;
  @media ${device.tablet} {
    margin-bottom: 114px;
  }
  @media ${device.laptop} {
    width: 104px;
    margin-bottom: 137px;
  }
`;
