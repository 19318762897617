import styled, { css } from 'styled-components';
import gradients from 'znipe-styles/gradients';

export const Container = styled.div`
  padding: 16px;
  ${props =>
    props.isDesktop &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  margin-top: 8px;
  color: ${props => props.theme.secondaryTextColor};
`;

export const ActionsContainer = styled.div`
  display: flex;
  ${props =>
    !props.isDesktop &&
    css`
      margin-top: 24px;
    `}
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Border = styled.div`
  width: 2px;
  height: 60px;
  margin: 0 24px;
  background: ${gradients.edgeFadingLine};
`;
