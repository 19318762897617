import { useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGetIsCompactPlayer } from 'tv-selectors/ui/makeGetIsCompactPlayer';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useWidth } from 'tv-selectors/browser/makeGetWidth';
import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';
import { useGetGameCompetitorsLineups } from 'tv-selectors/games/makeGetGameCompetitorsLineups';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import MatchInfo from 'tv-modules/MatchInfo/MatchInfo';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import FlourishContainer from 'znipe-elements/data-display/FlourishContainer/FlourishContainer';
import { useShouldShowOnboardingStep } from 'tv-selectors/auth/makeGetShouldShowOnboardingStep';
import OnboardingPopup from 'tv-modules/Onboarding/Popup/Popup';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';
import useHasMatchStarted from 'tv-hooks/useHasMatchStarted';
import {
  highlightPopupInfo,
  statsPopupInfo,
  predictionsPopupInfo,
  STAGE_WELCOME_STEP_ID,
} from 'tv-routes/Stage/Stage.constants';
import useHasMounted from 'znipe-hooks/useHasMounted';
import featureFlag from 'tv-constants/staticFeatureFlags';
import StatsButton from './components/StatsButton';
import PredictionsButton from './components/PredictionsButton';
// import RoomsButton from './components/RoomsButton';
import TeamStreams from './components/TeamStreams';
import HighlightsButton from './components/HighlightsButton';
import ChatButton from './components/ChatButton';
import GlobalStreamsButton from './components/GlobalStreamsButton';
import MatchGameSelector from '../MatchGameSelector/MatchGameSelector';
import GameSelectorButton from './GameSelectorButton/GameSelectorButton';
import MobileSelectedStreamItem from './MobileSelectedStreamItem/MobileSelectedStreamItem';
import StageMatchbarLoading from './StageMatchbar.loading';
import {
  MobileContainer,
  RightArea,
  StreamButtonPositioner,
  FullWidthContainer,
  FullWidthMiddleArea,
  MatchAndGameInfoWrapper,
  ButtonArea,
  FullWidthContentWrapper,
  MobileContentWrapper,
  MatchInfoArea,
  MatchInfoContentWrapper,
  IconWrapper,
  themes,
  AlignCenter,
} from './StageMatchBar.styles';

const playerRefShape = PropTypes.shape({
  current: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
});

const emptyObject = {};

const StageMatchBar = ({
  matchId,
  selectedMatchGameIndex,
  onStatsButtonClick = null,
  onGameSelect,
  onStreamItemClick = () => {},
  statsButtonActive = false,
  onHighlightClick = null,
  onChatButtonClick = null,
  chatButtonActive = false,
  predictionsButtonActive = false,
  onStreamPopout,
  playerRef = { current: null },
  highlightsButtonActive = false,
  onPredictionsButtonClick = null,
  onMobileStreamItemClick = () => {},
  isLiveMatch = false,
  // onRoomClick,
  // roomButtonActive,
  loading = false,
}) => {
  const mounted = useHasMounted();
  const showTutorial = useShouldShowOnboardingStep({ stepId: STAGE_WELCOME_STEP_ID }) && mounted;
  const currentWidth = useWidth();
  const isCompactPlayer = useGetIsCompactPlayer();
  const lineups = useGetGameCompetitorsLineups({ matchId, selectedMatchGameIndex });
  const shouldShowStatsButton = lineups.length > 0 && !isCompactPlayer;
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const globalStreams = useGetGameGlobalStreams({
    matchId,
    selectedMatchGameIndex,
  });
  const { themeName, teamVsTeam, stageMatchBar } = useThemeContext();
  const hasGlobalStreams = globalStreams.length > 0;

  const background = useMemo(() => {
    if (stageMatchBar.backgroundColor) return stageMatchBar.backgroundColor;
    return teamVsTeam;
  }, [stageMatchBar.backgroundColor, teamVsTeam]);

  const flourish = useMemo(() => {
    if (themeName === 'proview') return 'waves';
    return 'edgedWaves';
  }, [themeName]);

  const [competitor1 = emptyObject, competitor2 = emptyObject] = useGetGameCompetitors({
    matchId,
    selectedMatchGameIndex,
  });

  const isWide = useMemo(
    () => (isTabletOrGreater && !isDesktopOrGreater) || (isDesktopOrGreater && currentWidth > 1500),
    [currentWidth, isDesktopOrGreater, isTabletOrGreater],
  );

  const matchInfoElement = useMemo(() => {
    const size = () => {
      if (isWide) return 'medium';
      return 'small';
    };
    return (
      <MatchInfoArea>
        <MatchInfoContentWrapper>
          <MatchInfo matchId={matchId} selectedMatchGame={selectedMatchGameIndex} size={size()} />
        </MatchInfoContentWrapper>
      </MatchInfoArea>
    );
  }, [matchId, selectedMatchGameIndex, isWide]);

  const setPlayedStatus = useSetPlayedStatus(STAGE_WELCOME_STEP_ID);
  const disableStageWelcome = useCallback(() => setPlayedStatus(true), [setPlayedStatus]);

  const statsButtonElement = useMemo(() => {
    if (!onStatsButtonClick) return null;
    const handleStatsButtonClick = (...args) => {
      disableStageWelcome();
      onStatsButtonClick(...args);
    };
    return (
      <OnboardingPopup
        popupId={statsPopupInfo.popupId}
        headline={statsPopupInfo.headline}
        description={statsPopupInfo.description}
        timer={statsPopupInfo.timer}
        horizontalPosition={isDesktopOrGreater ? 'center' : 'right'}
        direction={isDesktopOrGreater ? 'left' : 'up'}
        targetShape={isDesktopOrGreater ? 'rectangle' : 'circle'}
      >
        <StatsButton
          onClick={handleStatsButtonClick}
          size={isTabletOrGreater && !isDesktopOrGreater ? 'large' : 'medium'}
          iconType={statsButtonActive && !isDesktopOrGreater ? 'close' : 'stats'}
          active={isDesktopOrGreater ? statsButtonActive : false}
          showLabel={isDesktopOrGreater}
        />
      </OnboardingPopup>
    );
  }, [
    onStatsButtonClick,
    statsButtonActive,
    isTabletOrGreater,
    isDesktopOrGreater,
    disableStageWelcome,
  ]);

  const predictionsButtonElement = useMemo(() => {
    if (!featureFlag.predictions) return null;
    if (!onPredictionsButtonClick) return null;
    const handlePredictionsButtonClick = (...args) => {
      disableStageWelcome();
      onPredictionsButtonClick(...args);
    };
    return (
      <OnboardingPopup
        popupId={predictionsPopupInfo.popupId}
        headline={statsPopupInfo.headline}
        description={statsPopupInfo.description}
        timer={statsPopupInfo.timer}
        horizontalPosition={isDesktopOrGreater ? 'center' : 'right'}
        direction={isDesktopOrGreater ? 'left' : 'up'}
        targetShape={isDesktopOrGreater ? 'rectangle' : 'circle'}
      >
        <PredictionsButton
          onClick={handlePredictionsButtonClick}
          size={isTabletOrGreater && !isDesktopOrGreater ? 'large' : 'medium'}
          iconType={predictionsButtonActive && !isDesktopOrGreater ? 'close' : 'stats'}
          active={isDesktopOrGreater ? predictionsButtonActive : false}
          showLabel={isDesktopOrGreater}
        />
      </OnboardingPopup>
    );
  }, [
    isDesktopOrGreater,
    predictionsButtonActive,
    isTabletOrGreater,
    disableStageWelcome,
    onPredictionsButtonClick,
  ]);

  const handleGameSelect = useCallback(
    (...args) => {
      disableStageWelcome();
      onGameSelect(...args);
    },
    [onGameSelect, disableStageWelcome],
  );

  const handleStreamItemClick = useCallback(
    (...args) => {
      disableStageWelcome();
      onStreamItemClick(...args);
    },
    [disableStageWelcome, onStreamItemClick],
  );

  const handleHighlightItemClick = useCallback(
    (...args) => {
      disableStageWelcome();
      onHighlightClick(...args);
    },
    [onHighlightClick, disableStageWelcome],
  );
  const matchHasStarted = useHasMatchStarted(matchId);

  if (isDesktopOrGreater) {
    if (loading) return <StageMatchbarLoading isLive={isLiveMatch} isDesktop />;
    return (
      <FullWidthContainer>
        <FlourishContainer
          flourish={flourish}
          background={background}
          shouldPreserveAspectRatio={false}
        >
          <FullWidthContentWrapper>
            <ButtonArea $align="left">
              {shouldShowStatsButton && onStatsButtonClick && statsButtonElement}
              {featureFlag.predictions && onPredictionsButtonClick && predictionsButtonElement}
            </ButtonArea>
            <FullWidthMiddleArea $isWide={isWide}>
              <TeamStreams
                showTutorial={showTutorial}
                competitor={competitor1}
                startIndex={0}
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                onStreamItemClick={handleStreamItemClick}
                onStreamPopout={onStreamPopout}
                playerRef={playerRef}
              />
              <AlignCenter $hasGlobalStreams={hasGlobalStreams}>
                {hasGlobalStreams && (
                  <StreamButtonPositioner id="stage-more-streams-button">
                    <GlobalStreamsButton
                      matchId={matchId}
                      selectedMatchGameIndex={selectedMatchGameIndex}
                      onStreamItemClick={handleStreamItemClick}
                      onStreamPopout={onStreamPopout}
                      playerRef={playerRef}
                    />
                  </StreamButtonPositioner>
                )}
                <MatchAndGameInfoWrapper>
                  {matchInfoElement}
                  <MatchGameSelector
                    matchId={matchId}
                    setSelectedMatchGame={handleGameSelect}
                    showContainerLabel={false}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                  />
                </MatchAndGameInfoWrapper>
              </AlignCenter>
              <TeamStreams
                showTutorial={showTutorial}
                competitor={competitor2}
                startIndex={lineups.length / 2}
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                onStreamItemClick={handleStreamItemClick}
                onStreamPopout={onStreamPopout}
                playerRef={playerRef}
              />
            </FullWidthMiddleArea>
            <ButtonArea $align="right">
              {onHighlightClick && (
                <OnboardingPopup
                  popupId={highlightPopupInfo.popupId}
                  headline={highlightPopupInfo.headline}
                  description={highlightPopupInfo.description}
                  timer={highlightPopupInfo.timer}
                  direction="right"
                  disabled={!matchHasStarted}
                >
                  <HighlightsButton
                    onClick={handleHighlightItemClick}
                    active={highlightsButtonActive}
                    size="medium"
                  />
                </OnboardingPopup>
              )}
              {onChatButtonClick && (
                <ChatButton onClick={onChatButtonClick} active={chatButtonActive} size="medium" />
              )}
              {/* {onRoomClick && <RoomsButton onClick={onRoomClick} active={roomButtonActive} />} */}
            </ButtonArea>
          </FullWidthContentWrapper>
        </FlourishContainer>
      </FullWidthContainer>
    );
  }

  if (loading) return <StageMatchbarLoading isLive={isLiveMatch} />;
  return (
    <MobileContainer $showTutorial={showTutorial}>
      <FlourishContainer flourish={flourish} background={background}>
        <MobileContentWrapper>
          {statsButtonActive ? (
            <IconWrapper>
              <Icon type="stats" size={24} />
            </IconWrapper>
          ) : (
            <MobileSelectedStreamItem
              onClick={onMobileStreamItemClick}
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
            />
          )}
          {matchInfoElement}
          <RightArea>
            {isCompactPlayer ? (
              <GameSelectorButton
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                onGameSelect={onGameSelect}
              />
            ) : (
              statsButtonElement
            )}
          </RightArea>
        </MobileContentWrapper>
      </FlourishContainer>
    </MobileContainer>
  );
};

StageMatchBar.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  onStatsButtonClick: PropTypes.func,
  onPredictionsButtonClick: PropTypes.func,
  onGameSelect: PropTypes.func,
  onHighlightClick: PropTypes.func,
  onChatButtonClick: PropTypes.func,
  onStreamItemClick: PropTypes.func,
  onStreamPopout: PropTypes.func.isRequired,
  onMobileStreamItemClick: PropTypes.func,
  isLiveMatch: PropTypes.bool,
  playerRef: playerRefShape,
  statsButtonActive: PropTypes.bool,
  predictionsButtonActive: PropTypes.bool,
  highlightsButtonActive: PropTypes.bool,
  chatButtonActive: PropTypes.bool,
  // onRoomClick: PropTypes.func,
  // roomButtonActive: PropTypes.bool,
  loading: PropTypes.bool,
};

export default withTheme(memo(StageMatchBar), themes, 'stageMatchBar');
