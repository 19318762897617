import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from './makeGetTournaments';

const getTournamentIdFromProps = (_, props) => props.tournamentId;

const makeGetTournamentCountry = () => {
  const getTournaments = makeGetTournaments();
  return createSelector(
    [getTournaments, getTournamentIdFromProps],
    (tournaments, tournamentId) => tournaments[tournamentId]?.location?.country ?? '',
  );
};

export const useGetTournamentCountry = props =>
  useMemoizedGqlSelector(makeGetTournamentCountry, props);

export default makeGetTournamentCountry;
