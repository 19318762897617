const FONT_FAMILY_HEADING = "'Exo', sans-serif";
const FONT_FAMILY_PARAGRAPH = FONT_FAMILY_HEADING;
const FONT_FAMILY_TITLE = FONT_FAMILY_HEADING;

export default {
  fonts: {
    preloads: [
      'https://assets.znipe.tv/fonts/Exo/Exo-Regular.woff2',
      'https://assets.znipe.tv/fonts/Exo/Exo-SemiBold.woff2',
    ],
    fontFaces: ['https://assets.znipe.tv/fonts/Exo/Exo.css'],
  },
  heading: {
    xl: {
      fontSize: '38px',
      lineHeight: '56px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    l: {
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    m: {
      fontSize: '22px',
      lineHeight: '36px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    s: {
      fontSize: '16px',
      lineHeight: '32px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    xs: {
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
  },
  paragraph: {
    l: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.02em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
    m: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
    s: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.02em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
  },
  title: {
    xxl: {
      fontSize: '36px',
      lineHeight: '56px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    xl: {
      fontSize: '22px',
      lineHeight: '36px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    l: {
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    m: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    s: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    xs: {
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
  },
};
