import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetGameCompetitors from 'tv-selectors/games/makeGetGameCompetitors';

const emptyArray = [];

const makeGetGameCompetitorsLineups = () => {
  const getGameCompetitors = makeGetGameCompetitors();
  return createSelector([getGameCompetitors], gameCompetitors => {
    if (gameCompetitors.length === 0) return emptyArray;
    return gameCompetitors.reduce((acc, competitor) => {
      const currentCompetitorLineup = competitor?.lineup || emptyArray;
      return acc.concat(currentCompetitorLineup);
    }, []);
  });
};
export const useGetGameCompetitorsLineups = props =>
  useMemoizedGqlSelector(makeGetGameCompetitorsLineups, props);

export default makeGetGameCompetitorsLineups;
