import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';

import { GRID, LIST, SCROLLLIST, PLAYLISTGRID } from './Section.constants';

export const SeeMoreSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-right: 10px;
  > a {
    color: ${colors.white};
  }
  @media ${device.desktop} {
    padding-right: 0;
  }
`;

export const SectionContainerMargins = css`
  margin-top: 40px;
  @media ${device.tablet} {
    margin-top: 48px;
  }
  @media ${device.desktop} {
    margin-top: 56px;
  }
  @media ${device.desktopLarge} {
    margin-top: 64px;
  }
`;

export const SectionWrapper = styled.div`
  align-self: center;
  ${({ noTopMargin }) => !noTopMargin && SectionContainerMargins}
`;

const GridStyles = css`
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 16px;
  min-height: 320px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(320px, auto));
  }
  @media ${device.desktop} {
    grid-template-columns: repeat(3, 1fr);
    min-height: 265px;
  }
  @media ${device.desktopExtraLarge} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const PlaylistGridStyles = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 0 16px;
  justify-content: space-between;
  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }
  @media ${device.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ListStyles = css`
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 8px;
  margin: 0 8px;
  position: relative;
  @media ${device.mobilePortrait} {
    margin: 0 16px;
  }
  @media (min-width: 576px) {
    margin: 0;
  }
  @media ${device.tablet} {
    gap: 16px;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin: 0;
      @media ${device.mobilePortrait} {
        margin: 0;
      }
    `}
`;

const ScrollListStyles = css`
  display: grid;
  grid-auto-flow: column;
  gap: 0px 8px;
  overflow: hidden;
  padding: 0 8px;
  @media ${device.mobilePortrait} {
    padding: 0 16px;
  }
  @media (min-width: 576px) {
    padding: unset;
  }
  @media ${device.laptop} {
    > div:last-child {
      width: 100%;
    }
  }
`;

export const ListWrapper = styled.div`
  display: grid;
  ${({ type }) => type === GRID && GridStyles}
  ${({ type }) => type === LIST && ListStyles}
  ${({ type }) => type === SCROLLLIST && ScrollListStyles}
  ${({ type }) => type === PLAYLISTGRID && PlaylistGridStyles}
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  > a {
    width: 100%;
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 8px;
  @media ${device.mobilePortrait} {
    margin: 0 16px;
  }
  @media (min-width: 576px) {
    margin: 0;
  }

  ${({ isGradientText, fullWidth }) =>
    !isGradientText &&
    fullWidth &&
    css`
      margin: 0;
      @media ${device.mobilePortrait} {
        margin: 0;
      }
    `}

  ${({ isGradientText }) =>
    !isGradientText &&
    css`
      margin-bottom: 8px;
      @media ${device.mobilePortrait} {
        margin-bottom: 8px;
      }
      @media (min-width: 576px) {
        margin-bottom: 8px;
      }
    `}
`;

export const LinkWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-template-rows: 1fr;
  gap: 0px 15px;
`;
