const supportsIntersectionObserver = () => {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  );
};

const supportsResizeObserver = () => {
  return 'ResizeObserver' in window;
};

export default function loadPolyfills() {
  const polyfills = [];

  if (!supportsIntersectionObserver()) {
    polyfills.push(import('intersection-observer'));
  }

  if (!supportsResizeObserver()) {
    polyfills.push(import('@juggle/resize-observer'));
  }

  return Promise.all(polyfills);
}
