import { useState, useCallback, memo, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isIE from 'znipe-utils/web/isIE';
import Card from './components/Card';
import CardTitle from './components/CardTitle';
import { Container, Block, ContentWrapper } from './BlockContainer.styles';

const BlockContainer = forwardRef(
  (
    {
      children,
      hideOverflow = false,
      isFlippable = false,
      alignMiddle = false,
      noPadding = false,
      defaultFlipState = false,
      icon = '',
      title = '',
      height = null,
      size = 'small',
    },
    ref,
  ) => {
    const [isFlipped, setIsFlipped] = useState(defaultFlipState);
    const browserIsIE = isIE();

    useEffect(() => {
      setIsFlipped(defaultFlipState);
    }, [defaultFlipState]);

    const handleFlip = useCallback(() => {
      setIsFlipped(prevState => !prevState);
    }, []);

    if (isFlippable && !browserIsIE)
      return (
        <Container ref={ref} data-testid="content-block">
          <Block
            data-testid={`showing-${isFlipped ? 'info' : 'data'}`}
            flipStatus={isFlipped}
            height={height}
          >
            <Card position="front">
              {title && (
                <CardTitle
                  icon={icon}
                  title={title}
                  size={size}
                  position="front"
                  onClick={handleFlip}
                />
              )}
              <Content hideOverflow={hideOverflow} alignMiddle={alignMiddle} noPadding={noPadding}>
                {children[0]}
              </Content>
            </Card>
            <Card position="back">
              {title && (
                <CardTitle
                  icon={icon}
                  title={title}
                  size={size}
                  position="back"
                  onClick={handleFlip}
                />
              )}
              <Content hideOverflow={hideOverflow} alignMiddle={alignMiddle} noPadding={noPadding}>
                {children[1]}
              </Content>
            </Card>
          </Block>
        </Container>
      );

    return (
      <Block data-testid="content-block" height={height} ref={ref}>
        {title && <CardTitle icon={icon} title={title} size={size} />}
        <Content hideOverflow={hideOverflow} alignMiddle={alignMiddle} noPadding={noPadding}>
          {children}
        </Content>
      </Block>
    );
  },
);

const Content = ({ hideOverflow = false, children, alignMiddle = false, noPadding = false }) => (
  <ContentWrapper
    data-testid="content-wrapper"
    hideOverflow={hideOverflow}
    alignMiddle={alignMiddle}
    noPadding={noPadding}
  >
    {children}
  </ContentWrapper>
);

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  hideOverflow: PropTypes.bool,
  alignMiddle: PropTypes.bool,
  noPadding: PropTypes.bool,
};

BlockContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  hideOverflow: PropTypes.bool,
  defaultFlipState: PropTypes.bool,
  alignMiddle: PropTypes.bool,
  noPadding: PropTypes.bool,
  isFlippable: PropTypes.bool,
  height: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default memo(BlockContainer);
