import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, StyledButton } from '../NavButton.styles';

const iconSize = { small: 16, medium: 16, large: 24 };

const RoundNavButton = memo(
  ({ id, label, iconType, isToggledOn, onClick, testid, size, showLabel }) => (
    <Container id={id} data-testid={`nav-button-${testid}`} $size={size}>
      <StyledButton
        onClick={onClick}
        $isToggledOn={isToggledOn}
        data-testid={`nav-button-${iconType}`}
        aria-label={label || iconType}
        $showingLabel={showLabel && label}
        $size={size}
      >
        <Icon type={iconType} size={iconSize[size]} fillColor="currentColor" inline />
      </StyledButton>
      {label && showLabel && (
        <Typography
          color="UIAccent"
          dataTestId="label"
          type={size === 'large' ? 'title-s' : 'title-xs'}
        >
          {label}
        </Typography>
      )}
    </Container>
  ),
);

RoundNavButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isToggledOn: PropTypes.bool,
  testid: PropTypes.string,
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
};

export default RoundNavButton;
