import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import { ReactNode, useCallback, useMemo } from 'react';
import colors from 'znipe-styles/colors';
import isTouchDevice from 'znipe-utils/web/isTouchDevice';
import Avatar from '../Avatar/Avatar';
import useChatContext from '../../hooks/useChatContext';
import { Label, Username } from './UserLabel.styles';

type UserLabelProps = {
  username: string;
  userId?: string;
  color?: string;
  isModerator?: boolean;
  signifierIds?: string[];
  badges?: string[];
  messageId?: string;
};

const UserLabel: React.FC<UserLabelProps> = ({
  username,
  userId,
  color = colors.grey53,
  isModerator = false,
  signifierIds,
  badges,
  messageId,
}) => {
  const { chatSignifiers, onUsernameClick, avatars } = useChatContext();
  const clickable = typeof onUsernameClick === 'function';
  const handleLabelClick =
    clickable && userId
      ? (e: React.MouseEvent<HTMLElement>) => {
          const target = e.target as HTMLElement;
          const getBoundingClientRect = () => target.getBoundingClientRect();
          onUsernameClick({
            username,
            userId,
            color,
            getBoundingClientRect,
            messageId,
          });
        }
      : undefined;
  const signifiers = useMemo(() => {
    if (!signifierIds?.length) return null;
    return signifierIds?.reduce((acc: ReactNode[], signifierId: string) => {
      const image = chatSignifiers?.find(({ humanReadableId }) => humanReadableId === signifierId);
      return image
        ? [
            ...acc,
            signifierId === 'moderator' ? (
              <Avatar src={image?.url} size="small" borderType="none" key={signifierId} />
            ) : (
              <ToolTip size="small" label={image?.displayName}>
                <Avatar src={image?.url} size="small" borderType="none" key={signifierId} />
              </ToolTip>
            ),
          ]
        : acc;
    }, []);
  }, [signifierIds, chatSignifiers]);

  const labelByBadge = useCallback(
    (badgeUrl: string) => {
      const avatar = avatars?.filter(a => !a.default).find(a => a.img === badgeUrl);
      return avatar?.label ?? avatar?.shortLabel ?? '';
    },
    [avatars],
  );

  const labelNode = (
    <Label $isModerator={isModerator}>
      {signifiers}
      <Username
        forwardedAs="span"
        type={isTouchDevice() ? 'heading-s' : 'heading-xs'}
        $color={color}
        $clickable={clickable}
        onClick={handleLabelClick}
      >
        {username}
      </Username>
      {badges?.map(imageSrc =>
        labelByBadge(imageSrc) ? (
          <ToolTip size="small" label={labelByBadge(imageSrc)}>
            <Avatar src={imageSrc} size="medium" borderType="none" key={imageSrc} />
          </ToolTip>
        ) : (
          <Avatar src={imageSrc} size="medium" borderType="none" key={imageSrc} />
        ),
      )}
    </Label>
  );
  if (!isModerator) return labelNode;
  return <ToolTip label="moderator">{labelNode}</ToolTip>;
};

export default UserLabel;
