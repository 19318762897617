import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import usePackageName from 'tv-hooks/usePackageName';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import Typography from 'znipe-elements/general/Typography/Typography';
import InputField from 'znipe-elements/data-entry/InputField/InputField';
import { NoResultsWrapper, LoadingWrapper, SearchContainer } from './Sidebar.styles';

const singletonLoadingComp = <LoadingSpinner fillColor="white" position="relative" />;

const Search = ({ onSearchChange = () => {}, noResultsFound = false, loading = false }) => {
  const packageName = usePackageName();
  const handleDeleteClick = useCallback(() => {
    onSearchChange('');
  }, [onSearchChange]);

  const searchPlaceholderText = useMemo(() => {
    if (packageName === 'proview') {
      return 'Players, teams, champions...';
    }
    return 'Search topics';
  }, [packageName]);

  return (
    <>
      <SearchContainer data-testid="search-container">
        <InputField
          placeholder={searchPlaceholderText}
          onSearchChange={onSearchChange}
          onClickDelete={handleDeleteClick}
          size="medium"
        />
        {loading && (
          <LoadingWrapper data-testid="loading-wrapper">{singletonLoadingComp}</LoadingWrapper>
        )}
      </SearchContainer>
      {!loading && noResultsFound && (
        <NoResultsWrapper data-testid="no-results">
          <Typography type="heading-s">No results found</Typography>
        </NoResultsWrapper>
      )}
    </>
  );
};

Search.propTypes = {
  onSearchChange: PropTypes.func,
  noResultsFound: PropTypes.bool,
  loading: PropTypes.bool,
};

export default memo(Search);
