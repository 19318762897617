import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { useMemo, useEffect, useCallback } from 'react';
import { useGamePrediction } from 'tv-selectors/predictions/makeGetGamePredictions';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { updatePredictions } from 'tv-actions/old/predictions';
import { useDispatch } from 'react-redux';
import PredictionOutcomeCard from './components/PredictionOutcome/PredictionOutcomeCard';

const usePredictionOutcomeCard = gatId => {
  const userId = useUserId();
  const dispatch = useDispatch();
  const gamePredictions = useGamePrediction({ gatId });

  const handleValue = useCallback(
    snapshot => {
      const value = snapshot.val();
      dispatch(updatePredictions(value, gatId));
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    [dispatch, gatId],
  );

  useEffect(() => {
    const ref = firebase.database().ref(`/predictions/${userId}/${gatId}/`);
    ref.on('value', handleValue);

    return () => ref.off('value', handleValue);
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [userId, gatId, handleValue]);

  return useMemo(
    () =>
      Object.values(gamePredictions).map(prediction => (
        <PredictionOutcomeCard prediction={prediction} size="small" key={prediction.id} />
      )),
    [gamePredictions],
  );
};

export default usePredictionOutcomeCard;
