import deepmerge from 'deepmerge';
import { handleActions } from 'redux-actions';

import {
  GET_PRODUCT_V4_PRICE_SUCCESS,
  GET_PRODUCT_V4_REQUEST,
  GET_PRODUCT_V4_SUCCESS,
  GET_PRODUCT_V4_FAILURE,
  GET_PRODUCTS_V4_SUCCESS,
} from 'tv-constants/oldRedux';

export const initialState = {};
const RESET_STATE = 'RESET_STATE';

export default handleActions(
  {
    [GET_PRODUCT_V4_PRICE_SUCCESS]: (state, action) => {
      const { response } = action;
      const { results = {} } = response;
      const { productId } = results;
      return deepmerge(state, {
        [productId]: {
          userPrice: results,
        },
      });
    },
    [GET_PRODUCTS_V4_SUCCESS]: (state, action) => {
      const { response } = action;
      const { results = {} } = response;
      const { products = [] } = results;
      return deepmerge(
        state,
        products.reduce(
          (acc, product) => ({
            ...acc,
            [product.id]: product,
          }),
          {},
        ),
      );
    },
    [GET_PRODUCT_V4_REQUEST]: (state, action) => {
      const { payload = {} } = action;
      const { productId } = payload;
      if (!productId) return state;
      return deepmerge(state, {
        [productId]: {
          status: 'request',
        },
      });
    },
    [GET_PRODUCT_V4_FAILURE]: (state, action) => {
      const { payload = {} } = action;
      const { productId } = payload;
      if (!productId) return state;
      return deepmerge(state, {
        [productId]: {
          status: 'failure',
        },
      });
    },
    [GET_PRODUCT_V4_SUCCESS]: (state, action) => {
      const { response } = action;
      const { status, results = {} } = response;
      if (status !== 'ok') return state;
      const { product } = results;
      if (!product) return state;
      return deepmerge(state, {
        [product.id]: {
          status: 'complete',
          ...product,
        },
      });
    },
    [RESET_STATE]: () => initialState,
  },
  initialState,
);
