import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import usePageColors from 'znipe-hooks/usePageColors';
import colors from 'znipe-styles/colors';
import getColorsFromGradientString from 'znipe-utils/misc/getColorsFromGradientString';

import useThemeContext from 'znipe-hooks/useThemeContext';
import { StyledLink, StyledButton, Wrapper, ButtonTypography } from './Button.styles';
import Shimmer from './Shimmer';
import { FeatureVariant, TextVariant } from './Borders';

const types = {
  xsmall: 'title-xs',
  small: 'title-m',
  medium: 'title-l',
  large: 'title-xl',
};

const iconSizes = {
  xsmall: 16,
  small: 16,
  medium: 24,
  large: 24,
};

const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  onClick,
  iconPosition = 'left',
  icon,
  iconGradient,
  iconColor,
  to,
  'data-testid': testId = 'button',
  solidColor,
  textColor,
  disabled = false,
  shimmer = false,
  color,
  square = false,
  type,
  isShakeActivated = false,
  textTransform,
  fluid = false,
  customWidth,
  lightness,
}) => {
  const [hovering, setIsHovering] = useState(false);
  const { buttonPrimary } = useThemeContext();
  const { hoverGradient, activeGradient, rootColor } = usePageColors();
  const Component = useMemo(() => (to ? StyledLink : StyledButton), [to]);

  const iconColors = useMemo(() => {
    const gradientColor = getColorsFromGradientString(iconGradient);
    if (iconGradient && gradientColor.length > 0)
      return { fill: gradientColor[0], active: gradientColor[1] };

    return {
      fill: iconColor ?? colors.white,
      active: null,
    };
  }, [iconGradient, iconColor]);

  const onMouseEnter = useCallback(() => setIsHovering(true), []);
  const onMouseLeave = useCallback(() => setIsHovering(false), []);

  return (
    <Component
      data-testid={testId}
      type={type}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      to={disabled ? undefined : to}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      $size={size}
      $variant={variant}
      $activeGradient={activeGradient}
      $hoverGradient={hoverGradient}
      $color={color || rootColor}
      $solidColor={solidColor ?? buttonPrimary ?? 'UIAccent'}
      $textColor={textColor}
      $compact={!children}
      $isShakeActivated={isShakeActivated}
      $fluid={fluid}
      $customWidth={customWidth}
      $square={square}
      $lightness={lightness || -14}
    >
      {variant === 'text' && <TextVariant hovering={hovering} color={textColor} />}
      {variant === 'feature' && <FeatureVariant size={size} />}
      {shimmer && <Shimmer enabled={shimmer} />}
      <Wrapper
        data-testid="button-wrapper"
        $iconShadow={!!icon && variant === 'feature'}
        $color={color || rootColor}
        $iconPosition={iconPosition}
      >
        {icon && (
          <Icon
            type={icon}
            fillColor={iconColors.fill}
            gradient={!!iconGradient}
            activeColor={iconColors.active}
            size={iconSizes[size]}
            inline
          />
        )}
        {children && (
          <ButtonTypography $textTransform={textTransform} type={types[size]} forwardedAs="span">
            {children}
          </ButtonTypography>
        )}
      </Wrapper>
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  iconGradient: PropTypes.string,
  iconColor: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  variant: PropTypes.oneOf(['feature', 'primary', 'secondary', 'text', 'solid-color']),
  solidColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  square: PropTypes.bool,
  'data-testid': PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]),
  disabled: PropTypes.bool,
  shimmer: PropTypes.bool,
  isShakeActivated: PropTypes.bool,
  textTransform: PropTypes.oneOf([
    'none',
    'capitalize',
    'uppercase',
    'lowercase',
    'initial',
    'inherit',
  ]),
  color: PropTypes.string,
  fluid: PropTypes.bool,
  customWidth: PropTypes.string,
  lightness: PropTypes.number,
};

export default Button;
