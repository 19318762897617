import { memo } from 'react';
import PropTypes from 'prop-types';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import { StyledLink } from './Subscribe.styles';

const BackButton = ({ children, to }) => {
  const { subscribe = {} } = useThemeContext();
  return (
    <StyledLink data-testid="back-button" to={to}>
      <Icon type="chevronLeft" inline fillColor={subscribe.backColor} />
      {children}
    </StyledLink>
  );
};

BackButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]).isRequired,
};

export default memo(BackButton);
