import { useCallback } from 'react';
import { EMOJI_TAG_REGEX } from '../ChatLockup.constants';
import type { StringTypes } from '../ChatLockup.types';
import useCalculateStringTypesLength from './useCalculateStringTypeLength';
import useIsValidEmoji from './useIsValidEmoji';
import createStringTypes from '../utils/createStringTypes';

const usePasteMessage = (
  message: string,
  characterLimit: number,
  onPaste: (data: StringTypes, forceUpdate?: boolean) => void,
  storeCaretPosition: (offset?: number) => void,
) => {
  const calculateStringTypeLength = useCalculateStringTypesLength();
  const isValidEmoji = useIsValidEmoji();

  return useCallback(
    (event: React.ClipboardEvent) => {
      storeCaretPosition();
      event.stopPropagation();
      event.preventDefault();

      const { clipboardData } = event;
      const pasteLimit = characterLimit - message.length;
      if (pasteLimit < 1) return;
      const clipboard = clipboardData?.getData('text/plain');
      const originalPaste = clipboard
        .replace(/\s(?!:)/g, ' ')
        .replace(/\s(?=:)/g, '')
        .trim();

      const pastedData = originalPaste.substring(0, pasteLimit);

      const regex = /(:|\s)[^\s:]*$/;
      const possibleEndEmojiMatches = pastedData.match(regex);
      const pasteData = createStringTypes(pastedData);
      const pastedDataLength = calculateStringTypeLength(pasteData);

      if (!possibleEndEmojiMatches?.length || possibleEndEmojiMatches[0].charAt(0) !== ':') {
        storeCaretPosition(pastedDataLength);
        onPaste(pasteData, true);
        return;
      }

      const endSection = originalPaste.substring(possibleEndEmojiMatches.index || 0);

      const indexOfEndSection = endSection.search(/(\s|:)/);
      const lastEntry = endSection.substring(
        0,
        indexOfEndSection > 0 ? indexOfEndSection + 1 : undefined,
      );

      const isLastEmoji = lastEntry.match(EMOJI_TAG_REGEX);

      const lastEmojiIsValid = !!isLastEmoji?.length && isValidEmoji(isLastEmoji[0]);

      if (!lastEmojiIsValid) {
        storeCaretPosition(pastedDataLength);
        onPaste(pasteData, true);
        return;
      }

      const pasteDataWithAppendedContent = createStringTypes(
        `${pastedData.substring(0, possibleEndEmojiMatches.index)}${lastEntry}`,
      );
      storeCaretPosition(calculateStringTypeLength(pasteDataWithAppendedContent));
      onPaste(pasteDataWithAppendedContent, true);
    },
    [storeCaretPosition, onPaste, characterLimit, message, isValidEmoji, calculateStringTypeLength],
  );
};

export default usePasteMessage;
