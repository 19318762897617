import { useState, useEffect, useMemo, useRef } from 'react';

export const LOADING_DELAY_MS = 2000;

/**
 * A hook to delay the final rendering of a component to help prevent flashing
 * @param {boolean} loadingState The current state of the element to be loaded
 * @param {number} loadingDelay The initial delay in ms
 * @param {boolean} readyAfterDelay If it should always return ready after the delay
 * @returns {boolean}
 */
const useLoadingDelay = (
  loadingState = false,
  loadingDelay = LOADING_DELAY_MS,
  readyAfterDelay = false,
) => {
  const [delayDone, setDelayDone] = useState(false);
  const timeRef = useRef(0);

  const isReady = useMemo(() => {
    const currentTime = Date.now();

    if (currentTime - timeRef.current < 100) {
      return !!loadingState;
    }

    return delayDone && (!!loadingState || readyAfterDelay);
  }, [delayDone, loadingState, readyAfterDelay]);

  useEffect(() => {
    timeRef.current = Date.now();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isReady) setDelayDone(true);
    }, loadingDelay);
    return () => clearTimeout(timer);
  }, [loadingDelay, isReady]);

  return isReady;
};

export default useLoadingDelay;
