import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: 16px;
`;

const CardContent = ({ children = null }) => <Container>{children}</Container>;

CardContent.propTypes = {
  children: PropTypes.node,
};

export default CardContent;
