import { useEffect, useCallback } from 'react';

const MIN_NUDGE = 0.5;
const DEFAULT_NUDGE = 6;

/*
 * Some users are having problems with the streams freezing from time to time, and some users to dont have the issue at
 * all. This is a hook to detect and unfreeze the stream for these users.
 */
const useAntiFreeze = player => {
  // The streams seems to unfreeze when we change the current time by seeking.
  const nudge = useCallback(() => {
    if (!player) return;
    const video = player.getMediaElement();
    if (!video) return;
    const { end } = player.seekRange() || {};
    const maxSeek = end - video.currentTime;
    // We try to seek for 0.5 - 6s depending on how close we are to live.
    const diff = Math.max(Math.min(maxSeek, DEFAULT_NUDGE), MIN_NUDGE);
    video.currentTime += diff;
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [player]);

  // One way of detecting the freeze is if it buffers for more than 6s.
  useEffect(() => {
    if (!player) return () => {};
    let timeout = null;
    const handleBuffering = ({ buffering }) => {
      if (!buffering) {
        clearTimeout(timeout);
        return;
      }
      timeout = setTimeout(() => {
        nudge();
      }, DEFAULT_NUDGE * 1000);
    };

    player.addEventListener('buffering', handleBuffering);

    return () => {
      player.removeEventListener('buffering', handleBuffering);
      clearTimeout(timeout);
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [player, nudge]);

  // Another way of detecting a freeze is to check if the time has not changed for 6s
  useEffect(() => {
    if (!player) return () => {};
    const video = player.getMediaElement();
    if (!video) return () => {};
    let timeout = null;
    const handleTimeUpdate = () => {
      const { currentTime } = video;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (video.paused) return;
        if (video.currentTime === currentTime) {
          nudge();
        }
      }, DEFAULT_NUDGE * 1000);
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      clearTimeout(timeout);
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [player, nudge]);
};

export default useAntiFreeze;
