import styled from 'styled-components';
import { Container } from '../DeadPlayerInfo/EliminatedInfo/EliminatedInfo.styles';

export const Stats = styled(Container)`
  margin-top: 24px;
  transform: translateY(100%);
  box-shadow: none;
  background: transparent;
`;

export const StatsSection = styled.div`
  display: flex;
  gap: ${({ $size }) => {
    if ($size === 'xsmall') return 4;
    if ($size === 'small') return 8;
    return 16;
  }}px;
`;
