import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  width: 100%;
  @media ${device.tablet} {
    width: 500px;
  }
  @media ${device.desktop} {
    width: 630px;
  }
`;

export const Padding = styled.div`
  margin-bottom: 17px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  @media ${device.mobilePortrait} {
    bottom: 0;
  }
  @media ${device.laptop} {
    margin: 20px 0 0 auto;
    position: relative;
    left: initial;
    bottom: initial;
    transform: none;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  gap: 22px 0px;
  width: 290px;
  margin: 30px 0;
  @media ${device.laptop} {
    width: fit-content;
  }
`;

export const Item = styled.div`
  grid-column-end: span 2;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media ${device.laptop} {
    flex-direction: row;
  }
`;
