import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'znipe-elements/general/Icon/Icon';
import VersusIcon from 'znipe-elements/data-display/VersusIcon/VersusIcon';
import TeamLogo from 'znipe-elements/data-display/TeamLogo/TeamLogo';

const fullDimensions = {
  small: '68px',
  large: '88px',
};

const TeamVsTeamVerticalContainer = styled.div`
  position: relative;
  width: ${({ $size }) => fullDimensions[$size]};
  height: ${({ $size }) => fullDimensions[$size]};
`;

const VersusContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const armTop = {
  small: '18px',
  large: '22px',
};

const ArmTopContainer = styled.div`
  position: absolute;
  top: 4px;
  right: ${({ $size }) => armTop[$size]};
  height: 25%;
`;

const armBottom = {
  small: '12px',
  large: '18px',
};

const ArmBottomContainer = styled.div`
  position: absolute;
  bottom: 4px;
  left: ${({ $size }) => armBottom[$size]};
  height: 25%;
`;

const logoPosition = {
  small: '-4px',
  large: '-16px',
};

const logoTwoPosition = {
  small: '-2px',
  large: '-12px',
};

const TeamLogoOneContainer = styled.div`
  position: absolute;
  top: ${({ $size }) => logoPosition[$size]};
  left: ${({ $size }) => logoPosition[$size]};
`;

const TeamLogoTwoContainer = styled.div`
  position: absolute;
  bottom: ${({ $size }) => logoTwoPosition[$size]};
  right: ${({ $size }) => logoTwoPosition[$size]};
`;

const VersusIconOpacityComponent = styled.div`
  ${({ $versusIconOpacity }) => `opacity: ${$versusIconOpacity}`}
`;

const logoSize = size => {
  switch (size) {
    case 'large':
      return 'medium';
    default:
      return size;
  }
};

const TeamVsTeamVertical = ({ teamLogo1 = '', teamLogo2 = '', size, versusIconOpacity = '1' }) => (
  <TeamVsTeamVerticalContainer $size={size}>
    <TeamLogoOneContainer $size={size}>
      <TeamLogo logo={teamLogo1} useLazyLoading={false} size={logoSize(size)} />
    </TeamLogoOneContainer>
    <VersusContainer>
      <ArmBottomContainer $size={size}>
        <Icon type="armBottom" />
      </ArmBottomContainer>
      <VersusIconOpacityComponent $versusIconOpacity={versusIconOpacity}>
        <VersusIcon size={size} opacity={versusIconOpacity} />
      </VersusIconOpacityComponent>
      <ArmTopContainer $size={size}>
        <Icon type="armTop" />
      </ArmTopContainer>
    </VersusContainer>
    <TeamLogoTwoContainer $size={size}>
      <TeamLogo logo={teamLogo2} useLazyLoading={false} size={logoSize(size)} />
    </TeamLogoTwoContainer>
  </TeamVsTeamVerticalContainer>
);

TeamVsTeamVertical.propTypes = {
  teamLogo1: PropTypes.string,
  teamLogo2: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  versusIconOpacity: PropTypes.string,
};

export default TeamVsTeamVertical;
