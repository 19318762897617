import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ErrorPage from 'tv-modules/Payment/ErrorPage/ErrorPage';
import SuccessPage from 'tv-modules/Payment/SuccessPage/SuccessPage';
import SuccessImage from 'tv-modules/Payment/SuccessPage/SuccessImage';
import ErrorImage from 'tv-modules/Payment/ErrorPage/CardError/ErrorImage';

export const successText = "We're sorry to see you go, and hope to see you again soon!";
export const errorText =
  'Sorry, there was a technical error processing your request. Please contact support or try again later.';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const UpdateCardSuccessOrError = ({
  buttonText = 'BACK TO MY PRO VIEW',
  to = '',
  isSuccess = true,
}) => {
  const id = isSuccess ? 'card-confirmed' : 'card-error';
  return (
    <Container data-testid={`${id}-container`}>
      {isSuccess ? (
        <SuccessPage
          description="You have successfully updated your payment method."
          image={SuccessImage}
          buttonLabel={buttonText}
          to={to}
        />
      ) : (
        <ErrorPage
          description="Unfortunately the provided card has been declined. Please double-check the entered information or try another card."
          image={ErrorImage}
          buttonLabel={buttonText}
          to={to}
        />
      )}
    </Container>
  );
};

UpdateCardSuccessOrError.propTypes = {
  buttonText: PropTypes.string,
  isSuccess: PropTypes.bool,
  to: PropTypes.string,
};

export default memo(UpdateCardSuccessOrError);
