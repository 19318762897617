import { useEffect, useState } from 'react';
import Icon from 'znipe-elements/general/Icon/Icon';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import {
  Container,
  Button,
  MoreEmojisButton,
  TRANSITION_DURATION,
} from './EmojiQuickSelect.styles';
import EmojiTile from '../EmojiTile/EmojiTile';

type EmojiQuickSelectProps = {
  isOpen: boolean;
  onSelect: (shortcode: string, action: string, trigger: string) => void;
  onClickMore: () => void;
};

const EmojiQuickSelect: React.FC<EmojiQuickSelectProps> = ({ isOpen, onSelect, onClickMore }) => {
  const { quickSelectEmojis = [] } = useChatContext() ?? {};
  const [hideOverflow, setHideOverflow] = useState(true);
  useEffect(() => {
    setTimeout(() => setHideOverflow(!isOpen), isOpen ? TRANSITION_DURATION : 0);
  }, [isOpen]);
  return (
    <Container $isOpen={isOpen} $hideOverflow={hideOverflow}>
      {quickSelectEmojis.map(({ url, shortcode }) => (
        <Button key={shortcode} onClick={() => onSelect(shortcode, 'emoji_select', 'quick_select')}>
          <EmojiTile size="medium" src={url} label={`:${shortcode}:`} as="div" />
        </Button>
      ))}
      <ToolTip label="more emojis">
        <MoreEmojisButton onClick={onClickMore} data-testid="more-emojis-button">
          <Icon type="moreVertical" size={24} inline fillColor="white" />
        </MoreEmojisButton>
      </ToolTip>
    </Container>
  );
};

export default EmojiQuickSelect;
