import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(${({ theme }) => convertHexToRGBDigits(theme.primaryBackgroundColor)}, 0.05);
  backdrop-filter: blur(110px);
  position: absolute;
  z-index: 1;
`;

export const BackButtonWrpaper = styled.div.attrs(() => ({
  type: 'button',
}))`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  @media ${device.tablet} {
    top: 16px;
    left: 16px;
  }
`;

export const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ $hasBorder }) =>
    $hasBorder &&
    css`
      position: absolute;
      width: 602px;
      height: 360px;
      border: 1px solid ${colors.grey30};
      border-radius: 3px;
    `}
`;
