import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Popup from 'tv-modules/Onboarding/Popup/Popup';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import colors from 'znipe-styles/colors';
import Typography from 'znipe-elements/general/Typography/Typography';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import ImageSkeleton from 'znipe-elements/feedback/ImageSkeleton/ImageSkeleton';
import useHasMatchStarted from 'tv-hooks/useHasMatchStarted';
import { highlightPopupInfo, multiPovPopupInfo } from 'tv-routes/Stage/Stage.constants';
import {
  Container,
  ItemsWrapper,
  NavItem,
  NavIcon,
  NavLabel,
  StatsButtonWrapper,
  NavItemLoadingWrapper,
} from './StageMiniNavbar.styles';

export const navItems = [
  {
    label: 'games',
    fillColor: colors.white,
    gradiantColor: colors.bubblePink,
    iconType: 'list',
  },
  /* {
    label: 'rooms',
    fillColor: colors.white,
    gradiantColor: colors.beanGreen,
    iconType: 'headphones',
  }, */
  {
    label: 'chat',
    fillColor: colors.white,
    gradiantColor: colors.beanGreen,
    iconType: 'chat',
    // onboardingPopup: {
    //   ...chatPopupInfo,
    //   offset: 800,
    // },
  },
  {
    label: 'highlights',
    fillColor: colors.white,
    gradiantColor: colors.pastelYellow,
    iconType: 'fire',
    onboardingPopup: {
      ...highlightPopupInfo,
      offset: 30,
    },
  },
  {
    label: 'streams',
    fillColor: colors.white,
    gradiantColor: colors.tooBlue,
    iconType: 'streamFilled',
    onboardingPopup: {
      ...multiPovPopupInfo,
      offset: 50,
    },
  },
];

const NavItemLoading = (
  <NavItemLoadingWrapper>
    <ImageSkeleton size={24} />
    <TextSkeleton height={10} width={60} />
  </NavItemLoadingWrapper>
);

const StageMiniNavbar = ({
  matchId,
  withStatsButton = false,
  onItemSelect = () => {},
  onStatsButtonClick,
  size = 'small',
  isLoading = false,
}) => {
  const medium = size === 'medium';
  const matchStarted = useHasMatchStarted(matchId);
  return (
    <Container $medium={medium} $compact={!withStatsButton}>
      <ItemsWrapper $textVisible={!withStatsButton}>
        {navItems.map(item => {
          if (isLoading) return NavItemLoading;
          const popupinfo = item.onboardingPopup || {};
          const navElement = (
            <NavItem
              $inline={medium}
              mediumSize={!withStatsButton}
              onClick={() => onItemSelect(item.label)}
              key={item.iconType}
            >
              <NavIcon data-testid="nav-icon-wrapper" $shadow={item.gradiantColor}>
                <Icon
                  type={item.iconType}
                  size={medium ? 24 : 16}
                  fillColor={item.fillColor}
                  activeColor={item.gradiantColor}
                  gradient
                />
              </NavIcon>
              <NavLabel data-testid="nav-label" $visible={!withStatsButton} $inline={medium}>
                <Typography type={medium ? 'title-s' : 'title-xs'} color="secondaryTextColor">
                  {item.label}
                </Typography>
              </NavLabel>
            </NavItem>
          );
          if (!popupinfo.popupId) return navElement;
          return (
            <Popup
              key={item.iconType}
              popupId={popupinfo.popupId}
              headline={popupinfo.headline}
              description={popupinfo.description}
              timer={popupinfo.timer}
              direction="up"
              offset={withStatsButton ? popupinfo.offset || 0 : 0}
              disabled={
                !popupinfo.popupId || (popupinfo.popupId === 'highlights-popup' && !matchStarted)
              }
            >
              {navElement}
            </Popup>
          );
        })}
      </ItemsWrapper>
      <StatsButtonWrapper $visible={withStatsButton} data-testid="stats-button-wrapper">
        <NavButton
          iconType="stats"
          onClick={onStatsButtonClick}
          size={medium ? 'large' : 'medium'}
        />
      </StatsButtonWrapper>
    </Container>
  );
};

StageMiniNavbar.propTypes = {
  matchId: PropTypes.string.isRequired,
  withStatsButton: PropTypes.bool,
  onItemSelect: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  onStatsButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default memo(StageMiniNavbar);
