import { useCallback, useEffect, useRef } from 'react';
import fitty from 'fitty';

const useCreateResizeText = ({ minSize = 6, maxSize = 40, multiLine = false } = {}) => {
  const fittyRef = useRef();

  useEffect(() => () => fittyRef.current?.unsubscribe?.(), []);

  return useCallback(
    node => {
      if (node !== null) {
        fittyRef.current?.unsubscribe?.();
        fittyRef.current = fitty(node, { minSize, maxSize, multiLine });
        return fittyRef.current;
      }
      return undefined;
    },
    [minSize, maxSize, multiLine],
  );
};

export default useCreateResizeText;
