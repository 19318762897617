import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const ClickWrapper = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-left: auto;
  white-space: nowrap;

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;

export const GradientContainer = styled.div`
  padding: 2px;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 3px;
  ${({ $margin }) => css`
    margin: ${$margin};
  `}
`;
