import { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import Icon from 'znipe-elements/general/Icon/Icon';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import OverlaySettings from 'tv-modules/OverlaySettings/OverlaySettings';
import Controls from 'tv-modules/Player/Controls/Controls';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import { useMute } from 'tv-selectors/control/makeGetMute';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import InformationHeader from './components/InformationHeader';
import CloseHeader from './components/CloseHeader';
import MobileVideoCompleted from './components/MobileVideoCompleted';
import themes from '../../ScreenOverlay.themes';
import {
  Content,
  Header,
  HeaderLeftWrapper,
  HeaderPlayerActionsWrapper,
  HeaderIconWrapper,
  MainContent,
  SettingMenuWrapper,
} from '../../ScreenOverlay.styles';

import { Container } from './ScreenControls.styles';
import MobileVideoControls from './components/MobileVideoControls';

const ScreenControls = ({
  title = '',
  description = '',
  isLandscape = false,
  onStatsClick,
  onStreamClick = () => {},
  onClose = null,
  languages = [],
  playerRef = playerDefaultProps,
  playerContainerRef = { current: null },
  isVisible = false,
  togglePersitentHoverState = () => {},
  handlePropagation = () => {},
  matchId = '',
  hasFinishedPlaying = false,
  playlistAutoplayingEnabled = false,
  onWatchNext,
  onAutoplayCancel,
  onWatchAgain,
  onSeekDone = () => {},
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [settingsIconAnimation, setSettingsIconAnimation] = useState(null);
  const [playing, setPlaying] = useState(false);
  const isClosingSettings = useRef(false);
  const settingsTimer = useRef(null);
  const masterMuted = useMute();
  const [mute, setMute] = useState(masterMuted);
  const isTabletOrGreater = useGetIsTabletOrGreater();

  const handleClickMenu = useCallback(() => setSettingsIconAnimation('openSub'), []);

  const handleClickSubMenu = useCallback(() => setSettingsIconAnimation('closeSub'), []);

  const handleClickSettings = useCallback(
    e => {
      handlePropagation(e);
      if (!isClosingSettings.current) {
        setIsSettingsVisible(!isSettingsVisible);
        togglePersitentHoverState(!isSettingsVisible);
        setIsSettingsOpen(!isSettingsOpen);
      }
    },
    [isSettingsOpen, isSettingsVisible, handlePropagation, togglePersitentHoverState],
  );

  const onOutsideClick = useCallback(() => {
    setIsSettingsOpen(false);
    setIsSettingsVisible(!isSettingsVisible);
    togglePersitentHoverState(!isSettingsOpen);
    isClosingSettings.current = !isClosingSettings.current;
    settingsTimer.current = setTimeout(() => {
      isClosingSettings.current = !isClosingSettings.current;
    }, 100);
  }, [isSettingsOpen, isSettingsVisible, togglePersitentHoverState]);

  const settingsRef = useOutsideClick(onOutsideClick);

  const togglePlayPause = useCallback(
    e => {
      handlePropagation(e);
      const player = playerRef.current;
      togglePersitentHoverState(!player.isPaused());
      if (player.isPaused()) player.play();
      else player.pause();
    },
    [playerRef, handlePropagation, togglePersitentHoverState],
  );

  const toggleMute = useCallback(
    e => {
      handlePropagation(e);
      const player = playerRef.current;
      if (player.isMuted()) {
        player.unmute();
      } else {
        player.mute();
      }
      setMute(!mute);
    },
    [playerRef, mute, handlePropagation],
  );

  const handleSkipTimeClick = useCallback(
    (type, e) => {
      handlePropagation(e);
      const player = playerRef.current;
      if (!player) return;
      const newTime =
        type === 'rewind' ? player.getCurrentTime() - 10 : player.getCurrentTime() + 10;
      player.seek(newTime);
      onSeekDone();
    },
    [playerRef, handlePropagation, onSeekDone],
  );

  const handleSpacebarPress = useCallback(
    event => {
      if (event.keyCode === 32) {
        togglePlayPause();
      }
    },
    [togglePlayPause],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleSpacebarPress);
    return () => {
      window.removeEventListener('keydown', handleSpacebarPress);
    };
  }, [handleSpacebarPress]);

  useEffect(() => {
    setMute(masterMuted);
  }, [masterMuted]);

  useEffect(
    () => () => {
      clearTimeout(settingsTimer.current);
    },
    [],
  );

  // Interval to check if value was set from somewhere else
  useEffect(() => {
    const player = playerRef?.current;
    if (!player) return () => {};
    setPlaying(!player.isPaused());
    const onPlay = () => setPlaying(true);
    const onPause = () => setPlaying(false);
    player.addEventListener('play', onPlay);
    player.addEventListener('playing', onPlay);
    player.addEventListener('pause', onPause);

    return () => {
      player.removeEventListener('play', onPlay);
      player.removeEventListener('playing', onPlay);
      player.removeEventListener('pause', onPause);
    };
  }, [playerRef]);

  const onRewind = useCallback(e => handleSkipTimeClick('rewind', e), [handleSkipTimeClick]);
  const onSkip = useCallback(e => handleSkipTimeClick('skip', e), [handleSkipTimeClick]);

  return (
    <Container data-testid="screen-controls">
      {isSettingsOpen && (
        <SettingMenuWrapper onClick={handlePropagation} ref={settingsRef}>
          <OverlaySettings
            languages={languages}
            visible={isSettingsVisible}
            onClickMenu={handleClickMenu}
            onClickSubMenu={handleClickSubMenu}
            playerRef={playerRef}
          />
        </SettingMenuWrapper>
      )}
      <Header>
        <HeaderLeftWrapper>
          {isVisible && (
            <>
              <InformationHeader title={title} description={description} />
              {onClose && <CloseHeader onClose={onClose} />}
            </>
          )}
        </HeaderLeftWrapper>
        {isVisible && (
          <HeaderPlayerActionsWrapper data-testid="player-header-actions">
            {!isSettingsOpen && (
              <HeaderIconWrapper onClick={toggleMute}>
                <Icon type={mute ? 'mute' : 'sound'} size={20} />
              </HeaderIconWrapper>
            )}
            <HeaderIconWrapper
              onClick={handleClickSettings}
              active={isSettingsOpen}
              menuAction={settingsIconAnimation}
              isActive={isSettingsOpen}
            >
              <Icon type="settings" size={24} />
            </HeaderIconWrapper>
          </HeaderPlayerActionsWrapper>
        )}
      </Header>
      {!isSettingsOpen && (
        <Content>
          {isLandscape && onStatsClick && (
            <NavButton
              iconType="stats"
              label="Stats"
              showLabel
              onClick={onStatsClick}
              testid="landscape-stats"
              size="large"
            />
          )}
          {isVisible && (
            <MainContent data-testid="player-controls" noScroll>
              {hasFinishedPlaying ? (
                <MobileVideoCompleted
                  playlistAutoplayingEnabled={playlistAutoplayingEnabled}
                  onWatchNext={onWatchNext}
                  onAutoplayCancel={onAutoplayCancel}
                  onWatchAgain={onWatchAgain}
                />
              ) : (
                <MobileVideoControls
                  playing={playing}
                  size={isLandscape || isTabletOrGreater ? 'medium' : 'small'}
                  onPlayPause={togglePlayPause}
                  onRewind={onRewind}
                  onSkip={onSkip}
                />
              )}
            </MainContent>
          )}
          {isLandscape && onStreamClick && (
            <NavButton
              iconType="stream"
              label="Streams"
              showLabel
              onClick={onStreamClick}
              testid="landscape-streams"
              size="large"
            />
          )}
        </Content>
      )}
      <div role="presentation" onClick={onSeekDone}>
        <Controls
          playerRef={playerRef}
          playerContainerRef={playerContainerRef}
          visible={isVisible}
          isMobile
          matchId={matchId}
        />
      </div>
    </Container>
  );
};

ScreenControls.propTypes = {
  playerRef: playerPropTypes,
  playerContainerRef: PropTypes.shape({}),
  title: PropTypes.string,
  description: PropTypes.string,
  matchId: PropTypes.string,
  isLandscape: PropTypes.bool,
  isVisible: PropTypes.bool,
  hasFinishedPlaying: PropTypes.bool,
  playlistAutoplayingEnabled: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  onWatchNext: PropTypes.func,
  onAutoplayCancel: PropTypes.func,
  onWatchAgain: PropTypes.func,
  onStatsClick: PropTypes.func,
  onStreamClick: PropTypes.func,
  onClose: PropTypes.func,
  handlePropagation: PropTypes.func,
  togglePersitentHoverState: PropTypes.func,
  onSeekDone: PropTypes.func,
};

export default withTheme(ScreenControls, themes, 'screenOverlay');
