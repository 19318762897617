import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetIsFetching = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.isFetching ?? false);
};

export const useIsFetching = () => useMemoizedSelector(makeGetIsFetching);

export default makeGetIsFetching;
