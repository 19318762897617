import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import Typography, { TypographyProps } from 'znipe-elements/general/Typography/Typography';
import { StyledButton as Button } from 'znipe-elements/general/Button_deprecated/Button.styles';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import type { Theme as BaseTheme } from 'znipe-themes/theme.types';

export const themes = {
  // TODO: Update theme name
  r6: {
    borderRadius: '0',
  },
};

type Theme = BaseTheme & {
  chatLockup: typeof themes.r6;
};

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-height: 40px;
  ${Button} {
    min-width: initial;
  }
`;

const inputType = 'paragraph-s';

type InputWrapperProps = TypographyProps & {
  $isReply?: boolean;
  $replyTarget?: string;
  $replyColor?: string;
};

export const InputContainer = styled.div`
  position: relative;
`;

export const InputPlaceholder = styled(Typography).attrs({
  forwardedAs: 'div',
})`
  margin: 0;
  position: absolute;
  color: ${colors.grey53};
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
`;

type ContainerProps = {
  $isHorizontal?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px 16px 12px;
  z-index: 1;
  ${({ $isHorizontal = false }) =>
    $isHorizontal &&
    css`
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      ${InputContainer} {
        flex-grow: 1;
      }
    `}
`;

export const InputWrapper = styled(Typography).attrs({
  forwardedAs: 'div',
})<InputWrapperProps>`
  width: inherit;
  box-sizing: border-box;
  min-height: 40px;
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.containerStroke};
  background: rgba(255, 255, 255, 0.03);
  border-radius: ${({ theme }) => (theme as Theme).chatLockup.borderRadius ?? '5px'};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
  outline: none;

  &[contentEditable] {
    padding: 10px 8px 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-height: calc((8px * 2) + (20px * 3));
    overflow-y: auto;
    word-break: break-word;
    ${Scrollbar}

    span {
      min-height: 20px;
      margin-bottom: 0;
      min-width: 1px;

      &:empty {
        min-height: 0;
      }
    }

    div[data-reply] {
      ${({ $isReply, $replyColor }) =>
        $isReply &&
        css`
          color: ${$replyColor};
          font-weight: 700;
        `}
    }

    div[data-image=''] {
      pointer-events: none;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
        transform: scale(1.2);
        margin: 0 4px;
      }
    }
  }

  > span:last-child:not(:first-child) {
    flex-grow: 1;
  }

  &:active,
  &:focus,
  &:focus-within {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid ${({ theme }) => theme.UIAccent};
  }
  padding: 0;
  overflow: hidden;
`;

export const DisabledBanner = styled.div`
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${colors.flamingo};
  background: rgba(255, 255, 255, 0.1);

  > * {
    margin: 0;
  }
`;

export const IconsWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  & > * {
    width: 24px;
    height: 24px;
    margin: 8px;
  }
`;

export const Avatar = styled.img`
  width: 150%;
  height: 150%;
  object-fit: cover;
`;

export const Input = styled(Typography).attrs({ forwardedAs: 'textarea', type: inputType })`
  width: 100%;
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.primaryTextColor};
  overflow: auto;
  height: auto;
  resize: none;
  padding: 8px;
  margin: 0;
  ${Scrollbar}

  &::placeholder {
    color: ${colors.grey53};
  }

  &:focus::placeholder {
    color: transparent;
  }

  @supports (-moz-appearance: none) {
    padding: 0 8px;
  }
`;
