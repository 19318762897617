import { schema } from 'normalizr';

const createPlayerId = (team, parent) => team.id ?? `${parent.gameId}_${team.playerId}`;

const createTeamObject = (team, parent) => ({
  ...team,
  id: createPlayerId(team, parent),
});

const team = new schema.Entity(
  'teams',
  {},
  {
    processStrategy: createTeamObject,
    idAttribute: createPlayerId,
  },
);

export default team;
