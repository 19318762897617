import { useMemo } from 'react';
import stripHtml from '../utils/stripHtml';
import { EMOJI_TAG_REGEX, REPLY_REGEX } from '../ChatLockup.constants';
import type { CaretPositionFunctions } from './useCaretPosition';
import useIsValidEmoji from './useIsValidEmoji';

const useSearchQuery = (message: string, { caretPosition }: CaretPositionFunctions) => {
  const isValidEmoji = useIsValidEmoji();
  return useMemo(() => {
    if (!message) return '';
    const [anchorPos, focusPos] = caretPosition.current ?? [message.length, message.length];
    const selectionStart = Math.min(anchorPos, focusPos);

    const rawEmojis = message.match(EMOJI_TAG_REGEX)?.filter(isValidEmoji);

    let cleanMessage = message;

    // Replace with a '.' to count as a character for calculating position
    rawEmojis?.forEach(emoji => {
      cleanMessage = cleanMessage.replace(emoji, '.');
    });
    cleanMessage = cleanMessage.replace(REPLY_REGEX, '.');

    const chunk = cleanMessage.substring(0, selectionStart);
    const pos = chunk.lastIndexOf(':');

    if (pos === -1) return '';

    const searchString = stripHtml(cleanMessage.substring(pos, selectionStart));
    return searchString.length > 2 ? searchString : '';
  }, [message, caretPosition, isValidEmoji]);
};

export default useSearchQuery;
