import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from 'tv-selectors/match/makeGetMatch';

const makeGetMatchGameTitle = () => {
  const getMatch = makeGetMatch();
  return createSelector([getMatch], match => match.game || '');
};

export const useGetMatchGameTitle = props => useMemoizedGqlSelector(makeGetMatchGameTitle, props);

export default makeGetMatchGameTitle;
