const popupWindow = (url, windowName = 'ZnipeTv', windowSettings = {}, windowDimension = {}) => {
  let windowWidth = windowDimension.width || 600;
  let windowHeight = windowDimension.height || 337.5;
  let windowleft = windowDimension.left || 0;
  let windowTop = windowDimension.top || 0;

  const settings = {
    resizable: 1,
    scrollbars: 0,
    status: 0,
    ...windowSettings,
  };

  const settingsString = Object.values(settings).join(',');

  if (global.document) {
    windowWidth = global.innerWidth * 0.75;
    windowHeight = windowWidth * 0.5625;
    const dualScreenLeft = global.screenLeft !== undefined ? global.screenLeft : global.screen.left;
    const dualScreenTop = global.screenTop !== undefined ? global.screenTop : global.screen.top;

    let width = 0;
    let height = 0;

    if (global.innerWidth) {
      width = global.innerWidth;
    } else if (document.documentElement.clientWidth) {
      width = document.documentElement.clientWidth;
    } else {
      width = global.screen.width;
    }

    if (global.innerHeight) {
      height = global.innerHeight;
    } else if (document.documentElement.clientHeight) {
      height = document.documentElement.clientHeight;
    } else {
      height = global.screen.height;
    }

    windowleft = width / 2 - windowWidth / 2 + dualScreenLeft;
    windowTop = height / 2 - windowHeight / 2 + dualScreenTop;
  }

  const openedWindow = global.open(
    url,
    windowName,
    `left=${windowleft},top=${windowTop},width=${windowWidth},height=${windowHeight},${settingsString}`,
  );
  return openedWindow;
};

export default popupWindow;
