import { memo, useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import { Container, RedDot } from './TimeLabel.styles';

const TimeLabel = ({
  isLiveStream = false,
  isWatchingLive = false,
  size = 'medium',
  timeLabel = '',
  playerRef = playerDefaultProps,
}) => {
  const [currentTime, totalTime] = timeLabel.split('/');
  const [livePlayer, setLivePlayer] = useState(false);
  const typographyType = useMemo(() => (size === 'small' ? 'title-xs' : 'title-m'), [size]);

  const seekToLive = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    const { end: maxSeekRange = 0 } = player.getSeekRange();
    player.seek(maxSeekRange);
  }, [playerRef]);

  useEffect(() => {
    if (/live/i.test(timeLabel) || isLiveStream) {
      setLivePlayer(true);
    } else {
      setLivePlayer(false);
    }
  }, [isLiveStream, timeLabel]);

  return (
    <Container
      onClick={livePlayer ? seekToLive : undefined}
      role={livePlayer ? 'button' : undefined}
      tabIndex="0"
      data-testid="time-label"
      $isLive={livePlayer}
    >
      {livePlayer ? (
        <>
          {!isWatchingLive && currentTime !== 'LIVE' && (
            <Typography type={typographyType}>{`${currentTime} / `}</Typography>
          )}
          <RedDot $onAir={isWatchingLive} data-testid="live-red-dot" />
          <Typography type={typographyType}>LIVE</Typography>
        </>
      ) : (
        <Typography type={typographyType} data-testid="label-text">
          {`${currentTime} / ${totalTime}`}
        </Typography>
      )}
    </Container>
  );
};

TimeLabel.propTypes = {
  isLiveStream: PropTypes.bool,
  isWatchingLive: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  timeLabel: PropTypes.string,
  playerRef: playerPropTypes,
};

const propsAreEqual = (prevProps, nextProps) => {
  const { isLiveStream: prevLive, isWatchingLive: prevWatching, timeLabel: prevTime } = prevProps;
  const { isLiveStream: nextLive, isWatchingLive: nextWatching, timeLabel: nextTime } = nextProps;
  return nextLive === prevLive && prevTime === nextTime && prevWatching === nextWatching;
};

export default memo(TimeLabel, propsAreEqual);
