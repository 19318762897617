import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetMatch from 'tv-selectors/match/makeGetMatch';

const makeGetMatchTournamentName = () => {
  const getSelectedMatch = makeGetMatch();
  return createSelector(
    [getSelectedMatch],
    selectedMatch => selectedMatch.metadata?.description || '',
  );
};

export const useGetMatchTournamentName = props =>
  useMemoizedSelector(makeGetMatchTournamentName, props);

export default makeGetMatchTournamentName;
