import PropTypes from 'prop-types';
import PlayerCard from 'tv-elements/data-display/PlayerCard/PlayerCard';
import TopicLink, { PLAYER_CARD } from 'tv-elements/data-display/TopicLink/TopicLink';
import useTopicLink from 'tv-hooks/useTopicLink';
import Link from 'znipe-elements/general/Link/Link';
import colors from 'znipe-styles/colors';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetPlayerName } from 'tv-selectors/players/makeGetPlayerName';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetTeamName } from 'tv-selectors/team/makeGetTeamName';
import { useGetPlayerGameTitle } from 'tv-selectors/players/makeGetPlayerGameTitle';

const PlayerResultItem = ({
  playerId,
  elementSizes,
  isDesktopOrGreater = false,
  shouldUsePlayerCard = false,
}) => {
  const { topicLinkSize, playerCardSize } = elementSizes;

  const image = useGetPlayerImage({ playerId });
  const nickname = useGetPlayerName({ playerId });
  const game = useGetPlayerGameTitle({ playerId });
  const link = useTopicLink(nickname, 'player', game);
  const playerTeam = useGetPlayerTeamId({ playerId });
  const teamLogo = useGetSelectedTeamLogo({ teamId: playerTeam });
  const teamName = useGetTeamName({ teamId: playerTeam });
  const role = ''; // TODO add role selector when ready
  if (isDesktopOrGreater || shouldUsePlayerCard) {
    return (
      <Link to={link} textColor={colors.white} data-testid="browse-player-result-wrapper">
        <PlayerCard
          teamLogo={teamLogo}
          playerImage={image}
          label={nickname}
          size={isDesktopOrGreater ? null : playerCardSize}
          iconType={role ? `${role?.toLowerCase()}Class` : ''}
          roundedCorners
          noMarker
        />
      </Link>
    );
  }
  return (
    <Link to={link} textColor={colors.white}>
      <TopicLink
        title={nickname}
        subtitle={teamName}
        image={image}
        secondaryImage={teamLogo}
        size={topicLinkSize}
        avatarIcon={`${role}Class`}
        type={PLAYER_CARD}
        hideChevronIcon
      />
    </Link>
  );
};

PlayerResultItem.propTypes = {
  playerId: PropTypes.string.isRequired,
  elementSizes: PropTypes.shape({
    tabsetSize: PropTypes.string,
    playerCardSize: PropTypes.string,
    topicCardSize: PropTypes.string,
    topicLinkSize: PropTypes.string,
    matchItemSize: PropTypes.string,
    videoListingSize: PropTypes.string,
    videoListingType: PropTypes.string,
  }).isRequired,
  shouldUsePlayerCard: PropTypes.bool,
  isDesktopOrGreater: PropTypes.bool,
};

export default PlayerResultItem;
