import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetContentSubscriptions from 'tv-selectors/contentSubscriptions/makeGetContentSubscriptions';

const getItemId = (_, props) => props?.id ?? '';
const getType = (_, props) => props?.type ?? '';

const makeGetSubscriptionMuteStatus = () => {
  const getContentSubscriptions = makeGetContentSubscriptions();
  return createSelector(
    [getContentSubscriptions, getItemId, getType],
    (subscriptions, id, type) => subscriptions?.[type]?.[id]?.muted ?? false,
  );
};

export const useGetSubscriptionMuteStatus = props =>
  useMemoizedGqlSelector(makeGetSubscriptionMuteStatus, props);

export default makeGetSubscriptionMuteStatus;
