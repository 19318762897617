import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsDesktopExtraLargeOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.desktopExtraLarge || false);
};

export const useGetIsDesktopExtraLargeOrGreater = () =>
  useMemoizedSelector(makeGetIsDesktopExtraLargeOrGreater);

export default makeGetIsDesktopExtraLargeOrGreater;
