import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProductionStatusLabel from 'znipe-elements/data-display/ProductionStatusLabel/ProductionStatusLabel';
import TextOverlay from 'znipe-elements/general/TextOverlay/TextOverlay';
import gradients from 'znipe-styles/gradients';
import colors from 'znipe-styles/colors';
import splitStringToTitle from './utils/splitStringToTitle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : 'none')};
  background-size: cover;
  background-position: center;
  min-height: ${({ size }) => (size === 'small' ? '104px' : '144px')};
`;

const GradientOverlay = styled.div`
  background: ${gradients.thumbnailGradient};
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LabelWrapper = styled.div`
  margin: 0 auto auto auto;
`;

const TextWrapper = styled.div`
  margin: auto;
`;

const HighlightHeader = ({ size = 'small', title = '', label = '', thumbnail = '' }) => {
  const titleArray = splitStringToTitle(title);
  return (
    <Container data-testid="highlight-header" size={size} backgroundImage={thumbnail}>
      <GradientOverlay />
      {label && (
        <LabelWrapper>
          <ProductionStatusLabel label={label} size={size} />
        </LabelWrapper>
      )}
      <TextWrapper>
        <TextOverlay
          backgroundText={titleArray[0]}
          foregroundText={titleArray[1]}
          strokeColor={colors.pastelYellow}
          size={size}
          type="solid"
          horizontalOffset={0.55}
        />
      </TextWrapper>
    </Container>
  );
};

HighlightHeader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  label: PropTypes.string,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
};

export default memo(HighlightHeader);
