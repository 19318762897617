import PropTypes from 'prop-types';
import { useSpring, animated, config } from '@react-spring/web';
import Icon from 'znipe-elements/general/Icon/Icon';
import usePageColors from 'znipe-hooks/usePageColors';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Component, Wrapper, IconWrapper } from './StreamsButton.styles';

const Streamsbutton = ({
  onClick,
  text = '',
  iconPosition = 'right',
  isToggledOn = false,
  disabled = false,
  icon,
  iconSize = 8,
  animation = true,
  smoothAnimation = true,
  dataTestId = 'streams-button',
}) => {
  const { hoverGradient, activeGradient, rootColor } = usePageColors();
  const theme = useThemeContext();

  const AnimatedIconWrapper = animated(IconWrapper);
  const iconAnimation = useSpring({
    transform: isToggledOn ? 'rotate(-180deg)' : 'rotate(0deg)',
    config: config.gentle,
    immediate: !smoothAnimation,
  });

  return (
    <Component
      data-testid={dataTestId}
      onClick={onClick}
      disabled={disabled}
      $activeGradient={activeGradient}
      $hoverGradient={hoverGradient}
      $isToggledOn={isToggledOn}
    >
      <Wrapper $color={rootColor} $iconPosition={iconPosition}>
        {icon && (
          <AnimatedIconWrapper
            data-testid="streams-button-icon-animation"
            style={animation ? iconAnimation : null}
          >
            <Icon type={icon} size={iconSize} fillColor={theme.UIAccent} inline />
          </AnimatedIconWrapper>
        )}
        <Typography type="title-xs" color={theme.buttonPrimaryTextColor} forwardedAs="span">
          {text}
        </Typography>
      </Wrapper>
    </Component>
  );
};

Streamsbutton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  isToggledOn: PropTypes.bool,
  disabled: PropTypes.bool,
  animation: PropTypes.bool,
  smoothAnimation: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  dataTestId: PropTypes.string,
};

export default Streamsbutton;
