import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import { BackgroundContainer } from 'znipe-elements/general/Flourish/Waves/Waves.styles';

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media ${device.tablet} {
    height: 72px;
    ${BackgroundContainer} {
      transform: translateX(-280px);
      width: 600px;
    }
  }
  @media ${device.desktop} {
    height: 64px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  span {
    text-transform: initial;
  }
`;

export const IconWrapper = styled.button`
  ${buttonDefaultStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const TitleContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;
