import { Reducer, useEffect, useReducer, useMemo, useRef, useCallback, useContext } from 'react';
import isEqual from 'lodash.isequal';
import deepmerge from 'deepmerge';
import usePrevious from 'znipe-hooks/usePrevious';
import { GqlClientContext } from 'znipe-gql/GqlClientContext';
import generateHashCodeFromString from 'znipe-utils/misc/generateHashCodeFromString';
import objectToQueryString from 'znipe-utils/location/objectToQueryString';
import parseJSONString from 'znipe-utils/string/parseJSONString';
import { constructPersistedQueryObject } from 'znipe-gql/utils/misc';
import isBrowser from 'znipe-utils/web/isBrowser';
import { print } from 'graphql/language/printer';
import { DocumentNode } from 'graphql/language/ast';
import logger from 'znipe-logger';
import { generateCacheHash, reducer, init } from './utils';
import { LOADING, ERROR, SUCCESS, defaultOptions } from './constants';

const isOnClient = isBrowser();

const usePassthroughEffect = (cb: () => void) => {
  const firstTime = useRef(true);
  if (firstTime.current) {
    firstTime.current = false;
    cb();
  }
};

type PersistedQuery = {
  sha256Hash: string;
};

type Extensions = {
  persistedQuery: PersistedQuery;
};

type FetchGqlQueryBody = {
  query: string;
  variables: Record<string, unknown>;
  extensions?: Extensions;
};

type GqlQueryOptions = {
  variables?: Record<string, unknown>;
  ssr?: boolean;
  cacheTime?: number;
  pollRate?: number;
  url?: string;
  persisted?: boolean;
  headers?: Record<string, string>;
  fetch?: RequestInit;
};

export type QueryResult<T> = {
  errors: Error[] | null;
  loading: boolean;
  data: T;
  refetch: (invalidate?: boolean) => void;
};

export const fetchGqlQuery = async <T>(
  body: FetchGqlQueryBody,
  fetchOptions: GqlQueryOptions,
  usePersisted = false,
): Promise<QueryResult<T>> => {
  const { url: fetchUrl = '', headers = {}, ...rest } = fetchOptions || {};
  if (!fetchUrl) {
    throw new Error('No fetch url passed in the options.');
  }

  if (usePersisted) {
    const { query, extensions } = body;
    const { persistedQuery } = extensions ?? {};
    const providedQueryHash =
      persistedQuery?.sha256Hash || generateHashCodeFromString(query).toString() || '';

    const defaultExtensions = constructPersistedQueryObject(providedQueryHash) || {};

    const selectedExtensions = deepmerge(defaultExtensions, extensions ?? {});

    // eslint-disable-next-line no-param-reassign
    body.extensions = selectedExtensions;

    const queryString = objectToQueryString({
      variables: JSON.stringify(body.variables),
      extensions: JSON.stringify(selectedExtensions),
    });
    const persistedUrl = `${fetchUrl}${queryString}`;
    const res = await fetch(persistedUrl, {
      ...rest,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (res.ok) return res.json() as Promise<QueryResult<T>>;

    if (res.status !== 404) {
      return Promise.reject(res);
    }
  }

  // Do not include extensions inside body when persisted is disabled
  const { extensions, ...restBody } = body;
  return fetch(fetchUrl, {
    ...rest,
    body: JSON.stringify(usePersisted ? body : restBody),
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => res.json())
    .catch((err: Error) => err) as Promise<QueryResult<T>>;
};

// We don't want to wait for the render on the server to speed things up
const useSelectedEffect = isOnClient ? useEffect : usePassthroughEffect;

export const parseQuery = (query: string | DocumentNode): string => {
  if (typeof query === 'string') return query;
  return print(query);
};

type GqlQueryState<T> = {
  error: Error | null;
  loading: boolean;
  data: T | null;
};

type Action<T> =
  | { type: typeof LOADING }
  | { type: typeof SUCCESS; data: T }
  | { type: typeof ERROR; error: Error };

const useGqlQuery = <T>(
  query: DocumentNode | string,
  options: GqlQueryOptions,
  initialState?: T,
  onSuccess?: (data: T) => void,
  onError?: (error: Error) => void,
) => {
  const { cache: gqlCache, client: gqlClient } = useContext(GqlClientContext);
  const firstTime = useRef<boolean>(true);
  const generatedCacheCode = useRef<string>('');
  const stringQuery = useMemo(() => parseQuery(query), [query]);
  const prevQuery = usePrevious(stringQuery);
  const prevOptions = usePrevious(options);
  const { variables, ssr, cacheTime, pollRate, url, persisted, headers } = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );

  const requestHasChanged = useMemo(
    () => stringQuery !== prevQuery || !isEqual(options, prevOptions),
    [stringQuery, prevQuery, options, prevOptions],
  );

  // @TODO: This should he a sha256.
  // Skipping for now as the browser version of generating a sha256 is async and would require som bigger changes in this code
  const queryHash = useMemo(
    () => generateHashCodeFromString(stringQuery).toString(),
    [stringQuery],
  );

  const cacheHash = useMemo(() => {
    if (!requestHasChanged) return generatedCacheCode.current;
    const code = generateCacheHash(queryHash, variables);
    generatedCacheCode.current = code;
    return code;
  }, [queryHash, requestHasChanged, variables]);

  const shouldUsePreloadedData = gqlCache.isPreloaded(cacheHash) && !gqlCache.isExpired(cacheHash);

  const [state, dispatch] = useReducer<
    Reducer<GqlQueryState<T>, Action<T>>,
    GqlQueryState<T> | undefined
  >(reducer, shouldUsePreloadedData ? gqlCache.get<GqlQueryState<T>>(cacheHash) : undefined, init);

  const dispatchResult = useCallback(
    (result: T) => {
      dispatch({ type: SUCCESS, data: result });
      if (onSuccess) onSuccess(parseJSONString(JSON.stringify(result)) as typeof result);
    },
    [onSuccess],
  );

  const dispatchError = useCallback(
    (error: Error) => {
      logger.error(error);
      dispatch({ type: ERROR, error });
      if (onError) onError(error);
    },
    [onError],
  );

  useSelectedEffect(() => {
    const preloadedData = gqlCache.get<T>(cacheHash);
    if (shouldUsePreloadedData && preloadedData) {
      dispatchResult(preloadedData);
    }
  }, []);

  const pollRateEnabled = useMemo(
    () => typeof pollRate === 'number' && pollRate > 0 && isOnClient,
    [pollRate],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const fetchData = useCallback(async () => {
    const useCache = Boolean(cacheTime && cacheTime > 0) || !isOnClient;

    // Use cached data if available
    const cachedData = gqlCache.get<T>(cacheHash);
    if (useCache && !gqlCache.isExpired(cacheHash)) {
      if (cachedData && cachedData !== state?.data) {
        dispatchResult(cachedData);
        return;
      }
    }

    dispatch({ type: LOADING });
    const { fetch: fetchOptions = {} } = options || {};
    const existingPromise = gqlClient.getPromise<QueryResult<T>>(cacheHash);

    try {
      const extensions = constructPersistedQueryObject(queryHash);

      const variablesWithDefault = variables ?? ({} as Record<string, unknown>);
      // Re-use already existing promise if one exists
      const promise =
        existingPromise ??
        fetchGqlQuery<T>(
          { query: stringQuery, variables: variablesWithDefault, extensions },
          { ...fetchOptions, headers, url },
          persisted,
        );

      // Make promise reusable so we don't call the same query multiple times at the same time
      if (existingPromise == null) {
        gqlClient.addPromise(cacheHash, promise);
      }

      const { data, errors } = await promise;

      if (errors?.length) throw errors[0];
      const selectedData = useCache && cachedData && isEqual(cachedData, data) ? cachedData : data;
      dispatchResult(selectedData);

      if (useCache) gqlCache.cache(cacheHash, selectedData, cacheTime);
    } catch (error: unknown) {
      dispatchError(error as Error);
    }

    gqlClient.removePromise(cacheHash);
    // @DEP_CHECK: I don't dare to change the dependencies here as I'm not sure options are memoized correctly by all parents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cacheTime,
    queryHash,
    persisted,
    cacheHash,
    dispatchError,
    dispatchResult,
    fetchGqlQuery,
    options?.fetch,
    state?.data,
    url,
  ]);

  useSelectedEffect(() => {
    if ((!ssr && !isOnClient) || !stringQuery) return () => null;

    // If data from server was received, don't do an additional fetch
    const shouldFetchData =
      !isOnClient || !ssr || !firstTime.current || !gqlCache.isPreloaded(cacheHash);

    firstTime.current = false;

    if (requestHasChanged && shouldFetchData) void fetchData();

    if (pollRateEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      const interval = setInterval(fetchData, (pollRate ?? 0) * 1000);
      return () => clearInterval(interval);
    }

    return () => null;
  }, [requestHasChanged, stringQuery]);

  const refetch = useCallback(
    (invalidate = false) => {
      if (invalidate) gqlCache.clearCache(cacheHash);
      return fetchData();
    },
    [cacheHash, fetchData, gqlCache],
  );

  const { error, loading, data } = state;
  return {
    error,
    loading,
    data: data || initialState,
    refetch,
  };
};

export default useGqlQuery;
