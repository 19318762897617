import Icon, { IconName } from 'znipe-elements/general/Icon/Icon';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import { PlacementTypes } from 'znipe-elements/feedback/ToolTip/ToolTip.types';
import { Bar, Button } from './ActionsBar.styles';
import useGetMessageActions from '../../../hooks/useGetMessageActions';

type ActionsBarProps = {
  messageId: string;
  placement?: 'over' | 'under';
};

const tooltipPlacementMap = {
  over: 'top',
  under: 'bottom',
};

const ActionsBar: React.FC<ActionsBarProps> = ({ messageId, placement = 'over' }) => {
  const actions = useGetMessageActions(messageId);
  const tooltipPlacement = tooltipPlacementMap?.[placement] as PlacementTypes;

  return (
    <Bar $placement={placement}>
      {actions.map(({ icon, tooltip, action }) => (
        <ToolTip label={tooltip} placement={tooltipPlacement} key={icon}>
          <Button onClick={action as () => void}>
            <Icon type={icon as IconName} inline size={22} />
          </Button>
        </ToolTip>
      ))}
    </Bar>
  );
};
export default ActionsBar;
