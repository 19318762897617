import { memo, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import Typography from 'znipe-elements/general/Typography/Typography';
import { CheckBox, Container, Input, Label } from './Checkbox.styles';
import { sizes } from './Checkbox.sizes';
import themes from './Checkbox.themes';

const TextCheckbox = forwardRef(
  (
    {
      name,
      checked,
      disabled = false,
      label,
      error = false,
      longLabel = false,
      onChange,
      size = 'medium',
      'data-testid': testId = 'input',
      onBlur,
    },
    ref,
  ) => {
    const id = useMemo(() => generateUniqueId(), []);
    return (
      <Container $size={sizes[size]} disabled={disabled}>
        <Input
          name={name}
          data-testid={testId}
          id={id}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
        />
        <Label data-testid="label" $size={sizes[size]} htmlFor={id} longLabel={longLabel}>
          <CheckBox data-testid="checkbox" $size={sizes[size]} error={error} />
          {typeof label === 'string' ? (
            <Typography type={longLabel ? 'paragraph-s' : 'paragraph-m'} color="secondaryTextColor">
              {label}
            </Typography>
          ) : (
            label
          )}
        </Label>
      </Container>
    );
  },
);

TextCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  longLabel: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  'data-testid': PropTypes.string,
};

const MemoTextCheckbox = memo(TextCheckbox);

export default withTheme(MemoTextCheckbox, themes, 'checkbox');
