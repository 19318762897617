import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};

const getState = state => state;

const makeGetGameMap = () => createSelector([getState], state => state.games || emptyObject);

export const useGetGameMap = () => useMemoizedGqlSelector(makeGetGameMap);

export default makeGetGameMap;
