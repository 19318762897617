import styled from 'styled-components';
import PropTypes from 'prop-types';
import { device } from 'znipe-styles/breakpoints';

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url(${({ $coverImage }) => $coverImage});
  background-size: cover;
  background-position: center;
  min-height: 128px;
  min-width: 288px;
  mask-image: linear-gradient(black, transparent);
  @media ${device.tablet} {
    min-height: 132px;
    mask-image: linear-gradient(to right, black 70%, transparent);
  }
  @media ${device.desktop} {
    min-height: 110px;
  }
`;

const Cover = ({ coverImage, children }) => (
  <ImageContainer data-testid="header-cover" $coverImage={coverImage}>
    {children}
  </ImageContainer>
);

Cover.propTypes = {
  coverImage: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Cover;
