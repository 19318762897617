export const PUSH = 'push';
export const COMPACT = 'compact';

export const PAGE_DESCRIPTIONS = {
  editorial: {
    proview: 'Enjoy one of the many editorial content pieces right here on Pro View.',
    default: 'Enjoy one of the many editorial content pieces right here on Znipe TV.',
  },
  highlight: {
    proview: "Watch this handpicked highlight from one of Pro View's many VODs.",
    default: "Watch this handpicked highlight from one of Znipe TV's many VODs.",
  },
};
