import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const fillCss = css`
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  border-radius: 5px;
  box-sizing: border-box;
  background: ${({ theme, $transparent }) =>
    $transparent ? theme.bg3Transparency : theme.secondaryBackgroundColor};
  border: ${({ theme, $transparent }) =>
    $transparent ? 'none' : `1px solid ${theme.containerStroke}`};
  ${({ $fill }) => $fill && fillCss}
  ${({ $customBackground }) =>
    $customBackground &&
    css`
      background: ${$customBackground};
    `};
`;

const Card = forwardRef(
  ({ children = null, transparent = true, fill = false, customBackground }, ref) => (
    <Container
      data-testid="card"
      ref={ref}
      $transparent={transparent}
      $fill={fill}
      $customBackground={customBackground}
    >
      {children}
    </Container>
  ),
);

Card.propTypes = {
  children: PropTypes.node,
  transparent: PropTypes.bool,
  fill: PropTypes.bool,
  customBackground: PropTypes.string,
};

export default Card;
