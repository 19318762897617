import PropTypes from 'prop-types';
import TopicLink, { TEAM } from 'tv-elements/data-display/TopicLink/TopicLink';
import LogoCard from 'tv-elements/data-display/LogoCard/LogoCard';
import useTopicLink from 'tv-hooks/useTopicLink';
import Link from 'znipe-elements/general/Link/Link';
import colors from 'znipe-styles/colors';
import { useGetTeamName } from 'tv-selectors/team/makeGetTeamName';
import { useGetTeamCountry } from 'tv-selectors/team/makeGetTeamCountry';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetTeamGameTitle } from 'tv-selectors/team/makeGetTeamGameTitle';

const TeamResultItem = ({ teamId, elementSizes, isDesktopOrGreater }) => {
  const { topicLinkSize, topicCardSize } = elementSizes;
  const name = useGetTeamName({ teamId });
  const country = useGetTeamCountry({ teamId });
  const logo = useGetSelectedTeamLogo({ teamId });
  const game = useGetTeamGameTitle({ teamId });
  const link = useTopicLink(name, 'team', game);

  if (isDesktopOrGreater) {
    return (
      <Link to={link} textColor={colors.white}>
        <LogoCard text={name} imageSrc={logo} size={topicCardSize} />
      </Link>
    );
  }
  return (
    <Link to={link} hideUnderline textColor={colors.white}>
      <TopicLink
        title={name}
        subtitle={country}
        image={logo}
        size={topicLinkSize}
        type={TEAM}
        hideChevronIcon
      />
    </Link>
  );
};

TeamResultItem.propTypes = {
  teamId: PropTypes.string.isRequired,
  elementSizes: PropTypes.shape({
    tabsetSize: PropTypes.string,
    playerCardSize: PropTypes.string,
    topicCardSize: PropTypes.string,
    topicLinkSize: PropTypes.string,
    matchItemSize: PropTypes.string,
    videoListingSize: PropTypes.string,
    videoListingType: PropTypes.string,
  }).isRequired,
  isDesktopOrGreater: PropTypes.bool.isRequired,
};

export default TeamResultItem;
