import { log } from 'znipe-logger';

const parseJSONString = string => {
  try {
    return JSON.parse(string);
  } catch (err) {
    log('failed to parse inputData', err);
    return null;
  }
};

export default parseJSONString;
