import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetBrowser from './makeGetBrowser';

const makeGetOrientation = () => {
  const getBrowser = makeGetBrowser();
  return createSelector([getBrowser], browser => browser.orientation || '');
};

export const useOrientation = () => useMemoizedSelector(makeGetOrientation);

export default makeGetOrientation;
