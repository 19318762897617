import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import useStreamItemInformation from 'tv-hooks/useStreamItemInformation';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import LOLPlayerSelector from 'tv-modules/Selector/LOL/PlayerSelector/PlayerSelector';
import Selector from 'tv-modules/Selector/Selector';

const Container = styled.div``;

const MobileSelectedStreamItem = ({ matchId, selectedMatchGameIndex, onClick }) => {
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const playingMobileStream = playerStreams[0] || {};
  const playingMobileStreamId = playingMobileStream.id;
  const selectedStreamInfo = useStreamItemInformation(
    matchId,
    selectedMatchGameIndex,
    playingMobileStreamId,
  );
  const isTabletOrGreater = useGetIsTabletOrGreater();

  const size = isTabletOrGreater ? 'medium' : 'small';

  const {
    playerId,
    teamId,
    gatPlayerPosition,
    globalStreamName,
    globalStreamImage,
    isGlobalStream,
  } = selectedStreamInfo;

  return (
    <Container id="stage-stream-selector">
      <div>
        {isGlobalStream ? (
          <Selector
            type="vertical"
            label={globalStreamName}
            image={globalStreamImage}
            imageType="image"
            onClick={onClick}
            size={size}
            borderRadius={0}
            isGlobalStream
          />
        ) : (
          <LOLPlayerSelector
            matchId={matchId}
            playerId={playerId}
            teamId={teamId}
            gatPlayerPosition={gatPlayerPosition}
            onClick={onClick}
            size={size}
          />
        )}
      </div>
    </Container>
  );
};

export default MobileSelectedStreamItem;

MobileSelectedStreamItem.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
