import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const ButtonWrapper = styled.div`
  /* ugly fix to change the height of the button */
  height: 100%;
  > button {
    height: 100%;
    z-index: 1;
    > div {
      height: 100%;
    }
  }
`;

export const IconWrapper = styled.div`
  height: 24px;
  ${({ $type }) =>
    $type !== 'text' &&
    css`
      margin: auto;
    `};
`;

export const Container = styled.div`
  max-height: 48px;
  display: flex;
  width: 100%;
  a {
    display: flex;
    height: 100%;
  }
`;

export const TextComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const PremiumLabelWrapper = styled.div`
  margin-left: 8px;
  color: ${colors.pastelYellow};
`;
