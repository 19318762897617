import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from './makeGetMatch';

const makeGetMatchStatus = () => {
  const getMatch = makeGetMatch();
  return createSelector([getMatch], match => (match?.status || '').toLowerCase());
};

export default makeGetMatchStatus;

export const useGetMatchStatus = props => useMemoizedGqlSelector(makeGetMatchStatus, props);
