import styled from 'styled-components';

export const Container = styled.div``;

export const SliderWrapper = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  bottom: 65px;
  position: absolute;
  z-index: 2;
  background: #0e1012;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 25px;
  height: 120px;
  align-items: flex-end;
  border-radius: 5px;
  margin-bottom: 12px;
`;
