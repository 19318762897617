import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import { Shimmer as ShimmerStyle, ShimmerContainer } from './Button.styles';

const AnimatedShimmer = animated(ShimmerStyle);

const Shimmer = ({ enabled }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const props = useSpring({
    left: enabled ? '250px' : '-250px',
    from: { left: '-250px' },
    config: { duration: 400 },
    delay: 400,
    immediate: prefersReducedMotion,
  });
  return (
    <ShimmerContainer>
      <AnimatedShimmer style={props} data-testid="sheen-animation" />
    </ShimmerContainer>
  );
};

Shimmer.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default Shimmer;
