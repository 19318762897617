import {
  SET_VIDEOS,
  SET_STAGE_VIDEOS,
  SET_PLAYLIST_VIDEOS,
  SET_VIDEO_TOKENS,
} from 'tv-constants/redux';

export const setVideos = (videos = {}) => ({
  type: SET_VIDEOS,
  payload: videos,
});

export const setStageVideos = (highlights = {}, featuredHighlight = {}) => ({
  type: SET_STAGE_VIDEOS,
  payload: { ...highlights, ...featuredHighlight },
});

export const setPlaylistVideos = (videos = {}) => ({
  type: SET_PLAYLIST_VIDEOS,
  payload: videos,
});

export const setVideoTokens = (streams = {}) => ({
  type: SET_VIDEO_TOKENS,
  payload: streams,
});
