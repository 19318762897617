import { createSelector } from 'reselect';
import makeGetProps from 'tv-selectors/makeGetProps';

const makeGetMatchId = () => {
  const getProps = makeGetProps();
  return createSelector(
    [getProps],
    ({ matchID, matchId, params = {} }) => matchID || matchId || params.matchId || '',
  );
};

export default makeGetMatchId;
