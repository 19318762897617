const noop = () => {};

let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      supportsPassive = true;
      return supportsPassive;
    },
  });
  global.addEventListener('test', null, opts);
} catch (_e) {
  // Nothing
}

const subscribe = (element, name, handle, { bubbles = true, passive = false } = {}) => {
  if (!element.addEventListener) return noop;

  const container = bubbles ? element : document;
  const customHandle = bubbles
    ? handle
    : e => {
        if (element === e.target || element.contains(e.target)) {
          handle(e);
        }
      };

  let options = false;
  if (supportsPassive && passive) {
    options = { passive };
  }

  container.addEventListener(name, customHandle, options);

  return () => element.removeEventListener(name, customHandle, options);
};

export default subscribe;
