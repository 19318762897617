import { useRef } from 'react';
import PropTypes from 'prop-types';
import StreamThumbnailPreview from 'znipe-elements/data-display/StreamThumbnailPreview/StreamThumbnailPreview';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Container,
  Thumbnail,
  Content,
  ThumbnailContainer,
  NowPlayingTextWrapper,
} from './ItemWithVideoPreview.styles';

const ItemWithVideoPreview = ({
  src,
  thumbnail,
  thumbnailAlt = '',
  size = 'small',
  orientation = 'vertical',
  children = null,
  noBorderRadius = false,
  autoPlay = false,
  onVideoClick,
  showVideo = true,
  isSelected = false,
}) => {
  const containerRef = useRef({});
  return (
    <Container
      data-testid="item-with-video-preview"
      ref={containerRef}
      orientation={orientation}
      size={size}
      showChildren={children}
      noBorderRadius={noBorderRadius}
    >
      {showVideo && (
        <ThumbnailContainer
          data-testid="item-with-video-preview-thumbnail-wrapper"
          orientation={orientation}
          size={size}
          onClick={onVideoClick}
        >
          <Thumbnail orientation={orientation} $isSelected={isSelected}>
            <StreamThumbnailPreview
              targetRef={containerRef}
              src={src}
              thumbnail={thumbnail}
              thumbnailAlt={thumbnailAlt}
              autoPlay={autoPlay}
            />
          </Thumbnail>
          {isSelected && (
            <NowPlayingTextWrapper>
              <Typography type="title-xs">Now playing</Typography>
            </NowPlayingTextWrapper>
          )}
        </ThumbnailContainer>
      )}
      {children && <Content size={size}>{children}</Content>}
    </Container>
  );
};

ItemWithVideoPreview.propTypes = {
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'fullWidth']),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  thumbnailAlt: PropTypes.string,
  children: PropTypes.node,
  noBorderRadius: PropTypes.bool,
  autoPlay: PropTypes.bool,
  showVideo: PropTypes.bool,
  isSelected: PropTypes.bool,
  onVideoClick: PropTypes.func,
};

export default ItemWithVideoPreview;
