import { memo } from 'react';
import PropTypes from 'prop-types';

import { Text, CardInfoSVG } from './CcvInformation.styles';

const CidInformationImage = () => (
  <CardInfoSVG data-testid="amex-info" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.07422"
      y="1.48145"
      width="243.953"
      height="129.712"
      rx="8"
      fill="#E9E9E9"
      stroke="#B9B9B9"
      strokeWidth="2"
    />
    <rect x="12.4141" y="32.6241" width="39.0528" height="28.7132" rx="2" fill="#F7F7F7" />
    <path
      d="M206.303 59.2981V68.5381H204.749V61.0621L202.579 62.4621L201.865 61.3001L204.875 59.2981H206.303ZM215.355 68.5381H209.405V67.2081L211.687 64.8561C212.377 64.1468 212.863 63.5914 213.143 63.1901C213.423 62.7888 213.563 62.3501 213.563 61.8741C213.563 61.3888 213.427 61.0434 213.157 60.8381C212.886 60.6328 212.443 60.5301 211.827 60.5301C211.248 60.5301 210.609 60.5954 209.909 60.7261L209.559 60.7821L209.461 59.5501C210.366 59.2794 211.304 59.1441 212.275 59.1441C214.207 59.1441 215.173 60.0028 215.173 61.7201C215.173 62.3921 215.023 62.9754 214.725 63.4701C214.435 63.9648 213.913 64.5621 213.157 65.2621L211.183 67.1801H215.355V68.5381ZM217.225 59.5921C218.093 59.2934 219.045 59.1441 220.081 59.1441C221.117 59.1441 221.868 59.3448 222.335 59.7461C222.811 60.1381 223.049 60.7634 223.049 61.6221C223.049 61.9954 222.974 62.3314 222.825 62.6301C222.685 62.9288 222.54 63.1481 222.391 63.2881C222.241 63.4188 222.055 63.5588 221.831 63.7081C222.325 63.9414 222.68 64.1981 222.895 64.4781C223.119 64.7488 223.231 65.1921 223.231 65.8081C223.231 66.7881 222.983 67.5161 222.489 67.9921C222.003 68.4588 221.224 68.6921 220.151 68.6921C219.731 68.6921 219.269 68.6548 218.765 68.5801C218.261 68.5148 217.859 68.4448 217.561 68.3701L217.127 68.2581L217.225 67.0541C218.251 67.2408 219.157 67.3341 219.941 67.3341C221.061 67.3248 221.621 66.8301 221.621 65.8501C221.621 65.4021 221.476 65.0614 221.187 64.8281C220.897 64.5948 220.515 64.4734 220.039 64.4641H218.177V63.1621H220.039C220.384 63.1621 220.701 63.0221 220.991 62.7421C221.289 62.4528 221.439 62.1074 221.439 61.7061C221.439 61.2954 221.308 60.9921 221.047 60.7961C220.795 60.6001 220.445 60.5021 219.997 60.5021C219.259 60.5021 218.499 60.5674 217.715 60.6981L217.351 60.7681L217.225 59.5921ZM228.853 68.5381V66.8861H224.695V65.6961L227.131 59.2981H228.853L226.277 65.5281H228.853V62.8121H230.421V65.5281H231.429V66.8861H230.421V68.5381H228.853Z"
      fill="black"
    />
    <rect x="12.4141" y="90.8336" width="50.3057" height="12.5613" rx="2" fill="#CFCFCF" />
    <rect x="70.4219" y="90.8336" width="50.3057" height="12.5613" rx="2" fill="#CFCFCF" />
    <rect x="124.783" y="90.8336" width="50.3057" height="12.5613" rx="2" fill="#CFCFCF" />
    <rect x="183.379" y="90.8336" width="50.3057" height="12.5613" rx="2" fill="#CFCFCF" />
    <path
      d="M241.178 63.8376C241.178 68.5601 238.529 72.9258 234.066 76.1489C229.604 79.371 223.39 81.3949 216.481 81.3949C209.571 81.3949 203.357 79.371 198.895 76.1489C194.432 72.9258 191.783 68.5601 191.783 63.8376C191.783 59.115 194.432 54.7494 198.895 51.5263C203.357 48.3042 209.571 46.2803 216.481 46.2803C223.39 46.2803 229.604 48.3042 234.066 51.5263C238.529 54.7494 241.178 59.115 241.178 63.8376Z"
      stroke="#EB5757"
      strokeWidth="2"
    />
  </CardInfoSVG>
);

const AmexInformation = ({ code }) => (
  <>
    <CidInformationImage />
    <Text>{`The ${code} code is a 3-4 digit long number on the back of your card`}</Text>
  </>
);

AmexInformation.propTypes = {
  code: PropTypes.string.isRequired,
};

export default memo(AmexInformation);
