import { schema } from 'normalizr';

export const createRoundId = (round, parent) => round.id ?? `${parent.gameId}_${round.roundNumber}`;

const createRoundObject = (round, parent) => {
  const newRound = {
    ...round,
    id: createRoundId(round, parent),
    startTime: (round?.roundStartOffset ?? 0) / 1000,
    endTime: (round?.roundEndOffset ?? 0) / 1000,
  };
  delete newRound.roundStartOffset;
  delete newRound.roundEndOffset;
  return newRound;
};

const round = new schema.Entity(
  'rounds',
  {},
  {
    processStrategy: createRoundObject,
    idAttribute: createRoundId,
    mergeStrategy: (prev, next) => ({
      ...prev,
      ...next,
      deaths: [...new Set([...(prev.deaths ?? []), ...(next.deaths ?? [])])],
    }),
  },
);

export default round;
