import { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SingleViewPlayer from 'znipe-player/src/components/SingleView/SingleView';
import colors from 'znipe-styles/colors';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import useAnalyticsParams from 'tv-hooks/useAnalyticsParams';
import PingOverlay from '../PingOverlay/PingOverlay';

const Container = styled.div`
  position: relative;
  background: ${colors.shadowGrey};
`;

const getTimeInfo = playerRef => {
  const player = playerRef?.current;
  if (!player) return {};
  const currentTime = player.getCurrentTime();
  if (isNaN(currentTime)) return {};
  const seekRange = player.getSeekRange();
  const timeInfo = { currentTime, seekRange };
  return timeInfo;
};

const PingPlayer = ({ videoId, src, onClose, startTime, endTime, hideOverlay = false }) => {
  const [timeInfo, setTimeInfo] = useState({ currentTime: 0, seekRange: { start: 0, end: 1 } });
  const analyticsParams = useAnalyticsParams(videoId, 'video');
  const playerContainerRef = useRef(null);
  const playerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const isDesktopOrGreater = useGetIsDesktopOrGreater();

  const onPlayerReady = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    const handleLoad = () => {
      player.selectQuality(2700000);
      player.setSeekRange(startTime, endTime);
      setTimeInfo(getTimeInfo(playerRef));
      player.removeEventListener('loaded', handleLoad);
    };

    player.addEventListener('loaded', handleLoad);
  }, [startTime, endTime]);

  const onMouseEnter = useCallback(() => setIsHovering(true), []);
  const onMouseLeave = useCallback(() => setIsHovering(false), []);
  const onContainerTap = useCallback(() => {
    if (isDesktopOrGreater) return;
    setIsHovering(value => !value);
  }, [isDesktopOrGreater]);

  const pingSrc = useMemo(() => {
    const search = `st=${startTime}&et=${endTime}`;
    if (src.indexOf('?') > 0) return `${src}&${search}`;
    return `${src}?${search}`;
  }, [src, startTime, endTime]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;
    player.setSeekRange(startTime, endTime);
    setTimeInfo(getTimeInfo(playerRef));
  }, [startTime, endTime]);

  useEffect(() => {
    const player = playerRef.current;

    const onTimeUpdate = ({ currentTime, seekRange }) => {
      const { end } = seekRange;

      if (currentTime > end) onClose();
    };

    player.addEventListener('timeupdate', onTimeUpdate);
    player.addEventListener('ended', onClose);

    return () => {
      player.removeEventListener('ended', onClose);
      player.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [onClose]);

  return (
    <Container
      ref={playerContainerRef}
      data-testid="ping-player"
      role="presentation"
      onClick={onContainerTap}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SingleViewPlayer
        ref={playerRef}
        src={pingSrc}
        onReady={onPlayerReady}
        startTime={startTime}
        muted
        autoPlay
        analyticsParams={analyticsParams}
      />
      {!hideOverlay && (
        <PingOverlay
          playerContainerRef={playerContainerRef}
          isVisible={isHovering}
          playerRef={playerRef}
          onClose={onClose}
          timeInfo={timeInfo}
        />
      )}
    </Container>
  );
};

PingPlayer.propTypes = {
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  videoId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  hideOverlay: PropTypes.bool,
};

export default PingPlayer;
