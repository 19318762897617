import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
import useSetDisabledStatus from 'tv-modules/Onboarding/hooks/useSetDisabledStatus';
import useOnboardingPopup from 'tv-modules/Onboarding/hooks/useOnboardingPopup';
import { STAGE_WELCOME_STEP_ID } from 'tv-routes/Stage/Stage.constants';
import { useIsOnboardingPlayed } from 'tv-selectors/auth/makeGetIsOnboardingStepPlayed';

import {
  Container,
  PopupContainer,
  PopupHeadline,
  DisableHints,
  CloseWrapper,
  TargetWrapper,
} from './Popup.styles';

const Popup = ({
  popupId,
  headline,
  description,
  timer,
  direction,
  horizontalPosition = 'center',
  targetShape = 'rectangle',
  offset = 0,
  disabled = false,
  children,
}) => {
  const containerRef = useRef(null);
  const timeOutRef = useRef();
  const [containerHiddenDiff, setContainerHiddenDiff] = useState({});
  const popupAlreadyPlayed = useIsOnboardingPlayed({ stepId: popupId });
  const stageWelcomeAlreadyPlayed = useIsOnboardingPlayed({ stepId: STAGE_WELCOME_STEP_ID });

  const setDisabledStatus = useSetDisabledStatus();
  const { isCurrentlyPlaying, requestPopupPlay, requestPopupDismiss } = useOnboardingPopup(popupId);

  const onDisableHintsClick = useCallback(() => setDisabledStatus(true), [setDisabledStatus]);
  const onCloseClick = useCallback(() => requestPopupDismiss(), [requestPopupDismiss]);

  const shouldshowPopup = isCurrentlyPlaying && !disabled && !popupAlreadyPlayed;

  useEffect(() => {
    const shouldNotPlayPopup =
      disabled || !timer || popupAlreadyPlayed || !stageWelcomeAlreadyPlayed;
    if (shouldNotPlayPopup) return () => {};
    const timeOut = setTimeout(() => requestPopupPlay(), timer);
    timeOutRef.current = timeOut;
    return () => {
      clearTimeout(timeOut);
    };
  }, [timer, disabled, popupAlreadyPlayed, stageWelcomeAlreadyPlayed, requestPopupPlay]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!containerRef.current) return;
    const el = containerRef.current;
    const rect = el.getBoundingClientRect();
    setContainerHiddenDiff({
      top: rect.top,
      left: rect.left,
      bottom: (window.innerHeight || document.documentElement.clientHeight) - rect.bottom,
      right: (window.innerWidth || document.documentElement.clientWidth) - rect.right,
    });
  }, [direction, shouldshowPopup]);

  const onTargetClick = useCallback(() => {
    if (disabled || popupAlreadyPlayed) return;

    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
      timeOutRef.current = null;
    }
    onCloseClick();
  }, [disabled, popupAlreadyPlayed, onCloseClick]);

  const stopPropagation = useCallback(e => e.stopPropagation(), []);

  if (!shouldshowPopup) {
    return <TargetWrapper onClick={onTargetClick}>{children}</TargetWrapper>;
  }

  return (
    <Container>
      <PopupContainer
        onClick={stopPropagation}
        ref={containerRef}
        $direction={direction}
        $horizontalPosition={horizontalPosition}
        $containerHiddenDiff={containerHiddenDiff}
        $targetShape={targetShape}
        $offset={offset}
      >
        <CloseWrapper onClick={onCloseClick}>
          <Icon type="close" />
        </CloseWrapper>
        <PopupHeadline>{headline}</PopupHeadline>
        <Typography type="paragraph-m" color={colors.white}>
          {description}
        </Typography>
        <DisableHints onClick={onDisableHintsClick}>
          <Typography type="title-xs" color={colors.grey75}>
            Disable hints
          </Typography>
        </DisableHints>
      </PopupContainer>
      <TargetWrapper onClick={onTargetClick}>{children}</TargetWrapper>
    </Container>
  );
};

Popup.propTypes = {
  popupId: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  timer: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'up']).isRequired,
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  targetShape: PropTypes.oneOf(['rectangle', 'circle']),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  offset: PropTypes.number,
};

export default Popup;
