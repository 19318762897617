import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import { SKILL_LEVEL_UP_EVENT } from 'tv-reducers/intensive';
import useGatId from 'tv-hooks/useGatId';
import createLolPlayer from 'tv-epics/utils/createLolPlayer';

export const SUBSCRIBE_SKILL_UPDATE = 'SUBSCRIBE_SKILL_UPDATE';
export const UNSUBSCRIBE_SKILL_UPDATE = 'UNSUBSCRIBE_SKILL_UPDATE';

export const useSkillLevelUpEpic = matchId => {
  const dispatch = useIntensiveDispatch();
  const gatId = useGatId(matchId);

  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_SKILL_UPDATE, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_SKILL_UPDATE, gatId });
  }, [dispatch, gatId]);
};

const createSkillLevelUp = data => {
  const gameTime = data.gameTime / 1000;
  return {
    ...data,
    skillLevelUp: {
      id: `${gameTime}:${data.skillSlot}`,
      gameTime,
      skillSlot: data.skillSlot,
    },
  };
};

const gatOffsetEpic = factorySubscribeEpic(
  [SUBSCRIBE_SKILL_UPDATE, UNSUBSCRIBE_SKILL_UPDATE],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('actionType').equalTo('skill_level_up'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const playersArray = payload.map(event => createSkillLevelUp(event));
      const players = playersArray.reduce((acc, info) => {
        const { skillLevelUp } = info;
        const player = createLolPlayer(info, gatId);
        const { id } = player;
        if (!acc[id]) acc[id] = player;
        const { skillLevelUps = {} } = acc[id];
        skillLevelUps[skillLevelUp.id] = skillLevelUp;
        acc[id].skillLevelUps = skillLevelUps;
        return acc;
      }, {});
      return {
        type: SKILL_LEVEL_UP_EVENT,
        gatId,
        payload: {
          id: gatId,
          players,
        },
      };
    },
);

export default gatOffsetEpic;
