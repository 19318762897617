import { useCallback, useMemo } from 'react';
import colors from 'znipe-styles/colors';
import { error } from 'znipe-logger';
import type { IconName } from 'znipe-elements/general/Icon/Icon';
import useMessage from './useMessage';
import useChatContext from './useChatContext';
import useChatAdminContext from './useChatAdminContext';
import useActionCallback from './useActionCallback';

const allAreTrue = (array: unknown[]) => array.every(item => Boolean(item));
const noRequirements = true;

type Action = {
  icon: IconName;
  tooltip: string;
  action: () => Promise<unknown> | unknown;
  conditions: unknown[];
};

const useGetMessageActions = (messageId?: string) => {
  const adminContext = useChatAdminContext();
  const { setBanAction } = adminContext || {};

  const actionCallback = useActionCallback(messageId);

  const {
    setReplyData,
    setAlert,
    accountInfo = { isModerator: false, uid: undefined },
  } = useChatContext();
  const { isModerator, uid } = accountInfo;

  const {
    avatarId: messageAvatar,
    color: messageColor = colors.white,
    username: messageUsername,
    userId: messageUserId,
    serverMessageId,
    reported,
    isMessageModerator,
  } = useMessage(messageId) ?? {};

  const startReportUser = useCallback(() => {
    setAlert({
      action: `Report ${messageUsername}?`,
      description: 'Reporting a user could result in them being banned from the chat.',
      onResolve: () => {
        void actionCallback('report');
        setAlert(null);
      },
      resolveText: 'Yes, report',
    });
  }, [setAlert, messageUsername, actionCallback]);

  const addReplyTarget = useCallback(() => {
    if (!messageUserId || !messageUsername || !messageColor || !serverMessageId) {
      error('Could not add reply target, missing data');
      return;
    }
    setReplyData({
      userId: messageUserId,
      username: messageUsername,
      color: messageColor,
      serverMessageId,
      isModerator: isMessageModerator,
    });
  }, [
    isMessageModerator,
    setReplyData,
    messageUsername,
    messageUserId,
    messageColor,
    serverMessageId,
  ]);

  const banUser = useCallback(async () => {
    if (setBanAction) {
      if (!serverMessageId) {
        error('Could not ban user, no serverMessageId provided');
        return;
      }
      if (!messageUserId || !messageUsername) {
        error('Could not ban user, no userId or username provided');
        return;
      }
      setBanAction({
        avatarId: messageAvatar,
        color: messageColor,
        username: messageUsername,
        userId: messageUserId,
        messageId: serverMessageId,
      });
    } else {
      await actionCallback('ban');
    }
  }, [
    setBanAction,
    actionCallback,
    messageAvatar,
    messageColor,
    messageUserId,
    messageUsername,
    serverMessageId,
  ]);

  const actions = useMemo(() => {
    const allActions: Action[] = [
      {
        icon: 'check',
        tooltip: 'Keep',
        action: async () => actionCallback('allow'),
        conditions: [isModerator, reported],
      },
      {
        icon: 'trash2',
        tooltip: 'Remove',
        action: () => actionCallback('remove'),
        conditions: [isModerator],
      },
      {
        icon: 'banHammer',
        tooltip: 'Ban',
        action: async () => await banUser(),
        conditions: [isModerator],
      },
      {
        icon: 'exclamation',
        tooltip: 'Report',
        action: async () => startReportUser(),
        conditions: [!isModerator],
      },
      {
        icon: 'cornerUpLeft',
        tooltip: 'Reply',
        action: async () => addReplyTarget(),
        conditions: [noRequirements],
      },
    ];

    const allowedActions = allActions.filter(action => allAreTrue(action.conditions));

    return allowedActions;
  }, [isModerator, reported, actionCallback, banUser, addReplyTarget, startReportUser]);

  if (uid === messageUserId) return [];
  return actions;
};

export default useGetMessageActions;
