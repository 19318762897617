import produce from 'immer';
import { SET_PLAYLISTS } from 'tv-constants/redux';

export const initialState = {};

const playlistsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_PLAYLISTS:
      Object.entries(payload).forEach(([key, value]) => (state[key] = value));
      return state;
    default:
      return state;
  }
}, initialState);

export default playlistsReducer;
