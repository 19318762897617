import type { StringTypes } from '../ChatLockup.types';

type InputHistoryProps = {
  undo: (
    current: StringTypes,
    fn: (c: StringTypes, force: boolean, replace: boolean) => void,
  ) => void;
  redo: (
    current: StringTypes,
    fn: (c: StringTypes, force: boolean, replace: boolean) => void,
  ) => void;
  apply: (s: StringTypes) => void;
  clear: () => void;
};

const undoOperations: StringTypes[] = [];
const redoOperations: StringTypes[] = [];

const compareTwoStringTypes = (a: StringTypes, b: StringTypes) => {
  const normalizedA = typeof a === 'string' ? a : a.join('-');
  const normalizedB = typeof b === 'string' ? b : b.join('-');
  return normalizedA === normalizedB;
};

const history = (): InputHistoryProps => {
  const clear = () => {
    undoOperations.length = 0;
    redoOperations.length = 0;
  };

  const apply = (content: StringTypes) => {
    const lastUndoItem = undoOperations[undoOperations.length - 1];
    if (lastUndoItem && compareTwoStringTypes(lastUndoItem, content)) return;
    undoOperations.push(content);
  };

  const handleOperations = (
    current: StringTypes,
    operations: StringTypes[],
    oppositeOperations: StringTypes[],
    fn: (content: StringTypes, force: boolean, replace: boolean) => void,
  ): void => {
    const item = operations.pop();
    if (!item || !item.length) return;

    oppositeOperations.push(current);
    fn(item, true, true);
  };

  const undo = (
    current: StringTypes,
    fn: (content: StringTypes, force: boolean, replace: boolean) => void,
  ) => {
    handleOperations(current, undoOperations, redoOperations, fn);
  };

  const redo = (
    current: StringTypes,
    fn: (content: StringTypes, force: boolean, replace: boolean) => void,
  ) => {
    handleOperations(current, redoOperations, undoOperations, fn);
  };

  return {
    undo,
    redo,
    apply,
    clear,
  };
};

export default history;
