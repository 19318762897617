import { useGetGameCompetitors } from 'tv-selectors/games/makeGetGameCompetitors';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';

const roleLabels = ['top', 'jungle', 'mid', 'bottom', 'support'];

const useStreamItemInformation = (matchId, selectedMatchGameIndex, streamId) => {
  const competitiors = useGetGameCompetitors({ matchId, selectedMatchGameIndex });
  const globalStreams = useGetGameGlobalStreams({ matchId, selectedMatchGameIndex });

  let playerId = '';
  let teamId = '';
  let gatPlayerPosition = 0;
  let role = '';
  let isGlobalStream = false;
  let globalStreamName = '';
  let globalStreamImage = '';

  // find player information using streamId
  competitiors.forEach((comp, compIndex) => {
    const currentTeamId = comp.teamId || '';
    const { lineup = [] } = comp;

    // check if the selected streamId exsits in current competitor lineup
    const findPlayerIndex = lineup.findIndex(player => player.streamId === streamId);

    // setup player information
    if (findPlayerIndex !== -1) {
      const playerInfo = lineup[findPlayerIndex];

      playerId = playerInfo.playerId;
      teamId = currentTeamId;
      // gatPlayerPosition does not exist in redux but is calculated using competitior and player array position

      gatPlayerPosition =
        compIndex > 0
          ? ((competitiors[compIndex] || {}).lineup || []).length + findPlayerIndex
          : findPlayerIndex;

      // find player role label using player position in array
      role = roleLabels[findPlayerIndex];
    }
  });

  if (!playerId) {
    const selectedGlobalStream = globalStreams.find(stream => stream.streamId === streamId) || {};

    isGlobalStream = !!selectedGlobalStream.streamId;
    globalStreamName = selectedGlobalStream.name || '';
    globalStreamImage = selectedGlobalStream.imageSrc || '';
  }

  return {
    playerId,
    teamId,
    gatPlayerPosition,
    role,
    globalStreamName,
    globalStreamImage,
    isGlobalStream,
  };
};

export default useStreamItemInformation;
