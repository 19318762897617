export const largeSizes = {
  title: 'title-l',
  component: 'medium',
  card: 'medium',
  stats: 'medium',
  playerCardSize: 'large',
};

export const mediumSizes = {
  title: 'title-l',
  component: 'small',
  card: 'medium',
  stats: 'medium',
  playerCardSize: 'medium',
};

export const smallSizes = {
  title: 'title-l',
  component: 'small',
  card: 'medium',
  stats: 'small',
  playerCardSize: 'medium',
};
