import { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { animated } from '@react-spring/web';
import { Wrapper, StrokeText, InitialName, Content } from './RepeatingTextBackground.styles';
import { SMALL, MEDIUM, LARGE } from './constants';

const SIZES = [SMALL, MEDIUM, LARGE];

const AnimatedContent = animated(Content);

const RepeatingTextBackground = ({ text = '', textSize = LARGE, animation }) => {
  const [repeatedText, setRepeatedText] = useState(['']);

  const rootText = useMemo(() => text.replace(/\s+/g, ''), [text]);

  const contentElement = useMemo(() => {
    if (animation) {
      return (
        <AnimatedContent size={textSize} style={animation}>
          {repeatedText}
        </AnimatedContent>
      );
    }
    return <Content size={textSize}>{repeatedText}</Content>;
  }, [animation, textSize, repeatedText]);

  const wrapperRef = useCallback(
    node => {
      if (node !== null) {
        const wrapperWidth = node.getBoundingClientRect().width || 1;
        const nameWidth = node.firstChild.getBoundingClientRect().width || 1;
        const repeatTimes = wrapperWidth / nameWidth + 2;
        const nameRepeater = [];
        for (let i = 0; i < repeatTimes; i++) {
          const element =
            i % 2 === 0 ? (
              <span key={i} data-testid="repeating-item">
                {rootText}
              </span>
            ) : (
              <StrokeText key={i} data-testid="repeating-item">
                {rootText}
              </StrokeText>
            );
          nameRepeater.push(element);
        }
        setRepeatedText(nameRepeater);
      }
    },
    [rootText],
  );

  return (
    <Wrapper ref={wrapperRef} data-testid="text-wrapper">
      <InitialName>{rootText}</InitialName>
      {contentElement}
    </Wrapper>
  );
};

RepeatingTextBackground.propTypes = {
  text: PropTypes.string,
  textSize: PropTypes.oneOf(SIZES),
  animation: PropTypes.shape({}),
};

export default memo(RepeatingTextBackground);
