import { useParams } from 'react-router-dom';
import { useGetPackageGameTitles } from 'tv-selectors/packages/makeGetPackageGameTitles';
import { useMemo } from 'react';

const usePackageIncludesGame = (gameTitle, packageName) => {
  const { packageSlug } = useParams();
  const packageGameTitles = useGetPackageGameTitles({ packageName: packageName || packageSlug });
  return useMemo(() => packageGameTitles.includes(gameTitle), [gameTitle, packageGameTitles]);
};

export default usePackageIncludesGame;
