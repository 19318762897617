export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const SENT = 'SET';
export const RESENT = 'RESENT';

export const firebaseErrors = {
  'auth/user-not-found': {
    message: 'Email not registered',
    type: 'email',
  },
  'auth/invalid-email': {
    message: 'Email is invalid',
    type: 'email',
  },
  'auth/wrong-password': {
    message: 'Incorrect password',
    type: 'password',
  },
  'auth/email-already-in-use': {
    message: 'Email already in use',
    type: 'email',
  },
  'auth/weak-password': {
    message: 'Password to weak',
    type: 'password',
  },
  'auth/invalid-action-code': {
    message: 'Looks like that link is invalid.',
    type: 'code',
  },
  'auth/expired-action-code': {
    message: 'Looks like that link is expired.',
    type: 'code',
  },
  'auth/too-many-requests': {
    message: 'Too frequent requests. Please wait a moment.',
    type: 'spam',
  },
};

export const defaultError = 'Oops, something went wrong! Try again.';
