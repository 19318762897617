import isBrowser from 'znipe-utils/web/isBrowser';
import type { ZnipeType } from '../../../global';

const registerZnipeGlobal = <T extends keyof ZnipeType>(
  key: T,
  value: Required<ZnipeType>[T],
): Required<ZnipeType>[T] => {
  if (!isBrowser()) return value;

  if (!window.Znipe) window.Znipe = {};
  if (!window.Znipe[key]) window.Znipe[key] = value;

  // TS doesn't know that window.Znipe[key] is defined
  return window.Znipe[key] as Required<ZnipeType>[T];
};

export default registerZnipeGlobal;
