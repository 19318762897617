import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import NeonBorder from 'znipe-elements/general/NeonBorder/NeonBorder';
import TeamStandings from 'tv-modules/TeamStandings/TeamStandings';
import ProductionStatusLabel from 'znipe-elements/data-display/ProductionStatusLabel/ProductionStatusLabel';
import { useMatchTeamsLogos } from 'tv-selectors/match/makeGetMatchTeamsLogos';
import { useMatchTeams } from 'tv-selectors/match/makeGetMatchTeams';
import { useGetMatchStartTime } from 'tv-selectors/match/makeGetMatchStartTime';
import { useGetMatchStatus } from 'tv-selectors/match/makeGetMatchStatus';
import { useGetMatchBestOf } from 'tv-selectors/match/makeGetMatchBestOf';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetTournament } from 'tv-selectors/tournaments/makeGetTournament';
import useMatchLink from 'tv-hooks/useMatchLink';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import Link from 'znipe-elements/general/Link/Link';
import VodDate from './VodDate';
import { OuterContianer, Container, DescriptionWrapper, LiveWrapper } from './MatchItem.styles';
import MatchItemLoading from './MatchItem.loading';

const getTournamentStageLabel = word => {
  if (typeof word !== 'string') return '';
  const splited = word.split(':')?.[1]?.replace('-', ' ');
  if (splited.length < 1) return '';
  return splited.charAt(0).toUpperCase() + splited.substring(1);
};

const MatchItem = ({ matchId, size = 'small' }) => {
  const [team1LogoSrc = '', team2LogoSrc = ''] = useMatchTeamsLogos({ matchId });
  const [teamOne, teamTwo] = useMatchTeams({ matchId });
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const teamOneLabel =
    isDesktopLargeOrGreater && size === 'medium' ? teamOne?.name : teamOne?.shortName;
  const teamTwoLabel =
    isDesktopLargeOrGreater && size === 'medium' ? teamTwo?.name : teamTwo?.shortName;
  const matchStartTime = useGetMatchStartTime({ matchId });
  const matchStatus = useGetMatchStatus({ matchId });
  const matchBestOf = useGetMatchBestOf({ matchId });
  const tournamentData = useGetTournament({ matchId });
  const { tournamentStages = [] } = tournamentData ?? {};

  const tournamentStageLabel = useMemo(
    () => getTournamentStageLabel(tournamentStages?.[0]) || 'Tournament Stage',
    [tournamentStages],
  );
  const bestOfLabel = `Best Of ${matchBestOf}`;
  const matchLink = useMatchLink(matchId);
  const invertTeamLogoPlacement = size === 'medium' && isDesktopLargeOrGreater;

  const { hasLoaded: team1LogoLoaded } = useOnImageLoad(team1LogoSrc);
  const { hasLoaded: team2LogoLoaded } = useOnImageLoad(team2LogoSrc);

  // load when component has at least one logo and team labels ready
  const componentHasLoaded = useMemo(() => {
    const hasATeamLogo = team1LogoLoaded || team2LogoLoaded;
    const hasTeamLabels = teamOneLabel && teamTwoLabel;
    return Boolean(hasATeamLogo && hasTeamLabels);
  }, [team1LogoLoaded, team2LogoLoaded, teamOneLabel, teamTwoLabel]);

  const showFullComponent = useLoadingDelay(componentHasLoaded);

  const sizes = useMemo(() => {
    switch (size) {
      case 'xsmall':
        return {
          liveLabelSize: 'xsmall',
          matchInfoSize: 'small',
          descriptionSize: 'paragraph-s',
        };
      case 'small':
        return {
          liveLabelSize: 'xsmall',
          matchInfoSize: 'medium',
          descriptionSize: 'paragraph-s',
        };
      default:
        return {
          liveLabelSize: 'medium',
          matchInfoSize: 'large',
          descriptionSize: 'title-m',
        };
    }
  }, [size]);

  const { liveLabelSize, matchInfoSize, descriptionSize } = sizes;
  const isLive = matchStatus === 'live';
  const Border = isLive ? NeonBorder : 'div';
  const vodDateSize = useMemo(() => (size === 'medium' ? 'medium' : 'small'), [size]);

  if (!showFullComponent) {
    return <MatchItemLoading size={size} />;
  }

  return (
    <Link to={matchLink} hideUnderline disabled={matchStatus === 'upcoming'}>
      <OuterContianer data-testid="match-item">
        <Border>
          <Container data-testid="match-item-container" size={size} isLive={isLive}>
            {isLive ? (
              <LiveWrapper size={size}>
                <ProductionStatusLabel label="live" size={liveLabelSize} />
              </LiveWrapper>
            ) : (
              <VodDate size={vodDateSize} matchStartTime={matchStartTime} />
            )}
            <TeamStandings
              size={matchInfoSize}
              teamOneLogo={team1LogoSrc}
              teamOneName={teamOneLabel}
              teamTwoLogo={team2LogoSrc}
              teamTwoName={teamTwoLabel}
              isLive={isLive}
              swapPlacement={invertTeamLogoPlacement}
              explodeAnimationWidth={size === 'small' ? '100px' : null}
              matchMissingScore
              hideScore
            />
            <DescriptionWrapper>
              {size === 'medium' ? (
                <>
                  <Typography type={descriptionSize}>{tournamentStageLabel}</Typography>
                  <Typography type={descriptionSize}>{bestOfLabel}</Typography>
                </>
              ) : (
                <Typography type={descriptionSize}>
                  {tournamentStageLabel} | {bestOfLabel}
                </Typography>
              )}
            </DescriptionWrapper>
          </Container>
        </Border>
      </OuterContianer>
    </Link>
  );
};

MatchItem.propTypes = {
  matchId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium']),
};

export default memo(MatchItem);
