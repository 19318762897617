import produce from 'immer';
import deepmerge from 'deepmerge';
import { SET_GAMES, SET_GAME_GAT_ID } from 'tv-constants/redux';

export const initialState = {};

const overwriteMerge = (_destinationArray, sourceArray) => sourceArray;

const gamesReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_GAMES:
      Object.entries(payload).forEach(([key, value]) => {
        const oldState = state[key] || {};
        return (state[key] = deepmerge(oldState, value, { arrayMerge: overwriteMerge }));
      });
      break;

    case SET_GAME_GAT_ID: {
      const { gameId, gatId } = payload;
      if (!state[gameId]) state[gameId] = {};
      state[gameId].gatId = gatId;
      break;
    }

    default:
      break;
  }
}, initialState);

export default gamesReducer;
