const themes = {
  default: {
    bookCover: {
      headline: 'Highlights',
      paragraphs: [
        'Quickly get caught up on all the action you missed while getting snacks.',
        'Kills and other team achievements will be displayed in an interactive video timeline.',
      ],
      buttonLabel: 'Got it',
      headerImage: 'https://assets.znipe.tv/onboarding/csgo/BookCoverFlourish_highlights.png',
    },
  },
  proview: {
    bookCover: {
      headline: 'Highlights',
      paragraphs: [
        'Quickly get caught up on all the action you missed while getting snacks.',
        'Kills and team objectives will be displayed in an interactive video timeline. ',
      ],
      buttonLabel: 'Got it',
      headerImage: 'https://assets.znipe.tv/onboarding/lol/highlights-coverbook-header.svg',
    },
  },
};

export default themes;
