// Config file that reads from system environments on server-side and global config on client side.
const config = global.process ? global.process.env : process.env;

if (config.NODE_ENV !== 'production') {
  let hostname = global.location ? global.location.hostname : 'localhost';
  if (process?.env?.localApi) {
    hostname = process.env.localApi;
  }
  config.API_URL = `http://${hostname}:9000`;
  config.STATIC_API_URL = `http://${hostname}:9000`;
  config.RIOT_AUTH_API = 'https://proview-staging-api.lolesports.com';
  config.GRAPHQL_API_URL = 'https://quarterback.espoespo.com/graphql';
  config.QUARTERBACK_API_URL = 'https://quarterback.espoespo.com';
  config.BOWSER_API_URL = 'https://bowser.staging.znipe.tv';
  config.ANALYTICS_EVENT_API_URL = 'https://quarterback.espoespo.com/v4/za/events';
  config.RIOT_REWARDS_API_URL = 'https://rex.rewards.lolesports.com/v1';
  config.PROVIEW_ENABLED = 'true';
  config.SENTRY_DSN = 'https://883c585785e144ffb5b452b815886abf@o850192.ingest.sentry.io/5817124';
  config.ICONS_URL = 'https://icons.znipe.tv/';
  config.ENVIRONMENT = 'dev';
  config.VERSION = '1.0.0';
}

export default config;

// Config file that reads from system environments on server-side and global config on client side.
// const config = global.process ? global.process.env : process.env;
// if system is run locally, you can set environment variable localApi, and you can host api on the network instead of local computer.
