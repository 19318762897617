import styled from 'styled-components';
import { StyledButton } from 'znipe-elements/general/Button_deprecated/Button.styles';

export const themes = {
  default: {
    useDefault: true,
  },
  proview: {
    backgroundColor: '#006464',
    hoverBackgroundColor: '#00C8C8',
    border: 'none',
    borderRadius: '2px',
    padding: {
      xsmall: '24px',
      small: '40px',
    },
  },
};

export const DefaultLoginButtonWrapper = styled.div`
  ${StyledButton} {
    min-width: unset;
  }
`;

export const CustomLoginButtonWrapper = styled.div`
  ${StyledButton} {
    min-width: unset;
    padding: 0 ${({ theme, $size }) => theme.login.padding[$size]};

    border: ${({ theme }) => theme.login.border};
    background-color: ${({ theme }) => theme.login.backgroundColor};
    border-radius: ${({ theme }) => theme.login.borderRadius};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.login.hoverBackgroundColor};
    }
  }
`;
