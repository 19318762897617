import { handleActions } from 'redux-actions';
import { SET_HIGHLIGHT_PING } from 'tv-constants/oldRedux';

export const initialState = {
  highlightPing: {},
};

export default handleActions(
  {
    [SET_HIGHLIGHT_PING]: (state, { videoId, src, startTime, endTime }) => {
      const highlightPing = { videoId, src, startTime, endTime };
      return { ...state, highlightPing };
    },
  },
  initialState,
);
