import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetUIMap from './makeGetUIMap';

const makeGetStreamLanguage = () => {
  const getUIMap = makeGetUIMap();
  return createSelector([getUIMap], ui => ui.streamLanguage);
};

export const useStreamLanguage = () => useMemoizedSelector(makeGetStreamLanguage);

export default makeGetStreamLanguage;
