import { useCallback } from 'react';
import Icon from 'znipe-elements/general/Icon/Icon';
import useDimensions from 'react-cool-dimensions';
import Typography from 'znipe-elements/general/Typography/Typography';
import Slide from 'znipe-elements/layout/Animation/Slide/Slide';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import { breakpoints } from 'znipe-styles/breakpoints';
import useGetMessageActions from '../../../hooks/useGetMessageActions';
import { Overlay, List, IconButton, IconWrapper, Button } from './MessageOptions.styles';

type MessageOptionsProps = {
  onClose: () => void;
  messageId?: string;
};

const MessageOptions: React.FC<MessageOptionsProps> = ({ onClose, messageId }) => {
  const actions = useGetMessageActions(messageId);
  const { observe, currentBreakpoint } = useDimensions({
    breakpoints: { small: 0, large: breakpoints.tablet },
    useBorderBoxSize: true,
  });

  const ref = useOutsideClick<HTMLDivElement>(onClose);

  const actionWrapper = useCallback(
    (action: (...args: unknown[]) => Promise<unknown> | unknown) =>
      async (...args: unknown[]) => {
        await action(...args);
        onClose();
      },
    [onClose],
  );

  return (
    <Overlay ref={observe} data-testid="overlay">
      <Slide type="bottom" height="auto" position="relative" show>
        <List ref={ref} data-testid="list" $size={currentBreakpoint}>
          <IconWrapper>
            <IconButton data-testid="close-button" onClick={onClose}>
              <Icon type="chevronDown" size={24} />
            </IconButton>
          </IconWrapper>
          {actions.map(({ icon, tooltip, action }) => (
            <Button onClick={actionWrapper(action)} key={tooltip}>
              <Icon type={icon} inline size={currentBreakpoint === 'large' ? 24 : 16} />
              <Typography type="title-s" as="span">
                {tooltip}
              </Typography>
            </Button>
          ))}
        </List>
      </Slide>
    </Overlay>
  );
};

export default MessageOptions;
