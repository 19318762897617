import TeamVsTeamVertical from 'znipe-elements/data-display/TeamVsTeam/TeamVsTeamVertical';
import TeamLogo from 'znipe-elements/data-display/TeamLogo/TeamLogo';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import Avatar from 'znipe-elements/data-display/Avatar/v2/Avatar';
import PropTypes from 'prop-types';
import {
  RelativePositioning,
  TeamVsTeamWrapper,
  TeamLogoWrapper,
  LogoImagePosition,
  LogoBackgroundImage,
  ImageWrapper,
  QuestionmarkContainer,
  QuestionMark,
  SelectImagePosition,
} from './PredictionCardRightIcon.styles';

export const Teams = ({ teamOneId, teamTwoId, size }) => {
  const teamOneLogo = useGetSelectedTeamLogo({ teamId: teamOneId });
  const teamTwoLogo = useGetSelectedTeamLogo({ teamId: teamTwoId });

  return (
    <RelativePositioning>
      <TeamVsTeamWrapper>
        <TeamVsTeamVertical
          size={size}
          versusIconOpacity="0.5"
          teamLogo1={teamOneLogo}
          teamLogo2={teamTwoLogo}
        />
      </TeamVsTeamWrapper>
    </RelativePositioning>
  );
};

Teams.propTypes = {
  teamOneId: PropTypes.string.isRequired,
  teamTwoId: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export const Team = ({ teamId }) => {
  const teamLogo = useGetSelectedTeamLogo({ teamId });

  return (
    <RelativePositioning>
      <TeamLogoWrapper>
        <TeamLogo size="xlarge" logo={teamLogo} />
      </TeamLogoWrapper>
    </RelativePositioning>
  );
};

Team.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export const Player = ({ playerId }) => {
  const playerImage = useGetPlayerImage({ playerId });
  const teamId = useGetPlayerTeamId({ playerId });
  const teamLogo = useGetSelectedTeamLogo({ teamId });

  return (
    <RelativePositioning>
      <LogoImagePosition>
        <LogoBackgroundImage>
          <TeamLogo size="xlarge" logo={teamLogo} />
        </LogoBackgroundImage>
        <ImageWrapper>
          <Avatar imageUrl={playerImage} />
        </ImageWrapper>
      </LogoImagePosition>
    </RelativePositioning>
  );
};

Player.propTypes = {
  playerId: PropTypes.string.isRequired,
};

export const Select = () => (
  <RelativePositioning>
    <SelectImagePosition>
      <QuestionmarkContainer>
        <QuestionMark>?</QuestionMark>
        <ImageWrapper>
          <Avatar imageUrl="https://assets.znipe.tv/players/default-player-img-medium.png" />
        </ImageWrapper>
      </QuestionmarkContainer>
    </SelectImagePosition>
  </RelativePositioning>
);
