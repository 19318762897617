import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatchGamesIds from 'tv-selectors/match/makeGetMatchGamesIds';
import makeGetGames from 'tv-selectors/games/makeGetGameMap';

const emptyArray = [];

const makeGetMatchAvailableGamesInfo = () => {
  const getMatchGamesIds = makeGetMatchGamesIds();
  const getGames = makeGetGames();
  return createSelector([getMatchGamesIds, getGames], (matchGameIds, games) => {
    if (matchGameIds.length === 0 || Object.keys(games).length === 0) return emptyArray;
    const gamesInfoArrs = [];
    matchGameIds.forEach(currentGameId => {
      const gameInfo = games[currentGameId] || {};
      const status = (gameInfo.status || '').toLowerCase();
      const isAvailable = status !== 'upcoming' && status !== 'not_played';
      if (isAvailable) {
        gamesInfoArrs.push(gameInfo);
      }
    });
    return gamesInfoArrs;
  });
};

export const useGetMatchAvailableGamesInfo = props =>
  useMemoizedGqlSelector(makeGetMatchAvailableGamesInfo, props);

export default makeGetMatchAvailableGamesInfo;
