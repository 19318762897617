import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsTabletOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.tablet);
};

export const useGetIsTabletOrGreater = () => useMemoizedSelector(makeGetIsTabletOrGreater);

export default makeGetIsTabletOrGreater;
