import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import ItemWithVideoPreview from 'tv-modules/Featured/ItemWithVideoPreview/ItemWithVideoPreview';
import ProductionStatusLabel from 'znipe-elements/data-display/ProductionStatusLabel/ProductionStatusLabel';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import PremiumBanner from 'tv-modules/Premium/PremiumBanner/PremiumBanner';
import { useGetVideoThumbnail } from 'tv-selectors/video/makeGetVideoThumbnail';
import { useGetVideoTitle } from 'tv-selectors/video/makeGetVideoTitle';
import { useGetVideoDate } from 'tv-selectors/video/makeGetVideoDate';
import { useGetVideoType } from 'tv-selectors/video/makeGetVideoType';
import { useGetIsVideoLocked } from 'tv-selectors/video/makeGetIsVideoLocked';
import { useGetFeaturedHighlight } from 'tv-selectors/featured/makeGetFeaturedHighlight';
import useHighlightLink from 'tv-hooks/useHighlightLink';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import getRelativeDate from 'znipe-utils/date/getRelativeDate';
import { LockWrapper } from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip.styles';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import LoadingItemWithVideoPreview from 'tv-modules/Featured/ItemWithVideoPreview/ItemWithVideoPreview.loading';
import {
  ContentWrapper,
  StatusLabelWrapper,
  TitleWrapper,
  ButtonWrapper,
  MiddleSection,
  DescriptionWrapper,
  FloatingBackgroundImageWrapper,
} from './FeaturedHighlightItem.styles';

const FeaturedHighlightItem = ({ size, featuredId }) => {
  const [videoId] = useGetFeaturedHighlight({ featuredId });
  const title = useGetVideoTitle({ videoId });
  const videoDate = useGetVideoDate({ videoId });
  const videoType = useGetVideoType({ videoId });
  const isLocked = useGetIsVideoLocked({ videoId });
  const thumbnailUrl = useGetVideoThumbnail({ videoId });
  const videoSrc = useStreamSrcWithToken(videoId, 'video');

  const dateString = useMemo(() => getRelativeDate(videoDate), [videoDate]);
  const premiumRef = useRef(null);

  const { hasLoaded } = useOnImageLoad(thumbnailUrl);
  const componentHasLoaded = useLoadingDelay(hasLoaded);

  const description = dateString;

  const videoShownType = useMemo(() => {
    if (videoType === 'original') {
      return 'editorial';
    }
    if (videoType === 'streamcut') {
      return 'highlight';
    }
    return videoType;
  }, [videoType]);

  const highlightLink = useHighlightLink({ highlightId: videoId, type: videoShownType, isLocked });

  const elementSpecs = useMemo(() => {
    switch (size) {
      case 'large':
        return {
          videoPreviewComponentLayout: 'horizontal',
          videoPreviewComponentSize: 'large',
          titleTypography: 'heading-m',
          descriptionTypography: 'paragraph-m',
          buttonSize: 'medium',
        };

      default:
        return {
          videoPreviewComponentLayout: 'vertical',
          videoPreviewComponentSize: 'small',
          titleTypography: 'heading-s',
          descriptionTypography: 'paragraph-m',
          buttonSize: 'small',
        };
    }
  }, [size]);

  const {
    videoPreviewComponentLayout,
    videoPreviewComponentSize,
    titleTypography,
    descriptionTypography,
    buttonSize,
  } = elementSpecs;

  if (!componentHasLoaded) {
    return (
      <LoadingItemWithVideoPreview
        orientation={videoPreviewComponentLayout}
        size={videoPreviewComponentSize}
      />
    );
  }

  return (
    <ItemWithVideoPreview
      src={videoSrc}
      thumbnail={thumbnailUrl}
      orientation={videoPreviewComponentLayout}
      size={videoPreviewComponentSize}
    >
      {isLocked && (
        <LockWrapper>
          <PremiumBanner targetRef={premiumRef} />
        </LockWrapper>
      )}
      <ContentWrapper size={size} data-testid="featured-highlight-item">
        <StatusLabelWrapper>
          <ProductionStatusLabel size={size} label={videoShownType} />
        </StatusLabelWrapper>
        <MiddleSection>
          <TitleWrapper size={size}>
            <Typography type={titleTypography}>{title}</Typography>
          </TitleWrapper>
          <DescriptionWrapper>
            <Typography type={descriptionTypography}>{description}</Typography>
          </DescriptionWrapper>
        </MiddleSection>
        <ButtonWrapper>
          <Button variant="feature" to={highlightLink} size={buttonSize} icon="streamFilled">
            Watch {videoShownType}
          </Button>
        </ButtonWrapper>
        <FloatingBackgroundImageWrapper
          data-testid="left-floating-fire-image"
          size={size}
          position="left"
        >
          <Icon type="fire" fill="currentColor" />
        </FloatingBackgroundImageWrapper>
        <FloatingBackgroundImageWrapper
          data-testid="right-floating-fire-image"
          size={size}
          position="right"
        >
          <Icon type="fire" fill="currentColor" />
        </FloatingBackgroundImageWrapper>
      </ContentWrapper>
    </ItemWithVideoPreview>
  );
};

FeaturedHighlightItem.propTypes = {
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  featuredId: PropTypes.string.isRequired,
};

export default FeaturedHighlightItem;
