export const PLAYER = 'player';
export const CHAMPION = 'champion';
export const RIOT_CHAMPION = 'riotChampion';
export const TEAM = 'team';
export const LEAGUE = 'league';
export const EVENT = 'event';

export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';

export const VERTICAL = 'vertcial';
export const HORIZONTAL = 'horizontal';

export const defaultPlayerImageUrl =
  'https://assets.znipe.tv/proview/players/default-player-img.png';

export const deprecatedDefaultPlayerImage =
  'https://assets.znipe.tv/teams/default/players/genericplayer.svg';
