import colors from 'znipe-styles/colors';

export default {
  proview: {
    color: colors.riotBackgroundRule,
    backgroundColor: colors.white,
    backButtonColor: colors.riotTextSecondary,
  },
  default: {
    color: colors.riotBackgroundRule,
    backgroundColor: colors.white,
    backButtonColor: colors.riotTextSecondary,
  },
};
