import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { EVENT, PLAYER, TEAM, CHAMPION } from 'tv-routes/Topic/Topics.constants';
import { Wrapper, Subtitle, Title, TitleWrapper } from './TopicTextBox.styles';
import { SMALL, MEDIUM, LARGE, SIZES } from '../../TopicHeader.constants';

const TopicTextBox = ({
  headline,
  subtitle,
  showMini = false,
  icon = '',
  size = SMALL,
  type = PLAYER,
}) => {
  const elementSizes = useMemo(() => {
    if (size === LARGE) {
      return {
        headline: 'heading-xl',
        subtitle: 'heading-m',
        iconSize: 40,
      };
    }
    if (size === MEDIUM) {
      return {
        headline: 'heading-l',
        subtitle: 'heading-s',
        iconSize: 32,
      };
    }
    return {
      headline: 'heading-m',
      subtitle: 'heading-s',
      iconSize: 16,
    };
  }, [size]);

  const headlineSize = useMemo(
    () => (showMini ? 'heading-s' : elementSizes.headline),
    [elementSizes.headline, showMini],
  );
  const subtitleSize = useMemo(
    () => (showMini ? 'heading-xs' : elementSizes.subtitle),
    [elementSizes.subtitle, showMini],
  );
  const iconSize = showMini ? 16 : elementSizes.iconSize;

  return (
    <Wrapper data-testid="topic-text-box">
      <TitleWrapper>
        <Title type={headlineSize}>{headline}</Title>
        {icon && <Icon type={icon} size={iconSize} inline />}
      </TitleWrapper>
      <Subtitle $capitalize={type === CHAMPION} type={subtitleSize}>
        {subtitle}
      </Subtitle>
    </Wrapper>
  );
};

TopicTextBox.propTypes = {
  headline: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string,
  showMini: PropTypes.bool,
  size: PropTypes.oneOf(SIZES),
  type: PropTypes.oneOf([PLAYER, TEAM, EVENT, CHAMPION]),
};

export default memo(TopicTextBox);
