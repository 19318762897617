import { SVG } from 'tv-modules/Payment/ErrorPage/ErrorPage.styles';

const ErrorImage = () => (
  <SVG viewBox="0 0 143 84" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="card-error">
    <path
      d="M127.794 32.5631V7.1897C127.794 4.42827 125.555 2.1897 122.794 2.1897H7.17188C4.41045 2.1897 2.17188 4.42827 2.17188 7.18969V70.3723C2.17188 73.1337 4.41045 75.3723 7.17187 75.3723H94.5837"
      stroke="#474747"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.54102 13.156H127.392" stroke="#474747" strokeWidth="3" />
    <path d="M2.17188 24.7327H128.022" stroke="#474747" strokeWidth="3" />
    <path
      d="M140.957 61.3474C140.957 72.9837 131.536 82.4138 119.917 82.4138C108.299 82.4138 98.877 72.9837 98.877 61.3474C98.877 49.7111 108.299 40.281 119.917 40.281C131.536 40.281 140.957 49.7111 140.957 61.3474Z"
      stroke="#474747"
      strokeWidth="3"
    />
    <path d="M9.59766 51.8494H48.3211" stroke="#474747" strokeWidth="3" strokeLinecap="round" />
    <path d="M9.59766 61.3474H33.0928" stroke="#474747" strokeWidth="3" strokeLinecap="round" />
    <path
      d="M111.596 69.6797L128.241 53.0152"
      stroke="#474747"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M128.24 69.6799L111.595 53.0154"
      stroke="#474747"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </SVG>
);

export default ErrorImage;
