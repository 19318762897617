import styled, { css } from 'styled-components';
import prefers from 'znipe-styles/prefers';
import { device } from 'znipe-styles/breakpoints';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${({ $medium }) => ($medium ? 64 : 56)}px;
  padding: 0 ${({ $medium }) => ($medium ? 16 : 8)}px;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme, $compact }) =>
    $compact
      ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(14, 16, 18, 0) 75.83%)'
      : theme.secondaryBackgroundColor};
  box-shadow: ${({ $compact }) => ($compact ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)')};
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ $inline }) => ($inline ? 'row' : 'column')};
  min-width: ${({ $inline }) => ($inline ? 120 : 40)}px;
  padding: 3px;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  & > ${NavItem} {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export const NavIcon = styled.div`
  height: 100%;
  @supports (filter: drop-shadow(0px 0px 0px #000000)) {
    filter: drop-shadow(0px 0px 6px rgba(${({ $shadow }) => convertHexToRGBDigits($shadow)}, 1));
  }
`;

export const NavLabel = styled.div`
  margin-top: 0px;
  opacity: 0;
  width: 0%;
  height: ${({ $inline }) => ($inline ? 'auto' : 0)};
  margin-left: ${({ $inline }) => ($inline ? 8 : 0)}px;
  ${({ $visible, $inline }) =>
    $visible &&
    css`
      opacity: 1;
      height: auto;
      width: 100%;
      margin-top: ${$inline ? 0 : 5}px;
    `};

  @media ${prefers.motion} {
    transition: 0.5s;
    transition-delay: 0.3s;
  }
`;

export const StatsButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  min-width: 0;
  width: 0;
  transform: translateX(100%);
  ${({ $visible }) =>
    $visible
      ? css`
          padding-right: 3px;
          opacity: 1;
          min-width: 60px;
          width: auto;
          transform: translateX(0%);
        `
      : css`
          pointer-events: none;
        `}

  @media ${prefers.motion} {
    transition: 0.3s;
    transition-delay: 0.1s;
  }
`;

export const NavItemLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > *:last-child {
    margin-top: 5px;
  }

  @media ${device.tablet} {
    flex-direction: row;

    & > *:last-child {
      margin-top: initial;
      margin-left: 5px;
    }
  }
`;
