import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { ROUND_END_SUMMARY_EVENT } from 'tv-reducers/intensive';
import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';

export const SUBSCRIBE_ROUND_END_SUMMARY = 'SUBSCRIBE_ROUND_END_SUMMARY';
export const UNSUBSCRIBE_ROUND_END_SUMMARY = 'UNSUBSCRIBE_ROUND_END_SUMMARY';

export const useRoundEndSummaryEpic = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_ROUND_END_SUMMARY, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_ROUND_END_SUMMARY, gatId });
  }, [dispatch, gatId]);
};

export const createRoundEndSummaryEvent = payload =>
  payload?.map(data => ({ ...data, roundEndSummary: data }));

export const getPlayers = payload =>
  payload?.reduce(
    (acc, { players: playersSummary }) =>
      playersSummary?.reduce((playersAcc, player) => {
        const playerObject = createPlayerObject(player);
        return {
          ...playersAcc,
          [playerObject.id]: playerObject,
        };
      }, acc),
    [],
  );

const roundEndSummaryEpic = factorySubscribeEpic(
  [SUBSCRIBE_ROUND_END_SUMMARY, UNSUBSCRIBE_ROUND_END_SUMMARY],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('type').equalTo('RoundEndSummaryEvent'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const players = getPlayers(payload);
      const rounds = createRoundEndSummaryEvent(payload);

      return {
        type: ROUND_END_SUMMARY_EVENT,
        gatId,
        payload: { id: gatId, rounds, players },
      };
    },
);

export default roundEndSummaryEpic;
