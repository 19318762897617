/**
 *
 * @param {number} count The value you are counting, i.e. 5
 * @param {string} type  What is being counted in it's singlular form, i.e. "day". One word only
 * @returns {string} A string with the correct suffix added, i.e. "5 days"
 */
const withSuffix = (count, type) => {
  if (Number.isNaN(count) || count === undefined || typeof type !== 'string') return null;
  if (/[sS]{1}$/.test(type) || /\s/.test(type)) return null;
  if (count > 1) {
    return `${count} ${type}s`;
  }
  if (count === 1) {
    return `${count} ${type}`;
  }
  return `${count} ${type}s`;
};

export default withSuffix;
