import { useCallback } from 'react';
import useIsValidEmoji from './useIsValidEmoji';
import { StringTypes } from '../ChatLockup.types';

const useCalculateStringTypesLength = () => {
  const isValidEmoji = useIsValidEmoji();

  return useCallback(
    (content: StringTypes) => {
      if (typeof content === 'string') {
        const validEmoji = isValidEmoji(content);
        return validEmoji ? 1 : content.length;
      }
      return content.reduce((acc, item) => {
        const itemIsValidEmoji = isValidEmoji(item);
        return acc + (itemIsValidEmoji ? 1 : item.length);
      }, 0);
    },
    [isValidEmoji],
  );
};

export default useCalculateStringTypesLength;
