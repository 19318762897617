export const sizes = {
  xsmall: {
    boxSize: 16,
    leftTranslate: 2.5,
    increment: -0.8,
    marginRight: 8,
  },
  small: {
    boxSize: 24,
    leftTranslate: 0.7,
    increment: -1.5,
    marginRight: 12,
  },
  medium: {
    boxSize: 32,
    leftTranslate: -1,
    increment: -2.2,
    marginRight: 16,
  },
  large: {
    boxSize: 48,
    leftTranslate: -5,
    increment: -3.5,
    marginRight: 16,
  },
};

export default sizes;
