import { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LoginCTA from 'tv-modules/Authentication/LoginCTA/LoginCTA';
import Modal from 'znipe-elements/feedback/Modal/Modal';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import FlourishContainer from 'znipe-elements/data-display/FlourishContainer/FlourishContainer';
import useFlourishValues from 'tv-hooks/useFlourishValues';
import Icon from 'znipe-elements/general/Icon/Icon';
import useThemeContext from 'znipe-hooks/useThemeContext';
import colors from 'znipe-styles/colors';

export const ModalContent = styled.div`
  width: ${({ $size }) => ($size === 'small' ? 512 : 304)}px;
  background: ${({ theme }) => theme.bg2Fade};
  padding: ${({ $size }) => ($size === 'small' ? '0 40px 40px 40px' : '0 16px 24px 16px')};
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.bg2Pop};
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlourishWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: flex-end;
`;

const icons = {
  default: 'znipeLogo',
  proview: 'riotFistbump',
};

const LoginCTAModal = ({ isOpen, description, heading, onCloseClick }) => {
  const { themeName } = useThemeContext();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const { flourish } = useFlourishValues();

  const size = useMemo(() => {
    if (isTabletOrGreater) return 'small';
    return 'xsmall';
  }, [isTabletOrGreater]);

  return (
    <Modal isOpen={isOpen} onCloseClick={onCloseClick} darkMode floatingHeader>
      <Header>
        <Icon type={icons[themeName]} size={56} inline fillColor={colors.white} />
      </Header>
      <ModalContent $size={size}>
        <LoginCTA description={description} heading={heading} size={size} onClose={onCloseClick} />
        <FlourishWrapper>
          <FlourishContainer
            flourish={flourish}
            background="none"
            shouldPreserveAspectRatio={false}
          />
        </FlourishWrapper>
      </ModalContent>
    </Modal>
  );
};

LoginCTAModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default LoginCTAModal;
