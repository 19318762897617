import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import { ONBOARDING_FITLER_TARGET_ID } from '../Filters.constants';
import { Marker } from '../Filters.styles';

const ChildrenWrapper = styled.div`
  margin-right: 6px;
`;

const FilterButton = ({ markerNumber, onClick = () => {} }) => (
  <div data-onboarding-id={ONBOARDING_FITLER_TARGET_ID}>
    <Button variant="secondary" icon="sliders" size="small" onClick={onClick}>
      <ChildrenWrapper>
        <Typography type="title-m">filter</Typography>
      </ChildrenWrapper>
      {markerNumber > 0 && (
        <Marker type="small" data-testid="marker">
          <Typography type="title-xs">{markerNumber}</Typography>
        </Marker>
      )}
    </Button>
  </div>
);

FilterButton.propTypes = {
  markerNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default FilterButton;
