import { ReactNode, useMemo, useState, useCallback, useRef } from 'react';
import uniqueArray from 'znipe-utils/array/uniqueArray';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useEmojiSearch } from 'znipe-chat/src/hooks/useEmojiSearch';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import EmojiTile from '../EmojiTile/EmojiTile';
import ContentSection from '../../ContentSection/ContentSection';
import { Container, TilesWrapper } from './EmojiCollection.styles';
import { InputWrapper, EmojiSearchInput } from '../Emojis.styles';
import { Emoji } from '../Emojis.types';

type EmojiGroups = { [key: string]: Emoji[] };

type EmojiCollectionProps = {
  onSelect?: (shortcode: string) => void;
  hasSearch?: boolean;
  minHeight?: number;
};

const getGroupedEmojisByCategory = (allEmojis: Emoji[] = []) =>
  uniqueArray(allEmojis.map(({ categoryDisplayName }) => categoryDisplayName))
    .sort((a: string, b: string) => (a.toUpperCase() < b.toUpperCase() ? -1 : 1))
    .reduce<EmojiGroups>(
      (acc: EmojiGroups, category: string) => ({
        ...acc,
        [category]: allEmojis.filter(({ categoryDisplayName }) => categoryDisplayName === category),
      }),
      {},
    );

const EmojiCollection: React.FC<EmojiCollectionProps> = ({
  onSelect,
  hasSearch = true,
  minHeight,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputMessage, setInputMessage] = useState('');
  const searchResult = useEmojiSearch(inputMessage);
  const { allEmojis } = useChatContext() ?? {};
  const groupedEmojis = useMemo(() => getGroupedEmojisByCategory(allEmojis), [allEmojis]);
  const emojiSections = useMemo(
    () =>
      Object.entries(groupedEmojis).map<ReactNode>(([category, emojis]) => (
        <ContentSection
          key={category}
          header={category}
          data-testid={`emoji-section-${emojis?.[0]?.category}`}
        >
          <TilesWrapper>
            {emojis.map(({ url, shortcode }) => (
              <EmojiTile
                src={url}
                size="small"
                key={shortcode}
                label={shortcode}
                onClick={onSelect}
              />
            ))}
          </TilesWrapper>
        </ContentSection>
      )),
    [groupedEmojis, onSelect],
  );

  const searchResultElements = useMemo(
    () =>
      searchResult?.map(emoji => {
        if (!emoji) return null;
        const { url, shortcode } = emoji;
        return (
          <EmojiTile src={url} size="small" key={shortcode} label={shortcode} onClick={onSelect} />
        );
      }),
    [searchResult, onSelect],
  );

  const searchSection = useMemo(() => {
    const hasResults = searchResult?.length > 0;
    return (
      <ContentSection
        header={hasResults ? 'Search results' : 'No results found'}
        data-testid="search-header"
      >
        {hasResults && <TilesWrapper>{searchResultElements}</TilesWrapper>}
      </ContentSection>
    );
  }, [searchResult, searchResultElements]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputMessage(value);
  }, []);

  const handleClick = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  return (
    <Container $minHeight={minHeight}>
      {hasSearch && (
        <InputWrapper onClick={handleClick}>
          <EmojiSearchInput
            ref={inputRef}
            placeholder="Search emojis..."
            onChange={handleChange}
            value={inputMessage}
          />
          <Icon type="search" size={16} inline />
        </InputWrapper>
      )}
      {inputMessage.length > 0 ? searchSection : emojiSections}
    </Container>
  );
};

export default EmojiCollection;
