import config from 'tv-config/config';
import {
  GET_PRODUCT_V4_REQUEST,
  GET_PRODUCT_V4_SUCCESS,
  GET_PRODUCT_V4_FAILURE,
  GET_PRODUCTS_V4_REQUEST,
  GET_PRODUCTS_V4_SUCCESS,
  GET_PRODUCTS_V4_FAILURE,
  PURCHASE_PRODUCT_V4_REQUEST,
  PURCHASE_PRODUCT_V4_SUCCESS,
  PURCHASE_PRODUCT_V4_FAILURE,
  GET_PRODUCT_V4_PRICE_REQUEST,
  GET_PRODUCT_V4_PRICE_SUCCESS,
  GET_PRODUCT_V4_PRICE_FAILURE,
  CANCEL_PRODUCT_SUBSCRIPTION_REQUEST,
  CANCEL_PRODUCT_SUBSCRIPTION_SUCCESS,
  CANCEL_PRODUCT_SUBSCRIPTION_FAILURE,
  GET_PRODUCT_V4_RECEIPTS_REQUEST,
  GET_PRODUCT_V4_RECEIPTS_SUCCESS,
  GET_PRODUCT_V4_RECEIPTS_FAILURE,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  GET_PRODUCT_V4_RECEIPT_SECRET_REQUEST,
  GET_PRODUCT_V4_RECEIPT_SECRET_SUCCESS,
  GET_PRODUCT_V4_RECEIPT_SECRET_FAILURE,
  ADD_PROMO_CODE_REQUEST,
  ADD_PROMO_CODE_SUCCESS,
  ADD_PROMO_CODE_FAILURE,
} from 'tv-constants/oldRedux';

const { API_URL } = config;
const API_URL_V4 = `${API_URL}/v4`;
const API_PRODUCTS_BASE_V4 = `${API_URL_V4}/products`;

const objectToQuery = options => {
  if (typeof options === 'undefined') return '';
  const keys = Object.keys(options);
  if (keys.length === 0) return '';
  const query = keys
    .reduce((obj, key) => {
      obj.push(`${key}=${encodeURIComponent(options[key])}`);
      return obj;
    }, [])
    .join('&');
  return `?${query}`;
};

export const getProduct = (productId, options) => ({
  types: [GET_PRODUCT_V4_REQUEST, GET_PRODUCT_V4_SUCCESS, GET_PRODUCT_V4_FAILURE],
  shouldCallAPI: ({ products }) => {
    if (!products[productId]) return true;
    const status = products[productId]?.status;
    if (status === 'request') return false;
    if (status === 'complete') return false;
    return true;
  },
  callAPI: () =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}${objectToQuery(options)}`, {
      method: 'GET',
    }),
  payload: {
    productId,
  },
});

export const getProducts = options => ({
  types: [GET_PRODUCTS_V4_REQUEST, GET_PRODUCTS_V4_SUCCESS, GET_PRODUCTS_V4_FAILURE],
  callAPI: () =>
    fetch(`${API_PRODUCTS_BASE_V4}${objectToQuery(options)}`, {
      method: 'GET',
    }),
});

export const cancelProductSubscription = (productId, feedback, options) => ({
  types: [
    CANCEL_PRODUCT_SUBSCRIPTION_REQUEST,
    CANCEL_PRODUCT_SUBSCRIPTION_SUCCESS,
    CANCEL_PRODUCT_SUBSCRIPTION_FAILURE,
  ],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/edit-subscription${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify({ action: 'cancel', data: { feedback } }),
    }),
});

export const editProductSubscriptionPaymentMethod = (productId, provider, data, options) => ({
  types: [
    UPDATE_PAYMENT_METHOD_REQUEST,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_FAILURE,
  ],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/edit-subscription${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify({ provider, data, action: 'update_payment_method' }),
    }),
});

export const addProductSubscriptionPromoCode = (productId, options) => ({
  types: [ADD_PROMO_CODE_REQUEST, ADD_PROMO_CODE_SUCCESS, ADD_PROMO_CODE_FAILURE],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/edit-subscription${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify({ action: 'add_promo_code' }),
    }),
});

export const subscribeToProduct = (productId, provider, data, options) => ({
  types: [PURCHASE_PRODUCT_V4_REQUEST, PURCHASE_PRODUCT_V4_SUCCESS, PURCHASE_PRODUCT_V4_FAILURE],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/subscribe${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ provider, data }), // subscribe
    }),
});

export const purchaseProduct = (productId, provider, data, options) => ({
  types: [PURCHASE_PRODUCT_V4_REQUEST, PURCHASE_PRODUCT_V4_SUCCESS, PURCHASE_PRODUCT_V4_FAILURE],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/purchase${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ provider, data }),
    }),
});

export const getProductPrice = (productId, options) => ({
  types: [GET_PRODUCT_V4_PRICE_REQUEST, GET_PRODUCT_V4_PRICE_SUCCESS, GET_PRODUCT_V4_PRICE_FAILURE],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/price${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'GET',
    }),
});

export const getProductReceipts = (productId, options) => ({
  types: [
    GET_PRODUCT_V4_RECEIPTS_REQUEST,
    GET_PRODUCT_V4_RECEIPTS_SUCCESS,
    GET_PRODUCT_V4_RECEIPTS_FAILURE,
  ],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/receipts${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'GET',
    }),
});

export const getProductReceiptSecret = (productId, options) => ({
  types: [
    GET_PRODUCT_V4_RECEIPT_SECRET_REQUEST,
    GET_PRODUCT_V4_RECEIPT_SECRET_SUCCESS,
    GET_PRODUCT_V4_RECEIPT_SECRET_FAILURE,
  ],
  callAPI: token =>
    fetch(`${API_PRODUCTS_BASE_V4}/${productId}/secrets/paymentIntent${objectToQuery(options)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'GET',
    }),
});
