import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isRiotDomain } from 'tv-utils/pageUtils';
import { logoutUser } from 'tv-actions/old/auth';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Typography from 'znipe-elements/general/Typography/Typography';
import { logout as riotLogout } from '../utils/riotAuthHelper';

export const LogoutTypography = styled(Typography)`
  margin-left: 8px;
`;

const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packageSlug } = useParams();
  const logout = useCallback(() => {
    if (isRiotDomain()) riotLogout(() => dispatch(logoutUser()));
    else {
      const route = packageSlug ? `/${packageSlug}/logout` : '/logout';
      navigate(route, { replace: true });
    }
  }, [dispatch, navigate, packageSlug]);
  return (
    <Button variant="text" size="small" icon="logout" onClick={logout}>
      Log Out
    </Button>
  );
};

export default memo(LogoutButton);
