import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import Link from 'znipe-elements/general/Link/Link';
import PropTypes from 'prop-types';
import { device } from 'znipe-styles/breakpoints';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  width: 100%;
  height: calc(100% - 16px);
  @media ${device.desktop} {
    height: calc(100% - 20px);
  }
  @media ${device.desktopLarge} {
    height: calc(100% - 24px);
  }
  padding: 8px;
  color: ${({ theme }) => theme.primaryTextColor};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondaryBakgroundFade};
  }
`;

export const TextWrapper = styled.div`
  max-width: 90px;
  position: relative;
`;

export const IconWrapper = styled.div`
  height: 10px;
  margin-bottom: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const FullScheduleButton = ({ link = '' }) => (
  <Link href={link} hideUnderline>
    <Container>
      <TextWrapper>
        <Typography type="title-s">Full schedule</Typography>
        <IconWrapper>
          <Icon type="chevronRight" />
        </IconWrapper>
      </TextWrapper>
    </Container>
  </Link>
);

FullScheduleButton.propTypes = {
  link: PropTypes.string,
};

export default FullScheduleButton;
