import styled, { css } from 'styled-components';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import { XSMALL, SMALL, MEDIUM } from 'znipe-constants/loading';
import { LOGO, CHAMPION, STREAM_SELECTOR } from './CardLoading.constants';

export const Card = styled.div`
  background: ${({ theme }) => convertHexToRGB(theme.secondaryBackgroundColor)};
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({ $type, $size }) => {
    if ($type === STREAM_SELECTOR) {
      return css`
        padding: 0;
      `;
    }
    if ($type === LOGO) {
      switch ($size) {
        case MEDIUM:
          return css`
            padding: 48px 0;
          `;
        case SMALL:
          return css`
            padding: 25px 0;
          `;
        default:
          return css`
            padding: 15px 0;
          `;
      }
    }
    return css`
      padding: 16px 0;
    `;
  }}
  width: 100%;
  ${({ $type, $size }) => {
    if ($type === STREAM_SELECTOR) {
      return css`
        width: 64px;
        height: 88px;
      `;
    }
    switch ($size) {
      case MEDIUM:
        return css`
          min-width: ${$type === LOGO ? 296 : 192}px;
          min-height: ${$type === LOGO ? 300 : 248}px;
          max-width: 400px;
        `;
      case SMALL:
        return css`
          min-width: 160px;
          max-width: 296px;
          min-height: 208px;
        `;
      default:
        return css`
          min-width: 128px;
          max-width: 159px;
          min-height: 168px;
        `;
    }
  }}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: ${({ $width }) => $width}px;
  margin-top: 10px;

  ${({ $type }) =>
    $type === CHAMPION &&
    css`
      > div:first-child {
        margin-bottom: ${({ $textGap }) => $textGap}px;
        width: 70%;
      }
    `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 20px;
`;

export const IconCompensation = styled.div`
  height: ${({ $size }) => ($size === XSMALL ? 24 : 40)}px;
`;
