import styled, { css, keyframes } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';
import { Link } from 'react-router-dom';

const blockAnimation = css`
  *,
  *:before,
  *:after {
    animation-play-state: paused !important;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  background-color: ${colors.white};
  color: ${props => props.theme.subscribe.color || props.theme.textPrimary};
  @media ${device.tablet} {
    padding: 70px 85px 0;
    display: flex;
    justify-content: center;
    background: url(${props => props.theme.subscribe.backgroundImageTablet});

    background-repeat: no-repeat;
    background-size: 120%;
    background-position: bottom;
    background-color: black;
  }
  @media ${device.desktop} {
    background: url(${props => props.theme.subscribe.backgroundImageDesktop});
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: bottom;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
  }

  ${props => props.isLoading && blockAnimation}
`;

export const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.subscribe?.backgroundColor || theme.secondaryBackgroundColor};
  @media ${device.tablet} {
    max-width: 600px;
    border-radius: 8px;
  }
`;

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

const normalCss = css`
  opacity: 1;
`;
const animateCss = css`
  opacity: 0;
  animation: ${fadeIn} ${props => props.animationTime}s ${props => props.animationDelay}s ease-out
    forwards;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    ${normalCss}
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 45px 15px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => (props.animate ? animateCss : normalCss)}

  @media ${device.tablet} {
    padding: 45px 50px 50px;
  }
`;

export const PageHeader = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 5px;
  left: 0;
  height: 24px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.subscribe.backButtonColor};
  font-size: 14px;
  font-weight: normal;
  line-height: 100%;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 25px;
  width: 75px;

  @media ${device.desktop} {
    width: 90px;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  ${({ $mobile }) =>
    $mobile
      ? css`
          top: 15px;
          right: 25px;
        `
      : css`
          top: 70px;
          left: calc(50% + 310px);
        `}
`;
