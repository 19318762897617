import { convertHexToRGBObject } from 'znipe-utils/misc/convertHexToRGB';

const convertHexColorToHSL = hex => {
  let { r, g, b } = convertHexToRGBObject(hex);

  // RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;
  h = Math.round(h * 60);
  if (h < 0) h += 360;
  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  return {
    hue: h,
    saturation: s,
    lightness: l,
  };
};

const formatHSLString = (h, s, l, a) =>
  a ? `hsla(${h},${s}%,${l}%,${a})` : `hsl(${h},${s}%,${l}%)`;

const addTogether = (HSLColorObject = {}, hslObject = {}) => {
  const { hue, saturation, lightness } = HSLColorObject;
  const { h: hueToAdd = 0, s: saturationToAdd = 0, l: lightnessToAdd = 0, a } = hslObject;
  const h = hue + hueToAdd;
  const s = saturation + saturationToAdd;
  const l = lightness + lightnessToAdd;

  return a ? { h, s, l, a } : { h, s, l };
};

const selectColors = (HSLColorObject, hslObject = {}) => {
  const { hue, saturation, lightness } = HSLColorObject;
  const { h: providedHue, s: providedSaturation, l: providedLightness, a } = hslObject;
  const h = providedHue || hue;
  const s = providedSaturation || saturation;
  const l = providedLightness || lightness;

  return a ? { h, s, l, a } : { h, s, l };
};

const convertHexToHSL = (hex, hslObject) => {
  const HSLColorObject = convertHexColorToHSL(hex);
  const { h, s, l, a } = hslObject?.addMode
    ? addTogether(HSLColorObject, hslObject)
    : selectColors(HSLColorObject, hslObject);

  return formatHSLString(h, s, l, a);
};

export default convertHexToHSL;
