import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetUsername = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.username ?? '');
};

export const useUsername = () => useMemoizedSelector(makeGetUsername);

export default makeGetUsername;
