import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withTheme from 'znipe-themes/hocs/withTheme';
import { device } from 'znipe-styles/breakpoints';
import Link from 'znipe-elements/general/Link/Link';
import themes from './Footer.themes';

const Container = styled.div`
  padding: 20px 0px;
  display: grid;
  grid-row-gap: 5px;
  grid-template-rows: repeat(3, min-content);
  justify-items: center;
  background-color: ${({ theme }) => theme.footer.backgroundColor};

  @media ${device.desktop} {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: initial;
    justify-content: center;
    grid-column-gap: 40px;
  }
`;

const Footer = ({
  links = [
    {
      href: 'https://support-leagueoflegends.riotgames.com/hc/en-us/requests/new',
      label: 'Help & Support',
    },
    {
      href: 'https://support-leagueoflegends.riotgames.com/hc/en-us/articles/360023636673-Pro-View-FAQ',
      label: 'FAQs',
    },
    {
      to: '/terms-of-use',
      label: 'Terms & Conditions',
    },
  ],
}) => (
  <Container data-testid="settings-footer">
    {links.map(({ to, label, href }) => (
      <Link key={to || href} to={to} href={href}>
        {label}
      </Link>
    ))}
  </Container>
);

Footer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default withTheme(memo(Footer), themes, 'footer');
