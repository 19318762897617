import PropTypes from 'prop-types';
import PlayerImage from 'znipe-elements/data-display/PlayerImage/PlayerImage';
import LOLAvatar from 'znipe-elements/data-display/Avatar/LOLAvatar/LOLAvatar';
import SelectorHorizontal from '../SelectorHorizontal';
import { propTypes } from '../../constants';

const lolAvatarSizeMap = { small: 'small', medium: 'large' };

const PlayerSelector = ({
  label,
  size = 'small',
  image = '',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
  game,
  teamLogo = '',
  championImage = '',
  level,
  kda,
  teamSide,
}) => {
  const rightArea =
    game === 'lol' && !!championImage ? (
      <LOLAvatar
        imageUrl={championImage}
        size={lolAvatarSizeMap[size]}
        level={level}
        levelStyle="circle"
        levelPosition="right"
      />
    ) : null;
  return (
    <SelectorHorizontal
      streamType="player"
      label={label}
      size={size}
      leftArea={<PlayerImage image={image} teamLogo={teamLogo} />}
      kda={kda}
      rightArea={rightArea}
      teamSide={teamSide}
      isSelected={isSelected}
      isLocked={isLocked}
      hasPremiumAccess={hasPremiumAccess}
      game={game}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      data-testid={testId}
    />
  );
};

PlayerSelector.propTypes = {
  ...propTypes,
  teamSide: PropTypes.oneOf(['none', 'teamOne', 'teamTwo']),
  teamLogo: PropTypes.string,
  championImage: PropTypes.string,
  level: PropTypes.number,
};

export default PlayerSelector;
