import styled, { css } from 'styled-components';

export const ResultTableColumnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
  gap: 8px;
  ${({ $highLighted }) =>
    !$highLighted &&
    css`
      opacity: 0.5;
    `};
`;

export const SelectResultTableColumnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 8px 0px 8px;
  gap: 8px;
`;

export const GridWrapper = styled.div`
  margin-top: 12px;
`;

export const IconWrapper = styled.div`
  margin-top: 2px;
`;

export const ThreeOptionsLabelContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
