import { SET_SEARCH_HISTORY_QUERY, SET_SEARCH_HISTORY_RESULT } from 'tv-constants/redux';

export const setSearchHistoryQuery = (searchHistoryQuery = '') => ({
  type: SET_SEARCH_HISTORY_QUERY,
  query: searchHistoryQuery,
});

export const setSearchHistoryResult = (category = '', searchResultHistory = {}) => ({
  type: SET_SEARCH_HISTORY_RESULT,
  result: searchResultHistory,
  category,
});
