import { memo } from 'react';
import PropTypes from 'prop-types';
import PlayerCard from 'tv-elements/data-display/PlayerCard/PlayerCard';
import useTopicLink from 'tv-hooks/useTopicLink';
import { SMALL, MEDIUM, LARGE, AUTO } from 'tv-modules/TopicHeader/TopicHeader.constants';
import Link from 'znipe-elements/general/Link/Link';
import { useGetPlayerGameTitle } from 'tv-selectors/players/makeGetPlayerGameTitle';
import colors from 'znipe-styles/colors';
import { PlayerCardWrapper } from '../TopicAbout.styles';

const TeamMateCard = ({ id, image, nickname, teamLogo, size = AUTO, isSelf = false }) => {
  const game = useGetPlayerGameTitle({ playerId: id });
  const playerLink = useTopicLink(nickname, 'player', game);
  return (
    <Link to={playerLink} data-testid="teammate-card" disabled={isSelf} textColor={colors.white}>
      <PlayerCardWrapper key={id}>
        <PlayerCard
          isHomeTeam
          teamLogo={teamLogo}
          playerImage={image}
          label={nickname}
          noMarker
          transparentBackground
          size={size === AUTO ? undefined : size}
          skipAnimation
        />
      </PlayerCardWrapper>
    </Link>
  );
};

TeamMateCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  teamLogo: PropTypes.string.isRequired,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE, AUTO]),
  isSelf: PropTypes.bool,
};

export default memo(TeamMateCard);
