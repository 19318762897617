import styled, { css } from 'styled-components';
import Cleave from 'cleave.js/react';
import Typography from 'znipe-elements/general/Typography/Typography';
import omitProps from '@hocs/omit-props';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $hideOverflow = true }) =>
    $hideOverflow &&
    css`
      overflow: hidden;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    `};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.isHorizontal ? 'column' : 'row')};
  align-items: ${props => (props.isHorizontal ? 'flex-start' : 'center')};
  box-sizing: border-box;
  line-height: ${({ theme: { input } }) => input.lineHeight};
  height: 100%;
  width: 100%;
`;

export const Label = styled.div`
  display: flex;
  pointer-events: none;
  color: ${({ theme, error, lightLabel }) => {
    if (error) return theme.errorRed;
    if (lightLabel) return theme.secondaryTextColor;
    return theme.textSecondary;
  }};
  text-transform: capitalize;
  white-space: nowrap;
  p {
    margin-bottom: unset;
  }
  ${({ isHorizontal }) =>
    isHorizontal
      ? css`
          margin-bottom: 5px;
        `
      : css`
          padding-right: 16px;
        `};
  ${({ centerAlign, labelAlign }) => css`
    flex-basis: ${centerAlign !== '' ? centerAlign : 'auto'};
    justify-content: ${labelAlign};
  `}
  ${({ centerAlign }) =>
    centerAlign &&
    css`
      width: calc(${centerAlign} + 2px);
    `}
`;

export const activeLabelPosition = css`
  transform: translateX(-12px) translateY(-200%);
  left: 0px;
  font-size: 12px;
`;

export const InlineLabelContent = styled.span`
  display: flex;
  position: absolute;
  top: ${({ theme }) => theme.input.height};
  left: 0px;
  transform: translateY(-110%);
  transition: all 0.3s ease;

  ${props => props.hasData && activeLabelPosition}
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const InlineLabel = styled.label`
  position: absolute;
  bottom: 0px;
  left: 20px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: ${({ theme, error }) => {
    if (error) return theme.errorRed;
    return theme.textSecondary;
  }};
  ${({ focus }) =>
    focus &&
    css`
      ${InlineLabelContent} {
        ${activeLabelPosition}
      }
    `}

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: translateX(${props => (props.focus ? 0 : -100)}%);

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
`;

export const inputFocusCss = css`
  border: 1px solid
    ${({ error, theme: { input, UIAccent, errorRed } }) => {
      if (error) return errorRed;

      return input.borderColor?.focus || UIAccent;
    }};
  background-color: ${({ theme }) => theme.input.bgColor.focus || theme.input.bgColor.default};
  outline: none;
`;

export const inputBaseCss = ({ theme, error, isInline, centerAlign, hasTopMargin, disabled }) => {
  const { input = {} } = theme;
  return css`
    width: ${centerAlign !== '' ? `calc(100% - ${centerAlign})` : '100%'};
    height: ${isInline ? '70px' : input.height};
    min-height: ${isInline ? '70px' : input.height};
    padding-top: ${isInline ? input.horizontalPadding : input.verticalPadding};
    padding-bottom: ${input.verticalPadding};
    padding-right: ${input.horizontalPadding};
    padding-left: ${isInline ? '8px' : input.horizontalPadding};
    outline: none;
    color: ${input.textColor || theme.primaryTextColor};
    background-color: ${input.bgColor?.default};
    border-radius: ${input.borderRadius};
    ${
      hasTopMargin &&
      css`
      margin-top: 8px;
    `
    }
    ${
      error
        ? css`
          border: ${input.errorBorderWidth} solid ${theme.errorRed};
        `
        : css`
          border: 1px solid ${input.borderColor?.default || theme.containerStroke};
        `
    };
    ${
      disabled &&
      css`
      cursor: not-allowed;
      opacity: 0.5;
    `
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  `;
};

export const inputStyles = css`
  ${inputBaseCss};
  ${({ centerAlign, customWidth }) => {
    let calcValue = '100%';
    if (centerAlign !== '') {
      calcValue = customWidth || `calc(100% - ${centerAlign})`;
    }
    return css`
      width: ${customWidth || '100%'};
      flex-basis: ${calcValue};
      justify-content: flex-start;
    `;
  }}

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  ${({ focus }) => focus && inputFocusCss}

  &:focus {
    ${inputFocusCss}
  }

  &:focus + ${InlineLabel} {
    ${InlineLabelContent} {
      ${activeLabelPosition}
    }
  }

  &:focus + ${/* sc-selector */ InlineLabel}::after {
    transform: translateX(0%);
  }

  &::placeholder {
    font-weight: normal;
    ${({ theme: { input } }) => css`
      color: ${input.placeholder.color};
      font-style: ${input.placeholder.fontStyle};
    `}
  }
`;

export const CleaveInput = styled(omitProps('isInline', 'error', 'focus')(Cleave))`
  ${inputStyles}
`;

export const NormalInput = styled(Typography).attrs(props => ({
  forwardedAs: 'input',
  type: 'paragraph-m',
  domType: props.type,
  dataTestId: props['data-testid'],
}))`
  margin: 0;
  ${inputStyles}
`;

export const Required = styled.div`
  padding-left: ${({ theme: { input } }) => input.marginAsterisk};
  color: ${({ theme }) => theme.UIAccent};
  &::after {
    content: '*';
  }
`;

export const MaxLength = styled.div`
  padding-left: 15px;
  font-weight: bold;
  color: ${({ theme: { input }, isCloseToMax, isMax }) => {
    if (isMax) {
      return input.maxLengthColor.max;
    }
    if (isCloseToMax) {
      return input.maxLengthColor.almostMax;
    }
    return input.maxLengthColor.default;
  }};
`;

const paddingLeftCss = css`
  padding-left: ${({ centerAlign, customWidth }) => {
    let calcValue = centerAlign || '0px';
    if (customWidth !== '') {
      calcValue = centerAlign || `calc(100% - ${centerAlign})`;
    }
    return calcValue;
  }};
`;

export const ErrorMessage = styled.div`
  padding-top: 10px;
  color: ${({ theme: { input } }) => input.errorMessageColor};
  ${paddingLeftCss}
`;

export const leftEndElementCss = css`
  position: absolute;
  color: ${({ theme }) => theme.secondaryTextColor};
  height: ${({
    theme: {
      input: { height, verticalPadding },
    },
  }) => `calc(${height} - 2 * ${verticalPadding})`};
  padding-left: 12px;
  text-align: left;
  ${({
    labelPosition,
    theme: {
      input: { verticalPadding },
    },
  }) =>
    labelPosition === 'top' &&
    css`
      bottom: calc(${verticalPadding});
    `}
`;

export const rightEndElementCss = css`
  ${leftEndElementCss};
  width: ${({ width }) => width};
  padding-left: unset;
  padding-right: 12px;
  text-align: right;
`;

const prefixAndSuffixStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${({ centerAlign, width }) => (width === '100%' ? 0 : centerAlign)};
  pointer-events: none;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const Prefix = styled.span`
  ${leftEndElementCss};
  ${prefixAndSuffixStyle};
`;

export const Suffix = styled.span`
  ${rightEndElementCss};
  ${prefixAndSuffixStyle};
`;

export const Comment = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  margin-top: 4px;
  p {
    margin-bottom: unset;
  }
  ${paddingLeftCss}
`;

export const TransparentInput = styled.input`
  ${inputStyles}
  border: none;
  height: 100%;
  width: 100%;
  min-height: unset;
  padding: 0;
  background-color: ${({ theme }) => theme.input.bgColor?.default};

  &:focus {
    border: none;
    background-color: transparent;
  }

  ::placeholder {
    ${({ theme: { input } }) => css`
      color: ${input.placeholder.color};
      font-style: ${input.placeholder.fontStyle};
    `}
  }
`;
