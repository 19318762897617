import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';

const getChampionIdFromProps = (_, props) => props.championId ?? '';
const getLimitFromProps = (_, props) => props.limit ?? 5;

const emptyArray = [];

export const makeGetSelectedChampionsSameClass = () => {
  const getChampions = makeGetChampions();
  return createSelector([getChampions, getChampionIdFromProps], (allChampions, championId) => {
    if (!allChampions || !championId) return emptyArray;
    return allChampions[championId]?.otherOfSameClass ?? emptyArray;
  });
};

const makeGetOtherClassChampions = () => {
  const getChampions = makeGetSelectedChampionsSameClass();
  return createSelector([getChampions, getLimitFromProps], (champions, limit) => {
    if (!champions) return emptyArray;
    if (typeof limit !== 'number') return champions;
    return champions.slice(0, limit) ?? emptyArray;
  });
};

export const useGetOtherClassChampions = props =>
  useMemoizedGqlSelector(makeGetOtherClassChampions, props);

export default makeGetOtherClassChampions;
