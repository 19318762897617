import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';
import makeGetSubscribedChampionIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedChampionIds';

const emptyArray = [];

const makeGetSubscribedChampions = () => {
  const getChampions = makeGetChampions();
  const getSubscribedChampionIds = makeGetSubscribedChampionIds();
  return createSelector(
    [getChampions, getSubscribedChampionIds],
    (allChampions, subscribedChampions) => {
      if (subscribedChampions.length === 0) return emptyArray;
      return subscribedChampions.map(id => ({ ...allChampions?.[id], _type: 'champions' }));
    },
  );
};

export const useGetSubscribedChampions = props =>
  useMemoizedGqlSelector(makeGetSubscribedChampions, props);

export default makeGetSubscribedChampions;
