import produce from 'immer';
import deepmerge from 'deepmerge';
import { SET_GLOBAL_STREAMS } from 'tv-constants/redux';

export const initialState = {};

const globalStreamsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_STREAMS:
      Object.entries(payload).forEach(([key, value]) => {
        const overwriteMerge = (_destinationArray, sourceArray) => sourceArray;
        const oldState = state[key] || {};
        return (state[key] = deepmerge(oldState, value, { arrayMerge: overwriteMerge }));
      });
      return state;

    default:
      return state;
  }
}, initialState);

export default globalStreamsReducer;
