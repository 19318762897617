import { useState, useEffect } from 'react';

// taken from https://usehooks.com/useOnScreen/
const useOnScreen = (ref, rootMargin = '0px', callback) => {
  if (typeof rootMargin !== 'string') {
    throw Error('rootMargin must be a string, i.e. 50px');
  }

  if (typeof ref === 'undefined') {
    throw Error('a valid ref must be defined');
  }

  if (callback && typeof callback !== 'function') {
    throw Error('callback should be a valid function');
  }

  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (callback) callback(isIntersecting);
  }, [isIntersecting, callback]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!ref.current) return undefined;
    const observedElement = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );

    if (observedElement) {
      observer.observe(observedElement);
    }
    return () => {
      observer.unobserve(observedElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export default useOnScreen;
