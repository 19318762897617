import PropTypes from 'prop-types';

export const weaponIdentifiers = [
  'knife',
  'glock',
  'p250',
  'usp_silencer',
  'usp_silencer_off',
  'hegrenade',
  'hkp2000',
  'fiveseven',
  'elite',
  'deagle',
  'revolver',
  'cz75a',
  'tec9',
  'm4a1',
  'm4a1_silencer',
  'm4a1_silencer_off',
  'aug',
  'awp',
  'famas',
  'scar20',
  'g3sg1',
  'ssg08',
  'galilar',
  'ak47',
  'sg556',
  'nova',
  'xm1014',
  'mag7',
  'm249',
  'negev',
  'mp9',
  'mp7',
  'ump45',
  'p90',
  'bizon',
  'mac10',
];

const CSGOWeaponIcon = ({ weapon }) => {
  const WeaponURL = `https://assets.znipe.tv/csgo/icons/${
    weaponIdentifiers.includes(weapon) ? weapon : 'kills'
  }.svg`;

  return <img src={WeaponURL} alt="weapon-icon" />;
};

CSGOWeaponIcon.propTypes = {
  weapon: PropTypes.string,
};

export default CSGOWeaponIcon;
