import { useState } from 'react';
import useDeepCompareEffect from 'znipe-hooks/useDeepCompareEffect';
import useVideosWithRefs from 'znipe-player/src/hooks/useVideosWithRef';
import selectMaster from 'znipe-player/src/utils/selectMaster';
import ZnipeEvent from 'znipe-utils/events/ZnipeEvent';
import {
  SYNC_INTERVAL,
  MAX_TIME_DIFF_TO_SYNC,
  MAX_TIME_DIFF_TO_SOFT_SYNC,
  MIN_TIME_DIFF_TO_SYNC,
  MIN_TIME_DIFF_TO_STOP_SYNC,
  PLAYBACK_RATE_CHANGE,
  SEEK_RANGE_THRESHOLD,
} from 'znipe-player/src/constants';
import estimateLoadTime from 'znipe-player/src/utils/estimateLoadTime';
import { log } from 'znipe-logger';
import { useFeatureFlag } from 'znipe-link/link';

export const handleSyncing = (master = {}, slave = {}, noLimit = false, useTTFB = false) => {
  const { video: masterVideo = { currentTime: 0 }, offset: masterOffset = 0 } = master;
  const {
    video: slaveVideo = { currentTime: 0 },
    offset: slaveOffset = 0,
    ref: slaveRef = {},
  } = slave;
  if (!masterVideo || !slaveVideo) return false;
  if (slaveVideo.paused) {
    return false;
  }
  const slavePlayer = slaveRef?.current;

  if (!slavePlayer || slavePlayer.getLoadMode() < 2 || slavePlayer.isBuffering()) return false;
  const { playbackRate } = masterVideo;
  const timeDiff = masterVideo.currentTime + masterOffset - (slaveVideo.currentTime + slaveOffset);
  const absTimeDiff = Math.abs(timeDiff);
  const { end } = slavePlayer.seekRange();
  if (end > 0 && end + SEEK_RANGE_THRESHOLD < slaveVideo.currentTime + timeDiff) {
    masterVideo.currentTime -= slaveVideo.currentTime + timeDiff - end;
    return false;
  }

  if (absTimeDiff <= MIN_TIME_DIFF_TO_STOP_SYNC) {
    slaveVideo.playbackRate = playbackRate; // Reset playback rate
    return true;
  }
  if (absTimeDiff <= MIN_TIME_DIFF_TO_SYNC) {
    return slaveVideo.playbackRate === playbackRate;
  }
  if (absTimeDiff <= MAX_TIME_DIFF_TO_SOFT_SYNC) {
    // When swapping the master, the pbr is 0 for a while causing syncing to fail
    if (playbackRate === 0) return false;
    // Time diff is positive if master is ahead
    const pbrChange = timeDiff > 0 ? PLAYBACK_RATE_CHANGE : -PLAYBACK_RATE_CHANGE;
    // Actually just playbackRate + pbrChange. However, JS rounds very weirdly when using floats.
    // This helps with these rounding issues
    slaveVideo.playbackRate =
      (parseInt(playbackRate * 100, 10) + parseInt(pbrChange * 100, 10)) / 100;
    return false;
  }
  // We normally have a limit on sync time because if it's a huge diff, then
  if (noLimit || absTimeDiff <= MAX_TIME_DIFF_TO_SYNC) {
    const { start: bufferStart, end: bufferEnd } = slavePlayer.getBufferedInfo()?.video?.[0] ?? {};
    const newTime = slaveVideo.currentTime + timeDiff;
    const loadPadding =
      newTime >= bufferStart && newTime <= bufferEnd ? 0 : estimateLoadTime(slavePlayer, useTTFB);

    log('sync seek', { loadPadding, newTime, bufferStart, bufferEnd });

    if (slave.AnalyticsListener) {
      slave.AnalyticsListener.sendSeekedEvent(
        slaveVideo.currentTime,
        newTime + loadPadding,
        'sync',
      );
    }
    slaveVideo.currentTime = newTime + loadPadding;

    return false;
  }
  return false;
};

const sync = (
  videosWithRef,
  master,
  setDebugInfo,
  sendDebugEvent,
  externalSyncRef,
  pbr,
  ref,
  useTTFB = false,
) => {
  const newDebugInfo = {};
  const hasExternalSyncRef = !!externalSyncRef?.current;

  // If we have an externalSyncRef, then create a fake video object to sync towards
  const masterPlayer = master?.ref?.current || {};
  const masterSyncInfo = hasExternalSyncRef
    ? {
        offset: externalSyncRef?.current?.offset || 0,
        video: {
          currentTime: externalSyncRef?.current.currentTime || 0,
          playbackRate: externalSyncRef?.current.playbackRate || 1,
          paused: false,
        },
      }
    : {
        ...master,
        video: masterPlayer?.getMediaElement?.() ?? {},
      };
  if (masterSyncInfo.video?.paused ?? true) return; // Do not try to sync if master is paused
  videosWithRef.forEach(video => {
    const videoPlayer = video?.ref?.current || {};
    const videoInfo = video?.ref
      ? {
          ...video,
          video: videoPlayer?.getMediaElement?.() ?? {},
          AnalyticsListener: videoPlayer.AnalyticsListener,
        }
      : {};
    // Do not sync master
    const inSync =
      masterSyncInfo.id !== video.id
        ? handleSyncing(masterSyncInfo, videoInfo, hasExternalSyncRef, useTTFB)
        : true;
    const playbackRate = pbr || 1;
    if (master && masterSyncInfo.video.playbackRate !== playbackRate) {
      masterSyncInfo.video.playbackRate = playbackRate;
    }
    if (setDebugInfo || sendDebugEvent) {
      const { currentTime } = videoInfo.video;
      const { offset } = video;
      const { video: masterVideo = { currentTime: 0 }, offset: masterOffset = 0 } = masterSyncInfo;
      const videoInfoPlayer = videoInfo?.ref?.current;
      const videoDateTime = videoInfoPlayer?.getPlayheadTimeAsDate()?.toISOString();
      const masterCurrentTime = masterVideo.currentTime + masterOffset;
      const videoSyncTime = currentTime + offset;
      const timeDiff = masterCurrentTime - videoSyncTime;
      const info = {
        id: video.id,
        offset,
        currentTime,
        dateTime: videoDateTime,
        videoSyncTime,
        masterCurrentTime,
        timeDiff,
        inSync,
        playbackRate: videoInfo.video.playbackRate,
        muted: videoInfo.video.muted,
        master: masterSyncInfo.id === video.id,
        volume: videoInfo.video.volume,
        stats: videoInfoPlayer?.getStats(),
        buffering: videoInfoPlayer?.isBuffering(),
        paused: videoInfo.video.paused,
      };
      newDebugInfo[video.id] = info;
    }
  });
  if (setDebugInfo) {
    setDebugInfo(newDebugInfo);
  }
  if (sendDebugEvent) {
    ref.current.dispatchEvent(new ZnipeEvent('debugInfo', { debugInfo: newDebugInfo }));
  }
};

// Returns a copy of the videos entered, but with a ref for each that needs to be added to the video object
// It currently assumes that the ref is a shaka-player
// External sync ref is expected to be a react ref containing a current time and offset.
// externalSyncRef.current.currentTime = <number>
// externalSyncRef.current.offset = <number>
// externalSyncRef.current.playbackRate = <number>
const useVideoSync = (
  originalVideos = [],
  debug = false,
  externalSyncRef = {},
  state = {},
  ref,
  sendDebugEvent = false,
) => {
  const videosWithRef = useVideosWithRefs(originalVideos);
  const [debugInfo, setDebugInfo] = useState({});
  const loadEstimationMode = useFeatureFlag('load-estimation-mode');

  useDeepCompareEffect(() => {
    const master = selectMaster(videosWithRef);
    if (!master) return () => {}; // No need to sync if we have no master
    const handleSyncingInterval = () =>
      sync(
        videosWithRef,
        master,
        debug ? setDebugInfo : undefined,
        sendDebugEvent,
        externalSyncRef,
        state.pbr,
        ref,
        loadEstimationMode && loadEstimationMode !== 'default',
      );

    const syncInterval = setInterval(handleSyncingInterval, SYNC_INTERVAL);

    return () => {
      clearInterval(syncInterval);
    };
  }, [videosWithRef, externalSyncRef, debug, state.pbr, loadEstimationMode]);

  if (!debug) return videosWithRef;
  // Attach debugInfo to the input and return it.
  return videosWithRef.reduce((res, video) => {
    const videoDebugInfo = debugInfo[video.id] || {};
    const videoWithDebugInfo = { ...video, debug: videoDebugInfo };
    return [...res, videoWithDebugInfo];
  }, []);
};

export default useVideoSync;
