import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: ${props => props.theme.paymentSummary.backgroundColor};
  border-radius: 5px;
  width: 100%;
  @media ${device.tablet} {
    width: 500px;
  }
  @media ${device.desktop} {
    width: 630px;
  }
`;

export const List = styled.ul`
  margin: 20px 0;
  padding: 0 15px;
  list-style: none;
  max-width: fit-content;
  &:not(:only-child) {
    margin-bottom: 18px;
  }
  @media ${device.tablet} {
    padding: 0 29px;
    &:not(:only-child) {
      margin-bottom: 13px;
    }
  }
`;

export const Item = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 25px;
  color: ${props => props.theme.paymentSummary.itemColor};
  height: 27px;
  &:last-child {
    margin-bottom: 0;
  }
  @media ${device.desktop} {
    margin-bottom: 22px;
    font-size: 20px;
  }
`;

export const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  gap: 0px 7px;
  @media ${device.desktop} {
    gap: 0px 10px;
  }
`;

export const CardTitle = styled.span`
  text-transform: capitalize;
`;

export const BoldText = styled.p`
  padding: 0;
  margin: 0;
  font-weight: bold;
`;

export const SecureDetails = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.paymentSummary.taglineColor};
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin: 0 11px 16px 0;
  @media ${device.tablet} {
    margin: 0 21px 21px 0;
  }
  @media ${device.desktop} {
    margin: 0 5px 7px 0;
    line-height: 24px;
  }
`;

export const CardDetails = styled.div`
  margin: 0 7px 0 0;
`;

export const TagLine = styled.p`
  margin: 0 7px 0 0;
`;
