import { createSelector } from 'reselect';
import makeGetSelectedGame from 'tv-selectors/games/makeGetSelectedGame';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyArray = [];

const makeGetGameInitialPlayers = () => {
  const getMatchGame = makeGetSelectedGame();
  return createSelector([getMatchGame], currentGame => currentGame.initialPlayers || emptyArray);
};

export const useGetGameInitialPlayers = props =>
  useMemoizedGqlSelector(makeGetGameInitialPlayers, props);

export default makeGetGameInitialPlayers;
