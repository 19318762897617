import colors from 'znipe-styles/colors';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import Spinner from 'znipe-elements/general/Icon/icons/general/Spinner';
import { useMemo, useState } from 'react';
import { Tile } from './EmojiTile.styles';

type Size = 'small' | 'medium';

type EmojiTileProps = {
  as?: keyof HTMLElementTagNameMap;
  src: string;
  label: string;
  size?: Size;
  onClick?: (label: string) => void;
  customAlt?: string;
};

const EmojiTile: React.FC<EmojiTileProps> = ({
  as,
  src,
  label,
  size = 'medium',
  onClick,
  customAlt,
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const tileAs = useMemo(() => {
    if (as) return as;
    if (onClick) return 'button';
    return 'div';
  }, [as, onClick]);

  const toolTipLabel = useMemo(() => {
    if (onClick) return `:${label}:`;
    return label;
  }, [label, onClick]);

  return (
    <ToolTip
      size="small"
      label={toolTipLabel}
      textColor={colors.black}
      backgroundColor={colors.grey90}
      delay={200}
      withTail
      uppercase={false}
    >
      <Tile
        $size={size}
        $isLoading={isImageLoading}
        as={tileAs}
        data-testid={`emoji-tile-${label.replace(/:/g, '')}`}
        onClick={() => {
          if (onClick) onClick(label);
        }}
      >
        {isImageLoading && <Spinner fillColor={colors.grey53} />}
        <img
          src={src}
          alt={customAlt || `${label.replace(/:/g, '')}-emoji`}
          onLoad={() => setIsImageLoading(false)}
        />
      </Tile>
    </ToolTip>
  );
};

export default EmojiTile;
