import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsMobileLandscapeOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.mobileLandscape);
};

export const useGetIsMobileLandscapeOrGreater = () =>
  useMemoizedSelector(makeGetIsMobileLandscapeOrGreater);

export default makeGetIsMobileLandscapeOrGreater;
