import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { CHAMPION_KILLS_EVENT } from 'tv-reducers/intensive';
import getTimeSeriesInfo from './utils/getTimeSeriesInfo';
import createLolPlayer from './utils/createLolPlayer';

export const SUBSCRIBE_CHAMPION_KILLS = 'SUBSCRIBE_CHAMPION_KILLS';
export const UNSUBSCRIBE_CHAMPION_KILLS = 'UNSUBSCRIBE_CHAMPION_KILLS';

export const useChampionKillsEpic = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_CHAMPION_KILLS, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_CHAMPION_KILLS, gatId });
  }, [dispatch, gatId]);
};

export const groupKillsByTimeSeries = (kills, gatId) =>
  kills?.reduce((acc, summary) => {
    const gameTime = summary.gameTime / 1000;
    const actor = createLolPlayer(summary.actor, gatId);
    const subject = createLolPlayer(summary.victim, gatId);
    const kill = {
      actor,
      subject,
      gameTime,
      id: `${gatId}:${summary.sequenceIndex}`,
    };

    const { id: timeSeriesId } = getTimeSeriesInfo(gatId, gameTime);
    const accVal = acc[timeSeriesId] ?? { kills: [] };

    acc[timeSeriesId] = {
      id: timeSeriesId,
      kills: [...accVal.kills, kill],
      gameId: gatId,
      gameTime,
    };
    return acc;
  }, {});

const emptyArray = [];
const championKillsEpic = factorySubscribeEpic(
  [SUBSCRIBE_CHAMPION_KILLS, UNSUBSCRIBE_CHAMPION_KILLS],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('actionType').equalTo('champion_kill'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const mappedPayload = groupKillsByTimeSeries(payload, gatId);
      const timeSeries = Object.values(mappedPayload);
      const kills = timeSeries.reduce(
        (acc, entry) => [...acc, ...(entry.kills ?? emptyArray)],
        emptyArray,
      );

      return {
        type: CHAMPION_KILLS_EVENT,
        gatId,
        payload: {
          id: gatId,
          timeSeries: Object.values(mappedPayload),
          kills,
        },
      };
    },
);

export default championKillsEpic;
