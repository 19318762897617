import isBrowser from 'znipe-utils/web/isBrowser';

const minBreakpointSize = 320;

const breakPoints = [
  { name: 'tiny', width: minBreakpointSize },
  { name: 'extraSmall', width: 480 },
  { name: 'small', width: 800 },
  { name: 'middle', width: 1200 },
  { name: 'large', width: 1400 },
  { name: 'extraLarge', width: 1600 },
  { name: 'superLarge', width: 2500 },
];

const getBreakpoint = name => breakPoints.find(x => x.name === name);

const findClosestManualWidth = (closestBreakpoint, manualBreakpoints) => {
  let { width } = closestBreakpoint;

  Object.keys(manualBreakpoints).forEach(name => {
    const breakPoint = getBreakpoint(name);

    if (closestBreakpoint.width >= breakPoint.width) {
      width = manualBreakpoints[name];
    }
  });

  return width;
};

export const updateImageUrl = (src = '', imageWidth = minBreakpointSize, format = '') =>
  src.replace(
    /^(http|https):\/\/assets.znipe.tv\//gm,
    `https://resize.znipe.tv/fit-in/${imageWidth}x/${format ? `filters:format(${format})/` : ''}`,
  );

const closestBreakpointForViewport = (arrayOfNumbers, windowSize) =>
  arrayOfNumbers.sort((a, b) => Math.abs(windowSize - a.width) - Math.abs(windowSize - b.width))[0];

const getResizeUrl = (src, manualBreakpoints, format = 'webp') => {
  if (!src) return undefined;

  const viewportWidth = isBrowser() ? window.innerWidth : 0;
  const closestBreakpoint = closestBreakpointForViewport(breakPoints, viewportWidth);

  if (typeof manualBreakpoints === 'string' || typeof manualBreakpoints === 'number') {
    return updateImageUrl(src, manualBreakpoints, format);
  }

  if (!manualBreakpoints) {
    return updateImageUrl(src, closestBreakpoint.width, format);
  }

  const closestManualWidth = findClosestManualWidth(closestBreakpoint, manualBreakpoints);
  return updateImageUrl(src, closestManualWidth, format);
};

export default getResizeUrl;
