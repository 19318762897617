import PropTypes from 'prop-types';

export const SMALL = 'small';
export const MEDIUM = 'medium';

export const IMAGE_TYPE_PORTRAIT = 'portrait';
export const IMAGE_TYPE_IMAGE = 'image';

export const STREAM_TYPE_PLAYER = 'player';
export const STREAM_TYPE_EVENT = 'event';
export const STREAM_TYPE_MAP = 'map';

export const TYPE_VERTICAL = 'vertical';
export const TYPE_HORIZONTAL = 'horizontal';
export const TYPE_SLIM_HORIZONTAL = 'slim-horizontal';

export const defaultProps = {
  size: 'small',
  imageType: 'image',
  kda: undefined,
  isSelected: false,
  isLocked: false,
  hasPremiumAccess: false,
  imageArea: null,
  game: 'csgo',
  image: '',
  onClick: () => {},
  onMouseOut: () => {},
  onMouseOver: () => {},
  'data-testid': 'container',
};

export const propTypes = {
  label: PropTypes.string.isRequired,
  imageArea: PropTypes.node,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf([SMALL, MEDIUM]),
  imageType: PropTypes.oneOf([IMAGE_TYPE_PORTRAIT, IMAGE_TYPE_IMAGE]),
  game: PropTypes.oneOf(['lol', 'csgo']),
  kda: PropTypes.shape({
    kills: PropTypes.number,
    deaths: PropTypes.number,
    assists: PropTypes.number,
  }),
  isSelected: PropTypes.bool,
  isLocked: PropTypes.bool,
  hasPremiumAccess: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
  'data-testid': PropTypes.string,
};
