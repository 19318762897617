import styled from 'styled-components';
import PropTypes from 'prop-types';
import { device } from 'znipe-styles/breakpoints';

const PremiumImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media ${device.tablet} {
    width: 240px;
    height: 100%;
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > svg {
    height: 50%;
    max-height: 125px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ProviewImage = ({ coverImage }) => (
  <PremiumImage>
    <img src={coverImage} alt="background" />
    <svg viewBox="0 0 75 63" fill="none">
      <path
        d="M0 47.7134C0 46.9459 0.622191 46.3237 1.3897 46.3237H72.6103C73.3778 46.3237 74 46.9459 74 47.7134V60.934C74 61.7015 73.3778 62.3237 72.6103 62.3237H1.3897C0.62219 62.3237 0 61.7015 0 60.934V47.7134Z"
        fill="url(#proview_image_paint0_linear)"
      />
      <g clipPath="url(#proview_image_clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9511 48.7651L15.451 52.8776H19.0954C19.2751 52.8776 19.4387 52.9858 19.5149 53.1551C19.5911 53.3245 19.5663 53.5244 19.4513 53.668L13.8138 59.8828L14.4015 55.7691H10.7572C10.5774 55.7691 10.4139 55.661 10.3377 55.4916C10.2614 55.3223 10.2863 55.1224 10.4013 54.9787L15.9511 48.7651Z"
          fill="#181919"
        />
      </g>
      <path
        d="M27.4254 55.3575H26.2581V57.1336H25.3325V51.4219H27.4254C28.7206 51.4219 29.3682 52.0611 29.3682 53.3397C29.3682 53.9956 29.2042 54.4959 28.8763 54.8405C28.5538 55.1852 28.0702 55.3575 27.4254 55.3575ZM26.2581 54.557H27.4171C28.0841 54.557 28.4177 54.1513 28.4177 53.3397C28.4177 52.9505 28.3371 52.667 28.1759 52.4892C28.0146 52.3113 27.7617 52.2223 27.4171 52.2223H26.2581V54.557Z"
        fill="#181919"
      />
      <path
        d="M31.8515 55.099V57.1336H30.926V51.4219H33.0939C34.3835 51.4219 35.0284 52.0278 35.0284 53.2396C35.0284 54.0512 34.7171 54.6015 34.0945 54.8906L35.0367 57.1336H34.0194L33.194 55.099H31.8515ZM34.0778 53.2479C34.0778 52.5642 33.7498 52.2223 33.0939 52.2223H31.8515V54.2986H33.1106C33.4441 54.2986 33.6887 54.2041 33.8443 54.0151C34 53.8205 34.0778 53.5648 34.0778 53.2479Z"
        fill="#181919"
      />
      <path
        d="M36.7474 57.1336V51.4219H40.3495V52.2307H37.6729V53.8483H39.8492V54.6488H37.6729V56.3164H40.3495V57.1336H36.7474Z"
        fill="#181919"
      />
      <path
        d="M42.0233 57.1336V51.4219H43.6326L44.9083 55.8828L46.184 51.4219H47.8017V57.1336H46.8678V52.4475H46.7427L45.3919 56.9001H44.4247L43.0739 52.4475H42.9488V57.1336H42.0233Z"
        fill="#181919"
      />
      <path d="M49.7746 57.1336V51.4219H50.7001V57.1336H49.7746Z" fill="#181919" />
      <path
        d="M53.5647 55.3075C53.5647 56.0468 53.9539 56.4165 54.7321 56.4165C55.5103 56.4165 55.8994 56.0468 55.8994 55.3075V51.4219H56.8333V55.2908C56.8333 55.9634 56.6554 56.4554 56.2997 56.7667C55.9495 57.0724 55.4242 57.2253 54.7237 57.2253C54.0289 57.2253 53.5064 57.0724 53.1562 56.7667C52.806 56.4554 52.6309 55.9634 52.6309 55.2908V51.4219H53.5647V55.3075Z"
        fill="#181919"
      />
      <path
        d="M58.771 57.1336V51.4219H60.3803L61.6561 55.8828L62.9318 51.4219H64.5494V57.1336H63.6155V52.4475H63.4905L62.1397 56.9001H61.1724L59.8217 52.4475H59.6966V57.1336H58.771Z"
        fill="#181919"
      />
      <g clipPath="url(#clip1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.091 37.8954H33.1279L36.0277 21.4243H31.9753L29.091 37.8954ZM17.2644 37.8954H21.7458L29.8766 21.4244H25.514L20.283 33.2827L19.058 21.4244H14.835L17.2644 37.8954ZM39.4181 21.4243H51.0172L50.4434 24.7776H42.8658L42.3489 27.8614H49.2494L48.6395 31.2251H41.7131L41.1497 34.5213H48.7532L48.1691 37.885H36.5442L39.4181 21.4243ZM70.0859 21.4243L66.2351 32.1528L65.0824 21.4243H61.2574L56.5433 32.1528L56.4037 21.4243H52.4133L53.4471 37.8954H57.2204L62.3894 26.462L63.5369 37.8954H67.3154L74.1229 21.4243H70.0859Z"
          fill="white"
        />
      </g>
      <path
        d="M9.85718 0.331701H2.88944L0 16.7976H4.06279L4.93117 11.9309H7.90848C12.39 11.9309 15.2535 9.53123 15.2535 5.39015C15.2535 2.49294 12.9999 0.331701 9.85718 0.331701ZM8.21345 8.56205H5.53077L6.37848 3.69536H9.05082C9.33352 3.66727 9.61894 3.70084 9.88749 3.79377C10.156 3.8867 10.4014 4.03678 10.6066 4.23372C10.8119 4.43066 10.9722 4.6698 11.0766 4.93472C11.181 5.19964 11.227 5.48407 11.2114 5.7685C11.2166 7.38554 10.1828 8.56205 8.21345 8.56205ZM31.2566 5.48344C31.2822 4.79735 31.1658 4.11337 30.9147 3.47463C30.6636 2.83589 30.2833 2.25624 29.7976 1.77222C29.312 1.28821 28.7316 0.910324 28.0931 0.662394C27.4546 0.414464 26.7717 0.301867 26.0877 0.331701H18.4531L15.5585 16.7976H19.5955L20.4432 11.905H23.2344L25.2038 16.7976H29.7318L27.3385 11.366C29.8248 10.4227 31.2566 8.35473 31.2566 5.48344ZM24.17 8.70716H21.0014L21.9163 3.57615H25.0849C25.3729 3.55869 25.6614 3.60281 25.9311 3.70558C26.2009 3.80835 26.4458 3.96742 26.6496 4.17226C26.8534 4.37711 27.0114 4.62303 27.1133 4.89375C27.2151 5.16448 27.2584 5.45381 27.2403 5.74258C27.2403 7.55139 26.0463 8.70716 24.17 8.70716ZM41.0931 0C36.6323 0 34.053 2.37892 33.2518 6.84653L32.8796 8.91966C32.7868 9.43272 32.7401 9.95309 32.7401 10.4745C32.7401 14.3565 35.552 17.1085 39.8474 17.1085C44.1428 17.1085 46.934 14.2113 47.637 10.1221L47.9885 8.04895C48.079 7.53559 48.1257 7.01542 48.128 6.49409C48.128 2.53959 45.1714 0 41.0931 0ZM44.0239 7.71724L43.6259 10.0081C43.2485 12.2419 42.0752 13.7501 40.0076 13.7501C37.94 13.7501 36.7719 12.5269 36.7719 10.5004C36.7777 10.1058 36.8157 9.71232 36.8856 9.32392L37.2888 7.09012C37.7074 4.69047 38.7412 3.34811 40.907 3.34811C42.9281 3.34811 44.1221 4.71638 44.1221 6.71177C44.1221 7.04934 44.0892 7.38609 44.0239 7.71724V7.71724Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="proview_image_paint0_linear"
          x1="11.3681"
          y1="62.3237"
          x2="17.4386"
          y2="34.1407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2A256" />
          <stop offset="1" stopColor="#F4DFAD" />
        </linearGradient>
        <clipPath id="proview_image_clip0">
          <rect
            width="11.1176"
            height="11.1176"
            fill="white"
            transform="translate(9.4502 48.7651)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect width="74.1175" height="37.6378" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </PremiumImage>
);

ProviewImage.propTypes = {
  coverImage: PropTypes.string.isRequired,
};

export default ProviewImage;
