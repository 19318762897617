import PropTypes from 'prop-types';
import styled from 'styled-components';
import gradients from 'znipe-styles/gradients';
import colors from 'znipe-styles/colors';

const Border = styled.div`
  position: absolute;
  background: ${gradients.bubbleBlue};
  width: 2px;
  top: 0;
  bottom: 0;
  ${props => props.position}: 0;
`;

const GlowShadow = styled.div`
  width: 50%;
  position: absolute;
  height: 100%;
  ${props => props.position}: -25%;
  background: ${`radial-gradient(
    50% 61.75% at 50% 50%,
    ${colors.tooBlue} 0%,
    rgba(151, 243, 255, 0) 100%
  )`};
  opacity: 0.15;
  filter: blur(20px);
`;

const Content = styled.div`
  width: 100%;
  padding: ${({ overlapContent }) => (overlapContent ? '0' : '0 2px')};
`;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:focus,
  &:hover {
    ${GlowShadow} {
      opacity: 0.25;
    }
  }
  &:active {
    ${GlowShadow} {
      opacity: 0.4;
    }
  }
`;

const NeonBorder = ({ children, overlapContent = false }) => (
  <Container data-testid="neon-border-container">
    <Border data-testid="neon-border-left" position="left" />
    <GlowShadow data-testid="neon-border-shadow-left" position="left" />
    <Content overlapContent={overlapContent}>{children}</Content>
    <GlowShadow data-testid="neon-border-shadow-right" position="right" />
    <Border data-testid="neon-border-right" position="right" />
  </Container>
);

NeonBorder.propTypes = {
  children: PropTypes.node.isRequired,
  overlapContent: PropTypes.bool,
};

export default NeonBorder;
