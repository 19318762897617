import { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import HighlightClip from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip';
import ToggleButton from 'znipe-elements/general/buttons/ToggleButton/ToggleButton';
import { useGetAutoplayEnabled } from 'tv-selectors/control/makeGetAutoplayEnabled';
import { setAutoplay } from 'tv-actions/old/control';
import { VideoItem, Container, ToggleWrapper, VideosWrapper } from './HighlightList.styles';

const HighlightList = ({
  videoList = [],
  size = 'small',
  autoPlayEnabled = true,
  playingVideoId = '',
  videoType = 'highlight',
  isPlaylist = false,
}) => {
  const dispatch = useDispatch();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isAutoplayEnabled = useGetAutoplayEnabled();
  const handleAutoplayToggle = useCallback(
    () => dispatch(setAutoplay(!isAutoplayEnabled)),
    [isAutoplayEnabled, dispatch],
  );

  const highlightClipType = useMemo(() => {
    if (isDesktopOrGreater) return 'tile';
    if (isTabletOrGreater) return 'list';
    return 'tile';
  }, [isTabletOrGreater, isDesktopOrGreater]);

  const videoItems = useMemo(
    () =>
      videoList.map(video => {
        const { id } = video;
        return (
          <VideoItem key={id} clipType={highlightClipType}>
            <HighlightClip
              highlightId={id}
              isSelected={isPlaylist && playingVideoId === id}
              type={highlightClipType}
              size={size}
              ignoreRouteHistory
              videoType={videoType}
            />
          </VideoItem>
        );
      }),
    [videoList, highlightClipType, isPlaylist, playingVideoId, size, videoType],
  );

  return (
    <Container data-testid="highlight-listing">
      {autoPlayEnabled && (
        <ToggleWrapper data-testid="toggle-wrapper">
          <ToggleButton
            size={size}
            label="AutoPlay"
            isActive={isAutoplayEnabled}
            onClick={handleAutoplayToggle}
          />
        </ToggleWrapper>
      )}
      <VideosWrapper $autoPlayEnabled={autoPlayEnabled}>{videoItems}</VideosWrapper>
    </Container>
  );
};

HighlightList.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  videoList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      tournamentName: PropTypes.string,
      date: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      teamAName: PropTypes.string,
      teamBName: PropTypes.string,
      src: PropTypes.string,
    }),
  ),
  autoPlayEnabled: PropTypes.bool,
  playingVideoId: PropTypes.string,
  videoType: PropTypes.oneOf(['highlight', 'editorial']),
  isPlaylist: PropTypes.bool,
};

export default memo(HighlightList);
