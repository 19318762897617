import PropTypes from 'prop-types';
import isInIframe from 'tv-utils/isInIframe';
import PoweredByZnipeStyles from './PoweredByZnipe.styles';

const PoweredByZnipe = ({ visible = false, useContainerSpace = false }) => {
  if (!visible && !isInIframe()) return null;
  return (
    <PoweredByZnipeStyles
      data-testid="powered-by-znipe"
      viewBox="0 0 290 23"
      useContainerSpace={useContainerSpace}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.141 4.01465C236.052 4.01465 234.547 4.17893 233.503 4.34322H233.419V19.0468H236.846V13.7075C237.138 13.7486 237.556 13.7896 238.016 13.7896C240.063 13.7896 241.818 13.3379 242.989 12.2289C243.908 11.3664 244.409 10.0932 244.409 8.65572C244.409 7.21822 243.741 5.945 242.78 5.20572C241.777 4.42536 240.272 4.01465 238.141 4.01465ZM238.099 11.2432C237.598 11.2432 237.222 11.2432 236.929 11.1611H236.971V6.68429L237.011 6.67777C237.269 6.63513 237.717 6.56107 238.392 6.56107C240.105 6.56107 241.066 7.3825 241.066 8.77893C241.066 10.3396 239.938 11.2432 238.099 11.2432Z"
        fill="white"
      />
      <path
        d="M201.158 7.01215L193.887 17.28V19.1282H205.796V16.3354H198.567V16.2532L205.671 6.10858V4.13715H194.597V6.93001H201.158V7.01215Z"
        fill="white"
      />
      <path
        d="M209.14 4.3018V19.2929H212.316V14.775C212.316 12.3518 212.274 10.2161 212.19 8.24466L212.274 8.20359C213.026 9.96966 213.987 11.9 214.906 13.5018L218.166 19.2929H221.802V4.3018H218.626V8.69644C218.626 10.9554 218.709 12.9679 218.918 14.9393H218.835C218.124 13.2554 217.247 11.3661 216.327 9.80537L213.151 4.3018H209.14Z"
        fill="white"
      />
      <path d="M229.365 4.13715V19.1282H225.896V4.13715H229.365Z" fill="white" />
      <path
        d="M256.318 12.8032H250.718V16.3764H256.987V19.1693H247.25V4.13715H256.652V6.93001H250.718V10.0104H256.318V12.8032Z"
        fill="white"
      />
      <path
        d="M260.915 16.5818C260.163 16.5818 259.578 17.1568 259.578 17.9783C259.578 18.7586 260.121 19.3747 260.915 19.3747C261.751 19.3747 262.253 18.7586 262.253 17.9783C262.253 17.1568 261.668 16.5818 260.915 16.5818Z"
        fill="white"
      />
      <path
        d="M269.733 5.78001H265.095V4.13715H276.378V5.78001H271.739V19.1282H269.733V5.78001Z"
        fill="white"
      />
      <path
        d="M277.38 4.13715L282.353 19.1282H284.568L290 4.13715H287.911L285.32 11.53C284.609 13.5014 283.982 15.3907 283.565 17.1157H283.523C283.105 15.3907 282.562 13.5425 281.893 11.53L279.511 4.13715H277.38Z"
        fill="white"
      />
      <path
        d="M162.545 21.6904V0H184.697L182.699 1.96441H164.544V16.9021L176.286 5.40213H166.126V3.47864H181.158L162.545 21.6904Z"
        fill="#1C84DE"
      />
      <path
        d="M185.947 23H163.794L165.751 21.0356H183.906V6.09788L172.205 17.5569H182.449V19.5214H167.334L185.947 1.35055V23Z"
        fill="#1C84DE"
      />
      <path
        d="M0 17.4495V6.02826H3.88266C4.55537 6.02826 5.16957 6.08705 5.72528 6.20465C6.29562 6.32224 6.77821 6.52068 7.17306 6.79996C7.58253 7.06455 7.89694 7.41733 8.1163 7.8583C8.35029 8.29928 8.46728 8.84315 8.46728 9.48991C8.46728 10.1073 8.35029 10.6511 8.1163 11.1215C7.89694 11.5772 7.58253 11.952 7.17306 12.246C6.76359 12.54 6.281 12.7605 5.72528 12.9075C5.16957 13.0545 4.55537 13.128 3.88266 13.128H1.82068V17.4495H0ZM1.82068 11.805H3.75105C4.7601 11.805 5.49861 11.6213 5.96658 11.2538C6.44917 10.8863 6.69047 10.3278 6.69047 9.57811C6.69047 8.79905 6.44186 8.25518 5.94464 7.9465C5.46205 7.63782 4.71623 7.48347 3.70718 7.48347H1.82068V11.805Z"
        fill="white"
      />
      <path
        d="M20.2376 17.7141C19.4479 17.7141 18.7241 17.5745 18.066 17.2952C17.4225 17.0159 16.8668 16.619 16.3988 16.1045C15.9309 15.5901 15.5653 14.958 15.302 14.2083C15.0388 13.4587 14.9072 12.6208 14.9072 11.6948C14.9072 10.7687 15.0388 9.93823 15.302 9.20328C15.5653 8.46832 15.9309 7.85095 16.3988 7.35118C16.8668 6.83671 17.4225 6.44718 18.066 6.1826C18.7241 5.90331 19.4479 5.76367 20.2376 5.76367C21.0127 5.76367 21.722 5.90331 22.3654 6.1826C23.0235 6.44718 23.5865 6.83671 24.0545 7.35118C24.5371 7.85095 24.91 8.46832 25.1732 9.20328C25.4365 9.93823 25.5681 10.7687 25.5681 11.6948C25.5681 12.6208 25.4365 13.4587 25.1732 14.2083C24.91 14.958 24.5371 15.5901 24.0545 16.1045C23.5865 16.619 23.0235 17.0159 22.3654 17.2952C21.722 17.5745 21.0127 17.7141 20.2376 17.7141ZM20.2376 16.1707C21.2906 16.1707 22.1241 15.7738 22.7383 14.98C23.3672 14.1716 23.6816 13.0765 23.6816 11.6948C23.6816 10.3131 23.3672 9.24002 22.7383 8.47567C22.1241 7.69661 21.2906 7.30708 20.2376 7.30708C19.1847 7.30708 18.3438 7.69661 17.715 8.47567C17.1008 9.24002 16.7937 10.3131 16.7937 11.6948C16.7937 13.0765 17.1008 14.1716 17.715 14.98C18.3438 15.7738 19.1847 16.1707 20.2376 16.1707Z"
        fill="white"
      />
      <path
        d="M33.9916 17.4495L31.4471 6.02826H33.2458L34.4523 11.9814C34.5693 12.5988 34.6863 13.2161 34.8033 13.8335C34.9203 14.4362 35.0373 15.0462 35.1543 15.6636H35.2201C35.3517 15.0462 35.4906 14.4362 35.6368 13.8335C35.7977 13.2161 35.9513 12.5988 36.0975 11.9814L37.7208 6.02826H39.3001L40.8795 11.9814C41.0258 12.5988 41.172 13.2161 41.3183 13.8335C41.4645 14.4362 41.618 15.0462 41.7789 15.6636H41.8667C41.9836 15.0462 42.0933 14.4362 42.1957 13.8335C42.2981 13.2161 42.4077 12.5988 42.5247 11.9814L43.7312 6.02826H45.4203L42.9415 17.4495H40.8137L39.0589 11.0554C38.9419 10.5997 38.8395 10.1587 38.7517 9.73245C38.664 9.29147 38.5763 8.85785 38.4885 8.43157H38.4008C38.2984 8.85785 38.2033 9.29147 38.1156 9.73245C38.0279 10.1587 37.9255 10.5997 37.8085 11.0554L36.0975 17.4495H33.9916Z"
        fill="white"
      />
      <path
        d="M52.2993 17.4495V6.02826H59.4504V7.48347H54.1199V10.7908H58.6387V12.1799H54.1199V15.9943H59.6697V17.4495H52.2993Z"
        fill="white"
      />
      <path
        d="M67.0975 17.4495V6.02826H71.024C71.6528 6.02826 72.2305 6.08705 72.7569 6.20465C73.298 6.30754 73.7587 6.49128 74.1389 6.75587C74.5337 7.02045 74.8408 7.36588 75.0602 7.79216C75.2796 8.21843 75.3892 8.74025 75.3892 9.35762C75.3892 10.2249 75.1479 10.9378 74.6654 11.4963C74.1974 12.0402 73.5686 12.4224 72.7789 12.6429L75.806 17.4495H73.8537L71.024 12.8634H68.9181V17.4495H67.0975ZM68.9181 11.5404H70.8266C72.7131 11.5404 73.6563 10.8496 73.6563 9.46786C73.6563 8.7329 73.4077 8.21843 72.9105 7.92445C72.4279 7.63047 71.7259 7.48347 70.8046 7.48347H68.9181V11.5404Z"
        fill="white"
      />
      <path
        d="M82.9668 17.4495V6.02826H90.1179V7.48347H84.7874V10.7908H89.3062V12.1799H84.7874V15.9943H90.3372V17.4495H82.9668Z"
        fill="white"
      />
      <path
        d="M97.7649 17.4495V6.02826H100.902C102.788 6.02826 104.243 6.50598 105.267 7.46143C106.305 8.40217 106.824 9.81329 106.824 11.6948C106.824 13.591 106.313 15.0241 105.289 15.9943C104.265 16.9644 102.832 17.4495 100.99 17.4495H97.7649ZM99.5856 16.0163H100.946C102.306 16.0163 103.307 15.6562 103.951 14.936C104.609 14.201 104.938 13.1206 104.938 11.6948C104.938 10.3572 104.609 9.32087 103.951 8.58591C103.307 7.85095 102.306 7.48347 100.946 7.48347H99.5856V16.0163Z"
        fill="white"
      />
      <path
        d="M123.098 17.4495V6.02826H126.828C127.456 6.02826 128.027 6.0797 128.539 6.1826C129.065 6.28549 129.518 6.44718 129.899 6.66767C130.279 6.88816 130.571 7.18214 130.776 7.54962C130.995 7.9024 131.105 8.33603 131.105 8.8505C131.105 9.40906 130.937 9.91619 130.601 10.3719C130.279 10.8128 129.789 11.1215 129.131 11.2979V11.3861C129.935 11.5478 130.571 11.8491 131.039 12.2901C131.522 12.7164 131.763 13.3264 131.763 14.1201C131.763 14.6934 131.646 15.1858 131.412 15.5974C131.193 16.009 130.871 16.3544 130.447 16.6337C130.037 16.913 129.548 17.1188 128.977 17.2511C128.407 17.3834 127.785 17.4495 127.113 17.4495H123.098ZM124.919 10.8569H126.63C127.639 10.8569 128.356 10.7026 128.78 10.3939C129.204 10.0852 129.416 9.6369 129.416 9.04894C129.416 8.46097 129.189 8.04204 128.736 7.79216C128.283 7.52757 127.603 7.39528 126.696 7.39528H124.919V10.8569ZM124.919 16.0825H126.893C127.844 16.0825 128.597 15.9281 129.153 15.6195C129.723 15.2961 130.008 14.7596 130.008 14.0099C130.008 13.3484 129.738 12.8634 129.197 12.5547C128.67 12.246 127.902 12.0917 126.893 12.0917H124.919V16.0825Z"
        fill="white"
      />
      <path
        d="M140.485 17.4495V13.128L136.822 6.02826H138.752L140.2 9.04894C140.39 9.47521 140.58 9.89414 140.77 10.3057C140.975 10.7026 141.18 11.1142 141.384 11.5404H141.472C141.677 11.1142 141.867 10.7026 142.042 10.3057C142.232 9.89414 142.43 9.47521 142.635 9.04894L144.082 6.02826H145.969L142.306 13.128V17.4495H140.485Z"
        fill="white"
      />
    </PoweredByZnipeStyles>
  );
};

PoweredByZnipe.propTypes = {
  visible: PropTypes.bool,
  useContainerSpace: PropTypes.bool,
};

export default PoweredByZnipe;
