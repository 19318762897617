import { createSelector } from 'reselect';
import makeGetChannel from 'tv-selectors/channels/makeGetChannel';

const emptyObject = {};

const makeGetOnLiveChannelEnd = () => {
  const getChannel = makeGetChannel();

  return createSelector([getChannel], channel => channel?.onLiveChannelEnd ?? emptyObject);
};

export default makeGetOnLiveChannelEnd;
