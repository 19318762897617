const FONT_FAMILY_HEADING = "'Rajdhani', sans-serif";
const FONT_FAMILY_PARAGRAPH = "'Poppins', sans-serif";
const FONT_FAMILY_TITLE = "'RobotoCondensed', sans-serif";

export default {
  fonts: {
    preloads: [
      'https://assets.znipe.tv/fonts/Rajdhani/Rajdhani-Bold.woff2',
      'https://assets.znipe.tv/fonts/Poppins/Poppins-Regular.woff2',
      'https://assets.znipe.tv/fonts/Poppins/Poppins-SemiBold.woff2',
      'https://assets.znipe.tv/fonts/RobotoCondensed/RobotoCondensed-Bold.woff2',
    ],
    fontFaces: [
      'https://assets.znipe.tv/fonts/Rajdhani/Rajdhani.css',
      'https://assets.znipe.tv/fonts/Poppins/Poppins.css',
      'https://assets.znipe.tv/fonts/RobotoCondensed/RobotoCondensed.css',
    ],
  },
  heading: {
    xl: {
      fontSize: '42px',
      lineHeight: '60px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    l: {
      fontSize: '34px',
      lineHeight: '44px',
      letterSpacing: '-0.005em',
      fontFamily: FONT_FAMILY_HEADING,
    },
    m: {
      fontSize: '26px',
      lineHeight: '40px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    s: {
      fontSize: '19px',
      lineHeight: '28px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_HEADING,
    },
    xs: {
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '-0.0075em',
      fontFamily: FONT_FAMILY_HEADING,
    },
  },
  paragraph: {
    l: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
    m: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
    s: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.02em',
      fontFamily: FONT_FAMILY_PARAGRAPH,
    },
  },
  title: {
    xxl: {
      fontSize: '43px',
      lineHeight: '56px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    xl: {
      fontSize: '25px',
      lineHeight: '36px',
      letterSpacing: '0.0925em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    l: {
      fontSize: '21px',
      lineHeight: '32px',
      letterSpacing: '0.09em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    m: {
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    s: {
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.08em',
      fontFamily: FONT_FAMILY_TITLE,
    },
    xs: {
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.075em',
      fontFamily: FONT_FAMILY_TITLE,
    },
  },
};
