import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';

const getPlayerIdFromProps = (_, props) => props.playerId ?? '';

const makeGetSelectedPlayerNickname = () => {
  const getPlayers = makeGetPlayers();
  return createSelector([getPlayers, getPlayerIdFromProps], (allPlayers, playerId) => {
    if (!allPlayers || !playerId) return '';
    return allPlayers[playerId]?.nickname ?? '';
  });
};

export const useGetSelectedPlayerNickname = props =>
  useMemoizedGqlSelector(makeGetSelectedPlayerNickname, props);

export default makeGetSelectedPlayerNickname;
