import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const emptyArray = [];

const getIdsFromProps = (_, props) => props.ids || [];

const makeGetSpotlightTeams = () => {
  const getTeams = makeGetTeams();
  return createSelector([getTeams, getIdsFromProps], (allTeams, ids) => {
    if (!allTeams || !ids?.length) return emptyArray;
    return ids.map(id => allTeams[id]);
  });
};

export const useGetSpotlightTeams = props => useMemoizedGqlSelector(makeGetSpotlightTeams, props);

export default makeGetSpotlightTeams;
