import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import { logoutUser } from 'tv-actions/old/auth';
import useQueryObject from 'tv-hooks/useQueryObject';
import 'firebase/auth';

const Logout = () => {
  const { redirect } = useQueryObject();
  const { packageSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    firebase.auth().signOut();
    const home = packageSlug ? `/${packageSlug}/home` : '/home';
    const redirectTo = redirect ? `/${redirect}` : home;
    dispatch(logoutUser()).then(() => navigate(redirectTo));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Logout;
