import { useEffect, useCallback } from 'react';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetMatchPopoutSoundFrom } from 'tv-selectors/match/makeGetMatchPopoutSoundFrom';
import { useQuality } from 'tv-selectors/control/makeGetQuality';
import useBeforeWindowClose from 'znipe-hooks/useBeforeWindowClose';
import { setMatchPlayerStreams, setPopoutSoundFrom } from 'tv-actions/matches';
import { useIsIOS } from 'tv-selectors/deviceInfo/makeGetIsIOS';
import usePrevious from 'znipe-hooks/usePrevious';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import {
  POPOUT_WINDOW_INFORMATION,
  POPOUT_WINDOW_REDIRECT,
  POPOUT_REQUEST_POPIN_STREAM,
  POPOUT_REQUEST_SOUND_SOURCE_CHANGE,
} from 'tv-constants/popout';
import { useMute } from 'tv-selectors/control/makeGetMute';

const usePopoutMessenger = (matchId, selectedMatchGameIndex, playerRef, popoutWindowRefs) => {
  const prevMatchId = usePrevious(matchId);
  const prevSelectedMatchGameIndex = usePrevious(selectedMatchGameIndex);
  const gqlDispatch = useGqlStoreDispatch();
  const popoutSoundFrom = useGetMatchPopoutSoundFrom({ matchId });
  const playerStreams = useGetMatchPlayerStreams({ matchId, selectedMatchGameIndex });
  const quality = useQuality();
  const isIOS = useIsIOS();
  const hasPremium = useHasPremiumAccess();
  const mute = useMute();

  const handleWindowClose = useCallback(() => {
    const refArr = Object.values(popoutWindowRefs);
    refArr.forEach(window => window.close());
  }, [popoutWindowRefs]);
  useBeforeWindowClose(handleWindowClose, isIOS);

  const broadcastPlayerState = useCallback(
    e => {
      const masterCurrentTime = e.currentTime;
      const popoutMatchGameId = `${matchId}-${selectedMatchGameIndex}`;
      const windowRefsArray = Object.values(popoutWindowRefs);
      const volume = e.target.getVideoStreamVolume();
      const isPlaying = !e.target.isPaused();

      windowRefsArray.forEach(ref => {
        ref.postMessage({
          type: POPOUT_WINDOW_INFORMATION,
          data: JSON.stringify({
            [popoutMatchGameId]: {
              masterCurrentTime,
              isPlaying,
              popoutSoundFrom,
              volume: mute ? 0 : volume,
              quality,
            },
          }),
        });
      });
    },
    [matchId, selectedMatchGameIndex, popoutSoundFrom, popoutWindowRefs, quality, mute],
  );

  useEffect(() => {
    const windowRefsArray = Object.values(popoutWindowRefs);
    windowRefsArray.forEach(ref => {
      if (prevMatchId !== matchId || prevSelectedMatchGameIndex !== selectedMatchGameIndex) {
        ref.postMessage({
          type: POPOUT_WINDOW_REDIRECT,
          data: JSON.stringify({
            matchId,
            selectedMatchGame: selectedMatchGameIndex,
            prevMatchId,
            prevSelectedMatchGame: prevSelectedMatchGameIndex,
          }),
        });
      }
    });
  }, [matchId, selectedMatchGameIndex, prevMatchId, prevSelectedMatchGameIndex, popoutWindowRefs]);

  // Share player info with other windows
  useEffect(() => {
    const { current } = playerRef;
    current.addEventListener('timeupdate', broadcastPlayerState);
    return () => {
      current.removeEventListener('timeupdate', broadcastPlayerState);
    };
  }, [playerRef, broadcastPlayerState]);

  const onMessageReceive = useCallback(
    e => {
      const { data = {} } = e;
      const dataType = data.type;
      // handle popping in stream into the player
      if (dataType === POPOUT_REQUEST_POPIN_STREAM) {
        const parsedData = JSON.parse(data.data || {});
        const popoutMatchGameId = `${matchId}-${selectedMatchGameIndex}`;
        const poppedStream = parsedData[popoutMatchGameId];

        if (!poppedStream) return;

        const poppedInStreamAlreadyExists = playerStreams.find(s => s.id === poppedStream.id);
        if (poppedInStreamAlreadyExists) return;

        const numberOfPlayingStreams = playerStreams.length;
        const newStreams = playerStreams.slice();

        if (numberOfPlayingStreams === 4 || (!hasPremium && numberOfPlayingStreams === 2)) {
          newStreams.splice(numberOfPlayingStreams - 1, 1, poppedStream);
        } else {
          newStreams.push(poppedStream);
        }

        gqlDispatch(setMatchPlayerStreams(matchId, newStreams));
      }

      // handle sound switch
      if (dataType === POPOUT_REQUEST_SOUND_SOURCE_CHANGE) {
        const parsedData = JSON.parse(data.data || {});
        const currentGameInfo = parsedData[`${matchId}-${selectedMatchGameIndex}`];
        if (!currentGameInfo) return;
        const { streamId } = parsedData[`${matchId}-${selectedMatchGameIndex}`];
        const player = playerRef.current;
        if (!streamId) {
          gqlDispatch(setPopoutSoundFrom(null, matchId));
          if (player) player.unmute();
        } else {
          gqlDispatch(setPopoutSoundFrom(streamId, matchId));
          if (player) player.mute();
        }
      }
    },
    [matchId, selectedMatchGameIndex, playerStreams, playerRef, hasPremium, gqlDispatch],
  );

  // setup message listener
  useEffect(() => {
    window.addEventListener('message', onMessageReceive);
    return () => {
      window.removeEventListener('message', onMessageReceive);
    };
  }, [onMessageReceive]);

  return null;
};

export default usePopoutMessenger;
