import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from './makeGetTournaments';

const getTournamentIdFromProps = (_, props) => props.tournamentId;

const makeGetTournamentName = () => {
  const getTournaments = makeGetTournaments();
  return createSelector(
    [getTournaments, getTournamentIdFromProps],
    (tournaments, tournamentId) => tournaments[tournamentId]?.name ?? '',
  );
};

export const useGetTournamentName = props => useMemoizedGqlSelector(makeGetTournamentName, props);

export default makeGetTournamentName;
