import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';

export const RoleButton = styled.div.attrs(() => ({
  type: 'button',
}))`
  ${buttonDefaultStyle}
  cursor: pointer;
  margin: 8px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.containerStroke};
  }
  @media ${device.desktop} {
    margin: 0;
  }
`;

export const IconWrapper = styled.div`
  margin: 11px 0;
  color: ${({ color }) => color};
  svg {
    display: block;
    margin: auto;
  }
  ${({ isSelected, theme }) =>
    !isSelected &&
    css`
      &:hover,
      &:focus {
        @supports (filter: drop-shadow(0px 0px 6px #000000)) {
          filter: drop-shadow(0px 0px 6px rgba(${convertHexToRGBDigits(theme.UIAccent)}, 0.5));
        }
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  max-width: 560px;
  @media ${device.desktop} {
    width: 240px;
    height: 40px;
  }
`;
