import { useEffect, useCallback } from 'react';
import type { Message as CjMessage } from 'cj';
import useChatContext from './useChatContext';
import {
  BanStatus,
  ChatStatus,
  BAN_TYPE,
  MESSAGE_TYPE,
  DELETE_MESSAGE_TYPE,
  BAN_MESSAGE_TYPE,
  STATUS_TYPE,
} from '../contexts/ChatContext';
import type { BannedUserMessage, IncomingMessage } from '../utils/chatStore';

type StatusMessage = {
  type: typeof STATUS_TYPE;
  maxMessageLength: number;
  enabled: boolean;
  rateLimit: {
    limit: number;
    refreshPeriodSeconds: number;
  };
};

type BanMessage = BanStatus & {
  type: typeof BAN_TYPE;
};

type MessageMessage = IncomingMessage & {
  type: typeof MESSAGE_TYPE;
};

type DeleteMessageMessage = {
  type: typeof DELETE_MESSAGE_TYPE;
  serverMessageId: string;
};

type BanMessageMessage = BannedUserMessage & {
  type: typeof BAN_MESSAGE_TYPE;
};

type Message =
  | StatusMessage
  | BanMessage
  | DeleteMessageMessage
  | MessageMessage
  | BanMessageMessage;

const useListenForMessages = () => {
  const { chatChannel, chatStore, setChatStatus, setBanStatus } = useChatContext() || {};

  const handleMessage = useCallback(
    (m: CjMessage<'CHAT'>) => {
      const message = m as unknown as Message;
      if (message.type === STATUS_TYPE) {
        if (setChatStatus === undefined) return;
        setChatStatus((prevStatus: ChatStatus) => ({
          ...prevStatus,
          disabled: !message.enabled,
          maxMessageLength: message.maxMessageLength,
          spamFilter: {
            maxMessages: message.rateLimit.limit,
            timeWindow: message.rateLimit.refreshPeriodSeconds * 1000,
          },
        }));
        return;
      }
      if (!chatStore) return;

      switch (message.type) {
        case BAN_TYPE:
          if (setBanStatus !== undefined) {
            setBanStatus(message);
          }
          break;
        case DELETE_MESSAGE_TYPE:
          chatStore.onMessage({
            serverMessageId: message.serverMessageId,
            deleted: true,
          });
          break;
        case BAN_MESSAGE_TYPE:
          chatStore.onUserBanned(message);
          break;
        case MESSAGE_TYPE:
          chatStore.onMessage(message);
          break;
        default:
      }
    },
    [chatStore, setBanStatus, setChatStatus],
  );

  useEffect(() => {
    if (!chatChannel) return undefined;
    chatChannel.on('value', handleMessage);

    return () => {
      chatChannel.off('value', handleMessage);
    };
  }, [chatChannel, handleMessage]);
};

export default useListenForMessages;
