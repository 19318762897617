import { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import { Container, Content, Label, Marker } from './PlayerCard.styles';
import { SMALL, MEDIUM, LARGE, XLARGE, elementSizeSet } from './constants';

const PlayerCardLoading = ({
  transparentBackground = false,
  size = LARGE,
  roundedCorners = false,
}) => {
  const [containerWidth, setContainerWidth] = useState();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();

  const handlePlayerCardResize = evt => {
    const {
      bounds: { width },
    } = evt;

    setContainerWidth(width);
  };

  const { skeleton, marker } = useMemo(() => {
    if (size) return elementSizeSet[size];
    if (isDesktopExtraLargeOrGreater) return elementSizeSet[XLARGE];
    if (isDesktopLargeOrGreater) return elementSizeSet[LARGE];
    if (isTabletOrGreater) return elementSizeSet[MEDIUM];
    return elementSizeSet[SMALL];
  }, [isDesktopExtraLargeOrGreater, isDesktopLargeOrGreater, isTabletOrGreater, size]);

  return (
    <Measure bounds onResize={handlePlayerCardResize}>
      {({ measureRef }) => (
        <Container
          data-testid="player-card-loading"
          transparentBackground={transparentBackground}
          size={size}
          containerWidth={containerWidth}
          roundedCorners={roundedCorners}
          ref={measureRef}
          $isLoading
        >
          <Content $hasLabel $markerSize={marker}>
            <Marker size={size} containerWidth={containerWidth} data-testid="marker" $isLoading />
            <Label data-testid="player-card-label" $loadingMargin={skeleton.topMargin}>
              <TextSkeleton size={size || XLARGE} width={skeleton.width} />
            </Label>
          </Content>
        </Container>
      )}
    </Measure>
  );
};

PlayerCardLoading.propTypes = {
  transparentBackground: PropTypes.bool,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE, XLARGE]),
  roundedCorners: PropTypes.bool,
};

export default memo(PlayerCardLoading);
