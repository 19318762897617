import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useGetTeamName } from 'tv-selectors/team/makeGetTeamName';
import Section from 'tv-elements/layout/Section/Section';
import FeaturedMatchItem from 'tv-modules/Featured/FeaturedMatchItem/FeaturedMatchItem';
import { GRID, LIST } from 'tv-elements/layout/Section/Section.constants';
import usePageRootColor from 'tv-hooks/usePageRootColor';
import { useGetSelectedPlayerNickname } from 'tv-selectors/players/makeGetSelectedPlayerNickname';
import { useGetFeaturedContents } from 'tv-selectors/page/makeGetFeaturedContents';
import useSeeAllLink from 'tv-hooks/useSeeAllLink';
import VODItem from 'tv-modules/VODListing/VODItem/VODItem';
import GroupedMatchList from 'tv-modules/Matches/GroupedMatchList/GroupedMatchList';
import { useGetTournamentName } from 'tv-selectors/tournaments/makeGetTournamentName';
import { useGetChampionName } from 'tv-selectors/champions/makeGetChampionName';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import { withTheme } from 'styled-components';
import useThemeContext from 'znipe-hooks/useThemeContext';
import {
  TOPIC,
  mobileSizes,
  PLAYER,
  TEAM,
  EVENT,
  CHAMPION,
  VODS,
  singletonPadding,
} from '../Topics.constants';

const TopicOverview = ({
  type = PLAYER,
  elementSizes = mobileSizes,
  groupedScheduledMatches,
  allLatestMatches,
  selectedTopicId,
  highlightVideos,
}) => {
  const palette = usePageRootColor();
  const { matches: plushPurple, standings: cottonCandy, highlights: peachParty } = palette;
  const playerNickname = useGetSelectedPlayerNickname({ playerId: selectedTopicId }) ?? '';
  const teamName = useGetTeamName({ teamId: selectedTopicId });
  const tournamentName = useGetTournamentName({ tournamentId: selectedTopicId });
  const championName = useGetChampionName({ championId: selectedTopicId });

  const { topicOverview = {} } = useThemeContext();
  const { useOverlayTitles = false } = topicOverview;

  const [featuredMatch, matchOfTheWeek] = useGetFeaturedContents({
    pageType: TOPIC,
  });

  const links = useSeeAllLink(TOPIC);

  const latestMatches = useMemo(() => {
    if (!allLatestMatches?.length) {
      return <NoResults padding={singletonPadding} type={VODS} topic={type} />;
    }

    return allLatestMatches?.map(id => (
      <VODItem key={id} matchId={id} size={elementSizes.vodItem} />
    ));
  }, [allLatestMatches, elementSizes.vodItem, type]);

  const highlightsTitle = useMemo(() => {
    if (type === PLAYER) return `${playerNickname}'s best`;
    if (type === TEAM) return `${teamName}'s`;
    if (type === EVENT) return `${tournamentName}'s best`;
    if (type === CHAMPION) return `${championName}'s`;
    return '';
  }, [championName, playerNickname, teamName, tournamentName, type]);

  const latestMatchesTitle = useMemo(() => {
    if (type === CHAMPION) return ['recently', 'played in...'];
    return ['latest', 'matches'];
  }, [type]);

  return (
    <>
      {featuredMatch && (
        <FeaturedMatchItem size={elementSizes.featuredMatch} featuredId={featuredMatch} autoPlay />
      )}
      <Section
        type={LIST}
        title={useOverlayTitles ? '' : `${latestMatchesTitle[0]} ${latestMatchesTitle[1]}`}
        backgroundTitle={useOverlayTitles ? latestMatchesTitle[0] : ''}
        foregroundTitle={useOverlayTitles ? latestMatchesTitle[1] : ''}
        titleColor={cottonCandy}
        link={links.vods}
        maxSectionHeaderSize="medium"
      >
        {latestMatches}
      </Section>
      {type !== CHAMPION && (
        <Section
          type={LIST}
          title={useOverlayTitles ? '' : 'When to watch next'}
          backgroundTitle={useOverlayTitles ? 'when to' : ''}
          foregroundTitle={useOverlayTitles ? 'watch next' : ''}
          titleColor={cottonCandy}
          link={links.schedule}
          maxSectionHeaderSize="medium"
        >
          <GroupedMatchList
            groupedItems={groupedScheduledMatches}
            size={elementSizes.genericSize}
            type="schedule"
            topicType={type}
            fullWidth
          />
        </Section>
      )}
      <Section
        fullWidth
        title={useOverlayTitles ? '' : `${highlightsTitle} moments`}
        backgroundTitle={useOverlayTitles ? highlightsTitle : ''}
        foregroundTitle={useOverlayTitles ? 'moments' : ''}
        titleColor={peachParty}
        type={GRID}
        link={links.highlights}
        maxSectionHeaderSize="medium"
      >
        {highlightVideos}
      </Section>
      {matchOfTheWeek && (
        <Section
          fullWidth
          title={useOverlayTitles ? '' : 'Relive the thrill'}
          backgroundTitle={useOverlayTitles ? 'relive' : ''}
          foregroundTitle={useOverlayTitles ? 'the thrill' : ''}
          type={LIST}
          titleColor={plushPurple}
          showAllLink={false}
          maxSectionHeaderSize="medium"
        >
          <FeaturedMatchItem size={elementSizes.matchRerun} featuredId={matchOfTheWeek} />
        </Section>
      )}
    </>
  );
};

TopicOverview.propTypes = {
  type: PropTypes.oneOf([PLAYER, TEAM, EVENT, CHAMPION]),
  elementSizes: PropTypes.shape({
    featuredMatch: PropTypes.string.isRequired,
    matchRerun: PropTypes.string.isRequired,
    vodItem: PropTypes.string.isRequired,
    highlightItems: PropTypes.number.isRequired,
    headerSize: PropTypes.string.isRequired,
    aboutSize: PropTypes.string.isRequired,
    genericSize: PropTypes.string.isRequired,
  }),
  groupedScheduledMatches: PropTypes.shape({
    items: PropTypes.PropTypes.shape({}),
    keys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  allLatestMatches: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTopicId: PropTypes.string.isRequired,
  highlightVideos: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

const themes = {
  default: {
    useOverlayTitles: false,
  },
  proview: {
    useOverlayTitles: true,
  },
};

export default memo(withTheme(TopicOverview, themes, 'topicOverview'));
