import Icon, { IconName } from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import { InteractionColors } from 'znipe-elements/general/Button/Button.styles';
import { StyledButton } from './IconButton.styles';
import type { AnimatedProps } from '@react-spring/web';
import type { Variant as VariantType } from '../Button/Button';
import { PlacementTypes } from 'znipe-elements/feedback/ToolTip/ToolTip.types';

export type Size = 'xsmall' | 'small' | 'medium';

export type Variant = VariantType;

type ButtonProps = {
  variant: Variant;
  size: Size;
  icon?: IconName;
  iconColor?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  tooltip: string;
  withTail?: boolean;
  style?: AnimatedProps<object>;
  iconStyle?: AnimatedProps<object>;
  tooltipPlacement?: PlacementTypes;
};

export const iconSizes = {
  xsmall: 20,
  small: 24,
  medium: 32,
};

const toolTipSize = (size: Size) => {
  if (size === 'xsmall') return 'small';
  return size;
};

const IconButton: React.FC<ButtonProps> = ({
  size,
  icon,
  variant,
  tooltip,
  disabled = false,
  withTail,
  children,
  iconColor,
  iconStyle,
  tooltipPlacement,
  ...props
}) => (
  <ToolTip
    label={tooltip}
    size={toolTipSize(size)}
    withTail={withTail}
    placement={tooltipPlacement}
  >
    <StyledButton $size={size} $variant={variant} $disabled={disabled} {...props}>
      <InteractionColors $size={size}>
        {icon && (
          <Icon
            type={icon}
            style={iconStyle}
            fillColor={iconColor ?? colors.white}
            size={iconSizes[size]}
            inline
          />
        )}
        {children}
      </InteractionColors>
    </StyledButton>
  </ToolTip>
);

export default IconButton;

// <IconButton toolTip="label" />
