import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetIsAuthenticated = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.isAuthenticated ?? false);
};

export const useIsAuthenticated = () => useMemoizedSelector(makeGetIsAuthenticated);

export default makeGetIsAuthenticated;
