import { useEffect, useMemo, useState } from 'react';
import { map } from 'rxjs';
import AuthManager, { UserSubject } from 'znipe-auth-manager';

const MODERATOR_PRIVILEGE = 'chat_moderation';

export type AccountInfo = {
  uid: string;
  isModerator: boolean;
  username: string;
  authToken: string;
};

const useAccountInfo = (authManagerId?: string) => {
  const authManager = useMemo(
    () => AuthManager.getAuthManager(authManagerId) ?? new AuthManager(authManagerId),
    [authManagerId],
  );
  const [account, setAccount] = useState<AccountInfo>();

  useEffect(() => {
    const sub = authManager
      .getUserSubject()
      .pipe(
        map((user: UserSubject) => {
          if (!user.userId || !user.username || !user.authToken) return undefined;
          return {
            uid: user.userId,
            isModerator: authManager.hasPrivilege(MODERATOR_PRIVILEGE) ?? false,
            username: user.username,
            authToken: user.authToken,
          };
        }),
      )
      .subscribe(data => setAccount(data));

    return () => sub.unsubscribe();
  }, [authManager]);

  return account;
};

export default useAccountInfo;
