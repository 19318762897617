import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const getWidth = isClient => (isClient ? window.innerWidth : undefined);

// inspired by https://usehooks.com/useWindowSize/
const useWindowWidth = (delay = 0) => {
  const isClient = Boolean(global.document);

  const [width, setWidth] = useState(getWidth(isClient));

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = debounce(() => setWidth(window.innerWidth), delay);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isClient, delay]);

  return width;
};

export default useWindowWidth;
