import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { EPIC_MONSTER_KILLS_EVENT } from 'tv-reducers/intensive';
import createCombinedId from 'tv-epics/utils/createCombinedId';
import getTimeSeriesInfo from './utils/getTimeSeriesInfo';
import createLolPlayer from './utils/createLolPlayer';

export const SUBSCRIBE_EPIC_MONSTER_KILLS = 'SUBSCRIBE_EPIC_MONSTER_KILLS';
export const UNSUBSCRIBE_EPIC_MONSTER_KILLS = 'UNSUBSCRIBE_EPIC_MONSTER_KILLS';

export const useEpicMonsterKillsEpic = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_EPIC_MONSTER_KILLS, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_EPIC_MONSTER_KILLS, gatId });
  }, [dispatch, gatId]);
};

export const groupKillsByTimeSeries = (kills, gatId) =>
  kills?.reduce((acc, summary) => {
    const gameTime = summary.gameTime / 1000;
    const actor = createLolPlayer(summary.actor, gatId);
    if (actor.teamId === createCombinedId(300, gatId)) return acc; // 300 means a minion killed it or it despawned
    const type = summary.victim?.type ?? summary.subActionType;
    const monsterKill = {
      actor,
      type,
      subType: summary.victim?.[`${type}Type`],
      gameTime,
      id: `${gatId}:${summary.sequenceIndex}`,
    };

    const { id: timeSeriesId } = getTimeSeriesInfo(gatId, gameTime);
    const accVal = acc[timeSeriesId] ?? { monsterKills: [] };

    acc[timeSeriesId] = {
      id: timeSeriesId,
      monsterKills: [...accVal.monsterKills, monsterKill],
      gameId: summary.gameId,
      gameTime,
    };
    return acc;
  }, {});

const emptyArray = [];
const epicMonsterKillsEpic = factorySubscribeEpic(
  [SUBSCRIBE_EPIC_MONSTER_KILLS, UNSUBSCRIBE_EPIC_MONSTER_KILLS],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase
        .database()
        .ref(`/gat/${gatId}`)
        .orderByChild('actionType')
        .equalTo('epic_monster_kill'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const mappedPayload = groupKillsByTimeSeries(payload, gatId);
      const timeSeries = Object.values(mappedPayload);
      const monsterKills = timeSeries.reduce(
        (acc, entry) => [...acc, ...(entry.monsterKills ?? emptyArray)],
        emptyArray,
      );
      return {
        type: EPIC_MONSTER_KILLS_EVENT,
        gatId,
        payload: {
          id: gatId,
          timeSeries,
          monsterKills,
        },
      };
    },
);

export default epicMonsterKillsEpic;
