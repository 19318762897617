import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import useGatId from 'tv-hooks/useGatId';

const useHasMatchStarted = (matchId, providedGatId) => {
  const fetchedGatId = useGatId(matchId);
  const gatId = providedGatId || fetchedGatId;
  const hasStarted = useIntensiveSelector(state => (state?.gats?.[gatId]?.gameTime ?? -1) > 0);
  return hasStarted;
};

export default useHasMatchStarted;
