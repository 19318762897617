import { useState } from 'react';
import PropTypes from 'prop-types';
import StageMiniNavbar from 'tv-modules/Stage/StageMiniNavbar/StageMiniNavbar';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { PlaceholderMobileNavMenu } from 'tv-routes/Stage/Stage.styles';

const MobileNavbar = ({ matchId, onStatsButtonClick, onSelectContent, isLoading = false }) => {
  const [navbarIsSticky, setNavbarIsSticky] = useState(false);
  const isTabletOrGreater = useGetIsTabletOrGreater();
  return (
    <div data-testid="mobile-navbar">
      <StickyComponent
        componentId="stage-mobile-navbar"
        onStickyChange={setNavbarIsSticky}
        enableStickyScroll
      >
        <PlaceholderMobileNavMenu data-testid="stage-nav-bar-mobile">
          <StageMiniNavbar
            matchId={matchId}
            withStatsButton={navbarIsSticky}
            onItemSelect={onSelectContent}
            onStatsButtonClick={onStatsButtonClick}
            size={isTabletOrGreater ? 'medium' : 'small'}
            isLoading={isLoading}
          />
        </PlaceholderMobileNavMenu>
      </StickyComponent>
    </div>
  );
};

MobileNavbar.propTypes = {
  matchId: PropTypes.string.isRequired,
  onStatsButtonClick: PropTypes.func.isRequired,
  onSelectContent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default MobileNavbar;
