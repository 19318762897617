import HappyPoro from 'tv-modules/Subscription/images/HappyPoro';

export default {
  proview: {
    imageComponent: HappyPoro,
  },
  default: {
    imageComponent: HappyPoro,
  },
};
