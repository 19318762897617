import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Typography from 'znipe-elements/general/Typography/Typography';
import { PLAYER, TEAM, CHAMPION } from 'tv-routes/Topic/Topics.constants';

export const ChampionAbilities = styled.div``;

export const ChampionAbilitiesWrapper = styled.div`
  padding: 24px 16px;
`;

export const Profile = styled.div`
  ${({ $type }) =>
    $type !== CHAMPION &&
    css`
      order: -1;
      @media ${device.desktop} {
        order: 2;
      }
    `}
`;

export const ChampionWrapper = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

export const ChampionImage = styled.img`
  border: 2px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
  border-radius: 71px;
  height: 48px;
  width: 48px;
`;

export const ProfileGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 12px 16px;
  margin-bottom: 24px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }
  @media ${device.desktop} {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }
`;

export const StatsWrapper = styled.div`
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  padding: 32px;
  margin-bottom: 32px;
  flex-direction: column;
`;

export const PlayerCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  border-radius: 5px;
`;

export const CardScroller = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-rows: 1fr;
  gap: 0 16px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  @media ${device.desktopExtraLarge} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const PlayerChampions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: row;
  }
  gap: 0 16px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const SectionTitle = styled(Typography)`
  margin-bottom: 4px;
`;

export const StatsContainer = styled.div``;
export const IntroWrapper = styled.div``;
export const TeamWrapper = styled.div`
  overflow: hidden;
`;
export const HistoryWrapper = styled.div``;
export const RivalryWrapper = styled.div``;
export const ParticipationsWrapper = styled.div``;

const ORDER_TEAM = css`
  > ${TeamWrapper} {
    order: 0;
  }
  > ${StatsContainer} {
    order: 1;
  }
  > ${RivalryWrapper} {
    order: 2;
  }
  > ${ParticipationsWrapper} {
    order: 3;
  }
`;

const ORDER_PLAYER = css`
  > ${IntroWrapper} {
    order: 0;
  }
  > ${StatsContainer} {
    order: 1;
  }
  > ${ChampionWrapper} {
    order: 2;
  }
  > ${TeamWrapper} {
    order: 3;
  }
  > ${HistoryWrapper} {
    order: 4;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  ${({ $type }) => $type === TEAM && ORDER_TEAM}
  ${({ $type }) => $type === PLAYER && ORDER_PLAYER}
`;

export const TeamLinkWrapper = styled.div`
  margin-bottom: 0;
  @media ${device.tablet} {
    margin-bottom: 16px;
  }
  @media ${device.desktopExtraLarge} {
    margin-bottom: unset;
  }
`;

const ORDER_CHAMPION = css`
  > ${Profile} {
    grid-row: 1;
  }
  > ${Content} {
    grid-row: 2;
  }
  > ${ChampionAbilities} {
    grid-row: 3;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, min-content);
  gap: 0 0;
  margin: 0 8px;
  @media ${device.desktop} {
    grid-template-rows: 1fr;
    gap: 0 16px;
    grid-template-columns: 1fr 336px;
  }
  @media ${device.desktopLarge} {
    gap: 0 32px;
    grid-template-columns: 1fr 384px;
  }
  @media ${device.desktopExtraLarge} {
    gap: 0 120px;
    grid-template-columns: 1fr 504px;
  }
  ${({ $type, $isDesktop }) => $type === CHAMPION && !$isDesktop && ORDER_CHAMPION}
`;
