import produce from 'immer';
import { SET_COMPETITORS } from 'tv-constants/redux';

export const initialState = {};

const competitorsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_COMPETITORS:
      Object.entries(payload).forEach(([key, value]) => (state[key] = value));
      return state;
    default:
      return state;
  }
}, initialState);

export default competitorsReducer;
