import gradients from 'znipe-styles/gradients';

// the nested objects are for color.
// Undefined means that it will default to a theme in the component
const theme = {
  default: {
    default: {
      background: undefined,
      activeBackground: undefined,
    },
    blue: {
      background: undefined,
      activeBackground: undefined,
    },
  },
  znipebase: {
    default: {
      background: undefined,
      activeBackground: gradients.sunrise,
    },
    blue: {
      background: undefined,
      activeBackground: gradients.twillightOcean,
    },
  },
};

export default theme;
