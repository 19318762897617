import PropTypes from 'prop-types';
import { MarkerDot } from './TransitionMarker.styles';

const TransitionMarker = ({ position, color = '#000' }) => (
  <MarkerDot key={`transitionMarker-${position}`} $pos={position} $markerColor={color} />
);

TransitionMarker.propTypes = {
  position: PropTypes.number.isRequired,
  color: PropTypes.string,
};
export default TransitionMarker;
