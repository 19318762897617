import usePackageName from 'tv-hooks/usePackageName';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';

export const PROVIEW_MARKETING_LINK = 'https://proview-marketing.lolesports.com';

const usePremiumMarketingLink = packageSlug => {
  const packageName = usePackageName(packageSlug);
  const hasPremiumAccess = useHasPremiumAccess(packageSlug);
  if (hasPremiumAccess) return '';
  if (packageName === 'proview') return PROVIEW_MARKETING_LINK;
  return '';
};

export default usePremiumMarketingLink;
