import { useMemo, useRef } from 'react';
import usePrevious from 'znipe-hooks/usePrevious';
import { useGetStreamSrc } from 'tv-selectors/streams/makeGetStreamSrc';
import { useGetStreamToken } from 'tv-selectors/streams/makeGetStreamToken';
import { useGetVideoSrc } from 'tv-selectors/video/makeGetVideoSrc';
import { useGetVideoToken } from 'tv-selectors/video/makeGetVideoToken';

const useStreamSrcWithToken = (streamId, type = 'match') => {
  const currentVideoSrc = useRef('');
  const matchSrc = useGetStreamSrc({ streamId });
  const matchToken = useGetStreamToken({ streamId });
  const videoStreamSrc = useGetVideoSrc({ videoId: streamId });
  const videoToken = useGetVideoToken({ videoId: streamId });
  const src = type === 'match' ? matchSrc : videoStreamSrc;
  const token = type === 'match' ? matchToken : videoToken;
  const prevSrc = usePrevious(src);

  const videoSrc = useMemo(() => {
    if (!token || !src) return '';

    if (src !== prevSrc || currentVideoSrc.current.indexOf('hdnts') === -1) {
      return `${src}?${token}`;
    }
    return currentVideoSrc.current;
  }, [prevSrc, src, token]);

  currentVideoSrc.current = videoSrc;
  return videoSrc;
};

export default useStreamSrcWithToken;
