import { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'tv-contexts/OnboardingContext';

export const PLAYING_STATE = 'playing';
export const DISMISSED_STATE = 'dismissed';
const TIMEOUT_TIMER = 30000;

const OnboardingProvider = ({ children }) => {
  const [queue, setQueue] = useState([]);
  const [playingState, setPlayingState] = useState({});
  const [currentPopup, setCurrentPopup] = useState('');
  const timeoutRef = useRef(null);

  const setNextPopup = useCallback(popupQueue => {
    const [nextPopup, ...restQueue] = popupQueue;
    if (!nextPopup) return;
    setCurrentPopup(nextPopup);
    setQueue(restQueue);
    setPlayingState(currentState => ({
      ...currentState,
      [nextPopup]: PLAYING_STATE,
    }));
  }, []);

  const addToQueue = useCallback(
    popupId => {
      if (queue.includes(popupId)) return;
      setQueue(currenQueue => currenQueue.concat([popupId]));
    },
    [queue],
  );

  const dismissPopup = useCallback(
    popupId => {
      setPlayingState(currentState => ({ ...currentState, [popupId]: DISMISSED_STATE }));
      const cleanQueue = queue.filter(item => item !== popupId);
      setQueue(cleanQueue);
      // show next popup after n seconds
      if (cleanQueue.length === 0) {
        setCurrentPopup('');
        return;
      }

      clearTimeout(timeoutRef.current);
      const ref = setTimeout(() => {
        setNextPopup(cleanQueue);
        timeoutRef.current = null;
      }, TIMEOUT_TIMER);
      timeoutRef.current = ref;
    },
    [queue, setNextPopup],
  );

  useEffect(() => {
    if (queue.length === 0) return;

    // We have a popup in queue but no popup is currently playing or pending then set it immediately
    if (!currentPopup) {
      setNextPopup(queue);
    }
  }, [currentPopup, queue, setNextPopup]);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  return (
    <Provider
      value={{
        onboardingPopupState: playingState,
        addToBoardingPopupQueue: addToQueue,
        dismissPopup,
      }}
    >
      {children}
    </Provider>
  );
};

OnboardingProvider.propTypes = {
  children: PropTypes.node,
};

export default OnboardingProvider;
