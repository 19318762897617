import firebase from 'firebase/app';

const disconnectFromRoom = store => {
  const { auth, rooms } = store.getState();
  const { userId } = auth;
  const activeRoomID = rooms.active;
  // Disconnect from room on unload
  if (activeRoomID) {
    const roomRef = firebase.database().ref('rooms').child(rooms.get('active'));

    roomRef.off();

    const activeRoomHost = rooms[activeRoomID]?.host ?? '';

    roomRef.child('members').child(userId).remove();

    if (activeRoomHost === userId) {
      roomRef.child('host').remove();
    }
  }
};

export default disconnectFromRoom;
