import styled, { css } from 'styled-components';
import prefers from 'znipe-styles/prefers';

const bufferAnimation = css`
  @keyframes buffer {
    0% {
      height: 0;
    }
    50% {
      height: 16px;
    }
    100% {
      height: 0;
    }
  }
`;

export const AnimationContainer = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  @media ${prefers.motion} {
    transition: opacity 0.1s linear;
  }
`;

export const AnimatedObject = styled.div`
  width: 3px;
  height: 20px;
  background: #fff;
  margin: 0 2px;
  border-radius: 15px;
  @media ${prefers.motion} {
    animation: buffer 0.8s infinite;
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
  &:nth-child(5) {
    animation-delay: 0.4s;
  }
  &:nth-child(6) {
    animation-delay: 0.5s;
  }
  &:nth-child(7) {
    animation-delay: 0.6s;
  }
  &:nth-child(8) {
    animation-delay: 0.7s;
  }

  ${bufferAnimation}
`;
