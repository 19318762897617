import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { STRUCTURE_DESTROYED_EVENT } from 'tv-reducers/intensive';
import createLolPlayer from 'tv-epics/utils/createLolPlayer';
import getTimeSeriesInfo from 'tv-epics/utils/getTimeSeriesInfo';

export const SUBSCRIBE_STRUCTURE_DESTROYED = 'SUBSCRIBE_STRUCTURE_DESTROYED';
export const UNSUBSCRIBE_STRUCTURE_DESTROYED = 'UNSUBSCRIBE_STRUCTURE_DESTROYED';

export const useStructuresDestroyedEpic = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_STRUCTURE_DESTROYED, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_STRUCTURE_DESTROYED, gatId });
  }, [dispatch, gatId]);
};

const emptyArray = [];
const emptyObject = {};

export const groupDemolitionsByTimeSeries = (demolitions, gatId) =>
  demolitions?.reduce((acc, summary) => {
    const gameTime = summary.gameTime / 1000;
    const actor = createLolPlayer(summary.actor, gatId);
    let type = summary.victim?.type ?? summary.subActionType;
    let subType = summary.turretTier ?? '';
    const location = summary.lane;
    const teamId = summary.victim?.teamID;

    // For some reason, the type of the nexus is turret...
    if (subType === 'nexus') {
      type = subType;
      subType = '';
    }

    const demolition = {
      actor: actor.id ? actor : undefined,
      type,
      subType,
      gameTime,
      id: `${gatId}:${summary.sequenceIndex}`,
      location,
      teamId,
    };

    const { id: timeSeriesId } = getTimeSeriesInfo(gatId, gameTime);
    const accVal = acc[timeSeriesId] ?? { structuresDestroyed: [] };

    acc[timeSeriesId] = {
      id: timeSeriesId,
      structuresDestroyed: [...accVal.structuresDestroyed, demolition],
      gameTime,
    };

    return acc;
  }, {}) ?? emptyObject;

const structuresDestroyedEpic = factorySubscribeEpic(
  [SUBSCRIBE_STRUCTURE_DESTROYED, UNSUBSCRIBE_STRUCTURE_DESTROYED],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase
        .database()
        .ref(`/gat/${gatId}`)
        .orderByChild('actionType')
        .equalTo('building_destroyed'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const mappedPayload = groupDemolitionsByTimeSeries(payload, gatId);
      const timeSeries = Object.values(mappedPayload);
      const structuresDestroyed = timeSeries.reduce(
        (acc, entry) => [...acc, ...(entry.structuresDestroyed ?? emptyArray)],
        emptyArray,
      );
      return {
        type: STRUCTURE_DESTROYED_EVENT,
        gatId,
        payload: {
          id: gatId,
          timeSeries,
          structuresDestroyed,
        },
      };
    },
);

export default structuresDestroyedEpic;
