import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';

const emptyArray = [];

const getIdsFromProps = (_, props) => props.ids || [];

const makeGetSpotlightChampions = () => {
  const getChampions = makeGetChampions();
  return createSelector([getChampions, getIdsFromProps], (allChampions, ids) => {
    if (!allChampions || !ids?.length) return emptyArray;
    return ids.map(id => allChampions[id]);
  });
};

export const useGetSpotlightChampions = props =>
  useMemoizedGqlSelector(makeGetSpotlightChampions, props);

export default makeGetSpotlightChampions;
