import { memo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { loadStripe } from '@stripe/stripe-js';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { formatPrice } from 'tv-routes/Subscribe/hooks/usePriceInfoText';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import colors from 'znipe-styles/colors';
import formatDateYYYYMMDD from 'znipe-utils/date/formatDateYYYYMMDD';
import config from 'tv-config/config';
import { Item, Field, AuthorizeButtonWrapper, ButtonLoading } from './BillingHistory.styles';
import useGetReceiptSecretRequest from './useGetReceiptSecretRequest';
import isPaid from './isPaid';

const BillingHistoryItem = ({ productId, receipt, index, onAuthFailure }) => {
  const {
    id,
    amount,
    amountPaid,
    paidAt,
    dueAt,
    currency,
    invoiceNumber,
    provider,
    requiresAction,
    hostedInvoiceUrl,
  } = receipt;

  const mounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const { billingHistory = {} } = useThemeContext();
  const paid = isPaid(amount, amountPaid);
  const date = formatDateYYYYMMDD(new Date(paid ? paidAt : dueAt), true, '-');
  const price = formatPrice(amount, currency);
  const showAuthorizeButton = requiresAction && !paid && !pending && provider === 'stripe';
  const showPaymentStatus = !isTabletOrGreater || (isTabletOrGreater && !showAuthorizeButton);
  const getReceiptSecret = useGetReceiptSecretRequest(productId);
  const hasLink = provider === 'stripe' && hostedInvoiceUrl;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const loadStripe3DSModal = async (clientSecret, paymentMethodId) => {
    const { STRIPE_PUBLIC_KEY } = config;
    const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
    try {
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
        setup_future_usage: 'off_session',
      });
      if (mounted.current) {
        setLoading(false);
      }
      if (error) throw Error(error);
      if (mounted.current) {
        onAuthFailure(false);
        setPending(true);
      }
    } catch (_error) {
      if (mounted.current) {
        onAuthFailure(true);
        setPending(false);
      }
    }
  };
  const handleClickAuthorize = async () => {
    setLoading(true);
    try {
      const response = await getReceiptSecret(id);
      const {
        results: { clientSecret, paymentMethodId } = {},
        status,
      } = response;

      if (status !== 'ok') throw Error('Failed to fetch receipts payment');
      if (mounted.current) {
        loadStripe3DSModal(clientSecret, paymentMethodId);
      }
    } catch (_error) {
      if (mounted.current) {
        onAuthFailure(true);
        setLoading(false);
      }
    }
  };

  const getStatus = useCallback(() => {
    if (paid) {
      return 'paid';
    }
    if (pending) {
      return 'pending';
    }
    return 'unpaid';
  }, [paid, pending]);

  const status = getStatus();
  return (
    <Item withButton={showAuthorizeButton} data-testid="receipt-row">
      {isTabletOrGreater && (
        <Field gridArea="Icon" data-testid={`icon-wrapper-${index}`}>
          {!paid && !pending && (
            <Icon type="exclamation" size={22} fillColor={billingHistory.iconColor} />
          )}
        </Field>
      )}
      <Field gridArea="Date" data-testid="date">
        {date}
      </Field>
      {hasLink ? (
        <Field as="a" href={hostedInvoiceUrl} data-testid="invoice">
          {invoiceNumber}
        </Field>
      ) : (
        <Field gridArea="Invoice" data-testid="invoice">
          {invoiceNumber}
        </Field>
      )}
      <Field gridArea="Amount" data-testid="amount">
        {price}
      </Field>

      {showPaymentStatus && (
        <Field gridArea="Status" status={status} data-testid="status">
          {status}
        </Field>
      )}
      {showAuthorizeButton && (
        <AuthorizeButtonWrapper isListItem data-testid={`btn-authorize-${index}`}>
          <Button
            data-testid="authorize-button"
            onClick={handleClickAuthorize}
            variant="solid-color"
            size="xsmall"
          >
            {loading ? (
              <ButtonLoading>
                <LoadingSpinner fillColor={colors.white} />
              </ButtonLoading>
            ) : (
              'authorize'
            )}
          </Button>
        </AuthorizeButtonWrapper>
      )}
    </Item>
  );
};

BillingHistoryItem.propTypes = {
  index: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  onAuthFailure: PropTypes.func.isRequired,
  receipt: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    amountPaid: PropTypes.number,
    paidAt: PropTypes.string,
    dueAt: PropTypes.string,
    currency: PropTypes.string,
    invoiceNumber: PropTypes.string,
    hostedInvoiceUrl: PropTypes.string,
    provider: PropTypes.string,
    requiresAction: PropTypes.bool,
  }).isRequired,
};

const areEqual = (prevProps, nextProps) => {
  if (!isEqual(prevProps.receipt, nextProps.receipt)) return false;
  if (!isEqual(prevProps.index, nextProps.index)) return false;
  if (!isEqual(prevProps.productId, nextProps.productId)) return false;
  if (!isEqual(prevProps.onAuthFailure, nextProps.onAuthFailure)) return false;
  return true;
};

export default memo(BillingHistoryItem, areEqual);
