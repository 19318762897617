import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme, $error }) => ($error ? theme.errorLight : theme.containerStroke)};
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  appearance: none;
  border-radius: ${({ $isDropdownOpen }) => ($isDropdownOpen ? '5px 5px 0 0' : '5px')};

  ${({ $isAbove, $isDropdownOpen }) => {
    if ($isDropdownOpen) {
      return css`
        border-radius: ${$isAbove ? '0 0 5px 5px' : '5px 5px 0 0'};
      `;
    }

    return css`
      border-radius: 5px;
    `;
  }};

  padding: 0 8px 0 16px;
  width: ${({ $customWidth }) => $customWidth || '190px'};
  height: 40px;
  z-index: 2;
  overflow: hidden;
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  user-select: none;
  &:hover {
    background: ${({ theme }) => theme.tertiaryBackgroundColor};
  }
  &:focus {
    outline: 1px solid ${({ theme }) => theme.tertiaryBackgroundColor};
    outline-offset: -6px;
  }
  &:focus:not(:focus-visible) {
    outline: 1px solid transparent;
  }
`;

export const DescriptionWrapper = styled.div`
  > p {
    white-space: nowrap;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const LabelWrapper = styled.div`
  text-align: left;
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:only-child {
    width: 100%;
  }
  &:not(:only-child) {
    width: calc(100% - 42px);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 16px);
  &:first-child {
    flex: 1;
    > *:not(:only-child) {
      margin-left: 4px;
    }
  }
  &:last-child {
    > *:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
`;

export const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
