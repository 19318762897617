import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetUserSession = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.userSession ?? '');
};

export const useUserSession = () => useMemoizedSelector(makeGetUserSession);

export default makeGetUserSession;
