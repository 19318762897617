// AUTH
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const GET_PURCHASED_EVENTS_FAILURE = 'GET_PURCHASED_EVENTS_FAILURE';
export const GET_PURCHASED_EVENTS_REQUEST = 'GET_PURCHASED_EVENTS_REQUEST';
export const GET_PURCHASED_EVENTS_SUCCESS = 'GET_PURCHASED_EVENTS_SUCCESS';
export const GET_TRIALED_EVENTS_FAILURE = 'GET_TRIALED_EVENTS_FAILURE';
export const GET_TRIALED_EVENTS_REQUEST = 'GET_TRIALED_EVENTS_REQUEST';
export const GET_TRIALED_EVENTS_SUCCESS = 'GET_TRIALED_EVENTS_SUCCESS';
export const GET_USER_BADGES_FAILURE = 'GET_USER_BADGES_FAILURE';
export const GET_USER_BADGES_REQUEST = 'GET_USER_BADGES_REQUEST';
export const GET_USER_BADGES_SUCCESS = 'GET_USER_BADGES_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const SET_AUTH = 'SET_AUTH';
export const SET_FETCHING_STATUS = 'SET_FETCHING_STATUS';
export const SET_USER_BADGE = 'SET_USER_BADGE';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';
export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_SUBSCRIBE_FAILURE = 'UPDATE_SUBSCRIBE_FAILURE';
export const UPDATE_SUBSCRIBE_REQUEST = 'UPDATE_SUBSCRIBE_REQUEST';
export const UPDATE_SUBSCRIBE_SUCCESS = 'UPDATE_SUBSCRIBE_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const SET_EXTERNAL_JWT = 'SET_EXTERNAL_JWT';

// Match
export const BUFFERING_END = 'BUFFERING_END';
export const BUFFERING_START = 'BUFFERING_START';
export const BUY_MATCH_FAILURE = 'BUY_MATCH_FAILURE';
export const BUY_MATCH_REQUEST = 'BUY_MATCH_REQUEST';
export const BUY_MATCH_SUCCESS = 'BUY_MATCH_SUCCESS';
export const COPY_STREAM_INFORMATION = 'COPY_STREAM_INFORMATION';
export const ENTER_STREAM = 'ENTER_STREAM';
export const GET_MATCHES_V3_FAILURE = 'GET_MATCHES_V3_FAILURE';
export const GET_MATCHES_V3_REQUEST = 'GET_MATCHES_V3_REQUEST';
export const GET_MATCHES_V3_SUCCESS = 'GET_MATCHES_V3_SUCCESS';
export const GET_MATCH_FAILURE = 'GET_MATCH_FAILURE';
export const GET_MATCH_REQUEST = 'GET_MATCH_REQUEST';
export const GET_MATCH_SUCCESS = 'GET_MATCH_SUCCESS';
export const GET_MATCH_TOKEN_V3_FAILURE = 'GET_MATCH_TOKEN_V3_FAILURE';
export const GET_MATCH_TOKEN_V3_REQUEST = 'GET_MATCH_TOKEN_V3_REQUEST';
export const GET_MATCH_TOKEN_V3_SUCCESS = 'GET_MATCH_TOKEN_V3_SUCCESS';
export const LEAVE_STREAM = 'LEAVE_STREAM';
export const REPLACE_PLAYER_STREAM = 'REPLACE_PLAYER_STREAM';
export const SELECTED_MATCH_GAME = 'SELECTED_MATCH_GAME';
export const SELECT_SCREEN = 'SELECT_SCREEN';
export const SELECT_STREAM = 'SELECT_STREAM';
export const SET_COUCH_CHANNEL = 'SET_COUCH_CHANNEL';
export const SET_COUCH_ROLE = 'SET_COUCH_ROLE';
export const SET_CURRENT_MATCH_ID = 'SET_CURRENT_MATCH_ID';
export const SET_DISABLED_STREAMS = 'SET_DISABLED_STREAMS';
export const SET_HIGHLIGHT = 'SET_HIGHLIGHT';
export const SET_HOMEPAGE_MATCHES = 'SET_HOMEPAGE_MATCHES';
export const SET_HOST_OFFSET = 'SET_HOST_OFFSET';
export const SET_LIVE_TIMESTAMPS = 'SET_LIVE_TIMESTAMPS';
export const SET_MASTER_CURRENT_TIME = 'SET_MASTER_CURRENT_TIME';
export const SET_MASTER_OFFSET = 'SET_MASTER_OFFSET';
export const SET_MASTER_STARTED = 'SET_MASTER_STARTED';
export const SET_MATCH_ESPORTSGAMEID = 'SET_MATCH_ESPORTSGAMEID';
export const SET_MATCH_EVENT_SERIES_ID = 'SET_MATCH_EVENT_SERIES_ID';
export const SET_MATCH_GAME_GATID = 'SET_MATCH_GAME_GATID';
export const SET_MATCH_GATID = 'SET_MATCH_GATID';
export const SET_MATCH_PLAYER_STREAMS = 'SET_MATCH_PLAYER_STREAMS';
export const SET_NEXT_MATCH = 'SET_NEXT_MATCH';
export const SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE';
export const SET_POPOUT_SOUND_FROM = 'SET_POPOUT_SOUND_FROM';
export const SET_REQUESTED_START_TIME = 'SET_REQUESTED_START_TIME';
export const SET_SOUND_FROM = 'SET_SOUND_FROM';
export const SET_STREAM = 'SET_STREAM';
export const SET_TIME_UPDATE_INFO = 'SET_TIME_UPDATE_INFO';
export const TOGGLE_PLAY = 'TOGGLE_PLAY';
export const GET_SINGLE_MATCH_SUCCESS = 'GET_SINGLE_MATCH_SUCCESS';

// STREAM
export const SET_HOMEPAGE_STREAMS = 'SET_HOMEPAGE_STREAMS';

// EVENT
export const GET_EVENTS_V3_FAILURE = 'GET_EVENTS_V3_FAILURE';
export const GET_EVENTS_V3_REQUEST = 'GET_EVENTS_V3_REQUEST';
export const GET_EVENTS_V3_SUCCESS = 'GET_EVENTS_V3_SUCCESS';
export const GET_EVENT_SERIES_V3_FAILURE = 'GET_EVENT_SERIES_V3_FAILURE';
export const GET_EVENT_SERIES_V3_REQUEST = 'GET_EVENT_SERIES_V3_REQUEST';
export const GET_EVENT_SERIES_V3_SUCCESS = 'GET_EVENT_SERIES_V3_SUCCESS';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_TOKEN_V3_FAILURE = 'GET_EVENT_TOKEN_V3_FAILURE';
export const GET_EVENT_TOKEN_V3_REQUEST = 'GET_EVENT_TOKEN_V3_REQUEST';
export const GET_EVENT_TOKEN_V3_SUCCESS = 'GET_EVENT_TOKEN_V3_SUCCESS';
export const GET_EVENT_TOURNAMENTS_V3_FAILURE = 'GET_EVENT_TOURNAMENTS_V3_FAILURE';
export const GET_EVENT_TOURNAMENTS_V3_REQUEST = 'GET_EVENT_TOURNAMENTS_V3_REQUEST';
export const GET_EVENT_TOURNAMENTS_V3_SUCCESS = 'GET_EVENT_TOURNAMENTS_V3_SUCCESS';
export const GET_EVENT_TRIAL_TOKEN_V3_FAILURE = 'GET_EVENT_TRIAL_TOKEN_V3_FAILURE';
export const GET_EVENT_TRIAL_TOKEN_V3_REQUEST = 'GET_EVENT_TRIAL_TOKEN_V3_REQUEST';
export const GET_EVENT_TRIAL_TOKEN_V3_SUCCESS = 'GET_EVENT_TRIAL_TOKEN_V3_SUCCESS';
export const GET_EVENT_V3_FAILURE = 'GET_EVENT_V3_FAILURE';
export const GET_EVENT_V3_PRICE_FAILURE = 'GET_EVENT_V3_PRICE_FAILURE';
export const GET_EVENT_V3_PRICE_REQUEST = 'GET_EVENT_V3_PRICE_REQUEST';
export const GET_EVENT_V3_PRICE_SUCCESS = 'GET_EVENT_V3_PRICE_SUCCESS';
export const GET_EVENT_V3_REQUEST = 'GET_EVENT_V3_REQUEST';
export const GET_EVENT_V3_SUCCESS = 'GET_EVENT_V3_SUCCESS';
export const GET_EVENT_V3_TAGS_FAILURE = 'GET_EVENT_V3_TAGS_FAILURE';
export const GET_EVENT_V3_TAGS_REQUEST = 'GET_EVENT_V3_TAGS_REQUEST';
export const GET_EVENT_V3_TAGS_SUCCESS = 'GET_EVENT_V3_TAGS_SUCCESS';
export const GET_EVENT_V3_TOURNAMENTS_SUCCESS = 'GET_EVENT_V3_TOURNAMENTS_SUCCESS';
export const PURCHASE_EVENT_V3_FAILURE = 'PURCHASE_EVENT_V3_FAILURE';
export const PURCHASE_EVENT_V3_REQUEST = 'PURCHASE_EVENT_V3_REQUEST';
export const PURCHASE_EVENT_V3_SUCCESS = 'PURCHASE_EVENT_V3_SUCCESS';
export const SET_EVENT = 'SET_EVENT';
export const SET_SELECTED_TEAM_IN_EVENT = 'SET_SELECTED_TEAM_IN_EVENT';

// TEAMS
export const SET_TEAMS = 'SET_TEAMS';

// CHANNELS
export const GET_CHANNEL_INFO_FAILURE = 'GET_CHANNEL_INFO_FAILURE';
export const GET_CHANNEL_INFO_REQUEST = 'GET_CHANNEL_INFO_REQUEST';
export const GET_CHANNEL_INFO_SUCCESS = 'GET_CHANNEL_INFO_SUCCESS';
export const GET_MATCHES_FAILURE = 'GET_MATCHES_FAILURE';
export const GET_MATCHES_REQUEST = 'GET_MATCHES_REQUEST';
export const GET_MATCHES_SUCCESS = 'GET_MATCHES_SUCCESS';
export const SET_CHANNEL_INFO = 'SET_CHANNEL_INFO';

// GAT
export const MERGE_GAT_STATE = 'MERGE_GAT_STATE';
export const SET_GAT_CREEP_BUILDINGS_DESTROYED = 'SET_GAT_CREEP_BUILDINGS_DESTROYED';
export const SET_GAT_DRAGONS = 'SET_GAT_DRAGONS';
export const SET_GAT_ENABLED = 'SET_GAT_ENABLED';
export const SET_GAT_PAUSES = 'SET_GAT_PAUSES';
export const SET_GAT_PLAYERS = 'SET_GAT_PLAYERS';
export const SET_GAT_STATE = 'SET_GAT_STATE';
export const SET_GAT_ZOOM_END = 'SET_GAT_ZOOM_END';
export const SET_GAT_ZOOM_START = 'SET_GAT_ZOOM_START';
export const SET_HIGHLIGHT_COLORS = 'SET_HIGHLIGHT_COLORS';

// CONTROL
export const NUMBER_OF_ALLOWED_PANES = 'NUMBER_OF_ALLOWED_PANES';
export const SET_AUTOPLAY = 'SET_AUTOPLAY';
export const SET_CASTER_VOLUME_RATIO = 'SET_CASTER_VOLUME_RATIO';
export const SET_CONTROL = 'SET_CONTROL';
export const SET_CONTROL_EXTERNAL_VIDEO_MUTE = 'SET_CONTROL_EXTERNAL_VIDEO_MUTE';
export const SET_MUTE = 'SET_MUTE';
export const SET_PANES = 'SET_PANES';
export const SET_POV_VOLUME_RATIO = 'SET_POV_VOLUME_RATIO';
export const SET_QUALITY = 'SET_QUALITY';
export const SET_VOLUME = 'SET_VOLUME';
export const TOGGLE_CASTER_AUDIO = 'TOGGLE_CASTER_AUDIO';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const TOGGLE_POV_AUDIO = 'TOGGLE_POV_AUDIO';
export const TOGGLE_ZNIPE_PING = 'TOGGLE_ZNIPE_PING';

// UI
export const TOGGLE_DEBUG = 'TOGGLE_DEBUG';
export const TOGGLE_JANKO_MODE = 'TOGGLE_JANKO_MODE';

// MACH_CREDENTIALS
export const GET_CREDENTIALS_FAILURE = 'GET_CREDENTIALS_FAILURE';
export const GET_CREDENTIALS_SUCCESS = 'GET_CREDENTIALS_SUCCESS';

// LOCALIZATION
export const FETCH_LOCALIZATION_STRINGS_FAILURE = 'FETCH_LOCALIZATION_STRINGS_FAILURE';
export const FETCH_LOCALIZATION_STRINGS_REQUEST = 'FETCH_LOCALIZATION_STRINGS_REQUEST';
export const FETCH_LOCALIZATION_STRINGS_SUCCESS = 'FETCH_LOCALIZATION_STRINGS_SUCCESS';

// CONSENT
export const SET_CONSENT = 'SET_CONSENT';
export const SET_OPTIONAL_COOKIES_CONSENT = 'SET_OPTIONAL_COOKIES_CONSENT';
export const SET_CRITICAL_COOKIES_CONSENT = 'SET_CRITICAL_COOKIES_CONSENT';
export const SHOW_COOKIE_POPUP = 'SHOW_COOKIE_POPUP';

// CORE_UI
export const SET_HIGHLIGHT_PING = 'SET_HIGHLIGHT_PING';

// ROOMS
export const SET_CONNECTION_LATENCY = 'SET_CONNECTION_LATENCY';
export const DISCONNECT_FROM_ROOM = 'DISCONNECT_FROM_ROOM';
export const SET_ROOM_ROLE = 'SET_ROOM_ROLE';
export const SET_ROOM_INFO = 'SET_ROOM_INFO';
export const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM';

// PRODUCTS
export const GET_PRODUCT_V4_REQUEST = 'GET_PRODUCT_V4_REQUEST';
export const GET_PRODUCT_V4_SUCCESS = 'GET_PRODUCT_V4_SUCCESS';
export const GET_PRODUCT_V4_FAILURE = 'GET_PRODUCT_V4_FAILURE';
export const GET_PRODUCTS_V4_REQUEST = 'GET_PRODUCTS_V4_REQUEST';
export const GET_PRODUCTS_V4_SUCCESS = 'GET_PRODUCTS_V4_SUCCESS';
export const GET_PRODUCTS_V4_FAILURE = 'GET_PRODUCTS_V4_FAILURE';
export const PURCHASE_PRODUCT_V4_REQUEST = 'PURCHASE_PRODUCT_V4_REQUEST';
export const PURCHASE_PRODUCT_V4_SUCCESS = 'PURCHASE_PRODUCT_V4_SUCCESS';
export const PURCHASE_PRODUCT_V4_FAILURE = 'PURCHASE_PRODUCT_V4_FAILURE';
export const GET_PRODUCT_V4_PRICE_REQUEST = 'GET_PRODUCT_V4_PRICE_REQUEST';
export const GET_PRODUCT_V4_PRICE_SUCCESS = 'GET_PRODUCT_V4_PRICE_SUCCESS';
export const GET_PRODUCT_V4_PRICE_FAILURE = 'GET_PRODUCT_V4_PRICE_FAILURE';
export const CANCEL_PRODUCT_SUBSCRIPTION_REQUEST = 'CANCEL_PRODUCT_SUBSCRIPTION_REQUEST';
export const CANCEL_PRODUCT_SUBSCRIPTION_SUCCESS = 'CANCEL_PRODUCT_SUBSCRIPTION_SUCCESS';
export const CANCEL_PRODUCT_SUBSCRIPTION_FAILURE = 'CANCEL_PRODUCT_SUBSCRIPTION_FAILURE';
export const GET_PRODUCT_V4_RECEIPTS_REQUEST = 'GET_PRODUCT_V4_RECEIPTS_REQUEST';
export const GET_PRODUCT_V4_RECEIPTS_SUCCESS = 'GET_PRODUCT_V4_RECEIPTS_SUCCESS';
export const GET_PRODUCT_V4_RECEIPTS_FAILURE = 'GET_PRODUCT_V4_RECEIPTS_FAILURE';
export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';
export const GET_PRODUCT_V4_RECEIPT_SECRET_REQUEST = 'GET_PRODUCT_V4_RECEIPT_SECRET_REQUEST';
export const GET_PRODUCT_V4_RECEIPT_SECRET_SUCCESS = 'GET_PRODUCT_V4_RECEIPT_SECRET_SUCCESS';
export const GET_PRODUCT_V4_RECEIPT_SECRET_FAILURE = 'GET_PRODUCT_V4_RECEIPT_SECRET_FAILURE';
export const ADD_PROMO_CODE_REQUEST = 'ADD_PROMO_CODE_REQUEST';
export const ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS';
export const ADD_PROMO_CODE_FAILURE = 'ADD_PROMO_CODE_FAILURE';

// VIDEO
export const GET_VIDEOS_REQUEST = 'GET_VIDEOS_REQUEST';
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';
export const GET_VIDEOS_FAILURE = 'GET_VIDEOS_FAILURE';
export const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAILURE = 'GET_VIDEO_FAILURE';
export const SET_HOMEPAGE_VIDEOS = 'SET_HOMEPAGE_VIDEOS';

// TOURNAMENTS
export const GET_EVENT_V3_TOURNAMENT_REQUEST = 'GET_EVENT_V3_TOURNAMENT_REQUEST';
export const GET_EVENT_V3_TOURNAMENT_SUCCESS = 'GET_EVENT_V3_TOURNAMENT_SUCCESS';
export const GET_EVENT_V3_TOURNAMENT_FAILURE = 'GET_EVENT_V3_TOURNAMENT_FAILURE';
export const GET_AVAILABLE_TOURNAMENTS_REQUEST = 'GET_AVAILABLE_TOURNAMENTS_REQUEST';
export const GET_AVAILABLE_TOURNAMENTS_SUCCESS = 'GET_AVAILABLE_TOURNAMENTS_SUCCESS';
export const GET_AVAILABLE_TOURNAMENTS_FAILURE = 'GET_AVAILABLE_TOURNAMENTS_FAILURE';
export const SET_HOMEPAGE_TOURNAMENT = 'SET_HOMEPAGE_TOURNAMENT';

// STATS
export const SET_STATS = 'SET_STATS';
export const SET_EXTENDED_STATS_FILTER = 'SET_EXTENDED_STATS_FILTER';

// PLAYER
export const IS_COMPACT_PLAYER = 'IS_COMPACT_PLAYER';
