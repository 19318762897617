import styled, { css } from 'styled-components';
import omitProps from '@hocs/omit-props';
import { Link } from 'react-router-dom';

export const Chevron = styled.div`
  position: absolute;
  height: 24px;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto;
  transition: all 0.3s ease;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

const transparentCss = css`
  background: transparent;
  border: none;
  margin: 15px 0;

  &:hover,
  &:focus {
    text-decoration: none;
    background: transparent;
  }
`;

const normalCss = css`
  position: relative;
  background: ${props => props.theme.paymentTypeButton.backgroundColor};
  border: 1px solid ${props => props.theme.paymentTypeButton.borderColor};
  padding: 15px;
  width: 100%;

  &:hover,
  &:focus {
    text-decoration: none;
    & > ${Chevron} {
      transform: translateX(8px);
    }
  }

  &:active {
    background-color: ${props => props.theme.paymentTypeButton.activeBackgroundColor};
    border-color: ${props => props.theme.paymentTypeButton.activeBorderColor};
    outline: none;
  }
`;

export const Button = styled(omitProps('isTransparent')(Link))`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${props => (props.isTransparent ? transparentCss : normalCss)}
`;

export const Text = styled.span`
  color: ${props => props.theme.paymentTypeButton.color};
  font-size: 20px;
  font-weight: normal;
  line-height: 25px;
  margin-left: 14px;
`;
