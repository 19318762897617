import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetPovVolumeRatio = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.povVolumeRatio ?? 1);
};

export const usePovVolumeRatio = () => useMemoizedSelector(makeGetPovVolumeRatio);

export default makeGetPovVolumeRatio;
