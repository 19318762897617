import convertTimestampToReadableTime from 'znipe-utils/date/convertTimestampToReadableTime';

const getTimeLabel = (isLive, timeInfo) => {
  const { currentTime = 0, seekRange = {} } = timeInfo;
  const { start = 0, end = 1 } = seekRange;
  if (isLive) {
    if (currentTime >= end - 10) {
      return 'LIVE';
    }
    const behindLiveLabel = convertTimestampToReadableTime(end - currentTime);

    return `-${behindLiveLabel} / LIVE`;
  }

  const currentTimeLabel = convertTimestampToReadableTime(currentTime - start);
  const endLabel = convertTimestampToReadableTime(end - start);

  return `${currentTimeLabel} / ${endLabel}`;
};

export default getTimeLabel;
