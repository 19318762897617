import { useCallback, useEffect, useRef, useState } from 'react';

const useShakeButton = () => {
  const [isShakeActivated, setIsShakeActivated] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const onSubmitAttempt = useCallback(() => {
    if (!isShakeActivated) {
      setIsShakeActivated(true);
      timeoutRef.current = setTimeout(() => {
        setIsShakeActivated(false);
      }, 1000);
    }
  }, [isShakeActivated]);

  return { isShakeActivated, onSubmitAttempt };
};

export default useShakeButton;
