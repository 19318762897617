import { from, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap, catchError } from 'rxjs/operators';
import firebase from 'firebase/app';
import config from 'tv-config/config';

let subscription = null;

export const logout = async cb => {
  if (subscription) subscription.unsubscribe();
  await firebase.auth().signOut();
  if (cb) cb();
};

export default jwt => {
  const authenticate$ = fromFetch(`${config.QUARTERBACK_API_URL}/login`, {
    headers: {
      Authorization: `bearer ${jwt}`,
    },
  })
    .pipe(
      switchMap(response => response.json()),
      catchError(logout),
    )
    .pipe(
      switchMap(({ results }) => {
        const { token } = results;
        if (!token) return logout();
        return from(firebase.auth().signInWithCustomToken(token)).pipe(
          switchMap(user => {
            if (!user) return logout();
            return of(user);
          }),
        );
      }),
      catchError(logout),
    );

  if (subscription) subscription.unsubscribe();
  subscription = authenticate$.subscribe();
  return subscription;
};
