import { memo } from 'react';
import PropTypes from 'prop-types';
import formatDate from 'znipe-utils/date/formatDate';
import { useGetAuthSubscriptionByProductId } from 'tv-selectors/auth/makeGetAuthSubscriptionByProductId';
import styled from 'styled-components';
import withTheme from 'znipe-themes/hocs/withTheme';
import { device } from 'znipe-styles/breakpoints';
import themes from './MembershipStatus.themes';

const Text = styled.p`
  margin: 0;
  padding: 0;
  line-height: 25px;
  color: ${({ theme }) => theme.membershipStatus.color};
  @media ${device.desktop} {
    font-size: 20px;
  }
`;

const EndDate = styled.span`
  font-weight: 700;
`;

const MembershipStatus = ({ productId, hasCancelled = false }) => {
  const productSubscription = useGetAuthSubscriptionByProductId({ productId });
  const userSince = formatDate(productSubscription.activationTime, true);
  const subscriptionEnd = formatDate(productSubscription.currentBillingEnd, true);

  if (hasCancelled)
    return (
      <Text data-testid="membership-cancel">
        Your subscription is cancelled and will expire on <EndDate>{subscriptionEnd}</EndDate>. If
        you wish to reactivate it, please update your payment method below.
      </Text>
    );

  return <Text data-testid="membership-status">Thanks for being a member since {userSince}!</Text>;
};

MembershipStatus.propTypes = {
  productId: PropTypes.string.isRequired,
  hasCancelled: PropTypes.bool,
};

export default withTheme(memo(MembershipStatus), themes, 'membershipStatus');
