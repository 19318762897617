import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'znipe-elements/layout/Accordion/Accordion';
import TeamDetailsCard from 'tv-modules/Teams/TeamDetailsCard/TeamDetailsCard';
import styled from 'styled-components';
import { SMALL } from 'tv-modules/TopicHeader/TopicHeader.constants';
import { device } from 'znipe-styles/breakpoints';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import { ROUND_ROBIN } from 'tv-routes/Standings/Standings.constants';
import useGqlStore from 'tv-hooks/useGqlStore';

const CardMargin = styled.div`
  margin-bottom: 8px;
`;

// TODO: This is a fix until the rivalries data is added
// Can be removed when that is ready to go
const CardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ $size }) => ($size === SMALL ? 'column' : 'row')};
  flex-wrap: wrap;
  ${CardMargin} {
    margin-right: 8px;
  }
`;

const TeamIcon = styled.img`
  height: 32px;
  width: 32px;
  @media ${device.tablet} {
    height: 48px;
    width: 48px;
  }
`;

const TeamIconWrapper = styled.div`
  user-select: none;
  display: grid;
  grid-template-rows: 1fr;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 32px);
  @media ${device.tablet} {
    gap: 25px;
    grid-template-columns: repeat(5, 1fr);
  }
`;

const emptyArray = [];
const emptyObject = {};
const EventGroups = ({ tournamentId = '', size = SMALL }) => {
  const gqlStore = useGqlStore();
  const tournamentStageId = useGqlSelector(state => {
    const tournamentStages = state.tournaments[tournamentId]?.tournamentStages ?? [];
    return tournamentStages.find(id => state.tournamentStages[id]?.type === ROUND_ROBIN);
  });
  const tournamentSchedules = useGqlSelector(
    state => state.tournamentStages[tournamentStageId]?.schedules ?? emptyArray,
  );
  const gameTitle = useGqlSelector(state => state.tournaments[tournamentId]?.game ?? '');

  const eventAccordion = useMemo(() => {
    const gqlState = gqlStore.getState();
    return tournamentSchedules
      .slice(0, 4)
      .reduce((items = [], { scheduleResults, scheduleName }, index) => {
        const children = scheduleResults?.map(({ team: teamId }) => {
          const teamDetails = gqlState.teams[teamId] ?? emptyObject;
          const { name, logoLight, country } = teamDetails;
          return (
            <CardMargin key={`${name}-card`}>
              <TeamDetailsCard
                teamName={name}
                country={country}
                image={logoLight}
                size={size}
                teamId={teamId}
                gameTitle={gameTitle}
              />
            </CardMargin>
          );
        });

        const headerChildren = scheduleResults?.map(({ team: teamId }) => {
          const teamDetails = gqlState.teams[teamId] ?? emptyObject;
          const { name, logoLight } = teamDetails;
          return {
            logo: logoLight,
            name,
          };
        });

        items.push({
          id: index,
          title: scheduleName,
          isActive: index === 0,
          children: <CardWrapper $size={size}>{children}</CardWrapper>,
          headerChildren: (
            <TeamIconWrapper $size={size}>
              {headerChildren.map(({ logo, name }) => (
                <TeamIcon key={name} src={logo} alt={name} $size={size} />
              ))}
            </TeamIconWrapper>
          ),
        });

        return items;
      }, []);
  }, [gqlStore, tournamentSchedules, size, gameTitle]);

  return <Accordion items={eventAccordion} size={size} />;
};

EventGroups.propTypes = {
  tournamentId: PropTypes.string,
  size: PropTypes.string,
};

export default memo(EventGroups);
