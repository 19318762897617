import { SET_STREAMS, SET_STREAM_TOKENS } from 'tv-constants/redux';

export const setStreams = (globalStreams, streamUrls) => {
  const streams = {
    globalStreams: globalStreams ? { ...globalStreams } : {},
    streamUrls: streamUrls ? { ...streamUrls } : {},
  };
  return {
    type: SET_STREAMS,
    payload: streams,
  };
};

export const setStreamTokens = (streams = {}) => ({
  type: SET_STREAM_TOKENS,
  payload: streams,
});
