/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useMemo, useState } from 'react';
import Flexsearch from 'flexsearch';
import { Emoji } from '../components/Emojis/Emojis.types';
import useChatContext from './useChatContext';

const createIndex = (data: Emoji[]) => {
  const index = Flexsearch.create({
    tokenize: 'full',
  });
  if (!index) return null;
  data.forEach((entry, i) => index.add(i, entry.shortcode));
  return index;
};

const searchOptions = {};
const emptyArray = [] as Emoji[];

export const useEmojiSearch = (query: string) => {
  const { allEmojis = emptyArray } = useChatContext() ?? {};
  const localIndex = useMemo(() => createIndex(allEmojis), [allEmojis]);
  const [searched, setSearched] = useState(emptyArray);

  useEffect(() => {
    if (!query || !localIndex) return;
    const normalizedQuery = query.replace(':', '');
    const promise = new Promise<Emoji[]>(async resolve => {
      const results = await localIndex.search(normalizedQuery, searchOptions);
      const mapped: Emoji[] = (results as number[])?.map(value => allEmojis?.[value]);
      resolve(mapped);
    });
    void promise.then(result => setSearched(result));
  }, [allEmojis, localIndex, query]);

  return searched;
};

export default useEmojiSearch;
