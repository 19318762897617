import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from './makeGetTournaments';

const getTournamentIdFromProps = (_, props) => props.tournamentId;

const makeGetTournamentLogo = () => {
  const getTournaments = makeGetTournaments();
  return createSelector(
    [getTournaments, getTournamentIdFromProps],
    (tournaments, tournamentId) => tournaments[tournamentId]?.tournamentLogo ?? '',
  );
};

export const useGetTournamentLogo = props => useMemoizedGqlSelector(makeGetTournamentLogo, props);

export default makeGetTournamentLogo;
