import Icon from 'znipe-elements/general/Icon/Icon';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, IconsContainer } from './LoginCTA.styles';
import useChatContext from '../../hooks/useChatContext';

const Icons = () => (
  <IconsContainer>
    <Icon type="chat" size={24} inline />
    <Icon type="exclamation" size={24} inline />
    <Icon type="threeHeads" size={24} inline />
    <Icon type="headset" size={24} inline />
    <Icon type="award" size={24} inline />
  </IconsContainer>
);

const LoginCTA = () => {
  const { onLoginClick } = useChatContext();

  return (
    <Container>
      <Typography type="paragraph-s" align="center">
        Login to cheer and banter in chat!
      </Typography>
      {onLoginClick && (
        <Button variant="solid-color" onClick={onLoginClick} size="xsmall">
          Login
        </Button>
      )}
      <Icons />
    </Container>
  );
};

export default LoginCTA;
