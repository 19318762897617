import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetVideoDate = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => video.date || '');
};

export const useGetVideoDate = props => useMemoizedGqlSelector(makeGetVideoDate, props);

export default makeGetVideoDate;
