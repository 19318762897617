import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SMALL, MEDIUM, LARGE } from 'tv-modules/TopicHeader/TopicHeader.constants';
import { smallSizes, mediumSizes, largeSizes } from '../TopicAbout.constants';
import { SectionTitle, CardScroller, CardsContainer, TeamWrapper } from '../TopicAbout.styles';

const TeamInfo = ({ size = LARGE, teamMateCards }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const elementSizes = useMemo(() => {
    if (size === LARGE) {
      return largeSizes;
    }
    if (size === MEDIUM) {
      return mediumSizes;
    }
    return smallSizes;
  }, [size]);

  return (
    <TeamWrapper>
      <SectionTitle dataTestId="team-players" type={elementSizes.title}>
        players
      </SectionTitle>
      {isDesktopOrGreater ? (
        <CardsContainer data-testid="teammate-cards">{teamMateCards}</CardsContainer>
      ) : (
        <ScrollContainer vertical={false}>
          <CardScroller data-testid="teammate-cards">{teamMateCards}</CardScroller>
        </ScrollContainer>
      )}
    </TeamWrapper>
  );
};

TeamInfo.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  teamMateCards: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default memo(TeamInfo);
