const isToday = someDate => {
  const today = new Date();
  const someDateAsDate = new Date(someDate);

  return (
    someDateAsDate.getDate() === today.getDate() &&
    someDateAsDate.getMonth() === today.getMonth() &&
    someDateAsDate.getFullYear() === today.getFullYear()
  );
};

export default isToday;
