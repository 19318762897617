import colors from 'znipe-styles/colors';

export default {
  proview: {
    headerText: {
      color: colors.riotTextPrimary,
      numberColor: colors.riotTextSecondary,
      padding: '36px',
      fontSize: '17px',
      fontWeight: 600,
    },
    icon: {
      fillColor: colors.white,
      hoverColor: colors.spaceGreen,
    },
  },
  default: {
    headerText: {
      color: colors.riotTextSecondary,
      numberColor: colors.riotTextSecondary,
      padding: '0px',
      fontSize: '14px',
      fontWeight: 700,
    },
    icon: {
      fillColor: colors.white,
      hoverColor: colors.riotAccentBlue,
    },
  },
};
