import { Fragment, memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SMALL, MEDIUM, LARGE } from './Accordion.constants';
import AccordionItem from './components/AccordionItem';
import AccordionHeader from './components/AccordionHeader';

const Accordion = ({ items, size = SMALL }) => {
  const [menuItems, setMenuItems] = useState([...items]);

  const handleClick = useCallback(
    id => {
      const selectedMenu = menuItems.map(item => {
        if (item.id === id) {
          return {
            ...item,
            isActive: !item.isActive,
          };
        }
        return { ...item, isActive: false };
      });
      setMenuItems(selectedMenu);
    },
    [menuItems],
  );

  const generateAccordion = useCallback(
    ({ id, title, isActive, children, headerChildren }) => (
      <Fragment key={id}>
        <AccordionHeader
          onClick={() => handleClick(id)}
          isActive={isActive}
          title={title}
          size={size}
        >
          {headerChildren}
        </AccordionHeader>
        <AccordionItem isActive={isActive}>{children}</AccordionItem>
      </Fragment>
    ),
    [handleClick, size],
  );

  return <div role="menu">{menuItems.map(generateAccordion)}</div>;
};

Accordion.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      children: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default memo(Accordion);
