import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

export const themes = {
  proview: {
    labelColor: colors.spaceGreen,
  },
  default: {
    labelColor: colors.spaceGreen,
  },
};

export const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const StreamLayout = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: ${props => {
    if (props.viewLayout === 3) {
      if (props.isCompact) {
        return '1.5fr 1fr';
      }
      return '2fr 1fr';
    }
    return 'repeat(2, 1fr)';
  }};
  width: 100%;
  grid-gap: 6px;
  @media ${device.tablet} {
    grid-gap: 9px;
  }
  @media ${device.desktop} {
    grid-gap: 12px;
  }
  @media ${device.desktopLarge} {
    grid-gap: 20px;
  }
`;

export const SelectorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  ${props =>
    props.isLayoutView &&
    css`
      max-width: 446px;
      @media ${device.tablet} {
        max-width: 676px;
      }
      @media ${device.desktop} {
        max-width: 850px;
      }
      @media ${device.desktopLarge} {
        max-width: 1251px;
      }
    `}
`;

export const BackAction = styled.button`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: ${colors.white};
`;

export const ActionLabel = styled.div`
  margin-left: 9px;
  color: ${props => (props.hasColor ? props.theme.screenPovSelectors.labelColor : colors.white)};
`;

export const SidebarContent = styled.div`
  min-width: ${props => (props.reserveSpace ? '40px' : 'auto')};
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StreamListWrapper = styled.div`
  width: 100%;
  display: block;
`;

export const PovSelectorsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const VideoPlayerholder = styled.div`
  width: 100%;
  max-width: 466px;
  margin-left: 52px;
  display: flex;
  align-items: center;
  @media ${device.desktopLarge} {
    max-width: 656px;
  }
`;

// 56.25% is 16:9 but we need some space for a border
export const AspectRatioBox = styled.div`
  width: 100%;
  padding-top: 57%;
  position: relative;
`;

export const AspectRatioContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
