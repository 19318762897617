import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const TosPage = styled.div`
  margin: auto;
  width: 70%;
  font-size: 14px;
  margin-bottom: 7%;

  a {
    color: #299ded;

    &:active,
    &:visited {
      color: #299ded;
    }

    &:hover,
    &:focus {
      color: #65c0ff;
    }

    h1 {
      font-weight: 200;
      text-align: center;
      font-size: 33px;
      text-transform: uppercase;
    }

    h2 {
      font-weight: 200;
      font-size: 27px;
      text-transform: uppercase;
    }
  }
`;

const Tos = () => (
  <TosPage>
    <Helmet title="Znipe´s Terms Of Use" />
    <div className="tos-page__content">
      <h1>Znipe.tv TERMS OF SERVICE </h1>
      <h2>1. INTRODUCTION</h2>
      <p>
        Thank you for choosing Znipe (“Znipe”, “we”, “us”, “our”). By signing up to or otherwise
        using the Znipe service, websites, and software applications (together, the “Znipe Service”
        or “Service”), or accessing any content or material that is made available by Znipe through
        the Service (the “Content”) you are entering into a binding contract with Znipe Esports AB,
        registration no. 559044-2389, having its registered address at Karlavägen 104, 115 26
        Stockholm.
      </p>
      <p>
        Your agreement with us includes these Terms and Conditions of Use (“Terms of Use”) and our
        <Link to="privacy" target="_blank">
          {' '}
          Privacy Policy{' '}
        </Link>
        . (The Terms of Use, Privacy Policy, and any additional terms that you agree to, as
        discussed in the
        <a href="#entireAgreement"> Entire Agreement section </a>, are referred to together as the
        “Agreements”.) If you wish to review the terms of the Agreements, the effective version of
        the Agreements can be found
        <a href="https://znipe.tv" target="_blank" rel="noopener noreferrer">
          {' '}
          Znipe’s website{' '}
        </a>
        . You acknowledge that you have read understood the Agreements, accept these Agreements,and
        agree to be bound by them. If you don’t agree with (or cannot comply with) the Agreements,
        then you may not use the Znipe Service or use any Content.
      </p>
      <p>
        In order to use the Znipe Service and access the Content, you need to (1) be of legal age in
        your country of resident or be 13 or older and have your parent or guardian’s consent to the
        Agreements, and (2) have the power to enter a binding contract with us and not be barred
        from doing so under any applicable laws. You also promise that any registration information
        that you submit to us is true, accurate, and complete, and you agree to keep it that way at
        all times. If you are a resident of one of the following countries, reference this chart for
        your country-specific age restrictions: <br />
        <br />
        <table>
          <tr>
            <th>Country</th>
            <th>Age requirements</th>
          </tr>
          <tr>
            <td>Chile, Ecuador, Paraguay, Peru</td>
            <td>Must be 18 or older, or be 15 or older and have parent or guardian consent.</td>
          </tr>
          <tr>
            <td>Brazil</td>
            <td>Must be 18 or older, or be 16 or older and have parent or guardian consent.</td>
          </tr>
          <tr>
            <td>Nicaragua, Taiwan</td>
            <td>Must be 20 or older, or be 13 or older and have parent or guardian consent.</td>
          </tr>
          <tr>
            <td>Bulgaria, Hungary, Germany</td>
            <td>Must be 18 or older, or be 14 or older and have parent or guardian consent.</td>
          </tr>
          <tr>
            <td>Italy</td>
            <td>
              Must be 13 or older to use Free Service. To register for any Paid Service, you must be
              18 or older, or be 13 or older and have parent or guardian consent (your
              parents/guardians will enter into contract on behalf of you).
            </td>
          </tr>
          <tr>
            <td>Malaysia</td>
            <td>
              Must be 18 or older, or if 13 to 18, parent or guardian consent is required, and
              guardian enters into agreement.
            </td>
          </tr>
          <tr>
            <td>Lithuania</td>
            <td>
              Must be 13 or older to use Service. For any Paid Service, you must be 18 or older, or
              be 14 or older with parent or guardian consent. If you are 13 to 18, guardian enters
              into agreement.
            </td>
          </tr>
          <tr>
            <td>Canada</td>
            <td>
              Must be 13 or older to use Service. For any Paid Service, you must be age of majority
              in your province or territory of residence, or 13 or older with parent or guardian
              consent.
            </td>
          </tr>
          <tr>
            <td>Spain</td>
            <td>
              Must be 14 or older to use Free Service. To register for any Paid Service, you must be
              18 or older, or be 14 or older and have parent or guardian consent (your
              parents/guardians will enter into contract on behalf of you).
            </td>
          </tr>
        </table>
      </p>

      <h2>2. CHANGES TO THE AGREEMENTS</h2>
      <p>
        Occasionally we may, in our discretion, make changes to the Agreements. When we make
        material changes to the Agreements, we’ll provide you with prominent notice as appropriate
        under the circumstances, e.g., by displaying a prominent notice within the Service or by
        sending you an email. In some cases, we will notify you in advance, and your continued use
        of the Service after the changes have been made will constitute your acceptance of the
        changes. Please therefore make sure you read any such notice carefully. If you do not wish
        to continue using the Service under the new versionof the Agreements, you may terminate the
        Agreements by contacting us through
        <a href="mailto:support@znipe.se" target="_top">
          {' '}
          support@znipe.se{' '}
        </a>
        .
      </p>
      <br />

      <h2>3. THE ZNIPE SERVICE</h2>
      <p>
        Our Services. Znipe provides streaming and on-demand services offering a selection of
        e-sport related livestream productions and other content. Certain Znipe Services may be
        provided to you free-of-charge. Other Znipe services require payment before you can access
        them. The Znipe services that may be accessed after payment are referred to as the “Paid
        Service”. You can learn more about our services by visiting our
        <a href="https://znipe.tv" target="_blank" rel="noopener noreferrer">
          {' '}
          website{' '}
        </a>
        .Codes and other pre-paid offers. If you have purchased or received a code, gift card,
        pre-paid offer or other offer provided or sold by or on behalf of Znipe for access to a Paid
        Service (“Code”), separate terms and conditions presented to you along with the Code may
        also apply to your access to the Service and you agree to comply with any such terms and
        conditions.
      </p>

      <h2>4. THIRD PARTY SERVICES</h2>
      <p>
        The Services may be integrated with certain third party applications, websites, and services
        (“Third Party Services”) to make available content, products, and/or services to you. These
        Third Party Services may have their own terms and conditions of use and privacy policies and
        your use of these Third Party Services will be governed by and subject to such terms and
        conditions and privacy policies. You understand and agree that we do not endorse and is not
        responsible or liable for the behaviour, features, or content of any Third Party Services or
        for any transaction you may enter into with the provider of any such Third Party Services.
      </p>

      <h2>5. RIGHTS WE GRANT YOU</h2>
      <p>
        The Znipe Service and the Content are the property of Znipe or Znipe&apos;s licensors. We
        grant you a limited, non-exclusive, revocable licence to make use of the Znipe Service, and,
        subject to payment of any applicable Paid Service Fees, a limited, non-exclusive, revocable
        licence to make personal, non-commercial, entertainment use of the Content, (the “Licence”).
        This Licence shall remain in effect until and unless terminated by you or Znipe. You promise
        and agree that you are using the Content for your own personal, non-commercial,
        entertainment use and that you will not redistribute or transfer the Znipe Service or the
        Content. The Znipe software applications and the Content are licensed, not sold, to you, and
        Znipe and its licensors retain ownership of all copies of the Znipe software applications
        and Content. All Znipe trademarks, service marks, trade names, logos, domain names, and any
        other features of the Znipe brand (“Znipe Brand Features”) are the sole property of Znipe.
        The Agreements do not grant you any rights to use any Znipe Brand Features whether for
        commercial or non-commercial use. Except for the rights expressly granted to you in these
        Agreements, Znipe grants no right, title, or interest to you in the Znipe Service or
        Content.
      </p>

      <h2>6. USER GENERATED CONTENT</h2>
      <p>
        Znipe users may post, upload, and/or contribute (“post”) content to the Service (which may
        include, for example, pictures, text, messages, information, and/or other types of content)
        (“User Content”). You promise that, with respect to any User Content you post on the Znipe
        Service, (1) you have the right to post such User Content, and (2) such User Content, or its
        use by Znipe as contemplated by the Agreements, does not violate the Agreements, applicable
        law, or the intellectual property (including without limitation copyright), publicity,
        personality, or other rights of others. Znipe may review, or edit User Content. In all
        cases, Znipe reserves the right to remove or disable access to any User Content for any or
        no reason, including but not limited to, User Content that, in Znipe’s sole discretion,
        violates the Agreements. Znipe may take these actions without prior notification to you or
        any third party. You are solely responsible for all User Content that you post.
      </p>

      <h2>7. RIGHTS YOU GRANT US</h2>
      <p>
        You grant Znipe a non-exclusive, transferable, sub-licensable, royalty-free, perpetual,
        irrevocable, fully paid, worldwide licence to use, reproduce, make available to the public
        (e.g. perform or display), publish, translate, modify, create derivative works from, and
        distribute any of your User Content in connection with the Service through any medium,
        whether alone or in combination with other content or materials, in any manner and by any
        means, method or technology, whether now known or hereafter created. Aside from the rights
        specifically granted herein, you retain ownership of all rights, including intellectual
        property rights, in the User Content. Where applicable and permitted under applicable law,
        you also agree to waive any “moral rights” (or the equivalent under applicable law) such as
        your right to be identified as the author of any User Content, and your right to object to
        derogatory treatment of such User Content.
      </p>

      <h2>8. USER GUIDELINES</h2>
      <p>
        The following is not permitted for any reason whatsoever:
        <br />
        <br />• copying, redistributing, transferring, or otherwise making available to the public
        any part of the Znipe Service or the Content;
        <br />• reverse-engineering, decompiling or creating derivative works based on the Znipe
        Service, Content or any part thereof unless permitted by applicable law;
        <br />• circumventing any technology used by Znipe, its licensors, or any third party to
        protect the Content or the Service;
        <br />• selling, renting, sublicensing or leasing of any part of the Znipe Service or the
        Content;
        <br />• providing your password to any other person or using any other person’s username and
        password;
        <br />• “crawling” the Znipe Service or otherwise using any automated means (including bots,
        scrapers, and spiders) to collect information from Znipe; or
        <br />• including malicious content such as malware, Trojan horses, or viruses.
        <br />
        <br />
        Don’t engage in any activity, post any User Content, or register and/or use a username,
        which is or includes material that:
        <br />
        <br />• is offensive, abusive, defamatory, pornographic, threatening, or obscene;
        <br />• is illegal, or intended to promote or commit an illegal act of any kind, including
        but not limited to violations of intellectual property rights and privacy rights
        <br />• includes your password or purposely includes any other user’s password or purposely
        includes personal data of third parties or is intended to solicit such personal data;
        <br />• involves the transmission of unsolicited mass mailings or other forms of spam;
        <br />• interferes with or in any way disrupts or tampers with the Znipe Service or Znipe’s
        computer systems or network;
        <br />• conflicts with the Agreements. You acknowledge and agree that posting any such User
        Content may result in immediate termination or suspension of your Znipe account. You also
        agree that Znipe may also reclaim your username for any reason. Your password protects your
        user account, and you are solely responsible for keeping your password confidential and
        secure. If your username or password is lost or stolen, or if you believe that any other
        person has illegitimately accessed your account, please notify us immediately and change
        your password as soon as possible.
      </p>

      <h2>9. SERVICE LIMITATIONS AND MODIFICATIONS</h2>
      <p>
        Znipe will make reasonable efforts to keep the Znipe Service operational. However, certain
        technical difficulties or maintenance may, from time to time, result in temporary
        interruptions. Znipe reserves the right, periodically and at any time, to modify or
        discontinue, temporarily or permanently, functions and features of the Znipe Service, with
        or without notice, all without liability to you, except where prohibited by law, for any
        interruption, modification, or discontinuation of the Znipe Service or any function or
        feature thereof. Notwithstanding the foregoing, if you have prepaid fees for any Paid
        Service that Znipe permanently discontinues before you have had chance to access such a Paid
        Service, Znipe will refund you the prepaid fees for the Paid Service after such
        discontinuation.
      </p>

      <h2>10. PAYMENTS AND COOLING OFF</h2>
      <p>
        Access to Content on the Paid Service can be purchased by pre-payment giving you access to
        various packaged Content as further described on our{' '}
        <a href="https://znipe.tv" target="_blank" rel="noopener noreferrer">
          {' '}
          website{' '}
        </a>
        .
      </p>
      <p>
        When you register for any Paid Service or Code online, you consent to get access to the Paid
        Service immediately. Where required under mandatory consumer law, you may change your mind
        for any or no reason and receive a full refund of all monies paid within fourteen (14) days
        from the date of the purchase (the “Cooling-off Period”). Refunds will not, however, be
        provided if you have accessed the purchased Paid Service at any time during the Cooling-off
        Period. If you wish to exercise your rights to cancel your purchase of a Paid Service and
        receive a refund you must
        <a href="mailto:support@znipe.se" target="_top">
          {' '}
          contact Customer Support{' '}
        </a>
        before the Cooling-off Period is over.
      </p>
      <p>
        Znipe may change the price for some or all Paid Services, or Codes from time to time, and
        will communicate any price changes to you in advance and, if applicable, how to accept those
        changes.{' '}
      </p>

      <h2>11. TERM AND TERMINATION</h2>

      <p>
        The Agreements will continue to apply to you until terminated by either you or Znipe.
        However, you acknowledge and agree that the perpetual licence granted by you in relation to
        User Content, is irrevocable and will therefore continue after expiry or termination of any
        of the Agreements for any reason. Znipe may terminate the Agreements or suspend your access
        to the Znipe Service at any time, including in the event of your actual or suspected
        unauthorised use of the Znipe Service and/or Content, or non-compliance with the Agreements.
        To learn how to terminate your Znipe account, please
        <a href="mailto:support@znipe.se" target="_top">
          {' '}
          contact Customer Support{' '}
        </a>
        . You may terminate the Agreements at any time.
      </p>
      <p>
        Any sections of the Agreements that, either explicitly or by their nature, must remain in
        effect even after termination of the Agreements, shall survive termination.
      </p>

      <h2>12. WARRANTY AND DISCLAIMER</h2>

      <p>
        WE ENDEAVOUR TO PROVIDE THE BEST SERVICE WE CAN, BUT YOU UNDERSTAND AND AGREE THAT THE ZNIPE
        SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE”, WITHOUT EXPRESS OR IMPLIED WARRANTY OR
        CONDITION OF ANY KIND. YOU USE THE ZNIPE SERVICE AT YOUR OWN RISK. TO THE FULLEST EXTENT
        PERMITTED BY APPLICABLE LAW, ZNIPE MAKES NO REPRESENTATIONS AND DISCLAIM ANY WARRANTIES OR
        CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON-INFRINGEMENT.
      </p>

      <h2>13. LIMITATION</h2>

      <p>
        YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY
        FOR ANY PROBLEMS OR DISSATISFACTION WITH THE ZNIPE SERVICE IS TO UNINSTALL ANY ZNIPE
        SOFTWARE AND TO STOP USING THE ZNIPE SERVICE. TO THE FULLEST EXTENT PERMITTED BY LAW,IN NO
        EVENT WILL ZNIPE, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES,
        AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR (1) ANY INDIRECT,
        SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE,
        DATA, BUSINESS, OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE
        OR INABILITY TO USE THE ZNIPE SERVICE, REGARDLESS OF LEGAL THEORY, WITHOUT REGARD TO WHETHER
        ZNIPE HAS BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS
        ESSENTIAL PURPOSE; OR (3) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE ZNIPE SERVICE,
        THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION CONTENT MORE THAN THE AMOUNTS PAID BY
        YOU TO ZNIPE DURING THE PRIOR TWELVE MONTHS IN QUESTION, TO THE EXTENT PERMISSIBLE BY
        APPLICABLE LAW.
      </p>
      <p>
        Nothing in the Agreements removes or limits Znipe’s liability for fraud, fraudulent
        misrepresentation, death or personal injury caused by its negligence, and, if required by
        applicable law, gross negligence. Some aspects of this section may not apply in some
        jurisdictions if prohibited by applicable law.
      </p>

      <h2 id="entireAgreement">14. ENTIRE AGREEMENT</h2>
      <p>
        Other than as stated in this section or as explicitly agreed upon in writing between you and
        Znipe, the Agreements constitute all the terms and conditions agreed upon between you and
        Znipe and supersede any prior agreements in relation to the subject matter of these
        Agreements, whether written or oral.
      </p>
      <p>
        Please note, however, that other aspects of your use of the Znipe Service may be governed by
        additional agreements. That could include, for example, access to the Znipe Service as a
        result of a gift card or free or discounted trials. When you are presented with an offer for
        such aspects of your use, you will be presented with any related additional agreement, and
        you may have an opportunity to agree to additional terms. To the extent that there is any
        irreconcilable conflict between any additional terms and these Terms, the additional terms
        shall prevail.
      </p>

      <h2>15. SEVERABILITY AND WAIVER</h2>
      <p>
        Unless as otherwise stated in the Agreements, should any provision of the Agreements be held
        invalid or unenforceable for any reason or to any extent, such invalidity or enforceability
        shall not in any manner affect or render invalid or unenforceable the remaining provisions
        of the Agreements, and the application of that provision shall be enforced to the extent
        permitted by law.
      </p>
      <p>
        Any failure by Znipe or any third party beneficiary to enforce the Agreements or any
        provision thereof shall not waive Znipe’s or the applicable third party beneficiary’s right
        to do so.
      </p>

      <h2>16. ASSIGNMENT</h2>
      <p>
        Znipe may assign the Agreements or any part of them, and Znipe may delegate any of its
        obligations under the Agreements. You may not assign the Agreements or any part of them, nor
        transfer or sub-license your rights under the Agreements, to any third party.
      </p>

      <h2>17. INDEMNIFICATION</h2>
      <p>
        To the fullest extent permitted by applicable law, you agree to indemnify and hol Znipe
        harmless from and against all damages, losses, and expenses of any kind (including
        reasonable attorney fees and costs) arising out of: (1) your breach of this Agreement; (2)
        any User Content; (3) any activity in which you engage on or through the Znipe Service; and
        (4) your violation of any law or the rights of a third party.
      </p>
      <h2>18. CHOICE OF LAW AND DISPUTE VENUE</h2>
      <p>
        The Agreements (and any non-contractual disputes/claims arising out of or in connection with
        them) shall be governed by the laws of Sweden without regard to its conflict of law
        provisions.
      </p>
      <p>
        All disputes arising out of or in connection with the present Agreement shall be finally
        settled under the Rules of Arbitration of the International Chamber of Commerce by one or
        more arbitrators appointed in accordance with the said Rules.
      </p>
      <p>
        The place of the arbitration shall be Stockholm, Sweden. The language of the arbitration
        shall be English.
      </p>
      <p>
        Nothing in this clause will prevent Znipe for seeking enforcement for payments due under
        this Agreement.
      </p>

      <h2>19. CONTACT US</h2>
      <p>
        If you have any questions concerning the Znipe Service or the Agreements, please contact
        Znipe customer service by
        <a href="mailto:support@znipe.se" target="_top">
          {' '}
          support@znipe.se{' '}
        </a>
        .
      </p>
      <p>Thank you for reading our Terms. We hope you enjoy Znipe!</p>
    </div>
  </TosPage>
);

export default Tos;
