import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import { BackgroundLayer } from '../Shared.styles';
import { Container as SearchResultItem } from '../SearchResultItem/SearchResultItem.styles';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  width: 100%;
  padding: 0 ${({ spacing }) => spacing}px;
`;

export const Results = styled.div`
  display: flex;
  padding: 0 ${({ spacing }) => spacing}px;
  ${SearchResultItem} {
    margin-right: ${({ spacing }) => spacing}px;
  }
`;

export const List = styled.div`
  width: 100%;
`;

export const HorizontalDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.containerStroke};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: auto 0;
`;

export const IconWrapper = styled.div`
  height: 100%;
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  & > p {
    margin: auto 0;
  }
`;

export const Row = styled.div.attrs(() => ({
  type: 'button',
}))`
  ${buttonDefaultStyle}
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 60px;
  cursor: pointer;
  ${HorizontalDivider} {
    width: ${({ spacing }) => `calc(100% - ${spacing * 2}px)`};
  }
  ${ContentWrapper} {
    padding: 0 ${({ spacing }) => spacing}px;
  }
  @media ${device.desktop} {
    &:focus,
    &:hover {
      ${BackgroundLayer} {
        opacity: 10%;
      }
    }
  }
`;
