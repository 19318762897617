import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const makeGetTeamHistory = () => {
  const getTeams = makeGetTeams();
  return createSelector(
    [getTeams, getTeamIdFromProps],
    (allTeams, teamId) => allTeams[teamId]?.stories?.teamHistory ?? '',
  );
};

export const useGetTeamHistory = props => useMemoizedGqlSelector(makeGetTeamHistory, props);

export default makeGetTeamHistory;
