import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';

export const Wrapper = styled.div`
  padding: 15px 25px 20px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding: 35px 70px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 150%;
  margin-top: 20px;
  max-width: 250px;
  white-space: initial;
  text-align: center;

  @media ${device.tablet} {
    margin-top: 35px;
  }
`;

export const CcvButton = styled.button.attrs({
  type: 'button',
  'data-testid': 'ccv-button',
})`
  height: 20px;
  width: 20px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${colors.grey3};
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
`;

export const CardInfoSVG = styled.svg.attrs({
  viewBox: '0 0 247 133',
})`
  width: 247px;
  height: 133px;
`;
