import { useCallback, useMemo } from 'react';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import popupWindow from 'znipe-utils/misc/popupWindow';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameCompetitorsLineups } from 'tv-selectors/games/makeGetGameCompetitorsLineups';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useGetStreamLabel } from 'tv-selectors/streams/makeGetStreamLabel';
import { setMatchPlayerStreams } from 'tv-actions/matches';
import useTheme from 'tv-hooks/useTheme';
import Icon from 'znipe-elements/general/Icon/Icon';

const Container = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  ${({ isVideoOverlay }) => {
    if (isVideoOverlay) {
      return css`
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
      `;
    }
    return css`
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
    `;
  }}
`;

const PopoutStream = ({
  matchId,
  streamId,
  selectedMatchGameIndex,
  isPlayerPopout = false,
  onWindowPopout = () => {},
  isVideoOverlay = false,
  isPopout = false,
}) => {
  const theme = useTheme();
  const { themeName } = theme;
  const gqlDispatch = useGqlStoreDispatch();
  const streams = useGetMatchPlayerStreams({ matchId, selectedMatchGameIndex });
  const matchGameLineups = useGetGameCompetitorsLineups({
    matchId,
    selectedMatchGame: selectedMatchGameIndex,
  });
  const globalStreams = useGetGameGlobalStreams({ matchId, selectedMatchGameIndex });

  const playerId = useMemo(() => {
    const playerInfo = matchGameLineups.find(lineup => lineup.streamId === streamId) || {};
    const selectedPlayerId = playerInfo.playerId || '';
    if (selectedPlayerId) return selectedPlayerId;
    const selectedGlobalStream = globalStreams.find(stream => stream.streamId === streamId);
    if (selectedGlobalStream) return `${streamId}_${selectedGlobalStream.type || ''}`;
    return '';
  }, [matchGameLineups, globalStreams, streamId]);

  const streamLabel = useGetStreamLabel({ matchId, streamId, playerId });

  const handleClick = useCallback(() => {
    if (isPlayerPopout) {
      const numberOfStreams = streams.length;

      // Dont allow popout in single view
      if (isPlayerPopout && numberOfStreams === 1) return;

      const poppedStreamInfo = streams.find(stream => stream.id === streamId) || {};
      const newStreams = streams.filter(stream => stream.id !== streamId);
      const poppedOutMaster = poppedStreamInfo.master || false;

      if (poppedOutMaster) {
        const firstStream = { ...newStreams[0] };
        firstStream.master = true;
        newStreams[0] = firstStream;
      }

      gqlDispatch(setMatchPlayerStreams(matchId, newStreams));
    }

    const url = `${themeName}/popout?type=player&m=${matchId}&g=${selectedMatchGameIndex}&pid=${playerId}`;
    const windowRef = popupWindow(url, `Znipe TV - ${streamLabel}`);
    const popoutWindowId = `${matchId}-${playerId}`;
    onWindowPopout(windowRef, popoutWindowId);
  }, [
    matchId,
    playerId,
    streamId,
    streamLabel,
    selectedMatchGameIndex,
    streams,
    gqlDispatch,
    onWindowPopout,
    themeName,
    isPlayerPopout,
  ]);

  return (
    <Container
      aria-label={isPopout ? 'Pop In' : 'Pop Out'}
      data-testid="popout-stream-button"
      onClick={handleClick}
      isVideoOverlay={isVideoOverlay}
    >
      <Icon type={isPopout ? 'popIn' : 'popOut'} size={24} fillColor="white" />
    </Container>
  );
};

PopoutStream.propTypes = {
  matchId: PropTypes.string.isRequired,
  streamId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  onWindowPopout: PropTypes.func,
  isPlayerPopout: PropTypes.bool,
  isVideoOverlay: PropTypes.bool,
  isPopout: PropTypes.bool,
};

export default PopoutStream;
