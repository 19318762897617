import monthNumberToString from './monthNumberToString';

/**
 *
 * @param {Date} dateString 2020-08-27T08:22:32Z
 * @param {boolean} showYear
 * @param {string} type DDMMYY / MMDDYY
 * @returns {string} Aug 27
 */

const formatDate = (dateString, showYear = false, type = 'DDMMYY') => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  if (isNaN(date)) {
    return '';
  }
  const month = `${date.getMonth() + 1}`;
  const monthString = monthNumberToString(parseInt(month, 10));
  const day = `0${date.getDate()}`.slice(-2);
  const year = `${date.getFullYear()}`;

  if (type === 'MMDDYY') {
    return `${monthString} ${day}${showYear ? ` ${year}` : ''}`;
  }

  return `${day} ${monthString}${showYear ? ` ${year}` : ''}`;
};

export default formatDate;
