import { SEARCH_RESULTS_ENDPOINT } from 'tv-constants/apiEndpoints';
import { LOL_PATCH_VERSION } from 'znipe-constants/misc';

// eslint-disable-next-line import/prefer-default-export
export const fetchSearchResults = async (query, limit = 10, productPackage = 'proview') => {
  try {
    const term = encodeURI(query);
    const response = await fetch(
      `${SEARCH_RESULTS_ENDPOINT}?q=${term}&c=players,teams,events,riotChampions&f=packages.humanReadableId:${productPackage},patch:${LOL_PATCH_VERSION}&l=${limit}&asSingleList=true`,
      { headers: { 'content-type': 'application/json' } },
    );
    const { results } = await response.json();
    return results;
  } catch (error) {
    return error;
  }
};
