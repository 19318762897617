const getNow = () => Math.floor(Date.now() / 1000);

type CacheState = { [key: string]: unknown };
type ExpireTimes = { [key: string]: number };

export default class GglClient {
  state: CacheState = {};

  expireTimes: ExpireTimes = {};

  preloaded: Set<string> = new Set();

  defaultState: CacheState = {};

  init(options: { cache?: CacheState; expireTimes?: ExpireTimes } = {}) {
    const { cache: initialState = {}, expireTimes: initialExpireTimes = {} } = options;
    const preFetchedKeys = Object.keys(initialExpireTimes);
    this.state = { ...this.defaultState, ...initialState };
    this.expireTimes = initialExpireTimes;
    this.preloaded = new Set(preFetchedKeys);
  }

  cache(key: string, value: unknown, time: number) {
    if (typeof time !== 'number') return;
    this.expireTimes[key] = getNow() + time;
    this.state[key] = value;
  }

  get<T = unknown>(key: string): T | undefined {
    return this.state[key] as T;
  }

  getCacheExpireTimes() {
    return this.expireTimes;
  }

  clearCache(key: string) {
    delete this.state[key];
    delete this.expireTimes[key];
    this.preloaded.delete(key);
  }

  clearAllCache() {
    this.state = this.defaultState;
  }

  isExpired(key: string): boolean {
    const expireTime = this.expireTimes[key];
    if (!expireTime) return true;
    if (expireTime <= getNow()) return true;
    return false;
  }

  isPreloaded(key: string): boolean {
    return this.preloaded.has(key);
  }
}
