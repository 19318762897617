import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: ${({ theme, noBackground }) => (noBackground ? 'none' : theme.containerStroke)};
  padding: ${({ size }) => {
    switch (size) {
      case 'xsmall':
        return '4px 8px';
      case 'small':
        return '6px 12px';
      case 'medium':
        return '6px 16px';
      default:
        return '6px 16px';
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'xsmall':
        return css`
          min-width: 48px;
          height: 16px;
        `;
      case 'small':
        return css`
          min-width: 60px;
          height: 22px;
        `;
      case 'medium':
        return css`
          min-width: 72px;
          height: 25px;
        `;
      default:
        return css`
          min-width: 83px;
          height: 29px;
        `;
    }
  }}
`;

export const LiveDotWrapper = styled.div`
  display: flex;
  margin-right: 4px;
`;

export const LabelWrapper = styled.div`
  display: flex;
`;
