import { memo } from 'react';
import PropTypes from 'prop-types';
import { Container, InnerContainer, Image } from '../Avatar.styles';

const deprecatedDefaultPlayerImage =
  'https://assets.znipe.tv/teams/default/players/genericplayer.svg';
const defaultPlayerImageSmall = 'https://assets.znipe.tv/proview/players/default-player-img.png';
const defaultPlayerImage = 'https://assets.znipe.tv/players/default-player-img-medium.png';

const invalidImages = [deprecatedDefaultPlayerImage, defaultPlayerImageSmall];

const PlayerAvatar = ({ imageUrl, imageAlt = 'Player image', size }) => {
  const image = invalidImages.includes(imageUrl) ? defaultPlayerImage : imageUrl;
  return (
    <Container $size={size}>
      <InnerContainer data-testid="avatar-inner-container" $isCircle $hasBorder>
        <Image data-testid="player-avatar-image" src={image} alt={imageAlt} />
      </InnerContainer>
    </Container>
  );
};

PlayerAvatar.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  size: PropTypes.number,
};

export default memo(PlayerAvatar);
