import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from 'tv-selectors/match/makeGetMatch';

const makeGetMatchStartTime = () => {
  const getSelectedMatch = makeGetMatch();
  return createSelector([getSelectedMatch], selectedMatch => selectedMatch?.startTime || '');
};

export const useGetMatchStartTime = props => useMemoizedGqlSelector(makeGetMatchStartTime, props);

export default makeGetMatchStartTime;
