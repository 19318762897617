import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedGame from 'tv-selectors/games/makeGetSelectedGame';

const makeGetGameHasStats = () => {
  const getSelectedGame = makeGetSelectedGame();
  return createSelector([getSelectedGame], game => game.hasStats ?? false);
};

export const useGetGameHasStats = props => useMemoizedGqlSelector(makeGetGameHasStats, props);

export default makeGetGameHasStats;
