import { createSelector } from 'reselect';
import makeGetPlayerId from 'tv-selectors/players/makeGetPlayerId';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';

const emptyObject = {};

const makeGetSelectedPlayer = () => {
  const getPlayersMap = makeGetPlayers();
  const getPlayerId = makeGetPlayerId();
  return createSelector(
    [getPlayersMap, getPlayerId],
    (players, playerId) => players[playerId] || emptyObject,
  );
};

export default makeGetSelectedPlayer;
