import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import useTheme from 'tv-hooks/useTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, TitleWrapper, IconWrapper, Label } from './ProfileLabel.styles';

const SMALL = 'small';
const MEDIUM = 'medium';

const ProfileLabel = ({ icon = '', text = '', size = SMALL, title = '' }) => {
  const theme = useTheme();
  const elementSizes = useMemo(() => {
    switch (size) {
      case MEDIUM:
        return {
          title: 'title-s',
          text: 'heading-s',
          icon: 24,
        };
      default:
        return {
          title: 'title-xs',
          text: 'heading-xs',
          icon: 20,
        };
    }
  }, [size]);

  return (
    <Container data-testid="profile-label">
      <TitleWrapper>
        <Typography dataTestId="title" type={elementSizes.title} color={theme.secondaryTextColor}>
          {title}
        </Typography>
      </TitleWrapper>
      <Label>
        <IconWrapper>
          <Icon type={icon} size={elementSizes.icon} inline fillColor={theme.highlight} />
        </IconWrapper>
        <div>
          <Typography dataTestId="label" type={elementSizes.text}>
            {text}
          </Typography>
        </div>
      </Label>
    </Container>
  );
};

ProfileLabel.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf([SMALL, MEDIUM]),
};

export default memo(ProfileLabel);
