const HappyPoro = () => (
  <svg viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M378.27 241.32C375.908 232.855 372.135 224.85 367.11 217.64C374.68 209.32 400 185.28 400 141.18C400 140.9 400 140.63 400 140.35C399.766 127.037 396.325 113.977 389.969 102.277C383.613 90.5767 374.53 80.5817 363.489 73.1394C352.448 65.697 339.775 61.0268 326.546 59.5245C313.316 58.0223 299.918 59.7325 287.49 64.51L286.85 41.17C286.85 41.17 258.3 49.29 247.76 60.42C239.43 45.12 225 0 225 0C225 0 185.33 14.05 154.21 49.09L136.49 35.41C136.49 35.41 125.76 52.52 118.09 66.9C106.31 60.45 97.16 59 82.78 59C37.61 59 0 95.16 0 140.11C0 184.75 32 208 32 208C28.67 216.31 12.62 248 19.12 289.07L28.63 283.32C16.23 324.32 26 355.92 26 355.92C0 394.94 21.38 400 33.78 400H378.68C396.95 400 410.13 376.73 378.68 352.16C378.68 352.16 385.28 318.27 377.13 275.56L389.23 280.07L378.27 241.32Z"
      fill="#C0A686"
    />
    <path
      d="M165 143.38C165 143.38 146.8 91 108.12 76.89C67.9999 62.28 -2.54007 98.74 20.8499 173.88C21.2484 175.138 22.0165 176.247 23.0541 177.062C24.0917 177.877 25.3507 178.361 26.6672 178.451C27.9837 178.54 29.2967 178.231 30.4351 177.564C31.5736 176.897 32.4847 175.902 33.0499 174.71C37.3899 165.31 45.2499 156.58 59.1299 161.64C85.4999 171.24 74.7099 198.18 74.7099 198.18L165 143.38Z"
      fill="#754C28"
    />
    <path
      d="M238.87 143.05C238.87 143.05 256.21 93 293.17 79.52C331.51 65.52 398.9 100.4 376.54 172.19C376.16 173.392 375.426 174.452 374.435 175.232C373.444 176.011 372.241 176.475 370.983 176.561C369.724 176.647 368.469 176.353 367.381 175.716C366.293 175.079 365.421 174.129 364.88 172.99C360.74 163.99 353.22 155.67 339.96 160.5C314.76 169.67 325.08 195.41 325.08 195.41L238.87 143.05Z"
      fill="#754C28"
    />
    <path
      d="M301 77.4399C298.35 77.917 295.745 78.6127 293.21 79.5199C288.058 81.4766 283.188 84.1096 278.73 87.3499C294.53 94.6599 320.5 114.35 332.64 164.35C334.908 162.728 337.387 161.426 340.01 160.48C343.928 158.937 348.273 158.866 352.24 160.28C341.22 114.5 319.52 90.1699 301 77.4399Z"
      fill="#603813"
    />
    <path
      d="M122.72 84.64C118.204 81.4444 113.297 78.84 108.12 76.89C104.762 75.7294 101.294 74.9151 97.7701 74.46C79.6501 85.85 55.3801 110.4 43.5901 162.39C47.6801 160.12 52.7001 159.31 59.1401 161.65C60.783 162.337 62.3624 163.167 63.8601 164.13C80.0001 97.25 117.74 85.81 122.72 84.64Z"
      fill="#603813"
    />
    <path
      d="M216.38 25.22C216.38 25.22 50.1299 132.05 50.1299 298C50.1299 357.51 114.54 384.87 200.32 384.87C286.1 384.87 349 360.46 349 317.07C349 187.44 241.29 157.84 216.38 25.22Z"
      fill="white"
    />
    <path
      d="M46.6599 311.9C46.6599 146.71 128.95 101.63 144.26 57.6101C144.26 57.6101 192.75 94.4001 165.73 142.68"
      fill="white"
    />
    <path
      d="M96.2401 158.73C96.2401 158.73 49.8201 168.23 43.1001 194.29C43.1001 194.29 69.1001 190.59 83.7101 207.97"
      fill="white"
    />
    <path
      d="M71.65 184.75C70.2 185.92 36.95 214.05 33.78 250.88C33.78 250.88 64.67 231.24 84.06 234.7"
      fill="white"
    />
    <path
      d="M55.6999 224.63C11.6999 340.72 69.0599 384.84 69.0599 384.84H104.14L120.69 350.84"
      fill="white"
    />
    <path
      d="M327.22 202C405.68 329.29 337.51 384.84 337.51 384.84H299.51L282.96 350.84"
      fill="white"
    />
    <path
      d="M231.49 148.73L322 224C352.59 219.55 365 196 365 196C337.9 197.53 281 117.31 281 117.31"
      fill="white"
    />
    <path
      d="M221.68 114.35C221.68 114.35 228.68 82.63 269.46 64.73C269.46 64.73 271.13 126.57 310.63 160.57"
      fill="white"
    />
    <path d="M334.24 213.89C334.24 213.89 357.54 220.44 366.05 251.61L338.77 242.43" fill="white" />
    <path
      d="M76.63 167.67C76.63 167.67 63.49 152.28 79.9 123.6C79.9 123.6 100.76 138.31 110.29 135.36"
      fill="white"
    />
    <path
      d="M329.08 174.25C329.08 174.25 310.63 151.57 304.43 120.36C298.905 127.887 295.04 136.5 293.09 145.63"
      fill="white"
    />
    <path
      d="M272.47 287.11C272.47 287.11 281.2 297.39 281.2 315.11C281.2 332.99 263.28 342.7 244.75 342.7C218.66 342.7 200.64 320.6 200.64 320.6C200.64 320.6 182.26 342.7 159.53 342.7C135.11 342.7 122.72 328.1 122.72 314.07C122.72 297.43 138.65 283.77 138.65 283.77"
      fill="white"
    />
    <path
      d="M272.47 287.11C272.47 287.11 281.2 297.39 281.2 315.11C281.2 332.99 263.28 342.7 244.75 342.7C218.66 342.7 200.64 320.6 200.64 320.6C200.64 320.6 182.26 342.7 159.53 342.7C135.11 342.7 122.72 328.1 122.72 314.07C122.72 297.43 138.65 283.77 138.65 283.77"
      stroke="#754C28"
      strokeWidth="23.33"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M320.84 288.4C339.248 288.4 354.17 280.211 354.17 270.11C354.17 260.009 339.248 251.82 320.84 251.82C302.432 251.82 287.51 260.009 287.51 270.11C287.51 280.211 302.432 288.4 320.84 288.4Z"
      fill="#E89EC5"
    />
    <path
      d="M84.06 288.4C102.468 288.4 117.39 280.211 117.39 270.11C117.39 260.009 102.468 251.82 84.06 251.82C65.6523 251.82 50.73 260.009 50.73 270.11C50.73 280.211 65.6523 288.4 84.06 288.4Z"
      fill="#E89EC5"
    />
    <path
      d="M245.62 234.87C250.739 228.768 257.011 223.734 264.076 220.055C271.141 216.377 278.861 214.126 286.796 213.431C294.731 212.737 302.725 213.612 310.321 216.007C317.918 218.402 324.968 222.27 331.07 227.39"
      stroke="#1D252B"
      strokeWidth="23.33"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M75 227.4C81.1017 222.28 88.1521 218.412 95.7488 216.017C103.345 213.622 111.339 212.747 119.274 213.441C127.209 214.136 134.93 216.387 141.994 220.065C149.059 223.744 155.331 228.778 160.45 234.88"
      stroke="#1D252B"
      strokeWidth="23.33"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.28 368.32L55 344.43C58.1792 341.049 62.017 338.354 66.277 336.512C70.537 334.671 75.1289 333.72 79.77 333.72C84.4111 333.72 89.0031 334.671 93.2631 336.512C97.523 338.354 101.361 341.049 104.54 344.43L127.3 368.54C128.615 369.915 129.493 371.649 129.825 373.523C130.156 375.397 129.925 377.326 129.162 379.069C128.399 380.812 127.136 382.29 125.534 383.317C123.933 384.344 122.063 384.874 120.16 384.84H39.42C37.5159 384.819 35.6586 384.247 34.0729 383.192C32.4873 382.138 31.2413 380.646 30.4858 378.898C29.7304 377.15 29.4978 375.221 29.8162 373.343C30.1346 371.466 30.9904 369.721 32.28 368.32V368.32Z"
      fill="#764D2A"
    />
    <path
      d="M376 368.66L353.54 344.84C350.381 341.487 346.569 338.815 342.34 336.988C338.11 335.162 333.552 334.22 328.945 334.22C324.338 334.22 319.78 335.162 315.55 336.988C311.32 338.815 307.509 341.487 304.35 344.84L281.89 368.66C276.05 374.73 280.54 384.84 288.89 384.84H369C370.885 384.865 372.735 384.332 374.317 383.307C375.9 382.283 377.144 380.813 377.892 379.083C378.641 377.353 378.86 375.44 378.524 373.585C378.187 371.73 377.309 370.017 376 368.66V368.66Z"
      fill="#764D2A"
    />
  </svg>
);

export default HappyPoro;
