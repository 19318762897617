import styled from 'styled-components';

export const Form = styled.form`
  display: grid;
  width: 100%;
  margin-top: 15px;

  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'code code code code'
    ' . error error .'
    ' . submit submit .';

  > *:first-child {
    margin-bottom: 20px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const GridArea = styled.div`
  grid-area: ${props => props.gridArea};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 90%;
  position: absolute;
`;
