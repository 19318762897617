import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetQuality = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.quality ?? '-1');
};

export const useQuality = () => useMemoizedSelector(makeGetQuality);

export default makeGetQuality;
