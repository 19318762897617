import { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';
import OverlayMessage from '../OverlayMessage/OverlayMessage';
import Hint from '../Hint/Hint';
import { MessageWrapper, PageWelcomeOverlay, BackgroundImage } from './PageWelcome.styles';

const PageWelcome = ({
  hints = [],
  headline,
  subHeadline,
  buttonLabel,
  backgroundImage,
  stepId,
  fixedPosition = false,
}) => {
  const [render, setRender] = useState(false);
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const hintSize = useMemo(() => {
    if (isDesktopLargeOrGreater) return 'large';
    if (isDesktopOrGreater) return 'medium';
    return 'small';
  }, [isDesktopLargeOrGreater, isDesktopOrGreater]);

  useEffect(() => {
    if (global.document) {
      document.body.classList.add('disable-scroll');
    }
    setRender(true);
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, []);

  const setPlayedStatus = useSetPlayedStatus(stepId);
  const onMessageButtonClick = useCallback(() => setPlayedStatus(true), [setPlayedStatus]);

  if (!render) return null;

  return (
    <PageWelcomeOverlay data-testid="page-welcome-overlay" $fixedPosition={fixedPosition}>
      {backgroundImage && <BackgroundImage src={backgroundImage} alt="" />}
      <MessageWrapper $fixedPosition={fixedPosition}>
        <OverlayMessage
          headline={headline}
          subHeadline={subHeadline}
          buttonLabel={buttonLabel}
          onClick={onMessageButtonClick}
        />
      </MessageWrapper>
      {hints.map(
        ({ hintContainer, label, description, height, direction, position, positionOffset }) => (
          <Hint
            key={hintContainer}
            hintContainer={hintContainer}
            size={hintSize}
            height={height}
            direction={direction}
            description={description}
            label={label}
            positionOffset={positionOffset ?? 30}
            position={position}
          />
        ),
      )}
    </PageWelcomeOverlay>
  );
};

PageWelcome.propTypes = {
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
  buttonLabel: PropTypes.string,
  backgroundImage: PropTypes.string,
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      hintContainer: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      height: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['left', 'right']).isRequired,
      position: PropTypes.oneOf(['top', 'bottom']).isRequired,
      positionOffset: PropTypes.number,
    }),
  ),
  stepId: PropTypes.string.isRequired,
  fixedPosition: PropTypes.bool,
};

export default PageWelcome;
