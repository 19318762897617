import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: ${({ $height }) => $height};
  width: 100%;
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom || 0};
`;

export const StickyElement = styled.div`
  top: ${({ $topPosition }) => $topPosition || 0};
  height: 100%;
  ${({ $isSticky }) =>
    $isSticky &&
    css`
      height: auto;
      width: 100%;
      position: fixed;
      transform: translateZ(0);
      will-change: transform;
      z-index: 2;
    `}
`;
