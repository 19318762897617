import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetChatAvatar = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.preferences?.chat?.avatarId ?? null);
};

export const useChatAvatar = () => useMemoizedSelector(makeGetChatAvatar);

export default makeGetChatAvatar;
