import { customSize } from 'tv-constants/sizings';

const { X_LARGE, LARGE, MEDIUM, SMALL } = customSize;

const deadPlayerPopupSize = (
  index,
  videosLength,
  multiPovAllowed,
  isTabletOrGreater,
  isDesktopLargeOrGreater,
  sidebarsOpen = 0,
) => {
  if (!multiPovAllowed) {
    if (isTabletOrGreater) {
      return MEDIUM;
    }
    return SMALL;
  }

  const shouldDownscale = sidebarsOpen > (isDesktopLargeOrGreater ? 1 : 0);

  switch (videosLength) {
    case 1: {
      if (isDesktopLargeOrGreater) {
        return X_LARGE;
      }
      return LARGE;
    }
    case 2: {
      if (isDesktopLargeOrGreater) {
        return shouldDownscale ? MEDIUM : LARGE;
      }
      return shouldDownscale ? SMALL : MEDIUM;
    }
    case 3: {
      if (index === 0) {
        return shouldDownscale ? MEDIUM : LARGE;
      }
      return SMALL;
    }
    default: {
      return shouldDownscale ? SMALL : MEDIUM;
    }
  }
};

export default deadPlayerPopupSize;
