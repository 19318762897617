export const chatPopOverWidth = {
  small: 320,
  medium: 360,
  large: 736,
} as const;

export const chatPopOverMaxHeight = {
  small: 434,
  medium: 434,
  large: 346,
} as const;
