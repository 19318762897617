import { schema } from 'normalizr';
import player from './player.normalizr';

export const createTeamObject = ({ id, players, ...rest }, extraFields = []) => {
  if (!id) return undefined;
  return {
    id,
    players,
    ...extraFields.reduce((extra, key) => ({ ...extra, [key]: rest[key] }), {}),
  };
};

const team = new schema.Entity('teams', {
  players: [player],
});

export default team;
