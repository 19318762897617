import shaka from 'shaka-player';
import makeHttpResponse from './utils/makeHttpResponse';

// Version of with changes to calculation of load time
// https://shaka-player-demo.appspot.com/docs/api/lib_net_http_xhr_plugin.js.html

export const parse = (uri, request, requestType, progressUpdated) => {
  const xhr = new XMLHttpRequest();

  // Last time stamp when we got progress event
  let lastTime;
  const initTime = Date.now();
  // Last number of bytes loaded
  let lastLoaded = 0;
  const promise = new Promise((resolve, reject) => {
    xhr.open(request.method, uri, true);
    xhr.responseType = 'arraybuffer';
    xhr.timeout = request.retryParameters.timeout;
    xhr.withCredentials = request.allowCrossSiteCredentials;
    xhr.onabort = () => {
      reject(
        new shaka.util.Error(
          shaka.util.Error.Severity.RECOVERABLE,
          shaka.util.Error.Category.NETWORK,
          shaka.util.Error.Code.OPERATION_ABORTED,
          uri,
          requestType,
        ),
      );
    };
    xhr.onload = event => {
      const { target } = event;
      // Since IE and Edge incorrectly return the header with a leading new
      // line character ('\n'), we trim the header here.
      const headerLines = target.getAllResponseHeaders().trim().split('\r\n');
      const headers = {};
      headerLines.forEach(header => {
        const parts = header.split(': ');
        headers[parts[0].toLowerCase()] = parts.slice(1).join(': ');
      });
      try {
        const response = makeHttpResponse(
          headers,
          target.response,
          target.status,
          uri,
          target.responseURL,
          requestType,
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };

    xhr.onerror = event => {
      reject(
        new shaka.util.Error(
          shaka.util.Error.Severity.RECOVERABLE,
          shaka.util.Error.Category.NETWORK,
          shaka.util.Error.Code.HTTP_ERROR,
          uri,
          event,
          requestType,
        ),
      );
    };
    xhr.ontimeout = () => {
      reject(
        new shaka.util.Error(
          shaka.util.Error.Severity.RECOVERABLE,
          shaka.util.Error.Category.NETWORK,
          shaka.util.Error.Code.TIMEOUT,
          uri,
          requestType,
        ),
      );
    };

    xhr.onprogress = event => {
      const currentTime = Date.now();
      // If the time between last time and this time we got progress event
      // is long enough, or if a whole segment is downloaded, call
      // progressUpdated().
      if (event.loaded >= 1 && !lastTime) {
        lastLoaded = event.loaded;
        shaka.net.NetworkingEngine.ttfb = (Date.now() - initTime) / 1000;
        lastTime = Date.now();
      } else if (
        currentTime - lastTime > 100 ||
        (event.lengthComputable && event.loaded === event.total)
      ) {
        progressUpdated(
          currentTime - lastTime,
          event.loaded - lastLoaded,
          event.total - event.loaded,
        );
        lastLoaded = event.loaded;
        lastTime = currentTime;
      }
    };

    Object.keys(request.headers).forEach(key => {
      const lowercasedKey = key.toLowerCase();
      // The Fetch API automatically normalizes outgoing header keys to
      // lowercase. For consistency's sake, do it here too.
      xhr.setRequestHeader(lowercasedKey, request.headers[key]);
    });
    xhr.send(request.body);
  });

  return new shaka.util.AbortableOperation(promise, () => {
    xhr.abort();
    return Promise.resolve();
  });
};

const register = () => {
  shaka.net.NetworkingEngine.registerScheme(
    'http',
    parse,
    shaka.net.NetworkingEngine.PluginPriority.FALLBACK,
  );
  shaka.net.NetworkingEngine.registerScheme(
    'https',
    parse,
    shaka.net.NetworkingEngine.PluginPriority.FALLBACK,
  );
};

export default register;
