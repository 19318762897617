import { memo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StreamLayout from 'tv-modules/Player/StreamLayout/StreamLayout';
import Icon from 'znipe-elements/general/Icon/Icon';
import { device } from 'znipe-styles/breakpoints';

const Container = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  background: ${props => props.theme.overlayBackground};
  display: flex;
  align-items: center;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 15%;
  right: 13%;
  @media ${device.deskopLarge} {
    top: 40px;
    right: 100px;
  }
  @media ${device.desktopExtraLarge} {
    top: 15%;
    right: 13%;
  }
`;

const DesktopStreamSelector = ({
  matchId,
  selectedMatchGameIndex,
  userSelectedStreamId = null,
  afterStreamSelect = () => {},
  onOverlayClick = () => {},
}) => {
  const escClick = useCallback(
    event => {
      if (event.key === 'Escape') {
        onOverlayClick();
      }
    },
    [onOverlayClick],
  );

  useEffect(() => {
    if (userSelectedStreamId) {
      document.addEventListener('keydown', escClick, true);
    }
    return () => {
      document.removeEventListener('keydown', escClick, true);
    };
  }, [escClick, userSelectedStreamId]);

  if (!userSelectedStreamId) return null;
  return (
    <Container data-testid="desktop-stream-selector" role="presentation" onClick={onOverlayClick}>
      <CloseWrapper>
        <Icon type="close" size={30} />
      </CloseWrapper>
      <StreamLayout
        matchId={matchId}
        selectedMatchGameIndex={selectedMatchGameIndex}
        userSelectedStreamId={userSelectedStreamId}
        afterStreamSelect={afterStreamSelect}
      />
    </Container>
  );
};

DesktopStreamSelector.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  userSelectedStreamId: PropTypes.string,
  onOverlayClick: PropTypes.func,
  afterStreamSelect: PropTypes.func,
};
export default memo(DesktopStreamSelector);
