import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const useSubscribePageLink = () => {
  const { packageSlug } = useParams();
  const location = useLocation();
  const base = packageSlug ? `/${packageSlug}/subscribe` : '/subscribe';
  const redirectPath = useMemo(() => {
    if (!global.document || location.pathname === '/') return '';
    return window.encodeURIComponent(`${location.pathname}${window.location.search}`);
  }, [location.pathname]);
  if (redirectPath) return `${base}?redirect-path=${redirectPath}`;

  return base;
};

export default useSubscribePageLink;
