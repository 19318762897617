import { useCallback, useContext } from 'react';
import OnboardingContext from 'tv-contexts/OnboardingContext';
import { PLAYING_STATE } from 'tv-modules/Onboarding/OnboardingProvider';
import { useIsOnboardingDisabled } from 'tv-selectors/auth/makeGetIsOnboardingDisabled';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';

const useOnboardingPopup = popupId => {
  const { onboardingPopupState, addToBoardingPopupQueue, dismissPopup } =
    useContext(OnboardingContext);

  const setPlayedStatus = useSetPlayedStatus(popupId);
  const isDisabled = useIsOnboardingDisabled();

  const requestPopupPlay = useCallback(
    () => addToBoardingPopupQueue(popupId),
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    [popupId, addToBoardingPopupQueue],
  );

  const requestPopupDismiss = useCallback(() => {
    dismissPopup(popupId);
    setPlayedStatus(true);
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [popupId, dismissPopup, setPlayedStatus]);

  const isCurrentlyPlaying = !isDisabled && onboardingPopupState?.[popupId] === PLAYING_STATE;

  return { isCurrentlyPlaying, requestPopupPlay, requestPopupDismiss };
};

export default useOnboardingPopup;
