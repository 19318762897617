import { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import Icon from 'znipe-elements/general/Icon/Icon';
import useStreamFunctions from 'tv-hooks/useStreamFunctions';
import StreamItem from './StreamItem';
import { Container, ContainerContent, BackAction, ActionLabel } from './StreamLayout.styles';

const BackButton = memo(({ onClick }) => (
  <BackAction onClick={onClick}>
    <Icon type="arrowLeft" size={14} />
    <ActionLabel hasColor>Back</ActionLabel>
  </BackAction>
));

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const StreamLayout = ({
  matchId,
  selectedMatchGameIndex,
  afterStreamSelect = () => {},
  userSelectedStreamId,
  onBackClick,
}) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const hasPremiumAccess = useHasPremiumAccess();

  const videoPlayerStreams = useMemo(
    () => playerStreams.filter(stream => !stream.audioOnly),
    [playerStreams],
  );
  const numberOfPlayingStreams = videoPlayerStreams.length;

  const limitAddStream = numberOfPlayingStreams >= 2 && !hasPremiumAccess;

  // set max number of allowed streams in layout based on viewport
  const allowedNumberOfViews = isDesktopOrGreater ? 4 : 3;
  // calulate the number of placeholder streams, at least one placeholder in
  // the layout as long as the total is less than or equal to allowedNumberOfViews
  const numberOfEmptySlots = Math.min(allowedNumberOfViews - numberOfPlayingStreams, 1);

  // setup array that represents the placeholder streams
  const emptySlots = [...Array(numberOfEmptySlots).keys()];

  const userSelectedStreamAlreadyInView = useMemo(
    () => !!videoPlayerStreams.find(stream => stream.id === userSelectedStreamId),
    [videoPlayerStreams, userSelectedStreamId],
  );

  const viewLayout = userSelectedStreamAlreadyInView
    ? numberOfPlayingStreams
    : numberOfPlayingStreams + numberOfEmptySlots;

  const streamFunctionsCallbacks = useMemo(
    () => ({
      onChangeLayout: afterStreamSelect,
    }),
    [afterStreamSelect],
  );

  const { removeStream, displayStream } = useStreamFunctions(
    matchId,
    selectedMatchGameIndex,
    streamFunctionsCallbacks,
  );

  const onStreamAdd = useCallback(
    userSelectedStreamPosition => {
      displayStream(userSelectedStreamId, userSelectedStreamPosition);
    },
    [displayStream, userSelectedStreamId],
  );

  return (
    <Container>
      {onBackClick && <BackButton onClick={onBackClick} />}
      <ContainerContent
        data-testid="stream-layout"
        viewLayout={viewLayout}
        isCompact={!isTabletOrGreater}
      >
        {videoPlayerStreams.map((stream, i) => {
          const currentStreamId = stream.id;
          const currentStreamIsSelected = !!playerStreams.find(s => s.id === currentStreamId);
          return (
            <StreamItem
              key={currentStreamId}
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              currentStreamIsSelected={currentStreamIsSelected}
              userSelectedStreamId={userSelectedStreamId}
              streamId={currentStreamId}
              gridArea={`stream-layout-item${i + 1}`}
              viewLayout={viewLayout}
              layoutPosition={i + 1}
              limitAddStream={limitAddStream && i < 2}
              userSelectedStreamAlreadyInView={userSelectedStreamAlreadyInView}
              afterStreamSelect={afterStreamSelect}
              onStreamAdd={() => onStreamAdd(i)}
              onStreamRemove={() => removeStream(currentStreamId)}
            >
              {stream}
            </StreamItem>
          );
        })}
        {!userSelectedStreamAlreadyInView &&
          emptySlots.map((slot, i) => (
            <StreamItem
              key={slot}
              matchId={matchId}
              gridArea={`stream-layout-item${numberOfPlayingStreams + i + 1}`}
              viewLayout={viewLayout}
              layoutPosition={i + 1}
              limitAddStream={limitAddStream && i < 2}
              userSelectedStreamId={userSelectedStreamId}
              userSelectedStreamPosition={numberOfPlayingStreams + i}
              afterStreamSelect={afterStreamSelect}
              onStreamAdd={() => onStreamAdd(numberOfPlayingStreams + i)}
              onStreamRemove={() => removeStream(numberOfPlayingStreams + i)}
              isEmpty
            />
          ))}
      </ContainerContent>
    </Container>
  );
};

StreamLayout.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  userSelectedStreamId: PropTypes.string.isRequired,
  afterStreamSelect: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default StreamLayout;
