import { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import SectionIndicators from 'znipe-elements/data-display/SectionIndicators/SectionIndicators';
import StatsMenu from 'tv-modules/Stats/StatsMenu/StatsMenu';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsLaptopOrGreater } from 'tv-selectors/browser/makeGetIsLaptopOrGreater';
import { Content } from '../../ScreenOverlay.styles';
import {
  IndicatorsWrapper,
  Container,
  SideContent,
  MainWrapper,
  StatsWrapper,
} from './ScreenStats.styles';

const isSameScrollInfo = (currentInfo, newInfo) => {
  if (currentInfo.items.length !== newInfo.items.length) return false;
  if (currentInfo.activeIndex !== newInfo.activeIndex) return false;
  return true;
};

const ScreenStats = ({ matchId, selectedMatchGameIndex, onClose }) => {
  const [scrollInfo, setScrollInfo] = useState({ items: [], activeIndex: 0 });
  const isDesktopOrLarger = useGetIsDesktopLargeOrGreater();
  const isLaptopOrLarger = useGetIsLaptopOrGreater();

  const onScroll = useCallback(
    info => setScrollInfo(state => (isSameScrollInfo(state, info) ? state : info)),
    [],
  );

  return (
    <Container data-testid="screen-stats">
      <Content>
        <SideContent>
          {onClose && (
            <div data-testid="landscape-nav-buttons">
              <NavButton iconType="close" label="Close" showLabel size="large" onClick={onClose} />
            </div>
          )}
        </SideContent>
        <MainWrapper hideScrollbar={!isDesktopOrLarger}>
          <StatsWrapper>
            <StatsMenu
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              type={isLaptopOrLarger ? 'overlay' : 'compact-overlay'}
              hideScrollbar={!isDesktopOrLarger}
              withPlayerSelector
              onScroll={isLaptopOrLarger ? undefined : onScroll}
            />
          </StatsWrapper>
        </MainWrapper>
        {!isLaptopOrLarger && (
          <IndicatorsWrapper>
            <SectionIndicators
              numberOfItems={scrollInfo.items.length}
              activeSection={scrollInfo.activeIndex + 1}
              layout="vertical"
              progressStyle
              isLined
            />
          </IndicatorsWrapper>
        )}
      </Content>
    </Container>
  );
};

ScreenStats.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func,
};

export default memo(ScreenStats);
