import { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import { Item } from '../Accordion.styles';

const AnimatedItem = animated(Item);

const AccordionItem = ({ isActive, children }) => {
  const [height, setHeight] = useState(0);
  const prefersReducedMotion = usePrefersReducedMotion();

  const props = useSpring({
    height: isActive ? height : 0,
    config: isActive ? config.gentle : config.default,
    immediate: prefersReducedMotion,
  });

  const callbackRef = useCallback(node => {
    if (node !== null) {
      setHeight(node?.scrollHeight);
    }
  }, []);

  return (
    <AnimatedItem ref={callbackRef} style={props} $isActive={isActive} data-testid="accordion-item">
      {children}
    </AnimatedItem>
  );
};

AccordionItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(AccordionItem);
