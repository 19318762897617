import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getProductReceipts } from 'tv-actions/old/products';

const useGetReceiptsRequest = (productId, sort, sortOrder) => {
  const dispatch = useDispatch();
  const getReceipts = useCallback(
    (limit, offset) => {
      const options = {};
      if (sort) options.sort = sort;
      if (sortOrder) options.sortOrder = sortOrder;
      if (typeof limit === 'number') options.limit = limit;
      if (typeof offset === 'number') options.offset = offset;
      return dispatch(getProductReceipts(productId, options));
    },
    [productId, sort, dispatch, sortOrder],
  );
  return getReceipts;
};

export default useGetReceiptsRequest;
