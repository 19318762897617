import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  left: 0;
  bottom: 0;
  width: 100%;
  user-select: none;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
`;

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  min-height: 30px;
  @media ${device.tablet} {
    padding: 0;
  }
  @media ${device.desktop} {
    padding: 5px 10px 10px 10px;
  }
`;

export const ControlGroup = styled.div`
  display: flex;
  align-items: center;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 24px;
    `}
  @media ${device.desktop} {
    padding: 0 6px;
    > *:last-child {
      margin-right: 6px;
    }
  }
  > *:not(:last-child) {
    margin-right: ${({ $isMobile }) => ($isMobile ? '8px' : '20px')};
  }
`;

export const ZnipeIconWrapper = styled.div`
  width: 152px;
  @media ${device.desktop} {
    width: 208px;
  }
`;

export const Seperator = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 31px;
`;

export const SettingsWrapper = styled.div`
  bottom: 70px;
  right: 60px;
  position: absolute;
`;
