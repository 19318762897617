import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import CardTitle from './CardTitle';
import CardContent from './CardContent';

const SimpleCard = forwardRef(
  (
    {
      title = null,
      icon = null,
      children = null,
      transparent = false,
      size = 'small',
      fill = false,
      onCardIconClick,
      underlinedHeader = true,
      headerPadding = '8px 16px',
      customBackground,
    },
    ref,
  ) => (
    <Card ref={ref} transparent={transparent} fill={fill} customBackground={customBackground}>
      <CardTitle
        size={size}
        icon={icon}
        onCardIconClick={onCardIconClick}
        underlinedHeader={underlinedHeader}
        headerPadding={headerPadding}
      >
        {title}
      </CardTitle>
      <CardContent>{children}</CardContent>
    </Card>
  ),
);

SimpleCard.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.element,
  children: PropTypes.node,
  transparent: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fill: PropTypes.bool,
  onCardIconClick: PropTypes.func,
  underlinedHeader: PropTypes.bool,
  headerPadding: PropTypes.string,
  customBackground: PropTypes.string,
};

export default SimpleCard;
