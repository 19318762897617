import PropTypes from 'prop-types';
import TopicLink, { DEFAULT } from 'tv-elements/data-display/TopicLink/TopicLink';
import LogoCard from 'tv-elements/data-display/LogoCard/LogoCard';
import useTopicLink from 'tv-hooks/useTopicLink';
import Link from 'znipe-elements/general/Link/Link';
import colors from 'znipe-styles/colors';
import { useGetSelectedEvent } from 'tv-selectors/events/makeGetSelectedEvent';

const EventResultItem = ({ eventId, elementSizes, isDesktopOrGreater }) => {
  const { topicLinkSize, topicCardSize } = elementSizes;
  const event = useGetSelectedEvent({ eventId });
  const { id, name = '', region, image = '', logo = '', humanReadableId } = event;
  const link = useTopicLink(humanReadableId, 'event');

  if (isDesktopOrGreater) {
    return (
      <Link to={link} textColor={colors.white} data-testid="event-result-wrapper">
        <LogoCard text={name} imageSrc={image || logo} size={topicCardSize} />
      </Link>
    );
  }
  return (
    <Link to={link} textColor={colors.white} data-testid="event-result-wrapper">
      <TopicLink
        id={id}
        title={name}
        subtitle={region}
        image={image || logo}
        size={topicLinkSize}
        type={DEFAULT}
        hideChevronIcon
      />
    </Link>
  );
};

EventResultItem.propTypes = {
  eventId: PropTypes.string.isRequired,
  elementSizes: PropTypes.shape({
    tabsetSize: PropTypes.string,
    playerCardSize: PropTypes.string,
    topicCardSize: PropTypes.string,
    topicLinkSize: PropTypes.string,
    matchItemSize: PropTypes.string,
    videoListingSize: PropTypes.string,
    videoListingType: PropTypes.string,
  }).isRequired,
  isDesktopOrGreater: PropTypes.bool.isRequired,
};

export default EventResultItem;
