import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';

const height = {
  small: 32,
  medium: 40,
  large: 48,
};

const iconSize = {
  small: 16,
  medium: 16,
  large: 24,
};

const Container = styled.div`
  display: flex;
  padding: ${({ $headerPadding }) => $headerPadding};
  align-items: center;
  justify-content: flex-end;
  position: relative;

  ${({ $underlinedHeader }) =>
    $underlinedHeader
      ? css`
          border-bottom: 1px solid ${props => props.theme.containerStroke};
        `
      : css`
          border-bottom: none;
        `};
  height: ${({ $size }) => height[$size]}px;
`;

const IconContainer = styled.div`
  width: ${({ $size }) => iconSize[$size]}px;
  height: ${({ $size }) => iconSize[$size]}px;

  background: transparent;
  border: none;

  ${({ $clickableIcon }) =>
    $clickableIcon &&
    css`
      cursor: pointer;
    `}
`;

const Title = styled(Typography)`
  position: absolute;
  left: ${({ $headerPadding }) => {
    const split = $headerPadding.split(' ');
    return split[1] ?? split[0];
  }};
  top: 50%;
  transform: translateY(-50%);
`;

const titleSizes = {
  small: 'title-xs',
  medium: 'title-s',
  large: 'title-m',
};

const CardTitle = ({
  children = null,
  icon = null,
  size = 'small',
  onCardIconClick,
  underlinedHeader = true,
  headerPadding = '8px 16px',
}) => (
  <Container $size={size} $underlinedHeader={underlinedHeader} $headerPadding={headerPadding}>
    <Title $headerPadding={headerPadding} type={titleSizes[size]} color="white">
      {children}
    </Title>
    {/* biome-ignore lint/a11y/useValidAriaRole: Not sure what this is supposed to be */}
    <IconContainer
      role="none"
      onClick={onCardIconClick}
      $clickableIcon={onCardIconClick}
      $size={size}
    >
      {icon}
    </IconContainer>
  </Container>
);

CardTitle.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.element,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  underlinedHeader: PropTypes.bool,
  onCardIconClick: PropTypes.func,
  headerPadding: PropTypes.string,
};

export default CardTitle;
