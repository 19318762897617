import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetAutoplayEnabled = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.autoplayEnabled ?? true);
};

export const useGetAutoplayEnabled = () => useMemoizedSelector(makeGetAutoplayEnabled);

export default makeGetAutoplayEnabled;
