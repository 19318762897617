import PropTypes from 'prop-types';
import TopicLink, { CHAMPION } from 'tv-elements/data-display/TopicLink/TopicLink';
import ChampionCard from 'tv-elements/data-display/ChampionCard/ChampionCard';
import useTopicLink from 'tv-hooks/useTopicLink';
import Link from 'znipe-elements/general/Link/Link';
import colors from 'znipe-styles/colors';
import { useGetChampionName } from 'tv-selectors/champions/makeGetChampionName';
import { useGetChampionImage } from 'tv-selectors/champions/makeGetChampionImage';
import { useGetChampionClass } from 'tv-selectors/champions/makeGetChampionClass';
import { useGetChampionTitle } from 'tv-selectors/champions/makeGetChampionTitle';

const ChampionResultItem = ({ championId, elementSizes, isDesktopOrGreater }) => {
  const { topicLinkSize, championCardSize } = elementSizes;
  const name = useGetChampionName({ championId });
  const title = useGetChampionTitle({ championId });
  const image = useGetChampionImage({ championId });
  const role = useGetChampionClass({ championId });
  const link = useTopicLink(name, 'champion');

  if (isDesktopOrGreater) {
    return (
      <Link to={link} textColor={colors.white} hideUnderline>
        <ChampionCard
          title={name}
          subtitle={title}
          image={image}
          size={championCardSize}
          icon={`${role?.toLowerCase()}Class`}
        />
      </Link>
    );
  }
  return (
    <Link to={link} textColor={colors.white} hideUnderline>
      <TopicLink
        title={name}
        subtitle={title}
        image={image}
        avatarIcon={`${role?.toLowerCase()}Class`}
        size={topicLinkSize}
        type={CHAMPION}
        hideChevronIcon
      />
    </Link>
  );
};

ChampionResultItem.propTypes = {
  championId: PropTypes.string.isRequired,
  elementSizes: PropTypes.shape({
    tabsetSize: PropTypes.string,
    playerCardSize: PropTypes.string,
    topicCardSize: PropTypes.string,
    topicLinkSize: PropTypes.string,
    matchItemSize: PropTypes.string,
    videoListingSize: PropTypes.string,
    videoListingType: PropTypes.string,
    championCardSize: PropTypes.string,
  }).isRequired,
  isDesktopOrGreater: PropTypes.bool.isRequired,
};

export default ChampionResultItem;
