import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Section from 'tv-elements/layout/Section/Section';
import VODItem from 'tv-modules/VODListing/VODItem/VODItem';
import ScheduleMatchItem from 'tv-modules/Schedule/MatchItem/MatchItem';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SCROLLLIST, LIST, GRID } from 'tv-elements/layout/Section/Section.constants';
import TopicLink, { PLAYER_CARD } from 'tv-elements/data-display/TopicLink/TopicLink';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import useSeeAllLink from 'tv-hooks/useSeeAllLink';
import TeamResultItem from './TeamResultItem';
import PlayerResultItem from './PlayerResultItem';
import VideoResultItem from './VideoResultItem';
import ChampionResultItem from './ChampionResultItem';
import EventResultItem from './EventResultItem';
import ListWrapperElement from './ListWrapperElement';
import SectionLoading from './SectionLoading';
import { ScrollWrapper, TopResultWrapper } from '../Browse.styles';
import {
  searchResultDefaultProps,
  searchResultPropTypes,
  elementSizesPropType,
} from '../Browse.constants';

const AllResults = ({
  seachResult = searchResultDefaultProps,
  elementSizes,
  searchQuery = '',
  isLoading = false,
}) => {
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();

  const { topResults, players, teams, editorials, vods, champions, highlights, schedule, events } =
    seachResult;

  const playerCount = useMemo(() => {
    if (isDesktopExtraLargeOrGreater) return 8;
    if (isDesktopOrGreater) return 6;
    if (isTabletOrGreater) return 4;
    return 6;
  }, [isDesktopExtraLargeOrGreater, isDesktopOrGreater, isTabletOrGreater]);

  const numberOfAllowedResults = useMemo(() => {
    const nonPlayerTypeCardCount = isDesktopOrGreater ? 4 : 3;
    const videoCount = isDesktopExtraLargeOrGreater ? 4 : 3;
    return {
      player: playerCount,
      team: nonPlayerTypeCardCount,
      champion: playerCount,
      event: nonPlayerTypeCardCount,
      editorial: videoCount,
      highlight: videoCount,
      vod: 3,
      schedule: 3,
    };
  }, [isDesktopExtraLargeOrGreater, isDesktopOrGreater, playerCount]);

  const playersResultHits = useMemo(
    () => players?.slice(0, numberOfAllowedResults.player) || [],
    [players, numberOfAllowedResults.player],
  );
  const teamsResultHits = useMemo(
    () => teams?.slice(0, numberOfAllowedResults.team) || [],
    [teams, numberOfAllowedResults.team],
  );
  const editorialsResultHits = useMemo(
    () => editorials?.slice(0, numberOfAllowedResults.editorial) || [],
    [editorials, numberOfAllowedResults.editorial],
  );
  const vodsResultHits = useMemo(
    () => vods?.slice(0, numberOfAllowedResults.vod) || [],
    [vods, numberOfAllowedResults.vod],
  );
  const championsResultHits = useMemo(
    () => champions?.slice(0, numberOfAllowedResults.champion) || [],
    [champions, numberOfAllowedResults.champion],
  );
  const highlightsResultsHits = useMemo(
    () => highlights?.slice(0, numberOfAllowedResults.highlight) || [],
    [highlights, numberOfAllowedResults.highlight],
  );
  const scheduleResultHits = useMemo(
    () => schedule?.slice(0, numberOfAllowedResults.schedule) || [],
    [schedule, numberOfAllowedResults.schedule],
  );
  const eventsResultHits = useMemo(
    () => events?.slice(0, numberOfAllowedResults.event) || [],
    [events, numberOfAllowedResults.event],
  );
  const topResultsHits = useMemo(() => topResults?.hits || [], [topResults]);
  const { topicLinkSize, matchItemSize, videoListingType } = elementSizes;

  const links = useSeeAllLink('browse');

  const playerResultItems = useMemo(
    () =>
      playersResultHits.map(id => (
        <PlayerResultItem
          key={id}
          playerId={id}
          elementSizes={elementSizes}
          isDesktopOrGreater={isDesktopOrGreater}
          shouldUsePlayerCard
        />
      )),
    [playersResultHits, elementSizes, isDesktopOrGreater],
  );

  const playerCardsList = useMemo(
    () =>
      isTabletOrGreater ? (
        <ListWrapperElement shouldUseWrapper isDesktopOrGreater={isDesktopOrGreater} type="players">
          {playerResultItems}
        </ListWrapperElement>
      ) : (
        <ScrollContainer vertical={false}>
          <ScrollWrapper>{playerResultItems}</ScrollWrapper>
        </ScrollContainer>
      ),
    [playerResultItems, isDesktopOrGreater, isTabletOrGreater],
  );

  if (isLoading && playersResultHits.length === 0)
    return <SectionLoading type="all" elementSizes={elementSizes} />;
  const hasSearchTopResult = searchQuery && topResultsHits.length > 0;
  return (
    <div data-testid="browse-all-results">
      {hasSearchTopResult && (
        <Section title="Top Result" noTopMargin direction="vertical" type={LIST}>
          {topResultsHits.map(({ type, name, teamName, image, logoLight, role }) => (
            <TopResultWrapper key={name}>
              {type === 'player' && (
                <TopicLink
                  title={name}
                  subtitle={teamName}
                  image={image}
                  secondaryImage={logoLight}
                  size={topicLinkSize}
                  avatarIcon={`${role}Class`}
                  type={PLAYER_CARD}
                  hideChevronIcon
                />
              )}
            </TopResultWrapper>
          ))}
        </Section>
      )}
      {playersResultHits.length > 0 && (
        <Section
          title="players"
          noTopMargin={!hasSearchTopResult}
          type={isTabletOrGreater ? LIST : SCROLLLIST}
          direction="vertical"
          link={links.players}
        >
          {playerCardsList}
        </Section>
      )}
      {teamsResultHits.length > 0 && (
        <Section title="teams" direction="vertical" type={LIST} link={links.teams}>
          <ListWrapperElement
            shouldUseWrapper={isDesktopOrGreater}
            isDesktopOrGreater={isDesktopOrGreater}
            type="teams"
          >
            {teamsResultHits.map(id => (
              <TeamResultItem
                key={id}
                teamId={id}
                elementSizes={elementSizes}
                isDesktopOrGreater={isDesktopOrGreater}
              />
            ))}
          </ListWrapperElement>
        </Section>
      )}
      {editorialsResultHits.length > 0 && (
        <Section
          title="editorials"
          direction="vertical"
          type={videoListingType}
          elementSizes={elementSizes}
          isDesktopOrGreater={isDesktopOrGreater}
          link={links.editorials}
        >
          {videoListingType === GRID ? (
            editorialsResultHits.map(videoId => (
              <VideoResultItem
                key={videoId}
                videoId={videoId}
                elementSizes={elementSizes}
                videoType="editorial"
              />
            ))
          ) : (
            <VideoResultItem
              videos={editorialsResultHits}
              elementSizes={elementSizes}
              videoType="editorial"
            />
          )}
        </Section>
      )}
      {vodsResultHits.length > 0 && (
        <Section title="vods" direction="vertical" type={LIST} link={links.vods}>
          {vodsResultHits.map(id => (
            <VODItem key={id} matchId={id} size={matchItemSize} />
          ))}
        </Section>
      )}
      {championsResultHits.length > 0 && (
        <Section title="champions" direction="vertical" type={LIST} link={links.champions}>
          <ListWrapperElement
            shouldUseWrapper={isDesktopOrGreater}
            isDesktopOrGreater={isDesktopOrGreater}
          >
            {championsResultHits.map(id => (
              <ChampionResultItem
                key={id}
                championId={id}
                elementSizes={elementSizes}
                isDesktopOrGreater={isDesktopOrGreater}
              />
            ))}
          </ListWrapperElement>
        </Section>
      )}
      {highlightsResultsHits.length > 0 && (
        <Section
          title="highlights"
          direction="vertical"
          type={videoListingType}
          link={links.highlights}
        >
          {videoListingType === GRID ? (
            highlightsResultsHits.map(videoId => (
              <VideoResultItem
                key={videoId}
                videoId={videoId}
                elementSizes={elementSizes}
                isDesktopOrGreater={isDesktopOrGreater}
              />
            ))
          ) : (
            <VideoResultItem
              videos={highlightsResultsHits}
              elementSizes={elementSizes}
              isDesktopOrGreater={isDesktopOrGreater}
            />
          )}
        </Section>
      )}
      {scheduleResultHits.length > 0 && (
        <Section title="schedule" direction="vertical" type={LIST} link={links.schedule}>
          {scheduleResultHits.map(id => (
            <ScheduleMatchItem key={id} matchId={id} size={matchItemSize} />
          ))}
        </Section>
      )}
      {eventsResultHits.length > 0 && (
        <Section title="events" direction="vertical" type={LIST} link={links.events}>
          <ListWrapperElement
            shouldUseWrapper={isDesktopOrGreater}
            isDesktopOrGreater={isDesktopOrGreater}
            type="events"
          >
            {eventsResultHits.map(id => (
              <EventResultItem
                key={id}
                eventId={id}
                elementSizes={elementSizes}
                isDesktopOrGreater={isDesktopOrGreater}
              />
            ))}
          </ListWrapperElement>
        </Section>
      )}
    </div>
  );
};

AllResults.propTypes = {
  seachResult: searchResultPropTypes,
  elementSizes: elementSizesPropType.isRequired,
  searchQuery: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default AllResults;
