import { useMemo } from 'react';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useRegion } from 'tv-selectors/auth/makeGetRegion';
import { useUserSession } from 'tv-selectors/deviceInfo/makeGetUserSession';
import { useIsIOS } from 'tv-selectors/deviceInfo/makeGetIsIOS';
import { useReferrer } from 'tv-selectors/deviceInfo/makeGetReferrer';
import config from 'tv-config/config';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import usePackageName from 'tv-hooks/usePackageName';
import useGetUserPackageSubscription from 'tv-hooks/useGetUserPackageSubscription';
import getDeviceId from 'znipe-utils/web/getDeviceId';

const useAnalyticsParams = (id, type = 'match', providedPackageName, module) => {
  const videoOrigin = useGqlSelector(state => state.videos[id]?.origin);
  const videoMatchId = useGqlSelector(state => state.videos[id]?.matchId ?? '');
  const dataObject = useMemo(
    () => (type === 'video' ? { videoId: id, videoOrigin } : {}),
    [id, type, videoOrigin],
  );

  const matchId = type === 'match' ? id : videoMatchId;
  const deviceId = useMemo(getDeviceId, []);
  const userSession = useUserSession();
  const region = useRegion();
  const userId = useUserId();
  const isIOS = useIsIOS();
  const hasPremiumAccess = useHasPremiumAccess(providedPackageName);
  const packageName = usePackageName(providedPackageName);
  const subscription = useGetUserPackageSubscription(packageName);
  const productId = subscription?.productId ?? '';
  const referrer = useReferrer();

  return useMemo(
    () => ({
      module: module || packageName,
      deviceId,
      matchId,
      region,
      userId,
      isIOS,
      userSession,
      clientConfig: config,
      isPremiumUser: hasPremiumAccess,
      productId,
      packageName,
      referrer,
      ...dataObject,
    }),
    [
      deviceId,
      matchId,
      region,
      userId,
      isIOS,
      userSession,
      hasPremiumAccess,
      packageName,
      productId,
      referrer,
      dataObject,
      module,
    ],
  );
};

export default useAnalyticsParams;
