// @INFO:
// The Reason this file is here and not in hooks is because it buggs out when used in multiple files at the same time
// If you want to use it in a lower lever well have to find a better solution and maybe add the info to redux

import { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import { useLocation } from 'react-router-dom';

const locationHistory = [];
const usePreviousLocation = () => {
  const [previousLocation, setPreviousLocation] = useState(
    locationHistory[locationHistory.length - 2] ?? {},
  );

  const location = useLocation();

  useEffect(() => {
    const lastLocation = locationHistory[locationHistory.length - 1];
    if (isEqual(lastLocation, location)) {
      setPreviousLocation(locationHistory[locationHistory.length - 2] ?? {});
      return;
    }
    if (lastLocation) setPreviousLocation(lastLocation);
    locationHistory.push(location);
  }, [location]);

  return previousLocation;
};

export default usePreviousLocation;
