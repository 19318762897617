import { Item, Gif, Grid } from './GifList.styles';
import type { GIF } from '../Gifs';

type GifListProps = {
  gifs: GIF[];
  columnsPerRow?: number;
  onSelect: (url: string) => void;
};

const GifList: React.FC<GifListProps> = ({ gifs, columnsPerRow = 3, onSelect }) => (
  <Grid data-testid="gif-list" $columnsPerRow={columnsPerRow}>
    {gifs.map(({ id, url }) => (
      <Item key={id} data-testid={`gif-${id}`} onClick={() => onSelect(url)}>
        <Gif data-testid="gif-image-element" $src={url} />
      </Item>
    ))}
  </Grid>
);

export default GifList;
