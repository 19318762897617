import { useMemo, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { useGetIsMobilePortraitOrGreater } from 'tv-selectors/browser/makeGetIsMobilePortraitOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import Typography from 'znipe-elements/general/Typography/Typography';
import BlockContainer from 'znipe-elements/data-display/BlockContainer/BlockContainer';
import BlockContainerLoading from 'znipe-elements/data-display/BlockContainer/BlockContainer.loading';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import useGqlStore from 'tv-hooks/useGqlStore';
import TableRow from './TableRow';
import {
  Container,
  Row,
  Cell,
  LabelColumns,
  NumberColumns,
  NumberColumnsWrapper,
  HorizontalLine,
} from './GroupsTable.styles';

const WINS = 'wins';
const TIES = 'ties';
const LOSSES = 'losses';
const RW = 'rw';
const POINTS = 'points';

export const lolColumns = [WINS, LOSSES];
export const csgoColumns = [WINS, TIES, LOSSES, RW, POINTS];
const emptyObject = {};

const GroupsTable = ({
  groupName = '',
  results = [],
  columns = lolColumns,
  selectedTeams = [],
}) => {
  const isMobilePortraitOrGreater = useGetIsMobilePortraitOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();
  const { secondaryTextColor } = useThemeContext();
  const gqlStore = useGqlStore();

  const sortFunc = useCallback(
    (a, b) =>
      b[columns.includes(POINTS) ? POINTS : WINS] - a[columns.includes(POINTS) ? POINTS : WINS],
    [columns],
  );

  const teamRows = useMemo(() => {
    const gqlState = gqlStore.getState();
    return [...results].sort(sortFunc).map(({ team: teamId, ...row }, index) => {
      const teamDetails = gqlState.teams[teamId] ?? emptyObject;
      const { shortName, name, logoDark, logoLight } = teamDetails;
      const teamName = isTabletOrGreater ? name : shortName;
      const logo = logoLight ?? logoDark;
      const rank = isMobilePortraitOrGreater ? index + 1 : null;
      const numbers = columns.reduce((o, key) => ({ ...o, [key]: row[key] }), {});
      const teamIsSelected = selectedTeams?.includes(teamId) || false;
      return (
        <Fragment key={teamId}>
          <TableRow
            id={teamId}
            rank={rank}
            logo={logo}
            name={teamName}
            numbers={numbers}
            isSelected={teamIsSelected}
          />
          {index !== results.length - 1 && <HorizontalLine />}
        </Fragment>
      );
    });
  }, [
    gqlStore,
    results,
    sortFunc,
    isTabletOrGreater,
    isMobilePortraitOrGreater,
    columns,
    selectedTeams,
  ]);

  const sizes = useMemo(() => {
    if (isDesktopExtraLargeOrGreater) return { headerRowText: 'title-m', block: 'large' };
    if (isDesktopLargeOrGreater) return { headerRowText: 'title-s', block: 'medium' };
    if (isDesktopOrGreater) return { headerRowText: 'title-xs', block: 'small' };
    if (isTabletOrGreater) return { headerRowText: 'title-s', block: 'medium' };
    return { headerRowText: 'title-xs', block: 'small' };
  }, [
    isTabletOrGreater,
    isDesktopOrGreater,
    isDesktopLargeOrGreater,
    isDesktopExtraLargeOrGreater,
  ]);

  const numberColumns = useMemo(
    () =>
      columns.map(column => (
        <Cell key={column} textColor={secondaryTextColor} isNumberColumn>
          <Typography type={sizes.headerRowText}>{column}</Typography>
        </Cell>
      )),
    [columns, sizes, secondaryTextColor],
  );

  const delay = useMemo(() => (teamRows?.length ? 0 : undefined), [teamRows]);
  const showFullComponent = useLoadingDelay(teamRows.length, delay);

  if (!showFullComponent) {
    return <BlockContainerLoading height={250} />;
  }

  return (
    <BlockContainer title={groupName} size={sizes.block} noPadding>
      <Container>
        <Row isHeader data-testid="row-header">
          <LabelColumns />
          <NumberColumnsWrapper numberOfColumns={columns.length}>
            <NumberColumns>{numberColumns}</NumberColumns>
          </NumberColumnsWrapper>
        </Row>
        {teamRows}
      </Container>
    </BlockContainer>
  );
};

export const teamShape = {
  losses: PropTypes.number,
  points: PropTypes.number,
  rw: PropTypes.number,
  ties: PropTypes.number,
  wins: PropTypes.number,
  team: PropTypes.string,
};

GroupsTable.propTypes = {
  groupName: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.shape(teamShape)),
  columns: PropTypes.arrayOf(PropTypes.string),
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default withTheme(GroupsTable, {}, 'groupsTable');
