import { createSelector } from 'reselect';
import makeGetSelectedProduct from 'tv-selectors/products/makeGetSelectedProduct';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductName = () => {
  const getSelectedProduct = makeGetSelectedProduct();
  return createSelector([getSelectedProduct], product => product.name ?? '');
};

export const useGetProductName = props => useMemoizedSelector(makeGetProductName, props);

export default makeGetProductName;
