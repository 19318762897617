import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import themes from './CelebrationImage.themes';

const ImageWrapper = styled.div`
  width: ${props => props.$size};
`;

const CelebrationImage = ({ size = '72px' }) => {
  const { celebrationImage = {} } = useThemeContext();
  const { imageComponent: Image } = celebrationImage;
  return (
    <ImageWrapper data-testid="celebration" $size={size}>
      <Image />
    </ImageWrapper>
  );
};

CelebrationImage.propTypes = {
  size: PropTypes.string,
};

export default withTheme(memo(CelebrationImage), themes, 'celebrationImage');
