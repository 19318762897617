import Helmet from 'react-helmet';
import Typography from 'znipe-elements/general/Typography/Typography';
import { OutLink, Container, Table } from 'tv-routes/ContentPolicy/ContentPolicy.styles';

const ContentPolicy = () => (
  <Container>
    <Helmet title="Pro View Watch Party and Content Creation Policy" />
    <Typography type="heading-xl">Pro View Watch Party and Content Creation Policy</Typography>
    <Typography type="paragraph-m">
      You&apos;re welcome to use Pro View as part of any video content you create or co-stream as
      part of an LCS watch party, so long as you follow the rules outlined below. We reserve the
      right at any time to deny any use of Pro View that we decide misinterprets these rules or uses
      Pro View inappropriately.
    </Typography>
    <Typography type="heading-l">Watch Party Broadcasts and Live Content Using Pro View</Typography>
    <Typography type="paragraph-s">
      You may <u>not</u> use Pro View for a watch party / live stream if you show content from the
      League of Legends European Championship (LEC) or international events i.e. Worlds or the Mid
      Season Invitational (MSI).
      <br />
      <br />
      You may use Pro View as part of a watch party if:
      <br />
      &emsp;• You are participating in the{' '}
      <OutLink href="https://lolesports.com/article/introducing-the-lcs-watch-party-program/bltc45257ac24f525d7">
        LCS Watch Party Program
      </OutLink>{' '}
      and are co-streaming LCS or LCS Academy gameplay; and
      <br />
      &emsp;• Your use of Pro View during the co-stream adds value to your stream and fan community,
      is reasonably limited, and does not otherwise unreasonably interfere with Pro View as a
      product.
    </Typography>
    <Typography type="paragraph-s">
      For illustrative purposes only, the following table outlines some examples of acceptable and
      unacceptable uses of Pro View content:
      <br />
      <Table>
        <tr>
          <th>Allowed</th>
          <th>Not allowed</th>
        </tr>
        <tr>
          <td>Using Pro View Premium during watch party with explicit permission from Riot</td>
          <td>Using Pro View Premium during watch party without explicit permission from Riot</td>
        </tr>
        <tr>
          <td>Using non-Pro View Premium content during watch party without explicit permission</td>
          <td />
        </tr>
        <tr>
          <td>Dynamically switching perspectives to focus on different players</td>
          <td>Setting up a static screen of player POVs and letting them run the whole game</td>
        </tr>
        <tr>
          <td>
            Exploring Pro View features and clips of exclusive content between games during approved
            Pro View watch party
          </td>
          <td>
            Watching entire episodes of Pro View exclusive content during approved Pro View watch
            party
          </td>
        </tr>
        <tr>
          <td>
            Posting highlights/clips from watch party on the co-streamer’s channels (including Pro
            View content in highlights)
          </td>
          <td>
            Posting entire/mostly uncut VODs of watch parties using Pro View (e.g. multiple-hour
            videos)
          </td>
        </tr>
      </Table>
    </Typography>
    <Typography type="heading-l">
      Making YouTube/Social and Non-Live Content Using Pro View
    </Typography>
    <Typography type="paragraph-s">
      If you make non-live content using Pro View (e.g., on YouTube or similar), your use of Pro
      View (as incorporated into the content) must add value to your content and fan community, be
      reasonably limited, and not otherwise unreasonably interfere with Pro View as a product. For
      illustrative purposes only, the following table outlines some examples of acceptable and
      unacceptable uses of Pro View content:
    </Typography>
    <Table>
      <tr>
        <th>Allowed</th>
        <th>Not allowed</th>
      </tr>
      <tr>
        <td>
          Using short clips (&#60;1 minute) from Pro View Premium POVs to augment content (e.g.,
          talking about Perkz&apos;s laning or using 15 seconds of his view from last week’s game
          inside a 10-minute piece)
        </td>
        <td>
          Using extended clips from Pro View Premium POVs/making content that is a majority Pro View
          Premium POVs (e.g., a montage of all mid laner kills from last week from their
          perspectives)
        </td>
      </tr>
      <tr>
        <td>
          Using clips from content exclusive to Pro View Premium with explicit permission from Riot
        </td>
        <td>
          Using clips from content exclusive to Pro View Premium without explicit permission from
          Riot
        </td>
      </tr>
      <tr>
        <td>
          Long-form video content using the free featured matchups (e.g. doing a VOD review of
          Licorice’s play when he was the featured free POV) posted 24+ hours after broadcast ends
        </td>
        <td>
          Long-form video content using Premium POVs (e.g. doing a VOD review of Licorice’s play in
          a game where he was NOT the featured free POV)
        </td>
      </tr>
      <tr>
        <td>
          Using non-live free Pro View content during a livestream (e.g. doing a VOD review of last
          week’s free POVs on your Twitch stream) 24+ hours after the broadcast ends
        </td>
        <td>
          Using non-live Pro View Premium content during a livestream (e.g. doing a VOD review of
          all of TSM’s POVs from last week’s match)
        </td>
      </tr>
    </Table>
    <Typography type="paragraph-s">
      * * *
      <br />
      We realize that a spectrum of behavior exists between the “Allowed” and “Not Allowed” use
      cases specified above, and we’re relying on you to exercise your best judgment to abide by our
      guidelines and make good faith efforts to avoid potential abuse of Pro View. If we consider,
      in our sole discretion, that you have misinterpreted our guidelines or are otherwise using Pro
      View inappropriately, we may restrict or prohibit your use of Pro View, including removal of
      any infringing content.
      <br />
      If you have any questions about the Pro View Watch Party and Content Creation Policy
      (including whether your planned use of Pro View complies with the policy), please reach out to{' '}
      <OutLink href="mailto:proviewinfo@riotgames.com">proviewinfo@riotgames.com</OutLink> for
      clarification.
    </Typography>
  </Container>
);

export default ContentPolicy;
