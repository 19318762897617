import { useState, useCallback, useRef, useMemo, useEffect, useImperativeHandle } from 'react';
import withTheme from 'znipe-themes/hocs/withTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import isTouchDevice from 'znipe-utils/web/isTouchDevice';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import useShouldUpdateContent from './hooks/useShouldUpdateContent';
import useChatContext from '../../hooks/useChatContext';
import useBanInfo from '../../hooks/useBanInfo';
import useScrollToBottom from '../../hooks/useScrollToBottom';
import Emojis from '../Emojis/Emojis';
import EmojiAutoComplete from '../Emojis/EmojiAutoComplete/EmojiAutoComplete';
import MentionAutoComplete from '../Mentions/MentionAutoComplete';
import {
  InputContainer,
  InputWrapper,
  IconsWrapper,
  DisabledBanner,
  themes,
  ActionsContainer,
  InputPlaceholder,
  Container,
} from './ChatLockup.styles';
import { EMOJI_TAG_REGEX, LARGE_NUMBER } from './ChatLockup.constants';
import { isReplyEntry } from './utils/entryType';
import useCreateContent from './hooks/useCreateContent';
import useSendMessage from './hooks/useSendMessage';
import usePasteMessage from './hooks/usePasteMessage';
import useCheckCharacterCount from './hooks/useCheckCharacterCount';
import useCaretPosition from './hooks/useCaretPosition';
import useRawContent from './hooks/useRawContent';
import useSearchQuery from './hooks/useSearchQuery';
import useUserSearchQuery from './hooks/useUserSearchQuery';
import useCalculateStringTypesLength from './hooks/useCalculateStringTypeLength';
import inputHistory from './utils/inputHistory';
import type { ReplyTo } from '../../utils/chatStore';

export type Direction = 'vertical' | 'horizontal';

type ChatLockupProps = {
  placeholder?: string;
  children?: React.ReactNode;
  autofocusTextInput?: boolean;
  direction?: Direction;
  isEmojiOpen: boolean;
  characterLimit?: number;
};

const ChatLockup: React.FC<ChatLockupProps> = ({
  placeholder = 'Send a message to chat',
  children,
  autofocusTextInput = true,
  direction = 'vertical',
  isEmojiOpen,
  characterLimit = 300,
}) => {
  const contentRef = useRef<HTMLDivElement>();
  const [isFocused, setIsFocused] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const { chatStatus, replyData } = useChatContext() ?? {};
  const banInfo = useBanInfo();

  const caretPositionData = useCaretPosition(contentRef);
  const { caretPosition, storeFocusNode, getCaretPosition, focus, select } = caretPositionData;
  const calculateStringTypesLength = useCalculateStringTypesLength();

  const { undo: inputUndo, redo: inputRedo, apply, clear: clearInputOperations } = inputHistory();
  const searchQuery = useSearchQuery(inputMessage, caretPositionData);
  const userSearchQuery = useUserSearchQuery(inputMessage, replyData ?? undefined);
  const { rawContent, resetRawContent, onInput, updateRawContent, insertEmoji, tagUser } =
    useRawContent(
      contentRef,
      caretPositionData,
      characterLimit,
      apply,
      searchQuery || userSearchQuery,
    );

  const outsideRef = useOutsideClick(storeFocusNode) as unknown as React.MutableRefObject<
    HTMLDivElement | undefined
  >;
  useImperativeHandle(outsideRef, () => contentRef.current);

  const disabledMessage = useMemo(() => {
    if (chatStatus?.disabled) {
      return 'Chat is currently disabled.';
    }
    if (banInfo?.active) {
      const timeInfo = banInfo.time ? `for ${banInfo.time}` : 'from the chat';
      return `You are banned ${timeInfo}.`;
    }
    return null;
  }, [banInfo, chatStatus?.disabled]);

  const { isAtBottom, scrollToBottom } = useScrollToBottom(contentRef);

  const isDisabled = useMemo(() => {
    if (replyData?.username) {
      return isReplyEntry(inputMessage);
    }

    return !inputMessage || !!disabledMessage;
  }, [inputMessage, disabledMessage, replyData?.username]);

  const createContent = useCreateContent();
  const shouldUpdateContent = useShouldUpdateContent(rawContent);

  const checkCharacterCount = useCheckCharacterCount(inputMessage, characterLimit);

  const { sendMessage, isShakeActivated, isOnCooldown, cooldown } = useSendMessage(
    inputMessage,
    resetRawContent,
    clearInputOperations,
    isDisabled,
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      const ctrlOrCmd = event.ctrlKey || event.metaKey;

      if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault();
        getCaretPosition();
        const goForward = event.key === 'ArrowRight';
        const [anchorPos, focusPos] = caretPosition.current;

        const action = (nextPos: number) => {
          if (event.shiftKey) {
            select(nextPos, goForward ? 'forward' : 'backward');
            return;
          }

          focus(nextPos);
        };

        if (ctrlOrCmd) {
          // @TODO Only move on the same line
          action(goForward ? inputMessage.length : 0);
          return;
        }
        if (event.altKey) {
          const whitespace = /(\s+)/;
          const either = new RegExp(`${whitespace.source}|${EMOJI_TAG_REGEX.source}`, 'g');
          const words = inputMessage.split(either).filter(Boolean);
          let contentLength = 0;
          let skipAmount = 0;
          const index = words.findIndex(chunk => {
            const chunkLength = calculateStringTypesLength(chunk);
            contentLength += chunkLength;
            if (contentLength > focusPos) {
              // If we're not i a whitespace, then we're in a word and skip to the start or end of it
              if (!chunk.match(whitespace)) {
                skipAmount = goForward
                  ? contentLength - focusPos
                  : focusPos - (contentLength - chunkLength);
              }
              return true;
            }
            return false;
          });
          const wordIndex = index < 0 ? words.length : index;

          const section = words.slice(
            goForward ? wordIndex : 0,
            goForward ? inputMessage.length : wordIndex,
          );

          if (!goForward) section.reverse();

          // Skip amount is > 0 if we are skipping section of a word
          if (skipAmount < 1) {
            skipAmount += calculateStringTypesLength(section[0] ?? '');
            // If the next "word" is a space, then skip 2 words
            if (section[0]?.match(/^\s+$/) && section[1]) {
              skipAmount += calculateStringTypesLength(section[1] ?? '');
            }
          }

          action(focusPos + (goForward ? skipAmount : -skipAmount));
          return;
        }

        // If we have a selection and press an arrow key, we want to collapse the selection to the specified direction
        if (focusPos !== anchorPos && !event.shiftKey) {
          const leftPos = Math.min(focusPos, anchorPos);
          const rightPos = Math.max(focusPos, anchorPos);
          focus(goForward ? rightPos : leftPos);
        } else action(goForward ? focusPos + 1 : focusPos - 1);
      }

      if (ctrlOrCmd && event.key === 'z') {
        event.preventDefault();
        event.stopPropagation();

        const fn = event.shiftKey ? inputRedo : inputUndo;
        fn(rawContent, updateRawContent);
      }
      if (ctrlOrCmd && event.key === 'y') {
        event.preventDefault();
        event.stopPropagation();
        inputRedo(rawContent, updateRawContent);
      }
    },
    [
      getCaretPosition,
      caretPosition,
      focus,
      select,
      inputMessage,
      calculateStringTypesLength,
      inputRedo,
      inputUndo,
      updateRawContent,
      rawContent,
    ],
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    },
    [sendMessage],
  );

  useEffect(() => {
    if (autofocusTextInput) {
      focus();
    }
  }, [autofocusTextInput, focus]);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    if (shouldUpdateContent()) {
      contentRef.current.innerHTML = createContent(rawContent);
      if (rawContent.join('') && rawContent.join('') !== '<br>') {
        focus(caretPosition.current[1]);
      }
      if (
        rawContent.length === 3 &&
        rawContent[0] === '<br>' &&
        rawContent[1].indexOf('@') === 0 &&
        rawContent[2] === '<br>'
      ) {
        focus(LARGE_NUMBER);
      }
    }

    setInputMessage(
      rawContent
        .map(chunk => {
          if (chunk.match(EMOJI_TAG_REGEX)) {
            return chunk.toLowerCase();
          }

          return chunk;
        })
        .join('')
        .replace(/<br>/g, ''),
    );

    if (isAtBottom) scrollToBottom();
  }, [
    rawContent,
    createContent,
    focus,
    caretPosition,
    shouldUpdateContent,
    isAtBottom,
    scrollToBottom,
  ]);

  const handlePaste = usePasteMessage(
    inputMessage,
    characterLimit,
    updateRawContent,
    getCaretPosition,
  );

  const handleChatPlaceholderClick = useCallback(() => {
    setIsFocused(true);
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, []);

  const handleBlur = useCallback(() => {
    getCaretPosition();
    storeFocusNode();
    setIsFocused(false);
  }, [getCaretPosition, storeFocusNode]);

  const handleReplySelect = useCallback(
    (user: ReplyTo) => {
      if (!user) return null;
      tagUser(`@${userSearchQuery}`, user);
      return true;
    },
    [userSearchQuery, tagUser],
  );

  useEffect(() => {
    if (isFocused) {
      getCaretPosition();
    }
  }, [isFocused, getCaretPosition]);

  return (
    <>
      {searchQuery?.length > 0 && <EmojiAutoComplete query={searchQuery} onSelect={insertEmoji} />}
      {userSearchQuery?.length > 0 && !replyData?.username && (
        <MentionAutoComplete query={userSearchQuery} onSelect={handleReplySelect} />
      )}
      <Emojis isOpen={isEmojiOpen} onSelect={insertEmoji} />
      <Container $isHorizontal={direction === 'horizontal'}>
        {disabledMessage ? (
          <DisabledBanner>
            <Typography type="paragraph-s" color="secondaryTextColor">
              {disabledMessage}
            </Typography>
          </DisabledBanner>
        ) : (
          <InputContainer data-testid="chat-container">
            {!isFocused && !inputMessage.length ? (
              <InputPlaceholder
                type={isTouchDevice() ? 'paragraph-m' : 'paragraph-s'}
                onClick={handleChatPlaceholderClick}
              >
                {placeholder}
              </InputPlaceholder>
            ) : null}
            <InputWrapper
              type={isTouchDevice() ? 'paragraph-m' : 'paragraph-s'}
              $replyTarget={replyData?.username}
              $isReply={!!replyData}
              $replyColor={replyData?.color}
              ref={contentRef}
              contentEditable
              suppressContentEditableWarning
              onInput={onInput}
              onKeyPress={checkCharacterCount(handleKeyPress)}
              onKeyDown={checkCharacterCount(handleKeyDown)}
              onPaste={handlePaste}
              onBlur={handleBlur}
              onFocus={() => setIsFocused(true)}
              dataTestId="chat-input"
            />
          </InputContainer>
        )}
        <ActionsContainer>
          <IconsWrapper>{children}</IconsWrapper>
          <Button
            variant={isDisabled || isOnCooldown ? 'secondary' : 'solid-color'}
            icon={isOnCooldown ? undefined : 'send'}
            data-testid="send-message-button"
            size="small"
            isShakeActivated={isShakeActivated}
            square
            onClick={sendMessage}
            disabled={isDisabled || isOnCooldown}
          >
            {isOnCooldown && <span data-testid="cooldown">{cooldown}</span>}
          </Button>
        </ActionsContainer>
      </Container>
    </>
  );
};

export default withTheme(ChatLockup, themes, 'chatLockup');
