import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetCasterMute = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.casterMute ?? false);
};

export const useCasterMute = () => useMemoizedSelector(makeGetCasterMute);

export default makeGetCasterMute;
