import defaultTheme from './themes/default.theme';
import znipebase from './themes/znipebase.theme';
import esportal from './themes/esportal.theme';
import efg from './themes/efg.theme';
import ubisoft from './themes/ubisoft.theme';
import svenskaCupen from './themes/svenska-cupen.theme';
import faceit from './themes/faceit.theme';

const themes = {
  default: defaultTheme,
  znipebase,
  esportal,
  efg,
  ubisoft,
  'svenska-cupen': svenskaCupen,
  faceit,
};

export default themes;
