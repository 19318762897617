import { useEffect, useMemo, useState } from 'react';
import { map } from 'rxjs';
import AuthManager from 'znipe-auth-manager';

type UserCustomization = {
  privileges?: string[];
  chatColorSets?: string[];
  chatSignifiers?: string[];
};

const useUserCustomization = (authManagerId?: string) => {
  const [customizations, setCustomizations] = useState({});
  const authManager = useMemo(
    () => AuthManager.getAuthManager(authManagerId) ?? new AuthManager(authManagerId),
    [authManagerId],
  );

  useEffect(() => {
    const sub = authManager
      .getUserSubject()
      .pipe(
        map(user => ({
          privileges: user.privileges ?? [],
          chatColorSets: user.chatColorSets ?? [],
          chatSignifiers: user.chatSignifiers ?? [],
        })),
      )
      .subscribe(setCustomizations);

    return () => sub.unsubscribe();
  }, [authManager]);

  return customizations as UserCustomization;
};

export default useUserCustomization;
