const createSpell = spell => {
  let spellImage = spell.image;
  if (spell.name === 'S5_SummonerSmitePlayerGanker') {
    spellImage = 'https://assets.znipe.tv/lol/Chilling_Smite.jpg';
  }
  if (spell.name === 'S5_SummonerSmiteDuel') {
    spellImage = 'https://assets.znipe.tv/lol/Challenging_Smite.jpg';
  }
  return {
    id: spell.id ?? spell.name,
    name: spell.name,
    description: spell.description,
    cooldown: spell.cooldown,
    image: spellImage,
  };
};

export default createSpell;
