import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import gradients from 'znipe-styles/gradients';
import { LEFT, PADDING } from './PremiumBanner.constants';

export const TextWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  ${({ $direction }) =>
    $direction === LEFT
      ? css`
          ${TextWrapper} {
            margin-left: 10px;
          }
        `
      : css`
          ${IconWrapper} {
            margin-right: 10px;
          }
        `};
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;
  padding: 4px ${`${PADDING}px`};
  background: ${gradients.glimmeringGold};
  border-radius: 0 0 0 3px;
  overflow: hidden;
  white-space: nowrap;
`;

export const NonAnimateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  gap: 8px;
  width: 107px;
  height: 24px;
  padding: 0px 12px;
  border-bottom-left-radius: 3px;
  text-transform: uppercase;
  color: ${colors.grey10};
  background: ${gradients.glimmeringGold};
`;
