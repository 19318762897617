import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring, config } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import Icon from 'znipe-elements/general/Icon/Icon';
import { sizesShape } from 'tv-modules/DeadPlayerHandling/constants';
import { useCallback } from 'react';

const AnimatedSkull = styled(animated.div)`
  position: absolute;
`;

const sizes = {
  xsmall: 64,
  small: 80,
  medium: 112,
  large: 152,
  xlarge: 232,
};

const Skull = ({ size, show = false, onAnimationEnd }) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const onRest = useCallback(() => {
    if (show) onAnimationEnd();
  }, [show, onAnimationEnd]);

  const props = useSpring({
    scale: show ? 1 : 0,
    config: config.wobbly,
    immediate: prefersReducedMotion || !show,
    onRest,
  });

  return (
    <AnimatedSkull style={props} data-testid="skull-wrapper">
      <Icon type="deathSkull" size={sizes[size]} inline />
    </AnimatedSkull>
  );
};

Skull.propTypes = {
  size: sizesShape.isRequired,
  show: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
};

export default Skull;
