const gaTrackEvent = global.document
  ? (action, label, type = 'event', value = null, category = global.location.pathname) => {
      if (category === '/') {
        // biome-ignore lint/style/noParameterAssign:
        category = 'landing'; // eslint-disable-line no-param-reassign
      }

      if (window && window.ga) {
        if (value) {
          ga('send', type, category, action, label, value); // eslint-disable-line no-undef
        } else {
          ga('send', type, category, action, label); // eslint-disable-line no-undef
        }
      }

      if (window && window.mixpanel) {
        // eslint-disable-next-line no-undef
        mixpanel.track(action, {
          label,
          url: category,
        });
      }
    }
  : () => null;

export default gaTrackEvent;
