import { useCallback, useMemo } from 'react';
import { setMatchPlayerStreams } from 'tv-actions/matches';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import buildStreamObject from 'tv-utils/buildStreamObject';
import { useGetStreams } from 'tv-selectors/streams/makeGetStreams';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import useGameStreamOffsets from 'tv-hooks/useGameStreamOffsets';

const useStreamFunctions = (matchId, gameIndex, callbacks = {}) => {
  const { onChangeLayout, onSelectStream } = callbacks;
  const dispatch = useGqlStoreDispatch();
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const hasPremiumAccess = useHasPremiumAccess();
  const videoPlayerStreams = useMemo(
    () => playerStreams?.filter(stream => !stream.audioOnly) ?? [],
    [playerStreams],
  );
  const streamsObject = useGetStreams();
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex: gameIndex });
  const offsets = useGameStreamOffsets(matchId, gameId);
  const streamLimit = hasPremiumAccess ? 4 : 2;

  const displayStream = useCallback(
    (streamId, providedPosition = -1) => {
      if (providedPosition < 0) {
        onSelectStream(streamId);
        return;
      }
      const position = Math.min(providedPosition, videoPlayerStreams.length, streamLimit - 1);

      if (position >= streamLimit) return;
      const currentAddedStream = streamsObject[streamId] || {};
      const { tokenExpireTime } = currentAddedStream;
      const selectedStreamInfo = currentAddedStream.hls || currentAddedStream.dash || {};
      const addedStream = buildStreamObject({
        streamId,
        offsets,
        master: false,
        tokenExpireTime,
        ...selectedStreamInfo,
      });

      if (!addedStream) {
        if (onChangeLayout) onChangeLayout();
        return;
      }

      const streamInSelectedPosition = videoPlayerStreams[position];

      const selectedStreamPositionInPlayingStreams = playerStreams.findIndex(
        s => s.id === streamId,
      );
      // check if should add new stream or replace/swap one that is currently playing
      if (streamInSelectedPosition) {
        // swap if if selected stream is already playing
        const userSelectedStreamPositionInPlayingStreams = playerStreams.findIndex(
          stream => stream.id === (videoPlayerStreams[position] || {}).id,
        );
        if (selectedStreamPositionInPlayingStreams !== -1) {
          const selectedStream = playerStreams[selectedStreamPositionInPlayingStreams];
          const newStreamLayout = playerStreams.slice();
          newStreamLayout[userSelectedStreamPositionInPlayingStreams] = selectedStream;
          newStreamLayout[selectedStreamPositionInPlayingStreams] = streamInSelectedPosition;
          dispatch(setMatchPlayerStreams(matchId, newStreamLayout));
        } else {
          // replace stream playing at the requested position
          const replacedStreamIsMaster = streamInSelectedPosition.master;
          const newStreamLayout = playerStreams.slice();
          newStreamLayout.splice(userSelectedStreamPositionInPlayingStreams, 1, {
            ...addedStream,
            master: Boolean(replacedStreamIsMaster),
          });
          dispatch(setMatchPlayerStreams(matchId, newStreamLayout));
        }
      } else if (selectedStreamPositionInPlayingStreams < 0) {
        const newStreams = playerStreams.concat([addedStream]);
        dispatch(setMatchPlayerStreams(matchId, newStreams));
      }
      if (onChangeLayout) onChangeLayout();
    },
    [
      dispatch,
      streamLimit,
      matchId,
      offsets,
      onChangeLayout,
      playerStreams,
      videoPlayerStreams,
      streamsObject,
      onSelectStream,
    ],
  );

  const removeStream = useCallback(
    streamId => {
      const newStreams = playerStreams.filter(s => s.id !== streamId);
      if (newStreams.length > 0) dispatch(setMatchPlayerStreams(matchId, newStreams));
      if (onChangeLayout) onChangeLayout();
    },
    [dispatch, onChangeLayout, matchId, playerStreams],
  );

  return useMemo(() => ({ displayStream, removeStream }), [displayStream, removeStream]);
};

export default useStreamFunctions;
