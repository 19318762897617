import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import { HeaderActionsWrapper, ActionLabel } from '../../../ScreenOverlay.styles';
import themes from '../../../ScreenOverlay.themes';

const CloseHeader = ({ onClose }) => (
  <HeaderActionsWrapper data-testid="portrait-actions" onClick={onClose}>
    <Icon type="arrowDown" size={8} />
    <ActionLabel>Close</ActionLabel>
  </HeaderActionsWrapper>
);

CloseHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default withTheme(memo(CloseHeader), themes, 'screenOverlay');
