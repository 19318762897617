import { memo, useMemo, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useSpring, animated } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';
import fitty from 'fitty';
import ChampionAvatar from 'znipe-elements/data-display/Avatar/v2/ChampionAvatar/ChampionAvatar';
import CardLoading from 'tv-elements/feedback/CardLoading/CardLoading';
import { CHAMPION } from 'tv-elements/feedback/CardLoading/CardLoading.constants';
import {
  Container,
  Title,
  Subtitle,
  IconWrapper,
  Section,
  AvatarWrapper,
  Shimmer,
  BodySection,
  GradientOverlay,
  TextContainer,
  PreviewWrapper,
} from './ChampionCard.styles';

const XSMALL = 'xsmall';
const SMALL = 'small';
const MEDIUM = 'medium';

const AnimatedShimmer = animated(Shimmer);

const ChampionCard = ({ size = SMALL, title, subtitle, icon, image }) => {
  const cardRef = useRef(null);
  const videoRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const { hasLoaded } = useOnImageLoad(image);

  const cleanChampionName = useMemo(() => title?.replace(/[\s'.]/g, '') || '', [title]);
  const backgroundVideo = `https://assets.znipe.tv/champion-animations/${cleanChampionName}.mp4`;

  const resizeText = useCallback((node, length = 15) => {
    if (node !== null) {
      const subtitleLength = node.textContent.length;
      if (subtitleLength <= length) return;
      fitty(node, {
        minSize: 6,
        multiLine: false,
      });
    }
  }, []);

  const elementSizes = useMemo(() => {
    switch (size) {
      case MEDIUM:
        return {
          title: 'heading-l',
          subtitle: 'heading-s',
          icon: 32,
        };
      case SMALL:
        return {
          title: 'heading-m',
          subtitle: 'heading-s',
          icon: 32,
        };
      default:
        return {
          title: 'heading-s',
          subtitle: 'paragraph-s',
          icon: 24,
        };
    }
  }, [size]);

  const props = useSpring({
    top: isHovering ? '150%' : '-150%',
    from: { top: '-50%' },
    config: { duration: 400 },
    delay: 200,
    immediate: prefersReducedMotion,
  });

  const handleMouseEnter = useCallback(() => setIsHovering(true), []);
  const handleMouseLeave = useCallback(() => setIsHovering(false), []);
  const handleVideoError = useCallback(() => setIsHovering(false), []);

  const showFullComponent = useLoadingDelay(hasLoaded);

  if (!showFullComponent) {
    return <CardLoading size={size} type={CHAMPION} />;
  }

  return (
    <Container
      height={elementSizes.height}
      size={size}
      ref={cardRef}
      data-testid="champion-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovering && <AnimatedShimmer style={props} data-testid="sheen-animation" />}
      <PreviewWrapper $isHovering={isHovering}>
        {isHovering && (
          <video autoPlay loop ref={videoRef} data-testid="animation-video">
            <source
              src={backgroundVideo}
              type="video/mp4"
              onError={handleVideoError}
              data-testid="animation-video-src"
            />
          </video>
        )}
      </PreviewWrapper>

      <Section size={size}>
        <GradientOverlay />
        <TextContainer size={size}>
          <Title type={elementSizes.title} dataTestId="champion-title">
            <div ref={ref => resizeText(ref, 13)}>{title}</div>
          </Title>
          <Subtitle type={elementSizes.subtitle} dataTestId="champion-subtitle">
            <div ref={resizeText}>{subtitle}</div>
          </Subtitle>
        </TextContainer>
      </Section>
      <BodySection size={size} $isVisible={!isHovering}>
        <Section size={size}>
          <AvatarWrapper size={size}>
            <ChampionAvatar shape="circle" imageUrl={image} hasBorder />
          </AvatarWrapper>
        </Section>
        <IconWrapper>
          <Icon type={icon} size={elementSizes.icon} inline />
        </IconWrapper>
      </BodySection>
    </Container>
  );
};

ChampionCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM]),
};

export default memo(ChampionCard);
