export const getContent = (node: Node) => {
  if (node instanceof Element) return node.textContent;
  if (node instanceof Text) return node.wholeText;
  return '';
};

export const getSelection = (node: Node): Selection | null => {
  // ShadowRoot.getSelection is a non-standard API and only works in chrome.
  const rootNode = node.getRootNode() as ShadowRoot & { getSelection?: () => Selection | null };

  if (rootNode?.getSelection) return rootNode.getSelection();
  return window.getSelection();
};

type NodeWalkCallback = (node: Node) => boolean;

// node_walk: walk the element tree, stop when func(node) returns false
export const nodeWalk = (node: Node, func: NodeWalkCallback) => {
  let result = func(node);
  for (let child = node.firstChild; result && child; child = child.nextSibling) {
    result = nodeWalk(child, func);
  }
  return result;
};

export const getSelectedContent = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
): string[] => {
  if (!ref.current) return [];

  const textContent: string[][] = [];
  const selection = getSelection(ref.current);
  if (!selection) return [];

  for (let i = 0, len = selection.rangeCount; i < len; ++i) {
    const innerTextContent: string[] = [];

    selection
      .getRangeAt(i)
      .cloneContents()
      .childNodes.forEach(node => {
        const regularText = node.textContent;
        if (node instanceof Element) {
          const imgs = node.getElementsByTagName('img');
          if (imgs.length) {
            const [image] = imgs;
            const emojiText = image.getAttribute('alt');
            if (emojiText) innerTextContent.push(emojiText);
          }
        }

        if (regularText) {
          innerTextContent.push(regularText);
        }
      });

    textContent.push(innerTextContent);
  }

  return textContent.flat();
};
