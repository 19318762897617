import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const themes = {
  default: {
    color: colors.grey65,
    previousGameColor: colors.white,
  },
  proview: {
    color: colors.riotTextSecondary,
    previousGameColor: colors.riotTextSecondary,
  },
  esportal: {
    color: colors.grey65,
    previousGameColor: colors.grey65,
  },
};

export const Container = styled.div`
  font-weight: 600;
  width: 100%;
`;

export const GamesList = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  ${props =>
    props.useVerticalLayout &&
    css`
      flex-direction: column;
      font-size: 14px;
    `}
`;

export const MobileGameLabel = styled.div`
  padding-right: 10px;
  text-transform: capitalize;
  line-height: 20px;
  font-weight: 600;
`;

export const GameWrapper = styled.div`
  cursor: pointer;
  outline: none;
  color: ${({ isSelected, isPreviousGame, theme }) => {
    if (isSelected) return theme.UIAccent;
    if (isPreviousGame) return theme.matchGameSelector.previousGameColor;
    return theme.matchGameSelector.color;
  }};

  ${props =>
    props.useButtonStyle
      ? css`
          width: 100%;
          min-height: 65px;
          display: flex;
          align-items: center;
          padding: 0 21px;
          border-bottom: 1px solid ${props.theme.borderColor};
          background: ${props.isSelected ? props.theme.containerStroke : 'transparent'};
          &:hover {
            background: ${props.theme.containerStroke};
          }
        `
      : css`
          width: 41px;

          padding: 0 16px;
          &:not(:last-child) {
            margin-right: 6px;
          }
          &:hover {
            text-decoration: none;
            color: props.theme.UIAccent;
          }
        `}
`;

export const StatusWrapper = styled.div`
  margin-left: auto;
  font-size: 12px;
`;

export const PopOverContainer = styled.div`
  width: auto;
  white-space: nowrap;
  background: ${({ theme }) => theme.popOverBackground};
  padding: 8px 16px;
  border-radius: 25px;
`;

export const PopOverWrapper = styled.div`
  ${props =>
    props.isMobile &&
    css`
      width: 100%;
    `}
`;

export const ArrowWrapper = styled.div`
  ${props =>
    props.isMobile &&
    css`
      display: none;
    `}
  ${props => props.marginDirection && `margin-${props.marginDirection}: 6px`}
`;
