import { memo, useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import { setQuality } from 'tv-actions/old/control';
import { useQuality } from 'tv-selectors/control/makeGetQuality';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import themes from './OverlaySettings.themes';
import SubMenuContainer from './SubMenuContainer';
import {
  Container,
  Menu,
  MenuItem,
  Label,
  SelectedOption,
  TextWrapper,
  IconWrapper,
  menuItemHeight,
  paddingFromScreen,
} from './OverlaySettings.styles';
import { subMenuHeaderHeight } from './SubMenu/SubMenu.styles';
import { FADE_OUT_DURATION } from './OverlaySettings.timers';

const soundOptionsHeight = 219;

const QUALITY = 'quality';
const SOUND = 'sound';
const CASTER_LANGUAGE = 'caster-language';

const availableOptions = [
  { id: QUALITY, menu: 'quality' },
  { id: SOUND, menu: 'sound' },
  { id: CASTER_LANGUAGE, menu: 'caster language' },
];

const qualityLabels = ['Auto', 'Very high', 'High', 'Medium', 'Low'];

const getSelectedQualityLabel = (qualityOptions, quality) => {
  const parsedQuality = parseInt(quality, 10);
  if (parsedQuality < 0 || isNaN(parsedQuality)) return 'Auto';
  let q = 0;
  for (let i = 0; i < qualityOptions.length; i++) {
    const bitrate = qualityOptions[i].bandwidth;
    if (bitrate > 0 && bitrate <= parsedQuality) {
      q = i;
      break;
    }
  }

  return qualityLabels[q] ?? qualityLabels[0];
};

const OverlaySettings = ({
  visible = true,
  languages = [],
  onClickMenu = () => {},
  onClickSubMenu = () => {},
  playerRef = playerDefaultProps,
  matchId,
}) => {
  const globalStreams = useGetGameGlobalStreams({ matchId });
  const options = useMemo(
    () =>
      availableOptions.filter(({ id }) => {
        if (id === QUALITY) return true;
        if (id === SOUND) return globalStreams.filter(stream => stream.type === 'audio').length > 0;
        if (id === CASTER_LANGUAGE) return languages.length > 1;
        return false;
      }),
    [languages, globalStreams],
  );
  const hasMultipleOptions = options.length > 1;
  const dispatch = useDispatch();
  const reduxQuality = useQuality();
  const [subMenuVisible, setSubMenuVisible] = useState(!hasMultipleOptions);
  const [openSubMenu, setOpenSubMenu] = useState(hasMultipleOptions ? null : options[0]?.menu);
  const [toBeClosedSubMenu, setToBeClosedSubMenu] = useState(openSubMenu);

  const playerQualityOptions = playerRef.current
    .getVariantTracks()
    .sort((a, b) => b.bandwidth - a.bandwidth);

  const autoQualityOption = { bandwidth: -1, type: 'fakeVariant' };
  const qualityOptions = [autoQualityOption].concat(playerQualityOptions).reduce((acc, option) => {
    if (acc.length >= qualityLabels.length) return acc;
    if (acc.some(({ bandwidth }) => bandwidth === option.bandwidth)) return acc;
    acc.push(option);
    return acc;
  }, []);

  const [selectedVideoQualityLabel, setSelectedVideoQualityLabel] = useState(() =>
    getSelectedQualityLabel(qualityOptions, reduxQuality),
  );

  const [casterLanguage, setCasterLanguage] = useState(languages[0]);

  const defaultMenuHeight = options.length * menuItemHeight + paddingFromScreen * 2;
  const [menuHeight, setMenuHeight] = useState(defaultMenuHeight);

  useEffect(() => {
    let height = defaultMenuHeight;
    if (openSubMenu === 'quality') {
      height = menuItemHeight * qualityOptions.length + subMenuHeaderHeight;
    } else if (openSubMenu === 'sound') {
      height = soundOptionsHeight + subMenuHeaderHeight;
    } else if (openSubMenu === 'caster language') {
      height = menuItemHeight * languages.length + subMenuHeaderHeight;
    }
    setMenuHeight(height);
  }, [openSubMenu, languages.length, qualityOptions.length, defaultMenuHeight]);

  const getSelectedOption = useCallback(
    menu => {
      if (menu === 'quality') {
        return selectedVideoQualityLabel;
      }
      if (menu === 'caster language') {
        return casterLanguage;
      }
      return null;
    },
    [casterLanguage, selectedVideoQualityLabel],
  );

  const handleClickMenu = useCallback(
    menu => {
      setOpenSubMenu(menu);
      setSubMenuVisible(true);
      setToBeClosedSubMenu(menu);
      onClickMenu();
    },
    [onClickMenu],
  );

  const handleQualitySelect = useCallback(
    quality => {
      const { current } = playerRef;
      const { selectQuality } = current;
      selectQuality(quality);
      dispatch(setQuality(quality));
      const selectedQualityIndex = qualityOptions.findIndex(q => q.bandwidth === quality);
      const qualityLabel = qualityLabels[selectedQualityIndex] || '';
      setSelectedVideoQualityLabel(qualityLabel);
    },
    [dispatch, playerRef, qualityOptions],
  );

  const handleBackToMain = useCallback(() => {
    setSubMenuVisible(false);
    const timeout = setTimeout(() => {
      setOpenSubMenu(null);
      setToBeClosedSubMenu(null);
      onClickSubMenu();
    }, FADE_OUT_DURATION);
    return () => clearTimeout(timeout);
  }, [onClickSubMenu]);

  const handleOptionItem = useCallback(
    option => {
      if (openSubMenu === 'quality') {
        handleQualitySelect(option);
      }
      if (openSubMenu === 'caster language') {
        setCasterLanguage(option);
      }
      if (hasMultipleOptions) handleBackToMain();
    },
    [openSubMenu, handleQualitySelect, handleBackToMain, hasMultipleOptions],
  );

  return (
    <Container visible={visible} data-testid="overlay-settings">
      <Menu isSubMenuOpen={openSubMenu} height={menuHeight}>
        {!openSubMenu &&
          options.map(option => (
            <MenuItem
              key={option.id}
              onClick={() => handleClickMenu(option.menu)}
              isVisible={!subMenuVisible}
              data-testid={`menu-item-${option.id}`}
            >
              <Label>{option.menu}</Label>
              <SelectedOption
                data-testid={`selected-option-${option.id}`}
                uppercase={option.id === 'caster-language'}
              >
                <TextWrapper>{getSelectedOption(option.menu)}</TextWrapper>
                <IconWrapper>
                  <Icon type="chevronRight" size={24} />
                </IconWrapper>
              </SelectedOption>
            </MenuItem>
          ))}
        <SubMenuContainer
          matchId={matchId}
          selected={getSelectedOption(openSubMenu)}
          qualityOptions={qualityOptions}
          qualityLabels={qualityLabels}
          languages={languages}
          menu={openSubMenu || toBeClosedSubMenu}
          onClickHeader={hasMultipleOptions ? handleBackToMain : undefined}
          onClickOptionItem={handleOptionItem}
          isVisible={subMenuVisible}
        />
      </Menu>
    </Container>
  );
};

OverlaySettings.propTypes = {
  visible: PropTypes.bool,
  matchId: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
  onClickMenu: PropTypes.func,
  onClickSubMenu: PropTypes.func,
  playerRef: playerPropTypes,
};

export default withTheme(memo(OverlaySettings), themes, 'overlaySettings');
