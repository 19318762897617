import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CHAMPION, PLAYER, TEAM, RIOT_CHAMPION } from '../constants';
import SearchResultItem from '../SearchResultItem/SearchResultItem';
import { List, Row } from './SearchSuggestions.styles';
import { BackgroundLayer } from '../Shared.styles';

const SearchSuggestions = ({ suggestions = [], onClickSuggestion = () => {} }) => {
  const getTitleSub = useCallback((category, hit) => {
    if (category?.indexOf(TEAM)) return hit.country;
    return hit.teamName;
  }, []);

  const getSearchResultItems = useCallback(
    results =>
      results.slice(0, 7).map((hit, index) => {
        const { id, _type, nickname, name, logoLight, image, description } = hit || {};
        const itemCategory = _type.slice(0, -1);
        const isPlayer = itemCategory.indexOf(PLAYER) !== -1;
        const isTeam = itemCategory.indexOf(TEAM) !== -1;
        const isChampion = itemCategory === RIOT_CHAMPION;
        const title = isPlayer ? nickname : name;
        const titleSub = isChampion ? description : getTitleSub(itemCategory, hit);
        const imageUrl = isTeam ? logoLight : image;

        const handleClickRow = () => onClickSuggestion(itemCategory, hit);
        return (
          <Row key={`${itemCategory}-${id}`} onClick={handleClickRow} data-testid={`row-${index}`}>
            <SearchResultItem
              type={isChampion ? CHAMPION : itemCategory}
              title={title}
              subtitle={titleSub}
              direction="horizontal"
              imageUrl={imageUrl}
            />
            <BackgroundLayer />
          </Row>
        );
      }),
    [onClickSuggestion, getTitleSub],
  );

  const listItems = useMemo(
    () => getSearchResultItems(suggestions),
    [suggestions, getSearchResultItems],
  );

  return <List data-testid="list">{listItems}</List>;
};

export const suggestionShape = PropTypes.shape({
  id: PropTypes.string,
  country: PropTypes.string,
  game: PropTypes.string,
  logoDark: PropTypes.string,
  matchesPlayed: PropTypes.number,
  created: PropTypes.string,
  logoLight: PropTypes.string,
  name: PropTypes.string,
  shortName: PropTypes.string,
  _type: PropTypes.string,
});

SearchSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(suggestionShape),
  onClickSuggestion: PropTypes.func,
};

export default memo(SearchSuggestions);
