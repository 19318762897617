import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsLaptopOrGreater } from 'tv-selectors/browser/makeGetIsLaptopOrGreater';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import themes from './CancelInfo.themes';

import { Container, List, Item } from './CancelInfo.styles';

const CancelInfo = ({
  introText = "We're sorry to see you leaving us! If you cancel the subscription, you will miss out on many great perks that you get with Pro View, such as:",
  listData = [
    { icon: 'tv', feature: 'Unique player PoV' },
    { icon: 'headphones', feature: 'Exclusive commentary' },
    { icon: 'barChart', feature: 'Real-time statistics' },
  ],
  buttonText = 'Cancel Subscription',
}) => {
  const { cancelInfo = {} } = useThemeContext();
  const isLaptopOrGreater = useGetIsLaptopOrGreater();
  const typographyType = useMemo(
    () => (isLaptopOrGreater ? 'title-xl' : 'title-l'),
    [isLaptopOrGreater],
  );
  return (
    <Container data-testid="cancel-info">
      <Typography type={typographyType}>{introText}</Typography>
      <List>
        {listData?.map(({ icon, feature }) => (
          <Item key={icon} data-testid="cancel-info-listItem">
            <Icon type={icon} fillColor={cancelInfo.iconColor} size={24} inline />
            <Typography type={typographyType}>{feature}</Typography>
          </Item>
        ))}
      </List>
      <Button variant="solid-color" size="medium" to="cancel/form">
        {buttonText}
      </Button>
    </Container>
  );
};

CancelInfo.propTypes = {
  introText: PropTypes.string,
  buttonText: PropTypes.string,
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      feature: PropTypes.string.isRequired,
    }),
  ),
};

export default withTheme(memo(CancelInfo), themes, 'cancelInfo');
