import { useEffect } from 'react';
import isBrowser from 'znipe-utils/web/isBrowser';

const useBeforeWindowClose = (handleOnUnload, isIOS = false) => {
  const listenProperty = isIOS ? 'pagehide' : 'beforeunload';
  useEffect(() => {
    if (!isBrowser()) return undefined;
    window.addEventListener(listenProperty, handleOnUnload);
    return () => {
      window.removeEventListener(listenProperty, handleOnUnload);
    };
  }, [listenProperty, handleOnUnload]);
};

export default useBeforeWindowClose;
