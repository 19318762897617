export default class GqlClient {
  promises: Record<string, Promise<unknown>> = {};

  getPromise<T>(key: string): Promise<T> | undefined {
    return this.promises[key] as Promise<T>;
  }

  addPromise(key: string, p: Promise<unknown>) {
    this.promises[key] = p;
  }

  removePromise(key: string) {
    delete this.promises[key];
  }

  getGqlData() {
    return Promise.all(Object.values(this.promises));
  }
}
