import PropTypes from 'prop-types';
import { SVG, Path, BackgroundContainer } from './EdgedWaves.styles';

const EdgedWaves = ({ animate = false, animationDuration = 5 }) => (
  <SVG viewBox="0 0 506 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M388 6.5913L373.875 21.0783H337.554L319.105 40H293.45L273.848 19.8957H238.104L218.502 40H195.153L179.299 23.7391H148.166L134.618 37.6348H117.034L95.1263 15.1652H63.1293L54.1932 6H0"
      stroke="url(#edged_waves0)"
      strokeOpacity="0.05"
      animate={animate}
      animationDuration={animationDuration}
    />
    <Path
      d="M506 9.21239H473.99L452.074 30.7345H434.483L420.929 17.4248H389.784L373.924 33H350.565L330.956 13.7434H295.197L275.587 33H249.922L231.466 14.8761H195.13L181 1"
      stroke="url(#edged_waves1)"
      strokeOpacity="0.1"
      animate={animate}
      animationDuration={animationDuration}
    />
    <defs>
      <linearGradient
        id="edged_waves0"
        x1="384.541"
        y1="10.1391"
        x2="36.321"
        y2="9.858"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.0290819" stopColor="white" stopOpacity="0.970918" />
        <stop offset="0.968234" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="edged_waves1"
        x1="181"
        y1="0.433661"
        x2="513"
        y2="0.433661"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.0265201" stopColor="white" stopOpacity="0.97348" />
        <stop offset="0.953506" stopColor="white" />
        <stop offset="0.990896" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
);

EdgedWaves.propTypes = {
  animate: PropTypes.bool,
  animationDuration: PropTypes.number,
};

export const EdgedWavesBackground = props => (
  <BackgroundContainer>
    <EdgedWaves {...props} /> {/* eslint-disable-line react/jsx-props-no-spreading */}
  </BackgroundContainer>
);

EdgedWavesBackground.propTypes = EdgedWaves.propTypes;

export default EdgedWaves;
