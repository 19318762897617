import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import AvatarWithTitlesSkeleton from 'znipe-elements/feedback/AvatarWithTitlesSkeleton/AvatarWithTitlesSkeleton';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import { CIRCLE } from 'znipe-constants/loading';
import { SIZES, SMALL, MEDIUM, LARGE } from './TopicHeader.constants';
import { Background, RightSection, Content, LeftSection } from './TopicHeader.styles';

const TopicHeaderLoading = ({ size = SMALL }) => {
  const { buttonHeight, buttonWidth, headerHeight } = useMemo(() => {
    if (size === LARGE) {
      return {
        headerHeight: 152,
        buttonHeight: 40,
        buttonWidth: 168,
      };
    }
    if (size === MEDIUM) {
      return {
        headerHeight: 136,
        buttonHeight: 40,
        buttonWidth: 168,
      };
    }
    return {
      headerHeight: 120,
      buttonHeight: 32,
      buttonWidth: 128,
    };
  }, [size]);

  return (
    <Background data-testid="topic-header-loading" $height={headerHeight}>
      <Content>
        <LeftSection $isLoading>
          <AvatarWithTitlesSkeleton avatarShape={CIRCLE} size={size} />
        </LeftSection>
        <RightSection>
          <TextSkeleton height={buttonHeight} width={buttonWidth} />
        </RightSection>
      </Content>
    </Background>
  );
};

TopicHeaderLoading.propTypes = {
  size: PropTypes.oneOf(SIZES),
};

export default memo(TopicHeaderLoading);
