import styled, { css } from 'styled-components';
import { SMALL } from './Accordion.constants';

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 24px;
  }
`;

export const Item = styled.div`
  overflow: hidden;
  margin: 8px 0 24px 0;
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  border-radius: 5px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  > *:only-child {
    margin: 0;
  }
  > *:not(:only-child) {
    margin: 8px 0;
  }
  min-height: 66px;
  padding: 8px 16px;
  ${({ $size }) =>
    $size !== SMALL &&
    css`
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      min-height: 88px;
    `}
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.tertiaryBackgroundColor};
    cursor: pointer;
  }
`;
