import { useEffect, useState, useCallback, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { Route, Navigate, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import GlobalStyle from 'znipe-styles/global.styles';
import withTheme from 'znipe-themes/hocs/withTheme';
import useTheme from 'tv-hooks/useTheme';
import logger from 'znipe-logger';
import useThemeContext from 'znipe-hooks/useThemeContext';
import BackButton from 'tv-routes/Subscribe/BackButton';
import useSelectCurrency from 'tv-hooks/useSelectCurrency';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import StripeCardForm from 'tv-modules/Payment/CardForm/StripeCardForm';
import PayPalForm from 'tv-modules/Payment/PayPalForm/PayPalForm';
import SelectPaymentMethod from 'tv-modules/Payment/SelectPaymentMethod/SelectPaymentMethod';
import PromoCodeForm from 'tv-modules/Payment/PromoCodeForm/PromoCodeForm';
import PaymentHeader from 'tv-modules/Payment/PaymentHeader/PaymentHeader';
import useGetPrice from 'tv-modules/Payment/hooks/useGetPrice';
import SuccessPage from 'tv-modules/Payment/SuccessPage/SuccessPage';
import CardError from 'tv-modules/Payment/ErrorPage/CardError/CardError';
import PayPalError from 'tv-modules/Payment/ErrorPage/PayPalError/PayPalError';
import BaseError from 'tv-modules/Payment/ErrorPage/BaseError/BaseError';
import TermsOfService from 'tv-modules/Legal/TermsOfService/TermsOfService';
import Icon from 'znipe-elements/general/Icon/Icon';
import Fonts from 'znipe-elements/general/Typography/Fonts/Fonts';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetPackageProducts } from 'tv-selectors/packages/makeGetPackageProducts';
import { useGetProductName } from 'tv-selectors/products/makeGetProductName';
import usePackageName from 'tv-hooks/usePackageName';
import { useGetAuthSubscriptionByProductId } from 'tv-selectors/auth/makeGetAuthSubscriptionByProductId';
import { getProduct } from 'tv-actions/old/products';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import useQueryObject from 'tv-hooks/useQueryObject';
import colors from 'znipe-styles/colors';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import { useIsFetching } from 'tv-selectors/auth/makeGetIsFetching';
import { isRiotDomain } from 'tv-utils/pageUtils';
import { login as riotLogin } from 'tv-modules/Authentication/utils/riotAuthHelper';
import SubscribeQuery from 'znipe-gql/queries/getPackage.graphql';
import usePriceInfoText from './hooks/usePriceInfoText';
import useSelectionDescription from './hooks/useSelectionDescription';
import {
  PageContainer,
  Content,
  FormContainer,
  LogoWrapper,
  CloseWrapper,
} from './Subscribe.styles';
import themes from './Subscribe.themes';
/*
 if we change this 'monthly', it need to change the logic of
 usePriceInfoText and useSelectionDescription as the assume that discountPeriods and text is monthly
*/
const paymentPeriod = 'monthly';
const successHeader = 'Hurray!';

const Subscribe = () => {
  const [loading, setLoading] = useState(true);
  const [promoCode, setPromoCode] = useState();
  const [animate, setAnimate] = useState(true);
  const hasPremiumAccess = useHasPremiumAccess();
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = useIsFetching();

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { packageSlug } = useParams();
  const packageName = usePackageName();
  const { 'redirect-path': queryPath } = useQueryObject();

  const isTabletOrGreater = useGetIsTabletOrGreater();
  const {
    subscribe: { error },
  } = useThemeContext();
  const currency = useSelectCurrency();

  const packageProducts = useGetPackageProducts({ packageName });
  const productId = packageProducts[0];

  const productName = useGetProductName({ productId });
  const getPrice = useGetPrice(currency, productId, paymentPeriod);
  const freeMonthsText = usePriceInfoText(productId);

  const productSubscription = useGetAuthSubscriptionByProductId({ productId });
  const isMissingSubscription = Object.keys(productSubscription).length < 1;
  const hasCancelled = productSubscription.canceled || false;
  const billingEndDate = productSubscription.currentBillingEnd;
  const subscriptionExpired = Date.parse(billingEndDate) < Date.now();
  const invoiceIsPastDue = productSubscription.invoiceIsPastDue || false;

  const userHasValidSubscription = useMemo(
    () =>
      !(
        invoiceIsPastDue ||
        hasCancelled ||
        isMissingSubscription ||
        subscriptionExpired ||
        !billingEndDate
      ),
    [invoiceIsPastDue, hasCancelled, isMissingSubscription, subscriptionExpired, billingEndDate],
  );

  const selectionDescription = useSelectionDescription(productId, !!promoCode);
  const queryOptions = useMemo(
    () => ({
      ssr: true,
      cacheTime: 30,
      variables: {
        package: packageName,
      },
    }),
    [packageName],
  );

  useReduxGqlQuery(SubscribeQuery, queryOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    const base = '/subscribe/error';
    if (packageName === 'proview')
      navigate(packageSlug ? `/${packageSlug}${base}` : base, { replace: true });
  }, [navigate, packageName, packageSlug]);

  useEffect(() => {
    // 'usd' should be calculated in some nice way on server side. Probably by location
    dispatch(getProduct(productId, { period: paymentPeriod, currency: 'usd' }));
  }, [dispatch, productId]);

  useEffect(() => {
    getPrice();
  }, [getPrice]);

  useEffect(() => {
    setLoading(false);
    const timeout = setTimeout(() => setAnimate(false), 600);
    return () => clearTimeout(timeout);
  }, []);
  const isOnSubPage = !!pathname.match(/.*\/subscribe\/.+$/);

  const onSuccess = useCallback(
    (paymentId, results) => {
      if (paymentId) {
        try {
          const { product = {} } = results || {};
          const { subscriptionPlans = {} } = product;
          const subPlan = subscriptionPlans[0];
          const { amount } =
            subPlan?.price?.find(currentPrice => currentPrice.currency === 'usd') ?? {};
          const price = amount / 100;
          if (global.document && global.gtag && price) {
            // Do we want to support muliple currencies?
            // eslint-disable-next-line no-undef
            gtag('event', 'purchase', {
              allow_custom_scripts: true,
              value: price,
              transaction_id: paymentId,
              send_to: 'DC-8663141/paid_0/provi0+transactions',
            });
          }
        } catch (e) {
          logger.log('Could not send purchase event', e);
        }
      }
      navigate(`success${search}`);
    },
    [navigate, search],
  );
  const onFailureCard = useCallback(() => {
    navigate(`card/error${search}`);
  }, [navigate, search]);
  const onFailurePayPal = useCallback(() => {
    navigate(`paypal/error${search}`);
  }, [navigate, search]);

  const redirectPath = useMemo(() => {
    if (!global.document || !queryPath) return packageSlug ? `/${packageSlug}/home` : '/home';
    return window.decodeURIComponent(queryPath);
  }, [packageSlug, queryPath]);

  const onPromoCodeSuccess = useCallback(
    (code, isFree = false) => {
      setPromoCode(code);
      if (isFree) onSuccess();
      else navigate(`..${search}`);
    },
    [navigate, onSuccess, search],
  );

  const selectionHeader = useMemo(
    () =>
      isTabletOrGreater ? (
        `Start watching ${productName}`
      ) : (
        <>
          Start watching
          <br />
          {productName}
        </>
      ),
    [isTabletOrGreater, productName],
  );

  const onLogoClick = useCallback(() => {
    navigate(packageSlug ? `/${packageSlug}/home` : '/home');
  }, [navigate, packageSlug]);

  const onCloseClick = useCallback(() => {
    navigate(redirectPath);
  }, [navigate, redirectPath]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (userHasValidSubscription && hasPremiumAccess && pathname.indexOf('/success') === -1)
      navigate(redirectPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isAuthenticated || isAuthenticating) return;
    if (isRiotDomain()) riotLogin();
    else {
      const base = packageSlug ? `/${packageSlug}/login` : '/login';
      const pathBack = window.encodeURIComponent(`${pathname}${search}`);

      navigate(`${base}?redirect-path=${pathBack}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthenticating]);

  return (
    <>
      <Fonts />
      <GlobalStyle />
      <PageContainer isLoading={loading}>
        <FormContainer>
          {isTabletOrGreater && (
            <LogoWrapper onClick={onLogoClick}>
              <Icon type="proViewPlainLogo" />
            </LogoWrapper>
          )}
          <PaymentHeader animate={animate} animationTime={0.4} thin={isOnSubPage} />
          {isTabletOrGreater && (
            <CloseWrapper onClick={onCloseClick}>
              <Icon size={32} type="close" fillColor={colors.white} inline />
            </CloseWrapper>
          )}
          <Content animate={animate} animationTime={0.1} animationDelay={0.4}>
            {!isTabletOrGreater && (
              <CloseWrapper $mobile onClick={onCloseClick}>
                <Icon size={24} type="close" fillColor={colors.black} inline />
              </CloseWrapper>
            )}
            <Routes>
              <Route
                path="/"
                element={
                  <SelectPaymentMethod
                    header={!promoCode ? selectionHeader : successHeader}
                    description={selectionDescription}
                    freeMonthsText={freeMonthsText}
                    disablePromoCode={!!promoCode}
                  />
                }
              />
              <Route
                path="card"
                element={
                  <>
                    <BackButton to={`..${search}`}>Select payment method</BackButton>
                    <StripeCardForm
                      header="Card details"
                      onSuccess={onSuccess}
                      onFailure={onFailureCard}
                      productId={productId}
                      buttonLabel="Subscribe"
                      freeMonthsText={freeMonthsText}
                      termsOfServices={[<TermsOfService productName={productName} />]}
                      promoCode={promoCode}
                      currency={currency}
                      period={paymentPeriod}
                    />
                  </>
                }
              />
              <Route
                path="paypal"
                element={
                  <>
                    <BackButton to={`..${search}`}>Select payment method</BackButton>
                    <PayPalForm
                      onSuccess={onSuccess}
                      onFailure={onFailurePayPal}
                      productId={productId}
                      freeMonthsText={freeMonthsText}
                      termsOfServices={[<TermsOfService productName={productName} />]}
                      promoCode={promoCode}
                      currency={currency}
                      period={paymentPeriod}
                    />
                  </>
                }
              />
              <Route
                path="coupon"
                element={
                  <>
                    <BackButton to={`..${search}`}>Select payment method</BackButton>
                    <PromoCodeForm
                      productId={productId}
                      onSuccess={onPromoCodeSuccess}
                      period={paymentPeriod}
                      currency={currency}
                      header="Redeem promo code"
                    />
                  </>
                }
              />
              <Route
                path="success"
                element={
                  <SuccessPage
                    header={successHeader}
                    productName={productName}
                    description={
                      <>
                        You&apos;ve successfully subscribed to <b>{productName}</b>
                      </>
                    }
                    to={redirectPath}
                  />
                }
              />
              <Route
                path="/card/error"
                element={
                  <>
                    <BackButton to={`..${search}`}>Card details</BackButton>
                    <CardError description="Unfortunately the provided card has been declined. Please double-check the entered information or try another card." />
                  </>
                }
              />
              <Route
                path="paypal/error"
                element={
                  <>
                    <BackButton to={`..${search}`}>Select payment method</BackButton>
                    <PayPalError description="The provided paypal account has insufficient funds. Please try another payment method." />
                  </>
                }
              />
              <Route
                path="error"
                element={<BaseError header={error?.header} description={error?.description} />}
              />
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </Content>
        </FormContainer>
      </PageContainer>
    </>
  );
};

const ThemedSubscribe = withTheme(Subscribe, themes, 'subscribe');

const ThemeWrapper = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <ThemedSubscribe />
    </ThemeProvider>
  );
};

export default ThemeWrapper;
