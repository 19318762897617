import colors from 'znipe-styles/colors';

export default {
  proview: {
    color: colors.riotTextSecondary,
    linkColor: colors.znipeBlue,
  },
  default: {
    color: colors.riotTextSecondary,
    linkColor: colors.znipeBlue,
  },
};
