import { memo } from 'react';
import styled from 'styled-components';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import ImageSkeleton from 'znipe-elements/feedback/ImageSkeleton/ImageSkeleton';
import { Row } from './Sidebar.styles';

const SubscriptionItemWrapper = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 16px;
  }
`;

const SidebarControlsWrapper = styled.div`
  width: 40px;
  height: 40px;
  justify-self: flex-end;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

const ListItemLoading = () => (
  <Row>
    <SubscriptionItemWrapper>
      <ImageSkeleton shape="circle" size="xsmall" />
      <FlexColumn>
        <TextSkeleton size="small" width={67} />
        <TextSkeleton size="small" width={47} />
      </FlexColumn>
    </SubscriptionItemWrapper>
    <SidebarControlsWrapper>
      <ImageSkeleton shape="circle" size="auto" />
    </SidebarControlsWrapper>
  </Row>
);

export default memo(ListItemLoading);
