import styled from 'styled-components';
import { animated } from 'react-spring';
import colors from 'znipe-styles/colors';

const VideoAmountToWidthRatio = {
  1: 1,
  2: 2,
  3: 1.5,
  4: 1,
};

const getAspectRatio = (videosAmount, useUltraWideLayout) => {
  if (useUltraWideLayout) {
    return `${16 * videosAmount} / 9`;
  }

  if (!VideoAmountToWidthRatio[videosAmount]) {
    return '16 / 9';
  }

  return `${16 * VideoAmountToWidthRatio[videosAmount]} / 9`;
};

export const OuterAspectRatioContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-height: inherit;
  aspect-ratio: ${({ videosAmount, useUltraWideLayout }) =>
    getAspectRatio(videosAmount, useUltraWideLayout)};
`;

export const AspectRatioContainer = styled.div`
  position: relative;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  aspect-ratio: ${({ videosAmount, useUltraWideLayout }) =>
    getAspectRatio(videosAmount, useUltraWideLayout)};
  background-color: ${colors.black};
  transition: aspect-ratio 0.5s ease;
  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

export const Overlay = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`;

export const OverlayContent = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: top left;
`;

export const ActiveStreamOverlay = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid ${colors.white};
`;

export const VideoContent = styled.div`
  display: flex;
  position: relative;

  &:hover,
  &:focus {
    ${Overlay} {
      visibility: visible;
    }
  }
`;

export const VideoWrapper = styled(animated.div)`
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: ${({ index }) => 4 - index};
`;

export const HiddenPlayer = styled.div`
  visibility: hidden;
  position: absolute;
  height: 0;
  width: 0;
`;
