import { useMemo } from 'react';
import PropTypes from 'prop-types';
import BaseButton from 'znipe-elements/general/Button_deprecated/Button';
import getColorsFromGradientString from 'znipe-utils/misc/getColorsFromGradientString';
import useThemeContext from 'znipe-hooks/useThemeContext';

const Button = ({ buttonLabel, onClick, size = 'small' }) => {
  const { onboardingGradient } = useThemeContext();
  const [color1] = useMemo(
    () => getColorsFromGradientString(onboardingGradient),
    [onboardingGradient],
  );
  return (
    <BaseButton variant="primary" size={size} color={color1} onClick={onClick}>
      {buttonLabel}
    </BaseButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
};

export default Button;
