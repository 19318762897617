import styled, { css } from 'styled-components';

const BaseContainer = styled.div`
  border-radius: 10px;
  background-color: black;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.ruleBackgroundColor};
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'initial')};
`;

const getSize = (size, theme, type) => {
  const { slider } = theme;
  switch (size) {
    case 'fat':
      if (type === 'bar') return slider.fat.bar;
      if (type === 'handle') return slider.fat.handle;
      return slider.fat.barHover;
    case 'medium':
      if (type === 'bar') return slider.medium.bar;
      if (type === 'handle') return slider.medium.handle;
      return slider.medium.barHover;
    default:
      if (type === 'bar') return slider.thin.bar;
      if (type === 'handle') return slider.thin.handle;
      return slider.thin.barHover;
  }
};

export const HorizontalContainer = styled(BaseContainer)`
  width: 100%;
  ${({ size, theme, hoverEffect }) => css`
    height: ${getSize(size, theme, 'bar')};
    ${
      hoverEffect &&
      css`
      &:hover,
      &:focus {
        height: ${getSize(size, theme, 'hover')};
      }
    `
    }
  `}
`;

export const VerticalContainer = styled(BaseContainer)`
  height: 100%;
  flex-direction: column;
  ${({ size, theme, hoverEffect }) => css`
    width: ${getSize(size, theme, 'bar')};
    ${
      hoverEffect &&
      css`
      &:hover,
      &:focus {
        width: ${getSize(size, theme, 'hover')};
      }
    `
    }
  `}
`;

const BaseHandle = styled.div`
  border-radius: ${({ size, theme }) => getSize(size, theme, 'borderRadius')};
  border: 1px solid ${props => props.theme.ruleBackgroundColor};
  background-color: ${props => props.theme.slider.progressBarBaseHandleColor};
  height: ${({ size, theme }) => getSize(size, theme, 'handle')};
  width: ${({ size, theme }) => getSize(size, theme, 'handle')};
  position: absolute;
`;

export const HorizontalHandle = styled(BaseHandle).attrs(({ position }) => ({
  style: {
    transform: `translateX(calc(-50% + ${position}px))`,
  },
}))``;
export const VerticalHandle = styled(BaseHandle).attrs(({ position }) => ({
  style: {
    transform: `translateY(calc(-50% + ${position}px))`,
  },
}))``;

const BaseTooltip = styled.span`
  position: absolute;
  background-color: ${props => props.theme.secondaryBackgroundColor};
  border-radius: 2px;
  padding: 5px 9px;
  color: ${props => props.theme.primaryTextColor};
  z-index: 2;
  font-size: 14px;
  line-height: 14px;
  visibility: ${({ visible }) => (visible ? 'unset' : 'hidden')};
`;

export const HorizontalTooltip = styled(BaseTooltip)`
  transform: translateX(${({ position }) => position}px) translateY(-22px);
  left: 0;
`;

export const VerticalTooltip = styled(BaseTooltip)`
  transform: translateX(40px) translateY(-${({ position }) => position}px);
  bottom: 0;
`;

export const BarWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`;
