import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const makeGetTeamGameTitle = () => {
  const getTeams = makeGetTeams();
  return createSelector([getTeams, getTeamIdFromProps], (allTeams, teamId) => {
    if (!allTeams || !teamId) return '';
    return allTeams[teamId]?.game ?? '';
  });
};

export const useGetTeamGameTitle = props => useMemoizedGqlSelector(makeGetTeamGameTitle, props);

export default makeGetTeamGameTitle;
