import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Container,
  TextWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  AvatarWrapper,
  TagLabel,
} from './AvatarInfo.styles';
import { XSMALL, SMALL, MEDIUM, LARGE, XLARGE } from './AvatarInfo.constants';

const AvatarInfo = ({
  size = XSMALL,
  title = '',
  subtitle = '',
  icon = null,
  tagLabel = '',
  children = null,
}) => {
  const elementSizes = useMemo(() => {
    switch (size) {
      case XLARGE:
        return {
          title: 'heading-xl',
          subtitle: 'heading-m',
          icon: 24,
          tagLabel: 'title-m',
        };
      case LARGE:
        return {
          title: 'heading-l',
          subtitle: 'heading-s',
          icon: 24,
          tagLabel: 'title-m',
        };
      case MEDIUM:
        return {
          title: 'heading-m',
          subtitle: 'heading-s',
          icon: 24,
          tagLabel: 'title-xs',
        };
      case SMALL:
        return {
          title: 'heading-s',
          subtitle: 'heading-xs',
          icon: 24,
          tagLabel: 'title-xs',
        };
      default:
        return {
          height: 72,
          title: 'heading-s',
          subtitle: 'heading-xs',
          icon: 16,
          tagLabel: 'title-xs',
        };
    }
  }, [size]);

  return (
    <Container>
      <AvatarWrapper $size={size}>{children}</AvatarWrapper>
      <TextWrapper>
        <TitleWrapper $size={size}>
          <Title dataTestId="avatar-info-title" type={elementSizes.title}>
            {title}
          </Title>
          {icon && <Icon type={icon} size={elementSizes.icon} inline />}
          {tagLabel && (
            <TagLabel data-testid="avatar-info-tag-label" $size={size}>
              <Typography type={elementSizes.tagLabel}>{tagLabel}</Typography>
            </TagLabel>
          )}
        </TitleWrapper>
        {subtitle && (
          <Subtitle dataTestId="avatar-info-subtitle" type={elementSizes.subtitle}>
            {subtitle}
          </Subtitle>
        )}
      </TextWrapper>
    </Container>
  );
};

AvatarInfo.propTypes = {
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM, LARGE, XLARGE]),
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tagLabel: PropTypes.string,
  children: PropTypes.node,
};

export default AvatarInfo;
