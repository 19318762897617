import { createSelector } from 'reselect';
import makeGetProductUserPrice from 'tv-selectors/products/makeGetProductUserPrice';
import makeGetSelectedProduct from 'tv-selectors/products/makeGetSelectedProduct';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductSubscriptionCurrency = () => {
  const getProductUserPrice = makeGetProductUserPrice();
  const getSelectedProduct = makeGetSelectedProduct();
  return createSelector([getProductUserPrice, getSelectedProduct], (userPrice, product) => {
    if (userPrice.productType === 'subscription' && typeof userPrice.currency === 'string') {
      return userPrice.currency;
    }
    if (typeof product.subscriptionCurrency === 'string') {
      return product.subscriptionCurrency;
    }

    return 'usd';
  });
};

export const useGetProductSubscriptionCurrency = props =>
  useMemoizedSelector(makeGetProductSubscriptionCurrency, props);

export default makeGetProductSubscriptionCurrency;
