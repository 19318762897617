import loadable from 'znipe-utils/web/loadable';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';

export const POV_SELECTOR_STREAM_ID = 'POV_SELECTOR_STREAM_ID';
export const SIDEBAR_STATS_VISIBLITIY = 'SIDEBAR_STATS_VISIBLITIY';
export const SIDEBAR_PREDICTIONS_VISIBLITIY = 'SIDEBAR_PREDICTIONS_VISIBLITIY';
export const SIDEBAR_HIGHLIGHTS_VISIBLITIY = 'SIDEBAR_HIGHLIGHTS_VISIBLITIY';
export const SIDEBAR_ROOMS_VISIBLITIY = 'SIDEBAR_ROOMS_VISIBLITIY';
export const SIDEBAR_GAMES_VISIBILITY = 'SIDEBAR_GAMES_VISIBILITY';
export const SIDEBAR_STREAMS_VISIBILITY = 'SIDEBAR_STREAMS_VISIBILITY';
export const SIDEBAR_CHAT_VISIBILITY = 'SIDEBAR_CHAT_VISIBILITY';
export const POV_SELECTOR_RESET = 'POV_SELECTOR_RESET';
export const STAGE_WELCOME_STEP_ID = 'stage-welcome';

export const initialState = {
  streamSelectorState: { selectedStreamId: undefined },
  highlightsSidebarVisible: false,
  statsSidebarVisible: false,
  predictionsSidebarVisible: false,
  roomsSidebarVisible: false,
  gamesSidebarVisibility: false,
  chatSidebarVisible: false,
};

export const hints = [
  {
    hintContainer: '#stage-highlight-button',
    label: 'Highlights',
    description: 'Video replays of kills and objectives',
    height: 'tall',
    direction: 'right',
    position: 'top',
    positionOffset: 90,
  },
  {
    hintContainer: '#stage-more-streams-button',
    label: 'Main streams',
    description: 'Access the event and map overview',
    height: 'short',
    direction: 'left',
    position: 'top',
    positionOffset: 35,
  },
  {
    hintContainer: '#stage-stats-button',
    label: 'Statistics',
    description: 'Keep track of team and player stats',
    height: 'xtall',
    direction: 'left',
    position: 'top',
    positionOffset: 90,
  },
  {
    hintContainer: '#predictions-button',
    label: 'Predictions',
    description: 'make predicions on game events',
    height: 'tall',
    direction: 'left',
    position: 'top',
    positionOffset: 90,
  },
  {
    hintContainer: '#stage-chat-button',
    label: 'Chat',
    description: 'Cheer and banter with others in the crowd!',
    height: 'xtall',
    direction: 'right',
    position: 'top',
    positionOffset: 90,
  },
  {
    hintContainer: '.free-pov-selector',
    label: 'Pro view povs',
    description: 'Change or add player POVs',
    height: 'short',
    direction: 'left',
    position: 'top',
    positionOffset: 105,
  },
];

export const laptopHints = [
  {
    hintContainer: '#stage-stats-button',
    label: 'Player stats',
    height: 'xshort',
    direction: 'right',
    position: 'top',
    positionOffset: 80,
  },
  {
    hintContainer: '#stage-stream-selector',
    label: 'Change streams',
    height: 'xshort',
    direction: 'left',
    position: 'top',
    positionOffset: 100,
  },
];

export const mobileHints = [
  {
    hintContainer: '#stage-stats-button',
    label: 'Player stats',
    height: 'xshort',
    direction: 'right',
    position: 'bottom',
    positionOffset: 15,
  },
  {
    hintContainer: '#stage-stream-selector',
    label: 'Change streams',
    height: 'xshort',
    direction: 'left',
    position: 'bottom',
    positionOffset: 1,
  },
];

export const multiPovPopupInfo = {
  popupId: 'multi-pov-popup',
  headline: 'Multi-POV',
  description:
    'Hey, don’t forget that you can click on a player to customize your multi-POV setup.',
  timer: 60000,
};

export const highlightPopupInfo = {
  popupId: 'highlights-popup',
  headline: 'Highlights',
  description: 'Missed something? Remember that you can use the highlights to get up to speed.',
  timer: 180000,
};
export const statsPopupInfo = {
  popupId: 'stats-popup',
  headline: 'Stats',
  description: ' Want more info? Open up the stats to get a better overview of the match!',
  timer: 300000,
};

export const globalStreamsPopupInfo = {
  popupId: 'global-streams-popup',
  headline: 'Streams',
  description: 'Keep in mind that you can customize your setup with the map and event stream.',
  timer: 120000,
};

export const predictionsPopupInfo = {
  popupId: 'predictions-popup',
  headline: 'Predictions',
  description: 'Predict on game events.',
  timer: 300000,
};

export const HIGHLIGHTLIST_MAP = {
  lol: loadable(() => import('tv-modules/GAT/HighlightList/LOL/HighlightList')),
  csgo: loadable(() => import('tv-modules/GAT/HighlightList/CSGO/HighlightList')),
  default: LoadingSpinner,
};

export const pageWelcomeSubHeading = {
  default: 'Get to know the stage before diving in.',
  faceit: 'Get to know the Watch arena before diving in.',
  proview: 'Get to know the Pro View esports arena before diving in.',
};
