import colors from 'znipe-styles/colors';
import { getThemableGradients } from 'znipe-styles/gradients';
import defaultTheme from './default.theme';
import { Theme } from './theme.types';

const theme: Theme = {
  ...defaultTheme,
  themeName: 'esportal',
  mobileOnly: false,
  primaryFont: "'Titillium Web', sans-serif",
  secondaryFont: "'Roboto', sans-serif",

  // Background Colors
  primaryBackgroundColor: colors.esportalBackgroundPrimary,
  secondaryBackgroundColor: colors.esportalBackgroundSecondary,
  tertiaryBackgroundColor: colors.esportalBackgroundPrimary,
  containerStroke: colors.esportalContainerStroke,
  highlight: colors.esportalIconBlue,

  // Accents
  UIAccent: colors.slimeGreen,
  teamOneColor: colors.CTBlue,
  teamTwoColor: colors.TYellow,

  buttonPrimary: colors.slimeGreen,

  // Gradients
  backgroundAvsB: colors.esportalBackgroundSecondary,

  // TODO cleanup
  bodyBackground: colors.esportalBackgroundPrimary,
  bodyColor: colors.white,
  borderColor: colors.esportalContainerStroke,
  csgoTColor: colors.TYellow,
  csgoCTColor: colors.CTBlue,
  overlayBackground: 'rgba(0,0,0,0.75)',
  popOverBackground: colors.esportalContainerStroke,

  // avatar styles
  avatarXSmallSize: '30px',
  avatarSmallSize: '40px',
  avatarSmallSizeRound: '34px',
  avatarMediumSize: '50px',
  avatarLargeSize: '60px',
  avatarCustomSize: '45px',
  avatarBackgroundColor: colors.onyxGrey,
  avatarLevelTextColor: colors.riotAccentGold,
  avatarLevelFont: "'Roboto', sans-serif",
  avatarLevelCircleBorderColor: colors.black,
  avatarLevelCircleBackgroundColor: colors.riotBackgroundSecondary,

  // a11y colors
  grey3: colors.grey75,
};

export default { ...theme, ...getThemableGradients(theme) } as Theme; // @TODO remove type assertion once getThemableGradients has proper type
