import { useMemo } from 'react';
import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import useQueryObject from 'tv-hooks/useQueryObject';
import makeGetMatch from './makeGetMatch';
import makeGetProps from '../makeGetProps';
import makeGetMatchStatus from './makeGetMatchStatus';
import makeGetMatchGamesIds from './makeGetMatchGamesIds';

const makeGetSelectedMatchGameIndex = () => {
  const getProps = makeGetProps();
  const getSelectedMatch = makeGetMatch();
  const getSelectedMatchStatus = makeGetMatchStatus();
  const getMatchGamesIds = makeGetMatchGamesIds();

  const getMatchGames = (state, props) => {
    const gameIds = getMatchGamesIds(state, props);
    return gameIds.map(id => state.games?.[id]).filter(Boolean);
  };

  return createSelector(
    [getProps, getSelectedMatch, getSelectedMatchStatus, getMatchGames],
    (props, selectedMatch, matchStatus, matchGames) => {
      const { selectedMatchGame, selectedMatchGameIndex } = props;
      const selectedMatchGameProp = selectedMatchGame ?? selectedMatchGameIndex;
      if (
        typeof selectedMatchGameProp !== 'undefined' &&
        !isNaN(parseInt(selectedMatchGameProp, 10))
      ) {
        return Number(selectedMatchGameProp);
      }

      const selectedMatchFromRedux = selectedMatch.selectedMatchGame;
      if (typeof selectedMatchFromRedux === 'number') return selectedMatchFromRedux;

      const availableGames = matchGames.filter(
        game =>
          (game.status || '').toLowerCase() !== 'upcoming' &&
          (game.status || '').toLowerCase() !== 'not_played',
      );

      const defaultSelectedGame =
        matchStatus === 'live' ? Object.keys(availableGames).length - 1 : 0;
      return defaultSelectedGame;
    },
  );
};
export default makeGetSelectedMatchGameIndex;

export const useGetSelectedMatchGameIndex = props => {
  // This is a way of getting data from the regular store into the gql store selector
  const { g } = useQueryObject();
  const selectedMatchGameFromURL = g ? parseInt(g, 10) : undefined;

  const newProps = useMemo(() => {
    const copy = { ...props };
    copy.selectedMatchGameIndex = copy.selectedMatchGameIndex ?? selectedMatchGameFromURL;
    return copy;
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [props, selectedMatchGameFromURL]);

  return useMemoizedGqlSelector(makeGetSelectedMatchGameIndex, newProps);
};
