import { useCallback, useMemo } from 'react';
import { useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import PlayerImage from 'znipe-elements/data-display/PlayerImage/PlayerImage';
import Typography from 'znipe-elements/general/Typography/Typography';
import useCreateResizeText from 'znipe-hooks/useCreateResizeText';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetPlayerName } from 'tv-selectors/players/makeGetPlayerName';
import { PlayerContainer, PlayerName, SizeContainer, sizes } from '../EliminatedInfo.styles';
import { sizesShape } from '../../../constants';

const PlayerSection = ({ playerId, size, type, show, onAnimationEnd }) => {
  const onRest = useCallback(() => {
    if (show) onAnimationEnd();
  }, [show, onAnimationEnd]);

  const startPos = type === 'left' ? -100 : 100;
  const spring = useSpring({
    x: show ? 0 : startPos,
    opacity: show ? 1 : 0,
    immediate: !show,
    onRest,
  });

  const props = useMemo(() => ({ playerId }), [playerId]);
  const name = useGetPlayerName(props);
  const image = useGetPlayerImage(props);
  const teamId = useGetPlayerTeamId(props);
  const teamLogo = useGetSelectedTeamLogo({ teamId });

  const resize = useCreateResizeText({ minSize: 6, maxSize: sizes[size].nameSize });
  return (
    <PlayerContainer style={spring} data-testid="player-section">
      <SizeContainer $size={size} $type={type}>
        <PlayerImage image={image} teamLogo={teamLogo} type={type} />
      </SizeContainer>
      <PlayerName $type={type} $size={size}>
        <Typography ref={resize} type={sizes[size].nameType}>
          {name}
        </Typography>
      </PlayerName>
    </PlayerContainer>
  );
};

PlayerSection.propTypes = {
  playerId: PropTypes.string,
  size: sizesShape.isRequired,
  type: PropTypes.oneOf(['left', 'right']).isRequired,
  show: PropTypes.bool.isRequired,
  onAnimationEnd: PropTypes.func.isRequired,
};

export default PlayerSection;
