import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { ROUND_LOADOUT_EVENT } from 'tv-reducers/intensive';
import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';

export const SUBSCRIBE_ROUND_LOADOUT = 'SUBSCRIBE_ROUND_LOADOUT';
export const UNSUBSCRIBE_ROUND_LOADOUT = 'UNSUBSCRIBE_ROUND_LOADOUT';

export const useRoundLoadoutSubscription = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_ROUND_LOADOUT, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_ROUND_LOADOUT, gatId });
  }, [dispatch, gatId]);
};

export const getPlayers = payload =>
  payload?.reduce(
    (acc, { loadouts }) =>
      loadouts.reduce((loadoutAcc, { actor }) => {
        const playerObject = createPlayerObject(actor);
        return {
          ...loadoutAcc,
          [playerObject.id]: playerObject,
        };
      }, acc),
    [],
  );

const roundLoadoutEpic = factorySubscribeEpic(
  [SUBSCRIBE_ROUND_LOADOUT, UNSUBSCRIBE_ROUND_LOADOUT],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('type').equalTo('LoadoutEvent'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const players = getPlayers(payload);
      return {
        type: ROUND_LOADOUT_EVENT,
        gatId,
        payload: { id: gatId, rounds: payload, players },
      };
    },
);

export default roundLoadoutEpic;
