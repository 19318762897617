import { schema } from 'normalizr';
import deepmerge from 'deepmerge';
import loadout from './loadout.normalizr';
import kill from './kill.normalizr';
import assist from './assist.normalizr';
import stat, { createStatsObject } from './stat.normalizr';
import { createPlayerObject } from './player.normalizr';

export const calcStartTime = (val1, val2) => {
  if (typeof val1 !== 'number') return val2;
  if (typeof val2 !== 'number') return val1;
  return Math.min(val1, val2);
};

export const calcEndTime = (val1, val2) => {
  if (typeof val1 !== 'number') return val2;
  if (typeof val2 !== 'number') return val1;
  return Math.max(val1, val2);
};

const roundsMergeStrategy = (
  { startTime: stA, endTime: etA, ...entityA },
  { startTime: stB, endTime: etB, ...entityB },
) => ({
  ...entityA,
  ...entityB,
  startTime: calcStartTime(stA, stB),
  endTime: calcEndTime(etA, etB),
});

const createDamageSummaryStats = (gameId, roundValue, damageSummary) => {
  if ((damageSummary?.length ?? 0) < 1) return {};
  return damageSummary.reduce((acc, stats) => {
    const { ingameTeam, weaponDamage, headshotDamage, damageArmor, utilityDamage } = stats;
    const statsToAdd = { weaponDamage, headshotDamage, damageArmor, utilityDamage, ingameTeam };
    const statsObject = createStatsObject(gameId, roundValue, statsToAdd, stats);
    return {
      ...acc,
      [statsObject.id]: statsObject,
    };
  }, {});
};

const createKillsSummaryStats = (gameId, roundValue, kills, deaths = []) => {
  if ((kills?.length ?? 0) < 1) return [{}, {}];

  const killsStatsSummary = kills.reduce(
    (acc, currentKill) => {
      const [statsAcc, killsAcc] = acc;
      const { actor, subject } = currentKill;
      const killStats = createStatsObject(
        gameId,
        roundValue,
        {
          ingameTeam: actor.ingameTeam,
          kills: 1,
          deaths: 0,
          deathTime: -1,
          headshots: currentKill.headshot ? 1 : 0,
        },
        actor,
      );

      const extraFields = ['location'];
      const actorObject = createPlayerObject(actor, extraFields);
      const subjectObject = createPlayerObject(subject, extraFields);

      const newKill = {
        ...currentKill,
        actor: actorObject,
        subject: subjectObject,
        gameTime: currentKill.gameTime / 1000,
        id: `${gameId}:${roundValue}:${actorObject.id}:${subjectObject.id}`,
      };
      if (statsAcc[killStats.id]) {
        killStats.kills += statsAcc[killStats.id].kills;
        killStats.headshots += statsAcc[killStats.id].headshots;
      }

      return [
        { ...statsAcc, [killStats.id]: killStats },
        { ...killsAcc, [newKill.id]: newKill },
      ];
    },
    [{}, {}],
  );
  deaths.forEach(({ gameTime, ...subject }) => {
    const deathStats = createStatsObject(
      gameId,
      roundValue,
      {
        ingameTeam: subject.ingameTeam,
        deaths: 1,
        kills: 0,
      },
      subject,
    );
    const { id } = deathStats;

    if (!killsStatsSummary[0][id]) killsStatsSummary[0][id] = deathStats;
    else killsStatsSummary[0][id].deaths += 1;
    killsStatsSummary[0][id].deathTime = gameTime;
  });

  return killsStatsSummary;
};

const createKillsAssistsSummaryStats = (gameId, roundValue, assists) => {
  if (!assists || !Array.isArray(assists) || assists.length < 1) return [{}, {}];

  return assists.reduce(
    (acc, currentAssist) => {
      const [statsAcc, assistsAcc] = acc;
      const { actor, subject } = currentAssist;

      const assistStats = createStatsObject(
        gameId,
        roundValue,
        {
          ingameTeam: actor.ingameTeam,
          assists: 1,
        },
        actor,
      );

      const actorObject = createPlayerObject(actor);
      const subjectObject = createPlayerObject(subject);
      const newAssist = {
        ...currentAssist,
        actor: actorObject,
        subject: subjectObject,
        gameTime: currentAssist.gameTime / 1000,
        id: `${gameId}:${roundValue}:${actorObject.id}:${subjectObject.id}`,
      };
      if (statsAcc[assistStats.id]) assistStats.assists += statsAcc[assistStats.id].assists;

      return [
        { ...statsAcc, [assistStats.id]: assistStats },
        { ...assistsAcc, [newAssist.id]: newAssist },
      ];
    },
    [{}, {}],
  );
};

const createRoundEndStatsObject = (gameId, roundValue, roundEndSummary) => {
  if ((roundEndSummary?.players?.length ?? 0) < 1) return {};
  const { players, winCondition, scoreT, scoreCT, winningTeam, winningTeamId } = roundEndSummary;
  const roundEndSummaryRound = { winCondition, scoreCT, scoreT, winningTeam, winningTeamId };

  const roundEndSummaryStats = players.reduce((acc, currentPlayer) => {
    const stats = createStatsObject(
      gameId,
      roundValue,
      {
        ingameTeam: currentPlayer.ingameTeam,
        alive: currentPlayer.alive,
      },
      currentPlayer,
    );

    return {
      ...acc,
      [stats.id]: stats,
    };
  }, {});
  return { roundEndSummaryStats, roundEndSummaryRound };
};

const createLoadoutsObject = (gameId, roundValue, loadouts) => {
  if ((loadouts?.length ?? 0) < 1) return {};
  const playerLoadouts = loadouts.reduce((acc, { actor, loadout: currentLoadout }) => {
    const playerObject = createPlayerObject(actor);
    const id = `${gameId}:${roundValue}:${playerObject.id}`;
    return {
      ...acc,
      [id]: {
        ...currentLoadout.reduce(
          (prev, equipment) => ({ ...prev, [equipment]: (prev[equipment] ?? 0) + 1 }),
          {},
        ),
        id,
        player: playerObject,
        ingameTeam: actor.ingameTeam,
      },
    };
  }, {});
  const teamLoadouts = Object.values(playerLoadouts).reduce((acc, currentLoadout) => {
    const { ingameTeam, ...playerLoadout } = currentLoadout;
    const accLoadout = acc[ingameTeam] ?? {};
    const newLoadout = Object.entries(playerLoadout).reduce((res, [equipment, amount]) => {
      if (equipment === 'id' || equipment === 'player' || equipment === 'ingameTeam') return res;
      const prevAmount = res[equipment] ?? 0;
      return { ...res, [equipment]: prevAmount + amount };
    }, accLoadout);

    const id = `${gameId}:${roundValue}:${ingameTeam}`;
    return {
      ...acc,
      [ingameTeam]: {
        ...newLoadout,
        id,
        ingameTeam,
      },
    };
  }, {});

  return { playerLoadouts, teamLoadouts };
};

const generateRoundId = ({ round: roundValue }, { id }) => `${id}:${roundValue}`;
const round = new schema.Entity(
  'rounds',
  {
    kills: [kill],
    assists: [assist],
    loadouts: [loadout],
    teamLoadouts: [loadout],
    stats: [stat],
  },
  {
    idAttribute: generateRoundId,
    processStrategy: (values, parent) => {
      const { gameId, round: roundValue, gameTime, startTime, endTime } = values;
      const damageSummaryStats = createDamageSummaryStats(gameId, roundValue, values.damageSummary);
      const [killsSummaryStats, killsSummary] = createKillsSummaryStats(
        gameId,
        roundValue,
        values.kills,
        values.deaths,
      );
      const [assistsSummaryStats, assistsSummary] = createKillsAssistsSummaryStats(
        gameId,
        roundValue,
        values.assists,
      );

      const { playerLoadouts = {}, teamLoadouts = {} } = createLoadoutsObject(
        gameId,
        roundValue,
        values.loadouts,
      );

      const { roundEndSummaryStats = {}, roundEndSummaryRound = {} } = createRoundEndStatsObject(
        gameId,
        roundValue,
        values.roundEndSummary,
      );

      return {
        ...roundEndSummaryRound,
        id: generateRoundId(values, parent),
        round: roundValue,
        loadouts: playerLoadouts,
        teamLoadouts,
        stats: deepmerge.all([
          damageSummaryStats,
          killsSummaryStats,
          assistsSummaryStats,
          roundEndSummaryStats,
        ]),
        kills: killsSummary,
        assists: assistsSummary,
        startTime: (startTime ?? gameTime) / 1000,
        endTime: (endTime ?? gameTime) / 1000,
      };
    },
    mergeStrategy: roundsMergeStrategy,
  },
);

export default round;
