import { memo } from 'react';
import PropTypes from 'prop-types';
import LogoCard from 'tv-elements/data-display/LogoCard/LogoCard';
import TopicLink from 'tv-elements/data-display/TopicLink/TopicLink';
import Typography from 'znipe-elements/general/Typography/Typography';
import Link from 'znipe-elements/general/Link/Link';
import useTopicLink from 'tv-hooks/useTopicLink';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { Card, TextContent } from './TeamDetailsCard.styles';

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';

const TeamDetailsCard = ({
  teamName,
  country,
  image,
  children: textContent,
  size = SMALL,
  gameTitle,
}) => {
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const link = useTopicLink(teamName, 'team', gameTitle);
  if (size === SMALL) {
    return (
      <Link to={link} hideUnderline>
        <Card $size={size}>
          <TopicLink title={teamName} subtitle={country} image={image} size="small" type="team" />
          {textContent && (
            <TextContent data-testid="text-content">
              <Typography type="paragraph-m">{textContent}</Typography>
            </TextContent>
          )}
        </Card>
      </Link>
    );
  }

  return (
    <Link to={link} hideUnderline>
      <Card $size={size}>
        <LogoCard
          text={teamName}
          imageSrc={image}
          size={isDesktopLargeOrGreater ? 'small' : 'xsmall'}
        />
        {textContent && (
          <TextContent data-testid="text-content">
            <Typography type="paragraph-m">{textContent}</Typography>
          </TextContent>
        )}
      </Card>
    </Link>
  );
};

TeamDetailsCard.propTypes = {
  children: PropTypes.node,
  teamName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  gameTitle: PropTypes.string.isRequired,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
};

export default memo(TeamDetailsCard);
