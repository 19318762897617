import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import EnergyStream from './Znipe_Energy_Streams.gif';
import EnergyBurst from './Znipe_Energy_Burst.gif';

const GifImage = styled.img`
  position: absolute;
  z-index: ${props => props.zIndex};
  opacity: 0.5;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${props =>
    props.explodeAnimationWidth &&
    css`
      max-width: ${props.explodeAnimationWidth};
      margin: auto;
    `}
`;

const TIMEOUT_TIMER = 1200;

const EnergyExplodeAnimation = ({ explodeAnimationWidth = '' }) => {
  const [explodeGif, setExplodeGif] = useState(EnergyBurst);

  useEffect(() => {
    const timeout = setTimeout(() => setExplodeGif(null), TIMEOUT_TIMER);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container explodeAnimationWidth={explodeAnimationWidth}>
      <GifImage
        data-testid="energy-stream-image"
        src={EnergyStream}
        alt="Energy stream animation"
        zIndex={3}
      />
      {explodeGif && (
        <GifImage
          data-testid="energy-explode-image"
          zIndex={1}
          src={explodeGif}
          alt="energy explode animation"
        />
      )}
    </Container>
  );
};

EnergyExplodeAnimation.propTypes = {
  explodeAnimationWidth: PropTypes.string,
};

export default EnergyExplodeAnimation;
