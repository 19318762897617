export const playerProfileItems = (
  playerName,
  playerCountry,
  playerBirthday,
  playerRoles,
  activeDates,
) => [
  {
    title: 'Name',
    icon: 'contactCard',
    text: playerName,
  },
  {
    title: 'Active',
    icon: 'clockFilled',
    text: activeDates,
  },
  {
    title: 'Nationality',
    icon: 'globeFilled',
    text: playerCountry,
  },
  {
    title: 'Birthday',
    icon: 'birthday',
    text: playerBirthday,
  },
  {
    title: 'Roles',
    icon: 'crosshair',
    text: playerRoles,
  },
];

export const teamProfileItems = (teamOwner, teamCountry, teamFounded) => [
  {
    title: 'Owner',
    icon: 'contactCard',
    text: teamOwner,
  },
  {
    title: 'Region',
    icon: 'globeFilled',
    text: teamCountry,
  },
  {
    title: 'Founded',
    icon: 'birthday',
    text: teamFounded,
  },
];

export const tournamentProfileItems = (dates, location) => [
  {
    title: 'start & end date',
    icon: 'calender',
    text: dates,
  },
  {
    title: 'Location',
    icon: 'globeFilled',
    text: location,
  },
];

export const championProfileItems = classType => [
  {
    title: 'class',
    icon: `${classType.toLowerCase()}Class`,
    text: classType,
  },
];
