import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'tv-hooks/useTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, TextWrapper } from './NoResults.styles';
import {
  SMALL,
  MEDIUM,
  LARGE,
  NONE,
  menuTypes,
  textContent,
  menuTypesWithTopics,
  topicTypes,
  topicSecondaryText,
} from './constants';

const NoResults = ({ type = NONE, size = MEDIUM, alignCenter = false, padding, topic }) => {
  const { secondaryTextColor } = useTheme();

  const text = useMemo(() => {
    if (menuTypesWithTopics.indexOf(type) !== -1 && topic) {
      const { primary } = textContent[type];
      const textReplacement = topicTypes.indexOf(topic) !== -1 ? topic : '';
      return {
        primary,
        secondary: topicSecondaryText[type].replace('[TOPIC]', textReplacement),
      };
    }
    return menuTypes.indexOf(type) === -1 ? textContent[NONE] : textContent[type];
  }, [type, topic]);

  const textSize = useMemo(() => {
    if (size === SMALL) return { primary: 'heading-s', secondary: 'paragraph-m' };
    if (size === MEDIUM) return { primary: 'heading-m', secondary: 'paragraph-m' };
    return { primary: 'heading-l', secondary: 'paragraph-l' };
  }, [size]);
  return (
    <Container size={size} alignCenter={alignCenter} $padding={padding} data-testid="container">
      <TextWrapper data-testid="primary-text" alignCenter={alignCenter} isTitle>
        <Typography type={textSize.primary}>{text.primary}</Typography>
      </TextWrapper>
      <TextWrapper data-testid="secondary-text" alignCenter={alignCenter} w>
        <Typography type={textSize.secondary} color={secondaryTextColor}>
          {text.secondary}
        </Typography>
      </TextWrapper>
    </Container>
  );
};

NoResults.propTypes = {
  type: PropTypes.oneOf(menuTypes),
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  alignCenter: PropTypes.bool,
  topic: PropTypes.oneOf(topicTypes),
  padding: PropTypes.string,
};

export default memo(NoResults);
