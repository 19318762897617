import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from './makeGetMatch';

const makeGetMatchBestOf = () => {
  const getMatch = makeGetMatch();
  return createSelector([getMatch], match => match?.bestOf || 1);
};

export const useGetMatchBestOf = props => useMemoizedGqlSelector(makeGetMatchBestOf, props);

export default makeGetMatchBestOf;
