import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSubscribedPlayersIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedPlayersIds';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';

const emptyArray = [];

const makeGetSubscribedPlayers = () => {
  const getPlayers = makeGetPlayers();
  const getSubscribedPlayers = makeGetSubscribedPlayersIds();
  return createSelector([getPlayers, getSubscribedPlayers], (allPlayers, subscribedPlayers) => {
    if (subscribedPlayers.length === 0) return emptyArray;
    return subscribedPlayers.map(id => ({ ...allPlayers?.[id], _type: 'players' }));
  });
};

export const useGetSubscribedPlayers = props =>
  useMemoizedGqlSelector(makeGetSubscribedPlayers, props);

export default makeGetSubscribedPlayers;
