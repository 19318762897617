import { useEffect, useMemo } from 'react';
import { fromEvent } from 'rxjs';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import { throttleTime, filter } from 'rxjs/operators';
import useGatId from 'tv-hooks/useGatId';
import { GAT_TIME_INFO_EVENT } from 'tv-reducers/intensive';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import { useSubscribeGatOffset } from 'tv-epics/gatOffsetEpic';
import makeGetMatchGameId from 'tv-selectors/games/makeGetGameId';
import { createRoundId } from 'tv-schema/csgoStats/round.normalizr';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import getTimeSeriesInfo from './utils/getTimeSeriesInfo';

export const SUBSCRIBE_GAME_INFO = 'SUBSCRIBE_GAME_INFO';
export const UNSUBSCRIBE_GAME_INFO = 'UNSUBSCRIBE_GAME_INFO';

export const useGameTimeInfoEpic = (playerRef, matchId, selectedMatchGameIndex) => {
  const gatId = useGatId(matchId);
  const getSelectedMatchGameId = useMemo(() => makeGetMatchGameId(), []);
  const gameId = useGqlSelector(state =>
    getSelectedMatchGameId(state, { matchId, selectedMatchGameIndex }),
  );
  useSubscribeGatOffset(matchId, gameId);

  const dispatch = useIntensiveDispatch();

  useEffect(() => {
    // We are going to move over to use the gameId instead of gatId for everything later, but ned to keep both for now
    if (!gatId && !gameId) return undefined;
    dispatch({ type: SUBSCRIBE_GAME_INFO, player: playerRef.current, gatId: gatId || gameId });
    return () => dispatch({ type: UNSUBSCRIBE_GAME_INFO, gatId: gatId || gameId });
  }, [dispatch, gatId, gameId, playerRef]);
};

const emptyObject = {};

const gameTimeInfoEpic = factorySubscribeEpic(
  [SUBSCRIBE_GAME_INFO, UNSUBSCRIBE_GAME_INFO],
  ({ gatId }) => gatId,
  ({ player, gatId }, state$) =>
    fromEvent(player, 'timeupdate').pipe(
      throttleTime(1000),
      filter(() => typeof state$.value.gats[gatId]?.offset === 'number'),
    ),
  ({ gatId }, state$) =>
    payload => {
      const [timeInfo] = payload;
      const state = state$.value;
      const {
        offset = 0,
        rounds = [],
        matchSetupDuration = 0,
        pauses = [],
        gameTitle,
      } = state.gats[gatId] ?? {};
      const { currentTime = 0 } = timeInfo;
      let gameTime = currentTime - offset - matchSetupDuration;

      // Lol does not change gameTime during pauses so we need to do some hacking here.
      if (gameTitle === 'lol') {
        // Use regular for loop to be able to break in the middle
        for (let i = 0; i < pauses.length; i++) {
          const id = pauses[i];
          const pause = state.pauses?.[id];
          // The first pause in lol is there for the production to be setup, It will be added to match setup duration offset
          if (pause.startTime <= 3) continue;
          const { startTime = 0, duration = Infinity } = pause;
          if (startTime > gameTime) break; // Pause hasn't started
          // After a pause
          if (startTime + duration <= gameTime) gameTime -= duration;
          // During a pause
          else {
            gameTime = startTime;
            break;
          }
        }
      }

      const roundNumberSort = (a, b) => a.roundNumber - b.roundNumber;
      const sortedRounds = rounds.map(id => state.rounds[id]).sort(roundNumberSort);
      const potentialRounds = sortedRounds.reduce((acc, round) => {
        if (!round) return acc;
        const { startTime = -1, endTime = Infinity } = round;
        if (gameTime >= startTime && gameTime <= endTime) acc.push(round);
        return acc;
      }, []);

      const lastRound = sortedRounds[sortedRounds.length - 1];

      const round = potentialRounds[potentialRounds.length - 1] ?? {
        id: createRoundId({ roundNumber: (lastRound?.roundNumber ?? 0) + 1 }, { gameId: gatId }),
        roundNumber: (lastRound?.roundNumber ?? 0) + 1,
      };

      const { id: timeSeriesEntry } = getTimeSeriesInfo(gatId, gameTime);
      const action = {
        type: GAT_TIME_INFO_EVENT,
        gatId,
        gameTime,
      };
      if (round) {
        action.round = round.id;
      }
      if (timeSeriesEntry) {
        const { timeSeriesEntries = emptyObject } = state$.value;
        if (timeSeriesEntries[timeSeriesEntry]) {
          action.timeSeriesEntry = timeSeriesEntry;
        } else if (gameTime > 0) {
          const [lastEntry] = Object.keys(timeSeriesEntries).slice(-1);
          action.timeSeriesEntry = lastEntry;
        } else {
          const { id: firstEntry } = getTimeSeriesInfo(gatId, 0);
          action.timeSeriesEntry = firstEntry;
        }
      }
      return action;
    },
);
export default gameTimeInfoEpic;
