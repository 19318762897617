import { memo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Slider from 'znipe-elements/data-entry/Slider/Slider';
import ControlButton from 'znipe-watch/src/modules/PlayerControls/ControlButtons/ControlButton/ControlButton';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import useAudioContext from 'tv-hooks/useAudioContext';
import { Container, SliderWrapper } from './SoundButton.styles';

const noop = () => {};

const SoundButton = ({ matchId, playerRef = playerDefaultProps }) => {
  const timer = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [onClient, setOnClient] = useState(false);

  const { toggleMute, setVolume, volume, mute } = useAudioContext(playerRef, matchId, !isHovering);

  const handleMouseOver = useCallback(() => {
    clearTimeout(timer.current);
    setIsHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timer.current = setTimeout(() => setIsHovering(false), 1000);
  }, []);

  useEffect(() => {
    setOnClient(true);
  }, []);

  return (
    <Container
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      data-testid="sound-container"
    >
      {onClient && (
        <>
          <ControlButton
            toolTip={mute ? 'Unmute' : 'Mute'}
            icon={mute ? 'mute' : 'sound'}
            onClick={toggleMute}
          />
          <SliderWrapper
            visible={isHovering}
            onMouseOver={handleMouseOver}
            onFocus={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            data-testid="volume-slider"
          >
            <Slider
              type="vertical"
              max={1}
              onChange={isHovering ? setVolume : noop}
              initialValue={mute ? 0 : volume}
              value={mute ? 0 : volume}
              size="medium"
              showBar
            />
          </SliderWrapper>
        </>
      )}
    </Container>
  );
};

SoundButton.propTypes = {
  matchId: PropTypes.string.isRequired,
  playerRef: playerPropTypes,
};

export default memo(SoundButton);
