import { EMOJI_TAG_REGEX, REPLY_REGEX_ONLY } from '../ChatLockup.constants';
import { StringTypes } from '../ChatLockup.types';

export const isEmojiEntry = (entry: StringTypes) => {
  const maybeEmoji = Array.isArray(entry) ? entry[entry.length - 1] : entry;
  if (!maybeEmoji) return false;
  return maybeEmoji === maybeEmoji.match(EMOJI_TAG_REGEX)?.[0];
};

export const isReplyEntry = (entry: StringTypes) => {
  const maybeReply = Array.isArray(entry) ? entry[entry.length - 1] : entry;
  if (!maybeReply) return false;
  return maybeReply === maybeReply.match(REPLY_REGEX_ONLY)?.[0];
};

export const isEitherReplyOrEmoji = (entry: StringTypes) =>
  isEmojiEntry(entry) || isReplyEntry(entry);
