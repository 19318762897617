import styled from 'styled-components';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';

export const MenuWrapper = styled.div`
  position: relative;
`;

export const Modal = styled.div`
  width: calc(100vw - 16px);
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.containerStroke};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  padding: 10px 8px 10px 16px;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const ModalContent = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.containerStroke};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    `rgba(${convertHexToRGBDigits(theme.primaryBackgroundColor)}, 0.7)`};
  @media (min-width: 576px) {
    ${Modal} {
      width: 560px;
      margin: auto;
    }
  }
`;
