import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import { OuterContianer, Container } from './MatchItem.styles';

const XSMALL = 'xsmall';
const SMALL = 'small';
const MEDIUM = 'medium';

const MatchItem = ({ size = SMALL }) => {
  const { lineOneWidth, lineTwoWidth } = useMemo(() => {
    if (size === SMALL || size === XSMALL) {
      return {
        lineOneWidth: 110,
        lineTwoWidth: 70,
      };
    }
    return {
      lineOneWidth: 160,
      lineTwoWidth: 120,
    };
  }, [size]);

  return (
    <OuterContianer data-testid="match-item">
      <Container data-testid="match-item-container-loading" size={size} $isLoading>
        <TextSkeleton width={lineOneWidth} size={size} />
        <TextSkeleton width={lineTwoWidth} size={size} />
      </Container>
    </OuterContianer>
  );
};

MatchItem.propTypes = {
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM]),
};

export default memo(MatchItem);
