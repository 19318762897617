import { createSelectorCreator } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlaylistHighlights from 'tv-selectors/playlists/makeGetPlaylistHighlights';
import makeGetAllVideos from 'tv-selectors/video/makeGetAllVideos';
import strictMemoize from 'tv-utils/strictMemoize';
import { shallowEqual } from 'react-redux';

const getVideoId = (_, props) => props?.videoId ?? 0;

const emptyArray = [];

const createSelector = createSelectorCreator(
  strictMemoize,
  shallowEqual,
  () => true,
  (a, b) => a === b,
);

const makeGetPlaylistHighlightVideos = () => {
  const getPlaylistHighlights = makeGetPlaylistHighlights();
  const getVideos = makeGetAllVideos();
  return createSelector(
    [getPlaylistHighlights, getVideos, getVideoId],
    (playlistHighlights, allVideos, videoId) => {
      if (!videoId || !Object.keys(playlistHighlights)?.length) return emptyArray;
      return playlistHighlights.reduce((result = [], id = '') => {
        if (id === videoId || !allVideos[id]) {
          return result;
        }
        result.push(allVideos[id]);
        return result;
      }, []);
    },
  );
};

export const useGetPlaylistHighlightVideos = props =>
  useMemoizedGqlSelector(makeGetPlaylistHighlightVideos, props);

export default makeGetPlaylistHighlightVideos;
