import { useMemo } from 'react';
import PropTypes from 'prop-types';
import HorizontalNavButton from './HorizontalNavButton/HorizontalNavButton';
import RoundNavButton from './RoundNavButton/RoundNavButton';

const NavButton = ({
  id = '',
  label = '',
  iconType,
  isToggledOn = false,
  showLabel = false,
  onClick,
  testid = '',
  size = 'medium',
  type = 'round',
}) => {
  const Component = useMemo(
    () => (type === 'horizontal' ? HorizontalNavButton : RoundNavButton),
    [type],
  );
  return (
    <Component
      id={id}
      label={label}
      iconType={iconType}
      isToggledOn={isToggledOn}
      showLabel={showLabel}
      onClick={onClick}
      testid={testid}
      size={size}
    />
  );
};

NavButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isToggledOn: PropTypes.bool,
  showLabel: PropTypes.bool,
  testid: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
  type: PropTypes.oneOf(['round', 'horizontal']),
};

export default NavButton;
