import {
  mergeUserDataWithToken,
  setOnboarding,
  updateAuthInStore,
} from 'tv-utils/firebaseAuthHelper';
import { logoutUser, setAuth } from 'tv-actions/old/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

const setupFirebaseLoginHandler = store => {
  if (firebase.apps.length === 0) return;

  const storeState = store.getState();
  const { onboarding } = storeState.auth || {};
  const userUIInfo = { onboarding };

  let userRef = null;
  firebase.auth().onIdTokenChanged(async user => {
    if (user) {
      userRef = firebase.database().ref(`/users`).child(user.uid);
      userRef.off();

      if (onboarding && Object.keys(onboarding).length !== 0) setOnboarding(user, onboarding);

      // We still need this for all users that isn't lg
      userRef.on('value', snap => {
        const userData = snap.val();
        mergeUserDataWithToken(user, userData)
          .then(result => {
            updateAuthInStore(store.dispatch, result);
          })
          .catch(() => {
            store.dispatch(setAuth({ ...userUIInfo, token: null, isFetching: false }));
            store.dispatch(logoutUser(userUIInfo));
          });
      });
    } else {
      const auth = store.getState().auth ?? {};
      // Logout in redux
      if (auth.isAuthenticated) {
        store.dispatch(
          setAuth({ ...userUIInfo, token: null, isFetching: false, isAuthenticated: false }),
        );
        store.dispatch(logoutUser(userUIInfo));
      }
      if (userRef) userRef.off();
    }
  });
};

export default setupFirebaseLoginHandler;
