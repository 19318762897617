import { memo } from 'react';
import PropTypes from 'prop-types';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';

const StatsButton = ({ onClick, active, iconType = 'stats', size, showLabel = true }) => (
  <NavButton
    id="stage-stats-button"
    label="Stats"
    iconType={iconType}
    onClick={onClick}
    isToggledOn={active}
    size={size}
    showLabel={showLabel}
  />
);

StatsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  iconType: PropTypes.oneOf(['stats', 'close']),
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']).isRequired,
};

export default memo(StatsButton);
