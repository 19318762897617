import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.secondaryTextColor};
  flex-direction: column;
  ${({ $type }) => {
    if ($type === 'description')
      return css`
        margin: 32px 0;
        @media ${device.tablet} {
          margin: 48px 0;
        }
        @media ${device.desktop} {
          margin: 16px 0;
          p {
            text-align: initial;
          }
        }
      `;
    if ($type === 'button')
      return css`
        margin-bottom: 8px;
      `;
    return null;
  }}
`;

export const LogoRow = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.desktop} {
    justify-content: flex-start;
  }
`;

export const Container = styled.div`
  text-align: center;
  width: 100%;
  max-width: 408px;
  p {
    margin: unset;
  }
  ${({ $isButtonType }) =>
    !$isButtonType &&
    css`
      ${Row} {
        justify-content: flex-start;
      }
    `}
`;
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  ${({ $logoSize }) => css`
    width: ${$logoSize.width}px;
    height: ${$logoSize.height}px;
  `};
`;

export const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: unset;
  margin: unset;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ListSubButton = styled.div`
  margin: 0px auto 0px 0px;
`;

export const CheckIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 9px;
  color: ${colors.spaceGreen};
`;
