import { memo } from 'react';
import PropTypes from 'prop-types';
import { Dot } from '../Carousel.styles';

const NavDot = ({ itemKey, isActive }) => (
  <Dot key={itemKey} active={isActive} data-testid={itemKey} />
);

NavDot.propTypes = {
  itemKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.isActive === nextProps.isActive;

export default memo(NavDot, propsAreEqual);
