import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Overlay from '../Overlay/Overlay';

const OFFSET = '132px';

export const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    position: relative;
    @media ${device.desktop} {
      bottom: 50px;
    }

    @media ${device.desktopLarge} {
      bottom: unset;
      top: 80px;
    }

    @media ${device.desktopExtraLarge} {
      bottom: unset;
      top: 140px;
    }

    ${({ $fixedPosition }) =>
      $fixedPosition &&
      css`
        @media ${device.desktop} {
          top: 50px;
          bottom: unset;
        }

        @media ${device.desktopLarge} {
          top: unset;
          bottom: 80px;
        }

        @media ${device.desktopExtraLarge} {
          top: unset;
          bottom: 140px;
        }
      `}
  }
`;

export const PageWelcomeOverlay = styled(Overlay)`
  ${({ $fixedPosition }) =>
    $fixedPosition &&
    css`
      position: fixed;
      top: ${OFFSET};
      left: 0;
      width: 100%;
      height: calc(100vh - ${OFFSET});
      background: rgba(11, 13, 14, 0.9);
    `}
`;

export const BackgroundImage = styled.img`
  position: fixed;
  bottom: 0;
  width: 100%;
  pointer-events: none;
`;
