import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import withTheme from 'znipe-themes/hocs/withTheme';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import DesktopScreenOverlay from './DesktopScreenOverlay/DesktopScreenOverlay';
import MobileScreenOverlay from './MobileScreenOverlay/MobileScreenOverlay';
import themes from './ScreenOverlay.themes';

const ScreenOverlay = ({
  matchId,
  selectedMatchGameIndex,
  title = '',
  description = '',
  playerRef = playerDefaultProps,
  playerContainerRef = { current: null },
  statsDisabled = false,
  hasFinishedPlaying = false,
  playlistAutoplayingEnabled = false,
  hasScreenControls = true,
  onWatchNext,
  onAutoplayCancel,
  springProps = {},
}) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const HOVER_TIMEOUT = 1500;
  return isDesktopOrGreater ? (
    <DesktopScreenOverlay
      matchId={matchId}
      selectedMatchGameIndex={selectedMatchGameIndex}
      playerRef={playerRef}
      playerContainerRef={playerContainerRef}
      hoverTimeout={HOVER_TIMEOUT}
      hasFinishedPlaying={hasFinishedPlaying}
      playlistAutoplayingEnabled={playlistAutoplayingEnabled}
      hasScreenControls={hasScreenControls}
      onWatchNext={onWatchNext}
      onAutoplayCancel={onAutoplayCancel}
      springProps={springProps}
    />
  ) : (
    <MobileScreenOverlay
      matchId={matchId}
      selectedMatchGameIndex={selectedMatchGameIndex}
      title={title}
      description={description}
      playerRef={playerRef}
      playerContainerRef={playerContainerRef}
      statsDisabled={statsDisabled}
      hasFinishedPlaying={hasFinishedPlaying}
      playlistAutoplayingEnabled={playlistAutoplayingEnabled}
      onWatchNext={onWatchNext}
      onAutoplayCancel={onAutoplayCancel}
    />
  );
};

const springProps = {
  id: PropTypes.number,
  key: PropTypes.string,
};

ScreenOverlay.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  statsDisabled: PropTypes.bool,
  hasFinishedPlaying: PropTypes.bool,
  playlistAutoplayingEnabled: PropTypes.bool,
  hasScreenControls: PropTypes.bool,
  onWatchNext: PropTypes.func,
  onAutoplayCancel: PropTypes.func,
  playerContainerRef: PropTypes.shape({}),
  playerRef: playerPropTypes,
  springProps: PropTypes.shape({
    transform: PropTypes.shape(springProps),
    width: PropTypes.shape(springProps),
    translate: PropTypes.shape(springProps),
  }),
};

export default withTheme(ScreenOverlay, themes, 'screenOverlay');
