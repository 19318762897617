import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import { GAT_CACHE_EVENT } from 'tv-reducers/intensive';
import useGatId from 'tv-hooks/useGatId';
import createLolItem from './utils/createLolItem';
import createLolImageSrc from './utils/createLolImageSrc';
import createLolCharacter from './utils/createLolCharacter';
import createLolSpell from './utils/createLolSpell';
import createLolPlayer from './utils/createLolPlayer';

export const SUBSCRIBE_GAT_CACHE = 'SUBSCRIBE_GAT_CACHE';
export const UNSUBSCRIBE_GAT_CACHE = 'UNSUBSCRIBE_GAT_CACHE';

export const useGatCacheEpic = matchId => {
  const dispatch = useIntensiveDispatch();
  const gatId = useGatId(matchId);

  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_GAT_CACHE, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_GAT_CACHE, gatId });
  }, [dispatch, gatId]);
};

const getSnap = snap => snap.val();
const emptyObject = {};

const fixItems = (items, patch) =>
  items
    ?.map(item =>
      createLolItem({ ...item, imageSrc: createLolImageSrc(item.image?.full, patch, 'item') }),
    )
    .reduce((acc, item) => ({ ...acc, [item.id]: item }), {}) ?? emptyObject;

const fixCharacterSpells = (characters, patch) =>
  characters
    ?.map(character => {
      const passive = character.passive
        ? createLolSpell({
            ...character.passive,
            id: `${character.championName}Passive`,
            image: createLolImageSrc(character.passive.image, patch, 'passive'),
          })
        : {};
      const spells =
        character.spells?.map(spell =>
          createLolSpell({
            ...spell,
            image: createLolImageSrc(spell.image?.full, patch, 'spell'),
          }),
        ) ?? [];
      return createLolCharacter(
        {
          ...character,
          passive,
          spells,
          image: createLolImageSrc(character.heroImageSrc, patch, 'champion'),
        },
        patch,
        'item',
      );
    })
    .reduce((acc, character) => ({ ...acc, [character.id]: character }), {}) ?? emptyObject;

const fixPlayers = (players, gatId) =>
  players?.map(player =>
    createLolPlayer(
      {
        ...player,
        character: {
          id: player.championName,
          name: player.championName,
        },
      },
      gatId,
    ),
  );

const createPerk = (perk, patch) => {
  const { name, id, icon, shortDesc, longDesc } = perk;
  return {
    name,
    id,
    image: createLolImageSrc(icon, patch),
    description: shortDesc,
    htmlDescription: longDesc,
  };
};

const fixPerks = (perks, patch) =>
  perks?.reduce((acc, perkTree) => {
    if (!acc[perkTree.id]) acc[perkTree.id] = createPerk(perkTree, patch);
    perkTree.slots?.forEach(perk => {
      if (acc[perk.id]) return;
      acc[perk.id] = createPerk(perk, patch);
    }, acc);

    return acc;
  }, {});

const gatOffsetEpic = factorySubscribeEpic(
  [SUBSCRIBE_GAT_CACHE, UNSUBSCRIBE_GAT_CACHE],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(firebase.database().ref(`/gatCache/${gatId}`), 'value').pipe(map(getSnap)),
  ({ gatId }) =>
    payload => {
      const gatCache = payload[0] ?? emptyObject;
      const {
        version: patch,
        items,
        participants,
        esportsMatchID: esportsMatchId,
        esportsGameID: esportsGameId,
        esportsTournamentID: esportsTournamentId,
      } = gatCache;

      return {
        type: GAT_CACHE_EVENT,
        gatId,
        patch,
        partnerIds: {
          esportsMatchId,
          esportsGameId,
          esportsTournamentId,
        },
        items: Object.values(fixItems(items, patch)),
        characters: Object.values(fixCharacterSpells(participants, patch)),
        players: fixPlayers(participants, gatId),
        perks: fixPerks(
          participants?.reduce(
            (acc, { runes = emptyObject }) => [...acc, ...Object.values(runes)],
            [],
          ),
          patch,
        ),
      };
    },
);

export default gatOffsetEpic;
