import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    margin-bottom: unset;
  }
`;

export const ColorSelect = styled.div<{ $isSelected?: boolean; $isLocked?: boolean }>`
  border: 2px solid transparent;
  border-radius: 100%;
  padding: 2px;
  cursor: ${({ $isLocked }) => ($isLocked ? 'default' : 'pointer')};
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 2px solid ${colors.white};
    `}
`;

export const ColorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LabelAndColors = styled.div`
  display: flex;
  flex-direction: column;
`;
