import { useCallback } from 'react';
import firebase from 'firebase/app';
import { useDispatch } from 'react-redux';
import ls from 'local-storage';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useIsOnboardingDisabled } from 'tv-selectors/auth/makeGetIsOnboardingDisabled';
import { LS_ONBOARDING } from 'tv-constants/localStorage';
import { updateAuth } from 'tv-actions/old/auth';

const useSetDisabledStatus = () => {
  const dispatch = useDispatch();
  const userId = useUserId();
  const isDisabled = useIsOnboardingDisabled();
  const setDisabledStatus = useCallback(
    disabled => {
      if (isDisabled === disabled) return;

      const currentOnboardingData = ls.get(LS_ONBOARDING) || {};
      const newOnboardingObject = { ...currentOnboardingData, disabled };
      ls.set(LS_ONBOARDING, newOnboardingObject);
      dispatch(updateAuth({ onboarding: newOnboardingObject }));

      if (!userId) return;
      const onboardingDisabledRef = firebase
        .database()
        .ref('users')
        .child(userId)
        .child('onboarding')
        .child('disabled');

      onboardingDisabledRef.set(disabled);
    },
    [isDisabled, dispatch, userId],
  );

  return setDisabledStatus;
};

export default useSetDisabledStatus;
