export const getMenuMarkerNumber = (selection, filterKey) => {
  if (!filterKey) return 0;
  const selectedValue = selection[filterKey];
  if (filterKey === 'classes') {
    return Object.values(selectedValue).filter(checked => checked).length;
  }
  return selectedValue?.length || 0;
};

export const getMarkerNumber = (selection, filterPanelOptions) => {
  const entriesToCheck = Object.entries(selection).filter(([key]) =>
    filterPanelOptions?.includes(key),
  );
  return entriesToCheck.reduce((acc, [key]) => acc + getMenuMarkerNumber(selection, key), 0);
};
