import { createSelector } from 'reselect';
import makeGetSelectedProduct from 'tv-selectors/products/makeGetSelectedProduct';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductPrice = () => {
  const getSelectedProduct = makeGetSelectedProduct();
  return createSelector([getSelectedProduct], product => product.userPrice ?? {});
};

export const useGetProductPrice = props => useMemoizedSelector(makeGetProductPrice, props);

export default makeGetProductPrice;
