import styled, { css } from 'styled-components';
import { BaseContainer } from '../Selector.styles';

const small = {
  containerWidth: '100%',
  containerHeight: '40px',
};

const medium = {
  containerWidth: '100%',
  containerHeight: '48px',
};

export const imageSize = {
  small: 16,
  medium: 24,
};

const sizes = { small, medium };

export const Container = styled(BaseContainer)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  ${({ $size }) => {
    const { containerWidth, containerHeight } = sizes[$size] ?? sizes.small;
    return css`
      width: ${containerWidth};
      height: ${containerHeight};
    `;
  }};
`;

export const ImageArea = styled.div`
  margin-left: 8px;
  ${({ $size }) => css`
    height: ${imageSize[$size]}px;
    width: ${imageSize[$size]}px;
  `}

  > * {
    width: 100%;
    height: 100%;
  }
`;
