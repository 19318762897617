import styled, { css } from 'styled-components';
import PaymentTypeButton from 'tv-modules/Payment/PaymentTypeButton/PaymentTypeButton';
import { device } from 'znipe-styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 290px;
`;

export const CelebrationImageWrapper = styled.div`
  align-self: center;
  margin: 30px 0 60px 0;
`;

export const StyledPaymentTypeButton = styled(PaymentTypeButton)`
  align-self: center;
  margin-bottom: 15px;
  ${props =>
    props.marginTop
      ? css`
          margin-top: 45px;
        `
      : ''}
`;

export const FreeMonthsInfo = styled.span`
  display: flex;
  align-items: center;
  margin-top: 45px;
  color: ${props => props.theme.selectPaymentMethod.freeMonthsInfoColor};
  font-size: 14px;
  line-height: 150%;
  text-align: center;

  ${CelebrationImageWrapper} {
    height: 61px;
    margin: 0;
    margin-right: 10px;
  }

  @media ${device.tablet} {
    text-align: left;
  }
`;
