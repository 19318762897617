import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const defaultArray = ['', 'lol'];

const useTopicLink = (id = '', type = '', customGame = '') => {
  const { packageSlug, topicType, topicQuery } = useParams();
  const [, game = 'lol'] = topicQuery?.split('-') ?? defaultArray;

  const baseUrl = useMemo(() => (packageSlug ? `/${packageSlug}` : ''), [packageSlug]);

  if (!id || !type) {
    return `${baseUrl}/topic/${topicType}/${topicQuery}`;
  }

  if (type === 'event') {
    return `${baseUrl}/topic/${type}/${id}`;
  }

  if (customGame) {
    return `${baseUrl}/topic/${type}/${id}-${customGame}`;
  }

  return `${baseUrl}/topic/${type}/${id}-${game}`;
};

export default useTopicLink;
