import produce from 'immer';
import { SET_TEAMS, SET_SINGLE_TEAM } from 'tv-constants/redux';
import getLastItem from 'znipe-utils/misc/getLastItem';

export const initialState = {};

const teamsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_TEAMS: {
      Object.entries(payload).forEach(([key, value]) => {
        const { teamDetails, ...rest } = value;
        const teamShortName = teamDetails?.shortName || value?.shortName || '';
        const teamName = teamDetails?.name || value?.name || '';
        const name = Array.isArray(teamName) ? getLastItem(teamName) : teamName;
        const shortName = Array.isArray(teamShortName) ? getLastItem(teamShortName) : teamShortName;
        const country = getLastItem(teamDetails?.country) || '';
        const roster = getLastItem(value.roster);
        const teamLogoLight = teamDetails?.logoLight ?? value?.logoLight;
        const logoLight = getLastItem(teamLogoLight);
        const teamLogoDark = teamDetails?.logoDark ?? value?.logoDark;
        const logoDark = getLastItem(teamLogoDark);
        const logoSrc = Array.isArray(value?.logoSrc)
          ? getLastItem(value?.logoSrc)
          : value?.logoSrc;
        state[key] = {
          ...rest,
          name,
          shortName,
          country,
          roster,
          logoSrc,
          logoLight,
          logoDark,
        };
      });
      return state;
    }
    case SET_SINGLE_TEAM: {
      Object.entries(payload).forEach(([key, value]) => {
        const { teamDetails = {}, ...rest } = value;
        if (!state[key]) state[key] = {};
        Object.entries({ ...rest, teamDetails }).forEach(([k, v]) => {
          if (k === 'team') k = 'teamId';
          if (Array.isArray(v)) {
            state[key][k] = getLastItem(v);
            return state;
          }
          state[key][k] = v;
          return state;
        }, {});
      });
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default teamsReducer;
