import { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import FlourishContainer from 'znipe-elements/data-display/FlourishContainer/FlourishContainer';
import useFlourishValues from 'tv-hooks/useFlourishValues';

import {
  Container,
  TextSection,
  HeaderSection,
  FooterSection,
  IconWrapper,
  Headline,
  FlourishWrapper,
  Ribbon,
  SectionWrapper,
} from './Layout.styles';

const Layout = ({
  headerComponent,
  headlineComponent,
  bodyComponent,
  footerComponent,
  onCloseClick,
  closeIcon = 'close',
  ribbonText = '',
}) => {
  const { flourish } = useFlourishValues();

  return (
    <Container>
      {onCloseClick && (
        <IconWrapper onClick={onCloseClick} data-testid="close-button">
          <Icon inline size={16} type={closeIcon} fillColor={colors.white} />
        </IconWrapper>
      )}
      {ribbonText && (
        <Ribbon>
          <Typography type="title-s" color={colors.black} dataTestId="ribbon-text">
            {ribbonText}
          </Typography>
        </Ribbon>
      )}
      {headerComponent && <HeaderSection>{headerComponent}</HeaderSection>}
      <SectionWrapper>
        {headlineComponent && (
          <Headline>
            <Typography type="heading-s" color={colors.white} dataTestId="banner-title">
              {headlineComponent}
            </Typography>
          </Headline>
        )}
        {bodyComponent && (
          <TextSection>
            <Typography type="paragraph-m" color={colors.grey75}>
              {bodyComponent}
            </Typography>
          </TextSection>
        )}
      </SectionWrapper>
      {footerComponent && <FooterSection $hasRibbon={ribbonText}>{footerComponent}</FooterSection>}

      <FlourishWrapper>
        <FlourishContainer
          flourish={flourish}
          background="none"
          shouldPreserveAspectRatio={false}
        />
      </FlourishWrapper>
    </Container>
  );
};

Layout.propTypes = {
  headerComponent: PropTypes.node,
  headlineComponent: PropTypes.node,
  bodyComponent: PropTypes.node,
  footerComponent: PropTypes.node,
  onCloseClick: PropTypes.func,
  closeIcon: PropTypes.string,
  ribbonText: PropTypes.string,
};

export default memo(Layout);
