import { createSelector } from 'reselect';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetProductSlug from 'tv-selectors/products/makeGetProductSlug';
import makeGetProductsMap from 'tv-selectors/products/makeGetProductsMap';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductId = () => {
  const getProps = makeGetProps();
  const getProductSlug = makeGetProductSlug();
  const getProductsMap = makeGetProductsMap();
  return createSelector(
    [getProps, getProductSlug, getProductsMap],
    ({ productId }, productSlug, productsMap) => {
      if (productId) return productId;
      const product =
        Object.values(productsMap).find(({ humanReadableId }) => humanReadableId === productSlug) ||
        {};
      return product.id ?? '';
    },
  );
};

export const useGetProductId = props => useMemoizedSelector(makeGetProductId, props);

export default makeGetProductId;
