import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetMute = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.mute ?? false);
};

export const useMute = () => useMemoizedSelector(makeGetMute);

export default makeGetMute;
