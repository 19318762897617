import { produce } from 'immer';
import deepmerge from 'deepmerge';
import { shallowEqual } from 'react-redux';
import { normalize } from 'normalizr';
import csgoGat from 'tv-schema/csgoStats/root.normalizr';
import gat from 'tv-schema/intensive/gat.normalizr';
import item from 'tv-schema/intensive/item.normalizr';
import perk from 'tv-schema/intensive/perk.normalizr';
import character from 'tv-schema/intensive/character.normalizr';
import { calcStartTime, calcEndTime } from 'tv-schema/intensive/round.normalizr';
import { addTogether, statKeysToIncrement } from 'tv-schema/intensive/stat.normalizr';
import { getGameTimeFromId } from 'tv-epics/utils/getTimeSeriesInfo';

export const CHAMPION_KILLS_EVENT = 'CHAMPION_KILLS_EVENT';
export const CHAMP_SELECT_EVENT = 'CHAMP_SELECT_EVENT';
export const EPIC_MONSTER_KILLS_EVENT = 'EPIC_MONSTER_KILLS_EVENT';
export const GAME_PAUSE_EVENT = 'GAME_PAUSE_EVENT';
export const GAT_CACHE_EVENT = 'GAT_CACHE_EVENT';
export const GAT_OFFSET_EVENT = 'GAT_OFFSET_EVENT';
export const GAT_TIME_INFO_EVENT = 'GAT_TIME_INFO_EVENT';
export const ROUND_DAMAGE_SUMMARY_EVENT = 'ROUND_DAMAGE_SUMMARY_EVENT';
export const ROUND_END_SUMMARY_EVENT = 'ROUND_END_SUMMARY_EVENT';
export const ROUND_KILLS_SUMMARY_EVENT = 'ROUND_KILLS_SUMMARY_EVENT';
export const ROUND_KILL_ASSISTS_SUMMARY_EVENT = 'ROUND_KILL_ASSISTS_SUMMARY_EVENT';
export const ROUND_LOADOUT_EVENT = 'ROUND_LOADOUT_EVENT';
export const SEQUENTIAL_EVENT = 'SEQUENTIAL_EVENT';
export const SKILL_LEVEL_UP_EVENT = 'SKILL_LEVEL_UP_EVENT';
export const TIME_SERIES_EVENT = 'TIME_SERIES_EVENT';
export const STRUCTURE_DESTROYED_EVENT = 'STRUCTURE_DESTROYED_EVENT';
export const SET_GAT_GAME_TITLE = 'SET_GAT_GAME_TITLE';
export const RESET_GAT = 'RESET_GAT';

export const CSGO_STATE = 'CSGO_STATE';

export const initialState = {
  assists: {},
  characters: {},
  gats: {},
  items: {},
  kills: {},
  loadouts: {},
  pauses: {},
  players: {},
  rounds: {},
  stats: {},
  timeSeriesEntries: {},
  scenarios: {},
};

const customKeys = [
  'assists',
  'baronQueue',
  'deaths',
  'dragonQueue',
  'items',
  'kills',
  'loadouts',
  'monsterKills',
  'perks',
  'pauses',
  'players',
  'rounds',
  'spells',
  'stats',
  'team1',
  'team2',
  'teams',
  'teamOneBans',
  'teamOnePicks',
  'teamTwoBans',
  'teamTwoPicks',
  'timeSeries',
  'structuresDestroyed',
  'victims',
  'weapons',
  'gatEvents',
  'eventFeed',
  'scenarios',
];

const customKeySortFun = {
  timeSeries: (a, b) => getGameTimeFromId(a) - getGameTimeFromId(b),
};

const customMerge = key => {
  if (customKeys.includes(key)) {
    return (val1, val2) => {
      if (Array.isArray(val1)) {
        if (val2.length < 1 || shallowEqual([...val1].sort(), [...val2].sort())) return val1;
        const newVal = [...new Set([...val1, ...val2])];
        if (customKeySortFun[key]) newVal.sort(customKeySortFun[key]);
        return newVal;
      }
      if (key === 'rounds') {
        const customRoundsMerge = () => (roundVal1, roundVal2) => {
          const updatedVal2 = {
            ...roundVal2,
            startTime: calcStartTime(roundVal1.startTime, roundVal2.startTime),
            endTime: calcEndTime(roundVal1.endTime, roundVal2.endTime),
          };
          return deepmerge(roundVal1, updatedVal2, { customMerge });
        };
        return deepmerge(val1, val2, { customMerge: customRoundsMerge });
      }
      return deepmerge(val1, val2, { customMerge });
    };
  }
  if (statKeysToIncrement.includes(key)) return addTogether;

  return (val1, val2) => deepmerge(val1, val2, { customMerge });
};

const cleanup = state =>
  Object.entries(state).reduce((acc, [key, val]) => {
    if (customKeys.includes(key) && Array.isArray(val)) return { ...acc, [key]: [...new Set(val)] };
    if (Array.isArray(val)) return { ...acc, [key]: val };
    if (val && typeof val === 'object') return { ...acc, [key]: cleanup(val) };
    return { ...acc, [key]: val };
  }, {});
const normalizeAndCleanup = (data, s) => cleanup(normalize(data, s).entities);

// Disabling the rule here due to the use of immer that expect that state to be mutated
const intensiveReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case CSGO_STATE:
      return deepmerge(state, normalizeAndCleanup(action.payload, csgoGat), { customMerge });
    case CHAMPION_KILLS_EVENT:
    case EPIC_MONSTER_KILLS_EVENT:
    case GAME_PAUSE_EVENT:
    case ROUND_DAMAGE_SUMMARY_EVENT:
    case ROUND_END_SUMMARY_EVENT:
    case ROUND_KILLS_SUMMARY_EVENT:
    case ROUND_KILL_ASSISTS_SUMMARY_EVENT:
    case ROUND_LOADOUT_EVENT:
    case SEQUENTIAL_EVENT:
    case STRUCTURE_DESTROYED_EVENT:
    case SKILL_LEVEL_UP_EVENT:
    case TIME_SERIES_EVENT:
      return deepmerge(state, normalizeAndCleanup(action.payload, gat), { customMerge });
    case CHAMP_SELECT_EVENT: {
      const newState = deepmerge(state, normalizeAndCleanup(action.payload, gat), { customMerge });

      newState.gats[action.gatId].teamOnePicks = action.payload.teamOnePicks.map(({ id }) => id);
      newState.gats[action.gatId].teamTwoPicks = action.payload.teamTwoPicks.map(({ id }) => id);
      newState.gats[action.gatId].teamOneBans = action.payload.teamOneBans.map(({ id }) => id);
      newState.gats[action.gatId].teamTwoBans = action.payload.teamTwoBans.map(({ id }) => id);

      return newState;
    }
    case GAT_TIME_INFO_EVENT:
      if (!state.gats) state.gats = {};
      if (!state.gats[action.gatId]) state.gats[action.gatId] = {};
      if (typeof action.round === 'string') {
        state.gats[action.gatId].currentRound = action.round;
      }
      if (typeof action.roundNumber === 'number') {
        state.gats[action.gatId].roundNumber = action.roundNumber;
      }
      if (typeof action.timeSeriesEntry === 'string') {
        state.gats[action.gatId].currentTimeSeriesEntry = action.timeSeriesEntry;
      }
      state.gats[action.gatId].gameTime = action.gameTime;
      return state;
    case GAT_CACHE_EVENT:
      return deepmerge.all(
        [
          state,
          { gats: { [action.gatId]: { patch: action.patch, partnerIds: action.partnerIds } } },
          normalize(action, {
            items: [item],
            characters: [character],
            perks: [perk],
          }).entities,
          normalizeAndCleanup({ players: action.players, id: action.gatId }, gat),
        ],
        { customMerge },
      );
    case GAT_OFFSET_EVENT:
      if (typeof action.offset !== 'number') return state;
      if (!state.gats[action.gatId]) state.gats[action.gatId] = {};
      state.gats[action.gatId].offset = action.offset;
      state.gats[action.gatId].matchSetupDuration = action.matchSetupDuration;
      return state;
    case SET_GAT_GAME_TITLE:
      if (!state.gats[action.gatId]) state.gats[action.gatId] = {};
      state.gats[action.gatId].gameTitle = action.gameTitle;
      return state;

    case RESET_GAT: {
      if (!state.gats[action.gatId]) return state; // Assume no info exists if no base exist

      const {
        pauses = [],
        teams = [],
        players = [],
        dragonQueue = [],
        baronQueue = [],
        timeSeries = [],
        monsterKills = [],
        structuresDestroyed = [],
        gatEvents = [],
        eventFeed = [],
        rounds = [],
      } = state.gats[action.gatId];
      const sequentials = [...dragonQueue, ...baronQueue];

      const makeCleanup = treeKey => key => {
        if (state[treeKey]?.[key]) delete state[treeKey]?.[key];
      };
      const statsCleanupFunc = makeCleanup('stats');

      pauses?.forEach(makeCleanup('pauses'));
      teams?.forEach(makeCleanup('teams'));
      players?.forEach(makeCleanup('players'));
      sequentials?.forEach(makeCleanup('sequentials'));
      monsterKills?.forEach(makeCleanup('monsterKills'));
      structuresDestroyed?.forEach(makeCleanup('structuresDestroyed'));
      gatEvents?.forEach(makeCleanup('gatEvents'));
      eventFeed?.forEach(makeCleanup('eventFeed'));
      rounds?.forEach(makeCleanup('rounds'));

      timeSeries.forEach(key => {
        const cleanupFunc = makeCleanup('timeSeriesEntries');
        const { playerStats = [], teamStats = [] } = state.timeSeriesEntries[key];
        const stats = [...playerStats, ...teamStats];

        stats.forEach(statsCleanupFunc);

        cleanupFunc(key);
      });

      delete state.gats[action.gatId];
      return state;
    }
    default:
      return state;
  }
});

export default intensiveReducer;
