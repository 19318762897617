import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayer from 'tv-selectors/players/makeGetPlayer';

const makeGetPlayerImage = () => {
  const getPlayer = makeGetPlayer();
  return createSelector([getPlayer], player => player.imageSrc || player.image || '');
};

export const useGetPlayerImage = props => useMemoizedGqlSelector(makeGetPlayerImage, props);

export default makeGetPlayerImage;
