import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import useVideoControl from 'znipe-watch/src/hooks/useVideoControl';
import ControlButton from 'znipe-watch/src/modules/PlayerControls/ControlButtons/ControlButton/ControlButton';

const PlayPauseButton = ({
  playerRef = playerDefaultProps,
  iconSize,
  hasFinishedPlaying = false,
}) => {
  const { isPlaying, togglePlayPause } = useVideoControl(playerRef);

  const selectedIcon = useMemo(() => {
    if (hasFinishedPlaying) {
      return {
        tooltip: 'Replay',
        icon: 'watchAgain',
      };
    }
    return {
      tooltip: isPlaying ? 'Pause' : 'Play',
      icon: isPlaying ? 'pause' : 'play',
    };
  }, [hasFinishedPlaying, isPlaying]);

  const { tooltip, icon } = selectedIcon;

  return (
    <ControlButton toolTip={tooltip} icon={icon} iconSize={iconSize} onClick={togglePlayPause} />
  );
};

PlayPauseButton.propTypes = {
  playerRef: playerPropTypes,
  iconSize: PropTypes.number,
  hasFinishedPlaying: PropTypes.bool,
};

export default memo(PlayPauseButton);
