import { error } from 'znipe-logger';
import type { Event } from '../AnalyticsManager';

const API_ENDPOINT = `${process.env.QUARTERBACK_API_URL}/v4/za/events`;

const callApi = (events: Event[], externalJwt?: string, overrideApiEndpoint?: string) => {
  if (!events || events.length < 1) return Promise.reject(new Error('Invalid events data'));
  const body = {
    events,
  };

  const headers = {
    'Content-Type': 'application/json',
    ...(externalJwt ? { Authorization: `bearer ${externalJwt}` } : {}),
  };

  return fetch(`${overrideApiEndpoint || API_ENDPOINT}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
    .then(resp => resp.json())
    .catch(error);
};

export default callApi;
