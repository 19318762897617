import PropTypes from 'prop-types';

export const ONBOARDING_FITLER_TARGET_ID = 'filter-button-target';

export const PLAYERS = 'players';
export const TEAMS = 'teams';
export const CHAMPIONS = 'champions';
export const EVENTS = 'events';
export const CLASSES = 'classes';
export const YEARS = 'years';
export const TOURNAMENTS = 'tournaments';

export const STANDINGS = 'standings';
export const STANDARD = 'standard';

export const ROLES = 'roles';

export const allFilterMenus = [PLAYERS, TEAMS, CHAMPIONS, EVENTS, CLASSES, TOURNAMENTS];

export const lolClassIcons = {
  Assassin: 'assassinClass',
  Fighter: 'fighterClass',
  Mage: 'mageClass',
  Marksman: 'marksmanClass',
  Support: 'supportClass',
  Tank: 'tankClass',
};

export const rolesShape = PropTypes.shape({
  supportRole: PropTypes.bool,
  bottomRole: PropTypes.bool,
  midRole: PropTypes.bool,
  jungleRole: PropTypes.bool,
  topRole: PropTypes.bool,
});

export const classesShape = PropTypes.shape({
  assassin: PropTypes.bool,
  fighter: PropTypes.bool,
  mage: PropTypes.bool,
  marksman: PropTypes.bool,
  support: PropTypes.bool,
  tank: PropTypes.bool,
});

const arrayOfObject = PropTypes.arrayOf(PropTypes.shape({}));

export const selectionShape = {
  [ROLES]: rolesShape,
  [CLASSES]: classesShape,
  [PLAYERS]: arrayOfObject,
  [TEAMS]: arrayOfObject,
  [CHAMPIONS]: arrayOfObject,
  [EVENTS]: arrayOfObject,
  [TOURNAMENTS]: arrayOfObject,
};

export const filterOptionsShape = {
  sort: PropTypes.bool,
  top: PropTypes.arrayOf(PropTypes.string),
  panel: PropTypes.arrayOf(PropTypes.string),
};

export const defaultFilterOptions = {
  sort: false,
  top: null,
  panel: [PLAYERS, TEAMS],
};

export const defaultRoleSelection = {
  supportRole: false,
  bottomRole: false,
  midRole: false,
  jungleRole: false,
  topRole: false,
};

export const defaultClassSelection = {
  assassin: false,
  fighter: false,
  mage: false,
  marksman: false,
  support: false,
  tank: false,
};

export const defaultFilterSelection = {
  [ROLES]: defaultRoleSelection,
  [CLASSES]: defaultClassSelection,
  [PLAYERS]: [],
  [TEAMS]: [],
  [CHAMPIONS]: [],
  [EVENTS]: [],
  [TOURNAMENTS]: [],
};

export const logoOptionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
});
