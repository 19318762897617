import { useUsername } from 'tv-selectors/auth/makeGetUsername';
import { useGetProductName } from 'tv-selectors/products/makeGetProductName';
import { useGetProductSubscriptionPrice } from 'tv-selectors/products/makeGetProductSubscriptionPrice';
import { useGetProductSubscriptionOriginalPrice } from 'tv-selectors/products/makeGetProductSubscriptionOriginalPrice';
import { useGetProductSubscriptionDiscountPeriods } from 'tv-selectors/products/makeGetProductSubscriptionDiscountPeriods';
import { useGetProductSubscriptionTrialDays } from 'tv-selectors/products/makeGetProductSubscriptionTrialDays';

const addPaymentMethod = 'Please add a payment method to finish redeeming your';
const discountAddCardText = `${addPaymentMethod} discount.`;
const codeAddCardText = `${addPaymentMethod} code.`;

const useSelectionDescription = (productId, promoCodeDescription = false) => {
  const username = useUsername();
  const productName = useGetProductName({ productId });
  const discountPeriods = useGetProductSubscriptionDiscountPeriods({ productId });
  const price = useGetProductSubscriptionPrice({ productId });
  const originalPrice = useGetProductSubscriptionOriginalPrice({ productId });
  const trialDays = useGetProductSubscriptionTrialDays({ productId });

  // No promo code
  if (!promoCodeDescription || discountPeriods === 0) {
    const start = username ? `${username}, you're` : `You're`;
    return `${start} just one step away from joining the pros on stage!`;
  }

  // I was required to use integers in this calculation to not get the infamous js decimal error
  // eg. 1 - 0.7 = 0.30000000000000004
  const discount = (100 - (price / originalPrice) * 100) / 100;
  const monthOrMonths = discountPeriods > 1 ? 'months' : 'month';

  if (discount === 1 && discountPeriods < 0) {
    return `You received a free subscription to ${productName}! Due to technical reasons, you still need to add a payment method.`;
  }

  // Promo code giving discount that is less than 100%
  if (discount > 0 && discount < 1) {
    const discountPercentage = `${discount * 100}%`;

    // Discount forever
    if (discountPeriods < 0)
      return `You received a ${discountPercentage} discount on your ${productName} subscription! ${discountAddCardText}`;
    const end = `${discountPeriods} ${monthOrMonths} of ${productName}! ${discountAddCardText}`;

    if (trialDays > 0)
      return `After the free trial, you will receive a ${discountPercentage} discount on your first ${end}`;

    return `You received ${discountPercentage} off your next ${end}`;
  }

  // Some free months
  const thinText = `You will receive ${discountPeriods}`;
  const boldText = `free ${monthOrMonths} of ${productName}!`;
  return (
    <>
      {thinText} <b>{boldText}</b> {codeAddCardText}
    </>
  );
};

export default useSelectionDescription;
