import styled, { css } from 'styled-components';
import { slideInFromRight, slideOutToRight } from 'znipe-styles/animations';
import { FADE_IN_DURATION, FADE_OUT_DURATION } from '../OverlaySettings.timers';
import { buttonBasicStyles, paddingFromScreen } from '../OverlaySettings.styles';

export const subMenuHeaderHeight = 65;
const subMenuBodyMaxHeight = `calc(100vh - ${subMenuHeaderHeight + paddingFromScreen * 2}px)`;

export const SubMenuWrapper = styled.div`
  width: 100%;
  animation-delay: 0ms;
  animation-timing-function: ease-out;
  ${({ visible }) =>
    visible
      ? css`
          animation: ${slideInFromRight()} ${FADE_IN_DURATION}ms forwards;
        `
      : css`
          animation: ${slideOutToRight()} ${FADE_OUT_DURATION}ms forwards;
        `};
`;

const headerStyles = css`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: ${`${subMenuHeaderHeight}px`};
  padding-left: 14px;
  padding-right: 16px;
  align-items: center;
  color: ${({ theme }) => theme.settingsSubMenu.headerTextColor};
  border-bottom: 2px solid ${({ theme }) => theme.settingsSubMenu.subMenuBorderColor} !important;
  ${buttonBasicStyles}
  ${({ hasScroll }) =>
    hasScroll &&
    css`
      box-shadow: 0 12px 6px -4px ${({ theme }) => theme.settingsSubMenu.shadowColor};
    `}
`;

export const SubMenuHeader = styled.div`
  ${headerStyles}
`;

export const SubMenuHeaderButton = styled.button.attrs({
  type: 'button',
})`
  ${headerStyles}
`;

export const SubMenuBody = styled.div`
  max-height: ${subMenuBodyMaxHeight};
  ${({ hasScroll }) =>
    hasScroll &&
    css`
      overflow-y: scroll;
    `}
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const Title = styled.div`
  text-transform: uppercase;
  padding-left: 8px;
`;

export const IconWrapper = styled.div`
  width: 24px;
`;

export const OptionWrapper = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  width: 100%;
  height: 55px;
  align-items: center;
  padding: 0 17px;
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  color: ${({ theme }) => theme.settingsSubMenu.headerTextColor};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.settingsSubMenu.bgColorHover};
  }
  ${buttonBasicStyles}
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin: 6px;
  border-radius: 3px;
  ${({ theme, selected }) =>
    selected
      ? css`
          border: solid 1px ${theme.settingsSubMenu.highlightColor};
          background-color: ${theme.settingsSubMenu.highlightColor};
        `
      : css`
          border: solid 1px ${theme.settingsSubMenu.unselectedColor};
        `}
`;

export const Label = styled.div`
  margin-left: 10px;
  color: ${({ theme, selected }) =>
    selected ? theme.settingsSubMenu.highlightColor : theme.settingsSubMenu.optionTextColor};
`;

export const SoundOptionsWrapper = styled.div`
  padding: 17px;
`;
