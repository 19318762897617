import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const CircleBackground = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  width: 100%;
  height: 100%;
`;

export const SVGElement = styled.svg`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
`;

export const SVGCircle = styled.circle`
  @keyframes circleAnimation {
    to {
      stroke-dashoffset: 0;
    }
  }
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  animation: circleAnimation ${({ animationLength }) => animationLength}s linear forwards;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
