import { memo } from 'react';
import PropTypes from 'prop-types';
import ButtonPredictInput from './ButtonPredictInput';
import DropdownPredictInput from './DropdownPredictInput';
import { variants } from '../../../predictionConstants';
import { scenarioType } from '../../../types';

const PredictionInputHandlement = ({ scenario, size = 'small', gameId }) => {
  const { variant } = scenario;
  switch (variant) {
    case variants.TEAM:
    case variants.TEAMS:
    case variants.PLAYER:
    case variants.ONE_VERSUS_ONE:
    case variants.ONE_VERSUS_MANY:
    case variants.TEXT:
      return <ButtonPredictInput scenario={scenario} size={size} gameId={gameId} />;
    case variants.SELECT:
      return <DropdownPredictInput scenario={scenario} gameId={gameId} />;
    default:
      return null;
  }
};

PredictionInputHandlement.propTypes = {
  scenario: scenarioType.isRequired,
  size: PropTypes.string,
  gameId: PropTypes.string.isRequired,
};

export default memo(PredictionInputHandlement);
