import PropTypes from 'prop-types';
import HighlightList from 'tv-modules/HighlightsListing/HighlightList/HighlightList';
import HighlightClip from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip';

const VideoResultItem = ({
  videoId = '',
  resultList = [],
  elementSizes,
  videoType = 'highlight',
}) => {
  const { videoListingSize, videoClipType } = elementSizes;
  const videoList = resultList.map(id => ({ id })); // convert to array of objects
  if (videoList.length > 0)
    return (
      <HighlightList
        autoPlayEnabled={false}
        videoList={videoList}
        size={videoListingSize}
        videoType={videoType}
      />
    );

  return (
    <HighlightClip
      highlightId={videoId}
      type={videoClipType}
      size={videoListingSize}
      videoType={videoType}
    />
  );
};

VideoResultItem.propTypes = {
  resultList: PropTypes.arrayOf(PropTypes.string),
  videoId: PropTypes.string,
  videoType: PropTypes.oneOf(['highlight', 'editorial']),
  elementSizes: PropTypes.shape({
    tabsetSize: PropTypes.string,
    playerCardSize: PropTypes.string,
    topicCardSize: PropTypes.string,
    topicLinkSize: PropTypes.string,
    matchItemSize: PropTypes.string,
    videoListingSize: PropTypes.string,
    videoListingType: PropTypes.string,
    videoClipType: PropTypes.string,
  }).isRequired,
};

export default VideoResultItem;
