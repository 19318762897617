export const SET_EVENT = 'SET_EVENT';
export const SET_FEATURED_CONTENT = 'SET_FEATURED_CONTENT';
export const SET_GAMES = 'SET_GAMES';
export const SET_MATCHES = 'SET_MATCHES';
export const SET_PAGE = 'SET_PAGE';
export const SET_PLAYLISTS = 'SET_PLAYLISTS';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TOURNAMENTS = 'SET_TOURNAMENTS';
export const SET_TOURNAMENT_STAGES = 'SET_TOURNAMENT_STAGES';
export const SET_COMPETITORS = 'SET_COMPETITORS';
export const SET_PLAYERS = 'SET_PLAYERS';
export const SET_ROSTER = 'SET_ROSTER';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_CHAMPIONS = 'SET_CHAMPIONS';

// Video contants
export const SET_VIDEOS = 'SET_VIDEOS';
export const SET_STAGE_VIDEOS = 'SET_STAGE_VIDEOS';
export const SET_PLAYLIST_VIDEOS = 'SET_PLAYLIST_VIDEOS';
export const SET_VIDEO_TOKENS = 'SET_VIDEO_TOKENS';

// Streams constants
export const SET_GLOBAL_STREAMS = 'SET_GLOBAL_STREAMS';
export const SET_STREAMS = 'SET_STREAMS';
export const SET_STREAM_TOKENS = 'SET_STREAM_TOKENS';

// Match constants
export const SET_MATCH_PLAYER_STREAMS = 'SET_MATCH_PLAYER_STREAMS';
export const SET_MATCH_ESPORTSGAMEID = 'SET_MATCH_ESPORTSGAMEID';
export const SET_POPOUT_SOUND_FROM = 'SET_POPOUT_SOUND_FROM';
export const SET_SOUND_FROM = 'SET_SOUND_FROM';

// Games constants
export const SET_GAME_GAT_ID = 'SET_GAME_GAT_ID';

// Search
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SEARCH_HISTORY_QUERY = 'SET_SEARCH_HISTORY_QUERY';
export const SET_SEARCH_HISTORY_RESULT = 'SET_SEARCH_HISTORY_RESULT';

// Content subscription
export const SET_CONTENT_SUBSCRIPTIONS = 'SET_CONTENT_SUBSCRIPTIONS';
export const POP_CONTENT_SUBSCRIPTIONS = 'POP_CONTENT_SUBSCRIPTIONS';
export const PUSH_CONTENT_SUBSCRIPTIONS = 'PUSH_CONTENT_SUBSCRIPTIONS';
export const UPDATE_CONTENT_SUBSCRIPTIONS = 'UPDATE_CONTENT_SUBSCRIPTIONS';

// Topic pages
export const SET_SINGLE_TEAM = 'SET_SINGLE_TEAM';
export const SET_SINGLE_PLAYER = 'SET_SINGLE_PLAYER';

// Channels
export const SET_CHANNELS = 'SET_CHANNELS';

// Predictions
export const SET_PREDICTIONS = 'SET_PREDICTIONS';
