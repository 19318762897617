import { memo } from 'react';
import PropTypes from 'prop-types';
import PanelHeader from './components/PanelHeader/PanelHeader';
import { Container } from './MenuPanel.styles';

const MenuPanel = ({
  children,
  type = 'scaling',
  header,
  isActive = false,
  onClose,
  avatar = '',
}) => (
  <Container $type={type} data-testid="menu-panel">
    <PanelHeader label={header} isActive={isActive} onClickClose={onClose} avatar={avatar} />
    {children}
  </Container>
);

MenuPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  type: PropTypes.oneOf(['small', 'medium', 'scaling']),
  header: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(MenuPanel);
