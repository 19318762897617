import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import { CHAMP_SELECT_EVENT } from 'tv-reducers/intensive';
import useGatId from 'tv-hooks/useGatId';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';

export const SUBSCRIBE_CHAMP_SELECT = 'SUBSCRIBE_CHAMP_SELECT';
export const UNSUBSCRIBE_CHAMP_SELECT = 'UNSUBSCRIBE_CHAMP_SELECT';

export const useChampSelectEpic = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_CHAMP_SELECT, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_CHAMP_SELECT, gatId });
  }, [dispatch, gatId]);
};

const emptyObject = {};
const emptyArray = [];
const createPickBanInfo = data => {
  if (!data.championName || !data.championID) return undefined;
  return {
    id: data.championName,
    order: data.pickTurn,
    teamId: data.teamID,
    name: data.championName,
    image: data.championImageSrc,
  };
};

const champSelectEpic = factorySubscribeEpic(
  [SUBSCRIBE_CHAMP_SELECT, UNSUBSCRIBE_CHAMP_SELECT],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('actionType').equalTo('champ_select'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const firstEvent =
        payload.sort((a, b) => b.sequenceIndex - a.sequenceIndex)[0]?.details ?? emptyObject;
      const { teamOne, teamTwo, bannedChampions } = firstEvent;
      const bansByTeam = bannedChampions?.reduce((acc, champion) => {
        const banInfo = createPickBanInfo(champion) ?? emptyObject;
        const bans = acc[banInfo.teamId] ?? [];
        bans.push(banInfo);
        acc[banInfo.teamId] = bans;
        return acc;
      }, {});
      // @TODO Real teamIds later
      const picksByTeam = {
        100: [],
        200: [],
      };
      teamOne?.forEach(player => {
        const pickInfo = createPickBanInfo(player);
        if (pickInfo) picksByTeam[100].push(pickInfo);
      });
      teamTwo?.forEach(player => {
        const pickInfo = createPickBanInfo(player);
        if (pickInfo) picksByTeam[200].push(pickInfo);
      });

      const teamOneBans = bansByTeam[100]?.sort((a, b) => a.order - b.order) ?? emptyArray;
      const teamTwoBans = bansByTeam[200]?.sort((a, b) => a.order - b.order) ?? emptyArray;
      const teamOnePicks = picksByTeam[100]?.sort((a, b) => a.order - b.order) ?? emptyArray;
      const teamTwoPicks = picksByTeam[200]?.sort((a, b) => a.order - b.order) ?? emptyArray;
      return {
        type: CHAMP_SELECT_EVENT,
        gatId,
        payload: { id: gatId, teamOneBans, teamTwoBans, teamOnePicks, teamTwoPicks },
      };
    },
);

export default champSelectEpic;
