import { SET_MATCHES, SET_MATCH_PLAYER_STREAMS, SET_POPOUT_SOUND_FROM } from 'tv-constants/redux';

export const setMatches = (matches = {}) => ({
  type: SET_MATCHES,
  payload: matches,
});

export const setMatchPlayerStreams = (matchId = '', streams = []) => ({
  type: SET_MATCH_PLAYER_STREAMS,
  payload: { matchId, streams },
});

export const setPopoutSoundFrom = (streamId = '', matchId = '') => ({
  type: SET_POPOUT_SOUND_FROM,
  payload: { streamId, matchId },
});
