import PropTypes from 'prop-types';
import { TDateISO } from 'znipe-utils/typescript/Date';

export type DebugInfo = {
  id: string;
  offset: number;
  currentTime: number;
  dateTime: string;
  videoSyncTime: number;
  masterCurrentTime: number;
  timeDiff: number;
  inSync: boolean;
  playBackRate: number;
  muted: boolean;
  master: boolean;
  volume: number;
  buffering: boolean;
  paused: boolean;
};

export type VideoData = Event & {
  id: string;
  master: boolean;
  audioOnly: boolean;
};

export type TimeEvent = Omit<Event, 'target'> & {
  currentTime: number;
  seekRange: { start: number; end: number };
  target: Player;
};

export type BufferingEvent = Event & {
  buffering: boolean;
};

export type AdaptationEvent = Event & {
  newStatus: boolean;
};

export type DebugEvent = Event & DebugInfo;

export type Variant = {
  bandwidth: number;
  width: number;
  height: number;
  framerate: number;
  active: boolean;
};

export type PlayerEventsKeyMap = {
  loaded: VideoData;
  unloading: VideoData;
  volumechange: VideoData;
  abort: UIEvent;
  loadeddata: Event;
  loadedmetadata: Event;
  loadstart: Event;
  pause: Event;
  play: Event;
  playing: Event;
  seeked: Event;
  seeking: Event;
  fullscreenchange: Event;
  ended: Event;
  adaptation: Event;
  loading: Event;
  streaming: Event;
  trackschanged: Event;
  variantchanged: Event;
  abrstatuschanged: AdaptationEvent;
  buffering: BufferingEvent;
  timeupdate: TimeEvent;
  debugInfo: DebugEvent;
};

export type PlayerEventListener = <K extends keyof PlayerEventsKeyMap>(
  type: K,
  listener: (this: Player, ev: PlayerEventsKeyMap[K]) => void,
) => void;

export type Player = {
  addEventListener: PlayerEventListener;
  getCurrentTime: () => number;
  getPlaybackRate: () => number;
  getSeekRange: () => { start: number; end: number };
  getVolume: () => number;
  getVariantTracks: () => Variant[];
  isLive: () => boolean;
  isMuted: () => boolean;
  isPaused: () => boolean;
  isFullscreen: () => boolean;
  mute: () => void;
  pause: () => void;
  play: () => void;
  seek: (pts: number) => void;
  selectQuality: (quality: number) => void;
  setFullscreen: (fullscreen: boolean) => void;
  setPlaybackRate: () => void;
  setSeekRange: (start: number, end: number) => void;
  setVolume: (volume: number) => void;
  unmute: () => void;
  removeEventListener: PlayerEventListener;
  getBufferedInfo: () => unknown;
  getAudioOnlyVolume: () => number;
  isAudioOnlyMuted: () => boolean;
  setAudioOnlyVolume: (volume: number, dontMuteZeroVolume?: boolean) => void;
  setVideoStreamVolume: (volume: number, dontMuteZeroVolume?: boolean) => void;
  getVideoStreamVolume: () => number;
  unmuteVideoStreamVolume: () => void;
  muteVideoStreamVolume: () => void;
  unmuteAudioOnlyVolume: () => void;
  muteAudioOnlyVolume: () => void;
  isVideoStreamMuted: () => boolean;
  getStats: () => unknown;
  getCurrentDateTime: () => TDateISO | null;
  getStartDateTime: () => TDateISO | null;
};

export type PlayerProps = {
  current: Player;
};
export const playerDefaultProps: PlayerProps = {
  current: {
    addEventListener: () => {},
    getCurrentTime: () => 0,
    getPlaybackRate: () => 1,
    getSeekRange: () => ({ start: 0, end: 0 }),
    getVolume: () => 1,
    getVariantTracks: () => [],
    isLive: () => false,
    isMuted: () => false,
    isPaused: () => false,
    isFullscreen: () => false,
    mute: () => {},
    pause: () => {},
    play: () => {},
    seek: () => {},
    selectQuality: () => {},
    setFullscreen: () => {},
    setPlaybackRate: () => {},
    setSeekRange: () => {},
    setVolume: () => {},
    unmute: () => {},
    removeEventListener: () => {},
    getBufferedInfo: () => {},
    getAudioOnlyVolume: () => 0,
    isAudioOnlyMuted: () => false,
    getVideoStreamVolume: () => 0,
    isVideoStreamMuted: () => false,
    unmuteVideoStreamVolume: () => {},
    muteVideoStreamVolume: () => {},
    unmuteAudioOnlyVolume: () => {},
    muteAudioOnlyVolume: () => {},
    setAudioOnlyVolume: () => {},
    setVideoStreamVolume: () => {},
    getStats: () => {},
    getCurrentDateTime: () => null,
    getStartDateTime: () => null,
  },
};

export const playerPropTypes = PropTypes.shape({
  current: PropTypes.shape({
    addEventListener: PropTypes.func,
    getCurrentTime: PropTypes.func,
    getPlaybackRate: PropTypes.func,
    getSeekRange: PropTypes.func,
    getVariantTracks: PropTypes.func,
    isLive: PropTypes.func,
    isMuted: PropTypes.func,
    isPaused: PropTypes.func,
    isFullscreen: PropTypes.func,
    mute: PropTypes.func,
    pause: PropTypes.func,
    play: PropTypes.func,
    seek: PropTypes.func,
    selectQuality: PropTypes.func,
    setFullscreen: PropTypes.func,
    setPlaybackRate: PropTypes.func,
    setSeekRange: PropTypes.func,
    setVolume: PropTypes.func,
    unmute: PropTypes.func,
    removeEventListener: PropTypes.func,
    getBufferedInfo: PropTypes.func,
    getAudioOnlyVolume: PropTypes.func,
    isAudioOnlyMuted: PropTypes.func,
    getVideoStreamVolume: PropTypes.func,
    isVideoStreamMuted: PropTypes.func,
    getStats: PropTypes.func,
  }),
});
