import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import { useGetChampionAbility } from 'tv-selectors/champions/makeGetChampionAbility';
import { device } from 'znipe-styles/breakpoints';
import colors from 'znipe-styles/colors';
import withDangerouslySetInnerHTML from 'znipe-elements/layout/withDangerouslySetInnerHTML/withDangerouslySetInnerHTML';

export const DescriptionBody = styled.div`
  width: 100%;
`;

const AbilitiesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const AbilitiesImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 3px;
  margin-right: 8px;
`;

const AbilitiesItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  @media ${device.desktop} {
    margin-bottom: 24px;
  }
  p {
    margin: 0;
  }
`;

const passivesAndSpells = ['passive', 'q', 'w', 'e', 'r'];

const WrappedDescriptionBody = withDangerouslySetInnerHTML(DescriptionBody);

const AbilityDetailsList = ({ topicId }) => {
  const passive = useGetChampionAbility({ championId: topicId, type: 'passive' });
  const qAbility = useGetChampionAbility({ championId: topicId, type: 'q' });
  const wAbility = useGetChampionAbility({ championId: topicId, type: 'w' });
  const eAbility = useGetChampionAbility({ championId: topicId, type: 'e' });
  const rAbility = useGetChampionAbility({ championId: topicId, type: 'r' });

  const createAbilitiesList = useMemo(
    () =>
      [passive, qAbility, wAbility, rAbility, eAbility].map(
        ({ name, description, image }, index) => {
          // This is a mock. Should be removed when the full image
          // paths are in the schema
          const imageUrl =
            passivesAndSpells[index] === 'passive'
              ? 'https://ddragon.leagueoflegends.com/cdn/11.8.1/img/passive/Annie_Passive.png'
              : `https://ddragon.leagueoflegends.com/cdn/11.8.1/img/spell/Annie${passivesAndSpells[
                  index
                ].toUpperCase()}.png`;
          return (
            <AbilitiesItem>
              <AbilitiesImage src={image || imageUrl} alt={name} />
              <div>
                <Typography type="title-m">
                  {name} ({passivesAndSpells[index]})
                </Typography>
                <Typography type="paragraph-m" color={colors.grey75}>
                  <WrappedDescriptionBody HTMLString={description} />
                </Typography>
              </div>
            </AbilitiesItem>
          );
        },
      ),
    [eAbility, passive, qAbility, rAbility, wAbility],
  );

  return <AbilitiesList>{createAbilitiesList}</AbilitiesList>;
};

AbilityDetailsList.propTypes = {
  topicId: PropTypes.string.isRequired,
};

export default memo(AbilityDetailsList);
