import { memo, useMemo } from 'react';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import { predictionType } from '../../../types';
import { SelectResultTableColumnWrapper, IconWrapper } from './PredictionDisplay.styles';
import { TeamLogoComponent, PlayerTeamLogoComponent } from './PredictionDisplayPlayerTeamImage';
import { targetTypes, status } from '../../../predictionConstants';

const PredictionDisplaySelectOptionResult = ({ prediction }) => {
  const { options, predictedValue, outcome } = prediction;

  const { label, target, targetType } = useMemo(
    () => Object.values(options).find(option => option.value === predictedValue),
    [predictedValue, options],
  );

  const team = targetType === targetTypes.TEAM;
  const player = targetType === targetTypes.PLAYER;
  const isNotPending = outcome !== status.pending;
  const isCorrect = outcome === predictedValue;

  return (
    <SelectResultTableColumnWrapper>
      {team && <TeamLogoComponent size="small" teamId={target} label={label} />}
      {player && <PlayerTeamLogoComponent size="small" playerId={target} label={label} />}
      {!team && !player && <Typography type="title-m">{label}</Typography>}
      {isNotPending && (
        <IconWrapper>
          <Icon
            type={isCorrect ? 'check' : 'close'}
            size={16}
            fillColor={isCorrect ? colors.beanGreen : colors.flamingo}
          />
        </IconWrapper>
      )}
    </SelectResultTableColumnWrapper>
  );
};

PredictionDisplaySelectOptionResult.propTypes = {
  prediction: predictionType.isRequired,
};

export default memo(PredictionDisplaySelectOptionResult);
