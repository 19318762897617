import { useLocation, useParams } from 'react-router-dom';

const useSeeAllLink = (pageType = 'browse') => {
  const { packageSlug } = useParams();
  const { search = '', pathname } = useLocation();
  const baseUrl = packageSlug ? `/${packageSlug}` : '';
  const pageUrl = `${baseUrl}/${pageType}`;

  if (pageType === 'topic') {
    return {
      players: `${pathname}/players${search}`,
      teams: `${pathname}/teams${search}`,
      events: `${pathname}/events${search}`,
      champions: `${pathname}/champions${search}`,
      vods: `${pathname}/vods${search}`,
      highlights: `${pathname}/highlights${search}`,
      editorials: `${pathname}/editorials${search}`,
      schedule: `${pathname}/schedule${search}`,
    };
  }

  return {
    players: `${pageUrl}/players${search}`,
    teams: `${pageUrl}/teams${search}`,
    events: `${pageUrl}/events${search}`,
    champions: `${pageUrl}/champions${search}`,
    vods: `${pageUrl}/vods${search}`,
    highlights: `${pageUrl}/highlights${search}`,
    editorials: `${pageUrl}/editorials${search}`,
    schedule: `${pageUrl}/schedule${search}`,
  };
};

export default useSeeAllLink;
