import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FilterTopicLink from 'tv-modules/Filters/FilterDetails/components/FilterTopicLink';
import { suggestionShape } from 'tv-modules/Search/SearchSuggestions/SearchSuggestions';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import { TEAMS, CHAMPIONS, PLAYERS, EVENTS, selectionShape } from '../../Filters.constants';

export const EmptyMessageWrapper = styled.div`
  padding: 16px;
`;

const supportedTopics = [TEAMS, CHAMPIONS, PLAYERS, EVENTS];

const SearchSuggestions = ({ selection, suggestions, onChangeSelection = () => {} }) => {
  const handleClickTopic = useCallback(
    (id, hit, category, isSelected) => {
      const newValue = isSelected
        ? selection[category].filter(item => item.id !== id)
        : [...selection[category], hit];
      onChangeSelection({ [category]: newValue });
    },
    [selection, onChangeSelection],
  );

  const suggestionByCategory = useMemo(
    () => suggestions.filter(({ _type }) => supportedTopics.indexOf(_type) !== -1),
    [suggestions],
  );

  const suggestionElements = suggestionByCategory.map(hit => {
    const { id, _type: category } = hit;
    const isSelected = Boolean(selection[category].find(item => item?.id === id));
    const onClick = () => handleClickTopic(id, hit, category, isSelected);
    return <FilterTopicLink data={hit} type={category} isSelected={isSelected} onClick={onClick} />;
  });

  return suggestionElements.length > 0 ? (
    suggestionElements
  ) : (
    <EmptyMessageWrapper>
      <NoResults size="small" type="filter" padding="0px" />
    </EmptyMessageWrapper>
  );
};

SearchSuggestions.propTypes = {
  selection: PropTypes.shape(selectionShape).isRequired,
  suggestions: PropTypes.shape(suggestionShape).isRequired,
  onChangeSelection: PropTypes.func,
};

export default SearchSuggestions;
