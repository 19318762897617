import { useState, useRef, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import withTheme from 'znipe-themes/hocs/withTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import { useSpring, animated, config } from '@react-spring/web';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import convertTimestampToReadableTime from 'znipe-utils/date/convertTimestampToReadableTime';
import themes from './SeekBar.themes';

import {
  Slider,
  Tooltip,
  Handle,
  Bar,
  Bars,
  BufferBar,
  HoverBar,
  Track,
  Input,
} from './SeekBar.styles';
import { EXPANDED, ZERO, MINIMIZED } from './SeekBar.constants';

const calculateBufferTrigger = playerRef => {
  const player = playerRef?.current;
  if (!player) return 0;
  const { video } = player.getBufferedInfo() || [];
  if (video?.length > 0) {
    return Math.floor(video[0].end);
  }
  return 0;
};

const AnimatedHandle = animated(Handle);
const AnimatedBars = animated(Bars);

const SeekBar = ({
  timeInfo,
  playerRef = playerDefaultProps,
  isMobile = false,
  isFullscreen = false,
  isLiveStream = false,
  isWatchingLive = false,
  pauses = [],
  markers = { markerTexts: [], markerDots: [] },
}) => {
  const { currentTime = 0, seekRange = {} } = timeInfo;
  const { start = 0, end = 1 } = seekRange;
  const [value, setValue] = useState(currentTime);
  const [bufferProgress, setBufferProgress] = useState(() => calculateBufferTrigger(playerRef));
  const [isHovering, setIsHovering] = useState(false);
  const [mouseHoverPos, setMouseHoverPos] = useState(0);
  const seekbarUIState = useRef({ isSliding: false, prevSeekValue: 0 });
  const inputRef = useRef(null);
  const sliderRef = useRef(null);
  const timer = useRef(null);
  const progressPercentage = (((value - start) * 100) / (end - start)).toFixed(2);
  const bufferPercentage = ((bufferProgress / end) * 100).toFixed(2);
  const { markerTexts, markerDots } = markers;
  const prefersReducedMotion = usePrefersReducedMotion();

  const { height, width, opacity, barHeight } = useSpring({
    height: isHovering || isMobile ? EXPANDED : ZERO,
    barHeight: isHovering || isMobile ? '7px' : MINIMIZED,
    width: isHovering || isMobile ? EXPANDED : ZERO,
    opacity: isHovering || isMobile ? 1 : 0,
    config: config.gentle,
    immediate: prefersReducedMotion,
  });

  const handleSeek = useMemo(
    () =>
      throttle(seekValue => {
        const player = playerRef.current;
        if (!player || isNaN(seekValue)) return;
        const currentPlayerTime = player.getCurrentTime();
        if (Math.abs(seekValue - currentPlayerTime) < 0.5) return; // Do not search if searching < 0.5s
        player.seek(seekValue);
      }, 250),
    [playerRef],
  );

  const handleMouseUp = useCallback(() => {
    timer.current = setTimeout(() => {
      seekbarUIState.current.isSliding = false;
    }, 100);
  }, []);

  const handleChange = useCallback(() => {
    const val = parseFloat(inputRef.current.value);
    seekbarUIState.current.isSliding = true;
    setValue(val);
    handleSeek(val);
  }, [handleSeek]);

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) {
      setIsHovering(true);
    }
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) {
      setIsHovering(false);
    }
  }, [isMobile]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Calculate on time change
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;
    if (!seekbarUIState.current.isSliding && !isLiveStream) {
      setBufferProgress(calculateBufferTrigger(playerRef));
    }
  }, [currentTime, isLiveStream, playerRef]);

  useEffect(() => {
    if (typeof currentTime === 'undefined') return;
    seekbarUIState.current = {
      ...seekbarUIState.current,
      prevSeekValue: value,
    };
    if (isLiveStream && isWatchingLive) {
      setValue(end);
    }
    if (!seekbarUIState.current.isSliding && !isWatchingLive) {
      setValue(currentTime);
    }
  }, [currentTime, end, isLiveStream, value, isWatchingLive]);

  useEffect(() => () => clearTimeout(timer.current), []);

  const hoverTimeInfo = useMemo(() => {
    const time = end - start;
    if (isLiveStream) {
      const hoverTime = (1 - mouseHoverPos / 100) * (end - start);
      return `-${convertTimestampToReadableTime(hoverTime)}`;
    }
    const hoverTime = (mouseHoverPos / 100) * time;
    return convertTimestampToReadableTime(hoverTime);
  }, [end, start, mouseHoverPos, isLiveStream]);

  useEffect(() => {
    const getMousePos = event => {
      if (!isHovering) return;
      const inputRect = inputRef.current.getBoundingClientRect();
      const mousePosBarPercent = ((event.pageX - inputRect.left) / inputRect.width) * 100;
      setMouseHoverPos(mousePosBarPercent);
    };

    window.addEventListener('mousemove', getMousePos);

    return () => {
      window.removeEventListener('mousemove', getMousePos);
    };
  }, [isHovering]);

  return (
    <>
      {markerTexts.length > 0 && !isMobile && markerTexts}
      <div
        style={{
          transform: `translateX(${mouseHoverPos}%) translateX(2px)`,
        }}
      >
        <Tooltip data-testid="seek-tooltip" $isActive={isHovering}>
          <Typography type="title-xs" color="textSecondary">
            {hoverTimeInfo}
          </Typography>
        </Tooltip>
      </div>
      <Slider
        ref={sliderRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isMobile={isMobile}
        isFullscreen={isFullscreen}
        data-testid="seek-container"
      >
        <AnimatedBars style={{ height: barHeight }}>
          <BufferBar style={{ transform: `translateX(${bufferPercentage - 100}%)` }} />
          <HoverBar
            style={{ transform: `translateX(${mouseHoverPos - 100}%)` }}
            $isActive={isHovering}
          />
          <Bar
            style={{ transform: `translateX(${progressPercentage - 100}%)` }}
            data-testid="seek-slider"
          />
          <Track />
          {markerDots.length > 0 && markerDots}
        </AnimatedBars>
        <div
          style={{
            transform: `translateX(${progressPercentage}%) translateX(-4px) translateY(-50%)`,
          }}
        >
          <AnimatedHandle style={{ height, width, opacity }} />
        </div>
        <Input
          type="range"
          step="any"
          ref={inputRef}
          min={start}
          max={end}
          value={value}
          onChange={handleChange}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleMouseUp}
          onClick={handleMouseUp}
          data-testid="seek-range"
        />
      </Slider>
      {pauses.length > 0 && pauses}
    </>
  );
};

SeekBar.propTypes = {
  timeInfo: PropTypes.shape({
    currentTime: PropTypes.number,
    seekRange: PropTypes.shape({}),
  }).isRequired,
  playerRef: playerPropTypes,
  isMobile: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isLiveStream: PropTypes.bool,
  isWatchingLive: PropTypes.bool,
  markers: PropTypes.shape({
    markerTexts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
    markerDots: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  }),
  pauses: PropTypes.arrayOf(PropTypes.node),
};

export default withTheme(memo(SeekBar), themes, 'seekBar');
