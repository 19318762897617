import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  UPPER_BRACKET,
  LOWER_BRACKET,
  columnPropShape,
} from 'tv-modules/Standings/Bracket/utils/constants';
import SubList from './SubList';
import { ListContainer } from '../CompactBracket.styles';

const emptyColumn = { name: '', matchups: [] };

const MatchupList = ({
  upper = emptyColumn,
  lower = emptyColumn,
  final = emptyColumn,
  selectedTeams = [],
}) => {
  const upperMatchups = useMemo(
    () =>
      upper && upper.matchups.length === 0 ? null : (
        <SubList matchups={upper.matchups} title={UPPER_BRACKET} selectedTeams={selectedTeams} />
      ),
    [selectedTeams, upper],
  );
  const lowerMatchups = useMemo(
    () =>
      lower && lower.matchups.length === 0 ? null : (
        <SubList matchups={lower.matchups} title={LOWER_BRACKET} selectedTeams={selectedTeams} />
      ),
    [selectedTeams, lower],
  );
  const finalMatch = useMemo(
    () =>
      final && final.matchups.length === 0 ? null : (
        <SubList matchups={final.matchups} selectedTeams={selectedTeams} />
      ),
    [selectedTeams, final],
  );

  if (finalMatch) {
    return <ListContainer>{finalMatch}</ListContainer>;
  }

  return (
    <ListContainer data-testid="list-container">
      {upperMatchups}
      {lowerMatchups}
    </ListContainer>
  );
};

MatchupList.propTypes = {
  upper: PropTypes.shape(columnPropShape),
  lower: PropTypes.shape(columnPropShape),
  final: PropTypes.shape(columnPropShape),
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default memo(MatchupList);
