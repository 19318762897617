import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetStream from 'tv-selectors/streams/makeGetStream';

const makeGetStreamSrcOffsetId = () => {
  const getStream = makeGetStream();
  return createSelector(
    [getStream],
    selectedStream => (selectedStream.hls || selectedStream.dash || {}).srcOffsetId || '',
  );
};

export const useGetStreamSrcOffsetId = props =>
  useMemoizedGqlSelector(makeGetStreamSrcOffsetId, props);

export default makeGetStreamSrcOffsetId;
