import { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SingleViewPlayer from 'znipe-player/src/components/SingleView/SingleView';
import ScreenOverlay from 'tv-modules/Player/ScreenOverlay/ScreenOverlay';
import useAnalyticsParams from 'tv-hooks/useAnalyticsParams';
import usePrevious from 'znipe-hooks/usePrevious';
import { useGetAutoplayEnabled } from 'tv-selectors/control/makeGetAutoplayEnabled';
import { useDebug } from 'tv-selectors/ui/makeGetDebug';
import { useQuality } from 'tv-selectors/control/makeGetQuality';
import subscribe from 'znipe-utils/web/subscribe';
import { useVolume } from 'tv-selectors/control/makeGetVolume';
import { useMute } from 'tv-selectors/control/makeGetMute';
import { setMute } from 'tv-actions/old/control';
import AudioProvider from 'tv-providers/AudioProvider';

const Container = styled.div`
  position: relative;
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const HighlightVideoFrame = ({ videoId, src, thumbnail, onWatchNext }) => {
  const subscriptions = useRef([]);
  const analyticsParams = useAnalyticsParams(videoId, 'video');
  const [hasFinsihedPlaying, setHasFinishedPlaying] = useState(false);
  const [shouldAutoplayCurrentVideo, setShouldAutoplayCurrentVideo] = useState(true);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const prevVideoId = usePrevious(videoId);
  const debug = useDebug();
  const quality = useQuality();
  const dispatch = useDispatch();
  const volume = useVolume();
  const mute = useMute();
  const userHasEnabledAutoplaying = useGetAutoplayEnabled();

  const onReady = useCallback(() => {
    const player = playerRef.current;
    const onVideoEnd = () => setHasFinishedPlaying(true);
    const onRewatch = () => setHasFinishedPlaying(false);
    const handlePlayerMuteState = () => {
      player.setVolume(volume, false);
      if (mute) {
        player.mute();
      } else {
        player.unmute();
      }
      dispatch(setMute(mute));
      player.removeEventListener('loaded', handlePlayerMuteState);
    };

    const handlePlayerQuality = () => player.selectQuality(quality);
    subscriptions.current.push(subscribe(player, 'ended', onVideoEnd));
    subscriptions.current.push(subscribe(player, 'loaded', handlePlayerMuteState));
    subscriptions.current.push(subscribe(player, 'playing', onRewatch));
    subscriptions.current.push(subscribe(player, 'loaded', handlePlayerQuality));
  }, [dispatch, mute, quality, volume]);

  useEffect(() => {
    if (prevVideoId && prevVideoId !== videoId) {
      setShouldAutoplayCurrentVideo(true);
      setHasFinishedPlaying(false);
    }
  }, [prevVideoId, videoId]);

  const handleAutoplayCancel = useCallback(() => setShouldAutoplayCurrentVideo(false), []);
  useEffect(() => () => subscriptions.current.forEach(unsubscribe => unsubscribe()), []);

  return (
    <AudioProvider playerRef={playerRef}>
      <Container ref={playerContainerRef}>
        <SingleViewPlayer
          ref={playerRef}
          poster={thumbnail}
          src={src}
          muted={mute}
          analyticsParams={analyticsParams}
          debug={debug}
          onReady={onReady}
          autoPlay
          loop
        />
        <OverlayWrapper>
          <ScreenOverlay
            playerRef={playerRef}
            playerContainerRef={playerContainerRef}
            hasFinishedPlaying={hasFinsihedPlaying}
            onWatchNext={onWatchNext}
            playlistAutoplayingEnabled={userHasEnabledAutoplaying && shouldAutoplayCurrentVideo}
            onAutoplayCancel={handleAutoplayCancel}
            hasScreenControls={false}
          />
        </OverlayWrapper>
      </Container>
    </AudioProvider>
  );
};

HighlightVideoFrame.propTypes = {
  videoId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  onWatchNext: PropTypes.func,
};

export default HighlightVideoFrame;
