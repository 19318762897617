import { useCallback, useState } from 'react';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import Proptypes from 'prop-types';
import Selector from 'tv-modules/Selector/Selector';
import useDimensions from 'react-cool-dimensions';
import StatsSelectorGroup from 'tv-modules/Selector/StatsSelectorGroup/StatsSelectorGroup';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetStreams } from 'tv-selectors/streams/makeGetStreams';
import { useGetMatchGameTitle } from 'tv-selectors/match/makeGetMatchGameTitle';
import useGameStreamOffsets from 'tv-hooks/useGameStreamOffsets';
import buildStreamObject from 'tv-utils/buildStreamObject';
import { setMatchPlayerStreams } from 'tv-actions/matches';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import { GlobalStreamsWrapper, Container, PremiumBorder } from './MobileStreamList.styles';

const MobileStreamList = ({
  matchId,
  selectedMatchGameIndex,
  onClick,
  onStreamItemHover = () => {},
  afterStreamSelect = () => {},
}) => {
  const gqlDispatch = useGqlStoreDispatch();
  const [size, setSize] = useState('small');
  const playerStreams = useGetMatchPlayerStreams({ matchId, selectedMatchGameIndex });
  const globalStreams = useGetGameGlobalStreams({
    matchId,
    selectedMatchGameIndex,
  });
  const hasPremiumAccess = useHasPremiumAccess();
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });
  const offsets = useGameStreamOffsets(matchId, gameId);
  const streams = useGetStreams();
  const gameTitle = useGetMatchGameTitle({ matchId });

  const availableGlobalStreams = globalStreams.filter(stream => stream.type !== 'audio');
  const isTabletOrGreater = useGetIsTabletOrGreater();

  const { ref } = useDimensions({
    breakpoints: { small: 0, medium: 400 },
    useBorderBoxSize: true,
    onResize: ({ currentBreakpoint }) => setSize(currentBreakpoint),
  });

  const onStreamSelect = useCallback(
    (playerId, playerPosition, streamId) => {
      if (onClick) {
        onClick(playerId, playerPosition, streamId);
        return;
      }
      const selectedStream = streams[streamId] || {};
      const { tokenExpireTime } = selectedStream;
      const currentStreamInformation = selectedStream.hls || selectedStream.dash || {};
      const stream = buildStreamObject({
        streamId,
        initialSoundFrom: streamId,
        offsets,
        tokenExpireTime,
        ...currentStreamInformation,
      });

      if (stream) gqlDispatch(setMatchPlayerStreams(matchId, [stream]));
      afterStreamSelect();
    },
    [matchId, offsets, streams, gqlDispatch, afterStreamSelect, onClick],
  );

  return (
    <Container ref={ref} data-testid="mobile-stream-list">
      <GlobalStreamsWrapper
        data-testid="global-stream-wrapper"
        itemsCount={availableGlobalStreams.length}
        $size={size}
      >
        {availableGlobalStreams.map(stream => {
          const {
            streamId,
            name: streamName,
            imageSrc: image,
            locked: isLocked,
            type: streamType,
          } = stream || {};
          const isSelected = !!playerStreams.find(s => s.id === streamId);
          const handleStreamSelect = () => onStreamSelect(null, null, streamId);
          return (
            <PremiumBorder $isPremium={isLocked && !hasPremiumAccess} key={streamId}>
              <Selector
                key={streamId}
                type="slim-horizontal"
                streamId={streamId}
                label={streamName}
                image={image}
                imageType="image"
                size={size}
                isSelected={isSelected}
                isLocked={isLocked}
                onClick={handleStreamSelect}
                onHover={onStreamItemHover}
                globalStreamType={streamType}
                isGlobalStream
              />
            </PremiumBorder>
          );
        })}
      </GlobalStreamsWrapper>
      <StatsSelectorGroup
        matchId={matchId}
        onItemClick={onStreamSelect}
        onItemHover={onStreamItemHover}
        type={isTabletOrGreater ? 'vertical' : 'horizontal'}
        size={isTabletOrGreater ? 'small' : size}
        selectedMatchGameIndex={selectedMatchGameIndex}
        hasPremiumContent
        gameTitle={gameTitle}
      />
    </Container>
  );
};

export default MobileStreamList;

MobileStreamList.propTypes = {
  matchId: Proptypes.string.isRequired,
  selectedMatchGameIndex: Proptypes.number.isRequired,
  onClick: Proptypes.func,
  onStreamItemHover: Proptypes.func,
  afterStreamSelect: Proptypes.func,
};
