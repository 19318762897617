import { useMemo } from 'react';
import PropTypes from 'prop-types';
import LiveDot from 'znipe-elements/data-display/LiveDot/LiveDot';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, LiveDotWrapper, LabelWrapper } from './ProductionStatusLabel.styles';

const ProductionStatusLabel = ({ label, size = 'small', noBackground = false }) => {
  const labelSize = useMemo(() => {
    switch (size) {
      case 'xsmall':
      case 'small':
        return 'title-xs';
      default:
        return 'title-m';
    }
  }, [size]);

  const dotSize = useMemo(() => {
    switch (size) {
      case 'large':
        return 'medium';
      default:
        return size;
    }
  }, [size]);
  return (
    <Container data-testid="production-status-label" size={size} noBackground={noBackground}>
      {label.toLowerCase() === 'live' && (
        <LiveDotWrapper>
          <LiveDot size={dotSize} />
        </LiveDotWrapper>
      )}
      <LabelWrapper size={size}>
        <Typography type={labelSize}>{label}</Typography>
      </LabelWrapper>
    </Container>
  );
};

ProductionStatusLabel.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  noBackground: PropTypes.bool,
};

export default ProductionStatusLabel;
