import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import SubscribeButton from '../SubscribeButton/SubscribeButton';

const SMALL = 'small';
const MEDIUM = 'medium';

export const description = {
  [SMALL]: 'Upgrade to Premium to unlock triple view, and other features!',
  [MEDIUM]: 'Upgrade to Premium to unlock triple and quad view, and other features!',
};

const Container = styled.div`
  width: 100%;
`;

const TextWrapper = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  p {
    margin: unset;
  }
  margin-bottom: ${({ $size }) => ($size === SMALL ? 8 : 16)}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const UnlockPremium = ({ size = MEDIUM }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const subscribeButtonSize = useMemo(
    () => (isDesktopOrGreater ? 'medium' : 'small'),
    [isDesktopOrGreater],
  );
  return (
    <Container $size={size}>
      <TextWrapper $size={size}>
        <Typography type="paragraph-s">{description[size]}</Typography>
      </TextWrapper>
      <ButtonWrapper>
        <SubscribeButton size={subscribeButtonSize} />
      </ButtonWrapper>
    </Container>
  );
};

UnlockPremium.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM]),
};

export default memo(UnlockPremium);
