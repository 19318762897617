import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';
import makeGetRosters from 'tv-selectors/roster/makeGetRosters';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const emptyArray = [];

const makeGetTeamRoster = () => {
  const getTeams = makeGetTeams();
  const getRosters = makeGetRosters();
  return createSelector([getTeams, getTeamIdFromProps, getRosters], (allTeams, teamId, rosters) => {
    if (!allTeams || !teamId) return emptyArray;
    const id = allTeams[teamId]?.roster ?? '';
    return rosters[id]?.value ?? emptyArray;
  });
};

export const useGetTeamRoster = props => useMemoizedGqlSelector(makeGetTeamRoster, props);

export default makeGetTeamRoster;
