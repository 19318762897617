const queryStringToObject = (queryString = '') => {
  const query = {};
  if (!queryString || queryString.indexOf('?') === -1) return query;
  if (!queryString || queryString.indexOf('?') > 0) return query;

  const trimmed = queryString.replace('?', '').trim();
  const split = trimmed.split('&');

  split.forEach(q => {
    const [key, value] = q.split('=');
    if (!key) return;
    if (typeof value === 'undefined') query[key] = 'true';
    // If no value, default to true
    else if (value.indexOf(',') !== -1) query[key] = value.split(',');
    else query[key] = value;
  });

  return query;
};

export default queryStringToObject;
