import { memo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  themes,
  LogoWrapper,
  IconWrapper,
  TeamInfoWrapper,
  TextWrapper,
  TeamLogo,
} from './Matchup.styles';

const TeamInfo = ({
  id,
  name,
  score,
  logo,
  isLoser = null,
  isDoubleElimination = false,
  highlightArrow = false,
}) => {
  const {
    containerStroke,
    textPrimary,
    secondaryTextColor,
    matchup: { scoreTextWidth },
  } = useThemeContext();

  const textColor = isLoser ? secondaryTextColor : textPrimary;

  return (
    <TeamInfoWrapper data-testid={id}>
      <LogoWrapper highlight={!!isLoser}>
        {logo ? (
          <TeamLogo logo={logo} />
        ) : (
          <Icon type="fallbackTeamLogo" size={32} fillColor={containerStroke} />
        )}
      </LogoWrapper>
      <TextWrapper color={textColor} data-testid={`team-name-${id}`}>
        <Typography type="paragraph-l">{name}</Typography>
      </TextWrapper>
      {isDoubleElimination && isLoser && (
        <IconWrapper>
          <Icon
            type={highlightArrow ? 'arrowDownExtendedGradient' : 'arrowDownExtended'}
            size={24}
            fillColor={secondaryTextColor}
          />
        </IconWrapper>
      )}
      <TextWrapper width={scoreTextWidth} color={textColor} data-testid={`team-score-${id}`}>
        <Typography type="title-m">{score}</Typography>
      </TextWrapper>
    </TeamInfoWrapper>
  );
};

TeamInfo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  logo: PropTypes.string,
  isLoser: PropTypes.bool,
  isDoubleElimination: PropTypes.bool,
  highlightArrow: PropTypes.bool,
};

export default withTheme(memo(TeamInfo), themes, 'matchup');
