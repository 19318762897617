import { useCallback, useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';

const HIGHT_OFFSET = 20;
const useScrollToBottom = (
  ref: React.RefObject<HTMLDivElement | undefined>,
  hightOffset = HIGHT_OFFSET,
) => {
  const [isAtBottom, setIsAtBottom] = useState(true);

  const scrollToBottom = useCallback(() => {
    if (!ref.current) return;
    // eslint-disable-next-line no-param-reassign
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [ref]);

  useEffect(() => {
    const target = ref.current;
    if (!target) return undefined;
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = target;
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - hightOffset);
    };

    const scrollObserver$ = fromEvent(ref.current, 'scroll');

    const subscription = scrollObserver$.subscribe(handleScroll);

    return () => subscription.unsubscribe();
  }, [hightOffset, ref]);

  useEffect(() => {
    if (!isAtBottom || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      scrollToBottom();
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [isAtBottom, ref, scrollToBottom]);

  return { isAtBottom, scrollToBottom };
};

export default useScrollToBottom;
