import getLastItem from 'znipe-utils/misc/getLastItem';

export const playerNodeReducer = (acc, cv) => {
  const { id } = cv.node;
  const imageArr = cv.node.image || [];
  const nameArr = cv.node.name || [];
  const teamNameArr = cv.node.team.name || [];
  const teamLogoDarkArr = cv.node.team.logoDark || [];
  const teamLogoLightArr = cv.node.team.logoLight || [];

  const image = imageArr[imageArr.length - 1]?.value;
  const nickname = nameArr[nameArr.length - 1]?.value;
  const teamName = teamNameArr[teamNameArr.length - 1]?.value;
  const teamLogoDark = teamLogoDarkArr[teamLogoDarkArr.length - 1]?.value;
  const teamLogoLight = teamLogoLightArr[teamLogoLightArr.length - 1]?.value;
  acc.push({ id, image, nickname, teamName, teamLogoDark, teamLogoLight });
  return acc;
};

const getLatestTeamData = (id, nameArr, logoDarkArr, logoLightArr, countryArr) => {
  const name = nameArr[nameArr.length - 1]?.value;
  const logoDark = logoDarkArr[logoDarkArr.length - 1]?.value;
  const logoLight = logoLightArr[logoLightArr.length - 1]?.value;
  const country = countryArr[countryArr.length - 1]?.value;
  return { id, logoDark, logoLight, name, country };
};

export const teamNodeReducer = (acc, cv) => {
  const { id } = cv.node;
  const nameArr = cv.node.name || [];
  const logoDarkArr = cv.node.logoDark || [];
  const logoLightArr = cv.node.logoLight || [];
  const countryArr = cv.node.country || [];
  acc.push(getLatestTeamData(id, nameArr, logoDarkArr, logoLightArr, countryArr));
  return acc;
};

export const teamDetailReducer = (acc, cv) => {
  const { id } = cv;
  const nameArr = cv.name || [];
  const logoDarkArr = cv.logoDark || [];
  const logoLightArr = cv.logoLight || [];
  const countryArr = cv.country || [];
  acc.push(getLatestTeamData(id, nameArr, logoDarkArr, logoLightArr, countryArr));
  return acc;
};

export const tournamentTeamNodeReducer = (acc, cv) => {
  const { teams } = cv.node;
  const teamsArr = teams
    ?.filter(team => team.teamDetails)
    .reduce((teamAcc, teamCv) => teamDetailReducer(teamAcc, teamCv.teamDetails), []);
  const result = Array.from(
    new Map([...acc, ...teamsArr].map(team => [team.id, team])).values(),
  ).sort((a, b) => a.name.localeCompare(b.name));
  return result;
};

export const eventNodeReducer = (acc, cv) => {
  const { id, name, humanReadableId, region, logo } = cv.node;
  acc.push({ id, name, region, image: logo, humanReadableId });
  return acc;
};

export const tournamentNodeReducer = (acc, cv) => {
  const {
    humanReadableId,
    event,
    location: { country },
  } = cv.node;
  const { id, name, logo } = event;
  acc.push({ id, name, region: country, image: logo, humanReadableId });
  return acc;
};

export const videoNodeReducer = (acc, cv) => {
  const { id } = cv.node;
  acc.push({ id });
  return acc;
};

export const championNodeReducer = (acc, cv) => {
  const { id, champId, image, class: classType, name, title } = cv.node;
  acc.push({
    id,
    champId,
    image,
    class: classType[0],
    name: getLastItem(name),
    title: getLastItem(title),
  });
  return acc;
};

export const convertPlayerNodeToObject = players => players?.edges?.reduce(playerNodeReducer, []);

export const convertTeamNodeToObject = teams => teams?.edges?.reduce(teamNodeReducer, []);

export const convertVideoNodeToObject = vods => vods?.edges?.reduce(videoNodeReducer, []);

export const convertChampionNodeToObject = champions =>
  champions?.edges?.reduce(championNodeReducer, []);
