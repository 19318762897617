/* eslint-disable react/no-array-index-key */
import { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import SearchResultItem from '../SearchResultItem/SearchResultItem';
import { SMALL, MEDIUM, RIOT_CHAMPION, CHAMPION } from '../constants';
import { BackgroundLayer } from '../Shared.styles';
import {
  Container,
  Title,
  Results,
  List,
  Row,
  ContentWrapper,
  IconWrapper,
  TextWrapper,
  HorizontalDivider,
} from './SearchHistory.styles';

const SearchHistory = ({
  label = '',
  searches = [],
  queries = [],
  size = SMALL,
  onClickResultItem = () => {},
  onClickSearchQuery = () => {},
}) => {
  const sizes = useMemo(
    () =>
      size === SMALL
        ? { title: 'title-m', query: 'paragraph-m', spacing: 16 }
        : { title: 'title-l', query: 'paragraph-l', spacing: 24 },
    [size],
  );

  const handleClickResultItem = useCallback(
    (category, item) => onClickResultItem(category, item),
    [onClickResultItem],
  );

  const searchesElement = useMemo(() => {
    if (searches.length === 0) return null;
    return (
      <Results spacing={sizes.spacing}>
        {searches.map(item => {
          const { id, category, image, name, nickname, logoDark, logoLight } = item;
          const onClick = () => {
            handleClickResultItem(category, item);
          };
          const title = name || nickname;
          const imageUrl = image || logoLight || logoDark;
          const itemType = category === RIOT_CHAMPION ? CHAMPION : category;
          return (
            <SearchResultItem
              key={`result-item-${id}`}
              title={title}
              type={itemType}
              onClick={onClick}
              imageUrl={imageUrl}
              size={size}
            />
          );
        })}
      </Results>
    );
  }, [searches, size, handleClickResultItem, sizes.spacing]);

  const handleClickQueryItem = useCallback(
    query => onClickSearchQuery(query),
    [onClickSearchQuery],
  );

  const generateQueryElements = useCallback(
    () =>
      queries.map(query => {
        const onClick = () => handleClickQueryItem(query);
        return (
          <Row key={query} spacing={sizes.spacing} onClick={onClick} data-testid={`query-${query}`}>
            <HorizontalDivider />
            <ContentWrapper spacing={sizes.spacing}>
              <IconWrapper>
                <Icon type="search" size={size === SMALL ? 16 : 24} />
              </IconWrapper>
              <TextWrapper>
                <Typography type={sizes.query}>{`“${query}”`}</Typography>
              </TextWrapper>
            </ContentWrapper>
            <BackgroundLayer />
          </Row>
        );
      }),
    [queries, size, sizes.query, sizes.spacing, handleClickQueryItem],
  );

  const queriesElement = useMemo(() => {
    if (queries.length === 0) return null;
    const listItemElements = generateQueryElements();
    return <List>{listItemElements}</List>;
  }, [queries, generateQueryElements]);

  return (
    <Container>
      {label && (
        <Title data-testid="title" spacing={sizes.spacing}>
          <Typography type={sizes.title}>{label}</Typography>
        </Title>
      )}
      {searchesElement}
      {queriesElement}
    </Container>
  );
};

export const searchesShape = {
  id: PropTypes.string,
  country: PropTypes.string,
  game: PropTypes.string,
  logoDark: PropTypes.string,
  matchesPlayed: PropTypes.number,
  created: PropTypes.string,
  logoLight: PropTypes.string,
  name: PropTypes.string,
  shortName: PropTypes.string,
  _type: PropTypes.string,
};

SearchHistory.propTypes = {
  label: PropTypes.string,
  searches: PropTypes.arrayOf(PropTypes.shape(searchesShape)),
  queries: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf([SMALL, MEDIUM]),
  onClickResultItem: PropTypes.func,
  onClickSearchQuery: PropTypes.func,
};

export default memo(SearchHistory);
