import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Barlow';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    src: local('Barlow Regular'), local('Barlow-Regular'),
      url('https://assets.znipe.tv/fonts/Barlow/Barlow-Regular.ttf') format('ttf');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
      U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html {
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    background: ${props => props.theme.primaryBackgroundColor};
    color: ${props => props.theme.primaryTextColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  #root {
    height: 100%;
  }
  body,
  input,
  button {
    font-family: ${props => props.theme.primaryFont};
  }
  a {
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  /* stylelint-disable a11y/no-outline-none */
  a:focus,
  a:active,
  button,
  input[type='reset']::-moz-focus-inner,
  input[type='button']::-moz-focus-inner,
  input[type='submit']::-moz-focus-inner,
  select::-moz-focus-inner,
  input[type='file'] > input[type='button']::-moz-focus-inner {
    outline: none;  
  }
  /* stylelint-enable a11y/no-outline-none */
  
  .disable-scroll {
    overflow-y: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

export default GlobalStyle;
