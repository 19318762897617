/* eslint-disable react/jsx-no-useless-fragment */
import { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import makeGetStrings from 'tv-selectors/localization/makeGetStrings';
import logger from 'znipe-logger';

const normalize = (string = '') => {
  if (typeof string !== 'string') return string;
  return string.toLowerCase();
};

const Localized = memo(({ string, strings }) => {
  if (typeof string === 'undefined') return null;
  if (!strings) {
    return <>{string}</>;
  }

  const key = Object.keys(strings).find(s => normalize(string) === normalize(s));
  if (key) return <>{strings.get(key)}</>;

  logger.warn(
    `Localized string does not exist for "${string}" with current language.
    This could mean that either the string does not exist with current or
    any language, or that there is a type in the "string" property.

    Please update the localizations loaded from the api endpoint to include
    "${string}". It will however fallback to the string provided for now.
  `,
  );
  return <>{string}</>;
});

Localized.propTypes = {
  string: PropTypes.string.isRequired,
  strings: PropTypes.objectOf(PropTypes.string),
};

const makeMapStateToProps = () => {
  const getStrings = makeGetStrings();
  return state => ({
    strings: getStrings(state),
  });
};

const mapStateToProps = makeMapStateToProps();

export default connect(mapStateToProps)(Localized);
