import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ItemWithVideoPreview from 'tv-modules/Featured/ItemWithVideoPreview/ItemWithVideoPreview';
import LoadingItemWithVideoPreview from 'tv-modules/Featured/ItemWithVideoPreview/ItemWithVideoPreview.loading';
import FeaturedMatchDetails from 'tv-modules/Featured/FeaturedMatchDetails/FeaturedMatchDetails';
import useMatchLink from 'tv-hooks/useMatchLink';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import { useGetFeaturedThumbnail } from 'tv-selectors/featured/makeGetFeaturedThumbnail';
import { useGetSelectedFeaturedMatches } from 'tv-selectors/featured/makeGetSelectedFeaturedMatches';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useGetMatchStatus } from 'tv-selectors/match/makeGetMatchStatus';
import useOnImageLoad from 'znipe-hooks/useOnImageLoad';
import useLoadingDelay from 'znipe-hooks/useLoadingDelay';

const FeaturedMatchItem = ({ size, autoPlay = false, showVideo = true, featuredId = '' }) => {
  const navigate = useNavigate();
  const [matchId] = useGetSelectedFeaturedMatches({ featuredId });
  const globalStreams = useGetGameGlobalStreams({ matchId });
  const eventStreamId = useMemo(
    () => globalStreams?.find(({ type }) => type === 'event')?.streamId ?? '',
    [globalStreams],
  );
  const thumbnail = useGetFeaturedThumbnail({ featuredId });
  const videoSrc = useStreamSrcWithToken(eventStreamId, 'match');
  const statusLabel = useGetMatchStatus({ matchId });

  const { hasLoaded } = useOnImageLoad(thumbnail);
  const componentHasLoaded = useLoadingDelay(hasLoaded);

  const elementSpecs = useMemo(() => {
    switch (size) {
      case 'xlarge':
        return {
          detailsComponentSize: 'medium',
          videoPreviewComponentLayout: 'horizontal',
          videoPreviewComponentSize: 'xlarge',
        };
      case 'medium':
        return {
          detailsComponentSize: 'small',
          videoPreviewComponentLayout: 'horizontal',
          videoPreviewComponentSize: 'medium',
        };

      default:
        return {
          detailsComponentSize: 'small',
          videoPreviewComponentLayout: 'vertical',
          videoPreviewComponentSize: 'small',
        };
    }
  }, [size]);

  const { detailsComponentSize, videoPreviewComponentLayout, videoPreviewComponentSize } =
    elementSpecs;

  const matchLink = useMatchLink(matchId);
  const onVideoClick = useCallback(() => {
    navigate(matchLink);
  }, [navigate, matchLink]);

  if (!componentHasLoaded) {
    return (
      <LoadingItemWithVideoPreview
        orientation={videoPreviewComponentLayout}
        size={videoPreviewComponentSize}
      />
    );
  }

  return (
    <ItemWithVideoPreview
      src={videoSrc}
      thumbnail={thumbnail}
      orientation={videoPreviewComponentLayout}
      size={videoPreviewComponentSize}
      autoPlay={autoPlay}
      onVideoClick={onVideoClick}
      showVideo={showVideo}
    >
      <FeaturedMatchDetails
        size={detailsComponentSize}
        matchId={matchId}
        statusLabel={statusLabel}
      />
    </ItemWithVideoPreview>
  );
};

FeaturedMatchItem.propTypes = {
  featuredId: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'xlarge']).isRequired,
  autoPlay: PropTypes.bool,
  showVideo: PropTypes.bool,
};

export default FeaturedMatchItem;
