import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetVideoTournamentName = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => video?.tournamentName ?? '');
};

export const useGetVideoTournamentName = props =>
  useMemoizedGqlSelector(makeGetVideoTournamentName, props);

export default makeGetVideoTournamentName;
