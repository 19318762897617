import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'tv-modules/Payment/SharedStyles/ErrorMessage';
import Checkbox from 'znipe-elements/data-entry/Checkbox/Checkbox';
import Textarea from 'znipe-elements/data-entry/Textarea/Textarea';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import { cancelProductSubscription } from 'tv-actions/old/products';
import { Form, Description, Options, Actions, ButtonGroup } from './CancelSubscriptionForm.styles';

const options = [
  { name: 'price', label: 'Price too high' },
  { name: 'features', label: 'Not enough features' },
  { name: 'content', label: 'Not enough content' },
  { name: 'bugs', label: 'Technical issues' },
  { name: 'other', label: 'Other reasons' },
];

const CancelSubscriptionForm = ({
  productId,
  onSuccess = () => {},
  onFailure = () => {},
  goBackTo,
  productName = 'Pro View',
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();
  const dispatch = useDispatch();

  const sendCancelRequest = values => {
    const { text, ...checkboxes } = values;
    const feedback = { options: checkboxes, text };
    return dispatch(cancelProductSubscription(productId, feedback));
  };
  const onSubmit = values => {
    sendCancelRequest(values).then(onSuccess, onFailure);
  };

  // validate that at least one checkbox is checked
  const validate = () => {
    const values = getValues();
    return options.some(option => values[option.name]);
  };

  const errorMessage = Object.keys(errors).length > 0 ? 'Please select reasons for cancelling' : '';

  return (
    <Form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
      <Description data-testid="description">
        Please let us know how we can improve {productName} by selecting your reasons for
        cancellation:
      </Description>
      <Options size={options.length}>
        {options.map(({ name, label }) => (
          <Checkbox {...register(name, { validate })} key={name} label={label} />
        ))}
      </Options>
      <Description>Optionally, please leave any additional feedback for us below:</Description>
      <Textarea {...register('text', { required: false })} labelPosition="top" />

      <Actions>
        <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>
        <ButtonGroup>
          <Button data-testid="submit-button" variant="solid-color" size="small" type="submit">
            Cancel Subscriptions
          </Button>
          <Button
            data-testid="go-back-button"
            variant="text"
            size="small"
            textColor="black"
            to={goBackTo}
          >
            I changed my mind
          </Button>
        </ButtonGroup>
      </Actions>
    </Form>
  );
};

CancelSubscriptionForm.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  goBackTo: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string,
};
export default CancelSubscriptionForm;
