import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import { createSelector } from 'reselect';
import { getShortLanguageName } from 'znipe-utils/misc/language-code';
import makeGetGameGlobalStreams from 'tv-selectors/games/makeGetGameGlobalStreams';

const emptyArray = [];

const makeGetGameLanguages = () => {
  const getGameGlobalStreams = makeGetGameGlobalStreams();
  return createSelector([getGameGlobalStreams], globalStreams => {
    if (globalStreams.length === 0) return emptyArray;
    const languages = globalStreams
      .map(stream => getShortLanguageName(stream.language))
      .filter(langauge => langauge !== '');
    if (languages.length === 0) return emptyArray;
    return Array.from(new Set(languages));
  });
};

export const useGetGameLanguages = props => useMemoizedSelector(makeGetGameLanguages, props);

export default makeGetGameLanguages;
