import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetOrientation from 'tv-selectors/browser/makeGetOrientation';

const makeGetIsLandscape = () => {
  const getOrientation = makeGetOrientation();
  return createSelector([getOrientation], orientation => orientation === 'landscape');
};

export const useGetIsLandscape = () => useMemoizedSelector(makeGetIsLandscape);

export default makeGetIsLandscape;
