import styled, { css } from 'styled-components';
import { fadeIn, fadeInFadeOut } from 'znipe-styles/animations';
import prefers from 'znipe-styles/prefers';
import colors from 'znipe-styles/colors';
import { Theme as BaseTheme } from 'znipe-themes/theme.types';

const defaultParams = {
  color: colors.white,
  boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 6px',
  borderColor: 'transparent',
  borderRadius: '40px',
};

export const themes = {
  proview: {
    ...defaultParams,
    borderColor: 'rgba(255, 255, 255, 0.05)',
  },
  lg: {
    ...defaultParams,
  },
  esportal: {
    borderColor: 'transparent',
    borderRadius: '40px',
  },
  znipebase: {
    ...defaultParams,
    borderRadius: '3px',
    boxShadow: '0px 4px 28px rgba(0, 0, 0, 0.55)',
  },
  default: defaultParams,
};

type Theme = BaseTheme & {
  popOver: typeof themes.znipebase;
};

type GetAlignStylesProps = {
  align: string;
  position: string;
};
const getAlignStyles = ({ align, position }: GetAlignStylesProps) => {
  const alignAxis = ['top', 'bottom'].includes(position) ? 'x' : 'y';
  switch (align) {
    case 'start':
      return css`
        ${alignAxis === 'x' ? 'right' : 'bottom'}: -10px;
      `;

    case 'end':
      return css`
        ${alignAxis === 'x' ? 'left' : 'top'}: -10px;
      `;

    default:
      return css`
        ${alignAxis === 'x' ? 'left' : 'top'}: 50%;
        ${alignAxis === 'x' ? 'transform: translateX(-50%)' : 'transform: translateY(-50%)'};
      `;
  }
};

type GetPositionStylesProps = {
  popoverAreaHeight: number;
  popoverAreaWidth: number;
  position: string;
  marginOffset: number;
};

const getPositionStyles = ({
  popoverAreaHeight,
  popoverAreaWidth,
  position,
  marginOffset,
}: GetPositionStylesProps) => {
  if (position === 'top') {
    return css`
      top: ${-(marginOffset + popoverAreaHeight)}px;
      bottom: auto;
    `;
  }

  if (position === 'bottom') {
    return css`
      top: auto;
      bottom: ${-(marginOffset + popoverAreaHeight)}px;
    `;
  }

  if (position === 'left') {
    return css`
      left: ${-(marginOffset + popoverAreaWidth)}px;
      right: auto;
    `;
  }

  return css`
    left: auto;
    right: ${-(marginOffset + popoverAreaWidth)}px;
  `;
};

const fadeInAnimation = fadeIn();
const fadeInFadeOutAnimation = fadeInFadeOut();

type GetFadeAnimationProps = {
  animationType: string;
  fadeDurationMs: number;
};
export const getFadeAnimation = ({ animationType, fadeDurationMs }: GetFadeAnimationProps) => {
  if (animationType === 'none') return css``;

  if (animationType === 'fadeIn') {
    return css`
      @media ${prefers.motion} {
        animation: ${fadeInAnimation} ${fadeDurationMs}ms ease-in forwards;
      }
    `;
  }

  if (animationType === 'fadeInFadeOut') {
    return css`
      opacity: 0;
      @media ${prefers.motion} {
        animation: ${fadeInFadeOutAnimation} ${fadeDurationMs}ms ease-in forwards;
      }
    `;
  }

  return css``;
};

export const Container = styled.div`
  position: relative;
`;

type PopOverAreaProps = GetAlignStylesProps &
  GetPositionStylesProps &
  GetFadeAnimationProps & {
    minWidth: string;
    popOverAreaBackground: string;
    useBorder: boolean;
    useBorderRadius: boolean;
    customBorderRadius: string;
  };

export const PopOverArea = styled.div<PopOverAreaProps>`
  position: absolute;
  min-width: ${props => props.minWidth};
  color: ${({ theme }) => (theme as Theme).popOver.color};
  background: ${({ theme, popOverAreaBackground }) =>
    popOverAreaBackground || theme.secondaryBackgroundColor};
  z-index: 9999999;
  border: ${({ theme, useBorder }) =>
    useBorder ? `1px solid ${(theme as Theme).popOver.borderColor}` : 'none'};
  border-radius: ${({ theme, useBorderRadius, customBorderRadius }) => {
    if (customBorderRadius) {
      return customBorderRadius;
    }
    return useBorderRadius ? (theme as Theme).popOver.borderRadius : '0px';
  }};
  box-shadow: ${({ popOverAreaBackground, theme }) =>
    popOverAreaBackground === 'transparent' ? 'none' : (theme as Theme).popOver.boxShadow};
  ${getAlignStyles}
  ${getPositionStyles}
  ${getFadeAnimation}
`;

export const PopOverTargetWrapper = styled.div`
  outline: none;
  cursor: pointer;
`;
