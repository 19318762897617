import PropTypes from 'prop-types';
import styled from 'styled-components';

const defaultBackgroundColor = '#e1e4e6';

/**
- The progress bar has height: "100%"" and width: "100%" so a wrapping element is needed to set dimensions.
*/

export const generateGradiant = (vertical, startColor, stopColor, fillRatio, bgColor) =>
  startColor && stopColor
    ? `linear-gradient(${
        vertical ? '0deg' : '90deg'
      }, ${startColor} 0%, ${stopColor} ${fillRatio}%, ${bgColor} ${fillRatio}%, ${bgColor} 100%)`
    : undefined;

const ProgressBar = styled.div.attrs(
  ({
    gradientStartColor: startColor,
    gradientStopColor: stopColor,
    fillColor,
    fillRatio,
    vertical = false,
    backgroundColor = defaultBackgroundColor,
  }) => ({
    style: {
      background:
        generateGradiant(vertical, fillColor, fillColor, fillRatio, backgroundColor) ||
        generateGradiant(vertical, startColor, stopColor, fillRatio, backgroundColor),
    },
  }),
)`
  height: 100%;
  width: 100%;
`;

ProgressBar.propTypes = {
  /**
  The percentage (0-100) of the bar to fill up with color
  */
  fillRatio: PropTypes.number.isRequired,
  fillColor: PropTypes.string,
  gradientStartColor: PropTypes.string,
  gradientStopColor: PropTypes.string,
  vertical: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default ProgressBar;
