import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSubscribedPlayersIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedPlayersIds';
import makeGetSubscribedTeamsIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedTeamsIds';
import makeGetSubscribedEventIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedEventIds';
import makeGetSubscribedChampionIds from 'tv-selectors/contentSubscriptions/makeGetSubscribedChampionIds';

const makeGetAllContentSubscriptionIds = () => {
  const getSubscribedTeamsIds = makeGetSubscribedTeamsIds();
  const getSubscribedPlayersIds = makeGetSubscribedPlayersIds();
  const getSubscribedEventsIds = makeGetSubscribedEventIds();
  const getSubscribedChampionIds = makeGetSubscribedChampionIds();

  return createSelector(
    [
      getSubscribedTeamsIds,
      getSubscribedPlayersIds,
      getSubscribedEventsIds,
      getSubscribedChampionIds,
    ],
    (allTeams, allPlayers, allEvents, allChampions) => [
      ...allTeams,
      ...allPlayers,
      ...allEvents,
      ...allChampions,
    ],
  );
};

export const useGetAllContentSubscriptionIds = () =>
  useMemoizedGqlSelector(makeGetAllContentSubscriptionIds);

export default makeGetAllContentSubscriptionIds;
