import { handleActions } from 'redux-actions';

import { TOGGLE_DEBUG, TOGGLE_JANKO_MODE, IS_COMPACT_PLAYER } from 'tv-constants/oldRedux';

export const initialState = {
  debug: false,
  jankoMode: false,
};

export default handleActions(
  {
    [TOGGLE_DEBUG]: state => ({ ...state, debug: !state.debug }),
    [TOGGLE_JANKO_MODE]: state => ({ ...state, jankoMode: !state.jankoMode }),
    [IS_COMPACT_PLAYER]: (state, action) => ({ ...state, isCompactPlayer: action.isCompactPlayer }),
  },
  initialState,
);
