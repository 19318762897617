import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isRiotDomain } from 'tv-utils/pageUtils';
import { useHostname } from 'tv-selectors/deviceInfo/makeGetHostname';

/*
This hook is used for when a package name is required. eg. GQL queries or theme
Do not use this the check the packageSlug from the URL as it's optional and can be undefiend
 */
const usePackageName = providedSlug => {
  const { packageSlug } = useParams();
  const hostname = useHostname();

  const defaultPackage = useMemo(() => {
    if (isRiotDomain(hostname)) return 'proview';
    return 'znipe';
  }, [hostname]);

  return providedSlug ?? packageSlug ?? defaultPackage;
};

export default usePackageName;
