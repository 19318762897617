import withDangerouslySetInnerHTML from 'znipe-elements/layout/withDangerouslySetInnerHTML/withDangerouslySetInnerHTML';
import { useMemo } from 'react';
import { Overlay, OverlayContent, PlainOverlay } from './Overlay.styles';
import type { OverlayProps } from './Overlay.types';

const HTMLOverlay = withDangerouslySetInnerHTML(PlainOverlay);

const OverlayComponent = ({ children, overlay, contentStyles, topMost }: OverlayProps) => {
  const content = useMemo(() => {
    if (!overlay) return null;
    if (typeof overlay === 'string') return <HTMLOverlay HTMLString={overlay} />;
    return (
      <Overlay $show data-testid="overlay">
        {overlay}
      </Overlay>
    );
  }, [overlay]);
  return (
    <>
      {overlay && (
        <OverlayContent style={contentStyles} $topMost={topMost === true}>
          {content}
        </OverlayContent>
      )}
      {children}
    </>
  );
};

export default OverlayComponent;
