import { createContext } from 'react';
import debounce from 'lodash.debounce';
import type { DebouncedFunc } from 'lodash';
import { Flags } from 'znipe-link/src/utils/fetchFeatureFlags';

type FeatureFlags = {
  flags: Flags;
  registerFlag: (flag: string) => Promise<void>;
  evaluate: DebouncedFunc<() => Promise<void>>;
};

const FeatureFlagsContext = createContext<FeatureFlags>({
  flags: {},
  registerFlag: async () => {},
  evaluate: debounce(async () => {}),
});

export const { Provider, Consumer } = FeatureFlagsContext;

export default FeatureFlagsContext;
