import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import LoginButton from 'tv-modules/Authentication/LoginButton/LoginButton';
import Icon from 'znipe-elements/general/Icon/Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  padding: 0 16px;
  white-space: normal;

  > * {
    max-width: ${({ $size }) => ($size === 'small' ? 378 : 272)}px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
`;

const LoginCTA = ({
  heading = '',
  description = '',
  onClose,
  size = 'small',
  center = false,
  icon = '',
}) => (
  <Wrapper $center={center} $size={size}>
    {icon && <Icon type={icon} size={32} inline />}
    <Typography type="heading-s" align={center ? 'center' : 'left'}>
      {heading}
    </Typography>
    <Typography type="paragraph-m" color={colors.grey75} align={center ? 'center' : 'left'}>
      {description}
    </Typography>
    <Buttons>
      {onClose && (
        <Button variant="text" size={size} onClick={onClose}>
          Close
        </Button>
      )}
      <LoginButton size={size} />
    </Buttons>
  </Wrapper>
);

LoginCTA.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  center: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small']),
  icon: PropTypes.string,
};

export default LoginCTA;
