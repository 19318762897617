import { useMemo } from 'react';
import usePackageName from 'tv-hooks/usePackageName';
import { useGetSubscriptions } from 'tv-selectors/auth/makeGetSubscriptions';
import { useGetPackageProducts } from 'tv-selectors/packages/makeGetPackageProducts';

const useGetUserPackageSubscription = providedPackage => {
  const packageName = usePackageName(providedPackage);
  const subscriptions = useGetSubscriptions();
  const packageProducts = useGetPackageProducts({ packageName });

  const subscription = useMemo(
    () => subscriptions.find(sub => packageProducts.includes(sub.productId)),
    [packageProducts, subscriptions],
  );

  return subscription;
};

export default useGetUserPackageSubscription;
