import { useState, useEffect, useMemo, useCallback, useRef, forwardRef } from 'react';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import PropTypes from 'prop-types';
import VideoOverlay from 'tv-modules/Player/VideoOverlay/VideoOverlay';
import MultiViewPlayer from 'znipe-player/src/components/MultiView/MultiView';
import ScreenOverlay from 'tv-modules/Player/ScreenOverlay/ScreenOverlay';
import DesktopStreamSelector from 'tv-modules/Player/DesktopStreamSelector/DesktopStreamSelector';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useQuality } from 'tv-selectors/control/makeGetQuality';
import { useGetMatchTournamentName } from 'tv-selectors/match/makeGetTournamentName';
import { useGetMatchTeamsShortNames } from 'tv-selectors/match/makeGetMatchTeamsShortNames';
import { useDebug } from 'tv-selectors/ui/makeGetDebug';
import { setMatchPlayerStreams } from 'tv-actions/matches';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import useListenToFullscreenEvent from 'tv-hooks/useListenToFullscreenEvent';
import { animated } from '@react-spring/web';
import useAnalyticsParams from 'tv-hooks/useAnalyticsParams';
import subscribe from 'znipe-utils/web/subscribe';
import DeadPlayerHandling from 'tv-modules/DeadPlayerHandling/DeadPlayerHandling';
import { useGetGameCompetitorsLineups } from 'tv-selectors/games/makeGetGameCompetitorsLineups';
import deadPlayerPopupSize from 'tv-modules/DeadPlayerHandling/deadPlayerPopupSize';
import AudioProvider from 'tv-providers/AudioProvider';
import { PlayerFrame, VideoContainer } from './VideoFrame.styles';

const AnimatedVideoContainer = animated(VideoContainer);

const VideoFrame = forwardRef(
  (
    {
      matchId,
      selectedMatchGameIndex,
      startTime,
      streamSelectorStreamId = '',
      closeSelector = () => {},
      handleWindowRefUpdate = () => {},
      springProps = {},
      initialSeekRange = {},
      setPlayerWidth = () => {},
      statsDisabled = false,
      preview = false,
      compact = false,
      sidebarsOpen = 0,
    },
    playerRef,
  ) => {
    const subscriptions = useRef([]);
    const [shouldUseUltraWideLayout, setShouldUseUltraWideLayout] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const playerContainerRef = useRef(null);
    const gqlDispatch = useGqlStoreDispatch();
    const videos = useGetMatchPlayerStreams({ matchId });
    const isTabletOrGreater = useGetIsTabletOrGreater();
    const isDesktopOrGreater = useGetIsDesktopOrGreater();
    const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
    const tournamentName = useGetMatchTournamentName({ matchId });
    const [teamOneName, teamTwoName] = useGetMatchTeamsShortNames({ matchId });
    const gameId = useGetGameId({ matchId, selectedMatchGameIndex });

    const numberOfPlayingStreams = useMemo(
      () => videos.filter(video => !(video || {}).audioOnly).length,
      [videos],
    );
    const multiPovAllowed = isDesktopOrGreater || isFullscreen;

    useListenToFullscreenEvent(playerContainerRef, setIsFullscreen);

    const removeClosedPlayer = useCallback(
      videoId => {
        const isRemovingMaster = videos.find(video => video?.id === videoId && video.master);
        let filteredPlayers;
        if (isRemovingMaster) {
          const remainingVideos = videos.filter(video => video?.id !== videoId);
          const firstRemainingVideo = remainingVideos[0];
          remainingVideos[0] = { ...firstRemainingVideo, master: true };
          filteredPlayers = remainingVideos;
        } else {
          filteredPlayers = videos.filter(video => video?.id !== videoId);
        }
        gqlDispatch(setMatchPlayerStreams(matchId, filteredPlayers));
      },
      [gqlDispatch, matchId, videos],
    );

    const setMasterSoundSource = useCallback(
      videoId => {
        const newPlayerStreams = videos.map(video => ({ ...video, master: video?.id === videoId }));
        gqlDispatch(setMatchPlayerStreams(matchId, newPlayerStreams));
      },
      [gqlDispatch, matchId, videos],
    );

    const matchGameLineups = useGetGameCompetitorsLineups({
      matchId,
      selectedMatchGame: selectedMatchGameIndex,
    });

    const videosWithOverlays = useMemo(
      () =>
        videos
          .filter(video => multiPovAllowed || video.master)
          .map((video, index) => {
            const { playerId } =
              matchGameLineups.find(lineup => lineup.streamId === video?.id) || {};
            return {
              ...video,
              hoverOverlay: (
                <VideoOverlay
                  matchId={matchId}
                  selectedMatchGameIndex={selectedMatchGameIndex}
                  streamId={video?.id}
                  onWindowPopout={handleWindowRefUpdate}
                  onSoundIconClick={() => setMasterSoundSource(video?.id)}
                  onCloseClick={() => removeClosedPlayer(video?.id)}
                  showActions={numberOfPlayingStreams > 1}
                  showLogo={false}
                  isPlayerPopout
                />
              ),
              overlay: playerId && (
                <DeadPlayerHandling
                  playerId={playerId}
                  gameId={gameId}
                  size={deadPlayerPopupSize(
                    index,
                    videos.length,
                    multiPovAllowed,
                    isTabletOrGreater,
                    isDesktopLargeOrGreater,
                    sidebarsOpen,
                  )}
                />
              ),
            };
          }),
      [
        videos,
        multiPovAllowed,
        matchGameLineups,
        matchId,
        selectedMatchGameIndex,
        handleWindowRefUpdate,
        numberOfPlayingStreams,
        gameId,
        isDesktopLargeOrGreater,
        isTabletOrGreater,
        setMasterSoundSource,
        removeClosedPlayer,
        sidebarsOpen,
      ],
    );

    useEffect(() => {
      const { current } = playerContainerRef;
      if (!current || !global.document) return () => {};
      const checkUltraWide = () => {
        const windowHeight = global.innerHeight;
        const windowWidth = global.innerWidth;
        setShouldUseUltraWideLayout(windowWidth >= windowHeight * 3);
      };
      checkUltraWide();
      const onWindowResize = () => {
        checkUltraWide();
      };
      window.addEventListener('resize', onWindowResize);
      return () => window.removeEventListener('resize', onWindowResize);
    }, []);

    const quality = useQuality();

    const onPlayerReady = useCallback(() => {
      const player = playerRef.current;

      const handlePlayerQuality = () => player.selectQuality(quality);

      subscriptions.current.push(subscribe(player, 'loaded', handlePlayerQuality));
      subscriptions.current.push(subscribe(player, 'unloading', handlePlayerQuality));
    }, [quality, playerRef]);

    const videoTitle = `${teamOneName} vs ${teamTwoName}, ${tournamentName}`;
    const videoDescription = `Game ${selectedMatchGameIndex + 1}`;
    const debug = useDebug();
    const analyticsParams = useAnalyticsParams(matchId);

    const springStylesVideo = useMemo(
      () =>
        isFullscreen
          ? {}
          : {
              transform: springProps?.transform,
              width: springProps?.width,
            },
      [springProps, isFullscreen],
    );

    useEffect(() => () => subscriptions.current.forEach(unsubscribe => unsubscribe()), []);

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
      const player = playerRef.current;
      player.selectQuality(quality);
      // Only set quality on layout change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videos.length]);

    const previewVideo = useMemo(() => (videos.length > 0 ? [videos[0]] : []), [videos]);

    const currentMultiViewVideos = useMemo(
      () => (preview ? previewVideo : videosWithOverlays),
      [preview, previewVideo, videosWithOverlays],
    );

    return (
      <PlayerFrame ref={playerContainerRef} data-testid="video-frame-container">
        <AudioProvider playerRef={playerRef} matchId={matchId}>
          <AnimatedVideoContainer
            ref={setPlayerWidth}
            data-testid="video-container"
            className="player-element"
            style={springStylesVideo}
            $compact={compact}
          >
            <MultiViewPlayer
              id={gameId ?? matchId}
              ref={playerRef}
              onReady={onPlayerReady}
              debug={debug}
              startTime={startTime}
              videos={currentMultiViewVideos}
              useUltraWideLayout={shouldUseUltraWideLayout}
              analyticsParams={analyticsParams}
              initialSeekRange={initialSeekRange}
              maxPovs={isDesktopOrGreater ? 4 : 1}
            />

            {!preview && (
              <DesktopStreamSelector
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                userSelectedStreamId={streamSelectorStreamId}
                afterStreamSelect={closeSelector}
                onOverlayClick={closeSelector}
              />
            )}
          </AnimatedVideoContainer>
          {!preview && !streamSelectorStreamId && !isDesktopOrGreater && (
            <ScreenOverlay
              playerRef={playerRef}
              playerContainerRef={playerContainerRef}
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              title={videoTitle}
              description={videoDescription}
              statsDisabled={statsDisabled || !isFullscreen}
            />
          )}
          {!preview && !streamSelectorStreamId && isDesktopOrGreater && (
            <ScreenOverlay
              playerRef={playerRef}
              playerContainerRef={playerContainerRef}
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              title={videoTitle}
              description={videoDescription}
              statsDisabled
              springProps={springProps}
            />
          )}
        </AudioProvider>
      </PlayerFrame>
    );
  },
);

const springProps = {
  id: PropTypes.number,
  key: PropTypes.string,
};

VideoFrame.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  streamSelectorStreamId: PropTypes.string,
  startTime: PropTypes.number,
  closeSelector: PropTypes.func,
  handleWindowRefUpdate: PropTypes.func,
  initialSeekRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  springProps: PropTypes.shape({
    transform: PropTypes.shape(springProps),
    width: PropTypes.shape(springProps),
    translate: PropTypes.shape(springProps),
  }),
  setPlayerWidth: PropTypes.func,
  statsDisabled: PropTypes.bool,
  preview: PropTypes.bool,
  compact: PropTypes.bool,
  sidebarsOpen: PropTypes.number,
};

export default VideoFrame;
