import { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { useGetIsLandscape } from 'tv-selectors/browser/makeGetIsLandscape';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetGameLanguages } from 'tv-selectors/games/makeGetGameLanguages';
import { useIsMobile } from 'tv-selectors/deviceInfo/makeGetIsMobile';
import { useIsTablet } from 'tv-selectors/deviceInfo/makeGetIsTablet';
import withTheme from 'znipe-themes/hocs/withTheme';
import useListenToFullscreenEvent from 'tv-hooks/useListenToFullscreenEvent';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import themes from '../ScreenOverlay.themes';
import ScreenControls from '../components/ScreenControls/ScreenControls';
import ScreenStats from '../components/ScreenStats/ScreenStats';
import ScreenPovSelectors from '../components/ScreenPovSelectors/ScreenPovSelectors';
import { Container, Wrapper } from '../ScreenOverlay.styles';
import {
  VIEW_DEFAULT,
  VIEW_STATS,
  VIEW_STREAMS,
  VIEW_HIGHLIGHTS,
  VIEW_ROOMS,
  OVERLAY_VISIBLE_TIME,
} from '../constants';

const MobileScreenOverlay = ({
  matchId,
  selectedMatchGameIndex,
  title = '',
  description = '',
  playerRef = playerDefaultProps,
  playerContainerRef = { current: {} },
  statsDisabled = false,
  hasFinishedPlaying = false,
  playlistAutoplayingEnabled = false,
  onWatchNext,
  onAutoplayCancel,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  useListenToFullscreenEvent(playerContainerRef, setIsFullscreen);

  const isLandscape = useGetIsLandscape();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const languages = useGetGameLanguages();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const overlayTimer = useRef(null);
  const persitHoverState = useRef(false);

  const [view, setView] = useState(VIEW_DEFAULT);
  const [isVisible, setIsVisible] = useState(false);

  const handlePersistentHover = useCallback(newState => {
    persitHoverState.current = newState;
  }, []);

  const setupOverlayTimer = useCallback((overlayIsVisible = false) => {
    clearTimeout(overlayTimer.current);
    overlayTimer.current = setTimeout(() => {
      if (!persitHoverState.current) {
        setIsVisible(overlayIsVisible);
      }
    }, OVERLAY_VISIBLE_TIME);
  }, []);

  const debounceHover = debounce(state => {
    setupOverlayTimer(state);
  }, 50);

  const onStatsClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      setView(VIEW_STATS);
      handlePersistentHover(true);
    },
    [handlePersistentHover],
  );

  const onStreamClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      setView(VIEW_STREAMS);
      handlePersistentHover(true);
    },
    [handlePersistentHover],
  );

  const onHighlightClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      setView(VIEW_HIGHLIGHTS);
      handlePersistentHover(true);
    },
    [handlePersistentHover],
  );

  const onRoomClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      setView(VIEW_ROOMS);
      handlePersistentHover(true);
    },
    [handlePersistentHover],
  );

  const onCloseView = useCallback(
    e => {
      if (e) e.stopPropagation();
      setView(VIEW_DEFAULT);
      handlePersistentHover(false);
    },
    [handlePersistentHover],
  );

  const handlePropagation = useCallback(e => {
    if (e) e.stopPropagation();
  }, []);

  const handleScreenTap = useCallback(() => {
    setIsVisible(!isVisible);
    setupOverlayTimer(false);
  }, [isVisible, setupOverlayTimer]);

  const handleWatchNextClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      onWatchNext();
    },
    [onWatchNext],
  );

  const handleAutoplayCancelClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      onAutoplayCancel();
    },
    [onAutoplayCancel],
  );

  const handleWatchAgain = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    player.play();
  }, [playerRef]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run on view change
  useEffect(() => {
    setupOverlayTimer(false);
    return () => {
      clearTimeout(overlayTimer.current);
    };
  }, [view, setupOverlayTimer]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return () => {};
    const onPlay = () => debounceHover(false);
    const onPause = () => debounceHover(true);
    player.addEventListener('play', onPlay);
    player.addEventListener('pause', onPause);
    return () => {
      player.removeEventListener('play', onPlay);
      player.removeEventListener('pause', onPause);
    };
  }, [debounceHover, playerRef]);

  const isTouchDevice = isMobile || isTablet;
  const shouldShowDefaultView = !isLandscape || view === VIEW_DEFAULT;
  const shouldShowStatsView = isTouchDevice && isLandscape && view === VIEW_STATS;
  const shouldShowStreamsView = isTouchDevice && isLandscape && view === VIEW_STREAMS;

  return (
    <Container
      data-testid="mobile-screen-overlay"
      isVisible={isVisible}
      onClick={shouldShowDefaultView ? handleScreenTap : handlePropagation}
      subMenuOpen={shouldShowStatsView || shouldShowStreamsView}
    >
      <Wrapper isVisible={isVisible}>
        {shouldShowDefaultView && (
          <ScreenControls
            title={title}
            description={description}
            isLandscape={isTouchDevice && isLandscape}
            isDesktopOrGreater={isDesktopOrGreater}
            onStatsClick={statsDisabled ? null : onStatsClick}
            onStreamClick={isFullscreen ? onStreamClick : null}
            languages={languages}
            onHighlightClick={onHighlightClick}
            onRoomClick={onRoomClick}
            playerRef={playerRef}
            playerContainerRef={playerContainerRef}
            isVisible={isVisible}
            handlePropagation={handlePropagation}
            matchId={matchId}
            hasFinishedPlaying={hasFinishedPlaying}
            playlistAutoplayingEnabled={playlistAutoplayingEnabled}
            onWatchAgain={handleWatchAgain}
            onWatchNext={onWatchNext ? handleWatchNextClick : null}
            onAutoplayCancel={handleAutoplayCancelClick}
            togglePersitentHoverState={handlePersistentHover}
            onSeekDone={setupOverlayTimer}
          />
        )}
        {shouldShowStatsView && (
          <ScreenStats
            matchId={matchId}
            selectedMatchGameIndex={selectedMatchGameIndex}
            onClose={onCloseView}
          />
        )}
        {shouldShowStreamsView && (
          <ScreenPovSelectors
            matchId={matchId}
            selectedMatchGameIndex={selectedMatchGameIndex}
            onClose={onCloseView}
            playerRef={playerRef}
          />
        )}
      </Wrapper>
    </Container>
  );
};

MobileScreenOverlay.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  statsDisabled: PropTypes.bool,
  hasFinishedPlaying: PropTypes.bool,
  playlistAutoplayingEnabled: PropTypes.bool,
  onWatchNext: PropTypes.func,
  onAutoplayCancel: PropTypes.func,
  playerRef: playerPropTypes,
  playerContainerRef: PropTypes.shape({}),
};

export default withTheme(MobileScreenOverlay, themes, 'screenOverlay');
