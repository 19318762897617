/* stylelint-disable selector-type-no-unknown */
import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

const armGradient = {
  start: 'rgba(255, 245, 249, 0.5)',
  middle: 'rgba(255, 189, 218, 0.5)',
  end: 'rgba(255, 127, 183, 0.5)',
};

const gradientPt1 = `linear-gradient(225.34deg, ${armGradient.start} 0%, ${armGradient.middle} 100%)`;
const gradientPt2 = `linear-gradient(163.69deg, ${armGradient.middle} 0%, ${armGradient.end} 101.17%)`;

const emptyColumnPadding = 62;

const emptyColumnWidthCss = css`
  width: calc(100% + ${emptyColumnPadding}px);
  @media ${device.tablet} {
    width: ${({ numberOfColumns }) => `${numberOfColumns * 264 + emptyColumnPadding}px`};
  }
  @media ${device.desktop} {
    width: ${({ numberOfColumns }) => `${numberOfColumns * 240 + emptyColumnPadding}px`};
  }
  @media ${device.desktopLarge} {
    width: ${({ numberOfColumns }) => `${numberOfColumns * 248 + emptyColumnPadding}px`};
  }
  @media ${device.desktopExtraLarge} {
    width: ${({ numberOfColumns }) => `${numberOfColumns * 298 + emptyColumnPadding}px`};
  }
`;

const getArmGradient = gradientType => css`
  background-image: ${({ theme }) => {
    const bgColor = theme.primaryBackgroundColor;
    return `linear-gradient(${bgColor}, ${bgColor}), ${gradientType}`;
  }};
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const flexCenterCss = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const baseRightArmCss = css`
  content: '';
  position: absolute;
  width: 16px;
  height: ${({ isFirstColumn, sameNumberOfMatchupsNext }) => {
    if (sameNumberOfMatchupsNext) {
      return '9px';
    }
    return isFirstColumn ? '17.5%' : '37%';
  }};
  margin-top: ${props => (props.isFirstColumn ? -1 : 0)}px;
  right: 0;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`;

const baseLeftArmCss = css`
  content: '';
  position: absolute;
  width: 17px;
  height: ${({ bracketType }) => (bracketType === 'lower' ? '19px' : '10%')};
  left: -1px;
  border-left: 1px solid ${({ theme }) => theme.borderColor};
`;

const toLowerActiveCss = css`
  ${getArmGradient(gradientPt1)};
  border: solid 1px transparent;
  border-top-right-radius: 2px;
  border-bottom: none;
  border-left: none;
`;

const toUpperActiveCss = css`
  ${getArmGradient(gradientPt2)};
  border: solid 1px transparent;
  border-bottom-right-radius: 2px;
  border-top: none;
  border-left: none;
`;

const fromUpperActiveCss = css`
  ${getArmGradient(gradientPt2)};
  border: solid 1px transparent;
  border-bottom-left-radius: 2px;
  border-top: none;
  border-right: none;
`;

const fromLowerActiveCss = css`
  ${getArmGradient(gradientPt2)};
  border: solid 1px transparent;
  border-top-left-radius: 2px;
  border-bottom: none;
  border-right: none;
`;

const toLowerArmCss = css`
  &:after {
    ${baseRightArmCss};
    top: ${props => (props.bracketType === 'upper' ? '50%' : 'calc(50% - 1px)')};
    border-top: 1px solid ${({ theme }) => theme.borderColor};
    ${({ hoveredTeam, winner }) => hoveredTeam && hoveredTeam === winner && toLowerActiveCss}
  }
`;

const toUpperArmCss = css`
  &:after {
    ${baseRightArmCss};
    bottom: 50%;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    ${({ hoveredTeam, winner }) => hoveredTeam && hoveredTeam === winner && toUpperActiveCss}
  }
`;

export const MatchWrapper = styled.div`
  ${({ isPrevColumnEmpty }) =>
    !isPrevColumnEmpty &&
    css`
      padding-left: 16px;
    `}
`;

export const BracketWrapper = styled.div``;

const fromUpperArmCss = css`
  ${MatchWrapper}:after {
    ${baseLeftArmCss};
    ${({ sameNumberOfMatchupsPrev }) =>
      sameNumberOfMatchupsPrev
        ? css`
            top: calc(50% + 8px);
          `
        : css`
            bottom: calc(50% + 19px);
          `};

    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    ${({ hoveredTeam, teamOneId, teamTwoId, sameNumberOfMatchupsPrev }) => {
      if (!hoveredTeam) return undefined;
      if (sameNumberOfMatchupsPrev && hoveredTeam === teamTwoId) return fromUpperActiveCss;
      if (!sameNumberOfMatchupsPrev && hoveredTeam === teamOneId) return fromUpperActiveCss;

      return undefined;
    }}
  }
`;

const fromLowerArmCss = css`
  ${MatchWrapper}:before {
    ${baseLeftArmCss};
    top: calc(50% + 19px);
    border-top: 1px solid ${({ theme }) => theme.borderColor};
    ${({ hoveredTeam, teamTwoId }) =>
      hoveredTeam && hoveredTeam === teamTwoId && fromLowerActiveCss}
  }
`;

const bothFromUpperArmCss = css`
  ${MatchWrapper}:after {
    ${baseLeftArmCss};
    height: 16px;
    top: calc(50% + 8px);
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    ${({ hoveredTeam, teamOneId }) =>
      hoveredTeam && hoveredTeam === teamOneId && fromUpperActiveCss}
  }
  ${MatchWrapper}:before {
    ${baseLeftArmCss};
    height: 16px;
    bottom: calc(50% + 16px);
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    ${({ hoveredTeam, winner }) => hoveredTeam && hoveredTeam === winner && fromLowerActiveCss}
  }
`;

export const OuterContainer = styled.div`
  display: flex;
`;

export const BracketItem = styled.div`
  ${flexCenterCss};
  flex: 1;
  position: relative;
  padding: 24px 16px 24px 0;
  ${({ isPrevColumnEmpty }) =>
    isPrevColumnEmpty &&
    css`
      padding-left: 0;
    `}
  ${({ isNextColumnEmpty, isLastColumn }) =>
    (isNextColumnEmpty || isLastColumn) &&
    css`
      padding-right: 0;
    `}

  ${({ isFirstColumn, isCrossBracket }) =>
    (isFirstColumn || isCrossBracket) &&
    css`
      &:nth-of-type(even) {
        margin-bottom: 15px;
      }
      &:nth-of-type(odd) {
        margin-top: 15px;
      }
    `}
  
  ${({ toLower }) => toLower && toLowerArmCss}
  ${({ toUpper }) => toUpper && toUpperArmCss}
  ${({ fromLower }) => fromLower && fromLowerArmCss}
  ${({ fromUpper }) => fromUpper && fromUpperArmCss}
  
  ${({ $isSelectedByFilter }) =>
    $isSelectedByFilter &&
    css`
      &:after {
        //border: 1px solid red;
      }
    `}
`;

export const LowerBracketItem = styled(BracketItem)`
  ${({ bothFromUpper }) => bothFromUpper && bothFromUpperArmCss}
`;

export const Column = styled.div`
  ${flexCenterCss};
  flex-direction: column;
`;

export const ConnectingBracket = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ compact }) =>
    compact
      ? css`
          width: 32px;
        `
      : emptyColumnWidthCss}
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`;

export const ColumnContainer = styled.div`
  ${flexCenterCss};
  align-items: stretch;
  ${({ bracketType }) =>
    bracketType === 'upper' &&
    css`
      ${Column}:first-child {
        ${ConnectingBracket} {
          margin-left: -28px;
        }
      }
    `}
`;

export const FinalWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${ConnectingBracket} {
    ${({ armMarginBottom }) =>
      armMarginBottom &&
      css`
        margin-bottom: ${armMarginBottom};
      `}
  }
`;

const flipVerticallyCss = css`
  transform: scaleX(-1);
  filter: fliph; /*IE*/
`;

export const LineWrapper = styled.div`
  ${flexCenterCss};
  flex: 1;
  padding: 16px 0;
  align-items: ${({ isOddChild }) => (isOddChild ? 'flex-end' : 'flex-start')};
  ${({ isOddChild }) => !isOddChild && flipVerticallyCss}
  ${({ compact }) =>
    compact &&
    css`
      width: 32px;
    `}
`;

export const MergeLeft = styled.div`
  height: ${({ compact }) => (compact ? '100%' : '50%')};
  width: 50%;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  ${({ active }) => active && toLowerActiveCss}
`;

export const MergeRight = styled.div`
  height: ${({ compact }) => (compact ? '100%' : '50%')};
  width: 50%;

  transform: translate(-1px, 1px);
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  border-left: 1px solid ${({ theme }) => theme.borderColor};
  ${({ active }) => active && fromUpperActiveCss}
`;

export const Title = styled.div`
  margin-bottom: ${({ bracketType }) => (bracketType === 'lower' ? '18px' : '0px')};
`;

export const Container = styled.div`
  height: ${({ height }) => height};
  ${({ padding }) =>
    padding &&
    css`
      padding-top: ${padding};
    `};
`;

export const FinalArmsWrapper = styled(Column)`
  display: flex;
  flex-direction: column;
  & > div {
    width: 32px;
  }
`;

export const BracketMatchupLoading = styled.div`
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  width: 240px;
  ${({ compactBracket }) =>
    compactBracket &&
    css`
      width: 540px;
      max-width: 100%;
      margin-top: 24px;
    `}
  max-width: 100%;
  height: 96px;
  border-radius: 3px;
`;
