import { memo } from 'react';
import PopOver from 'znipe-elements/data-display/PopOver/PopOver';
import withTheme from 'znipe-themes/hocs/withTheme';
import { FlexContainer, HeaderWrapper, ItemWrapper, themes } from './PopOverMenuList.styles';

const getKey = (item: React.ReactNode, i: number) => {
  if (typeof item === 'string') return item;
  return `popover-menu-list-item-${i}`;
};

type PopOverMenuListProps = {
  onOpenStateChange?: (isOpen: boolean) => void;
  position?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'start' | 'center' | 'end';
  selectedItemIndexes?: number[];
  headerElement?: React.ReactNode;
  targetElement: React.ReactNode;
  menuPadding?: string;
  headerPadding?: string;
  menuList: React.ReactNode[];
  useBorderRadius?: boolean;
  customBorderRadius?: string;
  useBorder?: boolean;
  animationType?: 'fadeInFadeOut' | 'fadeIn' | 'none';
  fadeDurationMs?: number;
  disableLayoutEffect?: boolean;
};

const PopOverMenuList: React.FC<PopOverMenuListProps> = ({
  onOpenStateChange,
  position = 'top',
  align = 'center',
  selectedItemIndexes,
  headerElement = null,
  targetElement,
  menuPadding = '10px 16px',
  headerPadding = '10px 16px',
  menuList,
  useBorderRadius = true,
  customBorderRadius = '',
  useBorder = true,
  animationType = 'none',
  fadeDurationMs = 400,
  disableLayoutEffect = false,
}) => (
  <PopOver
    targetElement={targetElement}
    position={position}
    align={align}
    onOpenStateChange={onOpenStateChange}
    useBorderRadius={useBorderRadius}
    customBorderRadius={customBorderRadius}
    useBorder={useBorder}
    animationType={animationType}
    fadeDurationMs={fadeDurationMs}
    disableLayoutEffect={disableLayoutEffect}
  >
    <FlexContainer maxHeight={menuList.length > 3 ? 67 * 4 : undefined}>
      {headerElement && (
        <HeaderWrapper data-testid="popover-menu-header" headerPadding={headerPadding}>
          {headerElement}
        </HeaderWrapper>
      )}
      {menuList.map((item, i) => (
        <ItemWrapper
          data-testid={`popover-menu-list-item-${i}`}
          key={getKey(item, i)}
          menuPadding={menuPadding}
          isSelected={selectedItemIndexes?.includes(i) || false}
          customBorderRadius={customBorderRadius}
          itemIndex={i}
          itemsLength={menuList.length}
        >
          {item}
        </ItemWrapper>
      ))}
    </FlexContainer>
  </PopOver>
);

export default withTheme(memo(PopOverMenuList), themes, 'popOverMenuList');
