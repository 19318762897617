import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from './makeGetTournaments';

const getTournamentIdFromProps = (_, props) => props.tournamentId;

const makeGetTournamentHumanReadableId = () => {
  const getTournaments = makeGetTournaments();
  return createSelector(
    [getTournaments, getTournamentIdFromProps],
    (tournaments, tournamentId) => tournaments[tournamentId]?.humanReadableId ?? '',
  );
};

export const useGetTournamentHumanReadableId = props =>
  useMemoizedGqlSelector(makeGetTournamentHumanReadableId, props);

export default makeGetTournamentHumanReadableId;
