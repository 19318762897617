import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import { TILE, LIST, BORDER_RADIUS } from './HighlightClip.constants';

const singleLineCss = css`
  /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */
  overflow: hidden;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.textPrimary};
  margin: 0;
`;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.secondaryTextColor};
  margin: 8px 0 0 0;
  ${singleLineCss}
`;

const getContentListStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 16px;
`;

const getContentTileStyles = css`
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  height: ${({ $isLoading = false }) => ($isLoading ? 'auto' : '100%')};
  ${({ type }) => (type === LIST ? getContentListStyles : getContentTileStyles)}
`;

const getTileImageBorderRadius = css`
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
`;

const getListImageBorderRadius = css`
  border-radius: ${BORDER_RADIUS};
`;

const getDynamicListSize = css`
  > div > div:first-of-type {
    width: ${({ width }) => width};
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  ${({ type }) => (type === TILE ? getTileImageBorderRadius : getListImageBorderRadius)};
  ${({ type }) => type === LIST && getDynamicListSize};
`;

const TitleContainerStyles = css`
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  &:hover,
  &:focus {
    cursor: pointer;
    ${Content} {
      background: ${props => props.theme.secondaryBakgroundFade};
    }
  }
`;

/* stylelint-disable no-descending-specificity */
const ListContainerStyles = css`
  max-width: inherit;
  flex-direction: row;
  ${Content} {
    border-radius: 0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0;
    ${ImageContainer} {
      border-radius: ${BORDER_RADIUS} 0 0 ${BORDER_RADIUS};
    }
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;
/* stylelint-enable no-descending-specificity */

export const Container = styled.div`
  ${({ type }) => (type === TILE ? TitleContainerStyles : ListContainerStyles)};
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

/* stylelint-disable no-descending-specificity */
const LoadingTileStyles = css`
  ${Content} {
    > div {
      margin-top: 8px;
      &:last-child {
        margin-bottom: 8px;
      }
    }
  }
`;

const LoadingListStyles = css`
  min-width: fit-content;
  place-items: center;
  div:first-of-type {
    min-width: 120px;
  }
  ${LoadingTileStyles}
`;
/* stylelint-enable no-descending-specificity */

export const LoadingContainer = styled(Container)`
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  ${({ type }) => (type === LIST ? LoadingListStyles : LoadingTileStyles)}
  &:hover,
  &:focus {
    cursor: default;
    ${Content} {
      background: ${({ theme }) => theme.secondaryBackgroundColor};
    }
  }
`;

export const LockWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
  pointer-events: none;
`;
