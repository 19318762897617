import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetStream from 'tv-selectors/streams/makeGetStream';

const makeGetStreamToken = () => {
  const getStream = makeGetStream();
  return createSelector([getStream], selectedStream => {
    const { tokenExpireTime } = selectedStream;
    const now = Date.now();
    if (!tokenExpireTime || now > tokenExpireTime) return '';
    const streamDetails = selectedStream.hls || selectedStream.dash || {};
    return streamDetails.token || '';
  });
};

export const useGetStreamToken = props => useMemoizedGqlSelector(makeGetStreamToken, props);

export default makeGetStreamToken;
