import { memo, useState } from 'react';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import PropTypes from 'prop-types';
import Avatar from 'znipe-elements/data-display/Avatar/v2/Avatar';
import VersusIcon from 'znipe-elements/data-display/VersusIcon/VersusIcon';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import TeamLogo from 'znipe-elements/data-display/TeamLogo/TeamLogo';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import { predict } from '../../../predictionActions';
import { variants, targetTypes } from '../../../predictionConstants';
import { scenarioType } from '../../../types';
import {
  PredictionsActionsContainer,
  ImageWrapper,
  TeamWrapper,
  VersusIconWrapper,
  ImageAndButtonContainer,
  ButtonContainer,
  ButtonCenterPositioner,
  LogoBackgroundImage,
  ConfirmButtonStyle,
} from './PredictionInputHandlement.styles';

const PlayerAndTeamBackground = ({ playerId, placeRight = false }) => {
  const playerImage = useGetPlayerImage({ playerId });
  const teamId = useGetPlayerTeamId({ playerId });
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  return (
    <>
      <LogoBackgroundImage $placeRight={placeRight}>
        <TeamLogo size="xlarge" logo={teamLogo} />
      </LogoBackgroundImage>
      <ImageWrapper>
        <Avatar imageUrl={playerImage} />
      </ImageWrapper>
    </>
  );
};

PlayerAndTeamBackground.propTypes = {
  playerId: PropTypes.string.isRequired,
  placeRight: PropTypes.bool,
};

const TeamBackground = ({ teamId }) => {
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  return (
    <TeamWrapper>
      <TeamLogo size="xlarge" logo={teamLogo} />
    </TeamWrapper>
  );
};

TeamBackground.propTypes = {
  teamId: PropTypes.string.isRequired,
};

const ButtonPredictInput = ({ scenario, size = 'small', gameId }) => {
  const { options, variant } = scenario;
  const [isConfirm, setIsConfirm] = useState('');
  const userId = useUserId();

  const optionsArray = Object.values(options);

  const onPredict = predictedValue => {
    if (predictedValue === isConfirm) {
      predict(scenario, predictedValue, userId, gameId);
    }
    setIsConfirm(predictedValue);
  };

  const moreThanTwoButtons = optionsArray.length > 2;

  switch (variant) {
    case variants.ONE_VERSUS_ONE:
    case variants.ONE_VERSUS_MANY:
      return (
        <PredictionsActionsContainer $size={size} $TwoButtonWidthException>
          {optionsArray.map((option, index) => (
            <ImageAndButtonContainer>
              {option.targetType === targetTypes.TEAM && <TeamBackground teamId={option.target} />}
              {option.targetType === targetTypes.PLAYER && (
                <PlayerAndTeamBackground playerId={option.target} placeRight={index === 1} />
              )}
              <ButtonCenterPositioner>
                <ConfirmButtonStyle $isConfirm={isConfirm === option.value}>
                  <Button
                    variant="secondary"
                    size="xsmall"
                    onClick={() => onPredict(option.value)}
                    fluid
                  >
                    {option.value === isConfirm ? 'Confirm' : option.label}
                  </Button>
                </ConfirmButtonStyle>
              </ButtonCenterPositioner>
            </ImageAndButtonContainer>
          ))}
          <VersusIconWrapper>
            <VersusIcon size="large" />
          </VersusIconWrapper>
        </PredictionsActionsContainer>
      );
    default:
      return (
        <PredictionsActionsContainer $extraMaginTop $size={size}>
          {optionsArray.map(option => (
            <ButtonContainer key={option.id} $moreThanTwoButtons={moreThanTwoButtons}>
              <ConfirmButtonStyle $isConfirm={isConfirm === option.value}>
                <Button
                  variant="secondary"
                  size="xsmall"
                  onClick={() => onPredict(option.value)}
                  fluid={moreThanTwoButtons}
                >
                  {option.value === isConfirm ? 'Confirm' : option.label}
                </Button>
              </ConfirmButtonStyle>
            </ButtonContainer>
          ))}
        </PredictionsActionsContainer>
      );
  }
};

ButtonPredictInput.propTypes = {
  size: PropTypes.string,
  scenario: scenarioType.isRequired,
  gameId: PropTypes.string.isRequired,
};

export default memo(ButtonPredictInput);
