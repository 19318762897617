import PropTypes from 'prop-types';
import PlayerImage from 'znipe-elements/data-display/PlayerImage/PlayerImage';
import LOLAvatar from 'znipe-elements/data-display/Avatar/LOLAvatar/LOLAvatar';
import SelectorVertical from '../SelectorVertical';
import { IMAGE_TYPE_IMAGE, IMAGE_TYPE_PORTRAIT, propTypes, SMALL, MEDIUM } from '../../constants';

const lolAvatarSizeMap = { [SMALL]: 'small', [MEDIUM]: 'large' };

const PlayerSelector = ({
  label,
  size = 'small',
  image = '',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
  game,
  teamLogo = '',
  championImage = '',
  level,
  kda,
}) => {
  const imageArea =
    game === 'lol' && championImage ? (
      <LOLAvatar
        imageUrl={championImage}
        size={lolAvatarSizeMap[size]}
        level={level}
        levelStyle="circle"
        levelPosition="right"
      />
    ) : (
      <PlayerImage image={image} teamLogo={teamLogo} />
    );

  const imageType = game === 'lol' && championImage ? IMAGE_TYPE_IMAGE : IMAGE_TYPE_PORTRAIT;

  return (
    <SelectorVertical
      label={label}
      size={size}
      imageArea={imageArea}
      imageType={imageType}
      kda={kda}
      isSelected={isSelected}
      isLocked={isLocked}
      hasPremiumAccess={hasPremiumAccess}
      game={game}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      data-testid={testId}
    />
  );
};

PlayerSelector.propTypes = {
  ...propTypes,
  teamLogo: PropTypes.string,
  championImage: PropTypes.string,
  level: PropTypes.number,
};

export default PlayerSelector;
