import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetHostname = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.hostname ?? '');
};

export const useHostname = () => useMemoizedSelector(makeGetHostname);

export default makeGetHostname;
