import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetIsTablet = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.isTablet ?? false);
};

export const useIsTablet = () => useMemoizedSelector(makeGetIsTablet);

export default makeGetIsTablet;
