import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import VideoSkeleton from 'znipe-elements/feedback/VideoSkeleton/VideoSkeleton';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import { LoadingContainer, Content } from './HighlightClip.styles';
import { SMALL, MEDIUM, TILE, LIST } from './HighlightClip.constants';

const HighlightClip = ({ type = TILE, size = SMALL }) => {
  const { lineOneWidth, lineTwoWidth } = useMemo(() => {
    if (type === LIST && size === SMALL) {
      return {
        lineOneWidth: 120,
        lineTwoWidth: 100,
      };
    }
    return {
      lineOneWidth: 277,
      lineTwoWidth: 224,
    };
  }, [size, type]);

  return (
    <LoadingContainer data-testid="highlight-clip-loading" type={type}>
      <VideoSkeleton />
      <Content $isLoading>
        <TextSkeleton width={lineOneWidth} size={size} />
        <TextSkeleton width={lineTwoWidth} size={size} />
      </Content>
    </LoadingContainer>
  );
};

HighlightClip.propTypes = {
  type: PropTypes.oneOf([TILE, LIST]),
  size: PropTypes.oneOf([SMALL, MEDIUM]),
};

export default memo(HighlightClip);
