import { schema } from 'normalizr';

const keys = ['id', 'name', 'htmlDescription', 'image', 'stats', 'description', 'cost'];

const item = new schema.Entity(
  'items',
  {},
  {
    processStrategy: values =>
      keys.reduce((acc, key) => {
        const val = values[key];
        if (val) acc[key] = val;
        return acc;
      }, {}),
  },
);

export default item;
