import { memo } from 'react';
import PropTypes from 'prop-types';
import { InformationWrapper } from '../../../ScreenOverlay.styles';

const InformationHeader = ({ title = '', description = '' }) => (
  <InformationWrapper>
    {title && title}
    {description && (
      <>
        <br />
        {description}
      </>
    )}
  </InformationWrapper>
);

InformationHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default memo(InformationHeader);
