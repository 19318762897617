import { createSelector } from 'reselect';
import makeGetSelectedProduct from 'tv-selectors/products/makeGetSelectedProduct';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';

const makeGetProductSubscriptionTrialDays = () => {
  const getSelectedProduct = makeGetSelectedProduct();
  return createSelector([getSelectedProduct], product => product.subscriptionTrialDays ?? 0);
};

export const useGetProductSubscriptionTrialDays = props =>
  useMemoizedSelector(makeGetProductSubscriptionTrialDays, props);

export default makeGetProductSubscriptionTrialDays;
