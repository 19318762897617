import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';

const useQueryObject = () => {
  const { search } = useLocation();
  return useMemo(() => queryStringToObject(search), [search]);
};

export default useQueryObject;
