import styled from 'styled-components';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import gradients from 'znipe-styles/gradients';
import colors from 'znipe-styles/colors';
import { animated } from '@react-spring/web';

export const Container = styled.div`
  position: absolute;
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const textSizes = {
  xsmall: 16,
  small: 22,
  medium: 28,
  large: 40,
  xlarge: 56,
};

export const KillBorder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${convertHexToRGB(colors.ravenousRed, 0.1)};
  box-shadow: inset 0px 0px 30px 3px ${convertHexToRGB(colors.ravenousRed, 0.65)};
`;

const paddings = {
  xsmall: '10px 16px',
  small: '8px 24px',
  medium: '11px 24px',
  large: '12px 24px',
  xlarge: '16px 24px',
};

export const Text = styled.div`
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ $size }) => textSizes[$size]}px;
  line-height: ${({ $size }) => textSizes[$size]}px;
  color: ${({ theme }) => theme.primaryTextColor};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  width: max-content;
  @supports (-webkit-text-stroke: 3px transparent) {
    transform: skew(-20deg);
    background: ${gradients.ravenousRedGradient};
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable property-no-vendor-prefix */
  }
`;

export const Centering = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TextBox = styled(animated.div)`
  position: absolute;
  top: 0px;
  margin: auto;
  padding: ${({ $size }) => paddings[$size]};
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 10px #000000;
  backdrop-filter: blur(20px);
  z-index: 10;

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
`;
