import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsDesktopLargeOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.desktopLarge || false);
};

export const useGetIsDesktopLargeOrGreater = () =>
  useMemoizedSelector(makeGetIsDesktopLargeOrGreater);

export default makeGetIsDesktopLargeOrGreater;
