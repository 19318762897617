import { useState, useEffect } from 'react';
import { map, distinctUntilChanged } from 'rxjs/operators';
import useChatContext from './useChatContext';

const useMessageIds = () => {
  const { chatStore } = useChatContext();
  const [ids, setIds] = useState(chatStore?.getState().messageIds ?? []);

  useEffect(() => {
    if (!chatStore?.subject) return undefined;
    const messageIds$ = chatStore.subject.pipe(
      map(({ messageIds }) => messageIds),
      distinctUntilChanged(),
    );

    const subscription = messageIds$.subscribe(setIds);

    return () => subscription.unsubscribe();
  }, [chatStore?.subject]);

  return ids;
};

export default useMessageIds;
