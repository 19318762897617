import { memo } from 'react';
import PropTypes from 'prop-types';
import { useGetAuthSubscriptionByProductId } from 'tv-selectors/auth/makeGetAuthSubscriptionByProductId';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import formatDate from 'znipe-utils/date/formatDate';
import useThemeContext from 'znipe-hooks/useThemeContext';
import hideEmailUsername from 'tv-utils/hideEmailUsername';
import { formatPrice } from 'tv-routes/Subscribe/hooks/usePriceInfoText';
import PromoCodeBanner from '../PromoCodeBanner/PromoCodeBanner';
import themes from './PaymentSummary.themes';
import {
  Container,
  SecureDetails,
  List,
  TagLine,
  Item,
  CardTitle,
  IconWrapper,
  BoldText,
  CardDetails,
} from './PaymentSummary.styles';

// Regex to catche dates with the format +1000000000-12-31T23:59:59.999999999Z
const foreverDateRegex = /\+10{9}(-\d{2}){2}T(\d{2}(:|.)){3}9{9}Z/;

const PaymentSummary = ({ productId }) => {
  const { paymentSummary = {} } = useThemeContext();
  const allSubData = useGetAuthSubscriptionByProductId({
    productId,
    returnAllSubs: true,
  });

  const today = Date.now();

  // Trial data
  const trialEndData = allSubData[allSubData.length - 1]?.trialEnd;
  const hasTrial = Date.parse(trialEndData) > today;
  const trialEndDate = formatDate(trialEndData, false, 'MMDD');

  // Promo data
  const subscriptionData = allSubData[0] ?? {};
  const codes = subscriptionData.campaignCodes ?? [];
  const getPromoDate = codes.filter(code => code.campaignCodeEnds ?? false);

  const promoEnd = getPromoDate.length > 0 && getPromoDate[0].campaignCodeEnds;

  const cardBrand = subscriptionData.cardBrand ?? '';
  const cardLast4 = subscriptionData.cardLast4 ?? '****';
  const paypalEmail = hideEmailUsername(subscriptionData.paypalEmail ?? '');
  const { currentBillingEnd } = subscriptionData;
  const friendlyBillingEnd = formatDate(currentBillingEnd, false, 'MMDD');
  const chargeAmount = subscriptionData.nextChargeAmount;
  const { currency } = subscriptionData;
  const formattedPrice = formatPrice(chargeAmount, currency);
  const isPaypal = /braintree/i.test(subscriptionData.provider);
  const isFreeForever = foreverDateRegex.test(subscriptionData.currentBillingEnd);

  const promoIsActive =
    foreverDateRegex.test(promoEnd) ||
    foreverDateRegex.test(currentBillingEnd) ||
    new Date(promoEnd) > today;

  return (
    <Container data-testid="payment-summary-container">
      {promoIsActive && (
        <PromoCodeBanner
          productId={productId}
          hasTrial={!!hasTrial}
          isFreeForever={!!isFreeForever}
        />
      )}
      {!isFreeForever && (
        <List data-testid="list">
          <Item data-testid="card-details">
            <CardDetails>
              {!isPaypal && (
                <>
                  <CardTitle data-testid={cardBrand}>{cardBrand}</CardTitle>: **** {cardLast4}
                </>
              )}
              {isPaypal && (
                <>
                  <CardTitle>Paypal</CardTitle>: {paypalEmail}
                </>
              )}
            </CardDetails>
            <IconWrapper>
              <Icon type={cardBrand || 'paypal'} size={26} inline />
            </IconWrapper>
          </Item>
          <Item data-testid="next-payment">
            Next payment due: {friendlyBillingEnd}&nbsp;
            <BoldText>{formattedPrice}</BoldText>
          </Item>
          {hasTrial && (
            <Item data-testid="trial-days">
              Free trial ends:&nbsp;
              <BoldText>{trialEndDate}</BoldText>
            </Item>
          )}
        </List>
      )}
      {!isPaypal && !isFreeForever && (
        <SecureDetails data-testid="stripe-logo">
          <TagLine>Secure payments handled by</TagLine>
          <IconWrapper>
            <Icon type="stripe" size={17} inline />
            <Icon type="lockIcon" fillColor={paymentSummary.iconColor} size={17} inline />
          </IconWrapper>
        </SecureDetails>
      )}
    </Container>
  );
};

PaymentSummary.propTypes = { productId: PropTypes.string.isRequired };

export default withTheme(memo(PaymentSummary), themes, 'paymentSummary');
