import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetIsOnboardingDisabled from 'tv-selectors/auth/makeGetIsOnboardingDisabled';
import makeGetIsOnboardingStepPlayed from 'tv-selectors/auth/makeGetIsOnboardingStepPlayed';
import isInIframe from 'tv-utils/isInIframe';

const makeGetShouldShowOnboardingStep = () => {
  const getIsOnboardingDisabled = makeGetIsOnboardingDisabled();
  const getIsOnboardingStepPlayed = makeGetIsOnboardingStepPlayed();
  return createSelector(
    [getIsOnboardingDisabled, getIsOnboardingStepPlayed],
    (isOnboardingDisabled, isOnboardingStepPlayed) =>
      !isOnboardingDisabled && !isOnboardingStepPlayed && !isInIframe(),
  );
};

export const useShouldShowOnboardingStep = props =>
  useMemoizedSelector(makeGetShouldShowOnboardingStep, props);

export default makeGetShouldShowOnboardingStep;
