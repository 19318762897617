import { useMemo } from 'react';
import SelectorHorizontal, { leftImageSize } from '../SelectorHorizontal';
import { LeftImage } from '../SelectorHorizontal.styles';
import { propTypes, STREAM_TYPE_EVENT } from '../../constants';

const EventSelector = ({
  label,
  size = 'small',
  image = '',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
}) => {
  const imageComponent = useMemo(() => {
    const imageWidth = leftImageSize[size];
    return typeof image === 'string' ? (
      <LeftImage $imageWidth={imageWidth}>
        <img src={image} width={imageWidth} height={imageWidth} alt="logo" />
      </LeftImage>
    ) : (
      <LeftImage $imageWidth={imageWidth}>{image}</LeftImage>
    );
  }, [image, size]);
  return (
    <SelectorHorizontal
      streamType={STREAM_TYPE_EVENT}
      label={label}
      size={size}
      leftArea={imageComponent}
      isSelected={isSelected}
      isLocked={isLocked}
      hasPremiumAccess={hasPremiumAccess}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      data-testid={testId}
    />
  );
};

EventSelector.propTypes = propTypes;

export default EventSelector;
