import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import colors from 'znipe-styles/colors';
import { emojiTileFeedbackStyles } from '../EmojiTile/EmojiTile.styles';

type ContainerProps = {
  $hasScroll?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  padding: ${({ $hasScroll = false }) => ($hasScroll ? '8px 0 8px 8px' : '8px')};
  box-shadow: 0px -9px 12px 0px ${convertHexToRGB(colors.black, 0.45)};
`;

type ListItemProps = {
  $hasFocus?: boolean;
  $height?: number;
  $color?: string;
};

export const ListItem = styled.button<ListItemProps>`
  ${buttonDefaultStyle};
  display: flex;
  padding: 4px;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  color: ${({ theme, $color }) => $color ?? theme.textPrimary};
  ${emojiTileFeedbackStyles};
  ${({ $height }) =>
    $height
      ? css`
          height: ${$height}px;
        `
      : null}
  ${({ $hasFocus = false }) =>
    $hasFocus
      ? css`
          background: ${({ theme }) => theme.tertiaryBackgroundColor};
        `
      : css`
          background: unset;
        `}
`;

export const EmojiName = styled.div`
  display: flex;
  height: 20px;
  margin-left: 4px;
`;

type TextProps = {
  $highlighted?: boolean;
};

export const Text = styled(Typography).attrs({ type: 'paragraph-s' })<TextProps>`
  ${({ $highlighted = false }) =>
    $highlighted && `background: ${convertHexToRGB(colors.white, 0.1)}`};
  height: 100%;
  margin-bottom: 0;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`;

type ScrollAreaProps = {
  $hasScrollFrom: number;
};

export const ScrollArea = styled.div<ScrollAreaProps>`
  ${Scrollbar};
  overflow-x: hidden;
  max-height: calc(32px * ${({ $hasScrollFrom }) => $hasScrollFrom});
`;
