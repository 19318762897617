import colors from 'znipe-styles/colors';

export default {
  proview: {
    highlightColor: colors.spaceGreen,
    unselectedColor: colors.grey75,
    optionTextColor: colors.riotTextPrimary,
    headerTextColor: colors.riotTextPrimary,
    bgColorHover: 'rgba(255, 255, 255, 0.1)',
    subMenuBorderColor: colors.riotBackgroundRule,
    shadowColor: colors.riotBackgroundSecondary,
  },
  default: {
    highlightColor: colors.spaceGreen,
    unselectedColor: colors.grey75,
    optionTextColor: colors.grey75,
    headerTextColor: colors.riotTextPrimary,
    bgColorHover: 'rgba(255, 255, 255, 0.1)',
    subMenuBorderColor: colors.riotBackgroundRule,
    shadowColor: colors.riotBackgroundSecondary,
  },
};
