import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedPlaylist from './makeGetSelectedPlaylistV2';

const makeGetPlaylistThumbnail = () => {
  const getSelectedPlaylist = makeGetSelectedPlaylist();
  return createSelector(
    [getSelectedPlaylist],
    selectedPlaylist => selectedPlaylist?.thumbnailUrl ?? '',
  );
};

export const useGetPlaylistThumbnail = props =>
  useMemoizedGqlSelector(makeGetPlaylistThumbnail, props);

export default makeGetPlaylistThumbnail;
