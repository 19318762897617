import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetVideoThumbnail = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => video.thumbnailUrl || '');
};

export const useGetVideoThumbnail = props => useMemoizedGqlSelector(makeGetVideoThumbnail, props);

export default makeGetVideoThumbnail;
