const defaultData = {
  title: 'Znipe.Tv',
  descriptions: {
    home: 'Discover pro player POVs and more esport related content right here on Znipe Tv.',
    browse:
      'Find all available Znipe Tv content categorised by players, teams, champions, and more.',
    standings: 'Check up on the most recent upsets and losses of ongoing esport events',
    matches: 'View upcoming as well as past pro matches with player POVs.',
  },
  thumbnail: 'https://assets.znipe.tv/thumbnails/generic-social-card-2.jpg',
  favicon: 'https://assets.znipe.tv/icons/favicon.jpg',
};

const proviewData = {
  title: 'Pro View',
  descriptions: {
    home: 'Discover pro player POVs and more LoL Esports related content right here on Pro View.',
    browse:
      'Find all available Pro View content categorised by players, teams, champions, and more.',
    standings: 'Check up on the most recent upsets and losses of ongoing LoL Esports events',
    matches: 'View upcoming as well as past pro matches with player POVs.',
  },
  thumbnail: 'https://assets.znipe.tv/thumbnails/Embed_Image_PV.jpg',
  favicon: 'https://assets.znipe.tv/icons/PV_Favicon.png',
};

export default {
  znipe: defaultData,
  proview: proviewData,
  default: defaultData,
};
