import { useState, useEffect } from 'react';
import prefers from 'znipe-styles/prefers';

const isRenderingOnServer = typeof window === 'undefined';

const getInitialState = () =>
  // For our initial server render, we won't know if the user
  // prefers reduced motion, but it doesn't matter. This value
  // will be overwritten on the client, before any animations occur.
  isRenderingOnServer ? true : !window.matchMedia(prefers.motion)?.matches;

// taken from https://www.joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion/
const usePrefersReducedMotion = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(getInitialState);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(prefers.motion);

    const listener = event => {
      setPrefersReducedMotion(!event.matches);
    };

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', listener);
    } else {
      mediaQueryList.addListener(listener);
    }

    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', listener);
      } else {
        mediaQueryList.removeListener(listener);
      }
    };
  }, []);

  return prefersReducedMotion;
};

export default usePrefersReducedMotion;
