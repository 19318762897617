import { useEffect } from 'react';
import { fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import { GAME_PAUSE_EVENT } from 'tv-reducers/intensive';
import useGatId from 'tv-hooks/useGatId';
import { setGatGameTitle } from 'tv-actions/intensive';
import { useGetMatchGameTitle } from 'tv-selectors/match/makeGetMatchGameTitle';
import logger from 'znipe-logger';

export const SUBSCRIBE_GAME_PAUSES = 'SUBSCRIBE_GAME_PAUSES';
export const UNSUBSCRIBE_GAME_PAUSES = 'UNSUBSCRIBE_GAME_PASUES';

const PAUSE_TYPE = 'PAUSE_TYPE';
const UN_PAUSE_TYPE = 'UN_PAUSE_TYPE';
const CHRONO_BREAK_TYPE = 'CHRONO_BREAK_TYPE';

export const useGamePauseSubscription = matchId => {
  const dispatch = useIntensiveDispatch();
  const gatId = useGatId(matchId);
  const gameTitle = useGetMatchGameTitle({ matchId });

  useEffect(() => {
    if (!gatId || !gameTitle) return undefined;
    dispatch(setGatGameTitle(gatId, gameTitle));
    dispatch({ type: SUBSCRIBE_GAME_PAUSES, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_GAME_PAUSES, gatId });
  }, [dispatch, gatId, gameTitle]);
};

const convertToPausePayload = ({ payload, type }, isMilliseconds) => {
  const gameTime = payload?.gameTime ?? 0;
  const divider = isMilliseconds ? 1000 : 1;
  const time = typeof gameTime === 'undefined' ? undefined : gameTime / divider;
  return {
    ...payload,
    startTime: type === PAUSE_TYPE || type === CHRONO_BREAK_TYPE ? time : undefined,
    endTime: type === UN_PAUSE_TYPE || type === CHRONO_BREAK_TYPE ? time : undefined,
  };
};

const order = {
  lol: 'actionType',
  default: 'type',
};

const startKey = {
  lol: 'pause_started',
  default: 'MatchPauseEvent',
};

const endKey = {
  lol: 'pause_ended',
  default: 'MatchUnpauseEvent',
};

const chronoBreakKey = {
  lol: 'chrono_break',
  default: 'chrono_break',
};

export const mergePauseEvents = (newPauses, state, gatId) => {
  const gameTitle = state?.gats[gatId]?.gameTitle;
  const currentPauses = Object.values(state?.pauses ?? {});
  const ongoingPauses = currentPauses.filter(
    ({ startTime, endTime }) => typeof startTime === 'number' && typeof endTime !== 'number',
  );

  const typeName = order[gameTitle] ?? order.default;
  const chronoKey = chronoBreakKey[gameTitle] ?? chronoBreakKey.default;

  const filteredNewPauses = newPauses.filter(
    ({ startTime, endTime, ...rest }) =>
      (typeof startTime !== 'undefined' || typeof endTime !== 'undefined') &&
      !currentPauses.find(
        pause =>
          ((typeof startTime === 'number' && startTime === pause.startTime) ||
            (typeof endTime === 'number' && endTime === pause.endTime)) &&
          rest[typeName] === pause[typeName],
      ),
  );

  const allPauseEvents = [...ongoingPauses, ...filteredNewPauses];

  const values = {
    [startKey[gameTitle] ?? startKey.default]: 0,
    [endKey[gameTitle] ?? endKey.default]: 1,
    [chronoBreakKey[gameTitle] ?? chronoBreakKey.default]: 0,
  };
  const sortedPauseEvents = Object.values(allPauseEvents).sort((a, b) => {
    const aValue = values[a[typeName]] ?? 1;
    const bValue = values[b[typeName]] ?? 1;
    if (aValue !== bValue) return aValue - bValue;

    const aGameTime = a.startTime ?? a.endTime ?? 0;
    const bGameTime = b.startTime ?? b.endTime ?? 0;

    if (aGameTime !== bGameTime) return aGameTime - bGameTime;

    if (a[typeName] === chronoKey) return -1;
    if (b[typeName] === chronoKey) return 1;
    return 0;
  });

  const chronoBreakTimes = sortedPauseEvents.reduce((breaks, event) => {
    if (event[typeName] !== chronoKey) return breaks;
    breaks.push({
      start: new Date(event.timestamp).getTime(),
      end: new Date(event.timestamp).getTime() + event.duration * 1000,
    });
    return breaks;
  }, []);

  const chronoBreakFilter = event => {
    if (event[typeName] === chronoKey) return true;
    const eventStart = new Date(event.timestamp).getTime();
    const foundBreak = chronoBreakTimes.find(
      ({ start, end }) => eventStart > start && eventStart < end,
    );
    return !foundBreak;
  };
  const allPauseEventsFiltered = sortedPauseEvents.filter(chronoBreakFilter);
  const filteredCurrentPauses = currentPauses.filter(chronoBreakFilter);

  const mapId = ({ sequenceId }) => sequenceId ?? 0;
  return allPauseEventsFiltered.reduce((pauses, event) => {
    const isUnPauseEvent =
      typeof event.endTime === 'number' && typeof event.startTime === 'undefined';
    if (!isUnPauseEvent) {
      const sequenceId =
        event.sequenceId ??
        Math.max(...pauses.map(mapId), ...filteredCurrentPauses.map(mapId), 0) + 1;
      return [...pauses, { ...event, sequenceId }];
    }

    const { endTime } = event;
    const possiblePauses = pauses
      .map((e, index) => ({ ...e, index }))
      .filter(pause => endTime > (pause.endTime ?? -1) && endTime >= pause.startTime);
    const { index } = possiblePauses[possiblePauses.length - 1] || {};
    if (!pauses[index]) {
      logger.error('Received unpause without a matching pause event', event);
      return pauses;
    }
    const { startTime } = pauses[index];
    // Lol does not update gameTime during pauses making start and end time the same. So we use timestamps here instead
    const special = state?.gats?.[gatId]?.gameTitle === 'lol';
    const duration = special
      ? (new Date(event.timestamp).getTime() - new Date(pauses[index].timestamp).getTime()) / 1000
      : endTime - startTime;

    pauses[index].endTime = endTime; // eslint-disable-line no-param-reassign
    pauses[index].duration = duration; // eslint-disable-line no-param-reassign
    return pauses;
  }, []);
};

const gamePauseEvents = factorySubscribeEpic(
  [SUBSCRIBE_GAME_PAUSES, UNSUBSCRIBE_GAME_PAUSES],
  ({ gatId }) => gatId,
  ({ gatId }, state$) => {
    const gameTitle = state$.value.gats[gatId]?.gameTitle;
    const startRef = firebase
      .database()
      .ref(`/gat/${gatId}`)
      .orderByChild(order[gameTitle] ?? order.default)
      .equalTo(startKey[gameTitle] ?? startKey.default);
    const endRef = firebase
      .database()
      .ref(`/gat/${gatId}`)
      .orderByChild(order[gameTitle] ?? order.default)
      .equalTo(endKey[gameTitle] ?? endKey.default);
    const chronoBreakRef = firebase
      .database()
      .ref(`/gat/${gatId}`)
      .orderByChild('actionType')
      .equalTo('chrono_break');

    return merge(
      fromEvent(startRef, 'child_added').pipe(
        map(([snap]) => ({ payload: snap.val(), type: PAUSE_TYPE })),
      ),
      fromEvent(endRef, 'child_added').pipe(
        map(([snap]) => ({ payload: snap.val(), type: UN_PAUSE_TYPE })),
      ),
      fromEvent(chronoBreakRef, 'child_added').pipe(
        map(([snap]) => ({ payload: snap.val(), type: CHRONO_BREAK_TYPE })),
      ),
    ).pipe(map(data => convertToPausePayload(data, gameTitle === 'lol')));
  },
  ({ gatId }, state$) =>
    payload => {
      const pauses = mergePauseEvents(payload, state$.value, gatId);

      return {
        type: GAME_PAUSE_EVENT,
        gatId,
        payload: { id: gatId, pauses },
      };
    },
);

export default gamePauseEvents;
