import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Description = styled.span`
  text-align: left;
  font-size: 17px;
  line-height: 150%;
  margin-bottom: 10px;
`;

export const Options = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: repeat(${props => props.size}, 30px);
  margin-bottom: 30px;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;

export const Actions = styled.div`
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
