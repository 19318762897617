import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedFeaturedContent from 'tv-selectors/featured/makeGetSelectedFeaturedContent';

const emptyArray = [];

const makeGetFeaturedHighlight = () => {
  const getSelectedFeaturedContent = makeGetSelectedFeaturedContent();
  return createSelector(
    [getSelectedFeaturedContent],
    featuredContent => featuredContent.videos || emptyArray,
  );
};

export const useGetFeaturedHighlight = props =>
  useMemoizedGqlSelector(makeGetFeaturedHighlight, props);

export default makeGetFeaturedHighlight;
