import {
  SET_VOLUME,
  TOGGLE_MUTE,
  SET_PANES,
  SET_QUALITY,
  SET_MUTE,
  SET_CONTROL,
  NUMBER_OF_ALLOWED_PANES,
  SET_CASTER_VOLUME_RATIO,
  SET_POV_VOLUME_RATIO,
  TOGGLE_CASTER_AUDIO,
  SET_CONTROL_EXTERNAL_VIDEO_MUTE,
  SET_AUTOPLAY,
  TOGGLE_POV_AUDIO,
} from 'tv-constants/oldRedux';

export const setControl = control => ({
  type: SET_CONTROL,
  control,
});

export const setVolume = (value, ignoreMuting = false) => ({
  type: SET_VOLUME,
  value,
  ignoreMuting,
  autoSync: true,
});

export const setQuality = value => ({
  type: SET_QUALITY,
  value,
  autoSync: true,
});

export const toggleMute = (videoID = null) => ({
  type: TOGGLE_MUTE,
  autoSync: true,
  videoID,
});

export const setMute = (value, videoID = null) => ({
  type: SET_MUTE,
  value,
  videoID,
});

export const setExternalVideoMute = value => ({
  type: SET_CONTROL_EXTERNAL_VIDEO_MUTE,
  value,
});

export const setNumberOfAllowedPanes = value => ({
  type: NUMBER_OF_ALLOWED_PANES,
  numberOfAllowedPanes: value,
});

export const setPanes = panes => ({
  type: SET_PANES,
  panes,
  autoSync: true,
});

export const postHighlight = shareUrl =>
  fetch(`${process.env.API_URL}/v2/highlights`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: `{"link":"${shareUrl}"}`,
  }).then(response => response.json());

export const setCasterVolumeRatio = value => ({
  type: SET_CASTER_VOLUME_RATIO,
  value,
});

export const setPovVolumeRatio = value => ({
  type: SET_POV_VOLUME_RATIO,
  value,
});

export const toggleCasterAudio = value => ({
  type: TOGGLE_CASTER_AUDIO,
  value,
});

export const togglePovAudio = value => ({
  type: TOGGLE_POV_AUDIO,
  value,
});

export const setAutoplay = value => ({
  type: SET_AUTOPLAY,
  value,
});
