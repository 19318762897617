import KDA from 'znipe-elements/data-display/KDA/KDA';
import { useMemo } from 'react';
import { KDAWrapper } from './Selector.styles';

const gamesToSwapDeathAssists = ['csgo'];

const useKDAComponent = (kda, gameTitle, size = 'xsmall', backgroundFade = false) => {
  const swapDeathsAssists = useMemo(() => gamesToSwapDeathAssists.includes(gameTitle), [gameTitle]);
  return useMemo(() => {
    if (!kda) return null;
    const { kills, deaths, assists } = kda ?? {};
    return (
      <KDAWrapper $backgroundFade={backgroundFade}>
        <KDA
          size={size}
          kills={kills}
          deaths={deaths}
          assists={assists}
          swapDeathsAssists={swapDeathsAssists}
          withWhiteSpace={false}
        />
      </KDAWrapper>
    );
  }, [kda, backgroundFade, size, swapDeathsAssists]);
};

export default useKDAComponent;
