import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import { TextContainer } from './TransitionMarker.styles';

const TransitionMarkerText = ({ text, position }) => (
  <TextContainer key={`textMarker-${position}`} pos={position}>
    <Typography type="title-m">{text}</Typography>
  </TextContainer>
);

TransitionMarkerText.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};

export default TransitionMarkerText;
