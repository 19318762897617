import { useMemo } from 'react';
import groupItemsByDate from 'znipe-utils/date/groupItemsByDate';
import { useGetMatches } from 'tv-selectors/match/makeGetMatches';

const defaultDate = new Date();

export const ASC = 'asc';
export const DESC = 'desc';
const invalidDate = 'Invalid Date';
const LIVE = 'live';

const useGroupMatchesByDate = (
  matchesArr = [],
  sortingOrder = DESC,
  dateAnchor = defaultDate,
  maxMatchesCount = -1,
) => {
  const matchesMap = useGetMatches();

  const matches = useMemo(
    () => [...new Set(matchesArr)].map(id => matchesMap[id]).filter(Boolean),
    [matchesArr, matchesMap],
  );

  return useMemo(() => {
    if (!matches?.length) return {};
    const groupedItems = groupItemsByDate(matches, dateAnchor, maxMatchesCount);

    const output = Object.keys(groupedItems)
      .sort((a, b) => {
        const sortBy = sortingOrder.toLowerCase();
        const aMatchStartTime = groupedItems[a][0].startTime;
        const bMatchStartTime = groupedItems[b][0].startTime;
        if (new Date(bMatchStartTime).toString() === invalidDate) return sortBy === ASC ? -1 : 1;
        if (sortBy === ASC) return aMatchStartTime - bMatchStartTime;
        return bMatchStartTime - aMatchStartTime;
      })
      .reduce((result = {}, key) => {
        const copy = { ...result };
        const selectedDate = groupedItems[key];
        if (key === 'today') {
          const liveMatches = selectedDate.filter(({ status }) => status === LIVE);
          copy[key] = [...new Set([...liveMatches, ...selectedDate])];
          return copy;
        }
        copy[key] = selectedDate;
        return copy;
      }, {});

    const { today, tomorrow, yesterday, ...rest } = output;

    if (sortingOrder === DESC) {
      return {
        tomorrow: tomorrow ?? [],
        today: today ?? [],
        yesterday: yesterday ?? [],
        ...rest,
      };
    }
    return {
      yesterday: yesterday ?? [],
      today: today ?? [],
      tomorrow: tomorrow ?? [],
      ...rest,
    };
  }, [dateAnchor, matches, sortingOrder, maxMatchesCount]);
};

export default useGroupMatchesByDate;
