import { memo, useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import isBrowser from 'znipe-utils/web/isBrowser';
import { useTransition, animated } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import DropdownButton from './DropdownButton/DropdownButton';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import { MULTI, SINGLE } from './Dropdown.constants';
import FloatingDropdownMenu, { FloatingContainer } from './DropdownMenu/FloatingDropdownMenu';
import {
  MenuWrapper,
  Overlay,
  Modal,
  ModalHeader,
  ModalContent,
  IconWrapper,
} from './Dropdown.styles';

const Dropdown = ({
  items,
  onDataChange,
  type = MULTI,
  label = '',
  description = '',
  closeOnSelect = true,
  useModal = false,
  error = false,
  customWidth = '',
  renderItem,
  renderSelected,
  dynamic = false,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [reducedLabel, setReducedLabel] = useState('');

  const handleButtonClick = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const generateSelectedItems = useCallback(() => {
    const reduceItems =
      items
        ?.reduce((filtered = [], item) => {
          if (item.selected) {
            filtered.push(item.title);
          }
          return filtered;
        }, [])
        .join(', ') ?? '';
    setReducedLabel(reduceItems);
  }, [items]);

  const handleMenuClose = useCallback(() => {
    if (!openMenu) return;
    generateSelectedItems();
    setOpenMenu(false);
  }, [generateSelectedItems, openMenu]);

  const ref = useOutsideClick(handleMenuClose);
  const prefersReducedMotion = usePrefersReducedMotion();

  const transitions = useTransition(openMenu, {
    from: { position: 'absolute', opacity: 0, zIndex: 3, width: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    immediate: prefersReducedMotion,
  });

  const buttonImage = useMemo(() => {
    if (type === MULTI) return '';
    const itemHasImage = items?.filter(item => item.image).length !== 0;
    if (!itemHasImage) return '';
    return items?.find(({ selected }) => selected)?.image || '';
  }, [items, type]);

  const buttonIcon = useMemo(() => {
    if (type === MULTI) return '';
    const itemHasIcon = items?.filter(item => item.icon).length !== 0;
    if (!itemHasIcon) return '';
    return items?.find(({ selected }) => selected)?.icon || '';
  }, [items, type]);

  const handleSelection = useCallback(
    itemId => {
      const oldSelection = items?.find(({ selected }) => selected);
      if (type === SINGLE && itemId === oldSelection?.id) return;
      const newSelection = items?.map(item => {
        if (item.id === itemId) {
          return { ...item, selected: type === MULTI ? !item.selected : true };
        }
        return { ...item, selected: type === MULTI ? item.selected : false };
      });
      onDataChange(newSelection);
      if (closeOnSelect && type === SINGLE) handleMenuClose();
    },
    [items, onDataChange, type, handleMenuClose, closeOnSelect],
  );

  useEffect(() => {
    const handleEscPress = e => {
      if (e.keyCode === 27) handleMenuClose();
    };
    document.addEventListener('keydown', handleEscPress);
    return () => document.removeEventListener('keydown', handleEscPress);
  }, [handleMenuClose]);

  useEffect(() => {
    generateSelectedItems();
  }, [generateSelectedItems]);

  if (useModal && openMenu) {
    return (
      <Overlay>
        <Modal>
          <ModalHeader>
            <Typography type="title-m">{label}</Typography>
            <IconWrapper onClick={handleMenuClose}>
              <Icon type="close" />
            </IconWrapper>
          </ModalHeader>
          <ModalContent>
            <DropdownMenu
              items={items}
              onSelect={handleSelection}
              isOpen={openMenu}
              useModal
              renderItem={renderItem}
            />
          </ModalContent>
        </Modal>
      </Overlay>
    );
  }

  if (dynamic && isBrowser()) {
    const anticipatedHeight = Math.min(window.innerHeight * 0.6, (items?.length ?? Infinity) * 40);
    return (
      <FloatingDropdownMenu anticipatedHeight={anticipatedHeight}>
        {isAbove => (
          <MenuWrapper ref={ref}>
            <DropdownButton
              onClick={handleButtonClick}
              isOpen={openMenu}
              description={type === SINGLE ? description : ''}
              label={reducedLabel || label}
              image={buttonImage}
              icon={buttonIcon}
              noAnimation={prefersReducedMotion}
              isModalType={useModal}
              error={error}
              customWidth={customWidth}
              renderSelected={renderSelected}
              $isAbove={isAbove}
            />
            {transitions(
              (props, item) =>
                !!item && (
                  <animated.div style={props} data-testid="menu-animation">
                    <FloatingContainer $isAbove={isAbove} $top={isAbove ? -40 : 0}>
                      <DropdownMenu
                        items={items}
                        onSelect={handleSelection}
                        isOpen={openMenu}
                        isMultiSelect={type === MULTI}
                        error={error}
                        renderItem={renderItem}
                        $isAbove={isAbove}
                        dynamic={dynamic}
                      />
                    </FloatingContainer>
                  </animated.div>
                ),
            )}
          </MenuWrapper>
        )}
      </FloatingDropdownMenu>
    );
  }

  return (
    <MenuWrapper ref={ref}>
      <DropdownButton
        onClick={handleButtonClick}
        isOpen={openMenu}
        description={type === SINGLE ? description : ''}
        label={reducedLabel || label}
        image={buttonImage}
        icon={buttonIcon}
        noAnimation={prefersReducedMotion}
        isModalType={useModal}
        error={error}
        customWidth={customWidth}
        renderSelected={renderSelected}
      />
      {transitions(
        (props, item) =>
          !!item && (
            <animated.div style={props} data-testid="menu-animation">
              <DropdownMenu
                items={items}
                onSelect={handleSelection}
                isOpen={openMenu}
                isMultiSelect={type === MULTI}
                error={error}
                renderItem={renderItem}
              />
            </animated.div>
          ),
      )}
    </MenuWrapper>
  );
};

export const itemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    selected: PropTypes.bool,
  }),
);

Dropdown.propTypes = {
  onDataChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([MULTI, SINGLE]),
  label: PropTypes.string,
  description: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  useModal: PropTypes.bool,
  items: itemsPropType,
  error: PropTypes.bool,
  customWidth: PropTypes.string,
  renderItem: PropTypes.func,
  renderSelected: PropTypes.func,
  dynamic: PropTypes.bool,
};

export default memo(Dropdown);
