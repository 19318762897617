import { useState, useEffect, useCallback } from 'react';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import stripHtml from '../utils/stripHtml';
import { getSelection, getContent } from '../utils/DOMNode';
import { isEitherReplyOrEmoji } from '../utils/entryType';
import { EMOJI_TAG_REGEX, LARGE_NUMBER } from '../ChatLockup.constants';
import useCalculateStringTypesLength from './useCalculateStringTypeLength';
import useIsValidEmoji from './useIsValidEmoji';
import type { CaretPositionFunctions } from './useCaretPosition';
import type { StringTypes } from '../ChatLockup.types';
import type { ReplyTo } from '../../../utils/chatStore';

const flattenAndCleanUpArray = (arr: StringTypes[]): string[] => arr.flat().filter(Boolean);

const useRawContent = (
  contentRef: React.RefObject<HTMLDivElement | undefined>,
  { getCaretPosition, caretPosition, storedFocusNode }: CaretPositionFunctions,
  characterLimit: number,
  applyInputHistory: (content: StringTypes) => void,
  searchQuery = '',
  initialValue = ['<br>'],
) => {
  const [rawContent, setRawContent] = useState(initialValue);
  const { analyticsManager, replyData, setReplyData } = useChatContext() ?? {};
  const calculateStringTypeLength = useCalculateStringTypesLength();
  const isValidEmoji = useIsValidEmoji();

  const resetRawContent = useCallback(() => {
    setRawContent(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setRawContent(prev => {
      if (!replyData?.username) return prev;
      if (prev.length === 1 && prev[prev.length - 1] === '<br>') {
        return ['<br>', `@${replyData.username}&nbsp;`, '<br>'];
      }

      if (prev.indexOf(replyData.username) > -1) return prev;
      return [...prev, `@${replyData.username}&nbsp;`, '<br>'];
    });
  }, [replyData]);

  const onInput = useCallback(
    (event: React.ChangeEvent<HTMLDivElement>) => {
      setRawContent(prev => {
        const { childNodes } = event.target;
        const { inputType } = event.nativeEvent as InputEvent;
        let slicedNext = [...childNodes];

        if (inputType === 'deleteContentBackward') {
          if (
            prev.length - 1 === childNodes.length &&
            (prev[prev.length - 1] === '' || prev[prev.length - 1] === '<br>')
          ) {
            slicedNext = [...childNodes].slice(0, childNodes.length - 1);
          }

          if (replyData !== null) {
            const username = replyData?.username;
            if (
              username &&
              (slicedNext.length === 0 ||
                !slicedNext.find((node: ChildNode) => node.textContent?.includes(username)))
            ) {
              setReplyData?.(null);
            }
          }
        }

        let caretOffset = 0;
        const next = slicedNext
          .reduce<StringTypes[]>((acc, node, index) => {
            const htmlContent = getContent(node);

            if (node instanceof Element) {
              if (node.hasAttribute('data-image')) {
                const img = node.querySelector('img');
                const alt = img?.getAttribute('alt');
                if (alt) acc.push(alt);
                return acc;
              }
              if (node.hasAttribute('data-reply')) {
                if (htmlContent) acc.push(htmlContent.replace(/\s/g, '&nbsp;'));
                return acc;
              }
            }

            if (!htmlContent) {
              // in firefox you couldn't cmd+a everything and then hit backspace
              // this resolves it
              if (inputType === 'deleteContentBackward' && !replyData?.username) {
                return [...acc, '<br>'];
              }

              return acc;
            }
            const foundEmoji = htmlContent
              .match(EMOJI_TAG_REGEX)
              ?.filter(emoji => isValidEmoji(emoji));

            if (!foundEmoji || foundEmoji.length < 1) {
              const cleanContent = stripHtml(htmlContent);
              if (!htmlContent.endsWith('<br>') && index === slicedNext.length - 1) {
                acc.push(`${cleanContent}<br>`);
              } else {
                acc.push(cleanContent);
              }
              return acc;
            }

            const result = foundEmoji
              .reduce((acc2, emoji, index2) => {
                caretOffset += emoji.length - 1;
                const restContent = htmlContent.replace(acc2.join(''), '');
                const emojiIndex = restContent.indexOf(emoji);

                const res = [...acc2, restContent.substring(0, emojiIndex), emoji];

                if (index2 === foundEmoji.length - 1) {
                  res.push(htmlContent.substring(res.join('').length));
                }

                return res;
              }, [] as string[])
              .filter(Boolean);
            acc.push(result);

            return acc;
          }, [])
          .filter(Boolean);

        if (next.length < 1) next.push('<br>');
        else {
          const flat = next.flat();
          // Adds a <br> if we deleted content and the last entry is an emoji
          if (isEitherReplyOrEmoji(flat[flat.length - 1])) next.push('<br>');
          // Add a <br> if we added content and the first entry is an emoji
          if (isEitherReplyOrEmoji(flat[0])) next.unshift('<br>');
        }
        getCaretPosition(-caretOffset);
        const normalizedNext = flattenAndCleanUpArray(next);
        applyInputHistory(prev);
        return normalizedNext;
      });
    },
    [getCaretPosition, replyData, setReplyData, isValidEmoji, applyInputHistory],
  );

  const getFocusContent = useCallback(() => {
    if (!contentRef.current) return null;

    const selection = getSelection(contentRef.current);
    const focusNode = (
      selection?.focusNode && contentRef.current.contains(selection?.focusNode)
        ? selection?.focusNode
        : storedFocusNode.current
    ) as HTMLElement | null;

    const focusContent = focusNode ? getContent(focusNode) : '';

    return focusContent;
  }, [contentRef, storedFocusNode]);

  const updateRawContent = useCallback(
    (content: StringTypes, forceUpdate = false, replace = false) =>
      setRawContent(prev => {
        if (prev.join('').replace(/<br>/g, '').length >= characterLimit) {
          return prev;
        }

        getCaretPosition();
        const focusContent = getFocusContent();

        const [anchorNode, focusNode] = caretPosition.current ?? [LARGE_NUMBER, LARGE_NUMBER]; // Default to end of string
        const selectionStart = Math.min(anchorNode, focusNode);
        const selectionEnd = Math.max(anchorNode, focusNode);
        const contentLength = calculateStringTypeLength(content);

        if (replace) {
          getCaretPosition(contentLength - searchQuery.length - (selectionEnd - selectionStart));
          const nextReplaceState = typeof content === 'string' ? [content] : content;
          if (forceUpdate && nextReplaceState.length === prev.length) nextReplaceState.push('<br>');
          return nextReplaceState;
        }

        let charactersUntilInjection = selectionStart;
        let charactersToDelete = selectionEnd - selectionStart;

        let injected = false;

        const next = prev.reduce((acc, item) => {
          const cleanedItem = item.replace(/<br>/g, '').replace(/\s/, ' ');
          const isEmoji = isValidEmoji(item);
          const isReply = replyData?.username && item === `@${replyData?.username}&nbsp;`;
          const validInjectionNode =
            focusContent &&
            cleanedItem.indexOf(focusContent.replace(/\s/g, ' ')) > -1 &&
            !isEmoji &&
            !isReply;

          if (validInjectionNode && charactersUntilInjection <= cleanedItem.length && !injected) {
            const before = item.substring(0, charactersUntilInjection);
            const cleanedBefore = before.replace(searchQuery, '');
            const after = item.substring(charactersUntilInjection, item.length);
            const cleanedAfter = after.substring(charactersToDelete);

            const data = [cleanedBefore, content, cleanedAfter].flat().filter(Boolean);
            const subItems = data.reduce((innerAcc, current) => {
              const innerPrev = innerAcc[innerAcc.length - 1];
              if (!innerPrev || isValidEmoji(innerPrev) || isValidEmoji(current)) {
                innerAcc.push(current);
                return innerAcc;
              }

              // eslint-disable-next-line no-param-reassign
              innerAcc[innerAcc.length - 1] = innerPrev + current;

              return innerAcc;
            }, [] as string[]);

            acc.push(...subItems);

            injected = true;
            charactersUntilInjection = 0;
            charactersToDelete -= item.length - cleanedAfter.length - before.length;

            return acc;
          }
          if (!isEmoji && !isReply) {
            charactersUntilInjection -= cleanedItem.length;
          } else {
            charactersUntilInjection -= 1;
          }

          if (charactersToDelete > 0 && charactersUntilInjection < 1) {
            if (isEmoji) {
              charactersToDelete -= 1;
              return acc;
            }
            if (isReply) {
              charactersToDelete -= 1;
              setReplyData?.(null);
              return acc;
            }
            const cleanItem = item.substring(charactersToDelete);
            charactersToDelete -= item.length - cleanItem.length;
            if (cleanItem) acc.push(cleanItem);
            return acc;
          }

          if (!injected && charactersUntilInjection < 1 && isEmoji) {
            acc.push(cleanedItem, ...(typeof content === 'string' ? [content] : content));
            injected = true;
          } else {
            acc.push(cleanedItem);
          }

          return acc;
        }, [] as StringTypes[]);

        if (!injected) {
          const lastItemOfNext = next[next.length - 1];

          if (
            typeof lastItemOfNext === 'string' &&
            searchQuery.length &&
            lastItemOfNext.indexOf(searchQuery) > -1
          ) {
            next[next.length - 1] = lastItemOfNext.replace(searchQuery, '');
          }

          if (
            typeof lastItemOfNext === 'string' &&
            typeof content === 'string' &&
            !isValidEmoji(lastItemOfNext) &&
            !isValidEmoji(content) &&
            lastItemOfNext !== '<br>'
          ) {
            next[next.length - 1] = lastItemOfNext + content;
          } else {
            next.push(content);
          }
        }

        const nextState = flattenAndCleanUpArray(next);
        // Adds a <br> if we deleted content and the last entry is an emoji
        if (isEitherReplyOrEmoji(nextState[nextState.length - 1])) nextState.push('<br>');
        // Add a <br> if we added content and the first entry is an emoji
        if (isEitherReplyOrEmoji(nextState[0])) nextState.unshift('<br>');

        getCaretPosition(
          contentLength - searchQuery.length - (selectionEnd - selectionStart),
          selectionEnd > selectionStart && anchorNode > focusNode ? 'anchor' : 'focus',
        );

        if (forceUpdate && nextState.length === prev.length) nextState.push('<br>');
        applyInputHistory(nextState);
        return nextState;
      }),
    [
      getCaretPosition,
      getFocusContent,
      caretPosition,
      replyData?.username,
      setReplyData,
      calculateStringTypeLength,
      isValidEmoji,
      searchQuery,
      characterLimit,
      applyInputHistory,
    ],
  );

  const insertEmoji = useCallback(
    (shortcode: string, action: string, trigger: string) => {
      if (!shortcode) return;
      if (!contentRef.current) return;

      if (action && analyticsManager) {
        analyticsManager?.trackUIEvent({ action, trigger, value: shortcode });
      }

      updateRawContent(`:${shortcode}:`);
    },
    [contentRef, analyticsManager, updateRawContent],
  );

  const tagUser = useCallback(
    (initialMessage: string, user: ReplyTo) => {
      if (!user) return;
      setReplyData?.(user);
      setRawContent((prev: string[]) => prev.map(str => str.replace(initialMessage, '')));
    },
    [setReplyData],
  );

  return { rawContent, resetRawContent, onInput, updateRawContent, insertEmoji, tagUser };
};

export default useRawContent;
