import { useMemo } from 'react';
import SelectorSlimHorizontal from '../SelectorSlimHorizontal';
import { propTypes } from '../../constants';

const EventSelector = ({
  label,
  size = 'small',
  image = '',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
}) => {
  const imageComponent = useMemo(
    () => (typeof image === 'string' ? <img src={image} alt="logo" /> : image),
    [image],
  );

  return (
    <SelectorSlimHorizontal
      label={label}
      size={size}
      imageArea={imageComponent}
      isSelected={isSelected}
      isLocked={isLocked}
      hasPremiumAccess={hasPremiumAccess}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      data-testid={testId}
    />
  );
};

EventSelector.propTypes = propTypes;

export default EventSelector;
