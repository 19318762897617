import { SET_GAMES, SET_GAME_GAT_ID } from 'tv-constants/redux';

export const setGames = (games = {}) => ({
  type: SET_GAMES,
  payload: games,
});

export const setGameGatId = (gameId, gatId) => ({
  type: SET_GAME_GAT_ID,
  payload: {
    gameId,
    gatId,
  },
});
