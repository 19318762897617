import PropTypes from 'prop-types';
import { Container, Countdown } from './CountdownTimerLine.styles';

const CountdownTimerLine = ({
  total,
  remaining = 0,
  color = '#fff',
  transitionDurationWidth = 0,
  borderRadius,
}) => {
  const fillRatio = remaining / total < 0 ? 0 : (remaining / total) * 100;
  return (
    <Container data-testid="timer-line-container" $borderRadius={borderRadius}>
      <Countdown
        data-testid="timer-line"
        total={total}
        fillRatio={fillRatio}
        transitionDurationWidth={transitionDurationWidth}
        $barColor={color}
      />
    </Container>
  );
};

CountdownTimerLine.propTypes = {
  total: PropTypes.number.isRequired,
  remaining: PropTypes.number,
  color: PropTypes.string,
  transitionDurationWidth: PropTypes.number,
  borderRadius: PropTypes.string,
};

export default CountdownTimerLine;
