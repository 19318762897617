import PropTypes from 'prop-types';
import { SVG, Path, BackgroundContainer } from './Waves.styles';

const Waves = ({ animate = false, singleWave = false, shouldPreserveAspectRatio = true }) => (
  <SVG
    viewBox="0 0 768 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio={shouldPreserveAspectRatio ? 'xMidYMid meet' : 'none'}
    isSingleWave={singleWave}
  >
    <g clipPath="url(#waves_clip0)">
      <Path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M932 -57L914.345 -40.6667C896.155 -24.3333 860.845 8.33334 825 16.5C789.155 24.6667 753.845 8.33334 718 11.0556C682.155 13.7778 646.845 35.5556 611 43.7222C575.155 51.8889 539.845 46.4444 504 41C468.155 35.5556 432.845 30.1111 397 35.5555C361.155 41 325.845 57.3333 307.655 65.5L267 90H290H307.655C325.845 90 361.155 90 397 90C432.845 90 468.155 90 504 90C539.845 90 575.155 90 611 90C646.845 90 682.155 90 718 90C753.845 90 789.155 90 825 90C860.845 90 896.155 90 914.345 90H932V-57Z"
        fill="url(#waves0)"
        animate={animate}
      />
      {!singleWave && (
        <Path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0L18.645 12.4444C37.855 24.8889 75.145 49.7778 113 56C150.855 62.2222 188.145 49.7778 226 51.8519C263.855 53.9259 301.145 70.5185 339 76.7407C376.855 82.963 414.145 78.8148 452 74.6667C489.855 70.5185 527.145 66.3704 565 70.5185C602.855 74.6667 640.145 87.1111 659.355 93.3333L678 99.5556V112H659.355C640.145 112 602.855 112 565 112C527.145 112 489.855 112 452 112C414.145 112 376.855 112 339 112C301.145 112 263.855 112 226 112C188.145 112 150.855 112 113 112C75.145 112 37.855 112 18.645 112H0V0Z"
          fill="url(#waves1)"
          animate={animate}
        />
      )}
    </g>
    <defs>
      <linearGradient
        id="waves0"
        x1="599.5"
        y1="-57"
        x2="599.5"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="waves1" x1="339" y1="0" x2="339" y2="112" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="waves_clip0">
        <rect width="768" height="90" fill="white" />
      </clipPath>
    </defs>
  </SVG>
);

Waves.propTypes = {
  animate: PropTypes.bool,
  singleWave: PropTypes.bool,
  shouldPreserveAspectRatio: PropTypes.bool,
};

export const WavesBackground = ({ animate = false, shouldPreserveAspectRatio = true }) => (
  <BackgroundContainer shouldPreserveAspectRatio={shouldPreserveAspectRatio}>
    <Waves animate={animate} shouldPreserveAspectRatio={shouldPreserveAspectRatio} />
  </BackgroundContainer>
);

WavesBackground.propTypes = Waves.propTypes;

export const singleWaveBackground = ({ animate = false }) => (
  <BackgroundContainer>
    <Waves animate={animate} singleWave shouldPreserveAspectRatio={false} />
  </BackgroundContainer>
);

singleWaveBackground.propTypes = Waves.propTypes;

export default Waves;
