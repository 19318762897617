import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import usePrevious from 'znipe-hooks/usePrevious';
import { useIsDesktop } from 'tv-selectors/deviceInfo/makeGetIsDesktop';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import {
  TargetWrapper,
  HoverContainer,
  FloatingContainer,
  StreamPreviewPlaceholder,
} from './StreamPreview.styles';
import StreamPreview from './StreamPreview';

export const calculateOffset = (streamElement, bounds, placement) => {
  const { width: streamWidth, height: streamHeight } =
    streamElement.current.getBoundingClientRect();
  const { left: targetLeft, right: targetRight, width: targetWidth, height: targetHeight } = bounds;
  if (placement === 'right') {
    return { horizontal: targetWidth, vertical: -(streamHeight / 2) + targetHeight / 2 };
  }
  if (placement === 'left') {
    return { horizontal: -streamWidth, vertical: -(streamHeight / 2) + targetHeight / 2 };
  }

  let calcLeft = targetWidth / 2 - streamWidth / 2;

  if (streamWidth / 2 > targetLeft + targetWidth / 2) {
    calcLeft += streamWidth / 2 - (targetLeft + targetWidth / 2) + 10;
  } else if (streamWidth / 2 > window.innerWidth - targetRight + targetWidth / 2) {
    calcLeft -= streamWidth / 2 - (window.innerWidth - targetRight + targetWidth / 2) + 10;
  }

  if (placement === 'over') {
    return { horizontal: calcLeft, vertical: -streamHeight };
  }

  return { horizontal: calcLeft, vertical: targetHeight };
};

const isWithinDimensions = (pos, dimensions) => {
  const { x, y } = pos;
  const { bottom, top, left, right } = dimensions;
  const margin = 3;
  if (x + margin < left || right < x - margin) return false;
  if (y + margin < top || bottom < y - margin) return false;
  return true;
};

const StreamPreviewOnHover = ({
  children,
  matchId,
  streamId,
  selectedMatchGameIndex,
  placement = 'over',
  streamMargin = 10,
  playerRef = playerDefaultProps,
  onStreamPopout = () => {},
  disable = false,
  isLocked = false,
  onStreamItemClick = () => {},
  previewContainerWidth = 470,
  hasPremiumAccess = false,
}) => {
  const targetNode = useRef(null);
  const floatingNode = useRef(null);
  const [visible, setVisible] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [offset, setOffset] = useState(null);
  const [animate, setAnimate] = useState(false);
  const prevVisible = usePrevious(visible);

  const isDesktop = useIsDesktop();

  const targetRef = useCallback(node => {
    if (node !== null) {
      targetNode.current = node;
      setBounds(node.getBoundingClientRect());
    }
  }, []);

  const setVisibility = useCallback(
    isVisible => {
      const { left: prevLeft, right: prevRight } = bounds;
      const { left: currentLeft, right: currentRight } = targetNode.current.getBoundingClientRect();

      if (prevLeft !== currentLeft || prevRight !== currentRight) {
        setBounds(targetNode.current.getBoundingClientRect());
      }
      setVisible(isVisible);
    },
    [bounds],
  );

  const handleHovering = useMemo(
    () =>
      throttle(e => {
        const hide = () => {
          setVisible(false);
          window.removeEventListener('mousemove', handleHovering);
        };
        if (!targetNode.current || !floatingNode.current) {
          hide();
          return;
        }
        const targetDimensions = targetNode.current?.getBoundingClientRect() ?? null;
        const floatingDimensions = floatingNode.current?.getBoundingClientRect() ?? null;
        if (!floatingDimensions) return;
        const { clientX, clientY } = e;
        const pos = { x: clientX, y: clientY };
        const isWithinTarget = isWithinDimensions(pos, targetDimensions);
        const isWithinFloating = isWithinDimensions(pos, floatingDimensions);

        const marginDimensions = {};

        if (placement === 'left') {
          marginDimensions.top = floatingDimensions.top;
          marginDimensions.bottom = floatingDimensions.bottom;
          marginDimensions.left = floatingDimensions.right;
          marginDimensions.right = targetDimensions.left;
        }
        if (placement === 'over') {
          marginDimensions.top = floatingDimensions.bottom;
          marginDimensions.bottom = targetDimensions.top;
          marginDimensions.left = floatingDimensions.left;
          marginDimensions.right = floatingDimensions.right;
        }
        if (placement === 'right') {
          marginDimensions.top = floatingDimensions.top;
          marginDimensions.bottom = floatingDimensions.bottom;
          marginDimensions.left = targetDimensions.right;
          marginDimensions.right = floatingDimensions.left;
        }
        if (placement === 'under') {
          marginDimensions.top = floatingDimensions.top;
          marginDimensions.bottom = targetDimensions.bottom;
          marginDimensions.left = floatingDimensions.left;
          marginDimensions.right = floatingDimensions.right;
        }

        const isBetween =
          typeof marginDimensions.top === 'number'
            ? isWithinDimensions(pos, marginDimensions)
            : false;

        if (!isWithinTarget && !isWithinFloating && !isBetween) {
          hide();
        }
      }, 100),
    [placement],
  );

  const onMouseEnter = useCallback(() => {
    setVisibility(true);
    window.addEventListener('mousemove', handleHovering);

    return () => window.removeEventListener('mousemove', handleHovering);
  }, [handleHovering, setVisibility]);

  const onClickStream = useCallback(() => {
    setVisibility(false);
    onStreamItemClick();
  }, [onStreamItemClick, setVisibility]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (floatingNode.current && bounds) {
      setOffset(calculateOffset(floatingNode, bounds, placement));
    }
  }, [bounds, placement, disable]);

  useEffect(() => {
    if (!prevVisible && visible && !animate) {
      setAnimate(true);
    }
  }, [prevVisible, visible, animate]);
  if (!isDesktop) return children;

  return (
    <HoverContainer data-testid="hover-container">
      <TargetWrapper ref={targetRef} onMouseEnter={onMouseEnter} data-testid="target-wrapper">
        {children}
      </TargetWrapper>
      {!disable && (
        <FloatingContainer
          data-testid="floating-container"
          ref={floatingNode}
          offsetPos={offset}
          placement={placement}
          visible={visible}
          animate={animate}
          margin={streamMargin}
          $noPremiumAccessAndLocked={!hasPremiumAccess && isLocked}
        >
          {visible ? (
            <StreamPreview
              matchId={matchId}
              streamId={streamId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              play={visible}
              size={previewContainerWidth}
              playerRef={playerRef}
              onStreamPopout={onStreamPopout}
              isLocked={isLocked}
              onClickStream={onClickStream}
            />
          ) : (
            <StreamPreviewPlaceholder $width={previewContainerWidth} />
          )}
        </FloatingContainer>
      )}
    </HoverContainer>
  );
};

StreamPreviewOnHover.propTypes = {
  children: PropTypes.element.isRequired,
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  streamId: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['over', 'under', 'left', 'right']),
  streamMargin: PropTypes.number,
  onStreamPopout: PropTypes.func,
  disable: PropTypes.bool,
  previewContainerWidth: PropTypes.number,
  playerRef: playerPropTypes,
  isLocked: PropTypes.bool,
  onStreamItemClick: PropTypes.func,
  hasPremiumAccess: PropTypes.bool,
};

export default StreamPreviewOnHover;
