import { useMemo, useState, useCallback, useReducer, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import useTheme from 'tv-hooks/useTheme';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useGetVideoTitle } from 'tv-selectors/video/makeGetVideoTitle';
import { useGetVideoMatchId } from 'tv-selectors/video/makeGetVideoMatchId';
import { useGetVideoDate } from 'tv-selectors/video/makeGetVideoDate';
import { useGetVideoThumbnail } from 'tv-selectors/video/makeGetVideoThumbnail';
import { useGetVideoTournamentName } from 'tv-selectors/video/makeGetVideoTournamentName';
import { useIsMobile } from 'tv-selectors/deviceInfo/makeGetIsMobile';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetPlaylistTitle } from 'tv-selectors/playlists/makeGetPlaylistTitle';
import { useGetPlaylistHighlightVideos } from 'tv-selectors/playlists/makeGetPlaylistHighlightVideos';
import { useGetPlaylistHighlights } from 'tv-selectors/playlists/makeGetPlaylistHighlights';
import { useGetPlaylistThumbnail } from 'tv-selectors/playlists/makeGetPlaylistThumbnail';
import { useGetHighlights } from 'tv-selectors/page/makeGetHighlights';
import HighlightVideoFrame from 'tv-modules/Stage/HighlightVideoFrame/HighlightVideoFrame';
import VideoDescription from 'tv-modules/Stage/VideoDescription/VideoDescription';
import HighlightList from 'tv-modules/HighlightsListing/HighlightList/HighlightList';
import HighlightHeader from 'tv-modules/HighlightsListing/HighlightHeader/HighlightHeader';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import Slide from 'znipe-elements/layout/Animation/Slide/Slide';
import Tabset from 'znipe-elements/navigation/Tabset/Tabset';
import calculateDaysSinceStartTime from 'znipe-utils/date/calculateDaysSinceStartTime';
import addQueriesToUrl from 'znipe-utils/location/addQueriesToUrl';
import GlobalStyle from 'znipe-styles/global.styles';
import { useGetMatchTeamsShortNames } from 'tv-selectors/match/makeGetMatchTeamsShortNames';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import usePackageName from 'tv-hooks/usePackageName';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import HelmetWrapper, { getTitlePackageName } from 'tv-elements/general/Helmet/Helmet';
import { HighlightQuery, PlaylistQuery, EditorialQuery } from './HighlightStage.queries';
import { PUSH, COMPACT, PAGE_DESCRIPTIONS } from './HighlightStage.constants';
import {
  Container,
  FooterContainer,
  ButtonsWrapper,
  MainWrapper,
  Sidebar,
  PlayerWrapper,
  MobileContentWrapper,
  TabWrapper,
  MobileContent,
  HighlightListWrapper,
  SideBarContainer,
} from './HighlightStage.styles';

const videoIdReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case PUSH: {
      if (state.length === payload.maxItems) {
        return [];
      }
      return [...new Set([...state, payload.videoId])];
    }
    default:
      return state;
  }
};

const HighlightStage = () => {
  const { h: videoId, l: playlistId, type } = useQueryObject();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const packageName = usePackageName();
  const [prevPlayedIds, dispatch] = useReducer(videoIdReducer, []);

  const isMobile = useIsMobile();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const hasPremiumAccess = useHasPremiumAccess();

  const [showVideoList, setShowVideoList] = useState(true);
  const [activeTab, setActiveTab] = useState('');
  const toggleVideoList = useCallback(() => {
    setShowVideoList(!showVideoList);
  }, [showVideoList]);

  const isCompact = type === COMPACT;
  const isPlaylist = !!playlistId;
  const editorialId = useMemo(() => {
    if (pathname.indexOf('editorial') !== -1) return videoId;
    return null;
  }, [pathname, videoId]);

  const STAGE_QUERY = useMemo(() => {
    if (playlistId) return PlaylistQuery;
    if (editorialId) return EditorialQuery;
    return HighlightQuery;
  }, [playlistId, editorialId]);

  const queryOptions = useMemo(() => {
    let variables = { package: packageName, locked: hasPremiumAccess };
    if (playlistId) variables = { ...variables, playlistId, playlistLimit: 1 };
    if (editorialId) variables.editorialId = editorialId;
    if (videoId) variables.videoId = videoId;
    return { variables };
  }, [playlistId, videoId, editorialId, packageName, hasPremiumAccess]);

  useReduxGqlQuery(STAGE_QUERY, queryOptions);

  const playlistTitle = useGetPlaylistTitle({ playlistId });
  const playlistThumbnail = useGetPlaylistThumbnail({ playlistId });
  const playlistVideoIds = useGetPlaylistHighlights({ playlistId });
  const initialPlaylistVideo = playlistVideoIds?.[0] ?? '';
  const playlistVideos = useGetPlaylistHighlightVideos({
    playlistId,
    videoId: initialPlaylistVideo,
  });

  const pageRecommendedHighlights = useGetHighlights({ pageType: 'highlight' });
  const recommendedEditorials = useGetHighlights({ pageType: 'editorial' });

  const recommendedVideos = useMemo(() => {
    const selectedVideos = editorialId ? recommendedEditorials : pageRecommendedHighlights;
    return selectedVideos.map(id => ({ id }));
  }, [editorialId, pageRecommendedHighlights, recommendedEditorials]);
  const initialVideo = editorialId || videoId || initialPlaylistVideo;

  const highlights = useMemo(() => {
    if (isPlaylist)
      return playlistVideos.map(video => ({
        id: video.id,
      }));
    return recommendedVideos;
  }, [isPlaylist, recommendedVideos, playlistVideos]);

  const tokenRequest = useMemo(() => {
    if (!initialVideo) return {};
    const requestedVideos = [{ id: initialVideo }].concat(highlights);
    return { videos: requestedVideos };
  }, [initialVideo, highlights]);

  useFetchVideoCredentials(tokenRequest, true);

  const srcWithToken = useStreamSrcWithToken(initialVideo, 'video');
  const highlightThumbnail = useGetVideoThumbnail({ videoId: initialVideo });
  const title = useGetVideoTitle({ videoId: initialVideo });
  const matchId = useGetVideoMatchId({ videoId: initialVideo });
  const tournamentName = useGetVideoTournamentName({ videoId: initialVideo });

  const [teamOneName = '', teamTwoName = ''] = useGetMatchTeamsShortNames({ matchId });
  const teamVsTeamString = teamOneName && teamTwoName ? `${teamOneName} vs ${teamTwoName} |` : '';
  const isoDate = useGetVideoDate({ videoId: initialVideo });
  const dateString = calculateDaysSinceStartTime(isoDate);
  const description = `${teamVsTeamString} ${
    tournamentName ? `${tournamentName} |` : ''
  } ${dateString}`;

  const onWatchNextHighlight = useCallback(() => {
    const nextVideo = highlights.filter(({ id }) => !prevPlayedIds.includes(id))?.[0] ?? {};
    const nextHighlightId = nextVideo.id;
    if (!nextHighlightId) return;
    const currentUrl = `${pathname}${search}`;
    const nextUrl = addQueriesToUrl(currentUrl, {
      h: nextHighlightId,
    });
    navigate(nextUrl, { replace: true });
  }, [highlights, pathname, search, navigate, prevPlayedIds]);

  useEffect(() => {
    if (videoId) dispatch({ type: PUSH, payload: { videoId, maxItems: highlights.length } });
  }, [highlights.length, videoId]);

  const helmetDescription = useMemo(() => {
    const pageName = editorialId ? 'editorial' : 'highlight';
    return (
      PAGE_DESCRIPTIONS[packageName]?.[pageName] ??
      PAGE_DESCRIPTIONS.default?.[pageName] ??
      PAGE_DESCRIPTIONS.default?.highlight
    );
  }, [packageName, editorialId]);

  return (
    <Container data-testid="highlight-stage-container">
      <HelmetWrapper
        title={`${title} | ${getTitlePackageName(packageName)}`}
        description={helmetDescription}
      />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <MainWrapper>
          <PlayerWrapper $isCompact={isCompact} isRightSidebarOpen={showVideoList}>
            <HighlightVideoFrame
              videoId={initialVideo}
              src={srcWithToken}
              thumbnail={highlightThumbnail}
              onWatchNext={isCompact ? null : onWatchNextHighlight}
            />
          </PlayerWrapper>
          {isDesktopOrGreater && !isCompact && (
            <SideBarContainer $show={showVideoList}>
              <Slide show={showVideoList} type="right">
                <Sidebar>
                  {playlistTitle && playlistThumbnail && (
                    <HighlightHeader
                      title={playlistTitle}
                      label={`${highlights.length} videos`}
                      thumbnail={playlistThumbnail}
                      size={isDesktopLargeOrGreater ? 'medium' : 'small'}
                    />
                  )}
                  <HighlightListWrapper>
                    <HighlightList
                      videoList={highlights}
                      title={playlistTitle}
                      thumbnail={playlistThumbnail}
                      playingVideoId={initialVideo}
                      size="small"
                      videoType={editorialId ? 'editorial' : 'highlight'}
                      isPlaylist={!!playlistId}
                    />
                  </HighlightListWrapper>
                </Sidebar>
              </Slide>
            </SideBarContainer>
          )}
        </MainWrapper>
        {!isCompact && (
          <>
            <FooterContainer isDesktop={isDesktopOrGreater}>
              <VideoDescription
                title={title}
                description={description}
                matchId={!editorialId ? matchId : ''}
              />
              {isDesktopOrGreater && (
                <ButtonsWrapper>
                  <NavButton
                    label="Queue"
                    iconType="list"
                    onClick={toggleVideoList}
                    isToggledOn={showVideoList}
                    size={isTabletOrGreater ? 'large' : 'medium'}
                  />
                </ButtonsWrapper>
              )}
            </FooterContainer>
            {!isDesktopOrGreater && (
              <MobileContentWrapper isPlaylist={isPlaylist}>
                {playlistTitle && playlistThumbnail && (
                  <HighlightHeader
                    title={playlistTitle}
                    label={`${highlights.length} videos`}
                    thumbnail={playlistThumbnail}
                    size={isDesktopLargeOrGreater ? 'medium' : 'small'}
                  />
                )}
                <TabWrapper>
                  <Tabset
                    tabs={[{ label: 'Up next' }]}
                    onClickTab={tab => setActiveTab(tab?.label)}
                    isMobile={isMobile}
                  />
                </TabWrapper>
                <MobileContent>
                  {activeTab !== 'context' && (
                    <HighlightListWrapper>
                      <HighlightList
                        videoList={highlights}
                        title={playlistTitle}
                        thumbnail={playlistThumbnail}
                        playingVideoId={initialVideo}
                        size={isTabletOrGreater ? 'medium' : 'small'}
                        videoType={editorialId ? 'editorial' : 'highlight'}
                        isPlaylist={!!playlistId}
                      />
                    </HighlightListWrapper>
                  )}
                </MobileContent>
              </MobileContentWrapper>
            )}
          </>
        )}
      </ThemeProvider>
    </Container>
  );
};

export default HighlightStage;
