import merge from 'deepmerge';
import { schema } from 'normalizr';
import player, { createPlayerId } from './player.normalizr';
import round from './round.normalizr';
import team from './team.normalizr';
import gatEvent from './gatEvent.normalizr';
import eventFeed from './eventFeed.normalizr';
import scenarios from './scenarios.normalizr';

const groupByTeam = (players = [], parent) =>
  players.reduce(
    (acc, currentPlayer) =>
      currentPlayer.teamId
        ? {
            ...acc,
            [currentPlayer.teamId]: {
              ...(acc[currentPlayer.teamId] ?? {}),
              [createPlayerId(currentPlayer, parent)]: currentPlayer,
            },
          }
        : acc,
    {},
  );

const msToSeconds = values => {
  const keys = Object.keys(values);
  return keys.reduce((acc, key) => {
    acc[key] = values[key] / 1000;
    return acc;
  }, {});
};

const gat = new schema.Entity(
  'gats',
  {
    players: [player],
    rounds: [round],
    teams: [team],
    team1: [player],
    team2: [player],
    gatEvents: [gatEvent],
    eventFeed: [eventFeed],
    scenarios: [scenarios],
  },
  {
    processStrategy: values => {
      const valueCopy = { ...values };
      let gatData = valueCopy;

      if (valueCopy.csgoGame) {
        delete valueCopy.csgoGame;

        gatData = merge(valueCopy, values.csgoGame);

        gatData.players = gatData.players?.reduce((acc, current) => {
          const prevIndex = acc.findIndex(prev => prev.playerId === current.playerId);

          if (prevIndex > -1) acc[prevIndex] = merge(acc[prevIndex], current);
          else acc.push(current);

          return acc;
        }, []);
      }

      // Spread summaries on top level of the GAT
      const { summaries = {} } = gatData;
      Object.assign(gatData, summaries);
      delete gatData.summaries;

      const flattened = Object.entries(gatData).reduce((acc, [key, entry]) => {
        if (!Array.isArray(entry)) {
          acc[key] = entry;
          return acc;
        }

        // This reducer will bring up all rounds from player.summaries.rounds,
        // add the gameId + playerId in a 'deaths' array of the round summary if the player died that round,
        // and then merge all round summaries from all players to create a summary of player deaths for each round
        const { vals, rounds: newRounds } = entry.reduce(
          /* eslint-disable no-param-reassign */
          (entryAcc, val) => {
            if (val?.gameOffsetStart && val?.roundOffsetStart) {
              const { gameOffsetStart, gameOffsetEnd, roundOffsetStart, roundOffsetEnd } = val;
              const newValues = msToSeconds({
                gameOffsetStart,
                gameOffsetEnd,
                roundOffsetStart,
                roundOffsetEnd,
              });
              // biome-ignore lint/style/noParameterAssign:
              val = merge(val, newValues);
            }
            if (!val?.summaries?.rounds) {
              entryAcc.vals.push(val);
              return entryAcc;
            }

            const roundsWithDeaths = val.summaries.rounds.map(roundSummary => {
              const { stats = {}, ...rest } = roundSummary;
              const { died = false } = stats;
              return {
                ...rest,
                deaths: died ? [createPlayerId(val, gatData)] : [],
              };
            });

            const mergedRounds = merge(entryAcc.rounds, roundsWithDeaths).reduce(
              (accRounds, currentRound) => {
                if (!accRounds[currentRound.roundNumber]) {
                  accRounds[currentRound.roundNumber] = currentRound;
                  return accRounds;
                }

                const mergedRound = merge(accRounds[currentRound.roundNumber], currentRound);

                mergedRound.deaths = [...new Set(mergedRound.deaths)];

                accRounds[currentRound.roundNumber] = mergedRound;

                return accRounds;
              },
              {},
            );
            const rounds = Object.values(mergedRounds);

            delete val.summaries.rounds;

            entryAcc.rounds = rounds;
            entryAcc.vals.push(val);

            return entryAcc;
          },
          { vals: [], rounds: [] },
        );
        /* eslint-enable no-param-reassign */

        // Place the rounds of the new format in the top level of the GAT
        const rounds = merge(acc.rounds, newRounds);
        acc.rounds = rounds;
        if (key === 'rounds') {
          const mergedRounds = merge(acc.rounds, vals);
          acc.rounds = mergedRounds;
          return acc;
        }
        if (key === 'highlights') {
          const gatEvents = vals;
          delete acc.events;
          return { ...acc, gatEvents };
        }

        acc[key] = vals;

        return acc;
      }, {});

      const groupedByTeam = groupByTeam(flattened.players, flattened);
      const [team1Id, team2Id] = Object.keys(groupedByTeam).sort();
      const team1 = groupedByTeam[team1Id] ?? [];
      const team2 = groupedByTeam[team2Id] ?? [];

      flattened.team1 = team1;
      flattened.team2 = team2;

      return flattened;
    },
  },
);

export default gat;
