import { memo, useCallback, useMemo } from 'react';
import SearchResultItem from 'tv-modules/Search/SearchResultItem/SearchResultItem';
import PropTypes from 'prop-types';
import { useGetNodeId } from 'tv-selectors/contentSubscriptions/makeGetNodeId';
import { useGetSubscriptionMuteStatus } from 'tv-selectors/contentSubscriptions/makeGetSubscriptionMuteStatus';
import { useGetChampionChampId } from 'tv-selectors/champions/makeGetChampionChampId';
import { Row } from './Sidebar.styles';
import SidebarControls from './SidebarControls';
import { CHAMPION, RIOTCHAMPION } from './Sidebar.constants';

const ListItem = ({
  id,
  title,
  type,
  subtitle,
  imageUrl,
  onDeleteClick,
  onNotificationsClick,
  onSubscribeClick,
  isSubscribed,
  champId = '',
}) => {
  const championId = useGetChampionChampId({ championId: id });
  const itemType = useMemo(() => (type === CHAMPION ? RIOTCHAMPION : type), [type]);
  const itemId = useMemo(
    () => (type === CHAMPION ? championId || champId : id),
    [champId, championId, id, type],
  );
  const nodeId = useGetNodeId({ id: itemId, type: itemType });
  const isMuted = useGetSubscriptionMuteStatus({ id, type });

  const handleSubscribeClick = useCallback(() => {
    onSubscribeClick(itemId, type);
  }, [itemId, onSubscribeClick, type]);

  return (
    <Row data-testid="list-item">
      <SearchResultItem
        title={title}
        type={type}
        hideLabel
        subtitle={subtitle}
        direction="horizontal"
        imageUrl={imageUrl}
      />
      <SidebarControls
        id={id}
        onDeleteClick={() => onDeleteClick(itemId, type, nodeId)}
        onNotificationsClick={() => onNotificationsClick(id, type, nodeId, !isMuted)}
        onSubscribeClick={handleSubscribeClick}
        isSubscribed={isSubscribed}
        isMuted={isMuted}
      />
    </Row>
  );
};

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  champId: PropTypes.string,
  onDeleteClick: PropTypes.func.isRequired,
  onNotificationsClick: PropTypes.func.isRequired,
  onSubscribeClick: PropTypes.func.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
};

export default memo(ListItem);
