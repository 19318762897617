import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import gradients from 'znipe-styles/gradients';

const ContentWidth = css`
  width: 100%;
  max-width: 580px;
  margin: auto;
  @media ${device.tablet} {
    margin: 0;
  }
`;

export const HeaderSection = styled.div`
  position: relative;
  z-index: 1;
`;

export const FooterSection = styled.div`
  display: flex;
  padding: 24px 16px;
  z-index: 2;
  ${ContentWidth}

  @media ${device.tablet} {
    width: auto;
    display: flex;
    margin: inherit;
    margin-left: auto;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding: 0 16px;
    ${({ $hasRibbon }) =>
      $hasRibbon &&
      css`
        padding-right: 56px;
      `}
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  background: ${({ theme }) => theme.bg2Fade};

  @media ${device.tablet} {
    flex-direction: row;
    align-items: stretch;
    min-height: 112px;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  ${ContentWidth}

  > * {
    margin: 0;
  }
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  ${ContentWidth}
  @media ${device.tablet} {
    padding: 0 16px 8px 16px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 3;
  margin: 8px;
  background: ${({ theme }) => theme.containerStroke};
  cursor: pointer;
  @media ${device.tablet} {
    top: 0;
  }
`;

export const FlourishWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: flex-end;
`;

export const Ribbon = styled.div`
  position: absolute;
  top: 25px;
  right: -35px;
  transform: rotate(35deg);
  background: ${gradients.orange};
  width: 164px;
  text-align: center;
  z-index: 3;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    width: 100%;
    max-width: 656px;
    padding: 24px 0;
    justify-content: center;
  }
`;
