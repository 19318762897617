import styled, { css } from 'styled-components';

const circleShapeStyles = () => css`
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  border-radius: 100%;
`;

export const Container = styled.div`
  position: relative;
  ${({ $size }) =>
    $size
      ? css`
          width: ${$size}px;
          height: ${$size}px;
        `
      : css`
          width: 100%;
          height: 100%;
        `};
`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  border: ${({ theme, $hasBorder }) => ($hasBorder ? `2px solid ${theme.containerStroke}` : 'none')};
  ${({ $isCircle }) => $isCircle && circleShapeStyles}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
