import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import PopOver from 'znipe-elements/data-display/PopOver/PopOver';
import Modal from 'znipe-elements/feedback/Modal/Modal';
import MatchGameSelector from 'tv-modules/MatchGameSelector/MatchGameSelector';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetMatchBestOf } from 'tv-selectors/match/makeGetMatchBestOf';
import styled from 'styled-components';
import useOutsideClick from 'znipe-hooks/useOutsideClick';

const InfoWrapper = styled.div`
  width: 75%;
  min-width: 320px;
`;

const GameSelectorButton = ({ matchId, onGameSelect = () => {}, selectedMatchGameIndex = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const matchBestOf = useGetMatchBestOf({ matchId });

  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });

  const handleOnOpenStateChange = useCallback(value => {
    setIsOpen(value);
  }, []);

  if (typeof matchBestOf !== 'number' || matchBestOf < 2) return null;

  const selectorInfo = (
    <InfoWrapper data-testid="game-selector-button-info-wrapper">
      <MatchGameSelector
        matchId={matchId}
        setSelectedMatchGame={onGameSelect}
        showContainerLabel={false}
        selectedMatchGameIndex={selectedMatchGameIndex}
      />
    </InfoWrapper>
  );
  const button = (
    <NavButton
      label="Stats"
      iconType="list"
      onClick={toggleOpen}
      isToggledOn={isOpen}
      testid="game-selector-button"
    />
  );

  if (isTabletOrGreater) {
    return (
      <div ref={ref}>
        <PopOver
          targetElement={button}
          position="top"
          align="start"
          ref={ref}
          onOpenStateChange={handleOnOpenStateChange}
        >
          {selectorInfo}
        </PopOver>
      </div>
    );
  }

  return (
    <div ref={ref}>
      <Modal isOpen={isOpen} onCloseClick={toggleOpen} darkMode>
        {selectorInfo}
      </Modal>
      {button}
    </div>
  );
};

GameSelectorButton.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number,
  onGameSelect: PropTypes.func,
};

export default GameSelectorButton;
