import styled, { css } from 'styled-components';

const mastheadHeight = 64;
const matchbarHeight = 105;
const mastheadMatchbarHeightDesktop = matchbarHeight + mastheadHeight;

export const PlayerFrame = styled.div`
  position: relative;
  background: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const VideoContainer = styled.div`
  margin: auto;
  width: 100%;
  min-height: auto;
  position: relative;
  z-index: 0;
  height: 100%;

  -webkit-tap-highlight-color: transparent;

  ${({ $compact }) => {
    const mastHeadOffset = $compact ? matchbarHeight : mastheadMatchbarHeightDesktop;
    return css`
      max-height: calc(100vh - ${mastHeadOffset}px);
    `;
  }}

  ${({ isPreview }) =>
    isPreview &&
    css`
      &:hover,
      &:focus {
        cursor: pointer;
      }
    `}
`;
