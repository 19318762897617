import { memo, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Input from 'znipe-elements/data-entry/Input/Input';
import ErrorMessage from 'tv-modules/Payment/SharedStyles/ErrorMessage';
import Description from 'tv-modules/Payment/SharedStyles/Description';
import Header from 'tv-modules/Payment/SharedStyles/Header';
import useGetPrice from 'tv-modules/Payment/hooks/useGetPrice';
import { subscribeToProduct, addProductSubscriptionPromoCode } from 'tv-actions/old/products';
import { Form, FormContainer, GridArea } from './PromoCodeForm.styles';
import LoadingButton from '../SharedStyles/LoadingButton';

const PromoCodeForm = ({
  onSuccess,
  header = '',
  buttonLabel = 'Redeem',
  currency,
  productId,
  period = 'monthly',
  edit = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const errorMessage = error || !!errors.code ? 'Invalid promo code' : '';
  const isMountedRef = useRef(null);

  const getPrice = useGetPrice(currency, productId, period);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const subscribeWithPromoCode = useCallback(
    code => {
      const options = {
        period,
        promoCode: code,
      };
      return dispatch(subscribeToProduct(productId, 'stripe', {}, options));
    },
    [dispatch, productId, period],
  );

  const addPromoCode = useCallback(
    code => {
      const options = {
        promoCode: code,
      };
      return dispatch(addProductSubscriptionPromoCode(productId, options));
    },
    [dispatch, productId],
  );

  const tryToSubscribe = useCallback(
    async ({ code }) => {
      if (loading) return;
      setLoading(true);
      try {
        const { results = {} } = await getPrice(code);
        const { price, discountPeriods } = results;
        // If the promocode provides a free subscription forever, try to subscribe with it
        if (price === 0 && discountPeriods === -1) {
          await subscribeWithPromoCode(code);
          onSuccess(code, true);
        } else {
          onSuccess(code);
        }
      } catch (_err) {
        setError(true);
      }
      if (isMountedRef.current) {
        setLoading(false);
      }
    },
    [onSuccess, getPrice, loading, subscribeWithPromoCode],
  );

  const addPromoCodeToSubscription = useCallback(
    async ({ code }) => {
      if (loading) return;
      setLoading(true);
      try {
        await getPrice(code);
        await addPromoCode(code);
        onSuccess(code);
      } catch (_err) {
        setError(true);
      }
      if (isMountedRef.current) {
        setLoading(false);
      }
    },
    [loading, getPrice, addPromoCode, onSuccess],
  );

  return (
    <FormContainer>
      {header && (
        <>
          <Header>{header}</Header>
          <Description data-testid="header-description">
            Only one code can be active at a time.
          </Description>
        </>
      )}
      <Form
        data-testid="promo-code-form"
        onSubmit={handleSubmit(edit ? addPromoCodeToSubscription : tryToSubscribe)}
      >
        <GridArea gridArea="code">
          <Input
            {...register('code', { required: true })}
            label="Promo code"
            error={error || !!errors.code}
          />
        </GridArea>
        <GridArea gridArea="error">
          <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>
        </GridArea>
        <GridArea gridArea="submit">
          <LoadingButton type="submit" loading={loading}>
            {buttonLabel}
          </LoadingButton>
        </GridArea>
      </Form>
    </FormContainer>
  );
};

PromoCodeForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  period: PropTypes.string,
  buttonLabel: PropTypes.string,
  currency: PropTypes.oneOf(['usd', 'eur']),
  productId: PropTypes.string.isRequired,
  header: PropTypes.string,
  edit: PropTypes.bool,
};

export default memo(PromoCodeForm);
