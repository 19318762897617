import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ColumnContainer,
  Column,
  MatchWrapper,
  BracketItem,
  BracketMatchupLoading,
} from './Bracket.styles';

const ColumnItem = ({
  isFirstColumn = false,
  isLastColumn = false,
  fromUpper = false,
  fromLower = false,
  toUpper = false,
  toLower = false,
}) => (
  <BracketItem
    bracketType="upper"
    isFirstColumn={isFirstColumn}
    isLastColumn={isLastColumn}
    fromUpper={fromUpper}
    fromLower={fromLower}
    toUpper={toUpper}
    toLower={toLower}
  >
    {isFirstColumn ? (
      <BracketMatchupLoading />
    ) : (
      <MatchWrapper>
        <BracketMatchupLoading />
      </MatchWrapper>
    )}
  </BracketItem>
);

ColumnItem.propTypes = {
  isFirstColumn: PropTypes.bool,
  isLastColumn: PropTypes.bool,
  fromUpper: PropTypes.bool,
  fromLower: PropTypes.bool,
  toUpper: PropTypes.bool,
  toLower: PropTypes.bool,
};

const BracketLoading = () => (
  <ColumnContainer bracketType="upper">
    <Column>
      <ColumnItem isFirstColumn toLower />
      <ColumnItem isFirstColumn toUpper />
      <ColumnItem isFirstColumn toLower />
      <ColumnItem isFirstColumn toUpper />
    </Column>
    <Column>
      <ColumnItem fromUpper fromLower toLower />
      <ColumnItem fromUpper fromLower toUpper />
    </Column>
    <Column>
      <ColumnItem isLastColumn fromLower fromUpper />
    </Column>
  </ColumnContainer>
);
export default memo(BracketLoading);
