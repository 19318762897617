import { useRef, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import { TabItem, IconWrapper } from './Tabset.styles';

const Tab = ({
  label,
  active = false,
  onClick,
  icon = '',
  badge = null,
  pageColor = '',
  size = 'small',
  ...restProps
}) => {
  const containerRef = useRef(null);

  const handleClick = useCallback(() => {
    if (active) return;
    const element = containerRef.current || {};
    const position = element.offsetLeft || 0;
    const width = element.offsetWidth || 0;
    onClick({ position, width }, label);
  }, [active, label, onClick]);

  const typographySize = useMemo(() => {
    if (size === 'medium') return 'title-m';
    return 'title-s';
  }, [size]);

  return (
    <div id={`tab-${label.replace(' ', '-').toLowerCase()}`}>
      <TabItem
        ref={containerRef}
        key={`item-${label}`}
        size={size}
        isActive={active}
        onClick={handleClick}
        data-testid={`item-${label}`}
        shadow={pageColor}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      >
        {icon && (
          <IconWrapper size={size} shadow={pageColor}>
            <Icon type={icon} />
          </IconWrapper>
        )}
        <Typography type={typographySize}>{label}</Typography>
        {badge}
      </TabItem>
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  badge: PropTypes.node,
  pageColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default memo(Tab);
