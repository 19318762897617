import { memo } from 'react';
import ModalWithButton from 'znipe-elements/feedback/Modal/ModalWithButton';
import PropTypes from 'prop-types';
import GeneralInformation from './GeneralInformation';
import AmexInformation from './AmexInformation';

import { Wrapper, CcvButton } from './CcvInformation.styles';

const CcvInformation = ({ code = 'CVC/CVV', type }) => (
  <ModalWithButton type="info" toggleButton={CcvButton} buttonText="?">
    <Wrapper data-testid="ccv-content">
      {type === 'americanExpress' || type === 'amex' ? (
        <AmexInformation code={code} />
      ) : (
        <GeneralInformation code={code} />
      )}
    </Wrapper>
  </ModalWithButton>
);

CcvInformation.propTypes = {
  code: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default memo(CcvInformation);
