import TeamLogo from 'znipe-elements/data-display/TeamLogo/TeamLogo';
import PropTypes from 'prop-types';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';

export const TeamLogoComponent = ({ teamId, label }) => {
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  return <TeamLogo size="small" logo={teamLogo} label={label} />;
};

TeamLogoComponent.propTypes = {
  teamId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export const PlayerTeamLogoComponent = ({ playerId, label }) => {
  const teamId = useGetPlayerTeamId({ playerId });
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  return <TeamLogo size="small" logo={teamLogo} label={label} />;
};

PlayerTeamLogoComponent.propTypes = {
  playerId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
