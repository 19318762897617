import { useMemo, useContext } from 'react';
import styled from 'styled-components';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import ChatContext from 'znipe-chat/src/contexts/ChatContext';
import ChatPopOver from 'znipe-chat/src/components/ChatPopOver/ChatPopOver';
import Layout, { Type } from '../PopoverLayout/PopoverLayout';
import ColorPicker from './ColorPicker/ColorPicker';
import AvatarPicker, { Size as AvatarPickerSize } from './AvatarPicker/AvatarPicker';

type Size = 'small' | 'medium';
type SizeInfo = {
  avatarSize: AvatarPickerSize;
  columnsPerRow: number;
};
type Sizes = Record<Size, SizeInfo>;

const sizes: Sizes = {
  small: {
    avatarSize: 'small',
    columnsPerRow: 4,
  },
  medium: {
    avatarSize: 'medium',
    columnsPerRow: 5,
  },
};

const compactSizes: SizeInfo = {
  avatarSize: 'small',
  columnsPerRow: 4,
};

export const CloseButton = styled.button`
  position: absolute;
  cursor: pointer;
  right: 12px;
  padding: 0;
  transform: translateY(-8px);

  background: transparent;
  border: none;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: content-box;
`;

type IdentityProps = {
  type: Type;
  size?: Size;
  targetElement?: React.ReactNode;
  onClose?: (identifier: string) => void;
};

const Identity: React.FC<IdentityProps> = ({ type, size = 'small', targetElement, onClose }) => {
  const { userAvatar, setUserAvatar, avatars } = useContext(ChatContext);

  const selectedSizes = useMemo(
    () => (type === 'compact' ? compactSizes : sizes[size]),
    [size, type],
  );

  const colorPickerElement = <ColorPicker label="Username color" />;

  const avatarPickerElement = Boolean(avatars?.length) && (
    <AvatarPicker
      label="Avatars"
      avatars={avatars}
      selectedAvatar={userAvatar}
      columnsPerRow={selectedSizes.columnsPerRow}
      avatarSize={selectedSizes.avatarSize}
      onAvatarSelect={setUserAvatar}
    />
  );
  if (type === 'compact')
    return (
      <ChatPopOver size={size} header="Chat identity" targetElement={targetElement}>
        {colorPickerElement}
        {avatarPickerElement}
      </ChatPopOver>
    );
  return (
    <Layout type={type}>
      {onClose && (
        <CloseButton data-testid="close-button" onClick={() => onClose('button_modal_close')}>
          <Icon type="chevronDown" size={24} />
        </CloseButton>
      )}
      {colorPickerElement}
      {avatarPickerElement}
    </Layout>
  );
};

export default Identity;
