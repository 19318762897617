import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import config from 'tv-config/config';

const { DISALLOW_ROBOTS, NODE_ENV } = config;

export const getTitlePackageName = packageName => {
  if (packageName === 'proview') return 'Pro View';
  return 'Znipe TV';
};

const TwitterTags = ({
  card = 'summary_large_image',
  site = '@ZnipeTv',
  title,
  description,
  image,
  imageAlt,
}) => (
  <Helmet>
    {card && image && <meta name="twitter:card" content={card} />}
    {site && <meta name="twitter:site" content={site} />}
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {image && <meta name="twitter:image" content={image} />}
    {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
  </Helmet>
);

TwitterTags.propTypes = {
  card: PropTypes.string,
  site: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
};

const OgTags = ({ siteName = 'Znipe.TV', title, type = 'website', url, image, description }) => (
  <Helmet>
    {siteName && <meta property="og:site_name" content={siteName} />}
    {title && <meta property="og:title" content={title} />}
    {type && <meta property="og:type" content={type} />}
    {url && <meta property="og:url" content={url} />}
    {image && <meta property="og:image" content={image} />}
    {description && <meta property="og:description" content={description} />}
  </Helmet>
);

OgTags.propTypes = {
  siteName: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

const BasicTags = ({ title, favIcon }) => (
  <Helmet>
    {title && <title>{title}</title>}
    {favIcon && <link rel="icon" href={favIcon} />}
    {DISALLOW_ROBOTS && <meta name="robots" content="noindex, follow" />}
    {NODE_ENV === 'production' && <link rel="manifest" href="/manifest.json" />}
  </Helmet>
);

BasicTags.propTypes = {
  title: PropTypes.string,
  favIcon: PropTypes.string,
};

const HelmetWrapper = ({
  title,
  siteName = 'Znipe.TV',
  pageType = 'website',
  url,
  image,
  imageAlt,
  description,
  twitterCard = 'summary_large_image',
  twitterSite = '@ZnipeTv',
  favIcon,
}) => (
  <>
    <BasicTags title={title} favIcon={favIcon} />
    <TwitterTags
      description={description}
      title={title}
      image={image}
      imageAlt={imageAlt}
      card={twitterCard}
      site={twitterSite}
    />
    <OgTags
      siteName={siteName}
      title={title}
      description={description}
      url={url}
      image={image}
      type={pageType}
    />
  </>
);

HelmetWrapper.propTypes = {
  title: PropTypes.string,
  siteName: PropTypes.string,
  pageType: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  description: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterSite: PropTypes.string,
  favIcon: PropTypes.string,
};

export default HelmetWrapper;
