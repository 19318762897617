import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  height: 100%;
  overflow: hidden;
  &:after {
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    content: '';
    width: 100%;
    height: 37px;
    z-index: 1;
  }

  @media ${device.tablet} {
    overflow: initial;
    min-height: 450px;
    &:after {
      display: none;
    }
  }
  @media ${device.desktop} {
    max-width: 880px;
    margin: auto;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  @media ${device.mobilePortrait} {
    margin-top: 24px;
  }
  @media ${device.tablet} {
    max-width: 568px;
    margin-top: 104px;
  }
  @media ${device.desktop} {
    margin-top: 56px;
  }
  @media ${device.desktopLarge} {
    max-width: 504px;
  }
  > * {
    margin-bottom: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 16px auto auto auto;
  button {
    background-color: ${({ theme }) => theme.containerStroke};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.containerStroke};
    }
  }
  @media ${device.desktop} {
    margin-top: 56px;
  }
`;

export const CoverImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const BackgroundCoverContainer = styled.div`
  ${({ $position }) => {
    if ($position === 'left')
      return css`
        transform: translateX(-20%);
      `;
    if ($position === 'right')
      return css`
        transform: translateX(20%);
      `;
    return '';
  }}
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  @media ${device.desktop} {
    right: -16%;
  }
  @media ${device.desktopLarge} {
    right: -40%;
  }
`;

export const BackgroundFade = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 110%;
  transform: translateX(-4.5%);
  background-image: url('https://assets.znipe.tv/onboarding/Line-and-fade.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media ${device.desktopLarge} {
    background-size: cover;
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 220px;
  width: 100%;

  @media ${device.mobilePortrait} {
    height: 290px;
  }

  @media ${device.tablet} {
    height: 490px;
  }

  @media ${device.desktop} {
    height: 220px;
    top: 50%;
  }

  @media ${device.desktopLarge} {
    height: 320px;
  }
`;

export const BackgroundContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
