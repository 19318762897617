import { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import UserAvatar from 'znipe-elements/data-display/Avatar/v2/UserAvatar/UserAvatar';
import Typography from 'znipe-elements/general/Typography/Typography';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import { useUsername } from 'tv-selectors/auth/makeGetUsername';
import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
import LoginButton from 'tv-modules/Authentication/LoginButton/LoginButton';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';

const avatarSize = {
  small: 32,
  medium: 40,
  large: 48,
};
const avatarCss = css`
  width: ${({ $size }) => avatarSize[$size]}px;
  height: ${({ $size }) => avatarSize[$size]}px;
  min-width: ${({ $size }) => avatarSize[$size]}px;
  min-height: ${({ $size }) => avatarSize[$size]}px;
  padding: 0;
`;

const menuCss = css`
  ${({ $size, theme }) =>
    $size !== 'large' &&
    css`
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: ${theme.secondaryBackgroundColor};
    `};

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const Container = styled.button`
  background: transparent;
  border: none;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  ${({ $type }) => ($type === 'avatar' ? avatarCss : menuCss)}
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
`;

const SettingsMenuButton = ({ size = 'small', type = 'avatar', onClick }) => {
  const isAuthenticated = useIsAuthenticated();
  const username = useUsername();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const showLoginButton = !isAuthenticated && isDesktopLargeOrGreater;
  return (
    <>
      {showLoginButton && <LoginButton>Login</LoginButton>}
      <Container $size={size} $type={type} onClick={onClick}>
        {isAuthenticated && type === 'menu' && size === 'large' && (
          <Typography type="heading-s" color={colors.white}>
            {username || 'My Account'}
          </Typography>
        )}
        {type === 'menu' && (
          <Icon
            size={size === 'large' ? 24 : 16}
            type="menu"
            fillColor={size === 'large' ? '#00C8C8' : colors.white}
          />
        )}
        {type === 'avatar' && <UserAvatar />}
      </Container>
    </>
  );
};

SettingsMenuButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['avatar', 'menu']),
  onClick: PropTypes.func.isRequired,
};

export default memo(SettingsMenuButton);
