import styled, { css } from 'styled-components';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.ul<{
  useModal: boolean;
  $error: boolean;
  $isAbove: boolean;
  $dynamic?: boolean;
}>`
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  border: 1px solid ${({ theme, $error }) => ($error ? theme.errorLight : theme.containerStroke)};

  ${({ $isAbove }) =>
    $isAbove
      ? css`
          border-bottom: transparent;
          border-radius: 5px 5px 0 0;
        `
      : css`
          border-top: transparent;
          border-radius: 0 0 5px 5px;
        `}

  overflow: hidden;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  max-height: min(${({ $dynamic }) => ($dynamic ? '605px' : '224px')}, 60vh);
  ${Scrollbar}
  ${({ useModal }) =>
    useModal &&
    css`
      border: unset;
    `}
`;

export const Divider = styled.div`
  height: 1px;
  margin: 6px 0;
  background-color: ${({ theme }) => theme.containerStroke};
`;
