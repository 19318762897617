import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetContentSubscriptions from 'tv-selectors/contentSubscriptions/makeGetContentSubscriptions';

const emptyArray = [];

const makeGetSubscribedPlayersIds = () => {
  const getContentSubscriptions = makeGetContentSubscriptions();
  return createSelector([getContentSubscriptions], subscriptions => {
    if (!subscriptions?.player) return emptyArray;
    const ids = Object.keys(subscriptions.player);
    return ids ?? emptyArray;
  });
};

export const useGetSubscribedPlayersIds = () => useMemoizedGqlSelector(makeGetSubscribedPlayersIds);

export default makeGetSubscribedPlayersIds;
