import { useMemo, useState, memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import logger from 'znipe-logger';
import config from 'tv-config/config';
import colors from 'znipe-styles/colors';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import useGroupMatchesByDate, { ASC } from 'tv-hooks/useGroupMatchesByDate';
import { fetchGqlQuery } from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useGetHighlights } from 'tv-selectors/page/makeGetHighlights';
import { useGetTopicId } from 'tv-selectors/page/makeGetTopicId';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useGetEvents as useGetTournamentEvents } from 'tv-selectors/events/makeGetEvents';
import { useGetScheduledMatches } from 'tv-selectors/page/makeGetScheduledMatches';
import { useGetVodMatches } from 'tv-selectors/page/makeGetVodMatches';
import { useGetPage } from 'tv-selectors/page/makeGetPage';
import { useGetPackageId } from 'tv-selectors/packages/makeGetPackageId';
import { useAuthToken } from 'tv-selectors/auth/makeGetAuthToken';
import { useGetLatestMatches } from 'tv-selectors/page/makeGetLatestMatches';
import { useGetMatchPreviews } from 'tv-selectors/matchPreviews/makeGetMatchPreviews';
import { getPageInfo, flattenData } from 'znipe-utils/normalize/normalizeData';
import {
  pushContentSubscriptionId,
  popContentSubscriptionId,
} from 'tv-actions/contentSubscriptions';
import { topicSchema } from 'tv-schema/topicSchema.normalizr';
import { setPage } from 'tv-actions/page';
import { TOPIC_PAGE } from 'tv-constants/pageTypes';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import { TabsContainer, StickyWrapper } from 'tv-elements/layout/Layout.styles';
import { BodyWrapper } from 'tv-elements/layout/PageLayout/PageLayout';
import Tabset from 'znipe-elements/navigation/Tabset/Tabset';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import GroupedMatchList from 'tv-modules/Matches/GroupedMatchList/GroupedMatchList';
import HighlightClip from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip';
import TopicHeader from 'tv-modules/TopicHeader/TopicHeader';
import TopicAbout from 'tv-modules/TopicAbout/TopicAbout';
import { createInputObject } from 'tv-modules/MyFeed/Sidebar/utils/createInputObject';
import { AddSubQuery, DeleteSubQuery } from 'tv-modules/MyFeed/Sidebar/Sidebar.queries';
import FiltersTabset, { SORT_RECENT } from 'tv-modules/Filters/FiltersTabset/FiltersTabset';
import usePackageName from 'tv-hooks/usePackageName';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import { LOL_PATCH_VERSION } from 'znipe-constants/misc';
import {
  OVERVIEW,
  SCHEDULE,
  ABOUT,
  HIGHLIGHTS,
  VODS,
  PLAYER,
  CHAMPION,
  RIOTCHAMPION,
  RIOT_CHAMPION,
  EVENT,
  TAB_MENU,
  desktopSizes,
  tabletSizes,
  mobileSizes,
  filterOptionsDesktop,
  filterOptionsMobile,
  CHAMPION_TAB_MENU,
  singletonPadding,
} from './Topics.constants';
import Overview from './components/Overview';
import HighlightScroll from './components/HighlightScroll';
import { ChampionQuery, EventQuery, PlayerQuery, TeamQuery } from './Topic.queries';
import { postSubscriptionsNormalize } from '../Home/Home';

const matchTabTypes = [VODS, HIGHLIGHTS];

const resetPageState = {
  custom: {
    latestMatches: [],
    scheduledMatches: [],
    highlights: [],
    champion: [],
    team: [],
    player: [],
    event: [],
  },
};

const singletonLoadingSpinner = <LoadingSpinner fillColor={colors.white} position="relative" />;

const tabOptions = TAB_MENU.map(tab => tab.label);

const Topic = () => {
  const { sort } = useQueryObject();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const { topicType, topicQuery, packageSlug, initialTab } = useParams();
  const [activeTab, setActiveTab] = useState(
    tabOptions.includes(initialTab) ? initialTab : OVERVIEW,
  );
  const [showMiniHeader, setShowMiniHeader] = useState(false);
  const [selectedSortType, setSelectedSortType] = useState(sort || SORT_RECENT);
  const packageName = usePackageName();
  const navigate = useNavigate();
  const currentUserId = useUserId();

  const topicName = topicType === 'event' ? 'tournaments' : topicType;
  const selectedTopicId = useGetTopicId({ pageType: TOPIC_PAGE, topicType: topicName });
  const selectedTournamentId = useGqlSelector(
    state =>
      Object.values(state.tournaments).find(({ humanReadableId }) => humanReadableId === topicQuery)
        ?.id,
  );
  const selectedEventId = useGqlSelector(
    state => state.tournaments[selectedTournamentId]?.event ?? '',
  );
  const tournamentEvents = useGetTournamentEvents();
  const matchPreview = useGetMatchPreviews();

  const singletonHighlightsComp = useMemo(
    () => <NoResults padding={singletonPadding} type={HIGHLIGHTS} topic={topicType} />,
    [topicType],
  );

  const [pageFilters, setPageFilters] = useState({});
  const { requestHashId } = pageFilters;

  const tabMenu = useMemo(() => {
    if (topicType === CHAMPION) return CHAMPION_TAB_MENU;
    return TAB_MENU;
  }, [topicType]);

  const scheduledMatches = useGetScheduledMatches({ pageType: TOPIC_PAGE });
  const [allScheduled, setScheduled] = useState({ [requestHashId]: scheduledMatches });
  const currentScheduleList = allScheduled[requestHashId];
  const groupedScheduledMatches = useGroupMatchesByDate(currentScheduleList, ASC);

  const vodMatches = useGetVodMatches({ pageType: TOPIC_PAGE });
  const [allVods, setAllVods] = useState({ [requestHashId]: vodMatches });

  const currentVodsList = allVods[requestHashId];
  const groupedVodMatches = useGroupMatchesByDate(
    currentVodsList,
    selectedSortType === SORT_RECENT ? 'DESC' : 'ASC',
  );

  const highlights = useGetHighlights({ pageType: TOPIC_PAGE });
  const [allHighlights, setAllHighlights] = useState({ [requestHashId]: highlights });

  const allLatestMatches = useGetLatestMatches({ pageType: TOPIC_PAGE });

  const authToken = useAuthToken();
  const packageId = useGetPackageId({ packageName });
  const gqlDispatch = useGqlStoreDispatch();
  const [loading, setLoading] = useState(false);
  const [nextCursor, setNextCursor] = useState('');

  useEffect(() => {
    const selectedTab = tabOptions.includes(initialTab) ? initialTab : OVERVIEW;
    setActiveTab(selectedTab);
  }, [initialTab]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setAllVods(prevMatches => {
      const prevMatchesList = prevMatches[requestHashId] || [];
      return { ...prevMatches, [requestHashId]: [...prevMatchesList, ...allLatestMatches] };
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLatestMatches]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setAllHighlights(prevHighlights => {
      const prevHighlightsList = prevHighlights[requestHashId] || [];
      const uniqueItems = [...new Set([...prevHighlightsList, ...highlights])];
      return { ...prevHighlights, [requestHashId]: uniqueItems };
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlights]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setScheduled(prevMatches => {
      const prevMatchesList = prevMatches[requestHashId] || [];
      return { ...prevMatches, [requestHashId]: [...prevMatchesList, ...scheduledMatches] };
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledMatches]);

  const fetchOptions = useMemo(
    () => ({
      url: config.GRAPHQL_API_URL,
      headers: { Authorization: `Token ${authToken}` },
    }),
    [authToken],
  );

  const [topicId = '', game = 'lol'] = useMemo(() => {
    if (topicType === 'event') {
      return [topicQuery, 'lol'];
    }
    return topicQuery.split('-');
  }, [topicQuery, topicType]);

  const { player: pagePlayer } = useGetPage({ pageType: TOPIC_PAGE });

  const playerId = useMemo(
    () => (topicType === PLAYER && pagePlayer?.length > 0 ? pagePlayer[0] : ''),
    [pagePlayer, topicType],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Reset on topic change
  useEffect(
    () => () => {
      gqlDispatch(setPage(resetPageState, { pageType: TOPIC_PAGE }));
    },
    [gqlDispatch, topicQuery],
  );

  const handleTabClick = useCallback(
    nextView => {
      if (nextView?.label === activeTab) return;
      const baseUrl = packageSlug ? `/${packageSlug}` : '';
      const nextPath =
        nextView?.label === OVERVIEW
          ? `${baseUrl}/topic/${topicType}/${topicQuery}`
          : `${baseUrl}/topic/${topicType}/${topicQuery}/${nextView?.label}`;
      navigate(nextPath, { replace: true });
      setActiveTab(nextView?.label);
    },
    [navigate, packageSlug, topicQuery, topicType, activeTab],
  );

  const queryOptions = useMemo(() => {
    let activeFilters = {};
    if (pageFilters.filterParams) {
      activeFilters = Object.keys(pageFilters.filterParams)
        .filter(key => !!pageFilters.filterParams[key] !== false)
        .reduce((acc, key) => ({ ...acc, [key]: pageFilters.filterParams[key] }), {});
    }

    return {
      variables: {
        topicId,
        game,
        playerId,
        package: packageName,
        tag: '',
        scheduledMatchesLimit: 6,
        playlistLimit: 4,
        highlightsLimit: 3,
        userId: currentUserId,
        nextPage: nextCursor,
        sortType: 'DESC',
        patch: LOL_PATCH_VERSION,
        actor: topicId,
        ...activeFilters,
      },
      persisted: false,
    };
  }, [topicId, game, playerId, packageName, currentUserId, nextCursor, pageFilters.filterParams]);

  const queryOptionsWithFilters = useMemo(() => {
    const sortType = selectedSortType === SORT_RECENT ? 'DESC' : 'ASC';
    return {
      variables: { ...queryOptions.variables, sortType },
      persisted: false,
      cacheTime: 0,
    };
  }, [queryOptions, selectedSortType]);

  const topicsGqlQuery = useMemo(() => {
    switch (topicType.toUpperCase()) {
      case 'PLAYER':
        return PlayerQuery;
      case 'TEAM':
        return TeamQuery;
      case 'EVENT':
        return EventQuery;
      case 'CHAMPION':
        return ChampionQuery;
      default:
        return '';
    }
  }, [topicType]);

  const { data = {}, refetch } = useReduxGqlQuery(
    topicsGqlQuery,
    queryOptionsWithFilters,
    {},
    topicSchema,
    postSubscriptionsNormalize,
  );

  const pageInfo = useMemo(() => {
    const flatData = flattenData(data) ?? {};

    if (topicType === EVENT) {
      return getPageInfo({
        latestMatches: data.latestMatches,
        scheduledMatches: data.scheduledMatches,
      });
    }

    return getPageInfo(flatData[topicType]?.[0] ?? {});
  }, [data, topicType]);

  const handleNextCursor = useCallback(
    (pageData, currentTab) => {
      if (!pageData || !pageInfo) return;

      const { latestMatches, scheduledMatches: nextMatches } = pageInfo;
      const { hasNextPage: hasVods, endCursor: vodsCursor } = latestMatches || {};
      const { hasNextPage: hasMatches, endCursor: matchesCursor } = nextMatches || {};

      if (matchTabTypes.includes(currentTab) && hasVods) {
        setNextCursor(vodsCursor);
        setLoading(true);
        return;
      }
      if (currentTab === SCHEDULE && hasMatches) {
        setNextCursor(matchesCursor);
        setLoading(true);
      }
    },
    [pageInfo],
  );

  const handleChange = useMemo(
    () =>
      debounce(isOnScreen => {
        if (isOnScreen) {
          handleNextCursor(data, activeTab);
        }
      }, 250),
    [handleNextCursor, data, activeTab],
  );

  const handleAddSubscriptionClick = useCallback(
    async (id, type, championId) => {
      try {
        const subType = type === CHAMPION ? RIOTCHAMPION : type;
        const inputType = type === CHAMPION ? RIOT_CHAMPION : type;
        const subId = type === EVENT ? selectedEventId : id;
        const reduxId = type === CHAMPION ? championId : subId;
        const variables = {
          input: createInputObject(subType, subId, packageId, false, inputType),
        };
        await fetchGqlQuery({ query: AddSubQuery, variables }, fetchOptions);
        gqlDispatch(pushContentSubscriptionId(reduxId, subType));
        refetch(true);
      } catch (error) {
        logger.error(error);
      }
    },
    [fetchOptions, gqlDispatch, packageId, refetch, selectedEventId],
  );

  const handleDeleteSubscriptionClick = useCallback(
    async (id, type, nodeId) => {
      try {
        const subId = type === EVENT ? selectedEventId : id;
        const subType = type === CHAMPION ? RIOTCHAMPION : type;
        const variables = { nodeId };
        await fetchGqlQuery({ query: DeleteSubQuery, variables }, fetchOptions);
        gqlDispatch(popContentSubscriptionId(subId, subType));
        refetch(true);
      } catch (error) {
        logger.error(error);
      }
    },
    [fetchOptions, gqlDispatch, refetch, selectedEventId],
  );

  const elementSizes = useMemo(() => {
    if (isDesktopOrGreater) {
      return desktopSizes;
    }
    if (isTabletOrGreater) {
      return tabletSizes;
    }
    return mobileSizes;
  }, [isDesktopOrGreater, isTabletOrGreater]);

  const highlightVideos = useMemo(() => {
    if (!highlights?.length) return singletonHighlightsComp;
    return allHighlights[requestHashId]?.map(id => (
      <HighlightClip key={id} highlightId={id} type="tile" size="small" />
    ));
  }, [highlights, allHighlights, requestHashId, singletonHighlightsComp]);

  const eventFilterOptions = useMemo(() => {
    if (tournamentEvents) {
      return Object.values(tournamentEvents).map(
        ({ id, name: title, logo: image, country: subtitle }) => ({
          id,
          title,
          image,
          subtitle,
        }),
      );
    }
    return [];
  }, [tournamentEvents]);

  const filterOptions = useMemo(
    () =>
      isDesktopOrGreater
        ? filterOptionsDesktop[topicType]?.[activeTab]
        : filterOptionsMobile[topicType]?.[activeTab],
    [isDesktopOrGreater, activeTab, topicType],
  );

  const useFilterMenu = useMemo(() => [VODS, HIGHLIGHTS].indexOf(activeTab) !== -1, [activeTab]);

  const handleFilterChange = useCallback(filter => {
    setNextCursor('');
    setPageFilters(filter);
  }, []);

  const handleSortChange = useCallback(filter => setSelectedSortType(filter), []);

  const filterMenu = useMemo(
    () => (
      <FiltersTabset
        eventOptions={eventFilterOptions}
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortChange}
      />
    ),
    [eventFilterOptions, filterOptions, handleFilterChange, handleSortChange],
  );

  const requestedVideos = useMemo(() => {
    const matches = currentVodsList?.reduce((acc, matchId) => {
      const streamId = matchPreview[matchId] || '';
      acc.push({ id: matchId, streams: [streamId] });
      return acc;
    }, []);
    const videos = highlights?.reduce((acc, highlightId) => {
      acc.push({ id: highlightId });
      return acc;
    }, []);
    return { matches, videos };
  }, [currentVodsList, highlights, matchPreview]);
  useFetchVideoCredentials(requestedVideos);

  return (
    <>
      <StickyWrapper showBorder>
        <StickyComponent enableStickyScroll onStickyChange={setShowMiniHeader}>
          <TopicHeader
            topicId={selectedTopicId}
            size={elementSizes.headerSize}
            showMini={showMiniHeader}
            type={topicType}
            onSubscribeClick={handleAddSubscriptionClick}
            onUnsubscribeClick={handleDeleteSubscriptionClick}
          />
        </StickyComponent>
      </StickyWrapper>
      <BodyWrapper>
        <TabsContainer data-testid="tab-container" isSticky={showMiniHeader} noPadding>
          <Tabset
            tabs={tabMenu}
            onClickTab={handleTabClick}
            showIcons
            alignment={isTabletOrGreater && !showMiniHeader ? 'left' : 'center'}
            showBottomBorder={false}
            hasTabMargin
            activeTab={activeTab}
          />
        </TabsContainer>
        {useFilterMenu && filterMenu}
        {activeTab === OVERVIEW && (
          <Overview
            type={topicType}
            elementSizes={elementSizes}
            groupedScheduledMatches={groupedScheduledMatches}
            allLatestMatches={allLatestMatches}
            selectedTopicId={selectedTopicId}
            highlightVideos={highlightVideos}
          />
        )}
        {activeTab === SCHEDULE && (
          <GroupedMatchList
            groupedItems={groupedScheduledMatches}
            size={elementSizes.genericSize}
            type="schedule"
            topicType={topicType}
          />
        )}
        {activeTab === VODS && (
          <>
            <GroupedMatchList
              groupedItems={groupedVodMatches}
              size={elementSizes.vodItem}
              callback={handleChange}
              topicType={topicType}
            />
            {loading && singletonLoadingSpinner}
          </>
        )}
        {activeTab === HIGHLIGHTS && (
          <>
            <HighlightScroll callback={handleChange}>{highlightVideos}</HighlightScroll>
            {loading && singletonLoadingSpinner}
          </>
        )}
        {activeTab === ABOUT && (
          <TopicAbout
            topicId={selectedTopicId}
            size={elementSizes.aboutSize}
            type={topicType}
            tournamentId={selectedTournamentId}
            elementSizes={elementSizes}
          />
        )}
      </BodyWrapper>
    </>
  );
};

export default memo(Topic);
