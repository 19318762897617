import PropTypes from 'prop-types';
import VODItem from 'tv-modules/VODListing/VODItem/VODItem';
import GroupedMatchList, { VOD } from 'tv-modules/Matches/GroupedMatchList/GroupedMatchList';
import useGroupMatchesByDate, { DESC } from 'tv-hooks/useGroupMatchesByDate';

const MatchResultItem = ({ result = {}, resultList = [], elementSizes }) => {
  const { matchItemSize } = elementSizes;
  const groupedItems = useGroupMatchesByDate(resultList, DESC);

  const { id } = result;
  if (resultList.length > 0) {
    return (
      <GroupedMatchList type={VOD} groupedItems={groupedItems} size={matchItemSize} fullWidth />
    );
  }
  return <VODItem matchId={id} size={matchItemSize} />;
};

const matchPropType = PropTypes.shape({
  id: PropTypes.string,
});

MatchResultItem.propTypes = {
  result: matchPropType,
  resultList: PropTypes.arrayOf(PropTypes.string),
  elementSizes: PropTypes.shape({
    tabsetSize: PropTypes.string,
    playerCardSize: PropTypes.string,
    topicCardSize: PropTypes.string,
    topicLinkSize: PropTypes.string,
    matchItemSize: PropTypes.string,
    videoListingSize: PropTypes.string,
    videoListingType: PropTypes.string,
  }).isRequired,
};

export { matchPropType };

export default MatchResultItem;
