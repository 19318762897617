const apps = {
  chat: {
    requiredOptions: [],
    moduleIdentifier: 'CHAT',
  },
  channel: {
    requiredOptions: [],
    moduleIdentifier: 'PRODUCTION_CHANNELS',
  },
  broadcast: {
    requiredOptions: [],
    moduleIdentifier: 'SYSTEM_MESSAGES',
  },
  statsOffsets: {
    requiredOptions: [],
    moduleIdentifier: 'STATS_OFFSETS',
  },
};

export default apps;
