import { useCallback } from 'react';
import { REMOVED, REPORTED, BANNED, ALLOWED, ACTION_TYPES } from '../constants';
import useChatContext from './useChatContext';
import useMessage from './useMessage';
import config from '../../config';

type SharedBodyKey = 'userId' | 'messageId' | 'channel' | 'username' | 'serverMessageId';
type BanBodyKey = SharedBodyKey | 'banEnd' | 'reason' | 'purgeMessages';

type BodyKey = SharedBodyKey | BanBodyKey;

type CallbacksInfo = {
  [key: string]: {
    action: (typeof ACTION_TYPES)[number];
    endpoint: string;
    body: BodyKey[];
  };
};

const callbacksInfo: CallbacksInfo = {
  report: {
    action: REPORTED,
    endpoint: 'reports',
    body: ['userId', 'messageId', 'channel', 'username'],
  },
  remove: {
    action: REMOVED,
    endpoint: 'admin/delete-message',
    body: ['userId', 'messageId', 'channel', 'username'],
  },
  ban: {
    action: BANNED,
    endpoint: 'admin/ban-user',
    body: ['userId', 'messageId', 'channel', 'username'],
  },
  allow: {
    action: ALLOWED,
    endpoint: 'admin/allow-message',
    body: ['userId', 'messageId'],
  },
};

type Type = keyof typeof callbacksInfo;

type BodyData = {
  [key in BodyKey]?: unknown;
};

const useActionCallback = (messageId?: string) => {
  const { userId: messageUserId, serverMessageId, username } = useMessage(messageId) ?? {};
  const { chatId, authToken, chatStore } = useChatContext();

  const getBody = useCallback(
    (type: Type, provided = {}) => {
      const baseBodyOptions = {
        messageId: serverMessageId,
        userId: messageUserId,
        channel: chatId,
        username,
      } as BodyData;

      const baseBody = callbacksInfo[type].body?.reduce(
        (acc, key: BodyKey) => ({
          ...acc,
          [key]: baseBodyOptions[key],
        }),
        {} as BodyData,
      );
      return JSON.stringify({
        ...baseBody,
        ...provided,
      });
    },
    [chatId, messageUserId, serverMessageId, username],
  );

  return useCallback(
    async (type: Type, bodyData = {} as BodyData) => {
      if (!callbacksInfo[type]) return Promise.reject(new Error(`Invalid action type: ${type}`));
      const { action, endpoint } = callbacksInfo[type];

      const body = getBody(type, bodyData);
      const res = await fetch(`${config.QUARTERBACK_API_URL}/v1/chat/${endpoint}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${authToken}`,
        },
        method: 'POST',
        body,
      });

      if (action) {
        chatStore?.onAction(
          (serverMessageId || bodyData.messageId || bodyData.serverMessageId) as string,
          action,
        );
      }
      return res;
    },
    [authToken, chatStore, getBody, serverMessageId],
  );
};

export default useActionCallback;
