import colors from 'znipe-styles/colors';
import { getThemableGradients } from 'znipe-styles/gradients';
import defaultTheme from './default.theme';
import { Theme } from './theme.types';

const theme: Theme = {
  ...defaultTheme,
  themeName: 'svenska-cupen',
  primaryFont: "'azo-sans-web', sans-serif",
  secondaryFont: "'Roboto', sans-serif",

  // Background Colors
  primaryBackgroundColor: '#000C17',
  secondaryBackgroundColor: '#0A151F',
  tertiaryBackgroundColor: '#0D1C29',
  containerStroke: '#0F2130',
  highlight: colors.znipeIconBlue,

  // Accents
  UIAccent: '#FEE001',
  teamOneColor: '#0061B0',
  teamTwoColor: '#F78B48',
  errorRed: colors.velvetCream,
  errorLight: colors.flamingo,
  amberYellow: colors.goldYellow,
  successGreen: colors.trollGreen,

  // Text
  textPrimary: colors.white,
  textSecondary: colors.grey75,

  mastheadGradient: 'linear-gradient(to right, #00457D 0%, #003763 100%)',
  videoBackground: 'https://assets.znipe.tv/anna-concepts/csgo-bg-svenska-cupen-11.mp4',
};

export default { ...theme, ...getThemableGradients(theme) } as Theme; // @TODO remove type assertion once getThemableGradients has proper type
