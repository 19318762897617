import { useState, useEffect, useRef, useReducer, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import EventListener from 'react-event-listener';
import debounce from 'lodash.debounce';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import ProgressBar from 'znipe-elements/data-display/ProgressBar/ProgressBar';
import {
  HorizontalContainer,
  VerticalContainer,
  HorizontalHandle,
  VerticalHandle,
  HorizontalTooltip,
  VerticalTooltip,
  BarWrapper,
} from './Slider.styles';
import themes from './Slider.themes';
import reducer, { initialState } from './Slider.reducer';

const Slider = ({
  value,
  onChange,
  min = 0,
  max,
  initialValue = 0,
  type = 'horizontal',
  size = 'medium',
  showHandle = true,
  children = null,
  tooltip = '',
  showBar = false,
  disabled = false,
}) => {
  const [containerDimensions, setContainerDimensions] = useState(0);
  const [tooltipDimensions, setTooltipDimensions] = useState(0);
  const [handlePos, setHandlePos] = useState(0);
  const [tooltipPos, setTooltipPos] = useState(0);
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleChange = useMemo(() => debounce(newValue => onChange(newValue), 50), [onChange]);

  const themeContext = useThemeContext();
  const fillColor = themeContext.UIAccent;
  const { bgColor } = themeContext.slider;

  // Reducer to keep track of hover and drag states
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    percentage: Math.min((initialValue - min) / (max - min), 1),
  });
  const { dragging, percentage, hovering, hoverPercentage } = state;

  const Container = type === 'horizontal' ? HorizontalContainer : VerticalContainer;
  const Handle = type === 'horizontal' ? HorizontalHandle : VerticalHandle;
  const Tooltip = type === 'horizontal' ? HorizontalTooltip : VerticalTooltip;

  // Keep track of container dimensions
  useEffect(() => {
    const { current } = containerRef;
    if (!current) return null;
    setContainerDimensions(current.getBoundingClientRect());
    const onWindowResize = () => setContainerDimensions(current.getBoundingClientRect());
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  // Keep track of tooltip dimensions
  useEffect(() => {
    const { current } = tooltipRef;
    if (!current) return () => {};
    setTooltipDimensions(current.getBoundingClientRect());
    const onWindowResize = () => setTooltipDimensions(current.getBoundingClientRect());
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  // On percentage change, update tooltip pos
  useEffect(() => {
    if (!tooltipDimensions) return;
    const { height, width } = containerDimensions;
    const { height: tooltipHeight, width: tooltipWidth } = tooltipDimensions;
    const position =
      type === 'horizontal'
        ? Math.max(Math.min(width * hoverPercentage - tooltipWidth / 2, width - tooltipWidth), 0)
        : Math.max(
            Math.min(height * hoverPercentage - tooltipHeight / 2, height - tooltipHeight),
            0,
          );
    setTooltipPos(position);
  }, [hoverPercentage, containerDimensions, type, tooltipDimensions]);

  // On percentage change, update handle pos
  useEffect(() => {
    if (!showHandle) return;
    const { height = 0, width = 0 } = containerDimensions;
    const position = type === 'horizontal' ? width * percentage : height - height * percentage;
    setHandlePos(position);
  }, [percentage, containerDimensions, type, showHandle]);

  // On Slider drag
  useEffect(() => {
    const newValue = (max - min) * percentage;
    handleChange(newValue);
  }, [min, max, percentage, handleChange]);

  // On value prop change
  useEffect(() => {
    if (typeof value === 'undefined') return;
    handleChange(value);
    dispatch({
      type: 'SET_STATE',
      percentage: value / (max - min),
    });
  }, [value, handleChange, min, max]);

  const onDragStart = e => {
    e.preventDefault();
    dispatch({
      type: 'DRAG_START',
      x: e.clientX,
      y: e.clientY,
      element: e.currentTarget,
      vertical: type === 'vertical',
    });
  };

  const onDragMove = e => {
    e.preventDefault();
    dispatch({
      type: 'DRAG_MOVE',
      x: e.clientX,
      y: e.clientY,
      vertical: type === 'vertical',
    });
  };

  const onDragEnd = e => {
    e.preventDefault();
    dispatch({
      type: 'DRAG_END',
      x: e.clientX,
      y: e.clientY,
      vertical: type === 'vertical',
    });
  };

  const onHoverStart = e => {
    e.preventDefault();
    dispatch({
      type: 'HOVER_START',
      x: e.clientX,
      y: e.clientY,
      element: e.currentTarget,
      vertical: type === 'vertical',
    });
  };

  const onHoverMove = e => {
    e.preventDefault();

    dispatch({
      type: 'HOVER_MOVE',
      x: e.clientX,
      y: e.clientY,
      vertical: type === 'vertical',
    });
  };

  const onHoverEnd = e => {
    e.preventDefault();
    dispatch({
      type: 'HOVER_END',
      x: e.clientX,
      y: e.clientY,
      vertical: type === 'vertical',
    });
  };

  return (
    <Container
      ref={containerRef}
      size={size}
      hoverEffect={!showHandle}
      onMouseDown={onDragStart}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      $disabled={disabled}
      data-testid="container"
    >
      {dragging && <EventListener target={window} onMouseMove={onDragMove} onMouseUp={onDragEnd} />}
      {hovering && <EventListener target={window} onMouseMove={onHoverMove} />}
      {showHandle && <Handle position={handlePos} size={size} data-testid="handle" />}
      {(showBar || !showHandle) && (
        <BarWrapper>
          <ProgressBar
            data-testid="bar"
            fillRatio={percentage * 100}
            fillColor={fillColor}
            backgroundColor={bgColor}
            vertical={type === 'vertical'}
          />
        </BarWrapper>
      )}
      {tooltip && (
        <Tooltip
          ref={tooltipRef}
          position={tooltipPos}
          visible={dragging || hovering}
          data-testid="tooltip"
        >
          {tooltip}
        </Tooltip>
      )}
      {children}
    </Container>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  initialValue: PropTypes.number,
  type: PropTypes.oneOf(['horizontal', 'vertical']),
  size: PropTypes.oneOf(['thin', 'medium', 'fat']),
  showHandle: PropTypes.bool,
  showBar: PropTypes.bool,
  children: PropTypes.element,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

const isEqual = (prevProps, nextProps) => {
  if (prevProps.min !== nextProps.min) return false;
  if (prevProps.max !== nextProps.max) return false;
  if (prevProps.value !== nextProps.value) return false;
  if (prevProps.tooltip !== nextProps.tooltip) return false;
  if (prevProps.onChange !== nextProps.onChange) return false;
  if (prevProps.disabled !== nextProps.disabled) return false;
  return true;
};

export default withTheme(memo(Slider, isEqual), themes, 'slider');
