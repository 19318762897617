import getBrowserLanguage from 'tv-utils/getBrowserLanguage';
import { register } from 'tv-utils/monkeyPatchFetch';
import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export const ScrollToTopOnMount = () => {
  // Taken from https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
  const { pathname, search } = useLocation();
  const navigationType = useNavigationType();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    // If user pressed back button, don't scroll to top
    // and return to their previous position on the page
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [navigationType, pathname, search]);

  return null;
};

export const scrollToTop = () => {
  if (!global.document) return;
  (window || global).scrollTo(0, 0);
};

let currentLocale;

export const componentDidMountMultiLang = (path, nextState, replace, callback) => {
  const { params = {} } = nextState;
  const { locale } = params;
  currentLocale = locale;

  const updateURL = newLocale => {
    if (currentLocale === newLocale) return;
    const newURL = Object.keys(params).reduce(
      (res, key) => res.replace(`:${key}`, params[key]),
      path.replace(':locale', newLocale),
    );
    replace(newURL);
    currentLocale = newLocale;
  };
  const convertLocale = _locale => _locale.replace('-', '_').toLowerCase();
  if (!locale) {
    if (global.document) {
      const browserLanguage = getBrowserLanguage();
      const browserLocale = convertLocale(browserLanguage);
      updateURL(browserLocale);
    } else {
      updateURL('en_us');
    }
  }
  scrollToTop();
  callback();
};

register({
  request(url, config) {
    const newURL = currentLocale
      ? `${url}${
          url.indexOf('?') === -1
            ? `?locale=${currentLocale.toLowerCase()}`
            : `&locale=${currentLocale.toLowerCase()}`
        }`
      : url;
    return [newURL, config];
  },
});
