import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import useOnPageScrollEnd from 'tv-hooks/useOnPageScrollEnd';
import CategorySection from './CategorySection';
import SectionLoading from './SectionLoading';
import {
  SCHEDULE,
  sectionTitles,
  categoryTypePropType,
  elementSizesPropType,
} from '../Browse.constants';

const CategoryResultWrapper = styled.div`
  min-height: 100vh;
`;

const unsearchableContentTypes = [SCHEDULE];

const CategoryResult = ({
  topResultData = [],
  highlightedResultsData = [],
  allResults = {},
  elementSizes,
  searchQuery = '',
  type,
  isUsingFilter = false,
  setDataCursor = () => {},
  isLoading = false,
}) => {
  const handlePageEnd = useCallback(() => {
    const { hasNextPage, endCursor } = allResults?.cursors?.[type] || {};
    if (hasNextPage && endCursor) {
      setDataCursor(endCursor);
    }
  }, [setDataCursor, allResults, type]);

  const PageEndElement = useOnPageScrollEnd(handlePageEnd, isLoading);

  const shouldShowSearchResultLayout = !unsearchableContentTypes.includes(type) && searchQuery;
  const allResultData = allResults[type] || [];
  if (isLoading && allResultData.length === 0)
    return (
      <SectionLoading hasSectionTitles={!searchQuery} type={type} elementSizes={elementSizes} />
    );
  const hasBrowseResults = allResultData.length > 0;
  if (!hasBrowseResults && isUsingFilter) return <NoResults type="filter" />;
  if (!hasBrowseResults) return <NoResults />;

  return shouldShowSearchResultLayout ? (
    <CategorySection data={allResultData} type={type} elementSizes={elementSizes} noTopMargin />
  ) : (
    <CategoryResultWrapper data-testid="browse-category-result">
      <CategorySection
        data={topResultData}
        type={type}
        title={sectionTitles[type].top}
        elementSizes={elementSizes}
        noTopMargin
      />
      <CategorySection
        data={highlightedResultsData}
        type={type}
        title={sectionTitles[type].highlighted}
        elementSizes={elementSizes}
      />
      <CategorySection
        data={allResultData}
        type={type}
        title={sectionTitles[type].all}
        elementSizes={elementSizes}
        loadMoreElement={PageEndElement}
        noTopMargin // remove this when data fetching for top and highlighted result is enabled
      />
    </CategoryResultWrapper>
  );
};

CategoryResult.propTypes = {
  topResultData: PropTypes.arrayOf(PropTypes.string),
  highlightedResultsData: PropTypes.arrayOf(PropTypes.string),
  allResults: PropTypes.shape({
    cursors: PropTypes.shape({}),
  }),
  elementSizes: elementSizesPropType.isRequired,
  searchQuery: PropTypes.string,
  isUsingFilter: PropTypes.bool,
  isLoading: PropTypes.bool,
  setDataCursor: PropTypes.func,
  type: categoryTypePropType.isRequired,
};

export default memo(CategoryResult);
