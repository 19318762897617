import generateHashCodeFromString from 'znipe-utils/misc/generateHashCodeFromString';
import { error } from 'znipe-logger';
import { LOADING, ERROR, SUCCESS } from './constants';

export const generateCacheHash = (queryHash, variables) => {
  try {
    const variablesArr = Object.entries(variables || {}).sort();
    const shouldIncludeVariable = variablesArr.length > 0;
    const variablesString = shouldIncludeVariable
      ? variablesArr.reduce((acc, [key, value]) => `${acc} ${key} ${value}`)
      : '';
    return `${queryHash}${generateHashCodeFromString(`${variablesString}`).toString()}`;
  } catch (e) {
    error('Could not generate cache code!', e);
    return '-1';
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      if (state.loading) return state;
      return { ...state, error: null, loading: true };
    case ERROR:
      return { ...state, error: action.error, loading: false };
    case SUCCESS:
      if (state.data === action.data && !state.loading) return state;
      return { ...state, data: action.data, error: null, loading: false };
    default:
      return state;
  }
};

export const init = data => ({
  error: null,
  data: data ?? null,
  loading: false,
});
