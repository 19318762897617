/**
 * v1
 * Breaks string up into an title array
 * @param {string} string String to split i.e. Team Liquid vs Effect
 * @returns {string[]} Parsed string i.e. ["Team Liquid vs" "Effect"]
 */
const splitStringToTitle = (string = '') => {
  const weekRegex = /(.*)(Week\s\d+\s-.*)/i;
  if (weekRegex.test(string)) {
    const [, intro, ...rest] = string.match(weekRegex);
    return [intro.trim(), rest.join(' - ')];
  }
  const dayRegex = /(.*)(Day\s\d+.*)/i;
  if (dayRegex.test(string)) {
    const [, intro, ...rest] = string.match(dayRegex);
    return [intro.trim(), rest.join(' - ')];
  }
  if (string.match(/\S+/g)?.length > 6 && /(.*VS)(.*)/.test(string)) {
    const [, intro, end] = string.match(/(.*VS)(.*)/);
    return [intro.trim(), end.trim()];
  }

  const stringToArray = string.split(' ');
  return [stringToArray.slice(0, -1).join(' '), stringToArray[stringToArray.length - 1]];
};

export default splitStringToTitle;
