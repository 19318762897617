import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import CoverOverlay from 'tv-modules/Onboarding/Overlay/Overlay';

const MOBILE_HEADER_MARGIN = '16px';
const DESKTOP_HEADER_MARGIN = '24px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  max-height: 100%;
  ${Scrollbar};
  overflow-x: hidden;
  & > :first-child {
    margin-bottom: ${MOBILE_HEADER_MARGIN};
    @media ${device.desktop} {
      margin-bottom: ${DESKTOP_HEADER_MARGIN};
    }
  }
  ${CoverOverlay} {
    margin-top: -${MOBILE_HEADER_MARGIN};
    min-height: calc(100% + ${MOBILE_HEADER_MARGIN});
    @media ${device.desktop} {
      margin-top: -${DESKTOP_HEADER_MARGIN};
      min-height: calc(100% + ${DESKTOP_HEADER_MARGIN});
    }
  }
`;

export const ClickableItemStyle = css`
  background: ${({ theme }) => theme.bg2Fade};
  cursor: pointer;
`;

export const DividerContainer = styled.div`
  display: flex;
  width: 100%;
`;
export const EmptyDivider = styled.div`
  height: 2px;
  width: 60px;
  background: none;
`;
export const TimerDivider = styled.div`
  height: 2px;
  width: 240px;
  background-color: ${({ theme }) => theme.containerStroke};
`;

export const BodyContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  ${({ $isShowingTutorial }) =>
    $isShowingTutorial &&
    css`
      overflow-y: hidden;
    `};
`;

export const Content = styled.div`
  padding: ${({ $sidebar }) => ($sidebar ? '0 16px' : '0')};
  width: 100%;
  max-width: 300px;
  @media ${device.tablet} {
    max-width: 396px;
  }

  > * {
    margin-bottom: 16px;
  }
`;
