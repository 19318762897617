import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import getResizeUrl from 'znipe-utils/misc/getResizeURL';
import useOnScreen from 'znipe-hooks/useOnScreen';

const LazyElement = ({
  alt = '',
  children,
  style,
  src = '',
  resizeDisabled = false,
  srcSet,
  format = 'webp',
  rootMargin = '0% 0% 25%',
}) => {
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref, rootMargin);

  const lazySrc = useMemo(() => {
    const isSVG = src && src.endsWith('.svg');
    const skipResize = !src || resizeDisabled || isSVG;
    if (skipResize) {
      return src;
    }
    return getResizeUrl(src, srcSet, format);
  }, [format, resizeDisabled, src, srcSet]);

  if (children) {
    return (
      <div ref={ref} style={style}>
        {isOnScreen && children}
      </div>
    );
  }

  return (
    <div style={style} ref={ref}>
      {isOnScreen && (
        <img
          src={lazySrc}
          alt={alt || 'image'}
          width={srcSet?.tiny}
          height={srcSet?.tiny}
          data-testid="lazy-image-element"
        />
      )}
    </div>
  );
};

LazyElement.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}), // extra css styles
  src: PropTypes.string,
  resizeDisabled: PropTypes.bool,
  srcSet: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.number, PropTypes.string]),
  format: PropTypes.string,
  rootMargin: PropTypes.string,
};

export default LazyElement;
