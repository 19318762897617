import { memo } from 'react';
import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Description from 'tv-modules/Payment/SharedStyles/Description';
import Header from 'tv-modules/Payment/SharedStyles/Header';
import { Wrapper, ErrorImageWrapper } from './ErrorPage.styles';
import ErrorImage from '../ErrorImage/ErrorImage';

const ErrorPage = ({
  header = '',
  description = '',
  buttonLabel = '',
  image: Image = ErrorImage,
  to,
  size = '98px',
}) => (
  <Wrapper data-testid="error-page">
    {header && <Header data-testid="header">{header}</Header>}
    {description && <Description data-testid="description">{description}</Description>}
    <ErrorImageWrapper>
      <Image size={size} />
    </ErrorImageWrapper>
    {buttonLabel && to && (
      <Button data-testid="button-link" variant="solid-color" size="small" to={to}>
        {buttonLabel}
      </Button>
    )}
  </Wrapper>
);

ErrorPage.propTypes = {
  header: PropTypes.string,
  image: PropTypes.func,
  size: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]),
};

export default memo(ErrorPage);
