import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import OnboardingButton from 'tv-modules/Onboarding/Button/Button';
import colors from 'znipe-styles/colors';
import { Container, OverlayHeadline } from './OverlayMessage.styles';

const OverlayMessage = ({ headline, subHeadline, buttonLabel, onClick }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();

  const subHeadlineSize = useMemo(() => {
    if (isDesktopLargeOrGreater) return 'heading-m';
    if (isTabletOrGreater) return 'heading-s';
    return 'heading-xs';
  }, [isDesktopLargeOrGreater, isTabletOrGreater]);

  return (
    <Container>
      {headline && <OverlayHeadline>{headline}</OverlayHeadline>}
      {subHeadline && (
        <Typography type={subHeadlineSize} color={colors.white}>
          {subHeadline}
        </Typography>
      )}
      {onClick && buttonLabel && <OnboardingButton buttonLabel={buttonLabel} onClick={onClick} />}
    </Container>
  );
};

OverlayMessage.propTypes = {
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
  onClick: PropTypes.func,
  buttonLabel: PropTypes.string,
};

export default OverlayMessage;
