import PropTypes from 'prop-types';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { setMatchPlayerStreams } from 'tv-actions/matches';

const SelectedVideoHighlight = ({ matchId, streamId, children }) => {
  const gqlDispatch = useGqlStoreDispatch();
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const videoHighlight = streamHighlight => {
    const playerStream = playerStreams.find(stream => stream.id === streamId);
    if (playerStream) {
      const playerStreamsUpdated = playerStreams.map(stream => {
        if (stream.id === streamId) {
          return {
            ...stream,
            isHighlighted: streamHighlight,
          };
        }
        return {
          ...stream,
          isHighlighted: false,
        };
      });
      gqlDispatch(setMatchPlayerStreams(matchId, playerStreamsUpdated));
    }
  };
  return (
    <div
      onMouseEnter={() => videoHighlight(true)}
      onMouseLeave={() => videoHighlight(false)}
      data-testid="selected-video-highlight"
    >
      {children}
    </div>
  );
};

SelectedVideoHighlight.propTypes = {
  matchId: PropTypes.string.isRequired,
  streamId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SelectedVideoHighlight;
