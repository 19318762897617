import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Headline from 'tv-modules/Onboarding/Headline/Headline';

export const OverlayHeadline = styled(Headline)`
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 32px;
  margin-top: 0;

  @media ${device.tablet} {
    font-size: 50px;
    line-height: 50px;
  }

  @media ${device.desktop} {
    font-size: 70px;
    line-height: 70px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > *:nth-child(2) {
    /* max-width: 344px; */
    max-width: 390px;
    text-align: center;
  }

  @media ${device.tablet} {
    > *:nth-child(2) {
      margin-bottom: 64px;
    }
  }
`;
