import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetVideoSrc = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => video.hlsSrc || video.dashSrc || '');
};

export const useGetVideoSrc = props => useMemoizedGqlSelector(makeGetVideoSrc, props);

export default makeGetVideoSrc;
