import { useMemo } from 'react';
import { useIsAdmin } from 'tv-selectors/auth/makeGetIsAdmin';
import useGetUserPackageSubscription from 'tv-hooks/useGetUserPackageSubscription';
import { useGetPackageProducts } from 'tv-selectors/packages/makeGetPackageProducts';
import usePackageName from 'tv-hooks/usePackageName';
import isInIframe from 'tv-utils/isInIframe';

const useHasPremiumAccess = providedPackage => {
  const isAdmin = useIsAdmin();
  const packageName = usePackageName(providedPackage);
  const subscription = useGetUserPackageSubscription(packageName);

  const productId = subscription?.productId ?? '';
  const hasPremiumAccess = useMemo(() => Boolean(productId), [productId]);
  const packageProducts = useGetPackageProducts({ packageName });
  /* check that the package products have been fetched to avoid flashing
     on page where products is not fetched */
  const hasFetchedPackageProducts = packageProducts.length > 0;
  /*
    Subscription information is only available the client side, so lets assume the user
    has premium access on the server side to avoid flashing premium elements when rendering the page
  */

  if (!global.document || isAdmin) return true;
  if (isInIframe()) return true; // This is temporary until we support signing in with embed
  if (!hasFetchedPackageProducts) return true;

  return hasPremiumAccess;
};

export default useHasPremiumAccess;
