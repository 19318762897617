import { useMemo } from 'react';
import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
import SelectorHorizontal, { leftImageSize } from '../SelectorHorizontal';
import { LeftImage } from '../SelectorHorizontal.styles';
import { propTypes, STREAM_TYPE_MAP } from '../../constants';

const MapSelector = ({
  label,
  size = 'small',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
}) => {
  const imageComponent = useMemo(() => {
    const imageWidth = leftImageSize[size];
    return (
      <LeftImage $imageWidth={imageWidth}>
        <Icon type="mapIcon" fillColor={colors.white} size={imageWidth} />
      </LeftImage>
    );
  }, [size]);
  return (
    <SelectorHorizontal
      streamType={STREAM_TYPE_MAP}
      label={label}
      size={size}
      leftArea={imageComponent}
      isSelected={isSelected}
      isLocked={isLocked}
      hasPremiumAccess={hasPremiumAccess}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      data-testid={testId}
    />
  );
};

MapSelector.propTypes = propTypes;

export default MapSelector;
