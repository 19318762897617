import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetStreams from './makeGetStreams';
import makeGetStreamId from './makeGetStreamId';

const emptyObject = {};
const makeGetStream = () => {
  const getStreams = makeGetStreams();
  const getStreamId = makeGetStreamId();
  return createSelector(
    [getStreams, getStreamId],
    (streams, streamId) => streams[streamId] || emptyObject,
  );
};

export const useGetStream = props => useMemoizedGqlSelector(makeGetStream, props);

export default makeGetStream;
