import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Button = styled.button`
  position: absolute;
  height: 16px;
  width: 16px;
  padding: 0;
  background: transparent;

  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto;

  outline: none;
  border: none;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
`;

export const RestrictionsText = styled(Typography)`
  margin: 0;
  color: ${({ $error, theme }) => ($error ? theme.errorRed : 'inherit')};
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`;
