import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetGameCompetitorsLineups from 'tv-selectors/games/makeGetGameCompetitorsLineups';

const makeGetStreamId = () => {
  const getProps = makeGetProps();
  const getGameCompetitorsLineups = makeGetGameCompetitorsLineups();
  return createSelector(
    [getProps, getGameCompetitorsLineups],
    ({ streamId, streamID, playerId, playerID }, lineups) => {
      // get streamId props
      const selectedStreamId = streamId || streamID;
      if (selectedStreamId) return selectedStreamId;

      // get streamId using playerId
      const selectedPlayerId = playerId || playerID || '';
      const isFakePlayerId = selectedPlayerId.indexOf('_') !== -1;
      if (isFakePlayerId) {
        const splitPlyerIdString = selectedPlayerId.split('_');
        return splitPlyerIdString[0];
      }
      const selectedLineup = lineups.find(lineup => lineup.playerId === selectedPlayerId) || {};
      return selectedLineup.streamId || '';
    },
  );
};

export default makeGetStreamId;

export const useGetStreamId = props => useMemoizedGqlSelector(makeGetStreamId, props);
