import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { ROUND_DAMAGE_SUMMARY_EVENT } from 'tv-reducers/intensive';
import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';

export const SUBSCRIBE_ROUND_DAMAGE_SUMMARY = 'SUBSCRIBE_ROUND_DAMAGE_SUMMARY';
export const UNSUBSCRIBE_ROUND_DAMAGE_SUMMARY = 'UNSUBSCRIBE_ROUND_DAMAGE_SUMMARY';

export const useRoundDamageSummary = matchId => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId) return undefined;
    dispatch({ type: SUBSCRIBE_ROUND_DAMAGE_SUMMARY, gatId });
    return () => dispatch({ type: UNSUBSCRIBE_ROUND_DAMAGE_SUMMARY, gatId });
  }, [dispatch, gatId]);
};

export const summaryAsObject = summary =>
  summary.reduce((acc, current) => ({ ...acc, [current.steamId]: current }), {});
export const mergeDamageSummaries = (summary1, summary2) =>
  Object.values(
    summary2.reduce((acc, current) => {
      const id = current.steamId;
      const oldData = acc[id] ?? {};
      const { weaponDamage = 0, headshotDamage = 0, damageArmor = 0, utilityDamage = 0 } = oldData;
      return {
        ...acc,
        [id]: {
          ...current,
          weaponDamage: weaponDamage + current.weaponDamage,
          headshotDamage: headshotDamage + current.headshotDamage,
          damageArmor: damageArmor + current.damageArmor,
          utilityDamage: utilityDamage + current.utilityDamage,
        },
      };
    }, summaryAsObject(summary1)),
  );

export const groupDamageSummariesByRound = summaries =>
  summaries?.reduce((acc, summary) => {
    if (!acc[summary.round]) return { ...acc, [summary.round]: summary };

    const id = summary.round;
    const accVal = acc[id] || {};
    return {
      ...acc,
      [id]: {
        ...accVal,
        damageSummary: mergeDamageSummaries(accVal.damageSummary, summary.damageSummary),
      },
    };
  }, {});

export const getPlayers = payload =>
  payload?.reduce(
    (acc, { damageSummary }) =>
      damageSummary.reduce((summaryAcc, summary) => {
        const playerObject = createPlayerObject(summary);
        return {
          ...summaryAcc,
          [playerObject.id]: playerObject,
        };
      }, acc),
    [],
  );

const roundDamageSummary = factorySubscribeEpic(
  [SUBSCRIBE_ROUND_DAMAGE_SUMMARY, UNSUBSCRIBE_ROUND_DAMAGE_SUMMARY],
  ({ gatId }) => gatId,
  ({ gatId }) =>
    fromEvent(
      firebase.database().ref(`/gat/${gatId}`).orderByChild('type').equalTo('DamageEvent'),
      'child_added',
    ).pipe(map(([snap]) => snap.val())),
  ({ gatId }) =>
    payload => {
      const mappedPayload = groupDamageSummariesByRound(payload);
      const players = getPlayers(payload);
      return {
        type: ROUND_DAMAGE_SUMMARY_EVENT,
        gatId,
        payload: { id: gatId, rounds: Object.values(mappedPayload), players },
      };
    },
);

export default roundDamageSummary;
