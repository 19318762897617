import { useMemo } from 'react';
import useUserCustomization from './useUserCustomization';
import usePackageCustomization from './usePackageCustomization';

const useUserSignifiers = (authManagerId?: string) => {
  const { chatSignifiers = [] } = usePackageCustomization();
  const { chatSignifiers: userSignifiers } = useUserCustomization(authManagerId);

  const signifierIds = useMemo(
    () =>
      userSignifiers?.reduce((acc: string[], type: string) => {
        const signifier = chatSignifiers?.find(({ humanReadableId }) => humanReadableId === type);
        return signifier ? [...acc, signifier.humanReadableId] : acc;
      }, []),
    [chatSignifiers, userSignifiers],
  );

  return { chatSignifiers, signifierIds };
};

export default useUserSignifiers;
