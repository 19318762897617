import colors from 'znipe-styles/colors';

export default {
  proview: {
    fillColor: colors.spaceGreen,
    bgColor: colors.riotBackgroundSecondary,
    progressBarBaseHandleColor: colors.riotBackgroundSecondary,
    fat: {
      bar: '18px',
      barHover: '24px',
      handle: '24px',
    },
    medium: {
      bar: '15px',
      barHover: '20px',
      handle: '20px',
    },
    thin: {
      bar: '12px',
      barHover: '15px',
      handle: '15px',
    },
  },
  esportal: {
    fillColor: colors.slimeGreen,
    bgColor: colors.riotBackgroundSecondary,
    progressBarBaseHandleColor: colors.slimeGreen,
    fat: {
      bar: '10px',
      barHover: '17px',
      handle: '21px',
    },
    medium: {
      bar: '8px',
      barHover: '10px',
      handle: '16px',
    },
    thin: {
      bar: '4px',
      barHover: '6px',
      handle: '9px',
    },
  },
  default: {
    fillColor: colors.spaceGreen,
    bgColor: colors.riotBackgroundSecondary,
    progressBarBaseHandleColor: colors.riotBackgroundRule,
    fat: {
      bar: '10px',
      barHover: '17px',
      handle: '21px',
    },
    medium: {
      bar: '8px',
      barHover: '10px',
      handle: '16px',
    },
    thin: {
      bar: '4px',
      barHover: '6px',
      handle: '9px',
    },
  },
};
