import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container as InputContainer } from 'znipe-elements/data-entry/Input/Input.styles';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import colors from 'znipe-styles/colors';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';

export const Form = styled.form`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  margin: 16px 0;
  max-width: 560px;
  color: ${colors.grey30};

  ${({ $hiddeInput }) =>
    $hiddeInput &&
    css`
      ${InputContainer} {
        visibility: hidden;
      }
    `}
`;

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const Options = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LinkTypography = styled(Typography).attrs({ forwardAs: 'span', type: 'paragraph-m' })`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  margin: 0;
  gap: 0.2rem;
  color: ${({ theme }) => theme.textSecondary};

  a {
    white-space: nowrap;
    color: ${({ theme }) => theme.UIAccent};
  }
`;

const SeparatorStyle = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  color: ${({ theme }) => theme.textSecondary};
  text-transform: uppercase;

  &:after,
  &:before {
    content: '';
    border-top: solid 1px ${({ theme }) => theme.containerStroke};
    width: 100%;
    height: 0;
  }
`;

export const Separator = () => <SeparatorStyle type="paragraph-s">Or</SeparatorStyle>;

export const LoadingButton = ({ loading = false, children = null, type = 'button' }) => (
  <Button variant="solid-color" type={type} size="small">
    {loading ? (
      <LoadingSpinnerWrapper>
        <LoadingSpinner fillColor={colors.white} />
      </LoadingSpinnerWrapper>
    ) : (
      children
    )}
  </Button>
);

LoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

const SuccessImage = styled.img`
  height: 120px;
  width: 120px;
  align-self: center;
`;

export const SuccessGif = () => (
  <SuccessImage alt="success" src="http://assets.znipe.tv/misc/success.gif" />
);

export const EnvelopeSVG = () => (
  <img alt="envelope" src="http://assets.znipe.tv/misc/envelope.svg" />
);

export const ErrorText = styled(Typography)`
  margin: 0;
  color: ${({ theme }) => theme.errorRed};
  align-self: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 32px 0;
`;

export const TypographyNoMargin = styled(Typography)`
  margin: 0;
`;

export const FakeLink = styled.button`
  background: transparent;
  border: none;
  color: -webkit-link;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
`;
