import shaka from 'shaka-player';
import logger from 'znipe-logger';
import makeHttpResponse from './utils/makeHttpResponse';

// Version of with changes to calculation of load time
// https://shaka-player-demo.appspot.com/docs/api/lib_net_http_fetch_plugin.js.html

const makeRequest = async (uri, requestType, init, abortStatus, progressUpdated) => {
  let response;
  let arrayBuffer;
  let loaded = 0;
  let lastLoaded = 0;

  // Last time stamp when we got a progress event.
  let lastTime;
  const initTime = Date.now();
  try {
    // The promise returned by fetch resolves as soon as the HTTP response
    // headers are available. The download itself isn't done until the promise
    // for retrieving the data (arrayBuffer, blob, etc) has resolved.
    response = await fetch(uri, init);
    // Getting the reader in this way allows us to observe the process of
    // downloading the body, instead of just waiting for an opaque promise to
    // resolve.
    // We first clone the response because calling getReader locks the body
    // stream; if we didn't clone it here, we would be unable to get the
    // response's arrayBuffer later.
    const reader = response.clone().body.getReader();

    const contentLengthRaw = response.headers.get('Content-Length');
    const contentLength = contentLengthRaw ? parseInt(contentLengthRaw, 10) : 0;

    const start = controller => {
      const push = async () => {
        let readObj;
        try {
          readObj = await reader.read();
        } catch (e) {
          // If we abort the request, we'll get an error here.  Just ignore it
          // since real errors will be reported when we read the buffer below.
          logger.error('error reading from stream', e.message);
          return;
        }

        if (!readObj.done) {
          loaded += readObj.value.byteLength;
        }

        const currentTime = Date.now();
        // If the time between last time and this time we got progress event
        // is long enough, or if a whole segment is downloaded, call
        // progressUpdated().
        if (loaded >= 1 && !lastTime) {
          shaka.net.NetworkingEngine.ttfb = (Date.now() - initTime) / 1000;
          lastLoaded = loaded;
          lastTime = Date.now();
        } else if (currentTime - lastTime > 100 || readObj.done) {
          progressUpdated(currentTime - lastTime, loaded - lastLoaded, contentLength - loaded);
          lastLoaded = loaded;
          lastTime = currentTime;
        }

        if (readObj.done) {
          controller.close();
        } else {
          controller.enqueue(readObj.value);
          push();
        }
      };
      push();
    };
    // Create a ReadableStream to use the reader. We don't need to use the
    // actual stream for anything, though, as we are using the response's
    // arrayBuffer method to get the body, so we don't store the ReadableStream.
    new ReadableStream({ start }); // eslint-disable-line no-new
    arrayBuffer = await response.arrayBuffer();
  } catch (error) {
    if (abortStatus.canceled) {
      throw new shaka.util.Error(
        shaka.util.Error.Severity.RECOVERABLE,
        shaka.util.Error.Category.NETWORK,
        shaka.util.Error.Code.OPERATION_ABORTED,
        uri,
        requestType,
      );
    }
    if (abortStatus.timedOut) {
      throw new shaka.util.Error(
        shaka.util.Error.Severity.RECOVERABLE,
        shaka.util.Error.Category.NETWORK,
        shaka.util.Error.Code.TIMEOUT,
        uri,
        requestType,
      );
    }
    throw new shaka.util.Error(
      shaka.util.Error.Severity.RECOVERABLE,
      shaka.util.Error.Category.NETWORK,
      shaka.util.Error.Code.HTTP_ERROR,
      uri,
      error,
      requestType,
    );
  }
  const headers = {};
  /** @type {Headers} */
  const responseHeaders = response.headers;
  responseHeaders.forEach((value, key) => {
    // Since IE/Edge incorrectly return the header with a leading new line
    // character ('\n'), we trim the header here.
    headers[key.trim()] = value;
  });
  return makeHttpResponse(headers, arrayBuffer, response.status, uri, response.url, requestType);
};

export const parse = (uri, request, requestType, progressUpdated) => {
  const headers = new window.Headers();

  Object.keys(request.headers).forEach(key => headers.append(key, request.headers[key]));
  const controller = new window.AbortController();

  const init = {
    // Edge does not treat null as undefined for body; https://bit.ly/2luyE6x
    body: request.body || undefined,
    headers,
    method: request.method,
    signal: controller.signal,
    credentials: request.allowCrossSiteCredentials ? 'include' : undefined,
  };
  const abortStatus = {
    canceled: false,
    timedOut: false,
  };

  const pendingRequest = makeRequest(uri, requestType, init, abortStatus, progressUpdated);

  const op = new shaka.util.AbortableOperation(pendingRequest, () => {
    abortStatus.canceled = true;
    controller.abort();
    return Promise.resolve();
  });

  // The fetch API does not timeout natively, so do a timeout manually using the
  // AbortController.
  const timeoutMs = request.retryParameters.timeout;
  if (timeoutMs) {
    const timer = new shaka.util.Timer(() => {
      abortStatus.timedOut = true;
      controller.abort();
    });

    timer.tickAfter(timeoutMs / 1000);

    // To avoid calling |abort| on the network request after it finished, we
    // will stop the timer when the requests resolves/rejects.
    op.finally(() => {
      timer.stop();
    });
  }

  return op;
};

const isSupported = () => {
  // On Edge, ReadableStream exists, but attempting to construct it results in
  // an error. See https://bit.ly/2zwaFLL
  // So this has to check that ReadableStream is present AND usable.
  if (window.ReadableStream) {
    try {
      new ReadableStream({}); // eslint-disable-line no-new
    } catch (_e) {
      return false;
    }
  } else {
    return false;
  }
  return !!(window.fetch && window.AbortController);
};

const register = () => {
  if (isSupported()) {
    shaka.net.NetworkingEngine.registerScheme(
      'http',
      parse,
      shaka.net.NetworkingEngine.PluginPriority.PREFERRED,
    );
    shaka.net.NetworkingEngine.registerScheme(
      'https',
      parse,
      shaka.net.NetworkingEngine.PluginPriority.PREFERRED,
    );
  }
};

export default register;
