import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetContentSubscriptions from 'tv-selectors/contentSubscriptions/makeGetContentSubscriptions';

const getItemId = (_, props) => props?.id ?? '';
const getType = (_, props) => props?.type ?? '';

const makeGetNodeId = () => {
  const getContentSubscriptions = makeGetContentSubscriptions();
  return createSelector(
    [getContentSubscriptions, getItemId, getType],
    (subscriptions, id, type) => {
      const subscription = subscriptions?.[type]?.[id];
      return subscription?.nodeId || '';
    },
  );
};

export const useGetNodeId = props => useMemoizedGqlSelector(makeGetNodeId, props);

export default makeGetNodeId;
