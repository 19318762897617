import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useThemeContext from 'znipe-hooks/useThemeContext';
import getColorsFromGradientString from 'znipe-utils/misc/getColorsFromGradientString';
import { LineSVG } from './Hint.styles';

const Line = ({ direction, height, position }) => {
  const { onboardingGradient } = useThemeContext();
  const [color1, color2] = useMemo(
    () => getColorsFromGradientString(onboardingGradient),
    [onboardingGradient],
  );
  return (
    <LineSVG
      data-testid="hint-line"
      $position={position}
      $direction={direction}
      width="86"
      height={height}
      viewBox={`0 0 86 ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M6 1V0H5V1H6ZM6 ${height}L11.7735 ${height - 6}L6 ${height - 12}L0.226497 ${
          height - 6
        }L6 ${height}ZM86 0H6V2H86V0ZM5 1V${height - 6}H7V1H5Z`}
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="1"
          x2="72.4247"
          y2="45.7249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1} />
          <stop offset="1" stopColor={color2} />
        </linearGradient>
      </defs>
    </LineSVG>
  );
};

Line.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  height: PropTypes.number.isRequired,
  position: PropTypes.oneOf(['top', 'bottom']).isRequired,
};

export default Line;
