import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import { DefaultLoginButtonWrapper, CustomLoginButtonWrapper, themes } from './LoginButton.styles';
import useOnLoginClick from './useOnLoginClick';

const LoginButton = ({ size = 'small' }) => {
  const { login } = useThemeContext();
  const { useDefault } = login;

  const Wrapper = useMemo(
    () => (useDefault ? DefaultLoginButtonWrapper : CustomLoginButtonWrapper),
    [useDefault],
  );

  const onClick = useOnLoginClick(true);

  return (
    <Wrapper $size={size}>
      <Button variant="solid-color" onClick={onClick} size={size}>
        Login
      </Button>
    </Wrapper>
  );
};

LoginButton.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small']),
};

export default withTheme(LoginButton, themes, 'login');
