import { useMemo } from 'react';
import { useGetMatchPreviews } from 'tv-selectors/matchPreviews/makeGetMatchPreviews';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';

const useFetchBrowseCredentials = (_activeTab, data) => {
  // setup token fetching
  const matchPreview = useGetMatchPreviews();
  const requstedItems = useMemo(() => {
    const formatMatchRequestArr = matchIds =>
      matchIds.map(id => {
        const previewStreamId = matchPreview[id];
        return { id, streams: [previewStreamId] };
      });

    const formatVideoRequestArr = videoIds => videoIds.map(id => ({ id }));

    // browse page tokens
    const { matches = [], vods = [], editorials = [], highlights = [] } = data || {};
    const videos = editorials.concat(highlights);
    const matchesArr = vods.concat(matches);
    let matchesRequest = [];
    let videosRequest = [];

    if (matchesArr.length > 0) {
      if (Object.entries(matchPreview).length === 0) return {};
      matchesRequest = formatMatchRequestArr(matchesArr);
    }

    if (videos.length > 0) {
      videosRequest = formatVideoRequestArr(videos);
    }
    return {
      matches: matchesRequest,
      videos: videosRequest,
    };
  }, [data, matchPreview]);

  useFetchVideoCredentials(requstedItems);
};

export default useFetchBrowseCredentials;
