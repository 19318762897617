import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetChatColor = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.preferences?.chat?.color);
};

export const useChatColor = () => useMemoizedSelector(makeGetChatColor);

export default makeGetChatColor;
