import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fromEvent } from 'rxjs';
import colors from 'znipe-styles/colors';
import useMessage from 'znipe-chat/src/hooks/useMessage';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import useAvatarImage from 'znipe-chat/src/hooks/useAvatarImage';
import { REMOVED, REPORTED } from 'znipe-chat/src/constants';
import ActionsBar from './ActionsBar/ActionsBar';
import Message from './Message/Message';
import { Entry } from './Entry.styles';
import { OVERLAY_MESSGAE_OPTIONS } from '../Chat/Chat.constants';

type ChatEntryProps = {
  messageId: string;
  isMobile?: boolean;
  parentRef?: React.RefObject<HTMLElement>;
};

type Placement = 'over' | 'under';

const ChatEntry: React.FC<ChatEntryProps> = ({ messageId, isMobile = false, parentRef }) => {
  const [actionsPlacement, setActionsPlacement] = useState<Placement>();
  const ref = useRef<HTMLDivElement>(null);
  const { accountInfo, setModalInfo } = useChatContext();
  const { isModerator, uid } = accountInfo ?? { isModerator: false, uid: undefined };
  const {
    color = colors.grey53,
    avatarId = null,
    username,
    message,
    reported,
    replyTo,
    deleted,
    action,
    userId,
    serverMessageId,
    signifierIds,
    isModerator: isModeratorMessage,
  } = useMessage(messageId) ?? {};

  const clickable = userId !== uid && isMobile;

  const reportHighlight = isModerator && (reported || action === REPORTED);
  const replyHighlight = Boolean(replyTo && uid === replyTo.userId);
  const avatarUrl = useAvatarImage(avatarId, false);
  const badges = useMemo(() => (avatarUrl ? [avatarUrl] : []), [avatarUrl]);

  const onClick = useCallback(() => {
    if (!clickable) return;

    setModalInfo({ modal: OVERLAY_MESSGAE_OPTIONS, props: { messageId } });
  }, [clickable, messageId, setModalInfo]);

  const handleActionPlacement = useCallback(() => {
    if (!parentRef?.current || !ref.current) {
      setActionsPlacement('over');
    } else if (
      parentRef.current.getBoundingClientRect().top + 40 >
      ref.current.getBoundingClientRect().top
    ) {
      setActionsPlacement('under');
    } else {
      setActionsPlacement('over');
    }
  }, [parentRef]);

  useEffect(() => {
    if (!parentRef?.current) return undefined;
    const scrollObserver$ = fromEvent(parentRef.current, 'scroll');

    const subscription = scrollObserver$.subscribe(handleActionPlacement);

    return () => subscription.unsubscribe();
  }, [handleActionPlacement, parentRef]);

  useEffect(() => {
    if (!actionsPlacement && ref.current) handleActionPlacement();
    // We want this to happen every until ref.current is set
  });

  const showActionsBar = useMemo(() => {
    if (isMobile || !uid) return false;
    const isRemoved = action === REMOVED || deleted;
    if (isModerator) return !isRemoved;
    return !isRemoved && action !== REPORTED;
  }, [action, deleted, uid, isModerator, isMobile]);

  // This might happen when a message is being cleared but the element is not removed from the dom yet
  if (!username || !message) return null;

  return (
    <Entry
      ref={ref}
      id={messageId}
      data-testid="chat-entry"
      $reported={reportHighlight}
      $replyHighlight={replyHighlight}
      $clickable={clickable}
      onClick={onClick}
    >
      <Message
        serverMessageId={serverMessageId}
        username={username}
        userId={userId}
        color={color}
        badges={badges}
        message={message}
        isModeratorMessage={isModeratorMessage}
        isModeratorUser={isModerator}
        signifierIds={signifierIds}
        replyData={replyTo}
        deleted={deleted}
        appliedAction={action}
        messageId={messageId}
      />
      {showActionsBar && <ActionsBar messageId={messageId} placement={actionsPlacement} />}
    </Entry>
  );
};

export default memo(ChatEntry);
