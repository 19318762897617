import { forwardRef, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import useThemeContext from 'znipe-hooks/useThemeContext';

const withTheme = <P,>(
  ComposedComponent: React.ComponentType<P>,
  localThemes: { [key: string]: unknown },
  keyName: string,
): React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<unknown>> => {
  const result = forwardRef<unknown, P>((props, ref) => {
    const globalTheme = useThemeContext();
    // biome-ignore lint/correctness/useExhaustiveDependencies: keyName and localThemes will not change
    const theme = useMemo(
      () => ({ [keyName]: localThemes[globalTheme.themeName] || localThemes.default || {} }),
      [globalTheme.themeName],
    );
    return (
      <ThemeProvider theme={theme as never}>
        <ComposedComponent {...props} ref={ref} />
      </ThemeProvider>
    );
  });
  return result;
};

export default withTheme;
