import { LARGE, MEDIUM, SMALL } from 'tv-modules/TopicHeader/TopicHeader.constants';
import { PLAYERS, TEAMS, EVENTS, CHAMPIONS } from 'tv-modules/Filters/Filters.constants';

export const OVERVIEW = 'overview';
export const SCHEDULE = 'schedule';
export const HIGHLIGHTS = 'highlights';
export const VODS = 'vods';
export const ABOUT = 'about';
export const SCROLLLIST = 'scroll-list';

export const TOPIC = 'topic';

export const PLAYER = 'player';
export const EVENT = 'event';
export const TEAM = 'team';
export const CHAMPION = 'champion';
export const RIOTCHAMPION = 'riotChampion';
export const RIOT_CHAMPION = 'riot_champion';

export const singletonPadding = '24px 0';

export const desktopSizes = {
  featuredMatch: MEDIUM,
  matchRerun: MEDIUM,
  vodItem: LARGE,
  highlightItems: 6,
  headerSize: LARGE,
  aboutSize: LARGE,
  genericSize: MEDIUM,
  topicCardSize: SMALL,
};

export const tabletSizes = {
  featuredMatch: MEDIUM,
  matchRerun: MEDIUM,
  vodItem: MEDIUM,
  highlightItems: 4,
  headerSize: MEDIUM,
  aboutSize: MEDIUM,
  genericSize: MEDIUM,
  topicLinkSize: MEDIUM,
};

export const mobileSizes = {
  featuredMatch: SMALL,
  matchRerun: SMALL,
  vodItem: SMALL,
  highlightItems: 3,
  headerSize: SMALL,
  aboutSize: SMALL,
  genericSize: SMALL,
  topicLinkSize: SMALL,
};

export const TAB_MENU = [
  { label: OVERVIEW },
  { label: SCHEDULE },
  { label: VODS },
  { label: HIGHLIGHTS },
  { label: ABOUT },
];

export const CHAMPION_TAB_MENU = [
  { label: OVERVIEW },
  { label: VODS },
  { label: HIGHLIGHTS },
  { label: ABOUT },
];

const emptyFilter = {
  sort: false,
  top: null,
  panel: null,
};

export const filterOptionsDesktop = {
  [PLAYER]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: emptyFilter,
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: [EVENTS],
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: [EVENTS],
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
  },
  [TEAM]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: emptyFilter,
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: [EVENTS],
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: [EVENTS],
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
  },
  [CHAMPION]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: emptyFilter,
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: [EVENTS],
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: [EVENTS],
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
  },
  [EVENT]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS],
    },
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
  },
};

export const filterOptionsMobile = {
  [PLAYER]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: emptyFilter,
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
    },
  },
  [TEAM]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: emptyFilter,
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
    },
  },
  [CHAMPION]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: emptyFilter,
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
    },
  },
  [EVENT]: {
    [OVERVIEW]: emptyFilter,
    [SCHEDULE]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS],
    },
    [ABOUT]: emptyFilter,
    [VODS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
    [HIGHLIGHTS]: {
      sort: false,
      top: null,
      panel: [PLAYERS, TEAMS, CHAMPIONS],
    },
  },
};
