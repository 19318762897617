import ls from 'local-storage';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import isBrowser from 'znipe-utils/web/isBrowser';

const LS_DEVICE_ID = 'device_id';

const getDeviceId = () => {
  if (!isBrowser()) return ''; // Server doesn't care about the id.
  const idFromLs = ls.get(LS_DEVICE_ID);
  if (idFromLs) return idFromLs;
  const generatedId = generateUniqueId();
  ls.set(LS_DEVICE_ID, generatedId);
  return generatedId;
};

export default getDeviceId;
