import produce from 'immer';
import deepmerge from 'deepmerge';
import { SET_MATCHES, SET_MATCH_PLAYER_STREAMS, SET_POPOUT_SOUND_FROM } from 'tv-constants/redux';

export const initialState = {};

const matchesReducer = produce((state, { type, payload = {} }) => {
  switch (type) {
    case SET_MATCHES:
      Object.entries(payload).forEach(([key, value]) => {
        const overwriteMerge = (_destinationArray, sourceArray) => sourceArray;
        const oldState = state[key] || {};
        return (state[key] = deepmerge(oldState, value, { arrayMerge: overwriteMerge }));
      });
      break;

    case SET_MATCH_PLAYER_STREAMS: {
      const { matchId, streams } = payload;
      if (state[matchId]) {
        state[matchId].playerStreams = streams;
      }
      break;
    }

    case SET_POPOUT_SOUND_FROM: {
      const { matchId, streamId } = payload;
      if (state[matchId]) {
        state[matchId].popoutSoundFrom = streamId;
      }
      break;
    }

    default:
  }
}, initialState);

export default matchesReducer;
