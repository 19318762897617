import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 0 8px;
  cursor: pointer;
  @media ${device.mobilePortrait} {
    width: 56px;
    padding: 0 16px;
  }
`;
