import colors from 'znipe-styles/colors';
import gradients, { getThemableGradients } from 'znipe-styles/gradients';
import defaultTheme from './default.theme';
import { Theme } from './theme.types';

const theme: Theme = {
  ...defaultTheme,
  themeName: 'proview',
  mobileOnly: false,
  primaryFont: "'Titillium Web', sans-serif",
  secondaryFont: "'Roboto', sans-serif",

  // Background Colors
  primaryBackgroundColor: colors.pvBackgroundPrimary,
  secondaryBackgroundColor: colors.pvBackgroundSecondary,
  tertiaryBackgroundColor: colors.pvBackgroundTertiary,
  containerStroke: colors.pvContainerStroke,
  highlight: colors.pvHighlight,

  // Accents
  UIAccent: colors.spaceGreen,
  teamOneColor: colors.teamOrder,
  teamTwoColor: colors.teamChaos,

  buttonPrimary: colors.spaceGreen,

  // TODO cleanup
  secondaryBakgroundFade: gradients.proviewBg2Fade,
  containerGradientBackground: gradients.proviewSubtleContainer,
  videoBackground: 'https://assets.znipe.tv/anna-concepts/bg_lol_3.mp4',
  // Old styles
  teamOneColorSecondary: colors.proviewTeamOrder,
  teamTwoColorSecondary: colors.proviewTeamChaos,
  primaryTextColor: colors.white,
  secondaryTextColor: colors.grey75,
  overlayBackground: 'rgba(0,0,0,0.75)',
  borderColor: colors.riotBackgroundRule,
  accentGold: colors.riotAccentGold,
  healthColor: '#32E818',
  manaColor: '#0089FF',
  progressBarBackground: colors.riotBackgroundRule,
  progressBarBaseHandleColor: colors.riotTextPrimary,
  hoverColor: colors.riotAccentBlue,
  navMenuPadding: '0px',
  navMenuBackgroundColor: colors.black,
  navMenuItemColorActive: colors.riotAccentBlue,
  navMenuItemColorActiveText: colors.white,
  navMenuItemColor: colors.riotTextSecondary,
  navMenuItemFontWeightActive: 700,
  navMenuItemFontWeight: 700,
  navMenuItemFontSizeLarge: '14px',
  navMenuItemFontSizeSmall: '12px',
  navMenuItemCaps: true,
  navMenuItemMargin: '0px 7px',
  avatarXSmallSize: '30px',
  avatarSmallSize: '40px',
  avatarSmallSizeRound: '34px',
  avatarMediumSize: '50px',
  avatarLargeSize: '60px',
  avatarCustomSize: '45px',
  avatarBackgroundColor: colors.onyxGrey,
  avatarLevelTextColor: colors.riotAccentGold,
  avatarLevelFont: "'Roboto', sans-serif",
  avatarLevelCircleBorderColor: colors.black,
  avatarLevelCircleBackgroundColor: colors.riotBackgroundSecondary,
  shouldZoomInAvatarImage: false,
  gatTeamOneAvatarBorderColor: colors.riotTeamOrderPrimary,
  gatTeamTwoAvatarBorderColor: colors.riotTeamChaosPrimary,
  ruleBackgroundColor: colors.riotBackgroundRule,
  articleBackgroundColor: colors.riotBackgroundArticle,
  blue: colors.riotAccentBlue,
  gold: colors.riotAccentGold,
  orange: colors.riotAccentOrange,
  settingsActiveColor: colors.riotAccentBlue,
  scoreboardIconColor: colors.riotAccentGold,
  disabledIconsColor: colors.riotBackgroundRule,
  disabledTextColor: '#666E75',
  optionsMenuHeaderColor: colors.white,
  optionsMenuActiveColor: colors.riotAccentBlue,
  playerStatsButtonArrowDisplay: 'none',
  playerStatsTitlePadding: '10px',
  playerStatsRowHeight: '26px',
  playerStatsWrapperPadding: '0',
  chatBackgroundColor: colors.znipeBackgroundTertiary,
  chatFooterBackgroundColor: colors.znipeBackgroundPrimary,
  chatTimeStampBorderColor: colors.znipeContainerStroke,
  chatTimeStampTextColor: colors.grey90,
  chatFooterColor: colors.znipeIconBlue,
  gatCategory: colors.goldYellow,
  gatTextColor: colors.white,
  popOverAreaText: colors.white,
  popOverBackground: colors.riotBackgroundSecondary,
  kdaSpacing: '0px 2px',
  kdaLineHeight: '16px',
  roleIconColor: colors.riotAccentGold,

  /* bar chart */
  barChartLabelTextColor: colors.white,
  barChartFontFamily: 'Titillium Web',
  barChartFontSize: 12,
  barChartGridCount: 3,
  barChartGridOpacity: 0.1,

  /* line graph */
  lineGraphAxisTickColor: colors.white,
  lineGraphAxisTicksLineColor: 'transparent',
  lineGraphAxisTicksTextColor: colors.white,
  lineGraphGridLineColor: colors.white,
  lineGraphGridLineOpacity: 0.1,
  lineGraphAreaOpacity: 0.3,
  lineGraphLegendTextColor: colors.white,
  lineGraphTooltipBackgroundColor: colors.onyxGrey,

  /* Stats runes */
  runesLabelColor: colors.riotBackgroundArticle,
  runeVerticalLineBackgroundColor: colors.riotBackgroundSecondary,
  runeVerticalLineBorderColor: colors.riotBackgroundRule,

  /* overlay */
  overlayBackgroundColor: colors.riotBackgroundSecondary,
  overlayBackgroundOpacity: 0.6,

  /* Scrollbar */
  scrollbarColor: colors.riotBackgroundPrimary,

  /* carousel */
  carouselNavigatonDotColor: colors.spaceGreen,

  /* gradient */
  backgroundAvsB: `linear-gradient(180deg, ${colors.riotBackgroundSecondary} 0%, ${colors.black} 100%)`,
  teamABackground: gradients.teamOrder,
  teamBBackground: gradients.teamChaos,
  mastheadGradient: colors.black,

  /* onboarding */
  onboardingStroke: colors.goldYellow,
};

export default { ...theme, ...getThemableGradients(theme) } as Theme; // @TODO remove type assertion when getThemableGradinets has proper type
