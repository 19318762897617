import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import firebase from 'firebase/app';
import logger from 'znipe-logger';
import { ERROR, LOADING, SUCCESS } from '../constants';
import setPersistentState from '../utils/setPersistentState';

const GoogleAuthButton = ({ state = '', onStateChange, persistent = false }) => {
  const authenticateWithGoogle = useCallback(() => {
    if (state === LOADING) return;
    onStateChange(LOADING);

    setPersistentState(persistent)
      .then(() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth().signInWithPopup(provider);
      })
      .then(() => onStateChange(SUCCESS))
      .catch(({ code, message }) => {
        onStateChange(ERROR);
        logger.log('failed to login', code, message);
        firebase.auth().signOut();
      });
  }, [state, onStateChange, persistent]);

  return (
    <Button
      variant="solid-color"
      size="small"
      solidColor="white"
      textColor="grey30"
      type="button"
      onClick={authenticateWithGoogle}
      icon="googleLogo"
    >
      &nbsp;Continue with Google
    </Button>
  );
};

GoogleAuthButton.propTypes = {
  state: PropTypes.oneOf([ERROR, LOADING, SUCCESS, '']),
  onStateChange: PropTypes.func.isRequired,
  persistent: PropTypes.bool,
};

export default GoogleAuthButton;
