import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetGameCompetitorsLineup from 'tv-selectors/games/makeGetGameCompetitorsLineups';

const makeGetPlayerId = () => {
  const getProps = makeGetProps();
  const getGameCompetitorsLineup = makeGetGameCompetitorsLineup();
  return createSelector([getProps, getGameCompetitorsLineup], (props, lineups) => {
    // get playerId from props
    const selectedPlayerId = props.playerId || props.playerID;
    if (selectedPlayerId) return selectedPlayerId;

    // get playerId using streamId
    const selectedStreamId = props.streamId || props.streamID;
    if (selectedStreamId) {
      const selectedLineup = lineups.find(lineup => lineup.streamId === selectedStreamId) || {};
      return selectedLineup.playerId || '';
    }
    return '';
  });
};

export const useGetPlayerId = props => useMemoizedGqlSelector(makeGetPlayerId, props);

export default makeGetPlayerId;
