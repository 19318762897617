import { memo } from 'react';
import PropTypes from 'prop-types';
import SubMenu from './SubMenu/SubMenu';

const SubMenuContainer = ({
  matchId,
  selected,
  qualityOptions,
  languages,
  menu,
  onClickHeader,
  onClickOptionItem = () => {},
  isVisible = false,
  qualityLabels = ['Auto', 'Very high', 'High', 'Medium', 'Low'],
}) => {
  switch (menu) {
    case 'quality':
      return (
        <SubMenu
          matchId={matchId}
          title={menu}
          optionItems={qualityOptions.map(({ bandwidth }, index) => ({
            id: 'quality',
            label: qualityLabels[index],
            value: bandwidth,
          }))}
          onClickHeader={onClickHeader}
          onClickOptionItem={onClickOptionItem}
          selected={selected}
          testid="quality"
          visible={isVisible}
        />
      );
    case 'sound':
      return (
        <SubMenu
          matchId={matchId}
          title={menu}
          onClickHeader={onClickHeader}
          testid="sound"
          visible={isVisible}
        />
      );
    case 'caster language':
      return (
        <SubMenu
          matchId={matchId}
          title={menu}
          optionItems={languages.map(language => ({
            id: 'caster-language',
            label: language,
            value: language,
          }))}
          onClickHeader={onClickHeader}
          onClickOptionItem={onClickOptionItem}
          selected={selected}
          testid="caster-language"
          visible={isVisible}
        />
      );
    default:
      return null;
  }
};

SubMenuContainer.propTypes = {
  matchId: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  qualityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      bandwidth: PropTypes.number,
    }),
  ).isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  menu: PropTypes.string,
  onClickHeader: PropTypes.func,
  onClickOptionItem: PropTypes.func,
  isVisible: PropTypes.bool,
  qualityLabels: PropTypes.arrayOf(PropTypes.string),
};

export default memo(SubMenuContainer);
