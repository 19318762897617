import { useEffect } from 'react';
import firebase from 'firebase/app';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useGetSelectedMatchGameIndex } from 'tv-selectors/match/makeGetSelectedMatchGameIndex';
import { setGameGatId } from 'tv-actions/games';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';

const noop = () => {};

const getGatId =
  gameId =>
  ({ games = {} }) =>
    games[gameId]?.gatId ?? null;

const useGatId = (customMatchId, customGameIndex) => {
  const dispatch = useGqlStoreDispatch();

  const { m } = useQueryObject();
  const matchId = customMatchId ?? m;

  const selectedMatchGameIndex = useGetSelectedMatchGameIndex({
    matchId,
    selectedMatchGameIndex: customGameIndex,
  });

  const gameId = useGetGameId({
    matchId,
    selectedMatchGameIndex,
  });

  const gatId = useGqlSelector(getGatId(gameId));

  useEffect(() => {
    if (!(matchId && gameId) || gatId !== null) return noop;
    const ref = firebase.database().ref(`/matches/${matchId}/games/${gameId}/gatId`);
    ref.on('value', snap => dispatch(setGameGatId(gameId, snap.val())));
    return () => ref.off();
  }, [matchId, gameId, gatId, dispatch]);

  return gatId;
};

export default useGatId;
