import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import Typography from 'znipe-elements/general/Typography/Typography';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const PaymentHeader = ({ children }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const typographyType = useMemo(
    () => (isTabletOrGreater ? 'heading-l' : 'heading-m'),
    [isTabletOrGreater],
  );
  return (
    <Container data-testid="styled-header">
      <Typography type={typographyType}>{children}</Typography>
    </Container>
  );
};

PaymentHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaymentHeader;
