export const SET_TIME_INFO = 'SET_TIME_INFO';
export const SET_TIME_TOOLTIP = 'SET_TIME_TOOLTIP';
export const RESET_REDUCER = 'RESET_REDUCER';

export const initialState = {
  isLiveStream: false,
  labels: {
    timeLabel: '00:00 / 00:00',
    tooltip: '00:00',
  },
  timeInfo: {
    currentTime: 0,
    seekRange: {},
  },
};

export const createInitialState = state => ({ ...initialState, ...state });

export default (state, { type, payload }) => {
  switch (type) {
    case SET_TIME_INFO:
      return {
        ...state,
        timeInfo: {
          ...payload,
        },
      };
    case SET_TIME_TOOLTIP:
      return {
        ...state,
        isLiveStream: payload.liveStream,
        isWatchingLive: payload.watchingLive,
        timeLabel: payload.label,
      };
    case RESET_REDUCER:
      return initialState;
    default:
      return state;
  }
};
