import deepmerge from 'deepmerge';
import { handleActions } from 'redux-actions';
import gaTrackEvent from 'tv-utils/gaTrackEvent';

import {
  NUMBER_OF_ALLOWED_PANES,
  SET_AUTOPLAY,
  SET_CASTER_VOLUME_RATIO,
  SET_CONTROL,
  SET_CONTROL_EXTERNAL_VIDEO_MUTE,
  SET_MUTE,
  SET_PANES,
  SET_POV_VOLUME_RATIO,
  SET_QUALITY,
  SET_VOLUME,
  TOGGLE_CASTER_AUDIO,
  TOGGLE_MUTE,
  TOGGLE_POV_AUDIO,
  TOGGLE_ZNIPE_PING,
} from 'tv-constants/oldRedux';

export const initialState = {
  volume: 0.5,
  mute: false,
  quality: '-1',
  showZnipePing: true,
  split: 1,
  casterVolumeRatio: 1,
  povVolumeRatio: 1,
  povMute: false,
  casterMute: true,
  externalVideoMute: true,
  autoplayEnabled: true,
};

export default handleActions(
  {
    [SET_CONTROL]: (state, { control }) =>
      deepmerge(state, {
        ...control,
      }),
    [SET_VOLUME]: (state, action) => {
      const { value, ignoreMuting } = action;
      return deepmerge(state, {
        volume: action.value,
        mute: ignoreMuting ? state.mute : value === 0,
      });
    },
    [SET_QUALITY]: (state, action) =>
      deepmerge(state, {
        quality: action.value,
      }),
    [TOGGLE_MUTE]: (state, action) => {
      if (action.videoID) {
        const mute = !state[action.videoID]?.mute ?? false;
        return deepmerge(state, {
          [action.videoID]: {
            mute,
          },
        });
      }

      const mute = !state.mute;
      return { ...state, mute };
    },
    [TOGGLE_ZNIPE_PING]: (state, action) => {
      const showZnipePing = action.value !== null ? action.value : !state.showZnipePing;
      const gaMessage = showZnipePing ? 'Enable znipe ping' : 'Disable znipe ping';
      gaTrackEvent('Click', gaMessage);
      return { ...state, showZnipePing };
    },
    [SET_MUTE]: (state, action) => {
      const { videoID } = action;
      if (videoID) {
        const video = state[videoID] ?? {};
        video.mute = action.value;
        return { ...state, [videoID]: video };
      }
      return { ...state, mute: action.value };
    },
    [SET_CONTROL_EXTERNAL_VIDEO_MUTE]: (state, action) => ({
      ...state,
      externalVideoMute: action.value,
    }),
    [SET_PANES]: (state, action) => {
      const oldSplit = state.split;
      if (oldSplit) {
        gaTrackEvent('Click', `Changing to view ${action.panes}`);
      }

      return { ...state, split: action.panes };
    },
    [NUMBER_OF_ALLOWED_PANES]: (state, action) => ({
      ...state,
      numberOfAllowedPanes: action.numberOfAllowedPanes,
    }),
    [SET_CASTER_VOLUME_RATIO]: (state, { value }) => ({
      ...state,
      casterVolumeRatio: value,
    }),
    [SET_POV_VOLUME_RATIO]: (state, { value }) => ({
      ...state,
      povVolumeRatio: value,
    }),
    [TOGGLE_CASTER_AUDIO]: (state, { value }) => ({
      ...state,
      casterMute: typeof value === 'boolean' ? value : !state.casterMute,
    }),
    [TOGGLE_POV_AUDIO]: (state, { value }) => ({
      ...state,
      povMute: typeof value === 'boolean' ? value : !state.povMute,
    }),
    [SET_AUTOPLAY]: (state, { value }) => ({
      ...state,
      autoplayEnabled: value,
    }),
  },
  initialState,
);
