import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import withTheme from 'znipe-themes/hocs/withTheme';
import themes from './Link.themes';

const linkStyle = css`
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: ${({ theme, $textColor }) => $textColor || theme.link.color};
  &:focus {
    outline: auto 2px ${({ theme }) => theme.link.color};
    outline-offset: 5px;
  }
  &:hover,
  &:active {
    font-weight: 600;
  }
`;

const HideTextDecoration = css`
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const WithIcon = ({ theme }) => css`
  color: ${theme.link.linkColor};
  &:focus {
    outline: auto 2px ${theme.link.linkColor};
  }
  font-size: 17px;
  font-weight: 400;
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-template-rows: 1fr;
  gap: 0 15px;
`;

const StyledLink = styled(({ withIcon, hideUnderline, $disabled, ...rest }) => (
  <ReactRouterLink {...rest} />
))`
  ${linkStyle}
  ${props => props.withIcon && WithIcon}
  ${props => props.hideUnderline && HideTextDecoration}
  ${props =>
    props.disabled
      ? css`
          pointer-events: none;
        `
      : css`
          cursor: pointer;
        `}
`;

const StyledAnchor = styled.a`
  ${linkStyle}
  ${props => props.withIcon && WithIcon}
  ${props => props.hideUnderline && HideTextDecoration}
`;

const Link = ({
  to = '',
  href = '',
  children,
  withIcon = false,
  'data-testid': testId,
  hideUnderline = false,
  disabled = false,
  textColor = '',
  replace = false,
  target = '',
}) => {
  if (href) {
    return (
      <StyledAnchor
        href={href}
        hideUnderline={hideUnderline}
        withIcon={withIcon}
        $textColor={textColor}
        target={target}
        data-testid={testId || 'anchor-link'}
      >
        {children}
      </StyledAnchor>
    );
  }

  return (
    <StyledLink
      to={to}
      hideUnderline={hideUnderline}
      withIcon={withIcon}
      disabled={disabled}
      $textColor={textColor}
      data-testid={testId || 'router-link'}
      replace={replace}
    >
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  textColor: PropTypes.string,
  'data-testid': PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withIcon: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
  replace: PropTypes.bool,
  target: PropTypes.string,
};

export default withTheme(memo(Link), themes, 'link');
