const millisecondsPerDay = 24 * 60 * 60 * 1000;

const treatAsUTC = date => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

const getDayDifference = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;
  const resetStartDate = new Date(startDate).setUTCHours(0, 0, 0, 0);
  const resetEndDate = new Date(endDate).setUTCHours(0, 0, 0, 0);
  return Math.floor((treatAsUTC(resetEndDate) - treatAsUTC(resetStartDate)) / millisecondsPerDay);
};

export default getDayDifference;
