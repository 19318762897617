import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPage from 'tv-selectors/page/makeGetPage';

const emptyObject = {};

const makePageCursors = () => {
  const getPage = makeGetPage();
  return createSelector([getPage], page => page?.cursors ?? emptyObject);
};

export const useGetPageCursors = props => useMemoizedGqlSelector(makePageCursors, props);

export default makePageCursors;
