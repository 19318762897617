import { memo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Description from 'tv-modules/Payment/SharedStyles/Description';
import CelebrationImage from 'tv-modules/Payment/CelebrationImage/CelebrationImage';
import Header from 'tv-modules/Payment/SharedStyles/Header';
import useSelectionDescription from 'tv-routes/Subscribe/hooks/useSelectionDescription';
import { Wrapper, CelebrationImageWrapper } from './SuccessPage.styles';
import themes from './SuccessPage.themes';

const SuccessPage = ({
  header = '',
  image: Image,
  buttonLabel = 'Start watching',
  to,
  productId = '',
  promoCode = false,
  description = '',
}) => {
  const offerDescription = useSelectionDescription(productId, promoCode);

  return (
    <Wrapper data-testid="success-page">
      {header && <Header>{header}</Header>}

      {description && <Description data-testid="description">{description}</Description>}
      {promoCode && productId && (
        <Description data-testid="offer-description">{offerDescription}</Description>
      )}

      {Image ? (
        <Image />
      ) : (
        <CelebrationImageWrapper>
          <CelebrationImage />
        </CelebrationImageWrapper>
      )}
      <Button data-testid="button-link" variant="solid-color" size="small" to={to}>
        {buttonLabel}
      </Button>
    </Wrapper>
  );
};

SuccessPage.propTypes = {
  header: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  image: PropTypes.func,
  to: PropTypes.string.isRequired,
  productId: PropTypes.string,
  promoCode: PropTypes.bool,
};

export default withTheme(memo(SuccessPage), themes, 'successPage');
