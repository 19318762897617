import colors from 'znipe-styles/colors';

export default {
  proview: {
    color: colors.riotBackgroundRule,
    backgroundColor: colors.paperGrey,
    activeBackgroundColor: colors.grey2,
    borderColor: colors.regsGrey,
    activeBorderColor: colors.midGrey,
    iconColor: colors.riotTextSecondary,
  },
  default: {
    color: colors.riotBackgroundRule,
    backgroundColor: colors.paperGrey,
    activeBackgroundColor: colors.grey2,
    borderColor: colors.regsGrey,
    activeBorderColor: colors.midGrey,
    iconColor: colors.riotTextSecondary,
  },
};
