import { useEffect } from 'react';
import PropTypes from 'prop-types';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import { timer } from 'rxjs';

import {
  Container,
  SVGElement,
  SVGCircle,
  CircleBackground,
  ContentWrapper,
} from './ReplayAnimation.styles';

const ReplayAnimation = ({
  iconSize = 40,
  animationLength = 10,
  onClick = () => {},
  onAnimationComplete,
}) => {
  useEffect(() => {
    const source = timer(animationLength * 1000);
    const subscribe = source.subscribe(onAnimationComplete);
    return () => subscribe.unsubscribe();
  }, [animationLength, onAnimationComplete]);

  return (
    <Container data-testid="replay-animation" onClick={onClick}>
      <CircleBackground>
        <SVGElement viewBox="6 6 150 150">
          <SVGCircle
            data-testid="replay-animation-circle"
            r="69.85699"
            cy="81"
            cx="81"
            strokeWidth="10"
            stroke={colors.grey65}
            fill="none"
            animationLength={animationLength}
          />
        </SVGElement>
        <ContentWrapper>
          <Icon type="watchNext" size={iconSize} />
        </ContentWrapper>
      </CircleBackground>
    </Container>
  );
};

ReplayAnimation.propTypes = {
  iconSize: PropTypes.number,
  animationLength: PropTypes.number,
  onAnimationComplete: PropTypes.func,
  onClick: PropTypes.func,
};

export default ReplayAnimation;
