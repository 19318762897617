import { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import useTheme from 'tv-hooks/useTheme';
import AvatarInfo from 'znipe-elements/data-display/AvatarInfo/AvatarInfo';
import Icon from 'znipe-elements/general/Icon/Icon';
import Avatar from 'znipe-elements/data-display/Avatar/v2/Avatar';
import PlayerAvatar from 'znipe-elements/data-display/Avatar/v2/PlayerAvatar/PlayerAvatar';
import ChampionAvatar from 'znipe-elements/data-display/Avatar/v2/ChampionAvatar/ChampionAvatar';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import {
  PLAYER,
  CHAMPION,
  TEAM,
  LEAGUE,
  EVENT,
  XSMALL,
  SMALL,
  MEDIUM,
  VERTICAL,
  HORIZONTAL,
  defaultPlayerImageUrl,
  deprecatedDefaultPlayerImage,
} from '../constants';
import {
  Container,
  HorizontalWrapper,
  VerticalWrapper,
  AvatarWrapper,
  VerticalTitle,
  BackgroundLayer,
} from './SearchResultItem.styles';

const avatarInfoSize = {
  [SMALL]: XSMALL,
  [MEDIUM]: SMALL,
};

const AnimatedBackground = animated(BackgroundLayer);

const SearchResultItem = ({
  imageUrl = '',
  title,
  subtitle = '',
  icon = '',
  type,
  direction = VERTICAL,
  size = SMALL,
  onClick = null,
  hideLabel = false,
}) => {
  const { containerStroke } = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const isHorizontal = direction === HORIZONTAL;
  const useFallbackAvatar = !imageUrl || imageUrl === deprecatedDefaultPlayerImage;
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = useSpring({
    height: isHovered ? '100%' : '0%',
    config: { duration: 60 },
    immediate: prefersReducedMotion,
  });

  const avatarElement = useFallbackAvatar ? (
    <>
      {type === PLAYER && <PlayerAvatar imageUrl={defaultPlayerImageUrl} />}
      {type === CHAMPION && <Icon type="champion" fillColor={containerStroke} />}
      {type === TEAM && <Icon type="fallbackTeamLogo" fillColor={containerStroke} />}
      {type === LEAGUE && <Icon type="globe" fillColor={containerStroke} />}
    </>
  ) : (
    <>
      {type === PLAYER && <PlayerAvatar imageUrl={imageUrl} />}
      {type === CHAMPION && <ChampionAvatar imageUrl={imageUrl} shape="circle" hasBorder />}
      {(type === TEAM || type === LEAGUE || type === EVENT) && <Avatar imageUrl={imageUrl} />}
    </>
  );

  const itemElement = isHorizontal ? (
    <HorizontalWrapper size={size} data-testid="avatar-wrapper">
      <AvatarInfo
        size={avatarInfoSize[size]}
        title={title}
        icon={icon}
        subtitle={subtitle}
        tagLabel={hideLabel ? '' : type}
      >
        {avatarElement}
      </AvatarInfo>
    </HorizontalWrapper>
  ) : (
    <VerticalWrapper size={size}>
      <AvatarWrapper size={size} data-testid="avatar-wrapper">
        {avatarElement}
      </AvatarWrapper>
      <VerticalTitle text={title} size={size}>
        {title}
      </VerticalTitle>
    </VerticalWrapper>
  );

  const backgroundElement = useMemo(() => {
    if (isHorizontal) return null;
    return <AnimatedBackground style={animation} />;
  }, [isHorizontal, animation]);

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <Container
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isHorizontal={isHorizontal}
      as={onClick ? 'button' : undefined}
      data-testid="result-item-container"
    >
      {itemElement}
      {backgroundElement}
    </Container>
  );
};

SearchResultItem.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf([SMALL, MEDIUM]),
  type: PropTypes.oneOf([PLAYER, CHAMPION, TEAM, LEAGUE]).isRequired,
  direction: PropTypes.oneOf([VERTICAL, HORIZONTAL]),
  onClick: PropTypes.func,
  hideLabel: PropTypes.bool,
};

export default memo(SearchResultItem);
