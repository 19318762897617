import { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import logger from 'znipe-logger';
import OverlaySettings from 'tv-modules/OverlaySettings/OverlaySettings';
import { useGetGameLanguages } from 'tv-selectors/games/makeGetGameLanguages';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
// import { useGetIsCompactPlayer } from 'tv-selectors/ui/makeGetIsCompactPlayer';
import { useIsIOS } from 'tv-selectors/deviceInfo/makeGetIsIOS';
import screenfull from 'screenfull';
import colors from 'znipe-styles/colors';
import useGatId from 'tv-hooks/useGatId';
import usePauses from 'tv-modules/GAT/hooks/usePauseMarkers';
import useSeekBarMarkers from 'tv-modules/GAT/hooks/useSeekBarMarkers';
import useListenToFullscreenEvent from 'tv-hooks/useListenToFullscreenEvent';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import { useGetSelectedMatchGameIndex } from 'tv-selectors/match/makeGetSelectedMatchGameIndex';
import throttle from 'lodash.throttle';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import ControlButton from 'znipe-watch/src/modules/PlayerControls/ControlButtons/ControlButton/ControlButton';
import PlayPauseButton from './components/PlayPauseButton/PlayPauseButton';
import RewindTenSecondsButton from './components/RewindTenSecondsButton/RewindTenSecondsButton';
import SkipTenSecondsButton from './components/SkipTenSecondsButton/SkipTenSecondsButton';
import TimeLabel from './components/TimeLabel/TimeLabel';
import SoundButton from './components/SoundButton/SoundButton';
import SeekBar from './components/SeekBar/SeekBar';
import BufferAnimation from './components/BufferAnimation/BufferAnimation';
import getTimeLabel from './utils/getTimeLabel';
import controlsReducer, {
  createInitialState,
  RESET_REDUCER,
  SET_TIME_INFO,
  SET_TIME_TOOLTIP,
} from './Controls.reducer';

import {
  Container,
  ControlGroup,
  ControlsWrapper,
  // Seperator,
  SettingsWrapper,
  // ZnipeIconWrapper,
} from './Controls.styles';

export const { white: iconColor } = colors;

const WEBSITE_URL = 'https://beta.znipe.tv/';

// @TODO Remove memo after adding https://babeljs.io/docs/en/babel-plugin-transform-react-constant-elements.html
const ZnipeIconWithLink = memo(({ isMobile }) => {
  const logoSize = isMobile ? 10 : 18;
  return (
    <a
      href={WEBSITE_URL}
      target="_blank"
      rel="noreferrer noopener"
      data-testid="external-link-logo"
    >
      <Icon type="poweredByZnipeLogo" size={logoSize} />
    </a>
  );
});

const getInitialTimeInfo = playerRef => {
  const player = playerRef?.current;
  if (!player) return {};
  const currentTime = player.getCurrentTime();
  if (isNaN(currentTime)) return {};
  const seekRange = player.getSeekRange();
  return { currentTime, seekRange };
};

const reducerInit = timeInfo => createInitialState({ timeInfo });

const Controls = ({
  visible = false,
  playerRef = playerDefaultProps,
  playerContainerRef = {
    current: {},
  },
  togglePersitentHoverState = () => {},
  matchId = '',
  hasFinishedPlaying = false,
}) => {
  const [state, dispatch] = useReducer(controlsReducer, getInitialTimeInfo(playerRef), reducerInit);
  const { timeInfo, isLiveStream, isWatchingLive, timeLabel } = state;
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const isClosingSettings = useRef(false);
  const isLessThanDesktop = !useGetIsDesktopOrGreater();
  const languages = useGetGameLanguages();
  const isIOS = useIsIOS();
  // const isCompactPlayer = useGetIsCompactPlayer();
  const matchGameIndex = useGetSelectedMatchGameIndex({ matchId });

  useListenToFullscreenEvent(playerContainerRef, setIsFullscreen);

  const handleTimeLabels = useCallback(
    player => {
      const isPlayerLive = player.isLive();
      const { currentTime, seekRange } = timeInfo || {};
      const { end } = seekRange || {};
      const watchingLive = currentTime >= end - 10;
      const label = getTimeLabel(isPlayerLive, timeInfo);
      dispatch({
        type: SET_TIME_TOOLTIP,
        payload: {
          liveStream: isPlayerLive,
          watchingLive,
          label,
        },
      });
    },
    [timeInfo],
  );

  const handleHoverToggle = useCallback(
    (shouldHover = true) => {
      if (!isSettingsOpen) {
        togglePersitentHoverState(shouldHover);
      }
    },
    [isSettingsOpen, togglePersitentHoverState],
  );

  const handleFullscreenToggle = useCallback(() => {
    if (screenfull.isEnabled) {
      const { current } = playerContainerRef;
      if (!current) return;
      screenfull.toggle(current).then(() => {
        /*
        Safari had issues with triggering handleHoverToggle function on onMouseLeave after going into fullscreen.
        While this behaviour was working on chrome, it was buggy on safari so we need to trigger the function manually
        when toggling fullscreen to ensure that the visiblity is changed as expected.
      */
        handleHoverToggle(false);
      });
      if (isLessThanDesktop && global?.screen?.orientation?.lock) {
        global.screen.orientation
          .lock('landscape-primary')
          .catch(() => logger.error('Orientation lock not supported'));
      }
    } else if (isIOS) {
      const { current } = playerRef;
      if (!current) return;
      const masterVideo = current.getMasterVideoElement();
      if (masterVideo.webkitEnterFullscreen) masterVideo.webkitEnterFullscreen();
    }
  }, [isIOS, playerContainerRef, isLessThanDesktop, playerRef, handleHoverToggle]);

  const handleSettingsClick = useCallback(
    e => {
      if (e) e.stopPropagation();
      if (!isClosingSettings.current) {
        setIsSettingsOpen(!isSettingsOpen);
        togglePersitentHoverState(!isSettingsOpen);
      }
    },
    [isSettingsOpen, togglePersitentHoverState],
  );

  const handleBuffering = useCallback(e => {
    const { buffering: playerIsBuffering = false } = e.buffering;
    setIsBuffering(playerIsBuffering);
  }, []);

  const onOutsideClick = useCallback(() => {
    setIsSettingsOpen(!isSettingsOpen);
    togglePersitentHoverState(!isSettingsOpen);
    isClosingSettings.current = !isClosingSettings.current;
    setTimeout(() => {
      isClosingSettings.current = !isClosingSettings.current;
    }, 100);
  }, [isSettingsOpen, togglePersitentHoverState]);

  const settingsRef = useOutsideClick(onOutsideClick);

  const gatId = useGatId(matchId);
  const { seekRange = {} } = timeInfo;
  const { start = 0, end = 0 } = seekRange;
  const gamePauses = usePauses(gatId, start, end);
  const markers = useSeekBarMarkers(gatId, start, end);

  useEffect(() => {
    if (!screenfull.isEnabled) return () => {};
    const handleScreenfullChange = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };

    screenfull.on('change', handleScreenfullChange);

    return () => screenfull.off('change', handleScreenfullChange);
  }, []);

  useEffect(() => {
    const handleOnEscClick = event => {
      if (!screenfull.isFullscreen) return;
      if (event.keyCode === 27) {
        handleFullscreenToggle();
      }
    };
    window.addEventListener('keydown', handleOnEscClick);

    return () => window.removeEventListener('keydown', handleOnEscClick);
  }, [handleFullscreenToggle]);

  // eslint-disable-next-line no-shadow
  const updatePlayerTime = useCallback(({ currentTime, seekRange }) => {
    const roundedTime = Math.floor(currentTime);
    dispatch({
      type: SET_TIME_INFO,
      payload: { currentTime: roundedTime, seekRange },
    });
  }, []);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;
    handleTimeLabels(player);
  }, [handleTimeLabels, playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return () => {};
    const handleTimeUpdate = throttle(updatePlayerTime, 900);
    player.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      player.removeEventListener('timeupdate', handleTimeUpdate);
      handleTimeUpdate.cancel();
    };
  }, [playerRef, updatePlayerTime]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return () => {};
    player.addEventListener('buffering', handleBuffering);
    return () => {
      player.removeEventListener('buffering', handleBuffering);
    };
  }, [handleBuffering, playerRef]);

  useEffect(() => {
    if (!visible) {
      setIsSettingsOpen(false);
    }
  }, [visible]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Reset on game or match change
  useEffect(
    () => () => {
      dispatch({ type: RESET_REDUCER });
    },
    [matchGameIndex, matchId],
  );

  if (isLessThanDesktop) {
    return (
      <Container $isVisible={visible} isMobile>
        <ControlsWrapper isMobile>
          <ControlGroup>
            <TimeLabel
              isLiveStream={isLiveStream}
              isWatchingLive={isWatchingLive}
              size="small"
              timeLabel={timeLabel}
              playerRef={playerRef}
            />
            <BufferAnimation isActive={isBuffering} />
          </ControlGroup>
          <ControlGroup $isMobile>
            {/*  {isCompactPlayer && isFullscreen ? ( */}
            {/*    <ZnipeIconWithLink isMobile /> */}
            {/*  ) : ( */}
            {/*    <ZnipeIconWrapper> */}
            {/*      <Icon type="poweredByZnipeLogo" /> */}
            {/*    </ZnipeIconWrapper> */}
            {/*  )} */}
            <ControlButton
              toolTip={isFullscreen ? 'Exit fullscreen' : 'Fullscreen'}
              icon={isFullscreen ? 'fullscreenExit' : 'fullscreen'}
              onClick={handleFullscreenToggle}
              color={iconColor}
              isMobile
            />
          </ControlGroup>
        </ControlsWrapper>
        <SeekBar
          matchId={matchId}
          playerRef={playerRef}
          timeInfo={timeInfo}
          isFullscreen={isFullscreen}
          isLiveStream={isLiveStream}
          isWatchingLive={isWatchingLive}
          pauses={gamePauses}
          markers={markers}
          isMobile
        />
      </Container>
    );
  }

  return (
    <Container
      $isVisible={visible}
      onMouseEnter={() => handleHoverToggle(true)}
      onFocus={() => handleHoverToggle(true)}
      onMouseLeave={() => handleHoverToggle(false)}
      data-testid="controls-bar"
      isFullscreen={isFullscreen}
    >
      <SeekBar
        matchId={matchId}
        playerRef={playerRef}
        timeInfo={timeInfo}
        isLiveStream={isLiveStream}
        isWatchingLive={isWatchingLive}
        pauses={gamePauses}
        markers={markers}
      />
      <ControlsWrapper>
        <ControlGroup>
          <PlayPauseButton playerRef={playerRef} hasFinishedPlaying={hasFinishedPlaying} />
          <RewindTenSecondsButton playerRef={playerRef} />
          <SkipTenSecondsButton playerRef={playerRef} />
          <SoundButton playerRef={playerRef} matchId={matchId} />
          <TimeLabel
            isLiveStream={isLiveStream}
            isWatchingLive={isWatchingLive}
            timeLabel={timeLabel}
            playerRef={playerRef}
          />
          <BufferAnimation isActive={isBuffering} />
        </ControlGroup>
        <ControlGroup>
          {/* {isCompactPlayer ? <ZnipeIconWithLink /> : <Icon type="poweredByZnipeLogo" size={18} />} */}
          {/* <Seperator /> */}
          {isSettingsOpen && (
            <SettingsWrapper ref={settingsRef}>
              <OverlaySettings
                matchId={matchId}
                languages={languages}
                visible={isSettingsOpen}
                playerRef={playerRef}
              />
            </SettingsWrapper>
          )}
          <ControlButton
            toolTip="Settings"
            icon="settings"
            onClick={handleSettingsClick}
            isActive={isSettingsOpen}
          />
          <ControlButton
            toolTip={isFullscreen ? 'Exit fullscreen' : 'Fullscreen'}
            icon={isFullscreen ? 'fullscreenExit' : 'fullscreen'}
            onClick={handleFullscreenToggle}
          />
        </ControlGroup>
      </ControlsWrapper>
    </Container>
  );
};

Controls.propTypes = {
  matchId: PropTypes.string,
  visible: PropTypes.bool,
  hasFinishedPlaying: PropTypes.bool,
  playerRef: playerPropTypes,
  togglePersitentHoverState: PropTypes.func,
  playerContainerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};

ZnipeIconWithLink.propTypes = {
  isMobile: PropTypes.bool,
};

export default memo(Controls);
