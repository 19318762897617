import { createRef, useCallback, useRef } from 'react';

// Adds React refs to video objects if they do not have one
const useVideosWithRef = (originalVideos = []) => {
  const videoRefs = useRef({});
  const createVideosRefs = useCallback(
    videos =>
      videos.map(video => {
        if (video.id && !videoRefs.current[video.id]) {
          if (video.ref) {
            videoRefs.current[video.id] = video.ref;
          } else {
            videoRefs.current[video.id] = createRef();
          }
        }

        return {
          ...video,
          ref: videoRefs.current[video.id],
        };
      }),
    [],
  );

  return createVideosRefs(originalVideos);
};

export default useVideosWithRef;
