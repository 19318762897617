import styled from 'styled-components';

const ErrorMessage = styled.span`
  height: 15px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-bottom: 10px;
  color: ${props => props.theme.errorRed};
`;

export default ErrorMessage;
