import getRelativeDate from 'znipe-utils/date/getRelativeDate';

/**
 *
 * @param {object[]} datesArray
 * @param {string|Date} dateAnchor
 * @returns {object} a date matrix with the items from the dates array arranged by date
 */

const defaultDate = new Date();

const groupItemsByDate = (dataArray, dateAnchor = defaultDate, maxMatchesCount = -1) => {
  if (!dataArray) {
    throw Error('An array of data must be defined');
  }

  const today = new Date(dateAnchor);

  const datesMatrix = {};

  dataArray.forEach((item, index) => {
    if (Object.keys(item).length === 0) return;
    const { startTime, timestamp } = item;
    const time = startTime || timestamp;
    if (!time) return;
    if (maxMatchesCount > 0 && index >= maxMatchesCount) return;
    const relativeDate = getRelativeDate(time, today);
    datesMatrix[relativeDate] = [...(datesMatrix[relativeDate] || []), item];
  });

  return datesMatrix;
};

export default groupItemsByDate;
