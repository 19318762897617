import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fromEvent } from 'rxjs';
import { useVolume } from 'tv-selectors/control/makeGetVolume';
import { useMute } from 'tv-selectors/control/makeGetMute';
import { useCasterMute } from 'tv-selectors/control/makeGetCasterMute';
import { usePovVolumeRatio } from 'tv-selectors/control/makeGetPovVolumeRatio';
import { useCasterVolumeRatio } from 'tv-selectors/control/makeGetCasterVolumeRatio';
import { usePovMute } from 'tv-selectors/control/makeGetPovMute';
import { playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import { useGetMatchPopoutSoundFrom } from 'tv-selectors/match/makeGetMatchPopoutSoundFrom';
import {
  setCasterVolumeRatio,
  setMute,
  setPovVolumeRatio,
  setVolume,
  toggleCasterAudio,
  togglePovAudio,
} from 'tv-actions/old/control';
import { Provider } from '../contexts/AudioContext';

const MIN = 0;
const MAX = 1;

const calcVolume = (volume, ratio) => Math.min(Math.max(MIN, volume * ratio), MAX);
const calcRatio = (volume, mainVolume) => Math.min(Math.max(MIN, volume / mainVolume), MAX);

const AudioProvider = ({ children, playerRef, matchId = '' }) => {
  const dispatch = useDispatch();
  const initialVolume = useVolume();
  const initialMute = useMute();
  const initialCasterMute = useCasterMute();
  const initialPovMute = usePovMute();
  const initialPovVolumeRatio = usePovVolumeRatio();
  const initialCasterVolumeRatio = useCasterVolumeRatio();
  const popoutSoundFrom = useGetMatchPopoutSoundFrom({ matchId });
  const prevMuteState = useRef({ caster: initialCasterMute, pov: initialPovMute });

  const hasPopoutSoundSource = !!popoutSoundFrom;

  const [audioInfo, setAudioInfo] = useState({
    volume: initialVolume,
    casterVolumeRatio: initialCasterVolumeRatio,
    povVolumeRatio: initialPovVolumeRatio,
    casterMute: initialCasterMute,
    povMute: initialPovMute,
    mute: initialMute,
  });

  const handlePovVolumeRatio = useCallback(
    ratio => {
      if (typeof ratio !== 'number') return;
      if (ratio === 0 && playerRef.current?.isVideoStreamMuted()) return;
      playerRef.current?.setVideoStreamVolume(
        calcVolume(audioInfo.volume, ratio),
        hasPopoutSoundSource,
      );
    },
    [audioInfo.volume, hasPopoutSoundSource, playerRef],
  );

  const togglePovMute = useCallback(() => {
    if (hasPopoutSoundSource) return;
    if (playerRef.current.isVideoStreamMuted()) {
      playerRef.current.unmuteVideoStreamVolume();
      prevMuteState.current.pov = false;
    } else {
      playerRef.current.muteVideoStreamVolume();
      prevMuteState.current.pov = true;
    }
  }, [hasPopoutSoundSource, playerRef]);

  const handleCasterVolumeRatio = useCallback(
    ratio => {
      if (typeof ratio !== 'number') return;
      if (ratio === 0 && playerRef.current?.isAudioOnlyMuted()) return;
      if (playerRef.current?.isAudioOnlyMuted()) {
        dispatch(toggleCasterAudio(false));
        setAudioInfo(prev => ({
          ...prev,
          casterVolumeRatio: ratio,
        }));
      }
      prevMuteState.current.caster = false;
      playerRef.current?.setAudioOnlyVolume(calcVolume(audioInfo.volume, ratio));
    },
    [audioInfo.volume, dispatch, playerRef],
  );

  const toggleCasterMute = useCallback(() => {
    if (hasPopoutSoundSource) return;
    if (playerRef.current.isAudioOnlyMuted()) {
      dispatch(toggleCasterAudio(false));
      prevMuteState.current.caster = false;
    } else {
      playerRef.current.muteAudioOnlyVolume();
      prevMuteState.current.caster = true;
    }
  }, [hasPopoutSoundSource, playerRef, dispatch]);

  const handleVolumeChange = useCallback(
    async e => {
      const mute = !hasPopoutSoundSource && playerRef.current.isMuted();
      dispatch(setMute(mute));
      const nextValues = {
        mute,
      };
      const nextStatePromise = new Promise(resolve => {
        setAudioInfo(prev => {
          if (e.audioOnly) {
            const casterMute = playerRef.current.isAudioOnlyMuted();
            const casterVolume = playerRef.current.getAudioOnlyVolume();
            nextValues.casterVolumeRatio = calcRatio(casterVolume, prev.volume);
            nextValues.casterMute = casterMute;
          } else {
            const povMute = playerRef.current.isVideoStreamMuted();
            const povVolume = playerRef.current.getVideoStreamVolume();
            nextValues.povVolumeRatio = calcRatio(povVolume, prev.volume);
            nextValues.povMute = !hasPopoutSoundSource && povMute;
          }
          const result = {
            ...prev,
            ...nextValues,
          };

          resolve(result);

          return result;
        });
      });

      const nextState = await nextStatePromise;

      dispatch(setPovVolumeRatio(nextState.povVolumeRatio));
      dispatch(togglePovAudio(nextState.povMute));
      dispatch(setCasterVolumeRatio(nextState.casterVolumeRatio));
      dispatch(toggleCasterAudio(nextState.casterMute));
    },
    [dispatch, hasPopoutSoundSource, playerRef],
  );

  const toggleMute = useCallback(async () => {
    if (hasPopoutSoundSource) {
      setAudioInfo(prev => {
        const povVolume = playerRef.current.getVideoStreamVolume();
        const volume = prev.volume || povVolume / prev.povVolumeRatio;
        const mute = !prev.mute;
        dispatch(setMute(mute));
        return {
          ...prev,
          volume,
          mute,
        };
      });
      return;
    }
    if (playerRef.current.isMuted()) {
      const nextStatePromise = new Promise(resolve => {
        setAudioInfo(prev => {
          const povVolume = playerRef.current.getVideoStreamVolume();
          const volume = prev.volume || povVolume / prev.povVolumeRatio;
          if (!prevMuteState.current.caster) toggleCasterMute();
          if (!prevMuteState.current.pov) togglePovMute();
          if (prevMuteState.current.caster && prevMuteState.current.pov) playerRef.current.unmute();
          const nextState = {
            ...prev,
            volume,
          };
          resolve(nextState);
          return nextState;
        });
      });
      await nextStatePromise;
    } else {
      prevMuteState.current.caster = playerRef.current.isAudioOnlyMuted();
      prevMuteState.current.pov = playerRef.current.isVideoStreamMuted();
      playerRef.current.mute();
    }
  }, [dispatch, hasPopoutSoundSource, playerRef, toggleCasterMute, togglePovMute]);

  const handleVolume = useCallback(
    async volume => {
      if (typeof volume !== 'number') return;
      const isMuted = playerRef.current?.isMuted();
      if (volume === 0 && isMuted) return; // Do not change the volume if it's muted
      if (volume === 0 && !isMuted) {
        await toggleMute();
        return;
      }
      const nextStatePromise = new Promise(resolve => {
        setAudioInfo(prev => {
          const nextState = {
            ...prev,
            volume,
          };
          resolve(nextState);
          return nextState;
        });
      });
      const { povVolumeRatio, casterVolumeRatio } = await nextStatePromise;
      const dontMuteZeroVolume = !playerRef.current?.isMuted() || hasPopoutSoundSource;
      playerRef.current?.setVideoStreamVolume(
        calcVolume(volume, povVolumeRatio),
        dontMuteZeroVolume,
      );
      playerRef.current?.setAudioOnlyVolume(
        calcVolume(volume, casterVolumeRatio),
        dontMuteZeroVolume,
      );

      dispatch(setVolume(volume));
    },
    [dispatch, playerRef, hasPopoutSoundSource, toggleMute],
  );

  useEffect(() => {
    if (!playerRef.current) return () => null;
    const sub = fromEvent(playerRef.current, 'volumechange').subscribe(handleVolumeChange);

    return () => {
      sub.unsubscribe();
    };
  }, [playerRef, handleVolumeChange]);

  useEffect(() => {
    const player = playerRef.current;

    const handleAudioLoaded = async e => {
      if (!e.audioOnly) return;
      playerRef.current.unmuteAudioOnlyVolume();
      playerRef.current?.setAudioOnlyVolume(
        calcVolume(audioInfo.volume, audioInfo.casterVolumeRatio),
      );
      await handleVolumeChange(e);
    };

    const handleAudioUnload = async e => {
      if (!e.audioOnly) return;
      playerRef.current.muteAudioOnlyVolume();
      await handleVolumeChange(e);
    };

    player.addEventListener('loaded', handleAudioLoaded);
    player.addEventListener('unloading', handleAudioUnload);

    return () => {
      player.removeEventListener('loaded', handleAudioLoaded);
      player.removeEventListener('unloading', handleAudioUnload);
    };
  }, [playerRef, audioInfo.casterVolumeRatio, audioInfo.volume, handleVolumeChange]);

  useEffect(() => {
    const player = playerRef.current;

    const handlePlayerMuteState = async e => {
      if (!e.master) return;
      if (audioInfo.mute) {
        player.mute();
      } else {
        player.unmute();
        await handleVolume(audioInfo.volume);
      }
      await handleVolumeChange(e);
    };

    player.addEventListener('loaded', handlePlayerMuteState);

    return () => {
      player.removeEventListener('loaded', handlePlayerMuteState);
    };
  }, [audioInfo.mute, audioInfo.volume, handleVolume, handleVolumeChange, playerRef]);

  const value = useMemo(
    () => ({
      toggleMute,
      setVolume: handleVolume,
      setCasterVolumeRatio: handleCasterVolumeRatio,
      setPovVolumeRatio: handlePovVolumeRatio,
      togglePovMute,
      toggleCasterMute,
      ...audioInfo,
    }),
    [
      toggleMute,
      handleVolume,
      handleCasterVolumeRatio,
      handlePovVolumeRatio,
      togglePovMute,
      toggleCasterMute,
      audioInfo,
    ],
  );

  return <Provider value={value}>{children}</Provider>;
};

AudioProvider.propTypes = {
  children: PropTypes.node.isRequired,
  playerRef: playerPropTypes.isRequired,
  matchId: PropTypes.string,
};

export default AudioProvider;
