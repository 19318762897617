import deepmerge from 'deepmerge';
import { handleActions } from 'redux-actions';
import { SET_PREDICTIONS } from 'tv-constants/redux';

export const initialState = {};

export default handleActions(
  {
    [SET_PREDICTIONS]: (state, action) => deepmerge(state, action.predictions),
  },
  initialState,
);
