import { memo, useMemo, useCallback } from 'react';
import { Container, Divider } from './DropdownMenu.styles';
import DropdownItem from '../DropdownItem/DropdownItem';

export type Item = {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  image: string;
  selected: boolean;
  onSelect?: () => void;
  key?: string;
};

export type DropdownMenuProps = {
  items: Item[];
  onSelect: () => void;
  renderItem: (item: Item) => JSX.Element;
  useModal: boolean;
  error: boolean;
  isMultiSelect: boolean;
  $isAbove: boolean;
  dynamic?: boolean;
};

const DropdownMenu = ({
  items,
  onSelect,
  useModal = false,
  isMultiSelect = false,
  error = false,
  renderItem,
  $isAbove = false,
  dynamic = false,
}: DropdownMenuProps) => {
  const itemsRenderer = useCallback(
    (item: Item, isLast: boolean) => {
      if (item.title === 'divider') {
        if (isLast) return null;
        return <Divider />;
      }
      if (renderItem)
        return renderItem({
          ...item,
          key: item.id,
          onSelect,
        });
      return (
        <DropdownItem
          key={item.id}
          id={item.id}
          title={item.title}
          subtitle={item.subtitle}
          image={item.image}
          icon={item.icon}
          selected={item.selected}
          onSelect={onSelect}
          isMultiSelect={isMultiSelect}
        />
      );
    },
    [isMultiSelect, renderItem, onSelect],
  );

  const menuItems = useMemo(
    () => items?.map((item, index) => itemsRenderer(item, items.length - 1 === index)),
    [items, itemsRenderer],
  );

  return (
    <Container
      data-testid="dropdown-menu"
      role="menu"
      useModal={useModal}
      $error={error}
      $isAbove={$isAbove}
      $dynamic={dynamic}
    >
      {menuItems}
    </Container>
  );
};

export default memo(DropdownMenu);
