import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedGame from 'tv-selectors/games/makeGetSelectedGame';

const makeGetGameNumber = () => {
  const getSelectedGame = makeGetSelectedGame();
  return createSelector([getSelectedGame], game => game.gameNumber ?? 0);
};

export const useGetGameNumber = props => useMemoizedGqlSelector(makeGetGameNumber, props);

export default makeGetGameNumber;
