const fonts = [
  {
    family: 'Source Sans Pro',
    style: 'normal',
    weight: 400,
    src: 'url(https://assets.znipe.tv/fonts/SourceSansPro-400.woff2)',
  },
  {
    family: 'Source Sans Pro',
    style: 'normal',
    weight: 700,
    src: 'url(https://assets.znipe.tv/fonts/SourceSansPro-700.woff2)',
  },
  {
    family: 'Source Sans Pro',
    style: 'normal',
    weight: 900,
    src: 'url(https://assets.znipe.tv/fonts/SourceSansPro-900.woff2)',
  },
  {
    family: 'Source Sans Pro',
    style: 'normal',
    weight: 600,
    src: 'url(https://assets.znipe.tv/fonts/SourceSansPro-600.woff2)',
  },
  {
    family: 'Roboto',
    style: 'normal',
    weight: 400,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-Regular.woff2)',
  },
  {
    family: 'Roboto',
    style: 'italic',
    weight: 400,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-Italic.woff2)',
  },
  {
    family: 'Roboto',
    style: 'normal',
    weight: 700,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-Bold.woff2)',
  },
  {
    family: 'Roboto',
    style: 'italic',
    weight: 700,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-BoldItalic.woff2)',
  },
  {
    family: 'Roboto',
    style: 'normal',
    weight: 900,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-Black.woff2)',
  },
  {
    family: 'Roboto',
    style: 'italic',
    weight: 900,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-BlackItalic.woff2)',
  },
  {
    family: 'Roboto',
    style: 'normal',
    weight: 500,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-Medium.woff2)',
  },
  {
    family: 'Roboto',
    style: 'italic',
    weight: 500,
    src: 'url(https://assets.znipe.tv/fonts/Roboto-MediumItalic.woff2)',
  },
  {
    family: 'Titillium Web',
    style: 'normal',
    weight: 400,
    src: 'url(https://assets.znipe.tv/fonts/TitilliumWeb-Regular.woff2)',
  },
  {
    family: 'Titillium Web',
    style: 'normal',
    weight: 700,
    src: 'url(https://assets.znipe.tv/fonts/TitilliumWeb-Bold.woff2)',
  },
  {
    family: 'Titillium Web',
    style: 'normal',
    weight: 900,
    src: 'url(https://assets.znipe.tv/fonts/TitilliumWeb-Black.woff2)',
  },
  {
    family: 'Titillium Web',
    style: 'normal',
    weight: 600,
    src: 'url(https://assets.znipe.tv/fonts/TitilliumWeb-SemiBold.woff2)',
  },
  {
    family: 'Teko',
    style: 'normal',
    weight: 500,
    src: 'url(https://assets.znipe.tv/fonts/Teko-500.woff2)',
  },
];

export default fonts;
