import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetIsDesktop = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.isDesktop ?? false);
};

export const useIsDesktop = () => useMemoizedSelector(makeGetIsDesktop);

export default makeGetIsDesktop;
