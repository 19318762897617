import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const makeGetTeamCountry = () => {
  const getTeams = makeGetTeams();
  return createSelector([getTeams, getTeamIdFromProps], (allTeams, teamId) => {
    if (!allTeams || !teamId) return '';
    return allTeams[teamId]?.country ?? '';
  });
};

export const useGetTeamCountry = props => useMemoizedGqlSelector(makeGetTeamCountry, props);

export default makeGetTeamCountry;
