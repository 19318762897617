import { memo } from 'react';
import PropTypes from 'prop-types';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import CardTitle from './components/CardTitle';
import { Block } from './BlockContainer.styles';

const BlockContainerLoading = ({ height = null, size = 'small' }) => (
  <Block data-testid="content-block-loading" height={height}>
    <CardTitle size={size}>
      <TextSkeleton height={14} width={120} />
    </CardTitle>
  </Block>
);

BlockContainerLoading.propTypes = {
  height: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default memo(BlockContainerLoading);
