import styled, { css } from 'styled-components';

const getScoreFontStyles = ({ size }) => {
  switch (size) {
    case 'small':
      return css`
        font-size: 18px;
        line-height: 21px;
      `;

    case 'medium':
      return css`
        font-size: 21px;
        line-height: 25px;
      `;

    case 'large':
      return css`
        font-size: 26px;
        line-height: 30px;
      `;

    default:
      return css`
        font-size: 38px;
        line-height: 44px;
      `;
  }
};

export const StyledScore = styled.div`
  font-weight: 900;
  ${getScoreFontStyles}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 3px 0;
  line-height: 20px;
  z-index: 0;
  gap: 0px 8px;
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr repeat(1, auto) 1fr;
    grid-template-rows: 1fr;
    margin: 0 auto;
    place-items: center;
    /* TEAM 1 LOGO */
    > *:first-child {
      justify-self: flex-end;
    }
    /* VS ICON */
    > *:nth-child(3) {
      display: flex;
    }
    /* TEAM 2 LOGO */
    > *:last-child {
      justify-self: flex-start;
    }
  }
`;

export const IconWrapper = styled.div`
  margin: 0px 5px;
  min-height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '16px';
      case 'medium':
        return '22px';
      case 'large':
        return '32px';
      default:
        return '42px';
    }
  }};
`;

export const TeamSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isReversed ? 'flex-start' : 'flex-end')};
  ${props =>
    props.size !== 'small' &&
    css`
      flex-direction: ${props.isReversed ? 'row' : 'row-reverse'};
      align-items: center;
    `}
`;

export const IndicatorsWrapper = styled.div`
  padding: 0 1px;
  margin-top: 3px;
  ${props =>
    !props.isReversed &&
    css`
      transform: rotate(180deg);
    `}
`;

export const MiddleSection = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    props.showingMatchScoreIndicators &&
    css`
      margin-top: ${props.size === 'small' ? '-8px' : '0px'};
    `}
`;

export const GifWrapper = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
