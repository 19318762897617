import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetProductsMap from './makeGetProductsMap';
import makeGetProductId from './makeGetProductId';

const makeGetSelectedProduct = () => {
  const getProductId = makeGetProductId();
  const getProductsMap = makeGetProductsMap();
  return createSelector(
    [getProductsMap, getProductId],
    (productsMap, productId) => productsMap[productId] || {},
  );
};

export const useGetSelectedProduct = props => useMemoizedSelector(makeGetSelectedProduct, props);

export default makeGetSelectedProduct;
