import colors from 'znipe-styles/colors';

export default {
  proview: {
    backgroundColor: colors.white,
  },
  default: {
    backgroundColor: colors.white,
  },
};
