import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';

import { Container, IconContainer, TextContainer } from './PlaceholderCard.styles';

const PlaceholderCard = () => (
  <Container>
    <IconContainer>
      <Icon type="sword" size={24} inline />
      <Icon type="tower" size={24} inline />
      <Icon type="riftHerald" size={24} inline />
      <Icon type="defaultDragon" size={24} inline />
      <Icon type="baron" size={24} inline />
    </IconContainer>
    <TextContainer>
      <Typography align="center" type="heading-m">
        No blood has been shed yet...
      </Typography>
      <Typography color="secondaryTextColor" align="center" type="paragraph-m">
        Match highlights like kills and team objectives will be displayed here.
      </Typography>
    </TextContainer>
  </Container>
);

export default PlaceholderCard;
