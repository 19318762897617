import styled, { css } from 'styled-components';

export const Item = styled.div`
  position: relative;
  padding-top: 100%;
`;

export const Gif = styled.div<{ $src?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  border-radius: 3px;
  ${({ $src }) =>
    $src &&
    css`
      background: url(${$src});
      background-size: cover;
      background-position: center;
    `}
`;

export const Grid = styled.div<{ $columnsPerRow?: number }>`
  display: grid;
  position: relative;
  ${({ $columnsPerRow }) =>
    $columnsPerRow &&
    css`
      grid-template-columns: repeat(${$columnsPerRow}, 1fr);
    `}
  grid-gap: 4px;
`;
