import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import OnboardingButton from 'tv-modules/Onboarding/Button/Button';
import Overlay from 'tv-modules/Onboarding/Overlay/Overlay';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';

import {
  CoverFlourishWrapper,
  CoverHeadline,
  ParagraphsWrapper,
  Container,
  Image,
} from './BookCover.styles';

const BookCover = ({
  bookCoverId,
  headline = '',
  paragraphs = [],
  buttonLabel = '',
  headerImage,
  topOffset = 0,
}) => {
  const setBookCoverPlayedStatus = useSetPlayedStatus(bookCoverId);
  const onButtonClick = useCallback(() => {
    setBookCoverPlayedStatus(true);
  }, [setBookCoverPlayedStatus]);

  return (
    <Overlay $padding="16" $top={topOffset} $reversedGradient>
      <Container>
        <CoverFlourishWrapper>
          <Image data-testid="book-cover-image" src={headerImage} alt="cover header" />
        </CoverFlourishWrapper>
        {headline && <CoverHeadline>{headline}</CoverHeadline>}
        <ParagraphsWrapper>
          {paragraphs.map((paragraph, i) => (
            <Typography key={paragraph} dataTestId={`book-cover-paragraph-${i}`} type="paragraph-m">
              {paragraph}
            </Typography>
          ))}
        </ParagraphsWrapper>
        <OnboardingButton buttonLabel={buttonLabel} onClick={onButtonClick} />
      </Container>
    </Overlay>
  );
};

BookCover.propTypes = {
  bookCoverId: PropTypes.string.isRequired,
  headline: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  headerImage: PropTypes.string.isRequired,
  topOffset: PropTypes.number,
};

export default BookCover;
