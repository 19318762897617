import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatches from './makeGetMatches';
import makeGetMatchId from './makeGetMatchId';

const emptyObject = {};

const makeGetMatch = () => {
  const getMatchMap = makeGetMatches();
  const getMatchId = makeGetMatchId();
  return createSelector(
    [getMatchMap, getMatchId],
    (matchMap, matchId) => matchMap[matchId] ?? emptyObject,
  );
};

export const useGetMatch = props => useMemoizedGqlSelector(makeGetMatch, props);

export default makeGetMatch;
