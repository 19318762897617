import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import themes from './ErrorImage.themes';

const Image = styled.img`
  height: ${props => props.size};
`;

const ErrorImage = ({ size = '100%' }) => {
  const { errorImage = {} } = useThemeContext();
  return <Image data-testid="error" src={errorImage.imageUrl} alt="error" size={size} />;
};

ErrorImage.propTypes = {
  size: PropTypes.string,
};

export default withTheme(memo(ErrorImage), themes, 'errorImage');
