import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedMatchTeams from 'tv-selectors/match/makeGetMatchTeams';
import makeGetMatchAvailableGamesInfo from 'tv-selectors/match/makeGetMatchAvailableGamesInfo';
import makeGetMatchTeamsCompetitors from 'tv-selectors/match/makeGetMatchTeamsCompetitors';

const emptyObject = {};

const makeGetMatchScore = () => {
  const getMatchAvailableGamesInfo = makeGetMatchAvailableGamesInfo();
  const getMatchTeamsCompetitors = makeGetMatchTeamsCompetitors();
  const getSelectedMatchTeams = makeGetSelectedMatchTeams();
  return createSelector(
    [getMatchAvailableGamesInfo, getMatchTeamsCompetitors, getSelectedMatchTeams],
    (games, teamCompetitors, matchTeams) => {
      if (games.length === 0 || matchTeams.length === 0) return emptyObject;

      const totalScore = {};
      const gameScore = {};

      games.forEach(game => {
        const currentGameScores = game.scores || [];
        currentGameScores.forEach(score => {
          const gameCompId = score.competitorId;
          gameScore[gameCompId] = score;
        });
      });

      matchTeams.forEach(team => {
        const teamId = team.id || '';
        const currentCompetitors = teamCompetitors[teamId] || [];
        let currentScore = 0;
        currentCompetitors.forEach(compId => {
          const compInfo = gameScore[compId];
          currentScore += compInfo?.points || 0;
        });

        totalScore[teamId] = currentScore;
      });

      return totalScore;
    },
  );
};

export const useGetMatchScore = props => useMemoizedGqlSelector(makeGetMatchScore, props);

export default makeGetMatchScore;
