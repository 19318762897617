import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetEvents from 'tv-selectors/events/makeGetEvents';

const getEventIdFromProps = (_, props) => props?.eventId || props?.eventID || '';

const getEventSlugFromProps = (_, props) => props?.eventSlug || '';

const makeGetEventId = () => {
  const getEvents = makeGetEvents();
  return createSelector(
    [getEventIdFromProps, getEventSlugFromProps, getEvents],
    (selectedEventId, eventSlug, events) => {
      if (selectedEventId) return selectedEventId;
      const eventId = Object.keys(events).find(id => events[id]?.humanReadableId === eventSlug);
      return eventId || '';
    },
  );
};

export const useGetEventId = props => useMemoizedGqlSelector(makeGetEventId, props);

export default makeGetEventId;
