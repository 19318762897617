import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Headline from '../Headline/Headline';

export const LineSVG = styled.svg`
  transform: ${({ $direction, $position }) =>
    `scaleX(${$direction === 'left' ? 1 : -1}) scaleY(${$position === 'top' ? 1 : -1})`};
  float: ${({ $direction }) => $direction};
`;

export const PopupHeadline = styled(Headline)`
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 8px;
  margin-top: 0;
`;

export const DisableHints = styled.div`
  position: absolute;
  right: 16px;
  bottom: 8px;
  cursor: pointer;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  height: 24px;
  right: 8px;
  top: 8px;
  z-index: 1;
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: absolute;
  border: 2px solid ${({ theme }) => theme.onboardingStroke};
  border-radius: 5px;
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  width: 312px;
  min-height: 152px;
  @media ${device.tablet} {
    width: 400px;
    min-height: 152px;
  }
  @media ${device.desktop} {
    width: 536px;
    min-height: 128px;
  }

  ${({ $offset }) =>
    $offset &&
    css`
      margin-left: ${$offset}px;
    `};

  ${({ $direction, $containerHiddenDiff, $horizontalPosition }) => {
    const tranformYValue =
      $containerHiddenDiff.bottom < 1
        ? `translateY(calc(-50% - ${12 + Math.abs($containerHiddenDiff.bottom)}px))`
        : 'translateY(-50%)';

    const tranformXValueForHiddenLeftEdge =
      $containerHiddenDiff.left < 1
        ? `translateX(calc(-50% + ${4 + Math.abs($containerHiddenDiff.left)}px))`
        : null;

    const tranformXValueForHiddenRightEdge =
      $containerHiddenDiff.right < 1
        ? `translateX(calc(-50% - ${4 + Math.abs($containerHiddenDiff.right)}px))`
        : null;

    const tranformXValue =
      tranformXValueForHiddenLeftEdge || tranformXValueForHiddenRightEdge || 'translateX(-50%)';

    if ($direction === 'left') {
      return css`
        left: calc(100% + 16px);
        top: 50%;
        transform: ${tranformYValue};

        @media ${device.desktop} {
          left: calc(100% + 32px);
        }
      `;
    }
    if ($direction === 'right') {
      return css`
        right: calc(100% + 16px);
        top: 50%;
        transform: ${tranformYValue};

        @media ${device.desktop} {
          right: calc(100% + 32px);
        }
      `;
    }

    // direction === up
    return css`
      top: calc(100% + 16px);
      @media ${device.desktop} {
        top: calc(100% + 32px);
      }
      // horizontal positioning
      ${
        $horizontalPosition === 'center' &&
        css`
        left: 50%;
        transform: ${tranformXValue};
      `
      }
      ${
        $horizontalPosition === 'left' &&
        css`
        left: 0;
      `
      } 
      ${
        $horizontalPosition === 'right' &&
        css`
        right: 0;
      `
      }
    `;
  }};
  z-index: 2;

  & > p {
    margin-bottom: 0;
  }

  & + *::after {
    content: '';
    position: absolute;
    width: 100%;
    height: ${({ $targetShape }) => ($targetShape === 'rectangle' ? 'calc(100% + 4px)' : '100%')};
    top: ${({ $targetShape }) => ($targetShape === 'rectangle' ? '-2px' : '0')};
    left: 0;
    border: 2px solid ${({ theme }) => theme.onboardingStroke};
    border-radius: ${({ $targetShape }) => ($targetShape === 'rectangle' ? '5px' : '100%')};
    z-index: 0;
    pointer-events: none;
  }

  & + *::before {
    content: '';
    height: 2px;
    width: 16px;
    background: ${({ theme }) => theme.onboardingStroke};
    position: absolute;
    z-index: 1;

    ${({ $direction }) => {
      if ($direction === 'left') {
        return css`
          top: calc(50% - 1px);
          right: 0;
          transform: translateX(100%);

          @media ${device.desktop} {
            width: 32px;
          }
        `;
      }
      if ($direction === 'right') {
        return css`
          top: calc(50% - 1px);
          left: 0;
          transform: translateX(-100%);

          @media ${device.desktop} {
            width: 32px;
          }
        `;
      }
      // direction === up
      return css`
        left: calc(50% - 1px);
        bottom: ${({ $targetShape }) => ($targetShape === 'rectangle' ? '-2px' : '0')};
        transform: translateY(100%);
        height: 16px;
        width: 2px;

        @media ${device.desktop} {
          height: 32px;
        }
      `;
    }};
  }
`;

export const TargetWrapper = styled.div`
  display: block;
`;
