import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetUserId = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.userId ?? '');
};

export const useUserId = () => useMemoizedSelector(makeGetUserId);

export default makeGetUserId;
