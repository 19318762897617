import CardSimple from 'znipe-elements/data-display/Card/CardSimple';
import colors from 'znipe-styles/colors';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import GreenRingAnimation from 'tv-elements/animation/GreenRingAnimation/GreenRingAnimation';
import { predictionType } from '../../types';
import {
  CardContentContainer,
  CardContainer,
  AnimationContainer,
} from './PredictionOutcomeCard.styles';
import PredictionCardRightIcon from '../PredictionCardRightIcon/PredictionCardRightIcon';
import PredictionOutcomeHandlement from './PredictionOutcomeHandlement/PredictionOutcomeHandlement';
import { status, variants } from '../../predictionConstants';

// Colors are kind of hardoded, will update colors for the feature in a separate PR.
const statusBackground = predictionStatus => {
  switch (predictionStatus) {
    case status.pending:
      return 'linear-gradient(280.39deg, rgba(206, 127, 255, 0.2) 0.03%, rgba(206, 127, 255, 0) 100%), #121821;';
    case status.wrong:
      return 'linear-gradient(280.39deg, rgba(253, 75, 75, 0.2) 0.03%, rgba(253, 75, 75, 0) 100%), #121821;';
    case status.win:
      return 'linear-gradient(280.39deg, rgba(73, 236, 89, 0.2) 0.03%, rgba(73, 236, 89, 0) 100%), #121821;';
    default:
      return undefined;
  }
};

const getPredictionStatus = (prediction, predictionResult) => {
  if (predictionResult === status.pending) {
    return status.pending;
  }
  if (prediction === predictionResult) {
    return status.win;
  }

  return status.wrong;
};

const getTextColor = predictionStatus => {
  switch (predictionStatus) {
    case status.win:
      return colors.beanGreen;
    case status.wrong:
      return colors.flamingo;
    case status.pending:
      return '#C99EFF';
    default:
      return null;
  }
};

const PredictionOutcomeCard = ({ prediction, size = 'small' }) => {
  const { predictedValue, outcome, label, variant } = prediction;

  const predictionStatus = getPredictionStatus(predictedValue, outcome);

  if (Object.keys(prediction).length === 0) return null;

  return (
    <CardContainer>
      <AnimationContainer>
        {predictionStatus === status.win && <GreenRingAnimation dimension="189px" />}
        <CardSimple
          title={label}
          underlinedHeader={false}
          headerPadding="16px 16px 0px 16px"
          customBackground={statusBackground(predictionStatus)}
        >
          <CardContentContainer $size={size} $teamsCard={variant === variants.TEAMS}>
            <div style={{ dispay: 'block' }}>
              <Typography type="title-xs" color={getTextColor(predictionStatus)}>
                {predictionStatus === status.win && 'Spot on!'}
                {predictionStatus === status.wrong && 'Wrong guess!'}
                {predictionStatus === status.pending && 'Your guess!'}
              </Typography>
              <PredictionOutcomeHandlement variant={variant} prediction={prediction} />
            </div>
            {size === 'large' && <PredictionCardRightIcon size={size} scenario={prediction} />}
          </CardContentContainer>
        </CardSimple>
      </AnimationContainer>
    </CardContainer>
  );
};

PredictionOutcomeCard.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  prediction: predictionType.isRequired,
};

export default PredictionOutcomeCard;
