import { useMemo } from 'react';

/**
 * An animation offset value to stagger animations in grids
 * @param {number} index the index of the item
 * @param {number} columnCount the number of colums in the animation
 * @param {number} tweakValue helps manage the offset for a smooth fade
 * @returns number offset
 */
const useGridAnimationOffset = (index = 0, columnCount = 3) =>
  useMemo(() => (index % columnCount) / 10, [columnCount, index]);

export default useGridAnimationOffset;
