import { memo, useMemo } from 'react';
import withTheme from 'znipe-themes/hocs/withTheme';
import {
  FinePrintLink,
  FinePrintRow,
} from 'tv-modules/Settings/SettingsPanel/SettingsPanel.styles';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Typography from 'znipe-elements/general/Typography/Typography';

const row1Keys = ['privacy', 'termsOfService', 'content'];
const row2Keys = ['cookies', 'faq', 'support'];

const rowFilter = entry => entry?.label && (entry?.to || entry?.onClick || entry?.href);
const findType = entry => {
  if (entry?.onClick) return 'button';
  if (entry?.href) return 'a';
  return undefined; // Default
};

const FinePrint = () => {
  const { 'fine-print-links': links } = useThemeContext();
  const row1 = useMemo(() => row1Keys.map(key => links[key]).filter(rowFilter), [links]);
  const row2 = useMemo(() => row2Keys.map(key => links[key]).filter(rowFilter), [links]);

  const rows = useMemo(() => [row1, row2].filter(row => row.length > 0), [row1, row2]);

  return rows.map((row, index) => (
    <FinePrintRow
      /* eslint-disable react/no-array-index-key */
      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
      key={`fine-print-row-${index}`}
    >
      {row.map(entry => {
        const as = findType(entry);
        const { to, onClick, href, label } = entry;
        return (
          <FinePrintLink key={label} as={as} to={to} href={href} onClick={onClick}>
            <Typography as="span" type="paragraph-s">
              {label}
            </Typography>
          </FinePrintLink>
        );
      })}
    </FinePrintRow>
  ));
};

const themes = {
  default: {
    privacy: {
      label: 'Privacy Policy',
      to: '/privacy',
    },
    termsOfService: {
      label: 'Terms of Service',
      to: '/terms-of-use',
    },
    cookies: undefined,
    faq: undefined,
    support: undefined,
  },
  proview: {
    privacy: {
      label: 'Privacy Policy',
      to: '/privacy',
    },
    termsOfService: {
      label: 'Terms of Service',
      to: '/terms-of-use',
    },
    content: {
      label: 'Content Policy',
      to: '/content-policy',
    },
    cookies: {
      label: 'Cookies',
      onClick: global.Osano
        ? () => global.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
        : undefined,
    },
    faq: {
      label: 'FAQs',
      href: 'https://support-leagueoflegends.riotgames.com/hc/en-us/articles/360023636673-Pro-View-FAQ',
    },
    support: {
      label: 'Support',
      href: 'https://support-leagueoflegends.riotgames.com/hc/en-us/requests/new',
    },
  },
};

export default memo(withTheme(FinePrint, themes, 'fine-print-links'));
