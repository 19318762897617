import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedGame from 'tv-selectors/games/makeGetSelectedGame';
import makeGetCompetitors from 'tv-selectors/competitors/makeGetCompetitors';

const emptyArray = [];

const makeGetGameCompetitors = () => {
  const getSelectedGame = makeGetSelectedGame();
  const getCompetitors = makeGetCompetitors();
  return createSelector([getSelectedGame, getCompetitors], (currentGame, allCompetitors) => {
    const { competitors = [] } = currentGame;
    if (competitors.length === 0) return emptyArray;
    return competitors.reduce((acc, competitorId) => {
      const competitiorInfo = allCompetitors[competitorId];
      if (competitiorInfo) acc.push(competitiorInfo);
      return acc;
    }, []);
  });
};

export const useGetGameCompetitors = props => useMemoizedGqlSelector(makeGetGameCompetitors, props);

export default makeGetGameCompetitors;
