import { memo, forwardRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import InputField from 'znipe-elements/data-entry/InputField/InputField';
import SearchHistory, { searchesShape } from 'tv-modules/Search/SearchHistory/SearchHistory';
import FeaturedSubscribeButton from 'tv-modules/Premium/FeaturedSubscribeButton/FeaturedSubscribeButton';
import SearchSuggestions, {
  suggestionShape,
} from 'tv-modules/Search/SearchSuggestions/SearchSuggestions';
import { setSearchHistoryQuery, setSearchHistoryResult } from 'tv-actions/search';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import {
  SearchInputWrapper,
  SearchDropdownBox,
  DesktopSearch,
  SearchOverlay,
  DesktopSearchContainer,
  PremiumButtonWrapper,
} from './SearchDropdown.styles';
import { RIOT_CHAMPION } from '../constants';

const themes = {
  default: {
    searchPlaceholderText: 'Search players, teams, matches...',
  },
  proview: {
    searchPlaceholderText: 'Search players, teams, champions...',
  },
};

const HIDDEN = 'hidden';
const PASSIVE = 'passive';
const ACTIVE = 'active';

const SUGGESTIONS = 'suggestions';
const HISTORY = 'history';

const SearchDropdown = forwardRef(
  (
    {
      baseUrl,
      searchState = HIDDEN,
      browseUrl = '',
      onClickDelete = () => {},
      onSearchChange = () => {},
      onFocus = () => {},
      suggestions = [],
      recentSearches = {
        searches: [],
        queries: [],
      },
      popularSearches = [],
      packageName = '',
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const gqlDispatch = useGqlStoreDispatch();
    const isDesktopOrGreater = useGetIsDesktopOrGreater();
    const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
    const contentType = useMemo(
      () => (searchState === ACTIVE ? SUGGESTIONS : HISTORY),
      [searchState],
    );

    const handleClickSearch = useCallback(
      query => {
        gqlDispatch(setSearchHistoryQuery(query));
        navigate(`${browseUrl}/?s=${query}`);
      },
      [browseUrl, navigate, gqlDispatch],
    );

    const { searchDropdown } = useThemeContext();

    const { searchPlaceholderText } = searchDropdown;

    const handleClickResultItem = useCallback(
      (category, searchItem) => {
        const { nickname, name, game } = searchItem || {};
        let topicUrl = '';
        if (category?.indexOf('player') !== -1) {
          topicUrl = `${baseUrl}/topic/player/${nickname}-${game}`;
        } else if (category?.indexOf('team') !== -1) {
          topicUrl = `${baseUrl}/topic/team/${name}-${game}`;
        } else if (category?.indexOf(RIOT_CHAMPION) !== -1) {
          topicUrl = `${baseUrl}/topic/champion/${name}-lol`;
        }
        if (onClickDelete) onClickDelete();
        gqlDispatch(setSearchHistoryResult(category, searchItem));
        if (topicUrl) navigate(topicUrl);
      },
      [baseUrl, navigate, onClickDelete, gqlDispatch],
    );

    if (!isDesktopOrGreater) {
      return (
        <SearchOverlay hidden={searchState === HIDDEN} data-testid="mobile-overlay">
          {contentType === HISTORY ? (
            <SearchHistory
              searches={recentSearches.searches}
              queries={recentSearches.queries}
              onClickResultItem={handleClickResultItem}
              onClickSearchQuery={handleClickSearch}
            />
          ) : (
            <SearchSuggestions
              suggestions={suggestions}
              onClickSuggestion={handleClickResultItem}
            />
          )}
        </SearchOverlay>
      );
    }

    const getDropdownElement = () => {
      const showSuggestions = searchState === ACTIVE && suggestions;
      if (searchState === HIDDEN) return null;
      const hasRecentSearches = recentSearches.searches.length > 0;
      const label = hasRecentSearches ? 'Recent Searches' : 'Popular Searches';
      const searches = hasRecentSearches ? recentSearches.searches : popularSearches;
      return (
        <SearchDropdownBox
          resultType={contentType}
          hidden={searchState === HIDDEN}
          data-testid="desktop-dropdown"
        >
          {showSuggestions ? (
            <SearchSuggestions
              suggestions={suggestions}
              onClickSuggestion={handleClickResultItem}
            />
          ) : (
            <SearchHistory
              label={label}
              searches={searches}
              queries={recentSearches.queries}
              onClickResultItem={handleClickResultItem}
              onClickSearchQuery={handleClickSearch}
            />
          )}
        </SearchDropdownBox>
      );
    };

    return (
      <DesktopSearch ref={ref}>
        {packageName === 'proview' && (
          <PremiumButtonWrapper>
            <FeaturedSubscribeButton
              packageName={packageName}
              type={isDesktopLargeOrGreater ? 'button' : 'icon'}
            />
          </PremiumButtonWrapper>
        )}
        <DesktopSearchContainer id="desktop-search">
          <SearchInputWrapper>
            <InputField
              placeholder={searchPlaceholderText}
              onClickIcon={handleClickSearch}
              onSearchChange={onSearchChange}
              onClickDelete={onClickDelete}
              onFocus={onFocus}
            />
          </SearchInputWrapper>
          {getDropdownElement()}
        </DesktopSearchContainer>
      </DesktopSearch>
    );
  },
);

SearchDropdown.propTypes = {
  searchState: PropTypes.oneOf([PASSIVE, ACTIVE, HIDDEN]),
  baseUrl: PropTypes.string.isRequired,
  browseUrl: PropTypes.string,
  onClickDelete: PropTypes.func,
  onSearchChange: PropTypes.func,
  onFocus: PropTypes.func,
  suggestions: PropTypes.arrayOf(suggestionShape),
  recentSearches: PropTypes.shape({
    searches: PropTypes.arrayOf(PropTypes.shape(searchesShape)),
    queries: PropTypes.arrayOf(PropTypes.string),
  }),
  popularSearches: PropTypes.arrayOf(PropTypes.shape(searchesShape)),
  packageName: PropTypes.string,
};

export default withTheme(memo(SearchDropdown), themes, 'searchDropdown');
