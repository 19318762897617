import firebase from 'firebase/app';

const useNodeConfiguration = true;

const setupFirebase = () => {
  const usedInChina = window.location.href.indexOf('matchdemochina') !== -1;
  if (usedInChina) return null;

  const config = global.process ? global.process.env : process.env;

  const firebaseConfig = useNodeConfiguration
    ? {
        token: config.FIREBASE_TOKEN || 'AIzaSyDs0GpLsDBsy5QDOhudWUAAgv17edbh3mg',
        project: config.FIREBASE_PROJECT || 'znipe-staging',
        sender: config.FIREBASE_SENDER || '48054606202',
      }
    : {
        token: 'AIzaSyAZIHUjSbqd6O6ZHv75xHu4XvbtRsHTWkw',
        project: 'znipe-production-1c540',
        sender: '310750791197',
      };

  const initConfig = {
    apiKey: firebaseConfig.token,
    authDomain: `${firebaseConfig.project}.firebaseapp.com`,
    databaseURL: `https://${firebaseConfig.project}.firebaseio.com`,
    storageBucket: `${firebaseConfig.project}.appspot.com`,
    messagingSenderId: `${firebaseConfig.sender}`,
    projectId: `${firebaseConfig.project}`,
  };

  return firebase.initializeApp(initConfig);
};

export const firebaseRest = ref => {
  const config = global.process ? global.process.env : process.env;

  const firebaseConfig = useNodeConfiguration
    ? {
        token: config.FIREBASE_TOKEN || 'AIzaSyDs0GpLsDBsy5QDOhudWUAAgv17edbh3mg',
        project: config.FIREBASE_PROJECT || 'znipe-staging',
        sender: config.FIREBASE_SENDER || '48054606202',
      }
    : {
        token: 'AIzaSyAZIHUjSbqd6O6ZHv75xHu4XvbtRsHTWkw',
        project: 'znipe-production-1c540',
        sender: '310750791197',
      };

  return `https://${firebaseConfig.project}.firebaseio.com/${ref}.json`;
};

export default setupFirebase;
