import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { dispatchGqlData } from 'tv-actions/thunks';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { normalizeData } from 'znipe-utils/normalize/normalizeData';
import { rootSchema } from 'tv-schema/rootSchema.normalizr';
import useGqlQuery from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import config from 'tv-config/config';

const useReduxGqlQuery = (
  query,
  options,
  initialState,
  schema = rootSchema,
  postProcessFunc = undefined,
  pageName = undefined,
  resetPage = false,
) => {
  const gqlDispatch = useGqlStoreDispatch();

  const queryOptions = useMemo(() => {
    if (options.url) return options;
    return { ...options, url: config.GRAPHQL_API_URL };
  }, [options]);

  const { pathname = '' } = useLocation();
  const { packageSlug } = useParams();
  const shouldRemeberPreviousEntries = !!options.variables?.nextCursor;

  const handleSuccessDispatch = useCallback(
    data => {
      const pathnameLC = pathname.toLowerCase();
      const pathArr = pathnameLC.split('/');
      const currentPageName = pageName ?? pathArr[packageSlug ? 2 : 1];
      const normalized = normalizeData(data, schema ?? rootSchema);
      // INFO: This is temp work around to help with nested page data
      // and filtering it into the page reducer
      const {
        latestMatches = {},
        scheduledMatches = {},
        matches = {},
        ...rest
      } = normalized.entities;

      const thunkData = postProcessFunc
        ? {
            ...rest,
            matches: {
              ...latestMatches,
              ...scheduledMatches,
              ...matches,
            },
            data: {
              custom: postProcessFunc(normalized.entities),
            },
          }
        : normalized.entities;

      gqlDispatch(
        dispatchGqlData(thunkData, {
          pageType: currentPageName,
          reset: resetPage,
          mergeData: shouldRemeberPreviousEntries,
        }),
      );
    },
    [
      gqlDispatch,
      packageSlug,
      pageName,
      pathname,
      postProcessFunc,
      resetPage,
      schema,
      shouldRemeberPreviousEntries,
    ],
  );

  return useGqlQuery(query, queryOptions, initialState, handleSuccessDispatch);
};

export default useReduxGqlQuery;
