import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';

const defaultPlayerObject = { firstName: '', lastName: '' };

const getPlayerIdFromProps = (_, props) => props.playerId ?? '';

const makeGetPlayerFullName = () => {
  const getPlayers = makeGetPlayers();
  return createSelector([getPlayers, getPlayerIdFromProps], (allPlayers, playerId) => {
    if (!allPlayers || !playerId) return '';
    const { firstName = '', lastName = '' } = allPlayers[playerId] ?? defaultPlayerObject;
    if (!firstName && !lastName) return '';
    return `${firstName} ${lastName}` ?? '';
  });
};

export const useGetPlayerFullName = props => useMemoizedGqlSelector(makeGetPlayerFullName, props);

export default makeGetPlayerFullName;
