export const theme = {
  proview: {
    left: {
      main: 'https://assets.znipe.tv/onboarding/lol/empty-state/my-feed-lol-left-main.png',
      large: 'https://assets.znipe.tv/onboarding/lol/empty-state/Normalised-PV-MyFeed-Left.png',
    },
    right: {
      main: 'https://assets.znipe.tv/onboarding/lol/empty-state/my-feed-lol-right-main.png',
      large: 'https://assets.znipe.tv/onboarding/lol/empty-state/Normalised-PV-MyFeed-Right.png',
    },
  },
  default: {
    left: {
      main: 'https://assets.znipe.tv/onboarding/csgo/empty-state/my-feed-csgo-left.png',
      large: 'https://assets.znipe.tv/onboarding/csgo/empty-state/Normalised-CSGO-MyFeed-Left.png',
    },
    right: {
      main: 'https://assets.znipe.tv/onboarding/csgo/empty-state/my-feed-csgo-right.png',
      large: 'https://assets.znipe.tv/onboarding/csgo/empty-state/Normalised-CSGO-MyFeed-Right.png',
    },
  },
};

export const uiSizes = {
  desktopLarge: {
    headline: 'heading-l',
    text: 'paragraph-l',
    button: 'small',
    arrowIcon: 'cursiveArrowLarge',
    arrowIconSize: 96,
  },
  desktop: {
    headline: 'heading-m',
    text: 'paragraph-m',
    button: 'small',
    arrowIcon: 'cursiveArrowLarge',
    arrowIconSize: 96,
  },
  tablet: {
    headline: 'heading-l',
    text: 'paragraph-l',
    button: 'small',
    arrowIcon: 'cursiveArrowLarge',
    arrowIconSize: 96,
  },
  mobilePortrait: {
    headline: 'heading-m',
    text: 'paragraph-m',
    button: 'xsmall',
    arrowIcon: 'cursiveArrow',
    arrowIconSize: 32,
  },
  mobile: {
    headline: 'heading-s',
    text: 'paragraph-m',
    button: 'xsmall',
    arrowIcon: 'cursiveArrow',
    arrowIconSize: 32,
  },
};
