import styled from 'styled-components';

export const ScrollWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 1fr);
`;

export const TopResultWrapper = styled.div`
  max-width: 656px;
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: ${({ $type }) => {
    switch ($type) {
      case 'teams':
        return 'repeat(4, 1fr)}';
      default:
        return 'repeat(6, 1fr)}';
    }
  }};
`;

export const SearchTermWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;
