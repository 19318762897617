import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Skull from './Skull/Skull';
import EliminatedInfo from './EliminatedInfo/EliminatedInfo';
import useIsHomeTeam from '../hooks/useIsHomeTeam';
import { sizesShape } from '../constants';

const SKULL_STATE = 'SKULL';
const ELIMINATED_STATE = 'ELIMINATED';

const DeadPlayerInfo = ({
  gameId,
  size = 'xsmall',
  show = false,
  actorId,
  victimId,
  weapon,
  onAnimationEnd = () => {},
}) => {
  const [animationState, setAnimationState] = useState(null);

  const isHomeTeam = useIsHomeTeam(gameId, actorId);

  const onAnimationStateChange = useCallback(
    () =>
      setAnimationState(prev => {
        if (prev === SKULL_STATE) return ELIMINATED_STATE;
        if (prev === ELIMINATED_STATE) {
          onAnimationEnd();
        }
        return prev;
      }),
    [onAnimationEnd],
  );

  useEffect(() => {
    setAnimationState(show ? SKULL_STATE : null);
  }, [show]);

  return (
    <>
      <Skull
        size={size}
        show={animationState === SKULL_STATE}
        onAnimationEnd={onAnimationStateChange}
      />
      <EliminatedInfo
        actorId={actorId}
        victimId={victimId}
        size={size}
        isHomeTeam={isHomeTeam}
        show={animationState === ELIMINATED_STATE}
        weapon={weapon}
        onAnimationEnd={onAnimationStateChange}
      />
    </>
  );
};

DeadPlayerInfo.propTypes = {
  size: sizesShape,
  gameId: PropTypes.string.isRequired,
  actorId: PropTypes.string,
  victimId: PropTypes.string,
  weapon: PropTypes.string,
  show: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
};

export default DeadPlayerInfo;
