import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';

const emptyObject = {};

const getState = state => state;

const makeGetFeaturedContents = () =>
  createSelector([getState], state => state.featured ?? emptyObject);

export const useGetFeaturedContentsMap = () => useMemoizedGqlSelector(makeGetFeaturedContents);

export default makeGetFeaturedContents;
