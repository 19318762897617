import { RefObject, useCallback, useMemo, useState } from 'react';
import useKeyBindings from 'znipe-hooks/useKeyBindings';
import { useEmojiSearch } from './useEmojiSearch';
import { Emoji } from '../components/Emojis/Emojis.types';

const useEmojiAutoComplete = (
  query: string,
  onSelect?: (shortcode: string, action: string, trigger: string) => void,
) => {
  const searchResults = useEmojiSearch(query);
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);

  const selectFocusedItem = useCallback(() => {
    if (!onSelect || !searchResults) return;
    const focusedResult: Emoji = searchResults[focusedItemIndex];
    onSelect(focusedResult?.shortcode, 'emoji_select', 'auto_complete');
  }, [focusedItemIndex, searchResults, onSelect]);

  const focusPrevItem = useCallback(() => {
    setFocusedItemIndex(prev => {
      if (prev === -1) return 0;
      return prev > 0 ? prev - 1 : prev;
    });
  }, []);

  const focusNextItem = useCallback(() => {
    setFocusedItemIndex(prev => (prev < searchResults.length - 1 ? prev + 1 : prev));
  }, [searchResults]);

  const keyBindings = useMemo(
    () => ({
      Enter: selectFocusedItem,
      Tab: selectFocusedItem,
      ArrowUp: focusPrevItem,
      ArrowDown: focusNextItem,
    }),
    [focusNextItem, focusPrevItem, selectFocusedItem],
  );

  const containerRef = useKeyBindings(keyBindings, false) as RefObject<HTMLDivElement>;

  return {
    containerRef,
    focusedItemIndex,
    searchResults,
  };
};

export default useEmojiAutoComplete;
