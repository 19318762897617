import styled from 'styled-components';
import Headline from 'tv-modules/Onboarding/Headline/Headline';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  max-width: 472px;

  @media ${device.tablet} {
    max-width: 656px;
  }
`;

export const CoverFlourishWrapper = styled.div`
  margin-bottom: 32px;
`;

export const CoverHeadline = styled(Headline)`
  font-size: 44px;
  line-height: 44px;
  margin-bottom: 24px;
`;

export const ParagraphsWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 304px;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 100%;
`;
