import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import gradients from 'znipe-styles/gradients';
import prefers from 'znipe-styles/prefers';

export const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  @media (min-width: 670px) {
    width: 560px;
  }
  @media ${device.tablet} {
    width: 656px;
  }
  @media ${device.desktop} {
    width: 1040px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }
  @media ${device.desktopLarge} {
    width: 1232px;
  }
  @media ${device.desktopExtraLarge} {
    width: 1648px;
  }
`;

export const PlayerWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const IconWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  margin: 6px 12px;
  @media ${device.desktop} {
    height: 45%;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const LeftSection = styled.div`
  ${({ $showMini }) =>
    $showMini &&
    css`
      display: flex;
      position: relative;
      width: 100%;
      ${IconWrapper} {
        position: initial;
        display: flex;
        align-items: center;
        left: initial;
        bottom: initial;
        height: initial;
      }
    `};
  ${({ $isLoading }) =>
    $isLoading
      ? css`
          align-self: flex-end;
          margin-bottom: 8px;
          margin-right: 8px;
          display: flex;
          flex-grow: 1;
          @media ${device.desktop} {
            margin-bottom: 16px;
          }
        `
      : css`
          align-self: initial;
        `}
  > div:last-child {
    z-index: -1;
  }
`;

export const RightSection = styled.div`
  ${({ $isSubscribed, $isHovering }) =>
    $isSubscribed &&
    $isHovering &&
    css`
      > button:hover > div {
        background-color: red;
      }
    `}
  ${({ $showMini }) =>
    $showMini
      ? css`
          /* 
      If topic header is scrolled, show the small version and position
      the button in the middle of the div
       */
          display: none;
          @media ${device.tablet} {
            display: flex;
            align-self: center;
          }
        `
      : css`
          margin-top: 8px;

          /* 
      If topic header is not scrolled, show the normal version and position
      the button in the top/bottom of the div
       */
          @media ${device.tablet} {
            margin-top: 0;
            margin-bottom: 26px;
            align-self: flex-end;
          }
        `};
`;

export const Background = styled.div`
  background: ${({ gradient, theme }) => gradient || theme.secondaryBackgroundColor};
  position: relative;
  overflow: hidden;
  align-self: center;
  height: ${({ $height }) => $height}px;
`;

export const ChampionImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ChampionImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ $width }) => $width || 400}px;
  height: auto;
  mask-image: ${gradients.imageFadeout};
  opacity: ${({ $showMini }) => ($showMini ? 0 : 1)};
  @media ${prefers.motion} {
    transition: opacity ease 500ms;
  }
`;
