import { useMemo } from 'react';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Typography from 'znipe-elements/general/Typography/Typography';

import {
  PreviewContainer,
  PreviewInnerContainer,
  PreviewImageContainer,
  TeamColor,
} from './AvatarPreview.styles';
import type { Size } from '../AvatarPicker';

type AvatarPreviewProps = {
  size: Size;
  label?: string;
  shortLabel?: string;
  img: string;
  isSelected: boolean;
  isHomeTeam?: boolean;
  isAwayTeam?: boolean;
  onClick?: () => void;
};

const AvatarPreview: React.FC<AvatarPreviewProps> = ({
  size,
  label = '',
  shortLabel = '',
  img,
  isSelected,
  isHomeTeam = false,
  isAwayTeam = false,
  onClick,
}) => {
  const { primaryTextColor } = useThemeContext();

  const selecedLabel = useMemo(
    () => (size === 'small' || size === 'medium' ? shortLabel ?? label : label),
    [size, shortLabel, label],
  );
  const labelType = useMemo(() => {
    if (size === 'medium') return 'heading-m';
    if (size === 'large') return 'heading-s';
    return 'heading-xs';
  }, [size]);
  return (
    <PreviewContainer
      data-testid="avatar-picker-preview"
      $isSelected={isSelected}
      onClick={onClick}
    >
      <TeamColor $isHomeTeam={isHomeTeam} $isAwayTeam={isAwayTeam} />
      <PreviewInnerContainer $size={size}>
        <PreviewImageContainer>
          <img src={img} alt="avatar" />
        </PreviewImageContainer>
        <Typography type={labelType} color={primaryTextColor}>
          {selecedLabel}
        </Typography>
      </PreviewInnerContainer>
    </PreviewContainer>
  );
};

export default AvatarPreview;
