import styled, { css } from 'styled-components';
import prefers from 'znipe-styles/prefers';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const ArrowWrapper = styled(IconWrapper)`
  height: 16px;
  @media ${prefers.motion} {
    transition: 200ms ease;
  }
  ${({ type }) =>
    type === 'right'
      ? css`
          margin-right: 4px;
        `
      : css`
          margin-left: 4px;
        `};
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MarkerWrapper = styled.div`
  margin-right: ${({ hasLeftArrow }) => (hasLeftArrow ? 8 : 16)}px;
`;

export const Container = styled.div.attrs(() => ({
  type: 'button',
}))`
  ${buttonDefaultStyle}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid
    ${({ withUnderline, theme }) => (withUnderline ? theme.tertiaryBackgroundColor : 'transparent')};
  &:hover,
  &:focus {
    ${ArrowWrapper} {
      transform: translateX(${({ arrowType }) => (arrowType === 'right' ? 4 : -4)}px);
    }
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;
