import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetVideoById from 'tv-selectors/video/makeGetVideo';

const makeGetIsVideoLocked = () => {
  const getVideo = makeGetVideoById();
  return createSelector([getVideo], video => video.locked ?? false);
};

export const useGetIsVideoLocked = props => useMemoizedGqlSelector(makeGetIsVideoLocked, props);

export default makeGetIsVideoLocked;
