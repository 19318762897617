import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.h2`
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;

export const SVG = styled.svg`
  align-self: center;
  margin: 20px 0 45px 0;
  height: 120px;
`;

export const ErrorImageWrapper = styled.div`
  align-self: center;
  margin: 30px 0 60px 0;
`;
