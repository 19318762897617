import { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';

const noOffsets = {};

const useGameStreamOffsets = (matchId, gameId) => {
  const [offsets, setOffsets] = useState({});

  const handleValue = useCallback(snapshot => {
    setOffsets(snapshot.val() || {});
  }, []);

  useEffect(() => {
    if (!matchId || !gameId) return () => null;
    const ref = firebase.database().ref(`/matches/${matchId}/games/${gameId}/offsets`);

    ref.on('value', handleValue);

    return () => ref.off('value', handleValue);
  }, [matchId, gameId, handleValue]);

  return offsets || noOffsets;
};

export default useGameStreamOffsets;
