import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

const extendedCss = css`
  color: ${({ theme, color }) => theme[color] || colors[color] || color};
  text-align: ${({ align }) => align ?? 'inherit'};
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}
`;

const getSizeStyle = (type, size) => css`
  ${({ theme: { typography } }) => {
    const { fontSize, lineHeight, fontFamily, letterSpacing, fontWeight } = typography[type][size];
    return css`
      font-size: ${fontSize};
      line-height: ${lineHeight};
      ${type === 'paragraph' && `margin-bottom: ${lineHeight};`}
      font-family: ${fontFamily};
      letter-spacing: ${letterSpacing};
      font-weight: ${fontWeight};
    `;
  }}
`;

const baseStyle = {
  heading: css`
    font-style: normal;
    font-weight: 600;
    margin: 0;
  `,
  paragraph: css`
    font-style: normal;
    font-weight: normal;
    margin-top: 0px;
  `,
  title: css`
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  `,
};

const headings = {
  'heading-xl': styled.h1`
    ${baseStyle.heading}
    ${getSizeStyle('heading', 'xl')}
    ${extendedCss};
  `,
  'heading-l': styled.h2`
    ${baseStyle.heading}
    ${getSizeStyle('heading', 'l')}
    ${extendedCss};
  `,
  'heading-m': styled.h3`
    ${baseStyle.heading}
    ${getSizeStyle('heading', 'm')}
    ${extendedCss};
  `,
  'heading-s': styled.h4`
    ${baseStyle.heading}
    ${getSizeStyle('heading', 's')}
    ${extendedCss};
  `,
  'heading-xs': styled.h5`
    ${baseStyle.heading}
    ${getSizeStyle('heading', 'xs')}
    ${extendedCss};
  `,
};
const paragraphs = {
  'paragraph-l': styled.p`
    ${baseStyle.paragraph}
    ${getSizeStyle('paragraph', 'l')}
    ${extendedCss};
  `,
  'paragraph-m': styled.p`
    ${baseStyle.paragraph}
    ${getSizeStyle('paragraph', 'm')}
    ${extendedCss};
  `,
  'paragraph-s': styled.p`
    ${baseStyle.paragraph}
    ${getSizeStyle('paragraph', 's')}
    ${extendedCss};
  `,
};
const titles = {
  'title-xxl': styled.span`
    ${baseStyle.title}
    ${getSizeStyle('title', 'xxl')}
    ${extendedCss};
  `,
  'title-xl': styled.span`
    ${baseStyle.title}
    ${getSizeStyle('title', 'xl')}
    ${extendedCss};
  `,
  'title-l': styled.span`
    ${baseStyle.title}
    ${getSizeStyle('title', 'l')}
    ${extendedCss};
  `,
  'title-m': styled.span`
    ${baseStyle.title}
    ${getSizeStyle('title', 'm')}
    ${extendedCss};
  `,
  'title-s': styled.span`
    ${baseStyle.title}
    ${getSizeStyle('title', 's')}
    ${extendedCss};
  `,

  'title-xs': styled.span`
    ${baseStyle.title}
    ${getSizeStyle('title', 'xs')}
    ${extendedCss};
  `,
};

const types = { ...headings, ...paragraphs, ...titles };

export const titleTypeNames = Object.keys(titles);
export const paragraphTypeNames = Object.keys(paragraphs);

export default types;
