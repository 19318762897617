import convertSecondsToHMS from './convertSecondsToHMS';

const convertTimestampToReadableTime = (time, fallback = '00:00') => {
  const convertedTime = convertSecondsToHMS(time);
  if (!convertedTime) return fallback;

  const { hours, minutes, seconds } = convertedTime;
  const hoursString = hours > 99 ? `${hours}` : `0${hours}`.slice(-2);
  const minutesString = `0${minutes}`.slice(-2);
  const secondsString = `0${seconds}`.slice(-2);

  let timeString = `${minutesString}:${secondsString}`;
  if (fallback === '00:00:00' && hours === 0) return `00:${timeString}`;
  if (hours > 0) {
    timeString = `${hoursString}:${timeString}`;
  }

  if (time < 0) timeString = `-${timeString}`;

  return timeString;
};

export default convertTimestampToReadableTime;
