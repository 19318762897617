import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import Matchup from 'tv-modules/Standings/Matchup/Matchup';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, Title, ColumnContainer, Column, BracketItem } from '../Bracket.styles';
import { GRAND_FINAL, columnPropShape } from '../utils/constants';

const BracketFinal = ({
  title = GRAND_FINAL,
  column = {
    roundNumber: 1,
    matchups: [],
  },
  type,
  height = 'inherit',
  paddingTop = '',
}) => {
  const { roundNumber, matchups, matchId } = column;
  const { teamOne, teamTwo, winner } = matchups[0];
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const typographyType = useMemo(
    () => (isDesktopOrGreater ? 'title-l' : 'title-m'),
    [isDesktopOrGreater],
  );
  const columnItem = useMemo(
    () => (
      <BracketItem bracketType="final" active={false}>
        <Matchup
          tournamentType={type}
          teamOne={teamOne}
          teamTwo={teamTwo}
          winner={winner}
          matchId={matchId}
        />
      </BracketItem>
    ),
    [type, teamOne, teamTwo, winner, matchId],
  );

  return (
    <Container data-testid="grand-final" height={height} padding={paddingTop}>
      <Title bracketType="final" data-testid="grand-final-title">
        <Typography type={typographyType}>{title}</Typography>
      </Title>
      <ColumnContainer bracketType="final">
        <Column key={roundNumber.toString()} data-testid={`column-${roundNumber}`}>
          {columnItem}
        </Column>
      </ColumnContainer>
    </Container>
  );
};

BracketFinal.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  column: PropTypes.shape(columnPropShape),
  height: PropTypes.string,
  paddingTop: PropTypes.string,
};

export default memo(BracketFinal);
