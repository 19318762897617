import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import useIntensiveDispatch from 'tv-hooks/useIntensiveDispatch';
import factorySubscribeEpic from 'tv-utils/factorySubscribeEpic';
import firebase from 'firebase/app';
import useGatId from 'tv-hooks/useGatId';
import { TIME_SERIES_EVENT } from 'tv-reducers/intensive';
import { createPlayerObject } from 'tv-schema/intensive/player.normalizr';
import getTimeSeriesInfo from './utils/getTimeSeriesInfo';
import createCombinedId from './utils/createCombinedId';

export const SUBSCRIBE_TIME_SERIES = 'SUBSCRIBE_TIME_SERIES';
export const UNSUBSCRIBE_TIME_SERIES = 'UNSUBSCRIBE_TIME_SERIES';

export const useTimeSeriesEpic = (matchId, series) => {
  const gatId = useGatId(matchId);
  const dispatch = useIntensiveDispatch();
  useEffect(() => {
    if (!gatId || !series) return undefined;
    dispatch({ type: SUBSCRIBE_TIME_SERIES, gatId, series });
    return () => dispatch({ type: UNSUBSCRIBE_TIME_SERIES, gatId, series });
  }, [dispatch, gatId, series]);
};

export const getPlayers = (payload, gatId) =>
  payload?.[0]?.reduce((acc, player) => {
    if (!player) return acc;
    const playerId = player.participantID ?? player.playerID ?? player.playerId ?? player.id; // @TODO Do not use participantId later
    const teamId = createCombinedId(player.teamId ?? player.teamID, gatId);
    const name = player.name ?? player.summonerName;
    const playerObject = createPlayerObject({
      ...player,
      playerId: createCombinedId(playerId, gatId),
      teamId,
      name,
    });
    return [...acc, playerObject];
  }, []) ?? [];

const emptyObject = {};
const emptyArray = [];

const prepareTeams = (
  newTeams = emptyArray,
  currentValues = emptyObject,
  currentTeams = emptyArray,
  gatId,
) => {
  const oldTeams = currentValues.teams ?? emptyArray;
  const updatedNewTeams = newTeams.filter(Boolean).map(team => ({
    ...team,
    id: createCombinedId(team.teamID ?? team.teamId ?? team.id, gatId),
  }));
  if (oldTeams.length < 1 && currentTeams.length < 1) return updatedNewTeams;
  const mappedOldTeams = oldTeams.reduce(
    (acc, team) => ({
      ...acc,
      [team.id]: team,
    }),
    {},
  );

  const updatedMap = updatedNewTeams.reduce((acc, team) => {
    if (currentTeams.includes(`${currentValues.gameId}:${currentValues.gameTime}:${team.id}`)) {
      return acc;
    }
    const { id } = team;
    const result = { ...acc };
    if (!acc[id]) {
      result[id] = team;
      return result;
    }
    Object.entries(team).forEach(([key, val]) => {
      if (result[id][key]) return;
      result[id][key] = val;
    });
    return result;
  }, mappedOldTeams);
  return Object.values(updatedMap);
};

const preparePlayers = (
  newPlayers = emptyArray,
  currentValues = emptyObject,
  currentPlayers = emptyArray,
  gatId,
) => {
  const oldPlayers = currentValues.players ?? emptyArray;
  const updatedNewPlayers = newPlayers.filter(Boolean).map(player => {
    const id = player.participantID ?? player.playerID ?? player.playerId ?? player.id; // @TODO Do not use participantId later
    return {
      ...player,
      id: createCombinedId(id, gatId),
      playerId: id,
      gold: player.gold ?? player.totalGold,
      name: player.ingameName ?? player.summonerName,
      teamId: createCombinedId(player.team ?? player.teamID ?? player.teamId, gatId),
    };
  });
  if (oldPlayers.length < 1 && currentPlayers.length < 1) return updatedNewPlayers;
  const mappedOldPlayers = oldPlayers.reduce(
    (acc, player) => ({
      ...acc,
      [player.id]: player,
    }),
    {},
  );

  const updatedMap = updatedNewPlayers.reduce((acc, player) => {
    if (currentPlayers.includes(`${currentValues.gameId}:${currentValues.gameTime}:${player.id}`)) {
      return acc;
    }
    const { id } = player;
    const result = { ...acc };
    if (!acc[id]) {
      result[id] = player;
      return result;
    }
    Object.entries(player).forEach(([key, val]) => {
      if (result[id][key]) return;
      result[id][key] = val;
    });
    return result;
  }, mappedOldPlayers);
  return Object.values(updatedMap);
};

export const groupTimeSeriesObject = (gatId, payload, state, series) => {
  const { timeSeriesEntries } = state;
  return payload.reduce((acc, entry) => {
    const { gameTime = 0, ...rest } =
      series === 'playerGold' ? { gameTime: entry[1].gameTime, players: entry } : entry; // Hack as playerGold has the wrong format
    const { id, gameTime: groupedGameTime } = getTimeSeriesInfo(gatId, gameTime / 1000);
    const oldEntry = timeSeriesEntries[id] ?? emptyObject;
    if (!acc[id]) {
      acc[id] = {
        id,
        gameTime: oldEntry.gameTime ?? groupedGameTime,
        gameId: oldEntry.gameId ?? gatId,
      };
    }

    Object.entries(rest).forEach(([key, value]) => {
      if (key === 'teams') {
        acc[id].teams = prepareTeams(value, acc[id], oldEntry.teamStats, gatId);
      } else if (key === 'players') {
        acc[id].players = preparePlayers(value, acc[id], oldEntry.playerStats, gatId);
      }
    });
    if (!acc[id].teams) acc[id].teams = [];
    if (!acc[id].players) acc[id].players = [];
    return acc;
  }, {});
};

const timeSeriesEpic = factorySubscribeEpic(
  [SUBSCRIBE_TIME_SERIES, UNSUBSCRIBE_TIME_SERIES],
  ({ gatId, series }) => `${gatId}-${series}`,
  ({ gatId, series }) =>
    fromEvent(firebase.database().ref(`/stats/${gatId}/timeSeries/${series}`), 'child_added').pipe(
      map(([snap]) => snap.val()),
    ),
  ({ gatId, series }, state$) =>
    payload => {
      const state = state$.value;
      const timeSeries = groupTimeSeriesObject(gatId, payload, state, series);
      const players = series === 'playerGold' ? getPlayers(payload, gatId) : emptyArray;
      return {
        type: TIME_SERIES_EVENT,
        gatId,
        payload: { id: gatId, timeSeries: Object.values(timeSeries), players },
      };
    },
);

export default timeSeriesEpic;
