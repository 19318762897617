import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageSkeleton from 'znipe-elements/feedback/ImageSkeleton/ImageSkeleton';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';

export const CIRCLE = 'circle';
export const SQUARE = 'square';
export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const AUTO = 'auto';

const GroupWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
  div:first-child {
    margin-bottom: ${({ $lineOneMarginBottom }) => $lineOneMarginBottom}px;
  }
`;

const AvatarWithTitlesSkeleton = ({ avatarShape = CIRCLE, size = SMALL }) => {
  const {
    avatarSize,
    linesMarginLeft,
    linesMarginBottom,
    lineOneMarginBottom,
    lineOneWidth,
    lineTwoWidth,
  } = useMemo(() => {
    if (size === LARGE) {
      return {
        avatarSize: MEDIUM,
        linesMarginLeft: 24,
        linesMarginBottom: 3,
        lineOneMarginBottom: 18,
        lineOneWidth: 130,
        lineTwoWidth: 90,
      };
    }
    if (size === MEDIUM) {
      return {
        avatarSize: MEDIUM,
        linesMarginLeft: 24,
        linesMarginBottom: 15,
        lineOneMarginBottom: 14,
        lineOneWidth: 120,
        lineTwoWidth: 80,
      };
    }
    if (size === SMALL) {
      return {
        avatarSize: SMALL,
        linesMarginLeft: 16,
        linesMarginBottom: 4,
        lineOneMarginBottom: 14,
        lineOneWidth: 80,
        lineTwoWidth: 60,
      };
    }
    return {
      avatarSize: XSMALL,
      linesMarginLeft: 16,
      linesMarginBottom: 4,
      lineOneMarginBottom: 10,
      lineOneWidth: 67,
      lineTwoWidth: 47,
    };
  }, [size]);

  return (
    <GroupWrapper data-testid="avatar-titles-skeleton">
      <ImageSkeleton size={avatarSize} shape={avatarShape} />
      <TextWrapper
        data-testid="text-wrapper-skeleton"
        $marginLeft={linesMarginLeft}
        $marginBottom={linesMarginBottom}
        $lineOneMarginBottom={lineOneMarginBottom}
      >
        <TextSkeleton size={size === XSMALL ? SMALL : size} width={lineOneWidth} />
        <TextSkeleton size={size === XSMALL ? SMALL : size} width={lineTwoWidth} />
      </TextWrapper>
    </GroupWrapper>
  );
};

AvatarWithTitlesSkeleton.propTypes = {
  avatarShape: PropTypes.oneOf([CIRCLE, SQUARE]),
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM, LARGE]),
};

export default AvatarWithTitlesSkeleton;
