export const breakpoints = {
  mobilePortraitSmall: 320,
  mobilePortrait: 375,
  mobileLandscape: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
  desktopLarge: 1920,
  desktopExtraLarge: 2560,
  desktop4K: 3840,
};

export const device = {
  mobilePortraitSmall: `(min-width: ${breakpoints.mobilePortraitSmall}px)`,
  mobilePortrait: `(min-width: ${breakpoints.mobilePortrait}px)`,
  mobileLandscape: `(min-width: ${breakpoints.mobileLandscape}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px)`,
  laptop: `(min-width: ${breakpoints.laptop}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
  desktopLarge: `(min-width: ${breakpoints.desktopLarge}px)`,
  desktopExtraLarge: `(min-width: ${breakpoints.desktopExtraLarge}px)`,
  desktop4K: `(min-width: ${breakpoints.desktop4K}px)`,
};

export default breakpoints;
