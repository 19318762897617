import { schema } from 'normalizr';
import character from './character.normalizr';

export const createPlayerObject = (
  { name, steamId, playerId, id, teamId, ...rest },
  extraFields = [],
) => ({
  id: id ?? playerId,
  name,
  steamId,
  teamId,
  ...extraFields.reduce((extra, key) => ({ ...extra, [key]: rest[key] }), {}),
});

const player = new schema.Entity(
  'players',
  {
    character,
  },
  {
    idAttribute: ({ id, playerId }) => id ?? playerId,
    processStrategy: values =>
      Object.entries(values).reduce((acc, [key, val]) => {
        if (key === 'stats') Object.assign(acc, val ?? {});
        else if (typeof val !== 'undefined' && val !== null) acc[key] = val;
        return acc;
      }, {}),
  },
);

export default player;
