import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { ControlIconsContainer, IconWrapper } from '../ScreenControls.styles';

const SMALL = 'small';
const MEDIUM = 'medium';

const iconSize = {
  [SMALL]: {
    skipTime: 24,
    playPause: 32,
  },
  [MEDIUM]: {
    skipTime: 32,
    playPause: 40,
  },
};

const MobileVideoControls = ({
  size = SMALL,
  playing,
  onPlayPause = () => {},
  onRewind = () => {},
  onSkip = () => {},
}) => {
  const { skipTime, playPause } = iconSize[size];
  return (
    <ControlIconsContainer size={size}>
      <IconWrapper onClick={onRewind}>
        <Icon type="rewindTenSeconds" size={skipTime} />
      </IconWrapper>
      <IconWrapper onClick={onPlayPause} data-testid="play-button">
        <Icon type={playing ? 'pause' : 'play'} size={playPause} />
      </IconWrapper>
      <IconWrapper onClick={onSkip}>
        <Icon type="skipTenSeconds" size={skipTime} />
      </IconWrapper>
    </ControlIconsContainer>
  );
};

MobileVideoControls.propTypes = {
  playing: PropTypes.bool.isRequired,
  size: PropTypes.oneOf([SMALL, MEDIUM]),
  onPlayPause: PropTypes.func,
  onRewind: PropTypes.func,
  onSkip: PropTypes.func,
};

export default memo(MobileVideoControls);
