import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import { BottomBorder, BottomCornerBorders, TopCornerBorders } from './Button.styles';

export const TextVariant = ({ hovering, color }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [{ width, opacity }, set] = useSpring(() => ({
    width: '0%',
    opacity: 0,
    immediate: prefersReducedMotion,
    config: { duration: 100 },
  }));

  useEffect(() => {
    set({ width: hovering ? '80%' : '0%', opacity: hovering ? 1 : 0 });
  }, [hovering, set]);

  return <BottomBorder data-testid="bottom-border" style={{ width, opacity }} $color={color} />;
};

TextVariant.propTypes = {
  hovering: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export const FeatureVariant = ({ size }) => (
  <>
    <TopCornerBorders data-testid="top-corner-borders" $size={size} />
    <BottomCornerBorders data-testid="bottom-corner-borders" $size={size} />
  </>
);

FeatureVariant.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']).isRequired,
};
