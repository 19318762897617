import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Header, IconWrapper } from '../BlockContainer.styles';

const CardTitle = ({
  icon = '',
  title = '',
  onClick = null,
  position = 'front',
  size = 'small',
  children = null,
}) => {
  const typographySize = useMemo(() => {
    switch (size) {
      case 'large':
        return 'm';
      case 'medium': {
        return 's';
      }
      default:
        return 'xs';
    }
  }, [size]);

  const iconSize = useMemo(() => {
    switch (size) {
      case 'large':
        return 24;
      default:
        return 16;
    }
  }, [size]);

  return (
    <Header data-testid="header">
      {icon && <Icon type={icon} size={iconSize} inline />}
      {title && (
        <Typography dataTestId="title" type={`title-${typographySize}`}>
          {title}
        </Typography>
      )}
      {onClick !== null && (
        <IconWrapper data-testid="info-wrapper" onClick={onClick}>
          <Icon type={position === 'back' ? 'close' : 'info'} size={iconSize} inline />
        </IconWrapper>
      )}
      {children && children}
    </Header>
  );
};

CardTitle.propTypes = {
  icon: PropTypes.string,
  position: PropTypes.oneOf(['front', 'back']),
  title: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node,
};

export default memo(CardTitle);
