import { useMemo } from 'react';
import PremiumIcon from 'znipe-elements/data-display/PremiumIcon/PremiumIcon';
import { Container, ImageArea, CenterArea } from './SelectorVertical.styles';
import { PremiumIconWrapper } from '../Selector.styles';
import useKDAComponent from '../useKDAComponent';
import useLabelTypography from '../useLabelTypography';
import { propTypes, SMALL } from '../constants';

const SelectorVertical = ({
  imageArea,
  imageType,
  kda,
  game,
  label,
  size = 'small',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
}) => {
  const kdaComponent = useKDAComponent(kda, game, 'xsmall', imageType === 'portrait');

  const labelOptions = useMemo(() => {
    const baseOptions = {
      textAlign: 'center',
      color: 'secondaryTextColor',
      type: 'heading-xs',
      maxWidth: '90%',
    };
    if (size === SMALL) return { ...baseOptions, maxSize: 10 };
    return baseOptions;
  }, [size]);

  const labelComponent = useLabelTypography(label, labelOptions);

  return (
    <Container
      $size={size}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      data-testid={testId}
      $isSelected={isSelected}
    >
      {labelComponent}
      <CenterArea $center={!kdaComponent && imageType === 'image'} $imageType={imageType}>
        <ImageArea $size={size} $imageType={imageType}>
          {imageArea}
        </ImageArea>
        {kdaComponent}
      </CenterArea>
      {isLocked && !hasPremiumAccess && (
        <PremiumIconWrapper>
          <PremiumIcon size={SMALL} />
        </PremiumIconWrapper>
      )}
    </Container>
  );
};

SelectorVertical.propTypes = propTypes;

export default SelectorVertical;
