import styled, { css } from 'styled-components';
import { buttonBasicStyles } from 'tv-modules/Player/ScreenOverlay/ScreenOverlay.styles';

export const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ControlIconWrapper = styled.div`
  margin: auto;
`;

export const ControlLabelWrapper = styled.div`
  margin-top: 8px;
  text-align: center;
`;

export const ReplayContainer = styled.div`
  width: 75px;
  height: 75px;
`;

export const IconWrapper = styled.button`
  ${buttonBasicStyles};
  padding: 0;
  height: 100%;
  user-select: none;
  cursor: pointer;
`;

export const ControlIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ size }) =>
    size === 'small'
      ? css`
          width: 152px;
          height: 32px;
          ${IconWrapper} {
            width: 24px;
            &:nth-of-type(2) {
              width: 32px;
            }
          }
        `
      : css`
          width: 216px;
          height: 40px;
          ${IconWrapper} {
            width: 32px;
            &:nth-of-type(2) {
              width: 40px;
            }
          }
        `}
`;
