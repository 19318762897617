import { createSelector } from 'reselect';
import makeGetChannel from 'tv-selectors/channels/makeGetChannel';
import makeGetGameMap from 'tv-selectors/games/makeGetGameMap';

const emptyMap = {};
const getGameId = (_state, props) => props.gameId;

const makeGetNextChannelGame = () => {
  const getChannel = makeGetChannel();
  const getGameMap = makeGetGameMap();

  return createSelector([getChannel, getGameMap, getGameId], (channel, gamesMap, gameId) => {
    const { games } = channel;

    if (!games) return emptyMap;
    const gameIndex = games.indexOf(gameId);
    if (gameIndex < 0) return emptyMap;
    const nextChannelGameId = games[gameIndex + 1];
    return gamesMap[nextChannelGameId] ?? emptyMap;
  });
};

export default makeGetNextChannelGame;
