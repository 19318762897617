import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';

const getTeamIdFromProps = (_, props) => props.teamId ?? '';

const makeGetTeamShortName = () => {
  const getTeams = makeGetTeams();
  return createSelector(
    [getTeams, getTeamIdFromProps],
    (allTeams, teamId) => allTeams?.[teamId]?.shortName ?? '',
  );
};

export const useGetTeamShortName = props => useMemoizedGqlSelector(makeGetTeamShortName, props);

export default makeGetTeamShortName;
