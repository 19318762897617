import { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledCard } from '../BlockContainer.styles';

const Card = ({ children, position }) => (
  <StyledCard position={position} data-testid={`card-${position}`}>
    {children}
  </StyledCard>
);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  position: PropTypes.oneOf(['front', 'back']).isRequired,
};

const stopInfoRender = prevProps =>
  // Prevents a rerender of the "back" info card
  // It's static and doesn't need to render again
  prevProps.position === 'back';
export default memo(Card, stopInfoRender);
