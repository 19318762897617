import produce from 'immer';
import { SET_SEARCH_HISTORY_QUERY, SET_SEARCH_HISTORY_RESULT } from 'tv-constants/redux';

export const initialState = {
  historyQueries: [],
  historyResults: [],
};

const searchReducer = produce((state, payload = {}) => {
  const { type } = payload;
  switch (type) {
    case SET_SEARCH_HISTORY_QUERY: {
      const { query = '' } = payload;
      const queries = state.historyQueries || [];
      queries.unshift(query);
      if (queries.length > 3) queries.pop();
      return state;
    }

    case SET_SEARCH_HISTORY_RESULT: {
      const { result, category } = payload;
      const searchId = result?.id;
      const historyResults = state.historyResults || [];
      const alreadyInTheHistory = searchId && historyResults.find(item => item.id === searchId);
      if (alreadyInTheHistory) return state;
      historyResults.unshift({ ...result, category });
      if (historyResults.length > 3) historyResults.pop();
      return state;
    }

    default:
      return state;
  }
}, initialState);

export default searchReducer;
