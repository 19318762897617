import colors from 'znipe-styles/colors';

export default {
  proview: {
    color: colors.riotBackgroundRule,
    backgroundColor: colors.white,
    backgroundImageTablet:
      'https://assets.znipe.tv/backgrounds/pro-view/riot_desktop_background_1x.jpg',
    backgroundImageDesktop:
      'https://assets.znipe.tv/backgrounds/pro-view/riot_desktop_background_2x.jpg',
  },
  default: {
    color: colors.riotBackgroundRule,
    backgroundColor: colors.white,
    backgroundImageTablet:
      'https://assets.znipe.tv/backgrounds/pro-view/riot_desktop_background_1x.jpg',
    backgroundImageDesktop:
      'https://assets.znipe.tv/backgrounds/pro-view/riot_desktop_background_2x.jpg',
  },
};
