import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  position: relative;
  background: ${props => props.theme.secondaryBackgroundColor};
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 238px;
  padding: 0 16px 8px 16px;
  ${props =>
    props.size === 'small' &&
    css`
      > div:not(:last-child) {
        margin-bottom: 24px;
      }
    `}
`;

export const StatusLabelWrapper = styled.div`
  position: relative;
  min-height: 15px;
  min-width: 45px;
`;

export const TitleWrapper = styled.div`
  max-width: ${props => (props.size === 'large' ? '383px' : '272px')};
  text-align: center;
  color: ${props => props.theme.primaryTextColor};
  margin-bottom: 8px;
`;

export const DescriptionWrapper = styled.div`
  color: ${props => props.theme.secondaryTextColor};
`;

export const ButtonWrapper = styled.div`
  margin: 24px auto;
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto;
`;

export const FloatingBackgroundImageWrapper = styled.div`
  display: ${props => (props.size === 'large' ? 'block' : 'none')};
  position: absolute;
  bottom: 0;
  opacity: 0.2;
  color: #ffcc7f;
  margin-bottom: -15px;
  ${props =>
    props.position === 'right'
      ? css`
          margin-right: -36px;
          transform: scaleX(-1);
        `
      : css`
          margin-left: -40px;
        `}
  ${props => css`
    ${props.position}: 0;
    height: ${props.position === 'left' ? 200 : 175}px;
  `}
`;
