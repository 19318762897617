import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import useVideoControl from 'znipe-watch/src/hooks/useVideoControl';
import ControlButton from 'znipe-watch/src/modules/PlayerControls/ControlButtons/ControlButton/ControlButton';

const RewindTenSecondsButton = ({ playerRef = playerDefaultProps, iconSize }) => {
  const videoControlFromRef = useVideoControl(playerRef);

  const rewindTenSeconds = useCallback(() => {
    videoControlFromRef.seek(-10);
  }, [videoControlFromRef]);

  return (
    <ControlButton
      toolTip="Rewind 10 seconds"
      icon="rewindTenSeconds"
      iconSize={iconSize}
      onClick={rewindTenSeconds}
    />
  );
};

RewindTenSecondsButton.propTypes = {
  playerRef: playerPropTypes,
  iconSize: PropTypes.number,
};

export default memo(RewindTenSecondsButton);
