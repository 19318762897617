import styled from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';

const gradientBackgroundHeight = {
  small: 56,
  medium: 88,
};

export const GradientWrapper = styled.div`
  background: ${({ $background }) => $background};
  height: ${({ $size }) => gradientBackgroundHeight[$size]}px;
  overflow: hidden;
`;

const predictionWrapperHeight = {
  small: 40,
  medium: 56,
};

export const OverflowHidden = styled.div`
  overflow: hidden;
`;

export const PredictionsHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: 30px;
  padding-right: 30px;
  height: ${({ $size }) => predictionWrapperHeight[$size]}px;
  padding-top: ${({ $size }) => gradientBackgroundHeight[$size] - predictionWrapperHeight[$size]}px;
`;

const iconTopPosition = {
  small: 0,
  medium: 32,
};

export const IconWrapper = styled.div`
  position: absolute;
  left: -22px;
  top: ${({ $size }) => iconTopPosition[$size]}px;
`;

export const ScoreWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
`;

const winWrapperWidthHeight = {
  small: 200,
  medium: 220,
};

export const WinBackground = styled.div`
  position: relative;
  left: -100px;
  top: -${({ $size }) => winWrapperWidthHeight[$size] / 2}px;
  width: ${({ $size }) => winWrapperWidthHeight[$size]}px;
  height: ${({ $size }) => winWrapperWidthHeight[$size]}px;
  margin: 0;
  background: ${({ $background }) => $background};
  transform: rotateY(0deg) rotate(-60deg);
`;

const winLooseTextTopOffsetPositionTop = {
  small: 6,
  medium: 22,
};

const looseTextTopOffsetPositionLeft = {
  small: '124px',
  medium: '156px',
};

export const WinLabel = styled(Typography)`
  position: absolute;
  background: ${({ $color }) => $color};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.7;
  top: ${({ $size }) =>
    predictionWrapperHeight[$size] / 2 + winLooseTextTopOffsetPositionTop[$size]}px;
  left: 40px;
`;

export const LoseLabel = styled(Typography)`
  position: absolute;
  color: ${({ theme }) => theme.textSecondary};
  opacity: 0.6;
  top: ${({ $size }) =>
    predictionWrapperHeight[$size] / 2 + winLooseTextTopOffsetPositionTop[$size]}px;
  left: ${({ $size }) => looseTextTopOffsetPositionLeft[$size]};
`;
