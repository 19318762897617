import { createSelector } from 'reselect';
import makeGetState from '../makeGetState';

const emptyObject = {};
const makeGetAuthMap = () => {
  const getState = makeGetState();
  return createSelector([getState], state => state.auth ?? emptyObject);
};

export default makeGetAuthMap;
