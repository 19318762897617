import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Helmet from 'tv-elements/general/Helmet/Helmet';
import helmetData from 'tv-elements/layout/PageLayout/helmetData';
import { useHostname } from 'tv-selectors/deviceInfo/makeGetHostname';

const createTitle = (packageName, pageLabel) => {
  const selectedData = helmetData[packageName];
  const defaultData = helmetData.default;

  const selectedTitle = selectedData?.title ?? defaultData.title;
  if (!pageLabel) return selectedTitle;

  return `${selectedTitle} | ${pageLabel}`;
};

const selectDescription = (packageName, pageLabel) => {
  const selectedData = helmetData[packageName];
  const defaultData = helmetData.default;

  const key = pageLabel?.toLowerCase();

  return (
    selectedData?.descriptions?.[key] ??
    defaultData.descriptions?.[key] ??
    defaultData.descriptions?.home
  );
};

const HelmetWrapper = ({ packageName, pageLabel }) => {
  const selectedData = helmetData[packageName];
  const defaultData = helmetData.default;
  const title = createTitle(packageName, pageLabel);
  const description = selectDescription(packageName, pageLabel);

  const hostname = useHostname();
  const { pathname, search } = useLocation();

  return (
    <Helmet
      title={title}
      description={description}
      favIcon={selectedData?.favicon ?? defaultData.favicon}
      url={`${hostname}${pathname}${search}`}
      image={selectedData?.thumbnail ?? defaultData.thumbnail}
      imageAlt={defaultData.thumbnail}
    />
  );
};

HelmetWrapper.propTypes = {
  packageName: PropTypes.string.isRequired,
  pageLabel: PropTypes.string,
};

export default HelmetWrapper;
