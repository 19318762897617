import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from './makeGetTournaments';
import makeGetMatchTournament from '../match/makeGetMatchTournament';
import makeGetTournamentId from './makeGetTournamentId';

const emptyObject = {};

const makeGetTournament = () => {
  const getTournaments = makeGetTournaments();
  const getMatchTournament = makeGetMatchTournament();
  const getTournamentId = makeGetTournamentId();
  return createSelector(
    [getTournaments, getTournamentId, getMatchTournament],
    (tournaments, tournamentId, matchTournamentId) =>
      tournaments[tournamentId] ?? tournaments[matchTournamentId] ?? emptyObject,
  );
};

export const useGetTournament = props => useMemoizedGqlSelector(makeGetTournament, props);

export default makeGetTournament;
