import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSearch from 'tv-selectors/search/makeGetSearch';

const emptyArray = [];

const makeGetSearchHistoryResults = () => {
  const getSearch = makeGetSearch();
  return createSelector([getSearch], search => search.historyResults) ?? emptyArray;
};

export const useGetSearchHistoryResults = () => useMemoizedGqlSelector(makeGetSearchHistoryResults);

export default makeGetSearchHistoryResults;
