import useThemeContext from 'znipe-hooks/useThemeContext';
import LoginCTA from 'tv-modules/Authentication/LoginCTA/LoginCTA';
import withTheme from 'znipe-themes/hocs/withTheme';

const theme = {
  default: {
    heading: 'Sign in to follow topics',
    description: 'You need to sign in order to follow topics',
    icon: 'znipeLogo',
  },
  proview: {
    heading: 'Sign in to follow topics',
    description: 'You need to sign in with your Riot account in order to follow topics.',
    icon: 'riotFistbump',
  },
};

const NotSingedIn = () => {
  const { notSignedIn } = useThemeContext();
  const { heading, description, icon } = notSignedIn;

  return <LoginCTA description={description} heading={heading} center icon={icon} />;
};

export default withTheme(NotSingedIn, theme, 'notSignedIn');
