export const heading = {
  X_LARGE: 'heading-xl',
  LARGE: 'heading-l',
  MEDIUM: 'heading-m',
  SMALL: 'heading-s',
  X_SMALL: 'heading-xs',
};

export const paragraph = {
  LARGE: 'paragraph-l',
  MEDIUM: 'paragraph-m',
  SMALL: 'paragraph-s',
};

export const title = {
  X_LARGE: 'title-xl',
  LARGE: 'title-l',
  MEDIUM: 'title-m',
  SMALL: 'title-s',
  X_SMALL: 'title-xs',
};

export const customSize = {
  X_LARGE: 'xlarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  X_SMALL: 'xSmall',
};
