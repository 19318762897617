import PropTypes from 'prop-types';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import gradients from 'znipe-styles/gradients';
import Link from 'znipe-elements/general/Link/Link';
import Icon from 'znipe-elements/general/Icon/Icon';
import getColorsFromGradientString from 'znipe-utils/misc/getColorsFromGradientString';
import Typography from 'znipe-elements/general/Typography/Typography';
import usePremiumMarketingLink from 'tv-hooks/usePremiumMarketingLink';
import useHasMounted from 'znipe-hooks/useHasMounted';

import {
  ButtonWrapper,
  IconWrapper,
  Container,
  TextComponentWrapper,
  PremiumLabelWrapper,
} from './FeaturedSubscribeButton.styles';

const iconColors = getColorsFromGradientString(gradients.glimmeringGold);

const ZapComponent = ({ type }) => (
  <IconWrapper $type={type} data-testid="featured-subscribe-button-zap-icon">
    <Icon type="zap" gradient fillColor={iconColors[0]} activeColor={iconColors[1]} />
  </IconWrapper>
);

ZapComponent.propTypes = {
  type: PropTypes.oneOf(['button', 'icon', 'text']).isRequired,
};

const FeaturedSubscribeButton = ({ label = 'Get Premium', type = 'button', packageName = '' }) => {
  const marketingLink = usePremiumMarketingLink(packageName);
  const isIconType = type === 'icon';
  const isButtonType = type === 'button';
  const isTextType = type === 'text';
  const mounted = useHasMounted();
  if (!mounted || !marketingLink) return null;
  if (isTextType) {
    return (
      <Container>
        <TextComponentWrapper>
          <ZapComponent type={type} />
          <PremiumLabelWrapper data-testid="featured-subscribe-button-label">
            <Typography type="title-s">{label}</Typography>
          </PremiumLabelWrapper>
        </TextComponentWrapper>
      </Container>
    );
  }
  return (
    <Container>
      <Link href={marketingLink} target="_blank" hideUnderline>
        {isIconType && <ZapComponent type={type} />}
        {isButtonType && (
          <ButtonWrapper data-testid="featured-subcribe-button-element">
            <Button
              variant="primary"
              size="small"
              icon="zap"
              iconGradient={gradients.glimmeringGold}
              color="#E4C282"
            >
              {label}
            </Button>
          </ButtonWrapper>
        )}
      </Link>
    </Container>
  );
};

FeaturedSubscribeButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['button', 'icon', 'text']),
  packageName: PropTypes.string,
};
export default FeaturedSubscribeButton;
