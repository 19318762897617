import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetPlayerName } from 'tv-selectors/players/makeGetPlayerName';
import { useGetPlayerImage } from 'tv-selectors/players/makeGetPlayerImage';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { makeGetSelectedPlayerStats } from 'tv-modules/Stats/LOL/PlayerStats/selectors';
import useIntensiveSelector from 'tv-hooks/useIntensiveSelector';
import useGatId from 'tv-hooks/useGatId';
import useHasMatchStarted from 'tv-hooks/useHasMatchStarted';
import createCombinedId from 'tv-epics/utils/createCombinedId';
import Selector from '../../Selector';

const PlayerSelector = ({
  size = 'small',
  playerId,
  matchId,
  teamId,
  streamId = '',
  gatPlayerPosition = null,
  team,
  isSelected = false,
  onClick,
  onHover = () => {},
  type = 'vertical',
  isLocked = false,
  hideChampionInfo = false,
}) => {
  const getPlayerStats = useMemo(() => makeGetSelectedPlayerStats(), []);
  const gatId = useGatId(matchId);
  const gatPlayerId = useMemo(
    () => createCombinedId(gatPlayerPosition + 1, gatId),
    [gatPlayerPosition, gatId],
  );
  const {
    kills = 0,
    assists = 0,
    deaths = 0,
    level = 0,
  } = useIntensiveSelector(state => getPlayerStats(state, { gatId, playerId: gatPlayerId }));

  const championImage = useIntensiveSelector(state => {
    const character = state?.players[gatPlayerId]?.character;
    return state.characters?.[character]?.image ?? '';
  });

  const hasMatchStarted = useHasMatchStarted(null, gatId);
  const playerName = useGetPlayerName({ playerId });
  const playerImage = useGetPlayerImage({ playerId });
  const playerTeamLogo = useGetSelectedTeamLogo({ teamId });
  const hasPickedChampion = !hideChampionInfo && Boolean(championImage) && hasMatchStarted;
  const kda = useMemo(
    () => (hasPickedChampion ? { kills, deaths, assists } : undefined),
    [kills, deaths, assists, hasPickedChampion],
  );

  return (
    <Selector
      kda={kda}
      teamLogo={playerTeamLogo}
      image={playerImage}
      championImage={hasPickedChampion ? championImage : undefined}
      streamType="player"
      isSelected={isSelected}
      label={playerName}
      level={level}
      onClick={onClick}
      onHover={onHover}
      playerId={playerId}
      size={size}
      streamId={streamId}
      teamSide={team}
      type={type}
      isLocked={isLocked}
      game="lol"
    />
  );
};

PlayerSelector.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf(['vertical', 'horizontal', 'slim-horizontal']),
  team: PropTypes.oneOf(['teamOne', 'teamTwo']),
  playerId: PropTypes.string.isRequired,
  matchId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  streamId: PropTypes.string,
  gatPlayerPosition: PropTypes.number,
  isSelected: PropTypes.bool,
  isLocked: PropTypes.bool,
  hideChampionInfo: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default memo(PlayerSelector);
