import type { Subscription } from 'rxjs';
import type { WebSocketSubject } from 'rxjs/webSocket';
import cj from './src/cj';

type GameType = {
  id: string;
  startTime: number;
};

export type MatchType = {
  id: string;
  games: GameType[];
};

type ProductionChannelMessage = {
  _id: string;
  forceRefresh: boolean;
  live: boolean;
  matches: MatchType[];
  onLiveChannelEnding?: {
    presentationTimestamp: number;
    matchId: string;
    gameId: string;
  };
};

type StatsOffsetsMessage = {
  statsOffset: number;
};

// @TODO
type SystemMessage = unknown;
type ChatMessage = unknown;

type ModuleTypeMap = {
  CHAT: {
    messageType: 'CHAT_MESSAGE';
    message: ChatMessage;
  };
  PRODUCTION_CHANNEL: {
    messageType: 'PRODUCTION_CHANNEL_MESSAGE';
    message: ProductionChannelMessage;
  };
  SYSTEM_MESSAGES: {
    messageType: 'SYSTEM_MESSAGES_MESSAGE';
    message: SystemMessage;
  };
  STATS_OFFSETS: {
    messageType: 'STATS_OFFSET_MESSAGE' | 'STATUS';
    message?: StatsOffsetsMessage;
  };
};

export type ModuleType = keyof ModuleTypeMap;

type AppMap = {
  chat: 'CHAT';
  channel: 'PRODUCTION_CHANNEL';
  systemMessages: 'SYSTEM_MESSAGES';
  statsOffsets: 'STATS_OFFSETS';
};

export type Message<T extends keyof ModuleTypeMap> = {
  type: ModuleTypeMap[T]['messageType'];
  channel: string;
  module: T;

  message: ModuleTypeMap[T]['message'];
};

type Callback<T extends keyof ModuleTypeMap> = (data: Message<T>) => void;

type EventType = 'value';

export type RefType<T extends keyof ModuleTypeMap> = {
  on: (event: EventType, callback: Callback<T>) => void;
  off: (event: EventType, callback: Callback<T>) => void;
  send: (data: unknown) => void;
};

export type Ref<T extends keyof ModuleTypeMap> = (channelId: string) => RefType<T>;

type Authenticate = (authToken: string) => void;

type Unauthenticate = (fullRest?: boolean) => void;

export type CjApp<T extends keyof ModuleTypeMap> = {
  ref: Ref<T>;
  authenticate: Authenticate;
  unauthenticate: Unauthenticate;
};

export type WebsocketInfo = {
  subscriptions: {
    [key: string]: {
      onMessage: (msg: string) => void;
      subscription: Subscription | null;
      callbacks: [(msg: string) => void];
    };
  };
  socket: WebSocketSubject<string> | null;
  authSubscription: {
    sub: Subscription | null;
    token?: string;
  };
};

const typedCj = <T extends keyof AppMap>(appName: T): CjApp<AppMap[T]> => cj(appName);

export default typedCj;
