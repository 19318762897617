import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export type Type = 'regular' | 'compact';

const Container = styled.div<{ $maxHeight?: string; $type?: Type }>`
  width: initial;
  height: 100%;
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
    `}
  overflow: auto;
  padding: 8px 4px 0 16px;
  display: flex;
  justify-content: center;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
  }

  @media ${device.desktopLarge} {
    padding: 16px;
  }

  ${({ $type }) =>
    $type === 'compact' &&
    css`
      padding-bottom: 16px;
    `}
`;

const ContentWrapper = styled.div<{ $allowScroll?: boolean; $type?: Type }>`
  width: 100%;
  max-width: 656px;
  height: 100%;
  padding-right: 4px;
  ${({ $allowScroll }) =>
    $allowScroll &&
    css`
      overflow: auto;
    `}

  ${({ $type }) => {
    if ($type === 'compact') {
      return css`
        > *:not(:last-child) {
          margin-bottom: 16px;
        }
      `;
    }
    return css`
      > * {
        margin-bottom: 16px;
      }
    `;
  }}
`;

type PopoverLayoutProps = {
  type?: Type;
  maxHeight?: string;
  allowScroll?: boolean;
  children?: React.ReactNode;
};

const PopoverLayout: React.FC<PopoverLayoutProps> = ({
  type = 'regular',
  children = null,
  maxHeight,
  allowScroll = true,
}) => (
  <Container $type={type} $maxHeight={maxHeight}>
    <ContentWrapper $type={type} $allowScroll={allowScroll}>
      {children}
    </ContentWrapper>
  </Container>
);

export default PopoverLayout;
