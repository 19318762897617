import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatchGamesIds from 'tv-selectors/match/makeGetMatchGamesIds';
import makeGetGameMap from 'tv-selectors/games/makeGetGameMap';
import makeGetSelectedMatchGameIndex from 'tv-selectors/match/makeGetSelectedMatchGameIndex';

const emptyObject = {};

const getGame = (state, { gameId }) => state?.games?.[gameId];

const makeGetSelectedGame = () => {
  const getGames = makeGetGameMap();
  const getMatchGamesIds = makeGetMatchGamesIds();
  const getSelectedMatchGameIndex = makeGetSelectedMatchGameIndex();
  return createSelector(
    [getGames, getMatchGamesIds, getSelectedMatchGameIndex, getGame],
    (allGames, selectedGames, selectedMatchGameIndex, game) => {
      if (game) return game;
      if (selectedGames.length === 0) return emptyObject;
      const games = selectedGames
        .reduce((output = [], gameId) => {
          output.push(allGames[gameId]);
          return output;
        }, [])
        .sort((a, b) => a.gameNumber - b.gameNumber);

      return games[selectedMatchGameIndex] ?? emptyObject;
    },
  );
};

export const useGetSelectedGame = props => useMemoizedGqlSelector(makeGetSelectedGame, props);

export default makeGetSelectedGame;
