import { getSelection, nodeWalk } from './DOMNode';

// getCaretSelection: return [start, end] as offsets to elem.textContent that
//   correspond to the selected portion of text
//   (if start == end, caret is at given position and no text is selected)
const getCaretPosition = (elem: Node): [number, number] | undefined => {
  const selection = getSelection(elem);
  const selectionPosition = [0, 0] as [number, number];

  if (!selection) return selectionPosition;

  if (selection.anchorNode === elem) {
    selectionPosition[0] = selection.anchorOffset;
    selectionPosition[1] = selection.focusOffset;
  } else {
    const nodesToFind = [selection.anchorNode, selection.focusNode];
    if (!elem.contains(selection.anchorNode) || !elem.contains(selection.focusNode)) {
      return undefined;
    }

    const found = [0, 0];
    nodeWalk(elem, (node: Node) => {
      for (let i = 0; i < 2; i++) {
        if (node === nodesToFind[i]) {
          found[i] = 1;
          if (found[i === 0 ? 1 : 0]) return false; // all done
        }
      }

      if (
        node.parentNode instanceof Element &&
        node.parentNode.getAttribute('contenteditable') === 'false'
      ) {
        return true;
      }

      if (node instanceof Element && node.getAttribute('contenteditable') === 'false') {
        for (let i = 0; i < 2; i++) {
          if (!found[i]) selectionPosition[i] += 1;
        }
      } else if (node.textContent && !node.firstChild) {
        for (let i = 0; i < 2; i++) {
          if (!found[i]) selectionPosition[i] += node.textContent.length;
        }
      }
      return true;
    });
    selectionPosition[0] += selection.anchorOffset;
    selectionPosition[1] += selection.focusOffset;
  }
  return selectionPosition;
};

export default getCaretPosition;
