import colors from 'znipe-styles/colors';
import { getThemableGradients } from 'znipe-styles/gradients';
import defaultTheme from './default.theme';
import { Theme } from './theme.types';

const theme: Theme = {
  ...defaultTheme,
  themeName: 'efg',
  mobileOnly: false,
  primaryFont: "'Titillium Web', sans-serif",
  secondaryFont: "'Roboto', sans-serif",

  // Background Colors
  primaryBackgroundColor: colors.efgBackgroundPrimary,
  secondaryBackgroundColor: colors.efgBackgroundSecondary,
  tertiaryBackgroundColor: colors.efgBackgroundTertiary,
  containerStroke: colors.efgContainerStroke,
  highlight: colors.efgHighlight,

  // Accents
  UIAccent: colors.efgUIAccent,
  teamOneColor: colors.efgTeamA,
  teamTwoColor: colors.efgTeamB,

  buttonPrimary: colors.efgUIAccent,

  // Gradients
  backgroundAvsB: colors.efgBackgroundSecondary,

  // TODO cleanup
  bodyBackground: colors.efgBackgroundPrimary,
  bodyColor: colors.white,
  borderColor: colors.efgContainerStroke,
  csgoTColor: colors.efgTeamA,
  csgoCTColor: colors.efgTeamB,
  overlayBackground: 'rgba(14,15,20,0.75)',
  popOverBackground: colors.efgContainerStroke,

  // avatar styles
  avatarXSmallSize: '30px',
  avatarSmallSize: '40px',
  avatarSmallSizeRound: '34px',
  avatarMediumSize: '50px',
  avatarLargeSize: '60px',
  avatarCustomSize: '45px',
  avatarBackgroundColor: colors.efgBackgroundPrimary,
  avatarLevelTextColor: colors.efgUIAccent,
  avatarLevelFont: "'Roboto', sans-serif",
  avatarLevelCircleBorderColor: colors.black,
  avatarLevelCircleBackgroundColor: colors.efgBackgroundSecondary,

  // a11y colors
  grey3: colors.grey75,
};

export default { ...theme, ...getThemableGradients(theme) } as Theme; // @TODO remove type assertion
