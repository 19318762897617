import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetDeviceInfo from './makeGetDeviceInfo';

const makeGetReferrer = () => {
  const getDeviceInfo = makeGetDeviceInfo();
  return createSelector([getDeviceInfo], deviceInfo => deviceInfo.referrer ?? '');
};

export const useReferrer = () => useMemoizedSelector(makeGetReferrer);

export default makeGetReferrer;
