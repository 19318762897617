import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const SidebarContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  @media ${device.tablet} {
    width: 320px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 64px 1fr;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  margin: 0 16px 12px 16px;
  padding-top: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 24px 0px;
  margin: 24px 0px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: 1fr;
  gap: 0px 8px;
  align-items: center;
`;

export const SubscribeButton = styled.div.attrs(() => ({
  type: 'button',
}))`
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const NoResultsWrapper = styled.div`
  margin: 20px 0;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  background: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const SearchContainer = styled.div`
  position: relative;
`;
