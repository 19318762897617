import { useMemo, memo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useVideoOffset from 'tv-hooks/useVideoOffset';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useGetStreamSrcOffsetId } from 'tv-selectors/streams/makeGetStreamSrcOffsetId';
import PremiumFeaturesModal from 'tv-modules/Premium/PremiumFeaturesModal/PremiumFeaturesModal';
import useStreamSrcWithToken from 'tv-hooks/useStreamSrcWithToken';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import PopoutStreamButton from 'tv-modules/Player/PopoutStreamButton/PopoutStreamButton';
import Player from 'znipe-player/src/components/MultiView/MultiView';
import { playerDefaultProps, playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import { useDebug } from 'tv-selectors/ui/makeGetDebug';
import {
  StreamContainer,
  StreamPreviewPlaceholder,
  PlaceholderVideo,
} from './StreamPreview.styles';

const posterSrc = {
  default: 'https://assets.znipe.tv/video-player/poster-image-proview-small.jpg',
  unavailable: 'https://assets.znipe.tv/video-player/poster-stream-disabled-proview.svg',
};

const useStreamVideoSyncRef = playerRef => {
  const streamPreviewSyncTarget = useRef({
    currentTime: playerRef.current?.getCurrentTime(),
    estimatedBandwidth: playerRef.current?.getStats()?.estimatedBandwidth,
  });
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return () => {};
    const updateSyncRef = e => {
      streamPreviewSyncTarget.current.currentTime = e.currentTime;
      streamPreviewSyncTarget.current.estimatedBandwidth = player.getStats()?.estimatedBandwidth;
    };
    player.addEventListener('timeupdate', updateSyncRef);
    return () => player.removeEventListener('timeupdate', updateSyncRef);
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [playerRef]);

  return streamPreviewSyncTarget;
};

const QUALITY_LIMIT = 1000000;

const StreamPreview = ({
  matchId,
  streamId,
  selectedMatchGameIndex,
  size,
  play = false,
  allowPopingOut = true,
  playerRef = playerDefaultProps,
  onStreamPopout = () => {},
  isLocked = false,
  onClickStream = () => {},
  isHoverable = true,
}) => {
  const streamPreviewPlayerRef = useRef(null);
  const matchGameId = useGetGameId({ matchId });
  const offsetId = useGetStreamSrcOffsetId({ streamId });
  const offset = useVideoOffset(matchId, matchGameId, offsetId);
  const externalSyncRef = useStreamVideoSyncRef(playerRef);
  const src = useStreamSrcWithToken(streamId, 'match');
  const isDebug = useDebug();
  const hasPremiumAccess = useHasPremiumAccess();

  const videos = [
    {
      id: streamId,
      src: play ? src : '',
      offset,
      master: true,
      muted: true,
      poster: streamId ? posterSrc.default : posterSrc.unavailable,
    },
  ];

  const getStartTime = useCallback(() => {
    if (!externalSyncRef.current) return undefined;
    const { currentTime = 0, offset: masterOffset = 0 } = externalSyncRef.current;
    if (currentTime < 1) return undefined;
    return currentTime + masterOffset;
  }, [externalSyncRef]);

  useEffect(() => {
    const player = streamPreviewPlayerRef.current;
    if (!player) return () => null;

    const handleQuality = () => {
      player.selectQuality(QUALITY_LIMIT); // Max bandwidth 1 Mbit/s
    };

    player.addEventListener('loaded', handleQuality);
    return () => player.removeEventListener('loaded', handleQuality);
  }, []);

  const showPremiumOverlay = useMemo(
    () => isLocked && !hasPremiumAccess,
    [isLocked, hasPremiumAccess],
  );

  const startStream = useCallback(() => {
    onClickStream();
  }, [onClickStream]);

  if (showPremiumOverlay)
    return (
      <StreamContainer size={size}>
        <StreamPreviewPlaceholder>
          <PremiumFeaturesModal isInteractable={isHoverable} />
          <PlaceholderVideo
            src="https://assets.znipe.tv/premium/premium_blur.mp4"
            autoPlay
            muted
            loop
          />
        </StreamPreviewPlaceholder>
      </StreamContainer>
    );

  return (
    <StreamContainer size={size}>
      <Player
        ref={streamPreviewPlayerRef}
        videos={videos}
        externalSyncRef={play ? externalSyncRef : undefined}
        startTime={getStartTime()}
        debug={isDebug}
        autoPlay
        maxPovs={1}
        onClick={startStream}
      />
      {streamId && allowPopingOut && (
        <PopoutStreamButton
          matchId={matchId}
          selectedMatchGameIndex={selectedMatchGameIndex}
          streamId={streamId}
          onWindowPopout={onStreamPopout}
        />
      )}
    </StreamContainer>
  );
};

StreamPreview.propTypes = {
  matchId: PropTypes.string.isRequired,
  streamId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  size: PropTypes.number,
  play: PropTypes.bool,
  allowPopingOut: PropTypes.bool,
  onStreamPopout: PropTypes.func,
  playerRef: playerPropTypes,
  isLocked: PropTypes.bool,
  isHoverable: PropTypes.bool,
  onClickStream: PropTypes.func,
};

export default memo(StreamPreview);
