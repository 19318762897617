import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import Icon from 'znipe-elements/general/Icon/Icon';
import IntroducePremium from 'tv-modules/Premium/IntroducePremium/IntroducePremium';
import PremiumBanner from 'tv-modules/Premium/PremiumBanner/PremiumBanner';
import useFormattedMonthlySubscriptionPrice from 'tv-hooks/useFormattedMonthlySubscriptionPrice';
import {
  Container,
  BackButtonWrpaper,
  RibbonWrapper,
  ContentWrapper,
} from './SubscribeToPremiumModal.styles';

const SubscribeToPremiumModal = ({ onClickBack }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const formattedPrice = useFormattedMonthlySubscriptionPrice();
  const ribbon = useMemo(
    () => (
      <RibbonWrapper>
        <PremiumBanner animate={false} />
      </RibbonWrapper>
    ),
    [],
  );
  return (
    <Container>
      {onClickBack && (
        <BackButtonWrpaper onClick={onClickBack}>
          <Icon type="chevronLeft" />
        </BackButtonWrpaper>
      )}
      {!isTabletOrGreater && ribbon}
      <ContentWrapper $hasBorder={isTabletOrGreater}>
        {isTabletOrGreater && ribbon}
        <IntroducePremium type="button" monthlyPrice={formattedPrice} />
      </ContentWrapper>
    </Container>
  );
};

SubscribeToPremiumModal.propTypes = {
  onClickBack: PropTypes.func,
};

export default memo(SubscribeToPremiumModal);
