import { Fragment, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuPanel from 'znipe-elements/layout/MenuPanel/MenuPanel';
import { useUsername } from 'tv-selectors/auth/makeGetUsername';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import {
  Container,
  Divider,
  Menu,
  BottomSection,
  MenuItemContainerRegularLink,
} from 'tv-modules/Settings/SettingsPanel/SettingsPanel.styles';
import FeaturedSubscribeButton from 'tv-modules/Premium/FeaturedSubscribeButton/FeaturedSubscribeButton';
import usePremiumMarketingLink from 'tv-hooks/usePremiumMarketingLink';
import LogoutButton from 'tv-modules/Authentication/LogoutButton/LogoutButton';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import LoginCTA from 'tv-modules/Authentication/LoginCTA/LoginCTA';
import useThemeContext from 'znipe-hooks/useThemeContext';

import FinePrint from './components/FinePrint';
import MenuItem from './components/MenuItem';

const loginCta = {
  default: {
    icon: 'znipeLogo',
    menuHeader: 'MY ZNIPE',
  },
  proview: {
    heading: 'Sign in with your riot account',
    icon: 'riotFistbump',
    menuHeader: 'MY PRO VIEW',
  },
};

const SettingsPanel = ({ onClose, packageSlug, packageName = '' }) => {
  const { themeName } = useThemeContext();
  const isAuthenticated = useIsAuthenticated();
  const userName = useUsername();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const marketingLink = usePremiumMarketingLink(packageName);

  const { icon, heading, menuHeader } = loginCta[themeName] ?? loginCta.default;

  const menuItems = useMemo(
    () => [
      {
        icon: 'creditCard',
        label: 'My Subscription',
        link: marketingLink ? '/subscribe' : '/settings',
      },
    ],
    [marketingLink],
  );

  const header = useMemo(() => {
    if (!isAuthenticated) return menuHeader;
    if (userName) return userName;
    return 'MY ACCOUNT';
  }, [userName, isAuthenticated, menuHeader]);

  return (
    <MenuPanel
      header={header}
      onClose={onClose}
      type={isTabletOrGreater ? 'medium' : 'scaling'}
      avatar={`avatar-${userName || 'default'}`}
    >
      <Container>
        <Menu>
          {isAuthenticated &&
            menuItems.map(({ icon: iconType, label, link }) => (
              <Fragment key={label}>
                <MenuItem label={label} icon={iconType} link={link} packageSlug={packageSlug} />
                <Divider />
              </Fragment>
            ))}
          {marketingLink && (
            <>
              <MenuItemContainerRegularLink href={marketingLink} target="_blank">
                <FeaturedSubscribeButton type="text" packageName={packageName} />
              </MenuItemContainerRegularLink>
              <Divider />
            </>
          )}
        </Menu>
        <BottomSection $thin={isAuthenticated}>
          {isAuthenticated && <LogoutButton />}
          {!isAuthenticated && <LoginCTA heading={heading} center icon={icon} />}
          <FinePrint />
        </BottomSection>
      </Container>
    </MenuPanel>
  );
};

SettingsPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  packageSlug: PropTypes.string,
  packageName: PropTypes.string,
};

export default memo(SettingsPanel);
