import { normalize } from 'normalizr';

export const flattenData = data =>
  Object.keys(data).reduce((output = {}, item) => {
    const outputCopy = {
      ...output,
      cursors: {
        ...output.cursors,
        [item]: {},
      },
    };

    const currentItemData = data[item];
    const hasEdges = Boolean(currentItemData.edges);
    const dataArr = hasEdges ? data[item].edges.map(({ node }) => node || {}) : [currentItemData];
    outputCopy[item] = [...(outputCopy[item] || []), ...dataArr];
    outputCopy.cursors[item] = currentItemData.pageInfo || {};
    return outputCopy;
  }, {});

export const normalizeData = (data = {}, schema) => {
  if (!schema || Object.keys(data).length === 0) {
    return [];
  }
  return normalize(flattenData(data), schema);
};

export const getPageInfo = data => {
  if (Object.keys(data).length === 0) {
    return null;
  }
  return Object.keys(data).reduce((output = {}, item) => {
    const outputCopy = { ...output };
    if (data[item]?.pageInfo) {
      outputCopy[item] = data[item].pageInfo;
    }
    return outputCopy;
  }, {});
};
