import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';

const getChampionIdFromProps = (_, props) => props.championId ?? '';

const getAbilityTypeFromProps = (_, props) => props.type ?? 'passive';

const defaultObject = {};

const makeGetChampionAbility = () => {
  const getChampions = makeGetChampions();
  return createSelector(
    [getChampions, getChampionIdFromProps, getAbilityTypeFromProps],
    (allChampions, championId, type) => {
      if (!allChampions || !championId) return defaultObject;
      return allChampions[championId]?.[type] ?? defaultObject;
    },
  );
};

export const useGetChampionAbility = props => useMemoizedGqlSelector(makeGetChampionAbility, props);

export default makeGetChampionAbility;
