export const defaultDimensions = {
  1: [{ scale: 1, x: 0, y: -50 }],
  2: [
    { scale: 0.5, x: -25, y: -50 },
    { scale: 0.5, x: 25, y: -50 },
  ],
  3: [
    { scale: 2 / 3, x: -100 / 6, y: -50 },
    { scale: 1 / 3, x: 100 / 3, y: -100 / 6 - 50 },
    { scale: 1 / 3, x: 100 / 3, y: 100 / 6 - 50 },
  ],
  4: [
    { scale: 0.5, x: -25, y: -75 },
    { scale: 0.5, x: 25, y: -75 },
    { scale: 0.5, x: 25, y: -25 },
    { scale: 0.5, x: -25, y: -25 },
  ],
};

export const ultraWideDimensions = {
  1: [{ scale: 1, x: 0, y: -50 }],
  2: [
    { scale: 1 / 2, x: -25, y: -50 },
    { scale: 1 / 2, x: 25, y: -50 },
  ],
  3: [
    { scale: 1 / 3, x: -100 / 3, y: -50 },
    { scale: 1 / 3, x: 0, y: -50 },
    { scale: 1 / 3, x: 100 / 3, y: -50 },
  ],
  4: [
    { scale: 1 / 4, x: -37.5, y: -50 },
    { scale: 1 / 4, x: -12.5, y: -50 },
    { scale: 1 / 4, x: 12.5, y: -50 },
    { scale: 1 / 4, x: 37.5, y: -50 },
  ],
};
