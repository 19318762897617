import useTheme from 'tv-hooks/useTheme';

const usePageRootColor = (type = 'rootColor') => {
  const theme = useTheme();
  const { colorSchemeMap } = theme;

  const colors = {};
  Object.keys(colorSchemeMap).forEach(page => {
    colors[page] = colorSchemeMap[page][type];
  });

  return colors;
};

export default usePageRootColor;
