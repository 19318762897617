import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import debounce from 'lodash.debounce';
import { Container, Input, IconWrapper } from './InputField.styles';
import { SMALL, MEDIUM } from './InputField.constants';

const InputField = ({
  placeholder = '',
  size = SMALL,
  onSearchChange,
  onClickDelete,
  onClickIcon,
  onFocus,
  onBlur,
  onEmpty,
  icon = 'search',
  noBackground = false,
}) => {
  const [isUsed, setIsUsed] = useState(false);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [query, setQuery] = useState('');
  const [usedQuery, setUsedQuery] = useState('');

  const handleOnChange = useCallback(
    e => {
      const { value } = e.target;
      setQuery(value);
      if (onEmpty) onEmpty(value.length < 2);
    },
    [onEmpty],
  );

  const handleDeleteClick = useCallback(() => {
    if (!query) return;
    setQuery('');
    if (onClickDelete) onClickDelete();
    if (onEmpty) onEmpty(true);
    setIsUsed(false);
  }, [query, onClickDelete, onEmpty]);

  const handleIconClick = useCallback(() => {
    if (!query) return;
    if (onClickIcon) onClickIcon(query);
    setUsedQuery(query);
    setIsUsed(true);
  }, [query, onClickIcon]);

  const debounceChanges = useMemo(() => {
    if (!onSearchChange) return null;
    return debounce((newQuery, inputFocused) => {
      if (!inputFocused) return;
      onSearchChange(newQuery);
    }, 100);
  }, [onSearchChange]);

  useEffect(() => {
    if (!debounceChanges) return () => {};
    debounceChanges(query, isOnFocus);
    if (query !== usedQuery) {
      setIsUsed(false);
    }
    return () => debounceChanges.cancel;
  }, [debounceChanges, isOnFocus, query, usedQuery]);

  const handleFocus = useCallback(
    e => {
      setIsOnFocus(true);
      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    e => {
      setIsOnFocus(false);
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleIconClick();
      }
    },
    [handleIconClick],
  );
  return (
    <Container>
      <Input
        name="search"
        hasQuery={query}
        value={query}
        onChange={handleOnChange}
        aria-label={placeholder}
        placeholder={!isOnFocus ? placeholder : ''}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        data-testid="search-input"
        tabIndex={0}
        size={size}
        autoComplete="autocomplete-off"
        noBackground={noBackground}
        isOnFocus={isOnFocus || query}
      />
      <IconWrapper
        onClick={isUsed || query ? handleDeleteClick : handleIconClick}
        aria-label={query ? 'Reset' : placeholder}
        isOnFocus={isOnFocus || query}
        data-testid={isUsed || query ? 'delete-button' : 'search-button'}
      >
        <Icon type={isUsed || query ? 'close' : icon} inline />
      </IconWrapper>
    </Container>
  );
};

InputField.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf([SMALL, MEDIUM]),
  onSearchChange: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickIcon: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEmpty: PropTypes.func,
  noBackground: PropTypes.bool,
};

const HIDDEN = 'hidden';
const PASSIVE = 'passive';
const ACTIVE = 'active';

export { HIDDEN, PASSIVE, ACTIVE };

export default memo(InputField);
