import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetProps from 'tv-selectors/makeGetProps';
import makeGetAuthMap from './makeGetAuthMap';

const emptyArray = [];
const emptyObject = {};
const makeGetAuthSubscriptionByProductId = () => {
  const getAuthMap = makeGetAuthMap();
  const getProps = makeGetProps();
  return createSelector([getAuthMap, getProps], (auth, props) => {
    const subscriptions = auth.subscriptions ?? emptyArray;
    const { productId, returnAllSubs } = props;

    const productSubscription = subscriptions
      .filter(sub => sub.productId === productId)
      .sort((a, b) => {
        const dateA = new Date(a.subscriptionStartTime);
        const dateB = new Date(b.subscriptionStartTime);
        return dateB > dateA ? 1 : -1;
      });

    if (returnAllSubs) {
      return productSubscription;
    }
    return productSubscription[0] ?? emptyObject;
  });
};

/**
 *
 * @param {string} props.productId - The id of the product
 * @param {boolean} [returnAllSubs=false] - Flag to return all or just the first object in the subscriptions array
 */
export const useGetAuthSubscriptionByProductId = props =>
  useMemoizedSelector(makeGetAuthSubscriptionByProductId, props);

export default makeGetAuthSubscriptionByProductId;
