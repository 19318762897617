import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetChampions from 'tv-selectors/champions/makeGetChampions';

const getChampionIdFromProps = (_, props) => props.championId ?? '';
const getLimitFromProps = (_, props) => props.limit ?? 5;

const emptyArray = [];

export const makeGetSelectedChampionTopPlayers = () => {
  const getChampions = makeGetChampions();
  return createSelector([getChampions, getChampionIdFromProps], (allChampions, championId) => {
    if (!allChampions || !championId) return emptyArray;
    return allChampions[championId]?.topPlayers ?? emptyArray;
  });
};

const makeGetChampionTopPlayers = () => {
  const getTopPlayers = makeGetSelectedChampionTopPlayers();
  return createSelector([getTopPlayers, getLimitFromProps], (topPlayers, limit) => {
    if (!topPlayers) return emptyArray;
    if (typeof limit !== 'number') return topPlayers;
    return topPlayers.slice(0, limit) ?? emptyArray;
  });
};

export const useGetChampionTopPlayers = props =>
  useMemoizedGqlSelector(makeGetChampionTopPlayers, props);

export default makeGetChampionTopPlayers;
