import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { animated, config, useTransition } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import PlayerCard from 'tv-elements/data-display/PlayerCard/PlayerCard';
import { useParams } from 'react-router-dom';
import PlayerAvatar from 'znipe-elements/data-display/Avatar/v2/PlayerAvatar/PlayerAvatar';
import ChampionAvatar from 'znipe-elements/data-display/Avatar/v2/ChampionAvatar/ChampionAvatar';
import { EVENT, PLAYER, TEAM, CHAMPION } from 'tv-routes/Topic/Topics.constants';
import { device } from 'znipe-styles/breakpoints';
import TopicTextBox from '../TopicTextBox/TopicTextBox';
import { LARGE, MEDIUM, SIZES, SMALL } from '../../TopicHeader.constants';

const eventStyles = css`
  max-width: 88px;
  img {
    object-fit: contain;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 16px 0 4px;
  height: ${({ $size }) => $size || '100%'};
  width: ${({ $size }) => $size || '100%'};
  ${({ $type }) => $type === EVENT && eventStyles}
  flex: 1;
`;

const miniStyles = css`
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 35px;
  ${ImageWrapper} {
    flex: 1;
  }
`;

const marginStyles = css`
  ${({ $type }) => {
    if ($type === PLAYER) {
      return css`
        margin: 0;
      `;
    }
    return css`
      margin: 8px 0;
      @media ${device.desktop} {
        margin: 16px 0;
      }
    `;
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  ${({ $isMini }) => ($isMini ? miniStyles : marginStyles)}
`;

const AnimatedContainer = animated(Container);

const TopicInfo = ({
  image,
  teamLogo = '',
  showMini = false,
  headline,
  subtitle,
  size = SMALL,
  icon = '',
  type = PLAYER,
}) => {
  const { topicType } = useParams();
  const prefersReducedMotion = usePrefersReducedMotion();

  const elementSizes = useMemo(() => {
    if (size === LARGE) {
      return {
        container: '56px',
        image: '50px',
        playerCard: MEDIUM,
        cardSize: '88px',
      };
    }
    if (size === MEDIUM) {
      return {
        container: '56px',
        image: '50px',
        playerCard: SMALL,
        cardSize: '88px',
      };
    }
    return {
      container: '48px',
      image: '40px',
      playerCard: SMALL,
      cardSize: '64px',
    };
  }, [size]);

  const transitions = useTransition(showMini, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
    immediate: prefersReducedMotion,
  });

  const Avatar = useMemo(() => {
    if (type === CHAMPION) {
      return <ChampionAvatar imageUrl={image} shape="circle" hasBorder />;
    }
    return <PlayerAvatar imageUrl={image} imageAlt={image} />;
  }, [image, type]);

  const Card = useMemo(() => {
    if (type === CHAMPION) {
      return <ChampionAvatar imageUrl={image} shape="circle" hasBorder fitImage />;
    }
    if (type !== PLAYER) {
      return <ChampionAvatar imageUrl={image} shape="square" fitImage />;
    }
    return (
      <PlayerCard
        teamLogo={teamLogo}
        playerImage={image}
        noMarker
        transparentBackground
        backgroundAlignment="left"
        size={elementSizes.playerCard}
        canOverflow
        skipAnimation
        type={topicType}
        noBottomGradient
      />
    );
  }, [elementSizes.playerCard, image, teamLogo, topicType, type]);

  const cardImageWrapperSize = type === PLAYER ? undefined : elementSizes.cardSize;

  return (
    <>
      {transitions((props, item) =>
        item ? (
          <AnimatedContainer data-testid="topic-info" $isMini style={props}>
            <ImageWrapper $size={elementSizes.image}>{Avatar}</ImageWrapper>
            <TopicTextBox
              headline={headline}
              subtitle={subtitle}
              showMini
              size={size}
              icon={icon}
              type={type}
            />
          </AnimatedContainer>
        ) : (
          <AnimatedContainer $type={type} $size={size} data-testid="topic-info" style={props}>
            <ImageWrapper $type={type} $size={cardImageWrapperSize}>
              {Card}
            </ImageWrapper>
            <TopicTextBox
              headline={headline}
              subtitle={subtitle}
              size={size}
              icon={icon}
              type={type}
            />
          </AnimatedContainer>
        ),
      )}
    </>
  );
};

TopicInfo.propTypes = {
  image: PropTypes.string.isRequired,
  teamLogo: PropTypes.string,
  icon: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showMini: PropTypes.bool,
  size: PropTypes.oneOf(SIZES),
  type: PropTypes.oneOf([PLAYER, TEAM, EVENT, CHAMPION]),
};

export default memo(TopicInfo);
