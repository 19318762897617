import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const Menu = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.soundOptions.headerText.color};
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  &:not(:first-child) {
    margin: 10px 0 0;
  }
`;

export const VolumeControlRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconMargin = styled.div`
  margin: 0px 0px 0px 15px;
`;

export const HeaderText = styled.span`
  text-transform: uppercase;
  line-height: 22px;
  color: ${({ theme, isLabel, disabled }) => {
    if (disabled) return colors.grey65;
    if (isLabel) return theme.soundOptions.headerText.color;
    return theme.soundOptions.headerText.numberColor;
  }};
  font-size: ${({ theme }) => theme.soundOptions.headerText.fontSize};
  font-weight: ${({ theme }) => theme.soundOptions.headerText.fontWeight || 700};
  ${({ theme, isLabel }) =>
    !isLabel &&
    css`
      padding-right: ${theme.soundOptions.headerText.padding};
    `}
`;
