import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getTeamColor = (teamOrder, theme) => {
  switch (teamOrder) {
    case 1:
      return theme.teamOneColor;
    case 2:
      return theme.teamTwoColor;
    default:
      return theme.teamOneColor;
  }
};

const StyledScore = styled.span`
  color: ${props => getTeamColor(props.order, props.theme)};
  font-family: ${props => props.theme.scoreFont};
  font-style: italic;
  opacity: ${props => (props.dimmed ? 0.4 : 1)};
`;

const Score = ({ teamOrder, value }) => (
  <StyledScore order={teamOrder} dimmed={value === 0} data-testid="score">
    {value}
  </StyledScore>
);

Score.propTypes = {
  teamOrder: PropTypes.oneOf([1, 2]).isRequired,
  value: PropTypes.number.isRequired,
};

export default memo(Score);
