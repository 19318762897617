import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MenuItemContainer } from 'tv-modules/Settings/SettingsPanel/SettingsPanel.styles';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';

const MenuItem = ({ icon, label, link, color = colors.white, packageSlug }) => {
  const location = useLocation();
  const composedLink = useMemo(() => {
    const basePath = packageSlug ? `/${packageSlug}` : '';
    if (link.indexOf('/') !== 0) return link;
    return {
      pathname: `${basePath}${link}`,
      state: {
        from: location.pathname,
      },
    };
  }, [link, location.pathname, packageSlug]);
  return (
    <MenuItemContainer to={composedLink}>
      <Icon type={icon} fillColor={color} inline size={24} />
      <Typography type="title-s" color={color}>
        {label}
      </Typography>
    </MenuItemContainer>
  );
};

MenuItem.propTypes = {
  color: PropTypes.string,
  packageSlug: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default memo(MenuItem);
