import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import { DateWrapper, LargeTimeFormat, LargeMinutesWrapper } from './MatchItem.styles';

const VodDate = ({ size = 'small', matchStartTime = '' }) => {
  const matchDateObject = useMemo(() => {
    const date = new Date(matchStartTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return { hours: `0${hours}`.slice(-2), minutes: `0${minutes}`.slice(-2) };
  }, [matchStartTime]);

  const { hours, minutes } = matchDateObject;

  return (
    <DateWrapper data-testid="vod-date-container">
      {size === 'small' ? (
        <Typography
          dataTestId="small-time-label"
          type="title-s"
        >{`${hours}:${minutes}`}</Typography>
      ) : (
        <LargeTimeFormat data-testid="large-time-label">
          <Typography type="heading-xl" as="span">
            {hours}
          </Typography>
          <LargeMinutesWrapper>
            <Typography type="heading-s" as="span">
              {minutes}
            </Typography>
          </LargeMinutesWrapper>
        </LargeTimeFormat>
      )}
    </DateWrapper>
  );
};

VodDate.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  matchStartTime: PropTypes.string,
};

export default VodDate;
