import { memo, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import { ThemeProvider } from 'styled-components';
import Navigation from 'znipe-elements/layout/Navigation/Navigation';
import Masthead from 'tv-modules/Masthead/Masthead';
import useTheme from 'tv-hooks/useTheme';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import GlobalStyle from 'znipe-styles/global.styles';
import usePackageName from 'tv-hooks/usePackageName';
import { PanelOverlay, PanelWrapper } from 'znipe-elements/layout/MenuPanel/MenuPanel.styles';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import SettingsPanel from 'tv-modules/Settings/SettingsPanel/SettingsPanel';
import config from 'tv-config/config';
import useAnalyticsParams from 'tv-hooks/useAnalyticsParams';
import { useUserSession } from 'tv-selectors/deviceInfo/makeGetUserSession';
import { COMPACT } from 'tv-routes/HighlightStage/HighlightStage.constants';
import useQueryObject from 'tv-hooks/useQueryObject';
import OnboardingProvider from 'tv-modules/Onboarding/OnboardingProvider';
import Fonts from 'znipe-elements/general/Typography/Fonts/Fonts';
import AnalyticsManager from 'znipe-analytics-manager';
import {
  PageContentWrapper,
  FooterWrapper,
  LayoutContainer,
  VideoElement,
} from './PageLayout.styles';
import HelmetWrapper from './HelmetWrapper';
import usePreviousLocation from './usePreviousLocation';

const { ANALYTICS_EVENT_API_URL } = config;
const AnimatedPanelWrapper = animated(PanelWrapper);
const AnimatedPanelOverlay = animated(PanelOverlay);

AnalyticsManager.setEndpoint(ANALYTICS_EVENT_API_URL);

const LayoutRoot = ({ children, disableScroll = false, packageName }) => {
  const theme = useTheme(packageName);
  return (
    <LayoutContainer data-testid="page-layout-root" $disableScroll={disableScroll}>
      <ThemeProvider theme={theme}>
        <Fonts />
        <GlobalStyle />
        <OnboardingProvider>{children}</OnboardingProvider>
      </ThemeProvider>
    </LayoutContainer>
  );
};

LayoutRoot.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disableScroll: PropTypes.bool,
  packageName: PropTypes.string.isRequired,
};

const PageLayout = ({ children, layout = 'none', compact = false }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const { type } = useQueryObject();
  const { packageSlug, eventSlug } = useParams();

  const packageName = usePackageName(packageSlug);
  const location = useLocation();

  const baseUrl = useMemo(() => (packageSlug ? `/${packageSlug}` : ''), [packageSlug]);

  const links = useMemo(
    () => [
      {
        label: 'Home',
        icon: 'home',
        link: `${baseUrl}/home`,
      },
      {
        label: 'Matches',
        icon: 'versus',
        link: `${baseUrl}/matches`,
      },
      {
        label: 'Standings',
        icon: 'list',
        link: `${baseUrl}/standings`,
      },
      {
        label: 'Browse',
        icon: 'browse',
        link: `${baseUrl}/browse`,
      },
    ],
    [baseUrl],
  );

  const selectedPageLabel = useMemo(
    () => links.find(({ link }) => location.pathname.indexOf(link) === 0)?.label,
    [links, location.pathname],
  );

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [renderSettingsMenu, setRenderSettingsMenu] = useState(false);
  const closeSettings = useCallback(() => setSettingsOpen(false), []);
  const panelRef = useOutsideClick(closeSettings);
  const { videoBackground } = useTheme(packageName);

  const openSettings = useCallback(() => {
    setSettingsOpen(true);
    setRenderSettingsMenu(true);
  }, []);

  // #TODO Refactor this bit to share code with useAnalyticsParams.js
  const prevLocation = usePreviousLocation();

  const userSession = useUserSession();
  const { isPremiumUser, productId, userId, region, referrer, deviceId } = useAnalyticsParams(
    undefined,
    undefined,
    packageName,
  );

  const analyticsManager = useMemo(() => new AnalyticsManager(packageName), [packageName]);

  useEffect(() => {
    if (!global.document) return;
    const { hostname, protocol } = global.location;
    const prevPath = prevLocation?.pathname
      ? `${protocol}//${hostname}${prevLocation.pathname ?? ''}${prevLocation.search ?? ''}`
      : undefined;
    const fields = {
      userId,
      deviceId,
      userSession,
      referrer: prevPath ?? referrer,
      metadata: {
        hostname,
        isPremiumUser,
        packageName,
        productId,
      },
      customerData: {
        regionId: region,
      },
      uri: `znipe:/${location.pathname}${location.search}`,
    };
    analyticsManager.trackNavigationEvent(fields);
  }, [
    location.pathname,
    location.search,
    userId,
    deviceId,
    region,
    userSession,
    isPremiumUser,
    packageName,
    productId,
    referrer,
    analyticsManager,
    prevLocation.pathname,
    prevLocation.search,
  ]);

  //  To here
  useEffect(() => () => AnalyticsManager.flush(), []);

  const prefersReducedMotion = usePrefersReducedMotion();

  const animationPanel = useSpring({
    transform: `translateX(${settingsOpen ? 0 : 100}%)`,
    config: { tension: 800, friction: 60 },
    onRest: () => setRenderSettingsMenu(settingsOpen), // This sets the render flag to false when animation is done and it's closed
    immediate: prefersReducedMotion,
  });

  const animationOverlay = useSpring({
    opacity: settingsOpen ? '70%' : '0%',
    config: { duration: 200 },
    immediate: prefersReducedMotion,
  });

  if (layout === 'none')
    return (
      <>
        <HelmetWrapper packageName={packageName} pageLabel={selectedPageLabel} />
        {children}
      </>
    );
  if (layout === 'stage') {
    const isCompact = compact || type === COMPACT;
    return (
      <LayoutRoot disableScroll packageName={packageName}>
        <HelmetWrapper packageName={packageName} pageLabel={selectedPageLabel} />
        {!isCompact && (
          <Masthead
            baseUrl={baseUrl}
            eventSlug={eventSlug}
            links={links}
            onSettingsClick={openSettings}
            packageName={packageName}
          />
        )}
        {children}
        {renderSettingsMenu && !isCompact && (
          <>
            <AnimatedPanelWrapper ref={panelRef} style={animationPanel}>
              <SettingsPanel
                onClose={closeSettings}
                packageSlug={packageSlug}
                packageName={packageName}
              />
            </AnimatedPanelWrapper>
            <AnimatedPanelOverlay style={animationOverlay} />
          </>
        )}
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot packageName={packageName}>
      <HelmetWrapper packageName={packageName} pageLabel={selectedPageLabel} />
      {isDesktopOrGreater && <VideoElement loop autoPlay muted src={videoBackground} />}
      <PageContentWrapper data-testid="layout-page-content-wrapper">
        <Masthead
          baseUrl={baseUrl}
          eventSlug={eventSlug}
          links={links}
          onSettingsClick={openSettings}
          packageName={packageName}
        />
        {children}
        {renderSettingsMenu && (
          <>
            <AnimatedPanelWrapper ref={panelRef} style={animationPanel}>
              <SettingsPanel
                onClose={closeSettings}
                packageSlug={packageSlug}
                packageName={packageName}
              />
            </AnimatedPanelWrapper>
            <AnimatedPanelOverlay style={animationOverlay} />
          </>
        )}
      </PageContentWrapper>
      <FooterWrapper data-testid="layout-footer">
        <Navigation menuItems={links} size={isTabletOrGreater ? 'medium' : 'small'} />
      </FooterWrapper>
    </LayoutRoot>
  );
};

PageLayout.propTypes = {
  layout: PropTypes.oneOf(['default', 'stage', 'none']),
  compact: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export {
  FeaturedWrapper,
  BodyWrapper,
  TabsetWrapper,
  FilterWrapper,
  HighlightsSectionWrapper,
} from './PageLayout.styles';
export default memo(PageLayout);
