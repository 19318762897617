import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetCasterVolumeRatio = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.casterVolumeRatio ?? 1);
};

export const useCasterVolumeRatio = () => useMemoizedSelector(makeGetCasterVolumeRatio);

export default makeGetCasterVolumeRatio;
