import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from './makeGetMatch';

const emptyArray = [];

const makeGetMatchGamesIds = () => {
  const getSelectedMatch = makeGetMatch();
  return createSelector([getSelectedMatch], selectedMatch => selectedMatch.games || emptyArray);
};

export const useGetMatchGamesIds = props => useMemoizedGqlSelector(makeGetMatchGamesIds, props);

export default makeGetMatchGamesIds;
