import styled, { css, keyframes } from 'styled-components';
import prefers from 'znipe-styles/prefers';

const fadeIn = (endOpacity = 1) => keyframes`
from { opacity: 0; } 
to { opacity: ${endOpacity}; }
`;

const fadeAnimation = css`
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`;

export const TeamImage = styled.img`
  position: absolute;
  grid-row: 3;
  width: 90%;
  top: 50%;
  ${({ $type }) =>
    $type === 'right'
      ? css`
          right: 0;
          transform: translate(33%, -50%);
        `
      : css`
          left: 0;
          transform: translate(-33%, -50%);
        `}
  opacity: 0.3;
  @media ${prefers.motion} {
    opacity: 0;
    ${fadeAnimation};
    animation-name: ${() => fadeIn(0.3)};
  }
`;

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  @media ${prefers.motion} {
    opacity: 0;
    ${fadeAnimation};
    animation-name: ${() => fadeIn(1)};
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
