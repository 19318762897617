import styled, { css } from 'styled-components';
import { AvatarWrapper } from 'znipe-elements/data-display/AvatarInfo/AvatarInfo.styles';
import { MEDIUM, PLAYER_CARD, TEXT } from './TopicLink.constants';

export const IconWrapper = styled.div`
  margin-right: 24px;
  display: flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
`;

const ContainerBackground = ({ theme, $isEmpty, $isActive, $noBackground }) => {
  let background = theme.secondaryBackgroundColor;
  if ($isActive) background = $noBackground ? 'transparent' : theme.tertiaryBackgroundColor;
  else if ($isEmpty) background = 'transparent';
  return css`
    background: ${background};
  `;
};

export const Container = styled.div`
  ${ContainerBackground}
  ${({ $roundedCorner }) =>
    $roundedCorner &&
    css`
      border-radius: 5px;
    `}
  height: ${({ $size }) => ($size === MEDIUM ? 88 : 72)}px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-left: ${({ $size }) => ($size === MEDIUM ? 24 : 16)}px;
  ${({ $type, $size }) =>
    $type === PLAYER_CARD &&
    css`
      padding: 0;
      ${AvatarWrapper} {
        height: initial;
        width: ${$size === MEDIUM ? 66 : 54}px;
      }
    `}
  ${({ $type }) =>
    $type === TEXT &&
    css`
      justify-content: space-between;
    `}
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
`;
