import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';

const useOffset = (matchId, gameId, offsetId) => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (!matchId || !gameId || !offsetId) return () => null;
    const offsetRef = `/matches/${matchId}/games/${gameId}/offsets/${offsetId}`;

    const handleSnapshot = snapshot => setOffset(snapshot.val() || 0);
    const ref = firebase.database().ref(offsetRef);

    ref.on('value', handleSnapshot);

    return () => ref.off('value', handleSnapshot);
  }, [matchId, gameId, offsetId]);

  return offset;
};

export default useOffset;
