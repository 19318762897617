import { useCallback, useEffect, useState } from 'react';
import { MultiViewRef } from 'znipe-watch/src/modules/MultiView/MultiView';
import { SingleViewRef } from 'znipe-watch/src/modules/SingleView/SingleView';

const useVideoControl = (
  playerRef:
    | React.MutableRefObject<MultiViewRef | undefined | null>
    | React.MutableRefObject<SingleViewRef | undefined | null>,
) => {
  const [isPlaying, setIsPlaying] = useState(!(playerRef?.current?.isPaused() ?? true));

  const togglePlayPause = useCallback(() => {
    const player = playerRef?.current;
    if (!player) return;
    if (player.isPaused()) {
      const { end } = player.getSeekRange();
      const currentTime = player.getCurrentTime();
      if (currentTime >= end) {
        player.seek(0);
      }

      player.play();
      setIsPlaying(true);
    } else {
      player.pause();
      setIsPlaying(false);
    }
  }, [playerRef]);

  const seek = useCallback(
    (amount: number) => {
      const player = playerRef?.current;
      if (!player) return;
      const newTime = player.getCurrentTime() + amount;
      player.seek(newTime);
    },
    [playerRef],
  );

  useEffect(() => {
    const player = playerRef?.current;
    if (!player) return () => {};
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    player.addEventListener('play', onPlay);
    player.addEventListener('playing', onPlay);
    player.addEventListener('pause', onPause);

    return () => {
      player.removeEventListener('play', onPlay);
      player.removeEventListener('playing', onPlay);
      player.removeEventListener('pause', onPause);
    };
  }, [playerRef]);

  return {
    isPlaying,
    togglePlayPause,
    seek,
  };
};

export default useVideoControl;
