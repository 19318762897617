import { ReactElement, useEffect, useRef } from 'react';
import type ReactDOM from 'react-dom/client';
import styled from 'styled-components';

type ReactDomOverlayProps = {
  ReactDom: typeof ReactDOM;
  component: ReactElement;
};

const Overlay = styled.div`
  width: 100%;
  height: 100%;
`;

const ReactDomOverlay = ({ component: Component, ReactDom }: ReactDomOverlayProps) => {
  const rootRef = useRef<ReactDOM.Root>();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // There are some potential race conditions when mounting and unmounting components synchronously in react
    // The recommended way seems to be to force it to run asynchronously
    const renderTimeout = setTimeout(() => {
      const container = ref.current;
      if (!container) return;
      const root = rootRef.current ?? ReactDom.createRoot(container);
      root.render(Component);
      rootRef.current = root;
    });

    return () => {
      clearTimeout(renderTimeout);
    };
  }, [ReactDom, Component]);

  useEffect(
    () => () => {
      const root = rootRef.current;
      rootRef.current = undefined;
      setTimeout(() => {
        root?.unmount();
      });
    },
    [],
  );

  return <Overlay ref={ref} />;
};

export default ReactDomOverlay;
