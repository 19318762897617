import { schema } from 'normalizr';
import getLastItem from 'znipe-utils/misc/getLastItem';
import {
  streamUrlSchema,
  tournamentSchema,
  gameSchema,
  packageSchema,
  matchSchema,
  eventSchema,
  contentSubSchema,
  allTournamentsSchema,
} from './rootSchema.normalizr';

export const standingTeamSchema = new schema.Entity('standingTeam');

const rosterPlayer = new schema.Entity(
  'rosterPlayers',
  {},
  {
    processStrategy: ({ playerDetails, ...value }) => {
      const image = playerDetails && playerDetails?.image ? getLastItem(playerDetails.image) : '';
      return { ...value, image };
    },
  },
);

const videoSchema = new schema.Entity('videos');

export const highlightSchema = new schema.Entity('playlistHighlights', {
  match: matchSchema,
  video: videoSchema,
});

export const playlistSchema = new schema.Entity('playlists', {
  highlights: [highlightSchema],
});

export const playerDetailsSchema = new schema.Entity(
  'playerDetails',
  {},
  {
    idAttribute: (_value, parent) => parent.id || null,
  },
);

export const playerSubSchema = new schema.Entity('players', {
  playerDetails: playerDetailsSchema,
});

export const rosterSchema = new schema.Entity(
  'roster',
  {
    value: [rosterPlayer],
  },
  {
    idAttribute: ({ timestamp = '' }, { id = '', version = 1 }) => {
      const [date = ''] = timestamp.split('T');
      return Buffer.from(id.slice(0, 6) + version + date + version).toString('base64');
    },
  },
);

export const teamSchema = new schema.Entity('teams', {
  players: [playerSubSchema],
  roster: [rosterSchema],
});

export const highlightMatchSchema = new schema.Entity('players', {
  teams: [teamSchema],
  games: [gameSchema],
  tournament: tournamentSchema,
  streams: [streamUrlSchema],
});

export const highlightsSchema = new schema.Entity(
  'highlights',
  {
    match: highlightMatchSchema,
  },
  {
    processStrategy: (value, _parent, key) => {
      if (key === 'edges') {
        return value.node;
      }
      return value;
    },
    idAttribute: (value, parent) => value.node.id ?? parent.id,
  },
);

export const highlightEdgesSchema = new schema.Entity(
  'highlightEdges',
  { edges: [highlightsSchema] },
  {
    idAttribute: (value, parent) => value.id ?? parent.id,
  },
);

const matchSubSchema = new schema.Entity(
  'latestMatches',
  {
    teams: [teamSchema],
    games: [gameSchema],
    tournament: tournamentSchema,
    streams: [streamUrlSchema],
    highlights: highlightEdgesSchema,
  },
  {
    processStrategy: (value, _parent, key) => {
      if (key === 'edges') {
        return value.node;
      }
      return value;
    },
    idAttribute: (value, parent) => value?.node?.id || parent?.id || value?.id,
  },
);

const scheduledMatchSubSchema = new schema.Entity(
  'scheduledMatches',
  {
    teams: [teamSchema],
    games: [gameSchema],
    tournament: tournamentSchema,
    streams: [streamUrlSchema],
  },
  {
    processStrategy: (value, _parent, key) => {
      if (key === 'edges') {
        return value.node;
      }
      return value;
    },
    idAttribute: (value, parent) => value?.node?.id || parent?.id || value?.id,
  },
);

const matchesSubSchema = new schema.Entity(
  'matchEdges',
  { edges: [matchSubSchema] },
  {
    idAttribute: (value, parent) => value.id ?? parent.id,
  },
);

const scheduledMatchesSubSchema = new schema.Entity('matchEdges', {
  edges: [scheduledMatchSubSchema],
});

export const playerSchema = new schema.Entity('player', {
  latestMatches: matchesSubSchema,
  scheduledMatches: scheduledMatchesSubSchema,
  team: teamSchema,
});

export const teamTopicSub = new schema.Entity(
  'team',
  {
    latestMatches: matchesSubSchema,
    scheduledMatches: scheduledMatchesSubSchema,
    roster: [rosterSchema],
  },
  {
    processStrategy: ({ stories, ...values }) => {
      const flatStories = stories?.[0]?.value.reduce((allStories = {}, { text, type }) => {
        const copy = { ...allStories };
        copy[type] = text;
        return copy;
      }, {});
      return {
        ...values,
        stories: flatStories,
      };
    },
  },
);

export const championSchema = new schema.Entity(
  'champion',
  {
    latestMatches: matchesSubSchema,
    scheduledMatches: scheduledMatchesSubSchema,
  },
  {
    idAttribute: value => value.champId,
    processStrategy: ({ otherOfSameClass, videos, ...value }) => {
      const actors = [];
      videos?.edges?.forEach(({ node }) => {
        node.actors?.forEach(({ id, player }) => {
          const { team, ...rest } = player;
          if (!actors.find(actor => actor.id === id)) {
            actors.push({
              id,
              nickname: getLastItem(rest.nickname),
              image: getLastItem(rest.image),
              teamId: team.id,
              teamName: getLastItem(team.name),
              teamLogoLight: getLastItem(team.logoDark),
              teamLogoDark: getLastItem(team.logoLight),
            });
          }
        });
      });
      const flattenedClasses =
        otherOfSameClass?.edges?.map(({ node }) =>
          Object.keys(node).reduce((output = {}, key) => {
            const copy = { ...output };
            if (key === 'class') {
              copy[key] = node[key]?.[0];
            } else if (Array.isArray(node[key])) {
              copy[key] = getLastItem(node[key]);
            } else {
              copy[key] = node[key];
            }
            return copy;
          }, {}),
        ) || [];
      return {
        ...value,
        otherOfSameClass: flattenedClasses,
        topPlayers: [...new Set(actors)],
      };
    },
  },
);

// eslint-disable-next-line import/prefer-default-export
export const topicSchema = new schema.Entity(
  'data',
  {
    player: [playerSchema],
    team: [teamTopicSub],
    tournament: [tournamentSchema],
    scheduledMatches: [scheduledMatchSubSchema],
    latestMatches: [matchSubSchema],
    playlists: [playlistSchema],
    packages: [packageSchema],
    events: [eventSchema],
    allTournaments: [allTournamentsSchema],
    champion: [championSchema],
    contentSubscriptions: [contentSubSchema],
  },
  {
    // should get eventid / partner-id / humanReadableId
    idAttribute: () => 'riot-games',
  },
);
