export const PLAYBACK_EVENT_CATEGORY = 'playback' as const;
export const PLAYBACK_EVENT_TYPE = 'playbackEvent' as const;

export const METADATA_EVENT_CATEGORY = 'metadata' as const;

export const NAVIGATION_EVENT_CATEGORY = 'interaction' as const;
export const NAVIGATION_EVENT_TYPE = 'navigationEvent' as const;

export const UI_EVENT_CATEGORY = 'interaction' as const;
export const UI_EVENT_TYPE = 'uiEvent' as const;

export const CHAT_MODULE = 'chat' as const;
export const VIDEO_PLAYBACK_MODULE = 'video-playback' as const;

export const eventTypes = [PLAYBACK_EVENT_TYPE, NAVIGATION_EVENT_TYPE, UI_EVENT_TYPE] as const;
export const categories = [
  PLAYBACK_EVENT_CATEGORY,
  NAVIGATION_EVENT_CATEGORY,
  UI_EVENT_CATEGORY,
] as const;
export const modules = [CHAT_MODULE, VIDEO_PLAYBACK_MODULE] as const;
