import { useMemo } from 'react';
import useGqlQuery from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import useThemeContext from 'znipe-hooks/useThemeContext';
import packageCustomizationQuery from '../queries/packageCustomization.gql';

type PackageCustomization = {
  packageCustomization?: {
    chatColorSets?: {
      humanReadableId: string;
      displayName: string;
      priority: number;
      isUnlockable: boolean;
      colors: {
        hexCode: string;
        displayName: string;
      }[];
    }[];
    chatSignifiers?: {
      humanReadableId: string;
      displayName: string;
      priority: number;
      url: string;
    }[];
    emojiPackageSet?: {
      emojiSetId: string;
      shortcode: string;
      category: string;
      categoryDisplayName: string;
      url: string;
    }[];
  };
};

const usePackageCustomization = (emojiSet?: string[]) => {
  const theme = useThemeContext();

  const gqlOptions = useMemo(
    () => ({
      url: `${process.env.QUARTERBACK_API_URL}/graphql`,
      ssr: false,
      variables: {
        package: theme.themeName,
        categories: emojiSet,
      },
    }),
    [theme, emojiSet],
  );

  const { data } = useGqlQuery<PackageCustomization>(packageCustomizationQuery, gqlOptions, {});

  return useMemo(
    () =>
      data?.packageCustomization ?? {
        chatColorSets: [],
        chatSignifiers: [],
        emojiPackageSet: [],
      },
    [data],
  );
};

export default usePackageCustomization;
