import { useState, useEffect, ReactNode } from 'react';
import { error } from 'znipe-logger';
import ChatPopOver from 'znipe-chat/src/components/ChatPopOver/ChatPopOver';
import Layout from '../PopoverLayout/PopoverLayout';
import GifList from './GifList/GifList';

type GifsProps = {
  type: 'regular' | 'compact';
  size?: 'small' | 'medium';
  targetElement?: ReactNode;
};

export type GIF = {
  name: string;
  type: string;
  url: string;
  id: string;
};

type Response = {
  results: {
    gifs: GIF[];
  };
};

const Gifs: React.FC<GifsProps> = ({ type, size = 'small', targetElement }) => {
  const [gifs, setGifs] = useState<GIF[]>([]);

  useEffect(() => {
    fetch('http://localhost:9000/v3/chat/gifs/list')
      .then(res => res.json())
      .then(({ results }: Response) => setGifs(results.gifs))
      .catch(error);
  }, []);

  if (type === 'compact')
    return (
      <ChatPopOver size={size} header="Chat Gifs" targetElement={targetElement}>
        <GifList gifs={gifs} columnsPerRow={3} onSelect={() => null} />
      </ChatPopOver>
    );
  return (
    <Layout type={type}>
      <GifList gifs={gifs} onSelect={() => null} />
    </Layout>
  );
};

export default Gifs;
