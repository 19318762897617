import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';
import { MenuWrapper } from 'znipe-elements/navigation/Dropdown/Dropdown.styles';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';

const MobileGrid = css`
  grid-template-columns: 1fr;
`;

const TabletGrid = $withSort => css`
  grid-template-columns: ${$withSort ? '230px 1fr auto' : '1fr auto'};
  > *:last-child {
    grid-column: ${$withSort ? 3 : 2};
  }
`;

const DesktopGrid = $withSort => css`
  grid-template-columns: ${$withSort ? '230px 1fr auto' : '1fr auto'};
`;

export const MiddleSection = styled.div`
  display: flex;
`;

export const DropdownWrapper = styled.div`
  width: 190px;
  margin-right: 8px;
  @media (min-width: 576px) {
    margin-right: unset;
  }
  @media ${device.desktopLarge} {
    margin-right: 16px;
  }
`;

export const SortByWrapper = styled.div`
  li {
    min-height: 84px;
  }
`;

export const Container = styled.div`
  display: grid;
  width: 100%;
  ${MobileGrid};
  ${({ size, $type, $withSort }) =>
    size === 'medium' && $type === 'standard' && TabletGrid($withSort)};
  ${({ size, $withSort }) => (size === 'large' || size === 'xlarge') && DesktopGrid($withSort)};
  grid-template-rows: 1fr;
  gap: 0 8px;
  justify-content: space-evenly;
  align-items: center;
  ${({ $noSortNoFilterPanel }) =>
    $noSortNoFilterPanel &&
    css`
      grid-template-columns: 1fr;
      justify-content: flex-start;
      ${DropdownWrapper}:first-child {
        margin-left: unset;
      }
    `}
  margin: 0 8px;
  @media ${device.mobilePortrait} {
    margin: 0 16px;
  }
  @media (min-width: 576px) {
    margin: 0;
  }
  @media ${device.desktop} {
    ${MenuWrapper}, button {
      max-width: 230px;
      width: 100%;
    }
  }
  @media ${device.desktopLarge} {
    gap: 0 16px;
  }
`;

export const ButtonWrapper = styled.div`
  button {
    background-color: ${({ theme }) => theme.containerStroke};
  }
  button:hover,
  button:focus {
    background-color: ${({ theme }) => theme.containerStroke};
  }
  ${({ isFloating }) =>
    isFloating &&
    css`
      position: fixed;
      z-index: 6;
      top: calc(100vh - 110px);
      left: 8px;
      @supports (filter: drop-shadow(0px 0px 0px ${colors.black})) {
        filter: drop-shadow(4px 4px 15px rgba(${convertHexToRGBDigits(colors.black)}, 0.7));
      }
      @media ${device.mobilePortrait} {
        bottom: calc(56px + 14px);
        top: unset;
        left: 16px;
      }
      @media ${device.tablet} {
        left: unset;
    `}
`;
