import styled, { css } from 'styled-components';

export const SVG = styled.svg`
  height: ${({ isSingleWave }) => (isSingleWave ? '64px' : 'auto')};
  ${({ isSingleWave }) =>
    isSingleWave &&
    css`
      max-width: 850px;
    `}
`;
export const Path = styled.path``;
export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  ${SVG} {
    width: 100%;
    ${({ shouldPreserveAspectRatio }) =>
      !shouldPreserveAspectRatio &&
      css`
        height: 100%;
      `}
  }
`;
