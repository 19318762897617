import { useCallback, useReducer, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';
import MobileStreamList from 'tv-modules/MobileStreamList/MobileStreamList';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import Icon from 'znipe-elements/general/Icon/Icon';
import StreamLayout from 'tv-modules/Player/StreamLayout/StreamLayout';
import StreamPreview from 'tv-modules/Player/StreamPreview/StreamPreview';
import { playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';

import {
  SidebarContent,
  Container,
  SelectorsWrapper,
  BackAction,
  ActionLabel,
  StreamListWrapper,
  PovSelectorsContainer,
  VideoPlayerholder,
  AspectRatioBox,
  AspectRatioContentWrapper,
  themes,
} from './ScreenPovSelectors.styles';
import { Content, MainContent } from '../../ScreenOverlay.styles';

const BackButton = memo(({ onClick }) => (
  <BackAction onClick={onClick}>
    <Icon type="arrowLeft" size={14} />
    <ActionLabel hasColor>Back</ActionLabel>
  </BackAction>
));

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const initialState = { userSelectedStreamId: null, isHoveredStreamLocked: false };
const reducer = (state, action) => ({
  ...state,
  ...action,
});

const ScreenPovSelectors = ({
  matchId,
  selectedMatchGameIndex,
  allowPopingOut = true,
  playerRef,
  onClose,
}) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const playerStreams = useGetMatchPlayerStreams({ matchId });

  const [state, dispatch] = useReducer(reducer, initialState);
  const { userSelectedStreamId, hoveredStream, isHoveredStreamLocked } = state;

  const numberOfPlayingStreams = playerStreams.length;

  const streamPreviewWidth = useMemo(
    () => (isDesktopLargeOrGreater ? 656 : 466),
    [isDesktopLargeOrGreater],
  );

  const onPlayerSelect = useCallback(
    (_playerId, _gatPlayerPosition, streamId) => {
      const isSingleView = numberOfPlayingStreams === 1;
      const isAlreadyAdded = playerStreams.find(s => s.id === streamId);
      if (isSingleView && isAlreadyAdded && onClose) return onClose();
      return dispatch({ userSelectedStreamId: streamId });
    },
    [playerStreams, numberOfPlayingStreams, onClose],
  );

  const onBack = useCallback(() => dispatch({ userSelectedStreamId: null }), []);

  const afterStreamSelect = useCallback(() => {
    dispatch({ userSelectedStreamId: null });
    if (onClose) onClose();
  }, [onClose]);

  const onStreamItemHover = useCallback((streamId, isLocked) => {
    dispatch({ hoveredStream: streamId, isHoveredStreamLocked: isLocked });
  }, []);

  return (
    <Container data-testid="screen-pov-selectors">
      <Content>
        {(isTabletOrGreater || userSelectedStreamId) && (
          <SidebarContent reserveSpace={isTabletOrGreater && !isDesktopOrGreater}>
            {userSelectedStreamId && <BackButton onClick={onBack}>back</BackButton>}
          </SidebarContent>
        )}
        <MainContent maxWidth="auto">
          <SelectorsWrapper isLayoutView={!!userSelectedStreamId}>
            {!userSelectedStreamId ? (
              <PovSelectorsContainer>
                <StreamListWrapper>
                  <MobileStreamList
                    matchId={matchId}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                    onClick={onPlayerSelect}
                    onStreamItemHover={onStreamItemHover}
                    afterStreamSelect={afterStreamSelect}
                  />
                </StreamListWrapper>
                {isDesktopOrGreater && (
                  <VideoPlayerholder>
                    <AspectRatioBox>
                      <AspectRatioContentWrapper>
                        {hoveredStream && (
                          <StreamPreview
                            matchId={matchId}
                            streamId={hoveredStream}
                            selectedMatchGameIndex={selectedMatchGameIndex}
                            size={streamPreviewWidth}
                            allowPopingOut={allowPopingOut}
                            playerRef={playerRef}
                            isLocked={isHoveredStreamLocked}
                            isHoverable={false}
                            play
                          />
                        )}
                      </AspectRatioContentWrapper>
                    </AspectRatioBox>
                  </VideoPlayerholder>
                )}
              </PovSelectorsContainer>
            ) : (
              <StreamLayout
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                userSelectedStreamId={userSelectedStreamId}
                afterStreamSelect={afterStreamSelect}
                onClose={onClose}
                onBackClick={isDesktopOrGreater ? onBack : null}
              />
            )}
          </SelectorsWrapper>
        </MainContent>
        {onClose && (
          <SidebarContent>
            <NavButton iconType="close" label="Close" showLabel size="large" onClick={onClose} />
          </SidebarContent>
        )}
      </Content>
    </Container>
  );
};

ScreenPovSelectors.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  allowPopingOut: PropTypes.bool,
  onClose: PropTypes.func,
  playerRef: playerPropTypes.isRequired,
};

export default withTheme(memo(ScreenPovSelectors), themes, 'screenPovSelectors');
