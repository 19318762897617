import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetMatch from 'tv-selectors/match/makeGetMatch';

const makeGetMatchPopoutSoundFrom = () => {
  const getSelectedMatch = makeGetMatch();
  return createSelector([getSelectedMatch], selectedMatch => selectedMatch.popoutSoundFrom || '');
};

export const useGetMatchPopoutSoundFrom = props =>
  useMemoizedGqlSelector(makeGetMatchPopoutSoundFrom, props);

export default makeGetMatchPopoutSoundFrom;
