import config from 'tv-config/config';
import {
  SET_AUTH,
  REQUEST_AUTH,
  GET_PURCHASED_EVENTS_REQUEST,
  GET_PURCHASED_EVENTS_SUCCESS,
  GET_PURCHASED_EVENTS_FAILURE,
  GET_TRIALED_EVENTS_REQUEST,
  GET_TRIALED_EVENTS_SUCCESS,
  GET_TRIALED_EVENTS_FAILURE,
  GET_USER_BADGES_REQUEST,
  GET_USER_BADGES_SUCCESS,
  GET_USER_BADGES_FAILURE,
  SET_USER_BADGE,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  UPDATE_SUBSCRIBE_REQUEST,
  UPDATE_SUBSCRIBE_SUCCESS,
  UPDATE_SUBSCRIBE_FAILURE,
  UPDATE_AUTH,
  SET_FETCHING_STATUS,
  SET_EXTERNAL_JWT,
} from 'tv-constants/oldRedux';

const { API_URL } = config;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const setAuth = auth => ({
  type: SET_AUTH,
  auth,
});

export const updateAuth = auth => ({
  type: UPDATE_AUTH,
  auth,
});

export const requestAuth = (closeAction = 'stay') => ({
  type: REQUEST_AUTH,
  closeAction,
});

export const loginUser = ({ email, password }) => ({
  types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  callAPI: () =>
    fetch(`${API_URL}/v2/tokens`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    }),
});

export const getPurchasedEvents = userID => ({
  types: [GET_PURCHASED_EVENTS_REQUEST, GET_PURCHASED_EVENTS_SUCCESS, GET_PURCHASED_EVENTS_FAILURE],
  callAPI: token =>
    fetch(`${API_URL}/v3/users/${userID}/purchases/events`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'GET',
    }),
});

export const getTrialedEvents = userID => ({
  types: [GET_TRIALED_EVENTS_REQUEST, GET_TRIALED_EVENTS_SUCCESS, GET_TRIALED_EVENTS_FAILURE],
  callAPI: token =>
    fetch(`${API_URL}/v3/users/${userID}/trials/events`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'GET',
    }),
});

export const getUserBadges = userID => ({
  types: [GET_USER_BADGES_REQUEST, GET_USER_BADGES_SUCCESS, GET_USER_BADGES_FAILURE],
  callAPI: token =>
    fetch(`${API_URL}/v3/users/${userID}/badges`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'GET',
    }),
});

export const setUserBadge = badgeID => ({
  type: SET_USER_BADGE,
  badgeID,
});

const logoutSuccess = (auth = {}) => ({
  ...auth,
  type: LOGOUT_SUCCESS,
  isFetching: false,
  isAuthenticated: false,
});

export const subscribe = (
  userID,
  provider,
  data,
  type = 'monthly',
  isDemoSubscription = false,
) => ({
  types: [SUBSCRIBE_REQUEST, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAILURE],
  callAPI: token =>
    fetch(`${API_URL}/v3/users/${userID}/subscriptions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ provider, data, type, isDemoSubscription }),
    }),
});

export const updateSubscription = (userID, action, type = 'monthly') => ({
  types: [UPDATE_SUBSCRIBE_REQUEST, UPDATE_SUBSCRIBE_SUCCESS, UPDATE_SUBSCRIBE_FAILURE],
  callAPI: token =>
    fetch(`${API_URL}/v3/users/${userID}/subscriptions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify({ action, type }),
    }),
});

// Logs the user out
export const logoutUser = auth => dispatch => {
  dispatch(logoutSuccess(auth));
  return Promise.resolve();
};

export const setFetchingStatus = value => ({
  type: SET_FETCHING_STATUS,
  isFetching: value,
});

export const setExternalJwt = value => ({
  type: SET_EXTERNAL_JWT,
  jwt: value,
});
