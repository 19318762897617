import { toggleDebug, toggleJankoMode } from 'tv-actions/old/ui';
import { bindKey } from 'tv-utils/keyBinder';

const setupKeyBindings = store => {
  [
    { key: '<', action: () => toggleDebug() },
    { key: 'j a n k o m o d e', action: () => toggleJankoMode() },
  ].forEach(({ key, action }) =>
    bindKey(`${key}`, e => {
      e.preventDefault();
      store.dispatch(action());
    }),
  );
};

export default setupKeyBindings;
