import colors from 'znipe-styles/colors';

export default {
  proview: {
    useGradient: false,
    desktopIcons: false,
    selectedTextColor: colors.white,
    inactive: colors.grey75,
    activeIcon: '#D3B07C',
    mobileBorder: true,
    desktopTabWidth: '128px',
  },
  esportal: {
    desktopIcons: true,
    useGradient: true,
    selectedTextColor: colors.white,
    inactive: colors.grey75,
    mobileBorder: false,
    desktopTabWidth: 160,
  },
  default: {
    desktopIcons: false,
    useGradient: true,
    selectedTextColor: colors.white,
    inactive: colors.grey75,
    mobileBorder: false,
    desktopTabWidth: '160px',
  },
};
