import { memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ErrorPage from 'tv-modules/Payment/ErrorPage/ErrorPage';
import ErrorImage from 'tv-modules/Payment/ErrorPage/CardError/ErrorImage';

const CardError = ({ header = 'Card declined', description, buttonLabel = 'Try Another card' }) => {
  const { search } = useLocation();

  return (
    <ErrorPage
      header={header}
      description={description}
      image={ErrorImage}
      buttonLabel={buttonLabel}
      to={`..${search}`}
    />
  );
};

CardError.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
};

export default memo(CardError);
