// This file can probably removed later when we've migrated to TS
import isBrowser from 'znipe-utils/web/isBrowser';
import { error } from 'znipe-logger';

const validEvents = [
  'buffering',
  'ended',
  'formatchanged',
  'loadvideo',
  'paused',
  'ready',
  'playing',
  'seeked',
  'started',
  'heartbeat',
];

const alwaysRequired = ['type', 'mediaPosition', 'playbackSession', 'streamSession', 'metadata.ma'];
const alwaysOptional = ['userSession', 'userId', 'metadata.packageName', 'metadata.isPremiumUser'];

const requiredFields = {
  seeked: ['metadata.seekedFromPosition'],
  started: ['metadata.contentType', 'metadata.location'],
  formatchanged: ['metadata.bitrateKbps', 'metadata.fps', 'metadata.resolution'],
  heartbeat: ['metadata.location'],
};

const optionalFields = {
  ready: ['metadata.liveEdgeOffsetMs'],
  seeked: ['metadata.seekTrigger'],
  loadvideo: [
    'customerData.regionId',
    'metadata.videoId',
    'metadata.videoOrigin',
    'metadata.playerTag',
    'metadata.matchId',
    'metadata.productName',
    'metadata.productVersion',
    'metadata.streamId',
    'metadata.url',
    'metadata.location',
    'metadata.referrer',
    'metadata.userAgent',
    'metadata.productId',
  ],
  heartbeat: [
    'metadata.matchId',
    'metadata.videoId',
    'metadata.liveEdgeOffsetMs',
    'metadata.pa',
    'metadata.mu',
    'metadata.vi',
    'metadata.fo',
    'metadata.et',
    'metadata.fu',
  ],
};

const fieldTypes = {
  contentType: 'string',
  matchId: 'string',
  mediaPosition: 'number',
  playbackSession: 'string',
  productName: 'string',
  productVersion: 'string',
  regionId: 'string',
  seekedFromPosition: 'string',
  streamId: 'string',
  videoId: 'string',
  videoOrigin: 'string',
  streamSession: 'string',
  type: 'string',
  url: 'string',
  location: 'string',
  userId: 'string',
  userSession: 'string',
  referrer: 'string',
  userAgent: 'string',
  packageName: 'string',
  isPremiumUser: 'boolean',
  productId: 'string',
  fps: 'number',
  resolution: 'string',
  bitrateKbps: 'number',
  playerTag: 'string',
  et: 'number',
  fo: 'boolean',
  ma: 'boolean',
  mu: 'boolean',
  pa: 'boolean',
  vi: 'boolean',
  fu: 'boolean',
};

/* eslint-disable valid-typeof */
export const validateEvent = (fields, nodeEnv) => {
  const { type } = fields;
  // biome-ignore lint/suspicious/useValidTypeof: It is a string literal
  if (!type || typeof type !== fieldTypes.type || !validEvents.includes(type)) {
    if (nodeEnv === 'development') {
      error('Invalid event type:', type, 'Valid events are:', ...validEvents);
    }
    return false;
  }

  const requiredFieldsForEvent = [...(requiredFields[type] ?? []), ...alwaysRequired];
  const invalidFields = requiredFieldsForEvent.filter(requiredField => {
    if (requiredField.indexOf('.') !== -1) {
      const [rootKey, key] = requiredField.split('.'); // Assuming that you can't nestle info more than 1 layer
      const rootValue = fields[rootKey];
      if (typeof rootValue === 'undefined') return true;
      if (typeof rootValue !== 'object') return true;

      const keyValue = rootValue[key];
      // biome-ignore lint/suspicious/useValidTypeof: It is a string literal
      if (typeof keyValue !== fieldTypes[key]) return true;
      return false;
    }

    if (!Object.keys(fields).includes(requiredField)) return true;
    // biome-ignore lint/suspicious/useValidTypeof: It is a string literal
    if (typeof fields[requiredField] !== fieldTypes[requiredField]) return true;

    return false;
  });

  if (invalidFields.length > 0) {
    if (nodeEnv === 'development') {
      error('Invalid or missing fields for event', type, 'fields:', ...invalidFields);
    }
    return false;
  }

  return true;
};
/* eslint-enable valid-typeof */

export const getCurrentProduct = productName => {
  const pathname = isBrowser() ? window.location.pathname : '';
  if (!pathname) return productName;
  if (pathname.match(/^\/channels\/.+\/.+/)) {
    if (pathname.match(/\/lol$/)) return 'pro-view';
    if (pathname.match(/\/lg$/)) return 'lg-uplus';
  }
  if (pathname.match(/^\/lg-secondary-stage$/)) return 'lg-uplus';
  if (pathname.match(/^\/riot-vods$/)) return 'pro-view';
  if (pathname.match(/\/lol-stage$/)) return 'pro-view';
  return productName;
};

export const cleanFields = fields => {
  const { type } = fields;
  const eventFields = [
    ...(requiredFields[type] ?? []),
    ...(optionalFields[type] ?? []),
    ...alwaysRequired,
    ...alwaysOptional,
  ];
  const flattenedEventFields = eventFields.map(field => {
    if (field.indexOf('.') !== -1) {
      const split = field.split('.');
      return split[split.length - 1];
    }
    return field;
  });

  const helper = (acc, key, _index, _source, currentValue) => {
    const value = currentValue ? currentValue[key] : fields[key];

    if (typeof value === 'object') {
      const keys = Object.keys(value);
      if (keys.length < 1) return acc;
      const res = keys.reduce((...args) => helper(...args, value), {});
      return {
        ...acc,
        [key]: res,
      };
    }
    if (flattenedEventFields.includes(key)) return { ...acc, [key]: value };

    return acc;
  };

  return Object.keys(fields).reduce(helper, {});
};

let sequenceNumber = 0;

const queuePlaybackEvent = (fields, analyticsManager, nodeEnv) => {
  if (!validateEvent(fields, nodeEnv)) return;
  const cleanedFields = cleanFields(fields);

  cleanedFields.sequenceNumber = sequenceNumber;
  sequenceNumber += 1;
  analyticsManager.trackPlaybackEvent(cleanedFields);
};

export default queuePlaybackEvent;
