import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  defaultError,
  ERROR,
  firebaseErrors,
  LOADING,
  SUCCESS,
} from 'tv-modules/Authentication/constants';
import firebase from 'firebase/app';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  ButtonWrapper,
  EnvelopeSVG,
  ErrorText,
  FakeLink,
  LinkTypography,
  TypographyNoMargin,
} from 'tv-modules/Authentication/Shared/Shared';
import Button from 'znipe-elements/general/Button_deprecated/Button';

const SuccessRoute = ({ state, setState, userEmail, continuePath }) => {
  const [error, setError] = useState({});
  const [resent, setResent] = useState(false);
  const { packageSlug } = useParams();
  const resend = useCallback(() => {
    if (state === LOADING) return;
    setState(LOADING);

    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => setState(SUCCESS))
      .then(() => setResent(true))
      .catch(({ code }) => {
        setState(ERROR);
        setError(firebaseErrors[code] ?? {});
      });
  }, [setState, state]);

  const home = useMemo(() => (packageSlug ? `/${packageSlug}/home` : '/home'), [packageSlug]);
  return (
    <>
      <Typography type="heading-m">
        {resent ? 'Resent verification email' : 'Verify your email'}
      </Typography>
      <EnvelopeSVG />
      <TypographyNoMargin type="paragraph-s" align="center">
        An email has been sent to <b>{userEmail}</b> for account verification.
      </TypographyNoMargin>
      <ButtonWrapper>
        <Button variant="solid-color" size="small" to={continuePath ?? home}>
          Continue to site
        </Button>
      </ButtonWrapper>
      <LinkTypography $center>
        Didn&apos;t get the email? <FakeLink onClick={resend}>Resend</FakeLink>
      </LinkTypography>
      {state === ERROR && <ErrorText type="paragraph-s">{error.message ?? defaultError}</ErrorText>}
    </>
  );
};

SuccessRoute.propTypes = {
  state: PropTypes.oneOf([SUCCESS, LOADING, ERROR, '']).isRequired,
  setState: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  continuePath: PropTypes.string,
};

export default SuccessRoute;
