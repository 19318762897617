import { memo, useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LOL_PATCH_VERSION } from 'znipe-constants/misc';
import config from 'tv-config/config';
import logger from 'znipe-logger';
import generateHashCodeFromString from 'znipe-utils/misc/generateHashCodeFromString';
import { fetchGqlQuery } from 'znipe-gql/hooks/useGqlQuery/useGqlQuery';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import InputField from 'znipe-elements/data-entry/InputField/InputField';
import FilterDetails from 'tv-modules/Filters/FilterDetails/FilterDetails';
import { SEARCH_SUGGESTIONS_ENDPOINT } from 'tv-constants/apiEndpoints';
import MenuPanel from 'znipe-elements/layout/MenuPanel/MenuPanel';
import usePackageName from 'tv-hooks/usePackageName';
import { selectionShape, defaultFilterSelection } from '../Filters.constants';
import { SearchBar } from './FilterPanel.styles';
import SearchSuggestions from './components/SearchSuggestions';
import FilterSubmenuQuery from './getFilterSubmenuData.graphql';
import formatFilterSubmenuData from '../utils/formatFilterSubmenuData';

const HIDDEN = 'hidden';
const PASSIVE = 'passive';
const ACTIVE = 'active';

const FilterPanel = ({
  selection = defaultFilterSelection,
  menuOptions,
  isActive = false,
  useRolesFilter = false,
  onChangeSelection = () => {},
  onClose = () => {},
  onClearAll = () => {},
  onClear = () => {},
}) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const [searchState, setSearchState] = useState(HIDDEN);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [browseResult, setBrowseResult] = useState({
    players: { hits: [] },
    teams: { hits: [] },
    events: { hits: [] },
    champions: { hits: [] },
  });
  const packageName = usePackageName();

  const getResultsInAvailableCategories = useCallback(
    hits =>
      hits
        .map(hit => {
          const { _type } = hit;
          if (_type === 'riotChampions') {
            const { title: description, tags } = hit;
            const role = tags?.length > 0 ? tags[0].toLowerCase() : '';
            return { ...hit, _type: 'champions', description, role };
          }
          return hit;
        })
        .filter(({ _type }) => menuOptions.includes(_type)),
    [menuOptions],
  );

  const fetchSearchSuggestions = useCallback(
    query => {
      if (!query) return;
      fetch(
        `${SEARCH_SUGGESTIONS_ENDPOINT}?q=${query}&f=packages.humanReadableId:${packageName},patch:${LOL_PATCH_VERSION}&l=10&asSingleList=true`,
        {
          headers: {
            'Znipe-Search-Context': 'web',
          },
        },
      )
        .then(res => res.json())
        .then(res => {
          const { results = [] } = res;
          const { hits } = results;
          const filteredHits = getResultsInAvailableCategories(hits);
          if (filteredHits) setSearchSuggestions(filteredHits);
        });
    },
    [packageName, getResultsInAvailableCategories],
  );

  const handleClickSearch = useCallback(
    query => {
      if (query) {
        fetchSearchSuggestions(query);
        setSearchState(ACTIVE);
        return;
      }
      setSearchState(HIDDEN);
    },
    [fetchSearchSuggestions],
  );

  const handleClickDelete = useCallback(() => {
    setSearchState(PASSIVE);
  }, []);

  const handleFocus = useCallback(
    e => {
      if (searchState === ACTIVE) return;
      if (!e.target.value) setSearchState(PASSIVE);
    },
    [searchState],
  );

  const handleChangeSelection = useCallback(
    newSelection => onChangeSelection({ ...selection, ...newSelection }),
    [selection, onChangeSelection],
  );

  const queryHash = useMemo(() => generateHashCodeFromString(FilterSubmenuQuery).toString(), []);

  const getInitialFetchResult = useCallback(
    async () =>
      fetchGqlQuery(
        {
          query: FilterSubmenuQuery,
          variables: {
            package: packageName,
            patch: LOL_PATCH_VERSION,
          },
          extensions: {
            persistedQuery: {
              version: 1,
              sha256Hash: queryHash,
            },
          },
        },
        { url: config.GRAPHQL_API_URL },
        true,
      ),
    [packageName, queryHash],
  );

  useEffect(() => {
    getInitialFetchResult()
      .then(res => {
        const browseData = formatFilterSubmenuData(res?.data);
        setBrowseResult(browseData);
      })
      .catch(err => logger.error('Failed to fetch filter submenu data', err));
  }, [getInitialFetchResult]);

  return (
    <MenuPanel
      type={isTabletOrGreater ? 'small' : 'scaling'}
      isActive={isActive}
      header="FILTER"
      onClose={onClose}
    >
      <SearchBar>
        <InputField
          placeholder="Search tags"
          onClickIcon={handleClickSearch}
          onSearchChange={handleClickSearch}
          onClickDelete={handleClickDelete}
          onFocus={handleFocus}
          size="small"
        />
      </SearchBar>
      {searchState === ACTIVE ? (
        <SearchSuggestions
          selection={selection}
          suggestions={searchSuggestions}
          onChangeSelection={handleChangeSelection}
        />
      ) : (
        <FilterDetails
          selection={selection}
          menuOptions={menuOptions}
          onChange={onChangeSelection}
          isActive={isActive}
          onClearAll={onClearAll}
          onClear={onClear}
          openSubMenu={openSubMenu}
          onClickSubMenu={setOpenSubMenu}
          menuData={browseResult}
          useRolesFilter={useRolesFilter}
        />
      )}
    </MenuPanel>
  );
};

FilterPanel.propTypes = {
  selection: PropTypes.shape(selectionShape),
  menuOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isActive: PropTypes.bool,
  useRolesFilter: PropTypes.bool,
  onChangeSelection: PropTypes.func,
  onClose: PropTypes.func,
  onClearAll: PropTypes.func,
  onClear: PropTypes.func,
};

export default memo(FilterPanel);
