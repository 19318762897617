import { memo, useMemo, forwardRef, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSprings } from 'react-spring';
import ShakaPlayer, {
  ShakaPlayerWithAnalytics,
} from 'znipe-player/src/components/ShakaPlayer/ShakaPlayer';
import Debug from 'znipe-player/src/components/Debug/Debug';
import useVideoSync from 'znipe-player/src/hooks/useVideoSync';
import useVideosWithRef from 'znipe-player/src/hooks/useVideosWithRef';
import useRefWithPlayerFunctionsAndEvents from 'znipe-player/src/hooks/useRefWithPlayerFunctionsAndEvents';
import usePrevious from 'znipe-hooks/usePrevious';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import ZnipeEvent from 'znipe-utils/events/ZnipeEvent';
import isEqual from 'lodash.isequal';
import OverlayComponent from 'znipe-elements/layout/Overlay/Overlay';
import estimateLoadTime from 'znipe-player/src/utils/estimateLoadTime';
import { useFeatureFlag } from 'znipe-link/link';
import {
  OuterAspectRatioContainer,
  AspectRatioContainer,
  VideoWrapper,
  VideoContent,
  HiddenPlayer,
  ActiveStreamOverlay,
  Overlay,
  OverlayContent,
} from './MultiView.styles';
import { defaultDimensions, ultraWideDimensions } from './dimensions';

const defaultVideos = [];
const emptyObject = {};

const MultiView = forwardRef(
  (
    {
      startTime,
      analyticsParams,
      externalSyncRef,
      liveDelay,
      maxPovs = 4,
      id,
      videos = defaultVideos,
      onReady,
      debug = false,
      debugInfoEvent = false,
      autoPlay = true,
      initialSeekRange = {},
      initialConfiguration = {},
      useUltraWideLayout = false,
      muted = true,
      audioOnlyMuted = false,
      videoStreamsMuted = false,
      onClick = null,
      isFullscreen = false,
    },
    ref,
  ) => {
    const storedTime = useRef();
    const Player = analyticsParams ? ShakaPlayerWithAnalytics : ShakaPlayer;
    const amountOfStreams = videos?.filter(video => !video.audioOnly).length;
    const videosWithRef = useVideosWithRef(videos);
    // biome-ignore lint/correctness/useExhaustiveDependencies: Only set initial state once
    const initialState = useMemo(
      () => ({
        muted,
        audioOnlyMuted: muted || audioOnlyMuted,
        videoStreamsMuted: muted || videoStreamsMuted,
        paused: !autoPlay,
      }),
      // Only set initial state once
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    const [player, state, setState] = useRefWithPlayerFunctionsAndEvents(
      videosWithRef,
      ref,
      initialState,
    );
    const videosWithSyncInfo = useVideoSync(
      videosWithRef,
      debug,
      externalSyncRef,
      state,
      ref,
      debugInfoEvent,
    );
    const videoPlayerId = useMemo(() => generateUniqueId(), []);
    const initializedDate = useMemo(() => new Date(), []);
    const prevVideos = usePrevious(videos) ?? defaultVideos;

    const loadEstimationMode = useFeatureFlag('load-estimation-mode');

    const getVideoStartTime = useMemo(() => {
      if (id) storedTime.current = undefined;
      return offset => {
        const getCorrectCurrentTime = () => {
          if (externalSyncRef && externalSyncRef.current) {
            const estimatedBufferTime = externalSyncRef.current.estimatedBandwidth
              ? (4 * 1500000) / externalSyncRef.current.estimatedBandwidth // @TODO make (4 * 1000000) dynamic. Number of seconds * quality
              : 0;

            return externalSyncRef.current.currentTime - offset + estimatedBufferTime;
          }

          const { loadLatency } = player.getStats();
          const calculatedLoadLatency = player.isPaused()
            ? 0
            : estimateLoadTime(player, loadEstimationMode && loadEstimationMode !== 'default');
          const estimatedBufferTime = Math.max(
            loadEstimationMode === 'load-latency' ? loadLatency : 0,
            calculatedLoadLatency,
          );

          if (player.getCurrentTime && !isNaN(player.getCurrentTime())) {
            return player.getCurrentTime() - offset + estimatedBufferTime;
          }

          if (storedTime.current !== undefined) {
            return storedTime.current - offset + estimatedBufferTime;
          }

          if (!isNaN(startTime)) return startTime - offset + estimatedBufferTime;

          return undefined;
        };
        const calculatedStartTime = getCorrectCurrentTime(); // Select start time of playing videos if they exist
        if (isNaN(calculatedStartTime)) return undefined;
        return Math.max(calculatedStartTime, 0);
      };
    }, [startTime, externalSyncRef, player, id, loadEstimationMode]);

    useEffect(() => {
      if (onReady) onReady();

      const onLoaded = () => {
        setState('muted', player.isMuted());
        player.removeEventListener('loaded', onLoaded);
      };
      player.addEventListener('loaded', onLoaded);
      return () => {
        player.removeEventListener('loaded', onLoaded);
      };
    }, [onReady, setState, player]);

    useEffect(() => {
      if (!id || !player) return () => {};
      const handlePauseStatus = e => {
        setState('paused', e.target.isPaused());
      };
      player.addEventListener('play', handlePauseStatus);
      player.addEventListener('pause', handlePauseStatus);

      return () => {
        player.removeEventListener('play', handlePauseStatus);
        player.removeEventListener('pause', handlePauseStatus);
      };
    }, [player, id, setState]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We only want this to trigger when the id changes
    useEffect(() => {
      setState('paused', !autoPlay);
      // We only want this to trigger when the id changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (!id || !player) return () => {};
      const handleTimeupdate = e => {
        storedTime.current = e.currentTime;
      };
      player.addEventListener('timeupdate', handleTimeupdate);

      return () => player.removeEventListener('timeupdate', handleTimeupdate);
    }, [player, id]);

    const handleQuality = useCallback(() => {
      const { quality } = state.current;
      if (typeof quality !== 'number') return;
      player.selectQuality(quality, false);
    }, [state, player]);

    useEffect(() => {
      player.addEventListener('loaded', handleQuality);
      return () => {
        player.removeEventListener('loaded', handleQuality);
      };
    }, [handleQuality, player]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Need to set quality again when amount of streams changes
    useEffect(() => {
      handleQuality();
    }, [handleQuality, amountOfStreams]);

    useEffect(() => {
      if (!player) return;

      const currentMasterVideo = videos.find(video => video.master) || emptyObject;
      const prevMasterVideo = prevVideos.find(video => video.master) || emptyObject;

      // set the new master as sound source when master changes
      if (currentMasterVideo.id !== prevMasterVideo.id) {
        player.setMasterAsSoundSource(state.current.muted);
      }

      // make sure to set volume on the new videos
      if (!isEqual(prevVideos, videos)) {
        const { audioOnlyVolume, videoStreamsVolume } = state.current;
        if (Number.isFinite(videoStreamsVolume)) {
          player.setVideoStreamVolume(videoStreamsVolume, true);
        } else {
          setState('videoStreamsVolume', player.getVideoStreamVolume());
        }

        if (Number.isFinite(audioOnlyVolume)) {
          player.setAudioOnlyVolume(audioOnlyVolume, true);
        } else {
          setState('audioOnlyVolume', player.getAudioOnlyVolume());
        }
      }
    }, [videos, prevVideos, state, player, setState]);

    const gridVideos = videosWithSyncInfo.filter(video => !video.audioOnly);
    const audioOnlyVideo = videosWithSyncInfo.filter(video => video.audioOnly);

    const prevGridVideos = usePrevious(gridVideos);
    const prevAudioOnlyVideo = usePrevious(audioOnlyVideo);

    const handleUnloadWhenRemovedVideos = useCallback(
      (current, prev) => {
        if (current?.length >= (prev?.length ?? 0)) return;
        const currentIds = current.map(video => video.id);

        const removedVideos = prev.filter(video => !currentIds.includes(video.id));

        removedVideos.forEach(video =>
          player.dispatchEvent(
            new ZnipeEvent('unloading', {
              id: video.id,
              master: video.master,
              audioOnly: video.audioOnly,
            }),
          ),
        );
      },
      [player],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: We only want this to trigger when the video streams length changes
    useEffect(() => {
      handleUnloadWhenRemovedVideos(gridVideos, prevGridVideos);
      // We only want this to trigger when the video streams length changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridVideos.length]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We only want this to trigger when the audio streams length changes
    useEffect(() => {
      handleUnloadWhenRemovedVideos(audioOnlyVideo, prevAudioOnlyVideo);
      // We only want this to trigger when the audio streams length changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioOnlyVideo.length]);

    const videoPlaceHolder = useMemo(() => Array.from({ length: maxPovs }), [maxPovs]);

    const dimensions = useMemo(
      () => (useUltraWideLayout ? ultraWideDimensions : defaultDimensions),
      [useUltraWideLayout],
    );
    const springs = useSprings(
      maxPovs,
      videoPlaceHolder.map((_, index) => {
        const splitData = dimensions[amountOfStreams];
        if (!splitData) return emptyObject;
        const data = splitData[index];
        const fallback = splitData[splitData.length - 1];
        const { scale, x, y } = data ?? fallback;
        return {
          scale,
          x: `${x}%`,
          y: `${y}%`,
          opacity: data ? 1 : 0,
        };
      }),
    );

    const overlaySprings = useSprings(
      maxPovs,
      videoPlaceHolder.map((_, index) => {
        const splitData = dimensions[amountOfStreams];
        if (!splitData) return emptyObject;
        const data = splitData[index];
        const fallback = splitData[splitData.length - 1];
        const { scale } = data ?? fallback;
        const counterScale = 1 / scale;
        const size = `${scale * 100}%`;
        return {
          scale: counterScale,
          width: size,
          height: size,
        };
      }),
    );

    return (
      <OuterAspectRatioContainer
        videosAmount={amountOfStreams}
        useUltraWideLayout={useUltraWideLayout}
      >
        <AspectRatioContainer
          onClick={onClick}
          data-testid="video-container"
          videosAmount={amountOfStreams}
          useUltraWideLayout={useUltraWideLayout}
        >
          {audioOnlyVideo.map(
            ({ id: streamId, master, src, poster, ref: videoPlayerRef, offset = 0 }) => {
              const realStartTime = getVideoStartTime(offset);
              const { start, end } = initialSeekRange;
              const configuration = { ...initialConfiguration, manifest: { disableVideo: true } };
              configuration.playRangeStart = typeof start === 'number' ? start - offset : undefined;
              configuration.playRangeEnd = typeof end === 'number' ? end - offset : undefined;
              const isMuted = state.current.muted || state.current.audioOnlyMuted;
              return (
                <HiddenPlayer key={streamId} data-testid="hidden-player">
                  <Player
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...(analyticsParams ?? emptyObject)}
                    ref={videoPlayerRef}
                    src={src}
                    poster={poster}
                    startTime={realStartTime}
                    autoPlay={autoPlay && !state.current.paused}
                    muted={isMuted}
                    videoPlayerId={videoPlayerId}
                    streamId={streamId}
                    liveDelay={liveDelay}
                    configuration={configuration}
                    disablePictureInPicture
                    master={master}
                    initializedDate={initializedDate}
                    isFullscreen={isFullscreen}
                  />
                </HiddenPlayer>
              );
            },
          )}
          {springs.map((styles, index) => {
            const {
              id: streamId = `empty-${index}`,
              src,
              poster,
              ref: videoPlayerRef,
              debug: debugInfo,
              hoverOverlay,
              overlay,
              offset = 0,
              master,
              isHighlighted,
            } = gridVideos[index] ?? emptyObject;
            const realStartTime = getVideoStartTime(offset);
            const { start, end } = initialSeekRange;
            const configuration = {};
            configuration.playRangeStart = typeof start === 'number' ? start - offset : undefined;
            configuration.playRangeEnd = typeof end === 'number' ? end - offset : undefined;
            const isMuted = state.current.muted || state.current.videoStreamsMuted || !master;
            const overlayStyles = overlaySprings[index];
            const isHighlightedStream = isHighlighted || false;
            const show = index < amountOfStreams;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <VideoWrapper key={streamId} index={index} style={styles}>
                {show && (
                  <VideoContent data-testid="video-content">
                    <OverlayComponent
                      overlay={overlay}
                      contentStyles={overlayStyles}
                      topMost={!debug}
                    >
                      <Player
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...(analyticsParams ?? emptyObject)}
                        ref={videoPlayerRef}
                        src={show ? src : ''}
                        poster={poster}
                        startTime={realStartTime}
                        autoPlay={autoPlay && !state.current.paused}
                        muted={isMuted}
                        videoPlayerId={videoPlayerId}
                        streamId={streamId}
                        liveDelay={liveDelay}
                        configuration={configuration}
                        disablePictureInPicture
                        master={master}
                        initializedDate={initializedDate}
                        isFullscreen={isFullscreen}
                      />
                      {Boolean(hoverOverlay || debug) && (
                        <OverlayContent style={overlayStyles}>
                          {hoverOverlay && (
                            <Overlay data-testid="hover-overlay">{hoverOverlay}</Overlay>
                          )}
                          {debug && <Debug info={debugInfo} />}
                        </OverlayContent>
                      )}
                      {isHighlightedStream && <ActiveStreamOverlay />}
                    </OverlayComponent>
                  </VideoContent>
                )}
              </VideoWrapper>
            );
          })}
        </AspectRatioContainer>
      </OuterAspectRatioContainer>
    );
  },
);

// Checks that the videos array has exactly one master
const countMasters = (acc, obj) => {
  if (obj.master) return acc + 1;
  return acc;
};

const masterIsAudioOnly = videos => videos.some(video => video.master && video.audioOnly);

export const videosPropType = (props, propName) => {
  const prop = props[propName];

  const amountOfMasters = prop.reduce(countMasters, 0);
  if (!prop) {
    throw Error(`The prop ${propName} in component MultiView is required.`);
  }
  if (!Array.isArray(prop)) {
    throw Error(
      `The prop ${propName} in component MultiView should be an array. Current type is ${typeof prop}`,
    );
  }
  if (prop.length > 0) {
    if (amountOfMasters !== 1) {
      throw Error(
        `The prop ${propName} in component MultiView must include exactly one master. Currently has ${amountOfMasters}`,
      );
    }

    if (masterIsAudioOnly(props.videos)) {
      throw Error(
        `The prop ${propName} in component MultiView cannot have a master that is also audioOnly.`,
      );
    }
  }

  PropTypes.checkPropTypes(
    {
      [propName]: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          src: PropTypes.string.isRequired,
          offset: PropTypes.number.isRequired,
          master: PropTypes.bool.isRequired,
          poster: PropTypes.string,
          hoverOverlay: PropTypes.node,
          overlay: PropTypes.node,
        }),
      ),
    },
    props,
    'prop',
    'MultiView',
  );
};

MultiView.propTypes = {
  maxPovs: PropTypes.number,
  id: PropTypes.string,
  videos: videosPropType,
  onReady: PropTypes.func, // Used to tell parent when ref is ready for event listeners
  debug: PropTypes.bool,
  debugInfoEvent: PropTypes.bool,
  autoPlay: PropTypes.bool,
  startTime: PropTypes.number,
  liveDelay: PropTypes.number,
  muted: PropTypes.bool, // Used for initial value
  audioOnlyMuted: PropTypes.bool, // Used for initial value
  videoStreamsMuted: PropTypes.bool, // Used for initial value
  initialSeekRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  initialConfiguration: PropTypes.shape({}),
  useUltraWideLayout: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  analyticsParams: PropTypes.shape({
    matchId: PropTypes.string,
    userSession: PropTypes.string,
    userId: PropTypes.string,
    region: PropTypes.string,
    isIOS: PropTypes.bool,
    isPremiumUser: PropTypes.bool,
    productId: PropTypes.string,
    packageName: PropTypes.string,
    authManagerId: PropTypes.string,
    clientConfig: PropTypes.shape({
      PRODUCT_NAME: PropTypes.string.isRequired,
      NODE_ENV: PropTypes.string.isRequired,
      ANALYTICS_EVENT_API_URL: PropTypes.string.isRequired,
      VERSION: PropTypes.string.isRequired,
    }),
  }),
  externalSyncRef: PropTypes.shape({
    current: PropTypes.shape({
      currentTime: PropTypes.number.isRequired,
      offset: PropTypes.number,
      playbackRate: PropTypes.number,
      estimatedBandwidth: PropTypes.number,
    }).isRequired,
  }),
  onClick: PropTypes.func,
};

// Only re-render if videos array change
const areEqual = (prevProps, nextProps) => {
  if (prevProps.debug !== nextProps.debug) return false;
  if (prevProps.useUltraWideLayout !== nextProps.useUltraWideLayout) return false;
  if (!isEqual(prevProps.videos, nextProps.videos)) return false;
  if (prevProps.isFullscreen !== nextProps.isFullscreen) return false; // This is needed for analytics
  return true;
};

export default memo(MultiView, areEqual);
