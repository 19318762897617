import { ReactNode, useMemo, useRef, useState } from 'react';
import loadable from '@loadable/component';
import type { ElementProps, MiddlewareState } from '@floating-ui/react';
import isTouchDevice from 'znipe-utils/web/isTouchDevice';
import { FloatingUIProps, PlacementTypes, SizeTypes, TriggerTypes } from './ToolTip.types';
import {
  FloatingWrapper,
  Hotkey,
  LabelText,
  Reference,
  Text,
  Tail,
  initialTranslate,
  ToolTipContainer,
} from './ToolTip.styles';

const isOnTouchDevice = isTouchDevice() as boolean;

const FloatingUI = loadable.lib(() => import('@floating-ui/react'), { ssr: false });

export type ToolTipProps = {
  label: string | ReactNode | ReactNode[];
  children: ReactNode;
  size?: SizeTypes;
  placement?: PlacementTypes;
  hotkey?: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  tailColor?: string;
  placementOffset?: number;
  transitionDuration?: number;
  delay?: number;
  triggers?: TriggerTypes[];
  withTail?: boolean;
  uppercase?: boolean;
  isVisible?: boolean;
  fluidWidth?: boolean;
  disableOnTouchDevice?: boolean;
  keepOpen?: boolean;
};

const ToolTip: React.FC<ToolTipProps & FloatingUIProps> = ({
  label,
  children,
  size = 'medium',
  placement = 'top',
  hotkey = '',
  textColor = '',
  borderColor = '',
  backgroundColor = '',
  tailColor = '',
  placementOffset = 4,
  transitionDuration = 0,
  delay = 0,
  triggers = ['hover'],
  withTail = false,
  uppercase = true,
  isVisible = true,
  fluidWidth = false,
  disableOnTouchDevice = true,
  keepOpen = false,
  floating,
}) => {
  const {
    arrow,
    detectOverflow,
    flip,
    offset,
    shift,
    useClick,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useTransitionStyles,
    FloatingArrow,
  } = floating;
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const customDetectOverflow = useMemo(
    () => ({
      name: 'middleware',
      async fn(state: MiddlewareState) {
        await detectOverflow(state);
        return {};
      },
    }),
    [detectOverflow],
  );
  const defaultMiddleware = useMemo(
    () => [customDetectOverflow, flip(), shift(), offset(placementOffset)],
    [customDetectOverflow, placementOffset, flip, offset, shift],
  );

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen || keepOpen,
    placement,
    middleware: withTail ? [...defaultMiddleware, arrow({ element: arrowRef })] : defaultMiddleware,
    onOpenChange: setIsOpen,
  });

  const { styles: transitionStyles, isMounted } = useTransitionStyles(context, {
    duration: transitionDuration,
    initial: ({ side }) => ({
      opacity: 0,
      transform: initialTranslate[side],
    }),
    open: { opacity: 1, transform: 'translate(0, 0)' },
    close: ({ side }) => ({ opacity: 0, transform: initialTranslate[side] }),
  });

  const isUsingClickAsTrigger = useMemo(() => triggers.includes('click'), [triggers]);

  const click = useClick(context, { toggle: true });
  const focus = useFocus(context, {
    enabled: !isUsingClickAsTrigger,
  });
  const hover = useHover(context, {
    restMs: delay,
    mouseOnly: true,
    enabled: !isUsingClickAsTrigger,
  });
  const interactionProps = useMemo(() => {
    const list = [] as ElementProps[];
    if (keepOpen) return list;
    if (isUsingClickAsTrigger) list.push(click);
    if (triggers.includes('focus') && !isUsingClickAsTrigger) list.push(focus);
    if (triggers.includes('hover') && !isUsingClickAsTrigger) list.push(hover);
    return list;
  }, [keepOpen, triggers, isUsingClickAsTrigger, click, hover, focus]);
  const { getReferenceProps, getFloatingProps } = useInteractions(interactionProps);

  if (
    !isVisible ||
    (disableOnTouchDevice && isOnTouchDevice) ||
    (!keepOpen && triggers?.length === 0)
  )
    return <div>{children}</div>;

  return (
    <>
      <Reference ref={refs.setReference} $fluidWidth={fluidWidth} {...getReferenceProps()}>
        {children}
      </Reference>
      {isMounted && (
        <FloatingWrapper ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <ToolTipContainer
            style={transitionStyles}
            $size={size}
            $textColor={textColor}
            $borderColor={borderColor}
            $backgroundColor={backgroundColor}
            $uppercase={uppercase}
            $tailColor={tailColor}
          >
            {typeof label === 'string' ? (
              <LabelText data-testid="label">
                <Text type={size === 'small' ? 'paragraph-s' : 'paragraph-m'}>{label}</Text>
                {hotkey && <Hotkey $textColor={textColor}>{hotkey}</Hotkey>}
              </LabelText>
            ) : (
              label
            )}
            {withTail && <Tail as={FloatingArrow} ref={arrowRef} context={context} />}
          </ToolTipContainer>
        </FloatingWrapper>
      )}
    </>
  );
};

const FloatingToolTip: React.FC<ToolTipProps> = props => (
  <FloatingUI>{floating => <ToolTip {...props} floating={floating} />}</FloatingUI>
);

export default FloatingToolTip;
