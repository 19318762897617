import styled from 'styled-components';
import colors from 'znipe-styles/colors';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';

export const StrikeThrough = styled.div`
  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    transform: translateY(-2px);
  }

  &:before {
    background: ${`linear-gradient(90deg, ${convertHexToRGB(colors.grey85, 0)}, ${colors.grey85})`};
    right: ${({ $showText }) => ($showText ? '1rem' : 0)};
    margin-left: ${({ $showText }) => ($showText ? '-50%' : 0)};
  }

  &:after {
    background: ${`linear-gradient(90deg, ${colors.grey85}, ${convertHexToRGB(colors.grey85, 0)})`};
    left: ${({ $showText }) => ($showText ? '1rem' : 0)};
    margin-right: ${({ $showText }) => ($showText ? '-50%' : 0)};
  }
`;

export const GamePause = styled.div`
  position: absolute;
  height: 14px;
  left: ${({ $left }) => `${$left}%`};
  width: ${({ $pauseWidth }) => `${$pauseWidth}%`};
  transform: translateY(-10px);
  text-transform: uppercase;
  color: ${colors.grey85};
`;
