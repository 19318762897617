import { useMemo } from 'react';
import type { Avatar } from 'znipe-chat/src/contexts/ChatContext';
import useChatContext from './useChatContext';

const useAvatarImage = (
  avatarId: string | null,
  fallbackToDefault = false,
  providedAvatars?: Avatar[],
) => {
  const { avatars: contextAvatars } = useChatContext() ?? {};

  const avatars = providedAvatars ?? contextAvatars;
  return useMemo(() => {
    if (avatarId === null) return null;
    const image = avatars?.find(avatar => avatar.id === avatarId)?.img;
    if (image) return image;
    if (!fallbackToDefault) return '';
    return avatars?.find(avatar => avatar.default)?.img ?? '';
  }, [avatarId, avatars, fallbackToDefault]);
};

export default useAvatarImage;
