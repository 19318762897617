import { useCallback, useRef, useMemo } from 'react';
import useDeepCompareEffect from 'znipe-hooks/useDeepCompareEffect';
import subscribe from 'znipe-utils/web/subscribe';
import { UPDATE_INTERVAL } from 'znipe-player/src/constants';
import ZnipeEvent from 'znipe-utils/events/ZnipeEvent';
import selectMaster, { Shaka, Video } from 'znipe-player/src/utils/selectMaster';

const PLAYER_EVENTS_ID = 'PLAYER_EVENTS_ID';

type EventTarget = {
  dispatchEvent: (event: ZnipeEvent) => void;
  getCurrentTime: () => number;
  getSeekRange: () => { start: number; end: number };
  pause: () => void;
};

const usePlayerEvents = (eventTarget: EventTarget, videos: Video[]) => {
  const registeredIds = useRef<string[]>([]);
  const masterPlayer = selectMaster(videos);
  const reCreateEvent = useCallback(
    (
      element: HTMLVideoElement | EventTarget | Shaka,
      eventName: string,
      forwardKey?: string,
      defaultDetails: Record<string, unknown> = {},
    ) => {
      if (!element || !eventName) return () => {};
      return subscribe(element, eventName, (event: ZnipeEvent) => {
        const details = forwardKey ? { [forwardKey]: event } : {};

        eventTarget.dispatchEvent(new ZnipeEvent(eventName, { ...defaultDetails, ...details }));
      });
    },
    [eventTarget],
  );

  const filteredVideos = useMemo(
    () => videos?.filter(video => video.id !== 'empty' && !!video.id),
    [videos],
  );

  const videoIds = useMemo(() => filteredVideos?.map(video => video.id), [filteredVideos]);

  useDeepCompareEffect(() => {
    const subscriptions: (() => void)[] = [];
    let interval: number | null = null;

    // biome-ignore lint/style/useConst:
    let waitForPlayerInterval: number | undefined;
    // This is needed as the ref might not be ready
    const setupEvents = () => {
      const player = masterPlayer?.ref?.current;
      if (!eventTarget || !player) return;
      const mediaElement = player.getMediaElement();
      if (!mediaElement) return;

      filteredVideos.forEach(video => {
        const currentPlayer = video.ref?.current;
        if (!currentPlayer) return;
        const currentMediaElement = currentPlayer.getMediaElement();
        if (!currentMediaElement) return;
        if (registeredIds.current.includes(video.id)) return;
        const eventData = {
          id: video.id,
          master: video.master,
          audioOnly: video.audioOnly,
        };
        // Shaka events
        subscriptions.push(reCreateEvent(currentPlayer, 'loaded', undefined, eventData));
        subscriptions.push(reCreateEvent(currentPlayer, 'unloading', undefined, eventData));
        // Video events
        subscriptions.push(
          reCreateEvent(currentMediaElement, 'volumechange', undefined, eventData),
        );
        registeredIds.current.push(video.id);
      });
      if (filteredVideos.length < registeredIds.current.length)
        clearInterval(waitForPlayerInterval);

      if (registeredIds.current.includes(PLAYER_EVENTS_ID)) return;

      // Shaka events
      subscriptions.push(reCreateEvent(player, 'abrstatuschanged', 'newStatus'));
      subscriptions.push(reCreateEvent(player, 'adaptation'));
      subscriptions.push(reCreateEvent(player, 'buffering', 'buffering'));
      subscriptions.push(reCreateEvent(player, 'loading'));
      subscriptions.push(reCreateEvent(player, 'streaming'));
      subscriptions.push(reCreateEvent(player, 'trackschanged'));
      subscriptions.push(reCreateEvent(player, 'variantchanged'));

      // Video events
      subscriptions.push(reCreateEvent(mediaElement, 'abort'));
      subscriptions.push(reCreateEvent(mediaElement, 'loadeddata'));
      subscriptions.push(reCreateEvent(mediaElement, 'loadedmetadata'));
      subscriptions.push(reCreateEvent(mediaElement, 'loadstart'));
      subscriptions.push(reCreateEvent(mediaElement, 'pause'));
      subscriptions.push(reCreateEvent(mediaElement, 'play'));
      subscriptions.push(reCreateEvent(mediaElement, 'playing'));
      subscriptions.push(reCreateEvent(mediaElement, 'seeked'));
      subscriptions.push(reCreateEvent(mediaElement, 'seeking'));
      subscriptions.push(reCreateEvent(mediaElement, 'fullscreenchange'));
      subscriptions.push(reCreateEvent(mediaElement, 'ended'));

      // time update event as a custom event on an interval
      let previousTime: number | null = null;
      interval = window.setInterval(() => {
        const currentTime = eventTarget?.getCurrentTime() ?? 0;
        const loadMode = player.getLoadMode();
        const { playTime = 0 } = player.getStats();
        if (previousTime === currentTime || loadMode < 2 || playTime === 0) return;
        previousTime = currentTime;
        const { start, end } = eventTarget?.getSeekRange() ?? {};
        const event = new ZnipeEvent('timeupdate', {
          currentTime,
          seekRange: { start, end },
        });
        eventTarget.dispatchEvent(event);

        // Fake ended event as shaka does not handle ended events correctly when setting a seek range
        if (currentTime >= end && mediaElement.paused) {
          eventTarget.dispatchEvent(new ZnipeEvent('ended'));
        }
      }, UPDATE_INTERVAL);
      registeredIds.current.push(PLAYER_EVENTS_ID);
    };

    waitForPlayerInterval = window.setInterval(setupEvents, 5);
    if (masterPlayer?.ref?.current?.getMediaElement) setupEvents();

    return () => {
      if (interval) clearInterval(interval);
      if (waitForPlayerInterval) clearInterval(waitForPlayerInterval);
      subscriptions.forEach(unsubscribe => unsubscribe());
      registeredIds.current = [];
    };
  }, [eventTarget, reCreateEvent, masterPlayer?.ref, videoIds]);
};

export default usePlayerEvents;
