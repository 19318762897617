import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import { animated, config, useSpring } from '@react-spring/web';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Header, LeftContent } from '../Accordion.styles';
import { SMALL, LARGE } from '../Accordion.constants';

const AccordionHeader = ({ onClick, size = SMALL, isActive = true, title, children }) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const sizes = useMemo(() => {
    if (size === LARGE) {
      return {
        headerSize: 'heading-m',
        iconSize: 32,
      };
    }
    return {
      headerSize: 'heading-s',
      iconSize: 24,
    };
  }, [size]);

  const iconAnimation = useSpring({
    transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
    from: { transform: 'rotate(0deg)', display: 'flex' },
    config: config.stiff,
    immediate: prefersReducedMotion,
  });

  return (
    <Header onClick={onClick} aria-expanded={isActive} data-testid="accordion-header" $size={size}>
      <LeftContent>
        <animated.div style={iconAnimation} data-testid="animation-wrapper">
          <Icon type="chevronRight" size={sizes.iconSize} inline />
        </animated.div>
        <Typography type={sizes.headerSize}>{title}</Typography>
      </LeftContent>
      {children && children}
    </Header>
  );
};

AccordionHeader.propTypes = {
  size: PropTypes.oneOf([SMALL, LARGE]),
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default memo(AccordionHeader);
