import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTournaments from './makeGetTournaments';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const generateDateString = dateString => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
};

const getTournamentIdFromProps = (_, props) => props.tournamentId;

const makeGetTournamentDates = () => {
  const getTournaments = makeGetTournaments();
  return createSelector([getTournaments, getTournamentIdFromProps], (tournaments, tournamentId) => {
    if (!Object.keys(tournaments)?.length || !tournaments[tournamentId]) {
      return '';
    }

    const start = tournaments[tournamentId]?.startTime ?? '';
    const end = tournaments[tournamentId]?.stopTime ?? '';

    const startString = generateDateString(start);
    const endString = generateDateString(end);

    if (!startString || !endString) return '';

    return `${startString} - ${endString}`;
  });
};

export const useGetTournamentDates = props => useMemoizedGqlSelector(makeGetTournamentDates, props);

export default makeGetTournamentDates;
