const createItem = item => ({
  id: item.id,
  name: item.name,
  htmlDescription: item.description,
  description: item.plaintext,
  image: item.imageSrc,
  stats: item.stats,
  cost: item.cost ?? item.gold?.total,
});

export default createItem;
