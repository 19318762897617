import { useCallback } from 'react';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import { EMOJI_TAG_REGEX } from '../ChatLockup.constants';

const useCreateContent = () => {
  const { allEmojis, replyData } = useChatContext() ?? {};

  const validateEmoji = useCallback(
    (emoji: string) =>
      allEmojis?.find(({ shortcode }) => shortcode.toLowerCase() === emoji.toLowerCase()),
    [allEmojis],
  );

  return useCallback(
    (raw: string[]) => {
      const normalizedRaw = raw
        .map(value => value.split(/(\s+)/))
        .flat()
        .filter(Boolean);
      return normalizedRaw
        .map((value, index) => {
          const insertValue = () => {
            if (value.match(/\s/)) return '<span>&nbsp;</span>';
            return `<span>${value}</span>`;
          };
          const matches = value.match(EMOJI_TAG_REGEX);
          if (!matches) {
            if (replyData?.username && value === `@${replyData?.username}&nbsp;`) {
              return `<div data-reply contenteditable="false">${value}</div>`;
            }

            if (!value || value === '<br>') return '<span><br></span>';
            return insertValue();
          }

          const key: string = value.split(':').join('');
          const previousValue = normalizedRaw[index - 1 > 0 ? index - 1 : 0];
          const previousValueMightBeEmoji = previousValue.indexOf(':') > -1;

          const emoji = validateEmoji(key);
          const previousIsEmoji =
            previousValueMightBeEmoji && !!validateEmoji(previousValue.split(':').join(''));

          if (emoji?.shortcode) {
            return `${
              previousIsEmoji ? '<span><br></span>' : ''
            }<div data-image contenteditable="false"><img tabIndex="-1" className="emoji" src="${
              emoji.url
            }" alt="${value}"/></div>`;
          }

          return insertValue();
        }, [] as string[])
        .join('');
    },
    [replyData, validateEmoji],
  );
};

export default useCreateContent;
