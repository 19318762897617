import styled from 'styled-components';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import Shimmer from 'znipe-elements/feedback/Shimmer/Shimmer';

export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';
export const SIZES = [XSMALL, SMALL, MEDIUM, LARGE, XLARGE];

export const heightOptions = {
  [XSMALL]: 10,
  [SMALL]: 14,
  [MEDIUM]: 16,
  [LARGE]: 20,
  [XLARGE]: 24,
};

const Skeleton = styled.div<{
  $width: number;
  $height: number;
  $size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
}>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  height: ${({ $size, $height }) => ($height ? `${$height}px` : `${heightOptions[$size]}px`)};
  background: ${({ theme }) => convertHexToRGB(theme.containerStroke, 0.7)};
  border-radius: 3px;
  display: inline-block;
  position: relative;
  overflow: hidden;
`;

const TextSkeleton = ({
  width = 0,
  height = 0,
  size = SMALL,
}: {
  width?: number;
  height?: number;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
}) => (
  <Skeleton data-testid="text-skeleton" $height={height} $size={size} $width={width}>
    <Shimmer type="text" />
  </Skeleton>
);

export default TextSkeleton;
