import styled from 'styled-components';
import PropTypes from 'prop-types';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { device } from 'znipe-styles/breakpoints';

const Container = styled.button`
  cursor: pointer;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border: none;
  background: none;
  height: ${({ $size }) => $size}px;

  @media ${device.desktop} {
    position: relative;
    right: initial;
    bottom: initial;
    top: initial;
    margin: initial;
  }
`;

const getTheme = themeName => {
  if (themeName === 'proview')
    return {
      size: { medium: 24, large: 40 },
      type: { medium: 'proViewLogo', large: 'proViewLogo' },
    };
  if (themeName === 'svenska-cupen')
    return {
      size: { medium: 24, large: 44 },
      type: { medium: 'svenskaCupenLogo', large: 'svenskaCupenLogo' },
    };
  return {
    size: { medium: 16, large: 16 },
    type: { medium: 'znipeLogoLight', large: 'znipeLogoLight' },
  };
};

const Logo = ({ title = '', onClick = () => {} }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const { themeName } = useThemeContext();
  const theme = getTheme(themeName);
  const type = isTabletOrGreater || !title ? theme.type.large : theme.type.medium;
  const size = isTabletOrGreater ? theme.size.large : theme.size.medium;
  return (
    <Container data-testid="logo-wrapper" onClick={onClick} $size={size}>
      <Icon type={type} size={size > -1 ? size : undefined} inline />
    </Container>
  );
};

Logo.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default Logo;
