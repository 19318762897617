import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring } from '@react-spring/web';
import { weaponIdentifiers } from 'znipe-elements/data-display/WeaponIcon/CSGOWeaponIcon';
import { Container } from './EliminatedInfo.styles';
import PlayerSection from './components/PlayerSection';
import Center from './components/Center';
import { sizesShape } from '../../constants';

const EliminatedInfo = ({
  show = false,
  actorId,
  victimId,
  size = 'xsmall',
  isHomeTeam = false,
  weapon,
  onAnimationEnd = () => {},
}) => {
  const [rests, setRests] = useState(0);

  useEffect(() => {
    if (!show) setRests(0);
  }, [show]);

  useEffect(() => {
    if (rests === 4) {
      onAnimationEnd();
      setRests(0);
    }
  }, [rests, onAnimationEnd]);

  const onRest = useCallback(() => {
    if (show) setRests(prev => prev + 1);
  }, [show]);

  const spring = useSpring({
    opacity: show ? 1 : 0,
    immediate: !show,
    onRest,
  });

  return (
    <Container style={spring} $size={size} data-testid="eliminated-info">
      <PlayerSection
        playerId={actorId}
        size={size}
        type="left"
        show={show}
        onAnimationEnd={onRest}
      />
      <Center
        size={size}
        isHomeTeam={isHomeTeam}
        show={show}
        weapon={weapon}
        onAnimationEnd={onRest}
      />
      <PlayerSection
        playerId={victimId}
        size={size}
        type="right"
        show={show}
        onAnimationEnd={onRest}
      />
    </Container>
  );
};

EliminatedInfo.propTypes = {
  show: PropTypes.bool,
  isHomeTeam: PropTypes.bool,
  actorId: PropTypes.string,
  victimId: PropTypes.string,
  weapon: PropTypes.oneOf(weaponIdentifiers),
  size: sizesShape,
  onAnimationEnd: PropTypes.func,
};

export default EliminatedInfo;
