import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import TopicLink from 'tv-elements/data-display/TopicLink/TopicLink';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import { useGetPlayerTeamId } from 'tv-selectors/players/makeGetPlayerTeamId';
import { useGetTeamName } from 'tv-selectors/team/makeGetTeamName';
import { useGetSelectedTeamLogo } from 'tv-selectors/team/makeGetSelectedTeamLogo';
import { useGetTeamCountry } from 'tv-selectors/team/makeGetTeamCountry';
import useTopicLink from 'tv-hooks/useTopicLink';
import ScrollContainer from 'react-indiana-drag-scroll';
import LogoCard from 'tv-elements/data-display/LogoCard/LogoCard';
import { SMALL, MEDIUM, LARGE } from 'tv-modules/TopicHeader/TopicHeader.constants';
import Link from 'znipe-elements/general/Link/Link';
import colors from 'znipe-styles/colors';
import { smallSizes, mediumSizes, largeSizes } from '../TopicAbout.constants';
import {
  SectionTitle,
  CardScroller,
  CardsContainer,
  TeamWrapper,
  TeamLinkWrapper,
} from '../TopicAbout.styles';

const PlayerInfo = ({ size = LARGE, topicId, teamMateCards }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();
  const teamId = useGetPlayerTeamId({ playerId: topicId }) || topicId;
  const teamName = useGetTeamName({ teamId });
  const teamLogo = useGetSelectedTeamLogo({ teamId });
  const teamCountry = useGetTeamCountry({ teamId });
  const teamLink = useTopicLink(teamName, 'team');

  const elementSizes = useMemo(() => {
    if (size === LARGE) {
      return largeSizes;
    }
    if (size === MEDIUM) {
      return mediumSizes;
    }
    return smallSizes;
  }, [size]);

  return (
    <TeamWrapper>
      <TeamLinkWrapper>
        <SectionTitle dataTestId="team-title" type={elementSizes.title}>
          team
        </SectionTitle>
        {!isDesktopExtraLargeOrGreater && (
          <Link to={teamLink} hideUnderline>
            <TopicLink
              size={elementSizes.component}
              title={teamName}
              subtitle={teamCountry}
              fullWidth
              type="team"
              image={teamLogo}
            />
          </Link>
        )}
      </TeamLinkWrapper>
      {isTabletOrGreater && (
        <CardsContainer data-testid="team-card">
          {isDesktopExtraLargeOrGreater && (
            <Link to={teamLink} hideUnderline textColor={colors.white}>
              <LogoCard text={teamName} imageSrc={teamLogo} />
            </Link>
          )}
          {teamMateCards}
        </CardsContainer>
      )}
      {!isTabletOrGreater && (
        <ScrollContainer vertical={false}>
          <CardScroller data-testid="teammate-cards">{teamMateCards}</CardScroller>
        </ScrollContainer>
      )}
    </TeamWrapper>
  );
};

PlayerInfo.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  topicId: PropTypes.string.isRequired,
  teamMateCards: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default memo(PlayerInfo);
