import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BaseModalBackground } from './StyledModal';
import { Provider } from './context';

const Derp = styled.div`
  position: 'absolute';
`;

const ModalProvider = ({ backgroundComponent: propsBackgroundComponent, children }) => {
  const modalNode = useRef(null);
  const [stateModalNode, setStateModalNode] = useState(null);
  const [BackgroundComponent, setBackgroundComponent] = useState(BaseModalBackground);

  useEffect(() => {
    if (propsBackgroundComponent) {
      setBackgroundComponent(propsBackgroundComponent);
    }
  }, [propsBackgroundComponent]);

  useEffect(() => {
    // This is to force a rerender
    setStateModalNode(modalNode.current);
  }, []);

  return (
    <Provider
      value={{
        modalNode: stateModalNode,
        BackgroundComponent,
      }}
    >
      {children}
      <Derp id="derp" ref={modalNode} />
    </Provider>
  );
};

ModalProvider.propTypes = {
  backgroundComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  children: PropTypes.node,
};

export default ModalProvider;
