import { useEffect, useCallback, useMemo } from 'react';
import cj from 'cj';

const useForceRefresh = (matchId, refreshCallback) => {
  const broadcastApp = useMemo(() => cj('broadcast'), []);
  const handleRefresh = useCallback(
    message => {
      if (message.matchId !== matchId) return;
      refreshCallback();
    },
    [matchId, refreshCallback],
  );

  useEffect(() => {
    if (!matchId || !refreshCallback) return () => null;

    const broadcastRef = broadcastApp.ref('force-refresh');

    broadcastRef.on('value', handleRefresh);

    return () => {
      broadcastRef.off('value', handleRefresh);
    };
  }, [matchId, refreshCallback, handleRefresh, broadcastApp]);
};

export default useForceRefresh;
