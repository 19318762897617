import { memo, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { CardNumberElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import usePaymentRequest from 'tv-modules/Payment/hooks/usePaymentRequest';
import config from 'tv-config/config';
import fonts from './fonts';

const loadSingletonStripe = (() => {
  let promise;
  const { STRIPE_PUBLIC_KEY } = config;
  return () => {
    promise = loadStripe(STRIPE_PUBLIC_KEY);
    return promise;
  };
})();

const withStripeElements = ComposedComponent => {
  // Would be nice to read this from themes or the html somehow
  const elementsOptions = {
    fonts,
  };

  const StripeFormWrapper = ({
    productId,
    promoCode,
    onSubmit = () => {},
    onSuccess = () => {},
    onFailure = () => {},
    currency,
    period,
    edit = false,
    ...props
  }) => {
    const [paymentError, setPaymentError] = useState(false);
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const sendPaymentRequest = usePaymentRequest(
      productId,
      promoCode,
      period,
      currency,
      'stripe',
      edit,
    );

    const handleSubmit = useCallback(
      async e => {
        if (loading) return;
        setLoading(true);
        setPaymentError(false);
        if (onSubmit) onSubmit(e);
        const cardNumberElement = elements.getElement(CardNumberElement);
        const { name } = e;
        const cardInformation = {
          card: cardNumberElement,
          billing_details: {
            name,
          },
        };
        try {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            ...cardInformation,
          });
          if (error) throw Error(error);
          const { id: paymentMethodId } = paymentMethod;
          const data = { paymentMethodId };
          const response = await sendPaymentRequest(data);

          const { results = {}, status } = response;
          if (status !== 'ok') throw Error('Failed to create payment');

          const { requiresAction, intentSecret } = results;
          if (!requiresAction) {
            setLoading(false);
            if (onSuccess) onSuccess(paymentMethodId, results);
            return;
          }

          const PAYMENT_INTENT = 'pi';
          const SET_UP_INTENT = 'seti';

          if (intentSecret.startsWith(PAYMENT_INTENT)) {
            const { error: err } = await stripe.confirmCardPayment(intentSecret, {
              payment_method: cardInformation,
            });
            if (err) throw Error(err);
          } else if (intentSecret.startsWith(SET_UP_INTENT)) {
            const { error: err } = await stripe.confirmCardSetup(intentSecret, {
              payment_method: cardInformation,
            });
            // @TODO This needs a special case. You have a free trial period but a card failure
            if (err) throw Error(err);
          }
          setLoading(false);
          if (onSuccess) onSuccess('success');
        } catch (error) {
          setPaymentError(true);
          setLoading(false);
          if (onFailure) onFailure(error);
        }
      },
      [sendPaymentRequest, onSubmit, onSuccess, onFailure, stripe, elements, loading],
    );

    return (
      <ComposedComponent
        {...props}
        paymentError={paymentError}
        onSubmit={handleSubmit}
        loading={loading}
      />
    );
  };

  StripeFormWrapper.propTypes = {
    productId: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    promoCode: PropTypes.string,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    onSubmit: PropTypes.func,
    edit: PropTypes.bool,
  };

  return memo(props => {
    const stripePromise = useMemo(() => loadSingletonStripe(), []);
    return (
      <Elements stripe={stripePromise} options={elementsOptions}>
        <StripeFormWrapper {...props} />
      </Elements>
    );
  });
};

export default withStripeElements;
