import produce from 'immer';
import { SET_ROSTER } from 'tv-constants/redux';

export const initialState = {};

const rosterReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_ROSTER: {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    }
    default:
      return state;
  }
}, initialState);

export default rosterReducer;
