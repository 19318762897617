import { memo, forwardRef, useState, useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Container,
  Wrapper,
  InlineLabel,
  InlineLabelContent,
  CleaveInput,
  NormalInput,
  Required,
  Label,
  MaxLength,
  Suffix,
  Comment,
  ErrorMessage,
  Prefix,
} from './Input.styles';
import themes from './Input.themes';
import PostTab from './PostTab';

const TextInput = forwardRef(
  (
    {
      customInput,
      maxLength,
      name,
      options,
      error = false,
      focus = false,
      active = false,
      disabled = false,
      useCleave = false,
      isRequired = false,
      showLengthCount = false,
      lightLabel = false,
      hideOverflow = true,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onKeyUp,
      onBeforeInput,
      label = '',
      centerAlign = '',
      placeholder = '',
      customWidth = '',
      defaultValue = '',
      prefix = '',
      suffix = '',
      postTab,
      defaultPostTab = '',
      errorMessage = '',
      comment = '',
      inputType = 'text',
      labelPosition = 'left',
      labelAlign = 'flex-start',
      'data-testid': testId = 'input',
    },
    ref,
  ) => {
    const [data, setData] = useState(defaultValue);
    const [currentLength, setCurrentLength] = useState(0);
    const [postTabValue, setPostTabValue] = useState(null);

    const Input = customInput || (useCleave ? CleaveInput : NormalInput);
    const handleChange = useCallback(
      e => {
        if (onChange) {
          if (postTab?.length) {
            onChange(e, postTabValue);
          }
          onChange(e);
        }
        const { target } = e;
        if (target) {
          const { value } = target;
          setData(value);
          setCurrentLength(value.length);
        }
      },
      [onChange, postTabValue, postTab?.length],
    );

    useEffect(() => {
      setCurrentLength(defaultValue.length);
    }, [defaultValue]);

    useEffect(() => {
      if (postTab && postTab.length > 0) {
        setPostTabValue(defaultPostTab !== '' ? defaultPostTab : postTab[0]);
      }
    }, [postTab, defaultPostTab]);

    useEffect(
      e => {
        if (postTabValue && onChange) onChange(e, postTabValue);
      },
      [onChange, postTabValue],
    );

    const getMaxLengthCounter = () => {
      if (showLengthCount && maxLength) {
        const remainingLength = maxLength - currentLength;
        return (
          <MaxLength
            isCloseToMax={remainingLength / maxLength < 0.35}
            isMax={remainingLength <= 0}
          >{`${remainingLength}/${maxLength}`}</MaxLength>
        );
      }
      return null;
    };

    const handleClickPostTab = useCallback(e => {
      setPostTabValue(e.target.id);
    }, []);

    const isInline = labelPosition === 'inline';
    const isHorizontal = labelPosition === 'top';
    const showErrorMessage = error && errorMessage !== '';

    const commentElement = useMemo(
      () =>
        comment && (
          <Comment centerAlign={centerAlign} customWidth={customWidth}>
            <Typography type="paragraph-s">{comment}</Typography>
          </Comment>
        ),
      [comment, centerAlign, customWidth],
    );

    return (
      <Container $hideOverflow={hideOverflow}>
        <Wrapper data-testid="text-input" isHorizontal={isHorizontal} disabled={disabled}>
          {!isInline && label && (
            <Label
              data-testid={`label-${labelPosition}`}
              error={error}
              position={labelPosition}
              centerAlign={centerAlign}
              labelAlign={labelAlign}
              lightLabel={lightLabel}
            >
              <Typography type={lightLabel ? 'paragraph-m' : 'heading-xs'}>{label}</Typography>
              {isRequired && <Required />}
            </Label>
          )}
          {isHorizontal && commentElement}
          {prefix !== '' && (
            <Prefix
              width={customWidth || '100%'}
              centerAlign={centerAlign}
              labelPosition={labelPosition}
              disabled={disabled}
            >
              <Typography type="title-xs">{prefix}</Typography>
            </Prefix>
          )}
          <Input
            name={name}
            data-testid={testId}
            error={error}
            focus={focus}
            autoComplete="autocomplete-off"
            disabled={disabled}
            isInline={isInline}
            centerAlign={centerAlign}
            placeholder={placeholder}
            required={inputType === 'time'}
            step={inputType === 'time' ? 2 : undefined}
            ref={ref}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            onBeforeInput={onBeforeInput}
            maxLength={maxLength}
            options={options}
            customWidth={customWidth}
            hasTopMargin={label && labelPosition === 'top'}
            type={inputType}
            defaultValue={data}
          />
          {suffix !== '' && (
            <Suffix
              width={customWidth || '100%'}
              centerAlign={centerAlign}
              labelPosition={labelPosition}
              disabled={disabled}
            >
              <Typography type="title-xs">{suffix}</Typography>
            </Suffix>
          )}
          {postTab?.length && (
            <PostTab
              tabs={postTab}
              centerAlign={centerAlign}
              customWidth={customWidth}
              selectedOption={postTabValue}
              isInline={isInline}
              onClickOption={handleClickPostTab}
            />
          )}
          {isInline && (
            <InlineLabel data-testid="label-inline" error={error} focus={focus}>
              <InlineLabelContent hasData={active || data.length > 0}>
                <Typography type="heading-xs">{label}</Typography>
                {isRequired && <Required />}
              </InlineLabelContent>
            </InlineLabel>
          )}
          {getMaxLengthCounter()}
        </Wrapper>
        {!isHorizontal && commentElement}
        {showErrorMessage && (
          <ErrorMessage centerAlign={centerAlign} customWidth={customWidth}>
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    );
  },
);

TextInput.propTypes = {
  customInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func.isRequired }),
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  focus: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  showLengthCount: PropTypes.bool,
  useCleave: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBeforeInput: PropTypes.func,
  maxLength: PropTypes.number,
  labelPosition: PropTypes.oneOf(['inline', 'left', 'top']),
  labelAlign: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  // Added the relevant ones. More info here https://nosir.github.io/cleave.js/
  options: PropTypes.shape({
    creditCard: PropTypes.bool,
    onCreditCardTypeChanged: PropTypes.func,
    date: PropTypes.bool,
    datePattern: PropTypes.arrayOf(PropTypes.oneOf(['d', 'm', 'y', 'Y'])),
    dateMin: PropTypes.string,
    dateMax: PropTypes.string,
    time: PropTypes.bool,
    timePattern: PropTypes.arrayOf(PropTypes.oneOf(['h', 'm', 's'])),
    timeFormat: PropTypes.oneOf(['12', '24']),
    numericOnly: PropTypes.bool,
  }),
  centerAlign: PropTypes.string,
  placeholder: PropTypes.string,
  customWidth: PropTypes.string,
  defaultValue: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  postTab: PropTypes.arrayOf(PropTypes.string),
  defaultPostTab: PropTypes.string,
  errorMessage: PropTypes.string,
  inputType: PropTypes.string,
  comment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lightLabel: PropTypes.bool,
  hideOverflow: PropTypes.bool,
  'data-testid': PropTypes.string,
};

const DefaultInput = withTheme(memo(TextInput), themes, 'input');

const withCleaveJs = ComposedComponent =>
  React.forwardRef((props, ref) => <ComposedComponent useCleave {...props} ref={ref} />);

export const CleaveJsInput = withCleaveJs(DefaultInput);

export default DefaultInput;
