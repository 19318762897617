import { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import RolesFilter from 'tv-modules/Filters/RolesFilter/RolesFilter';
import MenuSelector from 'tv-modules/Filters/MenuSelector/MenuSelector';
import SubMenuContent from './components/SubMenuContent';
import ActiveFilters from './components/ActiveFilters';
import { CLASSES, ROLES, YEARS, selectionShape } from '../Filters.constants';
import { Container, Slide, SlideContainer, MenuSelectorWrapper } from './FilterDetails.styles';
import { getMenuMarkerNumber } from '../utils/getMarkerNumber';

const FilterDetails = ({
  selection,
  isActive = false,
  openSubMenu,
  onClickSubMenu = () => {},
  menuData = {},
  onChange = () => {},
  onClearAll = () => {},
  onClear = () => {},
  menuOptions,
  useRolesFilter = false,
}) => {
  const openSubMenuMarkerNumber = useMemo(
    () => getMenuMarkerNumber(selection, openSubMenu),
    [selection, openSubMenu],
  );

  const menuSelectorList = useMemo(
    () =>
      menuOptions
        ?.filter(filterKey => filterKey !== ROLES)
        .map(filterKey => {
          const markerNumber = getMenuMarkerNumber(selection, filterKey);
          return (
            <MenuSelectorWrapper key={filterKey} data-testid={`menu-wrapper-${filterKey}`}>
              <MenuSelector
                label={filterKey}
                markerNumber={markerNumber}
                onClickMenu={onClickSubMenu}
              />
            </MenuSelectorWrapper>
          );
        }),
    [selection, menuOptions, onClickSubMenu],
  );

  const subMenuSearchResults = useMemo(() => {
    if (openSubMenu === CLASSES || openSubMenu === YEARS) {
      return null;
    }
    return menuData[openSubMenu];
  }, [menuData, openSubMenu]);

  const handleChangeSelection = useCallback(
    newSelection => onChange({ ...selection, ...newSelection }),
    [selection, onChange],
  );

  const handleChangeSelectionRoles = useCallback(
    newSelection => handleChangeSelection({ [ROLES]: newSelection }),
    [handleChangeSelection],
  );

  return (
    <Container>
      <SlideContainer currentSlide={openSubMenu}>
        <Slide>
          {useRolesFilter && (
            <RolesFilter selection={selection[ROLES]} onChange={handleChangeSelectionRoles} />
          )}
          {menuSelectorList}
          {isActive && (
            <ActiveFilters
              selection={selection}
              menuOptions={menuOptions}
              onClickClearAll={onClearAll}
              onClickClear={onClear}
            />
          )}
        </Slide>
        <Slide>
          {openSubMenu && (
            <>
              <MenuSelectorWrapper data-testid={`submenu-wrapper-${openSubMenu}`}>
                <MenuSelector
                  label={openSubMenu}
                  arrowType="left"
                  onClickMenu={onClickSubMenu}
                  markerNumber={openSubMenuMarkerNumber}
                />
              </MenuSelectorWrapper>
              <SubMenuContent
                openSubMenu={openSubMenu}
                searchResults={subMenuSearchResults}
                selection={selection[openSubMenu]}
                onChangeSelection={handleChangeSelection}
              />
            </>
          )}
        </Slide>
      </SlideContainer>
    </Container>
  );
};

FilterDetails.propTypes = {
  selection: PropTypes.shape(selectionShape).isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  menuData: PropTypes.shape({}),
  isActive: PropTypes.bool,
  useRolesFilter: PropTypes.bool,
  openSubMenu: PropTypes.string,
  onClickSubMenu: PropTypes.func,
  onChange: PropTypes.func,
  onClearAll: PropTypes.func,
  onClear: PropTypes.func,
};

export default memo(FilterDetails);
