export const smallSkeletonSizes = {
  primary: {
    height: 20,
    width: 160,
  },
  secondary: {
    height: 17,
    width: 130,
  },
  button: {
    height: 48,
    width: 177,
  },
};

export const largeSkeletonSizes = {
  primary: {
    height: 34,
    width: 210,
  },
  secondary: {
    height: 18,
    width: 160,
  },
  button: {
    height: 56,
    width: 226,
  },
};
