import styled from 'styled-components';

export const MarkerDot = styled.div`
  position: absolute;
  left: ${({ $pos }) => `${$pos}%`};
  width: 4px;
  height: 100%;
  background: ${({ $markerColor, $pos }) => ($pos === 0 ? 'transparent' : `${$markerColor}`)};
`;

export const TextContainer = styled.div`
  position: absolute;
  color: #fff;
  transform: translateY(-24px) translateX(4px);
  left: ${({ pos }) => `${pos}%`};
`;

export default MarkerDot;
