import { schema } from 'normalizr';

// Don't want this to get messy in the future so I'm doing hard filtering
const processStrategy = ({ id, type, startTime, stopTime }) => ({
  id,
  type,
  startTime,
  stopTime,
});

const sequential = new schema.Entity('sequentials', {}, { processStrategy });

export default sequential;
