import { memo } from 'react';
import PropTypes from 'prop-types';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import FlourishContainer from 'znipe-elements/data-display/FlourishContainer/FlourishContainer';
import UserAvatar from 'znipe-elements/data-display/Avatar/v2/UserAvatar/UserAvatar';
import {
  Header,
  HeaderContent,
  IconWrapper,
  TitleContent,
  AvatarWrapper,
} from './PanelHeader.styles';

const PanelHeader = ({ isActive = false, onClickClose, label, avatar = '' }) => {
  const theme = useThemeContext();
  return (
    <Header>
      <FlourishContainer
        flourish="singleWave"
        activeBackground={theme.deepForestGreen}
        animationDuration={0.3}
        active={isActive}
      >
        <HeaderContent>
          <TitleContent>
            {avatar && (
              <AvatarWrapper>
                <UserAvatar />
              </AvatarWrapper>
            )}
            <Typography type="title-m">{label}</Typography>
          </TitleContent>
          <IconWrapper onClick={onClickClose}>
            <Icon type="close" size={24} />
          </IconWrapper>
        </HeaderContent>
      </FlourishContainer>
    </Header>
  );
};

PanelHeader.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  onClickClose: PropTypes.func,
};

export default memo(PanelHeader);
