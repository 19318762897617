import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Icon from 'znipe-elements/general/Icon/Icon';
import { useGetIsMobilePortraitOrGreater } from 'tv-selectors/browser/makeGetIsMobilePortraitOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import getColorsFromGradientString from 'znipe-utils/misc/getColorsFromGradientString';
import gradients from 'znipe-styles/gradients';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import { theme, uiSizes } from './MyFeed.constants';
import {
  Container,
  ContentWrapper,
  ButtonWrapper,
  BackgroundCoverContainer,
  BackgroundContainer,
  BackgroundContent,
  BackgroundFade,
  CoverImage,
  ArrowWrapper,
} from './MyFeed.styles';

const BackgroundCover = ({ position }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const { myFeedOnboarding } = useThemeContext();
  const { main, large } = myFeedOnboarding[position] || {};

  return (
    <BackgroundCoverContainer $position={position}>
      <CoverImage src={isDesktopOrGreater ? large : main} alt="player" />
    </BackgroundCoverContainer>
  );
};

BackgroundCover.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
};

const MyFeed = ({ headline = '', text = '', onClick }) => {
  const { primaryTextColor, secondaryTextColor } = useThemeContext();
  const isMobilePortraitOrGreater = useGetIsMobilePortraitOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const sizes = useMemo(() => {
    if (isDesktopLargeOrGreater) return uiSizes.desktopLarge;
    if (isDesktopOrGreater) return uiSizes.desktop;
    if (isTabletOrGreater) return uiSizes.tablet;
    if (isMobilePortraitOrGreater) return uiSizes.mobilePortrait;
    return uiSizes.mobile;
  }, [isDesktopLargeOrGreater, isDesktopOrGreater, isTabletOrGreater, isMobilePortraitOrGreater]);
  const iconColors = getColorsFromGradientString(gradients.orange);

  return (
    <Container>
      <ArrowWrapper>
        <Icon
          inline
          size={sizes.arrowIconSize}
          type={sizes.arrowIcon}
          fillColor={iconColors[1]}
          activeColor={iconColors[0]}
          gradient
        />
      </ArrowWrapper>
      <ContentWrapper>
        <Typography type={sizes.headline} align="center" color={primaryTextColor}>
          {headline}
        </Typography>
        <Typography type={sizes.text} align="center" color={secondaryTextColor}>
          {text}
        </Typography>
      </ContentWrapper>
      {onClick && (
        <ButtonWrapper>
          <Button variant="secondary" icon="layers" size={sizes.button} onClick={onClick}>
            Browse
          </Button>
        </ButtonWrapper>
      )}
      <BackgroundContainer>
        <BackgroundContent>
          <BackgroundCover position="left" />
          <BackgroundCover position="right" />
          {isDesktopOrGreater && <BackgroundFade />}
        </BackgroundContent>
      </BackgroundContainer>
    </Container>
  );
};

MyFeed.propTypes = {
  headline: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default withTheme(MyFeed, theme, 'myFeedOnboarding');
