import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';
import withTheme from 'znipe-themes/hocs/withTheme';
import { Container, InnerContainer, Image } from '../Avatar.styles';
import {
  ChampionImageWrapper,
  BadgeWrapper,
  LevelContainer,
  LevelTextWrapper,
  IconContainer,
  DefaultChampionWrapper,
  themes,
} from './ChampionAvatar.styles';

const ChampionAvatar = ({
  imageUrl = '',
  shape = 'square',
  hasBorder = false,
  level = null,
  levelPosition = 'left',
  icon = null,
  iconPosition = 'left',
  imageAlt = 'champion avatar image',
}) => {
  const isCircle = shape === 'circle';
  const hasSelectedChampion = imageUrl;
  const hasLevel = typeof level === 'number';
  return (
    <Container>
      <InnerContainer $isCircle={isCircle} $hasBorder={!hasSelectedChampion || hasBorder}>
        {!hasSelectedChampion ? (
          <DefaultChampionWrapper data-testid="default-champion-wrapper">
            <Icon type="champion" />
          </DefaultChampionWrapper>
        ) : (
          <ChampionImageWrapper $isCircle={isCircle}>
            <Image data-testid="champion-image" src={imageUrl} alt={imageAlt} />
          </ChampionImageWrapper>
        )}
      </InnerContainer>
      {hasSelectedChampion && hasLevel && (
        <BadgeWrapper
          data-testid="level-badge-wrapper"
          $isLevelItem
          $isCircle={isCircle}
          $alignment={levelPosition}
        >
          <LevelContainer>
            <LevelTextWrapper data-testid="avatar-champion-level">{level}</LevelTextWrapper>
          </LevelContainer>
        </BadgeWrapper>
      )}
      {hasSelectedChampion && icon && (
        <BadgeWrapper data-testid="icon-badge-wrapper" $alignment={iconPosition}>
          <IconContainer data-testid="icon-container" $alignment={iconPosition}>
            <Icon type={icon} />
          </IconContainer>
        </BadgeWrapper>
      )}
    </Container>
  );
};

ChampionAvatar.propTypes = {
  imageUrl: PropTypes.string,
  shape: PropTypes.oneOf(['', 'square', 'circle']),
  hasBorder: PropTypes.bool,
  levelPosition: PropTypes.oneOf(['', 'left', 'right']),
  iconPosition: PropTypes.oneOf(['', 'left', 'center', 'right']),
  level: PropTypes.number,
  icon: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default withTheme(memo(ChampionAvatar), themes, 'championAvatar');
