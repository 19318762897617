import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetSelectedFeaturedContent from 'tv-selectors/featured/makeGetSelectedFeaturedContent';
import makeGetIsDesktopOrGreater from 'tv-selectors/browser/makeGetIsDesktopOrGreater';

export const defaultThumbnail = 'https://assets.znipe.tv/video-player/znipe-single-view-poster.png';

const makeGetFeaturedThumbnail = () => {
  const getSelectedFeaturedContent = makeGetSelectedFeaturedContent();
  const getIsDesktopOrGreater = makeGetIsDesktopOrGreater();
  return createSelector(
    [getSelectedFeaturedContent, getIsDesktopOrGreater],
    (featuredContent, isDesktopOrLarge) => {
      if (isDesktopOrLarge) {
        return featuredContent.thumbnailLargeUrl;
      }
      return featuredContent.thumbnailUrl || defaultThumbnail;
    },
  );
};

export const useGetFeaturedThumbnail = props =>
  useMemoizedGqlSelector(makeGetFeaturedThumbnail, props);

export default makeGetFeaturedThumbnail;
