import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetEvents from './makeGetEvents';
import makeGetEventId from './makeGetEventId';

const emptyObject = {};

const makeGetSelectedEvent = () => {
  const getEventId = makeGetEventId();
  const getEvents = makeGetEvents();
  return createSelector(
    [getEvents, getEventId],
    (events, eventId) => events[eventId] || emptyObject,
  );
};

export const useGetSelectedEvent = props => useMemoizedGqlSelector(makeGetSelectedEvent, props);

export default makeGetSelectedEvent;
