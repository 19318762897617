import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetIsAdmin = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.isAdmin ?? false);
};

export const useIsAdmin = () => useMemoizedSelector(makeGetIsAdmin);

export default makeGetIsAdmin;
