import { useMemo } from 'react';
import PropTypes from 'prop-types';
import PremiumIcon from 'znipe-elements/data-display/PremiumIcon/PremiumIcon';
import {
  AreaDivider,
  Container,
  ContentWrapper,
  LeftArea,
  Marker,
  RightArea,
  CenterArea,
} from './SelectorHorizontal.styles';
import { PremiumIconWrapper } from '../Selector.styles';
import useKDAComponent from '../useKDAComponent';
import useLabelTypography from '../useLabelTypography';
import { SMALL, MEDIUM, propTypes, STREAM_TYPE_PLAYER } from '../constants';

export const leftImageSize = { [SMALL]: 40, [MEDIUM]: 48 };
const typographyTypeBySize = { [SMALL]: 'heading-s', [MEDIUM]: 'heading-m' };

const SelectorHorizontal = ({
  label,
  size = 'small',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
  streamType,
  leftArea = null,
  rightArea = null,
  teamSide = 'none',
  kda,
  game,
}) => {
  const kdaComponent = useKDAComponent(kda, game, size);

  const hasRightArea = Boolean(rightArea);

  const labelOptions = useMemo(() => {
    const baseOptions = {
      type: typographyTypeBySize[size] ?? typographyTypeBySize.small,
      color: 'secondaryTextColor',
    };
    if (size === SMALL) {
      return { ...baseOptions, maxWidth: hasRightArea ? '80px' : '140px' };
    }
    return { ...baseOptions, maxWidth: hasRightArea ? '120px' : '200px' };
  }, [size, hasRightArea]);

  const labelComponent = useLabelTypography(label, labelOptions);

  return (
    <Container
      $size={size}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      data-testid={testId}
      $isSelected={isSelected}
    >
      <ContentWrapper $size={size}>
        <LeftArea>{leftArea}</LeftArea>
        <CenterArea>
          {labelComponent}
          {kdaComponent}
        </CenterArea>
        {hasRightArea && <AreaDivider data-testid="divider" $size={size} />}
        {hasRightArea && <RightArea>{rightArea}</RightArea>}
        {streamType === STREAM_TYPE_PLAYER && teamSide !== 'none' && (
          <Marker $color={`${teamSide}Color`} data-testid="team-marker" />
        )}
      </ContentWrapper>
      {isLocked && !hasPremiumAccess && (
        <PremiumIconWrapper>
          <PremiumIcon size={MEDIUM} />
        </PremiumIconWrapper>
      )}
    </Container>
  );
};

SelectorHorizontal.propTypes = {
  ...propTypes,
  leftArea: PropTypes.node,
  rightArea: PropTypes.node,
  teamSide: PropTypes.oneOf(['none', 'teamOne', 'teamTwo']),
};

export default SelectorHorizontal;
