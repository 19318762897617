import PropTypes from 'prop-types';
import { LIST, GRID } from 'tv-elements/layout/Section/Section.constants';
import { TILE } from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip.constants';
import config from 'tv-config/config';
import { CLASSES } from 'tv-modules/Filters/Filters.constants';

const { GRAPHQL_API_URL } = config;

export const BROWSE_ENDPOINT = GRAPHQL_API_URL;

const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';

export const tabContentType = {
  vods: 'matches',
  highlights: 'videos',
};

export const PLAYERS = 'players';
export const TEAMS = 'teams';
export const VODS = 'vods';
export const CHAMPIONS = 'champions';
export const RIOTCHAMPIONS = 'riotChampions';
export const HIGHLIGHTS = 'highlights';
export const EVENTS = 'events';
export const SCHEDULE = 'schedule';
export const EDITORIALS = 'editorials';
export const ALL = 'all';

// TODO: schedule when available
export const tabs = [
  { label: ALL, type: ALL },
  { label: PLAYERS, type: ALL },
  { label: TEAMS },
  { label: EDITORIALS },
  { label: VODS },
  { label: CHAMPIONS },
  { label: HIGHLIGHTS },
  // { label: SCHEDULE },
  { label: EVENTS },
];

export const breakpointSizes = {
  desktopLarge: {
    tabsetSize: MEDIUM,
    playerCardSize: LARGE,
    topicCardSize: MEDIUM,
    championCardSize: SMALL,
    matchItemSize: LARGE,
    videoListingSize: MEDIUM,
    videoListingType: GRID,
    videoClipType: TILE,
  },
  desktop: {
    tabsetSize: MEDIUM,
    playerCardSize: LARGE,
    topicCardSize: SMALL,
    championCardSize: SMALL,
    matchItemSize: MEDIUM,
    videoListingSize: MEDIUM,
    videoListingType: GRID,
    videoClipType: TILE,
  },
  tablet: {
    tabsetSize: SMALL,
    playerCardSize: null,
    topicLinkSize: MEDIUM,
    championCardSize: MEDIUM,
    matchItemSize: MEDIUM,
    videoListingSize: MEDIUM,
    videoListingType: LIST,
    videoClipType: LIST,
  },
  default: {
    tabsetSize: SMALL,
    playerCardSize: MEDIUM,
    topicLinkSize: SMALL,
    championCardSize: MEDIUM,
    matchItemSize: SMALL,
    videoListingSize: SMALL,
    videoListingType: LIST,
    videoClipType: TILE,
  },
};

export const searchResultDefaultProps = {
  topResult: { hits: [], count: 0 },
  [PLAYERS]: {
    hits: [],
    count: 0,
  },
  [TEAMS]: {
    hits: [],
    count: 0,
  },
  [EDITORIALS]: {
    hits: [],
    count: 0,
  },
  [VODS]: {
    hits: [],
    count: 0,
  },
  [CHAMPIONS]: {
    hits: [],
    count: 0,
  },
  [HIGHLIGHTS]: {
    hits: [],
    count: 0,
  },
  [SCHEDULE]: {
    hits: [],
    count: 0,
  },
  [EVENTS]: {
    hits: [],
    count: 0,
  },
};

export const searchResultPropTypes = PropTypes.shape({
  [PLAYERS]: PropTypes.arrayOf(PropTypes.string),
  [TEAMS]: PropTypes.arrayOf(PropTypes.string),
  [EDITORIALS]: PropTypes.arrayOf(PropTypes.string),
  [VODS]: PropTypes.arrayOf(PropTypes.string),
  [CHAMPIONS]: PropTypes.arrayOf(PropTypes.string),
  [HIGHLIGHTS]: PropTypes.arrayOf(PropTypes.string),
  [SCHEDULE]: PropTypes.arrayOf(PropTypes.string),
  [EVENTS]: PropTypes.arrayOf(PropTypes.string),
});

export const filterOptionsDesktop = {
  [PLAYERS]: {
    sort: false,
    top: null,
    panel: [TEAMS],
  },
  [TEAMS]: {
    sort: false,
    top: [EVENTS],
    panel: null,
  },
  [EDITORIALS]: {
    sort: false,
    top: null,
    panel: null,
  },
  [VODS]: {
    sort: false,
    top: [EVENTS],
    panel: [PLAYERS, TEAMS, CHAMPIONS],
  },
  [CHAMPIONS]: {
    sort: false,
    top: null,
    panel: [CLASSES],
  },
  [HIGHLIGHTS]: {
    sort: false,
    top: [EVENTS],
    panel: [PLAYERS, TEAMS, CHAMPIONS],
  },
  [SCHEDULE]: {
    sort: false,
    top: [EVENTS],
    panel: [TEAMS],
  },
  [EVENTS]: {
    sort: false,
    top: null,
    panel: null,
  },
};

export const filterOptionsMobile = {
  [PLAYERS]: {
    sort: false,
    top: null,
    panel: [TEAMS],
  },
  [TEAMS]: {
    sort: false,
    top: null,
    panel: [EVENTS],
  },
  [EDITORIALS]: {
    sort: false,
    top: null,
    panel: null,
  },
  [VODS]: {
    sort: false,
    top: null,
    panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
  },
  [CHAMPIONS]: {
    sort: false,
    top: null,
    panel: [CLASSES],
  },
  [HIGHLIGHTS]: {
    sort: false,
    top: null,
    panel: [PLAYERS, TEAMS, EVENTS, CHAMPIONS],
  },
  [SCHEDULE]: {
    sort: false,
    top: null,
    panel: [TEAMS, EVENTS],
  },
  [EVENTS]: {
    sort: false,
    top: null,
    panel: null,
  },
};

export const sectionTitles = {
  [PLAYERS]: {
    top: 'Rising star',
    highlighted: 'Popular',
    all: 'All Players',
  },
  [TEAMS]: {
    top: 'Defying expectations',
    highlighted: 'Popular',
    all: 'All teams',
  },
  [EDITORIALS]: {
    top: 'Staff recommendations',
    highlighted: 'Popular',
    all: 'Newest',
  },
  [VODS]: {
    top: '',
    highlighted: '',
    all: '',
  },
  [CHAMPIONS]: {
    top: 'Unstoppable force',
    highlighted: 'Popular',
    all: 'All champions',
  },
  [HIGHLIGHTS]: {
    top: "Curator's pick",
    highlighted: 'Popular',
    all: 'Newest',
  },
  [EVENTS]: {
    top: '',
    highlighted: '',
    all: '',
  },
};

export const elementSizesPropType = PropTypes.shape({
  tabsetSize: PropTypes.string,
  playerCardSize: PropTypes.string,
  topicCardSize: PropTypes.string,
  topicLinkSize: PropTypes.string,
  matchItemSize: PropTypes.string,
  videoListingSize: PropTypes.string,
  videoListingType: PropTypes.string,
});

export const categoryTypePropType = PropTypes.oneOf([
  ALL,
  PLAYERS,
  TEAMS,
  VODS,
  CHAMPIONS,
  RIOTCHAMPIONS,
  HIGHLIGHTS,
  EVENTS,
  SCHEDULE,
  EDITORIALS,
]);
