import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetTeams from 'tv-selectors/team/makeGetTeams';
import makeGetMatch from 'tv-selectors/match/makeGetMatch';

const emptyArray = [];

const makeGetMatchTeams = () => {
  const getAllTeams = makeGetTeams();
  const getMatch = makeGetMatch();
  return createSelector([getMatch, getAllTeams], (match, allTeams = {}) => {
    const { teams: matchTeams = [] } = match;
    if (matchTeams.length === 0) return emptyArray;
    const teamsInfo = matchTeams.reduce((teams = [], teamId) => {
      if (allTeams[teamId]) {
        teams.push(allTeams[teamId]);
      }
      return teams;
    }, []);
    if (teamsInfo.length === 0) return emptyArray;
    return teamsInfo;
  });
};

export const useMatchTeams = props => useMemoizedGqlSelector(makeGetMatchTeams, props);

export default makeGetMatchTeams;
