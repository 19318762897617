import PropTypes from 'prop-types';
import { Team, Teams, Select, Player } from './PredictionRightIconTypes';
import { exceptions, variants } from '../../predictionConstants';

const PredictionCardRightIcon = ({ scenario }) => {
  const { options, variant, type } = scenario;
  if (exceptions.includes(type)) return null;

  const optionsArray = Object.values(options);

  switch (variant) {
    case variants.TEAMS: {
      const teamOneId = optionsArray[0].target;
      const teamTwoId = optionsArray[1].target;
      return <Teams teamOneId={teamOneId} teamTwoId={teamTwoId} size="large" />;
    }
    case variants.TEAM: {
      const teamId = optionsArray[0].target;
      return <Team teamId={teamId} />;
    }
    case variants.PLAYER: {
      const playerId = optionsArray[0].target;
      return <Player playerId={playerId} />;
    }
    case variants.SELECT:
      return <Select />;
    default:
      return null;
  }
};

PredictionCardRightIcon.propTypes = {
  scenario: PropTypes.shape({
    id: PropTypes.string,
    variant: PropTypes.string,
    label: PropTypes.string,
    outcome: PropTypes.string,
    type: PropTypes.string,
    expiresIn: PropTypes.number,
    gameOffset: PropTypes.number,
    options: PropTypes.shape({
      id: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        target: PropTypes.string,
        targetType: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default PredictionCardRightIcon;
