import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetControlMap from './makeGetControlMap';

const makeGetPovMute = () => {
  const getControlMap = makeGetControlMap();
  return createSelector([getControlMap], control => control.povMute ?? false);
};

export const usePovMute = () => useMemoizedSelector(makeGetPovMute);

export default makeGetPovMute;
