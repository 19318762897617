import { useCallback } from 'react';

const useCheckCharacterCount = (message: string, characterLimit: number) =>
  useCallback(
    (callback: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void) =>
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const ctrlOrCmd = e.ctrlKey || e.metaKey;

        const allowedKey =
          e.key === 'Backspace' ||
          e.key === 'Enter' ||
          e.key === 'End' ||
          e.key === 'Home' ||
          e.key === 'ArrowLeft' ||
          e.key === 'ArrowUp' ||
          e.key === 'ArrowRight' ||
          e.key === 'ArrowDown' ||
          e.key === 'Delete' ||
          (ctrlOrCmd && e.key === 'a') ||
          (ctrlOrCmd && e.key === 'x') ||
          (ctrlOrCmd && e.key === 'c') ||
          (ctrlOrCmd && e.key === 'v') ||
          (ctrlOrCmd && e.key === 'z');

        if (!allowedKey && message.length >= characterLimit) {
          e.preventDefault();
          return;
        }
        callback(e);
      },
    [characterLimit, message],
  );

export default useCheckCharacterCount;
