import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetAuthMap from './makeGetAuthMap';

const makeGetIsOnboardingDisabled = () => {
  const getAuthMap = makeGetAuthMap();
  return createSelector([getAuthMap], auth => auth.onboarding?.disabled ?? !global.document);
};

export const useIsOnboardingDisabled = () => useMemoizedSelector(makeGetIsOnboardingDisabled);

export default makeGetIsOnboardingDisabled;
