import styled from 'styled-components';

export const Container = styled.div`
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

export const SubListContainer = styled.div`
  padding-top: 16px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  padding-bottom: 8px;
`;

export const ItemsWrapper = styled.div`
  & > div:last-child {
    margin-bottom: 16px;
  }
  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const ContentWrapper = styled.div`
  .slick-track {
    display: flex;
    align-items: flex-start;
  }
`;
