import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const FilterPadding = styled.div`
  display: flex;
  margin: 16px 0;
  @media ${device.desktop} {
    margin-top: 32px;
  }
`;

export const TabsetWrapper = styled.div`
  margin: 16px 0;
`;

export const EdgeShade = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ position }) => (position === 'left' ? 'left: 0' : 'right: 0')};
  width: 10%;
  z-index: 1;
  background: ${props =>
    `linear-gradient(${props.position === 'left' ? '270' : '90'}deg, rgba(0, 0, 0, 0), ${
      props.theme.primaryBackgroundColor
    })`};
`;

export const LoadingDropdownWrapper = styled.div`
  margin: 32px 0;
`;
