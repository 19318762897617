import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const SubContainer = styled.div`
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #0e1012 0%, rgba(0, 0, 0, 0) 100%);
  margin-left: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LabelIcon = styled.img`
  height: 30px;
  width: 30px;
`;

export const LabelText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.45);
`;

export const ActionContainer = styled.div`
  display: flex;
  border-left: ${({ border }) => (border ? '1px solid rgba(255, 255, 255, 0.6)' : 'none')};
  margin-left: 24px;
  padding: 0 8px;
  height: 25px;
`;

export const IconWrapper = styled.div`
  padding: 0 10px;
  cursor: pointer;
  height: 25px;
`;

export const PoweredByWrapper = styled.div`
  width: 152px;
  height: 12px;
  @media ${device.desktop} {
    width: 228px;
    height: 18px;
  }
`;

export const CreditContainer = styled.div`
  background: linear-gradient(0deg, #0e1012 0%, rgba(0, 0, 0, 0) 100%);
  margin-left: auto;
  margin-top: auto;
  padding: 0 20px;
  width: 100%;
  min-width: 227px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${device.desktop} {
    min-width: 369px;
  }
`;
