import { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import logger from 'znipe-logger';
import PasswordInput from '../PasswordInput/PasswordInput';
import { Form, LinkTypography, LoadingButton, SuccessGif } from '../Shared/Shared';
import { LOADING, SUCCESS, ERROR, firebaseErrors } from '../constants';

const Success = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  margin: 32px 0;
`;

const ResetPasswordForm = () => {
  const [error, setError] = useState({});
  const [state, setState] = useState('');
  const [email, setEmail] = useState();
  const {
    register,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = useForm();
  const { pathname, state: locationState } = useLocation();
  const navigate = useNavigate();
  const { signup, login, forgot } = useMemo(
    () => ({
      signup: pathname.replace('/reset-password', '/register'),
      login: pathname.replace('/reset-password', ''),
      forgot: pathname.replace('/reset-password', '/forgot-password'),
    }),
    [pathname],
  );

  useEffect(() => {
    try {
      setFocus('password');
    } catch (_e) {
      logger.error('Failed to focus');
    }
  }, [setFocus]);

  const setPassword = useCallback(
    data => {
      if (state === LOADING) return;
      setState(LOADING);
      const resetCode = locationState.actionCode;

      const { password } = data;
      const auth = firebase.auth();
      auth
        .verifyPasswordResetCode(resetCode)
        .then(setEmail)
        .then(() => auth.confirmPasswordReset(resetCode, password))
        .then(() => setState(SUCCESS))
        .catch(({ code }) => {
          setState(ERROR);
          setError(firebaseErrors[code] ?? {});
        });
    },
    [locationState.actionCode, state],
  );

  useEffect(() => {
    if (error.type === 'code') navigate(forgot, { state: { error } });
  }, [error, forgot, navigate]);

  if (state === SUCCESS) {
    return (
      <>
        <SuccessGif />
        <Typography type="heading-m">Password updated</Typography>
        <Success>
          <Button
            variant="solid-color"
            size="small"
            to={{
              pathname: login,
              state: {
                email,
              },
            }}
          >
            Log In
          </Button>
        </Success>
      </>
    );
  }

  return (
    <>
      <Typography type="heading-m">Reset password</Typography>
      <Form onSubmit={handleSubmit(setPassword)}>
        <PasswordInput
          {...register('password', { required: true })}
          data-testid="password-input"
          errorMessage={error.message}
          error={error.type === 'password' || !!errors.password}
          showRestrictionsInfo
        />
        <LoadingButton type="submit" loading={state === LOADING}>
          Save password
        </LoadingButton>
        <LinkTypography $center>
          Need an account? <Link to={signup}>Sign Up</Link>
        </LinkTypography>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
