import config from 'tv-config/config';
import callAPI from './callAPI';

const { API_URL } = config;

export const getPurchaseToken = (userID = '', tokenType = '', authToken = null) =>
  new Promise((resolve, reject) => {
    const URL = `${API_URL}/v3/users/${userID}/purchases/tokens?tokenType=${tokenType}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${authToken}`,
    };

    const onResponse = body => body.results;

    callAPI(URL, 'GET', headers).then(onResponse).then(resolve, reject);
  });

export const postPromoCode = (userID = '', promo = '', authToken = null) =>
  new Promise((resolve, reject) => {
    const URL = `${API_URL}/v3/users/${userID}/promocodes?code=${promo}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${authToken}`,
    };
    const body = {}; // must send body

    const onResponse = ({ results }) => results;

    callAPI(URL, 'POST', headers, body).then(onResponse).then(resolve, reject);
  });
