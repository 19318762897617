import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import usePackageIncludesGame from 'tv-hooks/usePackageIncludesGame';
import { CHAMPIONS } from 'tv-routes/Browse/Browse.constants';
import { filterOptionsDesktop, filterOptionsMobile } from './Matches.constants';

const useFilterOptions = activeTab => {
  const { packageSlug } = useParams();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const packageIncludesLol = usePackageIncludesGame('lol', packageSlug);
  return useMemo(() => {
    const filterOptions = isDesktopOrGreater
      ? filterOptionsDesktop[activeTab]
      : filterOptionsMobile[activeTab];
    if (packageIncludesLol) return filterOptions;

    return {
      ...filterOptions,
      panel: filterOptions?.panel?.filter(panel => panel !== CHAMPIONS) ?? null,
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies:
  }, [isDesktopOrGreater, activeTab, packageIncludesLol]);
};

export default useFilterOptions;
