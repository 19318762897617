import shaka from 'shaka-player';

// https://shaka-player-demo.appspot.com/docs/api/lib_net_http_plugin_utils.js.html

const makeHttpResponse = (headers, data, status, uri, responseURL, requestType) => {
  if (status >= 200 && status <= 299 && status !== 202) {
    const response = {
      uri: responseURL || uri,
      originalUri: uri,
      data,
      headers,
      fromCache: !!headers['x-shaka-from-cache'],
    };
    return response;
  }

  let responseText = null;

  try {
    responseText = shaka.util.StringUtils.fromBytesAutoDetect(data);
  } finally {
    if (shaka.log) shaka.log.debug('HTTP error text:', responseText);
  }

  const severity =
    status === 401 || status === 403
      ? shaka.util.Error.Severity.CRITICAL
      : shaka.util.Error.Severity.RECOVERABLE;

  throw new shaka.util.Error(
    severity,
    shaka.util.Error.Category.NETWORK,
    shaka.util.Error.Code.BAD_HTTP_STATUS,
    uri,
    status,
    responseText,
    headers,
    requestType,
  );
};

export default makeHttpResponse;
