import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetBrowser from './makeGetBrowser';

const makeGetWidth = () => {
  const getBrowser = makeGetBrowser();
  return createSelector([getBrowser], browser => browser.width || 1920);
};

export const useWidth = () => useMemoizedSelector(makeGetWidth);

export default makeGetWidth;
