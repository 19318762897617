import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import BannerLayout from 'tv-elements/data-display/Banner/Layout/Layout';
import SubscribeButton from 'tv-modules/Premium/SubscribeButton/SubscribeButton';
import useFreemiumShowBanner from 'tv-hooks/useFreemiumShowBanner';
import ProviewPromoLogo from './ProviewPromoLogo';

const GetPremiumButton = () => <SubscribeButton />;

const bannerTypeContent = {
  splitType: {
    coverImage: 'https://assets.znipe.tv/premium/premium-banner-1.jpg',
    title: 'Want to see more of the action?',
    text: 'Unlock the triple and quad view layout, and more with Pro View Premium!',
  },
  povs: {
    coverImage: 'https://assets.znipe.tv/premium/premium-banner-2.jpg',
    title: 'See through the eyes of the pros',
    text: 'Get access to every pro player’s point of view and follow their game with Premium.',
  },
  map: {
    coverImage: 'https://assets.znipe.tv/premium/premium-banner-3.jpg',
    title: 'Want to keep track of everyone at once?',
    text: "Get an exclusive bird's-eye view stream of the entire map with Pro View Premium!",
  },
};

const PremiumPromoSection = ({ type = 'splitType' }) => {
  const { packageSlug } = useParams();
  const { pathname = '' } = useLocation();
  const pathnameLC = pathname.toLowerCase();
  const pathArr = pathnameLC.split('/');
  const currentPageName = pathArr[packageSlug ? 2 : 1];

  const { isVisible: shouldShowBanner, onBannerHide } = useFreemiumShowBanner({
    pageType: currentPageName,
  });

  const onClose = useCallback(() => onBannerHide(), [onBannerHide]);

  if (!shouldShowBanner) return null;

  const { title, text, coverImage } = bannerTypeContent[type] || {};
  return (
    <BannerLayout
      coverImage={coverImage}
      headerComponent={<ProviewPromoLogo coverImage={coverImage} />}
      footerComponent={<GetPremiumButton />}
      onCloseClick={onClose}
      headlineComponent={title}
      bodyComponent={text}
    />
  );
};

PremiumPromoSection.propTypes = {
  type: PropTypes.oneOf(['splitType', 'povs', 'map']),
};

export default PremiumPromoSection;
