import PropTypes from 'prop-types';
import Triangle from './Arrow.styles';

const Arrow = ({ height = 8, width = 16, direction = 'up', team = 1 }) => {
  let rotating = '0';
  switch (direction) {
    case 'left': {
      rotating = '-90deg';
      break;
    }
    case 'right': {
      rotating = '90deg';
      break;
    }
    case 'down': {
      rotating = '180deg';
      break;
    }
    default:
      break;
  }
  return <Triangle rotating={rotating} width={width / 2} height={height} team={team} />;
};

Arrow.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  team: PropTypes.oneOf([1, 2]),
};

export default Arrow;
