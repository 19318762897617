import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import IconButton from 'znipe-elements/general/IconButton/IconButton';
import Slider from 'znipe-elements/data-entry/Slider/Slider';
import useAudioContext from 'tv-hooks/useAudioContext';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useGetMatchPopoutSoundFrom } from 'tv-selectors/match/makeGetMatchPopoutSoundFrom';
import themes from './SoundOptions.themes';
import { Menu, Header, VolumeControlRow, IconMargin, HeaderText } from './SoundOptions.styles';

const VISUAL_MAX = 100;

const SoundOptions = ({ matchId = '' }) => {
  const themeContext = useThemeContext();
  const {
    toggleCasterMute,
    setCasterVolumeRatio,
    togglePovMute,
    setPovVolumeRatio,
    toggleMute,
    setVolume,
    casterMute,
    casterVolumeRatio,
    povMute,
    povVolumeRatio,
    mute,
    volume,
  } = useAudioContext();

  const videos = useGetMatchPlayerStreams({ matchId });
  const globalStreams = useGetGameGlobalStreams({ matchId });
  const masterStreamId = useMemo(() => videos.find(({ master }) => master)?.id, [videos]);
  const popoutSoundFrom = useGetMatchPopoutSoundFrom({ matchId });
  const enableCasterSlider = useMemo(() => {
    const stream = globalStreams.find(({ streamId }) => {
      if (popoutSoundFrom) return streamId === popoutSoundFrom;
      return streamId === masterStreamId;
    });

    return stream?.type !== 'event';
  }, [globalStreams, masterStreamId, popoutSoundFrom]);

  const calculateVisualVolume = useCallback(
    percentage => {
      if (mute || percentage === 0 || isNaN(percentage)) return 0;

      return Math.max(Math.min(Math.ceil(VISUAL_MAX * percentage), VISUAL_MAX), 0);
    },
    [mute],
  );

  const visualPovVolume = useMemo(
    () => calculateVisualVolume(povMute ? 0 : povVolumeRatio),
    [calculateVisualVolume, povVolumeRatio, povMute],
  );
  const visualCasterVolume = useMemo(
    () => calculateVisualVolume(casterMute ? 0 : casterVolumeRatio),
    [calculateVisualVolume, casterVolumeRatio, casterMute],
  );

  const visualVolume = useMemo(
    () => calculateVisualVolume(volume),
    [calculateVisualVolume, volume],
  );

  return (
    <Menu>
      <Header>
        <HeaderText disabled={!enableCasterSlider} isLabel>
          Caster audio
        </HeaderText>
        <HeaderText>{visualCasterVolume}</HeaderText>
      </Header>
      <VolumeControlRow>
        <Slider
          max={1}
          onChange={setCasterVolumeRatio}
          initialValue={mute || casterMute ? 0 : casterVolumeRatio}
          value={mute || casterMute ? 0 : casterVolumeRatio}
          showBar={enableCasterSlider}
          showHandle={enableCasterSlider}
          disabled={!enableCasterSlider}
        />
        <IconMargin>
          <IconButton transparent onClick={toggleCasterMute} disabled={!enableCasterSlider}>
            <Icon
              type={mute || casterMute ? 'mute' : 'sound'}
              size={mute || casterMute ? 20 : 18}
              fillColor={
                enableCasterSlider ? themeContext.soundOptions.icon.fillColor : colors.grey65
              }
              hoverColor={
                enableCasterSlider ? themeContext.soundOptions.icon.hoverColor : colors.grey65
              }
            />
          </IconButton>
        </IconMargin>
      </VolumeControlRow>
      <Header>
        <HeaderText isLabel>POV audio</HeaderText>
        <HeaderText>{visualPovVolume}</HeaderText>
      </Header>
      <VolumeControlRow>
        <Slider
          max={1}
          onChange={setPovVolumeRatio}
          initialValue={mute || povMute ? 0 : povVolumeRatio}
          value={mute || povMute ? 0 : povVolumeRatio}
          showBar
        />
        <IconMargin>
          <IconButton transparent onClick={togglePovMute}>
            <Icon
              type={mute || povMute ? 'mute' : 'sound'}
              size={mute || povMute ? 20 : 18}
              fillColor={themeContext.soundOptions.icon.fillColor}
              hoverColor={themeContext.soundOptions.icon.hoverColor}
            />
          </IconButton>
        </IconMargin>
      </VolumeControlRow>
      <Header>
        <HeaderText isLabel>Master Volume</HeaderText>
        <HeaderText>{visualVolume}</HeaderText>
      </Header>
      <VolumeControlRow>
        <Slider
          type="horizontal"
          max={1}
          onChange={setVolume}
          initialValue={mute ? 0 : volume}
          value={mute ? 0 : volume}
          showBar
        />
        <IconMargin>
          <IconButton transparent onClick={toggleMute}>
            <Icon
              type={mute ? 'mute' : 'sound'}
              size={mute ? 20 : 18}
              fillColor={themeContext.soundOptions.icon.fillColor}
              hoverColor={themeContext.soundOptions.icon.hoverColor}
            />
          </IconButton>
        </IconMargin>
      </VolumeControlRow>
    </Menu>
  );
};

SoundOptions.propTypes = {
  matchId: PropTypes.string,
};

export default withTheme(memo(SoundOptions), themes, 'soundOptions');
