import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextSkeleton from 'znipe-elements/feedback/TextSkeleton/TextSkeleton';
import VideoSkeleton from 'znipe-elements/feedback/VideoSkeleton/VideoSkeleton';
import { Container, Thumbnail, Content } from './VODItem.styles';
import { SMALL, MEDIUM, LARGE } from './VODItem.constants';

const VODItemLoading = ({ size = SMALL }) => {
  const elementSizes = useMemo(() => {
    if (size === SMALL) {
      return {
        lineOneWidth: 110,
        lineOneHeight: 16,
        lineTwoWidth: 80,
        lineTwoHeight: 16,
      };
    }
    if (size === MEDIUM) {
      return {
        lineOneWidth: 160,
        lineOneHeight: 20,
        lineTwoWidth: 200,
        lineTwoHeight: 16,
      };
    }
    return {
      lineOneWidth: 350,
      lineOneHeight: 20,
      lineTwoWidth: 264,
      lineTwoHeight: 20,
    };
  }, [size]);

  return (
    <Container data-testid="vod-item-loading" $isLoading>
      <Thumbnail size={size}>
        <VideoSkeleton />
      </Thumbnail>
      <Content size={size} $isLoading>
        <TextSkeleton width={elementSizes.lineOneWidth} height={elementSizes.lineOneHeight} />
        <TextSkeleton width={elementSizes.lineTwoWidth} height={elementSizes.lineTwoHeight} />
      </Content>
    </Container>
  );
};

VODItemLoading.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
};

export default VODItemLoading;
