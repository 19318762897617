import { useRef } from 'react';
import styled from 'styled-components';
import useOnScreen from 'znipe-hooks/useOnScreen';

const HiddenSpan = styled.span`
  visibility: hidden;
`;

type InfiniteScrollWrapperProps = {
  callback: (shouldFetch: boolean) => void;
  rootMargin?: string;
};

const InfiniteScrollWrapper: React.FC<InfiniteScrollWrapperProps> = ({
  callback,
  rootMargin = 50,
}) => {
  const itemRef = useRef(null);
  useOnScreen(itemRef, `${rootMargin}px`, callback);
  return <HiddenSpan data-testid="infinite-scroll-wrapper" ref={itemRef} id="lastItem" />;
};

export default InfiniteScrollWrapper;
