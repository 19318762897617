import registerZnipeGlobal from 'znipe-utils/web/registerZnipeGlobal';
import isBrowser from 'znipe-utils/web/isBrowser';
import AuthManager from 'znipe-auth-manager';
import { error } from 'znipe-logger';
import ZnipeEventTarget from 'znipe-utils/events/ZnipeEventTarget';
import ZnipeEvent from 'znipe-utils/events/ZnipeEvent';
import {
  METADATA_EVENT_CATEGORY,
  modules,
  NAVIGATION_EVENT_CATEGORY,
  NAVIGATION_EVENT_TYPE,
  PLAYBACK_EVENT_CATEGORY,
  PLAYBACK_EVENT_TYPE,
  UI_EVENT_CATEGORY,
  UI_EVENT_TYPE,
} from 'znipe-constants/analytics';
import callApi from './utils/callApi';
import { NavigationFields, NavigationEvent } from './types/navigation';
import { UIFields, UIEvent } from './types/uiEvents';
import { PlaybackEvent, PlaybackFields } from './types/videoPlayback';
import { MetaDataEvent, MetaDataFields } from './types/metadata';

export const getTimestamp = () => Date.now() / 1000;

const EVENT = 'analytics';

type Module = (typeof modules)[number];

export type Event = UIEvent | NavigationEvent | PlaybackEvent | MetaDataEvent;

type EventFields = Event & { clientTimestamp: number; module: string };

type Timer = string | number | NodeJS.Timeout | undefined;

type Queue = {
  fields: EventFields;
  authToken?: string;
}[];

const DEFAULT_TIMER_KEY = 'ANALYTICS_QUEUE_TIMER';
const BATCH_TIMER = 5 * 1000;

class AnalyticsManager extends ZnipeEventTarget {
  static timer: Timer;

  static queue: Queue = [];

  private readonly module;

  static apiEndpoint?: string;

  private authManager?;

  constructor(module: Module, authManagerId?: string) {
    super();
    this.module = module;

    this.authManager = AuthManager.getAuthManager(authManagerId) ?? new AuthManager(authManagerId);
  }

  destructor() {
    this.authManager = undefined;
    AnalyticsManager.flush();
  }

  public trackUIEvent(data: UIFields) {
    const uiEvent = {
      ...data,
      category: UI_EVENT_CATEGORY,
      eventType: UI_EVENT_TYPE,
    };
    this.trackEvent(uiEvent);
  }

  public trackNavigationEvent(data: NavigationFields) {
    const navigationEvent = {
      ...data,
      category: NAVIGATION_EVENT_CATEGORY,
      eventType: NAVIGATION_EVENT_TYPE,
    };
    this.trackEvent(navigationEvent);
  }

  public trackPlaybackEvent(data: PlaybackFields) {
    const playbackEvent = {
      ...data,
      category: PLAYBACK_EVENT_CATEGORY,
      eventType: PLAYBACK_EVENT_TYPE,
    };
    this.trackEvent(playbackEvent);
  }

  public trackMetadata(type: string, data: MetaDataFields) {
    const event = {
      ...data,
      eventType: type,
      category: METADATA_EVENT_CATEGORY,
    };

    this.trackEvent(event);
  }

  private trackEvent(data: Event) {
    const authToken = this.authManager?.getAuthToken();

    if (!isBrowser()) return;
    const fieldsWithTimeAndModule = {
      ...data,
      clientTimestamp: getTimestamp(),
      module: this.module,
    };
    AnalyticsManager.queue.push({
      fields: fieldsWithTimeAndModule,
      authToken,
    });
    if (!AnalyticsManager.timer) {
      AnalyticsManager.timer = setTimeout(() => AnalyticsManager.flush(), BATCH_TIMER);
    }

    this.dispatchEvent(new ZnipeEvent(EVENT, { event: fieldsWithTimeAndModule }));
  }

  static setEndpoint(endpoint: string) {
    AnalyticsManager.apiEndpoint = endpoint;
  }

  static flush() {
    clearTimeout(AnalyticsManager.timer);
    AnalyticsManager.timer = undefined;

    const groups = AnalyticsManager.queue.reduce(
      (acc, { fields, authToken }) => {
        const key = authToken ?? DEFAULT_TIMER_KEY;
        if (!acc[key]) acc[key] = [];
        acc[key].push(fields);
        return acc;
      },
      {} as { [key: string]: Event[] },
    );
    AnalyticsManager.queue = [];

    Object.entries(groups).forEach(([key, events]) => {
      if (!events || events.length < 1) return;
      const authToken = key === DEFAULT_TIMER_KEY ? undefined : key;
      callApi(events, authToken, AnalyticsManager.apiEndpoint).catch(error);
    });
  }
}

export type AnalyticsManagerType = typeof AnalyticsManager;

export type AnalyticsManagerInstanceType = AnalyticsManager;

export default registerZnipeGlobal('AnalyticsManager', AnalyticsManager);
