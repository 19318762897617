import langs from 'langs';

// Lookup language code in ISO-639-2
// langs.where returns, {1: "en", 2: "eng", 3: "eng", name: "English", local: "English", 2T: "eng", 2B: "eng"}
export const getShortLanguageName = (code = '') => {
  if (code === '') {
    return '';
  }
  const splitedCode = code.split('_');
  if (splitedCode.length !== 2) {
    return '';
  }
  const results = langs.where('1', splitedCode[0]);
  if (!results) {
    return '';
  }
  return results[2];
};

export default getShortLanguageName;
