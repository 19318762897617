import { Logo } from './PayPalForm.styles';

export default () => (
  <Logo viewBox="0 0 148 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.923 10.1596H48.9689C48.501 10.1596 48.0332 10.6275 47.7992 11.0954L44.524 31.6824C44.524 32.1503 44.758 32.3842 45.2258 32.3842H48.9689C49.4368 32.3842 49.9047 31.9163 50.1387 31.4485L51.0744 25.8338C51.0744 25.3659 51.5423 24.898 52.2441 24.898H54.8175C60.1982 24.898 63.2395 22.3247 63.9413 17.1779C64.4092 15.0724 63.9413 13.2008 63.0056 12.0311C61.6019 10.8614 59.7303 10.1596 56.923 10.1596ZM57.8588 17.8797C57.3909 20.687 55.2854 20.687 53.1799 20.687H52.0102L52.946 15.3063C52.946 15.0724 53.1799 14.8385 53.6478 14.8385H54.1157C55.5194 14.8385 56.923 14.8385 57.6249 15.7742C57.8588 16.0082 58.0927 16.71 57.8588 17.8797Z"
      fill="#263B80"
    />
    <path
      d="M81.0192 17.6458H77.2761C77.0422 17.6458 76.5743 17.8797 76.5743 18.1137L76.3403 19.2834L76.1064 18.8155C75.1706 17.6458 73.533 17.1779 71.6615 17.1779C67.4505 17.1779 63.7074 20.4531 63.0056 24.898C62.5377 27.2375 63.2395 29.343 64.4092 30.7466C65.5789 32.1503 67.2165 32.6182 69.322 32.6182C72.8312 32.6182 74.7027 30.5127 74.7027 30.5127L74.4688 31.6824C74.4688 32.1503 74.7027 32.3842 75.1706 32.3842H78.6798C79.1477 32.3842 79.6156 31.9163 79.8495 31.4485L81.955 18.3476C81.7211 18.1137 81.2532 17.6458 81.0192 17.6458ZM75.6385 25.132C75.1706 27.2375 73.533 28.8751 71.1936 28.8751C70.0239 28.8751 69.0881 28.4072 68.6202 27.9393C68.1523 27.2375 67.9184 26.3017 67.9184 25.132C68.1523 23.0265 70.0239 21.3889 72.1294 21.3889C73.2991 21.3889 74.0009 21.8568 74.7027 22.3246C75.4046 23.0265 75.6385 24.1962 75.6385 25.132Z"
      fill="#263B80"
    />
    <path
      d="M101.372 17.6458H97.3952C96.9273 17.6458 96.6934 17.8797 96.4594 18.1137L91.3127 26.0678L88.9733 18.5816C88.7393 18.1137 88.5054 17.8798 87.8035 17.8798H84.0604C83.5925 17.8798 83.3586 18.3476 83.3586 18.8155L87.5696 31.2145L83.5925 36.8292C83.3586 37.2971 83.5925 37.9989 84.0604 37.9989H87.8035C88.2714 37.9989 88.5054 37.765 88.7393 37.531L101.606 19.0495C102.308 18.3476 101.84 17.6458 101.372 17.6458Z"
      fill="#263B80"
    />
    <path
      d="M27.2121 3.14131C25.5745 1.26976 22.5332 0.333984 18.3223 0.333984H6.62507C5.92324 0.333984 5.22141 1.03581 4.98747 1.73764L0.308594 32.3843C0.308594 33.0861 0.776481 33.554 1.24437 33.554H8.49662L10.3682 22.0907V22.5586C10.6021 21.8568 11.3039 21.155 12.0058 21.155H15.5149C22.2993 21.155 27.1829 18.4061 28.8205 10.686C28.8205 10.452 29.0837 10.1596 29.0837 9.92567C28.8497 9.92567 28.8497 9.92567 29.0837 9.92567C29.3176 6.88441 28.8497 5.01286 27.2121 3.14131Z"
      fill="#263B80"
    />
    <path
      d="M114.239 10.1596H106.285C105.817 10.1596 105.349 10.6275 105.116 11.0954L101.84 31.6824C101.84 32.1503 102.074 32.3842 102.542 32.3842H106.753C107.221 32.3842 107.455 32.1503 107.455 31.6824L108.391 25.8338C108.391 25.3659 108.859 24.898 109.56 24.898H112.134C117.515 24.898 120.556 22.3247 121.258 17.1779C121.726 15.0724 121.258 13.2009 120.322 12.0311C118.918 10.8614 116.813 10.1596 114.239 10.1596ZM115.175 17.8797C114.707 20.6871 112.602 20.6871 110.496 20.6871H109.093L110.028 15.3064C110.028 15.0724 110.262 14.8385 110.73 14.8385H111.198C112.602 14.8385 114.005 14.8385 114.707 15.7742C115.175 16.0082 115.175 16.71 115.175 17.8797Z"
      fill="#139AD6"
    />
    <path
      d="M138.101 17.6458H134.358C134.124 17.6458 133.657 17.8797 133.657 18.1137L133.423 19.2834L133.189 18.8155C132.253 17.6458 130.615 17.1779 128.744 17.1779C124.533 17.1779 120.79 20.4531 120.088 24.898C119.62 27.2375 120.322 29.343 121.491 30.7466C122.661 32.1503 124.299 32.6182 126.404 32.6182C129.913 32.6182 131.785 30.5127 131.785 30.5127L131.551 31.6824C131.551 32.1503 131.785 32.3842 132.253 32.3842H135.762C136.23 32.3842 136.698 31.9164 136.932 31.4485L139.037 18.3476C138.803 18.1137 138.569 17.6458 138.101 17.6458ZM132.721 25.132C132.253 27.2375 130.615 28.8751 128.276 28.8751C127.106 28.8751 126.17 28.4072 125.702 27.9393C125.235 27.2375 125.001 26.3017 125.001 25.132C125.235 23.0265 127.106 21.3889 129.212 21.3889C130.381 21.3889 131.083 21.8568 131.785 22.3247C132.721 23.0265 132.955 24.1962 132.721 25.132Z"
      fill="#139AD6"
    />
    <path
      d="M142.547 10.8614L139.271 31.9163C139.271 32.3842 139.505 32.6182 139.973 32.6182H143.248C143.716 32.6182 144.184 32.1503 144.418 31.6824L147.693 11.0953C147.693 10.6275 147.459 10.3935 146.991 10.3935H143.248C143.014 10.1596 142.78 10.3935 142.547 10.8614Z"
      fill="#139AD6"
    />
    <path
      d="M28.5282 9.51624C28.5282 9.75018 28.8499 10.3935 28.8499 10.6275C27.2122 18.5816 22.0655 21.1549 15.2811 21.1549H11.772C11.0701 21.1549 10.3683 21.8568 10.1344 22.5586L7.79492 36.8292C7.79492 37.297 8.02887 37.7649 8.7307 37.7649H14.8132C15.5151 37.7649 16.2169 37.297 16.2169 36.5952V36.3613L17.3866 29.109V28.6411C17.3866 27.9393 18.0884 27.4714 18.7903 27.4714H19.7261C25.5746 27.4714 30.2535 25.132 31.4232 18.1137C31.8911 15.3063 31.6572 12.733 30.2535 11.0954C30.0196 10.6275 29.4054 9.95488 28.5282 9.51624Z"
      fill="#139AD6"
    />
    <path
      d="M25.8089 8.75591C24.8439 8.58048 23.9374 8.52197 22.7676 8.52197H13.6438C13.4099 8.52197 13.176 8.52197 12.942 8.75591C12.4741 8.98986 12.2402 9.45774 12.2402 9.92563L10.1055 22.3539V22.8218C10.3394 22.1199 11.3044 21.1549 12.0062 21.1549H15.5154C22.2998 21.1549 27.1834 18.5523 28.821 10.8321C28.9672 10.3935 29.0841 10.1131 29.0841 9.80863C28.3823 9.22378 26.774 8.93135 25.8089 8.75591Z"
      fill="#232C65"
    />
  </Logo>
);
