import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import GridTable from 'znipe-elements/data-display/GridTable/GridTable';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';
import Icon from 'znipe-elements/general/Icon/Icon';
import { predictionType } from '../../../types';
import {
  ResultTableColumnWrapper,
  GridWrapper,
  ThreeOptionsLabelContainer,
} from './PredictionDisplay.styles';
import { highlightTableSide } from './highlightTableSide';
import { TeamLogoComponent, PlayerTeamLogoComponent } from './PredictionDisplayPlayerTeamImage';
import { targetTypes, status } from '../../../predictionConstants';

const PredictionDisplayedResult = ({ prediction, numberOfOptions }) => {
  const { predictedValue, outcome, options } = prediction;
  const isNotPending = outcome !== status.pending;

  const isThreeOptions = useMemo(() => numberOfOptions === 3, [numberOfOptions]);
  const columns = useMemo(() => Array(numberOfOptions).fill(''), [numberOfOptions]);

  const rowColumns = Object.values(options).map(option => {
    const player = option.targetType === targetTypes.PLAYER;
    const team = option.targetType === targetTypes.TEAM;
    const isCorrect = outcome === option.value;
    return (
      <ResultTableColumnWrapper
        $highLighted={highlightTableSide(predictedValue, outcome, option.value)}
      >
        {!isThreeOptions && player && (
          <PlayerTeamLogoComponent playerId={option.target} label={option.label} />
        )}
        {!isThreeOptions && team && (
          <TeamLogoComponent teamId={option.target} label={option.label} />
        )}
        {!isThreeOptions && !player && !team && (
          <Typography type="title-m">{option.label}</Typography>
        )}
        {isThreeOptions && (
          <ThreeOptionsLabelContainer>
            <Typography type="title-s">{option.label}</Typography>
          </ThreeOptionsLabelContainer>
        )}
        {isNotPending && (
          <Icon
            type={isCorrect ? 'check' : 'close'}
            size={16}
            fillColor={isCorrect ? colors.beanGreen : colors.flamingo}
          />
        )}
      </ResultTableColumnWrapper>
    );
  });

  return (
    <GridWrapper>
      <GridTable columns={columns} data={[rowColumns]} borderType="vertical" withLabels />
    </GridWrapper>
  );
};

PredictionDisplayedResult.propTypes = {
  prediction: predictionType.isRequired,
  numberOfOptions: PropTypes.number.isRequired,
};

export default memo(PredictionDisplayedResult);
