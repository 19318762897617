import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetGreaterThan from './makeGetGreaterThan';

const makeGetIsMobilePortraitOrGreater = () => {
  const getGreaterThan = makeGetGreaterThan();
  return createSelector([getGreaterThan], greaterThan => greaterThan.mobilePortrait);
};

export const useGetIsMobilePortraitOrGreater = () =>
  useMemoizedSelector(makeGetIsMobilePortraitOrGreater);

export default makeGetIsMobilePortraitOrGreater;
