import styled from 'styled-components';
import colors from 'znipe-styles/colors';

export const Container = styled.div`
  margin: auto;
  width: 70%;
  font-size: 14px;
  margin-bottom: 7%;
`;

export const OutLink = styled.a`
  color: #299ded;

  &:active,
  &:visited {
    color: #299ded;
  }

  &:hover,
  &:focus {
    color: #65c0ff;
  }
`;

export const Table = styled.table`
  border-spacing: 0px;
  margin: 16px 0;
  td,
  th {
    height: 24px;
    width: 300px;
    border: 1px solid white;
    margin: 0;
    padding: 4px 8px;
  }

  th {
    color: black;
    font-weight: bold;
    &:first-child {
      background-color: ${colors.goGreen};
    }
    &:last-child {
      background-color: ${colors.dangerRed};
    }
  }
`;
