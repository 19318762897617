import { memo } from 'react';
import PropTypes from 'prop-types';
import { SCROLLLIST } from 'tv-elements/layout/Section/Section.constants';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SpotlightWrapper } from 'tv-routes/Home/Home.styles';
import ListWrapperElement from 'tv-routes/Browse/components/ListWrapperElement';
import Section from 'tv-elements/layout/Section/Section';

const Spotlight = ({
  title = '',
  backgroundTitle = '',
  foregroundTitle = '',
  link,
  children,
  isDesktopOrGreater = true,
  type = 'players',
  noContent = false,
}) => (
  <Section
    type={SCROLLLIST}
    direction="vertical"
    title={title}
    backgroundTitle={backgroundTitle}
    foregroundTitle={foregroundTitle}
    link={link}
  >
    {noContent ? (
      children
    ) : (
      <ScrollContainer vertical={false}>
        <SpotlightWrapper data-testid="spotlight-wrapper">
          <ListWrapperElement type={type} isDesktopOrGreater={isDesktopOrGreater} shouldUseWrapper>
            {children}
          </ListWrapperElement>
        </SpotlightWrapper>
      </ScrollContainer>
    )}
  </Section>
);

Spotlight.propTypes = {
  title: PropTypes.string,
  backgroundTitle: PropTypes.string,
  foregroundTitle: PropTypes.string,
  link: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['players', 'teams', 'events', 'champions']),
  noContent: PropTypes.bool,
  isDesktopOrGreater: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default memo(Spotlight);
