import colors from 'znipe-styles/colors';

export default {
  proview: {
    iconColor: colors.riotBackgroundRule,
  },
  default: {
    iconColor: colors.riotBackgroundRule,
  },
};
