const buildStreamObject = ({
  streamId,
  src,
  token,
  initialSoundFrom,
  audioOnly = false,
  offsets,
  srcOffsetId,
  tokenExpireTime,
  ...streamInfo
}) => {
  if (!src || !token) return null;
  const now = Date.now();
  if (!tokenExpireTime || now > tokenExpireTime) return null;
  const streamSrc = token ? `${src}?${token}` : src;
  const isMaster = initialSoundFrom === streamId;
  const streamOffset = offsets[srcOffsetId] || 0;
  return {
    ...streamInfo,
    id: streamId,
    src: streamSrc,
    audioOnly,
    master: isMaster,
    offset: streamOffset,
    srcOffsetId,
  };
};

export default buildStreamObject;
