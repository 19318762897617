import colors from 'znipe-styles/colors';

export default {
  proview: {
    iconColor: colors.liveRed,
    bgColorListItemDark: colors.paleGrey,
    textColorPaid: colors.silver,
    textColorUnpaid: colors.liveRed,
    textColorPending: colors.riotAccentOrange,
    textColorHeader: colors.silver,
    textColor: colors.black,
  },
  default: {
    iconColor: colors.liveRed,
    bgColorListItemDark: colors.paleGrey,
    textColorPaid: colors.silver,
    textColorUnpaid: colors.liveRed,
    textColorPending: colors.riotAccentOrange,
    textColorHeader: colors.silver,
    textColor: colors.black,
  },
};
