import { memo } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'znipe-elements/data-display/Avatar/Avatar';
import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';

import {
  Container,
  ImageContainer,
  LevelBadge,
  IconWrapper,
  AvatarWrapper,
  LevelNumber,
} from './LOLAvatar.style';

const selectedEmptyPlayer = gameRole => {
  if (gameRole === 'top') return <Icon type="topRole" />;
  if (gameRole === 'jungle') return <Icon type="jungleRole" />;
  if (gameRole === 'mid') return <Icon type="midRole" />;
  if (gameRole === 'bottom' || gameRole === 'marksman') return <Icon type="bottomRole" />;
  if (gameRole === 'support') return <Icon type="supportRole" />;
  return <Icon type="defaultRole" />;
};

const getIconSize = (size, customIconSize) => {
  if (customIconSize) return customIconSize;
  switch (size) {
    case 'large':
      return 16;
    case 'medium':
      return 16;
    default:
      return 11;
  }
};

const LOLAvatar = ({
  imageUrl,
  gameRole,
  level,
  levelStyle = 'default',
  size = 'large',
  shape = 'square',
  withBorder = false,
  levelPosition = 'left',
  highlight = false,
  icon,
  dimmed = false,
  customIconSize,
  iconPosition,
}) => {
  const isPlaceholder = typeof imageUrl === 'undefined';
  const iconSize = getIconSize(size, customIconSize);
  return (
    <Container>
      <ImageContainer
        size={size}
        $shape={shape}
        withBorder={withBorder}
        highlight={highlight}
        isPlaceholder={isPlaceholder}
      >
        <AvatarWrapper
          size={size}
          $shape={shape}
          highlight={highlight}
          dimmed={dimmed}
          withBorder={withBorder}
        >
          <Avatar
            imageUrl={imageUrl}
            shape={shape}
            size={size}
            fallbackIcon={selectedEmptyPlayer(gameRole)}
          />
        </AvatarWrapper>
        {level && (
          <LevelBadge
            position={levelPosition}
            type={levelStyle}
            shape={shape}
            size={size}
            highlight={highlight}
            data-testid="lol-avatar-level-badge"
          >
            <LevelNumber>{level}</LevelNumber>
          </LevelBadge>
        )}
        {icon && (
          <IconWrapper
            offset={iconSize / 2}
            position={iconPosition}
            data-testid="wrapper-for-passed-icon"
          >
            <Icon type={icon} fillColor={colors.white} size={iconSize} />
          </IconWrapper>
        )}
      </ImageContainer>
    </Container>
  );
};

LOLAvatar.propTypes = {
  imageUrl: PropTypes.string,
  gameRole: PropTypes.oneOf(['top', 'jungle', 'mid', 'bottom', 'support', 'champion']),
  level: PropTypes.number,
  levelPosition: PropTypes.oneOf(['left', 'right']),
  levelStyle: PropTypes.oneOf(['default', 'circle']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'custom']),
  shape: PropTypes.oneOf(['square', 'round']),
  highlight: PropTypes.bool,
  withBorder: PropTypes.bool,
  icon: PropTypes.string,
  dimmed: PropTypes.bool,
  customIconSize: PropTypes.number,
  iconPosition: PropTypes.string,
};

export default memo(LOLAvatar);
