import { BehaviorSubject, type Subject } from 'rxjs';
import { UserListItem, AccountInfo } from '../contexts/ChatContext';
import { ACTION_TYPES } from '../constants';
import type { ChatStoreInfo } from 'znipe-chat/src/hooks/useInitialLoadCallback';

export type ReplyTo = {
  color: string;
  serverMessageId?: string;
  userId: string;
  username: string;
  isModerator?: boolean;
};

export type StateMessage = {
  clientMessageId: string;
  serverMessageId?: string;
  userId: string;
  channel: string | null;
  username: string;
  color: string;
  message: string;
  timestamp: string;
  avatarId?: string | null;
  isModerator?: boolean;
  reported?: boolean;
  isMessageModerator?: boolean;
  action?: (typeof ACTION_TYPES)[number];
  deleted?: boolean;
  signifierIds?: string[];
  replyTo?: ReplyTo | null;
};

type MessageUser = {
  userId: string;
  username: string;
  color: string;
};

export type Messages = Record<string, StateMessage>;

type State = {
  messageIds: string[];
  messages: Messages;
  users: UserListItem[];
  account?: AccountInfo;
};

export type BannedUserMessage = {
  userId: string;
  channel: string | null;
  reason?: string;
  banEnd?: string;
  serverMessageId?: string;
};

export type IncomingMessage = {
  avatarId: string | null;
  channel: string | null;
  clientMessageId: string;
  color: string;
  isModerator?: boolean;
  message: string;
  replyTo: ReplyTo | null;
  serverMessageId?: string;
  signifierIds: string[];
  userId: string;
  username: string;
};

type DeletedMessage = {
  serverMessageId: string;
  deleted: true;
};

const isDeletedMessage = (message: IncomingMessage | DeletedMessage): message is DeletedMessage =>
  'deleted' in message;

export type ChatStore = {
  onMessage: (message: IncomingMessage | DeletedMessage) => void;
  getState: () => State;
  onUserBanned: (message: BannedUserMessage) => void;
  clearChat: () => void;
  subject: Subject<State>;
  onAction: (id: string, action: (typeof ACTION_TYPES)[number]) => void;
  setAccount: (account?: AccountInfo) => void;
  loadChat: (latestMessages: ChatStoreInfo) => void;
  removeMessage: (messageId: string) => void;
};

const getUsersFromMessages = (messages: Messages, currentUserId?: string) => {
  const sortedmessages = Object.values(messages).sort((a, b) => {
    if (new Date(a.timestamp) > new Date(b.timestamp)) return -1;
    if (new Date(a.timestamp) < new Date(b.timestamp)) return 1;
    return 0;
  });

  const userIds = new Set();
  if (currentUserId) userIds.add(currentUserId);

  return sortedmessages.reduce((acc, current) => {
    if (!userIds.has(current.userId)) {
      userIds.add(current.userId);

      acc.push({
        userId: current.userId,
        username: current.username,
        color: current.color,
      });
    }

    return acc;
  }, [] as MessageUser[]);
};

const createStore = (): ChatStore => {
  const initialState: State = {
    messageIds: [], // Ids to render the list of messages from
    messages: {}, // Message info for entries to listen on
    users: [], // users that have sent messages before
  };
  const maxMessages = 150;

  let state = { ...initialState };
  const subject = new BehaviorSubject(state);

  return {
    subject,
    getState: () => subject.value,
    onAction: (id, action) => {
      const { messages } = state;
      if (!Object.prototype.hasOwnProperty.call(messages, id)) return;
      if (!ACTION_TYPES.includes(action)) return;

      const newMessage = {
        ...messages[id],
        action,
      };
      state = {
        ...state,
        messages: {
          ...messages,
          [id]: newMessage,
        },
      };
      subject.next(state);
    },
    onMessage: (message: IncomingMessage | DeletedMessage) => {
      const { messageIds, messages } = state;
      // Replace local version with server version when getting a response
      if (
        !isDeletedMessage(message) &&
        message.serverMessageId &&
        message.clientMessageId in messages
      ) {
        const messageIndex = messageIds.indexOf(message.clientMessageId);

        const prevMessage = messages[message.clientMessageId];
        const newMessages: Messages = {
          ...messages,
          [message.serverMessageId]: {
            ...prevMessage,
            ...message,
            timestamp: prevMessage.timestamp ?? new Date().toISOString(),
          },
        };

        delete newMessages[message.clientMessageId];
        const newIds = [...messageIds];
        newIds[messageIndex] = message.serverMessageId;

        state = {
          ...state,
          messageIds: newIds,
          messages: newMessages,
          users: getUsersFromMessages(newMessages, state.account?.uid),
        };

        subject.next(state);
        return;
      }

      // Update message if it already exist
      if (message.serverMessageId && message.serverMessageId in messages) {
        const prevMessage = messages[message.serverMessageId];
        const newMessages: Messages = {
          ...messages,
          [message.serverMessageId]: {
            ...prevMessage,
            ...message,
            timestamp: prevMessage.timestamp ?? new Date().toISOString(),
          },
        };

        state = {
          ...state,
          messages: newMessages,
          users: getUsersFromMessages(newMessages, state.account?.uid),
        };
        subject.next(state);

        return;
      }

      // Should not get here with deleted message
      if (isDeletedMessage(message)) return;

      const messageId = message.serverMessageId ?? message.clientMessageId;
      if (!messageId) return;
      const newMessages: Messages = {
        ...messages,
        [messageId]: {
          ...message,
          timestamp: new Date().toISOString(),
        },
      };
      const newIds = [...messageIds, messageId];

      // Delete messages if we have to many
      if (messageIds.length === maxMessages) {
        const messageToRemove = messageIds[0];
        delete newMessages[messageToRemove];
        newIds.shift();
      }

      state = {
        ...state,
        messageIds: newIds,
        messages: newMessages,
        users: getUsersFromMessages(newMessages, state.account?.uid),
      };

      subject.next(state);
    },
    onUserBanned: (message: BannedUserMessage) => {
      const { userId } = message;
      const { messages } = state;

      const newMessages = { ...messages };
      Object.keys(newMessages).forEach(messageId => {
        if (newMessages[messageId].userId === userId) {
          newMessages[messageId] = {
            ...newMessages[messageId],
            deleted: true,
          };
        }
      });

      state = {
        ...state,
        messages: newMessages,
        users: getUsersFromMessages(newMessages, state.account?.uid),
      };

      subject.next(state);
    },
    removeMessage: (messageId: string) => {
      const { messages, messageIds } = state;
      if (!Object.prototype.hasOwnProperty.call(messages, messageId)) return;

      const newMessages = { ...messages };

      delete newMessages[messageId];
      const newIds = messageIds.filter(id => messageId !== id);

      state = {
        ...state,
        messageIds: newIds,
        messages: newMessages,
        users: getUsersFromMessages(newMessages, state.account?.uid),
      };

      subject.next(state);
    },
    clearChat: () => {
      state = { ...initialState };
      subject.next(state);
    },
    loadChat: (latestMessages: ChatStoreInfo) => {
      state = {
        ...state,
        ...latestMessages,
      };
      state.users = getUsersFromMessages(latestMessages.messages, state.account?.uid);
      subject.next(state);
    },
    setAccount: (account?: AccountInfo) => {
      state.account = account;
      subject.next(state);
    },
  };
};
export default createStore;
