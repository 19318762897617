import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'znipe-styles/breakpoints';

export const Background = styled.div`
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: black;
  background-image: url(${({ theme }) => theme.settingsPageHeader.backgroundImageTablet});
  background-repeat: no-repeat;
  background-size: cover;
  @media ${device.desktop} {
    background: url(${({ theme }) => theme.settingsPageHeader.backgroundImageDesktop});
  }
`;
export const LogoWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 75px;
  cursor: pointer;
  @media ${device.desktop} {
    width: 90px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.63) 0%, rgba(14, 16, 18, 0) 100%);
  background-size: 100% 23px;
  background-position-y: bottom;
  background-repeat: no-repeat;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  @media ${device.tablet} {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 21px 18px 0px 15px;
  color: ${({ theme }) => theme.settingsPageHeader.backgroundColor};
  height: 90px;
  border: 0;

  > *:last-child {
    margin-left: auto;
    margin-right: 0;

    @media ${device.tablet} {
      position: absolute;
      right: 20px;
    }
    @media ${device.desktop} {
      position: initial;
    }
  }
  @media ${device.tablet} {
    max-width: 500px;
    padding: 17px 0px 0px 0px;
  }
  @media ${device.desktop} {
    max-width: 630px;
  }
`;

export const StyledLink = styled(Link)`
  height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.settingsPageHeader.backgroundColor};
  margin-right: 15px;
  @media ${device.tablet} {
    margin-right: 21px;
  }
  @media ${device.desktop} {
    margin-right: 15px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;
