import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown from 'znipe-elements/navigation/Dropdown/Dropdown';
import RolesFilter from 'tv-modules/Filters/RolesFilter/RolesFilter';
import FilterButton from 'tv-modules/Filters/FilterButton/FilterButton';
import FilterMenuStandings from 'tv-modules/Filters/FilterMenuStandings/FilterMenuStandings';
import { useGetIsMobilePortraitOrGreater } from 'tv-selectors/browser/makeGetIsMobilePortraitOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import useQueryObject from 'tv-hooks/useQueryObject';
import {
  selectionShape,
  defaultRoleSelection,
  defaultFilterSelection,
  logoOptionShape,
  EVENTS,
  ROLES,
  filterOptionsShape,
  defaultFilterOptions,
  STANDARD,
  STANDINGS,
} from '../Filters.constants';
import { sortItemsDetails, SORT_FEATURED, SORT_POPULAR, SORT_RECENT } from './FilterMenu.constants';
import {
  Container,
  ButtonWrapper,
  MiddleSection,
  DropdownWrapper,
  SortByWrapper,
} from './FilterMenu.styles';
import { getMarkerNumber } from '../utils/getMarkerNumber';

const emptyArray = [];
const emptyFunction = () => {};

const FilterMenu = ({
  selection = defaultFilterSelection,
  sortTypes = [SORT_FEATURED, SORT_POPULAR, SORT_RECENT],
  filterOptions = defaultFilterOptions,
  eventOptions = emptyArray,
  setYear = emptyFunction,
  setEvent = emptyFunction,
  onClickFilterButton = emptyFunction,
  onChangeSelection = emptyFunction,
  onSortChange = emptyFunction,
  type = STANDARD,
  setTournament = emptyFunction,
  tournamentOptions = emptyArray,
  tabs = [],
  activeTab = '',
  setTab = emptyFunction,
}) => {
  const [sortItems, setSortItems] = useState([]);
  const [yearFilter, setYearFilter] = useState([]);
  const [eventFilter, setEventFilter] = useState([]);
  const isMobilePortraitOrGreater = useGetIsMobilePortraitOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();

  const navigate = useNavigate();
  const { pathname = '' } = useLocation();
  const { sort } = useQueryObject();

  const { top: topLevelFilter, sort: useSort, panel: filterPanel } = filterOptions;

  const useRolesFilter = topLevelFilter?.includes(ROLES);
  const useYearsFilter = false; // hidden - support planned
  const useEventsFilter = topLevelFilter?.includes(EVENTS);

  const sizes = useMemo(() => {
    if (isDesktopLargeOrGreater) {
      return { gridSize: 'xlarge' };
    }
    if (isDesktopOrGreater) {
      return { gridSize: 'large' };
    }
    if (isTabletOrGreater) {
      return { gridSize: 'medium' };
    }
    return { gridSize: 'small' };
  }, [isDesktopOrGreater, isTabletOrGreater, isDesktopLargeOrGreater]);

  const yearOptions = useMemo(() => {
    const max = new Date().getFullYear();
    const length = 4;
    const years = [];
    for (let i = max; i > max - length; i--) years.push(i);
    return years;
  }, []);

  const generateYearFilter = useCallback(() => {
    const selectedYears = selection?.years;
    const yearsObject = yearOptions.map((year, index) => ({
      id: index.toString(),
      title: year.toString(),
      selected: selectedYears.indexOf(year) !== -1,
    }));
    setYearFilter(yearsObject);
  }, [selection, yearOptions]);

  const generateEventFilter = useCallback(() => {
    const newValue = eventOptions.map(event => {
      const selectedEvents = selection?.events.map(item => item.id);
      return {
        ...event,
        selected: selectedEvents.indexOf(event.id) !== -1,
      };
    });
    setEventFilter(newValue);
  }, [selection, eventOptions]);

  const setInitialSortBy = useCallback(() => {
    const sortOptions = sortTypes.map((item, index) => {
      const sortItemInfo = sortItemsDetails[item];
      const sortQuery = sort?.toLowerCase();
      const isSelected = sortQuery ? item === sortQuery : index === 0;
      return {
        ...sortItemInfo,
        selected: isSelected,
      };
    });
    setSortItems(sortOptions);
  }, [sort, sortTypes]);

  const selectedYear = useMemo(() => yearFilter.filter(year => year.selected), [yearFilter]);
  const selectedEvent = useMemo(() => eventFilter.filter(event => event.selected), [eventFilter]);

  useEffect(() => {
    if (Object.keys(selectedYear)?.length) {
      setYear(selectedYear);
    }
  }, [selectedYear, setYear]);

  useEffect(() => {
    if (Object.keys(selectedEvent)?.length) {
      setEvent(selectedEvent);
    }
  }, [selectedEvent, setEvent]);

  const handleChangeSelectionRoles = useCallback(
    newSelection => onChangeSelection({ ...selection, roles: newSelection }),
    [onChangeSelection, selection],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: useYearFilter
  useEffect(() => {
    if (useYearsFilter) generateYearFilter();
    if (useEventsFilter) generateEventFilter();
    if (useSort) setInitialSortBy();
  }, [
    useYearsFilter,
    useEventsFilter,
    useSort,
    generateYearFilter,
    generateEventFilter,
    setInitialSortBy,
  ]);

  const markerNumber = useMemo(
    () => getMarkerNumber(selection, filterPanel),
    [selection, filterPanel],
  );

  const handleChangeYear = useCallback(
    newValue => {
      setYearFilter(newValue);
      onChangeSelection({
        ...selection,
        years: newValue.filter(item => item.selected).map(item => Number(item.title)),
      });
    },
    [onChangeSelection, selection],
  );

  const handleChangeEvent = useCallback(
    newValue => {
      setEventFilter(newValue);
      onChangeSelection({
        ...selection,
        events: newValue.filter(item => item.selected),
      });
    },
    [onChangeSelection, selection],
  );

  const handleChangeSortBy = useCallback(
    newValue => {
      setSortItems(newValue);
      onSortChange(newValue);
      const selectedSort = newValue.find(({ selected }) => selected)?.title?.toLowerCase();
      navigate(pathname.concat(`?sort=${selectedSort}`));
    },
    [navigate, pathname, onSortChange],
  );

  const sortByLabel = useMemo(
    () => sortItems.find(item => item.selected)?.title || 'featured',
    [sortItems],
  );

  const sortBySection = useMemo(
    () =>
      useSort && (
        <SortByWrapper>
          <Dropdown
            items={sortItems}
            onDataChange={handleChangeSortBy}
            type="single"
            label={sortByLabel}
            description="sort by"
            useModal={!isMobilePortraitOrGreater}
          />
        </SortByWrapper>
      ),
    [useSort, sortItems, sortByLabel, handleChangeSortBy, isMobilePortraitOrGreater],
  );

  const rolesSection = useMemo(() => {
    const selectionData = selection ? selection?.roles : defaultRoleSelection;
    return <RolesFilter selection={selectionData} onChange={handleChangeSelectionRoles} />;
  }, [selection, handleChangeSelectionRoles]);

  const noSortNoFilterPanel = !filterOptions.sort && !filterOptions.panel;

  // biome-ignore lint/correctness/useExhaustiveDependencies: useYearFilter
  const filterMenuElement = useMemo(() => {
    if (type === STANDINGS) {
      return (
        <FilterMenuStandings
          filterOptions={filterOptions}
          onFilterChange={onClickFilterButton}
          tournamentOptions={tournamentOptions}
          setTournament={setTournament}
          tabs={tabs}
          activeTab={activeTab}
          setTab={setTab}
        />
      );
    }

    const middleDropdownElements = (
      <>
        {useRolesFilter && rolesSection}
        {useYearsFilter && (
          <DropdownWrapper>
            <Dropdown
              items={yearFilter}
              onDataChange={handleChangeYear}
              type="multi"
              label="years"
              closeOnSelect={false}
            />
          </DropdownWrapper>
        )}
        {useEventsFilter && (
          <DropdownWrapper>
            <Dropdown
              items={eventFilter}
              onDataChange={handleChangeEvent}
              type="multi"
              label="events"
              closeOnSelect={false}
            />
          </DropdownWrapper>
        )}
      </>
    );

    if (noSortNoFilterPanel) {
      return middleDropdownElements;
    }
    return (
      sortTypes?.length > 0 && (
        <>
          {sortBySection}
          {isDesktopOrGreater && <MiddleSection>{middleDropdownElements}</MiddleSection>}
        </>
      )
    );
  }, [
    activeTab,
    eventFilter,
    filterOptions,
    handleChangeEvent,
    handleChangeYear,
    isDesktopOrGreater,
    onClickFilterButton,
    rolesSection,
    setTab,
    setTournament,
    sortBySection,
    sortTypes?.length,
    tabs,
    tournamentOptions,
    type,
    noSortNoFilterPanel,
    useEventsFilter,
    useRolesFilter,
    useYearsFilter,
    yearFilter,
  ]);

  return (
    <Container size={sizes.gridSize} $type={type} $withSort={useSort}>
      {filterMenuElement}
      {type !== STANDINGS && filterOptions.panel && (
        <ButtonWrapper isFloating={!isTabletOrGreater}>
          <FilterButton onClick={onClickFilterButton} markerNumber={markerNumber} />
        </ButtonWrapper>
      )}
    </Container>
  );
};

const eventOptionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
});

FilterMenu.propTypes = {
  selection: PropTypes.shape(selectionShape),
  eventOptions: PropTypes.arrayOf(eventOptionShape),
  sortTypes: PropTypes.arrayOf(PropTypes.oneOf([SORT_FEATURED, SORT_POPULAR, SORT_RECENT])),
  filterOptions: PropTypes.shape(filterOptionsShape),
  setYear: PropTypes.func,
  setEvent: PropTypes.func,
  onClickFilterButton: PropTypes.func,
  onChangeSelection: PropTypes.func,
  onSortChange: PropTypes.func,
  type: PropTypes.oneOf([STANDINGS, STANDARD]),
  setTournament: PropTypes.func,
  tournamentOptions: PropTypes.arrayOf(logoOptionShape),
  tabs: PropTypes.arrayOf(PropTypes.string),
  activeTab: PropTypes.string,
  setTab: PropTypes.func,
};

export default memo(FilterMenu);
