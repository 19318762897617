import deepmerge from 'deepmerge';
import { handleActions } from 'redux-actions';
import ls from 'local-storage';
import {
  CREATE_USER_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  SET_AUTH,
  UPDATE_AUTH,
  UPDATE_USER_SUCCESS,
  GET_PURCHASED_EVENTS_SUCCESS,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  UPDATE_SUBSCRIBE_SUCCESS,
  GET_TRIALED_EVENTS_SUCCESS,
  GET_USER_BADGES_SUCCESS,
  SET_USER_BADGE,
  GET_EVENT_V3_TAGS_SUCCESS,
  SET_FETCHING_STATUS,
  SET_EXTERNAL_JWT,
} from 'tv-constants/oldRedux';
import { LS_AUTH } from 'tv-constants/localStorage';

export const initialState = {};

export default handleActions(
  {
    [SET_AUTH]: (state, { auth }) => ({
      ...auth,
      externalJwt: state.externalJwt,
      loaded: true,
    }),
    [UPDATE_AUTH]: (state, { auth }) =>
      deepmerge(state, {
        ...auth,
      }),
    [GET_PURCHASED_EVENTS_SUCCESS]: (state, action) =>
      deepmerge(state, {
        purchasedItems: {
          events: action.response.results,
        },
      }),
    [SUBSCRIBE_SUCCESS]: (state, action) => {
      const currentState = state.subscriptions ?? [];
      const oldSubState = currentState.find(
        x => x.subscriptionId === action.response.results.subscriptionId,
      );

      if (!oldSubState) {
        return deepmerge(state, {
          subscriptions: currentState.push(action.response.results),
        });
      }

      const index = currentState.indexOf(oldSubState);
      const newSubState = deepmerge(oldSubState, action.response.results);
      const newState = { ...currentState };
      currentState[index] = newSubState;
      return deepmerge(state, {
        subscriptions: newState,
      });
    },
    [SUBSCRIBE_FAILURE]: state =>
      deepmerge(state, {
        freeMonthAlreadyUsed: true,
      }),
    [UPDATE_SUBSCRIBE_SUCCESS]: (state, action) => {
      const canceledSubscriptions = action.response.results.canceledPendingSubscriptions;
      const mergedCanceledSubscriptions = canceledSubscriptions.push(
        action.response.results.canceledSubscription,
      );

      const subscriptions = state.subscriptions ?? [];

      return deepmerge(state, {
        subscriptions: subscriptions.reduce((reduction, sub, i) => {
          const match = mergedCanceledSubscriptions.find(
            current => current.subscriptionId === sub.subscriptionId,
          );
          if (match) reduction[i] = match;
          return reduction;
        }, []),
      });
    },
    [GET_TRIALED_EVENTS_SUCCESS]: (state, action) =>
      deepmerge(state, {
        trialedItems: {
          events: action.response.results,
        },
      }),
    [LOGIN_REQUEST]: state =>
      deepmerge(state, {
        isFetching: false,
        isAuthenticated: false,
      }),
    [LOGIN_SUCCESS]: (state, action) =>
      deepmerge(state, {
        isFetching: false,
        isAuthenticated: true,
        token: action.response.token,
        userId: action.response.user_id,
        username: action.response.user_username,
        userEmail: action.response.user_email,
        subscriptions: action.response.subscriptions,
        loaded: true,
      }),
    [CREATE_USER_SUCCESS]: (state, action) =>
      deepmerge(state, {
        isFetching: false,
        isAuthenticated: true,
        token: action.response.token,
        userId: action.response.id,
        username: action.response.username,
        userEmail: action.response.email,
        loaded: true,
      }),
    [LOGIN_FAILURE]: state =>
      deepmerge(state, {
        isFetching: false,
        isAuthenticated: false,
      }),
    [LOGOUT_SUCCESS]: (_state, { auth }) => {
      ls.remove(LS_AUTH);
      return {
        ...auth,
        isAuthenticated: false,
        isFetching: false,
        loaded: true,
        isLinkedToLGUPlus: false,
      };
    },
    [UPDATE_USER_SUCCESS]: (state, action) => {
      const { username } = action.response;

      return deepmerge(state, {
        username,
      });
    },
    [GET_USER_BADGES_SUCCESS]: (state, action) => {
      const { results } = action.response;
      return deepmerge(state, {
        badges: results,
      });
    },
    [SET_USER_BADGE]: (state, { badgeID }) =>
      deepmerge(state, {
        activeBadge: badgeID,
      }),
    [GET_EVENT_V3_TAGS_SUCCESS]: (state, action) => {
      const results = action.response.results || {};
      return { ...state, tags: results.tags };
    },
    [SET_FETCHING_STATUS]: (state, action) => ({
      ...state,
      isFetching: action.isFetching,
    }),
    [SET_EXTERNAL_JWT]: (state, action) => ({
      ...state,
      externalJwt: action.jwt,
    }),
  },
  initialState,
);
