import styled from 'styled-components';
import colors from 'znipe-styles/colors';

export const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
`;

export const List = styled.div<{ $size: string }>`
  padding-top: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  > * {
    max-width: ${({ $size }) => ($size === 'large' ? 656 : 472)}px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 8px 2px;
  color: ${colors.white};
  width: 100%;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.05);
  }

  *:first-child {
    margin-right: 12px;
  }
`;

export const IconButton = styled.button`
  position: absolute;
  cursor: pointer;
  right: 0;
  transform: translateY(-16px);

  background: transparent;
  border: none;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: content-box;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;
`;
