import deepmerge from 'deepmerge';
import { handleActions } from 'redux-actions';
import {
  DISCONNECT_FROM_ROOM,
  SET_ACTIVE_ROOM,
  SET_CONNECTION_LATENCY,
  SET_ROOM_INFO,
  SET_ROOM_ROLE,
} from 'tv-constants/oldRedux';

export const initialState = {};

export default handleActions(
  {
    [SET_ROOM_ROLE]: (state, action) =>
      deepmerge(state, {
        [action.roomID]: {
          role: action.role,
        },
      }),
    [SET_CONNECTION_LATENCY]: (state, action) =>
      deepmerge(state, {
        [action.roomID]: {
          latency: action.latency,
        },
      }),
    [SET_ROOM_INFO]: (state, action) => {
      const { roomID, roomInfo = {} } = action;
      const newState = deepmerge(state, {
        [roomID]: {
          ...roomInfo,
        },
      });
      const { members = [] } = roomInfo;
      newState[roomID].members = members;
      return newState;
    },
    [DISCONNECT_FROM_ROOM]: (state, action) => {
      delete state[action.roomID];
      return { ...state };
    },
    [SET_ACTIVE_ROOM]: (state, action) => ({
      ...state,
      active: action.roomID,
    }),
  },
  initialState,
);
