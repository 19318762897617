import { createSelector } from 'reselect';
import makeGetState from '../makeGetState';

const emptyObject = {};
const makeGetGamePredictions = () => {
  const getState = makeGetState();
  return createSelector([getState], state => state.predictions ?? emptyObject);
};

export default makeGetGamePredictions;
