import selectMaster from 'znipe-player/src/utils/selectMaster';
import isBrowser from 'znipe-utils/web/isBrowser';

let timeDiff = 0;
if (isBrowser()) {
  const now = Date.now();
  fetch('https://time.akamai.com/')
    .then(res => res.text())
    .then(time => parseInt(time, 10) * 1000)
    .then(time => time - now)
    .then(diff => {
      timeDiff = diff;
    });
}

const getVideoElement = playerInfo => {
  const { ref = {} } = playerInfo;
  const player = ref.current;
  if (!player) return {};
  const video = player.getMediaElement();
  if (!video) return {};
  return video;
};

const createVideoFunc =
  (playersInfo = [], funcName) =>
  () => {
    playersInfo.forEach(playerInfo => {
      const video = getVideoElement(playerInfo);
      video?.[funcName]?.();
    });
  };

const createIsPausedFunc = master => () => {
  const video = getVideoElement(master);
  return video.paused ?? false;
};

const createGetCurrentTime =
  (master, playersInfo = []) =>
  () => {
    const { offset } = master;
    const video = getVideoElement(master);
    const currentTime = video.currentTime + offset;
    if (!isNaN(currentTime)) return currentTime;

    const playerWithTimeInfo = playersInfo.find(player => {
      const playerVideo = getVideoElement(player);
      return !isNaN(playerVideo.currentTime + player.offset);
    });
    if (!playerWithTimeInfo) return currentTime;

    const playerVideo = getVideoElement(playerWithTimeInfo);
    return playerVideo.currentTime + playerWithTimeInfo.offset;
  };

const calculateDateTime = (startISOTime, master) => {
  const video = getVideoElement(master);
  const { currentTime = 0 } = video;
  const date = new Date(startISOTime);
  const ms = date.getTime();

  return ms + currentTime * 1000;
};

const createGetCurrentDateTime = master => startISOTime => {
  const { offset = 0 } = master;
  const player = master.ref?.current;
  if (!player) return null;
  const playhead = player.getPlayheadTimeAsDate();
  if (!playhead && !startISOTime) return null;

  const ms = playhead ? playhead.getTime() + timeDiff : calculateDateTime(startISOTime, master);
  const norm = ms + offset * 1000;
  return new Date(norm);
};

const createGetStartDateTime = master => startISOTime => {
  const { offset = 0 } = master;
  const player = master.ref?.current;
  if (!player) return null;
  const startDate = player.getPresentationStartTimeAsDate();
  if (!startDate && !startISOTime) return null;
  const ms = startDate ? startDate.getTime() + timeDiff : new Date(startISOTime).getTime();
  const norm = ms + offset * 1000;
  return new Date(norm);
};

const createGetSeekRange = master => () => {
  const { offset = 0 } = master;
  const player = master.ref?.current;
  if (!player) return { start: 0, end: 0 };
  const { playRangeStart, playRangeEnd } = player.getConfiguration();
  const { start = 0, end = 0 } = player.seekRange() || {};
  const selectedStartValue = Number.isFinite(playRangeStart) ? playRangeStart : start;
  const selectedEndValue = Number.isFinite(playRangeEnd) ? playRangeEnd : end;
  return { start: selectedStartValue + offset, end: selectedEndValue + offset };
};

const createSetSeekRange = players => (start, end) => {
  players.forEach(playerInfo => {
    const { offset = 0 } = playerInfo;
    const player = playerInfo.ref.current;
    if (!player) return;
    const playRangeStart = Math.max(start - offset, 0);
    const playRangeEnd =
      end < start
        ? undefined
        : Math.min(
            Math.max(end - offset, playRangeStart),
            player.getManifest().presentationTimeline.getSeekRangeEnd(),
          );
    player.configure({
      playRangeStart,
      playRangeEnd,
    });
  });
};

const createSeekFunc = (playersInfo = [], master) => {
  // Time is assumed to include offset as it is in timeUpdateEvent
  const getSeekRange = createGetSeekRange(master);
  return time => {
    if (!master.ref?.current) return;
    const { start = 0, end = 1 } = getSeekRange();
    playersInfo.forEach(playerInfo => {
      const { offset = 0 } = playerInfo;
      const normalizedTime = Math.max(start, Math.min(end, time), 0);
      const newTime = normalizedTime - offset;
      const video = getVideoElement(playerInfo);
      const player = playerInfo.ref.current || {};
      if (player.AnalyticsListener) {
        player.AnalyticsListener.sendSeekedEvent(video.currentTime, newTime, 'manual');
      }
      video.currentTime = newTime;
    });
  };
};

const createGetBufferedInfo = master => () => {
  const player = master.ref?.current;
  if (!player) return {};
  return player.getBufferedInfo();
};

const createIsLiveFunc = master => () => {
  const player = master.ref?.current;
  if (!player) return false;
  return player.isLive();
};

const createIsMuted = (playersInfo = [], options = {}) => {
  const { includeAudioOnly = true, includeVideoStreams = true } = options;
  return () => {
    const unmutedMap = playersInfo
      .filter(item => {
        if (includeAudioOnly && item.audioOnly) return true;
        if (includeVideoStreams && !item.audioOnly) return true;
        return false;
      })
      .filter(item => {
        const video = getVideoElement(item);
        if (typeof video.muted !== 'boolean') {
          return false;
        }
        return !video.muted;
      });
    return unmutedMap.length < 1;
  };
};

const createMuteFunc = (playersInfo = [], options = {}, setState) => {
  const { includeAudioOnly = true, includeVideoStreams = true } = options;
  const isMuted = createIsMuted(playersInfo);
  const isVideoStreamsMuted = createIsMuted(playersInfo, { includeAudioOnly: false });
  const isAudioOnlyMuted = createIsMuted(playersInfo, { includeVideoStreams: false });
  return () => {
    playersInfo
      .filter(item => {
        if (includeAudioOnly && item.audioOnly) return true;
        if (includeVideoStreams && !item.audioOnly) return true;
        return false;
      })
      .forEach(playerInfo => {
        const video = getVideoElement(playerInfo);
        video.muted = true;
      });
    if (setState) {
      setState('muted', isMuted());
      if (includeAudioOnly) setState('audioOnlyMuted', isAudioOnlyMuted());
      if (includeVideoStreams) setState('videoStreamsMuted', isVideoStreamsMuted());
    }
  };
};

const createUnmuteFunc = (playersInfo = [], master, options = {}, setState) => {
  const { includeAudioOnly = true, includeVideoStreams = true } = options;
  const isMuted = createIsMuted(playersInfo);
  const isVideoStreamsMuted = createIsMuted(playersInfo, { includeAudioOnly: false });
  const isAudioOnlyMuted = createIsMuted(playersInfo, { includeVideoStreams: false });

  return () => {
    playersInfo
      .filter(item => {
        if (includeAudioOnly && item.audioOnly) return true;
        if (includeVideoStreams && !item.audioOnly && item.id === master.id) return true;
        return false;
      })
      .forEach(playerInfo => {
        const video = getVideoElement(playerInfo);
        video.muted = false;
      });
    if (setState) {
      setState('muted', isMuted());
      if (includeAudioOnly) setState('audioOnlyMuted', isAudioOnlyMuted());
      if (includeVideoStreams) setState('videoStreamsMuted', isVideoStreamsMuted());
    }
  };
};

const createGetVolume = (playersInfo = [], options = {}) => {
  const { includeAudioOnly = true, includeVideoStreams = true } = options;
  return () => {
    const volumeMap = playersInfo
      .filter(item => {
        if (includeAudioOnly && item.audioOnly) return true;
        if (includeVideoStreams && !item.audioOnly) return true;
        return false;
      })
      .map(item => {
        const video = getVideoElement(item);
        return video.volume;
      });
    const cleanVolumeMap = volumeMap.filter(v => Number(v) === v);
    return Math.max(...cleanVolumeMap);
  };
};

const createSetVolume = (playersInfo = [], master, options = {}, setState) => {
  const { includeAudioOnly = true, includeVideoStreams = true } = options;
  const mute = createMuteFunc(playersInfo, options, setState);
  const unmute = createUnmuteFunc(playersInfo, master, options, setState);
  const getAudioOnlyVolume = createGetVolume(playersInfo, { includeVideoStreams: false });
  const getVideoVolume = createGetVolume(playersInfo, { includeAudioOnly: false });
  return (volume, dontMuteZeroVolume) => {
    if (Boolean(dontMuteZeroVolume) === false) {
      if (volume === 0) {
        mute();
        return;
      }
      unmute();
    }

    playersInfo
      .filter(item => {
        if (includeAudioOnly && item.audioOnly) return true;
        if (includeVideoStreams && !item.audioOnly) return true;
        return false;
      })
      .forEach(item => {
        const video = getVideoElement(item);
        video.volume = volume;
      });

    if (setState && Number.isFinite(getVideoVolume())) {
      setState('videoStreamsVolume', getVideoVolume());
    }
    if (setState && Number.isFinite(getAudioOnlyVolume())) {
      setState('audioOnlyVolume', getAudioOnlyVolume());
    }
  };
};

const createGetPlaybackRate = master => () => {
  const video = getVideoElement(master);
  return video.playbackRate;
};

const createSetPlaybackRate = (playersInfo, setState) => playbackRate => {
  playersInfo.forEach(playerInfo => {
    const video = getVideoElement(playerInfo);
    if (!video) return;
    video.playbackRate = playbackRate;
  });
  setState('pbr', playbackRate);
};

const aggressiveAdjustors = {
  max: 1,
  mid: 0.5,
  min: 0.25,
};

const adjustors = {
  max: 1,
  mid: 0.75,
  min: 0.5,
};

const getSplitAdjustedValue = (split, index, max, aggressive = true) => {
  const { mid: midAdj, min: minAdj } = aggressive ? aggressiveAdjustors : adjustors;

  const mid = max ? midAdj * max : 1;
  const min = max ? minAdj * max : 1;

  switch (split) {
    case 2:
      return mid;
    case 4:
      return min;
    case 3: {
      if (index === 0) return aggressive ? mid : max;
      return min;
    }
    case 1:
    default:
      return max;
  }
};

export const getClosestLowerQualityVariant = (variants = [], quality = 0) => {
  const sortedVariants = variants.sort((q1, q2) => q2.bandwidth - q1.bandwidth);

  const lastVariant = sortedVariants[variants.length - 1] ?? {};
  const { bandwidth: lowestBitrate } = lastVariant;
  if (quality < lowestBitrate) {
    return lastVariant;
  }
  return sortedVariants.find((variant = {}) => {
    const { bandwidth: bitrate } = variant;
    return bitrate <= quality;
  });
};

const getMaxBitrates = variants => {
  if (variants.length < 1) return [];
  const sortedVariants = variants?.sort((q1, q2) => q1.bandwidth - q2.bandwidth) ?? [];
  const highestQualityVariant = sortedVariants[variants.length - 1] ?? {};
  const { bandwidth: maxBitrate, height: maxHeight } = highestQualityVariant;

  // if there are multiple tracks of the highest res, choose the one with lowest bitrate
  const variantsWithHighestResolution = variants.filter(v => v.height >= maxHeight) ?? {};
  const { bandwidth: highestAllowedBitrateInMultiView } = variantsWithHighestResolution[0];
  return [maxBitrate, highestAllowedBitrateInMultiView];
};

const createSelectQuality =
  (playersInfo = [], setState, aggressive = false) =>
  (providedQuality, clearBuffer = true, safeMargin = 2) => {
    const quality = Number(providedQuality);
    /*
    don't include audioOnly players in quality calculations
    and don't set quality to audioOnly players
    */
    const playersWithVideo = playersInfo.filter(player => !player.audioOnly);
    const split = playersWithVideo.length;

    playersWithVideo.forEach((playerInfo, index) => {
      const player = playerInfo.ref?.current;
      if (!player) return;
      const variants = player.getVariantTracks();
      if (quality === -1) {
        const { estimatedBandwidth } = player.getStats();
        const [maxBitrate, maxBitrateInMultiView] = getMaxBitrates(variants);
        const max = Math.min(estimatedBandwidth, maxBitrateInMultiView || maxBitrate);
        const bitrate = getSplitAdjustedValue(split, index, max, aggressive);
        player.configure({
          abr: {
            enabled: true,
            restrictions: {
              maxBandwidth: split < 2 || !bitrate ? Infinity : bitrate,
            },
          },
        });
      } else {
        const adjustedQuality = getSplitAdjustedValue(split, index, quality, aggressive);
        const track = getClosestLowerQualityVariant(variants, adjustedQuality);
        if (track && track.active) return;
        if (track) {
          player.configure('abr.enabled', false);
          player.selectVariantTrack(track, clearBuffer, safeMargin);
        } else {
          // If we try to select a quality before we know about tracks, set an abr restriction
          player.configure('abr.restrictions.maxBandwidth', quality);
        }
      }

      if (setState) setState('quality', quality);
    });
  };

const createGetVariantTracks = master => () => {
  const player = master.ref?.current;
  if (!player) return [];
  return player.getVariantTracks();
};

const createGetMasterVideoElement = master => () => {
  const video = getVideoElement(master);
  return video;
};

const createSetMasterAsSoundSource =
  playersInfo =>
  (shouldBeMuted = true) => {
    playersInfo.forEach(video => {
      const videoElement = getVideoElement(video);
      if (video.master && !shouldBeMuted) {
        videoElement.muted = false;
      } else if (!video.audioOnly) {
        videoElement.muted = true;
      }
    });
  };

const createGetStats = master => () => {
  const player = master.ref?.current;
  if (!player) return {};
  return player.getStats();
};

const usePlayerFunctions = (playersInfo, setState = () => {}, featureFlags = {}) => {
  const master = selectMaster(playersInfo) || { ref: { current: null } };
  const playerFunctions = {
    play: createVideoFunc(playersInfo, 'play'),
    pause: createVideoFunc(playersInfo, 'pause'),
    isPaused: createIsPausedFunc(master),
    getCurrentTime: createGetCurrentTime(master, playersInfo),
    getCurrentDateTime: createGetCurrentDateTime(master),
    getStartDateTime: createGetStartDateTime(master),
    seek: createSeekFunc(playersInfo, master),
    setPlaybackRate: createSetPlaybackRate(playersInfo, setState),
    getPlaybackRate: createGetPlaybackRate(master),
    getVariantTracks: createGetVariantTracks(master),
    isLive: createIsLiveFunc(master),
    // seekRange - shaka player and offset from info - master
    getSeekRange: createGetSeekRange(master),
    setSeekRange: createSetSeekRange(playersInfo),
    selectQuality: createSelectQuality(
      playersInfo,
      setState,
      featureFlags?.qualityAdjustorStrategy === 'aggressive',
    ),
    mute: createMuteFunc(playersInfo, undefined, setState),
    muteAudioOnlyVolume: createMuteFunc(playersInfo, { includeVideoStreams: false }, setState),
    muteVideoStreamVolume: createMuteFunc(playersInfo, { includeAudioOnly: false }, setState),
    unmute: createUnmuteFunc(playersInfo, master, undefined, setState),
    unmuteAudioOnlyVolume: createUnmuteFunc(
      playersInfo,
      master,
      { includeVideoStreams: false },
      setState,
    ),
    unmuteVideoStreamVolume: createUnmuteFunc(
      playersInfo,
      master,
      { includeAudioOnly: false },
      setState,
    ),
    isMuted: createIsMuted(playersInfo),
    isAudioOnlyMuted: createIsMuted(playersInfo, { includeVideoStreams: false }),
    isVideoStreamMuted: createIsMuted(playersInfo, { includeAudioOnly: false }),
    getBufferedInfo: createGetBufferedInfo(master),
    getVolume: createGetVolume(playersInfo),
    getAudioOnlyVolume: createGetVolume(playersInfo, { includeVideoStreams: false }),
    getVideoStreamVolume: createGetVolume(playersInfo, { includeAudioOnly: false }),
    setVolume: createSetVolume(playersInfo, master, undefined, setState),
    setAudioOnlyVolume: createSetVolume(
      playersInfo,
      master,
      { includeVideoStreams: false },
      setState,
    ),
    setVideoStreamVolume: createSetVolume(
      playersInfo,
      master,
      { includeAudioOnly: false },
      setState,
    ),
    getMasterVideoElement: createGetMasterVideoElement(master),
    setMasterAsSoundSource: createSetMasterAsSoundSource(playersInfo),
    getStats: createGetStats(master),
  };

  return playerFunctions;
};

export default usePlayerFunctions;
