import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Icon from 'znipe-elements/general/Icon/Icon';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import colors from 'znipe-styles/colors';
import ControlledMenu from '../ControlledMenu/ControlledMenu';
import { IconWrapper, Label, LinkItem, Container, HintContainer } from './Navigation.styles';
import themes from './Navigation.themes';

const Navigation = ({ menuItems, size = 'small' }) => {
  const { pathname } = useLocation();
  const { colorSchemeMap, navigation } = useThemeContext();
  const iconColors = useMemo(() => {
    const { home = {}, matches = {}, standings = {}, browse = {} } = colorSchemeMap;
    return [home, matches, standings, browse];
  }, [colorSchemeMap]);
  const textSize = useMemo(() => {
    if (size === 'large') return 'title-m';
    if (size === 'medium') return 'title-s';
    return 'title-xs';
  }, [size]);

  const links = useMemo(
    () =>
      menuItems?.map(({ label, icon, link }, index) => {
        const {
          desktopIcons,
          inactive,
          activeIcon,
          useGradient,
          desktopTabWidth = '160px',
        } = navigation;
        const gradient = useGradient ? iconColors[index].gradient : undefined;
        const gradientColor = useGradient ? iconColors[index].rootColor : undefined;
        const isSelectedPage = pathname.includes(link);
        const iconColor = isSelectedPage ? activeIcon : inactive;
        return {
          key: label,
          width: size === 'large' ? desktopTabWidth : 'auto',
          component: (
            <HintContainer id={`masthead-navigation-${label.toLowerCase()}`}>
              <LinkItem
                key={`${label}_${icon}`}
                $active={isSelectedPage}
                active={isSelectedPage.toString()}
                $gradient={gradient}
                $shadow={gradientColor}
                $size={size}
                to={link}
                data-testid="list-item"
              >
                {(size !== 'large' || desktopIcons) && (
                  <IconWrapper $size={size}>
                    <Icon
                      type={icon}
                      size={24}
                      fillColor={useGradient ? colors.white : iconColor}
                      activeColor={gradientColor}
                      gradient={useGradient}
                    />
                  </IconWrapper>
                )}
                <Label type={textSize}>{label}</Label>
              </LinkItem>
            </HintContainer>
          ),
        };
      }),
    [iconColors, menuItems, pathname, textSize, navigation, size],
  );

  return (
    <Container $size={size} data-testid="navigation-container" aria-label="navigation" tabIndex={0}>
      <ControlledMenu items={links} position={size === 'large' ? 'left' : 'center'} />
    </Container>
  );
};

Navigation.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
};

export default withTheme(memo(Navigation), themes, 'navigation');
