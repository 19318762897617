import { createSelector } from 'reselect';
import useMemoizedSelector from 'tv-hooks/useMemoizedSelector';
import makeGetCoreUIMap from 'tv-selectors/coreUI/makeGetCoreUIMap';

const makeGetHighlightPing = () => {
  const getCoreUI = makeGetCoreUIMap();
  return createSelector([getCoreUI], coreUI => coreUI.highlightPing ?? {});
};

export const useMakeGetHighlightPing = () => useMemoizedSelector(makeGetHighlightPing);

export default makeGetHighlightPing;
