import Icon from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
import SelectorVertical from '../SelectorVertical';
import { imageSize } from '../SelectorVertical.styles';
import { propTypes } from '../../constants';

const MapSelector = ({
  label,
  size = 'small',
  isSelected = false,
  isLocked = false,
  hasPremiumAccess = false,
  onClick,
  onMouseOut,
  onMouseOver,
  'data-testid': testId = 'container',
}) => (
  <SelectorVertical
    label={label}
    size={size}
    imageArea={<Icon type="mapIcon" fillColor={colors.white} size={imageSize[size]} />}
    isSelected={isSelected}
    isLocked={isLocked}
    hasPremiumAccess={hasPremiumAccess}
    onClick={onClick}
    onMouseOut={onMouseOut}
    onMouseOver={onMouseOver}
    data-testid={testId}
  />
);

MapSelector.propTypes = propTypes;

export default MapSelector;
