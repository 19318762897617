import { useMemo } from 'react';
import Typography from 'znipe-elements/general/Typography/Typography';
import AuthManager from 'znipe-auth-manager';
import useChatContext from '../../../hooks/useChatContext';
import ColorItem from './ColorItem';
import ContentSection from '../../ContentSection/ContentSection';
import { Container, ColorsWrapper, ColorSelect } from './ColorPicker.styles';

type ColorPickerProps = {
  label?: string;
};

const ColorPicker: React.FC<ColorPickerProps> = ({ label = 'Username color' }) => {
  const { usernameColor, setUsernameColor, analyticsManager, nameColors, authManagerId } =
    useChatContext();
  const authManager = useMemo(
    () => AuthManager.getAuthManager(authManagerId) ?? new AuthManager(authManagerId),
    [authManagerId],
  );
  const isModerator = authManager.hasPrivilege('chat_moderation');
  const hasLockedOption = useMemo(
    () => (nameColors ? nameColors.filter(({ locked }) => locked).length > 0 : false),
    [nameColors],
  );
  return (
    <Container>
      <ContentSection header={label}>
        <ColorsWrapper>
          {nameColors?.map(({ hexCode, displayName, locked }) => {
            const onClick = () => {
              if (locked) return;
              analyticsManager?.trackUIEvent({
                action: `color_change`,
                trigger: 'button_color_picker_option',
                value: hexCode,
              });
              setUsernameColor(hexCode);
            };
            return (
              <ColorSelect
                key={hexCode}
                data-testid={`color-${displayName}`}
                onClick={onClick}
                $isLocked={locked}
                $isSelected={hexCode === usernameColor}
              >
                <ColorItem hexCode={hexCode} isLocked={locked} />
              </ColorSelect>
            );
          })}
        </ColorsWrapper>
      </ContentSection>
      {!isModerator && hasLockedOption && (
        <Typography type="paragraph-s" color="textSecondary">
          Upgrade to premium to access all colors.
        </Typography>
      )}
    </Container>
  );
};

export default ColorPicker;
