import { schema } from 'normalizr';

export const createPlayerId = (player, parent) =>
  player.id ?? `${parent.gameId}_${player.playerId}`;

const createPlayerObject = (player, parent) => ({
  ...player,
  id: createPlayerId(player, parent),
});

const player = new schema.Entity(
  'players',
  {},
  {
    processStrategy: createPlayerObject,
    idAttribute: createPlayerId,
  },
);

export default player;
