import { memo } from 'react';
import PropTypes from 'prop-types';
import { useGetChampionLoreText } from 'tv-selectors/champions/makeGetChampionLoreText';
import Typography from 'znipe-elements/general/Typography/Typography';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import Card from 'znipe-elements/data-display/Card/Card';
import SimpleCard from 'znipe-elements/data-display/Card/CardSimple';
import {
  ChampionAbilities,
  ChampionAbilitiesWrapper,
} from 'tv-modules/TopicAbout/TopicAbout.styles';
import AbilityDetailsList from 'tv-modules/Abilities/AbilityDetailsList/AbilityDetailsList';
import useTheme from 'tv-hooks/useTheme';
import Section from 'tv-elements/layout/Section/Section';
import { LIST } from 'tv-elements/layout/Section/Section.constants';
import ListWrapperElement from 'tv-routes/Browse/components/ListWrapperElement';
import { useGetChampionClass } from 'tv-selectors/champions/makeGetChampionClass';
import PlayerResultItem from 'tv-routes/Browse/components/PlayerResultItem';
import { useGetChampionTopPlayers } from 'tv-selectors/champions/makeGetChampionTopPlayers';
import ChampionResultItem from 'tv-routes/Browse/components/ChampionResultItem';
import { useGetOtherClassChampions } from 'tv-selectors/champions/makeGetOtherClassChampions';

const FactSplit = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
    margin: 0 8px 8px 0;
  }
  @media ${device.desktop} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 16px;
`;

const ChampionInfo = ({ topicId, elementSizes }) => {
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const loreText = useGetChampionLoreText({ championId: topicId });
  const { secondaryTextColor } = useTheme();
  const randomFacts = false;
  const championTopPlayers = useGetChampionTopPlayers({ championId: topicId, limit: 4 });
  const championClass = useGetChampionClass({ championId: topicId });
  const otherClassChampions = useGetOtherClassChampions({ championId: topicId, limit: 4 });

  return (
    <div>
      {isDesktopOrGreater && <Typography type="title-l">LORE</Typography>}
      {isDesktopOrGreater ? (
        <Card transparent>
          <Container>
            <Typography type="paragraph-m" color={secondaryTextColor}>
              {loreText}
            </Typography>
          </Container>
        </Card>
      ) : (
        <SimpleCard transparent title="lore">
          {loreText}
        </SimpleCard>
      )}
      {!isDesktopOrGreater && (
        <ChampionAbilities>
          <Typography type="title-m">abilities</Typography>
          <Card>
            <ChampionAbilitiesWrapper>
              <AbilityDetailsList topicId={topicId} />
            </ChampionAbilitiesWrapper>
          </Card>
        </ChampionAbilities>
      )}
      {randomFacts && (
        <>
          <Typography type="title-l">did you know?</Typography>
          <FactSplit>
            <SimpleCard transparent title="random fact #1">
              {loreText}
            </SimpleCard>
            <SimpleCard transparent title="random fact #2">
              {loreText}
            </SimpleCard>
          </FactSplit>
        </>
      )}
      {!!championTopPlayers?.length && (
        <Section title="top players" direction="vertical" type={LIST}>
          <ListWrapperElement
            shouldUseWrapper={isDesktopOrGreater}
            isDesktopOrGreater={isDesktopOrGreater}
            type="champions"
          >
            {championTopPlayers.map(({ id, ...result }) => (
              <PlayerResultItem
                key={id}
                result={{ ...result, role: championClass }}
                elementSizes={elementSizes}
                isDesktopOrGreater={isDesktopOrGreater}
                shouldUsePlayerCard={isDesktopOrGreater}
              />
            ))}
          </ListWrapperElement>
        </Section>
      )}
      {!!otherClassChampions?.length && (
        <Section title={`other ${championClass}s`} direction="vertical" type={LIST}>
          <ListWrapperElement
            shouldUseWrapper={isDesktopOrGreater}
            isDesktopOrGreater={isDesktopOrGreater}
            type="champions"
          >
            {otherClassChampions.map(({ id, ...result }) => (
              <ChampionResultItem
                key={id}
                result={result}
                elementSizes={elementSizes}
                isDesktopOrGreater={isDesktopOrGreater}
              />
            ))}
          </ListWrapperElement>
        </Section>
      )}
    </div>
  );
};

ChampionInfo.propTypes = {
  topicId: PropTypes.string.isRequired,
  elementSizes: PropTypes.shape({
    featuredMatch: PropTypes.string.isRequired,
    matchRerun: PropTypes.string.isRequired,
    vodItem: PropTypes.string.isRequired,
    highlightItems: PropTypes.number.isRequired,
    headerSize: PropTypes.string.isRequired,
    aboutSize: PropTypes.string.isRequired,
    genericSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(ChampionInfo);
