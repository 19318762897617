import { useCallback, useEffect, useMemo, useRef } from 'react';
import useDimensions from 'react-cool-dimensions';
import useCreateResizeText from 'znipe-hooks/useCreateResizeText';
import useThemeContext from 'znipe-hooks/useThemeContext';
import { ResizeText } from './Selector.styles';
import typographyThemes from '../../general/Typography/Typography.themes';

const useLabelTypography = (text, { type = '', maxSize, minSize, maxWidth, textAlign, color }) => {
  const fittyObject = useRef(null);
  const theme = useThemeContext();
  const { observe, width } = useDimensions();

  const calculatedMaxSize = useMemo(() => {
    if (maxSize) return maxSize;
    const [typeStyle, typeSize] = type.split('-');
    const fontSize = typographyThemes[theme?.themeName]?.[typeStyle]?.[typeSize]?.fontSize;
    if (!fontSize) return maxSize;

    return parseInt(fontSize.replace('px', ''), 10) || maxSize;
  }, [theme?.themeName, type, maxSize]);

  const resizeText = useCreateResizeText({ maxSize: calculatedMaxSize, minSize });

  const setRef = useCallback(
    node => {
      fittyObject.current = resizeText(node);
      observe(node);
    },
    [resizeText, observe],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Recalculate on resize
  useEffect(() => {
    fittyObject.current.fit();
  }, [width]);

  return useMemo(
    () => (
      <ResizeText
        align={textAlign}
        ref={setRef}
        as={type ? undefined : 'span'}
        type={type}
        color={color}
        $maxWidth={maxWidth}
        forwardedAs="span"
      >
        {text}
      </ResizeText>
    ),
    [textAlign, setRef, type, color, maxWidth, text],
  );
};

export default useLabelTypography;
