import { forwardRef, memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import Matchup from 'tv-modules/Standings/Matchup/Matchup';
import Typography from 'znipe-elements/general/Typography/Typography';
import EmptyColumn from '../EmptyColumn/EmptyColumn';
import { Title, ColumnContainer, Column, MatchWrapper, LowerBracketItem } from '../Bracket.styles';
import { columnsPropType, LOWER_BRACKET } from '../utils/constants';

const BracketLower = forwardRef(
  ({ title = LOWER_BRACKET, columns = [], selectedTeams = [] }, ref) => {
    const [hoveredTeam, setHoveredTeam] = useState('');
    const isDesktopOrGreater = useGetIsDesktopOrGreater();
    const typographyType = useMemo(
      () => (isDesktopOrGreater ? 'title-l' : 'title-m'),
      [isDesktopOrGreater],
    );
    const columnItems = useMemo(
      () =>
        columns.map((column, index) => {
          const { roundNumber, roundName, matchups } = column;
          const isFirstColumn = index === 0;
          const isLastColumn = index === columns.length - 1;
          const prevColumnMatchups = isFirstColumn ? matchups : columns[index - 1].matchups;
          const nextColumnMatchups = isLastColumn ? matchups : columns[index + 1].matchups;
          const lessMatchupsThanPrev = prevColumnMatchups.length > matchups.length;
          const sameNumberOfMatchupsNext = nextColumnMatchups.length === matchups.length;
          if (matchups.length === 0) {
            return (
              <EmptyColumn numberOfMatchupsPrev={prevColumnMatchups.length} numberOfColumns={1} />
            );
          }
          return matchups.map((matchup, matchupIndex) => {
            const toUpper = !isLastColumn && matchupIndex % 2 === 1 && !sameNumberOfMatchupsNext;
            const toLower =
              (!isLastColumn && matchupIndex % 2 === 0 && !sameNumberOfMatchupsNext) ||
              (!isLastColumn && sameNumberOfMatchupsNext);
            const label = roundName || `Round ${roundNumber}`;
            return (
              <LowerBracketItem
                key={matchup.id}
                active={false}
                isLastColumn={isLastColumn}
                bothFromUpper={!lessMatchupsThanPrev}
                fromUpper={lessMatchupsThanPrev}
                fromLower={lessMatchupsThanPrev}
                toUpper={toUpper && !isLastColumn}
                toLower={toLower && !isLastColumn}
                sameNumberOfMatchupsNext={sameNumberOfMatchupsNext}
                hoveredTeam={hoveredTeam}
                winner={matchup.winner}
              >
                {isFirstColumn ? (
                  <Matchup
                    label={matchupIndex === 0 ? label : ''}
                    teamOne={matchup.teamOne}
                    teamTwo={matchup.teamTwo}
                    winner={matchup.winner}
                    setHoveredTeam={setHoveredTeam}
                    selectedTeams={selectedTeams}
                    matchId={matchup.matchId}
                  />
                ) : (
                  <MatchWrapper>
                    <Matchup
                      label={matchupIndex === 0 ? label : ''}
                      teamOne={matchup.teamOne}
                      teamTwo={matchup.teamTwo}
                      winner={matchup.winner}
                      setHoveredTeam={setHoveredTeam}
                      selectedTeams={selectedTeams}
                      matchId={matchup.matchId}
                    />
                  </MatchWrapper>
                )}
              </LowerBracketItem>
            );
          });
        }),
      [columns, hoveredTeam, selectedTeams],
    );

    return (
      <div data-testid="lower-bracket">
        <Title bracketType="lower" data-testid="lower-bracket-title">
          <Typography type={typographyType}>{title}</Typography>
        </Title>
        <ColumnContainer ref={ref}>
          {columnItems.map((item, index) => (
            <Column
              key={columns[index].roundNumber.toString()}
              data-testid={`column-${columns[index].roundNumber}`}
            >
              {item}
            </Column>
          ))}
        </ColumnContainer>
      </div>
    );
  },
);

BracketLower.propTypes = {
  title: PropTypes.string,
  columns: columnsPropType,
  selectedTeams: PropTypes.arrayOf(PropTypes.string),
};

export default memo(BracketLower);
