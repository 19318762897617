import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  width: 100%;
  padding: ${({ $padding }) => $padding ?? '24px 8px 0 8px'};
  display: flex;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  @media ${device.mobilePortrait} {
    padding: ${({ $padding }) => $padding ?? '24px 16px 0 16px'};
  }
  @media ${device.tablet} {
    padding: ${({ $padding }) => $padding ?? '48px 0 0 0'};
  }
  @media ${device.desktop} {
    padding: ${({ $padding }) => $padding ?? '64px 0 0 0'};
  }
`;

export const TextWrapper = styled.div`
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      text-align: center;
    `};
  ${({ isTitle }) =>
    isTitle &&
    css`
      margin-bottom: 8px;
    `}
`;
