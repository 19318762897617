import { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import ChatEntry from '../Entry/Entry';
import useMessageIds from '../../hooks/useMessageIds';
import useScrollToBottom from '../../hooks/useScrollToBottom';
import { LATEST_MESSAGES_SEPARATOR } from '../../constants';
import { FeedContainer, Line, ScrollDownButton, Separator, Wrapper } from './Feed.styles';

const LatestMessagesSeparator = () => (
  <Separator>
    <Line />
    <Typography type="title-xs" color="grey53">
      New
    </Typography>
  </Separator>
);

const Feed = ({ isMobile = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const messageIds = useMessageIds();

  const { isAtBottom, scrollToBottom } = useScrollToBottom(ref);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Scroll to bottom when messages are added
  useLayoutEffect(() => {
    if (isAtBottom) scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageIds.length, messageIds[0]]);

  return (
    <Wrapper>
      <FeedContainer ref={ref} isScrolledUp={!isAtBottom}>
        {messageIds.map(id =>
          id === LATEST_MESSAGES_SEPARATOR ? (
            <LatestMessagesSeparator key={LATEST_MESSAGES_SEPARATOR} />
          ) : (
            <ChatEntry messageId={id} key={id} isMobile={isMobile} parentRef={ref} />
          ),
        )}
      </FeedContainer>
      {!isAtBottom && (
        <ScrollDownButton>
          <Button
            icon="chevronDown"
            variant="solid-color"
            solidColor="containerStroke"
            size="xsmall"
            lightness={-4}
            fluid
            onClick={scrollToBottom}
          >
            New messages
          </Button>
        </ScrollDownButton>
      )}
    </Wrapper>
  );
};

Feed.propTypes = {
  isMobile: PropTypes.bool,
};

export default Feed;
