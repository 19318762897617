import colors from 'znipe-styles/colors';

export default {
  proview: {
    bgColor: colors.riotBackgroundPrimary,
    bgColorHover: 'rgba(255, 255, 255, 0.1)',
    textColorSelected: colors.spaceGreen,
    textColorMenu: colors.white,
    button: {
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '22px',
    },
  },
  default: {
    bgColor: colors.riotBackgroundPrimary,
    bgColorHover: 'rgba(255, 255, 255, 0.1)',
    textColorSelected: colors.spaceGreen,
    textColorMenu: colors.white,
    button: {
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '22px',
    },
  },
};
