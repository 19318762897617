import styled from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import convertHexToRGB, { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';
import { searchInputWidth } from 'tv-modules/Masthead/Masthead.styles';

export const SearchInputWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  align-items: center;
  flex-direction: column;
  flex-basis: ${searchInputWidth.desktop}px;
  @media ${device.desktopLarge} {
    flex-basis: ${searchInputWidth.desktopLarge}px;
  }
`;

export const SearchDropdownBox = styled.div`
  width: 100%;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => convertHexToRGB(theme.primaryBackgroundColor, 0.9)};
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(20px);
  border-radius: 0px 0px 5px 5px;
  padding-top: ${({ resultType }) => (resultType === 'history' ? 24 : 8)}px;
`;

export const DesktopSearchContainer = styled.div`
  width: ${searchInputWidth.desktop}px;
  @media ${device.desktopLarge} {
    width: ${searchInputWidth.desktopLarge}px;
  }
  @media ${device.desktopExtraLarge} {
    right: 0px;
  }
`;

export const DesktopSearch = styled.div`
  position: absolute;
  z-index: 4;
  top: 8px;
  right: 25px;
  display: flex;
`;

export const SearchOverlay = styled.div`
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  z-index: 7;
  padding-top: 16px;
  background: rgba(${({ theme }) => convertHexToRGBDigits(theme.primaryBackgroundColor)}, 0.9);
  backdrop-filter: blur(15px);
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    top: 72px;
    padding: 24px 40px 0 40px;
  }
`;

export const PremiumButtonWrapper = styled.div`
  margin-right: 16px;
  display: flex;
`;
