import produce from 'immer';
import deepmerge from 'deepmerge';
import { SET_TOURNAMENTS } from 'tv-constants/redux';

export const initialState = {};

const customMerge = key => {
  if (key === 'tournamentStages') return (val1, val2) => [...new Set([...val1, ...val2])];

  return undefined;
};

const tournamentsReducer = produce((state, { type, payload }) => {
  switch (type) {
    case SET_TOURNAMENTS:
      return deepmerge(state, payload, { customMerge });
    default:
      return state;
  }
}, initialState);

export default tournamentsReducer;
