import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';

export const Container = styled.div`
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    height: calc(100% - 72px);
    padding-bottom: 64px;
  }
  @media ${device.desktop} {
    height: calc(100% - 64px);
  }
`;

export const Menu = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const MenuItemContainerStyles = css`
  display: flex;
  background: transparent;
  align-items: center;
  padding: 0 20px;
  height: 64px;
  border: none;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }

  &:hover {
    background: ${({ theme }) => theme.tertiaryBackgroundColor};
    text-decoration: none;
  }
`;

export const MenuItemContainer = styled(Link)`
  ${MenuItemContainerStyles}
`;

export const MenuItemContainerRegularLink = styled.a`
  ${MenuItemContainerStyles}
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.tertiaryBackgroundColor};
  height: 1px;
  width: 100%;
  margin: 0 16px;
`;

export const BottomSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:first-child {
    width: ${({ $thin }) => ($thin ? '140px' : 'auto')};
    height: ${({ $thin }) => ($thin ? '40px' : 'auto')};
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

export const FinePrintLink = styled(Link)`
  color: ${({ theme }) => theme.secondaryTextColor};
  text-align: center;
  background: transparent;
  border: none;
  cursor: pointer;

  &:not(:last-child):after {
    display: inline-block;
    content: '·';
    margin: 0 8px;
    color: ${({ theme }) => theme.secondaryTextColor};
  }

  &:hover,
  &:focus {
    color: ${colors.white};
    text-decoration: underline;
  }
`;

export const FinePrintRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
