import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayers from 'tv-selectors/players/makeGetPlayers';
import makeGetGameGlobalStreams from 'tv-selectors/games/makeGetGameGlobalStreams';
import makeGetStreamId from 'tv-selectors/streams/makeGetStreamId';
import makeGetPlayerId from 'tv-selectors/players/makeGetPlayerId';

const makeGetStreamLabel = () => {
  const getStreamId = makeGetStreamId();
  const getPlayerId = makeGetPlayerId();
  const getPlayersMap = makeGetPlayers();
  const getGameGlobalStreams = makeGetGameGlobalStreams();
  return createSelector(
    [getPlayersMap, getGameGlobalStreams, getStreamId, getPlayerId],
    (players, gameGlobalStreams, streamId, playerId) => {
      let label = '';
      gameGlobalStreams.forEach(({ streamId: currentStreamId, name, type }) => {
        if (currentStreamId === streamId) {
          label = name || type;
        }
      });

      if (!label && playerId !== null) {
        label = (players[playerId] || {}).name || '';
      }
      return label;
    },
  );
};

export const useGetStreamLabel = props => useMemoizedGqlSelector(makeGetStreamLabel, props);

export default makeGetStreamLabel;
