import { memo } from 'react';
import PropTypes from 'prop-types';
import NavButton from 'znipe-elements/navigation/NavButton/NavButton';

const PredictionsButton = ({
  onClick,
  active,
  iconType = 'circleQuestion',
  size,
  showLabel = true,
}) => (
  <NavButton
    id="predictions-button"
    label="Predict"
    iconType={iconType}
    onClick={onClick}
    isToggledOn={active}
    size={size}
    showLabel={showLabel}
  />
);

PredictionsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  iconType: PropTypes.oneOf(['info', 'stats', 'close']),
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']).isRequired,
};

export default memo(PredictionsButton);
