import { createSelector } from 'reselect';
import useMemoizedGqlSelector from 'tv-hooks/useMemoizedGqlSelector';
import makeGetPlayer from 'tv-selectors/players/makeGetPlayer';

const makeGetPlayerName = () => {
  const getPlayer = makeGetPlayer();
  return createSelector([getPlayer], player => player.name || '');
};

export const useGetPlayerName = props => useMemoizedGqlSelector(makeGetPlayerName, props);

export default makeGetPlayerName;
